import { Folder } from 'interfaces/folder';
import { Project } from 'interfaces/projects';

export interface FoldersState {
    foldersList: Folder[];
    total: number;
    currentFolder: Folder | null;
    foldersHistory: Folder[];
    isLoading: boolean;
    isUpdateLoading: boolean;
    isCreationLoading: boolean;
    isDeleteLoading: boolean;
    isMoveToFolderLoading: boolean;
}

export enum FoldersActionTypes {
    // get folders
    GET_FOLDERS = '@@folders/GET_FOLDERS',
    GET_FOLDERS_START = '@@folders/GET_FOLDERS_START',
    GET_FOLDERS_FAIL = '@@folders/GET_FOLDERS_FAIL',
    SET_FOLDERS = '@@folders/SET_FOLDERS',
    // Current folder
    GET_CURRENT_FOLDER = '@@folders/GET_CURRENT_FOLDER',
    SET_CURRENT_FOLDER = '@@folders/SET_CURRENT_FOLDER',
    // folders history
    SET_FOLDERS_HISTORY = '@@folders/SET_FOLDERS_HISTORY',
    ADD_FOLDER_TO_HISTORY = '@@folders/ADD_FOLDER_TO_HISTORY',
    // UPDATE_FOLDERS_HISTORY = '@@folders/UPDATE_FOLDERS_HISTORY',
    // move to folder
    MOVE_TO_FOLDER = '@@folders/MOVE_TO_FOLDER',
    MOVE_TO_FOLDER_START = '@@folders/MOVE_TO_FOLDER_START',
    MOVE_TO_FOLDER_FAIL = '@@folders/MOVE_TO_FOLDER_FAIL',
    MOVE_TO_FOLDER_SUCCESS = '@@folders/MOVE_TO_FOLDER_SUCCESS',
    // create folder
    CREATE_FOLDER = '@@folders/CREATE_FOLDER',
    CREATE_FOLDER_START = '@@folders/CREATE_FOLDER_START',
    CREATE_FOLDER_FAIL = '@@folders/CREATE_FOLDER_FAIL',
    CREATE_FOLDER_SUCCESS = '@@folders/CREATE_FOLDER_SUCCESS',
    // update folder
    UPDATE_FOLDER = '@@folders/UPDATE_FOLDER',
    UPDATE_FOLDER_START = '@@folders/UPDATE_FOLDER_START',
    UPDATE_FOLDER_SUCCESS = '@@folders/UPDATE_FOLDER_SUCCESS',
    UPDATE_FOLDER_FAIL = '@@folders/UPDATE_FOLDER_FAIL',
    // delete folder
    DELETE_FOLDER = '@@folders/DELETE_FOLDER',
    DELETE_FOLDER_START = '@@folders/DELETE_FOLDER_START',
    DELETE_FOLDER_SUCCESS = '@@folders/DELETE_FOLDER_SUCCESS',
    DELETE_FOLDER_FAIL = '@@folders/DELETE_FOLDER_FAIL',
}

// get folders

export interface GetFoldersActionPayload {
    isLoadMore?: boolean;
    id?: string;
}

export interface GetFoldersAction {
    type: FoldersActionTypes.GET_FOLDERS;
    payload?: GetFoldersActionPayload;
}

export interface GetFoldersStartAction {
    type: FoldersActionTypes.GET_FOLDERS_START;
}

export interface GetFoldersFailAction {
    type: FoldersActionTypes.GET_FOLDERS_FAIL;
}

export interface SetFoldersActionPayload {
    folders: Folder[];
    total: number;
}

export interface SetFoldersAction {
    type: FoldersActionTypes.SET_FOLDERS;
    payload: SetFoldersActionPayload;
}

// move to folder

export interface MoveToFolderActionPayload {
    folder?: Folder;
    project?: Project;
    selectedFolderId?: string;
    currentFolderId?: string;
}

export interface MoveToFolderAction {
    type: FoldersActionTypes.MOVE_TO_FOLDER;
    payload: MoveToFolderActionPayload;
}

export interface MoveToFolderStartAction {
    type: FoldersActionTypes.MOVE_TO_FOLDER_START;
}

export interface MoveToFolderFailAction {
    type: FoldersActionTypes.MOVE_TO_FOLDER_FAIL;
}

export interface MoveToFolderSuccessAction {
    type: FoldersActionTypes.MOVE_TO_FOLDER_SUCCESS;
}

// create folder

export interface CreateFolderAction {
    type: FoldersActionTypes.CREATE_FOLDER;
    payload: Folder;
}

export interface CreateFolderStartAction {
    type: FoldersActionTypes.CREATE_FOLDER_START;
}

export interface CreateFolderFailAction {
    type: FoldersActionTypes.CREATE_FOLDER_FAIL;
}

export interface CreateFolderSuccessAction {
    type: FoldersActionTypes.CREATE_FOLDER_SUCCESS;
}

// update folder

export interface UpdateFolderActionPayload {
    id: string;
    data: Folder;
    hideModal?: boolean;
}

export interface UpdateFolderAction {
    type: FoldersActionTypes.UPDATE_FOLDER;
    payload: UpdateFolderActionPayload;
}

export interface UpdateFolderStartAction {
    type: FoldersActionTypes.UPDATE_FOLDER_START;
}

export interface UpdateFolderFailAction {
    type: FoldersActionTypes.UPDATE_FOLDER_FAIL;
}

export interface UpdateFolderSuccessAction {
    type: FoldersActionTypes.UPDATE_FOLDER_SUCCESS;
}

// folders history

export interface SetFoldersHistoryAction {
    type: FoldersActionTypes.SET_FOLDERS_HISTORY;
    payload: Folder[];
}

// export interface UpdateFoldersHistoryAction {
//     type: FoldersActionTypes.UPDATE_FOLDERS_HISTORY;
//     payload: {
//       isGoBack?: boolean;
//       folder?: Folder;
//     };
// }
export interface AddFolderToHistoryAction {
    type: FoldersActionTypes.ADD_FOLDER_TO_HISTORY;
    payload: Folder;
}

// delete folder

export interface DeleteFolderAction {
    type: FoldersActionTypes.DELETE_FOLDER;
    payload: string;
}

export interface DeleteFolderStartAction {
    type: FoldersActionTypes.DELETE_FOLDER_START;
}

export interface DeleteFolderFailAction {
    type: FoldersActionTypes.DELETE_FOLDER_FAIL;
}

export interface DeleteFolderSuccessAction {
    type: FoldersActionTypes.DELETE_FOLDER_SUCCESS;
}

// Current Folder
export interface GetCurrentFolderAction {
    type: FoldersActionTypes.GET_CURRENT_FOLDER;
    payload: string;
}

export interface SetCurrentFolderAction {
    type: FoldersActionTypes.SET_CURRENT_FOLDER;
    payload: Folder | null;
}

export type FoldersAction =
    | GetFoldersAction
    | GetFoldersStartAction
    | GetFoldersFailAction
    | SetFoldersAction
    | SetFoldersHistoryAction
    | MoveToFolderSuccessAction
    | MoveToFolderStartAction
    | MoveToFolderFailAction
    | MoveToFolderAction
    | CreateFolderAction
    | CreateFolderStartAction
    | CreateFolderFailAction
    | CreateFolderSuccessAction
    | UpdateFolderAction
    | UpdateFolderStartAction
    | UpdateFolderFailAction
    | UpdateFolderSuccessAction
    | DeleteFolderAction
    | DeleteFolderStartAction
    | DeleteFolderFailAction
    | DeleteFolderSuccessAction
    | SetCurrentFolderAction
    | GetCurrentFolderAction
    | AddFolderToHistoryAction;
