import styled from 'styled-components';
import { useMemo } from 'react';

import { Workspace, WorkspacePlanNames } from 'interfaces/workspaces';
import { useDispatch } from 'react-redux';
import { changeWorkspace } from 'state/modules/workspaces';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../UI';
import { NEUTRAL_300, NEUTRAL_600, PURPLE_200, PURPLE_50 } from '../../styles/colors';

const CardContainer = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
`;

const Avatar = styled.div<{ size?: string }>`
    width: ${({ size }) => (size ? `${size}` : '2rem')};
    height: ${({ size }) => (size ? `${size}` : '2rem')};
    background: ${PURPLE_50};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    margin-right: 0.5rem;
`;

const AvatarPlaceholder = styled.span`
    font-family: 'Inter Bold', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    color: ${PURPLE_200};
    text-transform: uppercase;
`;

const Indicator = styled.div`
    border: 3px solid rgb(255, 255, 255);
    background-color: rgb(255, 199, 56);
    border-radius: 100%;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -5px;
    bottom: -5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 5px;
    padding: 0.3rem;
`;

const Info = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
`;

const Name = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: left;
    color: ${NEUTRAL_600};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0.188rem;
`;

const InfoFooter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.781rem;
    color: ${NEUTRAL_300};
    text-transform: capitalize;
    //letter-spacing: 0.01em;
`;

const InfoDivider = styled.p`
    font-size: 0.71875rem;
    line-height: 0.75rem;
    letter-spacing: 0.01em;
    max-width: 0.625rem;
    margin: 0 0.25rem;
    color: ${NEUTRAL_300};
`;

const Plan = styled.p``;

const Members = styled.p``;

const IconContainer = styled.div<{ isUp: boolean }>`
    margin-right: 5px;
    transform: ${(props) => (props.isUp ? 'rotate(180deg)' : 'none')};
`;

interface Props {
    workspace: Workspace;
    isMembersHidden?: boolean;
    disabled?: boolean;
    hasIndicator?: boolean;
    onClick?: () => void;
    isModal?: boolean;
    showIcon?: boolean;
}

const WorkspaceCard = ({
    workspace,
    disabled,
    onClick,
    isMembersHidden,
    hasIndicator,
    isModal,
    showIcon,
}: Props) => {
    const { membersCount, planName } = workspace;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const workspaceName = useMemo(() => {
        return workspace?.name || '';
    }, [workspace]);

    const namePlaceholder = useMemo(() => {
        return workspaceName[0];
    }, [workspaceName]);

    const handleSelect = () => {
        dispatch(
            changeWorkspace({
                workspace,
                navigate,
            })
        );
    };

    return (
        <CardContainer disabled={disabled} onClick={onClick || handleSelect}>
            <Avatar size="2.625rem">
                <AvatarPlaceholder>{namePlaceholder}</AvatarPlaceholder>
                {hasIndicator && <Indicator />}
            </Avatar>
            <Info>
                <Name>{workspaceName}</Name>
                {
                    (planName || membersCount) && (
                        <>
                            <InfoFooter>
                                <Plan>{planName === WorkspacePlanNames.INITIAL ? 'Free plan' : planName}</Plan>
                                {
                                    !isMembersHidden && (
                                        <>
                                            <InfoDivider>•</InfoDivider>
                                            <Members>{membersCount} Members</Members>
                                        </>
                                    )
                                }
                            </InfoFooter>
                        </>
                    )
                }
            </Info>
            {
                showIcon && (
                    <IconContainer isUp={!!isModal}>
                        <Icon name="dropdown-new" size={16} />
                    </IconContainer>
                )
            }
        </CardContainer>
    );
};

export default WorkspaceCard;
