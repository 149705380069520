/* eslint-disable no-restricted-globals */
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Auth } from 'aws-amplify';

import {
    FormContainer,
    NewButton,
    NewInput,
    PasswordInput,
} from 'components/UI';

import { validate } from 'utils';
import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';

import styles from './styles.module.scss';

const AppSumoSignIn = () => {
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const fixEmailIfItRequired = (value: string): string => {
        if (!value.includes('@')) {
            const splitEmailAddressByDot: string[] = `${value}`.split('.');

            const userEmail: string = splitEmailAddressByDot
                .slice(0, -2)
                .join('.');

            const domain = `${
                splitEmailAddressByDot[splitEmailAddressByDot.length - 2]
            }.${splitEmailAddressByDot.pop()}`;

            return `${userEmail}@${domain}`;
        }

        return value;
    };

    useEffect(() => {
        let creds = location.search
            .replace('?', '')
            .split('&')
            .map((kv: any) => {
                const [key, value] = kv.split('=');
                return { key, value };
            });

        const user = creds.find(({ key }: any) => key === `user`)?.value || '';
        const pass = creds.find(({ key }: any) => key === `code`)?.value || '';

        if (pass?.length && user?.length && !email.length && !code.length) {
            const formattedName = fixEmailIfItRequired(user || '');

            setEmail(formattedName || '');
            setCode(pass || '');
        }
    }, [location, email, code]);

    const handleSubmit = async () => {
        setLoading(true);

        const passwordValidationErrors = validate(
            password,
            [ValidatorType.Required, ValidatorType.Password],
            ValidationType.OnSubmit
        );

        const passwordError = passwordValidationErrors[0] || '';

        if (email.length && !passwordError.length) {
            try {
                const res = await Auth.signIn(
                    email.replace('@', '.'),
                    code
                ).then(async (results) => {
                    if (results.challengeName !== `NEW_PASSWORD_REQUIRED`) {
                        return navigate('/');
                    }

                    const completeNewPasswordRes =
                        await Auth.completeNewPassword(
                            results, // the Cognito User Object
                            password // the new password
                        )
                            .then(async () => {
                                localStorage.setItem(
                                    'isAppSumoUser',
                                    JSON.stringify(true)
                                );

                                setLoading(false);
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                });

                // const completeNewPasswordRes = await Auth.completeNewPassword(
                //     currentUser, // the Cognito User Object
                //     password // the new password
                // )
                //     .then(async () => {
                //         localStorage.setItem(
                //             'isAppSumoUser',
                //             JSON.stringify(true)
                //         );

                //         setLoading(false);
                //     })
                //     .catch((e) => {
                //         console.log(e);
                //     });

                // console.log({completeNewPasswordRes})
            } catch (error) {
                console.log('completeNewPassword', { error });
                showNotification(
                    NotificationTypes.error,
                    (error as any)?.response?.data?.message ||
                        (error as any)?.message ||
                        ''
                );

                setLoading(false);
            }
        }
        setLoading(false);
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        // setEmail(value.toLowerCase());
        setEmailError('');
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setPassword(value);
        setPasswordError('');
    };

    const handleButtonDisabledStatus = (
        emailError: string,
        passwordError: string
    ) => Boolean(emailError || passwordError);

    const renderButton = () => {
        const isSubmitDisabled = handleButtonDisabledStatus(
            emailError,
            passwordError
        );

        return (
            <div className={styles.SignIn__formButtonContainer}>
                <NewButton
                    color="rgb(0, 192, 186)"
                    type="submit"
                    disabled={isSubmitDisabled}
                    loading={isLoading}
                    borderRadius={4}
                    textColor="#ffffff"
                    className={styles.SignIn__submitButton}
                >
                    Continue
                </NewButton>
            </div>
        );
    };

    return (
        <FormContainer onSubmit={handleSubmit} className={styles.SignIn__form}>
            <div className={styles.SignIn}>
                <div className={styles.SignIn__container}>
                    <p className={styles.SignIn__title}>Sign In to NOVA</p>
                    <div className={[styles.SignIn__inputWrap].join(' ')}>
                        <NewInput
                            type="email"
                            value={email}
                            onChange={handleChangeEmail}
                            placeholder="Email"
                            className={styles.SignIn__input}
                            disabled
                        />
                        {emailError.length ? (
                            <p className={styles.SignIn__inputErrorMessage}>
                                {emailError}
                            </p>
                        ) : null}
                    </div>
                    <div className={styles.SignIn__inputWrap}>
                        <PasswordInput
                            className={styles.SignIn__input}
                            type="password"
                            value={password}
                            onChange={handleChangePassword}
                            placeholder="New Password"
                        />
                        {passwordError.length ? (
                            <p className={styles.SignIn__inputErrorMessage}>
                                {passwordError}
                            </p>
                        ) : null}
                    </div>
                    {renderButton()}
                </div>
            </div>
        </FormContainer>
    );
};

export default AppSumoSignIn;
