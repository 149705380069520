import { ColorOption } from 'interfaces/colors';
import { ExportedSubtitledVideoData } from 'interfaces/export';
import { Trailer } from 'interfaces/generation';
import { MediaFile } from 'interfaces/videos';
import {
  SelectedMetadataItem,
  SelectedSentence,
  SelectedAnalysisResultItem,
  SelectedShotItem,
  Scene,
} from 'state/modules/videoExplorer';
import { ClipGenerationData, ClipOrder } from '../generation';
import { Job } from '../jobs';

export interface ExportAsVideoParams {
  job: Job | null;
  jobId: string | null;
  isPreviewGenerationInProgress: boolean;
  preview: ClipGenerationData | null;
  videoId: string | null;
  isClipGenerationInProgress: boolean;
  clip: Trailer | null;
  clipDownloadLink: string | null;
  isClipDownloaded: boolean | null;
}

export interface ExportState {
    itemsToExport: Array<SelectedMetadataItem>;
    exportList: Array<MetadataExportItem>;
    exportAsVideoParams: ExportAsVideoParams | null;
    isExportAsVideoLoading: boolean;

    isExportSubtitledVideoLoading: boolean;
    isSubtitledVideoDataLoading: boolean;
    subtitledVideoData: ExportedSubtitledVideoData | null;
    subtitlesExportFontSize: number;
    subtitlesExportColor: ColorOption;

    videosUsedForExport: MediaFile[];

    isBurningSubtitlesCancelLoading: boolean;
}

export interface AnalysisResultExportItem extends SelectedAnalysisResultItem {
    videoName: string;
}
export interface ShotExportItem extends SelectedShotItem {
    videoName: string;
}
export interface SentenceExportItem extends SelectedSentence {
    videoName: string;
}

export type MetadataExportItem =
    | AnalysisResultExportItem
    | ShotExportItem
    | SentenceExportItem;

export interface GroupedByVideoExportItem {
    videoName: string;
    data: Array<MetadataExportItem>;
    videoId: string;
}

export enum ExportActionTypes {
    SELECT_MULTIPLE_ITEMS_TO_EXPORT = '@@export/SELECT_MULTIPLE_ITEMS_TO_EXPORT',
    SELECT_ITEM_TO_EXPORT = '@@export/SELECT_ITEM_TO_EXPORT',
    UNSELECT_ITEM_TO_EXPORT = '@@export/UNSELECT_ITEM_TO_EXPORT',
    SET_ITEMS_TO_EXPORT = '@@export/SET_ITEMS_TO_EXPORT',
    TOGGLE_ALL_ITEMS_TO_EXPORT = '@@export/TOGGLE_ALL_ITEMS_TO_EXPORT',
    REMOVE_ALL_ITEMS_TO_EXPORT = '@@export/REMOVE_ALL_ITEMS_TO_EXPORT',
    CONFIRM_EXPORT_LIST_UPADATE = '@@export/CONFIRM_EXPORT_LIST_UPADATE',
    SET_EXPORT_LIST = '@@export/SET_EXPORT_LIST',
    CLEAR_EXPORT_LIST = '@@export/CLEAR_EXPORT_LIST',
    REMOVE_EXPORT_ITEM = '@@export/REMOVE_EXPORT_ITEM',
    CLEAR_ITEMS_TO_EXPORT_LIST = '@@export/CLEAR_ITEMS_TO_EXPORT_LIST',
    TOGGLE_ITEM_TO_EXPORT = '@@export/TOGGLE_ITEM_TO_EXPORT',
    ADD_SPECIFIC_SCENE_TO_EXPORT = '@@export/ADD_SPECIFIC_SCENE_TO_EXPORT',
    EXPORT_SELECTED_METADATA_AS_VIDEO = '@@export/EXPORT_SELECTED_METADATA_AS_VIDEO',
    EXPORT_SELECTED_METADATA_AS_VIDEO_START = '@@export/EXPORT_SELECTED_METADATA_AS_VIDEO_START',
    EXPORT_SELECTED_METADATA_AS_VIDEO_FAIL = '@@export/EXPORT_SELECTED_METADATA_AS_VIDEO_FAIL',
    EXPORT_SELECTED_METADATA_AS_VIDEO_SUCCESS = '@@export/EXPORT_SELECTED_METADATA_AS_VIDEO_SUCCESS',
    SET_EXPORT_AS_VIDEO_PARAMS = '@@export/SET_EXPORT_AS_VIDEO_PARAMS',
    UPDATE_EXPORT_AS_VIDEO_PARAMS = '@@export/UPDATE_EXPORT_AS_VIDEO_PARAMS',

    EXPORT_SUBTITLED_VIDEO = '@@export/EXPORT_SUBTITLED_VIDEO',
    SET_EXPORT_SUBTITLED_VIDEO_LOADING = '@@export/SET_EXPORT_SUBTITLED_VIDEO_LOADING',
    
    GET_SUBTITLED_VIDEO_DATA = '@@export/GET_SUBTITLED_VIDEO_DATA',
    SET_SUBTITLED_VIDEO_DATA = '@@export/SET_SUBTITLED_VIDEO_DATA',
    SET_SUBTITLED_VIDEO_DATA_LOADING = '@@export/SET_SUBTITLED_VIDEO_DATA_LOADING',

    SYNC_VIDEO_SUBTITLING_STATUS = '@@export/SYNC_VIDEO_SUBTITLING_STATUS',

    SET_SUBTITLES_FONT_SIZE = '@@export/SET_SUBTITLES_FONT_SIZE',
    SET_SUBTITLES_COLOR = '@@export/SET_SUBTITLES_COLOR',
    
    ADD_CURRENT_VIDEO_TO_VIDEOS_USED_FOR_EXPORT = '@@export/ADD_CURRENT_VIDEO_TO_VIDEOS_USED_FOR_EXPORT',
    ADD_VIDEO_USED_FOR_EXPORT = '@@export/ADD_VIDEO_USED_FOR_EXPORT',
    SET_VIDEOS_USED_FOR_EXPORT = '@@export/SET_VIDEOS_USED_FOR_EXPORT',

    CANCEL_BURNING_SUBTITLES_JOB = '@@export/CANCEL_BURNING_SUBTITLES_JOB',
    CANCEL_BURNING_SUBTITLES_JOB_START = '@@export/CANCEL_BURNING_SUBTITLES_JOB_START',
    CANCEL_BURNING_SUBTITLES_JOB_FAIL = '@@export/CANCEL_BURNING_SUBTITLES_JOB_FAIL',
    CANCEL_BURNING_SUBTITLES_JOB_SUCCESS = '@@export/CANCEL_BURNING_SUBTITLES_JOB_SUCCESS',
}

export interface SelectMultipleItemsToExportAction {
    type: ExportActionTypes.SELECT_MULTIPLE_ITEMS_TO_EXPORT;
    payload: Array<SelectedMetadataItem>;
}
export interface SelectItemToExportAction {
    type: ExportActionTypes.SELECT_ITEM_TO_EXPORT;
    payload: SelectedMetadataItem;
}

export interface ToggleItemToExportAction {
    type: ExportActionTypes.TOGGLE_ITEM_TO_EXPORT;
    payload: SelectedMetadataItem;
}
export interface UnselectItemFromExportAction {
    type: ExportActionTypes.UNSELECT_ITEM_TO_EXPORT;
    payload: SelectedMetadataItem;
}

export interface ToggleAllItemsToExportAction {
    type: ExportActionTypes.TOGGLE_ALL_ITEMS_TO_EXPORT;
}
export interface RemoveAllItemsToExportAction {
    type: ExportActionTypes.REMOVE_ALL_ITEMS_TO_EXPORT;
}
export interface SetItemsToExportAction {
    type: ExportActionTypes.SET_ITEMS_TO_EXPORT;
    payload: Array<SelectedMetadataItem>;
}
export interface ConfirmExportListUpdateAction {
    type: ExportActionTypes.CONFIRM_EXPORT_LIST_UPADATE;
}
export interface SetExportListAction {
    type: ExportActionTypes.SET_EXPORT_LIST;
    payload: Array<MetadataExportItem>;
}
export interface ClearExportListAction {
    type: ExportActionTypes.CLEAR_EXPORT_LIST;
}
export interface ClearItemsToExportListAction {
    type: ExportActionTypes.CLEAR_ITEMS_TO_EXPORT_LIST;
}
export interface RemoveExportItemAction {
    type: ExportActionTypes.REMOVE_EXPORT_ITEM;
    payload: MetadataExportItem;
}
export interface AddSpecificSceneToExportAction {
    type: ExportActionTypes.ADD_SPECIFIC_SCENE_TO_EXPORT;
    payload: Scene;
}

export interface ExportSelectedMetadataAsVideoActionPayload {
  order: ClipOrder
}

export interface ExportSelectedMetadataAsVideoAction {
    type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO;
    payload: ExportSelectedMetadataAsVideoActionPayload
}

export interface ExportSelectedMetadataAsVideoStartAction {
  type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_START;
}
export interface ExportSelectedMetadataAsVideoFailAction {
  type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_FAIL;
}
export interface ExportSelectedMetadataAsVideoSuccessAction {
  type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_SUCCESS;
}

export interface SetExportAsVideoParamsAction {
  type: ExportActionTypes.SET_EXPORT_AS_VIDEO_PARAMS;
  payload: ExportAsVideoParams;
}

export interface UpdateExportAsVideoParamsAction {
  type: ExportActionTypes.UPDATE_EXPORT_AS_VIDEO_PARAMS;
}

export interface ExportSubtitledVideoActionPayload {
  videoId: string;
  color: string;
  fontSize: number;
  language: string;
}

export interface ExportSubtitledVideoAction {
  type: ExportActionTypes.EXPORT_SUBTITLED_VIDEO;
  payload: ExportSubtitledVideoActionPayload
}

export interface SetExportSubtitledVideoLoadingAction {
  type: ExportActionTypes.SET_EXPORT_SUBTITLED_VIDEO_LOADING;
  payload: boolean;
};

export interface GetSubtitledVideoDataAction {
  type: ExportActionTypes.GET_SUBTITLED_VIDEO_DATA;
};

export interface SetSubtitledVideoDataAction {
  type: ExportActionTypes.SET_SUBTITLED_VIDEO_DATA;
  payload: ExportedSubtitledVideoData | null;
};

export interface SetSubtitledVideoDataLoadingAction {
  type: ExportActionTypes.SET_SUBTITLED_VIDEO_DATA_LOADING;
  payload: boolean;
};

export interface SyncVideoSubtitlingStatusAction {
  type: ExportActionTypes.SYNC_VIDEO_SUBTITLING_STATUS;
};

export interface SetSubtitlesFontSizeAction {
  type: ExportActionTypes.SET_SUBTITLES_FONT_SIZE;
  payload: number;
};

export interface SetSubtitlesColorAction {
  type: ExportActionTypes.SET_SUBTITLES_COLOR;
  payload: ColorOption;
};

export interface AddCurrentVideoToVideosUsedForExportAction {
  type: ExportActionTypes.ADD_CURRENT_VIDEO_TO_VIDEOS_USED_FOR_EXPORT;
};

export interface AddVideoUsedForExportAction {
  type: ExportActionTypes.ADD_VIDEO_USED_FOR_EXPORT;
  payload: MediaFile;
};

export interface SetVideosUsedForExportAction {
  type: ExportActionTypes.SET_VIDEOS_USED_FOR_EXPORT;
  payload: MediaFile[];
};

export interface CancelBurningsSubtitlesJobActionPayload {
  videoId: string;
  jobId: string;
}

export interface CancelBurningsSubtitlesJobAction {
  type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB;
  payload: CancelBurningsSubtitlesJobActionPayload;
};

export interface CancelBurningsSubtitlesJobStartAction {
  type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_START;
};

export interface CancelBurningsSubtitlesJobFailAction {
  type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_FAIL;
};

export interface CancelBurningsSubtitlesJobSuccessAction {
  type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_SUCCESS;
};


export type CollectionsAction =
    | SelectMultipleItemsToExportAction
    | SelectItemToExportAction
    | ToggleItemToExportAction
    | UnselectItemFromExportAction
    | ToggleAllItemsToExportAction
    | RemoveAllItemsToExportAction
    | SetItemsToExportAction
    | ConfirmExportListUpdateAction
    | SetExportListAction
    | ClearExportListAction
    | ClearItemsToExportListAction
    | RemoveExportItemAction
    | AddSpecificSceneToExportAction
    | ExportSelectedMetadataAsVideoAction
    | ExportSelectedMetadataAsVideoStartAction
    | ExportSelectedMetadataAsVideoFailAction
    | ExportSelectedMetadataAsVideoSuccessAction
    | SetExportAsVideoParamsAction
    | UpdateExportAsVideoParamsAction
    
    | ExportSubtitledVideoAction
    | SetExportSubtitledVideoLoadingAction
    | GetSubtitledVideoDataAction
    | SetSubtitledVideoDataAction
    | SetSubtitledVideoDataLoadingAction
    | SyncVideoSubtitlingStatusAction

    | SetSubtitlesFontSizeAction
    | SetSubtitlesColorAction

    | AddVideoUsedForExportAction
    | SetVideosUsedForExportAction
    | AddCurrentVideoToVideosUsedForExportAction

    | CancelBurningsSubtitlesJobStartAction
    | CancelBurningsSubtitlesJobFailAction
    | CancelBurningsSubtitlesJobSuccessAction
