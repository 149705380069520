import { IconProps } from './index';

const FolderIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 240 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="NewFolderModalstyled__FolderIcon-sc-1711jy0-9 ecfkEo"
        >
            <path
                d="M64.0878 1.17083C62.8328 0.405104 61.3911 0 59.9209 0H8C3.58172 0 0 3.58173 0 8.00001V232C0 236.418 3.58173 240 8.00001 240H232C236.418 240 240 236.418 240 232V32.5368C240 28.1185 236.418 24.5368 232 24.5368H105.193C103.355 24.5368 101.553 24.0304 99.9846 23.0733L64.0878 1.17083Z"
                fill={color}
            />
        </svg>
    );
};

export default FolderIcon;
