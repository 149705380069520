class Range {
    private _start: number;

    private _end: number;

    constructor(start = 0, end = 0) {
        if (typeof start === 'number' && typeof end === 'number') {
            if (end - start >= 0) {
                this._start = start;
                this._end = end;
            } else {
                throw RangeError('Range "end" can not be less than "start"');
            }
        } else {
            throw TypeError('Range "start" and "end" must be number');
        }
    }

    // PUBLIC API
    get start() {
        return this._start;
    }

    get end() {
        return this._end;
    }

    get length() {
        return this._end - this._start;
    }

    isEqual(range: Range) {
        this.validateRange(range);
        return this.start === range.start && this.end === range.end;
    }

    isSubPoint(point: number) {
        if (typeof point === 'number') {
            return point >= this._start && point < this._end;
        }
        throw TypeError('Range "point" must be a number');
    }

    isSubRange(range: Range) {
        this.validateRange(range);
        return (
            this.isSubPoint(range.start) &&
            (this.isSubPoint(range.end) || range.end === this.end)
        );
    }

    union(range: Range) {
        this.validateRange(range);
        return new Range(
            Math.min(this.start, range.start),
            Math.max(this.end, range.end)
        );
    }

    intersection(range: Range) {
        this.validateRange(range);
        const start = Math.max(this.start, range.start);
        const end = Math.min(this.end, range.end);
        if (end - start >= 0) {
            return new Range(start, end);
        }
        return new Range(start, start); // Empty Range
    }

    validateRange(range: Range) {
        if (!(range instanceof Range)) {
            throw TypeError('"range" must be Range instance');
        }
    }
}

export { Range };
