import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import withRouter from 'hocs';

import {
    FormContainer,
    Icon,
    NewButton,
    NewInput,
    PasswordInput,
} from 'components/UI';

import { validate } from 'utils';
import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';

import AuthClient from 'services/api/auth';

import styles from './styles.module.scss';
import { amplitudeAnalytics } from '../../services/api/amplitudeAnalytics';
import { googleAnalytics } from '../../services/api/googleAnalytics';

export declare enum CognitoHostedUIIdentityProvider {
    Cognito = 'COGNITO',
    Google = 'Google',
    Facebook = 'Facebook',
    Amazon = 'LoginWithAmazon',
    Apple = 'SignInWithApple',
}

const CustomSignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // const location = useLocation();

    // useEffect(() => {
    //     Hub.listen('auth', ({ payload: { event, data } }: any) => {
    //         console.log('auth', { event }, { data });
    //         switch (event) {
    //             case 'signIn':
    //                 // this.setState({ user: data });
    //                 break;
    //             case 'signOut':
    //                 // this.setState({ user: null });
    //                 break;
    //             case 'customOAuthState':
    //                 // this.setState({ customState: data });
    //                 break;
    //             default:
    //                 return null;
    //         }
    //     });

    //     Auth.currentAuthenticatedUser()
    //         .then((user) => console.log({ user }))
    //         .catch(() => console.log('Not signed in'));
    // }, []);

    const handleSubmit = async () => {
        const passwordValidationErrors = validate(
            password,
            [ValidatorType.Required, ValidatorType.Password],
            ValidationType.OnSubmit
        );

        const emailValidationErrors = validate(
            email,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        const passwordError = passwordValidationErrors[0] || '';
        const emailError = emailValidationErrors[0] || '';

        if (emailError.length || passwordError.length) {
            setEmailError(emailError);
            setPasswordError(passwordError);
        } else {
            setLoading(true);

            try {
                const name = email.replace('@', '.');
                let invitationCode = localStorage.getItem('invitationCode');

                const signInRes = await AuthClient.signIn({
                    email,
                    password,
                    name,
                    invitationCode,
                });

                if (signInRes.status === 200) {
                    const res = await Auth.signIn(name, password);

                    // const currentPlan = (await $http.get(
                    //     `/user/${signInRes.data.user_id}/payments/subscription`
                    // )) as any;

                    localStorage.removeItem('invitationCode');

                    // Analytics.record({
                    //     name: 'sign_in',
                    //     attributes: {
                    //         userId: signInRes.data.user_id,
                    //         plan: currentPlan?.planNameId || PlanNameId.INITIAL,
                    //     },
                    // });
                }
            } catch (error) {
                console.log({ error });
                setLoading(false);
                showNotification(
                    NotificationTypes.error,
                    (error as any)?.response?.data?.message ||
                        (error as any).message
                );
            }
            // finally {
            //     if (this.isComponetMounted) {
            //         this.setState({
            //             isLoading: false,
            //         });
            //     }
            // }
        }
    };

    const resetForm = () => {
        setEmail('');
        setPassword('');
        setEmailError('');
        setPasswordError('');
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        // const validationErrors = validate(
        //   value,
        //   [ValidatorType.Email],
        //   ValidationType.OnInput,
        // );

        // if (validationErrors.length === 0) {
        //   this.setState({ email: value, emailError: '' });
        // } else {
        //   this.setState({ emailError: validationErrors[0] });
        // }

        setEmail(value.toLowerCase());
        setEmailError('');
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setPassword(value);
        setPasswordError('');
    };

    const handleChangePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const renderEyeIcon = (iconName: string) => {
        return (
            <Icon
                name={iconName}
                color={isPasswordVisible ? '#333333' : '#cecece'}
            />
        );
    };

    const handleButtonDisabledStatus = (
        emailError: string,
        passwordError: string
    ) => Boolean(emailError || passwordError);

    const handleGoogleAuth = () => {
        try {
            Auth.federatedSignIn({ provider: 'Google' } as any)
                .then(data => {
                    console.log('Sign In with Google: Success');

                    amplitudeAnalytics.login({
                        login_method: 'google',
                    });

                    googleAnalytics.login({
                        method: 'google',
                    });
                })
                .catch((error) => {
                    console.error('Sign in error', error);
                });
        } catch (error) {
            console.log({ error });
        }
    };

    const renderButton = () => {
        const isSubmitDisabled = handleButtonDisabledStatus(
            emailError,
            passwordError
        );

        return (
            <div className={styles.SignIn__formButtonContainer}>
                <NewButton
                    color="rgb(0, 192, 186)"
                    type="submit"
                    disabled={isSubmitDisabled}
                    loading={isLoading}
                    borderRadius={4}
                    textColor="#ffffff"
                    className={styles.SignIn__submitButton}
                >
                    Continue
                </NewButton>
            </div>
        );
    };

    const renderGoogleSignIn = () => (
        <>
            <NewButton
                color="white"
                textColor="#62646a"
                leftContainer={<Icon size={18} name="google" />}
                bordered
                className={styles.SignIn__googleButton}
                onClick={handleGoogleAuth}
            >
                <p className={styles.SignIn__googleButtonTitle}>
                    Continue with Google
                </p>
            </NewButton>
            <div className={styles.SignIn__divider}>
                <span className={styles.SignIn__dividerTitle}>Or</span>
            </div>
        </>
    );

    return (
        <FormContainer
            autoComplete
            onSubmit={handleSubmit}
            className={styles.SignIn__form}
        >
            <div className={styles.SignIn}>
                <div className={styles.SignIn__container}>
                    <p className={styles.SignIn__title}>Sign In to NOVA</p>
                    {renderGoogleSignIn()}
                    <div className={[styles.SignIn__inputWrap].join(' ')}>
                        <NewInput
                            type="email"
                            value={email}
                            onChange={handleChangeEmail}
                            placeholder="Email"
                            className={styles.SignIn__input}
                        />
                        {emailError.length ? (
                            <p className={styles.SignIn__inputErrorMessage}>
                                {emailError}
                            </p>
                        ) : null}
                    </div>
                    <div className={styles.SignIn__inputWrap}>
                        <PasswordInput
                            className={styles.SignIn__input}
                            type="password"
                            value={password}
                            onChange={handleChangePassword}
                            placeholder="Password"
                        />
                        {passwordError.length ? (
                            <p className={styles.SignIn__inputErrorMessage}>
                                {passwordError}
                            </p>
                        ) : null}
                    </div>
                    {renderButton()}
                    <div className={styles.SignIn__actionsContainer}>
                        <Link
                            to="/forgot-password"
                            className={styles.SignIn__action}
                        >
                            Forgot password?
                        </Link>
                        <Link
                            to="/resend-confirmation-email"
                            className={styles.SignIn__action}
                        >
                            Resend confirmation email
                        </Link>
                    </div>
                </div>
                <div className={styles.SignIn__bottomContainer}>
                    <p>Not a member yet?</p>
                    <Link
                        to="/signup"
                        className={styles.SignIn__createAccountButton}
                    >
                        Join now
                    </Link>
                </div>
            </div>
        </FormContainer>
    );
};

export default withRouter(CustomSignIn);
