import { EdlExportFpsOption } from "interfaces/edlExport";

export const timecodeWithFramesToMs = (timecode: any, fps: EdlExportFpsOption) => {
  const offsetWithoutFrames = timecode.substring(0, 8);
  const offsetWithoutFramesArray = offsetWithoutFrames.split(':');
  const frames = +timecode.substring(9, 11);

  const hoursInMs = Number(offsetWithoutFramesArray[0]) * 60 * 60 * 1000;
  const minutesInMs = Number(offsetWithoutFramesArray[1]) * 60 * 1000;
  const secondsInMs = Number(offsetWithoutFramesArray[2]) * 1000;
  const framesInMs = (1000 / fps.value) * frames;
  
  const sum = hoursInMs + minutesInMs + secondsInMs + framesInMs;
  // console.log({sum}, 'timecodeWithFramesToMs', {framesInMs})
  return sum;
}