/* eslint-disable no-control-regex */
export const ConnectedScripts = [
    'ar',
    'ar-AE',
    'ar-SA',
    'ar-EG', // Arabic
    'fa',
    'fa-IR', // Persian/Farsi
    'ur', // Urdu
    'he',
    'he-IL',
    'iw', // Hebrew
    'syr', // Syriac
    'dv', // Thaana (used for writing the Maldivian language)
    'nqo', // N'ko (used for writing Manding languages in West Africa)
    'mn', // Mongolian (Traditional Mongolian script)
    'bo', // Tibetan
    'km', // Khmer (Cambodian)
    'si', // Sinhalese
    'bn', // Bengali
    'hi',
    'hi-IN', // Devanagari (used for writing Hindi, Sanskrit, and several other Indian languages)
    'gu', // Gujarati
    'pa', // Gurmukhi (used for writing Punjabi)
    'kn', // Kannada
    'ml', // Malayalam
    'or', // Oriya (also spelled Odia)
    'ta',
    'ta-IN', // Tamil
    'te',
    'te-IN', // Telugu
    'mr', // Marathi
    'ckb',
    'ku', // Kurdish
];

export const containsNonLatinCodepoints = (s: any) => {
    return /[^\u0000-\u00ff]/.test(s);
};

export const RTLDefaultFont = 'DejaVuSans';
