import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 27 / 24;

const SoundOff = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1141 1.63121C14.6531 1.87496 15 2.40933 15 2.99996V21C15 21.5906 14.6531 22.125 14.1141 22.3687C13.575 22.6125 12.9422 22.514 12.5016 22.1203L6.17813 16.5H3C1.34531 16.5 0 15.1546 0 13.5V10.5C0 8.84527 1.34531 7.49996 3 7.49996H6.17813L12.5016 1.87964C12.9422 1.48589 13.575 1.39214 14.1141 1.63121ZM19.9219 7.82808L22.5 10.4062L25.0781 7.82808C25.5187 7.38746 26.2313 7.38746 26.6672 7.82808C27.1031 8.26871 27.1078 8.98121 26.6672 9.41714L24.0891 11.9953L26.6672 14.5734C27.1078 15.014 27.1078 15.7265 26.6672 16.1625C26.2266 16.5984 25.5141 16.6031 25.0781 16.1625L22.5 13.5843L19.9219 16.1625C19.4813 16.6031 18.7688 16.6031 18.3328 16.1625C17.8969 15.7218 17.8922 15.0093 18.3328 14.5734L20.9109 11.9953L18.3328 9.41714C17.8922 8.97652 17.8922 8.26402 18.3328 7.82808C18.7734 7.39215 19.4859 7.38746 19.9219 7.82808Z"
                fill={color}
            />
        </svg>
    );
};

export default SoundOff;
