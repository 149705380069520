/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Icon, NewButton } from 'components/UI';
import FolderTreeRoot from 'components/FolderTreeRoot';

import { getModalOptionalData, hideModal } from 'state/modules/modal';
import { getUserId } from 'state/modules/user';
import { getCurrentWorkspaceInfo } from 'state/modules/workspaces';
import { getMoveToFolderLoading, moveToFolder } from 'state/modules/folders';

import { Folder, FolderStatuses } from 'interfaces/folder';
import { Project } from 'interfaces/projects';

import FoldersClient from 'services/api/folders';

const ObjectName = styled.p`
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    color: rgb(144, 148, 165);
    margin-left: 1rem;
`;

const Title = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: rgb(25, 32, 51);
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Header = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
`;

const Content = styled.div`
    height: calc(100% - 40px - 48px);
    overflow: auto;
`;

const CancelButton = styled.div``;

const Footer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    gap: 15px;
    height: 48px;
`;

const Container = styled.div`
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 1.25rem;
    width: min(90vw, 750px);
    height: min(90vh, 750px);
    padding: 1.5rem;
    z-index: 10;
`;

const CraeteButton = styled.button`
    display: flex;
    align-items: center;
    outline: none;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 5px;
    border-radius: 6px;

    &:hover {
        color: rgb(25, 32, 51);
        background-color: rgb(238, 238, 240);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const CraeteFolderButton = styled(CraeteButton)`
    margin-left: auto;
`;

const CraeteFolderButtonTitle = styled.span`
    margin-left: 8px;
`;

const MoveToFolderModal = () => {
    const [newFolderError, setNewFolderError] = useState(false);
    const [isCreateLoading, setLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
    const [foldersTree, setFoldersTree] = useState<{
        [key: string]: Folder[];
    }>({});
    const [folders, setFodlers] = useState<Folder[]>([]);

    const optionalData = useSelector(getModalOptionalData);
    const userId = useSelector(getUserId);
    const isMoveToFolderLoading = useSelector(getMoveToFolderLoading);
    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);

    const {
        project,
        folder,
    }: {
        project: Project;
        folder: Folder;
    } = optionalData;

    const dispatch = useDispatch();

    const location = useLocation();

    const currentFolderId = useMemo(() => {
        const ids = location.pathname.split('folder/')?.[1];

        if (ids?.length) {
            const idsArray = ids.split('/');

            return idsArray[idsArray.length - 1];
        }

        return '';
    }, [location]);

    const loadFolders = async () => {
        try {
            const res = await FoldersClient.getFolders(0);

            const data = res.data.content;

            let updatedTree = {} as any;

            data.forEach((item: Folder) => {
                updatedTree[item.id] = [];
            });

            setFodlers(data);
            setFoldersTree(updatedTree);
        } catch (error) {
            console.log({ error });
        }
    };

    useEffect(() => {
        loadFolders();
    }, []);

    const filteredFolders = useMemo(
        () => folders.filter((item) => item.id !== folder?.id),
        [folders, folder]
    );

    const isLoading = useMemo(
        () => isMoveToFolderLoading || isCreateLoading,
        [isMoveToFolderLoading, isCreateLoading]
    );

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleCreateFolder = async () => {
        let defaultFolder = {
            status: FolderStatuses.INACTIVE,
            name: '',
            userId,
            isDefault: true,
            description: '',
            folderId: selectedFolder?.id || '',
            workspaceId: currentWorkspace?.id || '',
            isTemp: true,
            id: uuid(),
        } as Folder;

        try {
            if (selectedFolder?.isTemp) {
                return;
            }

            let updatedTree = {
                ...foldersTree,
            } as any;

            if (selectedFolder && foldersTree[selectedFolder.id]) {
                const folderItems = foldersTree[selectedFolder.id];

                const hasTempFolders = folderItems.filter(
                    (item) => item.isTemp
                );

                if (hasTempFolders.length > 0) return;

                updatedTree[selectedFolder?.id] = [
                    ...foldersTree[selectedFolder.id],
                    defaultFolder,
                ];
            } else {
                updatedTree.temp = [defaultFolder];
            }

            if (!selectedFolder) {
                const hasTempFolders = folders.filter((item) => item.isTemp);

                if (hasTempFolders.length > 0) return;

                setFodlers([...folders, defaultFolder]);
            }

            setFoldersTree(updatedTree);
            setSelectedFolder(defaultFolder);
        } catch (error) {
            console.log({ error });
        }
    };

    const handleMove = async (currentSelectedFolder?: Folder | null) => {
        if (currentSelectedFolder?.isTemp) {
            const inputElem = document.querySelector(
                '[data-id=temp-folder-input]'
            );

            if (inputElem) {
                // @ts-ignore: Unreachable code error
                const inputElemValue: string = inputElem.value;
                const isEmpty = !inputElemValue.length;

                if (!isEmpty) {
                    setLoading(true);

                    const transformedFolder: Folder = {
                        ...currentSelectedFolder,
                        name: inputElemValue,
                        status: FolderStatuses.ACTIVE,
                    };

                    // @ts-ignore: Unreachable code error
                    delete transformedFolder.id;
                    delete transformedFolder.isTemp;

                    const res = await FoldersClient.createFolder(
                        transformedFolder
                    );

                    const createdFolder: Folder = res.data.newFolder;

                    setTimeout(() => {
                        dispatch(
                            moveToFolder({
                                folder,
                                project,
                                selectedFolderId: createdFolder.id || '',
                                currentFolderId,
                            })
                        );
                    }, 1000);
                } else {
                    return setNewFolderError(true);
                }
            }
        } else {
            dispatch(
                moveToFolder({
                    folder,
                    project,
                    selectedFolderId: currentSelectedFolder?.id || '',
                    currentFolderId,
                })
            );
        }
    };

    const handleSelect = useCallback((selectedObj: Folder | null) => {
        setSelectedFolder(selectedObj);
    }, []);

    const handleUpdateFoldersTreeItem = (id: string, data: Folder[]) => {
        const updatedTree = {
            ...foldersTree,
            [id]: data,
        };

        setFoldersTree(updatedTree);
    };

    const handleConfirmCreateFolder = async (newFolder: Folder) => {
        try {
            const newFolderRes = await FoldersClient.createFolder(newFolder);
            const createdFolder = newFolderRes.data.newFolder;

            setSelectedFolder(createdFolder);
            handleMove(createdFolder);
        } catch (error) {
            console.log({ error });
        }
    };

    const handleClearNewFolderError = () => {
        setNewFolderError(false);
    };

    const renderCreateFolderButton = () => {
        return (
            <CraeteFolderButton
                disabled={isLoading}
                onClick={handleCreateFolder}
            >
                <Icon name="create-folder-second" size={20} />
                <CraeteFolderButtonTitle>New Folder</CraeteFolderButtonTitle>
            </CraeteFolderButton>
        );
    };

    return (
        <Container>
            <Header>
                <Title>Move to</Title>
                <ObjectName>
                    {selectedFolder?.name || 'All Projects'}
                </ObjectName>
            </Header>
            <Content>
                <FolderTreeRoot
                    onSelect={handleSelect}
                    onUpdateFoldersTreeItem={handleUpdateFoldersTreeItem}
                    selectedFolderId={selectedFolder?.id || ''}
                    foldersTree={foldersTree}
                    folders={filteredFolders}
                    folderIdToExclude={folder?.id || ''}
                    onConfirmCreateFolder={handleConfirmCreateFolder}
                    hasError={newFolderError}
                    onClearError={handleClearNewFolderError}
                />
            </Content>
            <Footer>
                {renderCreateFolderButton()}
                <CancelButton>
                    <NewButton
                        disabled={isLoading}
                        borderRadius={10}
                        color="rgb(247, 247, 248)"
                        textColor="rgb(25, 32, 51)"
                        hoverTextColor="rgb(0, 152, 253)"
                        hoverBoxShadow="rgb(0, 0, 0, 0.1) 0px 1px 2px"
                        hoverBorder="1px solid rgb(103, 193, 255)"
                        height={48}
                        onClick={handleClose}
                    >
                        Cancel
                    </NewButton>
                </CancelButton>
                <NewButton
                    onClick={() => handleMove(selectedFolder)}
                    loading={isMoveToFolderLoading}
                    disabled={isLoading}
                    borderRadius={10}
                    color="rgb(25, 32, 51)"
                    textColor="rgb(255, 255, 255)"
                    height={48}
                    hoverBoxShadow="rgb(0, 0, 0, 0.2) 0px 5px 6px"
                >
                    Move
                </NewButton>
            </Footer>
        </Container>
    );
};

export default MoveToFolderModal;
