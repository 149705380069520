import { IconProps } from './index';

const DownloadIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 15;
  if (size) {
    height = size;
  }

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Rev-4"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Nova-Enterprise---Home-Page-v2--Selected"
          transform="translate(-1147.000000, -332.000000)"
          fill={color}
        >
          <g
            id="Bulk-Actions"
            transform="translate(1107.000000, 329.000000)"
          >
            <path
              d="M41.6666667,12.1665 L41.6666667,16.3331667 L53.3333333,16.3331667 L53.3333333,12.1665 L55,12.1665 L55,17.9998333 L40,17.9998333 L40,12.1665 L41.6666667,12.1665 Z M48.33325,3 L48.33325,12.1666667 L51.9999167,9.41666667 L52.9999167,10.75 L47.4999167,14.875 L41.9999167,10.75 L42.9999167,9.41666667 L46.6665833,12.1666667 L46.6665833,3 L48.33325,3 Z"
              id="download"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
