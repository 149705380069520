import { TransformedSentence } from 'state/modules/metadata';
import {
    ProjectAudio,
    ProjectElement,
    ProjectImage,
    ProjectNavRoute,
    ProjectText,
    ProjectTransformedSentence,
    ProjectVideo,
    VideoTransition,
} from 'interfaces/projects';

export enum SelectedProjectObjectType {
    VIDEO,
    AUDIO,
    AUDIO_WITH_SUBTITLES,
    IMAGE,
    TEXT,
    TRANSITION,
    ELEMENT,
    SENTENCE,
}

export type SelectedProjectObjectTypes =
    | ProjectVideo
    | ProjectImage
    | ProjectAudio
    | ProjectText
    | VideoTransition
    | ProjectElement
    | TransformedSentence
    | ProjectTransformedSentence;

export interface SelectedProjectObject {
    type: SelectedProjectObjectType;
    object: SelectedProjectObjectTypes;
}

export interface ProjectCanvasSelectedShape {
    id: string;
    shape?: any;
}

export interface ProjectsStudioState {
    timelineTime: number;
    timelineSliderValue: number;
    isPlaying: boolean;
    timelineHeight: number;
    projectNavRoute: ProjectNavRoute;
    selectedShape: ProjectCanvasSelectedShape | null;
    preparedMediaSources: string[];
    isAudioWaveVisible: boolean;
    timelineSelectedObjects: SelectedProjectObject[];
    navigateToRoute: string;

    copiedMedia: SelectedProjectObject | null;
}

export enum ProjectsStudioActionTypes {
    SET_PLAYING = '@@projectsStudio/SET_PLAYING',
    SPLIT_PROJECT_TIMELINE = '@@projectsStudio/SPLIT_PROJECT_TIMELINE',
    SET_TIMELINE_HEIGHT = '@@projectsStudio/SET_TIMELINE_HEIGHT',
    SET_TIMELINE_TIME = '@@projectsStudio/SET_TIMELINE_TIME',
    SET_TIMELINE_SLIDER_VALUE = '@@projectsStudio/SET_TIMELINE_SLIDER_VALUE',

    SET_PROJECT_NAV_ROUTE = '@@projectsStudio/SET_PROJECT_NAV_ROUTE',

    SET_SELECTED_SHAPE = '@@projectsStudio/SET_SELECTED_SHAPE',

    FINISH_PROJECT_VIDEO = '@@projectsStudio/FINISH_PROJECT_VIDEO',
    PAUSE_PROJECT_VIDEO = '@@projectsStudio/PAUSE_PROJECT_VIDEO',
    UPDATE_PROJECT_TIME = '@@projectsStudio/UPDATE_PROJECT_TIME',

    TOGGLE_PROJECT_PLAYER = '@@projectsStudio/TOGGLE_PROJECT_PLAYER',

    ADD_PREPARED_MEDIA_SOURCE = '@@projectsStudio/ADD_PREPARED_MEDIA_SOURCE',
    SET_PREPARED_MEDIA_SOURCES = '@@projectsStudio/SET_PREPARED_MEDIA_SOURCES',

    SKIP_FRAME = '@@projectsStudio/SKIP_FRAME',
    SKIP_TIME = '@@projectsStudio/SKIP_TIME',
    SKIP_TIMELINE_CUT = '@@projectsStudio/SKIP_TIMELINE_CUT',
    SLIDE_PROJECT_TIMELINE = '@@projectsStudio/SLIDE_PROJECT_TIMELINE',

    SET_AUDIO_WAVE_VISIBLE = '@@projectsStudio/SET_AUDIO_WAVE_VISIBLE',

    SELECT_PTOJECT_OBJECT = '@@projectsStudio/SELECT_PTOJECT_OBJECT',
    SET_SELECTED_PTOJECT_OBJECTS = '@@projectsStudio/SET_SELECTED_PTOJECT_OBJECTS',
    SELECT_ALL_OBJECTS = '@@projectsStudio/SELECT_ALL_OBJECTS',
    REMOVE_SELECTED_OBJECTS = '@@projectsStudio/REMOVE_SELECTED_OBJECTS',

    NAVIGATE_TO_ROUTE = '@@projectsStudio/NAVIGATE_TO_ROUTE',

    COPY_PROJECT_MEDIA = '@@projectsStudio/COPY_PROJECT_MEDIA',
    PASTE_PROJECT_MEDIA = '@@projectsStudio/PASTE_PROJECT_MEDIA',
    SET_COPIED_MEDIA = '@@projectsStudio/SET_COPIED_MEDIA',
}

export interface SplitProjectTimelineAction {
    type: ProjectsStudioActionTypes.SPLIT_PROJECT_TIMELINE;
}

// Time //
export interface SetTimelineTimeAction {
    type: ProjectsStudioActionTypes.SET_TIMELINE_TIME;
    payload: number;
}

export interface SetTimelineSliderValueAction {
    type: ProjectsStudioActionTypes.SET_TIMELINE_SLIDER_VALUE;
    payload: number;
}

//
export interface SetProjectNavRouteAction {
    type: ProjectsStudioActionTypes.SET_PROJECT_NAV_ROUTE;
    payload: ProjectNavRoute;
}

export interface SetSelectedShapeAction {
    type: ProjectsStudioActionTypes.SET_SELECTED_SHAPE;
    payload: ProjectCanvasSelectedShape | null;
}

export interface SetPojectPlayingAction {
    type: ProjectsStudioActionTypes.SET_PLAYING;
    payload: boolean;
}

export interface SetTimelineHeightAction {
    type: ProjectsStudioActionTypes.SET_TIMELINE_HEIGHT;
    payload: number;
}

export interface FinishProjectVideoActionPayload {
    id: string;
}
export interface FinishProjectVideoAction {
    type: ProjectsStudioActionTypes.FINISH_PROJECT_VIDEO;
    payload: FinishProjectVideoActionPayload;
}

export interface PauseProjectVideoAction {
    type: ProjectsStudioActionTypes.PAUSE_PROJECT_VIDEO;
}

export interface UpdateProjectTimeActionPayload {
    time: number;
    video: ProjectVideo;
}

export interface UpdateProjectTimeAction {
    type: ProjectsStudioActionTypes.UPDATE_PROJECT_TIME;
    payload: UpdateProjectTimeActionPayload;
}

export interface ToggleProjectPlayerAction {
    type: ProjectsStudioActionTypes.TOGGLE_PROJECT_PLAYER;
}

export interface AddPreparedMediaSourceAction {
    type: ProjectsStudioActionTypes.ADD_PREPARED_MEDIA_SOURCE;
    payload: string;
}
export interface SetPreparedMediaSourcesAction {
    type: ProjectsStudioActionTypes.SET_PREPARED_MEDIA_SOURCES;
    payload: string[];
}

export interface SkipProjectTimeAction {
    type: ProjectsStudioActionTypes.SKIP_TIME;
    payload: number;
}

export interface SkipTimelineCutAction {
    type: ProjectsStudioActionTypes.SKIP_TIMELINE_CUT;
    payload: boolean;
}

export interface SkipProjectFrameAction {
    type: ProjectsStudioActionTypes.SKIP_FRAME;
    payload: number;
}

export interface SliderProjectTimelineAction {
    type: ProjectsStudioActionTypes.SLIDE_PROJECT_TIMELINE;
    payload: number;
}

export interface SetProjectAudioWaveVisibleAction {
    type: ProjectsStudioActionTypes.SET_AUDIO_WAVE_VISIBLE;
    payload: boolean;
}

export interface SelectProjectTimelineObjectActionPayload {
    object: SelectedProjectObject;
    isSelected?: boolean;
    isAdd?: boolean;
}
export interface SelectProjectObjectAction {
    type: ProjectsStudioActionTypes.SELECT_PTOJECT_OBJECT;
    payload: SelectProjectTimelineObjectActionPayload;
}

export interface SetProjectSelectedObjectsAction {
    type: ProjectsStudioActionTypes.SET_SELECTED_PTOJECT_OBJECTS;
    payload: SelectedProjectObject[];
}
export interface SelectAllObjectsAction {
    type: ProjectsStudioActionTypes.SELECT_ALL_OBJECTS;
}

export interface RemoveSelectedObjectsAction {
    type: ProjectsStudioActionTypes.REMOVE_SELECTED_OBJECTS;
    payload: string[];
}

export interface NavigateToRouteAction {
    type: ProjectsStudioActionTypes.NAVIGATE_TO_ROUTE;
    payload: string;
}

export interface CopyProjectMediaActionPayload {
    isCut: boolean,
}

export interface CopyProjectMediaAction {
    type: ProjectsStudioActionTypes.COPY_PROJECT_MEDIA;
    payload: CopyProjectMediaActionPayload,
}

export interface PasteProjectMediaAction {
    type: ProjectsStudioActionTypes.PASTE_PROJECT_MEDIA;
}

export interface SetCopiedMediaAction {
    type: ProjectsStudioActionTypes.SET_COPIED_MEDIA;
    payload: SelectedProjectObject,
}

export type ProjectsStudioAction =
    | SplitProjectTimelineAction
    | SetTimelineTimeAction
    | SetProjectNavRouteAction
    | SetSelectedShapeAction
    | SetPojectPlayingAction
    | SetTimelineSliderValueAction
    | SetTimelineHeightAction
    | FinishProjectVideoAction
    | PauseProjectVideoAction
    | UpdateProjectTimeAction
    | ToggleProjectPlayerAction
    | AddPreparedMediaSourceAction
    | SetPreparedMediaSourcesAction
    | SkipProjectTimeAction
    | SliderProjectTimelineAction
    | SetProjectAudioWaveVisibleAction
    | SelectProjectObjectAction
    | SetProjectSelectedObjectsAction
    | SelectAllObjectsAction
    | RemoveSelectedObjectsAction
    | NavigateToRouteAction
    | SkipProjectFrameAction
    | SkipTimelineCutAction
    | CopyProjectMediaAction
    | PasteProjectMediaAction
    | SetCopiedMediaAction;
