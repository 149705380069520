import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCreditCardForm from 'components/AddCreditCardForm';

import { NewButton } from 'components/UI';

import {
    addCustomerCard,
    getAddCustomerCardLoading,
    getCustomerCreationLoading,
    getCustomerInfo,
    getSubscriptionCreationLoading,
} from 'state/modules/payment';
import { getModalEvent, ModalEvent } from 'state/modules/modal';

import { Colors } from 'styles';
import { CardFormInputKey } from 'interfaces/payment';
import PaymentClient from 'services/api/payment';
import {
    useStripe,
    useElements,
    PaymentElement,
} from '@stripe/react-stripe-js';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const AddCreditCardModal = (props: Props): JSX.Element => {
    const [expiry, setExpiry] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cvc, setCvc] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [cvcError, setCvcError] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const isCustomerCreationLoading = useSelector(getCustomerCreationLoading);
    const isAddCustomerCardLoading = useSelector(getAddCustomerCardLoading);
    const isSubscriptionCreationLoading = useSelector(
        getSubscriptionCreationLoading
    );

    const modalEvent = useSelector(getModalEvent);
    const customer = useSelector(getCustomerInfo);

    const { closeButton } = props;

    const dispatch = useDispatch();

    useEffect(
        () => () => {
            setExpiry('');
            setCardNumber('');
            setCvc('');
        },
        []
    );

    const handleSetError = (key: CardFormInputKey, error: string) => {
        switch (key) {
            case CardFormInputKey.CARD_NUMBER:
                return setCardNumberError(error);
            case CardFormInputKey.EXPIRY:
                return setExpiryError(error);
            case CardFormInputKey.CVC:
                return setCvcError(error);
            default:
                break;
        }
    };

    const handleChangeFieldValue = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: string
    ) => {
        const { value } = event.target;

        switch (field) {
            case 'cardNumber':
                setCardNumber(value);
                setCardNumberError('');

                return;
            case 'cvc':
                setCvc(value);
                setCvcError('');

                return;
            case 'expiry':
                setExpiry(value);
                setExpiryError('');

                return;
            default:
                return null;
        }
    };

    const handleSubmit = async () => {
        const expiryDateParts = expiry.split('/');
        const transformedCardNumber = cardNumber.split('-').join('');

        if (
            modalEvent === ModalEvent.ADD_CREDIT_CARD ||
            modalEvent === ModalEvent.ADD_CREDIT_CARD_TO_BY_ADDON
        ) {
            const isFirstCard = !customer?.cards?.length;

            dispatch(
                addCustomerCard({
                    cardBody: {
                        card: {
                            number: transformedCardNumber.replace('_', ''),
                            expMonth: +expiryDateParts[0],
                            expYear: 2000 + +expiryDateParts[1],
                            cvcCode: cvc.replace('_', ''),
                            isPrefer: isFirstCard,
                        },
                    },
                    stripe,
                })
            );
        } else if (
            modalEvent ===
            ModalEvent.ADD_CREDIT_CARD_FOR_SUBSCRIPTION_ACTIVATION
        ) {
            const isFirstCard = !customer?.cards?.length;

            dispatch(
                addCustomerCard({
                    cardBody: {
                        card: {
                            number: transformedCardNumber.replace('_', ''),
                            expMonth: +expiryDateParts[0],
                            expYear: 2000 + +expiryDateParts[1],
                            cvcCode: cvc.replace('_', ''),
                            isPrefer: isFirstCard,
                        },
                    },
                    activateСurrentSubscription: true,
                })
            );
        }
    };

    const getTitle = () => {
        if (customer?.cards?.length) {
            return 'Add new card';
        }
        return 'Add card';
    };

    const renderAddCardButton = () => (
        <NewButton
            color={Colors.GREY3}
            textColor={Colors.WHITE}
            bordered
            borderRadius={5}
            width={132}
            height={46}
            onClick={handleSubmit}
            loading={
                isCustomerCreationLoading ||
                isAddCustomerCardLoading ||
                isSubscriptionCreationLoading
            }
            disabled={
                isCustomerCreationLoading ||
                isAddCustomerCardLoading ||
                isSubscriptionCreationLoading ||
                cardNumberError.length > 0 ||
                expiryError.length > 0 ||
                cvcError.length > 0
            }
        >
            Add card
        </NewButton>
    );

    return (
        <div className={styles.AddCreditCardModal}>
            <p
                className={[
                    styles.AddCreditCardModal__title,
                    modalEvent === ModalEvent.ADD_CREDIT_CARD ||
                    modalEvent ===
                        ModalEvent.ADD_CREDIT_CARD_FOR_SUBSCRIPTION_ACTIVATION
                        ? styles.AddCreditCardModal__title_offset
                        : null,
                ].join(' ')}
            >
                {getTitle()}
            </p>
            <div className={styles.AddCreditCardModal__container}>
                <AddCreditCardForm
                    cardNumber={cardNumber}
                    cardNumberError={cardNumberError}
                    expiry={expiry}
                    expiryError={expiryError}
                    cvc={cvc}
                    cvcError={cvcError}
                    setError={handleSetError}
                    changeFieldValue={handleChangeFieldValue}
                    horizontal
                />
            </div>
            <div className={styles.AddCreditCardModal__actionButtonsContainer}>
                {renderAddCardButton()}
            </div>
            {/* <PaymentElement /> */}
            {closeButton}
        </div>
    );
};

export default AddCreditCardModal;
