import { IconProps } from './index';

const ImageIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 33;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_419_17825)">
                <path
                    d="M0.5 6.5C0.5 4.29375 2.29375 2.5 4.5 2.5H28.5C30.7062 2.5 32.5 4.29375 32.5 6.5V26.5C32.5 28.7062 30.7062 30.5 28.5 30.5H4.5C2.29375 30.5 0.5 28.7062 0.5 26.5V6.5ZM20.7375 13.1562C20.4562 12.7437 19.9937 12.5 19.5 12.5C19.0063 12.5 18.5375 12.7437 18.2625 13.1562L12.825 21.1313L11.1687 19.0625C10.8812 18.7062 10.45 18.5 10 18.5C9.55 18.5 9.1125 18.7062 8.83125 19.0625L4.83125 24.0625C4.46875 24.5125 4.4 25.1313 4.65 25.65C4.9 26.1687 5.425 26.5 6 26.5H12H14H27C27.5562 26.5 28.0688 26.1938 28.325 25.7C28.5813 25.2063 28.55 24.6125 28.2375 24.1562L20.7375 13.1562ZM7.5 12.5C9.15625 12.5 10.5 11.1562 10.5 9.5C10.5 7.84375 9.15625 6.5 7.5 6.5C5.84375 6.5 4.5 7.84375 4.5 9.5C4.5 11.1562 5.84375 12.5 7.5 12.5Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_419_17825">
                    <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.5 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ImageIcon;
