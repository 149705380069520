import { StatusType } from './statuses';

export enum DATE_FILTER_TYPE {
    ANYTIME = 'Anytime',
    YESTERDAY = 'Yesterday',
    LAST_7_DAYS = 'Last 7 Days',
    LAST_30_DAYS = 'Last 30 Days',
    LAST_90_DAYS = 'Last 90 Days',
    CUSTOM = 'Custom',
}

export interface DateFilterOption {
    value: DATE_FILTER_TYPE;
    id: number;
}

export interface StatusFilterOption {
    value:
        | StatusType.SUCCEEDED
        | StatusType.FAILED
        | StatusType.IN_PROGRESS
        | null;
    id: number;
    label: string;
}
