import { IconProps } from '../index';

const AudioIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.3492 5.72903C26.3492 4.36283 25.0653 3.36037 23.7399 3.69173L12.0399 6.61673C11.105 6.85044 10.4492 7.6904 10.4492 8.65403V12.3826C10.449 12.3934 10.449 12.4043 10.4492 12.4151V13.5999V21.1124C9.68596 20.4502 8.68978 20.0495 7.6 20.0495C5.19756 20.0495 3.25 21.997 3.25 24.3995C3.25 26.8019 5.19756 28.7495 7.6 28.7495C10.0024 28.7495 11.95 26.8019 11.95 24.3995C11.95 24.3716 11.9497 24.3438 11.9492 24.3161V13.5999V12.985L24.8492 9.76004V18.7141C24.0859 18.0519 23.0896 17.651 21.9997 17.651C19.5973 17.651 17.6497 19.5986 17.6497 22.001C17.6497 24.4035 19.5973 26.351 21.9997 26.351C24.4022 26.351 26.3497 24.4035 26.3497 22.001C26.3497 21.9784 26.3496 21.9557 26.3492 21.9331V9.39998V8.81624C26.3495 8.80546 26.3495 8.79465 26.3492 8.78381V5.72903ZM24.8492 8.21387V5.72903C24.8492 5.33868 24.4824 5.05227 24.1037 5.14694L12.4037 8.07194C12.1366 8.13871 11.9492 8.37871 11.9492 8.65403V11.4389L24.8492 8.21387ZM21.9997 19.151C23.5554 19.151 24.8199 20.3974 24.8492 21.9461V21.9999C24.8492 22.0053 24.8493 22.0106 24.8494 22.0158C24.8495 22.019 24.8495 22.0221 24.8496 22.0252C24.8366 23.5881 23.5657 24.851 21.9997 24.851C20.4257 24.851 19.1497 23.575 19.1497 22.001C19.1497 20.427 20.4257 19.151 21.9997 19.151ZM10.4492 24.332V24.3999C10.4492 24.4102 10.4494 24.4204 10.4498 24.4305C10.4332 25.9902 9.16365 27.2495 7.6 27.2495C6.02599 27.2495 4.75 25.9735 4.75 24.3995C4.75 22.8254 6.02599 21.5495 7.6 21.5495C9.15148 21.5495 10.4134 22.7892 10.4492 24.332Z"
                fill={color}
            />
        </svg>
    );
};

export default AudioIconNew;


