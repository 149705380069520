interface ButtonSizes {
    [key: string]: {
        width: number;
        height: number;
    };
}

interface ButtonBorderRadius {
    [key: string]: number;
}

export const ButtonSizes: ButtonSizes = {
  xs: {
    width: 82,
    height: 27,
  },
  sm: {
    width: 142,
    height: 40,
  },
  md: {
    width: 152,
    height: 40,
  },
  lg: {
    width: 186,
    height: 40,
  },
};

export const ButtonBorderRadius: ButtonBorderRadius = {
  xs: 13,
  sm: 5,
  md: 5,
  lg: 5,
};

export const breakpoints = {
    xs: '576px',
    sm: '768px',
    md: '1024px',
    lg: '1200px',
    xlg: '1366px',
    xlg1: '1367px',
    xxl: '1440px',
    xxxl: '1761',
};
