import { AxiosResponse, CancelToken } from 'axios';
import { build, omit } from 'search-params';

import store from 'state/store';

import {
    GetMetadataRes,
    GetTimestampsReqMetadata,
} from 'state/modules/metadata';

import { ShotType } from 'interfaces/shots';
import { SentenceUpdateBodyIF } from 'interfaces/transcript';
import { SentenceTypes } from 'interfaces/sentences';

import { deleteSentenceLanguageParams } from 'models/sentences';

import $http from './instance';

class MetadataClient {
    public static async getMetadata(
        {
            videoId,
            types,
            confidence = 50,
            scaleFactor = 0,
            afterKey = '',
            size = 50,
        }: {
            videoId: string;
            types: string;
            confidence: number;
            scaleFactor: number;
            afterKey?: string;
            size?: number;
        },
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<GetMetadataRes>> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            afterKey,
            types,
            scaleFactor,
            size,
        });

        if (!types.length || types === 'all') {
            query = omit(query, ['types']).querystring;
        }

        if (!afterKey.length) {
            query = omit(query, ['afterKey']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata?confidence[gte]=${confidence}&sortBy=+name${
                query.length ? `&${query}` : ''
            }`,
            { cancelToken }
        );
    }

    public static async getMetadataDetails(
        videoId: string,
        type = '',
        name = '',
        confidence = 50,
        scaleFactor = 0,
        offset = 0
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            scaleFactor,
            offset,
            limit: 1000,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/type/${type}/name/${name}?${query}&confidence[gte]=${confidence}`
        );
    }

    public static async getTimestampsForManyItems(
        videoId: string,
        data: Array<GetTimestampsReqMetadata>,
        offset?: number
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/many?limit=1000&offset=${
                offset || 0
            }`,
            {
                labels: data,
            }
        );
    }

    public static async getTranscript(
        videoId: string,
        q = '',
        offset = 0,
        language: string,
        sentenceType?: string,
        disableForProject?: string
    ): Promise<AxiosResponse> {
        const { user, workspaces , projects} = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            q,
            limit: 250,
            offset,
            language,
            sentenceType: sentenceType || 'original',
            disableForProject,
        });

        if (!q.length) {
            query = omit(query, ['q']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/transcript/sentences?${query}`
        );
    }

    public static async createSentence(
        videoId: string,
        data: any
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects} = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/transcript/sentences`,
            data
        );
    }

    public static async getMetadataCategoriesTotal(
        videoId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/categories`
        );
    }

    public static async getChildrens(
        videoId: string,
        type: string,
        name: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/type/${type}/name/${name}/children`
        );
    }

    public static async getAutocomplete(
        videoId: string,
        q: string,
        types: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            q,
            types,
        });

        if (!types.length || types === 'all') {
            query = omit(query, ['types']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/autocomplete?${query}`
        );
    }

    public static async getMetadataByGlobalSearch(
        q: string,
        types: string,
        minConfidence: number
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            q,
            types,
        });

        if (!types.length || types === 'all') {
            query = omit(query, ['types']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/metadata/global?confidence[gte]=${minConfidence}&${query}`
        );
    }

    public static async getTranscriptByGlobalSearch(
        q: string,
        offset: number
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/metadata/global/transcript?q=${q}&limit=1000&offset=${offset}`
        );
    }

    public static async getSegments(
        videoId: string,
        offset: number,
        types: ShotType | string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            offset,
            types,
            limit: 50,
        });

        if (!types.length) {
            query = omit(query, ['types']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/segments?${query}`
        );
    }

    public static async getAvailableLanguages(
        videoId: string,
        projectId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            videoId,
            projectId,
        });

        if (!videoId) {
            query = omit(query, ['videoId']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/metadata/transcript/languages?${query}`
        );
    }

    public static async editSentence(
        videoId: string,
        sentenceId: string,
        data: SentenceUpdateBodyIF
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/transcript/sentences/${sentenceId}`,
            data
        );
    }

    public static async deleteSentence(
        videoId: string,
        sentenceId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/transcript/sentences/${sentenceId}`
        );
    }

    public static async getShotsBySearch(
        videoId: string,
        query: string,
        offset: number
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/metadata/search?q=${query}&offset=${offset}&limit=500`
        );
    }

    public static async deleteSentenceLanguages(
        params: deleteSentenceLanguageParams
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { sentenceType, language, videoId, projectId, projectVersion } = params;

        let query = build({
            sentenceType,
            language,
            id: videoId,
            projectId,
            projectVersion,
        });

        if (!projectId.length) {
            query = omit(query, ['projectId']).querystring;
        }
        if (!videoId) {
            query = omit(query, ['id']).querystring;
        }

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/metadata/transcript?${query}`
        );
    }

    public static async disableProjectSentences(
        projectId: string,
        sentenceIds: string,
        type: SentenceTypes
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            sentenceIds,
            type,
        });

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/metadata/transcript/sentences/disable/${projectId}?${query}`
        );
    }
}

export default MetadataClient;
