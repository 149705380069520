import { IconProps } from './index';


const ProjectIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 25;
  if (size) {
    height = size;
  }

  return (
    <svg width={height}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        stroke={color}
        strokeWidth="1.5"
      >
        <path
          d="M2 8.6c0-2.66 0-3.99.518-5.006a4.75 4.75 0 0 1 2.076-2.076C5.61 1 6.94 1 9.6 1h5.067L21 7.333v8.234c0 2.66 0 3.99-.518 5.006a4.75 4.75 0 0 1-2.075 2.076c-1.017.518-2.347.518-5.007.518H9.6c-2.66 0-3.99 0-5.006-.518a4.75 4.75 0 0 1-2.076-2.076C2 19.557 2 18.227 2 15.567V8.6Z" /><path d="M14.667 1v6.333H21" strokeLinecap="round" strokeLinejoin="round" /><path d="M15.3 13.99 12.51 15.67c-.022 0-.022.023-.044.023l-2.791 1.681a.874.874 0 0 1-.484.162c-.461 0-.857-.392-.857-.899v-6.84c0-.161.044-.3.11-.438.242-.414.747-.576 1.165-.322l2.835 1.704 2.813 1.705c.132.069.242.184.33.322.264.438.132.99-.286 1.22Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectIcon;
