export enum ClipTemplate {
    CUSTOM = 'CUSTOM',
    DR = 'DR',
    CUSTOM_TEN_SEC = '10 seconds (Custom)',
    CUSTOM_TWENTY_SEC = '20 seconds (Custom)',
    HIGHLIGHT_EXTRACTION = 'HIGHLIGHT_EXTRACTION',
}

export enum ClipTemplateTitle {
    CUSTOM = 'Custom',
    DR = 'Celebrities',
    CUSTOM_TEN_SEC = '10 seconds (Custom)',
    CUSTOM_TWENTY_SEC = '20 seconds (Custom)',
    HIGHLIGHT_EXTRACTION = 'Highlights for sport',
}

export interface Trailer {
    id: string;
    videoId: string;
    status: {
        result: string;
        message: string;
    };
    ownerId: string;
    createdDate: string;
    rerollCount: number;
    jobRequestId: string;
    jobId: string;
    trailerName: string;
    trailerS3Path: string;
    metadata: string;
    mediaInfo: {
        filename: string;
        container: {
            duration: number;
            fileSize: number;
            totalBitrate: number;
            format: string;
        };
        video: [
            {
                duration: number;
                colorSpace: string;
                codec: string;
                frameCount: number;
                framerate: number;
                profile: string;
                bitDepth: number;
                width: number;
                bitrate: number;
                aspectRatio: string;
                scanType: string;
                height: number;
            }
        ];
        audio: [
            {
                duration: number;
                codec: string;
                frameCount: number;
                channels: number;
                samplePerFrame: number;
                bitrateMode: string;
                samplingRate: number;
                bitrate: number;
            }
        ];
    };
    timestamps: Array<TrailerTimestamp>;
}

export interface TrailerTimestamp {
    from: string;
    to: string;
    metadata: TrailerTimestampMedata;
}

export interface TrailerTimestampMedata {
    from: number;
    to: number;
    name: string;
    color: string;
    type: string;
    id: string;
    duration: number;
}

export interface TransformedGenTemplate {
    value: string | ClipTemplate;
    isDisabled?: boolean;
    label: string | ClipTemplateTitle;
    config?: {
        minSceneLength?: number;
        maxSceneLength?: number;
        minTotalLength?: number;
        maxTotalLength?: number;
    };
}
