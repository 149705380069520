import produce from 'immer';

import {
    FoldersState,
    FoldersAction,
    FoldersActionTypes,
} from 'state/modules/folders';
import { SignOutAction, UserActionTypes } from '../user';

const initialState: FoldersState = {
    foldersList: [],
    total: 0,
    foldersHistory: [],
    isLoading: false,
    currentFolder: null,
    // isSubfoldersLoading: false,
    isCreationLoading: false,
    isDeleteLoading: false,
    isUpdateLoading: false,
    isMoveToFolderLoading: false,
};

export default (
    state = initialState,
    action: FoldersAction | SignOutAction
): FoldersState =>
    produce(state, (draft) => {
        switch (action.type) {
            case FoldersActionTypes.GET_FOLDERS_START:
                draft.total = 0;
                draft.foldersList = [];
                draft.isLoading = true;

                return;
            case FoldersActionTypes.GET_FOLDERS_FAIL:
                draft.isLoading = false;

                return;
            case FoldersActionTypes.SET_FOLDERS:
                draft.foldersList = action.payload.folders;
                draft.total = action.payload.total;
                draft.isLoading = false;

                return;
            case FoldersActionTypes.MOVE_TO_FOLDER_START:
                draft.isMoveToFolderLoading = true;

                return;
            case FoldersActionTypes.MOVE_TO_FOLDER_FAIL:
            case FoldersActionTypes.MOVE_TO_FOLDER_SUCCESS:
                draft.isMoveToFolderLoading = false;

                return;
            case FoldersActionTypes.CREATE_FOLDER_START:
                draft.isCreationLoading = true;

                return;
            case FoldersActionTypes.CREATE_FOLDER_FAIL:
            case FoldersActionTypes.CREATE_FOLDER_SUCCESS:
                draft.isCreationLoading = false;

                return;
            case FoldersActionTypes.UPDATE_FOLDER_START:
                draft.isUpdateLoading = true;

                return;
            case FoldersActionTypes.UPDATE_FOLDER_FAIL:
            case FoldersActionTypes.UPDATE_FOLDER_SUCCESS:
                draft.isUpdateLoading = false;

                return;
            case FoldersActionTypes.SET_FOLDERS_HISTORY:
                draft.foldersHistory = action.payload;

                return;
            case FoldersActionTypes.SET_CURRENT_FOLDER:
                draft.currentFolder = action.payload;

                return;
            case UserActionTypes.SIGN_OUT:
                return initialState;
            default:
                return state;
        }
    });
