import { IconProps } from './index';

const AudioIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 33;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 36 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.5 23.2028V15.7972C5.5 15.1425 5.23661 14.5146 4.76777 14.0516C4.29893 13.5887 3.66304 13.3286 3 13.3286C2.33696 13.3286 1.70107 13.5887 1.23223 14.0516C0.763393 14.5146 0.5 15.1425 0.5 15.7972V23.2028C0.5 23.8575 0.763393 24.4854 1.23223 24.9484C1.70107 25.4113 2.33696 25.6714 3 25.6714C3.66304 25.6714 4.29893 25.4113 4.76777 24.9484C5.23661 24.4854 5.5 23.8575 5.5 23.2028Z"
                fill={color}
            />
            <path
                d="M15.4999 30.6084V8.3914C15.4999 7.7367 15.2365 7.10882 14.7676 6.64587C14.2988 6.18293 13.6629 5.92285 12.9999 5.92285C12.3368 5.92285 11.701 6.18293 11.2321 6.64587C10.7633 7.10882 10.4999 7.7367 10.4999 8.3914V30.6084C10.4999 31.2631 10.7633 31.891 11.2321 32.3539C11.701 32.8169 12.3368 33.0769 12.9999 33.0769C13.6629 33.0769 14.2988 32.8169 14.7676 32.3539C15.2365 31.891 15.4999 31.2631 15.4999 30.6084Z"
                fill={color}
            />
            <path
                d="M25.5 25.6712V13.3284C25.5 12.6737 25.2366 12.0458 24.7678 11.5829C24.2989 11.1199 23.663 10.8599 23 10.8599C22.337 10.8599 21.7011 11.1199 21.2322 11.5829C20.7634 12.0458 20.5 12.6737 20.5 13.3284V25.6712C20.5 26.3259 20.7634 26.9538 21.2322 27.4167C21.7011 27.8797 22.337 28.1397 23 28.1397C23.663 28.1397 24.2989 27.8797 24.7678 27.4167C25.2366 26.9538 25.5 26.3259 25.5 25.6712Z"
                fill={color}
            />
            <path
                d="M35.5 35.5456V3.45439C35.5 2.79969 35.2366 2.17181 34.7678 1.70886C34.2989 1.24592 33.663 0.98584 33 0.98584C32.337 0.98584 31.7011 1.24592 31.2322 1.70886C30.7634 2.17181 30.5 2.79969 30.5 3.45439V35.5456C30.5 36.2003 30.7634 36.8282 31.2322 37.2911C31.7011 37.7541 32.337 38.0141 33 38.0141C33.663 38.0141 34.2989 37.7541 34.7678 37.2911C35.2366 36.8282 35.5 36.2003 35.5 35.5456Z"
                fill={color}
            />
        </svg>
    );
};

export default AudioIcon;