import { useDispatch, } from 'react-redux';

import { Icon } from 'components/UI';

import { ModalEvent, ModalType, showModal } from 'state/modules/modal';
import styles from './styles.module.scss';

interface Props {
  size?: string;
  navigateBack?: boolean;
  onClick?: () => void;
}

export const CardPlaceholder = (props: Props): JSX.Element => {
  const { size, navigateBack, onClick } = props;

  const dispatch = useDispatch();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (navigateBack) {
      dispatch(
        showModal(
          ModalType.ADD_CREDIT_CARD,
          ModalEvent.ADD_CREDIT_CARD_TO_BY_ADDON,
        ),
      );
    } else {
      dispatch(
        showModal(ModalType.ADD_CREDIT_CARD, ModalEvent.ADD_CREDIT_CARD),
      );
    }
  };

  return (
    <button
      className={[
        styles.CardPlaceholder,
        size === 'large' ? styles.CardPlaceholder_large : null,
        size === 'medium' ? styles.CardPlaceholder_medium : null,
        size === 'small' ? styles.CardPlaceholder_small : null,
      ].join(' ')}
      onClick={handleClick}
    >
      <Icon name="plus" size={36} />
      <p className={styles.CardPlaceholder__title}>Add new card</p>
    </button>
  );
};

export default CardPlaceholder;
