import moment from 'moment';

export const timecodeToMs = (timecode: string): number => {
    const h =
        Number(timecode.slice(0, 2).replace('_', '0')) * 60 * 60 * 1000 || 0;
    const m = Number(timecode.slice(3, 5).replace('_', '0')) * 60 * 1000 || 0;
    const s = Number(timecode.slice(6, 8).replace('_', '0')) * 1000 || 0;
    const ms = Number(timecode.slice(9, 10).replace('_', '0')) * 100 || 0;

    const sum = h + m + s + ms;

    return sum;
};

export const msToSec = (ms: number): string => {
    const sec = ms / 1000;

    const secArray = `${sec}`.split('.');
    const firstPart = secArray[0];
    const secondPart = secArray[1] || '0';

    const trasformedSec = firstPart.length > 1 ? firstPart : `0${firstPart}`;
    const transformedMs = secondPart.length > 1 ? secondPart : `${secondPart}0`;

    return `${trasformedSec}.${transformedMs}`;
};

export const getFormattedTimecode = (time: number): string => {
    const duration = moment.duration(time, 'seconds') as any;

    const { hours, minutes, seconds, milliseconds } = duration._data;

    let h = `${hours}`;
    let m = `${minutes}`;
    let s = `${seconds}`;
    let ms = `${milliseconds}`;

    if (h.length === 1) {
        h = `0${h}`;
    }
    if (m.length === 1) {
        m = `0${m}`;
    }
    if (s.length === 1) {
        s = `0${s}`;
    }

    ms = ms.slice(0, 1);

    return `${h}:${m}:${s}.${ms}`;
};

export function timeToMs(hhMmSsMs: string): number {
    const [hours, minutes, seconds, milliseconds] = `${hhMmSsMs}`.split(':');

    const hoursInMs = Number(hours) * 60 * 60 * 1000;

    const minutesInMs = Number(minutes) * 60 * 1000;

    const secondsInMs = Number(seconds) * 1000;

    return hoursInMs + minutesInMs + secondsInMs + Number(milliseconds);
}
