import produce from 'immer';

import {
  GenerationAction,
  GenerationActionTypes,
  GenerationState,
} from 'state/modules/generation';
import { SignOutAction, UserActionTypes } from 'state/modules/user';
import { ClearDataAction, VideosActionTypes } from 'state/modules/media';

const initialState: GenerationState = {
  trailers: [],
  previews: [],
  isClipsRenderingLoading: false,
  isPreviewsLoading: false,
  isTrailersLoading: false,
  isGenerationLoading: false,
};

export default (
  state = initialState,
  action: GenerationAction | SignOutAction | ClearDataAction,
): GenerationState => produce(state, (draft) => {
  switch (action.type) {
    case GenerationActionTypes.GET_TRAILERS_START:
      draft.isTrailersLoading = true;

      return;
    case GenerationActionTypes.GET_TRAILERS_FAIL:
      draft.isTrailersLoading = false;

      return;
    case GenerationActionTypes.GET_TRAILERS_SUCCESS:
      draft.trailers = action.payload.trailers;
      draft.isTrailersLoading = false;

      return;
    case GenerationActionTypes.GENERATE_CLIP_PREVIEWS_START:
      draft.isGenerationLoading = true;

      return;
    case GenerationActionTypes.GENERATE_CLIP_PREVIEWS_FAIL:
      draft.isGenerationLoading = false;

      return;
    case GenerationActionTypes.GENERATE_CLIP_PREVIEWS_SUCCESS:
      draft.isGenerationLoading = false;

      return;
    case GenerationActionTypes.GET_PREVIEWS_START:
      draft.isPreviewsLoading = true;

      return;
    case GenerationActionTypes.GET_PREVIEWS_FAIL:
      draft.isPreviewsLoading = false;

      return;
    case GenerationActionTypes.GET_PREVIEWS_SUCCESS:
      draft.isPreviewsLoading = false;
      draft.previews = action.payload.previews;

      return;
    case GenerationActionTypes.GENERATE_CLIPS_START:
      draft.isClipsRenderingLoading = true;

      return;
    case GenerationActionTypes.GENERATE_CLIPS_SUCCESS:
      draft.isClipsRenderingLoading = false;

      return;
    case GenerationActionTypes.GENERATE_CLIPS_FAIL:
      draft.isClipsRenderingLoading = false;

      return;
    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      return initialState;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
