import produce from 'immer';

import { 
  TranscriptState, 
  TranscriptAction, 
  TranscriptActionTypes, 
} from 'state/modules/transcript';
import { ClearDataAction, VideosActionTypes } from 'state/modules/media';
import { SignOutAction, UserActionTypes } from 'state/modules/user';

const initialState: TranscriptState = {
  mediaLanguagesCodes: [],
  isTranscriptGenerationLoading: false,
  isTranslateGenerationLoading: false,
  isCancelTranslateLoading: false,
};

export default (
  state = initialState,
  action: TranscriptAction | SignOutAction | ClearDataAction,
): TranscriptState => produce(state, (draft) => {
  switch (action.type) {
    case TranscriptActionTypes.SET_MEDIA_LANGUAGES_CODES:
      draft.mediaLanguagesCodes = action.payload;

      return;
    case TranscriptActionTypes.SET_TRANSCRIPT_GENERATION_LOADING:
      draft.isTranscriptGenerationLoading = action.payload;

      return;
    case TranscriptActionTypes.SET_TRANSLATE_GENERATION_LOADING:
      draft.isTranslateGenerationLoading = action.payload;

      return;
    case TranscriptActionTypes.CANCEL_TRANSLATE_START:
      draft.isCancelTranslateLoading = true;

      return;
    case TranscriptActionTypes.CANCEL_TRANSLATE_SUCCESS:
      draft.isCancelTranslateLoading = false;

      return;
    case TranscriptActionTypes.CANCEL_TRANSLATE_FAIL:
      draft.isCancelTranslateLoading = false;

      return;
    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      return initialState;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
