import { actionChannel, all, call, delay, flush, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import without from 'lodash.without';
import { v4 as uuid } from 'uuid';
import sortBy from 'lodash.sortby';
import isEqual from 'lodash.isequal';
import { getInfo } from 'react-mediainfo';
import { Analytics } from 'aws-amplify';

import {
    AudioFadeProps,
    CleanAudioGenerationMediaStatuses,
    Project,
    ProjectAudio,
    ProjectAudioMediaSource,
    ProjectElement,
    ProjectImage,
    ProjectMediaAudioOptions,
    ProjectMediaSourcesSettings,
    ProjectSize,
    ProjectSort,
    ProjectStatuses,
    ProjectText,
    ProjectUpload,
    ProjectVideo,
    TemplateStatus,
    TimelineMedias,
    VideoFilter,
    VideoTransition,
} from 'interfaces/projects';
import { MediaFile, MediaType, TempMediaFile } from 'interfaces/videos';
import { StatusType } from 'interfaces/statuses';
import { ProjectPreferences } from 'interfaces/preferences';

import ProjectsClient from 'services/api/projects';
import VideosClient from 'services/api/videos';
import { Range } from 'utils/range';

import * as UserActions from 'state/modules/user/actions';
import * as ModalsActions from 'state/modules/modal/actions';
import * as ProjectsStudioActions from 'state/modules/projectsStudio/actions';
import * as UploadActions from 'state/modules/uploading/actions';
import * as StockMediaActions from 'state/modules/stockMedia/actions';
import * as ProjectUploadActions from 'state/modules/projectUpload/actions';
import * as subtitlesActions from 'state/modules/projectSubtitles/actionsNew';

import {
    adaptAddedVideos,
    defaultProjectSubtitlesSettings,
    detachProjectAudioFromProjectVideo,
    getMediaSourcesPreviewsTimestamps,
    getPreparedNewProjectData,
    getSplittedProjectAudios,
    getSplittedProjectElements,
    getSplittedProjectImages,
    getSplittedProjectText,
    getSplittedProjectVideos,
    handleCheckProjectDuration,
    handleCreateUploadsNewData,
    handleCreateUploadsUpdateData,
    handleNormalizeTranscriptStartTimes,
    projectExportPresets,
    projectTextDefaultSettings,
    transformMediaFilesToProjectVideos,
} from 'utils/projects';
import { checkIsTranslateInProgress } from 'utils/statuses';
import { getVideosWithInProgressStatus, transformFilesToMediaFiles , getVideosWithNotAddedSubtitles } from 'utils/mediaFiles';
import {
    addFloatNumbers,
    calculatePriceInCreditsForDubbingOperation,
    parseFloatNumber,
    toFixedWithoutRounding,
} from 'utils/calc';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { getLocalVideosPreviewImages } from 'utils/localMediaFile';
import { getImageMeta } from 'utils/image';
import { getAnalysisOperationPrice } from 'utils/analysis';
import { secondsToFrameNumber } from 'utils/timeline';
import { splitSentenceBySpecifiedTime, transformSentencesToRawSentence } from 'utils/transcript';

import MediaClient from 'services/api/media';
import UserClient from 'services/api/user';
import TextToSpeechClient from 'services/api/textToSpeech';

import { Folder } from 'interfaces/folder';

import { transformAudioTrack, transformGeneralTrack } from 'utils/mediaInfoHelpers';
import { Workspace } from 'interfaces/workspaces';
import uniqBy from 'lodash.uniqby';

import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import isEmpty from 'lodash.isempty';
import * as ProjectsActions from './actions';

import { TransformedSentence } from '../metadata';

import {
    AddTempMediaFilePreviewAction,
    AddVideosFromLibraryToProjectAction,
    ChangeAudioLanguageAction,
    ChangeEnhancedAudioAction,
    ChangeTextAnimationAction,
    CreateDefaultProjectAction,
    CreateProjectAction,
    CreateProjectElementAction,
    CreateProjectFromUploadAction,
    CreateProjectTextAction,
    CreateProjectUploadAction,
    CreateProjectWithStockVideoAction,
    DeleteProjectAction,
    DeleteProjectDubbingLanguageAction,
    DeleteProjectUploadAction,
    DetouchAudioFromVideoAction,
    DuplicateProjectAction,
    GenerateCleanAudioAction,
    GenerateProjectAction,
    GenerateTextToSpeechAction,
    GetMediaAudioTracksOptionsAction,
    GetProjectDetailsAction,
    GetProjectsAction,
    GetPublishedTemplatesAction,
    GetSubmittedTemplatesAction,
    GetUsableTemplatesAction,
    GoByProjectHistoryAction,
    ProjectsActionTypes,
    ProjectVideoLanguages,
    PublishTemplateAction,
    ReviewTemplateAction,
    SubmitTemplatesAction,
    UpdateProjectAction,
    UpdateProjectAudioAction,
    UpdateProjectAudiosAction,
    UpdateProjectElementAction,
    UpdateProjectImageAction,
    UpdateProjectPreferencesAction,
    UpdateProjectTemplateAction,
    UpdateProjectTextAction,
    UpdateProjectUploadAction,
    UpdateProjectVideoAction,
    UpdateProjectVideosAction,
    UseTemplateInProjectAction,
    GenerateProjectAudioDubbingAction,
    ChangeAudioFadeAction,
    ChangeAudioVolumeAction,
} from './types';
import {
    getMedias,
    getMediasWithCleanAudioGeneration,
    getProjectAudioMediaSources,
    getProjectAudiosList,
    getProjectDetailsInfo, getProjectFilters,
    getProjectMediaSources,
    getProjectsCount,
    getProjectsIdsForDelete,
    getProjectsList,
    getProjectsSort,
    getProjectsTotal,
    getProjectTempMediaSources,
    getProjectTempMediaSourcesPreviews,
    getProjectTransitions,
    getProjectUpdateLoading,
    getProjectUpdatesHistory,
    getProjectUploadsMedia,
    getProjectUploadsSuccess,
    getProjectVideos,
    getPublishedTemplatesCount,
    getPublishedTemplatesList,
    getSubmittedTemplatesCount,
    getSubmittedTemplatesList,
    getUsableTemplatesCount,
    getUsableTemplatesList,
    getProjectAudiosIdsWithDubbingGeneration,
    getProjectUnsyncedSubtitlesList,
    getProjectMediaSourceSettings,
} from './selectors';

import {
    CurrentSubscription,
    getCurrentCredits,
    getCurrentDubbingCredits,
    getCurrentUserPlan,
    getUserUsageData,
    handleDubbingPaymentLimitation,
    PlanNameId,
    UserUsageAndTotalSubscriptionData,
} from '../payment';
import { getProjectPreferences, getUserId, getUserInfo, User } from '../user';
import { ModalEvent, ModalType, ModalUploadType } from '../modal';
import {
    changeProjectVideoLanguage,
    GenerateProjectTranslationForDubbingAction,
    getProjectVideosIdsWithTranscriptGeneration,
    getProjectVideosIdsWithTranslateGeneration,
    getProjectVideosLanguages,
    ProjectSubtitlesActionTypes,
    handleGetProjectVideosLanguages,
    getProjectSubtitlesList,
    getProjectDeletedUnsyncedSubtitlesList,
    getProjectAllCurrentSubtitlesList,
    handleDeleteSelectedSubtitles,
} from '../projectSubtitles';
import {
    getProjectPlaying,
    getProjectTimelineTime,
    getTimelineSelectedAudios,
    getTimelineSelectedAudiosWithSubtitles,
    getTimelineSelectedElements,
    getTimelineSelectedImages,
    getTimelineSelectedObjects,
    getTimelineSelectedSentences,
    getTimelineSelectedText,
    getTimelineSelectedVideos,
    hadlePauseCurrentVideo,
    SelectedProjectObject,
    SelectedProjectObjectType,
} from '../projectsStudio';
import { getCurrentFolderInfo } from '../folders';
import { getCurrentWorkspaceInfo } from '../workspaces';
import { ReanalysisReqData } from '../analysis';
import { RekognitionCategory } from '../../../interfaces/analysis';
import AnalysisClient from '../../../services/api/analysis';
import { translateLanguagesArray } from '../../../utils/language';
import StockMediaClient from '../../../services/api/stockMedia';
import { reportErrorToSentry } from '../../../utils/errorTracking';
import { DeletedSentence } from '../../../interfaces/transcript';

export function* handleGetProjects(action: GetProjectsAction): Generator {
    yield put(ProjectsActions.getProjectsStart());

    const isLoadMore = action?.payload?.isLoadMore;
    const folderId = action?.payload?.folderId;
    const isTemplate = !!action?.payload?.isTemplate;

    try {
        const oldProjects = (yield select(getProjectsList)) as Project[];
        const projectsCount = (yield select(getProjectsCount)) as number;
        const sort = (yield select(getProjectsSort)) as ProjectSort;

        const currentFolder = (yield select(getCurrentFolderInfo)) as Folder;

        const res = (yield call(ProjectsClient.getProjects, {
            offset: isLoadMore ? projectsCount : 0,
            sort,
            folder: folderId || currentFolder?.id || '',
            isTemplate: !!isTemplate,
        })) as AxiosResponse;

        let projects = res.data.content;
        const projectsTotal = res.data._metadata.totalCount;

        if (isLoadMore) {
            projects = [...oldProjects, ...projects];
        }

        yield put(
            ProjectsActions.getProjectsSuccess({
                projects,
                projectsTotal,
            })
        );

        yield delay(30000);
        yield put(ProjectsActions.syncProjectsList());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.getProjectsFail());
    }
}

export function* handleSyncProjectDetails(): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    try {
        if (projectDetails) {
            const res = (yield call(
                ProjectsClient.getProjectById,
                projectDetails.id
            )) as AxiosResponse;

            const historyRes = (yield call(
                ProjectsClient.getProjectByIdHistory,
                projectDetails.id
            )) as AxiosResponse;

            const updatedProjectDetails = res.data.content as Project;
            const lastGeneration = historyRes.data.content;

            yield put(
                ProjectsActions.setProjectLastGeneration(
                    Object.keys(lastGeneration)?.length > 1
                        ? lastGeneration
                        : null
                )
            );
            yield put(ProjectsActions.setProjectDetail(updatedProjectDetails));

            if (
                updatedProjectDetails.status &&
                (updatedProjectDetails.status === ProjectStatuses.PREPARING ||
                    updatedProjectDetails.status ===
                        ProjectStatuses.IN_PROGRESS ||
                    updatedProjectDetails.status ===
                        ProjectStatuses.WAITING_FOR_TRANSCODING)
            ) {
                yield delay(15000);

                yield put(ProjectsActions.syncProjectDetails());
            }
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* checkMediaSourcesSettings(): Generator {
    try {
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const mediaSourcesLanguages = (yield select(
            getProjectVideosLanguages
        )) as ProjectVideoLanguages[];

        const mediaSourcesWithoutSelectedLanguage =
            projectDetails.mediaSourcesSettings.filter(
                (settings) => settings.language === ''
            );

        if (mediaSourcesWithoutSelectedLanguage?.length) {
            const updatedMediaSourcesSettings =
                projectDetails.mediaSourcesSettings.map((settings) => {
                    const mediaSourceLanguage = mediaSourcesLanguages.find(
                        (item) => item.videoId === settings.mediaSourceId
                    );

                    if (mediaSourceLanguage?.languagesList?.length) {
                        return {
                            ...settings,
                            language: mediaSourceLanguage.languagesList[0],
                        };
                    }

                    return settings;
                });

            if (
                !isEqual(
                    projectDetails.mediaSourcesSettings,
                    updatedMediaSourcesSettings
                )
            ) {
                const updatedProjectDetails = {
                    ...projectDetails,
                    mediaSourcesSettings: updatedMediaSourcesSettings,
                } as Project;

                yield put(
                    ProjectsActions.updateProject({
                        project: updatedProjectDetails,
                        skipVersionUpdate: true,
                        getSubtitles: true
                    })
                );

                return updatedProjectDetails;
            }
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* checkProjectVideosWithoutMediaSource(): Generator {
    try {
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const mediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const tempMediaSources = (yield select(
            getProjectTempMediaSources
        )) as TempMediaFile[];

        const mediaSourcesIds = [...mediaSources, ...tempMediaSources].map(
            (media) => media.id
        );

        const filteredVideos = projectDetails.videos.filter(
            (video) =>
                mediaSourcesIds.includes(video.mediaSourcesId) ||
                video.isStockMedia
        );

        yield put(ProjectsActions.setProjectVideos(filteredVideos));
    } catch (error) {
        console.log({ error });
    }
}

function* getAudioMediaSources(): Generator {
    try {
        const mediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const responses = (yield all(
            mediaSources.map((mediaSource) =>
                call(VideosClient.getVideoAudioTracks, mediaSource.id)
            )
        )) as AxiosResponse[];

        let videoAudioFiles = [] as ProjectAudioMediaSource[];
        const audioDubbingOptions = {} as ProjectMediaAudioOptions;

        responses.forEach((item: AxiosResponse) => {
            // const resContent = item.data.content[0];
            const resContent = item.data.content;
            const mediaId = item.data?.content[0]?.videoId;

            if (resContent?.length) {
                audioDubbingOptions[mediaId] = resContent;
                videoAudioFiles = [...videoAudioFiles, ...resContent]
                // videoAudioFiles.push(resContent);
            }
        });

        const audioFilesIds = videoAudioFiles.map((audio) => audio.id);

        const audioMediaSourcesIdsArr = projectDetails?.audioMediaSorces || [];

        const filteredMediaSourcesIds = audioMediaSourcesIdsArr.filter(
            (id) => !audioFilesIds.includes(id)
        );

        const mediaSourcesIdsString = filteredMediaSourcesIds.join(',');

        const audioFiles = [];

        if (mediaSourcesIdsString?.length) {
            const audioMediaSourcesRes = (yield call(
                MediaClient.getAudioMediaSourceByIds,
                mediaSourcesIdsString
            )) as AxiosResponse;

            audioFiles.push(...audioMediaSourcesRes.data.content);
        }

        yield put(
            ProjectsActions.setProjectAudioMediaSources([
                ...videoAudioFiles,
                ...audioFiles,
            ])
        );

        if(!isEmpty(videoAudioFiles)) {
            yield put(ProjectsActions.setProjectAudioOptions(
                audioDubbingOptions
            ));
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* syncProjectMedias(
    skipVersionUpdate = false,
    isInitProject?: boolean,
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const oldProjectMediaSources = (yield select(
        getProjectMediaSources
    )) as MediaFile[];
    const projectTempMediaSources = (yield select(
        getProjectTempMediaSources
    )) as TempMediaFile[];
    const projectUploads = (yield select(
        getProjectUploadsMedia
    )) as ProjectUpload[];
    const isGetProjectUploadsSuccess = (yield select(
        getProjectUploadsSuccess
    )) as boolean;

    if (projectDetails) {
        try {
            const videosIds = projectDetails.mediaSources;

            if (videosIds?.length) {
                const videosIdsString = videosIds.join();

                const projectVideoMediaSourcesRes = (yield call(
                    VideosClient.getVideosByIds,
                    videosIdsString
                )) as AxiosResponse;

                const projectVideoMediaSources = projectVideoMediaSourcesRes
                    .data.content as MediaFile[];

                if(isGetProjectUploadsSuccess) {
                    const changedUploads = handleCreateUploadsUpdateData(
                        projectUploads,
                        projectVideoMediaSources
                    );

                    if(changedUploads?.length) {
                        yield put(ProjectsActions.updateProjectUpload({
                            uploadsData: changedUploads,
                        }))
                    }
                }

                const transcodedMediaSources = projectVideoMediaSources.filter(
                    (video) =>
                        video.status?.transcoding?.status ===
                        StatusType.SUCCEEDED
                );

                const transcodedMediaSourcesIds = transcodedMediaSources.map(
                    (video) => video.id
                );

                const filteredTempMediaSources = projectTempMediaSources.filter(
                    (video) => !transcodedMediaSourcesIds.includes(video.id)
                );

                const tempMediaSourceToRemove = projectTempMediaSources.filter(
                    (video) => transcodedMediaSourcesIds.includes(video.id)
                );

                const isPlaying = yield select(getProjectPlaying);

                if (tempMediaSourceToRemove?.length && isPlaying) {
                    yield put(ProjectsStudioActions.setPojectPlaying(false));
                }

                yield put(
                    ProjectsActions.setProjectMediaSources(
                        projectVideoMediaSources
                    )
                );

                yield put(
                    ProjectsActions.setProjectTempMediaSources(
                        filteredTempMediaSources
                    )
                );

                const videosWithNotAddedSubtitles =
                    getVideosWithNotAddedSubtitles(
                        projectVideoMediaSources
                    ) as MediaFile[];

                if(videosWithNotAddedSubtitles.length) {
                    yield put(subtitlesActions.addGeneratedSubtitles({
                        videos: videosWithNotAddedSubtitles,
                        projectDetails
                    }));
                }

                yield call(getAudioMediaSources);
                yield call(handleGetProjectVideosLanguages, isInitProject);

                const videosWithInProgressStatus =
                    getVideosWithInProgressStatus(
                        projectVideoMediaSources
                    ) as MediaFile[];

                yield delay(3000);

                if (
                    videosWithInProgressStatus?.length ||
                    projectTempMediaSources?.length
                ) {
                    yield put(ProjectsActions.syncProjectMediaSources());
                }

                // stop sync and fetch transcript

                const oldVideosWithInProgressStatus =
                    getVideosWithInProgressStatus(
                        oldProjectMediaSources
                    ) as MediaFile[];

                if (oldVideosWithInProgressStatus?.length) {
                    const oldMediaSourcesWithTranslateInProgressIds =
                        oldVideosWithInProgressStatus
                            .filter((mediaSource) =>
                                checkIsTranslateInProgress(
                                    mediaSource.status.analyze
                                )
                            )
                            .map((mediaSource) => mediaSource.id);
                    const updatedMediaSourcesWithTranslateInProgressIds =
                        projectVideoMediaSources
                            .filter((mediaSource) =>
                                checkIsTranslateInProgress(
                                    mediaSource.status.analyze
                                )
                            )
                            .map((mediaSource) => mediaSource.id);

                    if (
                        oldMediaSourcesWithTranslateInProgressIds?.length > 0 &&
                        updatedMediaSourcesWithTranslateInProgressIds?.length !==
                            oldMediaSourcesWithTranslateInProgressIds?.length
                    ) {
                        const res = (yield call(
                            ProjectsClient.getProjectById,
                            projectDetails.id
                        )) as AxiosResponse;

                        const updatedProjectDetails = res.data
                            .content as Project;

                        yield put(
                            ProjectsActions.updateProject({
                                project: updatedProjectDetails,
                                skipVersionUpdate,
                            })
                        );
                    }

                    yield call(checkMediaSourcesSettings);

                    const currentProjectDetails = (yield select(
                        getProjectDetailsInfo
                    )) as Project;

                    console.log('requesting subs: 3')
                    // console.log(currentProjectDetails, 'currentProjectDetails')
                    if(currentProjectDetails?.sentences) {
                        yield put(
                            subtitlesActions.getProjectAllSubtitlesList({
                                projectDetails: currentProjectDetails
                            })
                        );
                    }
                }
            }

            yield call(getAudioMediaSources);
            // if (projectDetails.audioMediaSorces)
            if (projectDetails.subtitlesNotAttached) {
                yield call(handleGetProjectVideosLanguages);
                if (projectDetails.latestAnalyzeJobId) {
                    const projectAnalyzeStatus: any = yield call(
                        ProjectsClient.getProjectAnalyzeStatus,
                        {
                            projectId: projectDetails.id,
                            data: {
                                stepFunctionsExecutionArn:
                                    projectDetails.latestAnalyzeJobId,
                            },
                        }
                    );
                    if(projectAnalyzeStatus?.data?.languages && projectAnalyzeStatus?.data?.status === 'SUCCEEDED') {
                        if(projectDetails.lastExecutionArn !== projectDetails?.latestAnalyzeJobId) {
                            yield put(subtitlesActions.addGeneratedSubtitles({
                                languages: projectAnalyzeStatus?.data?.languages?.split(','),
                                projectDetails
                            }));

                            yield take(ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_SUCCESS);
                        }
                    }

                    if (projectAnalyzeStatus?.data?.status === 'RUNNING') {
                        yield put(ProjectsActions.syncProjectMediaSources());
                        yield put(
                            subtitlesActions.setProjectSubtitlesTranslateLoading(
                                true
                            )
                        );
                    } else {
                        yield put(
                            subtitlesActions.setProjectSubtitlesTranslateLoading(
                                false
                            )
                        );
                        const res = (yield call(
                            ProjectsClient.getProjectById,
                            projectDetails.id
                        )) as AxiosResponse;

                        const updatedProjectDetails = res.data
                            .content as Project;

                        yield put(
                            ProjectsActions.updateProject({
                                project: updatedProjectDetails,
                                getSubtitles: true,
                            })
                        );
                    }
                } else {
                    yield put(
                        subtitlesActions.setProjectSubtitlesTranslateLoading(
                            false
                        )
                    );
                }
            }
        } catch (error) {
            yield put(ProjectsActions.syncProjectMediaSources());
            console.log({ error });
        }
    }
}

type SafeCallResult<T> =
    | { status: 'fulfilled'; value: T }
    | { status: 'rejected'; reason: any };

function* safeCall<T>(fn: (...args: any[]) => Promise<T>, ...args: any[]): Generator<any, SafeCallResult<T>, T> {
    try {
        const result = yield call(fn, ...args);
        return { status: 'fulfilled', value: result };
    } catch (error) {
        return { status: 'rejected', reason: error };
    }
}

export function* handleInitProject(action: GetProjectDetailsAction): Generator {
    yield put(ProjectsActions.getProjectDetailsStart());
    let attemptsCount = 5;

    function* handleGetProject(): Generator {
        try {
            const currentPlan = (yield select(
                getCurrentUserPlan
            )) as CurrentSubscription;

            const projectId = action.payload;

            const res = (yield call(
                ProjectsClient.getProjectById,
                projectId
            )) as AxiosResponse;

            const historyRes = (yield call(
                ProjectsClient.getProjectByIdHistory,
                projectId
            )) as AxiosResponse;

            const lastGeneration = historyRes.data.content;

            let projectDetails = res.data.content as Project;

            const videos = projectDetails?.videos;
            const stockVideos = videos?.filter(item => item?.isStockMedia);
            let newStockMediaUrls = {} as any;
            let modifiedVideos = [...videos];

            if (stockVideos?.length) {
                if(currentPlan?.planNameId !== PlanNameId.INITIAL) {
                    const fixedSize = 'hd15';

                    const downloadRequests = stockVideos.map((media) =>
                        safeCall(StockMediaClient.downloadStockVideo, media.mediaSourcesId, fixedSize)
                    );

                    const downloadResults: SafeCallResult<any>[] = (yield all(downloadRequests)) as SafeCallResult<any>[];

                    newStockMediaUrls = downloadResults.reduce((acc: { [key: string]: string }, result, index) => {
                        if (result.status === 'fulfilled') {
                            const media = stockVideos[index] as ProjectVideo;
                            return { ...acc, [media.id]: result.value.data.uri };
                        }
                        return acc;
                    }, {});

                    modifiedVideos = videos.map(video => {
                        if (video?.isStockMedia && newStockMediaUrls[video.id]) {
                            return {
                                ...video,
                                stockMediaUrl: newStockMediaUrls[video.id],
                            };
                        }
                        return video;
                    });
                } else {
                    const mediaRequests = stockVideos.map((media) =>
                        call(StockMediaClient.getStockVideoSizes, media.mediaSourcesId)
                    );


                    try {
                        const results = (yield all(mediaRequests)) as any[];

                        newStockMediaUrls = results?.reduce((acc, res, index) => {
                            const media = stockVideos[index] as ProjectVideo;
                            return { ...acc, [media.id]: res?.data?.display_sizes?.[0]?.uri};
                        }, {});
                    } catch (error) {
                        console.error('Error downloading media:', error);
                    }

                    modifiedVideos = videos.map(
                        video => {
                            if(video?.isStockMedia && newStockMediaUrls[video.id]) {
                                return {
                                    ...video,
                                    stockMediaUrl: newStockMediaUrls?.[video.id] || video?.stockPreviewUrl || video?.stockMediaUrl,
                                }
                            }
                            return video;
                        }
                    );
                }
            }

            if (projectDetails?.version) {
                yield put(
                    ProjectsActions.getProjectDetailsSuccess({
                        ...projectDetails,
                        settings: {
                            ...projectDetails.settings,
                            subtitles: {
                                ...projectDetails.settings?.subtitles,
                            },
                        },
                        videos: modifiedVideos,
                    })
                );

                yield put(
                    ProjectsActions.setProjectLastGeneration(
                        Object.keys(lastGeneration)?.length > 1
                            ? lastGeneration
                            : null
                    )
                );

                yield put(
                    ProjectsActions.getProjectUploads()
                );

                /// call handleGetProjectVideosLanguages after sync
                yield call(syncProjectMedias, false, true);

                yield call(checkProjectVideosWithoutMediaSource);

                // yield call(handleGetProjectVideosLanguages);
                yield put(
                    ProjectsActions.setProjectAudios(projectDetails.audios)
                );
                yield put(
                    ProjectsActions.setProjectVideosTransitions(
                        projectDetails.transitions || []
                    )
                );
                yield put(
                    ProjectsActions.setProjectVideosFilters(
                        projectDetails.filters || []
                    )
                );

                /// check if media sources have empty language value
                yield call(checkMediaSourcesSettings);

                ///  check language after translations
                console.log('requesting subs: 4');
                // console.log(projectDetails, 'projectDetails')
                yield put(
                    subtitlesActions.getProjectAllSubtitlesList({ projectDetails })
                );

                yield put(ProjectsActions.getElementsList());

                yield put(ProjectsActions.getProjectLatestVersionEnd());

                // stock media
                yield put(StockMediaActions.getCategorizedStockVideos());

                yield put(StockMediaActions.getStockMediaCollections());
                yield put(StockMediaActions.getStockVideos());
                yield put(StockMediaActions.getStockVideosThumbnailPreviews());

                // /// check if media sources have empty language value

                const isProjectGenerationInProgress =
                    projectDetails.status &&
                    (projectDetails.status === ProjectStatuses.PREPARING ||
                        projectDetails.status === ProjectStatuses.IN_PROGRESS);

                if (isProjectGenerationInProgress) {
                    yield delay(15000);

                    yield put(ProjectsActions.syncProjectDetails());
                }
            } else {
                attemptsCount--;
                yield delay(3000);

                yield handleGetProject();
            }
        } catch (error) {
            attemptsCount--;
            console.log({ error });
            if (error?.response?.status === 404) {
                yield put(ProjectsActions.getProjectDetailsFail());
            } else {
                if (attemptsCount < 0) {
                    yield put(ProjectsActions.getProjectDetailsFail());
                } else {
                    yield delay(1000);
                    yield handleGetProject();
                }
            }
        }
    }

    yield handleGetProject();
}

export function* handleDeleteProject(action: DeleteProjectAction): Generator {
    yield put(ProjectsActions.deleteProjectStart());

    const projectId = action.payload;

    const projectsIdsForDelete = (yield select(
        getProjectsIdsForDelete
    )) as Array<string>;

    const projectsList = (yield select(getProjectsList)) as Project[];
    const projectsTotal = (yield select(getProjectsTotal)) as number;

    yield put(
        ProjectsActions.setProjectsIdsForDelete([
            ...projectsIdsForDelete,
            projectId,
        ])
    );

    try {
        (yield call(ProjectsClient.deleteProject, projectId)) as AxiosResponse;

        const filteredProjectsIdsForDelete = without(
            projectsIdsForDelete,
            projectId
        );

        const filteredProjects = projectsList.filter(
            (project: Project) => project.id !== projectId
        );

        yield put(ProjectsActions.setProjects(filteredProjects));
        yield put(
            ProjectsActions.getProjectsSuccess({
                projects: filteredProjects,
                projectsTotal: projectsTotal - 1,
            })
        );

        yield put(
            ProjectsActions.setProjectsIdsForDelete(
                filteredProjectsIdsForDelete
            )
        );
        yield put(ProjectsActions.deleteProjectSuccess());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.deleteProjectFail());
    }
}

export function* handleCreateProject(action: CreateProjectAction): Generator {
    yield put(ProjectsActions.createProjectStart());

    let projectDetails = action.payload;

    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;
    const preferences = (yield select(
        getProjectPreferences
    )) as ProjectPreferences;

    if (preferences) {
        projectDetails = {
            ...projectDetails,
            settings: {
                ...projectDetails.settings,
                subtitles: preferences.subtitlesSettings,
            },
        };
    }

    try {
        (yield call(ProjectsClient.createProject, {
            ...projectDetails,
            isSelected: true,
        })) as AxiosResponse;

        yield delay(2500);

        yield put(ProjectsActions.getProjects({}));
        yield put(ProjectsActions.createProjectSuccess());
        yield put(ModalsActions.hideModal());

        Analytics.record({
            name: 'create_project',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });

        googleAnalytics.createProject();
        amplitudeAnalytics.projectCreated();
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.createProjectFail());
    }
}

export function* prepareProjectUpdate(
    project: Project,
    skipVersionUpdate?: boolean,
): Generator {
    const projectSubtitlesList = (yield select(getProjectAllCurrentSubtitlesList)) as TransformedSentence[];

    let version = project.version as string | number;

    let updatedDuration = project.duration || 1;

    if (project.isProjectDurationAutomatic) {
        updatedDuration = (yield call(
            handleCheckProjectDuration,
            project,
            projectSubtitlesList,
        )) as number;
    }

    if (!skipVersionUpdate) {
        version =
            Math.round((parseFloat(`${version}`) + parseFloat('0.1')) * 100) /
            100;
    }

    let name = project.name;

    if (
        !name?.length ||
        name === 'Untitled projects' ||
        name === 'Untitled project'
    ) {
        if (project?.videos?.length) {
            name = project.videos[0].name;
        } else if (project?.audios?.length) {
            name = project.audios[0].name;
        }
    }

    const updatedProjectDetails = {
        ...project,
        duration: updatedDuration,
        isSelected: true,
        version: `${version}`,
        isSubtitling: project.isSubtitling || false,
        settings: {
            subtitles: project?.settings?.subtitles
                ? project.settings.subtitles
                : defaultProjectSubtitlesSettings,
            video: project.settings.video
                ? project.settings.video
                : {
                      resolution: projectExportPresets[1].resolution,
                      quality: projectExportPresets[1].quality,
                      preset: projectExportPresets[1].preset,
                      fps: projectExportPresets[1].fps,
                  },
            mediaSubtitlesSettings:
                project?.settings?.mediaSubtitlesSettings || []
        },
        status: ProjectStatuses.DRAFT,
        name,
    } as Project;

    return updatedProjectDetails;
}

export function* handleUpdateProject(
    action: UpdateProjectAction,
    channel: any
): Generator {
    let attemptsCount = 5;

    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;
    const {
        project,
        skipVersionUpdate,
        isUseTemplate,
        getSubtitles,
        isVersionChange,
        isChangeLanguage,
    } = action.payload;

    function* updatePoject(): Generator {
        yield put(ProjectsActions.updateProjectStart());
        try {
            (yield call(
                ProjectsClient.updateProject,
                project,
                skipVersionUpdate,
            )) as AxiosResponse;

            if(isUseTemplate) {
                yield put(ProjectsActions.getProjectLatestVersionStart());

                yield put(ProjectsActions.getProjectDetails(project.id));
            }

            yield put(ProjectsActions.updateProjectSuccess());

            if(isUseTemplate) {
                googleAnalytics.templatesUsed({
                    template_id: project.id,
                    template_name: project.name,
                    template_creator: project?.username || '',
                });

                amplitudeAnalytics.templatesUsed({
                    template_id: project.id,
                    template_name: project.name,
                    template_creator: project?.username || '',
                });
            }

            if(getSubtitles) {
                console.log('requesting subs: 5');
                // console.log(project, 'project')
                yield put(
                    subtitlesActions.getProjectAllSubtitlesList({
                        projectDetails: project,
                        isVersionChange: isVersionChange || isChangeLanguage
                    })
                );
            }
        } catch (error) {
            attemptsCount--;
            console.log({ error });

            if (attemptsCount < 0) {
                yield flush(channel);

                Analytics.record({
                    name: 'update_project_fail',
                    attributes: {
                        userId,
                        plan: currentPlan?.planNameId,
                    },
                    userId,
                    properties: {
                        error: error?.message || error?.response?.status,
                        version: project?.version,
                    },
                });

                showNotification(
                    NotificationTypes.error,
                    'Project update failed! \nPlease contact our chat support'
                );

                yield put(ProjectsActions.getProjectDetails(project.id));

                yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

                yield put(ProjectsActions.updateProjectFail());
            } else if(error.response.status === 409) {
                showNotification(
                    NotificationTypes.info,
                    'Your project is not the latest version. \nGetting the latest saved one'
                );

                yield put(ProjectsActions.getProjectLatestVersionStart());

                yield put(ProjectsActions.getProjectDetails(project.id));

                yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

                yield put(ProjectsActions.updateProjectFail());
            } else if(error.response.status === 405) {
                showNotification(
                    NotificationTypes.info,
                    'You cannot make changes to a submitted template.'
                );

                yield put(ProjectsActions.getProjectLatestVersionStart());

                yield put(ProjectsActions.getProjectDetails(project.id));

                yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

                yield put(ProjectsActions.updateProjectFail());
            } else {
                yield delay(1500);
                yield updatePoject();
            }
        }
    }

    yield updatePoject();
}

export function* handleSplitText(
    time: number,
    updatedProject: Project,
    hasSelectedItemsUnderThePlayhead: boolean,
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let texts = projectDetails.texts || [];

    const timelineSelectedTextObjects = (yield select(
        getTimelineSelectedText
    )) as SelectedProjectObject[];

    if (
        hasSelectedItemsUnderThePlayhead &&
        !timelineSelectedTextObjects.length
    ) {
        return;
    }

    const timelineSelectedTexts: ProjectText[] =
        timelineSelectedTextObjects.map((item) => item.object as ProjectText);

    const applicableTexts = !hasSelectedItemsUnderThePlayhead
        ? texts
        : texts.filter((text) =>
            timelineSelectedTexts.some((item) => item.id === text.id)
        );

    const affectedTexts = applicableTexts.filter(
        (text) => time >= text.startTime && time <= text.endTime
    );

    const affectedTextsIds = affectedTexts.map((text) => text.id);

    const restTexts = texts.filter(
        (text) => !affectedTextsIds.includes(text.id)
    );
    const updatedTexts = [...restTexts];

    affectedTexts.forEach((text) => {
        const [firstTextPart, secondTextPart] = getSplittedProjectText(
            text,
            time
        );

        const insertIndex = texts.findIndex((item) => item.id === text.id);

        updatedTexts.splice(insertIndex, 0, firstTextPart, secondTextPart);
    });

    updatedProject.texts = updatedTexts;
}

export function* handleSplitImage(
    time: number,
    updatedProject: Project,
    hasSelectedItemsUnderThePlayhead: boolean,
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let images = projectDetails.images || [];
    let elements = projectDetails.elements || [];

    const timelineSelectedElementsObjects = (yield select(
        getTimelineSelectedElements
    )) as SelectedProjectObject[];

    const timelineSelectedImagesObjects = (yield select(
        getTimelineSelectedImages
    )) as SelectedProjectObject[];

    if (
        hasSelectedItemsUnderThePlayhead &&
        (
            !timelineSelectedElementsObjects.length &&
            !timelineSelectedImagesObjects.length
        )
    ) {
        return;
    }

    const timelineSelectedImages = timelineSelectedImagesObjects.map(item => item.object as ProjectImage);
    const timelineSelectedElements = timelineSelectedElementsObjects.map(item => item.object as ProjectElement);

    const applicableImages = !hasSelectedItemsUnderThePlayhead
        ? images
        : images.filter((image) =>
            timelineSelectedImages.some((item) => item.id === image.id)
        );
    const applicableElements = !hasSelectedItemsUnderThePlayhead
        ? elements
        : elements.filter((element) =>
            timelineSelectedElements.some((item) => item.id === element.id)
        );

    const affectedImages = applicableImages.filter(
        (image) => time >= image.startTime && time <= image.endTime
    );
    const affectedElements = applicableElements.filter(
        (element) => time >= element.startTime && time <= element.endTime
    );

    const affectedImagesIds = affectedImages.map((image) => image.id);
    const affectedElementsIds = affectedElements.map((element) => element.id);

    const restImages = images.filter(
        (image) => !affectedImagesIds.includes(image.id)
    );
    const restElements = elements.filter(
        (element) => !affectedElementsIds.includes(element.id)
    );

    const updatedImages = [...restImages];
    const updatedElements = [...restElements];

    affectedImages.forEach((image) => {
        const [firstImage, secondImage] = getSplittedProjectImages(
            image,
            time
        );

        const insertIndex = images.findIndex((item) => item.id === image.id);

        updatedImages.splice(insertIndex, 0, firstImage, secondImage);
    });

    affectedElements.forEach((element) => {
        const [firstElement, secondElement] = getSplittedProjectElements(
            element,
            time
        );

        const insertIndex = elements.findIndex((item) => item.id === element.id);

        updatedElements.splice(insertIndex, 0, firstElement, secondElement);
    });

    updatedProject.images = updatedImages;
    updatedProject.elements = updatedElements;
}

export function* handleSplitMedia(
    time: number,
    updatedProject: Project,
    hasSelectedItemsUnderThePlayhead: boolean,
): Generator {
    const { audios, videos, frameRate, subtitles } = (yield select(
        getMedias
    )) as TimelineMedias;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const timelineSelectedVideos = (yield select(
        getTimelineSelectedVideos
    )) as SelectedProjectObject[];

    const timelineSelectedAudiosWithSubtitles = (yield select(
        getTimelineSelectedAudiosWithSubtitles
    )) as SelectedProjectObject[];

    const selectedVideosAndAudiosWithSubs = [
        ...timelineSelectedVideos,
        ...timelineSelectedAudiosWithSubtitles,
    ];

    if (
        hasSelectedItemsUnderThePlayhead &&
        !selectedVideosAndAudiosWithSubs.length
    ) {
        return;
    }

    const timelineSelectedMedias = selectedVideosAndAudiosWithSubs.map(
        (item) => item.object
    );

    const medias =
        hasSelectedItemsUnderThePlayhead && !timelineSelectedMedias.length
            ? []
            : [...audios, ...videos];

    const applicableMedias = !hasSelectedItemsUnderThePlayhead
        ? medias
        : medias.filter((media) =>
              timelineSelectedMedias.some((item) => item.id === media.id)
          );

    const affectedMedias = applicableMedias.filter(
        (media) => time >= media.startTime && time <= media.endTime
    );

    for (let i = 0; i < affectedMedias.length; i++) {
        const selectedMediaIndex = medias.findIndex(
            (media) => affectedMedias[i].id === media.id
        );

        const selectedMedia = medias[selectedMediaIndex];
        const prevMedia = medias[selectedMediaIndex - 1];
        const nextProjectMedia = medias[selectedMediaIndex + 1];
        const isMediasSeparationZone =
            nextProjectMedia?.startTime === time || prevMedia?.endTime === time;

        if (
            selectedMedia &&
            !isMediasSeparationZone &&
            !selectedMedia.isDummy
        ) {
            const restMediasSources = updatedProject.videos || medias;

            const restMedias = restMediasSources.filter(
                (media) => media.id !== selectedMedia?.id
            );

            const [firstMediaPart, secondMediaPart] = getSplittedProjectVideos(
                selectedMedia,
                time
            );

            const updatedVideos = [
                ...restMedias,
                firstMediaPart,
                secondMediaPart,
            ];

            yield put(ProjectsActions.setProjectVideos(updatedVideos));

            const sentenceTimelineTime =
                (selectedMedia?.trimStart || 0) -
                (selectedMedia?.startTime || 0) +
                time;

            const selectedSentence = subtitles.find(
                (item) =>
                    selectedMedia.mediaSourcesId === item.videoId &&
                    secondsToFrameNumber(+item.startTime, frameRate) <=
                        secondsToFrameNumber(sentenceTimelineTime, frameRate) &&
                    secondsToFrameNumber(sentenceTimelineTime, frameRate) <=
                        secondsToFrameNumber(+item.endTime, frameRate)
            );

            updatedProject.videos = !updatedProject.videos
                ? updatedVideos
                : updatedVideos;

            if (selectedSentence) {
                const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
                const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
                const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

                const splittedSentences = splitSentenceBySpecifiedTime({
                    sentence: selectedSentence,
                    time: sentenceTimelineTime,
                });

                const removableId = selectedSentence.id;

                let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
                let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
                let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

                updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                    item => item.id !== removableId
                );
                updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                    item => item.id !== removableId
                );
                updatedDeletedUnsyncedSubtitlesIds = [
                    ...updatedDeletedUnsyncedSubtitlesIds,
                    removableId,
                ];

                updatedUnsyncedSubtitlesList = [
                    ...updatedUnsyncedSubtitlesList,
                    ...splittedSentences,
                ];

                yield put(subtitlesActions.setProjectAllSubtitlesListAction({
                    subtitles: updatedCurrentSubtitlesList,
                }));
                yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
                    subtitles: updatedUnsyncedSubtitlesList
                }));
                yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

                // ////////////////////
                const transformedNewSentences = transformSentencesToRawSentence(
                    splittedSentences,
                    projectDetails.id
                );
                const filteredSentences = projectDetails.sentences?.filter(item =>
                    item !== removableId
                );
                const updatedSentencesIds = transformedNewSentences.map(item => item.id);

                updatedProject.sentences = [
                    ...(filteredSentences || []),
                    ...updatedSentencesIds,
                ];

                yield put(subtitlesActions.addOrEditSubtitle({
                    projectDetails,
                    subtitles: transformedNewSentences,
                    subtitleIds: [removableId],
                }));
            }
        }
    }
}

export function* handleSplitAudio(
    time: number,
    updatedProject: Project,
    hasSelectedItemsUnderThePlayhead: boolean,
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let audios = projectDetails.audios || [];

    const timelineSelectedAudioObjects = (yield select(
        getTimelineSelectedAudios
    )) as SelectedProjectObject[];

    if (
        hasSelectedItemsUnderThePlayhead &&
        !timelineSelectedAudioObjects.length
    ) {
        return;
    }

    const timelineSelectedAudios: ProjectAudio[] =
        timelineSelectedAudioObjects.map((item) => item.object as ProjectAudio);

    const applicableAudios = !hasSelectedItemsUnderThePlayhead
        ? audios
        : audios.filter((audio) =>
              timelineSelectedAudios.some((item) => item.id === audio.id)
          );

    const affectedAudios = applicableAudios.filter(
        (audio) => time >= audio.startTime && time <= audio.endTime
    );

    const affectedAudiosIds = affectedAudios.map((audio) => audio.id);

    const restAudios = audios.filter(
        (audio) => !affectedAudiosIds.includes(audio.id)
    );
    const updatedAudios = [...restAudios];

    affectedAudios.forEach((audio) => {
        const [firstAudioPart, secondAudioPart] = getSplittedProjectAudios(
            audio,
            time
        );

        const insertIndex = audios.findIndex((item) => item.id === audio.id);

        updatedAudios.splice(insertIndex, 0, firstAudioPart, secondAudioPart);
    });

    updatedProject.audios = updatedAudios;

    yield put(ProjectsActions.setProjectAudios(updatedAudios));
}

export function* handleSplitMediaItems(time: number): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const timelineSelectedObjects = (yield select(
        getTimelineSelectedObjects
    )) as SelectedProjectObject[];

    const hasSelectedItemsUnderThePlayhead = timelineSelectedObjects.some(
        (item) =>
            ('startTime' in item.object && 'endTime' in item.object) &&
            (time >= item.object.startTime && time <= item?.object.endTime)
    );

    let updatedProject = { ...projectDetails } as Project;

    yield call(handleSplitMedia, time, updatedProject, hasSelectedItemsUnderThePlayhead);
    yield call(handleSplitAudio, time, updatedProject, hasSelectedItemsUnderThePlayhead);
    yield call(handleSplitImage, time, updatedProject, hasSelectedItemsUnderThePlayhead);
    yield call(handleSplitText, time, updatedProject, hasSelectedItemsUnderThePlayhead);

    yield put(
        ProjectsActions.updateProject({
            project: updatedProject,
        })
    );
}

export function checkCurrentProjectAssets(project: Project) {
    const { videos, images, audios } = project;

    const uniqVideos = uniqBy(videos, 'mediaSourcesId');
    const stockMediaVideos = uniqVideos.filter((item) => item.isStockMedia);

    const stockImages = images.filter((image) => image.isStockMedia);

    const stockAudio = audios.filter((audio) => audio.isStockMedia);
    const uniqAudios = uniqBy(stockAudio, 'songId');

    const totalAssets =
        stockMediaVideos?.length + stockImages?.length + uniqAudios?.length;

    return totalAssets;
}

export function* handleStockMediaLimitations(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan) {
        // if (currentUserPlan.planNameId !== PlanNameId.INITIAL) {
        //     return yield put(
        //         ModalsActions.showModal(
        //             ModalType.ADD_MORE_CREDITS,
        //             ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT
        //         )
        //     );
        // }

        return yield put(
            ModalsActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT
            )
        );
    }
}

function* checkTranscriptValidity(): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
    const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
    const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

    const projectTranscriptList = [...projectCurrentSubtitles, ...projectUnsyncedSubtitlesList];

    const projectTranscriptHasNegative = projectTranscriptList?.some(
        obj => (+obj.startTime < 0 || obj?.items?.some(
            item => +item.startTime < 0
        ))
    );

    if(projectTranscriptHasNegative) {
        const {
            updatedNegativeTranscripts,
            negativeSentencesIds,
        } = handleNormalizeTranscriptStartTimes(projectTranscriptList);

        if(updatedNegativeTranscripts.length) {
            let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
            let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
            let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

            updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                item => !negativeSentencesIds.some(id => id === item.id)
            );
            updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                item => !negativeSentencesIds.some(id => id === item.id)
            );
            updatedDeletedUnsyncedSubtitlesIds = [
                ...updatedDeletedUnsyncedSubtitlesIds,
                ...negativeSentencesIds,
            ];

            updatedUnsyncedSubtitlesList = [
                ...updatedUnsyncedSubtitlesList,
                ...updatedNegativeTranscripts,
            ];

            yield put(subtitlesActions.setProjectAllSubtitlesListAction({
                subtitles: updatedCurrentSubtitlesList,
            }));
            yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
                subtitles: updatedUnsyncedSubtitlesList
            }));
            yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

            // /////////////////
            const newSentences = transformSentencesToRawSentence(
                updatedNegativeTranscripts,
                projectDetails.id
            );
            const filteredSentences = projectDetails.sentences?.filter(
                item => !negativeSentencesIds.some(id => id === item)
            );
            const updatedSentencesIds = updatedNegativeTranscripts.map(item => item.id);

            const updatedProject = {
                ...projectDetails,
                sentences: [
                    ...(filteredSentences || []),
                    ...updatedSentencesIds,
                ]
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                })
            );

            yield put(subtitlesActions.addOrEditSubtitle({
                projectDetails,
                subtitles: newSentences,
                subtitleIds: negativeSentencesIds,
            }));

            yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);
        }
    }
}

export function* handleGenerateProject(
    action: GenerateProjectAction
): Generator {
    try {
        yield call(checkTranscriptValidity);

        const { navigate } = action.payload;

        yield put(ProjectsActions.generateProjectStart());

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const mediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const currentSubscription = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;
        const userId = (yield select(getUserId)) as string;

        let updatedProjectDetails = {
            ...projectDetails,
            isSubtitling: projectDetails.isSubtitling,
            settings: {
                subtitles: projectDetails.settings.subtitles
                    ? {
                          ...projectDetails.settings.subtitles,
                      }
                    : {
                          subtitlesColor: 'black',
                          subtitlesFontSize: 27,
                      },
                video: projectDetails.settings.video,
                mediaSubtitlesSettings: projectDetails.settings?.mediaSubtitlesSettings || [],
            },
            percentage: 0,
        } as Project;

        if (
            projectDetails.aspectRatio === ProjectSize.ORIGINAL &&
            +mediaSources?.[0]?.mediaInfo.proxy?.video?.[0]?.aspectRatio < 1
        ) {
            const exportRes = projectDetails.settings.video.resolution;
            const resItems = exportRes.split(':');
            const exportWidth = resItems[0];
            const exportHeight = resItems[1];

            const updatedRes = `${exportHeight}:${exportWidth}`;

            updatedProjectDetails = {
                ...updatedProjectDetails,
                settings: {
                    ...updatedProjectDetails.settings,
                    video: {
                        ...updatedProjectDetails.settings.video,
                        resolution: updatedRes,
                    },
                },
            };
        }

        if (
            currentSubscription.exportVideoDurationTime &&
            projectDetails.duration >
                +currentSubscription.exportVideoDurationTime / 1000
        ) {
            yield put(ProjectsActions.generateProjectFail());
            yield put(
                ModalsActions.showModal(
                    ModalType.SELECT_PLAN,
                    ModalEvent.PROJECT_EXPORT_LENGTH_OVER_LIMIT
                )
            );
        } else {
            let { version } = updatedProjectDetails;

            version = `${
                Math.round(
                    (parseFloat(`${projectDetails.version}`) +
                        parseFloat('0.1')) *
                        100
                ) / 100
            }`;
            const projectVideosLanguages = (yield select(
                getProjectVideosLanguages
            )) as ProjectVideoLanguages[];

            const mediaSourcesSettings =
                projectDetails.mediaSourcesSettings.map((mediaSource) => {
                    if (mediaSource.language === '') {
                        const languageObj = projectVideosLanguages.find(
                            (videoLanguageObj) =>
                                videoLanguageObj.videoId ===
                                mediaSource.mediaSourceId
                        );

                        return {
                            mediaSourceId: mediaSource.mediaSourceId,
                            language: languageObj?.languagesList[0] || '',
                        };
                    }

                    return mediaSource;
                });

            updatedProjectDetails = {
                ...updatedProjectDetails,
                mediaSourcesSettings,
            };
            yield put(
                ProjectsActions.updateProject({
                    project: updatedProjectDetails,
                })
            );

            yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);

            yield delay(1000);

            const userInfo = (yield select(getUserInfo)) as User;

            const genRes = (yield call(
                ProjectsClient.generateProject,
                updatedProjectDetails.id,
                version,
                userInfo.attributes.email
            )) as AxiosResponse;

            const assetsCount = checkCurrentProjectAssets(
                updatedProjectDetails
            );

            if (genRes.status === 201) {
                Analytics.record({
                    name: 'project_export',
                    attributes: {
                        userId,
                        plan: currentSubscription.planNameId,
                        assetsCount: `${assetsCount}`,
                    },
                });

                googleAnalytics.exportVideo({
                    resolution: `${projectDetails.aspectRatio} ${updatedProjectDetails.settings.video.resolution}`,
                    export_option:
                        updatedProjectDetails.settings.video.settingsPreset,
                });

                amplitudeAnalytics.projectExported({
                    resolution: `${projectDetails.aspectRatio} ${updatedProjectDetails.settings.video.resolution}`,
                    export_option:
                        updatedProjectDetails.settings.video.settingsPreset,
                });

                Analytics.record({
                    name: 'project_exported',
                });

                yield delay(5000);

                const res = (yield call(
                    ProjectsClient.getProjectById,
                    updatedProjectDetails.id
                )) as AxiosResponse;
                const historyRes = (yield call(
                    ProjectsClient.getProjectByIdHistory,
                    projectDetails.id
                )) as AxiosResponse;

                const lastGeneration = historyRes.data.content;
                const updatedProject = res.data.content as Project;

                yield put(ProjectsActions.setProjectDetail(updatedProject));
                yield put(
                    ProjectsActions.setProjectLastGeneration(lastGeneration)
                );

                const projectsList = (yield select(
                    getProjectsList
                )) as Project[];

                const updatedProjectsList = projectsList.map((project) => {
                    if (project.id === updatedProject.id) {
                        return updatedProject;
                    }

                    return project;
                });

                yield put(ProjectsActions.setProjects(updatedProjectsList));

                yield put(ProjectsActions.generateProjectSuccess());

                setTimeout(
                    () => navigate(`/video-editing/${projectDetails.id}/view`), 0);
                // yield put(ProjectsStudioActions.setNavigateToRoute(`/video-editing/${projectDetails.id}/view`));

                yield delay(15000);

                yield put(ProjectsActions.syncProjectDetails());
                yield put(ProjectsActions.syncProjectsList());
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.generateProjectFail());
        showNotification(
            NotificationTypes.error,
            (error as any)?.response?.data?.message || (error as any).message
        );
    }
}

export function* handleSyncProjects(): Generator {
    try {
        const projectsList = (yield select(getProjectsList)) as Project[];

        const projectsInProgress = projectsList.filter(
            (project) =>
                project.copyInProgress ||
                (project.status &&
                    (project.status === ProjectStatuses.IN_PROGRESS ||
                        project.status === ProjectStatuses.PREPARING ||
                        project.status ===
                            ProjectStatuses.WAITING_FOR_TRANSCODING))
        );

        const projectsInProgressIds = projectsInProgress.map(
            (project) => project.id
        );

        const updatedProjectsRes = (yield call(
            ProjectsClient.getProjectsByIds,
            projectsInProgressIds
        )) as AxiosResponse;

        const updatedProjects = updatedProjectsRes.data.content as Project[];

        const projects = projectsList.map((project) => {
            const updatedData = updatedProjects.find(
                (updatedProject) => project.id === updatedProject.id
            );
            if (updatedData) {
                return updatedData;
            }

            return project;
        });

        yield put(ProjectsActions.setProjects(projects));

        const updatedProjectsInProgress = projects.filter(
            (project) =>
                project.copyInProgress ||
                (project.status &&
                    (project.status === ProjectStatuses.IN_PROGRESS ||
                        project.status === ProjectStatuses.PREPARING))
        );

        if (updatedProjectsInProgress?.length) {
            yield delay(15000);

            yield put(ProjectsActions.syncProjectsList());
        }
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.generateProjectFail());
    }
}

export function* checkVideosTransitions(): Generator {
    let videos = (yield select(getProjectVideos)) as ProjectVideo[];
    videos = sortBy(videos, 'startTime');

    const transitions = (yield select(
        getProjectTransitions
    )) as VideoTransition[];

    const lastIndex = videos?.length - 1;

    const filteredTransitions = transitions.filter((transition) => {
        const startTargetVideoIndex = videos.findIndex(
            (item) => item.id === transition.startTarget
        );
        const endTargetVideoIndex = videos.findIndex(
            (item) => item.id === transition.endTarget
        );

        const startTargetVideo = videos[startTargetVideoIndex];
        const endTargetVideo = videos[endTargetVideoIndex];

        if (endTargetVideoIndex === 0 || lastIndex === startTargetVideoIndex) {
            return transition;
        }

        if (
            endTargetVideoIndex - startTargetVideoIndex === 1 &&
            endTargetVideo.startTime - startTargetVideo.endTime <= 1
        ) {
            return transition;
        }
    });

    return filteredTransitions;
}

export function* handleCreateDefaultProject(
    action: CreateDefaultProjectAction
): Generator {
    yield put(ProjectsActions.createProjectStart());

    const {
        navigate,
        folderId,
        isStock,
        isTemplate,
        isFirstLogin
    } = action.payload;

    const currentWorkspace = (yield select(
        getCurrentWorkspaceInfo
    )) as Workspace;
    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;
    const user = (yield select(getUserInfo)) as User;

    try {
        let data = getPreparedNewProjectData(
            [],
            [],
            '',
            currentWorkspace?.id || '',
            true
        );

        const preferences = (yield select(
            getProjectPreferences
        )) as ProjectPreferences;

        if (preferences) {
            data = {
                ...data,
                settings: {
                    ...data.settings,
                    subtitles: preferences.subtitlesSettings,
                },
            };
        }

        const currentFolder = (yield select(getCurrentFolderInfo)) as Folder;

        if (folderId || currentFolder) {
            data.folder = folderId || currentFolder?.id || '';
        }

        const projectData = {
            ...data,
            isSelected: true,
        };

        if(isTemplate) {
            const username = user?.username || '';

            projectData.isTemplate = true;
            projectData.templateStatus = TemplateStatus.DRAFT;
            projectData.username = username;
        }

        const createProjectRes = (yield call(ProjectsClient.createProject, projectData)) as AxiosResponse;

        yield delay(500);

        yield call(
            navigate,
            `/video-editing/${createProjectRes.data.projectId}`
        );

        yield put(ProjectsActions.createProjectSuccess());

        if (!isStock) {
            if(isFirstLogin) {
                yield put(
                    ModalsActions.showModal(
                        ModalType.WELCOME,
                        ModalEvent.WELCOME_INTRO_VIDEO_EDITING,
                    )
                );
            } else {
                yield put(
                    ModalsActions.showModal(
                        ModalType.PROJECT_UPLOAD,
                        ModalEvent.PROJECT_UPLOAD,
                        '',
                        {
                            uploadType: ModalUploadType.DEFAULT,
                        }
                    )
                );
            }
        }
        yield put(ProjectsActions.getProjects({}));

        Analytics.record({
            name: 'create_project',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });

        googleAnalytics.createProject();
        amplitudeAnalytics.projectCreated();
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.createProjectFail());
    }
}

export function* handleCreateProjectFromUpload(
    action: CreateProjectFromUploadAction
): Generator {
    try {
        const { videos, navigate } = action.payload;

        const userInfo = (yield select(getUserInfo)) as User;
        const currentWorkspace = (yield select(
            getCurrentWorkspaceInfo
        )) as Workspace;
        const userId = (yield select(getUserId)) as string;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        const transformedMediaFiles = (yield call(
            transformFilesToMediaFiles,
            videos,
            userInfo
        )) as TempMediaFile[];
        const mediaSourcesIds = transformedMediaFiles.map((item) => item.id);
        const data = getPreparedNewProjectData(
            transformedMediaFiles,
            mediaSourcesIds,
            transformedMediaFiles[0].title,
            currentWorkspace.id || ''
        );

        const createProjectRes = (yield call(ProjectsClient.createProject, {
            ...data,
            isSelected: true,
        })) as AxiosResponse;

        yield delay(2500);

        yield call(
            navigate,
            `/video-editing/${createProjectRes.data.projectId}`
        );

        yield put(ProjectsActions.getProjects({}));
        yield put(ProjectsActions.createProjectSuccess());

        yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

        yield put(
            ProjectsActions.setProjectTempMediaSources(transformedMediaFiles)
        );
        yield put(ProjectsActions.setProjectVideos(data.videos));
        yield put(
            ModalsActions.showModal(
                ModalType.PROJECT_VIDEOS_UPLOADING_STATUS,
                ModalEvent.PROJECT_VIDEOS_UPLOADING_STATUS
            )
        );

        Analytics.record({
            name: 'create_project',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });
    } catch (error) {
        console.log({ error });
    }
}

export function* сheckAnalysisTimeLimitations(
    videos: TempMediaFile[]
): Generator {
    let isAnalysisOperationPriceOverLimit = false;

    const userUsage = (yield select(
        getUserUsageData
    )) as UserUsageAndTotalSubscriptionData;

    if (userUsage) {
        const currentCredits = (yield select(getCurrentCredits)) as number;

        const analysisOperationPrice = getAnalysisOperationPrice(videos);

        isAnalysisOperationPriceOverLimit =
            analysisOperationPrice > currentCredits;
    }

    return isAnalysisOperationPriceOverLimit;
}

export function* uploadingCleanup(): Generator {
    yield put(UploadActions.setUploadingVideos([]));
    yield put(UploadActions.setUploadingStatuses([]));
    yield put(UploadActions.setUploadingMediaDurationItems([]));
}

export function* handleAnalysisLimitations(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan) {
        yield call(uploadingCleanup);

        if (
            currentUserPlan.planNameId === PlanNameId.BASIC ||
            currentUserPlan.planNameId === PlanNameId.PROFESSIONAL
        ) {
            return yield put(
                ModalsActions.showModal(
                    ModalType.ADD_MORE_CREDITS,
                    ModalEvent.ANALYSIS_PRICE_OVER_LIMIT
                )
            );
        }

        return yield put(
            ModalsActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.ANALYSIS_PRICE_OVER_LIMIT
            )
        );
    }
}

export function* createProjectTempVideoPreviewImages(): Generator {
    try {
        const tempMediaFiles = (yield select(
            getProjectTempMediaSources
        )) as TempMediaFile[];

        const filteredMediaFiles = tempMediaFiles.filter(
            (item) => item.mediaType === MediaType.VIDEO
        );

        const previewsTimestamps =
            getMediaSourcesPreviewsTimestamps(filteredMediaFiles);

        const images = (yield all(
            previewsTimestamps.map((preview: any) =>
                getLocalVideosPreviewImages(preview)
            )
        )) as any[];

        if (images?.length > 0) {
            const filteredPreview = images.filter(
                (previewData) => previewData.id
            );

            const transformedPreviews = filteredPreview.map((previewData) => {
                return {
                    images: previewData,
                    id: previewData[0].id,
                };
            });

            yield put(
                ProjectsActions.setProjectTempMediaSourcesPreviews(
                    transformedPreviews
                )
            );
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleStorageLimitations(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan) {
        yield call(uploadingCleanup);

        if (
            currentUserPlan.planNameId === PlanNameId.BASIC ||
            currentUserPlan.planNameId === PlanNameId.PROFESSIONAL
        ) {
            return yield put(
                ModalsActions.showModal(
                    ModalType.MANAGE_STORAGE,
                    ModalEvent.NOT_ENOUGH_STORAGE
                )
            );
        }

        return yield put(
            ModalsActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.NOT_ENOUGH_STORAGE
            )
        );
    }
}

export function* handleAddVideosFromLibraryToProject(
    action: AddVideosFromLibraryToProjectAction
): Generator {
    try {
        const {
            newVideos,
            isFromUploads = false,
            replaceableMedia,
        } = action.payload;
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        let projectVideos = (yield select(getProjectVideos)) as ProjectVideo[];
        let projectAudios = (yield select(
            getProjectAudiosList
        )) as ProjectAudio[];
        let oldProjectFilters = (yield select(
            getProjectFilters
        )) as VideoFilter[];
        let oldProjectTransitions = (yield select(
            getProjectTransitions
        )) as VideoTransition[];

        projectVideos = sortBy(projectVideos, 'startTime');
        projectAudios = sortBy(projectAudios, 'startTime');

        const projectMediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const transformedProjectVideos =
            transformMediaFilesToProjectVideos(
                newVideos,
                replaceableMedia
            );

        let updatedProjectVideos = [...projectVideos];
        const updatedProjectAudios = [...projectAudios];

        let updatedProjectFilters = [...oldProjectFilters];
        let updatedProjectTransitions = [...oldProjectTransitions];

        if(replaceableMedia) {
            updatedProjectVideos = updatedProjectVideos.filter(item => item.id !== replaceableMedia.id);
            updatedProjectFilters = updatedProjectFilters.map(filter => {
                if(filter.videoId === replaceableMedia.id) {
                    return {
                        ...filter,
                        videoId: transformedProjectVideos[0].id,
                    }
                }
                return filter;
            });
            updatedProjectTransitions = updatedProjectTransitions.map(item => {
                if(item.startTarget === replaceableMedia.id) {
                    return {
                        ...item,
                        startTarget: transformedProjectVideos[0].id,
                    }
                } if(item.endTarget === replaceableMedia.id) {
                    return {
                        ...item,
                        endTarget: transformedProjectVideos[0].id,
                    }
                }
                return item;
            });
        }

        const newMediaSourcesIds = newVideos.map(
            (mediaSource) => mediaSource.id
        );

        if (projectVideos?.length > 0 && !replaceableMedia) {
            updatedProjectVideos = adaptAddedVideos(
                projectVideos,
                transformedProjectVideos,
                updatedProjectVideos,
            )
        } else {
            updatedProjectVideos.push(...transformedProjectVideos);
        }

        const mediaSourcesSettings = newVideos.map((video) => ({
            mediaSourceId: video.id || '',
            language: video.defaultLanguage || video.language || '',
        })) as ProjectMediaSourcesSettings[];

        const videosDurationArray = updatedProjectVideos.map(
            (item) => item.trimEnd
        );
        const totalProjectDuration = videosDurationArray.reduce(
            (accumulator: number, currentValue: number): number =>
                addFloatNumbers(accumulator, currentValue, 1000),
            0
        );

        const existingMediaSourceIds = new Set(projectDetails.mediaSourcesSettings.map(mediaSource => mediaSource.mediaSourceId));
        const filteredNewMediaSources = mediaSourcesSettings.filter(mediaSource => !existingMediaSourceIds.has(mediaSource.mediaSourceId));
        const filteredNewMediaSourcesIds = filteredNewMediaSources.map(mediaSource => mediaSource.mediaSourceId);

        const updatedProjectDetails = {
            ...projectDetails,
            isSubtitling: true,
            mediaSources: [
                ...(projectDetails?.mediaSources || []),
                ...filteredNewMediaSourcesIds,
            ],
            videos: updatedProjectVideos,
            duration: totalProjectDuration,
            mediaSourcesSettings: [
                ...projectDetails.mediaSourcesSettings,
                ...filteredNewMediaSources,
            ],
            transitions: updatedProjectTransitions || [],
            filters: updatedProjectFilters || [],
        } as Project;

        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));
        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
        yield put(
            ProjectsActions.setProjectVideosTransitions(
                updatedProjectTransitions || []
            )
        );
        yield put(
            ProjectsActions.setProjectVideosFilters(
                updatedProjectFilters || []
            )
        );

        if(replaceableMedia) {
            yield put(
                ProjectsStudioActions.setSelectedShape({
                    id: transformedProjectVideos[0]?.id,
                })
            );
            yield put(
                ProjectsStudioActions.selectProjectObject(
                    {
                        object: {
                            object: transformedProjectVideos[0],
                            type: replaceableMedia?.isAudio ?
                                SelectedProjectObjectType.AUDIO_WITH_SUBTITLES :
                                SelectedProjectObjectType.VIDEO,
                        },
                    }
                ));
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProjectDetails,
            })
        );
        yield put(
            ProjectsActions.setProjectMediaSources([
                ...projectMediaSources,
                ...newVideos,
            ])
        );
        yield put(ModalsActions.hideModal());
        yield put(ProjectsActions.getProjects({}));

        yield call(syncProjectMedias);
        const currentDetails = (yield call(
            checkMediaSourcesSettings
        )) as Project;

        yield delay(3000);

        const projectTranscriptList = (yield select(getProjectAllCurrentSubtitlesList)) as TransformedSentence[];

        const subtitlesVideoIds = filteredNewMediaSourcesIds.filter(
            id => !projectTranscriptList?.some(item => item.videoId === id)
        );

        if(subtitlesVideoIds.length) {
            yield put(subtitlesActions.addLibraryVideoSubtitles({
                projectDetails: currentDetails,
                videoIds: filteredNewMediaSourcesIds
            }));
        }

        yield delay(3000);

        if(!isFromUploads) {
            const projectUploads = (yield select(
                getProjectUploadsMedia
            )) as ProjectUpload[];

            const newUploads = handleCreateUploadsNewData(
                projectUploads,
                [...newVideos,]
            );

            if(newUploads?.length) {
                yield put(ProjectsActions.createProjectUpload({
                    uploadMedias: newUploads,
                }))
            }
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* addTempMediaFilePreviewImage(
    action: AddTempMediaFilePreviewAction
): Generator {
    const { id, image, timestamp } = action.payload;

    const tempMediaSourcesPreviews = (yield select(
        getProjectTempMediaSourcesPreviews
    )) as any[];

    const oldPreviews = tempMediaSourcesPreviews.find(
        (previews) => previews.id === id
    );

    let updatedPreviews = [];

    if (oldPreviews) {
        updatedPreviews = tempMediaSourcesPreviews.map((preview) => {
            if (preview.id === oldPreviews.id) {
                const updatedImages = [
                    ...preview.images,
                    {
                        image,
                        timestamp,
                    },
                ];

                const sortedImages = sortBy(updatedImages, 'timestamp');

                return {
                    ...preview,
                    images: sortedImages,
                };
            }

            return preview;
        });
    } else {
        updatedPreviews.push({
            id,
            images: [
                {
                    image,
                    timestamp,
                },
            ],
        });
    }

    yield put(
        ProjectsActions.setProjectTempMediaSourcesPreviews(updatedPreviews)
    );
}

export function* handleAddTempMediaFilePreview(): Generator {
    const addTempMediaFilePreviewChannel = yield actionChannel(
        ProjectsActionTypes.ADD_PROJECT_TEMP_MEDIA_FILE_PREVIEW
    ) as any;

    while (true) {
        const action = yield take(addTempMediaFilePreviewChannel as any);

        yield call(addTempMediaFilePreviewImage as any, action);
    }
}

export function* handleCreateProjectTempVideoPreviews(): Generator {
    const createProjectTempVideoPreviewsChannel = yield actionChannel(
        ProjectsActionTypes.CREATE_PROJECT_TEMP_VIDEO_PREVIEWS
    ) as any;

    while (true) {
        const action = yield take(createProjectTempVideoPreviewsChannel as any);

        yield call(createProjectTempVideoPreviewImages as any, action);
    }
}

export function* handleLocalProjectUpdate(
    action: UpdateProjectAction
): Generator {
    const { project, skipVersionUpdate, checkMediaSources } = action.payload;

    const preparedProject = (yield call(
        prepareProjectUpdate,
        project,
        skipVersionUpdate
    )) as Project;

    yield put(ProjectsActions.setProjectDetail(preparedProject));

    if (checkMediaSources) {
        yield call(checkMediaSourcesSettings);
    }
}

function* handleFilterFutureProjectUpdates(project: Project): Generator {
    const oldProjectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const projectUpdatesHistory = (yield select(
        getProjectUpdatesHistory
    )) as Project[];

    const updatedProjectUpdatesHistory = [] as Project[];

    if (!projectUpdatesHistory?.length) {
        // first update
        updatedProjectUpdatesHistory.push(oldProjectDetails);
    }

    const filteredOldProjectUpdates = projectUpdatesHistory.filter(
        (projectUpdate) =>
            parseFloatNumber(`${projectUpdate.version}`) <=
            parseFloatNumber(`${oldProjectDetails.version}`)
    );

    updatedProjectUpdatesHistory.push(...filteredOldProjectUpdates);

    // last version
    updatedProjectUpdatesHistory.push(project);

    return updatedProjectUpdatesHistory;
}

export function* handleSyncProjectUpdate(): Generator {
    const updateProjectReqChannel = yield actionChannel(
        ProjectsActionTypes.UPDATE_PROJECT
    ) as any;

    while (true) {
        const action = (yield take(
            updateProjectReqChannel as any
        )) as UpdateProjectAction;

        const projectUpdatesHistory = (yield select(
            getProjectUpdatesHistory
        )) as Project[];

        const {
            project,
            skipVersionUpdate,
            isUseTemplate,
            isChangeLanguage,
        } = action.payload;

        let preparedProject = (yield call(
            prepareProjectUpdate,
            project,
            skipVersionUpdate
        )) as Project;

        if (!skipVersionUpdate) {
            const filteredUpdatesHistory = (yield call(
                handleFilterFutureProjectUpdates,
                preparedProject
            )) as Project[];

            yield put(
                ProjectsActions.setProjectUpdatesHistory(filteredUpdatesHistory)
            );

            preparedProject = {
                ...preparedProject,
            } as Project;
        } else {
            const currentVersion = preparedProject.version;
            const prevProject = projectUpdatesHistory.find(item => item.version === `${currentVersion}`);

            if(prevProject && !isChangeLanguage) {
                const prevSentences = prevProject.sentences;
                const currentSentences = preparedProject.sentences;

                if(prevSentences && currentSentences && !isEqual(prevSentences, currentSentences)) {
                    const updatedVersions = projectUpdatesHistory.map(item => {
                        if(item.version === currentVersion) {
                            return preparedProject;
                        }
                        return item;
                    });

                    yield put(
                        ProjectsActions.setProjectUpdatesHistory(updatedVersions)
                    );
                }
            }
        }

        yield call(
            handleUpdateProject as any,
            {
                type: action.type,
                skipVersionUpdate,
                payload: {
                    ...action.payload,
                    project: preparedProject,
                },
                isUseTemplate,
            },
            updateProjectReqChannel
        );
    }
}

export function* handleSyncProjectMediaSources(): Generator {
    const syncProjectMediaSourcesChannel = yield actionChannel(
        ProjectsActionTypes.SYNC_PROJECT_MEDIA_SOURCES
    );

    while (true) {
        yield take(syncProjectMediaSourcesChannel as any);

        yield delay(10000);

        yield call(syncProjectMedias);
    }
}

export function* handleGoByProjectHistory(
    action: GoByProjectHistoryAction
): Generator {
    const direction = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectUpdatesHistory = (yield select(
        getProjectUpdatesHistory
    )) as Project[];
    const isProjectUpdating = yield select(getProjectUpdateLoading);

    let isDisabled = !projectUpdatesHistory?.length || isProjectUpdating;

    if (!isDisabled && direction === 'back') {
        const firstUpdateVersion = projectUpdatesHistory?.[0]?.version;

        isDisabled = projectDetails.version === firstUpdateVersion;
    }

    if (!isDisabled && direction === 'forward') {
        const lastUpdateVersion =
            projectUpdatesHistory[projectUpdatesHistory?.length - 1].version;

        isDisabled = projectDetails.version === lastUpdateVersion;
    }

    if (isDisabled) {
        return;
    }

    const currentProjectVersionIndex = projectUpdatesHistory.findIndex(
        (project) => project.version === projectDetails.version
    );

    let selectedProjectVersion =
        direction === 'back'
            ? projectUpdatesHistory[currentProjectVersionIndex - 1]
            : projectUpdatesHistory[currentProjectVersionIndex + 1];

    yield put(ProjectsActions.setProjectVideos(selectedProjectVersion.videos));
    yield put(ProjectsActions.setProjectAudios(selectedProjectVersion.audios));

    yield put(
        ProjectsActions.updateProject({
            project: selectedProjectVersion,
            skipVersionUpdate: true,
            checkMediaSources: true,
            getSubtitles: true,
            isVersionChange: true
        })
    );
}

export function* handleUpdateProjectImage(
    action: UpdateProjectImageAction
): Generator {
    const updatedImage = action.payload;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const updatedImages = projectDetails.images.map((image) => {
        if (image.id === updatedImage.id) {
            return updatedImage;
        }

        return image;
    });

    const updatedProjectDetails = {
        ...projectDetails,
        images: updatedImages,
    } as Project;

    yield put(
        ProjectsActions.updateProject({
            project: updatedProjectDetails,
        })
    );
}

export function* handleUpdateProjectElement(
    action: UpdateProjectElementAction
): Generator {
    const updatedElement = action.payload;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const updatedElements = projectDetails.elements.map((element) => {
        if (element.id === updatedElement.id) {
            return updatedElement;
        }

        return element;
    });

    const updatedProjectDetails = {
        ...projectDetails,
        elements: updatedElements,
    } as Project;

    yield put(
        ProjectsActions.updateProject({
            project: updatedProjectDetails,
        })
    );
}

export function* handleUpdateProjectAudio(
    action: UpdateProjectAudioAction
): Generator {
    try {
        const updatedProjectAudio = action.payload;
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const projectAudios = (yield select(
            getProjectAudiosList
        )) as ProjectAudio[];

        const updatedProjectAudios = projectAudios.map((auido) => {
            if (auido.id === updatedProjectAudio.id) return updatedProjectAudio;

            return auido;
        });

        const updatedProject = {
            ...projectDetails,
            audios: updatedProjectAudios,
        } as Project;

        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
    } catch (error) {
        console.log({ error });
    }
}

export function* handleDetachAudioFromVideo(
    action: DetouchAudioFromVideoAction
): Generator {
    try {
        yield put(ProjectsActions.detachAudioFromVideoStart());

        const selectedVideo = action.payload;
        const mediaSourceId = selectedVideo.mediaSourcesId;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const audioFiles = (yield select(
            getProjectAudioMediaSources
        )) as ProjectAudioMediaSource[];
        const projectAudios = (yield select(
            getProjectAudiosList
        )) as ProjectAudio[];
        const selectedAudioFile = audioFiles.find(
            (file) => {
                if(selectedVideo.audioLanguage) {
                    return (
                        file.videoId === mediaSourceId &&
                        file.language === selectedVideo.audioLanguage
                    );
                }
                return file.videoId === mediaSourceId
            }
        );

        if (selectedAudioFile) {
            const updatedProjectVideo = {
                ...selectedVideo,
                isAudioDetached: true,
                videoVolume: 0,
            } as ProjectVideo;

            if(updatedProjectVideo.audioLanguage) {
                delete updatedProjectVideo.audioLanguage;
            }
            if(updatedProjectVideo.dubVolume) {
                delete updatedProjectVideo.dubVolume;
            }

            const updatedProjectVideos = projectDetails.videos.map((video) => {
                if (video.id === updatedProjectVideo.id)
                    return updatedProjectVideo;

                return video;
            });

            const newProjectAudio = detachProjectAudioFromProjectVideo(
                selectedVideo,
                selectedAudioFile
            );

            const updatedProjectAudios = [...projectAudios, newProjectAudio];

            const updatedProject = {
                ...projectDetails,
                videos: updatedProjectVideos,
                audios: updatedProjectAudios,
            } as Project;

            yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
            yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                })
            );

            yield put(ProjectsActions.detachAudioFromVideoSuccess());
        } else {
            yield put(ProjectsActions.detachAudioFromVideoFail());
        }
    } catch (error) {
        yield put(ProjectsActions.detachAudioFromVideoFail());
    }
}

export function* getProjectItemsMaxZIndex(): Generator {
    const medias = (yield select(getMedias)) as TimelineMedias;
    const indices = medias.items.map((item) => item.zIndex ?? 1);
    if (indices?.length > 0) {
        return Math.max(...indices);
    }
    return 1;
}

export function* getProjectItemZIndex(
    startTime: number,
    endTime: number
): Generator {
    const medias = (yield select(getMedias)) as TimelineMedias;
    const maxZIndex = (yield getProjectItemsMaxZIndex()) as number;
    let zIndex;

    const range1 = new Range(
        secondsToFrameNumber(startTime, medias.frameRate),
        secondsToFrameNumber(endTime, medias.frameRate)
    );
    const needNewTrack = medias.items.some((item) => {
        const range2 = new Range(
            secondsToFrameNumber(item.startTime, medias.frameRate),
            secondsToFrameNumber(item.endTime, medias.frameRate)
        );
        const intersection = range1.intersection(range2);
        return intersection?.length !== 0 && (item.zIndex ?? 1) === maxZIndex;
    });

    if (!needNewTrack) {
        zIndex = maxZIndex;
    } else {
        zIndex = maxZIndex + 1;
    }
    return zIndex;
}

export function* handleCreateProjectText(
    action: CreateProjectTextAction
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const userId = (yield select(getUserId)) as string;
    const text = action.payload;
    const zIndex = (yield getProjectItemZIndex(
        text.startTime,
        text.endTime
    )) as number;

    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    const updatedProjectDetails = {
        ...projectDetails,
        texts: [...(projectDetails?.texts || []), { ...text, zIndex }],
    } as Project;

    yield put(
        ProjectsActions.updateProject({
            project: updatedProjectDetails,
        })
    );
    yield put(
        ProjectsStudioActions.setSelectedShape({
            id: action.payload.id,
        })
    );

    amplitudeAnalytics.textAdded({
        text_type: text.data === 'Headline Title' ? 'headline' : 'regular_text',
    });

    Analytics.record({
        name: 'add_text_to_project',
        attributes: {
            userId,
            plan: currentPlan.planNameId,
        },
    });
}

export function* handleUpdateProjectText(
    action: UpdateProjectTextAction
): Generator {
    const updatedText = action.payload;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const updatedProjectTexts = (projectDetails?.texts || []).map((text) => {
        if (text.id === updatedText.id) {
            return updatedText;
        }

        return text;
    });

    const updatedProjectDetails = {
        ...projectDetails,
        texts: updatedProjectTexts,
    } as Project;

    yield put(
        ProjectsActions.updateProject({
            project: updatedProjectDetails,
        })
    );
}

export function* handleUpdateProjectVideo(
    action: UpdateProjectVideoAction
): Generator {
    const updatedVideo = action.payload;

    const isPlaying = yield select(getProjectPlaying);

    if (isPlaying) {
        yield call(hadlePauseCurrentVideo);

        // yield take(ProjectsStudioActionTypes.SET_PLAYING);
    }

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let projectVideos = (yield select(getProjectVideos)) as ProjectVideo[];
    projectVideos = sortBy(projectVideos, 'startTime');

    const updatedVideos = projectVideos.map((video) => {
        if (video.id === updatedVideo.id) {
            return updatedVideo;
        }

        return video;
    });

    const filteredVideos = updatedVideos.filter((video) => !video.isDummy);

    yield put(ProjectsActions.setProjectVideos(filteredVideos));

    const updatedTransitions = (yield call(
        checkVideosTransitions
    )) as VideoTransition[];

    yield put(ProjectsActions.setProjectVideosTransitions(updatedTransitions));
    yield put(
        ProjectsActions.updateProject({
            project: {
                ...projectDetails,
                videos: filteredVideos,
                transitions: updatedTransitions,
            },
        })
    );
}

export function* handleUpdateProjectVideos(
    action: UpdateProjectVideosAction
): Generator {
    const newVideos = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let projectVideos = (yield select(getProjectVideos)) as ProjectVideo[];
    const updatedVideos: ProjectVideo[] = [];
    for (const video of projectVideos) {
        if (!video.isDummy) {
            const updatedVideo = newVideos.find((v) => v.id === video.id);
            if (updatedVideo) {
                updatedVideos.push(updatedVideo);
            } else {
                updatedVideos.push(video);
            }
        }
    }

    yield put(ProjectsActions.setProjectVideos(updatedVideos));

    const updatedTransitions = (yield call(
        checkVideosTransitions
    )) as VideoTransition[];

    yield put(ProjectsActions.setProjectVideosTransitions(updatedTransitions));
    yield put(
        ProjectsActions.updateProject({
            project: {
                ...projectDetails,
                videos: updatedVideos,
                transitions: updatedTransitions,
            },
        })
    );
}

export function* handleUpdateProjectAudios(
    action: UpdateProjectAudiosAction
): Generator {
    const newAudios = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    let projectAudios = (yield select(getProjectAudiosList)) as ProjectAudio[];

    const updatedAudios: ProjectAudio[] = [];

    for (const audio of projectAudios) {
        const updatedAudio = newAudios.find((a) => a.id === audio.id);
        if (updatedAudio) {
            updatedAudios.push(updatedAudio);
        } else {
            updatedAudios.push(audio);
        }
    }

    yield put(ProjectsActions.setProjectAudios(updatedAudios));

    yield put(
        ProjectsActions.updateProject({
            project: {
                ...projectDetails,
                audios: updatedAudios,
            },
        })
    );
}

function* handleAutoSelectAfterDubbing(
    audioLanguage: string,
    videoId: string,
    hasTargetTranslation: boolean,
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const videos = projectDetails.videos;

    let currentVideos = videos.filter(video => video.mediaSourcesId === videoId) ;
    let updatedVideos = videos.filter(video => video.mediaSourcesId !== videoId);

    if(currentVideos.length) {
        const changedMedias = currentVideos.map(
            (video) => {
                let currentVideo = video;
                if (!audioLanguage && currentVideo.audioLanguage) {
                    currentVideo = {
                        ...currentVideo,
                        videoVolume: currentVideo.enhanced ? 0 : 1,
                        dubVolume: 0,
                        enhancedVolume: currentVideo.enhanced ? 1 : 0,
                    };
                    delete currentVideo.audioLanguage;
                } else {
                    currentVideo = {
                        ...currentVideo,
                        audioLanguage: audioLanguage === 'he-IL' ? 'iw-IL' : audioLanguage,
                        videoVolume: 0,
                        dubVolume: 1,
                        enhancedVolume: 0,
                    };
                }
                return currentVideo;
            }
        );

        updatedVideos = [...updatedVideos, ...changedMedias];


        const videosLanguages = (yield select(
            getProjectVideosLanguages
        )) as ProjectVideoLanguages[];
        const videoLanguages = videosLanguages.find(item => item.videoId === videoId);

        const targetTranslationLanguage = videoLanguages?.languagesList.find(
            lang => {
                const indexOfDash = lang.indexOf("-");
                const formattedLang = (indexOfDash > 0 && lang !== "zh-TW") ? lang.split("-")[0] : lang;

                return (
                    (lang === audioLanguage || lang === audioLanguage.split("-")[0]) ||
                    translateLanguagesArray.some(
                        item =>
                            (item?.value === formattedLang) &&
                            item?.altValue?.some(value => value === audioLanguage)
                    )
                );
            }
        );

        let updatedProjectDetails = {
            ...projectDetails,
        }

        if(targetTranslationLanguage) {
            if(!hasTargetTranslation) {
                if (!projectDetails.subtitlesNotAttached) {
                    const updatedMediaSourcesSettings =
                        projectDetails.mediaSourcesSettings.map((settings) => {
                            if (settings.mediaSourceId === videoId) {
                                return {
                                    ...settings,
                                    language: targetTranslationLanguage,
                                };
                            }

                            return settings;
                        });
                    updatedProjectDetails = {
                        ...projectDetails,
                        mediaSourcesSettings: updatedMediaSourcesSettings,
                    } as Project;
                } else {
                    updatedProjectDetails = {
                        ...projectDetails,
                        language: targetTranslationLanguage,
                    };
                }
            }
        }

        yield put(ProjectsActions.setProjectVideos(updatedVideos));

        const updatedProject = {
            ...updatedProjectDetails,
            videos: updatedVideos,
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);

        if(targetTranslationLanguage) {
            if(!hasTargetTranslation) {
                yield put(changeProjectVideoLanguage({
                    videoId,
                    language: targetTranslationLanguage,
                }));
            } else {
                yield put(changeProjectVideoLanguage({
                    videoId,
                    language: targetTranslationLanguage,
                }));
            }
        }
    }
}

function* handleTranslateForDubbing(
    action: GenerateProjectTranslationForDubbingAction
): Generator {
    const {
        sourceLanguage,
        targetLanguage,
        mediaDetails,
        hasSubtitles,
    } = action.payload;

    const currVideoId = mediaDetails?.id;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    if(mediaDetails) {
        const { duration } = (
            mediaDetails.mediaInfo?.proxy ||
            mediaDetails.mediaInfo?.original
        ).container;

        const operationPrice = (yield call(
            calculatePriceInCreditsForDubbingOperation,
            {
                videoDuration: duration * 1000,
            }
        )) as number;

        const currentDubbingCredits = (yield select(
            getCurrentDubbingCredits
        )) as number;

        const isOperationAvailable = currentDubbingCredits >= operationPrice;

        if(!isOperationAvailable) {
            yield call(handleDubbingPaymentLimitation);
        } else {
            let updatedAudiosIdsWithTranscriptGeneration: string[] = [];
            let updatedAudiosIdsWithTranslateGeneration: string[] = [];

            if(!hasSubtitles) {
                const videosIdsWithTranscriptGeneration = (yield select(
                    getProjectVideosIdsWithTranscriptGeneration
                )) as string[];

                updatedAudiosIdsWithTranscriptGeneration = [
                    ...videosIdsWithTranscriptGeneration,
                    currVideoId,
                ];

                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                        updatedAudiosIdsWithTranscriptGeneration
                    )
                );
            }

            const videosIdsWithTranslateGeneration = (yield select(
                getProjectVideosIdsWithTranslateGeneration
            )) as string[];

            updatedAudiosIdsWithTranslateGeneration = [
                ...videosIdsWithTranslateGeneration,
                currVideoId,
            ];

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                    updatedAudiosIdsWithTranslateGeneration
                )
            );

            try {
                const categories = [] as string[];
                const analysisData: ReanalysisReqData = {} as ReanalysisReqData;

                if(!hasSubtitles) {
                    categories.push(RekognitionCategory.AWS_TRANSCRIPT);
                }

                categories.push(RekognitionCategory.AWS_TRANSLATE);
                analysisData.translate = {
                    sourceLanguage,
                    targetLanguages: targetLanguage,
                    projectId: projectDetails.id,
                    projectVersion: projectDetails.version,
                    creditType: "dubbingCredits",
                }

                analysisData.creditType = 'dubbingCredits';
                analysisData.language = sourceLanguage;
                analysisData.categories = categories;

                yield call(
                    AnalysisClient.reanalyze,
                    currVideoId,
                    analysisData
                );

                yield delay(2000);

                let statusesResponse = (yield call(
                    VideosClient.getVideosByIds,
                    currVideoId
                )) as AxiosResponse;

                let projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

                while(projectVideoMediaSources[0].status?.analyze?.translate?.status === StatusType.IN_PROGRESS)  {
                    yield delay(5000);
                    statusesResponse = (yield call(
                        VideosClient.getVideosByIds,
                        currVideoId
                    )) as AxiosResponse;

                    projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

                    if(updatedAudiosIdsWithTranscriptGeneration.some(item => item === currVideoId )) {
                        if(statusesResponse?.data?.content[0].status?.analyze?.transcript?.status === StatusType.FAILED) {
                            break;
                        }
                    }
                }

                // yield call(getAudioMediaSources);

                yield call(syncProjectMedias);

                if(projectVideoMediaSources[0].status?.analyze?.translate?.status === StatusType.FAILED) {
                    showNotification(
                        NotificationTypes.error,
                        'Translation generation failed'
                    );
                } else if(projectVideoMediaSources[0].status?.analyze?.transcript?.status === StatusType.FAILED) {
                    showNotification(
                        NotificationTypes.error,
                        'Subtitles generation failed'
                    );
                }

                updatedAudiosIdsWithTranscriptGeneration =
                    updatedAudiosIdsWithTranscriptGeneration.filter((item) => item !== currVideoId);
                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                        updatedAudiosIdsWithTranscriptGeneration
                    )
                );

                updatedAudiosIdsWithTranslateGeneration =
                    updatedAudiosIdsWithTranslateGeneration?.filter((item) => item !== currVideoId);
                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                        updatedAudiosIdsWithTranslateGeneration
                    )
                );
            } catch(error) {
                console.log({ error });
                showNotification(
                    NotificationTypes.error,
                    'An error occurred while generating the translation'
                );
                yield call(syncProjectMedias);
            }
        }
    }
}

function* handleGenerateDubbing(
    action: GenerateProjectAudioDubbingAction
): Generator {

    const {
        sourceLanguage,
        targetLanguage,
        voiceId,
        voiceStyle,
        mediaDetails,
    } = action.payload;

    const currVideoId = mediaDetails?.id;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    if(mediaDetails) {
        const { duration } = (
            mediaDetails.mediaInfo?.proxy ||
            mediaDetails.mediaInfo?.original
        ).container;

        const operationPrice = (yield call(
            calculatePriceInCreditsForDubbingOperation,
            {
                videoDuration: duration * 1000,
            }
        )) as number;

        const currentDubbingCredits = (yield select(
            getCurrentDubbingCredits
        )) as number;

        const mediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];

        const currentMedia = mediaSources.find(item => item.id === currVideoId) as MediaFile;

        const isOperationAvailable = currentDubbingCredits >= operationPrice;

        if(!(isOperationAvailable || mediaDetails?.isDubbingAvailable || currentMedia?.isDubbingAvailable)) {
            yield call(handleDubbingPaymentLimitation);
        } else {
            const projectAudiosIdsWithDubbingGeneration = (yield select(
                getProjectAudiosIdsWithDubbingGeneration
            )) as string[];

            let updatedAudiosIdsWithDubbingGeneration = [
                ...projectAudiosIdsWithDubbingGeneration,
                currVideoId,
            ];

            yield put(
                ProjectsActions.setProjectAudiosIdsWithDubbingGeneration(
                    updatedAudiosIdsWithDubbingGeneration
                )
            );

            try {
                const categories = [RekognitionCategory.AWS_DUBBING] as string[];
                const analysisData: ReanalysisReqData = {} as ReanalysisReqData;

                analysisData.creditType = 'dubbingCredits';
                analysisData.language = sourceLanguage;
                analysisData.categories = categories;
                analysisData.dubbing = {
                    sourceLanguage,
                    targetLanguages: targetLanguage,
                    // voiceId,
                    // voiceStyle: voiceStyle || '',
                    projectId: projectDetails.id,
                    projectVersion: projectDetails.version,
                    creditType: "dubbingCredits",
                }

                yield call(
                    AnalysisClient.reanalyze,
                    currVideoId,
                    analysisData
                );

                yield delay(2000);

                let statusesResponse = (yield call(
                    VideosClient.getVideosByIds,
                    currVideoId
                )) as AxiosResponse;

                let projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

                while(projectVideoMediaSources[0].status?.analyze?.dubbing?.status === StatusType.IN_PROGRESS)  {
                    yield delay(5000);
                    statusesResponse = (yield call(
                        VideosClient.getVideosByIds,
                        currVideoId
                    )) as AxiosResponse;

                    projectVideoMediaSources = statusesResponse.data.content as MediaFile[];
                }

                yield call(getAudioMediaSources);

                yield call(syncProjectMedias, true);

                if(projectVideoMediaSources[0].status?.analyze?.dubbing?.status === StatusType.FAILED) {
                    showNotification(
                        NotificationTypes.error,
                        'Dubbed audio generation failed'
                    );
                    yield call(syncProjectMedias);
                } else {
                    amplitudeAnalytics.dubbingGenerated({
                        from: sourceLanguage,
                        to: targetLanguage,
                    });

                    googleAnalytics.dubbingGenerated({
                        from: sourceLanguage,
                        to: targetLanguage,
                    });

                    yield call(handleAutoSelectAfterDubbing, targetLanguage, currVideoId, true)
                }

                updatedAudiosIdsWithDubbingGeneration =
                    updatedAudiosIdsWithDubbingGeneration.filter(
                        (id) => id !== currVideoId
                    );

                yield put(
                    ProjectsActions.setProjectAudiosIdsWithDubbingGeneration(
                        updatedAudiosIdsWithDubbingGeneration
                    )
                );
            } catch (error) {
                console.log({ error });
                showNotification(
                    NotificationTypes.error,
                    'An error occurred while generating the dubbing'
                );
                yield call(syncProjectMedias);
            }
        }
    }
}

export function* handleChangeAudioLanguage(
    action: ChangeAudioLanguageAction
): Generator {
    const {audioLanguage, videoId} = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const videos = projectDetails.videos;

    let currentVideos = videos.filter(video => video.mediaSourcesId === videoId) ;
    let updatedVideos = videos.filter(video => video.mediaSourcesId !== videoId);

    if(currentVideos.length) {
        const changedMedias = currentVideos.map(
            (video) => {
                let currentVideo = video;
                if(!audioLanguage && currentVideo.audioLanguage) {
                    currentVideo = {...currentVideo,
                        videoVolume: currentVideo.enhanced ? 0 : 1,
                        dubVolume: 0,
                        enhancedVolume: currentVideo.enhanced ? 1 : 0,
                    };
                    delete currentVideo.audioLanguage;
                } else {
                    currentVideo = {
                        ...currentVideo,
                        audioLanguage,
                        videoVolume: 0,
                        dubVolume: 1,
                        enhancedVolume: 0,
                    };
                }
                return currentVideo;
            }
        );

        updatedVideos = [...updatedVideos, ...changedMedias];

        const updatedProject = {
            ...projectDetails,
            videos: updatedVideos,
        }

        yield put(ProjectsActions.setProjectVideos(updatedVideos));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
                // skipVersionUpdate: true,
            })
        );
    }
}

export function* handleDeleteProjectDubbingLanguage(
    action: DeleteProjectDubbingLanguageAction
):Generator {
    const { languages, videoId } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const videos = projectDetails?.videos;

    try {
        const isSelectedAudio = videos.some(
            item => (item.mediaSourcesId === videoId) &&
                (item?.audioLanguage && languages.includes(item.audioLanguage)));

        yield call(VideosClient.deleteDubbingLanguage, videoId, languages);

        yield delay(3000);

        yield put(ProjectsActions.getMediaAudioTracksOptions({
            mediaItemsIds: [videoId]
        }));

        if(isSelectedAudio) {
            yield put(ProjectsActions.changeAudioLanguage({
                audioLanguage: '',
                videoId
            }));
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetMediaAudioOptions(
    action: GetMediaAudioTracksOptionsAction
): Generator {
    try {
        const { mediaItemsIds } = action.payload;

        const responses = (yield all(
            mediaItemsIds.map((id: string) =>
                call(VideosClient.getVideoAudioTracks, id)
            )
        )) as AxiosResponse[];

        const videoAudioFiles = {} as ProjectMediaAudioOptions;

        responses.forEach((item: AxiosResponse) => {
            const resContent = item.data.content;
            const mediaId = item.data.content[0]?.videoId;

            if (resContent) {
                videoAudioFiles[mediaId] = resContent;
            }
        });

        if(!isEmpty(videoAudioFiles)) {
            yield put(ProjectsActions.setProjectAudioOptions(videoAudioFiles));
        }
    } catch (error) {
        console.log({ error });
    }
}

function* handleResetProjectTextSettings(): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const updatedText = (projectDetails?.texts || []).map((text) => ({
        ...text,
        ...projectTextDefaultSettings,
    }));

    const updatedProject = {
        ...projectDetails,
        texts: updatedText,
    } as Project;

    yield put(
        ProjectsActions.updateProject({
            project: updatedProject,
        })
    );
}

function* handleLoadElementsList(): Generator {
    yield put(ProjectsActions.getElementsListStart());

    try {
        const res = (yield call(ProjectsClient.getElements)) as AxiosResponse;

        const elements = res.data.content;

        yield put(ProjectsActions.setElementsList(elements));
    } catch (error) {
        yield put(ProjectsActions.getElementsListFail());
        console.log({ error });
    }
}

function* handleCreateProjectElement(
    action: CreateProjectElementAction
): Generator {
    const element = action.payload;

    try {
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const elements = projectDetails?.elements || [];
        const currentTime = (yield select(getProjectTimelineTime)) as number;
        const userId = (yield select(getUserId)) as string;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        const canvas = document.getElementById('ProjectPlayerCanvas') as any;

        const canvasHeight = canvas.clientHeight;
        const canvasWidth = canvas.clientWidth;

        const imageMeta = (yield call(
            getImageMeta,
            `${window.config.REACT_APP_CLOUDFRONT_URL}${element.s3Path}`
        )) as any;

        const { width, height } = imageMeta;

        const imageAspectRatio = width / height;

        const imageWidth = canvasWidth * 0.25;
        const imageHeight = imageWidth / imageAspectRatio;

        const newElement = {
            ...element,
            id: uuid(),
            startTime: currentTime,
            endTime: currentTime + 1,
            translationX: 0.5,
            translationY: 0.5,
            angle: 0,
            width: imageWidth / canvasWidth,
            height: imageHeight / canvasHeight,
        } as ProjectElement;

        const zIndex = (yield getProjectItemZIndex(
            newElement.startTime,
            newElement.endTime
        )) as number;
        newElement.zIndex = zIndex;

        const updatedElements = [...elements, newElement];

        const updatedProject = {
            ...projectDetails,
            elements: updatedElements,
        } as Project;

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
        yield put(
            ProjectsStudioActions.setSelectedShape({
                id: newElement.id,
            })
        );

        amplitudeAnalytics.elementAdded({ element_type: element.category });
        Analytics.record({
            name: 'add_element_to_project',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });
    } catch (error) {
        console.log({ error });
    }
}

function* handleUpdateProjectPreferences(
    action: UpdateProjectPreferencesAction
): Generator {
    const textSettings = action.payload?.textSettings;

    yield put(ProjectsActions.updateProjectStart());
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const preferences = (yield select(
        getProjectPreferences
    )) as ProjectPreferences;

    try {
        const updatePreferences = {
            ...preferences,
            textSettings:
                textSettings ||
                preferences?.textSettings ||
                projectTextDefaultSettings,
            subtitlesSettings: {
                subtitlesColor:
                    projectDetails.settings?.subtitles?.subtitlesColor ||
                    defaultProjectSubtitlesSettings?.subtitlesColor ||
                    '',
                subtitlesFontSize:
                    projectDetails.settings?.subtitles?.subtitlesFontSize ||
                    defaultProjectSubtitlesSettings.subtitlesFontSize,
                translationY:
                    projectDetails.settings?.subtitles?.translationY ||
                    defaultProjectSubtitlesSettings.translationY,
                translationX:
                    projectDetails.settings?.subtitles?.translationX ||
                    defaultProjectSubtitlesSettings.translationX,
                box:
                    projectDetails.settings?.subtitles?.box ||
                    defaultProjectSubtitlesSettings.box,
                angle:
                    projectDetails.settings?.subtitles?.angle ||
                    defaultProjectSubtitlesSettings.angle,
                width:
                    projectDetails.settings?.subtitles?.width ||
                    defaultProjectSubtitlesSettings.width,
                align:
                    projectDetails.settings?.subtitles?.align ||
                    defaultProjectSubtitlesSettings.align,
                subtitleEffect:
                    projectDetails.settings?.subtitles?.subtitleEffect ||
                    defaultProjectSubtitlesSettings.subtitleEffect,
                subtitlesTextStyle:
                    projectDetails.settings?.subtitles?.subtitlesTextStyle ||
                    defaultProjectSubtitlesSettings.subtitlesTextStyle,
                fontFamily:
                    projectDetails.settings?.subtitles?.fontFamily ||
                    defaultProjectSubtitlesSettings.fontFamily,
                spacing:
                    projectDetails.settings?.subtitles?.spacing ||
                    defaultProjectSubtitlesSettings.spacing,
                lineHeight:
                    projectDetails.settings?.subtitles?.lineHeight ||
                    defaultProjectSubtitlesSettings.lineHeight,
                outline:
                    projectDetails.settings?.subtitles?.outline ||
                    defaultProjectSubtitlesSettings.outline,
                outlineColor:
                    projectDetails.settings?.subtitles?.outlineColor ||
                    defaultProjectSubtitlesSettings.outlineColor,
                shadowColor:
                    projectDetails.settings?.subtitles?.shadowColor ||
                    defaultProjectSubtitlesSettings.shadowColor,
                shadow:
                    projectDetails.settings?.subtitles?.shadow ||
                    defaultProjectSubtitlesSettings.shadow,
                textLines:
                    projectDetails.settings?.subtitles?.textLines ??
                    defaultProjectSubtitlesSettings.textLines,
                fontWeight:
                    projectDetails.settings?.subtitles?.fontWeight ??
                    defaultProjectSubtitlesSettings.fontWeight,
            },
            videoSettings: projectDetails?.settings?.video,
            aspectRatio: projectDetails.aspectRatio,
        } as ProjectPreferences;

        yield call(UserClient.updateUserProjectPreferences, updatePreferences);

        yield put(UserActions.setProjectPreferences(updatePreferences));

        yield put(ProjectsActions.updateProjectSuccess());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.updateProjectFail());
    }
}

function* handleAddTextToSpeechToProject({
    s3Path,
    text,
}: {
    s3Path: string;
    text: string;
}): any {
    try {
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const time = (yield select(getProjectTimelineTime)) as number;
        const userId = (yield select(getUserId)) as string;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;
        const userInfo = (yield select(getUserInfo)) as User;
        const fileRes = (yield call(
            axios.get,
            `${window.config.REACT_APP_CLOUDFRONT_URL}${encodeURI(s3Path).replaceAll('?', '%3F')}`,
            {
                responseType: 'blob',
            }
        )) as AxiosResponse;
        const info = yield call(getInfo, fileRes.data as any);
        const audioTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'Audio'
        );
        const generalTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'General'
        );
        const generalMediaInfoTrack = transformGeneralTrack(generalTrack);
        const audioMediaInfoTrack = transformAudioTrack(audioTrack);
        const { duration } = generalMediaInfoTrack;
        const projectAudioMediaSources = (yield select(
            getProjectAudioMediaSources
        )) as ProjectAudioMediaSource[];

        let newAudioMediaSource = {
            mediaInfo: audioMediaInfoTrack,
            s3Path,
            userId: userInfo.id,
            // isDetached: true,
            isDetach: true,
        } as ProjectAudioMediaSource;
        const newAudioMediaSourceRes = (yield call(
            MediaClient.createAudioMediasSource,
            newAudioMediaSource,
            true
        )) as AxiosResponse;
        newAudioMediaSource = newAudioMediaSourceRes.data.content;
        const updatedAudioMediaSources = [
            ...projectAudioMediaSources,
            newAudioMediaSource,
        ];
        const newProjectAuido = {
            volume: 1,
            videoId: '',
            startTime: time,
            trimStart: 0,
            endTime: time + duration,
            trimEnd: duration,
            s3AudioPath: newAudioMediaSource.s3Path,
            id: uuid(),
            speed: 1,
            mediaSourcesId: newAudioMediaSource.id,
            mediaInfo: audioMediaInfoTrack,
            name: text,
            projectTrackId: uuid(),
            isActive: true,
        } as ProjectAudio;

        const updatedProjectAudios = [
            ...projectDetails.audios,
            newProjectAuido,
        ];
        const updatedAudioMediaSourcesIds = updatedAudioMediaSources.map(
            (mediaSource) => mediaSource.id
        );
        const updatedProjectDuration =
            duration > projectDetails.duration
                ? duration
                : projectDetails.duration;
        const updatedProject = {
            ...projectDetails,
            audios: updatedProjectAudios,
            audioMediaSorces: updatedAudioMediaSourcesIds,
            duration: updatedProjectDuration,
        } as Project;

        yield put(
            ProjectsActions.setProjectAudioMediaSources(
                updatedAudioMediaSources
            )
        );
        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));
        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
        yield put(ProjectsActions.generateTextToSpeechSuccess());
        Analytics.record({
            name: 'generate_text_to_speech',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.generateTextToSpeechFail(error));
    }
}

function* handleSyncTextToSpeechGeneration({
    taskId,
    text,
    s3Path,
    language
}: {
    taskId: string;
    text: string;
    s3Path: string;
    language: string;
}): any {
    const statusRes = yield call(TextToSpeechClient.getStatus, {
        taskId,
        s3Path,
    });

    const status = statusRes.data.content.status;

    if (status === 'Succeeded') {
        googleAnalytics.textToSpeechGenerated({
            text_length: text?.length || 0,
            language: '',
        });

        amplitudeAnalytics.textToSpeechGenerated({
            text_length: text?.length || 0,
            language,
        });

        yield delay(5000);

        yield call(handleAddTextToSpeechToProject, { text, s3Path });
    } else if (status === 'Failed') {
        yield put(ProjectsActions.generateTextToSpeechFail());
    } else {
        yield delay(10000);

        return yield call(handleSyncTextToSpeechGeneration, {
            taskId,
            text,
            s3Path,
            language,
        });
    }
}

function* handleGenerateTextToSpeech(
    action: GenerateTextToSpeechAction
): Generator {
    const { text, voiceId, language, voiceStyle, rate } = action.payload;
    yield put(ProjectsActions.generateTextToSpeechStart());

    try {
        const queryParams: any = {voiceId, language, text, rate};

        if(voiceStyle) {
            queryParams.voiceStyle = voiceStyle;
        }

        const res = (yield call(TextToSpeechClient.generate, queryParams)) as AxiosResponse;

        const isDone = res.data.content.done;
        const s3Path = res.data.content.s3Path;
        const taskId = res.data.content.taskId;

        const s3PathPart = s3Path.split('private')[1];

        const formattedS3Path = `private${s3PathPart}`;

        if (isDone) {
            googleAnalytics.textToSpeechGenerated({
                text_length: text?.length || 0,
                language,
            });

            amplitudeAnalytics.textToSpeechGenerated({
                text_length: text?.length || 0,
                language,
            });

            return yield call(handleAddTextToSpeechToProject, {
                // text: encodeURI(text),
                text,
                s3Path: formattedS3Path,
            });
        }
        if (taskId) {
            yield delay(10000);

            return yield call(handleSyncTextToSpeechGeneration, {
                taskId,
                text: encodeURI(text),
                s3Path: formattedS3Path,
                language,
            });
        }

        return yield put(ProjectsActions.generateTextToSpeechFail());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.generateTextToSpeechFail(error));

        showNotification(
            NotificationTypes.error,
            (error as any)?.response?.data?.message || (error as any).message
        );
    }
}

// Duplicate

function* handleDuplicateProject(action: DuplicateProjectAction): Generator {
    const project = action.payload;

    try {
        (yield call(
            ProjectsClient.duplicateProject,
            project.id,
            project.version,
            !!project?.isTemplate
        )) as AxiosResponse;

        yield delay(1000);

        yield put(ProjectsActions.getProjects({
            isTemplate: !!project?.isTemplate
        }));
    } catch (error) {
        console.log({ error });
    }
}

function* handleCreateProjectWithStockVideo(
    action: CreateProjectWithStockVideoAction
): Generator {
    const { navigate, video } = action.payload;
    try {
        yield put(
            ProjectsActions.createDefaultProject({ navigate, isStock: true })
        );

        yield take(ProjectsActionTypes.CREATE_PROJECT_SUCCESS);

        yield delay(1000);

        yield put(
            ProjectUploadActions.uploadProjectStockVideo({
                media: video,
            })
        );
    } catch (error) {
        console.log({ error });
    }
}


// Uploads
function* handleGetProjectUploads(): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = projectDetails.id;

    try {
        if(projectId) {
            const res = (yield call(
                ProjectsClient.getProjectUploads,
                projectId
            )) as AxiosResponse;

            const uploads = res?.data?.content as ProjectUpload[];

            if(uploads) {
                yield put(ProjectsActions.setProjectUploads(
                    uploads
                ));
            }
        }
    } catch(error) {
        console.log({ error });
    }
}

function* handleCreateProjectUpload (
    action: CreateProjectUploadAction
): Generator {
    const { uploadMedias } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = projectDetails.id;
    const workspaceId = projectDetails.workspaceId;

    try {
        if(projectId && uploadMedias) {
            const uploadTasks = uploadMedias?.map(media => {
                return call(ProjectsClient.createProjectUpload, {
                    projectId,
                    projectUpload: {
                        uploadDetails: {
                            ...media,
                            isUploadFinished: !!media?.isUploadFinished || false,
                            isUploadFailed: !!media?.isUploadFailed || false,
                        },
                        workspaceId,
                        projectId,
                    }
                });
            });

            yield all(uploadTasks);

            yield put(
                ProjectsActions.getProjectUploads()
            );
        }
    } catch(error) {
        console.log({ error });
    }
}

function* handleUpdateProjectUpload (
    action: UpdateProjectUploadAction
): Generator {
    const { uploadsData } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = projectDetails.id;

    try {
        if(projectId && uploadsData) {
            const uploadTasks = uploadsData.map(media => {
                const newData = media?.updatedData;

                return call(ProjectsClient.updateProjectUpload, {
                    projectId,
                    uploadId: media?.uploadId,
                    projectUpload: {
                        ...media.prevData,
                        uploadDetails: {
                            ...media?.prevData?.uploadDetails,
                            isUploadFinished: true,
                            isUploadFailed: newData?.status?.transcoding?.status === StatusType.FAILED,
                            s3Path: newData?.s3Path,
                            title: newData?.title,
                            lastModified: newData?.lastModified,
                            mediaInfo: newData?.mediaInfo,
                        },
                    }
                });
            });

            yield all(uploadTasks);

            yield put(
                ProjectsActions.getProjectUploads()
            );
        }
    } catch(error) {
        console.log({ error });
    }
}

function* handleDeleteProjectUpload (
    action: DeleteProjectUploadAction
): Generator {
    const { uploadId } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = projectDetails.id;

    try {
        if(projectId && uploadId) {
            yield call(
                ProjectsClient.deleteProjectUpload,
                {
                    projectId,
                    uploadId,
                },
            );

            yield put(
                ProjectsActions.getProjectUploads()
            );
        }
    } catch(error) {
        console.log({ error });
    }
}

function* handleGenerateCleanAudio (
    action: GenerateCleanAudioAction
): Generator {
    const { mediaId, videoId, mediaDuration } = action.payload;

    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan?.planNameId === PlanNameId.INITIAL) {
        return;
    }

    try {
        const mediasWithCleanAudioGeneration = (yield select(
            getMediasWithCleanAudioGeneration
        )) as CleanAudioGenerationMediaStatuses[];

        let updatedMediasList = [
            ...mediasWithCleanAudioGeneration,
            {
                mediaId,
                isLoading: true,
                isSuccess: false,
                isFail: false,
            }
        ];

        yield put(
            ProjectsActions.setMediasWithCleanAudioGeneration(updatedMediasList)
        );

        const analysisData: ReanalysisReqData = {
            categories: ['enhance'],
        } as ReanalysisReqData;

        yield call(
            AnalysisClient.reanalyze,
            mediaId,
            analysisData,
        );

        yield delay(5000);

        let statusesResponse = (yield call(
            VideosClient.getVideosByIds,
            mediaId,
        )) as AxiosResponse;

        let projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

        while(projectVideoMediaSources[0].status?.analyze?.enhance?.status === StatusType.IN_PROGRESS)  {
            yield delay(5000);
            statusesResponse = (yield call(
                VideosClient.getVideosByIds,
                mediaId,
            )) as AxiosResponse;

            projectVideoMediaSources = statusesResponse.data.content as MediaFile[];
        }

        const filteredMediasList =
            updatedMediasList.filter(
                (item) => item.mediaId !== mediaId
            );

        yield call(syncProjectMedias);

        // yield put(ProjectsActions.changeAudioLanguage({
        //     audioLanguage: '',
        //     videoId: mediaId,
        // }));

        if(projectVideoMediaSources[0].status?.analyze?.enhance?.status === StatusType.FAILED) {
            showNotification(
                NotificationTypes.error,
                'Clean audio generation failed'
            );
            updatedMediasList = [
                ...filteredMediasList,
                {
                    mediaId,
                    isLoading: false,
                    isSuccess: false,
                    isFail: true,
                }
            ];
        } else {
            updatedMediasList = [
                ...filteredMediasList,
                {
                    mediaId,
                    isLoading: false,
                    isSuccess: true,
                    isFail: false,
                }
            ];

            googleAnalytics.cleanAudioGenerated({
                audio_duration: mediaDuration,
            });

            amplitudeAnalytics.cleanAudioGenerated({
                audio_duration: mediaDuration,
            });
        }

        yield put(
            ProjectsActions.setMediasWithCleanAudioGeneration(updatedMediasList)
        );

        yield put(ProjectsActions.enhancedAudioSwitch({
            mediaId: videoId,
            isSwitchToClean: true,
        }));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleChangeEnhancedAudio(
    action: ChangeEnhancedAudioAction,
): Generator {
    const { mediaId, isSwitchToClean } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const videos = projectDetails.videos;
    const audios = projectDetails.audios;

    let currentVideos = videos.filter(video => video.id === mediaId);
    let updatedVideos = videos.filter(video => video.id !== mediaId);

    let currentAudios = audios.filter(audio => audio.id === mediaId);
    let updatedAudios = audios.filter(audio => audio.id !== mediaId);


    if (currentVideos.length) {
        const changedMedias = currentVideos.map(
            (video) => {
                let currentVideo = video;
                const isVideoDubbed = (
                    currentVideo?.hasOwnProperty('dubVolume') &&
                    currentVideo.dubVolume !== undefined &&
                    currentVideo?.audioLanguage
                );

                currentVideo = {
                    ...currentVideo,
                    videoVolume: (!isSwitchToClean && !isVideoDubbed) ? 1 : 0,
                    enhancedVolume: (isSwitchToClean && !isVideoDubbed) ? 1 : 0,
                    dubVolume: isVideoDubbed ? 1 : 0,
                    enhanced: isSwitchToClean,
                };
                return currentVideo;
            }
        );

        updatedVideos = [...updatedVideos, ...changedMedias];

        const updatedProject = {
            ...projectDetails,
            videos: updatedVideos,
        }

        yield put(ProjectsActions.setProjectVideos(updatedVideos));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
    }

    if (currentAudios.length) {
        const changedMedias = currentAudios.map(
            (audio) => {
                let currentAudio = audio;
                currentAudio = {
                    ...currentAudio,
                    volume: isSwitchToClean ? 0 : 1,
                    enhancedVolume: isSwitchToClean ? 1 : 0,
                    enhanced: isSwitchToClean,
                };
                return currentAudio;
            }
        );

        updatedAudios = [...updatedAudios, ...changedMedias];

        const updatedProject = {
            ...projectDetails,
            audios: updatedAudios,
        }

        yield put(ProjectsActions.setProjectAudios(updatedAudios));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
    }
}

export function* handleSubmitTemplate(
    action: SubmitTemplatesAction
): Generator {
    const template = action?.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = template ? template.id : projectDetails.id;
    const projectVersion = template ? template.version : projectDetails.version;

    try {
        const res = (yield call(
            ProjectsClient.submitTemplate,
            {
                projectId,
                version: projectVersion,
            }
        )) as AxiosResponse;

        showNotification(
            NotificationTypes.success,
            'Successfully submitted'
        );

        if(template) {
            yield put(ProjectsActions.getProjects({isTemplate: true}));
        } else {
            yield put(ProjectsActions.syncProjectDetails());
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleReviewTemplate(
    action: ReviewTemplateAction
): Generator {
    const { template, status } = action?.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = template ? template.id : projectDetails.id;
    const projectVersion = template ? template.version : projectDetails.version;

    try {
        const res = (yield call(
            ProjectsClient.reviewTemplate,
            {
                projectId,
                version: projectVersion,
                status,
            }
        )) as AxiosResponse;

        showNotification(
            NotificationTypes.success,
            'Successfully Reviewed'
        );

        if(template) {
            yield put(ProjectsActions.getProjects({isTemplate: true}));
        } else {
            yield put(ProjectsActions.syncProjectDetails());
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handlePublishTemplate(
    action: PublishTemplateAction
): Generator {
    const template = action?.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const projectId = template ? template.id : projectDetails.id;
    const projectVersion = template ? template.version : projectDetails.version;

    try {
        const res = (yield call(
            ProjectsClient.publishTemplate,
            {
                projectId,
                version: projectVersion,
            }
        )) as AxiosResponse;

        showNotification(
            NotificationTypes.success,
            'Successfully Published'
        );

        if(template) {
            yield put(ProjectsActions.getProjects({isTemplate: true}));
        } else {
            yield put(ProjectsActions.syncProjectDetails());
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleUpdateTemplate(
    action: UpdateProjectTemplateAction
): Generator {
    const template = action?.payload || {} as Project;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const templateId = template ? template.id : projectDetails.id;

    try {
        const res = (yield call(
            ProjectsClient.updateTemplate,
            {
                templateId,
                templateData: template,
            }
        )) as AxiosResponse;

        showNotification(
            NotificationTypes.success,
            'Successfully unpublished'
        );

        yield put(ProjectsActions.getPublishedTemplates());
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetSubmittedTemplates(action: GetSubmittedTemplatesAction): Generator {
    yield put(ProjectsActions.getSubmittedTemplatesStart());

    const isLoadMore = action?.payload?.isLoadMore;

    try {
        const oldTemplates = (yield select(getSubmittedTemplatesList)) as Project[];
        const templatesCount = (yield select(getSubmittedTemplatesCount)) as number;
        const sort = (yield select(getProjectsSort)) as ProjectSort;

        const res = (yield call(ProjectsClient.getSubmittedTemplates, {
            offset: isLoadMore ? templatesCount : 0,
            sort,
        })) as AxiosResponse;

        let templates = res.data.content;
        const templatesTotal = res.data._metadata.totalCount;

        if (isLoadMore) {
            templates = [...oldTemplates, ...templates];
        }

        yield put(
            ProjectsActions.getSubmittedTemplatesSuccess({
                templates,
                templatesTotal,
            })
        );

        // yield delay(30000);
        // yield put(ProjectsActions.syncSubmittedTemplatesList());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.getSubmittedTemplatesFail());
    }
}

export function* handleGetPublishedTemplates(action: GetPublishedTemplatesAction): Generator {
    yield put(ProjectsActions.getPublishedTemplatesStart());

    const isLoadMore = action?.payload?.isLoadMore;

    try {
        const oldTemplates = (yield select(getPublishedTemplatesList)) as Project[];
        const templatesCount = (yield select(getPublishedTemplatesCount)) as number;
        const sort = (yield select(getProjectsSort)) as ProjectSort;

        const res = (yield call(ProjectsClient.getPublishedTemplates, {
            offset: isLoadMore ? templatesCount : 0,
            sort,
        })) as AxiosResponse;

        let templates = res.data.content;
        const templatesTotal = res.data._metadata.totalCount;

        if (isLoadMore) {
            templates = [...oldTemplates, ...templates];
        }

        yield put(
            ProjectsActions.getPublishedTemplatesSuccess({
                templates,
                templatesTotal,
            })
        );

        // yield delay(30000);
        // yield put(ProjectsActions.syncProjectsList());
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.getPublishedTemplatesFail());
    }
}

export function* handleGetUsableTemplates(action: GetUsableTemplatesAction): Generator {
    yield put(ProjectsActions.getUsableTemplatesStart());

    const isLoadMore = action?.payload?.isLoadMore;

    try {
        const oldTemplates = (yield select(getUsableTemplatesList)) as Project[];
        const templatesCount = (yield select(getUsableTemplatesCount)) as number;

        const res = (yield call(ProjectsClient.getUsableTemplates, {
            offset: isLoadMore ? templatesCount : 0,
            sort: ProjectSort.NAME,
        })) as AxiosResponse;

        let templates = res.data.content;
        const templatesTotal = res.data._metadata.totalCount;

        if (isLoadMore) {
            templates = [...oldTemplates, ...templates];
        }

        yield put(
            ProjectsActions.getUsableTemplatesSuccess({
                templates,
                templatesTotal,
            })
        );
    } catch (error) {
        console.log({ error });
        yield put(ProjectsActions.getUsableTemplatesFail());
    }
}

export function* handleUseTemplateInProject(action: UseTemplateInProjectAction): Generator {
    try {
        const { template } = action?.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        const templateData = {
            ...template,
        };

        if(templateData.templateStatus) {
            delete templateData.templateStatus;
        }

        const videos = templateData?.videos;
        const stockVideos = videos?.filter(item => item?.isStockMedia);
        let newStockMediaUrls = {} as any;
        let modifiedVideos = [...videos];

        if (stockVideos?.length) {
            if(currentPlan?.planNameId !== PlanNameId.INITIAL) {
                const fixedSize = 'hd15';

                const downloadRequests = stockVideos.map((media) =>
                    safeCall(StockMediaClient.downloadStockVideo, media.mediaSourcesId, fixedSize)
                );

                const downloadResults: SafeCallResult<any>[] = (yield all(downloadRequests)) as SafeCallResult<any>[];

                newStockMediaUrls = downloadResults.reduce((acc: { [key: string]: string }, result, index) => {
                    if (result.status === 'fulfilled') {
                        const media = stockVideos[index] as ProjectVideo;
                        return { ...acc, [media.id]: result.value.data.uri };
                    }
                    return acc;
                }, {});

                modifiedVideos = videos.map(video => {
                    if (video?.isStockMedia && newStockMediaUrls[video.id]) {
                        return {
                            ...video,
                            stockMediaUrl: newStockMediaUrls[video.id],
                        };
                    }
                    return video;
                });
            } else {
                const mediaRequests = stockVideos.map((media) =>
                    call(StockMediaClient.getStockVideoSizes, media.mediaSourcesId)
                );


                try {
                    const results = (yield all(mediaRequests)) as any[];

                    newStockMediaUrls = results?.reduce((acc, res, index) => {
                        const media = stockVideos[index] as ProjectVideo;
                        return { ...acc, [media.id]: res?.data?.display_sizes?.[0]?.uri};
                    }, {});
                } catch (error) {
                    console.error('Error downloading media:', error);
                }

                modifiedVideos = videos.map(
                    video => {
                        if(video?.isStockMedia && newStockMediaUrls[video.id]) {
                            return {
                                ...video,
                                stockMediaUrl: newStockMediaUrls?.[video.id] || video?.stockPreviewUrl || video?.stockMediaUrl,
                            }
                        }
                        return video;
                    }
                );
            }
        }

        const updatedProject = {
            ...templateData,
            id: projectDetails.id,
            createdAt: projectDetails.createdAt,
            updatedAt: projectDetails.updatedAt,
            version: projectDetails.version,
            workspaceId: projectDetails.workspaceId,
            isTemplate: false,
            isPublished: false,
            folder: projectDetails?.folder || '',
            videos: modifiedVideos,
        } as Project;

        if(!projectDetails?.folder) {
            delete updatedProject.folder;
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
                isUseTemplate: true,
            })
        );
    } catch (error) {
        console.error('Error using a template:', error);
    }
}

export function* handleChangeTextAnimation(
    action: ChangeTextAnimationAction
): Generator {
    const {
        animationType,
        position,
        textObject,
    } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const timelineSelectedObjects = (yield select(
        getTimelineSelectedObjects
    )) as SelectedProjectObject[];

    try{
        const updatedSelectedTimelineObjects = timelineSelectedObjects?.map(item => {
            if (
                item.type === SelectedProjectObjectType.TEXT &&
                item.object.id === textObject.object.id
            ) {
                const textItem = item.object as ProjectText;
                return {
                    ...item,
                    object: {
                        ...textItem,
                        animations: {
                            ...(textItem.animations || {}),
                            [position]: {
                                type: animationType
                            },
                        }
                    }
                };
            }
            return item;
        });

        const updatedProjectTexts = projectDetails.texts?.map(item => {
            if (
                item.id === textObject.object.id
            ) {
                return {
                    ...item,
                    animations: {
                        ...(item.animations || {}),
                        [position]: {
                            type: animationType
                        },
                    }
                };
            }
            return item;
        });

        yield put(
            ProjectsStudioActions.setProjectSelectedObjects(
                updatedSelectedTimelineObjects
            )
        );

        yield put(
            ProjectsActions.updateProject({
                project: {
                    ...projectDetails,
                    texts: updatedProjectTexts,
                },
            })
        );

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);


        googleAnalytics.textAnimationsApplied({
            type: animationType,
        });

        amplitudeAnalytics.textAnimationsApplied({
            type: animationType,
        });
    } catch(error) {
        console.log({ error });
        yield take(ProjectsActionTypes.UPDATE_PROJECT_FAIL);
    }
}

export function* handleChangeAudioFade(
    action: ChangeAudioFadeAction
): Generator {
    try {
        const { fadeProps, media, isVideo } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        let updatedProject = {
            ...projectDetails,
        } as Project;

        const updatedProps = {} as AudioFadeProps;

        if(fadeProps) {
            if(fadeProps.hasOwnProperty('audioFadeInDuration')) {
                updatedProps.audioFadeInDuration = fadeProps.audioFadeInDuration;
            }
            if(fadeProps.hasOwnProperty('audioFadeOutDuration')) {
                updatedProps.audioFadeOutDuration = fadeProps.audioFadeOutDuration;
            }
        }

        if(isVideo) {
            const updatedVideos = (projectDetails?.videos || []).map((video) => {
                if (video.id === media?.id) {
                    let updatedVideo = {...video};

                    if(!fadeProps) {
                        if(updatedVideo.hasOwnProperty('audioFadeInDuration')) {
                            delete updatedVideo.audioFadeInDuration;
                        }
                        if(updatedVideo.hasOwnProperty('audioFadeOutDuration')) {
                            delete updatedVideo.audioFadeOutDuration;
                        }
                    } else {
                        updatedVideo = {
                            ...updatedVideo,
                            ...updatedProps,
                        };
                    }

                    return {
                        ...updatedVideo
                    };
                }

                return video;
            });

            updatedProject = {
                ...projectDetails,
                videos: updatedVideos,
            };

            yield put(ProjectsActions.setProjectVideos(updatedVideos));
        } else {
            const updatedAudios = (projectDetails?.audios || []).map((audio) => {
                if (audio.id === media?.id) {
                    let updatedAudio = {...audio};

                    if(!fadeProps) {
                        if(updatedAudio.hasOwnProperty('audioFadeInDuration')) {
                            delete updatedAudio.audioFadeInDuration;
                        }
                        if(updatedAudio.hasOwnProperty('audioFadeOutDuration')) {
                            delete updatedAudio.audioFadeOutDuration;
                        }
                    } else {
                        updatedAudio = {
                            ...updatedAudio,
                            ...updatedProps,
                        };
                    }

                    return {
                        ...updatedAudio
                    };
                }

                return audio;
            });

            updatedProject = {
                ...projectDetails,
                audios: updatedAudios,
            };

            yield put(ProjectsActions.setProjectAudios(updatedAudios));
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
                skipVersionUpdate: true,
            })
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleChangeAudioFade', action?.payload);

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            'An error occurred while changing audio fade settings.'
        );
    }
}

export function* handleChangeAudioVolume(
    action: ChangeAudioVolumeAction
): Generator {
    try {
        const {media, newVolume, isVideo } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        let updatedProject = {
            ...projectDetails,
        } as Project;

        if(isVideo) {
            const videoIsEnhanced = (
                media?.hasOwnProperty('enhancedVolume') &&
                media.enhancedVolume !== undefined &&
                media?.enhanced
            );

            let videoIsDubbed = false;

            if ('dubVolume' in media) {
                videoIsDubbed = !!(
                    media?.hasOwnProperty('dubVolume') &&
                    media.dubVolume !== undefined &&
                    media?.audioLanguage
                );
            }

            const updatedVideos = (projectDetails?.videos || []).map((video) => {
                if (video.id === media?.id) {
                    if(videoIsDubbed) {
                        return {
                            ...video,
                            dubVolume: toFixedWithoutRounding(newVolume, 1),
                        };
                    }
                    if(videoIsEnhanced) {
                        return {
                            ...video,
                            enhancedVolume: toFixedWithoutRounding(newVolume, 1),
                        };
                    }
                    return {
                        ...video,
                        videoVolume: toFixedWithoutRounding(newVolume, 1),
                    };
                }

                return video;
            });

            updatedProject = {
                ...updatedProject,
                videos: updatedVideos,
            } as Project;

            yield put(ProjectsActions.setProjectVideos(updatedVideos));
        } else {
            const updatedAudios = (projectDetails.audios || []).map((audio) => {
                if (audio.id === media?.id) {
                    return {
                        ...audio,
                        volume: toFixedWithoutRounding(newVolume, 1),
                    };
                }

                return audio;
            });

            updatedProject = {
                ...updatedProject,
                audios: updatedAudios,
            } as Project;

            yield put(ProjectsActions.setProjectAudios(updatedAudios));
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleChangeAudioVolume', action?.payload);

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            'An error occurred while changing audio volume.'
        );
    }
}

export function* handleDeleteProjectVideo(
    updatedProject: Project,
    removableItems: string[],
): Generator {
    try {
        const mediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const mediaSourcesSettings = (yield select(
            getProjectMediaSourceSettings
        )) as ProjectMediaSourcesSettings[];
        const audioMediaSources = (yield select(
            getProjectAudioMediaSources
        )) as ProjectAudioMediaSource[];

        const videoIds = removableItems;

        let projectVideos = (yield select(getProjectVideos)) as ProjectVideo[];
        projectVideos = sortBy(projectVideos, 'startTime');

        const filteredVideos = projectVideos.filter(
            (video) => !videoIds.includes(video.id)
        );

        yield put(ProjectsActions.setProjectVideos(filteredVideos));

        const updatedTransitions = (yield call(
            checkVideosTransitions
        )) as VideoTransition[];

        // const filterdMediaSources = mediaSources.filter((mediaSource) => {
        //     const mediaSourceVideos = filteredVideos.filter(
        //         (video) => video.mediaSourcesId === mediaSource.id
        //     );
        //
        //     // const hasDetachedAudio = audioMediaSources.some(item => item?.videoId === mediaSource.id);
        //     // todo should get project audios, then see if there are audios
        //     //  with audioMediaSources for which the original mediaSource id is this one
        //
        //     return (mediaSourceVideos.length > 0);
        // });

        const filteredMediaSources = mediaSources.filter(
            (mediaSource) => filteredVideos.some(
                video => video.mediaSourcesId === mediaSource.id
            )
        );

        const filteredMediaSourcesIds = filteredMediaSources.map(
            (item) => item.id
        );

        const filteredMediaSourcesSettings = mediaSourcesSettings.filter(
            item => filteredMediaSourcesIds?.some(id => item.mediaSourceId === id)
        );

        updatedProject.videos = filteredVideos;
        updatedProject.transitions = updatedTransitions;
        updatedProject.mediaSources = filteredMediaSourcesIds;
        updatedProject.mediaSourcesSettings = filteredMediaSourcesSettings;

        yield put(
            ProjectsActions.setProjectVideosTransitions(updatedTransitions)
        );

        yield put(ProjectsActions.setProjectMediaSources(filteredMediaSources));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleDeleteProjectAudio(
    updatedProject: Project,
    removableItems: string[],
): Generator {
    const audioIds = removableItems;
    const filteredAudios = updatedProject.audios.filter(
        (audio) => !audioIds.includes(audio.id)
    );
    const selectedAudios = updatedProject.audios.filter((audio) =>
        audioIds.includes(audio.id)
    );
    const selectedAudiosIds = selectedAudios.map((item) => item.videoId);

    const selectedVideos = (updatedProject?.videos || []).filter((video) =>
        selectedAudiosIds.includes(video.id)
    );

    const selectedVideosIds = selectedVideos.map((item) => item.id);

    if (selectedVideos) {
        const updatedProjectVideos = updatedProject.videos.map((video) => {
            if (selectedVideosIds.includes(video.id)) {
                return {
                    ...video,
                    isAudioDetached: false,
                    videoVolume: 0,
                };
            }

            return video;
        });

        updatedProject.videos = updatedProjectVideos;

        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
    }

    updatedProject.audios = filteredAudios;

    yield put(ProjectsActions.setProjectAudios(filteredAudios));

    // yield put(
    //     ProjectsActions.updateProject({
    //         project: updatedProject,
    //     })
    // );
    // yield put(ProjectsStudioActions.setProjectSelectedObjects([]));
    yield put(ProjectsStudioActions.removeSelectedObjects(selectedAudiosIds)); // todo
}

export function* handleDeleteProjectImage(
    updatedProject: Project,
    removableItems: string[],
): Generator {
    const imageIds = removableItems;
    // const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const filteredImages = updatedProject.images.filter(
        (image) => !imageIds.includes(image.id)
    );

    updatedProject.images = filteredImages;

    // yield put(
    //     ProjectsActions.updateProject({
    //         project: updatedProject,
    //     })
    // );
    // yield put(ProjectsStudioActions.setProjectSelectedObjects([]));
    yield put(ProjectsStudioActions.removeSelectedObjects(imageIds));
}

export function* handleDeleteProjectText(
    updatedProject: Project,
    removableItems: string[],
): Generator {
    const ids = removableItems;
    // const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const filteredProjectTexts = (updatedProject?.texts || []).filter(
        (text) => !ids.includes(text.id)
    );

    updatedProject.texts = filteredProjectTexts;
    // const updatedProjectDetails = {
    //     ...projectDetails,
    //     texts: filteredProjectTexts,
    // } as Project;

    // yield put(
    //     ProjectsActions.updateProject({
    //         project: updatedProjectDetails,
    //     })
    // );
    // yield put(ProjectsStudioActions.setProjectSelectedObjects([]));
    yield put(ProjectsStudioActions.removeSelectedObjects(ids));
}

export function* handleDeleteProjectElement(
    updatedProject: Project,
    removableItems: string[],
): Generator {
    const elementIds = removableItems;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const filteredElements = projectDetails.elements.filter(
        (element) => !elementIds.includes(element.id)
    );

    updatedProject.elements = filteredElements;
    // const updatedProject = {
    //     ...projectDetails,
    //     elements: filteredElements,
    // } as Project;

    // yield put(
    //     ProjectsActions.updateProject({
    //         project: updatedProject,
    //     })
    // );
    // yield put(ProjectsStudioActions.setProjectSelectedObjects([]));
    yield put(ProjectsStudioActions.removeSelectedObjects(elementIds));
}

export function* handleDeleteSelectedItems(
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    let updatedProject = {
        ...projectDetails,
    } as Project;

    const selectedTimelineObjects = (yield select(
        getTimelineSelectedObjects
    )) as SelectedProjectObject[];
    const selectedTimelineAudios = (yield select(
        getTimelineSelectedAudios
    )) as SelectedProjectObject[];
    const selectedTimelineVideos = (yield select(
        getTimelineSelectedVideos
    )) as SelectedProjectObject[];
    const selectedTimelineAudiosWithSubtitles = (yield select(
        getTimelineSelectedAudiosWithSubtitles
    )) as SelectedProjectObject[];
    const selectedTimelineElements = (yield select(
        getTimelineSelectedElements
    )) as SelectedProjectObject[];
    const selectedTimelineImages = (yield select(
        getTimelineSelectedImages
    )) as SelectedProjectObject[];
    const selectedTimelineTexts = (yield select(
        getTimelineSelectedText
    )) as SelectedProjectObject[];
    const selectedTimelineSentences = (yield select(
        getTimelineSelectedSentences
    )) as SelectedProjectObject[];
    const mediaSources = (yield select(
        getProjectMediaSources
    )) as MediaFile[];

    if (selectedTimelineObjects) {
        if (selectedTimelineVideos.length) {
            const ids = selectedTimelineVideos.map(
                (item) => item.object.id
            );
            yield put(ProjectsStudioActions.setPojectPlaying(false));
            yield call(handleDeleteProjectVideo, updatedProject, ids);
        }

        if (selectedTimelineAudiosWithSubtitles.length) {
            const ids = selectedTimelineAudiosWithSubtitles.map(
                (item) => item.object.id
            );
            yield put(ProjectsStudioActions.setPojectPlaying(false));
            yield call(handleDeleteProjectVideo, updatedProject, ids);
        }

        if (selectedTimelineAudios.length) {
            const ids = selectedTimelineAudios.map(
                (item) => item.object.id
            );
            yield call(handleDeleteProjectAudio, updatedProject, ids);
        }

        if (selectedTimelineImages.length) {
            const ids = selectedTimelineImages.map(
                (item) => item.object.id
            );
            yield call(handleDeleteProjectImage, updatedProject, ids);
        }

        if (selectedTimelineTexts.length) {
            const ids = selectedTimelineTexts.map((item) => item.object.id);

            yield call(handleDeleteProjectText, updatedProject, ids);
        }

        if (selectedTimelineElements.length) {
            const ids = selectedTimelineElements.map(
                (item) => item.object.id
            );

            yield call(handleDeleteProjectElement, updatedProject, ids);
        }

        if (selectedTimelineSentences.length) {
            const items =  selectedTimelineSentences.map((item) => ({
                    id: item.object.id,
                    language: (item.object as TransformedSentence)
                        .language,
                    videoId: (item.object as TransformedSentence)
                        ?.videoId,
                })) as DeletedSentence[];

            yield call(handleDeleteSelectedSubtitles, updatedProject, items);
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield put(ProjectsStudioActions.setProjectSelectedObjects([]));

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);
        if(selectedTimelineVideos.length) {
            const projectDetailsUpdated = (yield select(getProjectDetailsInfo)) as Project;
            const filteredMediaSourcesIds = updatedProject.mediaSources;

            const deletedMediaSources = mediaSources.filter(
                item => !filteredMediaSourcesIds.some(id => id === item.id)
            );

            const deletedMediaSourcesIds = deletedMediaSources.map(item => item.id);
            if(deletedMediaSourcesIds.length) {
                yield put(subtitlesActions.deleteVideoSubtitles({
                    projectDetails: projectDetailsUpdated,
                    videoIds: deletedMediaSourcesIds,
                }));
            }
        }

    }
}

export function* projectsSaga(): Generator {
    yield takeLatest(
        ProjectsActionTypes.GO_BY_PROJECT_HISTORY,
        handleGoByProjectHistory
    );
    yield takeLatest(ProjectsActionTypes.GET_PROJECTS, handleGetProjects);
    yield takeLatest(ProjectsActionTypes.SYNC_PROJECTS, handleSyncProjects);
    yield takeLatest(
        ProjectsActionTypes.GET_PROJECT_DETAILS,
        handleInitProject
    );
    yield takeLatest(
        ProjectsActionTypes.SYNC_PROJECT_DETAILS,
        handleSyncProjectDetails
    );
    yield takeEvery(ProjectsActionTypes.DELETE_PROJECT, handleDeleteProject);
    yield takeLatest(ProjectsActionTypes.CREATE_PROJECT, handleCreateProject);
    yield takeLatest(
        ProjectsActionTypes.CREATE_PROJECT_FROM_UPLOAD,
        handleCreateProjectFromUpload
    );
    yield takeLatest(
        ProjectsActionTypes.GENERATE_PROJECT,
        handleGenerateProject
    );
    yield takeLatest(
        ProjectsActionTypes.CREATE_DEFAULT_PROJECT,
        handleCreateDefaultProject
    );
    yield takeLatest(
        ProjectsActionTypes.ADD_VIDEOS_FROM_LIBRARY_TO_PROJECT,
        handleAddVideosFromLibraryToProject
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_IMAGE,
        handleUpdateProjectImage
    );
    yield takeLatest(
        ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO,
        handleDetachAudioFromVideo
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_AUDIO,
        handleUpdateProjectAudio
    );
    yield takeLatest(
        ProjectsActionTypes.CREATE_PROJECT_TEXT,
        handleCreateProjectText
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_TEXT,
        handleUpdateProjectText
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT,
        handleLocalProjectUpdate
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_VIDEO,
        handleUpdateProjectVideo
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_VIDEOS,
        handleUpdateProjectVideos
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_PREFERENCES,
        handleUpdateProjectPreferences
    );
    yield takeLatest(
        ProjectsActionTypes.RESET_PROJECT_TEXT_SETTINGS,
        handleResetProjectTextSettings
    );
    // elements
    yield takeLatest(
        ProjectsActionTypes.GET_ELEMENTS_LIST,
        handleLoadElementsList
    );
    yield takeLatest(
        ProjectsActionTypes.CREATE_PROJECT_ELEMENT,
        handleCreateProjectElement
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_ELEMENT,
        handleUpdateProjectElement
    );
    yield takeLatest(
        ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH,
        handleGenerateTextToSpeech
    );
    yield takeLatest(
        ProjectsActionTypes.DUPLICATE_PROJECT,
        handleDuplicateProject
    );
    yield takeLatest(
        ProjectsActionTypes.CREATE_PROJECT_WITH_STOCK_VIDEO,
        handleCreateProjectWithStockVideo
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_AUDIOS,
        handleUpdateProjectAudios
    );
    yield takeLatest(
        ProjectsActionTypes.GET_MEDIA_AUDIO_OPTIONS,
        handleGetMediaAudioOptions
    )
    yield takeLatest(
        ProjectSubtitlesActionTypes.GENERATE_PROJECT_TRANSLATION_FOR_DUBBING,
        handleTranslateForDubbing
    );
    yield takeLatest(
        ProjectsActionTypes.GENERATE_PROJECT_AUDIO_DUBBING,
        handleGenerateDubbing
    );
    yield takeLatest(
        ProjectsActionTypes.DELETE_PROJECT_DUBBING_LANGUAGE,
        handleDeleteProjectDubbingLanguage
    );
    yield takeLatest(
        ProjectsActionTypes.CHANGE_AUDIO_LANGUAGE,
        handleChangeAudioLanguage
    );
    yield takeLatest(
        ProjectsActionTypes.GET_PROJECT_UPLOADS,
        handleGetProjectUploads,
    );
    yield takeLatest(
        ProjectsActionTypes.CREATE_PROJECT_UPLOAD,
        handleCreateProjectUpload,
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_PROJECT_UPLOAD,
        handleUpdateProjectUpload,
    );
    yield takeLatest(
        ProjectsActionTypes.DELETE_PROJECT_UPLOAD,
        handleDeleteProjectUpload,
    );
    yield takeLatest(
        ProjectsActionTypes.GENERATE_CLEAN_AUDIO,
        handleGenerateCleanAudio,
    );
    yield takeLatest(
        ProjectsActionTypes.CHANGE_ENHANCED_AUDIO,
        handleChangeEnhancedAudio
    );
    yield takeLatest(
        ProjectsActionTypes.SUBMIT_TEMPLATE,
        handleSubmitTemplate
    );
    yield takeLatest(
        ProjectsActionTypes.REVIEW_TEMPLATE,
        handleReviewTemplate
    );
    yield takeLatest(
        ProjectsActionTypes.PUBLISH_TEMPLATE,
        handlePublishTemplate
    );
    yield takeLatest(
        ProjectsActionTypes.UPDATE_TEMPLATE,
        handleUpdateTemplate
    );
    yield takeLatest(
        ProjectsActionTypes.GET_SUBMITTED_TEMPLATES,
        handleGetSubmittedTemplates
    );
    yield takeLatest(
        ProjectsActionTypes.GET_PUBLISHED_TEMPLATES,
        handleGetPublishedTemplates
    );
    yield takeLatest(
        ProjectsActionTypes.GET_USABLE_TEMPLATES,
        handleGetUsableTemplates
    );
    yield takeLatest(
        ProjectsActionTypes.USE_TEMPLATE,
        handleUseTemplateInProject,
    )
    yield takeLatest(
        ProjectsActionTypes.CHANGE_TEXT_ANIMATION,
        handleChangeTextAnimation
    );
    yield takeLatest(
        ProjectsActionTypes.CHANGE_AUDIO_FADE,
        handleChangeAudioFade
    );
    yield takeLatest(
        ProjectsActionTypes.CHANGE_AUDIO_VOLUME,
        handleChangeAudioVolume
    );
    yield takeLatest(
        ProjectsActionTypes.DELETE_SELECTED_ITEMS,
        handleDeleteSelectedItems
    );
    yield all([
        call(handleSyncProjectMediaSources),
        call(handleSyncProjectUpdate),
        call(handleAddTempMediaFilePreview),
        call(handleCreateProjectTempVideoPreviews),
    ]);
}
