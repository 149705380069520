import {
  AnalysisResultExportItem,
  SentenceExportItem,
  ShotExportItem,
} from 'state/modules/export';
import { AnalysisResultsType } from './analysis';

export enum EdlExportSettingsOptionsTitle {
    ObjectPerFile = 'Object per file',
    VideoPerFile = 'Video per file',
}

export interface EdlFileSettingsOption {
    value: EdlExportSettingsOptionsTitle;
    id: number;
}

export interface EdlExportFpsOption {
    value: number;
    id: number;
}

export interface EdlMergeIntervalOption {
    value: number;
    id: number;
}

export interface EdlAnalysisResultExportItem
    extends Omit<AnalysisResultExportItem, 'timestamps'> {
    timestamps: Array<number>;
}
export interface EdlTranscriptExportItem
    extends Omit<SentenceExportItem, 'timestamps'> {
    timestamps: Array<number>;
}
export interface EdlShotExportItem extends Omit<ShotExportItem, 'timestamps'> {
    timestamps: Array<number>;
}

export interface TempTransformedEdlExportItem {
    startTime: number;
    endTime: number;
    name: string;
    originalFileName: string;
}

export interface TransformedEdlExportItem extends TempTransformedEdlExportItem {
    FileName: string;
}

export type EdlExportItem =
    | EdlAnalysisResultExportItem
    | EdlShotExportItem
    | EdlTranscriptExportItem;

export type GroupedByTypeEdlExportData = {
    [key in AnalysisResultsType]: Array<EdlExportItem>;
};

export type GroupedByVideoIdEdlExportData = {
    [key: string]: GroupedByTypeEdlExportData;
};

export interface EdlExportData {
    selectedItems: Array<EdlExportItem>;
    groupedData: GroupedByVideoIdEdlExportData;
}

export interface EdlExportScene {
    from: number;
    to: number;
}

export interface TransformedOffsetSettings {
  id: string,
  value: number;
}