import React, { memo } from 'react';
import Loader from 'react-loader-spinner';

import { Icon } from 'components/UI';

import { RekognitionCategory } from 'interfaces/analysis';
import { StatusType } from 'interfaces/statuses';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface StatusProps {
  label: string;
  status: string;
}

const Status: React.FC<StatusProps> = memo(({ label, status }) => {
  const renderStatus = (statusValue: string): string => {
    switch (statusValue) {
      case StatusType.IN_PROGRESS:
        return 'In Progress';
      case StatusType.SAVING:
        return 'Saving';
      case StatusType.PREPARING:
        return 'Preparing';
      case StatusType.COMPLETED:
        return 'Completed';
      case StatusType.SUCCEEDED:
        return 'Completed';
      case StatusType.FAILED:
        return 'Failed';
      case 'MP4 FAILED':
        return 'MP4 Failed';
      default:
        return '';
    }
  };

  const renderLabel = () => {
    switch (label) {
      case RekognitionCategory.AWS_CELEBRITIES:
        return 'Celebrities';
      case RekognitionCategory.AWS_FACE_MATCHES:
        return 'Collections';
      case RekognitionCategory.AWS_FACES:
        return 'Faces';
      case RekognitionCategory.AWS_LABELS:
        return 'Objects and Activities';
      case RekognitionCategory.AWS_TRANSCRIPT:
        return 'Transcript';
      case RekognitionCategory.AWS_SHOTS:
        return 'Shots';
      case RekognitionCategory.AWS_MODERATED_LABELS:
        return 'Moderated Labels';
      case RekognitionCategory.AWS_TRANSLATE:
        return 'Translate';

      default:
        return '';
    }
  };

  const renderIcon = (): JSX.Element => {
    switch (status) {
      case StatusType.COMPLETED:
      case StatusType.SUCCEEDED:
        return <Icon name="success-check" color={Colors.TURQUOISE} />;
      case StatusType.PREPARING:
      case StatusType.SAVING:
      case StatusType.IN_PROGRESS:
      case StatusType.INPROGRESS:
        return (
          <Loader
            type="Oval"
            color="#fd0b50"
            height={20}
            width={20}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={styles.Status}>
      <div className={styles.Status__iconWrap}>
        <div className={[styles.Status__icon].join(' ')}>
          {renderIcon()}
        </div>
      </div>

      <span className={styles.Status__label}>
        {renderLabel()}
        :
      </span>
      <span
        className={[
          styles.Status__status,
          (
            status === StatusType.COMPLETED ||
            status === StatusType.SUCCEEDED
          ) && styles.Status__status_success,
          (
            status === StatusType.IN_PROGRESS ||
            status === StatusType.SAVING ||
            status === StatusType.WAITING ||
            status === StatusType.PREPARING
          ) && styles.Status__status_inProgress
        ].join(' ')}
      >
        {renderStatus(status)}
      </span>
    </div>
  );
});

export default Status;
