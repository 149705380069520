import { v4 as uuid } from 'uuid';
import {
    RawUploadingFileMetadata,
    TransformedUploadingFileMetadata,
    UploadingMedia,
} from 'interfaces/uploading';

export const getTrasformedMetadaForImageUpload = (metadata: {
    imageName: string;
    collectionId?: string;
}): TransformedUploadingFileMetadata => {
    const { imageName, collectionId } = metadata;

    const transformedMetadata = {} as TransformedUploadingFileMetadata;

    if (collectionId) {
        transformedMetadata['collection-id'] = collectionId;
    }

    if (collectionId && imageName) {
        transformedMetadata['collection-id'] = collectionId;
        transformedMetadata['image-name'] = imageName;
    }

    return transformedMetadata;
};

export const getTrasformedMetadaForMediaFileUpload = (
    mediaFile: any,
    fps?: number
): TransformedUploadingFileMetadata => {
    let uploadingFileOptions = {
        videoTitle: encodeURI(mediaFile.title) || '',
        videoDescription: encodeURI(mediaFile.description) || '',
    } as RawUploadingFileMetadata;

    if (mediaFile.autoAnalyze) {
        uploadingFileOptions = {
            ...uploadingFileOptions,
            transcriptLanguage:
                mediaFile.transcriptLanguage !== 'Autodetect' &&
                mediaFile.transcriptLanguage !== 'en-EN'
                    ? mediaFile.transcriptLanguage
                    : null,
            collectionId: mediaFile.collectionId,
            autoAnalyze: mediaFile.autoAnalyze,
            categories: mediaFile.categories,
        };
    } else {
        uploadingFileOptions = {
            ...uploadingFileOptions,
            autoAnalyze: false,
        };
    }

    const {
        transcriptLanguage,
        autoAnalyze,
        categories,
        collectionId,
        imageName,
        videoTitle,
        videoDescription,
    } = uploadingFileOptions;

    const transformedData = {} as TransformedUploadingFileMetadata;

    if (transcriptLanguage) {
        transformedData['audio-language'] = transcriptLanguage;
    }

    if (autoAnalyze !== null && autoAnalyze !== undefined) {
        transformedData['auto-analyze'] = autoAnalyze ? 'true' : 'false';
    }

    if (categories) {
        transformedData.categories = JSON.stringify(categories);
    }

    if (collectionId) {
        transformedData['collection-id'] = collectionId;
    }

    if (collectionId && imageName) {
        transformedData['collection-id'] = collectionId;
        transformedData['image-name'] = imageName;
    }

    if (videoTitle) {
        transformedData['video-title'] = videoTitle;
    }

    if (videoDescription) {
        transformedData['video-description'] = videoDescription;
    }

    if (fps) {
        transformedData['video-fps'] = `${fps * 1000}`;
    }

    return transformedData;
};

export const fileExtensionsMap = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    mp3: 'audio/mp3',
    wav: 'audio/wav',
    wave: 'audio/wav',
    flac: 'audio/flac',
    mp4: 'video/mp4',
    webm: 'video/webm',
    mov: 'video/quicktime',
};

export const imageFileExtensionsMap = {
    png: 'png',
    jpg: 'jpeg',
    jpeg: 'jpeg',
};

export const imageFileTypesMap = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
};

export const getTransformedUploadingFileName = (
    media: UploadingMedia,
    workspaceId: string
): string => {
    const { id } = media;
    const fileName = media.file.name;

    const splittedNameString = fileName.split('.');
    const fileExt =
        splittedNameString && splittedNameString.length > 0
            ? splittedNameString.pop()
            : '';

    return [
        'workspace',
        workspaceId,
        'media',
        id,
        'content',
        ['source', fileExt].join('.'),
    ].join('/');
};

export const getUploadingFileContentType = (fileName: string): string => {
    const splittedNameString = fileName.split('.');
    const fileExt =
        splittedNameString && splittedNameString.length > 0
            ? splittedNameString.pop()
            : '';
    const rawLowCasefileExt = fileExt ? fileExt.toLowerCase() : '';

    return fileExtensionsMap[
        rawLowCasefileExt as keyof typeof fileExtensionsMap
    ];
};
