import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 15 / 20;

const RequestFeatureIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 15 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_418_17480)">
                <path
                    d="M2.5 0C1.12109 0 0 1.12109 0 2.5V17.5C0 18.8789 1.12109 20 2.5 20H12.5C13.8789 20 15 18.8789 15 17.5V6.25H10C9.30859 6.25 8.75 5.69141 8.75 5V0H2.5ZM10 0V5H15L10 0ZM8.4375 15.9375C8.4375 16.457 8.01953 16.875 7.5 16.875C6.98047 16.875 6.5625 16.457 6.5625 15.9375V11.9492L5.35156 13.1602C4.98438 13.5273 4.39062 13.5273 4.02734 13.1602C3.66406 12.793 3.66016 12.1992 4.02734 11.8359L6.83984 9.02344C7.20703 8.65625 7.80078 8.65625 8.16406 9.02344L10.9766 11.8359C11.3438 12.2031 11.3438 12.7969 10.9766 13.1602C10.6094 13.5234 10.0156 13.5273 9.65234 13.1602L8.44141 11.9492V15.9375H8.4375Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_418_17480">
                    <rect width="15" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RequestFeatureIcon;
