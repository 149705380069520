import {
    ProjectAudio, ProjectElement,
    ProjectImage,
    ProjectItem,
    ProjectText,
    ProjectVideo,
    VideoTransitionType,
} from 'interfaces/projects';
import { DrawingItems, DrawingItemsProps } from 'interfaces/videoElement';
import { isSingleImageTransition } from './transition';

export const FRAME_SIZE = 0.01; // px
export const TIMELINE_FRAME_RATE = 24;
export const TIMELINE_DURATION = 60; // sec
export const TIMELINE_MIN_ZOOM_SEC = 3600; // sec
export const ITEM_TRACK_HEIGHT = 35;
export const VIDEO_TRACK_HEIGHT = 60;
export const VIDEO_TRACKS_LIMIT = 15;
export const AUDIO_TRACK_HEIGHT = 34;
export const AUDIO_TRACKS_LIMIT = 15;
export const MIN_TIMELINE_HEIGHT = 286;

export const visualSizeToFrameNumber = (size: number, zoom: number): number => {
    /**
     * Converts a visual size to frame number
     *
     * @param  {Number} size - Visual size
     * @param  {Number} zoom - Zoom index
     * @return {Number} Returns the frame number
     */
    const frameSize = FRAME_SIZE * zoom;
    return Math.round(size / frameSize);
};

export const onSlide = (
    sliderVal: number,
    zoom: number,
    duration: number,
    timeLineTunnelSize: number
): number => {
    /**
     *
     * @param  {Number} zoom - Zoom index
     * @param  {Number} sliderVal - Slider value in percent
     * @param  {Number} timeLineTunnelSize - Slider visual size i px-s
     * @return {Number} Returns the player time in frames
     */
    const time =
        (visualSizeToFrameNumber(timeLineTunnelSize, zoom) * sliderVal) / 100;
    const currentTime = Math.round(time);
    return currentTime;
};

export const frameNumberToVisualSize = (
    frames: number,
    zoom: number
): number => {
    /**
     * Converts a frame number to visual size in 'px'
     *
     * @param  {Number} frames - Frames count
     * @param  {Number} zoom - Zoom index
     * @return {Number} Returns the frame number visual size
     */
    const frameSize = FRAME_SIZE * zoom;
    return frames * frameSize;
};

export const calculateTimelineVisibleZoomValue = (
    timeLineVisibleSize: number,
    timeLineDuration: number
): number => {
    // const zoomMinIndex = Math.floor(
    //   ((timeLineVisibleSize * 2)/(timeLineDuration))/FRAME_SIZE)
    // ;
    const zoomMinIndex = timeLineVisibleSize / timeLineDuration / FRAME_SIZE;
    // const zoomMinIndex = Math.round(((timeLineVisibleSize)/timeLineDuration)/FRAME_SIZE);
    // const zoomMinIndex = Math.ceil(((timeLineVisibleSize)/timeLineDuration)/FRAME_SIZE);

    // console.log((timeLineVisibleSize)/(timeLineDuration))
    // console.log((timeLineVisibleSize * 2)/(timeLineDuration))

    return zoomMinIndex;
};

export const calculateZoomMinValue = (
    timeLineVisibleSize: number,
    timelineFullVisibleZoomValue: number,
    frameRate: number
) => {
    const minZoom1 =
        timeLineVisibleSize / (frameRate * TIMELINE_MIN_ZOOM_SEC) / FRAME_SIZE;
    const minZoom2 = timelineFullVisibleZoomValue / 2;

    return minZoom2 < minZoom1 ? minZoom2 : minZoom1;
};

export const calculateZoomMaxValue = (minZoom: number): number => {
    const FRAME_MAX_SIZE = 6; // One fre max visual size in 'px'
    const zoomMinIndex = Math.floor(FRAME_MAX_SIZE / FRAME_SIZE);

    // console.log(timeLineVisibleSize / zoomMinIndex)
    // console.log({timeLineDuration})
    // console.log({zoomMinIndex})
    // console.log(FRAME_MAX_SIZE/FRAME_SIZE)
    // // console.log(timeLineDuration/FRAME_SIZE)
    // console.log(timeLineDuration/FRAME_MAX_SIZE)

    return zoomMinIndex < minZoom ? minZoom * 2 : zoomMinIndex;
    // return 800;
    // return 2000;
};

export const frameNumberToPlayerTime = (
    frame: number,
    frameRate: number
): string => {
    /**
     * Returns the current SMPTE Time code in the video.
     * - Can be used as a conversion utility.
     *
     * @param  {Number} frame Frame number for conversion to it's equivalent SMPTE Time code.
     * @param  {Number} fps-frameRate Per second rendered frames count.
     * @return {String} Returns a SMPTE Time code in HH:MM:SS:FF format
     */
    function wrap(n: number): any {
        return n < 10 ? `0${n}` : n;
    }
    const frameNumber = Number(frame);
    const fps = frameRate;
    const hour = fps * 60 * 60;
    const minute = fps * 60;
    const frameNumberToHours = frameNumber / hour;
    const hours = parseInt(frameNumberToHours.toString());
    const minutes =
        Number((frameNumber / minute).toString().split('.')[0]) % 60;
    const seconds = Number((frameNumber / fps).toString().split('.')[0]) % 60;
    const SMPTE = `${wrap(hours)}:${wrap(minutes)}:${wrap(seconds)}:${wrap(
        Math.round(frameNumber % fps)
    )}`;
    return SMPTE;
};

export const secondsToFrameNumber = (
    time: number,
    frameRate: number
): number => {
    /**
     * Returns the current frame number
     * @param  {Number} fps-frameRate Per second rendered frames count
     * @return {Number} - Frame number in video
     */
    return Math.round((+time?.toFixed(5) || 0) * frameRate);
};

export const frameNumberToSeconds = (
    frames: number,
    frameRate: number
): number => {
    /**
     * Converts a frame number to Seconds
     *
     * @param  {Number} frames - Frames count
     * @param  {Number} fps-frameRate Per second rendered frames count
     * @return {Number} Returns the Second count of a frames count
     */
    const time =
        ((1000 / frameRate) * (Number.isNaN(frames) ? 0 : frames)) / 1000 +
        0.001;
    return +time.toFixed(3);
};

export const getTrackIndexDiff = (
    y: number,
    trackHeight = ITEM_TRACK_HEIGHT
) => {
    if (y === 0) {
        return 0;
    }
    const newIndex = y / trackHeight;
    const roundedNewIndex =
        newIndex < 0 ? Math.floor(newIndex) : Math.ceil(newIndex);

    return -roundedNewIndex;
};

export const mediasToTrackMediasAdapter = <T extends ProjectItem>(
    medias: T[]
): { [key: string]: T[] } => {
    return medias.reduce((acc: { [key: string]: T[] }, curr: T) => {
        const zIndex = curr.zIndex ?? 1;
        if (!acc[zIndex]) {
            acc[zIndex] = [curr];
        } else {
            acc[zIndex].push(curr);
        }
        return acc;
    }, {});
};

export const getDrawingItems = (props: DrawingItemsProps): DrawingItems => {
    const {
        trackVideos,
        videosTransitions,
        frameRate,
        currentTime,
        videoElements,
        currentVideo,
        currentVideoElement,
        isPlaying,
    } = props;

    const items: DrawingItems = {};

    const trackFilteredVideos = trackVideos.filter((video) => !video.isDummy);

    if (trackFilteredVideos.length > 0) {
        const firstVideo = trackFilteredVideos[0];
        const lastVideo = trackFilteredVideos[trackFilteredVideos.length - 1];

        const firstVideoTransition = videosTransitions.find(
            (transition) =>
                transition.startTarget === '' &&
                transition.endTarget === firstVideo.id &&
                transition.type !== VideoTransitionType.NONE
        );

        if (firstVideoTransition) {
            const halfTransitionDuration = firstVideoTransition.duration / 2;
            if (
                secondsToFrameNumber(currentTime, frameRate) >=
                    secondsToFrameNumber(firstVideo.startTime, frameRate) &&
                secondsToFrameNumber(currentTime, frameRate) <=
                    secondsToFrameNumber(
                        firstVideo.startTime + halfTransitionDuration,
                        frameRate
                    )
            ) {
                items.transitionStartTime =
                    firstVideo.startTime -
                    (isSingleImageTransition(firstVideoTransition.type)
                        ? halfTransitionDuration
                        : 0);
                items.next = {
                    video: firstVideo,
                    element: videoElements[firstVideo.id],
                };
                items.transitionEndTime =
                    firstVideo.startTime + halfTransitionDuration;
                items.transition = firstVideoTransition;
            }
        }

        const lastVideoTransition = videosTransitions.find(
            (transition) =>
                transition.startTarget === lastVideo.id &&
                transition.endTarget === '' &&
                transition.type !== VideoTransitionType.NONE
        );

        if (lastVideoTransition) {
            const halfTransitionDuration = lastVideoTransition.duration / 2;
            if (
                secondsToFrameNumber(currentTime, frameRate) >=
                    secondsToFrameNumber(
                        lastVideo.endTime - halfTransitionDuration,
                        frameRate
                    ) &&
                secondsToFrameNumber(currentTime, frameRate) <=
                    secondsToFrameNumber(lastVideo.endTime, frameRate)
            ) {
                items.transitionStartTime =
                    lastVideo.endTime - halfTransitionDuration;
                items.current = {
                    video: lastVideo,
                    element: videoElements[lastVideo.id],
                };
                items.transitionEndTime =
                    lastVideo.endTime +
                    (isSingleImageTransition(lastVideoTransition.type)
                        ? halfTransitionDuration
                        : 0);
                items.transition = lastVideoTransition;
            }
        }
    }

    for (let j = 0; j < trackVideos.length - 1; j++) {
        const startVideo = trackVideos[j];
        const endVideo = trackVideos[j + 1];
        const videoTransition = videosTransitions.find(
            (transition) =>
                transition.startTarget === startVideo.id &&
                transition.endTarget === endVideo.id &&
                transition.type !== VideoTransitionType.NONE
        );
        if (videoTransition) {
            const halfTransitionDuration = videoTransition.duration / 2;
            if (
                (currentTime >= startVideo.endTime - halfTransitionDuration &&
                    currentTime <
                        startVideo.endTime + halfTransitionDuration) ||
                (currentTime >= endVideo.startTime - halfTransitionDuration &&
                    currentTime < endVideo.startTime + halfTransitionDuration)
            ) {
                if (
                    startVideo.endTime - halfTransitionDuration >
                        startVideo.startTime &&
                    endVideo.startTime + halfTransitionDuration <
                        endVideo.endTime
                ) {
                    items.current = {
                        video: startVideo,
                        element: videoElements[startVideo.id],
                    };
                    items.transitionStartTime =
                        startVideo.endTime - halfTransitionDuration;
                    items.next = {
                        video: endVideo,
                        element: videoElements[endVideo.id],
                    };
                    items.transitionEndTime =
                        endVideo.startTime + halfTransitionDuration;
                    items.transition = videoTransition;

                    if (
                        !isPlaying &&
                        items?.current?.element !== currentVideoElement &&
                        items.current.element
                    ) {
                        items.current.element.currentTime =
                            items.current.video.trimEnd;
                    }
                }
            }
        }
    }
    if (!items.next && !items.current && currentVideo) {
        items.next = {
            video: currentVideo,
            element: currentVideoElement,
        };
    }

    return items;
};

export const pasteVideoHasOverlap = (
    videos: ProjectVideo[],
    timelineTime: number,
    currVideo: ProjectVideo,
) => {
    const currZIndex = currVideo.zIndex;
    const filteredTrackVideos = videos.filter(item =>
        !item.isAudio && (item.zIndex === currZIndex)
    );

    const duration = currVideo.endTime - currVideo.startTime;
    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredTrackVideos.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};

export const pasteAudioWithSubtitlesHasOverlap = (
    videos: ProjectVideo[],
    timelineTime: number,
    currAudio: ProjectVideo,
) => {
    const currZIndex = currAudio.zIndex;
    const filteredTrackAudios = videos.filter(item =>
        item.isAudio && (item.zIndex === currZIndex)
    );

    const duration = currAudio.endTime - currAudio.startTime;
    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredTrackAudios.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};

export const pasteAudioHasOverlap = (
    audios: ProjectAudio[],
    timelineTime: number,
    currAudio: ProjectAudio,
) => {
    const currTrackId = currAudio.projectTrackId;
    const filteredAudios = audios.filter(item => item.projectTrackId === currTrackId);

    const duration = currAudio.endTime - currAudio.startTime;

    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredAudios.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};

export const pasteImageHasOverlap = (
    images: ProjectImage[],
    timelineTime: number,
    currImage: ProjectImage,
) => {
    const currZIndex = currImage.zIndex;
    const filteredImages = images.filter(item => item.zIndex === currZIndex);

    const duration = currImage.endTime - currImage.startTime;

    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredImages.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};

export const pasteTextHasOverlap = (
    texts: ProjectText[],
    timelineTime: number,
    currText: ProjectText,
) => {
    const currZIndex = currText.zIndex;
    const filteredTexts = texts.filter(item => item.zIndex === currZIndex);

    const duration = currText.endTime - currText.startTime;

    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredTexts.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};

export const pasteElementHasOverlap = (
    elements: ProjectElement[],
    timelineTime: number,
    currElement: ProjectElement,
) => {
    const currZIndex = currElement.zIndex;
    const filteredElements = elements.filter(item => item.zIndex === currZIndex);

    const duration = currElement.endTime - currElement.startTime;

    const newEnd = timelineTime + duration;

    const hasOverlappingItem = filteredElements.some(item =>
            (
                item.startTime <= timelineTime &&
                item.endTime >= timelineTime
            ) || (
                item.startTime <= newEnd &&
                item.endTime >= newEnd
            ) || (
                item.startTime >= timelineTime &&
                item.endTime <= newEnd
            )
    );

    return hasOverlappingItem;
};
