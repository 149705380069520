import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import WorkspaceCard from 'components/WorkspaceCard';
import { NewButton } from 'components/UI';

import { getModalOptionalData, hideModal } from 'state/modules/modal';
import {
    getCurrentWorkspaceInfo,
    getInviteeWorkspacesList,
    getWorkspacesList,
} from 'state/modules/workspaces';

import { Workspace } from 'interfaces/workspaces';
import { ProjectCopingType } from 'interfaces/projects';

import ProjectsClient from 'services/api/projects';
import { NotificationTypes, showNotification } from 'utils/notifications';

const Actions = styled.p`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
`;

const Title = styled.p`
    font-size: 1.75rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: 'Proxima Nova Medium', sans-serif;
    color: rgb(25, 32, 51);
    margin-bottom: 2rem;
    text-align: center;
`;

const Container = styled.div`
    padding: 3rem;
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 20px;
    background-color: #fff;
    z-index: 10;
`;

const WorkspacesContainer = styled.div`
    padding: 0 0.5rem;
`;

const WorkspacesList = styled.div`
    max-height: 70vh;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 50px;
`;

const WorkspacesListContainer = styled.div``;

const WorkspaceCardContainer = styled.div<{ isSelected: boolean }>`
    border: ${({ isSelected }) =>
        isSelected ? '2px solid rgb(5,27,90)' : '2px solid transparent'};
    padding-right: 10px;
    border-radius: 0.75rem;
`;

const MoveProjectToWorkspaceModal = () => {
    const [selectedWorkspace, setSelectedWorkspace] =
        useState<Workspace | null>(null);

    const [isLoading, setLoading] = useState(false);
    const { project } = useSelector(getModalOptionalData);
    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);
    const workspaces = useSelector(getWorkspacesList);
    const inviteeWorkspaces = useSelector(getInviteeWorkspacesList);

    const dispatch = useDispatch();

    const combinedWorkspaces = useMemo(() => {
        const filteredInviteeWorkspaces = inviteeWorkspaces.filter(
            (item) => item.id !== currentWorkspace?.id
        );
        const filteredWorkspaces = workspaces.filter(
            (item) => item.id !== currentWorkspace?.id
        );

        return [...filteredWorkspaces, ...filteredInviteeWorkspaces];
    }, [workspaces, inviteeWorkspaces, currentWorkspace]);

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);

            await ProjectsClient.duplicateProject(
                project.id,
                project.version,
                !!project?.isTemplate,
                selectedWorkspace?.id,
                ProjectCopingType.OTHER_WORKSPACE
            );

            setLoading(false);

            showNotification(
                NotificationTypes.success,
                'The project started copying to the selected workspace'
            );

            handleClose();
        } catch (error) {
            console.log({ error });
            setLoading(false);
        }
    };

    const handleSelect = (workspace: Workspace) => {
        setSelectedWorkspace(workspace);
    };

    const renderWorkspace = (workspace: Workspace) => {
        return (
            <WorkspaceCardContainer
                isSelected={selectedWorkspace?.id === workspace.id}
                key={workspace.id}
            >
                <WorkspaceCard
                    workspace={workspace}
                    onClick={() => handleSelect(workspace)}
                />
            </WorkspaceCardContainer>
        );
    };

    return (
        <Container>
            <Title>Select workspace</Title>
            <WorkspacesContainer>
                <WorkspacesListContainer>
                    {combinedWorkspaces.length ? (
                        <WorkspacesList>
                            {combinedWorkspaces.map((workspace) =>
                                renderWorkspace(workspace)
                            )}
                        </WorkspacesList>
                    ) : null}
                </WorkspacesListContainer>
            </WorkspacesContainer>
            <Actions>
                <NewButton
                    disabled={isLoading}
                    borderRadius={10}
                    color="rgb(247, 247, 248)"
                    textColor="rgb(25, 32, 51)"
                    hoverTextColor="rgb(0, 152, 253)"
                    hoverBoxShadow="rgb(0, 0, 0, 0.1) 0px 1px 2px"
                    hoverBorder="1px solid rgb(103, 193, 255)"
                    height={48}
                    onClick={handleClose}
                >
                    Cancel
                </NewButton>
                <NewButton
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={isLoading || !selectedWorkspace}
                    borderRadius={10}
                    color="rgb(25, 32, 51)"
                    textColor="rgb(255, 255, 255)"
                    height={48}
                    type="submit"
                    hoverBoxShadow="rgb(0, 0, 0, 0.2) 0px 5px 6px"
                >
                    Copy
                </NewButton>
            </Actions>
        </Container>
    );
};

export default MoveProjectToWorkspaceModal;
