import { IconProps } from './index';

const PauseIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 18;

  if (size) {
    height = size;
  }

  return (
    <svg width={height} height={height} viewBox="0 0 18 18">
      <path
        fill={color}
        d="M6 1H3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1zm6 0c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1h-3z"
      />
    </svg>
  );
};

export default PauseIcon;
