import { IconProps } from './index';

const CreateFolderIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.717 14.061v16.938a3.5 3.5 0 0 1-3.5 3.5H7a3.5 3.5 0 0 1-3.5-3.5V9A3.5 3.5 0 0 1 7 5.5h5.745l7.838 4.747a2.17 2.17 0 0 0 1.124.314h11.51a3.5 3.5 0 0 1 3.5 3.5ZM2 9a5 5 0 0 1 5-5h5.977a.67.67 0 0 1 .347.097l8.036 4.867a.67.67 0 0 0 .347.097h11.51a5 5 0 0 1 5 5v16.938a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V9Zm18.86 10.083a.75.75 0 0 0-1.5 0v2.167h-2.167a.75.75 0 0 0 0 1.5h2.166v2.167a.75.75 0 0 0 1.5 0V22.75h2.167a.75.75 0 1 0 0-1.5h-2.167v-2.167Z"
                fill={color}
            />
        </svg>
    );
};

export default CreateFolderIcon;
