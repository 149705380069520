import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 0.914;

const DeleteIcon = ({ size }: IconProps): JSX.Element => {
  let height = 35;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4852 6.69553V3.34776C21.4852 2.84354 21.0766 2.43466 20.5721 2.43466H12.0503C11.5461 2.43466 11.1375 2.84354 11.1375 3.34776V6.69553H9.61568C9.11145 6.69553 8.70258 6.28692 8.70258 5.7827V3.0434C8.70258 1.36265 10.0652 0 11.746 0H20.8764C22.5574 0 23.9201 1.36265 23.9201 3.0434V5.7827C23.9201 6.28692 23.5112 6.69553 23.007 6.69553H21.4852Z"
        fill="#7987CA"
      />
      <path
        d="M3.22424 7.6084H28.7897V31.9564C28.7897 33.6371 27.427 34.9998 25.746 34.9998H6.26791C4.58689 34.9998 3.22424 33.6371 3.22424 31.9564V7.6084Z"
        fill="#6879C7"
      />
      <path
        d="M26.3548 10.9566V31.6521C26.3548 32.1564 25.9459 32.5652 25.4417 32.5652H6.57228C6.06779 32.5652 5.65918 32.1564 5.65918 31.6521V10.9566C5.65918 10.4521 6.06779 10.0435 6.57228 10.0435H25.4417C25.9459 10.0435 26.3548 10.4521 26.3548 10.9566Z"
        fill="#405095"
      />
      <path
        d="M9.00694 12.478H10.8331C11.3374 12.478 11.746 12.8869 11.746 13.3911V29.2171C11.746 29.7216 11.3374 30.1302 10.8331 30.1302H9.00694C8.50272 30.1302 8.09384 29.7216 8.09384 29.2171V13.3911C8.09384 12.8869 8.50272 12.478 9.00694 12.478Z"
        fill="#6879C7"
      />
      <path
        d="M15.094 12.478H16.9199C17.4242 12.478 17.833 12.8869 17.833 13.3911V29.2171C17.833 29.7216 17.4242 30.1302 16.9199 30.1302H15.094C14.5898 30.1302 14.1809 29.7216 14.1809 29.2171V13.3911C14.1809 12.8869 14.5898 12.478 15.094 12.478Z"
        fill="#6879C7"
      />
      <path
        d="M21.1808 12.478H23.007C23.5112 12.478 23.9201 12.8869 23.9201 13.3911V29.2171C23.9201 29.7216 23.5112 30.1302 23.007 30.1302H21.1808C20.6766 30.1302 20.2677 29.7216 20.2677 29.2171V13.3911C20.2677 12.8869 20.6766 12.478 21.1808 12.478Z"
        fill="#6879C7"
      />
      <path
        d="M1.09395 5.47852H30.92C31.4242 5.47852 31.8331 5.88712 31.8331 6.39162V7.00008C31.8331 7.50457 31.4242 7.91318 30.92 7.91318H1.09395C0.589722 7.91318 0.180847 7.50457 0.180847 7.00008V6.39162C0.180847 5.88712 0.589722 5.47852 1.09395 5.47852Z"
        fill="#313C6E"
      />
    </svg>
  );
};

export default DeleteIcon;
