import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'components/UI';

import { ModalEvent, ModalType, showModal } from 'state/modules/modal';

const Button = styled.button<{ bgColor?: string }>`
    border-radius: 8px;
    background-color: ${({ bgColor }) => bgColor || '#34cfff'};
    cursor: pointer;
    outline: none;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 16px;

    &:hover {
        opacity: 0.9;
    }
`;

const Title = styled.p<{ color?: string }>`
    color: ${({ color }) => color || '#fff'};
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
`;

const IconContainer = styled.div`
    margin-right: 10px;
    height: 1rem;
    width: 1rem;
`;

interface Props {
    bgColor?: string;
    textColor?: string;
    isFromWorkspaceSettings?: boolean;
}

const UpgradeButton = ({ bgColor, textColor, isFromWorkspaceSettings }: Props) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(showModal(
            ModalType.SELECT_PLAN,
            ModalEvent.UPGRADE_PLAN,
            '',
            {
                isFromWorkspaceSettings: !!isFromWorkspaceSettings,
            }
        ));
    };

    return (
        <Button onClick={handleClick} bgColor={bgColor}>
            <IconContainer>
                <Icon name="flash" size={16} color="#fff" />
            </IconContainer>
            <Title color={textColor}>Upgrade</Title>
        </Button>
    );
};

export default UpgradeButton;
