/* eslint-disable no-return-await */
import styled from 'styled-components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import WorkspaceMembersList from 'components/WorkspaceMembersList';
import InviteCollaboratorForm from 'components/InviteCollaboratorForm';

import { Icon } from 'components/UI';

import { hideModal } from 'state/modules/modal';
import { getInviterInvites } from 'state/modules/workspaces';
import { NEUTRAL_500 } from '../../../styles/colors';

const Container = styled.div`
    width: 28rem;
    height: 28rem;
    max-height: 90vh;
    overflow: hidden;
    border-radius: 1.25rem;
    background-color: rgb(255, 255, 255);
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 16px;
    transition: color 0.15s ease-in-out 0s,
        background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
        box-shadow 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;
    padding: 1.5rem;
    position: relative;
`;

const Title = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0 auto 0 0;
    cursor: default;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
    align-self: flex-end;
    padding: 0;
`;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.25rem;
`;

const MembersContainer = styled.div`
    height: calc(100% - 148px);
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
`;

const InviteCollaboratorModal = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInviterInvites());
    }, [dispatch]);

    const closeModal = () => {
        dispatch(hideModal());
    };

    const renderCloseButton = () => (
        <CloseButton onClick={closeModal}>
            <Icon name="close-new" size={16} color={NEUTRAL_500}/>
        </CloseButton>
    );

    return (
        <Container>
            <Header>
                <HeaderContent>
                    <Title>Invite Collaborators</Title>
                </HeaderContent>
                <InviteCollaboratorForm />
            </Header>
            <MembersContainer>
                <WorkspaceMembersList />
            </MembersContainer>
            {renderCloseButton()}
        </Container>
    );
};

export default InviteCollaboratorModal;
