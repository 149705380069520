import { memo } from 'react';
import groupBy from 'lodash.groupby';

import { AnalysisResultsType } from 'interfaces/analysis';
import { MediaFile } from 'interfaces/videos';
import {
  AvidExportData,
  AvidExportItem,
  AvidTranscriptExportItem,
} from 'interfaces/avidExport';

import {
  EdlExportData,
  EdlExportItem,
  EdlTranscriptExportItem,
} from 'interfaces/edlExport';

import styles from './styles.module.scss';

interface Props {
  dataForExport: AvidExportData | EdlExportData;
  videosData: Array<MediaFile>;
}

interface AvidTranscriptWithHighLightsExportItem
  extends AvidTranscriptExportItem {
  highlights: {
    [key: string]: Array<string>;
  };
}
interface AvidTranscriptWithHighLightsExportItem
  extends EdlTranscriptExportItem {
  highlights: {
    [key: string]: Array<string>;
  };
}

const ListToExport = (props: Props): JSX.Element => {
  const { dataForExport, videosData } = props;

  const renderItemFromSelectedVideo = (
    item: AvidExportItem | EdlExportItem,
  ) => (
    <div
      className={styles.ListToExport__item}
      key={item.type + item.name}
    >
      <p>
        {item.name}
        {' '}
        -
        {item.timestamps.length}
        {' '}
        Instances
      </p>
    </div>
  );

  const renderGroupedByVideoItems = (videoId: string) => {
    const selectedVideo = videosData.find(
      (video: MediaFile) => video.id === videoId,
    );

    const selectedSentencesFromVideo = dataForExport.groupedData[videoId].sentence;

    let sentencesGroupedBySearchQuery: null | {
      [key: string]: Array<AvidExportItem | EdlExportItem>;
    } = null;

    if (selectedSentencesFromVideo) {
      sentencesGroupedBySearchQuery = groupBy(
        selectedSentencesFromVideo as Array<
          | AvidTranscriptWithHighLightsExportItem
          | AvidTranscriptWithHighLightsExportItem
        >,
        (
          item:
            | AvidTranscriptWithHighLightsExportItem
            | AvidTranscriptWithHighLightsExportItem,
        ) => {
          let highlight = 'Sentence';

          if (item && 'highlights' in item && item.highlights) {
            const highlightData = item.highlights.data
              || item.highlights[`data_${item.language}`];

            const startHighlight = highlightData[0].indexOf('>') + 1;
            const endHighlight = highlightData[0].lastIndexOf('<');

            highlight = highlightData[0].substring(
              startHighlight,
              endHighlight,
            );
          }

          return highlight;
        },
      );
    }

    const itemsFromVideo = dataForExport.selectedItems.filter(
      (selectedItem: AvidExportItem | EdlExportItem) => selectedItem.videoId === videoId
        && selectedItem.type !== AnalysisResultsType.SENTENCE,
    );

    return (
      <div key={videoId} className={styles.ListToExport__video}>
        <div className={styles.ListToExport__videoTitle}>
          {selectedVideo?.title || selectedVideo?.filename || ''}
        </div>
        <div className={styles.ListToExport__itemsList}>
          {itemsFromVideo.map(
            (item: AvidExportItem | EdlExportItem) => renderItemFromSelectedVideo(item),
          )}
          {sentencesGroupedBySearchQuery
            && Object.keys(sentencesGroupedBySearchQuery).map(
              (key: string) => (
                <div
                  className={styles.ListToExport__item}
                  key={key}
                >
                  {`${key} in Transcript - ${sentencesGroupedBySearchQuery?.[key].length} Instances`}
                </div>
              ),
            )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.ListToExport}>
      <p className={styles.ListToExport__title}>
        Selected Items For Export
      </p>
      <div className={styles.ListToExport__videos}>
        {Object.keys(dataForExport.groupedData).map((key: string) => renderGroupedByVideoItems(key))}
      </div>
    </div>
  );
};

export default memo(ListToExport);
