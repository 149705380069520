import { IconProps } from './index';

const GoogleDriveIcon = ({ size, color}: IconProps): JSX.Element => {
    let height = 19;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.4926 14.2509C24.4901 14.209 24.479 14.1708 24.468 14.1301C24.4569 14.0894 24.447 14.0512 24.4298 14.0142C24.4236 14.0019 24.4236 13.9896 24.4174 13.9785L16.8288 0.833349C16.7747 0.739683 16.6969 0.661901 16.6033 0.607822C16.5096 0.553743 16.4034 0.525271 16.2952 0.525269H8.70412C8.59637 0.525489 8.49057 0.553958 8.39727 0.607834C8.3139 0.658518 8.24412 0.728726 8.19394 0.812399C8.18777 0.821025 8.17792 0.824722 8.17175 0.833349L0.583126 13.9785C0.528682 14.0721 0.5 14.1784 0.5 14.2866C0.5 14.3948 0.528682 14.5011 0.583126 14.5947L4.37867 21.1666C4.3873 21.1827 4.40578 21.1888 4.41564 21.2036C4.45651 21.2629 4.50788 21.3143 4.56722 21.3552C4.59063 21.3712 4.60912 21.3872 4.63376 21.3995C4.71879 21.4439 4.80998 21.4735 4.91227 21.4747H20.0908C20.1989 21.4747 20.3052 21.4462 20.3988 21.3922C20.4925 21.3381 20.5703 21.2603 20.6244 21.1666L24.4187 14.5947C24.4273 14.5799 24.4248 14.5602 24.431 14.5454C24.4625 14.4796 24.4817 14.4086 24.4877 14.3359C24.4889 14.3199 24.5 14.3051 24.5 14.2866C24.5 14.2743 24.4951 14.2632 24.4938 14.2496M15.9378 1.75759L22.8154 13.6704H16.6526L9.77254 1.75759H15.9378ZM15.2268 13.6704H9.77131L12.4997 8.94448L15.2268 13.6704ZM1.8253 14.2866L8.70412 2.37375L11.7874 7.71339L4.91104 19.6262L1.8253 14.2866ZM19.7334 20.2424H5.97822L9.06149 14.9027H22.8166L19.7334 20.2424Z"
                fill={color}
            />
        </svg>
    );
};

export default GoogleDriveIcon;