const lightTheme = {
    nav: 'rgba(49, 49, 49, 0.65)',
    projectDescription: 'rgba(0, 0, 0, 0.4)',
    input: 'rgba(49, 49, 49, 0.03)',
    text: '#313131',
    secondText: 'rgba(49, 49, 49, 0.4)',
};

const darkTheme = {
    nav: 'rgba(49, 49, 49, 0.65)',
    projectDescription: 'rgba(0, 0, 0, 0.4)',
};

export default { lightTheme, darkTheme };
