import { createSelector } from 'reselect';

// import { State } from "state/modules";

import { MediaFile } from 'interfaces/videos';
import { StatusType } from 'interfaces/statuses';

import { getInProgressStatus } from 'utils/statuses';
import store from 'state/store';
import { VideosState } from './types';

export const getVideosState: () => VideosState = () => store.getState().videos;

// export const getVideosState = (state: State): VideosState => state.videos;

export const getVideoFiles = createSelector(
    getVideosState,
    (state) => state.videos
);

export const getSelectedVideos = createSelector(
    getVideosState,
    (state) => state.selectedVideos
);

export const getMediaListStatusFilter = createSelector(
    getVideosState,
    (state) => state.statusFilter
);

export const getSelectedVideosCount = createSelector(
    getVideosState,
    (state) => state.selectedVideos.length
);

export const getVideoFilesTotal = createSelector(
    getVideosState,
    (state) => state.totalVideos
);

export const getVideoFilesLoading = createSelector(
    getVideosState,
    (state) => state.isVideosLoading
);

export const getVideoFilesCount = createSelector(
    getVideosState,
    (state) => state.videos.length
);

export const getVideoInfo = createSelector(
    getVideosState,
    (state) => state.videoDetails
);

export const getVideoDetailsLoading = createSelector(
    getVideosState,
    (state) => state.isVideoDetailsLoading
);

export const getVideoId = createSelector(
    getVideoInfo,
    (video: MediaFile | null) => {
        if (video) {
            return video.id;
        }
        return '';
    }
);

export const getVideosDeletingStatus = createSelector(
    getVideosState,
    (state) => state.isVideosDeleting
);

export const getVideosIdsForDelete = createSelector(
    getVideosState,
    (state) => state.videosIdsForDelete
);

export const checkVideosIsInProgressStatus = createSelector(
    getVideoFiles,
    (videos) => {
        const videoInProgressIndex = videos.findIndex((video: MediaFile) => {
            const statusInProgress = getInProgressStatus(video) as
                | string
                | undefined;

            if (statusInProgress) {
                return video;
            }
        });

        return videoInProgressIndex >= 0;
    }
);

export const getVideosInProgress = createSelector(getVideoFiles, (videos) => {
    const videosInProgress = videos.filter((video: MediaFile) => {
        const statusInProgress = getInProgressStatus(video) as
            | string
            | undefined;

        if (statusInProgress) {
            return video;
        }
    });

    return videosInProgress;
});

export const checkIsSelectedFilesInProgress = createSelector(
    getSelectedVideos,
    (videos) => {
        const videoInProgressIndex = videos.findIndex((video: MediaFile) => {
            const statusInProgress = getInProgressStatus(video) as
                | string
                | undefined;

            if (statusInProgress) {
                return video;
            }
        });

        return videoInProgressIndex >= 0;
    }
);

export const checkVideoDeleting = (id: string) =>
    createSelector(
        getVideosIdsForDelete,
        (videosIdsForDelete) =>
            videosIdsForDelete.findIndex((videoId: string) => videoId === id) >=
            0
    );

export const getVideoFilesWithGenerationInProgress = createSelector(
    getVideoFiles,
    (videos) =>
        videos.filter(
            (video: MediaFile) =>
                video.status.generation &&
                video.status.generation.status.status === StatusType.IN_PROGRESS
        )
);

export const getIsSelectedAllVideosStatus = createSelector(
    [getVideoFiles, getSelectedVideos],
    (videos, selectedVideos) => videos.length === selectedVideos.length
);

export const getVideoIsSelectedStatus = (id: string) =>
    createSelector(getSelectedVideos, (selectedVideos) => {
        if (selectedVideos.length) {
            return (
                selectedVideos.findIndex(
                    (item: MediaFile) => item?.id === id
                ) >= 0
            );
        }
        return false;
    });

export const getVideosInProgressCount = createSelector(
    getVideoFiles,
    (videos) =>
        videos.filter((video: MediaFile) => {
            const statusInProgress = getInProgressStatus(video) as
                | string
                | undefined;

            if (statusInProgress) {
                return video;
            }
        }).length
);

export const getVideoLibraryFilter = createSelector(
    getVideosState,
    (state) => state.videoFilter
);

export const getVideoUpdateLoading = createSelector(
    getVideosState,
    (state) => state.isVideoUpdateLoading
);
