import { LanguageOption } from 'interfaces/language';
import ae from 'assets/flags/ae.svg';
import af from 'assets/flags/af.svg';
import al from 'assets/flags/al.svg';
import am from 'assets/flags/am.svg';
import ar from 'assets/flags/ar.svg';
import at from 'assets/flags/at.svg';
import au from 'assets/flags/au.svg';
import az from 'assets/flags/az.svg';
import ba from 'assets/flags/ba.svg';
import bd from 'assets/flags/bd.svg';
import be from 'assets/flags/be.svg';
import bg from 'assets/flags/bg.svg';
import bh from 'assets/flags/bh.svg';
import bo from 'assets/flags/bo.svg';
import br from 'assets/flags/br.svg';
import by from 'assets/flags/by.svg';
import ca from 'assets/flags/ca.svg';
import cd from 'assets/flags/cd.svg';
import ch from 'assets/flags/ch.svg';
import cl from 'assets/flags/cl.svg';
import cn from 'assets/flags/cn.svg';
import co from 'assets/flags/co.svg';
import cr from 'assets/flags/cr.svg';
import cu from 'assets/flags/cu.svg';
import cz from 'assets/flags/cz.svg';
import de from 'assets/flags/de.svg';
import dk from 'assets/flags/dk.svg';
import doFlag from 'assets/flags/do.svg';
import dz from 'assets/flags/dz.svg';
import ec from 'assets/flags/ec.svg';
import ee from 'assets/flags/ee.svg';
import eg from 'assets/flags/eg.svg';
import es from 'assets/flags/es.svg';
import et from 'assets/flags/et.svg';
import fi from 'assets/flags/fi.svg';
import fr from 'assets/flags/fr.svg';
import gbWls from 'assets/flags/gb-wls.svg';
import gb from 'assets/flags/gb.svg';
import ge from 'assets/flags/ge.svg';
import gh from 'assets/flags/gh.svg';
import gr from 'assets/flags/gr.svg';
import gq from 'assets/flags/gq.svg';
import gt from 'assets/flags/gt.svg';
import hk from 'assets/flags/hk.svg';
import hn from 'assets/flags/hn.svg';
import hr from 'assets/flags/hr.svg';
import ht from 'assets/flags/ht.svg';
import hu from 'assets/flags/hu.svg';
import id from 'assets/flags/id.svg';
import ie from 'assets/flags/ie.svg';
import il from 'assets/flags/il.svg';
import inFlag from 'assets/flags/in.svg';
import iq from 'assets/flags/iq.svg';
import ir from 'assets/flags/ir.svg';
import is from 'assets/flags/is.svg';
import it from 'assets/flags/it.svg';
import jo from 'assets/flags/jo.svg';
import jp from 'assets/flags/jp.svg';
import ke from 'assets/flags/ke.svg';
import kg from 'assets/flags/kg.svg';
import kh from 'assets/flags/kh.svg';
import kr from 'assets/flags/kr.svg';
import kw from 'assets/flags/kw.svg';
import kz from 'assets/flags/kz.svg';
import la from 'assets/flags/la.svg';
import lb from 'assets/flags/lb.svg';
import lk from 'assets/flags/lk.svg';
import ls from 'assets/flags/ls.svg';
import lt from 'assets/flags/lt.svg';
import lu from 'assets/flags/lu.svg';
import lv from 'assets/flags/lv.svg';
import ly from 'assets/flags/ly.svg';
import ma from 'assets/flags/ma.svg';
import mg from 'assets/flags/mg.svg';
import mi from 'assets/flags/mi.svg';
import mk from 'assets/flags/mk.svg';
import ml from 'assets/flags/ml.svg';
import mm from 'assets/flags/mm.svg';
import mn from 'assets/flags/mn.svg';
import mr from 'assets/flags/mr.svg';
import mt from 'assets/flags/mt.svg';
import mv from 'assets/flags/mv.svg';
import mx from 'assets/flags/mx.svg';
import my from 'assets/flags/my.svg';
import ng from 'assets/flags/ng.svg';
import ni from 'assets/flags/ni.svg';
import nl from 'assets/flags/nl.svg';
import no from 'assets/flags/no.svg';
import np from 'assets/flags/np.svg';
import nz from 'assets/flags/nz.svg';
import om from 'assets/flags/om.svg';
import pa from 'assets/flags/pa.svg';
import pe from 'assets/flags/pe.svg';
import ph from 'assets/flags/ph.svg';
import pk from 'assets/flags/pk.svg';
import pl from 'assets/flags/pl.svg';
import pr from 'assets/flags/pr.svg';
import ps from 'assets/flags/ps.svg';
import pt from 'assets/flags/pt.svg';
import py from 'assets/flags/py.svg';
import qa from 'assets/flags/qa.svg';
import ro from 'assets/flags/ro.svg';
import rs from 'assets/flags/rs.svg';
import ru from 'assets/flags/ru.svg';
import rw from 'assets/flags/rw.svg';
import sa from 'assets/flags/sa.svg';
import se from 'assets/flags/se.svg';
import sg from 'assets/flags/sg.svg';
import si from 'assets/flags/si.svg';
import sk from 'assets/flags/sk.svg';
import sl from 'assets/flags/sl.svg';
import so from 'assets/flags/so.svg';
import sv from 'assets/flags/sv.svg';
import sy from 'assets/flags/sy.svg';
import tj from 'assets/flags/tj.svg';
import th from 'assets/flags/th.svg';
import tm from 'assets/flags/tm.svg';
import tn from 'assets/flags/tn.svg';
import tr from 'assets/flags/tr.svg';
import tw from 'assets/flags/tw.svg';
import tz from 'assets/flags/tz.svg';
import ua from 'assets/flags/ua.svg';
import ug from 'assets/flags/ug.svg';
import ur from 'assets/flags/ur.svg';
import us from 'assets/flags/us.svg';
import uy from 'assets/flags/uy.svg';
import uz from 'assets/flags/uz.svg';
import va from 'assets/flags/va.svg';
import ve from 'assets/flags/ve.svg';
import vn from 'assets/flags/vn.svg';
import ws from 'assets/flags/ws.svg';
import ye from 'assets/flags/ye.svg';
import za from 'assets/flags/za.svg';
import zm from 'assets/flags/zm.svg';
import zw from 'assets/flags/zw.svg';

export const languagesArray: Array<LanguageOption> = [
  {
    label: "Afrikaans (South Africa)",
    value: "af-ZA"
  },
  {
    label: "Albanian (Albania)",
    value: "sq-AL"
  },
  {
    label: "Amharic (Ethiopia)",
    value: "am-ET"
  },
  {
    label: "Arabic (Algeria)",
    value: "ar-DZ"
  },
  {
    label: "Arabic (Bahrain)",
    value: "ar-BH"
  },
  {
    label: "Arabic (Egypt)",
    value: "ar-EG"
  },
  {
    label: "Arabic (Iraq)",
    value: "ar-IQ"
  },
  {
    label: "Arabic (Israel)",
    value: "ar-IL"
  },
  {
    label: "Arabic (Jordan)",
    value: "ar-JO"
  },
  {
    label: "Arabic (Kuwait)",
    value: "ar-KW"
  },
  {
    label: "Arabic (Lebanon)",
    value: "ar-LB"
  },
  {
    label: "Arabic (Mauritania)",
    value: "ar-MR"
  },
  {
    label: "Arabic (Morocco)",
    value: "ar-MA"
  },
  {
    label: "Arabic (Oman)",
    value: "ar-OM"
  },
  {
    label: "Arabic (Qatar)",
    value: "ar-QA"
  },
  {
    label: "Arabic (Saudi Arabia)",
    value: "ar-SA"
  },
  {
    label: "Arabic (State of Palestine)",
    value: "ar-PS"
  },
  {
    label: "Arabic (Tunisia)",
    value: "ar-TN"
  },
  {
    label: "Arabic (United Arab Emirates)",
    value: "ar-AE"
  },
  {
    label: "Arabic (Yemen)",
    value: "ar-YE"
  },
  {
    label: "Armenian (Armenia)",
    value: "hy-AM"
  },
  {
    label: "Azerbaijani (Azerbaijan)",
    value: "az-AZ"
  },
  {
    label: "Basque (Spain)",
    value: "eu-ES"
  },
  {
    label: "Bengali (Bangladesh)",
    value: "bn-BD"
  },
  {
    label: "Bengali (India)",
    value: "bn-IN"
  },
  {
    label: "Bosnian (Bosnia and Herzegovina)",
    value: "bs-BA"
  },
  {
    label: "Bulgarian (Bulgaria)",
    value: "bg-BG"
  },
  {
    label: "Burmese (Myanmar)",
    value: "my-MM"
  },
  {
    label: "Catalan (Spain)",
    value: "ca-ES"
  },
  {
    label: "Chinese, Cantonese (Traditional Hong Kong)",
    value: "yue-Hant-HK"
  },
  {
    label: "Chinese, Mandarin (Simplified, China)",
    value: "zh"
  },
  {
    label: "Chinese, Mandarin (Traditional, Taiwan)",
    value: "zh-TW"
  },
  {
    label: "Croatian (Croatia)",
    value: "hr-HR"
  },
  {
    label: "Czech (Czech Republic)",
    value: "cs-CZ"
  },
  {
    label: "Danish (Denmark)",
    value: "da-DK"
  },
  {
    label: "Dutch (Belgium)",
    value: "nl-BE"
  },
  {
    label: "Dutch (Netherlands)",
    value: "nl-NL"
  },
  {
    label: "English (Australia)",
    value: "en-AU"
  },
  {
    label: "English (Canada)",
    value: "en-CA"
  },
  {
    label: "English (Ghana)",
    value: "en-GH"
  },
  {
    label: "English (Hong Kong)",
    value: "en-HK"
  },
  {
    label: "English (India)",
    value: "en-IN"
  },
  {
    label: "English (Ireland)",
    value: "en-IE"
  },
  {
    label: "English (Kenya)",
    value: "en-KE"
  },
  {
    label: "English (New Zealand)",
    value: "en-NZ"
  },
  {
    label: "English (Nigeria)",
    value: "en-NG"
  },
  {
    label: "English (Pakistan)",
    value: "en-PK"
  },
  {
    label: "English (Philippines)",
    value: "en-PH"
  },
  {
    label: "English (Singapore)",
    value: "en-SG"
  },
  {
    label: "English (South Africa)",
    value: "en-ZA"
  },
  {
    label: "English (Tanzania)",
    value: "en-TZ"
  },
  {
    label: "English (United Kingdom)",
    value: "en-GB"
  },
  {
    label: "English (United States)",
    value: "en-US"
  },
  {
    label: "Estonian (Estonia)",
    value: "et-EE"
  },
  {
    label: "Filipino (Philippines)",
    value: "fil-PH"
  },
  {
    label: "Finnish (Finland)",
    value: "fi-FI"
  },
  {
    label: "French (Belgium)",
    value: "fr-BE"
  },
  {
    label: "French (Canada)",
    value: "fr-CA"
  },
  {
    label: "French (France)",
    value: "fr-FR"
  },
  {
    label: "French (Switzerland)",
    value: "fr-CH"
  },
  {
    label: "Galician (Spain)",
    value: "gl-ES"
  },
  {
    label: "Georgian (Georgia)",
    value: "ka-GE"
  },
  {
    label: "German (Austria)",
    value: "de-AT"
  },
  {
    label: "German (Germany)",
    value: "de-DE"
  },
  {
    label: "German (Switzerland)",
    value: "de-CH"
  },
  {
    label: "Greek (Greece)",
    value: "el-GR"
  },
  {
    label: "Gujarati (India)",
    value: "gu-IN"
  },
  {
    label: "Hebrew (Israel)",
    value: "iw-IL"
  },
  {
    label: "Hindi (India)",
    value: "hi-IN"
  },
  {
    label: "Hungarian (Hungary)",
    value: "hu-HU"
  },
  {
    label: "Icelandic (Iceland)",
    value: "is-IS"
  },
  {
    label: "Indonesian (Indonesia)",
    value: "id-ID"
  },
  {
    label: "isiXhosa (South Africa)",
    value: "xh-ZA"
  },
  {
    label: "Italian (Italy)",
    value: "it-IT"
  },
  {
    label: "Italian (Switzerland)",
    value: "it-CH"
  },
  {
    label: "Japanese (Japan)",
    value: "ja-JP"
  },
  {
    label: "Javanese (Indonesia)",
    value: "jv-ID"
  },
  {
    label: "Kannada (India)",
    value: "kn-IN"
  },
  {
    label: "Kazakh (Kazakhstan)",
    value: "kk-KZ"
  },
  {
    label: "Khmer (Cambodia)",
    value: "km-KH"
  },
  {
    label: "Kinyarwanda (Rwanda)",
    value: "rw-RW"
  },
  {
    label: "Korean (South Korea)",
    value: "ko-KR"
  },
  {
    label: "Lao (Laos)",
    value: "lo-LA"
  },
  {
    label: "Latvian (Latvia)",
    value: "lv-LV"
  },
  {
    label: "Lithuanian (Lithuania)",
    value: "lt-LT"
  },
  {
    label: "Macedonian (North Macedonia)",
    value: "mk-MK"
  },
  {
    label: "Malay (Malaysia)",
    value: "ms-MY"
  },
  {
    label: "Malayalam (India)",
    value: "ml-IN"
  },
  {
    label: "Marathi (India)",
    value: "mr-IN"
  },
  {
    label: "Mongolian (Mongolia)",
    value: "mn-MN"
  },
  {
    label: "Nepali (Nepal)",
    value: "ne-NP"
  },
  {
    label: "Norwegian Bokmål (Norway)",
    value: "no-NO"
  },
  {
    label: "Persian (Iran)",
    value: "fa-IR"
  },
  {
    label: "Polish (Poland)",
    value: "pl-PL"
  },
  {
    label: "Portuguese (Brazil)",
    value: "pt-BR"
  },
  {
    label: "Portuguese (Portugal)",
    value: "pt-PT"
  },
  {
    label: "Punjabi (Gurmukhi India)",
    value: "pa-Guru-IN"
  },
  {
    label: "Romanian (Romania)",
    value: "ro-RO"
  },
  {
    label: "Russian (Russia)",
    value: "ru-RU"
  },
  {
    label: "Serbian (Serbia)",
    value: "sr-RS"
  },
  {
    label: "Setswana (South Africa)",
    value: "tn-latn-za"
  },
  {
    label: "Sinhala (Sri Lanka)",
    value: "si-LK"
  },
  {
    label: "Slovak (Slovakia)",
    value: "sk-SK"
  },
  {
    label: "Slovenian (Slovenia)",
    value: "sl-SI"
  },
  {
    label: "Southern Sotho (South Africa)",
    value: "st-ZA"
  },
  {
    label: "Spanish (Argentina)",
    value: "es-AR"
  },
  {
    label: "Spanish (Bolivia)",
    value: "es-BO"
  },
  {
    label: "Spanish (Chile)",
    value: "es-CL"
  },
  {
    label: "Spanish (Colombia)",
    value: "es-CO"
  },
  {
    label: "Spanish (Costa Rica)",
    value: "es-CR"
  },
  {
    label: "Spanish (Dominican Republic)",
    value: "es-DO"
  },
  {
    label: "Spanish (Ecuador)",
    value: "es-EC"
  },
  {
    label: "Spanish (El Salvador)",
    value: "es-SV"
  },
  {
    label: "Spanish (Guatemala)",
    value: "es-GT"
  },
  {
    label: "Spanish (Honduras)",
    value: "es-HN"
  },
  {
    label: "Spanish (Mexico)",
    value: "es-MX"
  },
  {
    label: "Spanish (Nicaragua)",
    value: "es-NI"
  },
  {
    label: "Spanish (Panama)",
    value: "es-PA"
  },
  {
    label: "Spanish (Paraguay)",
    value: "es-PY"
  },
  {
    label: "Spanish (Peru)",
    value: "es-PE"
  },
  {
    label: "Spanish (Puerto Rico)",
    value: "es-PR"
  },
  {
    label: "Spanish (Spain)",
    value: "es-ES"
  },
  {
    label: "Spanish (United States)",
    value: "es-US"
  },
  {
    label: "Spanish (Uruguay)",
    value: "es-UY"
  },
  {
    label: "Spanish (Venezuela)",
    value: "es-VE"
  },
  {
    label: "Sundanese (Indonesia)",
    value: "su-ID"
  },
  {
    label: "Swahili (Kenya)",
    value: "sw-KE"
  },
  {
    label: "Swahili (Tanzania)",
    value: "sw-TZ"
  },
  {
    label: "Swati (South Africa)",
    value: "ss-latn-za"
  },
  {
    label: "Swedish (Sweden)",
    value: "sv-SE"
  },
  {
    label: "Tamil (India)",
    value: "ta-IN"
  },
  {
    label: "Tamil (Malaysia)",
    value: "ta-MY"
  },
  {
    label: "Tamil (Singapore)",
    value: "ta-SG"
  },
  {
    label: "Tamil (Sri Lanka)",
    value: "ta-LK"
  },
  {
    label: "Telugu (India)",
    value: "te-IN"
  },
  {
    label: "Thai (Thailand)",
    value: "th-TH"
  },
  {
    label: "Tsonga (South Africa)",
    value: "ts-ZA"
  },
  {
    label: "Turkish (Turkey)",
    value: "tr-TR"
  },
  {
    label: "Ukrainian (Ukraine)",
    value: "uk-UA"
  },
  {
    label: "Urdu (India)",
    value: "ur-IN"
  },
  {
    label: "Urdu (Pakistan)",
    value: "ur-PK"
  },
  {
    label: "Uzbek (Uzbekistan)",
    value: "uz-UZ"
  },
  {
    label: "Venda (South Africa)",
    value: "ve-ZA"
  },
  {
    label: "Vietnamese (Vietnam)",
    value: "vi-VN"
  },
  {
    label: "Zulu (South Africa)",
    value: "zu-ZA"
  }
]

export const translateLanguagesArray: Array<LanguageOption> = [
    {
        "value": "af",
        "label": "Afrikaans",
        "altValue": ["af-ZA"],
    },
    {
        "value": "ak",
        "label": "Akan"
    },
    {
        "value": "sq",
        "label": "Albanian",
        "altValue": ["sq-AL"],
    },
    {
        "value": "am",
        "label": "Amharic",
        "altValue": ["am-ET"],
    },
    {
        "value": "ar",
        "label": "Arabic",
        "altValue": ["ar-AE", "ar-BH", "ar-DZ", "ar-EG", "ar-IQ", "ar-JO", "ar-KW",
            "ar-LB", "ar-LY", "ar-MA", "ar-OM", "ar-QA", "ar-SA", "ar-SY", "ar-TN", "ar-YE"],
    },
    {
        "value": "hy",
        "label": "Armenian",
        "altValue": ["hy-AM"],
    },
    {
        "value": "as",
        "label": "Assamese",
    },
    {
        "value": "ay",
        "label": "Aymara"
    },
    {
        "value": "az",
        "label": "Azerbaijani",
        "altValue": ["az-AZ"],
    },
    {
        "value": "bm",
        "label": "Bambara"
    },
    {
        "value": "eu",
        "label": "Basque",
        "altValue": ["eu-ES"],
    },
    {
        "value": "be",
        "label": "Belarusian",
    },
    {
        "value": "bn",
        "label": "Bengali",
        "altValue": ["bn-IN", "bn-BD"],
    },
    {
        "value": "bs",
        "label": "Bosnian",
        "altValue": ["bs-BA"],
    },
    {
        "value": "bg",
        "label": "Bulgarian",
        "altValue": ["bg-BG"],
    },
    {
        "value": "my",
        "label": "Burmese",
        "altValue": ["my-MM"],
    },
    {
        "value": "ca",
        "label": "Catalan",
        "altValue": ["ca-ES"],
    },
    {
        "value": "ceb",
        "label": "Cebuano",
    },
    {
        "value": "ckb",
        "label": "Central Kurdish",
    },
    {
        "value": "zh",
        "label": "Chinese (Simplified)",
        "altValue": ["wuu-CN", "zh-CN", "zh-CN-henan", "zh-CN-liaoning", "zh-CN-shaanxi",
            "zh-CN-shandong", "zh-CN-sichuan"],
    },
    {
        "value": "zh-TW",
        "label": "Chinese (Traditional)",
        "altValue": ["zh-HK", "zh-TW", "yue-CN"],
    },
    {
        "value": "co",
        "label": "Corsican",
    },
    {
        "value": "hr",
        "label": "Croatian",
        "altValue": ["hr-HR"],
    },
    {
        "value": "cs",
        "label": "Czech",
        "altValue": ["cs-CZ"],
    },
    {
        "value": "da",
        "label": "Danish",
        "altValue": ["da-DK"],
    },
    {
        "value": "dv",
        "label": "Divehi",
    },
    {
        "value": "doi",
        "label": "Dogri",
    },
    {
        "value": "nl",
        "label": "Dutch",
        "altValue": ["nl-BE", "nl-NL"],
    },
    {
        "value": "en-US",
        "label": "English (United States)",
        "altValue": ["en-AU", "en-CA", "en-HK", "en-IE", "en-IN",
        "en-KE", "en-NG", "en-NZ", "en-PH", "en-SG", "en-TZ", "en-US", "en-ZA"],
    },
    {
        "value": "en-GB",
        "label": "English (United Kingdom)",
        "altValue": ["en-GB"],
    },
    {
        "value": "et",
        "label": "Estonian",
        "altValue": ["et-EE"],
    },
    {
        "value": "ee",
        "label": "Ewe",
    },
    {
        "value": "fa",
        "label": "Farsi (Persian)",
        "altValue": ["fa-IR"],
    },
    {
        "value": "fil",
        "label": "Filipino",
        "altValue": ["fil-PH"],
    },
    {
        "value": "fi",
        "label": "Finnish",
        "altValue": ["fi-FI"],
    },
    {
        "value": "fr",
        "label": "French",
        "altValue": ["fr-BE", "fr-CA", "fr-CH", "fr-FR"],
    },
    {
        "value": "gl",
        "label": "Galician",
        "altValue": ["gl-ES"],
    },
    {
        "value": "lg",
        "label": "Ganda",
    },
    {
        "value": "ka",
        "label": "Georgian",
        "altValue": ["ka-GE"],
    },
    {
        "value": "de",
        "label": "German",
        "altValue": ["de-AT", "de-CH", "de-DE"],
    },
    {
        "value": "gom",
        "label": "Goan Konkani",
    },
    {
        "value": "el",
        "label": "Greek",
        "altValue": ["el-GR"],
    },
    {
        "value": "gn",
        "label": "Guarani",
    },
    {
        "value": "gu",
        "label": "Gujarati",
        "altValue": ["gu-IN"],
    },
    {
        "value": "ht",
        "label": "Haitian Creole",
    },
    {
        "value": "ha",
        "label": "Hausa",
    },
    {
        "value": "iw",
        "label": "Hebrew",
        "altValue": ["he", "he-IL", "iw-IL"],
    },
    {
        "value": "hi",
        "label": "Hindi",
        "altValue": ["hi-IN"],
    },
    {
        "value": "hmn",
        "label": "Hmong",
    },
    {
        "value": "hu",
        "label": "Hungarian",
        "altValue": ["hu-HU"],
    },
    {
        "value": "is",
        "label": "Icelandic",
        "altValue": ["is-IS"],
    },
    {
        "value": "ig",
        "label": "Igbo",
    },
    {
        "value": "ilo",
        "label": "Iloko",
    },
    {
        "value": "id",
        "label": "Indonesian",
        "altValue": ["id-ID"],
    },
    {
        "value": "ga",
        "label": "Irish",
        "altValue": ["ga-IE"],
    },
    {
        "value": "it",
        "label": "Italian",
        "altValue": ["it-IT"],
    },
    {
        "value": "ja",
        "label": "Japanese",
        "altValue": ["ja-JP"],
    },
    {
        "value": "jv",
        "label": "Javanese",
    },
    {
        "value": "jw",
        "label": "Javanese (Roman)",
        "altValue": ["jv-ID"],
    },
    {
        "value": "kn",
        "label": "Kannada",
        "altValue": ["kn-IN"],
    },
    {
        "value": "kk",
        "label": "Kazakh",
        "altValue": ["kk-KZ"],
    },
    {
        "value": "km",
        "label": "Khmer",
        "altValue": ["km-KH"],
    },
    {
        "value": "rw",
        "label": "Kinyarwanda",
    },
    {
        "value": "ko",
        "label": "Korean",
        "altValue": ["ko-KR"],
    },
    {
        "value": "kri",
        "label": "Krio",
    },
    {
        "value": "ku",
        "label": "Kurdish",
    },
    {
        "value": "ky",
        "label": "Kyrgyz",
    },
    {
        "value": "lo",
        "label": "Lao",
        "altValue": ["lo-LA"],
    },
    {
        "value": "la",
        "label": "Latin",
    },
    {
        "value": "lv",
        "label": "Latvian",
        "altValue": ["lv-LV"],
    },
    {
        "value": "ln",
        "label": "Lingala",
    },
    {
        "value": "lt",
        "label": "Lithuanian",
        "altValue": ["lt-LT"],
    },
    {
        "value": "lb",
        "label": "Luxembourgish",
    },
    {
        "value": "mk",
        "label": "Macedonian",
        "altValue": ["mk-MK"],
    },
    {
        "value": "mai",
        "label": "Maithili",
    },
    {
        "value": "mg",
        "label": "Malagasy",
    },
    {
        "value": "ms",
        "label": "Malay",
        "altValue": ["ms-MY"],
    },
    {
        "value": "ml",
        "label": "Malayalam",
        "altValue": ["ml-IN"],
    },
    {
        "value": "mt",
        "label": "Maltese",
        "altValue": ["mt-MT"],
    },
    {
        "value": "mni-Mtei",
        "label": "Manipuri",
    },
    {
        "value": "mi",
        "label": "Māori",
    },
    {
        "value": "mr",
        "label": "Marathi",
        "altValue": ["mr-IN"],
    },
    {
        "value": "lus",
        "label": "Mizo",
    },
    {
        "value": "mn",
        "label": "Mongolian",
        "altValue": ["mn-MN"],
    },
    {
        "value": "ne",
        "label": "Nepali",
        "altValue": ["ne-NP"],
    },
    {
        "value": "nso",
        "label": "Northern Sotho",
    },
    {
        "value": "no",
        "label": "Norwegian",
        "altValue": ["nb-NO"],
    },
    {
        "value": "ny",
        "label": "Nyanja",
    },
    {
        "value": "or",
        "label": "Odia",
    },
    {
        "value": "om",
        "label": "Oromo",
    },
    {
        "value": "ps",
        "label": "Pashto",
        "altValue": ["ps-AF"],
    },
    {
        "value": "pl",
        "label": "Polish",
        "altValue": ["pl-PL"],
    },
    {
        "value": "pt-PT",
        "label": "Portuguese (Portugal)",
        "altValue": ["pt-PT", "pt"],
    },
    {
        "value": "pt-BR",
        "label": "Portuguese (Brazil)",
        "altValue": ["pt-BR"],
    },
    {
        "value": "pa",
        "label": "Punjabi",
    },
    {
        "value": "qu",
        "label": "Quechua",
    },
    {
        "value": "ro",
        "label": "Romanian",
        "altValue": ["ro-RO"],
    },
    {
        "value": "ru",
        "label": "Russian",
        "altValue": ["ru-RU"],
    },
    {
        "value": "sm",
        "label": "Samoan",
    },
    {
        "value": "sa",
        "label": "Sanskrit",
    },
    {
        "value": "gd",
        "label": "Scottish Gaelic",
    },
    {
        "value": "sr",
        "label": "Serbian",
        "altValue": ["sr-RS"],
    },
    {
        "value": "sn",
        "label": "Shona",
    },
    {
        "value": "sd",
        "label": "Sindhi",
    },
    {
        "value": "si",
        "label": "Sinhala",
        "altValue": ["si-LK"],
    },
    {
        "value": "sk",
        "label": "Slovak",
        "altValue": ["sk-SK"],
    },
    {
        "value": "sl",
        "label": "Slovenian",
        "altValue": ["sl-SI"],
    },
    {
        "value": "so",
        "label": "Somali",
        "altValue": ["so-SO"],
    },
    {
        "value": "st",
        "label": "Southern Sotho",
    },
    {
        "value": "es",
        "label": "Spanish",
        "altValue": ["es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-CU", "es-DO",
            "es-EC", "es-ES", "es-GQ", "es-GT", "es-HN", "es-MX", "es-NI", "es-PA",
            "es-PE", "es-PR", "es-PY", "es-SV", "es-US", "es-UY", "es-VE"],
    },
    {
        "value": "su",
        "label": "Sundanese",
        "altValue": ["su-ID"],
    },
    {
        "value": "sw",
        "label": "Swahili",
        "altValue": ["sw-TZ", "sw-KE"],
    },
    {
        "value": "sv",
        "label": "Swedish",
        "altValue": ["sv-SE"],
    },
    {
        "value": "tl",
        "label": "Tagalog",
    },
    {
        "value": "tg",
        "label": "Tajik",
    },
    {
        "value": "ta",
        "label": "Tamil",
        "altValue": ["ta-SG", "ta-IN", "ta-LK", "ta-MY"],
    },
    {
        "value": "tt",
        "label": "Tatar",
    },
    {
        "value": "te",
        "label": "Telugu",
        "altValue": ["te-IN"],
    },
    {
        "value": "th",
        "label": "Thai",
        "altValue": ["th-TH"],
    },
    {
        "value": "ti",
        "label": "Tigrinya",
    },
    {
        "value": "ts",
        "label": "Tsonga",
    },
    {
        "value": "tr",
        "label": "Turkish",
        "altValue": ["tr-TR"],
    },
    {
        "value": "tk",
        "label": "Turkmen",
    },
    {
        "value": "uk",
        "label": "Ukrainian",
        "altValue": ["uk-UA"],
    },
    {
        "value": "ur",
        "label": "Urdu",
        "altValue": ["ur-IN", "ur-PK"],
    },
    {
        "value": "ug",
        "label": "Uyghur",
    },
    {
        "value": "uz",
        "label": "Uzbek",
        "altValue": ["uz-UZ"],
    },
    {
        "value": "vi",
        "label": "Vietnamese",
        "altValue": ["vi-VN"],
    },
    {
        "value": "cy",
        "label": "Welsh",
        "altValue": ["cy-GB"],
    },
    {
        "value": "fy",
        "label": "Western Frisian",
    },
    {
        "value": "xh",
        "label": "Xhosa",
    },
    {
        "value": "yo",
        "label": "Yoruba",
    },
    {
        "value": "zu",
        "label": "Zulu",
        "altValue": ["zu-ZA"],
    }
]

export const dubbingLanguagesArrayLocal: Array<LanguageOption> = [
  {
    "value": "af-ZA",
    "label": "Afrikaans (South Africa)",
  },
  {
    "value": "sq-AL",
    "label": "Albanian (Albania)",
  },
  {
    "value": "am-ET",
    "label": "Amharic (Ethiopia)",
  },
  {
    "value": "ar-AE",
    "label": "Arabic (United Arab Emirates)",
  },
  {
    "value": "ar-BH",
    "label": "Arabic (Bahrain)",
  },
  {
    "value": "ar-DZ",
    "label": "Arabic (Algeria)"
  },
  {
    "value": "ar-EG",
    "label": "Arabic (Egypt)"
  },
  {
    "value": "ar-IQ",
    "label": "Arabic (Iraq)"
  },
  {
    "value": "ar-JO",
    "label": "Arabic (Jordan)"
  },
  {
    "value": "ar-KW",
    "label": "Arabic (Kuwait)"
  },
  {
    "value": "ar-LB",
    "label": "Arabic (Lebanon)"
  },
  {
    "value": "ar-LY",
    "label": "Arabic (Libya)"
  },
  {
    "value": "ar-MA",
    "label": "Arabic (Morocco)"
  },
  {
    "value": "ar-OM",
    "label": "Arabic (Oman)"
  },
  {
    "value": "ar-QA",
    "label": "Arabic (Qatar)"
  },
  {
    "value": "ar-SA",
    "label": "Arabic (Saudi Arabia)"
  },
  {
    "value": "ar-SY",
    "label": "Arabic (Syria)"
  },
  {
    "value": "ar-TN",
    "label": "Arabic (Tunisia)"
  },
  {
    "value": "ar-YE",
    "label": "Arabic (Yemen)"
  },
  {
    "value": "hy-AM",
    "label": "Armenian (Armenia)",
  },
  {
    "value": "az-AZ",
    "label": "Azerbaijani (Azerbaijan)",
  },
  {
    "value": "eu-ES",
    "label": "Basque (Spain)",
  },
  {
    "value": "bn-IN",
    "label": "Bengali (India)",
  },
  {
    "value": "bn-BD",
    "label": "Bangla (Bangladesh)"
  },
  {
    "value": "bs-BA",
    "label": "Bosnian (Bosnia and Herzegovina)",
  },
  {
    "value": "bg-BG",
    "label": "Bulgarian (Bulgaria)",
  },
  {
    "value": "my-MM",
    "label": "Burmese (Myanmar)",
  },
  {
    "value": "ca-ES",
    "label": "Catalan (Spain)",
  },
  {
    "value": "wuu-CN",
    "label": "Chinese (Wu, Simplified)",
  },
  // {
  //   "value": "yue-CN",
  //   "label": "Chinese (Cantonese, Simplified)",
  // },
  {
    "value": "zh-CN",
    "label": "Chinese (Mandarin, Simplified)",
  },
  {
    "value": "zh-CN-henan",
    "label": "Chinese (Zhongyuan Mandarin Henan, Simplified)",
  },
  {
    "value": "zh-CN-liaoning",
    "label": "Chinese (Northeastern Mandarin, Simplified)",
  },
  {
    "value": "zh-CN-shaanxi",
    "label": "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
  },
  {
    "value": "zh-CN-shandong",
    "label": "Chinese (Jilu Mandarin, Simplified)",
  },
  {
    "value": "zh-CN-sichuan",
    "label": "Chinese (Southwestern Mandarin, Simplified)",
  },
  {
    "value": "zh-HK",
    "label": "Chinese (Cantonese, Traditional)",
  },
  {
    "value": "zh-TW",
    "label": "Chinese (Taiwanese Mandarin, Traditional)",
  },
  {
    "value": "hr-HR",
    "label": "Croatian (Croatia)",
  },
  {
    "value": "cs-CZ",
    "label": "Czech (Czechia)",
  },
  {
    "value": "da-DK",
    "label": "Danish (Denmark)",
  },
  {
    "value": "nl-BE",
    "label": "Dutch (Belgium)",
  },
  {
    "value": "nl-NL",
    "label": "Dutch (Netherlands)",
  },
  {
    "value": "en-AU",
    "label": "English (Australia)",
  },
  {
    "value": "en-CA",
    "label": "English (Canada)",
  },
  {
    "value": "en-GB",
    "label": "English (United Kingdom)",
  },
  {
    "value": "en-HK",
    "label": "English (Hong Kong SAR)",
  },
  {
    "value": "en-IE",
    "label": "English (Ireland)",
  },
  {
    "value": "en-IN",
    "label": "English (India)",
  },
  {
    "value": "en-KE",
    "label": "English (Kenya)",
  },
  {
    "value": "en-NG",
    "label": "English (Nigeria)",
  },
  {
    "value": "en-NZ",
    "label": "English (New Zealand)",
  },
  {
    "value": "en-PH",
    "label": "English (Philippines)",
  },
  {
    "value": "en-SG",
    "label": "English (Singapore)",
  },
  {
    "value": "en-TZ",
    "label": "English (Tanzania)",
  },
  {
    "value": "en-US",
    "label": "English (United States)",
  },
  {
    "value": "en-ZA",
    "label": "English (South Africa)",
  },
  {
    "value": "et-EE",
    "label": "Estonian (Estonia)",
  },
  {
    "value": "fa-IR",
    "label": "Persian (Iran)",
  },
  {
    "value": "fil-PH",
    "label": "Filipino (Philippines)",
  },
  {
    "value": "fi-FI",
    "label": "Finnish (Finland)",
  },
  {
    "value": "fr-BE",
    "label": "French (Belgium)",
  },
  {
    "value": "fr-CA",
    "label": "French (Canada)",
  },
  {
    "value": "fr-CH",
    "label": "Switzerland",
  },
  {
    "value": "fr-FR",
    "label": "French (France)",
  },
  {
    "value": "gl-ES",
    "label": "Galician",
  },
  {
    "value": "ka-GE",
    "label": "Georgian (Georgia)",
  },
  {
    "value": "de-AT",
    "label": "German (Austria)",
  },
  {
    "value": "de-DE",
    "label": "German (Germany)",
  },
  {
    "value": "de-CH",
    "label": "German (Switzerland)",
  },
  {
    "value": "el-GR",
    "label": "Greek (Greece)",
  },
  {
    "value": "gu-IN",
    "label": "Gujarati (India)",
  },
  {
    "value": "iw-IL",
    "label": "Hebrew (Israel)",
  },
  {
    "value": "hi-IN",
    "label": "Hindi (India)",
  },
  {
    "value": "hu-HU",
    "label": "Hungarian (Hungary)",
  },
  {
    "value": "is-IS",
    "label": "Icelandic (Iceland)",
  },
  {
    "value": "id-ID",
    "label": "Indonesian",
  },
  {
    "value": "ga-IE",
    "label": "Irish (Ireland)",
  },
  {
    "value": "it-IT",
    "label": "Italian",
  },
  {
    "value": "ja-JP",
    "label": "Japanese (Japan)",
  },
  {
    "value": "jv-ID",
    "label": "Javanese (Latin, Indonesia)",
  },
  // {
  //   "value": "jw",
  //   "label": "Javanese (Latin, Indonesia)",
  // },
  {
    "value": "kn-IN",
    "label": "Kannada (India)",
  },
  {
    "value": "kk-KZ",
    "label": "Kazakh (Kazakhstan)",
  },
  {
    "value": "km-KH",
    "label": "Khmer (Cambodia)",
  },
  {
    "value": "ko-KR",
    "label": "Korean (Korea)",
  },
  {
    "value": "lo-LA",
    "label": "Lao (Laos)",
  },
  {
    "value": "lv-LV",
    "label": "Latvian (Latvia)",
  },
  {
    "value": "lt-LT",
    "label": "Lithuanian (Lithuania)",
  },
  {
    "value": "mk-MK",
    "label": "Macedonian (North Macedonia)",
  },
  {
    "value": "ms-MY",
    "label": "Malay (Malaysia)",
  },
  {
    "value": "ml-IN",
    "label": "Malayalam (India)",
  },
  {
    "value": "mt-MT",
    "label": "Maltese (Malta)",
  },
  {
    "value": "mr-IN",
    "label": "Marathi (India)",
  },
  {
    "value": "mn-MN",
    "label": "Mongolian (Mongolia)",
  },
  {
    "value": "ne-NP",
    "label": "Nepali (Nepal)",
  },
  {
    "value": "nb-NO",
    "label": "Norwegian Bokmål (Norway)",
  },
  {
    "value": "ps-AF",
    "label": "Pashto (Afghanistan)",
  },
  {
    "value": "pl-PL",
    "label": "Polish (Poland)",
  },
  {
    "value": "pt-BR",
    "label": "Portuguese (Brazil)",
  },
  {
    "value": "pt-PT",
    "label": "Portuguese (Portugal)",
  },
  {
    "value": "ro-RO",
    "label": "Romanian (Romania)",
  },
  {
    "value": "ru-RU",
    "label": "Russian (Russia)",
  },
  {
    "value": "sr-RS",
    "label": "Serbian (Cyrillic, Serbia)",
  },
  // {
  //   "value": "sr-Latn-RS",
  //   "label": "Serbian (Latin, Serbia)",
  // },
  {
    "value": "si-LK",
    "label": "Sinhala (Sri Lanka)",
  },
  {
    "value": "sk-SK",
    "label": "Slovak (Slovakia)",
  },
  {
    "value": "sl-SI",
    "label": "Slovenian (Slovenia)",
  },
  {
    "value": "so-SO",
    "label": "Somali (Somalia)",
  },
  {
    "value": "es-AR",
    "label": "Spanish (Argentina)",
  },
  {
    "value": "es-BO",
    "label": "Spanish (Bolivia)",
  },
  {
    "value": "es-CL",
    "label": "Spanish (Chile)",
  },
  {
    "value": "es-CO",
    "label": "Spanish (Colombia)",
  },
  {
    "value": "es-CR",
    "label": "Spanish (Costa Rica)",
  },
  {
    "value": "es-CU",
    "label": "Spanish (Cuba)",
  },
  {
    "value": "es-DO",
    "label": "Spanish (Dominican Republic)",
  },
  {
    "value": "es-EC",
    "label": "Spanish (Ecuador)",
  },
  {
    "value": "es-ES",
    "label": "Spanish (Spain)",
  },
  {
    "value": "es-GQ",
    "label": "Spanish (Equatorial Guinea)",
  },
  {
    "value": "es-GT",
    "label": "Spanish (Guatemala)",
  },
  {
    "value": "es-HN",
    "label": "Spanish (Honduras)",
  },
  {
    "value": "es-MX",
    "label": "Spanish (Mexico)",
  },
  {
    "value": "es-NI",
    "label": "Spanish (Nicaragua)",
  },
  {
    "value": "es-PA",
    "label": "Spanish (Panama)",
  },
  {
    "value": "es-PE",
    "label": "Spanish (Peru)",
  },
  {
    "value": "es-PR",
    "label": "Spanish (Puerto Rico)",
  },
  {
    "value": "es-PY",
    "label": "Spanish (Paraguay)",
  },
  {
    "value": "es-SV",
    "label": "Spanish (El Salvador)",
  },
  {
    "value": "es-US",
    "label": "Spanish (United States)",
  },
  {
    "value": "es-UY",
    "label": "Spanish (Uruguay)",
  },
  {
    "value": "es-VE",
    "label": "Spanish (Venezuela)",
  },
  {
    "value": "su-ID",
    "label": "Sundanese (Indonesia)",
  },
  {
    "value": "sw-KE",
    "label": "Swahili (Kenya)",
  },
  {
    "value": "sw-TZ",
    "label": "Swahili (Tanzania)",
  },
  {
    "value": "sv-SE",
    "label": "Swedish (Sweden)",
  },
  {
    "value": "ta-SG",
    "label": "Tamil (Singapore)",
  },
  {
    "value": "ta-IN",
    "label": "Tamil (India)",
  },
  {
    "value": "ta-LK",
    "label": "Tamil (Sri Lanka)",
  },
  {
    "value": "ta-MY",
    "label": "Tamil (Malaysia)",
  },
  {
    "value": "te-IN",
    "label": "Telugu (India)",
  },
  {
    "value": "th-TH",
    "label": "Thai (Thailand)",
  },
  {
    "value": "tr-TR",
    "label": "Turkish (Turkey)",
  },
  {
    "value": "uk-UA",
    "label": "Ukrainian (Ukraine)",
  },
  {
    "value": "ur-IN",
    "label": "Urdu (India)",
  },
  {
    "value": "ur-PK",
    "label": "Urdu (Pakistan)",
  },
  {
    "value": "uz-UZ",
    "label": "Uzbek (Latin, Uzbekistan)",
  },
  {
    "value": "vi-VN",
    "label": "Vietnamese (Vietnam)",
  },
  {
    "value": "cy-GB",
    "label": "Welsh (United Kingdom)",
  },
  {
    "value": "zu-ZA",
    "label": "Zulu (South Africa)",
  }
];

export const dubbingLanguagesArray: Array<LanguageOption> = [
    {
        "value": "en",
        "label": "English",
    },
    {
        "value": "hi",
        "label": "Hindi",
    },
    {
        "value": "pt",
        "label": "Portuguese",
    },
    {
        "value": "zh",
        "label": "Chinese",
    },
    {
        "value": "es",
        "label": "Spanish",
    },
    {
        "value": "fr",
        "label": "French",
    },
    {
        "value": "de",
        "label": "German",
    },
    {
        "value": "ja",
        "label": "Japanese",
    },
    {
        "value": "ar",
        "label": "Arabic",
    },
    {
        "value": "ru",
        "label": "Russian",
    },
    {
        "value": "ko",
        "label": "Korean",
    },
    {
        "value": "id",
        "label": "Indonesian",
    },
    {
        "value": "it",
        "label": "Italian",
    },
    {
        "value": "nl",
        "label": "Dutch",
    },
    {
        "value": "tr",
        "label": "Turkish",
    },
    {
        "value": "pl",
        "label": "Polish",
    },
    {
        "value": "sv",
        "label": "Swedish",
    },
    {
        "value": "fil",
        "label": "Filipino",
    },
    {
        "value": "ms",
        "label": "Malay",
    },
    {
        "value": "ro",
        "label": "Romanian",
    },
    {
        "value": "uk",
        "label": "Ukrainian",
    },
    {
        "value": "el",
        "label": "Greek",
    },
    {
        "value": "cs",
        "label": "Czech",
    },
    {
        "value": "da",
        "label": "Danish",
    },
    {
        "value": "fi",
        "label": "Finnish",
    },
    {
        "value": "bg",
        "label": "Bulgarian",
    },
    {
        "value": "hr",
        "label": "Croatian",
    },
    {
        "value": "sk",
        "label": "Slovak",
    },
    {
        "value": "ta",
        "label": "Tamil",
    },
];

const charsList: any = {
    Ё: 'YO',
    Й: 'I',
    Ц: 'TS',
    У: 'U',
    К: 'K',
    Е: 'E',
    Н: 'N',
    Г: 'G',
    Ш: 'SH',
    Щ: 'SCH',
    З: 'Z',
    Х: 'H',
    Ъ: "'",
    ё: 'yo',
    й: 'i',
    ц: 'ts',
    у: 'u',
    к: 'k',
    е: 'e',
    н: 'n',
    г: 'g',
    ш: 'sh',
    щ: 'sch',
    з: 'z',
    х: 'h',
    ъ: "'",
    Ф: 'F',
    Ы: 'I',
    В: 'V',
    А: 'a',
    П: 'P',
    Р: 'R',
    О: 'O',
    Л: 'L',
    Д: 'D',
    Ж: 'ZH',
    Э: 'E',
    ф: 'f',
    ы: 'i',
    в: 'v',
    а: 'a',
    п: 'p',
    р: 'r',
    о: 'o',
    л: 'l',
    д: 'd',
    ж: 'zh',
    э: 'e',
    Я: 'Ya',
    Ч: 'CH',
    С: 'S',
    М: 'M',
    И: 'I',
    Т: 'T',
    Ь: "'",
    Б: 'B',
    Ю: 'YU',
    я: 'ya',
    ч: 'ch',
    с: 's',
    м: 'm',
    и: 'i',
    т: 't',
    ь: "'",
    б: 'b',
    ю: 'yu',
};

export const transliterate = (word: string): string =>
    word
        .split('')
        .map((char) => charsList[char] || char)
        .join('');

// export const getCountryFlagByCode = (code: string) => {
//     switch (code) {
//         case 'en-US':
//             return '🇺🇸';
//         case 'tr-TR':
//             return '🇹🇷';
//         case 'sv-SE':
//             return '🇸🇪';
//         case 'ru-RU':
//             return '🇷🇺';
//         case 'ro-RO':
//             return '🇷🇴';
//         case 'pt-PT':
//             return '🇵🇹';
//         case 'pt-BR':
//             return '🇧🇷';
//         case 'pl-PL':
//             return '🇵🇱 ';
//         case 'nl-NL':
//             return '🇳🇱';
//         case 'nb-NO':
//             return '🇳🇴';
//         case 'ko-KR':
//             return '🇰🇷';
//         case 'ja-JP':
//             return '🇯🇵';
//         case 'it-IT':
//             return '🇮🇹';
//         case 'is-IS':
//             return '🇮🇸';
//         case 'fr-FR':
//             return '🇫🇷';
//         case 'fr-CA':
//             return '🇨🇦';
//         case 'es-US':
//             return '🇺🇸';
//         case 'es-MX':
//             return '🇲🇽';
//         case 'es-ES':
//             return '🇪🇸';
//         case 'en-GB-WLS':
//             return '🏴󠁧󠁢󠁷󠁬󠁳󠁿';
//         case 'en-NZ':
//             return '🇳🇿';
//         case 'en-ZA':
//             return '🇿🇦';
//         case 'en-IN':
//             return '🇮🇳';
//         case 'en-GB':
//             return '🇬🇧';
//         case 'en-AU':
//             return '🇦🇺';
//         case 'de-DE':
//             return '🇩🇪';
//         case 'da-DK':
//             return '🇩🇰';
//         case 'cy-GB':
//             return '🏴󠁧󠁢󠁷󠁬󠁳󠁿';
//         case 'cmn-CN':
//             return '🇨🇳';
//         case 'arb':
//             return '🇦🇪';
//         case 'ar-AE':
//             return '🇦🇪';
//         case 'ca-ES':
//             return '🇪🇸';
//         case 'de-AT':
//             return '🇩🇪';
//         case 'yue-CN':
//             return '🇨🇳';
//         case 'fi-FI':
//             return '🇫🇮';
//         default:
//             return '';
//     }
// };

export const getFlagByCountryCode = (code: string) => {
  switch (code.toLowerCase()) {
    case 'af-za':
    case 'en-za':
    case 'af':
    case 'en-ab':
    case 'nso':
      return za;
    case 'st':
      return ls;
    case 'am-et':
    case 'am':
    case 'ti':
      return et;
    case 'ar-ae':
      return ae;
    case 'ar-bh':
      return bh;
    case 'ar-dz':
      return dz;
    case 'ar-eg':
      return eg;
    case 'ar-il':
      return il;
    case 'ar-ly':
      return ly;
    case 'ar-sy':
      return sy;
    case 'ar-iq':
    case 'ckb':
    case 'ku':
      return iq;
    case 'ar-jo':
      return jo;
    case 'ar-kw':
      return kw;
    case 'ar-lb':
      return lb;
    case 'ar-ma':
      return ma;
    case 'ar-mr':
      return mr;
    case 'ar-om':
      return om;
    case 'ar-ps':
      return ps;
    case 'ar-qa':
      return qa;
    case 'ar-sa':
    case 'ar':
    case 'arb':
      return sa;
    case 'ar-tn':
      return tn;
    case 'ar-ye':
      return ye;
    case 'az-az':
    case 'az':
      return az;
    case 'be':
      return by;
    case 'bm':
      return ml;
    case 'bg-bg':
    case 'bg':
      return bg;
    case 'bn-bd':
    case 'bn':
      return bd;
    case 'bn-in':
      return inFlag;
    case 'bs-ba':
    case 'bs':
      return ba;
    case 'ca-es':
    case 'ca':
    case 'eu':
    case 'gl':
      return es;
    case 'cs-cz':
    case 'cs':
      return cz;
    case 'ln':
      return cd;
    case 'da-dk':
    case 'da':
      return dk;
    case 'de-at':
      return at;
    case 'de-ch':
      return ch;
    case 'de-de':
    case 'de':
      return de;
    case 'dv':
      return mv;
    case 'el-gr':
    case 'el':
      return gr;
    case 'en-au':
      return au;
    case 'en-ca':
      return ca;
    case 'en-gb':
    case 'en-GB':
    case 'en':
    case 'gd-gb':
    case 'gd':
      return gb;
    case 'en-wl':
    case 'cy':
    case 'cy-gb':
    case 'en-gb-wls':
      return gbWls;
    case 'en-gh':
    case 'ak':
    case 'ee':
      return gh;
    case 'en-hk':
      return hk;
    case 'en-ie':
    case 'ga':
    case 'ga-ie':
      return ie;
    case 'en-in':
      return inFlag;
    case 'en-ke':
    case 'om':
      return ke;
    case 'en-ng':
    case 'ha':
    case 'ig':
    case 'yo':
      return ng;
    case 'en-nz':
      return nz;
    case 'en-ph':
    case 'tl':
    case 'ceb':
    case 'ilo':
      return ph;
    case 'en-pk':
      return pk;
    case 'en-sg':
      return sg;
    case 'en-tz':
      return tz;
    case 'en-US':
    case 'en-us':
    case 'es-us':
      return us;
    case 'es-ar':
      return ar;
    case 'es-cu':
      return cu;
    case 'es-gq':
      return gq;
    case 'es-bo':
    case 'ay':
      return bo;
    case 'es-cl':
      return cl;
    case 'es-co':
      return co;
    case 'es-cr':
      return cr;
    case 'es-do':
      return doFlag;
    case 'es-ec':
      return ec;
    case 'es-es':
    case 'es':
      return es;
    case 'es-gt':
      return gt;
    case 'es-hn':
      return hn;
    case 'es-mx':
      return mx;
    case 'es-ni':
      return ni;
    case 'es-pa':
      return pa;
    case 'es-pe':
    case 'qu':
      return pe;
    case 'es-pr':
      return pr;
    case 'es-py':
    case 'gn':
      return py;
    case 'es-sv':
      return sv;
    case 'es-uy':
      return uy;
    case 'es-ve':
      return ve;
    case 'la':
      return va;
    case 'lg':
      return ug;
    case 'et-ee':
    case 'et':
      return ee;
    case 'eu-es':
      return es;
    case 'fa-ir':
    case 'fa':
      return ir;
    case 'fi-fi':
    case 'fi':
      return fi;
    case 'fil-ph':
    case 'fil':
      return ph;
    case 'fr-be':
      return be;
    case 'fr-ca':
      return ca;
    case 'fr-ch':
      return ch;
    case 'fr-fr':
    case 'fr':
    case 'co':
      return fr;
    case 'fa-af':
    case 'ps':
    case 'ps-af':
      return af;
    case 'gl-es':
      return es;
    case 'gu-in':
    case 'gom':
      return inFlag;
    case 'hi-in':
    case 'te':
    case 'pa':
    case 'mr':
    case 'ml':
    case 'kn':
    case 'hi':
    case 'gu':
    case 'as':
    case 'doi':
    case 'lus':
    case 'mai':
    case 'mni-mtei':
    case 'or':
    case 'sa':
      return inFlag;
    case 'hr-hr':
    case 'hr':
      return hr;
    case 'hu-hu':
    case 'hu':
      return hu;
    case 'hy-am':
    case 'hy':
      return am;
    case 'id-id':
    case 'id':
    case 'jv-id':
    case 'su-id':
    case 'su':
    case 'jv':
    case 'jw':
      return id;
    case 'is-is':
    case 'is':
      return is;
    case 'it-ch':
      return ch;
    case 'it-it':
    case 'it':
      return it;
    case 'iw-il':
    case 'he-il':
    case 'he':
    case 'iw':
      return il;
    case 'ht':
      return ht;
    case 'ja-jp':
    case 'ja':
      return jp;
    case 'ka-ge':
    case 'ka':
      return ge;
    case 'kk-kz':
    case 'kk':
      return kz;
    case 'km-kh':
    case 'km':
      return kh;
    case 'ky':
      return kg;
    case 'kn-in':
      return inFlag;
    case 'ko-kr':
    case 'ko':
      return kr;
    case 'lo-la':
    case 'lo':
      return la;
    case 'lt-lt':
    case 'lt':
      return lt;
    case 'lv-lv':
    case 'lv':
      return lv;
    case 'lb':
      return lu;
    case 'mk-mk':
    case 'mk':
      return mk;
    case 'mg':
      return mg;
    case 'mi':
      return mi;
    case 'ml-in':
      return inFlag;
    case 'mn-mn':
    case 'mn':
      return mn;
    case 'mr-in':
      return inFlag;
    case 'ms-my':
    case 'ms':
    case 'ta-my':
      return my;
    case 'my-mm':
    case 'my':
      return mm;
    case 'ny':
      return zm;
    case 'mt':
    case 'mt-mt':
      return mt;
    case 'ne-np':
    case 'ne':
      return np;
    case 'nl-be':
      return be;
    case 'nl-nl':
    case 'nl':
    case 'fy':
      return nl;
    case 'no-no':
    case 'nb-no':
    case 'no':
      return no;
    case 'pa-guru-in':
      return inFlag;
    case 'pl-pl':
    case 'pl':
      return pl;
    case 'pt-BR':
    case 'pt-br':
      return br;
    case 'pt-PT':
    case 'pt-pt':
    case 'pt':
      return pt;
    case 'ro-ro':
    case 'ro':
      return ro;
    case 'ru-ru':
    case 'ru':
      return ru;
    case 'rw-rw':
    case 'rw':
      return rw;
    case 'si-lk':
    case 'ta-lk':
    case 'ta':
    case 'si':
      return lk;
    case 'sk-sk':
    case 'sk':
      return sk;
    case 'sl-si':
    case 'sl':
      return si;
    case 'kri':
      return sl;
    case 'sq-al':
    case 'sq':
      return al;
    case 'sr-rs':
    case 'sr':
      return rs;
    case 'ss-latn-za':
      return za;
    case 'st-za':
      return za;
    case 'sv-se':
    case 'sv':
      return se;
    case 'so':
    case 'so-so':
      return so;
    case 'sw-ke':
      return ke;
    case 'sw-tz':
    case 'sw':
      return tz;
    case 'ta-in':
      return inFlag;
    case 'ta-sg':
      return sg;
    case 'tg':
      return tj;
    case 'tk':
      return tm;
    case 'te-in':
      return inFlag;
    case 'th-th':
    case 'th':
      return th;
    case 'tn-latn-za':
      return za;
    case 'tr-tr':
    case 'tr':
      return tr;
    case 'tt':
      return kz;
    case 'ts-za':
    case 'ts':
      return za;
    case 'uk-ua':
    case 'uk':
      return ua;
    case 'ur-in':
      return inFlag;
    case 'ur-pk':
    case 'ur':
    case 'sd':
      return pk;
    case 'uz-uz':
    case 'uz':
      return uz;
    case 've-za':
      return za;
    case 'vi-vn':
    case 'vi':
      return vn;
    case 'sm':
      return ws;
    case 'xh-za':
    case 'xh':
      return za;
    case 'yue-hant-hk':
    case 'zh-hk':
      return hk;
    case 'zh-hans-cn':
    case 'zh-cn':
    case 'yue-cn':
    case 'cmn-cn':
    case 'zh':
    case 'hmn':
    case 'wuu-cn':
    case 'zh-cn-henan':
    case 'zh-cn-liaoning':
    case 'zh-cn-shaanxi':
    case 'zh-cn-shandong':
    case 'zh-cn-sichuan':
      return cn;
    case 'ug':
      return ur;
    case 'zh-hant-tw':
    case 'zh-tw':
      return tw;
    case 'zu-za':
    case 'zu':
      return za;
    case 'sn':
      return zw;
    default:
      return null;
  }
};
