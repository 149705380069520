import { IconProps } from '../index';

const SoundOffIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 14V10C2 8.89543 2.89543 8 4 8H6.35907C6.77592 8 7.18234 7.86975 7.52155 7.62747L12.4188 4.12946C13.0806 3.65669 14 4.12982 14 4.94319V19.0568C14 19.8702 13.0806 20.3433 12.4188 19.8705L7.52155 16.3725C7.18234 16.1302 6.77592 16 6.35907 16H4C2.89543 16 2 15.1046 2 14Z"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M22 14L18 10"
                  stroke={color}
                  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path d="M22 10L18 14"
                  stroke={color}
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
        </svg>
    );
};

export default SoundOffIconNew;


