export const getImageDimension = (image: File) =>
    new Promise<{ width: number; height: number }>((res, rej) => {
        try {
            const fr = new FileReader();

            fr.onload = () => {
                // file is loaded
                const img = new Image();

                img.onload = () =>
                    res({
                        width: img.width,
                        height: img.height,
                    });

                img.onerror = (error) => {
                    console.log({ error });
                };

                img.src = fr.result as any; // is the data URL because called with readAsDataURL
            };

            fr.readAsDataURL(image);
        } catch (error) {
            console.log({ error });

            rej();
        }
    });

export const getImageMeta = (url: string) =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject();
        img.src = url;
    });
