import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 0.764;

const Calendar = ({ size, color }: IconProps): JSX.Element => {
  let height = 21;
  if (size) {
    height = size;
  }

  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg width={width} height={height} viewBox="0 0 13 17" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="10-Nova-Enterprise---Filter-3"
          transform="translate(-1155.000000, -477.000000)"
          fill={color}
        >
          <g
            id="Filter-3"
            transform="translate(1012.000000, 198.000000)"
          >
            <g
              id="Group-4-Copy-2"
              transform="translate(22.000000, 270.000000)"
            >
              <path
                d="M130.750141,9 L130.750141,10.6250234 L124.250047,10.6250234 L124.250047,9 L122.625023,9 L122.625023,10.6250234 L121,10.6250234 L121,14.687582 L121,16.3126055 L121,25.2502344 L134.001,25.2502344 L134.001,10.6250234 L132.375977,10.6250234 L132.375977,9 L130.750141,9 Z M122.625023,14.6883945 L132.375977,14.6883945 L132.375977,12.2500469 L122.625023,12.2500469 L122.625023,14.6883945 Z M122.625023,23.6252109 L132.375977,23.6252109 L132.375977,16.3126055 L122.625023,16.3126055 L122.625023,23.6252109 Z M124.250047,22.0001875 L128.312605,22.0001875 L128.312605,17.9376289 L124.250047,17.9376289 L124.250047,22.0001875 Z"
                id="calendar"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Calendar;
