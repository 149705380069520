import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import Subtitles from "components/Subtitles";
import { NewInput, NewButton, ColorSelect, Icon } from "components/UI";

import { ColorOption } from "interfaces/colors";

import { getVideoInfo } from "state/modules/media";
import { getTranscriptList } from "state/modules/metadata";
import { getBurningSubtitlesInProgressJobs } from "state/modules/jobs";
import { getMediaLanguagesCodes } from "state/modules/transcript";
import { getMediaLanguageCode } from "state/modules/videoExplorer";
import {
  exportSubtitledVideo,
  getIsExportSubtitledVideoLoading,
  getIsSubtitledVideoDataLoading,
  getSubtitledVideoData,
  getSubtitledVideoDataInfo,
} from "state/modules/export";

import { secondsToHms } from "utils/files";
import { colorsOptions } from "utils/colors";

import styles from "./styles.module.scss";

const PLAYING_DEBOUNCE_TIME = 50;

const windowFork = window;

const ExportSubtitlesModal = (): JSX.Element => {
  const [fontSize, setFontSize] = useState(16);
  const [color, setColor] = useState<ColorOption>(colorsOptions[0]);
  const [activeSentenceId, setActiveSentenceId] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [lastJobSettings, setLastJobSettings] = useState<any>(null);

  const isWaitingTimeout = useRef(null) as any;
  const isPlayingTimeout = useRef(null) as any;

  const videoDetails = useSelector(getVideoInfo);
  const burningSubtitlesInProgressJobs = useSelector(
    getBurningSubtitlesInProgressJobs
  );
  const transcript = useSelector(getTranscriptList);
  const mediaLanguagesCodes = useSelector(getMediaLanguagesCodes);
  const mediaLanguageCode = useSelector(getMediaLanguageCode);

  const isExportSubtitledVideoLoading = useSelector(
    getIsExportSubtitledVideoLoading
  );
  const isSubtitledVideoDataLoading = useSelector(
    getIsSubtitledVideoDataLoading
  );
  const subtitledVideoData = useSelector(getSubtitledVideoDataInfo);

  const videoMediaInfo =
    videoDetails?.mediaInfo?.proxy || videoDetails?.mediaInfo?.original;

  const dispatch = useDispatch();

  const videoRef = useRef<HTMLVideoElement>(null);

  const hasJobsInProgress = burningSubtitlesInProgressJobs.length > 0;

  const localVideoUrl =
    windowFork.config.REACT_APP_CLOUDFRONT_URL + videoDetails?.s3Path || null;

  const videoUrl = localVideoUrl || "";

  const hasSettingsDiff =
    color.value !== lastJobSettings?.color ||
    fontSize !== +lastJobSettings?.fontSize;

  useEffect(() => {
    dispatch(getSubtitledVideoData());
  }, [dispatch]);

  useEffect(() => {
    if (!lastJobSettings) {
      if (burningSubtitlesInProgressJobs.length) {
        const lastJob = burningSubtitlesInProgressJobs[0];
        const selectedColorOption = colorsOptions.find(
          (colorOption) => colorOption.value === lastJob.color
        );

        setFontSize(+lastJob.fontSize);

        if (selectedColorOption) {
          setColor(selectedColorOption);
        }

        setLastJobSettings({
          color: lastJob.color,
          fontSize: lastJob.fontSize,
        });
      } else if (!burningSubtitlesInProgressJobs.length && subtitledVideoData) {
        const settings = subtitledVideoData?.lastJobSettings;

        if (settings) {
          const selectedColorOption = colorsOptions.find(
            (colorOption) => colorOption.value === settings.color
          );

          setFontSize(+settings.fontSize);

          if (selectedColorOption) {
            setColor(selectedColorOption);
          }

          setLastJobSettings(settings);
        }
      }
    }
  }, [burningSubtitlesInProgressJobs, lastJobSettings, subtitledVideoData]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const element = videoRef.current;

    const pauseHandler = (): void => {
      clearTimeout(isWaitingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const loadedMetadataHandler = (): void => {
      setIsMetadataLoaded(true);
    };

    const timeUpdateHandler = (event: any): void => {
      setCurrentTime(event?.target?.currentTime);
      setIsVideoFinished(false);
      setIsWaiting(false);
    };

    const videoFinishedHandler = (): void => {
      setIsVideoFinished(true);
      setIsPlaying(false);
    };

    element.addEventListener("pause", pauseHandler);
    element.addEventListener("timeupdate", timeUpdateHandler);
    element.addEventListener("loadedmetadata", loadedMetadataHandler);
    element.addEventListener("ended", videoFinishedHandler);

    return (): void => {
      element.removeEventListener("pause", pauseHandler);
      element.removeEventListener("timeupdate", timeUpdateHandler);
      element.removeEventListener("loadedmetadata", loadedMetadataHandler);
      element.removeEventListener("ended", videoFinishedHandler);
    };
  }, [videoRef]);

  const handleSubmit = () => {
    dispatch(
      exportSubtitledVideo({
        videoId: videoDetails?.id || "",
        color: color.value,
        fontSize,
        language: mediaLanguageCode || mediaLanguagesCodes[0],
      })
    );
    setLastJobSettings({
      color: color.value,
      fontSize,
    });

    localStorage.removeItem(videoDetails?.id || "");
  };

  const handleDownload = useCallback(() => {
    try {
      const link = document.createElement("a");
      link.target = "_blank";

      link.href = subtitledVideoData?.signetUrl || "";

      document.body.appendChild(link);
      link.click();

        if (document?.body?.contains(link)) {
            document.body.removeChild(link);
        }
    } catch (error) {
      console.log({ error });
    }
  }, [subtitledVideoData]);

  const changeFontSize = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numValue = +value;

    if (Number.isNaN(numValue)) {
      return;
    }

    if (!value) {
      setFontSize(0);
    } else {
      setFontSize(numValue);
    }
  };

  const handleChangeColor = (newColor: any) => {
    if (newColor) {
      setColor(newColor);
    }
  };

  const handleSetActiveSentenceId = useCallback((id: string | null) => {
    setActiveSentenceId(id);
  }, []);

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      try {
        if (!videoRef.current.paused) {
          videoRef.current.pause();
          setIsPlaying(false);
        } else {
          videoRef.current.play();
          setIsPlaying(true);
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const handleDownloadClick = () => {
    let isTranscriptChanged = false;

    const rawVideoInfo = localStorage.getItem(videoDetails?.id || "");

    const parsed = rawVideoInfo ? JSON.parse(rawVideoInfo) : null;

    isTranscriptChanged = parsed?.isTranscriptChanged || false;

    if (hasSettingsDiff || isTranscriptChanged) {
      handleSubmit();
    } else {
      handleDownload();
    }
  };

  const renderFontSizeInput = (): JSX.Element => (
    <div className={styles.ExportSubtitlesModal__inputWrap}>
      <p className={styles.ExportSubtitlesModal__inputLabel}>
        Choose Font Size
      </p>
      <NewInput value={fontSize} onChange={changeFontSize} />
    </div>
  );

  const renderColorInput = (): JSX.Element => (
    <div className={styles.ExportSubtitlesModal__inputWrap}>
      <p className={styles.ExportSubtitlesModal__inputLabel}>Choose Color</p>
      <div
        className={styles.ExportSubtitlesModal__input}
        style={{
          padding: 0,
        }}
      >
        <ColorSelect
          options={colorsOptions}
          onChange={handleChangeColor}
          value={color}
          position="left"
        />
      </div>
    </div>
  );

  const renderSubtitles = (): JSX.Element => {
    if (
      videoRef?.current &&
      transcript &&
      videoMediaInfo &&
      !isSubtitledVideoDataLoading &&
      isMetadataLoaded
    ) {
      return (
        <Subtitles
          currentTime={currentTime}
          transcript={transcript}
          videoRef={videoRef}
          setActiveSentenceId={handleSetActiveSentenceId}
          activeSentenceId={activeSentenceId}
          zIndex={10}
          dark={color.value === "black"}
          light={color.value === "white"}
          fontSize={fontSize}
          defaultCoords={{
            y: videoRef.current.clientHeight - 75,
            // y: videoRef.current.clientHeight - 95
          }}
        />
      );
    }

    return <></>;
  };

  const renderPlayButton = (): JSX.Element => (
    <div className={[styles.ExportSubtitlesModal__playButton].join("")}>
      <Icon name={isPlaying ? "pause" : "play"} color="#ffffff" size={16} />
    </div>
  );

  const renderOverlay = (): JSX.Element => (
    <button
      className={[
        styles.ExportSubtitlesModal__overlay,
        !isPlaying ? styles.ExportSubtitlesModal__overlay_paused : null,
      ].join(" ")}
      onClick={handlePlayPauseClick}
      disabled={isWaiting}
    >
      {renderPlayButton()}
    </button>
  );

  const renderPlayer = (): JSX.Element | null =>
    videoDetails ? (
      <div className={styles.ExportSubtitlesModal__playerWrap}>
        <p className={styles.ExportSubtitlesModal__playerTitle}>Preview</p>
        <div className={styles.ExportSubtitlesModal__playerContainer}>
          <video
            ref={videoRef}
            src={videoUrl}
            controls={false}
            className={styles.ExportSubtitlesModal__player}
          />
          {renderOverlay()}
          {renderSubtitles()}
        </div>
      </div>
    ) : null;

  const renderSubmitButtonInnerContent = (): JSX.Element => {
    let innerContent = <>Download Video</>;

    if (hasJobsInProgress) {
      innerContent = (
        <div className={styles.ExportSubtitlesModal__submitButtonInnerContent}>
          <p className={styles.ExportSubtitlesModal__submitButtonTitle}>
            Subtitling in progress...
          </p>
          <Loader type="Oval" color="#fd0b50" height={20} width={20} />
        </div>
      );
    }

    return innerContent;
  };

  const renderEstimatedTime = (): JSX.Element | null | undefined => {
    if (videoMediaInfo && hasJobsInProgress) {
      const minTime =
        videoMediaInfo.container.duration > 180
          ? secondsToHms(videoMediaInfo.container.duration - 180)
          : secondsToHms(180);
      const maxTime = secondsToHms(videoMediaInfo.container.duration + 180);

      return (
        <div className={styles.ExportSubtitlesModal__estimatedTime}>
          Estimated time: {`${minTime} - ${maxTime}`}
        </div>
      );
    }
  };

  return (
    <div className={styles.ExportSubtitlesModal} id="exportSubtitlesModal">
      <p className={styles.ExportSubtitlesModal__title}>Subtitles</p>
      {renderPlayer()}
      {!hasJobsInProgress &&
      !isSubtitledVideoDataLoading &&
      !isExportSubtitledVideoLoading ? (
        <div className={styles.ExportSubtitlesModal__inputs}>
          {renderColorInput()}
          {renderFontSizeInput()}
        </div>
      ) : null}
      <div className={styles.ExportSubtitlesModal__buttonsWrap}>
        <NewButton
          color="#eb2f7e"
          textColor="#ffffff"
          bordered
          onClick={handleDownloadClick}
          className={styles.ExportSubtitlesModal__action}
          disabled={
            burningSubtitlesInProgressJobs.length > 0 ||
            isExportSubtitledVideoLoading ||
            isSubtitledVideoDataLoading
          }
          loading={isExportSubtitledVideoLoading}
          id="burningSubtitlesButton"
        >
          {renderSubmitButtonInnerContent()}
        </NewButton>
      </div>
      {renderEstimatedTime()}
    </div>
  );
};

export default ExportSubtitlesModal;
