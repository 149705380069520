export enum FolderStatuses {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface Folder {
    id: string;
    status: FolderStatuses;
    name: string;
    userId: string;
    isDefault: boolean;
    description: string;
    folderId?: string;
    createdDate: Date;
    updatedDate: Date;
    deletedDate: Date;
    workspaceId: string;
    isTemp?: boolean;
}
