import { useEffect, useMemo, useState, CSSProperties } from "react";
import Select from "react-select";
import { Range } from "react-range";
import sortBy from "lodash.sortby";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, Switcher } from "components/UI";

import { getModalOptionalData } from "state/modules/modal";
import { getCelebsTotal, getSegmetsTotal } from "state/modules/metadata";
import { getVideoInfo } from "state/modules/media";
import {
  generateClipPreviews,
  getGenerationLoading,
  ClipOrder,
} from "state/modules/generation";
import {
  getTemplates,
  getTemplatesList,
  TemplateBody,
} from "state/modules/templates";
import { Scene } from "state/modules/videoExplorer";

import { IThumbProps, ITrackProps } from "react-range/lib/types";

import { ClipTemplate, TransformedGenTemplate } from "interfaces/generation";

import { defaultTemplates } from "constants/generation";

import { getFilteredDataForGeneration } from "utils/generatorHelpres";

import moment from "moment";
import styles from "./styles.module.scss";

const colourStyles = {
  control: (selectStyles: CSSProperties) => ({
    ...selectStyles,
    border: "none",
    borderBottom: "1px solid #707070",
    borderRadius: "none",
    background: "transparent",
    padding: 0,
    outline: "none",
  }),
};

interface Props {
  closeButton: JSX.Element;
  closeModal: () => void;
}

const GenerationModal = (props: Props): JSX.Element => {
  const [minMaxSceneLength, setMinMaxSceneLength] = useState([1, 10]);
  const [minMaxClipLength, setMinMaxTotalLength] = useState([1, 60]);
  const [diffFactor, setDiffFactor] = useState([0.5]);

  const [numberOfClips, setNumberOfClips] = useState([1]);
  const [isMuted, setIsMute] = useState(false);

  const [selectedTemplate, setSelectedTemplate] =
    useState<TransformedGenTemplate | null>(null);
  const [selectedTimestamps, setSelectedTimestamps] = useState<Array<Scene>>(
    []
  );
  const [minConfidence, setMinConfidence] = useState<Array<number>>([50]);
  const [minScaleFactor, setMinScaleFactor] = useState<Array<number>>([0]);
  const [options, setOptions] = useState<Array<TransformedGenTemplate>>([]);
  const [templatesOptions, setTemplatesOptions] = useState<
    Array<TransformedGenTemplate>
  >([]);
  const [order, setOrder] = useState(ClipOrder.LOGICAL);

  const generatorData = useSelector(getModalOptionalData);
  const shotsTotal = useSelector(getSegmetsTotal);
  const celebsTotal = useSelector(getCelebsTotal);
  const videoDetails = useSelector(getVideoInfo);
  const isGenerationLoading = useSelector(getGenerationLoading);
  const templates = useSelector(getTemplatesList) as Array<TemplateBody>;

  const mediaInfo =
    videoDetails?.mediaInfo?.proxy || videoDetails?.mediaInfo?.original;

  const videoDurationInMs = (mediaInfo?.container?.duration || 0) * 1000;

  const isCustomGeneration =
    selectedTemplate?.value === ClipTemplate.CUSTOM ||
    selectedTemplate?.value === ClipTemplate.CUSTOM_TEN_SEC ||
    selectedTemplate?.value === ClipTemplate.CUSTOM_TWENTY_SEC;

  const [timeInterval, setTimeInterval] = useState([0, videoDurationInMs]);

  const { closeButton } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplates());

    setSelectedTemplate(generatorData.isCustom ? defaultTemplates[0] : null);
    setSelectedTimestamps(
      generatorData.selectedTimestamps ? generatorData.selectedTimestamps : []
    );

    setMinConfidence(
      generatorData.minConfidence ? generatorData.minConfidence : [50]
    );
    setMinScaleFactor(
      generatorData.minScaleFactor ? generatorData.minScaleFactor : [0]
    );
  }, [dispatch, generatorData]);

  useEffect(() => {
    if (generatorData) {
      const transformedDefaultTemplates = defaultTemplates.map(
        (item: TransformedGenTemplate) => {
          const isCustom =
            item.value === ClipTemplate.CUSTOM_TWENTY_SEC ||
            item.value === ClipTemplate.CUSTOM_TEN_SEC ||
            item.value === ClipTemplate.CUSTOM;

          if (isCustom) {
            return {
              ...item,
              isDisabled: !generatorData.isCustom,
            };
          }
          if (item.value === ClipTemplate.HIGHLIGHT_EXTRACTION) {
            return {
              ...item,
              isDisabled: false,
            };
          }
          return {
            ...item,
            isDisabled: shotsTotal === 0 || celebsTotal === 0,
          };
        }
      );

      setOptions(transformedDefaultTemplates);
    }
  }, [generatorData, shotsTotal, celebsTotal]);

  useEffect(() => {
    if (templates.length) {
      const transformedTemplates = templates.map((item: TemplateBody) => {
        const transformedTemplate = {
          value: item.name,
          isDisabled: false,
          label: item.name,
        };

        return transformedTemplate;
      }) as Array<TransformedGenTemplate>;

      setTemplatesOptions(transformedTemplates);
    }
  }, [templates]);

  const handleSelectTemplate = (newSelectedTemplate: any) => {
    if (newSelectedTemplate) {
      setSelectedTemplate(newSelectedTemplate);
    }
  };

  const handleGenerate = () => {
    if (videoDetails && selectedTemplate) {
      dispatch(
        generateClipPreviews({
          minSceneLength: minMaxSceneLength[0] * 1000,
          maxSceneLength: minMaxSceneLength[1] * 1000,
          minClipLength: minMaxClipLength[0] * 1000,
          maxClipLength: minMaxClipLength[1] * 1000,
          selectedTemplate,
          selectedTimestamps,
          order,
          minConfidence: minConfidence[0],
          minScaleFactor: minScaleFactor[0],
          videoId: videoDetails.id,
          numberOfClips: numberOfClips[0],
          isMuted,
          diffFactor: diffFactor[0],
          startTimeInterval: timeInterval[0],
          endTimeInterval: timeInterval[1],
        })
      );
    }
  };

  const shotsForCustomGeneration = useMemo(() => {
    const isCustomWithLimit =
      selectedTemplate?.value === ClipTemplate.CUSTOM_TEN_SEC ||
      selectedTemplate?.value === ClipTemplate.CUSTOM_TWENTY_SEC;

    const timestamps = getFilteredDataForGeneration(selectedTimestamps, {
      maxSceneLength: minMaxSceneLength[1] * 1000,
      minSceneLength: minMaxSceneLength[0] * 1000,
      minConfidence: minConfidence[0],
      minScaleFactor: minScaleFactor[0] ? minScaleFactor[0] : 0,
      isCustomWithLimit,
      isRandomOrder: order === ClipOrder.RANDOM,
      maxClipLength: minMaxClipLength[1] * 1000,
    });

    if (order === ClipOrder.LOGICAL) {
      return sortBy(timestamps, "from");
    }
    return timestamps;
  }, [
    selectedTimestamps,
    selectedTemplate,
    minMaxSceneLength,
    minMaxClipLength,
    minConfidence,
    minScaleFactor,
    order,
  ]) as Array<Scene>;

  const shotsForCustomGenerationDuration = useMemo(
    () =>
      shotsForCustomGeneration.reduce(
        (accumulator: number, currentShot: Scene): number =>
          accumulator + currentShot.duration,
        0
      ),
    [shotsForCustomGeneration]
  ) as number;

  const handleChangeRangeValue = (values: Array<number>) =>
    setMinConfidence(values);

  const handleChangeBoundigBoxRangeValue = (values: Array<number>) =>
    setMinScaleFactor(values);

  const handleChangeMinMaxSceneLength = (value: Array<number>) => {
    setMinMaxSceneLength(value);
  };

  const handleChangeMinMaxTotalLength = (value: Array<number>) => {
    setMinMaxTotalLength(value);
  };

  const handleChangeTimeInterval = (value: Array<number>) => {
    setTimeInterval(value);
  };

  const handleChangeDiffFactor = (value: Array<number>) => {
    setDiffFactor(value);
  };

  const handleChangeNumberOfClips = (value: Array<number>) => {
    setNumberOfClips(value);
  };

  const handleChangeOrder = () => {
    if (order === ClipOrder.LOGICAL) {
      setOrder(ClipOrder.RANDOM);
    } else {
      setOrder(ClipOrder.LOGICAL);
    }
  };

  const handleChangeIsMute = () => {
    setIsMute(!isMuted);
  };

  const renderTrack = ({
    props,
    children,
  }: {
    props: ITrackProps;
    children: React.ReactNode;
    isDragged: boolean;
    disabled: boolean;
  }) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: "3px",
        width: "100%",
        backgroundColor: "#010a2b80",
        padding: "0 10px",
      }}
    >
      {children}
    </div>
  );

  const renderThumb = ({
    props,
  }: {
    props: IThumbProps;
    value: number;
    index: number;
    isDragged: boolean;
  }) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: "24px",
        width: "24px",
        backgroundColor: "#209ed9",
        borderRadius: "100%",
        outline: "none",
      }}
    />
  );

  const renderMinMaxSceneLengthRange = () => (
    <div className={styles.GenerationModal__rangeWrap}>
      <span className={styles.GenerationModal__rangeLabel}>
        Shot length: {minMaxSceneLength[0]} -{minMaxSceneLength[1]} seconds
      </span>
      <Range
        step={1}
        min={1}
        max={10}
        values={minMaxSceneLength}
        onChange={handleChangeMinMaxSceneLength}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
      />
    </div>
  );

  const renderMinMaxTotalLengthRange = () => (
    <div className={styles.GenerationModal__rangeWrap}>
      <span className={styles.GenerationModal__rangeLabel}>
        Clip length: {minMaxClipLength[0]} -{minMaxClipLength[1]} seconds
      </span>
      <Range
        step={1}
        min={1}
        max={60}
        values={minMaxClipLength}
        onChange={handleChangeMinMaxTotalLength}
        renderTrack={renderTrack}
        renderThumb={renderThumb}
      />
    </div>
  );

  const renderTimeIntervalRange = () => {
    const intervalString = `Time interval: ${moment
      .duration(timeInterval[0], "milliseconds")
      .format("hh:mm:ss", {
        trim: false,
      })} - ${moment
      .duration(timeInterval[1], "milliseconds")
      .format("hh:mm:ss", {
        trim: false,
      })}`;

    return (
      <div className={styles.GenerationModal__rangeWrap}>
        <span className={styles.GenerationModal__rangeLabel}>
          {intervalString}
        </span>
        <Range
          min={0}
          max={videoDurationInMs}
          step={1000}
          values={timeInterval}
          onChange={handleChangeTimeInterval}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    );
  };

  const renderDifferenceRange = () =>
    selectedTemplate?.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
      <div className={styles.GenerationModal__rangeWrap}>
        <span className={styles.GenerationModal__rangeLabel}>
          Shots difference: {diffFactor[0] * 100}%
        </span>
        <Range
          min={0}
          step={0.1}
          max={1}
          values={diffFactor}
          onChange={handleChangeDiffFactor}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    ) : null;

  const renderConfidenceRange = () =>
    selectedTemplate &&
    selectedTemplate.value !== ClipTemplate.DR &&
    selectedTemplate.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
      <div className={styles.GenerationModal__rangeWrap}>
        <span className={styles.GenerationModal__rangeLabel}>
          Minimum Confidence: {minConfidence[0]}%
        </span>
        <Range
          step={1}
          min={0}
          max={100}
          values={minConfidence}
          onChange={handleChangeRangeValue}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    ) : null;

  const renderBoundingBoxRange = () =>
    selectedTemplate &&
    selectedTemplate.value !== ClipTemplate.DR &&
    selectedTemplate.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
      <div className={styles.GenerationModal__rangeWrap}>
        <span className={styles.GenerationModal__rangeLabel}>
          Wide shot: {minScaleFactor[0]}%
        </span>
        <Range
          step={1}
          min={0}
          max={100}
          values={minScaleFactor}
          onChange={handleChangeBoundigBoxRangeValue}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    ) : null;

  const renderNumberOfClipsRange = () =>
    selectedTemplate?.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
      <div className={styles.GenerationModal__rangeWrap}>
        <span className={styles.GenerationModal__rangeLabel}>
          Number of clips: {numberOfClips[0]}
        </span>
        <Range
          step={1}
          min={1}
          max={10}
          values={numberOfClips}
          onChange={handleChangeNumberOfClips}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </div>
    ) : null;

  const renderRanges = () =>
    selectedTemplate ? (
      <div className={styles.GenerationModal__inputsWrap}>
        {selectedTemplate.value !== ClipTemplate.CUSTOM_TEN_SEC &&
        selectedTemplate.value !== ClipTemplate.CUSTOM_TWENTY_SEC &&
        selectedTemplate.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
          <>
            {renderMinMaxSceneLengthRange()}
            {renderMinMaxTotalLengthRange()}
          </>
        ) : null}
        {!isCustomGeneration &&
        selectedTemplate.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
          <>
            {renderTimeIntervalRange()}
            {renderDifferenceRange()}
            {renderNumberOfClipsRange()}
          </>
        ) : null}
        {renderConfidenceRange()}
        {renderBoundingBoxRange()}
      </div>
    ) : null;

  const renderRandomOrderCheckbox = () =>
    selectedTemplate &&
    selectedTemplate.value !== ClipTemplate.DR &&
    selectedTemplate.value !== ClipTemplate.HIGHLIGHT_EXTRACTION ? (
      <div className={styles.GenerationModal__checkboxWrap}>
        <Switcher
          checked={order === ClipOrder.LOGICAL}
          labelLeft="Random"
          labelRight="Logical"
          onChange={handleChangeOrder}
        />
      </div>
    ) : null;

  const renderMuteCheckbox = () =>
    selectedTemplate && (
      <div className={styles.GenerationModal__checkboxWrap}>
        <Checkbox
          title="Sound"
          titleColor="#010a2b80"
          titleFontSize={17}
          onChange={handleChangeIsMute}
          checked={!isMuted}
        />
      </div>
    );

  const renderGenerationButton = () => {
    let isGenerationDisabled = true;

    if (selectedTemplate) {
      if (selectedTemplate.value === ClipTemplate.DR) {
        isGenerationDisabled = !celebsTotal;
      } else if (isCustomGeneration) {
        isGenerationDisabled = !selectedTimestamps.length;
      } else {
        isGenerationDisabled = false;
      }
    } else {
      isGenerationDisabled = true;
    }

    if (isGenerationLoading) {
      isGenerationDisabled = true;
    }

    let disabled = isGenerationDisabled || isGenerationLoading;
    const minClipLength = minMaxClipLength[0] * 1000;

    if (isCustomGeneration && !disabled) {
      disabled =
        !shotsForCustomGeneration.length ||
        minClipLength > shotsForCustomGenerationDuration;
    }

    return (
      <div className={styles.GenerationModal__buttonWrap}>
        <button
          className={[
            styles.GenerationModal__saveButton,
            disabled && styles.GenerationModal__saveButton_disabled,
          ].join(" ")}
          onClick={handleGenerate}
          disabled={disabled}
        >
          {isGenerationLoading
            ? "Starting clip previews generation..."
            : "SAVE & GENERATE"}
        </button>
      </div>
    );
  };

  const renderTemplateSelect = () => (
    <div className={styles.GenerationModal__selectWrap}>
      <span className={styles.GenerationModal__selectLabel}>Template</span>
      <Select
        styles={colourStyles as any}
        options={[...options, ...templatesOptions]}
        onChange={handleSelectTemplate}
        value={selectedTemplate}
      />
    </div>
  );

  const renderControls = () => (
    <div className={styles.GenerationModal__controls}>
      {renderTemplateSelect()}
      {renderRanges()}
      <div className={styles.GenerationModal__controlsBottomLine}>
        {renderMuteCheckbox()}
        {renderRandomOrderCheckbox()}
      </div>
    </div>
  );

  const renderContent = () => (
    <div className={styles.GenerationModal__content}>{renderControls()}</div>
  );

  return (
    <div className={styles.GenerationModal}>
      <div className={styles.GenerationModal__topLine}>{closeButton}</div>
      <div className={styles.GenerationModal__wrap}>
        {renderContent()}
        {renderGenerationButton()}
      </div>
    </div>
  );
};

export default GenerationModal;
