import {
    cancelUserSubscription,
    Customer,
    getConfirmSetupLoading,
    getCurrentUserPlan,
    getCustomerInfo,
    getPaymentPlans,
    getStripePromise,
    getSubscriptionCancelationLoading,
    getUpcomingUserPlan,
    PaymentPeriodType,
    PlanNameId,
    PlanType,
    Product,
    setupIntent,
} from 'state/modules/payment';
import PlanCard from 'components/PlanCard';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AvailableStorage from 'components/AvailableStorage';
import AvailableMinutes from 'components/AvailableMinutes';
import AvailableDubbingCredits from 'components/AvailableDubbingCredits';
import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import { getPaymentMethodName } from 'utils/payment';
import ApplePayLogo from 'assets/images/apple-pay.svg';
import GooglePayLogo from 'assets/images/google-pay.svg';
import { PaymentForm } from './PaymentForm';
import { NEUTRAL_500, NEUTRAL_BLACK, WHITE_PRIMARY } from '../../../styles/colors';
import { hideModal, ModalEvent, ModalType, showModal } from '../../../state/modules/modal';

const PlansListContainer = styled.div`
    margin-top: 1.25rem;
    background-color: #F7F7F8;
`;

const Grid = styled.div`
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    grid-auto-flow: column;
    row-gap: 0;
    display: grid;
    width: 100%;
    align-content: center;
    gap: 1.188rem;
`;

const SeePlanLimitsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    //margin-top: 1.625rem;
    margin-top: 0.938rem;
`;

const SeePlanLimits = styled.p`
    text-align: left;
    display: inline-flex;
    font-size: 0.75rem;
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    color: ${NEUTRAL_500};
    cursor: pointer;
`;

const LinkText = styled.span`
    font-family: 'Inter Medium', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.313rem;
    color: ${NEUTRAL_500};

    &:hover {
        color: ${NEUTRAL_BLACK};
    }
`;

const Footer = styled.div`
    display: flex;
    margin-top: 0.625rem;
`;

const FooterContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FooterLeft = styled.div`
    display: flex;
`;

const FooterRight = styled.div`
    display: flex;
    flex-direction: row;
`;

const CurrentPlanInfoContainer = styled.div`
    display: flex;
    background-color: ${WHITE_PRIMARY};
    border-radius: 0.625rem;
    padding: 0.875rem;

    & > :first-child {
        margin-right: 1.25rem;
    }
`;

const CurrentPlanInfo = styled.div`
    font-size: 0.813rem;
`;

const CurrentPlanInfoHeader = styled.p`
    margin-bottom: 0.313rem;
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
`;

const CurrentPlanInfoData = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
`;

const FooterContent = styled.div``;

// to be done later
const AppSumoLink = styled.a`
    width: 260px;
    background: #ffbc00;
    border: 1px solid #ffbc00;
    transition: border 0.2s ease-in-out 0s;
    color: #272727;
    border-radius: 5px;
    height: 48px;
    padding: 0 16px;
    display: flex;
    min-width: 87px;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    margin: auto;
`;

// all below: to be moved
const PaymentMethodContainer = styled.div`
    background-color: ${WHITE_PRIMARY};
    border-radius: 10px;
    padding: 14px;
    height: 100px;
    display: flex;
    flex-direction: column;

    @media (max-height: 860px) {
        padding: 10px;
        height: 95px;
    }
`;

const PaymentMethodButton = styled.button`
    font-family: 'Proxima Nova medium', sans-serif;
    font-size: 12px;
    color: rgb(0, 152, 253);
    cursor: pointer;
    border: none;
    background: transparent;
    display: block;
    margin-top: auto;
    text-align: left;

    &:disabled {
        color: rgb(144, 148, 165);
        cursor: not-allowed;
    }
`;

const CardPaymentMethodBrand = styled.span`
    text-transform: uppercase;
`;

const PaymentMethodName = styled.span`
    display: flex;
    align-items: center;
    column-gap: 4px;
`;

const PaymentMethodDescription = styled.p`
    font-size: 12px;
    font-family: 'Proxima Nova medium', sans-serif;
    color: rgb(144, 148, 165);
    margin-bottom: 10px;
`;

const PaymentMethodTitle = styled.p`
    font-size: 14px;
    font-family: 'Proxima Nova medium', sans-serif;
    color: #212529;
    margin-bottom: 5px;
`;

const LimitsTitle = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-weight: 600;
`;

const LimitsContainer = styled.div`
`;

const Limits = styled.div`
    display: flex;

    > div {
        margin-right: 15px;
    }
`;

interface Props {
    onSelectPlan: (plan: Product) => void;
    onShowPaymentManagement: () => void;
    onHidePaymentManagement: () => void;
    selectedPeriodOption: PaymentPeriodType;
    currentPlanName: string;
    renewInfo: string | undefined;
}

export const PlansList = (props: Props): JSX.Element => {
    const {
        onSelectPlan,
        selectedPeriodOption,
        currentPlanName,
        renewInfo,
        onShowPaymentManagement,
        onHidePaymentManagement,
    } = props;

    const dispatch = useDispatch();

    const stripePromise = useSelector(getStripePromise);
    const currentPlan = useSelector(getCurrentUserPlan);
    const isSubscriptionCancelationLoading = useSelector(
        getSubscriptionCancelationLoading
    );
    const upcomingPlan = useSelector(getUpcomingUserPlan);
    const customer = useSelector(getCustomerInfo) as Customer;
    const plans = useSelector(getPaymentPlans) as Array<Product>;

    const isConfirmSetupLoading = useSelector(getConfirmSetupLoading);

    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const handleDowngradeToFree = () => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm('Are you sure you want to downgrade to Free Plan?')
        ) {
            dispatch(cancelUserSubscription());
        }
    };

    const handleEditPaymentMethod = () => {
        onShowPaymentManagement();
        setClientSecretLoading(true);
        dispatch(
            setupIntent((error, data) => {
                setClientSecretLoading(false);
                if (!error) {
                    setClientSecret(data.clientSecret);
                }
            })
        );
    };

    const renderAvailableLimits = () => {
        return (
            <LimitsContainer>
                <LimitsTitle> </LimitsTitle>
                <Limits>
                    {currentPlan?.planNameId !== PlanNameId.ENTERPRISE ? (
                        <>
                            <AvailableMinutes
                                isFixed
                                currentSubscription={currentPlan}
                            />
                            <AvailableDubbingCredits
                                currentSubscription={currentPlan}
                            />
                            <AvailableStorage
                                currentSubscription={currentPlan}
                            />
                        </>
                    ) : null}
                </Limits>
            </LimitsContainer>
        );
    };

    const renderCurrentPaymentMethod = () => {
        if (!customer?.paymentMethod) return null;
        return (
            <div>
                <PaymentMethodTitle>Payment Method</PaymentMethodTitle>
                <PaymentMethodDescription>
                    {customer.paymentMethod.type === 'card' ? (
                        <PaymentMethodName>
                            {customer.paymentMethod.card?.wallet?.apple_pay && (
                                <img src={ApplePayLogo} alt="Apple pay" />
                            )}
                            {customer.paymentMethod.card?.wallet
                                ?.google_pay && (
                                <img src={GooglePayLogo} alt="Google Pay" />
                            )}
                            <span>
                                <CardPaymentMethodBrand>
                                    {customer.paymentMethod.card?.brand}
                                </CardPaymentMethodBrand>{' '}
                                ending in {customer.paymentMethod.card?.last4}
                            </span>
                        </PaymentMethodName>
                    ) : (
                        <>{getPaymentMethodName(customer.paymentMethod.type)}</>
                    )}
                </PaymentMethodDescription>
            </div>
        );
    };

    const renderFooter = () => {
        const renewInfoSplitted = renewInfo?.split(':');

        const renewText = renewInfoSplitted?.[0];
        const renewDate = renewInfoSplitted?.[1];

        return (
            <Footer>
                <FooterContentContainer>
                    <FooterLeft>
                        <FooterContent>
                            {renderAvailableLimits()}
                            {
                                // renderCancelPlan()
                            }
                        </FooterContent>
                        {customer?.paymentMethod && (
                            <PaymentMethodContainer>
                                {renderCurrentPaymentMethod()}
                                <PaymentMethodButton
                                    disabled={clientSecretLoading || isConfirmSetupLoading}
                                    onClick={handleEditPaymentMethod}
                                >
                                    {clientSecretLoading || isConfirmSetupLoading ? (
                                        <Loader
                                            type="Oval"
                                            color="rgb(144, 148, 165)"
                                            height={20}
                                            width={20}
                                        />
                                    ) : (
                                        'Edit'
                                    )}
                                </PaymentMethodButton>
                            </PaymentMethodContainer>
                        )}
                    </FooterLeft>
                    <FooterRight>
                        <CurrentPlanInfoContainer>
                            <CurrentPlanInfo>
                                <CurrentPlanInfoHeader>Current Plan:</CurrentPlanInfoHeader>
                                <CurrentPlanInfoData>{currentPlanName}</CurrentPlanInfoData>
                            </CurrentPlanInfo>
                            {
                                renewInfo && (
                                    <CurrentPlanInfo>
                                        <CurrentPlanInfoHeader>{renewText}:</CurrentPlanInfoHeader>
                                        <CurrentPlanInfoData>{renewDate}</CurrentPlanInfoData>
                                    </CurrentPlanInfo>
                                )
                            }
                        </CurrentPlanInfoContainer>
                    </FooterRight>
                </FooterContentContainer>
            </Footer>
        );
    }

    const handleClosePaymentForm = () => {
        setClientSecret(null);
        onHidePaymentManagement();
    };

    const handleSeePlanLimits = () => {
        dispatch(showModal(
            ModalType.WORKSPACE_SETTINGS,
            ModalEvent.WORKSPACE_SETTINGS,
            '',
            {
                defaultRoute: 'plan_limits',
            }
        ));
    };

    const renderPlan = (planNameId: PlanNameId) => {
        if (planNameId === PlanNameId.INITIAL) {
            return (
                <PlanCard
                    key={planNameId}
                    data={{
                        isTrial: true,
                        planNameId: PlanNameId.INITIAL,
                    }}
                    onSelect={handleDowngradeToFree}
                    isDisabled={
                        isSubscriptionCancelationLoading ||
                        currentPlan?.planNameId === PlanNameId.INITIAL ||
                        upcomingPlan?.name === 'Initial product' ||
                        upcomingPlan?.planNameId === PlanNameId.INITIAL ||
                        !upcomingPlan ||
                        isConfirmSetupLoading
                    }
                    isLoading={isSubscriptionCancelationLoading}
                    currentUserPlan={currentPlan}
                    selectedPeriodOption={selectedPeriodOption}
                />
            );
        }

        const planDetails = plans.find(
            (plan) => plan?.planNameId === planNameId
        );

        if (planDetails) {
            return (
                <PlanCard
                    key={planNameId}
                    data={planDetails}
                    onSelect={onSelectPlan}
                    isDisabled={
                        isSubscriptionCancelationLoading ||
                        isConfirmSetupLoading
                    }
                    currentUserPlan={currentPlan}
                    selectedPeriodOption={selectedPeriodOption}
                />
            );
        }
    };

    if (clientSecret) {
        return (
            <Elements
                stripe={stripePromise}
                options={{
                    clientSecret,
                }}
            >
                <PaymentForm
                    handleClose={handleClosePaymentForm}
                    isEditMode
                />
            </Elements>
        );
    }

    const renderPlans = () => {
        return (
            <>
                {renderPlan(PlanNameId.INITIAL)}
                {renderPlan(PlanNameId.BASIC)}
                {renderPlan(PlanNameId.PROFESSIONAL)}
                {renderPlan(PlanNameId.BUSINESS)}
            </>
        );
    };

    const renderAppSumoPlanChange = () => {
        return (
            <AppSumoLink
                href="https://appsumo.com/account/products"
                target="_blank"
                rel="noreferrer"
            >
                Change Plan
            </AppSumoLink>
        );
    };

    const renderSeeAllPlanFeaturesLink = () => (
        <SeePlanLimitsContainer>
            <SeePlanLimits
                onClick={handleSeePlanLimits}
            >
                <LinkText>See plan limits</LinkText>
            </SeePlanLimits>
        </SeePlanLimitsContainer>
    );

    return (
        <PlansListContainer>
            <Grid>
                {
                    currentPlan?.type === PlanType.APP_SUMO_PLAN ? (
                        renderAppSumoPlanChange()
                    ) : (
                        renderPlans()
                    )
                }
            </Grid>
            {renderSeeAllPlanFeaturesLink()}
            {
                // renderFooter()
            }
        </PlansListContainer>
    );
};
