import React, { memo } from 'react';

import { ButtonLoader, Icon } from 'components/UI';

import styles from './styles.module.scss';

interface IButtonProps {
  children: JSX.Element | string;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  red?: boolean;
  transparent?: boolean;
  blue?: boolean;
  black?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: 'button' | 'submit' | 'reset' | undefined;
  leftIconName?: string;
  leftIconColor?: string;
  loading?: boolean;
  className?: string;
  compressible?: boolean;
  leftIconSize?: number;
  id?: string;
}

/**
 * Button component
 * ================
 * Renders custom button
 */
const Button: React.FC<IButtonProps> = memo(
  ({
    children,
    large,
    medium,
    small,
    extraSmall,
    red,
    transparent,
    blue,
    black,
    onClick,
    type,
    disabled,
    rounded,
    leftIconName,
    loading,
    className,
    compressible,
    leftIconColor,
    leftIconSize,
    id,
  }) => {
    const buttonStyles = [
      styles.Button,
      red ? styles.Button_red : '',
      blue ? styles.Button_blue : '',
      transparent ? styles.Button_transparent : '',
      black ? styles.Button_black : '',
      large ? styles.Button_large : '',
      medium ? styles.Button_medium : '',
      small ? styles.Button_small : '',
      extraSmall ? styles.Button_extraSmall : '',
      rounded ? styles.Button_rounded : '',
      compressible ? styles.Button_compressible : '',
      className,
      disabled ? styles.Button_disabled : '',
    ].join(' ');
    return (
      <button
        onClick={onClick}
        className={buttonStyles}
        type={type}
        disabled={disabled}
        id={id || `${Math.random}`}
      >
        {leftIconName ? (
          <div className={styles.Button__leftIconWrap}>
            <Icon
              size={leftIconSize}
              color={leftIconColor}
              name={leftIconName}
            />
          </div>
        ) : null}
        {loading ? <ButtonLoader /> : children}
      </button>
    );
  },
);

export default Button;
