import { Icon } from 'components/UI';
import React, { useMemo } from 'react';

import { PaymentPeriodType, PlanNameId, Price } from 'state/modules/payment';

import basicPlanImg from 'assets/images/plans/basic.webp';
import proPlanImg from 'assets/images/plans/pro.webp';
import enterprisePlanImg from 'assets/images/plans/enterprise.png';
import freePlanImg from 'assets/images/plans/free.webp';

import styles from './styles.module.scss';

const windowFork = window as any;

interface Props {
    planNameId: PlanNameId;
    onUnselect: () => void;
    selectedBillingPeriod: Price | null;
}

const SelectedPlanCard = ({ planNameId, onUnselect, selectedBillingPeriod }: Props): JSX.Element => {
    const isMonthlySelected = useMemo(
        () => selectedBillingPeriod?.interval === PaymentPeriodType.MONTHLY,
        [selectedBillingPeriod]
    );

    const getFeaturesList = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return [
                    'Watermarked',
                    '30 min of subtitles',
                    '10 min of dubbing',
                    'Upload limit - 2GB',
                    'Upload/Export length - 10 min',
                    '1,000 characters text-to-speech',
                ];
            case PlanNameId.BASIC:
                return [
                    `${isMonthlySelected ? '150' : '1,800'} min of subtitles / translation`,
                    `${isMonthlySelected ? '60' : '720'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 60 min',
                    'Storage 100 GB',
                    'SRT/VTT/TXT subtitles download',
                    '6,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            case PlanNameId.PROFESSIONAL:
                return [
                    `${isMonthlySelected ? '300' : '3,600'} min of subtitles / translation`,
                    `${isMonthlySelected ? '120' : '1,440'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 150 min',
                    'Storage 500 GB',
                    'SRT/VTT/TXT subtitles download',
                    '15,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            case PlanNameId.BUSINESS:
                return [
                    `${isMonthlySelected ? '900' : '10,800'} min of subtitles / translation`,
                    `${isMonthlySelected ? '360' : '4,320'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 450 minutes',
                    'Storage 2 TB',
                    'SRT/VTT/TXT subtitles download',
                    '40,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            default:
                return [];
        }
    };

    const getDescription = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'For quick projects and short content without leaving credit card details.';
            case PlanNameId.BASIC:
                return 'For users who need simple online video editing';
            case PlanNameId.PROFESSIONAL:
                return 'For professionals that need translations, subtitle exports, longer videos and brand tools';
            case PlanNameId.BUSINESS:
                return 'For companies managing large volumes of video and audio content.';
            default:
                return '';
        }
    };

    const getTitle = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'Free';
            case PlanNameId.BASIC:
                return 'Basic';
            case PlanNameId.PROFESSIONAL:
                return 'Professional';
            case PlanNameId.BUSINESS:
                return 'Business';
            default:
                return '';
        }
    };

    const handleHelpClick = () => {
        if (window?.$crisp) {
            window.$crisp.push(['do', 'chat:show']);
            window.$crisp.push(['do', 'chat:open']);
        }
    };

    const renderTitle = () => {
        const title = getTitle();

        return <p className={styles.SelectedPlanCard__title}>{title}</p>;
    };

    const renderDescription = () => {
        const description = getDescription();

        return (
            <p className={styles.SelectedPlanCard__description}>
                {description}
            </p>
        );
    };

    const renderFeature = (feature: string) => (
        <div className={styles.SelectedPlanCard__feature} key={feature}>
            <div className={styles.SelectedPlanCard__featureIcon}>
                <Icon name="check" size={9} />
            </div>
            {feature}
        </div>
    );

    const renderFeaturesList = () => {
        const features = getFeaturesList();

        return (
            <div className={styles.SelectedPlanCard__featuresListWrap}>
                <div className={styles.SelectedPlanCard__featuresList}>
                    {features.map((feature) => renderFeature(feature))}
                </div>
            </div>
        );
    };

    const renderImage = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return (
                    <img
                        className={styles.SelectedPlanCard__image}
                        src={freePlanImg}
                        alt="free"
                    />
                );
            case PlanNameId.BASIC:
                return (
                    <img
                        className={styles.SelectedPlanCard__image}
                        src={basicPlanImg}
                        alt="basic"
                    />
                );
            case PlanNameId.PROFESSIONAL:
                return (
                    <img
                        className={styles.SelectedPlanCard__image}
                        src={proPlanImg}
                        alt="pro"
                    />
                );
            case PlanNameId.BUSINESS:
                return (
                    <img
                        className={styles.SelectedPlanCard__image}
                        src={enterprisePlanImg}
                        alt="business"
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className={styles.SelectedPlanCard}>
            <div className={styles.SelectedPlanCard__goBackButtonWrap}>
                <button
                    onClick={onUnselect}
                    className={styles.SelectedPlanCard__goBackButton}
                >
                    <Icon name="arrow" size={12} />
                </button>
            </div>
            <div className={styles.SelectedPlanCard__header}>
                {renderImage()}
                <div>
                    {renderTitle()}
                    {renderDescription()}
                </div>
            </div>
            <div className={styles.SelectedPlanCard__body}>
                {renderFeaturesList()}
            </div>
            <div className={styles.SelectedPlanCard__divider} />
            <div className={styles.SelectedPlanCard__footer}>
                <button
                    className={styles.SelectedPlanCard__helpButton}
                    onClick={handleHelpClick}
                >
                    <Icon name="help" size={15} />
                    Help
                </button>
                <a
                    className={styles.SelectedPlanCard__compareLink}
                    href="https://promomii.com/pricing/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Compare all plans
                    <div className={styles.SelectedPlanCard__compareLinkIcon}>
                        <Icon
                            name="arrowDown"
                            size={9}
                            color="rgb(144, 148, 165)"
                        />
                    </div>
                </a>
            </div>
        </div>
    );
};

export default SelectedPlanCard;
