import { ProjectNavRoute } from 'interfaces/projects';
import {
    AddPreparedMediaSourceAction,
    CopyProjectMediaAction,
    CopyProjectMediaActionPayload,
    FinishProjectVideoAction,
    FinishProjectVideoActionPayload,
    NavigateToRouteAction,
    PasteProjectMediaAction,
    PauseProjectVideoAction,
    ProjectCanvasSelectedShape,
    ProjectsStudioActionTypes,
    RemoveSelectedObjectsAction,
    SelectAllObjectsAction,
    SelectedProjectObject,
    SelectProjectObjectAction,
    SelectProjectTimelineObjectActionPayload, SetCopiedMediaAction,
    SetPojectPlayingAction,
    SetPreparedMediaSourcesAction,
    SetProjectAudioWaveVisibleAction,
    SetProjectNavRouteAction,
    SetProjectSelectedObjectsAction,
    SetSelectedShapeAction,
    SetTimelineHeightAction,
    SetTimelineSliderValueAction,
    SetTimelineTimeAction,
    SkipProjectFrameAction,
    SkipProjectTimeAction,
    SkipTimelineCutAction,
    SliderProjectTimelineAction,
    SplitProjectTimelineAction,
    ToggleProjectPlayerAction,
    UpdateProjectTimeAction,
    UpdateProjectTimeActionPayload,
} from './types';

/// ////////////////////////////

export function splitProjectTimeline(): SplitProjectTimelineAction {
    return {
        type: ProjectsStudioActionTypes.SPLIT_PROJECT_TIMELINE,
    };
}

//
export function setProjectTimelineTime(payload: number): SetTimelineTimeAction {
    return {
        type: ProjectsStudioActionTypes.SET_TIMELINE_TIME,
        payload,
    };
}

export function setProjectTimelineSliderValue(
    payload: number
): SetTimelineSliderValueAction {
    return {
        type: ProjectsStudioActionTypes.SET_TIMELINE_SLIDER_VALUE,
        payload,
    };
}

//

export function setProjectNavRoute(
    payload: ProjectNavRoute
): SetProjectNavRouteAction {
    return {
        type: ProjectsStudioActionTypes.SET_PROJECT_NAV_ROUTE,
        payload,
    };
}

export function setPojectPlaying(payload: boolean): SetPojectPlayingAction {
    return {
        type: ProjectsStudioActionTypes.SET_PLAYING,
        payload,
    };
}

export function setTimelineHeight(payload: number): SetTimelineHeightAction {
    return {
        type: ProjectsStudioActionTypes.SET_TIMELINE_HEIGHT,
        payload,
    };
}

export function setSelectedShape(
    payload: ProjectCanvasSelectedShape | null
): SetSelectedShapeAction {
    return {
        type: ProjectsStudioActionTypes.SET_SELECTED_SHAPE,
        payload,
    };
}

export function finishProjectVideo(
    payload: FinishProjectVideoActionPayload
): FinishProjectVideoAction {
    return {
        type: ProjectsStudioActionTypes.FINISH_PROJECT_VIDEO,
        payload,
    };
}

export function pauseProjectVideo(): PauseProjectVideoAction {
    return {
        type: ProjectsStudioActionTypes.PAUSE_PROJECT_VIDEO,
    };
}

export function updateProjectTime(
    payload: UpdateProjectTimeActionPayload
): UpdateProjectTimeAction {
    return {
        type: ProjectsStudioActionTypes.UPDATE_PROJECT_TIME,
        payload,
    };
}

export function toggleProjectPlayer(): ToggleProjectPlayerAction {
    return {
        type: ProjectsStudioActionTypes.TOGGLE_PROJECT_PLAYER,
    };
}

export function addPreparedMediaSource(
    payload: string
): AddPreparedMediaSourceAction {
    return {
        type: ProjectsStudioActionTypes.ADD_PREPARED_MEDIA_SOURCE,
        payload,
    };
}

export function setPreparedMediaSources(
    payload: string[]
): SetPreparedMediaSourcesAction {
    return {
        type: ProjectsStudioActionTypes.SET_PREPARED_MEDIA_SOURCES,
        payload,
    };
}

export function skipProjectTime(payload: number): SkipProjectTimeAction {
    return {
        type: ProjectsStudioActionTypes.SKIP_TIME,
        payload,
    };
}

export function skipProjectFrame(payload: number): SkipProjectFrameAction {
    return {
        type: ProjectsStudioActionTypes.SKIP_FRAME,
        payload,
    };
}

export function skipTimelineCut(payload: boolean): SkipTimelineCutAction {
    return {
        type: ProjectsStudioActionTypes.SKIP_TIMELINE_CUT,
        payload,
    };
}

export function slideProjectTimeline(
    payload: number
): SliderProjectTimelineAction {
    return {
        type: ProjectsStudioActionTypes.SLIDE_PROJECT_TIMELINE,
        payload,
    };
}

export function setProjectAudioWaveVisible(
    payload: boolean
): SetProjectAudioWaveVisibleAction {
    return {
        type: ProjectsStudioActionTypes.SET_AUDIO_WAVE_VISIBLE,
        payload,
    };
}

//

export function selectProjectObject(
    payload: SelectProjectTimelineObjectActionPayload
): SelectProjectObjectAction {
    return {
        type: ProjectsStudioActionTypes.SELECT_PTOJECT_OBJECT,
        payload,
    };
}

export function setProjectSelectedObjects(
    payload: SelectedProjectObject[]
): SetProjectSelectedObjectsAction {
    return {
        type: ProjectsStudioActionTypes.SET_SELECTED_PTOJECT_OBJECTS,
        payload,
    };
}

export function selectAllProjectObjects(): SelectAllObjectsAction {
    return {
        type: ProjectsStudioActionTypes.SELECT_ALL_OBJECTS,
    };
}

export function removeSelectedObjects(
    payload: string[]
): RemoveSelectedObjectsAction {
    return {
        type: ProjectsStudioActionTypes.REMOVE_SELECTED_OBJECTS,
        payload,
    };
}

export function setNavigateToRoute(
    payload: string,
): NavigateToRouteAction {
    return {
        type: ProjectsStudioActionTypes.NAVIGATE_TO_ROUTE,
        payload,
    };
}

export function copyProjectMedia(
    payload: CopyProjectMediaActionPayload,
): CopyProjectMediaAction {
    return {
        type: ProjectsStudioActionTypes.COPY_PROJECT_MEDIA,
        payload,
    };
}

export function pasteProjectMedia(): PasteProjectMediaAction {
    return {
        type: ProjectsStudioActionTypes.PASTE_PROJECT_MEDIA,
    };
}

export function setCopiedMedia(
    payload: SelectedProjectObject,
): SetCopiedMediaAction {
    return {
        type: ProjectsStudioActionTypes.SET_COPIED_MEDIA,
        payload,
    };
}

