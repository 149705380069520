import { IconProps } from '../index';

const TextOutDragonflyIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.772 21.059c.4-.673.88-1.01 1.439-1.01.56 0 1.03.305 1.41.913.386.601.58 1.327.58 2.179 0 .851-.196 1.581-.586 2.19-.39.607-.863.911-1.418.911-.55 0-1.026-.364-1.425-1.094v.901H16.2V18h.572v3.059zm1.404 4.325c.4 0 .738-.226 1.015-.676.283-.451.424-.977.424-1.578 0-.608-.137-1.138-.41-1.588-.272-.451-.613-.677-1.022-.677-.41 0-.753.212-1.03.634-.273.422-.41.955-.41 1.599 0 .637.14 1.177.417 1.62.277.444.616.666 1.015.666z"
                fill="#7C7C7D" />
            <path
                d="M15.2 26.083h-.638l-.792-2.28h-2.118l-.786 2.28H10.2L12.627 19h.182l2.391 7.083zm-1.704-3.09-.778-2.3-.786 2.3h1.564z"
                fill="#CECECF" />
            <path
                d="M26.33 24.57c-.264.429-.61.756-1.039.98a2.897 2.897 0 0 1-1.379.337 2.532 2.532 0 0 1-1.94-.847c-.514-.565-.772-1.255-.772-2.071 0-.816.26-1.514.782-2.092.52-.584 1.195-.877 2.022-.877.49 0 .94.119 1.351.357.417.238.742.571.975 1l-.598.418c-.447-.646-1.032-.969-1.755-.969-.57 0-1.049.204-1.435.612-.386.401-.579.901-.579 1.5 0 .598.19 1.112.57 1.54.38.429.861.643 1.444.643.698 0 1.287-.326 1.765-.98l.588.45z"
                fill="#525253" />
            <path d="m26.932 15.5-.732 2.732M30.932 22.428l-2.732-.732M29.798 18.464l-2.45 1.414" stroke="#7C7C7D"
                  strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TextOutDragonflyIcon;