import React, { memo } from 'react';

import styles from './styles.module.scss';

interface FormContainerProps {
  children: JSX.Element;
  onSubmit: () => void;
  autoComplete?: boolean;
  className?: string;
}

const FormContainer: React.FC<FormContainerProps> = memo(
  ({ children, onSubmit, autoComplete, className }) => {
    const submit = (e: React.FormEvent): void => {
      e.preventDefault();
      onSubmit();
    };
    return (
      <form
        autoComplete={autoComplete ? 'on' : 'off'}
        className={[styles.FormContainer, className].join(' ')}
        onSubmit={submit}
      >
        {children}
      </form>
    );
  },
);

export default FormContainer;
