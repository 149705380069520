import { IconProps } from './index';

const BellIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.89" clipPath="url(#clip0_1258_4060)">
                <path
                    d="M11.9211 4.11328C15.6799 4.11328 18.727 7.1604 18.727 10.9192V16.452C18.727 17.0279 19.1939 17.4949 19.7699 17.4949C19.8098 17.4949 19.8421 17.5272 19.8421 17.5671V18.1676C19.8421 18.5391 19.5409 18.8403 19.1694 18.8403H4.6727C4.30118 18.8403 4 18.5391 4 18.1676V17.5671C4 17.5272 4.03234 17.4949 4.07224 17.4949C4.64824 17.4949 5.11513 17.0279 5.11513 16.452V10.9192C5.11513 7.16039 8.16225 4.11328 11.9211 4.11328Z"
                    stroke={color}
                    strokeWidth="2"
                />
                <path
                    d="M8.38672 19.2852V19.2852C8.38672 21.4398 10.1334 23.1866 12.2881 23.1866V23.1866C14.4428 23.1866 16.1895 21.4398 16.1895 19.2852V19.2852"
                    stroke={color}
                    strokeWidth="2"
                />
                <path
                    d="M14.1504 4.23047V4.23047C14.1504 2.99873 13.1519 2.00021 11.9201 2.00021V2.00021C10.6884 2.00021 9.68986 2.99873 9.68986 4.23047V4.23047"
                    stroke={color}
                    strokeWidth="2"
                />
            </g>
            <defs>
                <clipPath id="clip0_1258_4060">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BellIcon;
