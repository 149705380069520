import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 2;

const Triangle = ({ color, size }: IconProps): JSX.Element => {
  let height = 17;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35 17"
    >
      <path
        d="M17.5,0,35,17H0Z"
        transform="translate(35 17) rotate(180)"
        fill={color}
      />
    </svg>
  );
};

export default Triangle;
