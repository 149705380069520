/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled from 'styled-components';
import { useMemo, useState } from 'react';

import { Icon } from 'components/UI';
import TempNewFolder from 'components/TempNewFolder';

import FoldersClient from 'services/api/folders';

import { Folder, FolderStatuses } from 'interfaces/folder';

const Title = styled.p`
    margin-left: 10px;
`;

const Placeholder = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.71875rem;
    line-height: 0.75rem;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: rgb(144, 148, 165);
    padding: 0.5rem 0;
`;

const Container = styled.div``;

const Toggle = styled.div<{ isOpen: boolean; isActive: boolean }>`
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

    & svg path {
        fill: ${({ isActive }) => (isActive ? 'rgb(0, 152, 253)' : '')};
    }
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Header = styled.div<{ isActive: boolean }>`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    user-select: none;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background: ${({ isActive }) =>
        isActive ? 'rgb(239, 249, 255)' : 'white'};
    color: ${({ isActive }) =>
        isActive ? 'rgb(0, 152, 253)' : 'rgb(93, 100, 123)'};
    cursor: pointer;

    ${TitleContainer} {
        & svg path {
            stroke: ${({ isActive }) =>
                isActive ? 'rgb(0, 152, 253)' : 'rgb(93, 100, 123)'};
        }
    }

    &:hover {
        background: rgb(239, 249, 255);

        ${TitleContainer} {
            color: rgb(0, 152, 253);

            & svg path {
                stroke: rgb(0, 152, 253);
            }
        }

        ${Toggle} {
            & svg path {
                fill: rgb(0, 152, 253);
            }
        }
    }
`;

const Content = styled.div<{ isOpen: boolean }>`
    padding-left: 1rem;
    margin-left: 1.5rem;
    border-left: 1px solid rgb(223, 224, 229);
    height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    visibility: ${({ isOpen }) => (isOpen ? 'initial' : 'hidden')};
`;

interface Props {
    folder: Folder;
    selectedFolderId: string;
    onSelect: (folder: Folder) => void;
    folders: Folder[];
    foldersTree: {
        [key: string]: Folder[];
    };
    onUpdateFoldersTreeItem: (id: string, data: Folder[]) => void;
    folderIdToExclude: string;
    onConfirmCreateFolder: (folder: Folder) => void;
    hasError?: boolean;
    onClearError?: () => void;
}

const FolderTreeItem = ({
    folder,
    onSelect,
    selectedFolderId,
    folders,
    foldersTree,
    onUpdateFoldersTreeItem,
    folderIdToExclude,
    onConfirmCreateFolder,
    hasError,
    onClearError,
}: Props) => {
    const [isOpen, setOpen] = useState(false);
    const [isFirstOpen, setFirstOpen] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const { status } = folder;

    const loadFolders = async () => {
        try {
            setLoading(true);
            const res = await FoldersClient.getFolders(0, folder?.id);

            const data = res.data.content;

            onUpdateFoldersTreeItem(folder.id, data);

            setLoading(false);
        } catch (error) {
            console.log({ error });
            setLoading(true);
        }
    };

    const toggle = () => {
        if (status !== FolderStatuses.INACTIVE) {
            if (isFirstOpen) {
                loadFolders();

                setFirstOpen(false);
            }

            onSelect(folder);
            setOpen(!isOpen);
        }
    };

    const filteredFolders = useMemo(
        () => folders.filter((item) => item.id !== folderIdToExclude),
        [folders, folderIdToExclude]
    );

    const handleSelect = (obj: Folder) => {
        onSelect(obj);
    };

    const isActive = useMemo(
        () => selectedFolderId === folder.id,
        [selectedFolderId, folder]
    );

    const handleConfirmCreate = async (newFolder: Folder) => {
        try {
            const transformedFolder: Folder = {
                ...newFolder,
                status: FolderStatuses.ACTIVE,
            };

            // @ts-ignore: Unreachable code error
            delete transformedFolder.id;
            delete transformedFolder.isTemp;

            onConfirmCreateFolder(transformedFolder);
        } catch (error) {
            console.log({ error });
        }
    };

    const renderHeader = () => {
        return (
            <Header onClick={toggle} isActive={isActive}>
                <TitleContainer>
                    <Icon
                        name="folder-outline"
                        size={20}
                        color="rgb(93, 100, 123)"
                    />
                    <Title>{folder?.name || ''}</Title>
                </TitleContainer>
                <Toggle isOpen={isOpen} isActive={isActive}>
                    <Icon name="triangle" size={6} />
                </Toggle>
            </Header>
        );
    };

    const renderItem = (folderItem: Folder) => {
        const itemFolders = foldersTree?.[folderItem?.id] || [];

        return (
            <FolderTreeItem
                key={folderItem.id}
                onSelect={handleSelect}
                folder={folderItem}
                selectedFolderId={selectedFolderId}
                folders={itemFolders}
                foldersTree={foldersTree}
                onUpdateFoldersTreeItem={onUpdateFoldersTreeItem}
                folderIdToExclude={folderIdToExclude}
                onConfirmCreateFolder={handleConfirmCreate}
            />
        );
    };

    const renderContent = () => {
        return (
            <Content isOpen={isOpen}>
                {filteredFolders.map((folderItem) => renderItem(folderItem))}
                {!filteredFolders.length && !isLoading ? (
                    <Placeholder>No child folders</Placeholder>
                ) : null}
                {isLoading ? <Placeholder>Loading...</Placeholder> : null}
            </Content>
        );
    };

    return (
        <Container>
            {folder.isTemp ? (
                <TempNewFolder
                    folder={folder}
                    selectedFolderId={selectedFolderId}
                    onSelect={toggle}
                    onConfirmCreate={handleConfirmCreate}
                    hasError={hasError}
                    onClearError={onClearError}
                />
            ) : (
                <>
                    {renderHeader()}
                    {renderContent()}
                </>
            )}
        </Container>
    );
};

export default FolderTreeItem;
