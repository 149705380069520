import { memo, useCallback, useEffect } from "react";
import { Icon } from "components/UI";
import { useDispatch, useSelector } from "react-redux";
import {
  getModalEvent,
  getModalOptionalData,
} from "state/modules/modal/selectors";
import { hideModal, ModalEvent } from "state/modules/modal";
import {
  deleteCollection,
  deleteCollectionsItems,
  setSelectedCollectionsItems,
} from "state/modules/collections";
import {
  deleteSpecificVideo,
  deleteVideos,
  getVideoFiles,
  getVideoInfo,
} from "state/modules/media";
import { MediaFile } from "interfaces/videos";
import { useNavigate } from "react-router-dom";
import { clearMetadata } from "state/modules/metadata";
import { deleteTemplate, deleteTemplates } from "state/modules/templates";
import { deleteProject } from "state/modules/projects";
import styles from "./styles.module.scss";

const ConfirmDeleteModal = () => {
  const dispatch = useDispatch();
  const modalEvent = useSelector(getModalEvent);
  const optionalData = useSelector(getModalOptionalData);
  const currentSelectedVideo = useSelector(getVideoInfo) as MediaFile;
  const videos = useSelector(getVideoFiles) as Array<MediaFile>;
  const navigate = useNavigate();

  const handleConfirm = useCallback(async () => {
    const dataToRemove = optionalData?.dataToRemove;

    if (modalEvent === ModalEvent.DELETE_PROJECT) {
      const { projectId } = dataToRemove;

      dispatch(deleteProject(projectId));
      dispatch(hideModal());
    } else if (modalEvent === ModalEvent.DELETE_TEMPLATE) {
      const { templateId } = dataToRemove;

      dispatch(deleteTemplate(templateId));
      dispatch(hideModal());
    } else if (modalEvent === ModalEvent.DELETE_TEMPLATES) {
      dispatch(deleteTemplates());
      dispatch(hideModal());
    } else if (modalEvent === ModalEvent.DELETE_COLLECTION) {
      const { selectedCollection } = dataToRemove;

      dispatch(deleteCollection(selectedCollection));
    } else if (modalEvent === ModalEvent.DELETE_COLLECTIONS_ITEMS) {
      const { selectedCollectionsItems } = dataToRemove;

      dispatch(setSelectedCollectionsItems([]));
      dispatch(deleteCollectionsItems(selectedCollectionsItems));
      dispatch(hideModal());
    } else if (modalEvent === ModalEvent.DELETE_VIDEOS) {
      dispatch(deleteVideos());
      dispatch(hideModal());
    } else if (modalEvent === ModalEvent.DELETE_SPECIFIC_VIDEO) {
      if (currentSelectedVideo) {
        if (videos.length === 1) {
          dispatch(clearMetadata());
          navigate("/files");
        } else if (currentSelectedVideo.id === optionalData?.videoId) {
          const currentSelectedVideoIndex = videos.findIndex(
            (item: MediaFile) => item.id === optionalData?.videoId
          );

          const nextVideo = videos[currentSelectedVideoIndex + 1];

          if (nextVideo) {
            dispatch(clearMetadata());
            navigate(`/smart-search/studio?id=${nextVideo.id}`);
          }
        }
      }

      dispatch(deleteSpecificVideo(optionalData?.videoId));

      dispatch(hideModal());
    }
  }, [
    currentSelectedVideo,
    dispatch,
    navigate,
    modalEvent,
    optionalData,
    videos,
  ]);

  const onKeyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleConfirm();
        // dispatch(hideModal());
      }
    },
    [handleConfirm]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDownHandler);
    return () => {
      window.removeEventListener("keydown", onKeyDownHandler);
    };
  }, [onKeyDownHandler]);

  const handleCancel = () => {
    dispatch(hideModal());
  };

  return (
    <div className={styles.ConfirmDeleteModal}>
      <div className={styles.ConfirmDeleteModal__leftSide}>
        <Icon name="attention" />
      </div>
      <div>
        <p className={styles.ConfirmDeleteModal__title}>
          Are you sure you want to delete the selected item?
        </p>
        <p className={styles.ConfirmDeleteModal__descriptions}>
          The selected item will be deleted immediately. <br /> You can’t undo
          this action.
        </p>
        <div className={styles.ConfirmDeleteModal__buttonsWrap}>
          <button
            onClick={handleCancel}
            className={[
              styles.ConfirmDeleteModal__button,
              styles.ConfirmDeleteModal__button_green,
            ].join(" ")}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className={[
              styles.ConfirmDeleteModal__button,
              styles.ConfirmDeleteModal__button_grey,
            ].join(" ")}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(ConfirmDeleteModal);
