import styled from 'styled-components';
import Bowser from 'bowser';
import { useMemo } from 'react';

const Container = styled.div`
    width: 42rem;
    overflow: hidden;
    height: 34rem;
    position: relative;
    border-radius: 10px;
    cursor: default;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 16px;
    background-color: rgb(255, 255, 255);
    padding: 2em 3em;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

const Shortcut = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
`;

const CategoryContainer = styled.div``;

const ShortcutKeys = styled.div`
    display: flex;
    align-items: center;
`;

const ShortcutTitle = styled.span``;

const ShortcutKey = styled.span`
    color: black;
    background: rgb(248, 249, 249);
    font-size: 0.9rem;
    min-width: 1.2rem;
    height: 1.2rem;
    padding: 0.2rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-left: 0.5rem;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
        Courier New, monospace;
`;

const Title = styled.p`
    text-align: center;
    font-family: 'Proxima Nova Medium';
    font-weight: 500;
    margin-bottom: 40px;
    font-size: 1.2rem;
`;

const ShortcutCategory = styled.p`
    font-family: 'Proxima Nova Semibold';
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
`;

const Footer = styled.div`
    margin: auto auto 0;
`;

const Message = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color: rgb(174, 179, 183);
    display: flex;
`;

interface ShortcutItem {
    label: string;
    keys: {
        win: string[];
        mac: string[];
    };
}

interface ShortcutCategory {
    category: string;
    list: ShortcutItem[];
}

const shorcutsList = [
    {
        category: 'PLAYBACK',
        list: [
            {
                label: 'Toggle playback',
                keys: {
                    win: ['Space'],
                    mac: ['Space'],
                },
            },
            {
                label: 'Skip forward 1s',
                keys: {
                    win: ['Tab'],
                    mac: ['Tab'],
                },
            },
            {
                label: 'Skip backward 1s',
                keys: {
                    win: ['Shift', 'Tab'],
                    mac: ['Shift', 'Tab'],
                },
            },
            {
                label: 'Skip forward 10s',
                keys: {
                    win: ['l'],
                    mac: ['l'],
                },
            },
            {
                label: 'Skip backward 10s',
                keys: {
                    win: ['j'],
                    mac: ['j'],
                },
            },
            {
                label: 'Seek to start',
                keys: {
                    win: ['Home'],
                    mac: ['fn', '←'],
                },
            },
            {
                label: 'Skip 1 frame',
                keys: {
                    win: ['→'],
                    mac: ['→'],
                },
            },
            {
                label: 'Skip backward 1 frame',
                keys: {
                    win: ['←'],
                    mac: ['←'],
                },
            },
            {
                label: 'Seek to start ',
                keys: {
                    win: ['fn', '←'],
                    mac: ['fn', '←'],
                },
            },

            {
                label: 'Skip cuts right',
                keys: {
                    win: ['↑'],
                    mac: ['↑'],
                },
            },
            {
                label: 'Skip cuts left',
                keys: {
                    win: ['↓'],
                    mac: ['↓'],
                },
            },
            {
                label: 'Zoom in',
                keys: {
                    win: ['+'],
                    mac: ['+'],
                },
            },
            {
                label: 'Zoom out',
                keys: {
                    win: ['-'],
                    mac: ['-'],
                },
            },
        ],
    },
    {
        category: 'EDITOR',
        list: [
            {
                label: 'Undo',
                keys: {
                    win: ['ctrl', 'z'],
                    mac: ['⌘', 'z'],
                },
            },
            {
                label: 'Redo',
                keys: {
                    win: ['ctrl', 'shift'],
                    mac: ['⌘', 'shift', 'z'],
                },
            },
            {
                label: 'Select multiple media/subtitles',
                keys: {
                    win: ['ctrl', 'click'],
                    mac: ['⌘', 'click'],
                },
            },
            {
                label: 'Select All',
                keys: {
                    win: ['ctrl', 'a'],
                    mac: ['⌘', 'a'],
                },
            },
            {
                label: 'Copy',
                keys: {
                    win: ['ctrl', 'c'],
                    mac: ['⌘', 'c'],
                },
            },
            {
                label: 'Cut',
                keys: {
                    win: ['ctrl', 'x'],
                    mac: ['⌘', 'x'],
                },
            },
            {
                label: 'Paste',
                keys: {
                    win: ['ctrl', 'v'],
                    mac: ['⌘', 'v'],
                },
            }
        ],
    },
] as ShortcutCategory[];

const message = {
    label: 'You can toggle available shortcuts by pressing',
    keys: {
        win: ['ctrl', 'k'],
        mac: ['⌘', 'k'],
    },
} as ShortcutItem;

const KeyboardShortcutsModal = (): JSX.Element => {
    const osKey: 'win' | 'mac' = useMemo(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);

        return browser.getOS().name === 'macOS' ? 'mac' : 'win';
    }, []);

    const renderShortcutCategory = (
        category: ShortcutCategory
    ): JSX.Element => (
        <CategoryContainer key={category.category}>
            <ShortcutCategory>{category.category}</ShortcutCategory>
            {category.list.map((shortcut) => (
                <Shortcut key={shortcut.label}>
                    <ShortcutTitle>{shortcut.label}</ShortcutTitle>
                    <ShortcutKeys>
                        {shortcut.keys[osKey].map((key) => (
                            <ShortcutKey key={key}>{key}</ShortcutKey>
                        ))}
                    </ShortcutKeys>
                </Shortcut>
            ))}
        </CategoryContainer>
    );

    return (
        <Container>
            <Title>Available shortcuts</Title>
            <Grid>
                {shorcutsList.map((shorcutsCategory) =>
                    renderShortcutCategory(shorcutsCategory)
                )}
            </Grid>
            <Footer>
                <Message>
                    {message.label}
                    <ShortcutKeys>
                        {message.keys[osKey].map((key) => (
                            <ShortcutKey key={key}>{key}</ShortcutKey>
                        ))}
                    </ShortcutKeys>
                </Message>
            </Footer>
        </Container>
    );
};

export default KeyboardShortcutsModal;
