import Loader from 'react-loader-spinner';

import styles from './styles.module.scss';

export default (): JSX.Element => (
  <div className={styles.AppLoader__overlay}>
    <p className={styles.AppLoader__overlayText}>Loading...</p>
    <Loader type="Oval" color="#fd0b50" height={45} width={45} />
  </div>
);
