import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, Input } from 'components/UI';

import { getUserInfo, setUser } from 'state/modules/user';

import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { validate } from 'utils';

const TopLine = styled.div`
    width: 100%;
    margin-bottom: 25px;

    /* > div:first-child {
        margin-right: 10px;
    } */
`;

const SettingsInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgb(247, 247, 248);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: 0.5rem;
`;

const SettingsInput = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
    padding-right: 5px;
    width: 100%;
`;

const ButtonWrap = styled.div``;

const FormWrap = styled.div`
    margin-bottom: 25px;
`;

const InputWrap = styled.div``;

const SettingsContent = styled.div``;

const SettingsActionsContainer = styled.div`
    display: flex;
    align-items: center;

    > button:first-child {
        margin-right: 8px;
    }
`;

const SettingsAction = styled.button<{ color: string }>`
    background-color: transparent;
    outline: none;
    border: none;
    color: ${({ color }) => color};
    cursor: pointer;
`;

const Title = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
    margin-bottom: 50px;
`;

const SettingsValue = styled.p`
    color: rgb(93, 100, 123);
`;

const SettingsTitle = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 500;
    color: rgb(25, 32, 51);
    display: flex;
    align-items: center;
`;

const EditToggler = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    width: 20px;
    height: 20px;
`;

const SettingsContainer = styled.div`
    flex: 1;

    &:first-child {
        margin-bottom: 25px;
    }
`;

const Container = styled.div`
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 1.25rem;
    padding: 46px;
    z-index: 10;
    max-width: 600px;
    max-height: 550px;
    width: 75vw;
    overflow: auto;
    position: relative;
`;

interface Props {
    closeButton: JSX.Element;
}

const AccountSettingsModal = ({ closeButton }: Props) => {
    const [oldPassword, setOldPassword] = useState('');
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
    const [name, setName] = useState('');
    const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] =
        useState(false);
    const [isEditPasswordActive, setPasswordActive] = useState(false);
    const [isEditNameActive, setEditNameActive] = useState(false);

    const userInfo = useSelector(getUserInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo) {
            setName(userInfo?.attributes?.name || userInfo?.username);
        }
    }, [userInfo]);

    const togglePasswordEdit = () => {
        setPasswordActive(!isEditPasswordActive);
    };

    const toggleNameEdit = () => {
        setEditNameActive(!isEditNameActive);
    };

    const cancelNameEdit = () => {
        setName(userInfo?.attributes?.name || userInfo?.username || '');
        setEditNameActive(false);
    };

    const handleSaveName = async () => {
        setEditNameActive(false);

        try {
            const user = await Auth.currentAuthenticatedUser();

            await Auth.updateUserAttributes(user, {
                name,
            });

            dispatch(
                setUser({
                    ...userInfo,
                    attributes: {
                        ...userInfo?.attributes,
                        name,
                    },
                } as any)
            );
        } catch (error) {
            console.log({ error });
            showNotification(NotificationTypes.error, (error as any)?.message);
        }
    };

    const handleSubmit = async () => {
        const passwordValidationErrors = validate(
            newPassword,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        const oldPasswordValidationErrors = validate(
            oldPassword,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        const confirmNewPasswordValidationErrors = validate(
            confirmNewPassword,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        const passwordError = passwordValidationErrors[0] || '';
        const oldPasswordErrorString = oldPasswordValidationErrors[0] || '';
        const confirmNewPasswordErrorString =
            confirmNewPasswordValidationErrors[0] || '';

        try {
            if (
                passwordError ||
                oldPasswordErrorString ||
                confirmNewPasswordErrorString
            ) {
                setNewPasswordError(passwordError);
                setConfirmNewPasswordError(confirmNewPasswordErrorString);
                setOldPasswordError(oldPasswordErrorString);
            } else if (newPassword !== confirmNewPassword) {
                setNewPasswordError('Passwords do not match');
                setConfirmNewPasswordError('Passwords do not match');
            } else {
                return Auth.currentAuthenticatedUser()
                    .then((user) =>
                        Auth.changePassword(user, oldPassword, newPassword)
                    )
                    .then((data) => {
                        setNewPasswordError('');
                        setConfirmNewPasswordError('');
                        setOldPasswordError('');
                        setIsOldPasswordVisible(false);
                        setIsNewPasswordVisible(false);
                        setIsConfirmNewPasswordVisible(false);
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmNewPassword('');

                        showNotification(NotificationTypes.success, data);
                        // setEditActive(false);
                    })
                    .catch((error) =>
                        showNotification(
                            NotificationTypes.error,
                            (error as any).message
                        )
                    );
            }
        } catch (error) {
            if ((error as any).code === 'LimitExceededException') {
                showNotification(
                    NotificationTypes.error,
                    (error as any).message
                );
            }
        }
    };

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setName(value);
    };

    const handleChangeNewPassword = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;

        setNewPassword(value);
        setNewPasswordError('');
        setConfirmNewPasswordError('');
    };

    const handleChangeConfirmNewPassword = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;

        setConfirmNewPassword(value);
        setConfirmNewPasswordError('');
        setNewPasswordError('');
    };

    const handleChangeOldPassword = (
        event: React.ChangeEvent<HTMLInputElement>,
        field?: string | undefined
    ) => {
        const { value } = event.target;

        setOldPassword(value);
        setOldPasswordError('');
    };

    const handleChangeOldPasswordVisibility = () => {
        setIsOldPasswordVisible(!isOldPasswordVisible);
    };

    const handleChangeNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };

    const handleChangeConfirmNewPasswordVisibility = () => {
        setIsConfirmNewPasswordVisible(!isConfirmNewPasswordVisible);
    };

    const renderEyeIcon = (iconName: string) => (
        <Icon
            name={iconName}
            color={isNewPasswordVisible ? '#333333' : '#cecece'}
        />
    );

    const renderChangePassword = () => {
        return (
            <SettingsContainer>
                <SettingsContent>
                    <SettingsTitle>
                        <span>Password</span>
                        {!isEditPasswordActive ? (
                            <EditToggler onClick={togglePasswordEdit}>
                                <Icon
                                    name={'edit'}
                                    size={12}
                                    color={'#333333'}
                                />
                            </EditToggler>
                        ) : null}
                    </SettingsTitle>
                    <SettingsValue>********</SettingsValue>
                    {isEditPasswordActive ? (
                        <>
                            <FormWrap>
                                <InputWrap>
                                    <Input
                                        label="Old Password"
                                        type={
                                            isOldPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={oldPassword}
                                        onChange={handleChangeOldPassword}
                                        error={oldPasswordError}
                                        placeholder="Enter your password"
                                        icon={renderEyeIcon(
                                            isOldPasswordVisible
                                                ? 'eye'
                                                : 'close-eye'
                                        )}
                                        isPasswordVisible={isOldPasswordVisible}
                                        onIconClick={
                                            handleChangeOldPasswordVisibility
                                        }
                                    />
                                </InputWrap>
                                <InputWrap>
                                    <Input
                                        label="New Password"
                                        type={
                                            isNewPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={newPassword}
                                        onChange={handleChangeNewPassword}
                                        error={newPasswordError}
                                        placeholder="Enter your password"
                                        icon={renderEyeIcon(
                                            isNewPasswordVisible
                                                ? 'eye'
                                                : 'close-eye'
                                        )}
                                        isPasswordVisible={isNewPasswordVisible}
                                        onIconClick={
                                            handleChangeNewPasswordVisibility
                                        }
                                    />
                                </InputWrap>
                                <InputWrap>
                                    <Input
                                        label="Repeat New Password"
                                        type={
                                            isConfirmNewPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={confirmNewPassword}
                                        onChange={
                                            handleChangeConfirmNewPassword
                                        }
                                        error={confirmNewPasswordError}
                                        placeholder="Enter your password"
                                        icon={renderEyeIcon(
                                            isConfirmNewPasswordVisible
                                                ? 'eye'
                                                : 'close-eye'
                                        )}
                                        isPasswordVisible={
                                            isConfirmNewPasswordVisible
                                        }
                                        onIconClick={
                                            handleChangeConfirmNewPasswordVisibility
                                        }
                                    />
                                </InputWrap>
                            </FormWrap>
                            <ButtonWrap>
                                <Button
                                    type="button"
                                    small
                                    blue
                                    onClick={handleSubmit}
                                    disabled={
                                        oldPassword.length === 0 ||
                                        newPassword.length === 0 ||
                                        confirmNewPassword.length === 0
                                    }
                                >
                                    Save
                                </Button>
                            </ButtonWrap>
                        </>
                    ) : null}
                </SettingsContent>
            </SettingsContainer>
        );
    };

    const renderUserName = () => {
        return (
            <SettingsContainer>
                <SettingsTitle>
                    Name
                    {!isEditNameActive ? (
                        <EditToggler onClick={toggleNameEdit}>
                            <Icon name={'edit'} size={12} color={'#333333'} />
                        </EditToggler>
                    ) : null}
                </SettingsTitle>
                {isEditNameActive ? (
                    <SettingsInputContainer>
                        <SettingsInput
                            value={name}
                            onChange={handleChangeName}
                        />
                        <SettingsActionsContainer>
                            <SettingsAction
                                color="#16a3ce"
                                onClick={handleSaveName}
                            >
                                Save
                            </SettingsAction>
                            <SettingsAction
                                color="#010829"
                                onClick={cancelNameEdit}
                            >
                                Cancel
                            </SettingsAction>
                        </SettingsActionsContainer>
                    </SettingsInputContainer>
                ) : (
                    <SettingsValue>{name || ''}</SettingsValue>
                )}
            </SettingsContainer>
        );
    };

    const renderUserEmail = () => {
        return (
            <SettingsContainer>
                <SettingsTitle>Email</SettingsTitle>
                <SettingsValue>
                    {userInfo?.attributes?.email || ''}
                </SettingsValue>
            </SettingsContainer>
        );
    };

    return (
        <Container>
            <Title>Account Settings</Title>
            <TopLine>
                {renderUserName()}
                {renderUserEmail()}
            </TopLine>
            {renderChangePassword()}
            {closeButton}
        </Container>
    );
};

export default AccountSettingsModal;
