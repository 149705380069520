import { IconProps } from './index';

const CheckMarkNew = ({ size, color, secondColor }: IconProps): JSX.Element => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="9"
                cy="9"
                r="8.5"
                fill={color}
                stroke={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                  d="M7.09091 11.7761L4.58523 9.1903L3.75 10.0522L7.09091 13.5L14.25 6.11194L13.4148 5.25L7.09091 11.7761Z"
                  fill={secondColor}
            />
        </svg>
    );
};

export default CheckMarkNew;
