import React, { ChangeEvent, memo, useState } from 'react';
import Icon from '../Icon';

import styles from './styles.module.scss';

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  type?: string;
}

const PasswordInput: React.FC<Props> = memo((props: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    value, onChange, onKeyDown, placeholder, className, disabled, type
  } = props;

  const toggle = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const renderVisibilityToggler = () => <button
    onClick={toggle}
    className={styles.PasswordInput__toggler}
    type="button"
  >
    <Icon
      name={isPasswordVisible ? 'eye' : 'close-eye'}
      color="#cecece"
      size={isPasswordVisible ? 14 : 18}
    />
  </button>

  return (
    <div
      className={[styles.PasswordInput, className || null].join(
        ' ',
      )}
    >
      <input
        onKeyDown={onKeyDown}
        className={styles.PasswordInput__input}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ''}
        disabled={disabled}
        type={isPasswordVisible ? 'text' : 'password'}
      />
      {renderVisibilityToggler()}
    </div>
  );
});

export default PasswordInput;
