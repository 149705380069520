import Select, { OptionProps, SingleValueProps } from "react-select";

import { ColorOption } from "interfaces/colors";

const colourStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    border: "none",
    borderRadius: 12,
    borderWidth: 0,
    background: "transparent",
    padding: 0,
    outline: "none",
    height: "100%",
  }),
  input: (provided: any) => ({
    ...provided,
    border: "none",
    padding: "5px 0",
    color: "#707070",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#707070",
  }),
  // placeholder: (placeholderStyles: CSSProperties) => placeholderStyles,
};

interface Props {
  options: Array<ColorOption>;
  onChange: (value: any) => void;
  value: ColorOption;
  position?: string;
}

export const ColorSelect = (props: Props): JSX.Element => {
  const { options, onChange, value, position } = props;

  return (
    <Select
      styles={{
        ...colourStyles,
        option: (provided: any, props: OptionProps<ColorOption, false>) => {
          const { data } = props;

          return {
            ...provided,
            backgroundColor: data.value,
            color: "transparent",
            width: "100%",
            height: "25px",
            padding: 0,
            cursor: "pointer",
            border: data.value === "white" ? "1px solid #d8d8d8" : "none",
          };
        },
        singleValue: (provided: any, props: SingleValueProps<ColorOption>) => {
          const { data } = props;

          return {
            ...provided,
            backgroundColor: data.value,
            color: "transparent",
            width: "30px",
            border: "1px solid #d8d8d8",
          };
        },
        menu: (provided: any) => {
          let updatedStyles = {
            ...provided,
            width: "100px",
            height: "130px",
            paddingRight: "6px",
            paddingLeft: "6px",
            display: "flex",
          };

          if (position === "left") {
            updatedStyles.left = 0;
          } else {
            updatedStyles.right = 0;
          }

          return updatedStyles;
        },
        menuList: (provided: any) => ({
          ...provided,
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "6px",
          justifyContent: "center",
          alignContent: "center",
        }),
        control: () => ({
          backgroundColor: "transparent",
          border: "none",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        indicatorsContainer: () => ({
          display: "none",
        }),
      }}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default ColorSelect;
