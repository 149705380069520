import { RawSentence, TransformedSentence } from 'state/modules/metadata';
import groupBy from 'lodash.groupby';
import { createSelector } from 'reselect';

import {
    Project,
    ProjectAudio,
    ProjectVideo,
    TimelineItem,
    TimelineItemType,
    TimelineMedias,
} from 'interfaces/projects';
import { MediaFile, MediaType, TempMediaFile } from 'interfaces/videos';

import { State } from 'state/modules';

import { reducer } from 'utils/calc';

import { secondsToFrameNumber, TIMELINE_FRAME_RATE } from 'utils/timeline';
import { sortBy } from 'lodash';
import { TimelineVideoContainer } from 'interfaces/timeline';
import { ProjectsState } from './types';
import { ProjectSubtitlesState } from '../projectSubtitles';

export const getProjectsState = (state: State): ProjectsState => state.projects;

export const getProjectsList = createSelector(
    getProjectsState,
    (state) => state.projectsList
);

export const getProjectsCount = createSelector(
    getProjectsState,
    (state) => state.projectsList.length
);

export const getProjectsTotal = createSelector(
    getProjectsState,
    (state) => state.projectsTotal
);

export const getProjectsLoading = createSelector(
    getProjectsState,
    (state) => state.isProjectsLoading
);

export const getProjectDetailsInfo = createSelector(
    getProjectsState,
    (state) => state.projectDetails
);

export const getProjectDetailsLoading = createSelector(
    getProjectsState,
    (state) => state.isProjectDetailsLoading
);

export const getProjectLatestVersionLoading = createSelector(
    getProjectsState,
    (state) => state.getProjectLatestVersionLoading
);

export const getProjectElements = createSelector(
    getProjectsState,
    (state) => state.projectDetails?.elements || []
);

export const getProjectCreationLoading = createSelector(
    getProjectsState,
    (state) => state.isProjectCreationLoading
);

export const getProjectsIdsForDelete = createSelector(
    getProjectsState,
    (state) => state.projectsIdsForDelete
);

export const getProjectGenerationLoading = createSelector(
    getProjectsState,
    (state) => state.isProjectGenerationLoading
);

export const getProjectUpdateLoading = createSelector(
    getProjectsState,
    (state) => state.isProjectUpdateLoading
);

export const getProjectVideos = createSelector(
    getProjectsState,
    (state) => state.projectVideos
);

export const getProjectVideosObjects = createSelector(
    getProjectsState,
    (state) => state.projectDetails?.videos,
);

export const getProjectStockVideos = createSelector(
    getProjectVideos,
    (videos) => videos.filter((item) => item.isStockMedia)
);

export const getProjectMediaSources = createSelector(
    getProjectsState,
    (state) => state.projectMediaSources
);

export const getProjectMediaSourceSettings = createSelector(
    getProjectsState,
    (state) => state.projectDetails?.mediaSourcesSettings
);

export const getProjectMediaSourcesIds = createSelector(
    getProjectMediaSources,
    (mediaSources: MediaFile[]) =>
        mediaSources.map((mediaSorce) => mediaSorce.id)
);

export const getProjectTempMediaSources = createSelector(
    getProjectsState,
    (state) => state.projectTempMediaSources
);

//
export const getProjectTempMediaSourcesPreviews = createSelector(
    getProjectsState,
    (state) => state.projectTempMediaSourcesPreviews
);

export const getFilteredProjectMediaSources = createSelector(
    [getProjectMediaSources, getProjectTempMediaSources],
    (mediaSources: MediaFile[], tempMediaSources: TempMediaFile[]) => {
        const filteredMediaSources = mediaSources.filter((mediaSource) => {
            const hasTempMediaSource =
                tempMediaSources.findIndex(
                    (tempMediaSource) => tempMediaSource.id === mediaSource.id
                ) >= 0;

            if (!hasTempMediaSource) {
                return mediaSource;
            }
        });

        return [...tempMediaSources, ...filteredMediaSources];
    }
);

export const checkIsProjectDeleting = (id: string) =>
    createSelector(
        getProjectsIdsForDelete,
        (projectsIdsForDelete) =>
            projectsIdsForDelete.findIndex(
                (projectId: string) => projectId === id
            ) >= 0
    );

export const getProjectUpdatesHistory = createSelector(
    getProjectsState,
    (state) => state.projectUpdatesHistory
);

export const getProjectAudioMediaSources = createSelector(
    getProjectsState,
    (state) => state.projectAudioMediaSources
);

    export const getProjectAudioLanguagesOptions = createSelector(
    getProjectsState,
    (state) => state.projectAudioLanguagesOptions
);

export const getProjectAudiosList = createSelector(
    getProjectsState,
    (state) => state.projectAudios || []
);

export const getProjectActiveAudiosList = createSelector(
    getProjectAudiosList,
    (audios: ProjectAudio[]) => audios.filter((item) => item.isActive)
);

export const getProjectSockAudiosList = createSelector(
    getProjectAudiosList,
    (audios: ProjectAudio[]) => audios.filter((item) => item.isStockMedia)
);

export const getProjectAudiosGroupedByTrackId = createSelector(
    getProjectAudiosList,
    (audios) => {
        const list = audios.filter((item) => item.isActive);

        const grouped = groupBy(list, 'projectTrackId');

        return grouped;
    }
);

export const getCountOfProjectAudiosTracks = createSelector(
    getProjectAudiosList,
    (audios) => {
        const list = audios.filter((item) => item.isActive);
        const grouped = groupBy(list, 'projectTrackId');
        return Object.keys(grouped).length || 0;
    }
);

export const getCountOfProjectTextTracks = createSelector(
    getProjectDetailsInfo,
    (project) => (project?.texts || []).length || 0
);

export const getCountOfProjectElementsTracks = createSelector(
    getProjectDetailsInfo,
    (project) => (project?.elements || []).length || 0
);

export const getProjectTransitions = createSelector(
    getProjectsState,
    (state) => state.transitions
);

export const getProjectFilters = createSelector(
    getProjectsState,
    (state) => state.filters
);

export const getProjectsSort = createSelector(
    getProjectsState,
    (state) => state.projectsSort
);

export const getElementsLibrary = createSelector(
    getProjectsState,
    (state) => state.elementsList
);

export const getGroupedElements = createSelector(
    getElementsLibrary,
    (elements) => groupBy(elements, 'category')
);

export const getProjectDetachedAudioMediaSources = createSelector(
    getProjectAudioMediaSources,
    getProjectAudiosList,
    (audioMediaSources, audiosList) => {
        return audioMediaSources.filter((audioSource) => {
            const relatedProjectAudio = audiosList.find(
                (audio) => audio.mediaSourcesId === audioSource.id
            );

            // if (relatedProjectAudio || !audioSource.videoId.length) return audioSource
            if (relatedProjectAudio) return audioSource;
        });
    }
);

// export const getProjectTotalFramesCount = createSelector(
//     getProjectMediaSources,
//     getProjectTempMediaSources,
//     getProjectAudioMediaSources,
//     (mediaSources: MediaFile[], tempMediaSources: TempMediaFile[]) => {
//         let filteredMediaSources = mediaSources.filter((mediaSource) => {
//             const hasTempMediaSource =
//                 tempMediaSources.findIndex(
//                     (tempMediaSource) => tempMediaSource.id === mediaSource.id
//                 ) >= 0;

//             if (!hasTempMediaSource) {
//                 return mediaSource;
//             }
//         });

//         filteredMediaSources = [
//             ...tempMediaSources,
//             ...filteredMediaSources,
//         ] as Array<any>;

//         let framesArray: number[] = [];

//         framesArray = filteredMediaSources.map(
//             (meidaSource: MediaFile | TempMediaFile) => {
//                 const mediaInfo =
//                     meidaSource?.mediaInfo?.proxy ||
//                     meidaSource?.mediaInfo?.original;

//                 return mediaInfo?.video?.[0]?.frameCount || 0;
//             }
//         );

//         return framesArray.reduce(reducer, 0);
//     }
// );

export const getProjectTotalDuration = createSelector(
    getProjectsState,
    (state) => {
        const mediaSources = state.projectMediaSources;
        const tempMediaSources = state.projectTempMediaSources;

        const filteredMediaSources = mediaSources.filter((mediaSource) => {
            const hasTempMediaSource =
                tempMediaSources.findIndex(
                    (tempMediaSource) => tempMediaSource.id === mediaSource.id
                ) >= 0;

            if (!hasTempMediaSource) {
                return mediaSource;
            }
        });

        const projectMediaSources = [
            ...tempMediaSources,
            ...filteredMediaSources,
        ];

        let durationArray: number[] = [];

        durationArray = projectMediaSources.map(
            (meidaSource: MediaFile | TempMediaFile) => {
                const mediaInfo =
                    meidaSource?.mediaInfo?.proxy ||
                    meidaSource?.mediaInfo?.original;

                const duration =
                    mediaInfo?.container?.duration ||
                    mediaInfo?.video?.[0]?.duration ||
                    mediaInfo?.audio?.[0]?.duration ||
                    0;

                return duration;
            }
        );

        return durationArray.reduce(reducer, 0);
    }
);

export const getProjectVideosContainers = createSelector(
    getProjectVideos,
    (videos) => {
        const videosArr = [] as ProjectVideo[][];

        let list = [...videos];
        list = sortBy(list, 'startTime');

        const frameRate = list[0]?.mediaInfo.framerate || 0;

        const lastIndex = list.length - 1;

        list.reduce(
            (
                previousValue: ProjectVideo[],
                currentValue: ProjectVideo,
                currentIndex: number
            ) => {
                let currentGroup = [...previousValue];
                const lastItem = currentGroup[currentGroup.length - 1];

                if (currentIndex === 0) {
                    currentGroup = [...currentGroup, currentValue];
                } else if (
                    secondsToFrameNumber(lastItem?.endTime, frameRate) ===
                    secondsToFrameNumber(
                        currentValue.startTime,
                        frameRate
                    ) &&
                    lastItem?.mediaSourcesId === currentValue.mediaSourcesId &&
                    lastItem?.zIndex === currentValue.zIndex
                ) {
                    currentGroup.push(currentValue);
                } else if (
                    secondsToFrameNumber(lastItem?.endTime, frameRate) !==
                    secondsToFrameNumber(
                        currentValue.startTime,
                        frameRate
                    ) ||
                    lastItem?.mediaSourcesId !== currentValue.mediaSourcesId ||
                    lastItem?.zIndex !== currentValue.zIndex
                ) {
                    videosArr.push(currentGroup);
                    currentGroup = [];
                    currentGroup.push(currentValue);
                }

                if (currentIndex === lastIndex) {
                    videosArr.push(currentGroup);

                    currentGroup = [];
                }

                return currentGroup;
            },
            []
        );

        let transformedVideos = videosArr.map((videoArr) => {
            const firstVideo = videoArr[0];
            const lastVideo = videoArr[videoArr.length - 1];
            const videosIds = videoArr.map((video) => video.id);

            const startTime = firstVideo.startTime;
            const trimStartTime = firstVideo.trimStart;
            const endTime = lastVideo.endTime;
            const trimEndTime = lastVideo.trimEnd;

            return {
                startTime,
                trimStartTime,
                endTime,
                trimEndTime,
                id: firstVideo.id,
                mediaSourceId: firstVideo.mediaSourcesId,
                videosIds,
                zIndex: firstVideo.zIndex,
                type: firstVideo.isAudio ? MediaType.AUDIO : MediaType.VIDEO,
            };
        });

        transformedVideos = transformedVideos.map((video) => {
            const topTrack = transformedVideos.filter(
                (container) =>
                    ((container.zIndex || 0) > (video.zIndex || 0) && (container.type === video.type))
            );

            const overlappedContainerIndex = topTrack.findIndex(
                (container) =>
                    secondsToFrameNumber(container.endTime, frameRate) >=
                    secondsToFrameNumber(video.startTime, frameRate) &&
                    secondsToFrameNumber(container.startTime, frameRate) <=
                    secondsToFrameNumber(video.startTime, frameRate)
            );
            const overlappedContainerNext =
                overlappedContainerIndex !== -1
                    ? topTrack[overlappedContainerIndex + 1]
                    : topTrack.find(
                        (container) =>
                            secondsToFrameNumber(
                                container.startTime,
                                frameRate
                            ) >=
                            secondsToFrameNumber(
                                video.startTime,
                                frameRate
                            ) &&
                            secondsToFrameNumber(
                                container.startTime,
                                frameRate
                            ) <=
                            secondsToFrameNumber(video.endTime, frameRate)
                    );

            return {
                ...video,
                startTime:
                    overlappedContainerIndex !== -1
                        ? transformedVideos[overlappedContainerIndex].endTime
                        : video.startTime,
                endTime: overlappedContainerNext
                    ? overlappedContainerNext.startTime
                    : video.endTime,
            };
        });

        return transformedVideos;
    }
);

export const getProjectSubtitlesState = (state: State): ProjectSubtitlesState =>
    state.projectSubtitlesNew;

export const getProjectSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectCurrentSubtitlesList
);

export const getProjectUnsyncedSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectUnsyncedSubtitlesList
);


export const getMedias = createSelector(
    getProjectVideos,
    getProjectSubtitlesList,
    getProjectUnsyncedSubtitlesList,
    getProjectVideosContainers as any,
    getProjectDetailsInfo as any,
    (
        medias: ProjectVideo[],
        subtitles: TransformedSentence[],
        unsyncedSubtitles: TransformedSentence[],
        videosContainers: TimelineVideoContainer[],
        projectDetails: Project
    ) => {
        medias = sortBy(medias, 'startTime');
        const videos = medias.filter((media) => !media.isAudio);
        const audios = medias.filter((media) => media.isAudio);

        const items: TimelineItem[] = [
            ...(projectDetails?.texts || [])
                .map((text) => ({
                    ...text,
                    type: TimelineItemType.TEXT as TimelineItemType.TEXT,
                }))
                .filter((text) => text.endTime > text.startTime),
            ...(projectDetails?.elements || [])
                .map((element) => ({
                    ...element,
                    type: TimelineItemType.ELEMENT as TimelineItemType.ELEMENT,
                }))
                .filter((element) => element.endTime > element.startTime),
            ...(projectDetails?.images || [])
                .map((image) => ({
                    ...image,
                    type: TimelineItemType.IMAGE as TimelineItemType.IMAGE,
                }))
                .filter((image) => image.endTime > image.startTime),
        ];

        const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;
        let filteredVideoSubtitles: TransformedSentence[] = [];
        let filteredAudioSubtitles: TransformedSentence[] = [];

        const allSubtitles = [...subtitles, ...unsyncedSubtitles];

        allSubtitles.forEach((subtitle) => {
            let startTime = 0;
            let endTime = 0;
            let minStartTime = 0;
            let maxEndTime = 0;
            let initialStartTime = 0;
            let initialEndTime = 0;

            let isValidSubtitle = false;
            let media;

            if (subtitle.videoId) {
                const subtitleMedia = medias.find(
                    (_media) =>
                        subtitle.videoId === _media.mediaSourcesId &&
                        secondsToFrameNumber(+subtitle.endTime, frameRate) >
                            secondsToFrameNumber(_media.trimStart, frameRate) &&
                        secondsToFrameNumber(+subtitle.startTime, frameRate) <
                            secondsToFrameNumber(_media.trimEnd, frameRate)
                );
                media = subtitleMedia;
                if (subtitleMedia) {
                    let duration = +subtitle.endTime - +subtitle.startTime;
                    startTime =
                        subtitleMedia.startTime -
                        subtitleMedia.trimStart +
                        +subtitle.startTime;
                    endTime = startTime + duration;

                    const subtitleMediaContainer = videosContainers.find(
                        (videoContainer) =>
                            videoContainer.videosIds.includes(
                                subtitleMedia.id
                            ) &&
                            secondsToFrameNumber(
                                videoContainer.startTime,
                                frameRate
                            ) <= secondsToFrameNumber(startTime, frameRate) &&
                            secondsToFrameNumber(
                                videoContainer.endTime,
                                frameRate
                            ) >= secondsToFrameNumber(endTime, frameRate)
                    );
                    if (subtitleMediaContainer) {
                        initialStartTime = startTime;
                        initialEndTime = endTime;

                        minStartTime = subtitleMediaContainer.startTime;
                        maxEndTime = subtitleMediaContainer.endTime;

                        isValidSubtitle = true;
                        if (projectDetails.duration < endTime) {
                            maxEndTime = projectDetails.duration;
                        }
                    }
                }
            } else {
                isValidSubtitle = true;

                startTime = +subtitle.startTime;
                endTime = +subtitle.endTime;

                minStartTime = 0;
                maxEndTime = projectDetails.duration;

                initialStartTime = startTime;
                initialEndTime = endTime;
            }

            if (isValidSubtitle) {
                const subtitleObj = {
                    ...subtitle,
                    timeline: {
                        startTime,
                        endTime,
                        minStartTime,
                        maxEndTime,
                        initialStartTime,
                        initialEndTime,
                    },
                };

                if (!media || !media.isAudio) {
                    filteredVideoSubtitles.push(subtitleObj);
                } else {
                    filteredAudioSubtitles.push(subtitleObj);
                }
            }
        });

        filteredVideoSubtitles = sortBy(
            filteredVideoSubtitles,
            'timeline.startTime'
        );
        filteredAudioSubtitles = sortBy(
            filteredAudioSubtitles,
            'timeline.startTime'
        );
        const filteredSubtitles = sortBy(
            [...filteredVideoSubtitles, ...filteredAudioSubtitles],
            'timeline.startTime'
        );
        return {
            videos,
            subtitles: filteredSubtitles,
            frameRate,
            audios,
            medias,
            audioSubtitles: filteredAudioSubtitles,
            videoSubtitles: filteredVideoSubtitles,
            items,
        } as TimelineMedias;
    }
);

export const getTimelineItems = createSelector(
    getProjectDetailsInfo as any,
    (
        projectDetails: Project
    ) => {
        const timelineItems = {
            elements: projectDetails?.elements,
            texts: projectDetails?.texts,
            images: projectDetails?.images,
            audios: projectDetails?.audios,
            videos: projectDetails?.videos,
            sentences: projectDetails?.sentences,
        }

        return timelineItems;
    }
);

export const getProjectsViewMode = createSelector(
    getProjectsState,
    (state) => state.viewMode
);

// last project generation
export const getProjectsLastGenerationInfo = createSelector(
    getProjectsState,
    (state) => state.lastProjectGeneration
);

// text ot speech
export const getTextToSpeechGenerationLoading = createSelector(
    getProjectsState,
    (state) => state.isTextToSpeechGenerationLoading
);

// Uploads section
export const getProjectUploadsMedia = createSelector(
    getProjectsState,
    (state) => state.projectUploads
);

export const getProjectUploadsSuccess = createSelector(
    getProjectsState,
    (state) => state.getProjectUploadsSuccess
);

export const getMediasWithCleanAudioGeneration = createSelector(
    getProjectsState,
    (state) => state.mediasWithCleanAudioGeneration
);

export const getIsChangeTextAnimationLoading = createSelector(
    getProjectsState,
    (state) => state.isChangeTextAnimationLoading
);

// templates
export const getTemplateWorkspaceId = createSelector(
    getProjectsState,
    (state) => state.templateWorkspaceId
);

export const getSubmittedTemplatesList = createSelector(
    getProjectsState,
    (state) => state.submittedTemplatesList
);

export const getSubmittedTemplatesCount = createSelector(
    getProjectsState,
    (state) => state.submittedTemplatesList.length
);

export const getSubmittedTemplatesTotal = createSelector(
    getProjectsState,
    (state) => state.submittedTemplatesTotal
);

export const getSubmittedTemplatesLoading = createSelector(
    getProjectsState,
    (state) => state.isSubmittedTemplatesLoading
);

export const getPublishedTemplatesList = createSelector(
    getProjectsState,
    (state) => state.publishedTemplatesList
);

export const getPublishedTemplatesCount = createSelector(
    getProjectsState,
    (state) => state.publishedTemplatesList.length
);

export const getPublishedTemplatesTotal = createSelector(
    getProjectsState,
    (state) => state.publishedTemplatesTotal
);

export const getPublishedTemplatesLoading = createSelector(
    getProjectsState,
    (state) => state.isPublishedTemplatesLoading
);

export const getUsableTemplatesList = createSelector(
    getProjectsState,
    (state) => state.usableTemplatesList
);

export const getUsableTemplatesCount = createSelector(
    getProjectsState,
    (state) => state.usableTemplatesList.length
);

export const getUsableTemplatesTotal = createSelector(
    getProjectsState,
    (state) => state.usableTemplatesTotal
);

export const getUsableTemplatesLoading = createSelector(
    getProjectsState,
    (state) => state.isUsableTemplatesLoading
);

export const getUseTemplateLoading = createSelector(
    getProjectsState,
    (state) => state.useTemplateLoading
);

export const getProjectAudiosIdsWithDubbingGeneration = createSelector(
    getProjectsState,
    (state) => state.projectAudiosIdsWithDubbingGeneration
);

