import moment from 'moment';
import styled from 'styled-components';
import { useMemo } from 'react';

import { Icon } from 'components/UI';

import { IInvoice } from 'interfaces/payment';

const Container = styled.button<{ bordered?: boolean }>`
    display: grid;
    grid-template-columns: 1.5fr 1fr 0.75fr 1fr 0.25fr;
    border: none;
    background-color: transparent;
    outline: none;
    border-bottom: ${({ bordered }) =>
        bordered ? '1px solid rgb(128, 128, 128)' : 'none'};
    //padding-left: 1.5rem;
    //padding-right: 1.5rem;
    cursor: pointer;
    width: 100%;
`;

const Column = styled.div`
    color: rgb(65, 69, 82);
    font-size: 14px;
    font-family: 'Proxima Nova', sans-serif;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
`;

const Amount = styled.span`
    color: rgb(65, 69, 82);
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-weight: 600;
`;

const Currency = styled.span`
    text-transform: uppercase;
    margin-left: 5px;
`;

const DownloadButton = styled.button`
    background: transparent;
    outline: none;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AmountContainer = styled.div`
    width: 85px;
    display: flex;
    justify-content: space-between;
`;

const Status = styled.p`
    margin-left: 20px;
    font-family: 'Proxima Nova';
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    border-radius: 4px;
    padding: 1px 6px;
    text-transform: capitalize;
`;

interface Props {
    data: IInvoice;
    bordered?: boolean;
}

const Invoice = ({ data, bordered }: Props) => {
    const {
        currency,
        amount_paid,
        number,
        created,
        hosted_invoice_url,
        invoice_pdf,
        status,
        due_date,
    } = data;

    const statusColors = useMemo(() => {
        switch (status) {
            case 'draft':
                return {
                    color: 'rgb(84, 89, 105)',
                    backgroundColor: 'rgb(235, 238, 241)',
                };
            case 'open':
                return {
                    color: '#fff',
                    backgroundColor: 'rgb(23, 167, 106)',
                };
            case 'void':
                return {
                    color: 'rgb(84, 89, 105)',
                    backgroundColor: 'rgb(235, 238, 241)',
                };
            case 'paid':
                return {
                    color: 'rgb(0,105,8)',
                    backgroundColor: 'rgb(215, 247, 194)',
                };
            case 'uncollectible':
                return {
                    color: '#fff',
                    backgroundColor: 'rgb(237, 104, 4)',
                };
            default:
                return {
                    color: '',
                    backgroundColor: '',
                };
        }
    }, [status]);

    const handleDownload = (event: any) => {
        event.stopPropagation();

        try {
            const link = document.createElement('a');
            link.target = '_blank';

            link.href = invoice_pdf || '';

            document.body.appendChild(link);
            link.click();

            if (document?.body?.contains(link)) {
                document.body.removeChild(link);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const handleOpen = () => {
        const link = document.createElement('a');
        link.target = '_blank';

        link.href = hosted_invoice_url || '';

        document.body.appendChild(link);
        link.click();

        if (document?.body?.contains(link)) {
            document.body.removeChild(link);
        }
    };

    const renderStatus = () => {
        return (
            <Status
                style={{
                    ...statusColors,
                }}
            >
                {status}
            </Status>
        );
    };

    return (
        <Container bordered={bordered} onClick={handleOpen}>
            <Column>
                <AmountContainer>
                    <Amount>
                        {currency === 'usd' ? '$' : ''}
                        {amount_paid / 100}
                    </Amount>
                    <Currency> {currency}</Currency>
                </AmountContainer>
                {renderStatus()}
            </Column>
            <Column>{number}</Column>
            <Column>
                {due_date ? moment.unix(due_date).format('MMM DD, YYYY	') : '-'}
            </Column>
            <Column>{moment.unix(created).format('MMM DD, h:mm A')}</Column>
            <Column>
                <DownloadButton name="downloadButton" onClick={handleDownload}>
                    <Icon name="download" size={16} />
                </DownloadButton>
            </Column>
        </Container>
    );
};

export default Invoice;
