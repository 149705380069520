import { createSelector } from 'reselect';

import { State } from 'state/modules';
import { CollectionsState } from 'state/modules/collections';

export const getState = (state: State): CollectionsState => state.collections;

export const getCollectionsList = createSelector(
  getState,
  (appState) => appState.collections,
);

export const getCollectionsTotal = createSelector(
  getState,
  (appState) => appState.total,
);

export const getCollectionsLoading = createSelector(
  getState,
  (appState) => appState.isCollectionsLoading,
);

export const getCollectionTempItems = createSelector(
  getState,
  (appState) => appState.collectionTempItems,
);

export const getCollectionsItemsListBySearch = createSelector(
  getState,
  (appState) => appState.collectionsItemsBySearch,
);

export const getCollectionsItemBySearchLoading = createSelector(
  getState,
  (appState) => appState.collectionsItemsBySearchLoading,
);

export const getCollectionsItemDeleteStatus = createSelector(
  getState,
  (appState) => appState.isDeleteLoading,
);

export const getImagesForDelete = createSelector(
  getState,
  (appState) => appState.imagesForDelete,
);

export const getUploadingStatus = createSelector(
  getState,
  (appState) => appState.isUploading,
);

export const getSelectedCollectionsItems = createSelector(
  getState,
  (appState) => appState.selectedCollectionsItems,
);

export const getCollectionsDeletingStatus = createSelector(
  getState,
  (appState) => appState.isCollectionDeleting,
);

export const getSelectedCollectionsForDelete = createSelector(
  getState,
  (appState) => appState.selectedCollectionsForDelete,
);

export const getCollectionsSearchQuery = createSelector(
  getState,
  (appState) => appState.searchQuery,
);
