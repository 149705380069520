import produce from 'immer';
import { UserAction, UserActionTypes } from '../user';

import {
    StockMediaAction,
    StockMediaActionTypes,
    StockMediaState,
} from './types';
import { ProjectsAction, ProjectsActionTypes } from '../projects';

const initialState: StockMediaState = {
    // images
    images: [],
    imagesPreviews: [],
    isImagesLoading: false,
    imagesTotal: 0,
    imagesSearchQuery: '',
    imagesListPage: 1,
    categorizedStockImages: {},
    // videos
    videos: [],
    videoPreviews: [],
    isVideosLoading: false,
    videosTotal: 0,
    videosSearchQuery: '',
    videosListPage: 1,
    stockVideosPreviews: [],
    categorizedStockVideos: {},
    categorizedVideoPreviews: {},
    // collections
    collections: [],
    selectedImageCollection: null,
    selectedVideoCollection: null,
    // audio
    songs: [],
    audioPlaylists: [],
    audioPlaylist: null,
    tags: [],
    selectedTag: null,
    categorizedStockSongs: {},
    songsSearchQuery: '',
    isAudiosLoading: false,
    // effects
    audioEffects: [],
    // categories
    categories: [],
    selectedCategory: null,
    activeStockAudio: null,
};

export default (
    state = initialState,
    action: StockMediaAction | UserAction | ProjectsAction
): StockMediaState =>
    produce(state, (draft) => {
        switch (action.type) {
            // images
            case StockMediaActionTypes.GET_STOCK_IMAGES_START:
                draft.isImagesLoading = true;

                return;
            case StockMediaActionTypes.GET_STOCK_IMAGES_FAIL:
                draft.isImagesLoading = false;

                return;
            case StockMediaActionTypes.SET_STOCK_IMAGES:
                draft.images = action.payload.images;
                draft.imagesTotal = action.payload.total;
                draft.isImagesLoading = false;

                return;
            case StockMediaActionTypes.SET_IMAGES_PREVIEWS:
                draft.imagesPreviews = action.payload;

                return;
            case StockMediaActionTypes.SET_IMAGES_SEARCH_QUERY:
                draft.imagesSearchQuery = action.payload;

                return;
            case StockMediaActionTypes.SET_IMAGES_LIST_PAGE:
                draft.imagesListPage = action.payload;

                return;
            case StockMediaActionTypes.SET_CATEGORIZED_STOCK_IMAGES:
                draft.categorizedStockImages = action.payload?.categorizedImages;
                draft.isImagesLoading = false;

                return;
            // videos
            case StockMediaActionTypes.GET_STOCK_VIDEOS_START:
                draft.isVideosLoading = true;

                return;
            case StockMediaActionTypes.GET_STOCK_VIDEOS_FAIL:
                draft.isVideosLoading = false;

                return;
            case StockMediaActionTypes.SET_STOCK_VIDEOS:
                draft.videos = action.payload.videos;
                draft.videosTotal = action.payload.total;
                draft.isVideosLoading = false;

                return;
            case StockMediaActionTypes.SET_CATEGORIZED_STOCK_VIDEOS:
                draft.categorizedStockVideos = action.payload?.categorizedVideos;
                draft.isVideosLoading = false;

                return;
            case StockMediaActionTypes.SET_VIDEOS_PREVIEWS:
                draft.videoPreviews = action.payload;

                return;
            case StockMediaActionTypes.SET_CATEGORIZED_VIDEOS_PREVIEWS:
                draft.categorizedVideoPreviews = action.payload?.categorizedVideos;

                return;
            case StockMediaActionTypes.SET_VIDEOS_SEARCH_QUERY:
                draft.videosSearchQuery = action.payload;

                return;
            case StockMediaActionTypes.SET_VIDEOS_LIST_PAGE:
                draft.videosListPage = action.payload;

                return;
            case StockMediaActionTypes.SET_STOCK_VIDEOS_PREVIEWS:
                draft.stockVideosPreviews = action.payload;

                return;
            // collections
            case StockMediaActionTypes.SET_COLLECTIONS:
                draft.collections = action.payload;

                return;
            case StockMediaActionTypes.SET_SELECTED_VIDEO_COLLECTION:
                draft.selectedVideoCollection = action.payload;
                draft.videos = [];
                draft.videosTotal = 0;

                return;
            case StockMediaActionTypes.SET_SELECTED_IMAGE_COLLECTION:
                draft.selectedImageCollection = action.payload;
                draft.images = [];
                draft.imagesTotal = 0;

                return;
            // Audio
            case StockMediaActionTypes.SET_STOCK_AUDIO_SONGS:
                draft.isAudiosLoading = false;

                return;
            case StockMediaActionTypes.GET_CATEGORIZED_STOCK_AUDIO_SONGS:
                draft.isAudiosLoading = true;

                return;
            case StockMediaActionTypes.SET_CATEGORIZED_STOCK_AUDIO_SONGS:
                draft.categorizedStockSongs = action.payload?.categorizedSongs;
                draft.isAudiosLoading = false;

                return;
            case StockMediaActionTypes.SET_AUDIOS_SEARCH_QUERY:
                draft.songsSearchQuery = action.payload;

                return;
            case StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLISTS:
                draft.audioPlaylists = action.payload;

                return;
            case StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLIST:
                draft.audioPlaylist = action.payload;

                return;
            case StockMediaActionTypes.SET_STOCK_AUDIO_TAGS:
                draft.tags = action.payload;

                return;
            case StockMediaActionTypes.SET_SELECTED_STOCK_AUDIO_TAG:
                draft.selectedTag = action.payload;

                return;
            // effects
            case StockMediaActionTypes.SET_STOCK_AUDIO_EFFECTS:
                draft.audioEffects = action.payload;

                return;
            case ProjectsActionTypes.CLEAR_PROJECT_DETAILS:
                draft.stockVideosPreviews = [];
                draft.selectedTag = null;
                draft.selectedVideoCollection = null;
                draft.selectedImageCollection = null;
                draft.audioPlaylist = null;
                draft.imagesListPage = 0;
                draft.imagesTotal = 0;
                draft.videosTotal = 0;
                draft.videosListPage = 0;
                draft.imagesSearchQuery = '';
                draft.videosSearchQuery = '';
                draft.activeStockAudio = null;

                return;
            // categories
            case StockMediaActionTypes.SET_STOCK_MEDIA_CATEGORIES:
                draft.categories = action.payload;

                return;
            case StockMediaActionTypes.SET_STOCK_MEDIA_SELECTED_CATEGORY:
                draft.selectedCategory = action.payload;

                return;
            // active audio
            case StockMediaActionTypes.SET_ACTIVE_STOCK_AUDIO:
                draft.activeStockAudio = action.payload;

                return;
            case UserActionTypes.SIGN_OUT:
                return initialState;
            default:
                return state;
        }
    });
