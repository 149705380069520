import React, { Component, memo } from 'react';

import { Icon } from 'components/UI';
import styles from './styles.module.scss';

interface CheckboxProps {
    title?: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    titleColor?: string;
    titleFontSize?: number;
    disabled?: boolean;
}

class Checkbox extends Component<CheckboxProps> {
    public renderIcon = (): JSX.Element => <Icon name="check" color="#fff" />;

    public handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { onChange } = this.props;
      const { checked } = event.target;

      if (onChange) {
        onChange(checked);
      }
    };

    public render() {
      const {
        checked, title, titleColor, titleFontSize, disabled,
      } = this.props;
      return (
        <label
          className={[
            styles.Checkbox__container,
            title && styles.Checkbox__container_withTitle,
          ].join(' ')}
        >
          <input
            type="checkbox"
            className={styles.Checkbox__hiddenCheckbox}
            checked={checked}
            onChange={this.handleCheckboxChange}
            disabled={disabled}
          />
          <span
            className={[
              styles.Checkbox__checkmark,
              disabled && styles.Checkbox__checkmark_disabled,
            ].join(' ')}
          >
            {checked && this.renderIcon()}
          </span>
          {title && (
            <span
              style={{
                fontSize: titleFontSize && titleFontSize,
                color: titleColor && titleColor,
              }}
              className={styles.Checkbox__title}
            >
              {title}
            </span>
          )}
        </label>
      );
    }
}

export default memo(Checkbox);
