import { IconProps } from '../index';

const TextOutSkidIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11 30h6m0 0-3-3m3 3-3 3" stroke="#525253" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M17.983 18.401c.677-.747 1.49-1.121 2.437-1.121.948 0 1.745.338 2.39 1.014.654.668.98 1.476.98 2.423 0 .947-.33 1.758-.992 2.435-.661.676-1.461 1.014-2.401 1.014-.933 0-1.737-.406-2.414-1.217v1.002h-.968V15h.968v3.401zm2.377 4.81c.678 0 1.251-.25 1.721-.752.478-.5.717-1.086.717-1.754 0-.676-.23-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.274.234-1.744.704-.462.47-.693 1.062-.693 1.778 0 .708.235 1.31.705 1.802.47.494 1.043.74 1.72.74zM34.695 22.347a3.316 3.316 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.003 0-1.844-.33-2.52-.991-.67-.66-1.005-1.468-1.005-2.423 0-.955.339-1.77 1.016-2.447.677-.684 1.553-1.026 2.629-1.026.637 0 1.223.14 1.757.418.541.278.964.668 1.266 1.17l-.776.489c-.582-.756-1.343-1.134-2.283-1.134-.74 0-1.362.239-1.864.716-.502.47-.753 1.054-.753 1.754s.247 1.301.741 1.803c.494.5 1.12.752 1.876.752.908 0 1.673-.382 2.295-1.146l.764.525z"
                fill="#F0F0F2" />
            <path
                d="M19.985 18.401c.678-.747 1.49-1.121 2.438-1.121.948 0 1.745.338 2.39 1.014.654.668.98 1.476.98 2.423 0 .947-.33 1.758-.992 2.435-.66.676-1.461 1.014-2.402 1.014-.932 0-1.736-.406-2.414-1.217v1.002h-.967V15h.967v3.401zm2.379 4.81c.677 0 1.25-.25 1.72-.752.478-.5.717-1.086.717-1.754 0-.676-.23-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.274.234-1.744.704-.462.47-.693 1.062-.693 1.778 0 .708.235 1.31.705 1.802.47.494 1.043.74 1.72.74z"
                fill="#D0D0D0" />
            <path
                d="M21.987 18.401c.678-.747 1.49-1.121 2.438-1.121.948 0 1.745.338 2.39 1.014.653.668.98 1.476.98 2.423 0 .947-.33 1.758-.992 2.435-.661.676-1.462 1.014-2.402 1.014-.932 0-1.736-.406-2.414-1.217v1.002h-.967V15h.967v3.401zm2.379 4.81c.677 0 1.25-.25 1.72-.752.478-.5.717-1.086.717-1.754 0-.676-.23-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.274.234-1.744.704-.462.47-.693 1.062-.693 1.778 0 .708.235 1.31.705 1.802.47.494 1.043.74 1.72.74z"
                fill="#525253" />
            <path
                d="M12.52 24.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H4L8.135 15h.31l4.075 9.083zM9.616 20.12 8.29 17.172 6.952 20.12h2.664z"
                fill="#F0F0F2" />
            <path
                d="M14.522 24.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H6.002L10.137 15h.31l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664z"
                fill="#D0D0D0" />
            <path
                d="M17.526 24.083H16.44l-1.35-2.924h-3.61l-1.338 2.924H9.006L13.14 15h.311l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664z"
                fill="#525253" />
            <path
                d="M36.697 22.347a3.314 3.314 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.003 0-1.844-.33-2.52-.991-.67-.66-1.005-1.468-1.005-2.423 0-.955.339-1.77 1.016-2.447.677-.684 1.553-1.026 2.629-1.026.637 0 1.223.14 1.757.418.541.278.964.668 1.266 1.17l-.776.489c-.582-.756-1.343-1.134-2.283-1.134-.74 0-1.362.239-1.864.716-.502.47-.753 1.054-.753 1.754s.247 1.301.741 1.803c.494.5 1.12.752 1.876.752.908 0 1.673-.382 2.294-1.146l.765.525z"
                fill="#D0D0D0" />
            <path
                d="M38.7 22.347a3.315 3.315 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.003 0-1.844-.33-2.521-.991-.67-.66-1.004-1.468-1.004-2.423 0-.955.339-1.77 1.016-2.447C33.725 17.342 34.6 17 35.677 17c.637 0 1.223.14 1.757.418.541.278.963.668 1.266 1.17l-.777.489c-.581-.756-1.342-1.134-2.282-1.134-.74 0-1.362.239-1.864.716-.502.47-.753 1.054-.753 1.754s.247 1.301.74 1.803c.495.5 1.12.752 1.877.752.908 0 1.673-.382 2.294-1.146l.765.525z"
                fill="#525253" />
        </svg>
    );
};

export default TextOutSkidIcon;