import { lazy, useEffect } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Modal from 'components/Modal';
import AppLoader from 'components/AppLoader';

import { AppContainer, ScreenContent } from 'components/UI';

import { appInit, getAppInitLoading } from 'state/modules/app';
import {
    getPreferences,
    getToggleToSmartSearchAvailableStatus,
    getUser,
    getUserId,
    getUserInfo,
} from 'state/modules/user';
import {
    getCurrentUserPlan,
    getPaymentFlowLoading,
    getStripePromise,
    setStripePromise,
} from 'state/modules/payment';
import { getModalType, getModalVisibility } from 'state/modules/modal';
import {
    getCurrentWorkspaceInfo,
    getWorkspaceFlowLoading,
} from 'state/modules/workspaces';
import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import ProjectSharing from 'containers/ProjectSharing';
import { getUploadingMediaWithAnalyzeCategories } from '../state/modules/uploading';
import { UploadingMedia } from '../interfaces/uploading';

const VideoEditingFlow = lazy(() => import('./VideoEditingFlow'));
const SmartSearchFlow = lazy(() => import('./SmartSearchFlow'));
const SettingsFlow = lazy(() => import('./SettingsFlow'));

interface Props {
    isSignIn: boolean;
    setIsSignIn: (isSignIn: boolean) => void;
}

const AppFlow = (props: Props): JSX.Element => {
    const { isSignIn, setIsSignIn } = props;

    const userPreferences = useSelector(getPreferences);
    const userId = useSelector(getUserId);
    const isPaymentFlowLoading = useSelector(getPaymentFlowLoading);
    const isAppInitFlowLoading = useSelector(getAppInitLoading);
    const isWorkspaceFlowLoading = useSelector(getWorkspaceFlowLoading);
    const isModalVisible = useSelector(getModalVisibility);
    const modalType = useSelector(getModalType);
    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);
    const currentPlan = useSelector(getCurrentUserPlan);
    const userInfo = useSelector(getUserInfo) as any;
    const videosToAnalyse = useSelector(getUploadingMediaWithAnalyzeCategories) as Array<UploadingMedia>;

    // const [clientSecret, setClientSecret] = useState('');
    const stripePromise = useSelector(getStripePromise);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isAppFlowSwitchAvailable = useSelector(
        getToggleToSmartSearchAvailableStatus
    );

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (videosToAnalyse.length > 0) {
                const message = 'The video analyze is in progress. Leaving will result in its termination and you will have to start it manually. \n Are you sure you want to leave?';
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [videosToAnalyse]);

    useEffect(() => {
        if (currentPlan && userInfo && isSignIn) {
            const method =
                JSON.parse(userInfo?.attributes?.identities || '[]')?.[0]
                    ?.providerType === 'Google'
                    ? 'google'
                    : 'email';

            googleAnalytics.login({
                method,
            });
            amplitudeAnalytics.login({
                login_method: method,
            });
            setIsSignIn(false);
        }
    }, [currentPlan, isSignIn, setIsSignIn, userInfo]);

    useEffect(() => {
        if (!userPreferences && !isAppInitFlowLoading && userId) {
            dispatch(appInit({ navigate, location }));
        }
    }, [
        dispatch,
        userPreferences,
        navigate,
        location,
        isAppInitFlowLoading,
        userId,
    ]);

    useEffect(() => {
        dispatch(
            setStripePromise(
                loadStripe(window.config.REACT_APP_STRIPE_PUBLIC_KEY)
            )
        );
    }, [dispatch]);

    const renderRoutes = (): JSX.Element => {
        if (userId && !isAppInitFlowLoading && currentWorkspace) {
            return (
                <>
                    <ScreenContent>
                        <Routes>
                            <Route
                                path="/video-editing/*"
                                element={<VideoEditingFlow />}
                            />
                            {isAppFlowSwitchAvailable ? (
                                <Route
                                    path="/smart-search/*"
                                    element={<SmartSearchFlow />}
                                />
                            ) : null}

                            <Route
                                path="/settings/*"
                                element={<SettingsFlow />}
                            />
                            <Route
                                path="/project/sharing/*"
                                element={<ProjectSharing />}
                            />
                            {/* <Route
                                path="/"
                                element={
                                    <Navigate to="/video-editing" replace />
                                }
                            /> */}
                        </Routes>
                    </ScreenContent>
                    {isModalVisible && modalType ? <Modal /> : null}
                </>
            );
        }

        return <AppLoader />;
    };

    return (
        <Elements stripe={stripePromise}>
            <AppContainer>
                {renderRoutes()}
                {!userPreferences ||
                isPaymentFlowLoading ||
                isWorkspaceFlowLoading ? (
                    <AppLoader />
                ) : (
                    <></>
                )}
            </AppContainer>
        </Elements>
    );
};

export default AppFlow;
