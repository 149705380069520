import { IconProps } from '../index';

const SplitIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5ZM11.3089 10.2482C11.745 9.60751 12 8.83353 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C8.83353 12 9.60751 11.745 10.2482 11.3089L14.9024 15.9631L10.6577 19.6772C9.9511 19.0486 9.02015 18.6667 8 18.6667C5.79086 18.6667 4 20.4575 4 22.6667C4 24.8758 5.79086 26.6667 8 26.6667C10.2091 26.6667 12 24.8758 12 22.6667C12 22.02 11.8465 21.4091 11.574 20.8685L15.9654 17.0261L26.1363 27.197C26.4292 27.4899 26.9041 27.4899 27.197 27.197C27.4899 26.9041 27.4899 26.4292 27.197 26.1363L16.5382 15.4776C16.5324 15.4716 16.5266 15.4657 16.5207 15.46L11.3089 10.2482ZM8 25.1667C9.38071 25.1667 10.5 24.0474 10.5 22.6667C10.5 21.286 9.38071 20.1667 8 20.1667C6.61929 20.1667 5.5 21.286 5.5 22.6667C5.5 24.0474 6.61929 25.1667 8 25.1667ZM18.7608 13.1511C18.4932 12.8349 18.5327 12.3617 18.8489 12.0941L27.5155 4.76079C27.8317 4.49324 28.305 4.53267 28.5725 4.84888C28.8401 5.16508 28.8007 5.63831 28.4845 5.90587L19.8178 13.2392C19.5016 13.5068 19.0284 13.4673 18.7608 13.1511Z"
                fill={color}
            />
        </svg>
    );
};

export default SplitIconNew;


