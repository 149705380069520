import React, { memo, useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Input } from "components/UI";

import {
  createTemplateFail,
  createTemplateStart,
  getTemplateCreationLoading,
  getTemplates,
  createTemplateSuccess,
} from "state/modules/templates";

import TemplateClient from "services/api/templates";
import { AxiosResponse } from "axios";
import { hideModal } from "state/modules/modal";
import styles from "./styles.module.scss";

const CreateTemplateModal = () => {
  const [title, setTitle] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const isLoading = useSelector(getTemplateCreationLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleCreateTemplate = async () => {
    const data = {
      name: title,
    };

    try {
      dispatch(createTemplateStart());

      const res = (await TemplateClient.createTemplate(data)) as AxiosResponse;

      if (res.status === 201) {
        const templateId = res.data?.content;

        setTimeout(() => {
          dispatch(getTemplates());
          dispatch(hideModal());

          if (templateId) {
            navigate(`/smart-search/templates/${templateId}`);
          }

          dispatch(createTemplateSuccess());
        }, 1500);
      }
    } catch (error) {
      dispatch(createTemplateFail(error));
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCreateTemplate();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDownHandler);

    return () => {
      window.removeEventListener("keydown", onKeyDownHandler);
    };
  }, [title]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <div className={styles.CreateTemplateModal}>
      <p className={styles.CreateTemplateModal__title}>Template name: </p>
      <div className={styles.CreateTemplateModal__inputWrap}>
        <Input
          error=""
          type="text"
          value={title}
          onChange={handleOnChange}
          aligned
          inputRef={inputRef}
        />
      </div>
      <div className={styles.CreateTemplateModal__saveButtonWrap}>
        <Button
          red
          type="button"
          small
          onClick={handleCreateTemplate}
          disabled={isLoading || !title.length}
        >
          {isLoading ? (
            <Loader type="ThreeDots" color="#ffffff" height={30} width={45} />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};

export default memo(CreateTemplateModal);
