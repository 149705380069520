import React, { memo } from 'react';

import styles from './styles.module.scss';

interface ProgressBarProps {
    currentProgress: string | number;
    remainingAmount: string | number;
    withoutOffset?: boolean;
}

const UploadingVideoProgressBar: React.FC<ProgressBarProps> = memo(
  ({ currentProgress }) => (
    <div className={styles.UploadingVideoProgressBarWrap}>
      <div className={styles.UploadingVideoProgressBar}>
        <div
          className={
                            styles.UploadingVideoProgressBar__currentProgress
                        }
          style={{
            width: `${currentProgress}%`,
          }}
        />
      </div>
      <span
        className={
                        styles.UploadingVideoProgressBar__currentProgressCount
                    }
      >
        {currentProgress}
        %
      </span>
    </div>
  ),
);

export default UploadingVideoProgressBar;
