import { IconProps } from './index';

const LibraryIcon = ({ color = '#ffffff', size }: IconProps): JSX.Element => {
    let height = 33;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path opacity="0.5"
                  d="M22.0749 7.49997C22.1393 7.13221 22.1225 6.7548 22.0256 6.39424C21.9286 6.03368 21.754 5.6987 21.5138 5.41281C21.2737 5.12693 20.9739 4.89707 20.6355 4.73937C20.297 4.58167 19.9282 4.49995 19.5548 4.49995H6.44598C6.07262 4.49995 5.70379 4.58167 5.36537 4.73937C5.02694 4.89707 4.72714 5.12693 4.487 5.41281C4.24685 5.6987 4.07219 6.03368 3.97527 6.39424C3.87836 6.7548 3.86153 7.13221 3.92597 7.49997M19.6004 4.49995C19.634 4.18795 19.652 4.03315 19.652 3.90475C19.6533 3.31158 19.4349 2.73894 19.0389 2.2973C18.6429 1.85566 18.0974 1.57631 17.5076 1.51314C17.3804 1.49994 17.2244 1.49994 16.9124 1.49994H9.0884C8.7764 1.49994 8.61919 1.49994 8.49199 1.51314C7.90219 1.57631 7.35668 1.85566 6.96069 2.2973C6.5647 2.73894 6.34629 3.31158 6.34758 3.90475C6.34758 4.03315 6.36438 4.18915 6.39918 4.49995"
                  stroke={color}
                  strokeWidth="1.5"
            />
            <path
                d="M16.6004 20.7H9.40039"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M1.4612 15.6516C0.924795 11.8548 0.657193 9.95755 1.7948 8.72754C2.9324 7.49994 4.95801 7.49994 9.00683 7.49994H16.9941C21.0429 7.49994 23.0685 7.49994 24.2061 8.72874C25.3425 9.95755 25.0749 11.8548 24.5397 15.6516L24.0333 19.2516C23.6133 22.2276 23.4033 23.7168 22.3269 24.6084C21.2505 25.5 19.6629 25.5 16.4865 25.5H9.51443C6.33922 25.5 4.75041 25.5 3.67401 24.6084C2.5976 23.7168 2.3876 22.2288 1.9676 19.2516L1.4612 15.6516Z"
                stroke={color}
                strokeWidth="1.5" />
        </svg>
    );
};

export default LibraryIcon;