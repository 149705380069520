import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import ApplePayLogo from '../../../assets/images/apple-pay.svg';
import GooglePayLogo from '../../../assets/images/google-pay.svg';
import { getPaymentMethodName } from '../../../utils/payment';
import {
    Customer,
    getConfirmSetupLoading,
    getCustomerInfo,
    getStripePromise,
    setupIntent,
} from '../../../state/modules/payment';
import { NEUTRAL_100, PURPLE_PRIMARY, WHITE_PRIMARY } from '../../../styles/colors';
import { PaymentForm } from '../SelectPlanModal/PaymentForm';

const Container = styled.div`
    flex: 3 1 0;
    padding-left: 1.5rem;
    padding-top: 2.25rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
`;

const PaymentFormContainer = styled.div`
    //margin-top: 0.5rem;
`;

const PaymentMethodContainer = styled.div`
    background-color: ${WHITE_PRIMARY};
    padding: 14px;
    height: 100px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid ${NEUTRAL_100};
    //margin-top: 20px;

    @media (max-height: 860px) {
        padding: 10px;
        height: 95px;
    }
`;

const PaymentMethodButton = styled.button`
    font-family: 'Inter Medium', sans-serif;
    font-size: 12px;
    color: ${PURPLE_PRIMARY};
    cursor: pointer;
    border: none;
    background: transparent;
    display: block;
    margin-top: auto;
    text-align: left;

    &:disabled {
        color: rgb(144, 148, 165);
        cursor: not-allowed;
    }
`;

const PaymentMethodDescription = styled.p`
    font-size: 12px;
    font-family: 'Inter Medium', sans-serif;
    color: rgb(144, 148, 165);
    margin-bottom: 10px;
`;

const PaymentMethodTitle = styled.p`
    font-size: 14px;
    font-family: 'Inter Medium', sans-serif;
    color: #212529;
    margin-bottom: 5px;
`;

const PaymentMethodName = styled.span`
    display: flex;
    align-items: center;
    column-gap: 4px;
`;

const CardPaymentMethodBrand = styled.span`
    text-transform: uppercase;
`;

const PaymentMethod = () => {
    const dispatch = useDispatch();

    const customer = useSelector(getCustomerInfo) as Customer;
    const isConfirmSetupLoading = useSelector(getConfirmSetupLoading);
    const stripePromise = useSelector(getStripePromise);

    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const handleEditPaymentMethod = () => {
        // onShowPaymentManagement();
        setClientSecretLoading(true);
        dispatch(
            setupIntent((error, data) => {
                setClientSecretLoading(false);
                if (!error) {
                    setClientSecret(data.clientSecret);
                }
            })
        );
    };

    const handleClosePaymentForm = () => {
        setClientSecret(null);
        // onHidePaymentManagement();
    };

    const renderCurrentPaymentMethod = () => {
        if (!customer?.paymentMethod) return null;
        return (
            <div>
                <PaymentMethodTitle>Payment Method</PaymentMethodTitle>
                <PaymentMethodDescription>
                    {customer.paymentMethod.type === 'card' ? (
                        <PaymentMethodName>
                            {customer.paymentMethod.card?.wallet?.apple_pay && (
                                <img src={ApplePayLogo} alt="Apple pay" />
                            )}
                            {customer.paymentMethod.card?.wallet
                                ?.google_pay && (
                                <img src={GooglePayLogo} alt="Google Pay" />
                            )}
                            <span>
                                <CardPaymentMethodBrand>
                                    {customer.paymentMethod.card?.brand}
                                </CardPaymentMethodBrand>{' '}
                                ending in {customer.paymentMethod.card?.last4}
                            </span>
                        </PaymentMethodName>
                    ) : (
                        <>{getPaymentMethodName(customer.paymentMethod.type)}</>
                    )}
                </PaymentMethodDescription>
            </div>
        );
    };
    
    return (
        <Container>
            {
                clientSecret ? (
                    <PaymentFormContainer>
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret,
                            }}
                        >
                            <PaymentForm
                                handleClose={handleClosePaymentForm}
                                isEditMode
                            />
                        </Elements>
                    </PaymentFormContainer>
                ) : (
                    <PaymentMethodContainer>
                        {renderCurrentPaymentMethod()}
                        <PaymentMethodButton
                            disabled={clientSecretLoading || isConfirmSetupLoading}
                            onClick={handleEditPaymentMethod}
                        >
                            {clientSecretLoading || isConfirmSetupLoading ? (
                                <Loader
                                    type="Oval"
                                    color="rgb(144, 148, 165)"
                                    height={20}
                                    width={20}
                                />
                            ) : (
                                'Edit'
                            )}
                        </PaymentMethodButton>
                    </PaymentMethodContainer>
                )
            }
        </Container>
    );
}

export default PaymentMethod;
