import InputMask from 'react-input-mask';
import { ChangeEvent } from 'react';

import { StripeLogoFooter } from 'components/UI';

import { CardFormInputKey } from 'interfaces/payment';

import styles from './styles.module.scss';

interface Props {
    cardNumber: string;
    cardNumberError: string;
    expiryError: string;
    cvcError: string;
    expiry: string;
    cvc: string;
    changeFieldValue: (
        event: ChangeEvent<HTMLInputElement>,
        field: string
    ) => void;
    vertical?: boolean;
    horizontal?: boolean;
    disabled?: boolean;
    setError: (key: CardFormInputKey, error: string) => void;
}

const AddCreditCardForm = (props: Props): JSX.Element => {
    const {
        cardNumber,
        expiry,
        cvc,
        changeFieldValue,
        vertical,
        horizontal,
        disabled,
        cardNumberError,
        expiryError,
        cvcError,
        setError,
    } = props;

    const handleOnBlur = (key: CardFormInputKey): void => {
        if (key === CardFormInputKey.CARD_NUMBER) {
            if (cardNumber.length < 19) {
                setError(key, 'Card number is not correct');
            }

            if (!cardNumber.length) {
                setError(key, 'Required');
            }
        }
        if (key === CardFormInputKey.EXPIRY) {
            const dateParts = expiry.split('/');
            const expiryMonth = dateParts[0];

            if (expiry.length < 5 || Number(expiryMonth) > 12) {
                setError(key, 'Expiration date is not correct');
            }

            if (!expiry.length) {
                setError(key, 'Required');
            }
        }
        if (key === CardFormInputKey.CVC) {
            if (cvc.length < 3) {
                setError(key, 'Min length 3 digits');
            }

            if (!cvc.length) {
                setError(key, 'Required');
            }
        }
    };

    return (
        <div className={styles.AddCreditCardForm}>
            <form className={styles.AddCreditCardForm__form}>
                <div
                    className={[
                        styles.AddCreditCardForm__formBottomLine,
                        horizontal
                            ? styles.AddCreditCardForm__formBottomLine_horizontal
                            : null,
                        vertical
                            ? styles.AddCreditCardForm__formBottomLine_vertical
                            : null,
                    ].join(' ')}
                >
                    <div className={styles.AddCreditCardForm__inputWrap}>
                        <InputMask
                            mask="9999-9999-9999-9999"
                            onBlur={() =>
                                handleOnBlur(CardFormInputKey.CARD_NUMBER)
                            }
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                changeFieldValue(event, 'cardNumber');
                            }}
                            value={cardNumber}
                            alwaysShowMask
                            // maskChar=" "
                            placeholder="Credit card number"
                            className={styles.AddCreditCardForm__input}
                            disabled={disabled}
                        />
                        {cardNumberError.length ? (
                            <p className={styles.AddCreditCardForm__inputError}>
                                {cardNumberError}
                            </p>
                        ) : null}
                    </div>
                    <div className={styles.AddCreditCardForm__inputsWrap}>
                        <div className={styles.AddCreditCardForm__inputWrap}>
                            <InputMask
                                mask="99/99"
                                onBlur={() =>
                                    handleOnBlur(CardFormInputKey.EXPIRY)
                                }
                                onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                ) => {
                                    changeFieldValue(event, 'expiry');
                                }}
                                value={expiry}
                                // maskChar=" "
                                alwaysShowMask
                                placeholder="MM/YY"
                                className={styles.AddCreditCardForm__input}
                                disabled={disabled}
                            />
                            {expiryError.length ? (
                                <p
                                    className={
                                        styles.AddCreditCardForm__inputError
                                    }
                                >
                                    {expiryError}
                                </p>
                            ) : null}
                        </div>
                        <div className={styles.AddCreditCardForm__inputWrap}>
                            <InputMask
                                mask="9999"
                                onBlur={() =>
                                    handleOnBlur(CardFormInputKey.CVC)
                                }
                                onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                ) => changeFieldValue(event, 'cvc')}
                                value={cvc}
                                alwaysShowMask
                                placeholder="CVC"
                                disabled={disabled}
                                className={styles.AddCreditCardForm__input}
                            />
                            {cvcError.length ? (
                                <p
                                    className={
                                        styles.AddCreditCardForm__inputError
                                    }
                                >
                                    {cvcError}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            </form>

            <div className={styles.AddCreditCardForm__footer}>
                <div className={styles.AddCreditCardForm__paymentSystems} />
                <StripeLogoFooter />
            </div>
        </div>
    );
};

export default AddCreditCardForm;
