import styled from 'styled-components';
import { useMemo, useState } from 'react';

import { Icon } from 'components/UI';
import FolderTreeItem from 'components/FolderTreeItem';

import { Folder } from 'interfaces/folder';

const Title = styled.p`
    margin-left: 10px;
`;

const Toggle = styled.div<{ isOpen: boolean }>`
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Content = styled.div<{ isOpen: boolean }>`
    padding-left: 1rem;
    margin-left: 1.5rem;
    border-left: 1px solid rgb(223, 224, 229);
    height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    visibility: ${({ isOpen }) => (isOpen ? 'initial' : 'hidden')};
    overflow: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};
`;

const Container = styled.div``;

const Header = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    user-select: none;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background: white;
    color: rgb(93, 100, 123);
    cursor: pointer;

    &:hover {
        background: rgb(239, 249, 255);

        ${TitleContainer} {
            color: rgb(0, 152, 253);

            & svg path {
                stroke: rgb(0, 152, 253);
            }
        }

        ${Toggle} {
            & svg path {
                fill: rgb(0, 152, 253);
            }
        }
    }
`;

interface Props {
    onSelect: (folder: Folder | null) => void;
    onConfirmCreateFolder: (folder: Folder) => void;
    selectedFolderId: string;
    onUpdateFoldersTreeItem: (id: string, data: Folder[]) => void;
    foldersTree: {
        [key: string]: Folder[];
    };
    folders: Folder[];
    folderIdToExclude: string;
    hasError?: boolean;
    onClearError?: () => void;
}

const FolderTreeRoot = ({
    onSelect,
    selectedFolderId,
    foldersTree,
    onUpdateFoldersTreeItem,
    folders,
    folderIdToExclude,
    onConfirmCreateFolder,
    hasError,
    onClearError,
}: Props) => {
    const [isOpen, setOpen] = useState(true);

    const toggle = () => {
        setOpen(!isOpen);
        onSelect(null);
    };

    const handleSelect = (obj: Folder) => {
        onSelect(obj);
    };

    const filteredFolders = useMemo(
        () => folders.filter((item) => item.id !== folderIdToExclude),
        [folders, folderIdToExclude]
    );

    const renderItem = (folder: Folder) => {
        const itemFolders = foldersTree?.[folder?.id] || [];

        return (
            <FolderTreeItem
                key={folder.id}
                onSelect={handleSelect}
                folder={folder}
                selectedFolderId={selectedFolderId}
                folders={itemFolders}
                foldersTree={foldersTree}
                onUpdateFoldersTreeItem={onUpdateFoldersTreeItem}
                folderIdToExclude={folderIdToExclude}
                onConfirmCreateFolder={onConfirmCreateFolder}
                hasError={hasError}
                onClearError={onClearError}
            />
        );
    };

    // console.log({ foldersTree });

    const renderContent = () => {
        return (
            <Content isOpen={isOpen}>
                {filteredFolders.map((folder) => renderItem(folder))}
            </Content>
        );
    };

    const renderHeader = () => {
        return (
            <Header onClick={toggle}>
                <TitleContainer>
                    <Icon
                        name="folder-outline"
                        size={20}
                        color="rgb(93, 100, 123)"
                    />
                    <Title>All Projects</Title>
                </TitleContainer>
                <Toggle isOpen={isOpen}>
                    <Icon name="triangle" size={6} />
                </Toggle>
            </Header>
        );
    };

    return (
        <Container>
            {renderHeader()}
            {renderContent()}
        </Container>
    );
};

export default FolderTreeRoot;
