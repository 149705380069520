import sortBy from 'lodash.sortby';
import { v4 as uuid } from 'uuid';

import {
    AnimationInstance,
    InTextAnimationProps,
    InTextAnimationType,
    NewRawProject,
    OutTextAnimationProps,
    OutTextAnimationType,
    Project,
    ProjectAudio,
    ProjectAudioMediaSource,
    ProjectElement,
    ProjectExportPreset,
    ProjectExportPresetSettingsOption,
    ProjectExportQualitySettingsOption,
    ProjectExportResolutionSettingsOption,
    ProjectImage,
    ProjectMediaSourcesSettings,
    ProjectSettingsPreset,
    ProjectSettingsPresetValue,
    ProjectSettingsQuality,
    ProjectSize,
    ProjectSizeOption,
    ProjectSubtitlesSettings,
    ProjectSubtitlesSettingsBox,
    ProjectText,
    ProjectUpload,
    ProjectVideo,
    SubtitleEffect,
    SubtitlesAlign,
    TextLinesValue,
    UploadDetails,
    UploadsUpdateData,
    VideoFilterType,
    VideoTransitionItem,
    VideoTransitionType,
} from 'interfaces/projects';

import { MediaFile, MediaInfoVideo, MediaType, TempMediaFile } from 'interfaces/videos';

import { reducer } from 'utils/calc';
import {
    AUDIO_TRACKS_LIMIT,
    mediasToTrackMediasAdapter,
    secondsToFrameNumber,
    TIMELINE_DURATION,
    TIMELINE_FRAME_RATE,
    VIDEO_TRACKS_LIMIT,
} from './timeline';
import { SentenceDataItem, TransformedSentence } from '../state/modules/metadata';
import { StatusType } from '../interfaces/statuses';

// import fadeImg from 'assets/images/transitions/fade.gif';
// import fadeBlackImg from 'assets/images/transitions/fadeblack.gif';
// import fadeWhiteImg from 'assets/images/transitions/fadewhite.gif';
// import distanceImg from 'assets/images/transitions/distance.gif';

// import wipeLeftImg from 'assets/images/transitions/wipeleft.gif';
// import wipeRightImg from 'assets/images/transitions/wiperight.gif';
// import wipeUpImg from 'assets/images/transitions/wipeup.gif';
// import wipeDownImg from 'assets/images/transitions/wipedown.gif';

// import slideLeftImg from 'assets/images/transitions/slideleft.gif';
// import slideRightImg from 'assets/images/transitions/slideright.gif';
// import slideUpImg from 'assets/images/transitions/slideup.gif';
// import slideDownImg from 'assets/images/transitions/slidedown.gif';

// import smoothLeftImg from 'assets/images/transitions/smoothleft.gif';
// import smoothRightImg from 'assets/images/transitions/smoothright.gif';
// import smoothUpImg from 'assets/images/transitions/smoothup.gif';
// import smoothDownImg from 'assets/images/transitions/smoothdown.gif';

// import rectCropImg from 'assets/images/transitions/rectcrop.gif';
// import circleCropImg from 'assets/images/transitions/circlecrop.gif';
// import circleCloseImg from 'assets/images/transitions/circleclose.gif';
// import circleOpenImg from 'assets/images/transitions/circleopen.gif';

// import horzCloseImg from 'assets/images/transitions/horzclose.gif';
// import horzOpenImg from 'assets/images/transitions/horzopen.gif';
// import vertCloseImg from 'assets/images/transitions/vertclose.gif';
// import vertOpenImg from 'assets/images/transitions/vertopen.gif';

// import diagBlImg from 'assets/images/transitions/diagbl.gif';
// import diagBrImg from 'assets/images/transitions/diagbr.gif';
// import diagTlImg from 'assets/images/transitions/diagtl.gif';
// import diagTrImg from 'assets/images/transitions/diagtr.gif';

// import hlSliceImg from 'assets/images/transitions/hlslice.gif';
// import hrSliceImg from 'assets/images/transitions/hrslice.gif';
// import vuSliceImg from 'assets/images/transitions/vuslice.gif';
// import vdSliceImg from 'assets/images/transitions/vdslice.gif';

// import dissolveImg from 'assets/images/transitions/dissolve.gif';
// import pixelizeImg from 'assets/images/transitions/pixelize.gif';
// import radialImg from 'assets/images/transitions/radial.gif';
// import hBlurImg from 'assets/images/transitions/hblur.gif';

// import wipetlImg from 'assets/images/transitions/wipetl.gif';
// import wipetrImg from 'assets/images/transitions/wipetr.gif';
// import wipeblImg from 'assets/images/transitions/wipebl.gif';
// import wipebrImg from 'assets/images/transitions/wipebr.gif';

// import fadegraysImg from 'assets/images/transitions/fadegrays.gif';
// import squeezevImg from 'assets/images/transitions/squeezev.gif';
// import squeezehImg from 'assets/images/transitions/squeezeh.gif';
// import zoominImg from 'assets/images/transitions/zoomin.gif';

export const projectExportPresets = [
    {
        resolution: '852:480',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.VERY_FAST,
        fps: 30,
        label: 'Draft',
        value: ProjectSettingsPresetValue.DRAFT,
        description: 'Low quality for quick render times and small files',
    },
    {
        resolution: '1280:720',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.FAST,
        fps: 30,
        label: 'Standard',
        value: ProjectSettingsPresetValue.STANDART,
        description: 'Tradeoff between quality and render times',
    },
    {
        resolution: '1920:1080',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.MEDIUM,
        fps: 30,
        label: 'HD',
        value: ProjectSettingsPresetValue.HD,
        description: 'Default: High quality with longer render times',
    },
    {
        resolution: '1920:1080',
        quality: ProjectSettingsQuality.HIGH,
        preset: ProjectSettingsPreset.MEDIUM,
        fps: 60,
        label: 'YouTube',
        value: ProjectSettingsPresetValue.YOUTUBE,
        description: 'Uses Youtube recommended settings for 1080p 60fps videos',
    },
    {
        resolution: '1080:1350',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.FAST,
        fps: 30,
        label: 'Instagram (In Feed video)',
        value: ProjectSettingsPresetValue.INSTAGRAM,
        description: 'Uses Instagram recommended settings',
    },
    {
        resolution: '1280:720',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.FAST,
        fps: 30,
        label: 'Hootsuite Twitter',
        value: ProjectSettingsPresetValue.TWITTER,
        description: 'Uses hootsuite recommended settings for twitter',
    },
    {
        resolution: '1280:720',
        quality: ProjectSettingsQuality.STANDARD,
        preset: ProjectSettingsPreset.FAST,
        fps: 30,
        label: 'Custom',
        value: ProjectSettingsPresetValue.CUSTOM,
    },
] as ProjectExportPreset[];

export const projectExportResolutionSettingsOptions = [
    {
        label: '1080p',
        value: '1920:1080',
    },
    {
        label: 'Instagram (1080 x 1350)',
        value: '1080:1350',
    },
    {
        label: 'Twitter (720p)',
        value: '1280:720',
    },
] as ProjectExportResolutionSettingsOption[];

export const projectExportPresetSettingsOptions = [
    {
        label: 'Balanced settings',
        value: ProjectSettingsPreset.FAST,
    },
    {
        label: 'Faster Render',
        value: ProjectSettingsPreset.VERY_FAST,
    },
    {
        label: 'Higher Quality',
        value: ProjectSettingsPreset.MEDIUM,
    },
] as ProjectExportPresetSettingsOption[];

export const projectExportQualitySettingsOptions = [
    {
        label: 'Standard',
        value: ProjectSettingsQuality.STANDARD,
    },
    {
        label: 'Low',
        value: ProjectSettingsQuality.LOW,
    },
    {
        label: 'High',
        value: ProjectSettingsQuality.HIGH,
    },
] as ProjectExportQualitySettingsOption[];

export const checkEmptyVideos = (
    videos: ProjectVideo[],
    duration: number
): ProjectVideo[] => {
    const filteredVideos = videos.filter((video) => !video?.isDummy);

    const array: ProjectVideo[] = [...filteredVideos];

    const sortedVideos = sortBy(filteredVideos, (video) => video.startTime);
    const emptyAreas: ProjectVideo[] = [];

    sortedVideos.forEach((video, index) => {
        const nextVideo = sortedVideos[index + 1];

        if (index === 0 && video.startTime > 0) {
            emptyAreas.push({
                ...video,
                // id: uuid(),
                id: `dummy-${video.startTime}`,
                isDummy: true,
                startTime: 0,
                trimStart: 0,
                endTime: video.startTime,
                trimEnd: Number(video.startTime.toFixed(2)),
            });
        }

        // if (nextVideo && nextVideo.startTime !== video.endTime) {
        if (
            nextVideo &&
            nextVideo?.startTime &&
            video?.endTime &&
            Number(nextVideo.startTime.toFixed(3)) !==
                Number(video.endTime.toFixed(3))
        ) {
            // console.log('2', index + 1);
            emptyAreas.push({
                ...video,
                // id: uuid(),
                id: `dummy-${nextVideo.startTime}`,
                isDummy: true,
                startTime: video.endTime,
                trimStart: 0,
                endTime: nextVideo.startTime,
                trimEnd: Number(
                    (nextVideo.startTime - video.endTime).toFixed(2)
                ),
            });
        }
    });

    array.push(...emptyAreas);

    const lastVideo = sortedVideos[sortedVideos.length - 1];

    if (lastVideo) {
        if (lastVideo.endTime < duration) {
            array.push({
                ...lastVideo,
                // id: uuid(),
                isDummy: true,
                id: `dummy-last`,
                startTime: lastVideo.endTime,
                trimStart: 0,
                endTime: duration,
                trimEnd: Number((duration - lastVideo.endTime).toFixed(2)),
            });
        }
    } else {
        array.push({
            id: `dummy-0`,
            isDummy: true,
            startTime: 0,
            trimStart: 0,
            endTime: duration,
            trimEnd: 0,
            mediaSourcesId: '',
            name: '',
            videoVolume: 0,
            speed: 0,
            path: '',
            zIndex: 1,
            mediaInfo: {
                aspectRatio: '',
                bitDepth: 0,
                bitrate: 0,
                codec: '',
                colorSpace: '',
                duration,
                frameCount: duration * TIMELINE_FRAME_RATE,
                framerate: TIMELINE_FRAME_RATE,
                height: 0,
                profile: '',
                scanType: '',
                width: 0,
            },
        });
    }

    return array;
};

export interface VideoOption {
    id: string;
    value: string;
    label: string;
    data: MediaFile | null;
}

export const transformFilesToProjectVideos = (
    media: MediaFile[] | TempMediaFile[],
    replaceableMedia?: ProjectVideo,
): ProjectVideo[] => {
    const transformedVideos = [] as ProjectVideo[];

    media.forEach((video: any, index: number) => {
        let startTime = 0;

        if (index > 0) {
            startTime = transformedVideos[index - 1].endTime;
        }

        const mediaInfo = video.mediaInfo.proxy || video.mediaInfo.original;
        const videoMediaInfo = mediaInfo?.video?.[0] as MediaInfoVideo;
        const duration =
            videoMediaInfo?.duration || mediaInfo?.container?.duration;

        let endTime = startTime + duration;
        let trimEnd = duration;
        let isShorterThanReplaceableMedia = false;
        let canvasProps = {};

        if(replaceableMedia) {
            isShorterThanReplaceableMedia = (replaceableMedia.endTime - replaceableMedia.startTime) > duration;
        }

        if(replaceableMedia) {
            startTime = replaceableMedia?.startTime || startTime;
            endTime = isShorterThanReplaceableMedia ?
                (startTime + duration) : replaceableMedia.endTime;
            trimEnd = endTime - startTime;
            if('canvas' in replaceableMedia && replaceableMedia?.canvas) {
                canvasProps = {
                    canvas: replaceableMedia.canvas
                };
            }
        }

        transformedVideos.push({
            mediaSourcesId: video.id,
            name: video.title,
            startTime,
            trimStart: 0,
            endTime,
            trimEnd,
            id: uuid(),
            volume: true,
            mediaInfo: videoMediaInfo || {
                aspectRatio: 1.777,
                duration,
                frameCount: Math.ceil(duration * 30),
                framerate: 30,
                height: 1080,
                width: 1920,
            },
            isAudio: video.mediaType === MediaType.AUDIO,
            zIndex: replaceableMedia ? replaceableMedia?.zIndex : 1,
            ...canvasProps
        } as any);
    });

    return transformedVideos;
};

export const transformMediaFilesToProjectVideos = (
    videos: MediaFile[] | TempMediaFile[],
    replaceableMedia?: ProjectVideo
): ProjectVideo[] => {
    const uploadingAudios = videos.filter(
        item => item.mediaType === MediaType.AUDIO
    );
    const uploadingVideos = videos.filter(
        item => item.mediaType === MediaType.VIDEO
    );

    const transformedAudios = transformFilesToProjectVideos(uploadingAudios, replaceableMedia);
    const transformedVideos = transformFilesToProjectVideos(uploadingVideos, replaceableMedia);

    return [...transformedAudios, ...transformedVideos];
};

export const detachProjectAudioFromProjectVideo = (
    selectedVideo: ProjectVideo,
    audioMediaSource: ProjectAudioMediaSource
): ProjectAudio => ({
    volume: 1,
    videoId: selectedVideo.id,
    startTime: selectedVideo.startTime,
    trimStart: selectedVideo.trimStart,
    endTime: selectedVideo.endTime,
    trimEnd: selectedVideo.trimEnd,
    s3AudioPath: audioMediaSource.s3Path,
    id: uuid(),
    speed: 1,
    mediaSourcesId: audioMediaSource.id,
    mediaInfo: audioMediaSource.mediaInfo,
    name: selectedVideo.name,
    projectTrackId: uuid(),
    enhanced: !!selectedVideo?.enhanced,
    enhancedVolume: selectedVideo?.enhancedVolume || 0,
    isActive: true,
});

export const transformAudiMediaFilesToProjectAudios = (
    audioMediaFiles: ProjectAudioMediaSource[]
): ProjectAudio[] => {
    const transformedAudios = [] as ProjectAudio[];

    audioMediaFiles.forEach(
        (mediaFile: ProjectAudioMediaSource, index: number) => {
            let startTime = 0;

            if (index > 0) {
                startTime = transformedAudios[index - 1].endTime;
            }

            const { mediaInfo } = mediaFile;
            const endTime = startTime + mediaInfo.duration;

            transformedAudios.push({
                volume: 1,
                startTime,
                trimStart: 0,
                endTime,
                trimEnd: mediaInfo.duration,
                s3AudioPath: '',
                id: uuid(),
                speed: 1,
                mediaSourcesId: '',
                mediaInfo: mediaFile.mediaInfo,
                name: '',
                videoId: '',
                projectTrackId: uuid(),
            });
        }
    );

    return transformedAudios;
};

export const adaptAddedVideos = (
    oldVideos: ProjectVideo[],
    newVideos: ProjectVideo[],
    updatedProjectVideos: ProjectVideo[]
) => {
    const adaptedVideos = [...updatedProjectVideos];

    const videos = oldVideos.filter(item => !item.isAudio);
    const audios = oldVideos.filter(item => item.isAudio);

    const videoTracks = mediasToTrackMediasAdapter(videos);
    const audioTracks = mediasToTrackMediasAdapter(audios);

    const videoTrackKeys = Object.keys(videoTracks).sort((a, b) => +b - +a);
    const audioTrackKeys = Object.keys(audioTracks).sort((a, b) => +b - +a);

    const videoTopKey = Number.isNaN(+videoTrackKeys[0]) ? 1 : +videoTrackKeys[0];
    const audioTopKey = Number.isNaN(+audioTrackKeys[0]) ? 1 : +audioTrackKeys[0];

    const newTrackVideos = [] as ProjectVideo[];

    const lastTrackVideoItems = oldVideos.filter(
        item => !item.isAudio && (item.zIndex === videoTopKey)
    );
    const lastTrackAudioItems = oldVideos.filter(
        item => item.isAudio && (item.zIndex === audioTopKey)
    );


    const lastVideo = lastTrackVideoItems[lastTrackVideoItems.length - 1];
    const lastAudio = lastTrackAudioItems[lastTrackAudioItems.length - 1];

    for(let i = 0; i < newVideos.length; i++) {
        const currMedia = newVideos[i];

        if(currMedia.isAudio) {
            if(audioTrackKeys.length < AUDIO_TRACKS_LIMIT) {
                const newLastVideo = newTrackVideos[newTrackVideos.length - 1];
                const startTime = !newLastVideo ? currMedia.startTime :
                    newLastVideo?.endTime;
                const newMedia = {
                    ...currMedia,
                    startTime,
                    endTime: startTime + (currMedia.endTime - currMedia.startTime),
                    zIndex: audioTopKey + 1,
                };

                newTrackVideos.push(newMedia);
                adaptedVideos.push(newMedia);
            } else {
                const newMedia = {
                    ...currMedia,
                    startTime: currMedia.startTime + (lastAudio?.endTime || 0),
                    endTime: currMedia.endTime + (lastAudio?.endTime || 0),
                    zIndex: audioTopKey,
                };

                adaptedVideos.push(newMedia);
            }
        } else {
            if(videoTrackKeys.length < VIDEO_TRACKS_LIMIT) {
                const newLastVideo = newTrackVideos[newTrackVideos.length - 1];
                const startTime = !newLastVideo ? currMedia.startTime :
                    newLastVideo?.endTime;
                const newMedia = {
                    ...currMedia,
                    startTime,
                    endTime: startTime + (currMedia.endTime - currMedia.startTime),
                    zIndex: videoTopKey + 1,
                };

                newTrackVideos.push(newMedia);
                adaptedVideos.push(newMedia);
            } else {
                const newMedia = {
                    ...currMedia,
                    startTime: currMedia.startTime + (lastVideo?.endTime || 0),
                    endTime: currMedia.endTime + (lastVideo?.endTime || 0),
                    zIndex: videoTopKey,
                };

                adaptedVideos.push(newMedia);
            }
        }
    }
    return adaptedVideos;
}

export const defaultProjectSubtitlesBoxSettings = {
    borderWidth: 8,
    round: 6,
} as ProjectSubtitlesSettingsBox;

export const defaultProjectSubtitlesSettings = {
    subtitlesColor: '#ffffff',
    subtitlesFontSize: 32,
    translationY: 0.85,
    translationX: 0.5,
    angle: 0,
    box: defaultProjectSubtitlesBoxSettings,
    width: 0.8,
    align: SubtitlesAlign.CENTER,
    subtitleEffect: SubtitleEffect.BLOCK,
    round: 4,
    fontFamily: 'Roboto',
    spacing: 0,
    lineHeight: 1,
    outline: 0,
    outlineColor: '#000000',
    shadow: 0,
    shadowColor: '#00000055',
    textLines: TextLinesValue.NONE,
    fontWeight: 400,
} as ProjectSubtitlesSettings;

export const getPreparedNewProjectData = (
    videos: MediaFile[] | TempMediaFile[],
    mediaSources: string[],
    name: string,
    workspaceId: string,
    isDefault = false
): NewRawProject => {
    const transformedVideos = transformMediaFilesToProjectVideos(
        videos
    ) as ProjectVideo[];

    const videosDurationArray = transformedVideos.map((item) => item.trimEnd);
    let totalProjectDuration;
    if (isDefault) {
        totalProjectDuration = TIMELINE_DURATION;
    } else {
        totalProjectDuration = videosDurationArray.reduce(reducer, 0);
    }

    let language = '';

    if (videos.length > 0) {
        language = videos[0].defaultLanguage || '';
    }

    const mediaSourcesSettings = videos.map((video) => ({
        mediaSourceId: video.id || '',
        language: video.defaultLanguage || video.language || '',
    })) as ProjectMediaSourcesSettings[];

    const data = {
        name: name?.length ? name : 'Untitled project',
        mediaSources,
        videos: transformedVideos,
        images: [],
        audios: [],
        texts: [],
        transcript: {
            deletedSentencesIds: [],
            createdSentencesIds: [],
            createdSentencesObjects: [],
            deletedSentencesObjects: [],
        },
        duration: totalProjectDuration,
        language,
        settings: {
            subtitles: defaultProjectSubtitlesSettings,
            video: {
                resolution: projectExportPresets[1].resolution,
                quality: projectExportPresets[1].quality,
                preset: projectExportPresets[1].preset,
                fps: projectExportPresets[1].fps,
                settingsPreset: projectExportPresets[1].value,
                bgColor: '#000000',
            },
        },
        mediaSourcesSettings,
        aspectRatio: ProjectSize.ORIGINAL,
        videoVolume: 1,
        speed: 1,
        isProjectDurationAutomatic: true,
        workspaceId,
        sentences: [],
    } as NewRawProject;

    return data;
};

export const getStockMediaPreviewTimestamps = (video: ProjectVideo) => {
    const mediaInfo = video.mediaInfo;
    const timestampsArray = [] as number[];

    const { frameCount, framerate, duration } = mediaInfo;

    const count = duration > 20 ? 20 : Math.trunc(duration);

    const framesInOneImage = frameCount / count;

    let currentFramesCount = 0;

    for (let index = 0; index < count; index++) {
        if (index === 0) {
            timestampsArray.push(0.01);
        } else {
            timestampsArray.push(currentFramesCount / framerate);
        }

        currentFramesCount += framesInOneImage;
    }

    return {
        id: video.mediaSourcesId,
        timestamps: timestampsArray,
    };
};

export const getMediaSourcesPreviewTimestamps = (
    mediaSource: MediaFile | TempMediaFile
) => {
    const mediaInfo =
        mediaSource?.mediaInfo?.proxy || mediaSource?.mediaInfo?.original;

    if (mediaInfo?.video?.length) {
        const mediaInfoVideoDetails = mediaInfo.video[0];
        const timestampsArray = [] as number[];

        const { frameCount, framerate, duration } = mediaInfoVideoDetails;

        const count = duration > 20 ? 20 : Math.trunc(duration);

        const framesInOneImage = frameCount / count;

        let currentFramesCount = 0;

        for (let index = 0; index < count; index++) {
            if (index === 0) {
                timestampsArray.push(0.01);
            } else {
                timestampsArray.push(currentFramesCount / framerate);
            }

            currentFramesCount += framesInOneImage;
        }

        return {
            id: mediaSource.id,
            timestamps: timestampsArray,
            path: mediaSource.s3Path,
        };
    }
};

export const getMediaSourcesPreviewsTimestamps = (
    mediaSources: MediaFile[] | TempMediaFile[]
) => {
    const mediaSourcesPreviewsTimestamps = [] as any;

    mediaSources.forEach((mediaSource) => {
        const timestamps = getMediaSourcesPreviewTimestamps(mediaSource);

        mediaSourcesPreviewsTimestamps.push(timestamps);
    });

    return mediaSourcesPreviewsTimestamps;
};

export const getStockMediasPreviewsTimestamps = (videos: ProjectVideo[]) => {
    const previewsTimestamps = [] as any;

    videos.forEach((video) => {
        const timestamps = getStockMediaPreviewTimestamps(video);

        previewsTimestamps.push(timestamps);
    });

    return previewsTimestamps;
};

export const calculateProjectGenerationTime = ({
    projectVideos,
    isSubtitling,
    mediaSources,
}: {
    projectVideos: ProjectVideo[];
    isSubtitling: boolean;
    mediaSources: TempMediaFile[] | MediaFile[];
}): number => {
    let projectCalculationTime = 180; // batch start

    for (const video of projectVideos) {
        if (isSubtitling) {
            projectCalculationTime += video.mediaInfo.duration;
        }

        const videoMediaSource = mediaSources.find(
            (mediaSource) => mediaSource.id === video.mediaSourcesId
        );

        const mediaSourceDuration =
            videoMediaSource?.mediaInfo?.proxy?.video?.[0]?.duration || 0;

        const downloadVideoTime: number = (mediaSourceDuration / 100) * 1; // get media source 1%

        const videoGenerationTime: number =
            (video.endTime - video.startTime) / 2; // half of video duration

        projectCalculationTime += downloadVideoTime + videoGenerationTime;
    }

    return projectCalculationTime;
};

export const projectSizeOptions = [
    {
        label: 'Original',
        value: ProjectSize.ORIGINAL,
        icon: 'original-size',
    },
    {
        // label: 'Square',
        label: 'Instagram post',
        value: ProjectSize.SQUARE,

        ratio: {
            width: 1,
            height: 1,
        },
    },
    {
        icon: 'youtube',
        label: 'Youtube',
        value: ProjectSize.YOUTUBE,
        ratio: {
            width: 16,
            height: 9,
        },
    },
    {
        label: 'Landscape',
        icon: 'landscape',
        value: ProjectSize.LANDSCAPE,
        ratio: {
            width: 16,
            height: 9,
        },
    },
    {
        label: 'Twitter & Facebook Portrait',
        value: ProjectSize.TWITTER_AND_FACEBOOK_PORTRAITE,
        ratio: {
            width: 4,
            height: 5,
        },
    },
    {
        label: 'Twitter & Facebook Landscape',
        value: ProjectSize.TWITTER_AND_FACEBOOK_LANDSCAPE,
        ratio: {
            width: 5,
            height: 4,
        },
    },
    {
        label: 'Instagram Story',
        value: ProjectSize.INSTAGRAM_STORY,
        ratio: {
            width: 9,
            height: 16,
        },
    },
    {
        icon: 'tiktok-second',
        label: 'TikTok',
        value: ProjectSize.TIKTOK,
        ratio: {
            width: 9,
            height: 16,
        },
    },
    {
        label: 'Facebook Cover',
        value: ProjectSize.FACEBOOK_COVER,
        ratio: {
            width: 820,
            height: 312,
        },
    },
    {
        label: 'Pinterest',
        value: ProjectSize.PINTEREST,
        ratio: {
            width: 2,
            height: 3,
        },
    },
    {
        label: 'LinkedIn Landscape',
        value: ProjectSize.LINKEDIN_LANDSCAPE,
        ratio: {
            width: 2.4,
            height: 1,
        },
    },
    {
        label: 'LinkedIn Portrait',
        value: ProjectSize.LINKEDIN_PORTRAITE,
        ratio: {
            width: 1,
            height: 2.4,
        },
    },
] as ProjectSizeOption[];

export const calculateCurrentProjectDuration = (
    videos: ProjectVideo[],
    audios: ProjectAudio[]
): number => {
    const videosDurationArr = videos.map((video) => video.mediaInfo.duration);
    const autiodiosDurationArr = audios.map(
        (video) => video.mediaInfo.duration
    );

    const videosDuration = videosDurationArr.reduce(reducer, 0);
    const audiosDuration = autiodiosDurationArr.reduce(reducer, 0);

    return videosDuration > audiosDuration ? videosDuration : audiosDuration;
};

export const getSplittedProjectVideos = (
    selectedVideo: ProjectVideo,
    timelineTime: number
): ProjectVideo[] => {
    const firstVideoDuration = timelineTime - selectedVideo.startTime;
    const firstVideoStartTime = selectedVideo.startTime;
    const firstVideoEndTime = timelineTime;
    const firstVideoTrimStart = selectedVideo.trimStart;
    const firstVideoTrimEnd = firstVideoTrimStart + firstVideoDuration;

    const secondVideoStartTime = timelineTime;
    const secondVideoEndTime = selectedVideo.endTime;
    const secondVideoTrimStart = firstVideoTrimEnd;
    const secondVideoTrimEnd = selectedVideo.trimEnd;

    const firstVideoPart = {
        ...selectedVideo,
        startTime: firstVideoStartTime,
        endTime: firstVideoEndTime,
        trimStart: firstVideoTrimStart,
        trimEnd: firstVideoTrimEnd,
    } as ProjectVideo;

    const secondVideoPart = {
        ...selectedVideo,
        id: uuid(),
        startTime: secondVideoStartTime,
        endTime: secondVideoEndTime,
        trimStart: secondVideoTrimStart,
        trimEnd: secondVideoTrimEnd,
    } as ProjectVideo;

    return [firstVideoPart, secondVideoPart];
};

export const getSplittedProjectText = (
    selectedText: ProjectText,
    timelineTime: number
): ProjectText[] => {
    const firstTextItemStartTime = selectedText.startTime;
    const firstTextItemEndTime = timelineTime;
    const secondTextItemStartTime = timelineTime;
    const secondTextItemEndTime = selectedText.endTime;

    const firstTextItem = {
        ...selectedText,
        id: uuid(),
        startTime: firstTextItemStartTime,
        endTime: firstTextItemEndTime,
    } as ProjectText;

    const secondTextItem = {
        ...selectedText,
        id: uuid(),
        startTime: secondTextItemStartTime,
        endTime: secondTextItemEndTime,
    } as ProjectText;

    return [firstTextItem, secondTextItem];
};

export const getSplittedProjectImages = (
    selectedImage: ProjectImage,
    timelineTime: number
): ProjectImage[] => {
    const firstImageStartTime = selectedImage.startTime;
    const firstImageEndTime = timelineTime;
    const secondImageStartTime = timelineTime;
    const secondImageEndTime = selectedImage.endTime;

    const firstImage = {
        ...selectedImage,
        id: uuid(),
        startTime: firstImageStartTime,
        endTime: firstImageEndTime,
    } as ProjectImage;

    const secondImage = {
        ...selectedImage,
        id: uuid(),
        startTime: secondImageStartTime,
        endTime: secondImageEndTime,
    } as ProjectImage;

    return [firstImage, secondImage];
};

export const getSplittedProjectElements = (
    selectedElement: ProjectElement,
    timelineTime: number
): ProjectElement[] => {
    const firstElementStartTime = selectedElement.startTime;
    const firstElementEndTime = timelineTime;
    const secondElementStartTime = timelineTime;
    const secondElementEndTime = selectedElement.endTime;

    const firstElement = {
        ...selectedElement,
        id: uuid(),
        startTime: firstElementStartTime,
        endTime: firstElementEndTime,
    } as ProjectElement;

    const secondElement = {
        ...selectedElement,
        id: uuid(),
        startTime: secondElementStartTime,
        endTime: secondElementEndTime,
    } as ProjectElement;

    return [firstElement, secondElement];
};

export const getSplittedProjectAudios = (
    selectedAudio: ProjectAudio,
    timelineTime: number
): ProjectAudio[] => {
    const firstVideoDuration = timelineTime - selectedAudio.startTime;
    const firstVideoStartTime = selectedAudio.startTime;
    const firstVideoEndTime = timelineTime;
    const firstVideoTrimStart = selectedAudio.trimStart;
    const firstVideoTrimEnd = firstVideoTrimStart + firstVideoDuration;

    const secondVideoStartTime = timelineTime;
    const secondVideoEndTime = selectedAudio.endTime;
    const secondVideoTrimStart = firstVideoTrimEnd;
    const secondVideoTrimEnd = selectedAudio.trimEnd;

    const firstAudioPart = {
        ...selectedAudio,
        startTime: firstVideoStartTime,
        endTime: firstVideoEndTime,
        trimStart: firstVideoTrimStart,
        trimEnd: firstVideoTrimEnd,
    } as ProjectAudio;

    const secondAudioPart = {
        ...selectedAudio,
        id: uuid(),
        startTime: secondVideoStartTime,
        endTime: secondVideoEndTime,
        trimStart: secondVideoTrimStart,
        trimEnd: secondVideoTrimEnd,
    } as ProjectAudio;

    return [firstAudioPart, secondAudioPart];
};

export const videoTransitionsList = [
    // {
    //     type: VideoTransitionType.FADE,
    //     preview: fadeImg,
    // },
    {
        type: VideoTransitionType.FADE_BLACK,
        // preview: fadeBlackImg,
    },
    {
        type: VideoTransitionType.FADE_WHITE,
        // preview: fadeWhiteImg,
    },
    // {
    //     type: VideoTransitionType.DISTANCE,
    //     preview: distanceImg,
    // },
    {
        type: VideoTransitionType.WIPE_LEFT,
        // preview: wipeLeftImg,
    },
    {
        type: VideoTransitionType.WIPE_RIGHT,
        // preview: wipeRightImg,
    },
    {
        type: VideoTransitionType.WIPE_UP,
        // preview: wipeUpImg,
    },
    {
        type: VideoTransitionType.WIPE_DOWN,
        // preview: wipeDownImg,
    },
    {
        type: VideoTransitionType.SLIDE_LEFT,
        // preview: slideLeftImg,
    },
    {
        type: VideoTransitionType.SLIDE_RIGHT,
        // preview: slideRightImg,
    },
    {
        type: VideoTransitionType.SLIDE_UP,
        // preview: slideUpImg,
    },
    {
        type: VideoTransitionType.SLIDE_DOWN,
        // preview: slideDownImg,
    },
    // {
    //     type: VideoTransitionType.SMOOTH_LEFT,
    //     preview: smoothLeftImg,
    // },
    // {
    //     type: VideoTransitionType.SMOOTH_RIGHT,
    //     preview: smoothRightImg,
    // },
    // {
    //     type: VideoTransitionType.SMOOTH_UP,
    //     preview: smoothUpImg,
    // },
    // {
    //     type: VideoTransitionType.SMOOTH_DOWN,
    //     preview: smoothDownImg,
    // },
    {
        type: VideoTransitionType.RECT_CROP,
        // preview: rectCropImg,
    },
    {
        type: VideoTransitionType.CIRCLE_CROP,
        // preview: circleCropImg,
    },
    // {
    //     type: VideoTransitionType.CIRCLE_CLOSE,
    //     preview: circleCloseImg,
    // },
    // {
    //     type: VideoTransitionType.CIRCLE_OPEN,
    //     preview: circleOpenImg,
    // },
    // {
    //     type: VideoTransitionType.HORZ_CLOSE,
    //     preview: horzCloseImg,
    // },
    // {
    //     type: VideoTransitionType.HORZ_OPEN,
    //     preview: horzOpenImg,
    // },
    // {
    //     type: VideoTransitionType.VERT_CLOSE,
    //     preview: vertCloseImg,
    // },
    // {
    //     type: VideoTransitionType.VERT_OPEN,
    //     preview: vertOpenImg,
    // },
    // {
    //     type: VideoTransitionType.DIABGL,
    //     preview: diagBlImg,
    // },
    // {
    //     type: VideoTransitionType.DIAGBR,
    //     preview: diagBrImg,
    // },
    // {
    //     type: VideoTransitionType.DIAGTL,
    //     preview: diagTlImg,
    // },
    // {
    //     type: VideoTransitionType.DIAGTR,
    //     preview: diagTrImg,
    // },
    // {
    //     type: VideoTransitionType.HL_SLICE,
    //     preview: hlSliceImg,
    // },
    // {
    //     type: VideoTransitionType.HR_SLICE,
    //     preview: hrSliceImg,
    // },
    // {
    //     type: VideoTransitionType.VU_SLICE,
    //     preview: vuSliceImg,
    // },
    // {
    //     type: VideoTransitionType.VD_SLICE,
    //     preview: vdSliceImg,
    // },
    // {
    //     type: VideoTransitionType.DISSOLVE,
    //     preview: dissolveImg,
    // },
    // {
    //     type: VideoTransitionType.PIXELIZE,
    //     preview: pixelizeImg,
    // },
    // {
    //     type: VideoTransitionType.RADIAL,
    //     preview: radialImg,
    // },
    // {
    //     type: VideoTransitionType.H_BLUR,
    //     preview: hBlurImg,
    // },
    // {
    //     type: VideoTransitionType.WIPE_TL,
    //     preview: wipetlImg,
    // },
    // {
    //     type: VideoTransitionType.WIPE_TR,
    //     preview: wipetrImg,
    // },
    // {
    //     type: VideoTransitionType.WIPE_BL,
    //     preview: wipeblImg,
    // },
    // {
    //     type: VideoTransitionType.WIPE_BR,
    //     preview: wipebrImg,
    // },
    // {
    //     type: VideoTransitionType.FADE_GRAYS,
    //     preview: fadegraysImg,
    // },
    {
        type: VideoTransitionType.SQUEEZE_V,
        // preview: squeezevImg,
    },
    {
        type: VideoTransitionType.SQUEEZE_H,
        // preview: squeezehImg,
    },
    {
        type: VideoTransitionType.ZOOM_IN,
        // preview: zoominImg,
    },
] as VideoTransitionItem[];

export const videoFiltersList = [
    {
        type: VideoFilterType.BLACK_AND_WHITE,
        name: 'Black and White',
    },
    {
        type: VideoFilterType.NEGATIVE,
        name: 'Negative',
    },
    {
        type: VideoFilterType.SEPIA,
        name: 'Sepia',
    },
    {
        type: VideoFilterType.AMARO,
        name: 'Amaro',
    },
    {
        type: VideoFilterType.LARK,
        name: 'Lark',
    },
    {
        type: VideoFilterType.RISE,
        name: 'Rise',
    },
    {
        type: VideoFilterType.RETRO,
        name: 'Retro',
    },
    {
        type: VideoFilterType.MATTE,
        name: 'Matte',
    },
    {
        type: VideoFilterType.COOL,
        name: 'Cool',
    },
];

export const projectTextDefaultSettings = {
    textFontSize: 32,
    translationY: 0.5,
    translationX: 0.5,
    angle: 0,
    width: 0.5,
    align: SubtitlesAlign.CENTER,
    textEffect: SubtitleEffect.DEFAULT,
    fontFamily: 'HelveticaNowTextBlack',
    outline: 0,
    shadow: 0,
    textColor: '#fff',
    outlineColor: '#000000',
    shadowColor: '#00000055',
    spacing: 0,
};

export function handleCheckProjectDuration(
    project: Project,
    projectSubtitlesList: TransformedSentence[]
): number {
    const { texts, images, audios, videos, elements } = project;

    const sortedText = sortBy(texts, 'endTime');
    const sortedImages = sortBy(images, 'endTime');
    const sortedAudios = sortBy(audios, 'endTime');
    const sortedVideos = sortBy(videos, 'endTime');
    const sortedElements = sortBy(elements, 'endTime');

    const lastTextElementEndTime =
        sortedText[sortedText.length - 1]?.endTime || 0;
    const lastImageElementEndTime =
        sortedImages[sortedImages.length - 1]?.endTime || 0;
    const lastAudioElementEndTime =
        sortedAudios[sortedAudios.length - 1]?.endTime || 0;
    const lastVideoElementEndTime =
        sortedVideos[sortedVideos.length - 1]?.endTime || 0;
    const lastElementEndTime =
        sortedElements[sortedElements.length - 1]?.endTime || 0;
    let lastSentenceEndTime = 0;

    if(project.subtitlesNotAttached && project?.sentences?.length) {
        const sortedSentences = projectSubtitlesList.sort(
            (a, b) => (+a.endTime) - (+b.endTime)
        );
        lastSentenceEndTime = +sortedSentences[sortedSentences.length - 1]?.endTime || 0;
    }

    const maxEndTime = Math.max(
        ...[
            lastTextElementEndTime,
            lastImageElementEndTime,
            lastAudioElementEndTime,
            lastVideoElementEndTime,
            lastElementEndTime,
            lastSentenceEndTime,
        ]
    );

    if (maxEndTime === 0) return TIMELINE_DURATION;

    return maxEndTime;
}

export const handleNormalizeTranscriptStartTimes = (
    transcriptList: TransformedSentence[],
) => {
    const updatedNegativeTranscripts: TransformedSentence[] = [];

    const filteredNegativeTranscripts = transcriptList?.filter(
        (obj) =>
            +obj.startTime < 0 ||
            obj?.items?.some((item) => +item.startTime < 0)
    );

    const negativeSentencesIds = filteredNegativeTranscripts?.map(item => item.id);

    for (let i = 0; i < filteredNegativeTranscripts.length; i++) {
        const changedItems: SentenceDataItem[] = [];
        const currentItem = filteredNegativeTranscripts[i];

        for (let j = 0; j < currentItem.items.length; j++) {
            const start = currentItem.items[j].startTime;
            changedItems.push({
                ...currentItem.items[j],
                startTime: +start < 0 ? '0' : start,
            });
        }

        const updatedTimeSteps = currentItem.timestamps.map((item) => {
            if (item.timestamp < 0) {
                return { timestamp: 0 };
            }
            return item;
        });
        const subtitleStart = currentItem.startTime;
        const changedSubtitle: TransformedSentence = {
            ...currentItem,
            items: changedItems,
            startTime: +subtitleStart < 0 ? '0' : subtitleStart,
            timestamps: updatedTimeSteps,
            id: uuid(),
        };
        updatedNegativeTranscripts.push(changedSubtitle);
    }

    return {
        updatedNegativeTranscripts,
        negativeSentencesIds,
    };
};

export const handleCreateUploadsUpdateData = (
    projectUploads: ProjectUpload[],
    projectVideoMediaSources: MediaFile[],
) => {
    const changedUploads = [] as UploadsUpdateData[];

    for(let i = 0; i < projectUploads.length; i++) {
        if(!projectUploads[i]?.uploadDetails?.isUploadFinished && !projectUploads[i]?.uploadDetails?.isUploadFailed) {
            const source = projectVideoMediaSources?.find(item => item?.id === projectUploads[i]?.uploadDetails?.id);
            if(
                source && (
                    source?.status?.transcoding?.status === StatusType.SUCCEEDED ||
                    source?.status?.transcoding?.status === StatusType.FAILED
                )
            ) {
                changedUploads.push({
                    uploadId: projectUploads[i]?.id || '',
                    prevData: projectUploads[i],
                    updatedData: source,
                })
            }
        }
    }

    return changedUploads;
}

export const handleCreateUploadsNewData = (
    projectUploads: ProjectUpload[],
    projectVideoMediaSources: MediaFile[],
) => {
    const newUploads = [] as UploadDetails[];

    for(let i = 0; i < projectVideoMediaSources.length; i++) {
        if(
            projectVideoMediaSources[i]?.status?.transcoding?.status === StatusType.SUCCEEDED &&
            !projectUploads?.some(item => item?.uploadDetails?.id === projectVideoMediaSources[i]?.id)
        ) {
            const newMediaSource = projectVideoMediaSources[i];
            const {
                mediaType,
                filename,
                title,
                id,
                lastModified,
                s3Path,
                size,
                mediaInfo,
                description,
                language,
                defaultLanguage,
                userId,
            } = newMediaSource;

            newUploads.push({
                mediaType,
                filename,
                title,
                id,
                lastModified,
                s3Path,
                size,
                mediaInfo,
                description,
                language,
                defaultLanguage,
                userId,
                movS3path: newMediaSource?.movS3path || '',
                isUploadFinished: true,
                isUploadFailed: false,
            });
        }
    }

    return newUploads;
}

export const InTextAnimationsList = [
    {
        type: InTextAnimationType.FADE,
        name: 'Fade',
    },
    {
        type: InTextAnimationType.SLIDE_RIGHT,
        name: 'Slide Right',
    },
    {
        type: InTextAnimationType.SLIDE_LEFT,
        name: 'Slide Left',
    },
    {
        type: InTextAnimationType.SLIDE_UP,
        name: 'Slide Up',
    },
    {
        type: InTextAnimationType.SLIDE_DOWN,
        name: 'Slide Down',
    },
    // {
    //     type: InTextAnimationType.BLOCK,
    //     name: 'Block',
    // },
    {
        type: InTextAnimationType.ZOOM_IN,
        name: 'Zoom In',
    },
    {
        type: InTextAnimationType.TYPEWRITER,
        name: 'Typewriter',
    },
    {
        type: InTextAnimationType.RISE,
        name: 'Rise',
    },
    {
        type: InTextAnimationType.POP,
        name: 'Pop',
    },
    {
        type: InTextAnimationType.DROP,
        name: 'Drop',
    },
    {
        type: InTextAnimationType.COMPRESS,
        name: 'Compress',
    },
    {
        type: InTextAnimationType.BOUNCE,
        name: 'Bounce',
    },
    {
        type: InTextAnimationType.WAVE,
        name: 'Wave',
    },
    {
        type: InTextAnimationType.FALL,
        name: 'Fall',
    },
    {
        type: InTextAnimationType.SKID,
        name: 'Skid',
    },
    {
        type: InTextAnimationType.FLIPBOARD,
        name: 'Flipboard',
    },
    {
        type: InTextAnimationType.SCALE,
        name: 'Scale',
    },
    {
        type: InTextAnimationType.DRAGONFLY,
        name: 'Dragonfly',
    },
    {
        type: InTextAnimationType.BILLBOARD,
        name: 'Billboard',
    },
    {
        type: InTextAnimationType.ROLL,
        name: 'Roll',
    },
]

export const OutTextAnimationsList = [
    {
        type: OutTextAnimationType.FADE,
        name: 'Fade',
    },
    {
        type: OutTextAnimationType.SLIDE_RIGHT,
        name: 'Slide Right',
    },
    {
        type: OutTextAnimationType.SLIDE_LEFT,
        name: 'Slide Left',
    },
    {
        type: OutTextAnimationType.SLIDE_UP,
        name: 'Slide Up',
    },
    {
        type: OutTextAnimationType.SLIDE_DOWN,
        name: 'Slide Down',
    },
    // {
    //     type: OutTextAnimationType.BLOCK,
    //     name: 'Block',
    // },
    {
        type: OutTextAnimationType.ZOOM_OUT,
        name: 'Zoom Out',
    },
    {
        type: OutTextAnimationType.SINK,
        name: 'Sink',
    },
    {
        type: OutTextAnimationType.POP,
        name: 'Pop',
    },
    {
        type: OutTextAnimationType.DROP,
        name: 'Drop',
    },
    {
        type: OutTextAnimationType.DECOMPRESS,
        name: 'Decompress',
    },
    {
        type: OutTextAnimationType.FALL,
        name: 'Fall',
    },
    {
        type: OutTextAnimationType.SKID,
        name: 'Skid',
    },
    {
        type: OutTextAnimationType.FLIPBOARD,
        name: 'Flipboard',
    },
    {
        type: OutTextAnimationType.SCALE,
        name: 'Scale',
    },
    {
        type: OutTextAnimationType.DRAGONFLY,
        name: 'Dragonfly',
    },
    {
        type: OutTextAnimationType.BILLBOARD,
        name: 'Billboard',
    },
    {
        type: OutTextAnimationType.ROLL,
        name: 'Roll',
    },
]

export const DEFAULT_ANIMATION_DURATION = 0.8;

export const getCurrentTextAnimationProps = (
    textData: ProjectText,
    currentTime: number,
    frameRate: number,
): any => {
    const animationData = {
        in: null,
        out: null
    } as any;

    const currentAnimations = textData.animations;

    const animationDuration = DEFAULT_ANIMATION_DURATION;

    const currentInAnimationType = (currentAnimations?.in?.type !== InTextAnimationType.NONE) && currentAnimations?.in?.type;
    const currentOutAnimationType = (currentAnimations?.out?.type !== OutTextAnimationType.NONE) && currentAnimations?.out?.type;

    const currInAnimationProps = currentInAnimationType ? InTextAnimationProps[currentInAnimationType] : null;
    const currOutAnimationProps = currentOutAnimationType ? OutTextAnimationProps[currentOutAnimationType] : null;

    const currentTextDurationInSeconds = textData.endTime - textData.startTime;

    const isTextDurationLessThanDefault = currentTextDurationInSeconds < animationDuration;

    const hasBothAnimations = currentInAnimationType && currentOutAnimationType;
    const isTextDurationLessThan2Animations = hasBothAnimations &&
        currentTextDurationInSeconds < (2 * animationDuration);

    let finalDuration = animationDuration;

    if(isTextDurationLessThan2Animations) {
        finalDuration = currentTextDurationInSeconds/2;
    } else if(isTextDurationLessThanDefault) {
        finalDuration = currentTextDurationInSeconds;
    }

    const currentTimeInFrames = secondsToFrameNumber(currentTime, frameRate);
    const startTimeInFrames = secondsToFrameNumber(textData?.startTime, frameRate);
    const endTimeInFrames = secondsToFrameNumber(textData?.endTime, frameRate);

    const durationInFrames = secondsToFrameNumber(finalDuration, frameRate);

    if(
        (currentTimeInFrames >= endTimeInFrames - durationInFrames) &&
        (currOutAnimationProps && currOutAnimationProps?.initial)
    ) {
        if(currOutAnimationProps && currOutAnimationProps?.initial) {
            const endAnimationStart = textData.endTime - finalDuration;
            let relativeTime = (currentTime - endAnimationStart) / finalDuration;

            animationData.relativeTime = relativeTime;
            animationData.out = {
                ...(currOutAnimationProps.shouldSplit ? {
                    shouldSplit: currOutAnimationProps.shouldSplit,
                    splitType: currOutAnimationProps.splitType,
                    staggerFactor: currOutAnimationProps.staggerFactor,
                } : {}),
                initial: {...currOutAnimationProps?.initial},
                animation: {
                    ...currOutAnimationProps?.animation,
                    duration: Math.floor(finalDuration * 10) / 10,
                },
            };
        }
    } else if(currentTimeInFrames <= startTimeInFrames + durationInFrames) {
        if(currInAnimationProps && currInAnimationProps?.initial) {
            let relativeTime = (currentTime - textData.startTime) / finalDuration;

            animationData.relativeTime = relativeTime;
            animationData.in = {
                ...(currInAnimationProps.shouldSplit ? {
                    shouldSplit: currInAnimationProps.shouldSplit,
                    splitType: currInAnimationProps.splitType,
                    staggerFactor: currInAnimationProps.staggerFactor,
                } : {}),
                initial: {...currInAnimationProps?.initial},
                animation: {
                    ...currInAnimationProps?.animation,
                    duration: Math.floor(finalDuration * 10) / 10,
                },
            };
        }
    }

    return animationData;
};
