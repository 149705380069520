import { AxiosResponse } from 'axios';
import { call, put, takeLatest, delay, all, select } from 'redux-saga/effects';
import Tap from '@tapfiliate/tapfiliate-js';
import PaymentClient from 'services/api/payment';
import { v4 as uuid } from 'uuid';
import { PaymentActionTypes } from 'state/modules/payment';

import * as paymentActions from 'state/modules/payment/actions';
import * as modalActions from 'state/modules/modal/actions';
import * as workspacesActions from 'state/modules/workspaces/actions';

import { getModalEvent, ModalEvent, ModalType } from 'state/modules/modal';
import {
    getCurrentUserPlan,
    getCustomerInfo,
    getInvoicesList,
    getPaymentPlans,
    getSelectedPlan,
    getUpcomingUserPlan,
} from 'state/modules/payment/selectors';

import { NotificationTypes, showNotification } from 'utils/notifications';
import UserClient from 'services/api/user';

import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import { IInvoice } from 'interfaces/payment';
import {
    AddCreditsAction,
    AddCustomerCardAction,
    CancelUserSubscriptionAction,
    AddCustomerPaymentMethodAndSubscriptionAction,
    CreateSubscriptionForCustomerAction,
    CurrentSubscription,
    Customer,
    DeleteCustomerCardAction,
    Product,
    SetPreferredCardAction,
    UpdateStorageAction,
    PlanNameId,
    SetupIntentAction,
    ConfirmSetupAction,
    PaymentType,
    UpcomingPlan,
    PaymentIntentAction,
} from './types';
import { getFirstLoginStatus, getUserInfo, User } from '../user';

export function* handleGetCustomer(): Generator {
    yield put(paymentActions.getCustomerStart());

    try {
        const res = (yield call(PaymentClient.getCustomer)) as AxiosResponse;

        const customer = res.data.content as Customer;

        if (res.status === 200) {
            yield put(paymentActions.getCustomerSuccess(customer));
        }

        const isFirstLogin = yield select(getFirstLoginStatus);

        if (isFirstLogin) {
            Tap.customer(customer.stripeCustomerId || '');

            yield call(UserClient.updateFirstLoginStatus, {
                isFirstLogin: false,
            });
        }

        return customer;
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.getCustomerFail(error));
    }
}

export function* handleGetPlans(): Generator {
    yield put(paymentActions.getPlansStart());

    try {
        const res = (yield call(
            PaymentClient.getProducts,
            'plan'
        )) as AxiosResponse;

        if (res.status === 200) {
            yield put(paymentActions.getPlansSuccess(res.data.content));
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.getPlansFail(error));
    }
}

export function* handleGetAddons(): Generator {
    yield put(paymentActions.getAddonsStart());

    try {
        const res = (yield call(
            PaymentClient.getProducts,
            'addon'
        )) as AxiosResponse;

        if (res.status === 200) {
            yield put(paymentActions.getAddonsSuccess(res.data.content));
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.getAddonsFail(error));
    }
}

export function* handleGetUsage(): Generator {
    yield put(paymentActions.getUserUsageStart());

    try {
        const res = (yield call(PaymentClient.getUsage)) as AxiosResponse;

        if (res.status === 200) {
            yield put(paymentActions.getUserUsageSuccess(res.data.content));
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.getUserUsageFail(error));
    }
}

export function* handleGetUpcomingSubscription(): Generator {
    yield put(paymentActions.getUpcomingSubscriptionStart());

    try {
        const res = (yield call(
            PaymentClient.getUpcomingSubscription
        )) as AxiosResponse;

        if (res.status === 200) {
            yield put(
                paymentActions.getUpcomingSubscriptionSuccess(res.data.content)
            );
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.getUpcomingSubscriptionFail(error));
    }
}

export function* handleGetInvoices(): Generator {
    yield put(paymentActions.getInvoicesStart());

    try {
        const res = (yield call(PaymentClient.getInvoices)) as AxiosResponse;

        yield put(paymentActions.getInvoicesSuccess(res.data.content));
    } catch (error) {
        yield put(paymentActions.getInvoicesFail(error));
    }
}

export function* handleCreateSubscriptionForCustomer(
    action: CreateSubscriptionForCustomerAction
): Generator {
    yield put(paymentActions.createSubscriptionForCustomerStart());

    const { data, stripe, paymentInfo } = action.payload;

    const modalEvent = (yield select(getModalEvent)) as ModalEvent;
    const userInfo = (yield select(getUserInfo)) as User;
    const customer = (yield select(getCustomerInfo)) as Customer;

    const currentSubscription = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    try {
        const res = (yield call(
            PaymentClient.createSubscriptionForCustomer,
            data
        )) as AxiosResponse;

        if (stripe && res.data?.clientSecret) {
            const stripeRes = (yield call(
                stripe.confirmCardPayment,
                res.data.clientSecret
            )) as any;

            if (stripeRes?.error) {
                yield put(
                    paymentActions.createSubscriptionForCustomerFail(
                        stripeRes?.error?.message
                    )
                );
                return yield call(
                    showNotification,
                    NotificationTypes.error,
                    stripeRes?.error?.message
                );
            }
        }

        if (res.status === 200) {
            const plans = (yield select(getPaymentPlans)) as Product[];
            const selectedPlan = plans.find(
                (plan) => plan.id === data.subscription.productId
            );
            const selectedPrice = selectedPlan?.prices?.find(
                (price) => price.id === data.subscription.priceId
            );

            if (window?.dataLayer && selectedPrice) {
                window.dataLayer.push({
                    event: 'from_submit_enhance',
                    enhanced_conversion_data: {
                        email: userInfo.attributes.email,
                    },
                });
            }

            if (selectedPrice && res.data.invoiceId) {
                Tap.conversion(
                    res.data.invoiceId || '',
                    selectedPrice?.amount / 100,
                    { customer_id: customer.stripeCustomerId || '' }
                );
            }

            yield delay(2000);
            yield put(paymentActions.setSelectedPlan(null));
            yield put(paymentActions.getUserSubscription());

            yield call(handleGetUpcomingSubscription);
            yield put(workspacesActions.getCurrentWorkspace());
            yield put(workspacesActions.getWorkspaces());
            yield put(paymentActions.getUserUsage());
            yield put(workspacesActions.getCurrentWorkspace());
            yield put(workspacesActions.getWorkspaces());
            yield call(handleGetCustomer);

            yield call(handleGetInvoices);

            yield put(paymentActions.createSubscriptionForCustomerSuccess());

            const invoices = (yield select(getInvoicesList)) as IInvoice[];
            const current_subscription_number = invoices.reduce(
                (acc, invoice: IInvoice) => {
                    if (
                        invoice.lines?.data?.[0]?.price?.metadata
                            ?.pricePlanId &&
                        invoice.lines.data[0].price.metadata.pricePlanId !==
                            'initialMonthly'
                    ) {
                        acc++;
                    }
                    return acc;
                },
                0
            );

            const upcomingSubscription = (yield select(
                getUpcomingUserPlan
            )) as UpcomingPlan;

            googleAnalytics.purchase({
                purchase_type: paymentInfo.type,
                name: paymentInfo.name,
                coupon: paymentInfo.couponName,
                value: paymentInfo.price,
            });

            amplitudeAnalytics.subscriptionChanged({
                old_subscription_plan: currentSubscription.name,
                new_subscription_plan: paymentInfo.plan || '',
                subscription_type: paymentInfo.interval || 'monthly',
                $revenue: paymentInfo.price,
                coupon_applied: paymentInfo.couponName,
                paywall_type: paymentInfo.paywallType,
                current_subscription_start_date:
                    upcomingSubscription.billingDate,
                current_subscription_number,
            });

            if (data.subscription.isTrial) {
                yield put(
                    modalActions.showModal(
                        ModalType.SUBSCRIPTION_ALL_SET,
                        ModalEvent.SUBSCRIPTION_ALL_SET
                    )
                );
            } else if (modalEvent === ModalEvent.NOT_ENOUGH_STORAGE) {
                yield put(
                    modalActions.showModal(ModalType.UPLOAD, ModalEvent.UPLOAD)
                );
            } else if (modalEvent === ModalEvent.UPLOAD_VIDEO_SIZE_OVER_LIMIT) {
                yield put(
                    modalActions.showModal(
                        ModalType.PROJECT_UPLOAD,
                        ModalEvent.PROJECT_UPLOAD
                    )
                );
            } else {
                yield put(modalActions.hideModal());
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.createSubscriptionForCustomerFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleGetUserSubscription(): Generator {
    let currentAttempt = 0;

    while (currentAttempt < 5) {
        yield put(paymentActions.getUserSubscriptionStart());

        try {
            const res = (yield call(
                PaymentClient.getUserCurrentSubscription
            )) as AxiosResponse;

            if (res.status === 200) {
                yield put(
                    paymentActions.getUserSubscriptionSuccess(res.data.content)
                );
                break;
            }
        } catch (error) {
            console.log({ error });
            yield put(paymentActions.getUserSubscriptionFail(error));

            // if ((error as any)?.response?.data?.statusCode === 400) {
            //     yield put(
            //         modalActions.showModal(
            //             ModalType.SELECT_PLAN,
            //             ModalEvent.SELECT_PLAN
            //         )
            //     );
            // }
        }

        yield delay(1500);
        currentAttempt++;
    }
}

export function* handleActivateSubscription(): Generator {
    // yield put(paymentActions.activateSubscriptionStart());

    try {
        const res = (yield call(
            PaymentClient.activateSubscription
        )) as AxiosResponse;

        if (res.status === 201) {
            yield delay(2000);

            yield call(handleGetUserSubscription);
            yield call(handleGetUpcomingSubscription);
            // yield put(paymentActions.activateSubscriptionSuccess());
        }
    } catch (error) {
        console.log({ error });
        // yield put(paymentActions.activateSubscriptionFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleAddCustomerCard(
    action: AddCustomerCardAction
): Generator {
    const { cardBody, activateСurrentSubscription, stripe } = action.payload;

    const modalEvent = (yield select(getModalEvent)) as ModalEvent;
    yield put(paymentActions.addCustomerCardStart());

    try {
        const res = (yield call(
            PaymentClient.addCustomerCard,
            cardBody
        )) as AxiosResponse;

        if (stripe && res.data?.clientSecret) {
            // 3ds
            const stripeRes = (yield call(
                stripe.confirmCardSetup,
                res.data.clientSecret
            )) as any;

            if (stripeRes?.error) {
                yield put(
                    paymentActions.addCustomerCardFail(
                        stripeRes?.error?.message
                    )
                );
                return yield call(
                    showNotification,
                    NotificationTypes.error,
                    stripeRes?.error?.message
                );
            }
        }

        if (res.status === 201) {
            yield delay(2000);
            yield put(paymentActions.getCustomer());
            yield put(paymentActions.getUserSubscription());

            const selectedPlan = yield select(getSelectedPlan) as Product;

            if (selectedPlan) {
                yield put(
                    modalActions.showModal(
                        ModalType.SELECT_PLAN,
                        ModalEvent.SELECT_PLAN
                    )
                );
            } else if (activateСurrentSubscription) {
                yield call(handleActivateSubscription);
            } else if (modalEvent === ModalEvent.ADD_CREDIT_CARD_TO_BY_ADDON) {
                yield delay(1000);

                yield put(
                    modalActions.showModal(
                        ModalType.PAYMENT_CARD_SELECT,
                        ModalEvent.PAYMENT_CARD_SELECT
                    )
                );
            } else {
                yield put(modalActions.hideModal());
            }

            yield put(paymentActions.addCustomerCardSuccess());
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.addCustomerCardFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleCancelUserSubscription(
    action: CancelUserSubscriptionAction
): Generator {
    // const { showCanceledStatus } = action.payload;

    yield put(paymentActions.cancelUserSubscriptionStart());

    const currentSubscription = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    const plans = (yield select(getPaymentPlans)) as Product[];
    const customer = (yield select(getCustomerInfo)) as Customer;

    const freePlan = plans.find(
        (plan) => plan.planNameId === PlanNameId.INITIAL
    );

    const subscriptionData = {
        subscription: {
            productId: freePlan?.id,
            priceId: freePlan?.prices?.[0]?.id,
        },
    };

    try {
        // const res = (yield call(
        //     PaymentClient.cancelUserSubscription
        // )) as AxiosResponse;
        const res = (yield call(
            PaymentClient.createSubscriptionForCustomer,
            subscriptionData
        )) as AxiosResponse;

        if (res.status === 200) {
            yield delay(2000);
            yield put(paymentActions.cancelUserSubscriptionSuccess());
            yield put(paymentActions.getUserUsage());
            yield put(paymentActions.getUserSubscription());
            yield put(paymentActions.getUpcomingSubscription());

            yield put(modalActions.hideModal());

            amplitudeAnalytics.subscriptionCancelled({
                old_subscription_plan: currentSubscription.name,
            });

            // if (showCanceledStatus) {
            //     yield put(
            //         modalActions.showModal(
            //             ModalType.PLAN_CANCELED,
            //             ModalEvent.PLAN_CANCELED,
            //             '',
            //             currentSubscription
            //         )
            //     );
            // } else {
            // yield put(modalActions.hideModal());
            // }
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.cancelUserSubscriptionFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleAddCredits(action: AddCreditsAction): Generator {
    yield put(paymentActions.addCreditsStart());

    const { data, paymentInfo } = action.payload;

    try {
        const res = (yield call(
            PaymentClient.addCredits,
            data
        )) as AxiosResponse;

        if (res.status === 200) {
            yield delay(2000);
            yield put(paymentActions.addCreditsSuccess());
            yield put(paymentActions.getUserUsage());
            yield put(paymentActions.getUserSubscription());
            yield put(modalActions.hideModal());
            if (paymentInfo) {
                googleAnalytics.purchase({
                    purchase_type: paymentInfo.type,
                    name: paymentInfo.name,
                    value: paymentInfo.price,
                });

                amplitudeAnalytics.addOnPurchased({
                    $price: paymentInfo.price,
                    add_on_type: paymentInfo.addonName || 'subtitle time',
                });
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.addCreditsFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleAddDubbingCredits(action: AddCreditsAction): Generator {
    yield put(paymentActions.addDubbingCreditsStart());

    const { data, paymentInfo } = action.payload;

    try {
        const res = (yield call(
            PaymentClient.addCredits,
            data
        )) as AxiosResponse;

        if (res.status === 200) {
            yield delay(2000);
            yield put(paymentActions.addDubbingCreditsSuccess());
            yield put(paymentActions.getUserUsage());
            yield put(paymentActions.getUserSubscription());
            yield put(modalActions.hideModal());
            if (paymentInfo) {
                googleAnalytics.purchase({
                    purchase_type: paymentInfo.type,
                    name: paymentInfo.name,
                    value: paymentInfo.price,
                });

                amplitudeAnalytics.addOnPurchased({
                    $price: paymentInfo.price,
                    add_on_type: paymentInfo.addonName || 'dubbing time',
                });
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.addDubbingCreditsFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}


export function* handleUpdateStorage(action: UpdateStorageAction): Generator {
    yield put(paymentActions.updateStorageStart());

    const { data, paymentInfo } = action.payload;

    try {
        const res = (yield call(
            PaymentClient.updateStorage,
            data
        )) as AxiosResponse;

        if (res.status === 200) {
            yield delay(1500);
            yield put(paymentActions.updateStorageSuccess());
            yield put(paymentActions.getUserUsage());
            yield put(paymentActions.getUserSubscription());
            yield put(paymentActions.getUpcomingSubscription());
            yield put(modalActions.hideModal());

            if (paymentInfo) {
                googleAnalytics.purchase({
                    purchase_type: paymentInfo.type,
                    name: paymentInfo.name,
                    value: paymentInfo.price,
                });

                amplitudeAnalytics.addOnPurchased({
                    $price: paymentInfo.price,
                    add_on_type: 'storage',
                });
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.updateStorageFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handleSetPreferredCard(
    action: SetPreferredCardAction
): Generator {
    yield put(paymentActions.setPreferredCardStart());

    try {
        const res = (yield call(
            PaymentClient.setPreferredCard,
            action.payload
        )) as AxiosResponse;

        if (res.status === 200) {
            yield put(paymentActions.getCustomer());

            yield delay(1000);

            yield put(paymentActions.setPreferredCardSuccess());
        }
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.setPreferredCardFail(error));
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

// export function* handleSelectPlan(action: SelectPlanAction): Generator {
//     const selectedPlan = action.payload as Product;

//     yield put(paymentActions.setSelectedPlan(selectedPlan));
//     const customer = (yield select(getCustomerInfo)) as Customer;

//     if (selectedPlan?.isTrial && customer.cards.length) {
//         yield put(
//             paymentActions.createSubscriptionForCustomer({
//                 data: {
//                     subscription: {
//                         productId: selectedPlan?.id,
//                         priceId: selectedPlan?.prices?.[0].id,
//                         cardId: customer.cards[0].id,
//                         isTrial: true,
//                     },
//                 },
//             })
//         );
//     }
//     //  else {
//     //   yield put(
//     //     modalActions.showModal(
//     //       ModalType.UPGRADE_PLAN,
//     //       ModalEvent.UPGRADE_PLAN,
//     //       '',
//     //       selectedPlan,
//     //     ),
//     //   );
//     // }
// }

export function* handleToggleSubscriptionActiveStatus(): Generator {
    const currentSubscription = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;
    const customer = (yield select(getCustomerInfo)) as Customer;

    try {
        if (currentSubscription.status === 'canceled') {
            if (customer.paymentMethod) {
                yield call(handleActivateSubscription);
            } else {
                yield put(
                    modalActions.showModal(
                        ModalType.ADD_CREDIT_CARD,
                        ModalEvent.ADD_CREDIT_CARD_FOR_SUBSCRIPTION_ACTIVATION
                    )
                );
            }
        } else {
            yield put(
                modalActions.showModal(
                    ModalType.CANCEL_PLAN,
                    ModalEvent.CANCEL_PLAN
                )
            );
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* checkSubscriptionStatusPaymentPosibility(): Generator {
    const customer = (yield select(getCustomerInfo)) as Customer;
    const currentSubscription = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (!customer.cards.length && currentSubscription.status !== 'canceled') {
        yield put(paymentActions.cancelUserSubscription());
    }
}

export function* handleDeleteCustomerCard(
    action: DeleteCustomerCardAction
): Generator {
    const { cardId, isDeletionConfirmed } = action.payload;

    const customer = (yield select(getCustomerInfo)) as Customer;

    if (customer?.cards?.length === 1 && !isDeletionConfirmed) {
        yield put(
            modalActions.showModal(
                ModalType.DELETE_LAST_CUSTOMER_CARD,
                ModalEvent.DELETE_LAST_CUSTOMER_CARD,
                '',
                cardId
            )
        );
    } else {
        try {
            yield put(paymentActions.setCardIdToRemove(cardId));
            yield put(paymentActions.deleteCustomerCardStart());

            const res = (yield call(
                PaymentClient.deleteCard,
                cardId
            )) as AxiosResponse;

            if (res.status === 200) {
                yield delay(1000);

                yield call(handleGetCustomer);

                yield put(paymentActions.setCardIdToRemove(''));

                yield put(paymentActions.deleteCustomerCardSuccess());

                yield call(checkSubscriptionStatusPaymentPosibility);
            }
        } catch (error) {
            yield put(paymentActions.deleteCustomerCardFail(error));
            console.log({ error });
            yield call(
                showNotification,
                NotificationTypes.error,
                (error as any)?.response?.data?.message
            );
        }
    }
}

export function* handlePaymentFlowInit(): Generator {
    yield put(paymentActions.paymentFlowInitStart());

    try {
        yield all([
            call(handleGetCustomer),
            call(handleGetUserSubscription),
            call(handleGetUsage),
            call(handleGetPlans),
            call(handleGetAddons),
            call(handleGetUpcomingSubscription),
        ]);

        yield put(paymentActions.paymentFlowInitSuccess());
    } catch (error) {
        console.log({ error });
        yield put(paymentActions.paymentFlowInitFail(error));
    }
}

export function* handleSetupIntent(action: SetupIntentAction): Generator {
    try {
        const details = (yield call(
            PaymentClient.setupIntent
        )) as AxiosResponse;

        action.cb(null, details.data);
    } catch (error) {
        action.cb(error);
    }
}

export function* handlePaymentIntent(action: PaymentIntentAction): Generator {
    const data = action.payload;

    try {
        const details = (yield call(
            PaymentClient.paymentIntent,
            data
        )) as AxiosResponse;

        action.cb(null, details.data);
    } catch (error) {
        action.cb(error);
    }
}

export function* handleConfirmSetup(action: ConfirmSetupAction): Generator {
    const { data, paymentInfo } = action.payload;
    const currentSubscription = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;
    const customerInfo = (yield select(getCustomerInfo)) as Customer;

    yield put(paymentActions.confirmSetupStart());
    try {
        (yield call(PaymentClient.confirmSetup, data)) as AxiosResponse;

        yield delay(2000);

        yield put(paymentActions.getUserSubscription());
        yield put(paymentActions.getUserUsage());
        yield put(paymentActions.getUpcomingSubscription());
        yield put(workspacesActions.getCurrentWorkspace());
        yield put(workspacesActions.getWorkspaces());
        yield call(handleGetCustomer);
        yield put(paymentActions.confirmSetupSuccess());
        if (data.type === PaymentType.subscription) {
            yield call(
                showNotification,
                NotificationTypes.success,
                'Your subscription has been modified.'
            );
        }

        Tap.conversion(uuid(), paymentInfo?.price, {
            customer_id: customerInfo.stripeCustomerId || '',
        });

        yield put(workspacesActions.getCurrentWorkspace());
        yield put(workspacesActions.getWorkspaces());

        if (paymentInfo) {
            googleAnalytics.purchase({
                purchase_type: paymentInfo.type,
                name: paymentInfo.name,
                coupon: paymentInfo.couponName,
                value: paymentInfo.price,
            });
            amplitudeAnalytics.subscriptionStarted({
                subscription_plan: paymentInfo.name,
                subscription_type: paymentInfo.interval || 'monthly',
                coupon_applied: paymentInfo.couponName,
                $revenue: paymentInfo.price,
                paywall_type: paymentInfo.paywallType,
            });
        }
    } catch (error) {
        console.log({
            error,
        });
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
        yield put(
            paymentActions.confirmSetupFail(
                (error as any)?.response?.data?.message
            )
        );
    }
}

export function* handleAddCustomerPaymentMethodAndSubscription(
    action: AddCustomerPaymentMethodAndSubscriptionAction
): Generator {
    yield put(paymentActions.createCustomerWithSubscriptionStart());

    const { customer, subscriptionBody, stripe } = action.payload;

    const userInfo = (yield select(getUserInfo)) as User;
    const plans = (yield select(getPaymentPlans)) as Product[];
    const selectedPlan = plans.find(
        (plan) => plan.id === subscriptionBody.productId
    );
    const selectedPrice = selectedPlan?.prices?.find(
        (price) => price.id === subscriptionBody.priceId
    );

    const modalEvent = (yield select(getModalEvent)) as ModalEvent;

    try {
        const addCustomerCardRes = (yield call(PaymentClient.addCustomerCard, {
            card: customer.customerData?.cards[0],
        })) as AxiosResponse;

        if (stripe && addCustomerCardRes.data?.clientSecret) {
            // 3ds
            yield call(
                stripe.confirmCardSetup,
                addCustomerCardRes.data.clientSecret
            );
        }

        if (addCustomerCardRes.status === 201) {
            yield delay(2000);

            const customerData = (yield call(handleGetCustomer)) as Customer;

            if (customerData.cards) {
                const subscription = {
                    subscription: {
                        ...subscriptionBody,
                        cardId: customerData.cards[0].id,
                    },
                };

                const subscriptionRes = (yield call(
                    PaymentClient.createSubscriptionForCustomer,
                    subscription
                )) as AxiosResponse;

                if (stripe && subscriptionRes.data?.clientSecret) {
                    const stripeRes = (yield call(
                        stripe.confirmCardPayment,
                        subscriptionRes.data.clientSecret
                    )) as any;

                    if (stripeRes?.error) {
                        yield put(
                            paymentActions.createCustomerWithSubscriptionFail(
                                stripeRes?.error?.message
                            )
                        );
                        return yield call(
                            showNotification,
                            NotificationTypes.error,
                            stripeRes?.error?.message
                        );
                    }
                }

                if (window?.dataLayer && selectedPrice) {
                    window.dataLayer.push({
                        event: 'from_submit_enhance',
                        enhanced_conversion_data: {
                            email: userInfo.attributes.email,
                        },
                    });
                }

                const currentSubscription = (yield select(
                    getCurrentUserPlan
                )) as CurrentSubscription;
                console.log({ currentSubscription });

                if (selectedPrice && subscriptionRes.data.invoiceId) {
                    Tap.conversion(
                        subscriptionRes.data.invoiceId || '',
                        selectedPrice?.amount / 100,
                        {
                            customer_id:
                                customer.customerData.stripeCustomerId || '',
                        }
                    );
                }

                yield delay(2000);

                yield put(paymentActions.getUserSubscription());
                yield put(paymentActions.getUserUsage());
                yield put(paymentActions.getUpcomingSubscription());
                yield call(handleGetCustomer);
                yield put(
                    paymentActions.createCustomerWithSubscriptionSuccess()
                );

                if (modalEvent === ModalEvent.NOT_ENOUGH_STORAGE) {
                    yield put(
                        modalActions.showModal(
                            ModalType.PROJECT_UPLOAD,
                            ModalEvent.PROJECT_UPLOAD
                        )
                    );
                } else {
                    yield put(modalActions.hideModal());
                }
                // yield put(appActions.checkAppIntroductionTourStatus());
            }
        }
    } catch (error) {
        console.log({ error });
        yield put(
            paymentActions.createCustomerWithSubscriptionFail(error as any)
        );
        yield call(
            showNotification,
            NotificationTypes.error,
            (error as any)?.response?.data?.message
        );
    }
}

export function* handlePaymentLimitation(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (
        // currentUserPlan?.planNameId === PlanNameId.PROFESSIONAL ||
        currentUserPlan?.planNameId === PlanNameId.ENTERPRISE
    ) {
        yield put(
            modalActions.showModal(
                ModalType.ADD_MORE_CREDITS,
                ModalEvent.ADD_MORE_CREDITS
            )
        );
    } else {
        yield put(
            modalActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.SELECT_PLAN
            )
        );
    }
}

export function* handleTranslatePaymentLimitation(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan?.planNameId === PlanNameId.ENTERPRISE) {
        yield put(
            modalActions.showModal(
                ModalType.ADD_MORE_CREDITS,
                ModalEvent.ADD_MORE_CREDITS
            )
        );
    } else {
        yield put(
            modalActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.NOT_ENOUGH_CREDITS_FOR_TRANSLATE
            )
        );
    }
}

export function* handleSubtitlingPaymentLimitation(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan?.planNameId === PlanNameId.ENTERPRISE) {
        yield put(
            modalActions.showModal(
                ModalType.ADD_MORE_CREDITS,
                ModalEvent.ADD_MORE_CREDITS
            )
        );
    } else {
        yield put(
            modalActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.NOT_ENOUGH_CREDITS_FOR_SUBTITLING
            )
        );
    }
}

export function* handleDubbingPaymentLimitation(): Generator {
    const currentUserPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentUserPlan?.planNameId === PlanNameId.ENTERPRISE) {
        yield put(
            modalActions.showModal(
                ModalType.ADD_MORE_DUBBING_CREDITS,
                ModalEvent.ADD_MORE_DUBBING_CREDITS
            )
        );
    } else {
        yield put(
            modalActions.showModal(
                ModalType.SELECT_PLAN,
                ModalEvent.NOT_ENOUGH_CREDITS_FOR_DUBBING
            )
        );
    }
}

export function* paymentSaga(): Generator {
    yield takeLatest(
        PaymentActionTypes.PAYMENT_FLOW_INIT,
        handlePaymentFlowInit
    );
    yield takeLatest(PaymentActionTypes.GET_PLANS, handleGetPlans);
    yield takeLatest(PaymentActionTypes.GET_ADDONS, handleGetAddons);
    yield takeLatest(PaymentActionTypes.GET_USAGE, handleGetUsage);
    yield takeLatest(PaymentActionTypes.GET_CUSTOMER, handleGetCustomer);
    yield takeLatest(
        PaymentActionTypes.GET_USER_SUBSCRIPTION,
        handleGetUserSubscription
    );
    yield takeLatest(
        PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION,
        handleGetUpcomingSubscription
    );
    yield takeLatest(
        PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER,
        handleCreateSubscriptionForCustomer
    );
    yield takeLatest(
        PaymentActionTypes.ADD_CUSTOMER_CARD,
        handleAddCustomerCard
    );
    yield takeLatest(
        PaymentActionTypes.CANCEL_USER_SUBSCRIPTION,
        handleCancelUserSubscription
    );
    yield takeLatest(PaymentActionTypes.ADD_CREDITS, handleAddCredits);
    yield takeLatest(PaymentActionTypes.ADD_DUBBING_CREDITS, handleAddDubbingCredits);
    yield takeLatest(PaymentActionTypes.UPDATE_STORAGE, handleUpdateStorage);
    yield takeLatest(
        PaymentActionTypes.SET_PREFERRED_CARD,
        handleSetPreferredCard
    );
    // yield takeLatest(PaymentActionTypes.SELECT_PLAN, handleSelectPlan);
    yield takeLatest(
        PaymentActionTypes.ACTIVATE_SUBSCRIPTION,
        handleActivateSubscription
    );
    yield takeLatest(
        PaymentActionTypes.TOGGLE_SUBSCRIPTION_ACTIVE_STATUS,
        handleToggleSubscriptionActiveStatus
    );
    yield takeLatest(
        PaymentActionTypes.DELETE_CUSTOMER_CARD,
        handleDeleteCustomerCard
    );
    yield takeLatest(
        PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION,
        handleAddCustomerPaymentMethodAndSubscription
    );

    yield takeLatest(PaymentActionTypes.SETUP_INTENT, handleSetupIntent);
    yield takeLatest(PaymentActionTypes.PAYMENT_INTENT, handlePaymentIntent);
    yield takeLatest(PaymentActionTypes.CONFIRM_SETUP, handleConfirmSetup);

    yield takeLatest(PaymentActionTypes.GET_INVOICES, handleGetInvoices);
}
