import { createSelector } from 'reselect';

import { State } from 'state/modules';

import { FoldersState } from './types';

export const getFoldersState = (state: State): FoldersState => state.folders;

export const getFoldersList = createSelector(
    getFoldersState,
    (state) => state.foldersList
);

export const getFoldersCount = createSelector(
    getFoldersState,
    (state) => state.foldersList.length
);

export const getFolderCreationLoading = createSelector(
    getFoldersState,
    (state) => state.isCreationLoading
);

export const getFolderUpdateLoading = createSelector(
    getFoldersState,
    (state) => state.isUpdateLoading
);

export const getFoldersHistory = createSelector(
    getFoldersState,
    (state) => state.foldersHistory
);

export const getCurrentFolderInfo = createSelector(
    getFoldersState,
    (state) => state.currentFolder
);

export const getMoveToFolderLoading = createSelector(
    getFoldersState,
    (state) => state.isMoveToFolderLoading
);
