import { IconProps } from './index';

const PlayheadIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 .956C0 .428.413 0 .923 0h9.154c.51 0 .923.428.923.956v4.36a.973.973 0 0 1-.271.676L6.152 10.72a.9.9 0 0 1-1.304 0L.271 5.991A.973.973 0 0 1 0 5.316V.955z"
                fill={color}
            />
        </svg>
    );
};

export default PlayheadIcon;


