import { ChangeEvent, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { Promocode } from 'interfaces/payment';
import SelectedPlanCard from 'components/SelectedPlanCard';
import { NewButton, NewInput } from 'components/UI';
import PaymentClient from 'services/api/payment';
import {
    createSubscriptionForCustomer,
    Customer,
    getCurrentUserPlan,
    getCustomerInfo,
    getStripePromise,
    getSubscriptionCreationLoading,
    getSubscriptionIssueStatus,
    paymentIntent,
    PaymentPeriodType,
    PaymentType,
    PlanNameId,
    Price,
    Product,
    setupIntent,
    UpdateSubscriptionBody,
} from 'state/modules/payment';
import styled from 'styled-components';
import { Colors } from 'styles';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { hideModal } from 'state/modules/modal';
import styles from './styles.module.scss';
import { PaymentForm } from './PaymentForm';

const PromocodeContainer = styled.div`
    display: grid;
    grid-template-columns: 0.75fr 0.25fr;
    gap: 10px;
    margin-bottom: 15px;
`;

const PromocodeTitle = styled.p`
    margin-bottom: 10px;
`;

const PromocodeError = styled.p`
    color: ${Colors.RED};
    font-size: 16px;
    margin: 10px 0;
`;

const PromocodeMessage = styled.p`
    color: ${Colors.TURQUOISE};
    font-size: 16px;
    margin: 10px 0;
`;

interface Props {
    selectedPlan: Product;
    selectedBillingPeriod: Price | null;
    onSelectPlan: (plan: Product | null) => void;
    onSelectBillingPeriod: (price: Price) => void;
    paywallType: string;
    selectedPeriodOption: PaymentPeriodType;
}

export const PlanDetails = (props: Props): JSX.Element => {
    const {
        selectedPlan,
        selectedBillingPeriod,
        onSelectPlan,
        onSelectBillingPeriod,
        paywallType,
        selectedPeriodOption,
    } = props;

    const dispatch = useDispatch();

    const currentPlan = useSelector(getCurrentUserPlan);
    const stripePromise = useSelector(getStripePromise);
    const hasIssueWithSubscription = useSelector(getSubscriptionIssueStatus);

    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState();
    const [promocodeData, setPromocodeData] = useState<Promocode | null>(null);
    const [isPromocodeValidationLoading, setPromocodeValidationLoading] =
        useState(false);
    const isSubscriptionCreationLoading = useSelector(
        getSubscriptionCreationLoading
    );

    const customer = useSelector(getCustomerInfo) as Customer;

    const [promocode, setPromocode] = useState('');
    const [promocodeError, setPromocodeError] = useState<null | string>(null);

    const currentPlanName = useMemo(() => {
        if (currentPlan) {
            if (currentPlan?.planNameId === PlanNameId.INITIAL) {
                return 'Free Plan';
            }
            return currentPlan.name;
        }

        return '';
    }, [currentPlan]);

    const planPrice = (selectedBillingPeriod?.amount || 0) / 100;

    const finalPrice = promocodeData
        ? planPrice - (planPrice * promocodeData.coupon.percent_off) / 100
        : planPrice;

    const close = () => dispatch(hideModal());

    const handleChangePromocode = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;

        setPromocodeError(null);

        setPromocode(value);
    };

    const handleSubmit = () => {
        if (selectedPlan?.id && selectedBillingPeriod?.id) {
            setClientSecretLoading(true);

            dispatch(
                paymentIntent(
                    (error, data) => {
                        setClientSecretLoading(false);
                        if (!error) {
                            setClientSecret(data.clientSecret);
                        }
                    },
                    {
                        subscription: {
                            productId: selectedPlan.id,
                            priceId: selectedBillingPeriod.id,
                            coupon: promocodeData?.id || promocode,
                        },
                    }
                )
            );
        }
    };

    const handlePay = async () => {
        if (selectedPlan && selectedBillingPeriod) {
            const subscriptionBody = {
                productId: selectedPlan.id,
                priceId: selectedBillingPeriod.id,
                isTrial: Boolean(selectedPlan.isTrial),
            } as UpdateSubscriptionBody;

            if (promocodeData) {
                subscriptionBody.coupon = promocodeData.id;
            }

            dispatch(
                createSubscriptionForCustomer({
                    data: {
                        subscription: subscriptionBody,
                    },
                    paymentInfo: {
                        plan: selectedPlan.name,
                        price: finalPrice,
                        couponName: promocodeData?.code,
                        interval:
                            selectedBillingPeriod.interval === 'month'
                                ? 'monthly'
                                : 'annual',
                        name: `${
                            selectedBillingPeriod.interval === 'month'
                                ? 'Monthly'
                                : 'Annual'
                        } ${selectedPlan.name}`,
                        type: 'plan',
                        paywallType,
                    },
                })
            );
        }
    };

    const renderBillingPeriodCard = (price: Price) => {
        const title =
            price.interval === 'month' ? 'Pay mothly' : 'Pay annual Save 25%';
        const isSelected = selectedBillingPeriod?.id === price.id;

        const priceTitle =
            price.interval === 'month' ? 'per month' : 'per year';

        return (
            <button
                key={price.id}
                onClick={() => onSelectBillingPeriod(price)}
                className={[
                    styles.SelectPlanModal__billingPeriod,
                    isSelected
                        ? styles.SelectPlanModal__billingPeriod_selected
                        : null,
                ].join(' ')}
            >
                <div>
                    <div
                        className={
                            styles.SelectPlanModal__billingPeriodNameContainer
                        }
                    >
                        <div
                            className={[
                                styles.SelectPlanModal__billingPeriodCircle,
                                isSelected
                                    ? styles.SelectPlanModal__billingPeriodCircle_selected
                                    : null,
                            ].join(' ')}
                        />
                        <p
                            className={
                                styles.SelectPlanModal__billingPeriodName
                            }
                        >
                            {title}
                        </p>
                    </div>
                    <div
                        className={styles.SelectPlanModal__billingCalculations}
                    >
                        <p className={styles.SelectPlanModal__billingBasePrice}>
                            {`${price.amount / 100} $`}
                        </p>
                        {` ${priceTitle}`}
                    </div>
                </div>
            </button>
        );
    };

    const renderBillingPeriods = () => {
        if (selectedPlan && selectedPlan.prices) {
            const filteredPrices =
                selectedPlan.id === currentPlan?.id
                    ? selectedPlan.prices.filter(
                          (price) => price.id !== currentPlan?.price.id
                      )
                    : selectedPlan.prices;

            return (
                <div className={styles.SelectPlanModal__billingPeriods}>
                    {filteredPrices.map((price) =>
                        renderBillingPeriodCard(price)
                    )}
                </div>
            );
        }
    };

    const renderPromocodeMessage = () => {
        if (
            promocodeData &&
            promocodeData.active &&
            promocodeData.coupon.valid
        ) {
            return <PromocodeMessage>Promo Code applied</PromocodeMessage>;
        }
    };

    const validatePromocode = async () => {
        try {
            setPromocodeValidationLoading(true);

            const res = (await PaymentClient.validatePromocode(
                promocode,
                selectedPlan.id as string
            )) as AxiosResponse;

            setPromocodeValidationLoading(false);

            if (res.status === 200) {
                setPromocodeData(res.data.content[0]);
            }
        } catch (error) {
            const errorMessage = (error as any)?.response?.data?.message;

            setPromocodeValidationLoading(false);
            setPromocodeData(null);

            if (errorMessage) {
                showNotification(NotificationTypes.error, errorMessage);
            }

            setPromocodeError(errorMessage || null);
        }
    };

    const renderPromocodeInput = () => (
        <div>
            <PromocodeTitle>Promo Code</PromocodeTitle>
            <PromocodeContainer>
                <NewInput value={promocode} onChange={handleChangePromocode} />
                <NewButton
                    disabled={!promocode.length || isPromocodeValidationLoading}
                    textColor="#ffffff"
                    color={Colors.TURQUOISE}
                    borderRadius={8}
                    onClick={validatePromocode}
                    loading={isPromocodeValidationLoading}
                >
                    Apply
                </NewButton>
            </PromocodeContainer>
            {promocodeError ? (
                <PromocodeError>{promocodeError}</PromocodeError>
            ) : null}
            {renderPromocodeMessage()}
        </div>
    );

    const renderFinalPrice = () => {
        return (
            <p className={styles.SelectPlanModal__submitButtonPrice}>
                {`${finalPrice} $(USD)`}
            </p>
        );
    };

    const renderSubmitButton = (): JSX.Element => {
        return (
            <button
                className={[
                    styles.SelectPlanModal__submitButton,
                    clientSecretLoading || isSubscriptionCreationLoading
                        ? styles.SelectPlanModal__submitButton_disabled
                        : null,
                ].join(' ')}
                onClick={customer?.paymentMethod ? handlePay : handleSubmit}
                disabled={clientSecretLoading || isSubscriptionCreationLoading}
                id={`${selectedPlan?.id || ''}${
                    selectedBillingPeriod?.id || ''
                }`}
            >
                {clientSecretLoading || isSubscriptionCreationLoading ? (
                    <Loader
                        type="Oval"
                        color="#ffffff"
                        height={20}
                        width={20}
                    />
                ) : (
                    <div
                        className={styles.SelectPlanModal__submitButtonContent}
                    >
                        <p>
                            {customer?.paymentMethod
                                ? 'Subscribe now'
                                : 'Go to Payment'}
                        </p>
                        {renderFinalPrice()}
                    </div>
                )}
            </button>
        );
    };

    const renderCancelButton = () => (
        <button
            className={styles.SelectPlanModal__cancelButton}
            onClick={close}
            disabled={hasIssueWithSubscription}
        >
            Cancel
        </button>
    );

    const renderCardForm = () => (
        <div className={styles.SelectPlanModal__cardsForm}>
            <div className={styles.SelectPlanModal__formFooter}>
                {renderPromocodeInput()}
                <div className={styles.SelectPlanModal__actionButtonsContainer}>
                    {renderSubmitButton()}
                    {renderCancelButton()}
                </div>
                <div className={styles.SelectPlanModal__termsConditions}>
                    <p>
                        <span>By clicking on subscribe, you agree to our </span>
                        <a
                            rel="noreferrer"
                            href="https://wearenova.ai/terms-and-conditions"
                            target="_blank"
                            className={
                                styles.SelectPlanModal__termsConditionsLink
                            }
                        >
                            Terms
                        </a>{' '}
                        and{' '}
                        <a
                            rel="noreferrer"
                            href="https://wearenova.ai/privacy-policy"
                            target="_blank"
                            className={
                                styles.SelectPlanModal__termsConditionsLink
                            }
                        >
                            Privacy Policy.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );

    if (!selectedPlan.planNameId) return <></>;

    const subscriptionBody: any = {
        productId: selectedPlan.id,
        priceId: selectedBillingPeriod?.id || '',
        isTrial: Boolean(selectedPlan.isTrial),
        type: PaymentType.subscription,
        price: finalPrice,
        name: `${
            selectedBillingPeriod?.interval === 'month' ? 'Monthly' : 'Annual'
        } ${selectedPlan.name}`,
        paymentType: 'plan',
        plan: selectedPlan.name,
        interval:
            selectedBillingPeriod?.interval === 'month' ? 'monthly' : 'annual',
        paywallType,
    };

    if (promocodeData?.id) {
        subscriptionBody.coupon = promocodeData.id;
        subscriptionBody.couponName = promocodeData.code;
    }

    const urlParams = new URLSearchParams(subscriptionBody).toString();

    return (
        <div className={styles.SelectPlanModal__mainContent}>
            <div className={styles.SelectPlanModal__mainContentGrid}>
                <SelectedPlanCard
                    planNameId={selectedPlan.planNameId}
                    onUnselect={() => onSelectPlan(null)}
                    selectedBillingPeriod={selectedBillingPeriod}
                />
                {!clientSecret ? (
                    <div className={styles.SelectPlanModal__main}>
                        <p className={styles.SelectPlanModal__mainTitle}>
                            {`Upgrade ${
                                currentPlan ? currentPlanName : ''
                            } to ${selectedPlan.name}`}
                        </p>
                        {renderBillingPeriods()}
                        {renderCardForm()}
                    </div>
                ) : (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                        }}
                    >
                        <PaymentForm
                            price={finalPrice}
                            handleClose={() => onSelectPlan(null)}
                            urlParams={urlParams}
                        />
                    </Elements>
                )}
            </div>
        </div>
    );
};
