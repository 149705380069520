import produce from 'immer';


import { SignOutAction, UserActionTypes } from 'state/modules/user';

import { AnalysisResultsCategory } from 'interfaces/analysis';
import {
  GlobalSearchAction,
  GlobalSearchActionTypes,
  GlobalSearchState,
} from './types';

export const initialState: GlobalSearchState = {
  globalSearchResults: [],
  transcriptGlobalSearchResults: [],
  isGlobalSearchLoading: false,
  searchQuery: '',
  minConfidence: [50],
  selectedCategory: {
    value: AnalysisResultsCategory.ALL,
    label: 'All',
  },
  videoIdsWithGlobalSearchResults: [],
  videoIdsWithTranscriptGlobalSearchResults: [],
  categories: [
    {
      value: AnalysisResultsCategory.ALL,
      label: 'All',
    },
    {
      value: AnalysisResultsCategory.OBJECT,
      label: 'Object',
    },
    {
      value: AnalysisResultsCategory.CELEBRITY,
      label: 'Celebrity',
    },

    {
      value: AnalysisResultsCategory.FACIAL_ATTRIBUTE,
      label: 'Facial Attribute',
    },
    {
      value: AnalysisResultsCategory.USER_DEFINED,
      label: 'Collections',
    },
    {
      value: AnalysisResultsCategory.TRANSCRIPT,
      label: 'Transcript',
    },
    {
      value: AnalysisResultsCategory.MODERATED_LABEL,
      label: 'Moderated Label',
    },
  ],
};

export default (
  state: GlobalSearchState = initialState,
  action: GlobalSearchAction | SignOutAction,
): GlobalSearchState => produce(state, (draft: GlobalSearchState) => {
  switch (action.type) {
    case GlobalSearchActionTypes.GET_METADATA_BY_GLOBAL_SEARCH:
      draft.isGlobalSearchLoading = true;

      return;
    case GlobalSearchActionTypes.SET_GLOBAL_SEARCH_RESULTS:
      draft.globalSearchResults = action.payload.analysisResults;
      draft.transcriptGlobalSearchResults = action.payload.transcriptResults;
      draft.isGlobalSearchLoading = false;

      return;
    case GlobalSearchActionTypes.SET_GLOBAL_SEARCH_MIN_CONFIDENCE:
      draft.minConfidence = action.payload;

      return;
    case GlobalSearchActionTypes.SET_GLOBAL_SEARCH_CATEGORY:
      draft.selectedCategory = action.payload;

      return;
    case GlobalSearchActionTypes.SET_GLOBAL_SEARCH_QUERY:
      draft.searchQuery = action.payload;

      return;
    case GlobalSearchActionTypes.CLEAR_GLOBAL_SEARCH_RESULTS:
      return initialState;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
