import { TransformedSentence } from '../metadata';
import { transformTranscript } from '../../utils/metadataUtils';

export const adaptGetProjectAllSubtitlesData = (
    projectUnsyncedSubtitlesList: TransformedSentence[],
    projectDeletedSubtitlesIds: string[],
    subtitles: TransformedSentence[],
    isVersionChange?: boolean
) => {
    let currentSyncedSubtitles = subtitles || [];
    let currentUnsyncedSubtitles = [] as TransformedSentence[];
    let currentDeletedIds = [...projectDeletedSubtitlesIds];

    if(!isVersionChange) {
        currentUnsyncedSubtitles = projectUnsyncedSubtitlesList.filter(
            item => !currentSyncedSubtitles.some(subtitle => subtitle.id === item.id)
        );
        currentSyncedSubtitles = currentSyncedSubtitles?.filter(
            item => !projectDeletedSubtitlesIds.some(id => id === item.id)
        );
    } else {
        currentDeletedIds = [];
    }

    const idSet = new Set();
    const uniqueSubtitlesList = currentSyncedSubtitles.filter(subtitle => {
        if (!idSet.has(subtitle.id)) {
            idSet.add(subtitle.id);
            return true;
        }
        return false;
    });

    const transformedSubtitlesList = transformTranscript(
        uniqueSubtitlesList,
        true
    ) as Array<TransformedSentence>;

    const allSubtitlesSorted = [...transformedSubtitlesList, ...currentUnsyncedSubtitles].sort(
        (a, b) => (+a.endTime) - (+b.endTime)
    );

    let highestEndTime = 0;

    if(allSubtitlesSorted.length) {
        highestEndTime = +allSubtitlesSorted?.[allSubtitlesSorted.length - 1]?.endTime;
    }

    return {
        currentSyncedSubtitles: transformedSubtitlesList,
        currentUnsyncedSubtitles,
        currentDeletedIds,
        highestEndTime,
    };
}