import { AnalysisResultsType } from 'interfaces/analysis';
import { SearchFilterItem } from 'state/modules/shots';

export const buildQueryString = (
  filterValues: Array<SearchFilterItem>,
): string => {
  let query = '';

  filterValues.forEach((item: SearchFilterItem) => {
    let itemQueryString = '';

    if (item.type === AnalysisResultsType.CELEBRITY) {
      let emotionQueryString = '';
      let attributeQueryString = '';

      if (item.facialAttribute) {
        attributeQueryString = `, attribute: ${item.facialAttribute}`;
      }

      if (item.emotion) {
        emotionQueryString = `, emotion: ${item.emotion}`;
      }

      itemQueryString = `:celebrity:={name: ${item.title}${emotionQueryString}${attributeQueryString}}`;
    } else {
      itemQueryString = `:${item.type}:=${item.title}`;
    }

    if (query.length) {
      query = `${query} + ${itemQueryString}`;
    } else {
      query = itemQueryString;
    }
  });

  return query;
};
