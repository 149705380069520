import { IconProps } from './index';

const StatusBarIcon = ({ color = '#B3C7DE', size }: IconProps): JSX.Element => {
  let height = 46;

  if (size) {
    height = size;
  }

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Rev-4"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Nova-Enterprise---Home-Page-v2"
          transform="translate(-26.000000, -753.000000)"
          fill={color}
        >
          <g id="Stats" transform="translate(16.000000, 741.000000)">
            <path
              d="M18,28 L22,28 L22,12 L18,12 L18,28 Z M26,28 L30,28 L30,16 L26,16 L26,28 Z M10,28 L14,28 L14,20 L10,20 L10,28 Z"
              id="bars"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StatusBarIcon;
