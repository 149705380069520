import produce from 'immer';

import {
  ClearMetadataAction,
  MetadataActionTypes,
} from 'state/modules/metadata';
import { ClearDataAction, VideosActionTypes } from 'state/modules/media';
import { SignOutAction, UserActionTypes } from 'state/modules/user';

import { AnalysisResultsCategory } from 'interfaces/analysis';

import { defaultAnalyzerVideoFilter } from 'utils/filter';
import {
  VideoExplorerAction,
  VideoExplorerActionTypes,
  VideoExplorerState,
} from './types';



const initialState: VideoExplorerState = {
  selectedCategory: AnalysisResultsCategory.TRANSCRIPT,
  selectedItems: [],
  searchQuery: '',
  minConfidence: [50],
  minScaleFactor: [0],
  sentencesToUpdate: [],
  sentencesToDelete: [],
  videos: [],
  isTimestampsLoading: false,
  isVideoLoading: false,
  videoFilter: defaultAnalyzerVideoFilter,
  videoListTotal: 0,
  selectedTrailer: null,
  mediaLanguageCode: null,
  isOriginalTimecodeEnabled: false,
  originalTimecode: null,
};

export default (
  state = initialState,
  action:
        | VideoExplorerAction
        | ClearDataAction
        | ClearMetadataAction
        | SignOutAction,
): VideoExplorerState => produce(state, (draft) => {
  switch (action.type) {
    case VideoExplorerActionTypes.SET_SEARCH_QUERY:
      draft.searchQuery = action.payload;

      return;
    case VideoExplorerActionTypes.CHANGE_SCALE_FACTOR:
      draft.minScaleFactor = action.payload;

      return;
    case VideoExplorerActionTypes.CHANGE_CONFIDENCE:
      draft.minConfidence = action.payload;

      return;
    case VideoExplorerActionTypes.SET_CATEGORY:
      draft.selectedCategory = action.payload;

      return;
    case VideoExplorerActionTypes.SET_SELECTED_ITEMS:
      draft.selectedItems = action.payload;

      return;
    case VideoExplorerActionTypes.UNSELECT_ALL_ANALYSIS_ITEMS:
      draft.selectedItems = [];

      return;
    case VideoExplorerActionTypes.SET_TIMESTAMPS_LOADING:
      draft.isTimestampsLoading = action.payload;

      return;
    case VideoExplorerActionTypes.SET_SELECTED_TRAILER:
      draft.selectedTrailer = action.payload;

      return;
    case VideoExplorerActionTypes.SET_SENTENCES_TO_UPDATE:
      draft.sentencesToUpdate = action.payload;

      return;
    case VideoExplorerActionTypes.SET_SENTENCES_TO_DELETE:
      draft.sentencesToDelete = action.payload;

      return;
    case VideoExplorerActionTypes.SET_VIDEO_FILTER:
      draft.videoFilter = action.payload;

      return;
    case VideoExplorerActionTypes.RESET_ANALYZER_VIDEO_FILTER:
      draft.videoFilter = defaultAnalyzerVideoFilter;

      return;
    case VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_START:
      draft.isVideoLoading = true;

      return;
    case VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_SUCCESS:
      draft.isVideoLoading = false;
      draft.videos = action.payload.videos;
      draft.videoListTotal = action.payload.total;

      return;
    case VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_FAIL:
      draft.isVideoLoading = false;

      return;

    case VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES_SUCCESS:
      draft.videos = action.payload;

      return;
    case VideoExplorerActionTypes.SET_MEDIA_LANGUAGE_CODE:
      draft.mediaLanguageCode = action.payload;

      return;
    case VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE:
      draft.originalTimecode = action.payload;

      return;
    case VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE_STATUS:
      draft.isOriginalTimecodeEnabled = action.payload;

      return;
    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      return {
        ...initialState,
        videos: state.videos,
        videoFilter: state.videoFilter,
        videoListTotal: state.videoListTotal,
      };
    case UserActionTypes.SIGN_OUT:
      return initialState;
    case MetadataActionTypes.CLEAR_METADATA:
      return {
        ...initialState,
        videos: state.videos,
        videoFilter: state.videoFilter,
        videoListTotal: state.videoListTotal,
      };
    default:
      return state;
  }
});
