import {
  Collection,
  CollectionImage,
  NewCollectionImage,
} from 'interfaces/collections';

export const getTransformedCollections = (
  collections: Array<Collection>,
): Array<Collection> => collections.map((collectionItem: Collection) => {
  const transformedImages = collectionItem.images.map(
    (imageItem: CollectionImage | NewCollectionImage) => ({
      ...imageItem,
      collectionId: collectionItem.id,
    }),
  );

  return {
    ...collectionItem,
    images: transformedImages,
  };
});
