import { IconProps } from './index';

const MediaReplaceIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.871 14.5C5.89 17.694 8.805 20 12.242 20 16.527 20 20 16.418 20 12s-3.473-8-7.758-8c-2.871 0-5.378 1.609-6.72 4M7.88 9H4V5"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MediaReplaceIcon;
