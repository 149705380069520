import { createSelector } from 'reselect';

import { State } from 'state/modules';

import { PaymentState, Product } from './types';

export const getPaymentState = (state: State): PaymentState => state.payment;

export const getPaymentPlans = createSelector(
    getPaymentState,
    (state) => state.plans
);

export const getPaymentPlansLoading = createSelector(
    getPaymentState,
    (state) => state.isPlansLoading
);

export const getUserUsageData = createSelector(
    getPaymentState,
    (state) => state.usage
);

export const getUserUsageDataLoading = createSelector(
    getPaymentState,
    (state) => state.isUsageLoading
);

export const getCustomerCreationLoading = createSelector(
    getPaymentState,
    (state) => state.isCustomerCreationLoading
);

export const getCurrentUserPlan = createSelector(
    getPaymentState,
    (state) => state.currentPlan
);

export const getUpcomingUserPlan = createSelector(
    getPaymentState,
    (state) => state.upcomingPlan
);

export const getCurrentPlanLoading = createSelector(
    getPaymentState,
    (state) => state.isCurrentPlanLoading
);

export const getUpcomingPlanLoading = createSelector(
    getPaymentState,
    (state) => state.isUpcomingPlanLoading
);

export const getCustomerInfo = createSelector(
    getPaymentState,
    (state) => state.customer
);

export const getSubscriptionCancelationLoading = createSelector(
    getPaymentState,
    (state) => state.isSubscriptionCancelationLoading
);

export const getSubscriptionCreationLoading = createSelector(
    getPaymentState,
    (state) => state.isSubscriptionCreationLoading
);

export const getAddonsList = createSelector(
    getPaymentState,
    (state) => state.addons
);

export const getAddCreditsLoading = createSelector(
    getPaymentState,
    (state) => state.isAddCreditsLoading
);

export const getAddDubbingCreditsLoading = createSelector(
    getPaymentState,
    (state) => state.isAddDubbingCreditsLoading
);

export const getStorageUpdateLoading = createSelector(
    getPaymentState,
    (state) => state.isStorageUpdateLoading
);

export const getAddCustomerCardLoading = createSelector(
    getPaymentState,
    (state) => state.isAddCustomerCardLoading
);

export const getSelectedPlan = createSelector(
    getPaymentState,
    (state) => state.selectedPlan
);

export const getSetPreferredCardLoading = createSelector(
    getPaymentState,
    (state) => state.isSetPreferredCardLoading
);

export const getPaymentFlowLoading = createSelector(
    getPaymentState,
    (state) => state.isPaymentFlowLoading
);

export const getCardIdToRemove = createSelector(
    getPaymentState,
    (state) => state.cardIdToRemove
);

export const getSubscriptionActivationLoading = createSelector(
    getPaymentState,
    (state) => state.isSubscriptionActivationLoading
);

export const getSubscriptionIssueStatus = createSelector(
    getPaymentState,
    (state) => state.hasIssueWithSubscription
);

export const getAdditionalStorageAddon = createSelector(
    getAddonsList,
    (addons: Array<Product>) =>
        addons.find((addon: Product) => addon.name === 'Additional Storage')
);

export const getCreditsAddon = createSelector(
    getAddonsList,
    (addons: Array<Product>) =>
        addons.find((addon: Product) => addon.name === 'Credits')
);

export const getDubbingCreditsAddon = createSelector(
    getAddonsList,
    (addons: Array<Product>) =>
        addons.find((addon: Product) => addon.name === 'Dubbing Credits')
);

export const getCurrentStorage = createSelector(getPaymentState, (state) => {
    const userUsage = state.usage;

    if (userUsage) {
        const { total, usage } = userUsage;

        return total.storage - usage.storage;
    }

    return 0;
});

export const getCurrentCredits = createSelector(getPaymentState, (state) => {
    const userUsage = state.usage;
    let credits = 0;

    if (userUsage) {
        const { total, usage } = userUsage;

        const diff =
            total.subscriptionAndAddonCredits -
            usage.credits.rekognition -
            usage.credits.transcribe;

        if (diff < 0) {
            credits = total.userCredits;
        } else {
            credits = diff + total.userCredits;
        }
    }

    return credits;
});

export const getCurrentDubbingCredits = createSelector(getPaymentState, (state) => {
    const userUsage = state.usage;
    let credits = 0;

    if (userUsage) {
        const { total, usage } = userUsage;

        const diff =
            (total?.subscriptionAndAddonDubbingCredits || 0) -
            (usage?.dubbingCredits || 0);

        if (diff < 0) {
            credits = total?.dubbingCredits || 0;
        } else {
            credits = diff + (total?.dubbingCredits || 0);
        }
    }

    return credits;
});

export const getStripePromise = createSelector(
    getPaymentState,
    (state) => state.stripePromise
);

export const getConfirmSetupLoading = createSelector(
    getPaymentState,
    (state) => state.isConfirmSetupLoading
);

// invoices

export const getInvoicesList = createSelector(
    getPaymentState,
    (state) => state.invoices
);

export const getInvoicesLoading = createSelector(
    getPaymentState,
    (state) => state.isInvoicesLoading
);
