import {
  Collection,
  CollectionImage,
  CollectionItemBySearch,
  CollectionItemToUpload,
  NewCollectionImage,
} from 'interfaces/collections';

export interface CollectionsState {
    collections: Array<Collection>;
    selectedCollectionsForDelete: Array<string>;
    isCollectionsLoading: boolean;
    collectionsItemsBySearchLoading: boolean;
    isDeleteLoading: boolean;
    isUploading: boolean;
    collectionTempItems: Array<NewCollectionImage>;
    imagesForDelete: Array<string>;
    total: number;
    selectedCollectionsItems: Array<CollectionImage>;
    collectionsItemsBySearch: Array<CollectionItemBySearch>;
    isCollectionDeleting: boolean;
    searchQuery: string;
}

export enum CollectionsActionTypes {
    GET_COLLECTIONS = '@@collections/GET_COLLECTIONS',
    GET_COLLECTIONS_START = '@@collections/GET_COLLECTIONS_START',
    GET_COLLECTIONS_SUCCESS = '@@collections/GET_COLLECTIONS_SUCCESS',
    GET_COLLECTIONS_FAIL = '@@collections/GET_COLLECTIONS_FAIL',
    GET_COLLECTION_BY_ID = '@@collections/GET_COLLECTION_BY_ID',
    GET_COLLECTION_BY_ID_START = '@@collections/GET_COLLECTION_BY_ID_START',
    GET_COLLECTION_BY_ID_FAIL = '@@collections/GET_COLLECTION_BY_ID_FAIL',
    DELETE_COLLECTION = '@@collections/DELETE_COLLECTION',
    DELETE_COLLECTION_START = '@@collections/DELETE_COLLECTION_START',
    DELETE_COLLECTION_SUCCESS = '@@collections/DELETE_COLLECTION_SUCCESS',
    DELETE_COLLECTION_FAIL = '@@collections/DELETE_COLLECTION_FAIL',
    DELETE_COLLECTIONS_ITEMS = '@@collections/DELETE_COLLECTIONS_ITEMS',
    DELETE_COLLECTIONS_ITEMS_START = '@@collections/DELETE_COLLECTIONS_ITEMS_START',
    DELETE_COLLECTIONS_ITEMS_SUCCESS = '@@collections/DELETE_COLLECTIONS_ITEMS_SUCCESS',
    DELETE_COLLECTIONS_ITEMS_FAIL = '@@collections/DELETE_COLLECTIONS_ITEMS_FAIL',
    SET_COLLECTION_IMAGES_IDS_FOR_UPDATE = '@@collections/SET_COLLECTION_IMAGES_IDS_FOR_UPDATE',
    ADD_COLLECTION_IMAGES_IDS_FOR_UPDATE = '@@collections/ADD_COLLECTION_IMAGES_IDS_FOR_UPDATE',
    GET_COLLECTIONS_ITEMS_BY_SEARCH = '@@collections/GET_COLLECTIONS_ITEMS_BY_SEARCH',
    GET_COLLECTIONS_ITEMS_BY_SEARCH_START = '@@collections/GET_COLLECTIONS_ITEMS_BY_SEARCH_START',
    GET_COLLECTIONS_ITEMS_BY_SEARCH_FAIL = '@@collections/GET_COLLECTIONS_ITEMS_BY_SEARCH_FAIL',
    GET_COLLECTIONS_ITEMS_BY_SEARCH_SUCCESS = '@@collections/GET_COLLECTIONS_ITEMS_BY_SEARCH_SUCCESS',
    UPDATE_COLLECTIONS_ITEMS = '@@collections/UPDATE_COLLECTIONS_ITEMS',
    UPDATE_COLLECTIONS_ITEMS_SUCCESS = '@@collections/UPDATE_COLLECTIONS_ITEMS_SUCCESS',
    ADD_COLLECTION_IMAGES_IDS_FOR_DELETE = '@@collections/ADD_COLLECTION_IMAGES_IDS_FOR_DELETE',
    SET_COLLECTION_IMAGES_IDS_FOR_DELETE = '@@collections/SET_COLLECTION_IMAGES_IDS_FOR_DELETE',
    UPLOAD_COLLECTION_ITEMS = '@@collections/UPLOAD_COLLECTION_ITEMS',
    UPLOAD_COLLECTION_ITEMS_START = '@@collections/UPLOAD_COLLECTION_ITEMS_START',
    UPLOAD_COLLECTION_ITEMS_FAIL = '@@collections/UPLOAD_COLLECTION_ITEMS_FAIL',
    UPLOAD_COLLECTION_ITEMS_SUCCESS = '@@collections/UPLOAD_COLLECTION_ITEMS_SUCCESS',
    SET_SELECTED_COLLECTIONS_ITEMS = '@@collections/SET_SELECTED_COLLECTIONS_ITEMS',
    SET_COLLECTIONS_FOR_DELETE = '@@collections/SET_COLLECTIONS_FOR_DELETE',
    ADD_COLLECTION_FOR_DELETE = '@@collections/ADD_COLLECTION_FOR_DELETE',
    REMOVE_COLLECTION_FOR_DELETE = '@@collections/REMOVE_COLLECTION_FOR_DELETE',
    SET_COLLECTION_DELETING = '@@collections/SET_COLLECTION_DELETING',
    SET_COLLECTIONS_SEARCH_QUERY = '@@collections/SET_COLLECTIONS_SEARCH_QUERY',
}

export interface ExtTypeMap {
    png: string;
    jpg: string;
    jpeg: string;
}

export interface GetCollectionsAction {
    type: CollectionsActionTypes.GET_COLLECTIONS;
}

export interface GetCollectionsStartAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_START;
}
export interface GetCollectionsFailAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_FAIL;
    payload: any;
}
export interface GetCollectionsSuccessAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_SUCCESS;
    payload: {
        collections: Array<Collection>;
        total: number;
    };
}
export interface GetCollectionByIdAction {
    type: CollectionsActionTypes.GET_COLLECTION_BY_ID;
    payload: {
        id: string;
        name: string;
    };
}
export interface GetCollectionByIdStartAction {
    type: CollectionsActionTypes.GET_COLLECTION_BY_ID_START;
}
export interface GetCollectionByIdFailAction {
    type: CollectionsActionTypes.GET_COLLECTION_BY_ID_FAIL;
    payload: any;
}

export interface DeleteCollectionAction {
    type: CollectionsActionTypes.DELETE_COLLECTION;
    payload: string;
}
export interface DeleteCollectionStartAction {
    type: CollectionsActionTypes.DELETE_COLLECTION_START;
}
export interface DeleteCollectionFailAction {
    type: CollectionsActionTypes.DELETE_COLLECTION_FAIL;
    payload: any;
}
export interface DeleteCollectionSuccessAction {
    type: CollectionsActionTypes.DELETE_COLLECTION_SUCCESS;
}
export interface DeleteCollectionsItemsAction {
    type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS;
    payload: Array<CollectionImage>;
}
export interface DeleteCollectionsItemsStartAction {
    type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_START;
}

export interface DeleteCollectionsItemsFailAction {
    type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_FAIL;
    payload: any;
}
export interface DeleteCollectionsItemsSuccessAction {
    type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_SUCCESS;
    payload: Array<Collection>;
}
export interface AddCollectionTempItemAction {
    type: CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_UPDATE;
    payload: Array<NewCollectionImage>;
}

export interface SetCollectionTempItemAction {
    type: CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_UPDATE;
    payload: Array<NewCollectionImage>;
}
export interface GetCollectionsItemsBySearchAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH;
    payload: string;
}
export interface GetCollectionsItemsBySearchStartAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_START;
}
export interface GetCollectionsItemsBySearchFailAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_FAIL;
    payload: any;
}
export interface GetCollectionsItemsBySearchSuccessAction {
    type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_SUCCESS;
    payload: Array<CollectionItemBySearch>;
}
export interface UpdateCollectionsItemsAction {
    type: CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS;
}
export interface UpdateCollectionsItemsSuccessAction {
    type: CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS_SUCCESS;
    payload: Array<Collection>;
}
export interface AddCollectionImagesIdsForDeleteAction {
    type: CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_DELETE;
    payload: Array<string>;
}
export interface SetCollectionImagesIdsForDeleteAction {
    type: CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_DELETE;
    payload: Array<string>;
}
export interface UploadCollectionItemsAction {
    type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS;
    payload: {
        items: Array<CollectionItemToUpload>;
        collectionId: string;
    };
}
export interface UploadCollectionItemsStartAction {
    type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_START;
}
export interface UploadCollectionItemsFailAction {
    type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_FAIL;
    payload: any;
}
export interface UploadCollectionItemsSuccessAction {
    type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_SUCCESS;
}
export interface SetSelectedCollectionsItemsAction {
    type: CollectionsActionTypes.SET_SELECTED_COLLECTIONS_ITEMS;
    payload: Array<CollectionImage>;
}
export interface SetCollectionDeletingAction {
    type: CollectionsActionTypes.SET_COLLECTION_DELETING;
    payload: boolean;
}
export interface SetCollectionsForDeleteAction {
    type: CollectionsActionTypes.SET_COLLECTIONS_FOR_DELETE;
    payload: Array<string>;
}
export interface AddCollectionForDeleteAction {
    type: CollectionsActionTypes.ADD_COLLECTION_FOR_DELETE;
    payload: string;
}
export interface RemoveCollectionForDeleteAction {
    type: CollectionsActionTypes.REMOVE_COLLECTION_FOR_DELETE;
    payload: string;
}
export interface SetCollectionsSearchQueryAction {
    type: CollectionsActionTypes.SET_COLLECTIONS_SEARCH_QUERY;
    payload: string;
}

export type CollectionsAction =
    | GetCollectionsAction
    | GetCollectionsStartAction
    | GetCollectionsFailAction
    | GetCollectionsSuccessAction
    | GetCollectionByIdAction
    | GetCollectionByIdStartAction
    | GetCollectionByIdFailAction
    | DeleteCollectionAction
    | DeleteCollectionStartAction
    | DeleteCollectionFailAction
    | DeleteCollectionSuccessAction
    | DeleteCollectionsItemsAction
    | DeleteCollectionsItemsStartAction
    | DeleteCollectionsItemsFailAction
    | DeleteCollectionsItemsSuccessAction
    | AddCollectionTempItemAction
    | SetCollectionTempItemAction
    | GetCollectionsItemsBySearchAction
    | GetCollectionsItemsBySearchStartAction
    | GetCollectionsItemsBySearchFailAction
    | GetCollectionsItemsBySearchSuccessAction
    | UpdateCollectionsItemsAction
    | UpdateCollectionsItemsSuccessAction
    | AddCollectionImagesIdsForDeleteAction
    | SetCollectionImagesIdsForDeleteAction
    | UploadCollectionItemsAction
    | UploadCollectionItemsStartAction
    | UploadCollectionItemsFailAction
    | UploadCollectionItemsSuccessAction
    | SetSelectedCollectionsItemsAction
    | SetCollectionDeletingAction
    | SetCollectionsForDeleteAction
    | AddCollectionForDeleteAction
    | RemoveCollectionForDeleteAction
    | SetCollectionsSearchQueryAction;
