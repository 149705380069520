import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useEffect, useMemo, useState } from 'react';

import { UserProfileCard } from 'components/UserProfileCard';
import WorkspaceInviteeCard from 'components/WorkspaceInviteeCard';

import { Icon } from 'components/UI';

import { getUserInfo } from 'state/modules/user';
import {
    deleteInvite,
    getCurrentWorkspaceInfo,
    getInviterInvites,
    getInviterInvitesList,
    getInviterInvitesLoading,
    getRevokeInviteLoading,
} from 'state/modules/workspaces';
import {
    InviteToWorkspace,
    InviteToWorkspaceStatuses,
} from 'interfaces/workspaces';
import { NEUTRAL_300, NEUTRAL_400, NEUTRAL_500 } from '../../styles/colors';

const Members = styled.div`
    border-radius: 0.875rem;
    flex: 1 1 230px;
    overflow-y: auto;
    height: 100%;
`;

const MembersLabel = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 500;
    color: ${NEUTRAL_400};
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const OwnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const OwnerLabel = styled.span`
    color: ${NEUTRAL_300};
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    appearance: none;
    display: block;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
`;

const MemberContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
`;

const DeleteButton = styled.button`
    background-color: transparent;
    outline: none;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    &:disabled {
        cursor: not-allowed;
    }
`;

const DeleteLoaderContainer = styled.div`
    margin-left: auto;
`;

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
`;

const LoaderTitle = styled.span`
    margin-right: 0.313rem;
    font-family: 'Inter Medium', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 500;
`;

const WorkspaceMembersList = () => {
    const list = useSelector(getInviterInvitesList);
    const isLoading = useSelector(getInviterInvitesLoading);
    const user = useSelector(getUserInfo);
    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);
    const isRevokeInviteLoading = useSelector(getRevokeInviteLoading);
    const [selectedInvite, setSelectedInvite] = useState<string | null>(null);

    const dispatch = useDispatch();

    const isCurrentUserOwner = useMemo(
        () => currentWorkspace?.userId === user?.id,
        [currentWorkspace, user]
    );

    const filteredList = useMemo(
        () =>
            list.filter(
                (item) => item.status !== InviteToWorkspaceStatuses.REJECTED
            ),
        [list]
    );

    useEffect(() => {
        dispatch(getInviterInvites());
    }, [dispatch]);

    useEffect(() => {
        setSelectedInvite(null);
    }, [list]);

    const handleDelete = (id: string, workspaceId: string) => {
        dispatch(
            deleteInvite({
                inviteId: id,
                workspaceId,
            })
        );
        setSelectedInvite(id);
    };

    const renderDeleteButton = (item: InviteToWorkspace) => {
        if (isCurrentUserOwner) {
            if (selectedInvite === item.id && isRevokeInviteLoading) {
                return (
                    <DeleteLoaderContainer>
                        <Loader
                            type="Oval"
                            color="rgb(5,27,90)"
                            height={25}
                            width={25}
                        />
                    </DeleteLoaderContainer>
                );
            }

            return item.status !== InviteToWorkspaceStatuses.REJECTED ? (
                <DeleteButton
                    onClick={() => handleDelete(item.id, item.workspaceId)}
                    disabled={isRevokeInviteLoading}
                >
                    <Icon name="trashcan-icon-new" size={16} color={NEUTRAL_500} />
                </DeleteButton>
            ) : null;
        }
    };

    return (
        <Members>
            <MembersLabel>Members</MembersLabel>
            {
                (isCurrentUserOwner && user) && (
                    <OwnerContainer>
                        <UserProfileCard user={user} avatarSize={2.25} />
                        <OwnerLabel>Owner</OwnerLabel>
                    </OwnerContainer>
                )
            }
            {filteredList.map((item) => (
                <MemberContainer key={item.id}>
                    <WorkspaceInviteeCard data={item} avatarSize={2.25} />
                    {renderDeleteButton(item)}
                </MemberContainer>
            ))}
            {
                isLoading && (
                    <LoaderContainer>
                        <LoaderTitle>Loading...</LoaderTitle>
                        <Loader
                            type="Oval"
                            color="rgb(5,27,90)"
                            height={12}
                            width={12}
                        />
                    </LoaderContainer>
                )
            }
        </Members>
    );
};

export default WorkspaceMembersList;
