import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 1;

const NoteIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;
    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                filter="url(#filter0_d_22_108)"
            >
                <path
                    d="M9.48833 2.85212L22.345 0.509422C22.9867 0.415714 23.5 1.03653 23.5 1.68077V16.2289C23.5 18.5013 21.6683 20.3287 19.4167 20.3287C17.165 20.3287 15.3333 18.5013 15.3333 16.2289C15.3333 13.9682 17.165 12.1292 19.4167 12.1292C20.0467 12.1292 20.63 12.2932 21.1667 12.5392V5.19482L10.6667 7.53752V17.986C10.3867 19.9772 8.63667 21.5 6.58333 21.5C4.33167 21.5 2.5 19.6727 2.5 17.4003C2.5 15.1396 4.33167 13.3006 6.58333 13.3006C7.21333 13.3006 7.79667 13.4645 8.33333 13.7105V4.02347C8.33333 3.37923 8.84667 2.95754 9.48833 2.85212Z"
                    fill={color}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_22_108"
                    x="0.5"
                    y="0.5"
                    width={width}
                    height={height}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_108" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22_108" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default NoteIcon;