import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import sortBy from 'lodash.sortby';
import { Analytics, Storage } from 'aws-amplify';
import { getInfo } from 'react-mediainfo';

import {
    Project,
    ProjectAudio,
    ProjectAudioMediaSource,
    ProjectImage,
    ProjectVideo, VideoFilter, VideoTransition,
} from 'interfaces/projects';
import { MediaFile, MediaType, TempMediaFile } from 'interfaces/videos';
import { UploadingMedia, UploadMediaType } from 'interfaces/uploading';

import * as ModalsActions from 'state/modules/modal/actions';
import * as ProjectsActions from 'state/modules/projects/actions';
import * as UploadActions from 'state/modules/uploading/actions';
import * as ProjectStudioActions from 'state/modules/projectsStudio/actions';
import * as StockMediaActions from 'state/modules/stockMedia/actions';
import * as ProjectUploadActions from 'state/modules/projectUpload/actions';

import { adaptAddedVideos, transformMediaFilesToProjectVideos } from 'utils/projects';

import { getUploadingFileContentType } from 'utils/upload';
import { transformFilesToMediaFiles } from 'utils/mediaFiles';
import { reducer, getPercentageValue } from 'utils/calc';
import { getImageDimension } from 'utils/image';
import {
    сheckStorageLimitations,
    сheckVideosDurationLimitations,
    сheckVideoSizeLimitations,
} from 'utils/limitations';
import {
    transformAudioTrack,
    transformGeneralTrack,
} from 'utils/mediaInfoHelpers';

import MediaClient from 'services/api/media';
import StockMediaClient from 'services/api/stockMedia';

import { Workspace } from 'interfaces/workspaces';

import { timeToMs } from 'utils/time';

import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import {
    CurrentSubscription,
    getCurrentUserPlan,
    getUserUsageData,
    PlanNameId,
    UserUsageAndTotalSubscriptionData,
} from '../payment';
import {
    getProjectAudioMediaSources,
    getProjectAudiosList,
    getProjectDetailsInfo, getProjectFilters,
    getProjectItemsMaxZIndex,
    getProjectItemZIndex,
    getProjectMediaSources,
    getProjectTempMediaSources, getProjectTransitions,
    getProjectVideos,
    handleStockMediaLimitations,
    handleStorageLimitations,
    syncProjectMedias,
} from '../projects';
import {
    UploadProjectVideoAction,
    ProjectsUploadActionTypes,
    UploadProjectImageAction,
    UploadProjectAudioAction,
    UploadProjectStockVideoAction,
    UploadProjectStockAudioAction,
    UploadProjectStockImageAction,
    UploadProjectStockSoundEffectAction,
} from './types';
import { getUserId, getUserInfo, User } from '../user';
import { ModalEvent, ModalType } from '../modal';
import { getProjectTimelineTime, SelectedProjectObjectType } from '../projectsStudio';
import { getCurrentWorkspaceInfo } from '../workspaces';
import { mediasToTrackMediasAdapter, VIDEO_TRACKS_LIMIT } from '../../../utils/timeline';

const windowFork = window;

export function* handleUploadProjectVideo(
    action: UploadProjectVideoAction
): Generator {
    try {
        const { videos, replaceableMedia } = action.payload;

        const projectMediaSources = (yield select(
            getProjectMediaSources
        )) as MediaFile[];
        const oldProjectTempMediaSources = (yield select(
            getProjectTempMediaSources
        )) as TempMediaFile[];
        const userInfo = (yield select(getUserInfo)) as User;
        const userId = (yield select(getUserId)) as string;
        const userUsage = (yield select(
            getUserUsageData
        )) as UserUsageAndTotalSubscriptionData;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        if (!currentPlan) return;

        const prevUploadedMediaFile =
            projectMediaSources?.[0] || oldProjectTempMediaSources?.[0];

        const transformedMediaFiles = (yield call(
            transformFilesToMediaFiles,
            videos,
            userInfo,
            prevUploadedMediaFile?.mediaInfo?.proxy?.video?.[0]?.framerate || 30
        )) as TempMediaFile[];

        let isVideosDurationOverLimit = false;
        let isFilesSizeOverStorageLimit = false;

        if (currentPlan?.planNameId !== PlanNameId.ENTERPRISE) {
            isVideosDurationOverLimit = (yield call(
                сheckVideosDurationLimitations,
                transformedMediaFiles,
                currentPlan?.availableVideoLength || 0
            )) as boolean;

            isFilesSizeOverStorageLimit = (yield call(
                сheckStorageLimitations,
                videos,
                userUsage
            )) as boolean;
        }

        if (isFilesSizeOverStorageLimit) {
            return yield call(handleStorageLimitations);
        }
        if (isVideosDurationOverLimit) {
            return yield put(
                ModalsActions.showModal(
                    ModalType.SELECT_PLAN,
                    ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT
                )
            );
        }

        if (currentPlan.planNameId === PlanNameId.INITIAL) {
            const isVideosSizeOverLimit = yield call(
                сheckVideoSizeLimitations,
                videos,
                2147483648
            );

            if (isVideosSizeOverLimit) {
                return yield put(
                    ModalsActions.showModal(
                        ModalType.SELECT_PLAN,
                        ModalEvent.UPLOAD_VIDEO_SIZE_OVER_LIMIT
                    )
                );
            }
        }

        localStorage.removeItem('isUserWithoutProjectUploadedVideo');

        const transformedStatuses = videos.map((item: UploadingMedia) => ({
            id: item.id,
            currentProgress: 0,
            remainingAmount: 100,
            isLoadig: true,
        }));

        yield put(UploadActions.setUploadingStatuses(transformedStatuses));

        /// ////////////

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        let oldProjectVideos = (yield select(
            getProjectVideos
        )) as ProjectVideo[];
        const oldProjectAudios = (yield select(
            getProjectAudiosList
        )) as ProjectAudio[];
        let oldProjectFilters = (yield select(
            getProjectFilters
        )) as VideoFilter[];
        let oldProjectTransitions = (yield select(
            getProjectTransitions
        )) as VideoTransition[];

        oldProjectVideos = sortBy(oldProjectVideos, 'startTime');

        const updatedTempMediaSources = [
            ...oldProjectTempMediaSources,
            ...transformedMediaFiles,
        ];
        const transformedProjectVideos = transformMediaFilesToProjectVideos(
            transformedMediaFiles,
            replaceableMedia,
        );

        let updatedProjectVideos = [...oldProjectVideos];
        const updatedProjectAudios = [...oldProjectAudios];

        let updatedProjectFilters = [...oldProjectFilters];
        let updatedProjectTransitions = [...oldProjectTransitions];

        if(replaceableMedia) {
            updatedProjectVideos = updatedProjectVideos.filter(item => item.id !== replaceableMedia.id);
            updatedProjectFilters = updatedProjectFilters.map(filter => {
                if(filter.videoId === replaceableMedia.id) {
                    return {
                        ...filter,
                        videoId: transformedProjectVideos[0].id,
                    }
                }
                return filter;
            });
            updatedProjectTransitions = updatedProjectTransitions.map(item => {
                if(item.startTarget === replaceableMedia.id) {
                    return {
                        ...item,
                        startTarget: transformedProjectVideos[0].id,
                    }
                } if(item.endTarget === replaceableMedia.id) {
                    return {
                        ...item,
                        endTarget: transformedProjectVideos[0].id,
                    }
                }
                return item;
            });
        }

        // start uploading
        yield put(
            UploadActions.confirmUploading({
                media: videos,
                showNotifications: false,
                fps: prevUploadedMediaFile?.mediaInfo?.original?.video?.[0]
                    ?.framerate,
                mediaType: UploadMediaType.VIDEO,
            })
        );

        if (oldProjectVideos.length > 0 && !replaceableMedia) {
            updatedProjectVideos = adaptAddedVideos(
                oldProjectVideos,
                transformedProjectVideos,
                updatedProjectVideos,
            )
        } else {
            updatedProjectVideos.push(...transformedProjectVideos);
        }

        const newMediaSourcesIds = transformedMediaFiles.map(
            (mediaSource) => mediaSource.id
        );

        const videosDurationArray = updatedProjectVideos.map(
            (item) => item.trimEnd
        );

        const totalProjectVideosDuration = videosDurationArray.reduce(
            reducer,
            0
        );

        const updatedProjectDuration =
            totalProjectVideosDuration > projectDetails.duration
                ? totalProjectVideosDuration
                : projectDetails.duration;

        const transformedMediaSourcesSettings = newMediaSourcesIds.map(
            (id: string) => ({
                mediaSourceId: id,
                language: '',
            })
        );

        const mediaSourceId = videos[0].id;

        const mediaSubtitlesSettings = projectDetails.settings?.mediaSubtitlesSettings || [];
        const newMediaSubtitlesSettings = {
            mediaId: mediaSourceId,
            settings: {...projectDetails.settings.subtitles}
        };

        const updatedMediaSubtitlesSettings = [
            ...mediaSubtitlesSettings,
            newMediaSubtitlesSettings
        ];

        const updatedProjectDetails = {
            ...projectDetails,
            name:
                projectDetails.name.length > 0
                    ? projectDetails.name
                    : transformedMediaFiles[0].title ||
                      transformedMediaFiles[0].filename,
            mediaSources: [
                ...projectDetails.mediaSources,
                ...newMediaSourcesIds,
            ],
            videos: updatedProjectVideos,
            duration: updatedProjectDuration,
            mediaSourcesSettings: [
                ...projectDetails.mediaSourcesSettings,
                ...transformedMediaSourcesSettings,
            ],
            isProjectDurationAutomatic: true,
            settings: {
                ...projectDetails.settings,
                mediaSubtitlesSettings: updatedMediaSubtitlesSettings,
            },
            transitions: updatedProjectTransitions || [],
            filters: updatedProjectFilters || [],
        } as Project;

        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));
        yield put(
            ProjectsActions.setProjectVideosTransitions(
                updatedProjectTransitions || []
            )
        );
        yield put(
            ProjectsActions.setProjectVideosFilters(
                updatedProjectFilters || []
            )
        );

        if(replaceableMedia) {
            yield put(
                ProjectStudioActions.setSelectedShape({
                    id: transformedProjectVideos[0]?.id,
                })
            );
            yield put(
                ProjectStudioActions.selectProjectObject(
                    {
                        object: {
                            object: transformedProjectVideos[0],
                            type: replaceableMedia?.isAudio ?
                                SelectedProjectObjectType.AUDIO_WITH_SUBTITLES :
                                SelectedProjectObjectType.VIDEO,
                        },
                    }
                ));
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProjectDetails,
            })
        );
        yield put(
            ProjectsActions.setProjectTempMediaSources(updatedTempMediaSources)
        );

        yield put(ProjectsActions.createProjectTempVideoPreviews());

        // yield put(
        //     ModalsActions.showModal(
        //         ModalType.PROJECT_VIDEOS_UPLOADING_STATUS,
        //         ModalEvent.PROJECT_VIDEOS_UPLOADING_STATUS
        //     )
        // );

        yield put(ModalsActions.hideModal());

        yield put(ProjectsActions.getProjects({}));

        googleAnalytics.uploadMedia({
            media_type:
                transformedMediaFiles[0].mediaType === MediaType.AUDIO
                    ? 'audio'
                    : 'video',
            media_source: 'PC',
        });

        amplitudeAnalytics.mediaUploaded({
            media_type:
                transformedMediaFiles[0].mediaType === MediaType.AUDIO
                    ? 'audio'
                    : 'video',
            media_source: 'PC',
        });

        Analytics.record({
            name: 'video_upload',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });

        // yield delay(5000);

        yield call(syncProjectMedias);

        yield put(ProjectsActions.createProjectUpload({
            uploadMedias: transformedMediaFiles,
        }))

    } catch (error) {
        console.log({ error });
    }
}

export function* handleUploadProjectImage(
    image: File,
    canvasHeight: number,
    canvasWidth: number,
    isStockMedia: boolean | undefined,
    prevImageEndTime: number,
    id?: string,
): Generator {
    const { name } = image;

    const time = (yield select(getProjectTimelineTime)) as number;
    const userId = (yield select(getUserId)) as string;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const projectDuration = projectDetails.duration;

    const contentType = getUploadingFileContentType(name);

    const uploadRes = (yield call(Storage.put as any, `images/${name}`, image, {
        level: 'private',
        bucket: `${windowFork.config.REACT_APP_DOWNLOAD_TRAILER_BUCKET}`,
        contentType,
    })) as any;

    const imageDimension = (yield call(getImageDimension, image)) as {
        width: number;
        height: number;
    };

    const { width, height } = imageDimension;
    const imageAspectRatio = width / height;

    let startTime = time > projectDuration ? projectDuration - 0.1 : time;

    let endTime = startTime + getPercentageValue(10, projectDuration);
    if (getPercentageValue(10, projectDuration) < 1) {
        endTime = startTime + 1;
    }
    if (endTime > projectDuration) {
        endTime = projectDuration;
    }

    const imageWidth = canvasWidth * 0.25;
    const imageHeight = imageWidth / imageAspectRatio;

    const projectImage = {
        id: uuid(),
        startTime: startTime + prevImageEndTime,
        endTime: endTime + prevImageEndTime,
        s3ImagePath: `private/${userId}/${uploadRes.key}`,
        translationY: 0.5,
        translationX: 0.5,
        angle: 0,
        width: imageWidth / (canvasWidth),
        height: imageHeight / (canvasHeight),
        horizontMirror: false,
        verticalMirror: false,
        isStockMedia: !!isStockMedia,
        ...(isStockMedia ? { assetId: id } : {}),
    } as ProjectImage;

    return projectImage;
}

export function* handleUploadProjectImages(
    action: UploadProjectImageAction
): Generator {
    try {
        yield put(ProjectUploadActions.uploadProjectImageStart());

        const {
            images,
            canvasHeight,
            canvasWidth,
            isStockMedia,
            videosToUpload,
            id,
        } =
            action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const userId = (yield select(getUserId)) as string;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        let prevImage: ProjectImage | undefined;
        const promises: Promise<ProjectImage>[] = [];

        for (const image of images) {
            const prevImageEndTime = prevImage
                ? prevImage.endTime
                : 0;
            const promise = call(
                handleUploadProjectImage,
                image,
                canvasHeight,
                canvasWidth,
                isStockMedia,
                prevImageEndTime,
                id,
            ) as unknown;
            promises.push(promise as Promise<ProjectImage>);
            prevImage = (yield promise) as ProjectImage;
        }

        let projectImages: ProjectImage[] = (yield all(
            promises
        )) as ProjectImage[];

        if (projectImages.length === 1) {
            const zIndex = (yield getProjectItemZIndex(
                projectImages[0].startTime,
                projectImages[0].endTime
            )) as number;
            projectImages = [{ ...projectImages[0], zIndex }];
        } else {
            const zIndex = ((yield getProjectItemsMaxZIndex()) as number) + 1;
            projectImages = projectImages.map((image) => ({
                ...image,
                zIndex,
            }));
        }

        const updatedProject = {
            ...projectDetails,
            images: projectDetails.images
                ? [...projectDetails.images, ...projectImages]
                : projectImages,
        } as Project;

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
        yield put(ProjectUploadActions.uploadProjectImageSuccess());
        yield put(ModalsActions.hideModal());

        yield put(
            ProjectStudioActions.setSelectedShape({
                id: projectImages[projectImages.length - 1].id,
            })
        );

        if(videosToUpload) {
            yield put(ProjectUploadActions.uploadProjectVideo({
                videos: videosToUpload,
            }));
        }

        googleAnalytics.uploadMedia({
            media_type: 'image',
            media_source: isStockMedia ? 'Stock' : 'PC',
        });

        amplitudeAnalytics.mediaUploaded({
            media_type: 'image',
            media_source: isStockMedia ? 'Stock' : 'PC',
        });

        Analytics.record({
            name: 'image_upload',
            attributes: {
                userId,
                plan: currentPlan?.planNameId || '',
            },
        });
    } catch (error) {
        console.log({ error });
        yield put(ProjectUploadActions.uploadProjectImageFail());
    }
}

export function* handleUploadProjectAudio(
    action: UploadProjectAudioAction
): Generator {
    try {
        const uploadingMedia = action.payload;

        const userInfo = (yield select(getUserInfo)) as User;
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const currentWorkspace = (yield select(
            getCurrentWorkspaceInfo
        )) as Workspace;
        const userId = (yield select(getUserId)) as string;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        if (!currentPlan) return;

        const transformedStatuses = {
            id: uploadingMedia.id,
            currentProgress: 0,
            remainingAmount: 100,
            isLoadig: true,
        };

        yield put(UploadActions.setUploadingStatuses([transformedStatuses]));

        yield put(
            UploadActions.confirmUploading({
                media: [uploadingMedia],
                mediaType: UploadMediaType.AUDIO,
            })
        );

        yield put(
            ModalsActions.showModal(
                ModalType.PROJECT_VIDEOS_UPLOADING_STATUS,
                ModalEvent.PROJECT_VIDEOS_UPLOADING_STATUS
            )
        );

        yield take(ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO_SUCCESS);

        const projectAudioMediaSources = (yield select(
            getProjectAudioMediaSources
        )) as ProjectAudioMediaSource[];
        const rawMediaInfo = (yield call(getInfo, uploadingMedia.file)) as any;
        const audioTrack = rawMediaInfo.media.track.find(
            (track: any) => track[`@type`] === 'Audio'
        );
        const generalTrack = rawMediaInfo.media.track.find(
            (track: any) => track[`@type`] === 'General'
        );
        const generalMediaInfoTrack = transformGeneralTrack(generalTrack);
        const audioMediaInfoTrack = transformAudioTrack(audioTrack);

        const { duration } = generalMediaInfoTrack;

        const fileKey = [
            'private',
            userInfo.id,
            'workspace',
            currentWorkspace.id,
            'media',
            uploadingMedia.id,
            'audio',
            uploadingMedia.title,
        ].join('/');

        let newAudioMediaSource = {
            mediaInfo: audioMediaInfoTrack,
            s3Path: fileKey,
            userId: userInfo.id,
            // isDetached: true,
            isDetach: true,
        } as ProjectAudioMediaSource;

        const newAudioMediaSourceRes = (yield call(
            MediaClient.createAudioMediasSource,
            newAudioMediaSource
        )) as AxiosResponse;

        newAudioMediaSource = newAudioMediaSourceRes.data.content;

        const updatedAudioMediaSources = [
            ...projectAudioMediaSources,
            newAudioMediaSource,
        ];

        const updatedAudioMediaSourcesIds = updatedAudioMediaSources.map(
            (mediaSource) => mediaSource.id
        );
        //

        const newProjectAuido = {
            volume: 1,
            videoId: '',
            startTime: 0,
            trimStart: 0,
            endTime: duration,
            trimEnd: duration,
            s3AudioPath: newAudioMediaSource.s3Path,
            id: uuid(),
            speed: 1,
            mediaSourcesId: newAudioMediaSource.id,
            mediaInfo: audioMediaInfoTrack,
            name: uploadingMedia.title,
            projectTrackId: uuid(),
            isActive: true,
        } as ProjectAudio;

        const updatedProjectAudios = [
            ...projectDetails.audios,
            newProjectAuido,
        ];

        const updatedProjectDuration =
            duration > projectDetails.duration
                ? duration
                : projectDetails.duration;

        const updatedProject = {
            ...projectDetails,
            audios: updatedProjectAudios,
            audioMediaSorces: updatedAudioMediaSourcesIds,
            duration: updatedProjectDuration,
            isProjectDurationAutomatic: true,
        } as Project;

        yield put(
            ProjectsActions.setProjectAudioMediaSources(
                updatedAudioMediaSources
            )
        );

        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        Analytics.record({
            name: 'audio_upload',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
            },
        });
    } catch (error) {
        console.log({ error });
    }
}

export function* handleUploadProjectStockVideo(
    action: UploadProjectStockVideoAction
): Generator {
    const { media } = action.payload;

    yield put(ProjectUploadActions.setProjectStockVideoIdForUpload(media.id));

    try {
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        const sizesRes = (yield call(
            StockMediaClient.getStockVideoSizes,
            media.id
        )) as AxiosResponse;

        const clipLength = sizesRes.data.clip_length;
        const downloadSizes = sizesRes.data.download_sizes;
        const lastSize = downloadSizes[0] as any;
        let sizeName = lastSize.name;

        if(downloadSizes.some((item: any) => item.name === 'hd15')) {
            sizeName = 'hd15';
        } else if(downloadSizes.some((item: any) => item.name === 'lwf')) {
            sizeName = 'lwf';
        } else if(downloadSizes.some((item: any) => item.name === 'hd16')) {
            sizeName = 'hd16';
        }

        let uri = '';
        if (currentPlan?.planNameId !== PlanNameId.INITIAL) {
            const res = (yield call(
                StockMediaClient.downloadStockVideo,
                media.id,
                sizeName
            )) as AxiosResponse;

            uri = res.data.uri;
        } else {
            uri = media.display_sizes[0].uri;
        }

        const duration = timeToMs(clipLength);
        const durationInSec = duration / 1000;
        const fps = lastSize.frame_rate;
        const aspectRatio = lastSize.width / lastSize.height;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        let oldProjectVideos = (yield select(
            getProjectVideos
        )) as ProjectVideo[];

        const id = uuid();

        const newVideo = {
            mediaSourcesId: media.id,
            name: media.title,
            startTime: 0,
            trimStart: 0,
            endTime: durationInSec,
            trimEnd: durationInSec,
            id,
            volume: true,
            videoVolume: 1,
            isDummy: false,
            speed: 1,
            path: '',
            mediaInfo: {
                aspectRatio: `${aspectRatio}`,
                duration: durationInSec,
                frameCount: Math.ceil(durationInSec * fps),
                framerate: fps,
                height: lastSize.height,
                width: lastSize.width,
                // bitDepth: lastSize.bit_depth,
                bitDepth: 1,
                bitrate: 0,
                codec: lastSize.compression,
                profile: 'High@L5',
                colorSpace: 'YUV 4:2:0',
                scanType: 'Progressive',
            },
            isAudio: false,
            isStockMedia: true,
            stockMediaUrl: uri,
            stockVideoDownloadSize: sizeName,
            stockPreviewUrl: media.display_sizes[0].uri,
        } as ProjectVideo;

        const updatedProjectDuration =
            durationInSec > projectDetails.duration
                ? durationInSec
                : projectDetails.duration;

        let updatedProjectVideos = [];

        if (oldProjectVideos.length > 0) {
            const videoTracks = mediasToTrackMediasAdapter(oldProjectVideos);
            const trackKeys = Object.keys(videoTracks).sort((a, b) => +b - +a);
            const topKey = +trackKeys[0];

            if(trackKeys.length < VIDEO_TRACKS_LIMIT) {
                updatedProjectVideos.push(...oldProjectVideos, {
                    ...newVideo,
                    startTime: 0,
                    endTime: durationInSec,
                    zIndex: topKey + 1,
                });
            } else {
                const lastTrackItems = oldProjectVideos.filter(
                    item => !item.isAudio && (item.zIndex === topKey)
                );

                const lastVideo = lastTrackItems[lastTrackItems.length - 1];
                const { endTime } = lastVideo;

                updatedProjectVideos.push(...oldProjectVideos, {
                    ...newVideo,
                    startTime: endTime,
                    endTime: endTime + durationInSec,
                    zIndex: topKey,
                });
            }
        } else {
            updatedProjectVideos.push(newVideo);
        }

        const updatedProjectDetails = {
            ...projectDetails,
            videos: updatedProjectVideos,
            duration: updatedProjectDuration,
            isProjectDurationAutomatic: true,
        } as Project;

        googleAnalytics.uploadMedia({
            media_type: 'video',
            media_source: 'Stock',
        });

        amplitudeAnalytics.mediaUploaded({
            media_type: 'video',
            media_source: 'Stock',
        });

        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
        yield put(
            ProjectsActions.updateProject({
                project: updatedProjectDetails,
            })
        );

        yield put(ProjectUploadActions.setProjectStockVideoIdForUpload(null));

        yield put(StockMediaActions.getStockVideosThumbnailPreviews());
    } catch (error) {
        console.log({ error });

        yield put(ProjectUploadActions.setProjectStockVideoIdForUpload(null));
    }
}

// Stock Audio

export function* handleUploadProjectStockAudio(
    action: UploadProjectStockAudioAction
): Generator {
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentPlan?.planNameId === PlanNameId.INITIAL) {
        return yield call(handleStockMediaLimitations);
    }

    const audio = action.payload;

    //  (yield call(
    //       StockMediaClient.getSongById,
    //       audio.id
    //   )) as AxiosResponse;

    yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(audio.id));

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    try {
        const response = (yield fetch(
            `${audio.audio.attributes.versions.mp3}`
        )) as any;

        let data = (yield response.blob()) as any;
        const ext = data.type.split('/')[1];
        let metadata = {
            type: data.type,
        };

        let file = new File([data], `stock_audio.${ext}`, metadata);

        const info = (yield call(getInfo, file as any)) as any;

        const audioTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'Audio'
        );
        const generalTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'General'
        );
        const generalMediaInfoTrack = transformGeneralTrack(generalTrack);
        const audioMediaInfoTrack = transformAudioTrack(audioTrack);

        const { duration } = generalMediaInfoTrack;

        const id = uuid();

        const newProjectAuido = {
            s3AudioPath: '',
            mediaSourcesId: '',
            mediaInfo: audioMediaInfoTrack,
            id,
            volume: 1,
            videoId: '',
            startTime: 0,
            trimStart: 0,
            endTime: duration,
            trimEnd: duration,
            speed: 1,
            projectTrackId: uuid(),
            isActive: true,
            name: audio.attributes.title,
            isStockMedia: true,
            songId: audio.id,
            audioFileId: audio.audio.id,
            stockMediaUrl: audio.audio.attributes.versions.mp3,
            stockMediaType: 'song',
        } as ProjectAudio;

        const updatedProjectAudios = [
            ...projectDetails.audios,
            newProjectAuido,
        ];

        const updatedProjectDuration =
            duration > projectDetails.duration
                ? duration
                : projectDetails.duration;

        const updatedProject = {
            ...projectDetails,
            audios: updatedProjectAudios,
            duration: updatedProjectDuration,
            isProjectDurationAutomatic: true,
        } as Project;

        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));

        googleAnalytics.uploadMedia({
            media_type: 'audio',
            media_source: 'Stock_Music',
        });

        amplitudeAnalytics.mediaUploaded({
            media_type: 'audio',
            media_source: 'Stock_Music',
        });

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(null));
    } catch (error) {
        console.log({ error });
        yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(null));
    }
}
// Stock Sound Effect

export function* handleUploadProjectStockSoundEffect(
    action: UploadProjectStockSoundEffectAction
): Generator {
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentPlan?.planNameId === PlanNameId.INITIAL) {
        return yield call(handleStockMediaLimitations);
    }

    const audio = action.payload;

    //  (yield call(
    //       StockMediaClient.getSongById,
    //       audio.id
    //   )) as AxiosResponse;

    yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(audio.id));

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    try {
        const response = (yield fetch(
            `${audio.attributes.versions.mp3}`
        )) as any;

        let data = (yield response.blob()) as any;
        const ext = data.type.split('/')[1];
        let metadata = {
            type: data.type,
        };

        let file = new File([data], `stock_audio.${ext}`, metadata);

        const info = (yield call(getInfo, file as any)) as any;

        const audioTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'Audio'
        );
        const generalTrack = info.media.track.find(
            (track: any) => track[`@type`] === 'General'
        );
        const generalMediaInfoTrack = transformGeneralTrack(generalTrack);
        const audioMediaInfoTrack = transformAudioTrack(audioTrack);

        const { duration } = generalMediaInfoTrack;

        const id = uuid();

        const newProjectAuido = {
            s3AudioPath: '',
            mediaSourcesId: '',
            mediaInfo: audioMediaInfoTrack,
            id,
            volume: 1,
            videoId: '',
            startTime: 0,
            trimStart: 0,
            endTime: duration,
            trimEnd: duration,
            speed: 1,
            projectTrackId: uuid(),
            isActive: true,
            name: audio.attributes.title,
            isStockMedia: true,
            songId: audio.id,
            stockMediaUrl: audio.attributes.versions.mp3,
            stockMediaType: 'soundEffect',
        } as ProjectAudio;

        const updatedProjectAudios = [
            ...projectDetails.audios,
            newProjectAuido,
        ];

        const updatedProjectDuration =
            duration > projectDetails.duration
                ? duration
                : projectDetails.duration;

        const updatedProject = {
            ...projectDetails,
            audios: updatedProjectAudios,
            duration: updatedProjectDuration,
            isProjectDurationAutomatic: true,
        } as Project;

        yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));

        googleAnalytics.uploadMedia({
            media_type: 'audio',
            media_source: 'Stock_Effect',
        });

        amplitudeAnalytics.mediaUploaded({
            media_type: 'audio',
            media_source: 'Stock_Effect',
        });

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(null));
    } catch (error) {
        console.log({ error });
        yield put(ProjectUploadActions.setProjectStockAudioIdForUpload(null));
    }
}

// Stock image

export function* handleUploadProjectStockImage(
    action: UploadProjectStockImageAction
): Generator {
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (currentPlan?.planNameId === PlanNameId.INITIAL) {
        return yield call(handleStockMediaLimitations);
    }

    try {
        const image = action.payload;

        yield put(
            ProjectUploadActions.setProjectStockImageIdForUpload(image.id)
        );

        const sizesRes = (yield call(
            StockMediaClient.getStockImageSizes,
            image.id
        )) as AxiosResponse;

        const downloadSizes = sizesRes.data.images[0].download_sizes;

        const lastSize = downloadSizes[1] as any;
        const sizeHeight = lastSize.height;

        const res = (yield call(
            StockMediaClient.downloadStockImage,
            image.id,
            sizeHeight
        )) as AxiosResponse;

        const canvas = document.getElementById('ProjectPlayerCanvas');

        if (canvas) {
            const canvasHeight = canvas.clientHeight;
            const canvasWidth = canvas.clientWidth;
            const uri = res.data.uri;

            let response = (yield fetch(`${uri}`)) as any;
            let data = (yield response.blob()) as any;
            let metadata = {
                type: 'image/jpeg',
            };
            let file = new File([data], `${image.title}.jpg`, metadata);

            yield put(
                ProjectUploadActions.uploadProjectImage({
                    images: [file],
                    canvasWidth,
                    canvasHeight,
                    isStockMedia: true,
                    id: image.id,
                })
            );

            yield take(ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_SUCCESS);
        }

        yield put(ProjectUploadActions.setProjectStockImageIdForUpload(null));
    } catch (error) {
        console.log({ error });
        yield put(ProjectUploadActions.setProjectStockImageIdForUpload(null));
    }
}

export function* projectUploadSaga(): Generator {
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_VIDEO,
        handleUploadProjectVideo
    );
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE,
        handleUploadProjectImages
    );
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO,
        handleUploadProjectAudio
    );
    // stock video
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_VIDEO,
        handleUploadProjectStockVideo
    );
    // stock audio
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_AUDIO,
        handleUploadProjectStockAudio
    );
    // stock audio
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_SOUND_EFFECT,
        handleUploadProjectStockSoundEffect
    );
    // stock image
    yield takeLatest(
        ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_IMAGE,
        handleUploadProjectStockImage
    );
}
