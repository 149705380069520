import { Icon } from 'components/UI';
import styled from 'styled-components';
import { useMemo } from 'react';

import { CurrentSubscription, PaymentPeriodType, PlanNameId, Product } from 'state/modules/payment';

import styles from './styles.module.scss';
import { NEUTRAL_200, NEUTRAL_BLACK, WHITE_PRIMARY } from '../../styles/colors';
import { breakpoints } from '../../styles/sizes';

const PlanCardContainer = styled.div<{isRecommended?: boolean, isCurrentPlan: boolean}>`
    background: ${({ isRecommended, isCurrentPlan }) =>
            isRecommended ? '#F2C802' : 
                    isCurrentPlan ? '#F9F9FF' : WHITE_PRIMARY};
    box-shadow: ${({ isRecommended }) =>
            isRecommended ? '0 0 30px -3px #00000040' : 'none'};
    border:  ${({ isCurrentPlan }) =>
            isCurrentPlan ? '1px solid #0000001A' : 'none'};
    //padding: 36px 19px 19px 19px;
    padding: 0.938rem 0.938rem 0.625rem;
    display: flex;
    flex-direction: column;

    height: 100%;
    border-radius: 1.25rem;
    //align-items: center;
    //max-width: 18rem;
    width: auto;
    margin: 0 auto;
`;

const PlanCardHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`;

const PlanCardTitle = styled.p`
    font-family: 'Inter Bold', sans-serif;
    font-weight: 700;
    //font-size: 22px;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: ${NEUTRAL_BLACK};

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 1rem;
    }
`;

const RecommendedLabel = styled.div`
    position: absolute;
    right: 0.125rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 12px;
    background: #F3F4F5;
    margin-left: 0.938rem;

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 0.625rem;
        margin-left: 0;
    }

    @media (max-width: ${breakpoints.lg}) {
        font-size: 0.5rem;
        padding: 0.4rem;
        right: -0.5rem;
    }

    @media (max-width: ${breakpoints.md}) {
        //position: initial;
        //font-size: 0.625rem;
        //margin-left: 0;
        //margin-top: 0.375rem;
        display: none;
    }
`;

const PlanPriceInformation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-height: 860px) {
        height: 3rem;
    }
`;

const PlanPrice = styled.div`
    color: rgb(25, 32, 51);
    font-size: 1.5rem;
    //text-align: center;
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;

const PriceCurrency = styled.p`
    font-family: 'Inter Bold', sans-serif;
    font-weight: 700;
    //font-size: 31px;
    font-size: 1.688rem;
    //line-height: 39px;
    line-height: 2.188rem;
    text-align: left;

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const PriceAmount = styled.p`
    font-family: 'Inter Bold', sans-serif;
    font-weight: 700;
    //font-size: 64px;
    font-size: 3.375rem;
    //line-height: 78px;
    line-height: 4.375rem;
    text-align: left;

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 3rem;
        line-height: 4rem;
    }
`;

const PriceDescriptionContainer = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 0.563rem;
    margin-left: 0.313rem;
    justify-content: center;

    @media (max-width: ${breakpoints.md}) {
        font-size: 0.75rem;
        line-height: 0.876rem;
    }
`;

const PriceDescription = styled.p``;

const PlanChangeButtonContainer = styled.div`
    //margin-top: 32px;
    margin: 0.938rem 0;
    
    width: 100%;
    //padding: 0 2rem 1rem;
    display: flex;
    flex-direction: column;

    @media (max-height: 800px) {
        flex-direction: column-reverse;
    }

    @media (max-width: ${breakpoints.xlg}) {
        margin: 0.75rem 0;
    }
`;

const CurrentPlanIndicatorContainer = styled.div`
    //height: 60px;
    height: 3.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    color: #E33EAC;
    font-family: 'Inter Medium', sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.688rem;
    //margin-bottom: 10px;

    @media (max-width: ${breakpoints.xlg}) {
        height: 3rem;
        line-height: 1.25rem;
    }
`;

const SubmitButton = styled.button<{
    isCurrentPlan?: boolean,
    isRecommended?: boolean,
    isBusiness?: boolean,
    isDowngrade?: boolean,
}>`
    //height: 60px;
    height: 3.5rem;
    border-radius: 12px;
    
    width: 100%;
    background: ${({ isBusiness, isDowngrade }) =>
            (isBusiness && !isDowngrade) ? NEUTRAL_BLACK : WHITE_PRIMARY};
    border: ${({ isRecommended, isDowngrade }) =>
            isRecommended && !isDowngrade
            ? 'none'
            : '1px solid #6D7488'};
    transition: border 0.2s ease-in-out 0s;
    color: ${({ isBusiness, isDowngrade }) => 
            isDowngrade ? '#6D7488' : 
            isBusiness ? WHITE_PRIMARY : NEUTRAL_BLACK};
    padding: 0 1rem;
    display: flex;
    min-width: 5.438rem;
    justify-content: center;
    align-items: center;
    user-select: none;
    //margin-bottom: 30px;
    //margin-bottom: 10px;
    cursor: pointer;
    text-decoration: none;

    font-family: 'Inter Medium', sans-serif;
    //font-size: 20px;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;

    &:not(:disabled):hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0 5px 6px;
    }

    &:disabled {
        background-color: ${NEUTRAL_200};
        border-color: ${NEUTRAL_200};
        cursor: not-allowed;
    }

    @media (max-width: ${breakpoints.xlg}) {
        height: 3rem;
        line-height: 1.25rem;
    }
`;

const PlanDescriptionWrapper = styled.div`
    width: 100%;
    //margin-bottom: 28px;
    margin-bottom: 1.563rem;
`;

const PlanDescription = styled.p`
    font-family: 'Inter Bold', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.188rem;

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 0.875rem;
        line-height: 1rem;
    }
`;

const PlanFeaturesListWrapper = styled.div`
    //height: 100%;
    //padding: 0 1rem;
    //margin-bottom: 0.5rem;
`;

const PlanFeaturesList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;

    @media (max-height: 800px) {
        > div:nth-child(6) {
            display: none;
        }

        > div:nth-child(7) {
            display: none;
        }
    }
`;

const PlanFeature = styled.div`
    font-family: 'Inter Light', sans-serif;
    //font-size: 16px;
    font-size: 0.938rem;
    font-weight: 300;
    //line-height: 19px;
    line-height: 1.063rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;

    @media (max-height: 860px) {
        margin-bottom: 0.5rem;
    }

    @media (max-width: ${breakpoints.xlg}) {
        font-size: 0.813rem;
        line-height: 0.875rem;
    }
`;

const FeatureIcon = styled.div`
    width: 1.125rem;
    height: 1.125rem;
    background: rgb(255, 255, 255);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-right: 0.5rem;
`;

interface Props {
    data: Product;
    onSelect?: (plan: Product) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    currentUserPlan?: CurrentSubscription | null;
    isCanceled?: boolean;
    selectedPeriodOption: PaymentPeriodType;
}

const PlanCard = ({
    data,
    onSelect,
    isDisabled,
    isLoading,
    currentUserPlan,
    isCanceled,
    selectedPeriodOption
}: Props): JSX.Element => {
    const { prices, planNameId } = data;

    const isMonthlySelected = useMemo(
        () => selectedPeriodOption === PaymentPeriodType.MONTHLY,
        [selectedPeriodOption]
    );

    const isCurrentPlan = useMemo(() => {
        const currentPlanId = currentUserPlan?.planNameId;

        return currentPlanId === planNameId;
    }, [planNameId, currentUserPlan]);

    const isUpgrade = useMemo(() => {
        const currentPlanId = currentUserPlan?.planNameId;
        const planId = data.planNameId as PlanNameId;

        if(currentPlanId === planId) {
            const isFree = currentPlanId === PlanNameId.INITIAL;
            const isMonthly = selectedPeriodOption === PaymentPeriodType.MONTHLY;
            const isCurrentYearly = currentUserPlan?.price?.interval === PaymentPeriodType.YEARLY;

            if(!isFree && isMonthly && isCurrentYearly) {
                return false;
            }
        }

        if (planId === PlanNameId.BASIC) {
            if (
                currentPlanId === PlanNameId.INITIAL ||
                currentPlanId === PlanNameId.BASIC
            ) {
                return true;
            }
        }

        if (planId === PlanNameId.PROFESSIONAL) {
            if (currentPlanId === PlanNameId.BUSINESS) {
                return false;
            }
            return true;
        }

        if (planId === PlanNameId.BUSINESS) {
            return true;
        }

        return false;
    }, [currentUserPlan, data, selectedPeriodOption]);

    const isRenderRecommended = useMemo(() => {
        return (
            planNameId === PlanNameId.PROFESSIONAL &&
            (currentUserPlan?.planNameId !== PlanNameId.BUSINESS)
        );
    }, [planNameId, currentUserPlan]);

    const getTitle = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'Free';
            case PlanNameId.BASIC:
                return 'Basic';
            case PlanNameId.PROFESSIONAL:
                return 'Professional';
            case PlanNameId.BUSINESS:
                return 'Business';
            default:
                return '';
        }
    };

    const getDescription = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'For quick projects and short content without leaving credit card details.';
            case PlanNameId.BASIC:
                return 'Perfect for individuals that need simple video editing capabilities.';
            case PlanNameId.PROFESSIONAL:
                return 'For professionals and teams working with video and audio content daily.';
            case PlanNameId.BUSINESS:
                return 'For companies managing large volumes of video and audio content.';
            default:
                return '';
        }
    };

    const getFeaturesList = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return [
                    'Watermarked',
                    '30 min of subtitles',
                    '10 min of dubbing',
                    'Upload limit - 2GB',
                    'Upload/Export length - 10 min',
                    '1,000 characters text-to-speech',
                ];
            case PlanNameId.BASIC:
                return [
                    `${isMonthlySelected ? '150' : '1,800'} min of subtitles / translation`,
                    `${isMonthlySelected ? '60' : '720'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 60 min',
                    'Storage 100 GB',
                    'SRT/VTT/TXT subtitles download',
                    '6,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            case PlanNameId.PROFESSIONAL:
                return [
                    `${isMonthlySelected ? '300' : '3,600'} min of subtitles / translation`,
                    `${isMonthlySelected ? '120' : '1,440'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 150 min',
                    'Storage 500 GB',
                    'SRT/VTT/TXT subtitles download',
                    '15,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            case PlanNameId.BUSINESS:
                return [
                    `${isMonthlySelected ? '900' : '10,800'} min of subtitles / translation`,
                    `${isMonthlySelected ? '360' : '4,320'} min of dubbing`,
                    'Upload file size - unlimited',
                    'Upload/Export length - 450 minutes',
                    'Storage 2 TB',
                    'SRT/VTT/TXT subtitles download',
                    '40,000 characters text-to-speech',
                    'Unlimited stock media',
                ];
            default:
                return [];
        }
    };

    const getPrice = () => {
        if (planNameId === PlanNameId.ENTERPRISE) {
            return 'Contact Us';
        }

        const isMonthly = selectedPeriodOption === PaymentPeriodType.MONTHLY;

        const price = prices?.find(
            (priceInfo) => isMonthly ? (priceInfo.interval === 'month') : (priceInfo.interval === 'year')
        );

        let priceValue = '';

        if (price) {
            if(isMonthly) {
                priceValue = `${price.amount / 100}`;
            } else {
                priceValue =  `${Math.floor(price.amount / 100 / 12)}`;
            }
        }

        if (!price) {
            priceValue = '0';
        }

        return priceValue;
    };

    const handleContinue = () => {
        if (onSelect) {
            onSelect(data);
        }
    };

    const renderRecommended = () => {
        return (
            <RecommendedLabel>Recommended</RecommendedLabel>
        );
    };

    const renderTitle = () => {
        const title = getTitle();

        return (
            <PlanCardTitle>{title}</PlanCardTitle>
        );
    };

    const renderPrice = () => {
        const price = getPrice();

        return (
            <PlanPrice>
                <PriceCurrency>$</PriceCurrency>
                <PriceAmount>{price}</PriceAmount>
            </PlanPrice>
        );
    };

    const renderContinueButton = () => {
        // if (planNameId === PlanNameId.ENTERPRISE) {
        //     return <Link href="mailto:nova@wearenova.ai">Contact Us</Link>;
        // }
        let title = isUpgrade ? 'Upgrade' : 'Downgrade';

        if (planNameId === PlanNameId.INITIAL) {
            title = 'Downgrade';
        }

        if (isLoading) {
            title = 'Loading...';
        }

        const isCurrentPlanWithPeriod = isCurrentPlan && (
            (planNameId === PlanNameId.INITIAL || planNameId === PlanNameId.TRIAL) ||
            (currentUserPlan?.price.interval === selectedPeriodOption)
        );

        return !isCanceled ? (
            isCurrentPlanWithPeriod ? (
                    <CurrentPlanIndicatorContainer>Current plan</CurrentPlanIndicatorContainer>
                    ) : (
                    <SubmitButton
                        isDowngrade={!isUpgrade && !isLoading}
                        onClick={handleContinue}
                        disabled={isDisabled}
                        isCurrentPlan={isCurrentPlan}
                        id={`${planNameId}-plan-change`}
                        isRecommended={isRenderRecommended}
                        isBusiness={planNameId === PlanNameId.BUSINESS}
                    >
                        {title}
                    </SubmitButton>
                )

        ) : null;
    };

    const renderDescription = () => {
        const description = getDescription();

        return <PlanDescription>{description}</PlanDescription>;
    };

    const renderFeature = (feature: string) => {
        let checkMainColor = '#9DA2AC';
        let checkSecondaryColor = WHITE_PRIMARY;

        const isBusiness = planNameId === PlanNameId.BUSINESS;

        if(isCurrentPlan) {
            checkMainColor = '#E33EAC';
        } else if(isBusiness) {
            checkMainColor = NEUTRAL_BLACK;
        } else if(isRenderRecommended) {
            checkMainColor = WHITE_PRIMARY;
            checkSecondaryColor = NEUTRAL_BLACK;
        }

        return (
            <PlanFeature key={feature}>
                <FeatureIcon className={styles.PlanCard__featureIcon}>
                    <Icon name="check-new" size={18} color={checkMainColor} secondColor={checkSecondaryColor}/>
                </FeatureIcon>
                {feature}
            </PlanFeature>
        );
    };

    const renderFeaturesList = () => {
        const features = getFeaturesList();

        return (
                <PlanFeaturesList>
                    {features.map((feature) => renderFeature(feature))}
                </PlanFeaturesList>
        );
    };

    const renderPlanHeader = () => {
        return (
            <PlanCardHeader>
                {renderTitle()}
                {
                    isRenderRecommended && (
                        renderRecommended()
                    )
                }
            </PlanCardHeader>
        );
    };

    const renderPriceDescription = () => {
        const isMonthly = selectedPeriodOption === PaymentPeriodType.MONTHLY;

        if(isMonthly) {
            return (
                <PriceDescriptionContainer>
                    <PriceDescription>
                        Per user, per month
                    </PriceDescription>
                    <PriceDescription>
                        Billed monthly
                    </PriceDescription>
                </PriceDescriptionContainer>
            );
        }

        const price = prices?.find(
            (priceInfo) => (priceInfo.interval === PaymentPeriodType.YEARLY)
        );

        let priceValue = '';

        if (price) {
            priceValue = `${price.amount / 100}`;
        }

        if (!price) {
            priceValue = '0';
        }

        return (
            <PriceDescriptionContainer>
                <PriceDescription>
                    Per user, per month
                </PriceDescription>
                <PriceDescription>
                    Billed ${priceValue} annually
                </PriceDescription>
            </PriceDescriptionContainer>
        );
    }

    const renderPriceInfo = () => {
        return (
            <PlanPriceInformation>
                {renderPrice()}
                {
                    planNameId !== PlanNameId.INITIAL && (
                        renderPriceDescription()
                    )
                }
            </PlanPriceInformation>
        )
    };

    return (
        <PlanCardContainer
            isRecommended={isRenderRecommended}
            isCurrentPlan={isCurrentPlan}
        >
            {renderPlanHeader()}
            {renderPriceInfo()}
            <PlanChangeButtonContainer>
                {renderContinueButton()}
            </PlanChangeButtonContainer>
            <PlanDescriptionWrapper>
                {renderDescription()}
            </PlanDescriptionWrapper>
            <PlanFeaturesListWrapper>
                {renderFeaturesList()}
            </PlanFeaturesListWrapper>
        </PlanCardContainer>
    );
};

export default PlanCard;
