import { IconProps } from './index';

const CutIcon = ({ size, color }: IconProps) => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_424_4306)">
                <path
                    d="M10 7.5L8.45703 5.95703C8.64844 5.46484 8.75 4.93359 8.75 4.375C8.75 1.95703 6.79297 0 4.375 0C1.95703 0 0 1.95703 0 4.375C0 6.79297 1.95703 8.75 4.375 8.75C4.93359 8.75 5.46484 8.64453 5.95703 8.45703L7.5 10L5.95703 11.543C5.46484 11.3516 4.93359 11.25 4.375 11.25C1.95703 11.25 0 13.207 0 15.625C0 18.043 1.95703 20 4.375 20C6.79297 20 8.75 18.043 8.75 15.625C8.75 15.0664 8.64453 14.5352 8.45703 14.043L19.5 3C19.7773 2.72266 19.7773 2.27734 19.5 2C18.3945 0.894531 16.6055 0.894531 15.5 2L10 7.5ZM10.8828 13.3828L15.5 18C16.6055 19.1055 18.3945 19.1055 19.5 18C19.7773 17.7227 19.7773 17.2773 19.5 17L13.3828 10.8828L10.8828 13.3828ZM6.25 4.375C6.25 5.41016 5.41016 6.25 4.375 6.25C3.33984 6.25 2.5 5.41016 2.5 4.375C2.5 3.33984 3.33984 2.5 4.375 2.5C5.41016 2.5 6.25 3.33984 6.25 4.375ZM4.375 17.5C3.33984 17.5 2.5 16.6602 2.5 15.625C2.5 14.5898 3.33984 13.75 4.375 13.75C5.41016 13.75 6.25 14.5898 6.25 15.625C6.25 16.6602 5.41016 17.5 4.375 17.5Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_424_4306">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CutIcon;
