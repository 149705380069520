import { IconProps } from './index';

const TrashIcon = ({ size, color }: IconProps): JSX.Element => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.28125 0.691406L7 1.25H3.25C2.55859 1.25 2 1.80859 2 2.5C2 3.19141 2.55859 3.75 3.25 3.75H18.25C18.9414 3.75 19.5 3.19141 19.5 2.5C19.5 1.80859 18.9414 1.25 18.25 1.25H14.5L14.2188 0.691406C14.0078 0.265625 13.5742 0 13.1016 0H8.39844C7.92578 0 7.49219 0.265625 7.28125 0.691406ZM18.25 5H3.25L4.07812 18.2422C4.14062 19.2305 4.96094 20 5.94922 20H15.5508C16.5391 20 17.3594 19.2305 17.4219 18.2422L18.25 5Z"
                fill={color}
            />
        </svg>
    );
};

export default TrashIcon;
