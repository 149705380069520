import { IconProps } from '../index';

const AddMediaIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0003 10.6667V21.3334M10.667 16H21.3337M29.3337 16C29.3337 23.3638 23.3641 29.3334 16.0003 29.3334C8.63653 29.3334 2.66699 23.3638 2.66699 16C2.66699 8.63622 8.63653 2.66669 16.0003 2.66669C23.3641 2.66669 29.3337 8.63622 29.3337 16Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


export default AddMediaIconNew;


