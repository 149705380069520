import { createSelector } from 'reselect';
import { State } from 'state/modules';
import { ModalState } from 'state/modules/modal';

export const getModalState = (state: State): ModalState => state.modal;

export const getModalOptionalData = createSelector(
  getModalState,
  (state) => state.optionalData,
);

export const getModalOptionalText = createSelector(
  getModalState,
  (state) => state.optionalText,
);

export const getModalVisibility = createSelector(
  getModalState,
  (state) => state.isModalVisible,
);

export const getModalType = createSelector(
  getModalState,
  (state) => state.modalType,
);

export const getModalEvent = createSelector(
  getModalState,
  (state) => state.event,
);

export const getModalBackRoute = createSelector(
  getModalState,
  (state) => state.backRoute,
);
