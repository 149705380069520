import { IconProps } from '../index';

const SoundWaveIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.33301 12.5002V7.50016M9.99967 16.6668V3.3335M6.66634 14.1668V5.8335M16.6663 7.50016V12.5002M13.333 5.8335L13.333 14.1668"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


export default SoundWaveIcon;


