import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import difference from "lodash.difference";
import uniqBy from "lodash.uniqby";

import {
  getExportList,
  getItemsToExport,
  getItemsToExportByVideoId,
  ExportActionTypes,
  MetadataExportItem,
  SelectItemToExportAction,
} from "state/modules/export";
import {
  getVideoExplorerSelectedItems,
  SelectedMetadataItem,
} from "state/modules/videoExplorer";
import { getVideoInfo } from "state/modules/media";
import {
  AnalysisResultTimestamp,
  AnalysisResultTimestampItem,
} from "state/modules/metadata";

import * as exportActions from "state/modules/export/actions";
import * as generationActions from "state/modules/generation/actions";
import * as jobsActions from "state/modules/jobs/actions";

import { MediaFile } from "interfaces/videos";
import { ClipTemplate, Trailer } from "interfaces/generation";
import { reducer } from "utils/calc";
import sortBy from "lodash.sortby";
import { getFormattedScenesForGeneration } from "utils/generatorHelpres";
import TrailersClient from "services/api/trailers";
import { AxiosResponse } from "axios";
import VideosClient from "services/api/videos";
import JobsClient from "services/api/jobs";
import { GetJobsByVideoIdResponse } from "models/jobs";
import { checkHasJobsInProgress } from "utils/jobs";
import {
  AddSpecificSceneToExportAction,
  AddVideoUsedForExportAction,
  CancelBurningsSubtitlesJobAction,
  ExportAsVideoParams,
  ExportSelectedMetadataAsVideoAction,
  ExportSubtitledVideoAction,
  GroupedByVideoExportItem,
  RemoveExportItemAction,
  SelectMultipleItemsToExportAction,
  ToggleItemToExportAction,
  UnselectItemFromExportAction,
} from "./types";
import {
  getExportAsVideoLoading,
  getExportAsVideoParams,
  getGroupedExportList,
  getVideosUsedForExport,
} from "./selectors";
import {
  ClipGenerationData,
  ClipOrder,
  generateClipPreviewsBySelectedTemplate,
  getPreviewsList,
  getTrailersList,
} from "../generation";
import {
  ClipRenderingJob,
  getBurningSubtitlesInProgressJobs,
  getClipPreviewsGenerationJobs,
  getClipRenderingInProgressJobs,
  Job,
} from "../jobs";

function* handleSelectItemToExport(
  action: SelectItemToExportAction
): Generator {
  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<MetadataExportItem>;

  const updatedItemsToExport = uniqBy([...itemsToExport, action.payload], "id");

  yield put(exportActions.setItemsToExport(updatedItemsToExport));
}

function* handleSelectMultipleItemsToExport(
  action: SelectMultipleItemsToExportAction
): Generator {
  const oldItemsToExport = (yield select(
    getItemsToExport
  )) as Array<MetadataExportItem>;
  const newItems = action.payload as Array<MetadataExportItem>;

  const filteredOldItemsToExport = oldItemsToExport.filter(
    (item: MetadataExportItem) => {
      const isPresentInNewItems = newItems.find(
        (newItem: MetadataExportItem) => item.id === newItem.id
      );

      if (!isPresentInNewItems) {
        return item;
      }
    }
  );

  const updatedItemsToExport = [...filteredOldItemsToExport, ...newItems];

  yield put(exportActions.setItemsToExport(updatedItemsToExport));
}

function* handleUnselectItemToExport(
  action: UnselectItemFromExportAction
): Generator {
  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<MetadataExportItem>;

  const updatedItemsToExport = itemsToExport.filter(
    (item: MetadataExportItem) => item.id !== action.payload.id
  );

  yield put(exportActions.setItemsToExport(updatedItemsToExport));
}

function* handleToggleAllItemsToExport(): Generator {
  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<MetadataExportItem>;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<MetadataExportItem>;

  const isAllItemsSelectedToExport =
    itemsToExport.length === selectedItems.length;

  if (isAllItemsSelectedToExport) {
    yield put(exportActions.setItemsToExport([]));
  } else {
    const notSelectedItemsToExport = difference(selectedItems, itemsToExport);

    yield put(
      exportActions.setItemsToExport([
        ...itemsToExport,
        ...notSelectedItemsToExport,
      ])
    );
  }
}

function* handleConfirmExportListUpdate(): Generator {
  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<SelectedMetadataItem>;
  const exportList = (yield select(getExportList)) as Array<MetadataExportItem>;
  const videoDetails = (yield select(getVideoInfo)) as MediaFile;

  const transformedItemsToExport = itemsToExport.map(
    (item: SelectedMetadataItem) => ({
      ...item,
      videoId: videoDetails.id,
      videoName: videoDetails.title || videoDetails.filename,
    })
  );

  const updatedExportList = uniqBy(
    [...exportList, ...transformedItemsToExport],
    "id"
  );

  yield put(exportActions.setExportList(updatedExportList));
}

function* handleRemoveExportItem(action: RemoveExportItemAction): Generator {
  const exportList = (yield select(getExportList)) as Array<MetadataExportItem>;

  const updatedExportList = exportList.filter(
    (item: MetadataExportItem) => item.id !== action.payload.id
  );

  yield put(exportActions.setExportList(updatedExportList));
}

function* handleRemoveAllItemsToExport(): Generator {
  const videoDetails = (yield select(getVideoInfo)) as MediaFile;
  const itemsToExport = (yield select(
    getItemsToExportByVideoId(videoDetails?.id || "")
  )) as Array<SelectedMetadataItem>;

  const updatedListItemsToExport = itemsToExport.filter(
    (item: SelectedMetadataItem) => item.videoId !== videoDetails.id
  );

  yield put(exportActions.setItemsToExport(updatedListItemsToExport));
}

function* handleToggleItemToExport(
  action: ToggleItemToExportAction
): Generator {
  const itemToExport = action.payload;

  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<SelectedMetadataItem>;
  let updatedItemsToExport = [...itemsToExport] as Array<SelectedMetadataItem>;

  const isSelectedToExport =
    itemsToExport.findIndex(
      (item: SelectedMetadataItem) => item.id === itemToExport.id
    ) >= 0;

  if (isSelectedToExport) {
    updatedItemsToExport = updatedItemsToExport.filter(
      (item: SelectedMetadataItem) => item.id !== itemToExport.id
    );
  } else {
    updatedItemsToExport = [...updatedItemsToExport, itemToExport];
  }

  yield put(exportActions.setItemsToExport(updatedItemsToExport));
}

function* handleAddSpecificSceneToExport(
  action: AddSpecificSceneToExportAction
): Generator {
  const exportList = (yield select(getExportList)) as Array<MetadataExportItem>;
  const itemsToExport = (yield select(
    getItemsToExport
  )) as Array<SelectedMetadataItem>;
  const scene = action.payload;

  const selectedItemToExport = itemsToExport.find(
    (itemToExport: SelectedMetadataItem) =>
      itemToExport.labelType === scene.type && itemToExport.name === scene.name
  );

  if (selectedItemToExport) {
    const listedForExportItemIndex = exportList.findIndex(
      (listItem: MetadataExportItem) => listItem.id === selectedItemToExport.id
    );
    let updatedExportList = [...exportList] as Array<MetadataExportItem>;

    if (listedForExportItemIndex >= 0) {
      updatedExportList = updatedExportList.map(
        (item: MetadataExportItem, index: number) => {
          if (index === listedForExportItemIndex) {
            const selectedTimestamps = selectedItemToExport.timestamps.filter(
              (timestampItem: AnalysisResultTimestamp) =>
                timestampItem.timestamp === scene.from ||
                timestampItem.timestamp === scene.to
            );

            const filteredScenes = uniqBy([...item.scenes, scene], "id");

            const filteredTimestamps = uniqBy(
              [...item.timestamps, ...selectedTimestamps],
              "id"
            ) as Array<AnalysisResultTimestampItem>;

            return {
              ...item,
              scenes: filteredScenes,
              timestamps: filteredTimestamps,
            };
          }
          return item;
        }
      );
    } else {
      const updatedItem = {
        ...selectedItemToExport,
        timestamps: selectedItemToExport.timestamps.filter(
          (timestampItem: AnalysisResultTimestamp) =>
            timestampItem.timestamp === scene.from ||
            timestampItem.timestamp === scene.to
        ),
        scenes: [scene],
      } as MetadataExportItem;

      updatedExportList.push(updatedItem);
    }

    yield put(exportActions.setExportList(updatedExportList));
  }
}

function* exportVideoMetadataAsVideo(
  metadata: GroupedByVideoExportItem,
  order: ClipOrder
): Generator {
  const { data, videoId } = metadata;

  const currentMediaDetails = (yield select(getVideoInfo)) as MediaFile;
  const isCurrentVideo = currentMediaDetails.id === videoId;

  const transformedMetadata = data.map((item) => item.scenes).flat();

  const totalShotsDurationArray = transformedMetadata.map(
    (item) => item.duration
  );
  const sortedByDurationShots = sortBy(transformedMetadata, "duration");

  const hasShotWithZeroFromValue =
    transformedMetadata.findIndex((item) => item.from === 0) >= 0;

  const totalShotsDuration = totalShotsDurationArray.reduce(reducer, 0);

  const formatedScenes = getFormattedScenesForGeneration(transformedMetadata);

  const jobId = yield call(generateClipPreviewsBySelectedTemplate, {
    videoId,
    template: ClipTemplate.CUSTOM,
    customClipData: {
      clipShots: formatedScenes,
      minSceneLength: hasShotWithZeroFromValue
        ? sortedByDurationShots[0].duration - 50
        : sortedByDurationShots[0].duration,
      maxSceneLength: hasShotWithZeroFromValue
        ? sortedByDurationShots[sortedByDurationShots.length - 1].duration - 50
        : sortedByDurationShots[sortedByDurationShots.length - 1].duration,

      minClipLength: hasShotWithZeroFromValue
        ? totalShotsDuration - 50
        : totalShotsDuration,
      maxClipLength: totalShotsDuration + 10000,
      isMuted: false,
      order,
      minConfidence: 0,
      minScaleFactor: 0,
      numberOfClips: 1,
    },
  });

  if (isCurrentVideo) {
    const exportAsVideoParams = (yield select(
      getExportAsVideoParams
    )) as ExportAsVideoParams;

    yield put(
      exportActions.setExportAsVideoParams({
        ...exportAsVideoParams,
        jobId: typeof jobId === "string" ? jobId : null,
        videoId,
        isPreviewGenerationInProgress: true,
        isClipGenerationInProgress: false,
        isClipDownloaded: false,
      })
    );
  }
}

function* handleExportSelectedMetadataAsVideo(
  action: ExportSelectedMetadataAsVideoAction
): Generator {
  const { order } = action.payload;
  try {
    yield put(exportActions.exportSelectedMetadataAsVideoStart());

    const currentMediaDetails = (yield select(getVideoInfo)) as MediaFile;
    const groupedExportItems = (yield select(
      getGroupedExportList
    )) as Array<GroupedByVideoExportItem>;

    const currentVideoExportItems = groupedExportItems.find(
      (item) => item.videoId === currentMediaDetails.id
    );

    if (currentVideoExportItems) {
      yield call(exportVideoMetadataAsVideo, currentVideoExportItems, order);
    }
  } catch (error) {
    yield put(exportActions.exportSelectedMetadataAsVideoFail());
    console.log({ error });
  }
}

function* handleUpdateExportAsVideoParams(): Generator {
  const exportParams = (yield select(
    getExportAsVideoParams
  )) as ExportAsVideoParams;

  if (exportParams) {
    const clipPreviewsGenerationJobs = (yield select(
      getClipPreviewsGenerationJobs
    )) as Array<Job>;
    const clipRenderingInProgressJobs = (yield select(
      getClipRenderingInProgressJobs
    )) as Array<ClipRenderingJob>;
    const previewsList = (yield select(
      getPreviewsList
    )) as Array<ClipGenerationData>;
    const clipsList = (yield select(getTrailersList)) as Array<Trailer>;
    const isLoading = yield select(getExportAsVideoLoading);

    const preview = previewsList.find(
      (item) => item.jobId === exportParams?.jobId
    );
    const clip = clipsList.find((item) => item.jobId === exportParams?.jobId);
    const isPreviewGenerationInProgress =
      clipPreviewsGenerationJobs.findIndex(
        (item) => item.jobId === exportParams?.jobId
      ) >= 0;
    const isClipGenerationInProgress =
      clipRenderingInProgressJobs.findIndex(
        (item) => item.clip.jobId === exportParams?.jobId
      ) >= 0;

    let updatedParams = {
      ...exportParams,
      isPreviewGenerationInProgress,
      isClipGenerationInProgress,
      preview: preview || null,
      clip: clip || null,
    } as ExportAsVideoParams;

    if (preview && !isClipGenerationInProgress && !clip) {
      yield put(
        generationActions.generateClips({
          videoId: exportParams.videoId || "",
          selectedClips: preview?.clips || [],
          previewsData: preview,
        })
      );

      updatedParams = {
        ...updatedParams,
        isClipGenerationInProgress: true,
      };
    }

    if (clip && !exportParams?.clipDownloadLink) {
      const downloadLinkRes = (yield call(
        TrailersClient.downloadTrailer,
        updatedParams.videoId || "",
        clip?.id || ""
      )) as AxiosResponse;

      const link = downloadLinkRes.data.url;

      updatedParams = {
        ...updatedParams,
        isClipGenerationInProgress: false,
        clipDownloadLink: link,
      };
    }

    if (isLoading && clip && updatedParams.clipDownloadLink) {
      yield put(exportActions.exportSelectedMetadataAsVideoSuccess());
    }

    yield put(exportActions.setExportAsVideoParams(updatedParams));
  }
}

function* handleSyncVideoSubtitlingStatus(): Generator {
  const currentMediaDetails = (yield select(getVideoInfo)) as MediaFile;

  try {
    const jobsRes = (yield call(
      JobsClient.getJobsByVideoId,
      currentMediaDetails.id
    )) as AxiosResponse<GetJobsByVideoIdResponse>;

    const jobsList = jobsRes.data.content;

    yield put(jobsActions.setVideoJobs(jobsList));

    const hasJobsInProgress = checkHasJobsInProgress(jobsList);

    if (hasJobsInProgress) {
      yield delay(30000);

      yield put(exportActions.syncVideoSubtitlingStatus());
    } else {
      const subtitlesDataRes = (yield call(
        VideosClient.getVideoSubtitles,
        currentMediaDetails.id || ""
      )) as AxiosResponse;

      const subtitlesData = subtitlesDataRes.data;

      yield put(exportActions.setSubtitledVideoData(subtitlesData));

      yield delay(1500);

      const url = subtitlesData?.signetUrl;
      const link = document.createElement("a");

      link.setAttribute(
        "download",
        currentMediaDetails.title || currentMediaDetails.filename
      );
      link.href = url;

      document.body.appendChild(link);

      link.click();
    }
  } catch (error) {
    console.log({ error });
  }
}

function* handleExportSubtitledVideo(
  action: ExportSubtitledVideoAction
): Generator {
  yield put(exportActions.setExportSubtitledVideoLoading(true));

  const { language, videoId, fontSize, color } = action.payload;

  try {
    (yield call(
      VideosClient.startBurningSubtitles,
      videoId,
      color,
      fontSize,
      language
    )) as AxiosResponse;

    yield delay(10000);

    yield put(jobsActions.getVideoJobs(videoId));

    yield delay(20000);

    yield put(exportActions.setExportSubtitledVideoLoading(false));

    yield put(exportActions.syncVideoSubtitlingStatus());
  } catch (error) {
    yield put(exportActions.setExportSubtitledVideoLoading(false));
  }
}

function* handleGetSubtitledVideoData(): Generator {
  yield put(exportActions.setSubtitledVideoDataLoading(true));
  const currentMediaDetails = (yield select(getVideoInfo)) as MediaFile;
  const burningSubtitlesInProgressJobs = (yield select(
    getBurningSubtitlesInProgressJobs
  )) as Job[];

  try {
    const res = (yield call(
      VideosClient.getVideoSubtitles,
      currentMediaDetails.id || ""
    )) as AxiosResponse;

    yield put(exportActions.setSubtitledVideoData(res.data));

    yield put(exportActions.setSubtitledVideoDataLoading(false));

    if (burningSubtitlesInProgressJobs.length) {
      yield delay(30000);

      yield put(exportActions.syncVideoSubtitlingStatus());
    }
  } catch (error) {
    yield put(exportActions.setSubtitledVideoDataLoading(false));
  }
}

function* handleAddCurrentVideoToVideosUsedForExport(): Generator {
  const mediaFile = (yield select(getVideoInfo)) as MediaFile;

  yield put(exportActions.addVideoUsedForExport(mediaFile));
}

function* handleAddVideoUsedForExport(
  action: AddVideoUsedForExportAction
): Generator {
  const mediaFile = action.payload;

  const usedForExportMediaFiles = (yield select(
    getVideosUsedForExport
  )) as MediaFile[];

  const isSelected =
    usedForExportMediaFiles.findIndex((item) => item.id === mediaFile.id) >= 0;

  if (!isSelected) {
    const updatedUsedMediaFilesForExport = [
      ...usedForExportMediaFiles,
      mediaFile,
    ];

    yield put(
      exportActions.setVideosUsedForExport(updatedUsedMediaFilesForExport)
    );
  }
}

function* handleCancelBurningSubtitles(
  action: CancelBurningsSubtitlesJobAction
): Generator {
  const { videoId, jobId } = action.payload;

  yield put(exportActions.cancelBurningsSubtitlesJobStart());

  try {
    (yield call(
      VideosClient.cancelBurningSubtitles,
      videoId,
      jobId
    )) as AxiosResponse;

    yield delay(10000);

    yield put(jobsActions.getVideoJobs(videoId));

    yield delay(2000);

    yield put(exportActions.setExportSubtitledVideoLoading(false));
    yield put(exportActions.cancelBurningsSubtitlesJobSuccess());
  } catch (error) {
    console.log({ error });
    yield put(exportActions.cancelBurningsSubtitlesJobFail());
  }
}

export function* exportSaga(): Generator {
  yield takeLatest(
    ExportActionTypes.SELECT_ITEM_TO_EXPORT,
    handleSelectItemToExport
  );
  yield takeLatest(
    ExportActionTypes.SELECT_MULTIPLE_ITEMS_TO_EXPORT,
    handleSelectMultipleItemsToExport
  );
  yield takeLatest(
    ExportActionTypes.UNSELECT_ITEM_TO_EXPORT,
    handleUnselectItemToExport
  );
  yield takeLatest(
    ExportActionTypes.TOGGLE_ITEM_TO_EXPORT,
    handleToggleItemToExport
  );
  yield takeLatest(
    ExportActionTypes.TOGGLE_ALL_ITEMS_TO_EXPORT,
    handleToggleAllItemsToExport
  );
  yield takeLatest(
    ExportActionTypes.REMOVE_ALL_ITEMS_TO_EXPORT,
    handleRemoveAllItemsToExport
  );
  yield takeLatest(
    ExportActionTypes.CONFIRM_EXPORT_LIST_UPADATE,
    handleConfirmExportListUpdate
  );
  yield takeLatest(
    ExportActionTypes.REMOVE_EXPORT_ITEM,
    handleRemoveExportItem
  );
  yield takeLatest(
    ExportActionTypes.ADD_SPECIFIC_SCENE_TO_EXPORT,
    handleAddSpecificSceneToExport
  );
  yield takeLatest(
    ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO,
    handleExportSelectedMetadataAsVideo
  );
  yield takeLatest(
    ExportActionTypes.UPDATE_EXPORT_AS_VIDEO_PARAMS,
    handleUpdateExportAsVideoParams
  );
  yield takeLatest(
    ExportActionTypes.EXPORT_SUBTITLED_VIDEO,
    handleExportSubtitledVideo
  );
  yield takeLatest(
    ExportActionTypes.GET_SUBTITLED_VIDEO_DATA,
    handleGetSubtitledVideoData
  );
  yield takeLatest(
    ExportActionTypes.SYNC_VIDEO_SUBTITLING_STATUS,
    handleSyncVideoSubtitlingStatus
  );
  yield takeLatest(
    ExportActionTypes.ADD_VIDEO_USED_FOR_EXPORT,
    handleAddVideoUsedForExport
  );
  yield takeLatest(
    ExportActionTypes.ADD_CURRENT_VIDEO_TO_VIDEOS_USED_FOR_EXPORT,
    handleAddCurrentVideoToVideosUsedForExport
  );
  yield takeLatest(
    ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB,
    handleCancelBurningSubtitles
  );
}
