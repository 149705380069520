import { VideoUpload } from 'interfaces/upload';
import {
  UploadingFace,
  UploadingStatus,
  UploadingMedia,
  UploadingVideoDataForUpdate,
  UploadingMediaDurationItem,
  UploadMediaType,
} from 'interfaces/uploading';

export interface UploadingState {
    isLoading: boolean;
    uploadingVideos: Array<UploadingMedia>;
    uploadingMediaDurationItems: Array<UploadingMediaDurationItem>;
    uploadingFaces: Array<UploadingFace>;
    uploadingStatuses: Array<UploadingStatus>;
    videosDuration: Array<number>;
    isStatusBarActive: boolean;
    collectionId: string;
    isUploadFromExternalSourceInProgress: boolean;
    uploadingMediaWithAnalyzeCategories: Array<UploadingMedia>;
}

export enum UploadingActionTypes {
    SET_UPLOADING_FACES = '@@uploading/SET_UPLOADING_FACES',
    SET_UPLOADING_MEDIA_DURATION_ITEMS = '@@uploading/SET_UPLOADING_MEDIA_DURATION_ITEMS',
    CHANGE_UPLOADING_MEDIA_DURATION = '@@uploading/CHANGE_UPLOADING_MEDIA_DURATION',
    SET_UPLOADING_VIDEOS = '@@uploading/SET_UPLOADING_VIDEOS',
    SET_COLLECTION_ID = '@@uploading/SET_COLLECTION_ID',
    SET_UPLOADING_STATUSES = '@@uploading/SET_UPLOADING_STATUSES',
    UPDATE_UPLOADING_STATUS = '@@uploading/UPDATE_UPLOADING_STATUS',
    CONFIRM_UPLOADING = '@@uploading/CONFIRM_UPLOADING',
    CHANGE_UPLOADING_VIDEO_INFO = '@@uploading/CHANGE_UPLOADING_VIDEO_INFO',
    SET_UPLOADING_MEDIA_WITH_ANALYZE_CATEGORIES = '@@uploading/SET_UPLOADING_MEDIA_WITH_ANALYZE_CATEGORIES',

    UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE = '@@uploading/UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE',
    UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_START = '@@uploading/UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_START',
    UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_FAIL = '@@uploading/UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_FAIL',
    UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_SUCCESS = '@@uploading/UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_SUCCESS',
}

export interface SetUploadingFacesAction {
    type: UploadingActionTypes.SET_UPLOADING_FACES;
    payload: Array<UploadingFace>;
}
export interface SetUploadingVideosAction {
    type: UploadingActionTypes.SET_UPLOADING_VIDEOS;
    payload: Array<UploadingMedia>;
}

export interface SetUploadingMediaWithAnalyzeCategoriesAction {
    type: UploadingActionTypes.SET_UPLOADING_MEDIA_WITH_ANALYZE_CATEGORIES;
    payload: Array<UploadingMedia>;
}

export interface SetCollectionIdAction {
    type: UploadingActionTypes.SET_COLLECTION_ID;
    payload: string;
}
export interface SetUploadingStatusesAction {
    type: UploadingActionTypes.SET_UPLOADING_STATUSES;
    payload: Array<UploadingStatus>;
}
export interface UpdateUploadingStatusAction {
    type: UploadingActionTypes.UPDATE_UPLOADING_STATUS;
    payload: UploadingStatus;
}
export interface ConfirmUploadingActionPayload {
  media: Array<UploadingMedia>,
  showNotifications?: boolean;
  fps?: number;
  mediaType: UploadMediaType
}

export interface ConfirmUploadingAction {
    type: UploadingActionTypes.CONFIRM_UPLOADING;
    payload: ConfirmUploadingActionPayload;
}
export interface ChangeUploadingVideoInfoAction {
    type: UploadingActionTypes.CHANGE_UPLOADING_VIDEO_INFO;
    payload: UploadingVideoDataForUpdate;
}

export interface setUploadingMediaDurationItemsItemsAction {
    type: UploadingActionTypes.SET_UPLOADING_MEDIA_DURATION_ITEMS;
    payload: Array<UploadingMediaDurationItem>;
}

export interface  ChangeUploadingMediaDurationAction {
    type: UploadingActionTypes.CHANGE_UPLOADING_MEDIA_DURATION;
    payload: UploadingMediaDurationItem;
}

export interface UploadVideoFromExternalSourceActionPayload {
  data: VideoUpload;
  isProjectUpload?: boolean;
}

export interface UploadVideoFromExternalSourceAction {
  type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE;
  payload: UploadVideoFromExternalSourceActionPayload;
}

export interface UploadVideoFromExternalSourceStartAction {
  type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_START;
}

export interface UploadVideoFromExternalSourceFailAction {
  type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_FAIL;
}
export interface UploadVideoFromExternalSourceSuccessAction {
  type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_SUCCESS;
}

export type UploadingAction =
    | SetUploadingFacesAction
    | SetUploadingVideosAction
    | SetCollectionIdAction
    | SetUploadingStatusesAction
    | UpdateUploadingStatusAction
    | ConfirmUploadingAction
    | ChangeUploadingVideoInfoAction
    | setUploadingMediaDurationItemsItemsAction
    | SetUploadingMediaWithAnalyzeCategoriesAction

    | UploadVideoFromExternalSourceAction
    | UploadVideoFromExternalSourceStartAction
    | UploadVideoFromExternalSourceFailAction
    | UploadVideoFromExternalSourceSuccessAction
