import {
  GlobalSearchItem,
  GlobalSearchType,
  GlobalSearchTypeLabel,
  TransformedGlobalSearchResult,
  TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';
import {
  RawGlobalSearchSentence,
  TransformedGlobalSearchSentence,
} from 'state/modules/globalSearch';
import { TransformedAnalysisResultItem } from 'state/modules/metadata';

export const transformRawGlobalSearchResults = (
  results: Array<GlobalSearchItem>,
): Array<TransformedGlobalSearchResult> => {
  const transformedGlobalSearchResults = results.map(
    (item: GlobalSearchItem) => {
      const data = [] as Array<TransformedGlobalSearchResultItem>;

      item.types.forEach((typeItem: GlobalSearchType) => {
        typeItem.labels.forEach((label: GlobalSearchTypeLabel) => {
          data.push({
            ...label,
            labelType: typeItem.type,
            videoId: item.id,
            hasChildren: false,
            parents: [],
            id: label.name + typeItem.type + item.id,
          });
        });
      });

      return {
        id: item.id,
        data,
      };
    },
  );

  return transformedGlobalSearchResults;
};

export const transformGlobalSearchTranscriptResults = (
  transcript: Array<RawGlobalSearchSentence>,
): Array<TransformedGlobalSearchSentence> => {
  const changedTranscript = transcript.map(
    (item: RawGlobalSearchSentence) => {
      const dataLangKey = `data_${item.language}`;

      const originalSentence = item[
                dataLangKey as keyof RawGlobalSearchSentence
      ] as string;

      return {
        ...item,
        labelType: item.type,
        name: item.data,
        originalSentence,
        timestamps: [
          { timestamp: +item.startTime * 1000 },
          { timestamp: +item.endTime * 1000 },
        ],
      };
    },
  );

  return changedTranscript;
};

export const transformGlobalSearchResultsToAnalysisResults = (
  results: Array<TransformedGlobalSearchResultItem>,
  videoId: string,
): Array<TransformedAnalysisResultItem> => {
  const changedResults = results.map(
    (item: TransformedGlobalSearchResultItem) => ({
      ...item,
      videoId,
      id: item.name + item.labelType + videoId,
      hasChildren: false,
      parents: [],
    }),
  );

  return changedResults;
};
