import { IconProps } from '.';

const UpdateIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="m385.96 420h-28c0-49 37.078-83.328 82.656-83.328 29.246 0 55.727 14.637 70.84 39.719l-23.98 14.281c-10.004-16.598-27.523-25.914-46.859-25.914-30.133 0-54.656 27.242-54.656 55.242z" />
                <path d="m375.23 454.8-49.125-49.133 19.797-19.797 29.328 29.332 29.34-29.332 19.793 19.797z" />
                <path d="m434.09 515.52c-28.82 0-55.965-15.91-70.84-40.613l23.98-14.734c9.9961 16.59 27.516 27.488 46.859 27.488 30.137 0 54.656-25.66 54.656-53.66h28c-0.007813 41.996-37.09 81.52-82.656 81.52z" />
                <path d="m528.8 464.51-29.328-29.328-29.34 29.328-19.793-19.793 49.133-49.129 49.125 49.129z" />
                <path d="m308 462h-77c-15.441 0-28-12.559-28-28v-301c0-15.441 12.559-28 28-28h173.8l64.203 64.203v138.8h-28v-127.2l-47.797-47.797h-162.2v301h77z" />
                <path d="m455 196h-77v-77h28v49h49z" />
                <use x="70" y="665" xlinkHref="#w" />
                <use x="95.6875" y="665" xlinkHref="#d" />
                <use x="112.945312" y="665" xlinkHref="#c" />
                <use x="136.683594" y="665" xlinkHref="#b" />
                <use x="160.304688" y="665" xlinkHref="#e" />
                <use x="177.035156" y="665" xlinkHref="#c" />
                <use x="200.773438" y="665" xlinkHref="#l" />
                <use x="238.011719" y="665" xlinkHref="#k" />
                <use x="263.0625" y="665" xlinkHref="#f" />
                <use x="298.0625" y="665" xlinkHref="#j" />
                <use x="323.40625" y="665" xlinkHref="#b" />
                <use x="347.027344" y="665" xlinkHref="#d" />
                <use x="364.289062" y="665" xlinkHref="#a" />
                <use x="388.332031" y="665" xlinkHref="#v" />
                <use x="409.164062" y="665" xlinkHref="#i" />
                <use x="421.164062" y="665" xlinkHref="#b" />
                <use x="444.78125" y="665" xlinkHref="#h" />
                <use x="479.78125" y="665" xlinkHref="#u" />
                <use x="504.988281" y="665" xlinkHref="#b" />
                <use x="528.605469" y="665" xlinkHref="#g" />
                <use x="565.074219" y="665" xlinkHref="#a" />
                <use x="589.121094" y="665" xlinkHref="#f" />
                <use x="611.9375" y="665" xlinkHref="#i" />
                <use x="623.933594" y="665" xlinkHref="#a" />
                <use x="647.976562" y="665" xlinkHref="#h" />
                <use x="70" y="700" xlinkHref="#t" />
                <use x="85.226562" y="700" xlinkHref="#d" />
                <use x="102.488281" y="700" xlinkHref="#a" />
                <use x="126.53125" y="700" xlinkHref="#g" />
                <use x="175.1875" y="700" xlinkHref="#e" />
                <use x="191.917969" y="700" xlinkHref="#s" />
                <use x="216.835938" y="700" xlinkHref="#c" />
                <use x="252.757812" y="700" xlinkHref="#r" />
                <use x="282.050781" y="700" xlinkHref="#a" />
                <use x="306.097656" y="700" xlinkHref="#q" />
                <use x="331.011719" y="700" xlinkHref="#p" />
                <use x="368.117188" y="700" xlinkHref="#o" />
                <use x="393.765625" y="700" xlinkHref="#d" />
                <use x="411.027344" y="700" xlinkHref="#a" />
                <use x="435.074219" y="700" xlinkHref="#n" />
                <use x="447.070312" y="700" xlinkHref="#c" />
                <use x="470.808594" y="700" xlinkHref="#m" />
                <use x="491.554687" y="700" xlinkHref="#e" />
            </g>
        </svg>
    );
};

export default UpdateIcon;
