import { IconProps } from './index';
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 18 / 22;

const AudioLines = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.504883 20.911V11.435M6.16788 20.911V6.05097M11.8319 20.911V0.667969M17.4949 20.911V8.85497"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AudioLines;