import { ColorOption } from "interfaces/colors";

export const colorsOptions = [
  {
    value: 'white',
    label: '1',
    id: 1,
  },
  {
    value: 'black',
    label: '2',
    id: 2, 
  },
] as Array<ColorOption>;