import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 23 / 20;

const BillingIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_418_17476)">
                <path
                    d="M12.0703 4.14062C12.5156 3.86719 12.8125 3.37109 12.8125 2.8125C12.8125 1.94922 12.1133 1.25 11.25 1.25C10.3867 1.25 9.6875 1.94922 9.6875 2.8125C9.6875 3.375 9.98438 3.86719 10.4297 4.14062L8.19141 8.61719C7.83594 9.32812 6.91406 9.53125 6.29297 9.03516L2.8125 6.25C3.00781 5.98828 3.125 5.66406 3.125 5.3125C3.125 4.44922 2.42578 3.75 1.5625 3.75C0.699219 3.75 0 4.44922 0 5.3125C0 6.17578 0.699219 6.875 1.5625 6.875C1.57031 6.875 1.58203 6.875 1.58984 6.875L3.375 16.6953C3.58984 17.8828 4.625 18.75 5.83594 18.75H16.6641C17.8711 18.75 18.9062 17.8867 19.125 16.6953L20.9102 6.875C20.918 6.875 20.9297 6.875 20.9375 6.875C21.8008 6.875 22.5 6.17578 22.5 5.3125C22.5 4.44922 21.8008 3.75 20.9375 3.75C20.0742 3.75 19.375 4.44922 19.375 5.3125C19.375 5.66406 19.4922 5.98828 19.6875 6.25L16.207 9.03516C15.5859 9.53125 14.6641 9.32812 14.3086 8.61719L12.0703 4.14062Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_418_17476">
                    <rect width="22.5" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BillingIcon;
