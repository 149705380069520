import { IconProps } from '../index';

const TextOutFlipboardIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.361 18.598c.678-.572 1.49-.857 2.438-.857.948 0 1.745.258 2.39.775.654.51.98 1.127.98 1.85 0 .723-.33 1.343-.992 1.86-.661.516-1.462.774-2.402.774-.932 0-1.736-.31-2.413-.93v.766h-.968V16h.968v2.598zm2.378 3.673c.678 0 1.251-.192 1.721-.574.478-.383.717-.83.717-1.34 0-.517-.23-.966-.693-1.35-.462-.382-1.04-.573-1.733-.573-.693 0-1.274.179-1.744.537-.462.359-.693.812-.693 1.358 0 .541.235 1 .705 1.377s1.043.565 1.72.565zM15.52 22h-1.087l-1.35-1.61h-3.61L8.136 22H7l4.135-5h.31l4.075 5zm-2.904-2.181-1.326-1.623-1.338 1.623h2.664zM35 22.83a4.797 4.797 0 0 1-1.946 1.678 5.833 5.833 0 0 1-2.582.576c-1.446 0-2.657-.483-3.632-1.45-.964-.967-1.446-2.15-1.446-3.548 0-1.398.487-2.593 1.463-3.583.975-1.002 2.238-1.503 3.787-1.503.918 0 1.762.204 2.53.612.781.408 1.39.978 1.826 1.713l-1.12.716c-.837-1.107-1.933-1.66-3.287-1.66-1.068 0-1.963.35-2.686 1.048-.723.688-1.085 1.544-1.085 2.57 0 1.025.356 1.905 1.068 2.639.711.734 1.612 1.1 2.703 1.1 1.308 0 2.41-.559 3.305-1.677L35 22.83z"
                fill="#525253" />
            <path d="m18.66 27.157 1.996-1.995 1.995 1.995" stroke="#CECECF" strokeWidth=".7" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M16 23.171v3.658a2.328 2.328 0 1 0 4.656 0v-1.172M19.974 10.682A2.328 2.328 0 0 0 16 12.328v10.614"
                  stroke="#CECECF" strokeWidth=".7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TextOutFlipboardIcon;