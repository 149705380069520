import { IconProps } from '../index';

const ElementsIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9997 4.75C13.4684 4.75 11.4163 6.80203 11.4163 9.33333C11.4163 11.8646 13.4684 13.9167 15.9997 13.9167C18.531 13.9167 20.583 11.8646 20.583 9.33333C20.583 6.80203 18.531 4.75 15.9997 4.75ZM9.91634 9.33333C9.91634 5.9736 12.6399 3.25 15.9997 3.25C19.3594 3.25 22.083 5.9736 22.083 9.33333C22.083 12.6931 19.3594 15.4167 15.9997 15.4167C12.6399 15.4167 9.91634 12.6931 9.91634 9.33333ZM8.66634 18.0833C6.13504 18.0833 4.08301 20.1354 4.08301 22.6667C4.08301 25.198 6.13504 27.25 8.66634 27.25C11.1976 27.25 13.2497 25.198 13.2497 22.6667C13.2497 20.1354 11.1976 18.0833 8.66634 18.0833ZM2.58301 22.6667C2.58301 19.3069 5.30661 16.5833 8.66634 16.5833C12.0261 16.5833 14.7497 19.3069 14.7497 22.6667C14.7497 26.0264 12.0261 28.75 8.66634 28.75C5.30661 28.75 2.58301 26.0264 2.58301 22.6667ZM18.7497 22.6667C18.7497 20.1354 20.8017 18.0833 23.333 18.0833C25.8643 18.0833 27.9163 20.1354 27.9163 22.6667C27.9163 25.198 25.8643 27.25 23.333 27.25C20.8017 27.25 18.7497 25.198 18.7497 22.6667ZM23.333 16.5833C19.9733 16.5833 17.2497 19.3069 17.2497 22.6667C17.2497 26.0264 19.9733 28.75 23.333 28.75C26.6927 28.75 29.4163 26.0264 29.4163 22.6667C29.4163 19.3069 26.6927 16.5833 23.333 16.5833Z"
                fill={color}
            />
        </svg>
    );
};

export default ElementsIconNew;


