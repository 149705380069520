import {
    StockMediaCollection,
    StockMedia,
    StockSong,
    StockPlaylist,
    StockSongTag,
    StockAudioEffect,
    StockMediaCategory,
    CategorizedStockVideos,
    CategorizedStockImages,
    CategorizedStockSongs,
} from 'interfaces/stockMedia';

export interface StockMediaState {
    // images
    images: StockMedia[];
    imagesPreviews: StockMedia[];
    isImagesLoading: boolean;
    imagesTotal: number;
    imagesListPage: number;
    imagesSearchQuery: string;
    categorizedStockImages: {},
    // videos
    videos: StockMedia[];
    videoPreviews: StockMedia[];
    isVideosLoading: boolean;
    videosTotal: number;
    videosListPage: number;
    videosSearchQuery: string;
    stockVideosPreviews: any[];
    categorizedStockVideos: {};
    categorizedVideoPreviews: {};
    // collections
    collections: StockMediaCollection[];
    selectedImageCollection: StockMediaCollection | null;
    selectedVideoCollection: StockMediaCollection | null;
    // audio
    songs: StockSong[];
    audioPlaylists: StockPlaylist[];
    audioPlaylist: StockPlaylist | null;
    tags: StockSongTag[];
    selectedTag: null | StockSongTag;
    categorizedStockSongs: {};
    songsSearchQuery: string;
    isAudiosLoading: boolean;
    // audio effect
    audioEffects: StockAudioEffect[];
    // categories
    categories: StockMediaCategory[];
    selectedCategory: StockMediaCategory | null;
    //
    activeStockAudio: string | null;
}

export enum StockMediaActionTypes {
    // images
    GET_STOCK_IMAGES = '@@stockMedia/GET_STOCK_IMAGES',
    GET_STOCK_IMAGES_START = '@@stockMedia/GET_STOCK_IMAGES_START',
    GET_STOCK_IMAGES_FAIL = '@@stockMedia/GET_STOCK_IMAGES_FAIL',
    SET_STOCK_IMAGES = '@@stockMedia/SET_STOCK_IMAGES',
    SET_IMAGES_SEARCH_QUERY = '@@stockMedia/SET_IMAGES_SEARCH_QUERY',
    SET_IMAGES_PREVIEWS = '@@stockMedia/SET_IMAGES_PREVIEWS',
    SET_IMAGES_LIST_PAGE = '@@stockMedia/SET_IMAGES_LIST_PAGE',
    GET_CATEGORIZED_STOCK_IMAGES = '@@stockMedia/GET_CATEGORIZED_STOCK_IMAGES',
    GET_CATEGORIZED_STOCK_IMAGES_START = '@@stockMedia/GET_CATEGORIZED_STOCK_IMAGES_START',
    GET_CATEGORIZED_STOCK_IMAGES_FAIL = '@@stockMedia/GET_CATEGORIZED_STOCK_IMAGES_FAIL',
    SET_CATEGORIZED_STOCK_IMAGES = '@@stockMedia/SET_CATEGORIZED_STOCK_IMAGES',
    // videos
    GET_STOCK_VIDEOS = '@@stockMedia/GET_STOCK_VIDEOS',
    GET_STOCK_VIDEOS_START = '@@stockMedia/GET_STOCK_VIDEOS_START',
    GET_STOCK_VIDEOS_FAIL = '@@stockMedia/GET_STOCK_VIDEOS_FAIL',
    SET_STOCK_VIDEOS = '@@stockMedia/SET_STOCK_VIDEOS',
    SET_VIDEOS_SEARCH_QUERY = '@@stockMedia/SET_VIDEOS_SEARCH_QUERY',
    SET_VIDEOS_PREVIEWS = '@@stockMedia/SET_VIDEOS_PREVIEWS',
    SET_VIDEOS_LIST_PAGE = '@@stockMedia/SET_VIDEOS_LIST_PAGE',
    GET_STOCK_VIDEOS_PREVIEWS = '@@stockMedia/GET_STOCK_VIDEOS_PREVIEWS',
    SET_STOCK_VIDEOS_PREVIEWS = '@@stockMedia/SET_STOCK_VIDEOS_PREVIEWS',
    GET_CATEGORIZED_STOCK_VIDEOS = '@@stockMedia/GET_CATEGORIZED_STOCK_VIDEOS',
    GET_CATEGORIZED_STOCK_VIDEOS_START = '@@stockMedia/GET_CATEGORIZED_STOCK_VIDEOS_START',
    GET_CATEGORIZED_STOCK_VIDEOS_FAIL = '@@stockMedia/GET_CATEGORIZED_STOCK_VIDEOS_FAIL',
    SET_CATEGORIZED_STOCK_VIDEOS = '@@stockMedia/SET_CATEGORIZED_STOCK_VIDEOS',
    SET_CATEGORIZED_VIDEOS_PREVIEWS = '@@stockMedia/SET_CATEGORIZED_VIDEOS_PREVIEWS',
    // collections
    GET_COLLECTIONS = '@@stockMedia/GET_COLLECTIONS',
    SET_COLLECTIONS = '@@stockMedia/SET_COLLECTIONS',

    SET_SELECTED_VIDEO_COLLECTION = '@@stockMedia/SET_SELECTED_VIDEO_COLLECTION',
    SET_SELECTED_IMAGE_COLLECTION = '@@stockMedia/SET_SELECTED_IMAGE_COLLECTION',
    // audio
    // songs
    GET_STOCK_AUDIO_SONGS = '@@stockMedia/GET_STOCK_AUDIO_SONGS',
    SET_STOCK_AUDIO_SONGS = '@@stockMedia/SET_STOCK_AUDIO_SONGS',
    GET_CATEGORIZED_STOCK_AUDIO_SONGS = '@@stockMedia/GET_CATEGORIZED_STOCK_AUDIO_SONGS',
    SET_CATEGORIZED_STOCK_AUDIO_SONGS = '@@stockMedia/SET_CATEGORIZED_STOCK_AUDIO_SONGS',
    SET_AUDIOS_SEARCH_QUERY = '@@stockMedia/SET_AUDIOS_SEARCH_QUERY',
    // playlist
    GET_STOCK_AUDIO_PLAYLISTS = '@@stockMedia/GET_STOCK_AUDIO_PLAYLISTS',
    SET_STOCK_AUDIO_PLAYLISTS = '@@stockMedia/SET_STOCK_AUDIO_PLAYLISTS',
    SET_STOCK_AUDIO_PLAYLIST = '@@stockMedia/SET_STOCK_AUDIO_PLAYLIST',
    // tags
    GET_STOCK_AUDIO_TAGS = '@@stockMedia/GET_STOCK_AUDIO_TAGS',
    SET_STOCK_AUDIO_TAGS = '@@stockMedia/SET_STOCK_AUDIO_TAGS',
    SET_SELECTED_STOCK_AUDIO_TAG = '@@stockMedia/SET_SELECTED_STOCK_AUDIO_TAG',
    // sound effects
    GET_STOCK_AUDIO_EFFECTS = '@@stockMedia/GET_STOCK_AUDIO_EFFECTS',
    SET_STOCK_AUDIO_EFFECTS = '@@stockMedia/SET_STOCK_AUDIO_EFFECTS',
    // categories
    GET_STOCK_MEDIA_CATEGORIES = '@@stockMedia/GET_STOCK_MEDIA_CATEGORIES',
    SET_STOCK_MEDIA_CATEGORIES = '@@stockMedia/SET_STOCK_MEDIA_CATEGORIES',
    SET_STOCK_MEDIA_SELECTED_CATEGORY = '@@stockMedia/SET_STOCK_MEDIA_SELECTED_CATEGORY',
    // active audio
    SET_ACTIVE_STOCK_AUDIO = '@@stockMedia/SET_ACTIVE_STOCK_AUDIO',
}

// images

export interface GetStockImagesActionPayload {
    isLoadMore?: boolean;
}

export interface GetStockImagesAction {
    type: StockMediaActionTypes.GET_STOCK_IMAGES;
    payload?: GetStockImagesActionPayload;
}

export interface GetStockImagesStartAction {
    type: StockMediaActionTypes.GET_STOCK_IMAGES_START;
}

export interface GetStockImagesFailAction {
    type: StockMediaActionTypes.GET_STOCK_IMAGES_FAIL;
    payload: any;
}

export interface SetStockImagesActionPayload {
    images: StockMedia[];
    total: number;
}

export interface SetStockImagesAction {
    type: StockMediaActionTypes.SET_STOCK_IMAGES;
    payload: SetStockImagesActionPayload;
}

export interface SetStockImagesPreviewsAction {
    type: StockMediaActionTypes.SET_IMAGES_PREVIEWS;
    payload: StockMedia[];
}

export interface SetStockImagesSearchQueryAction {
    type: StockMediaActionTypes.SET_IMAGES_SEARCH_QUERY;
    payload: string;
}

export interface SetStockImagesListPageAction {
    type: StockMediaActionTypes.SET_IMAGES_LIST_PAGE;
    payload: number;
}

export interface GetCategorizedStockImagesAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_IMAGES;
}

export interface GetCategorizedStockImagesStartAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_IMAGES_START;
}

export interface GetCategorizedStockImagesFailAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_IMAGES_FAIL;
    payload: any;
}

export interface SetCategorizedStockImagesActionPayload {
    categorizedImages: CategorizedStockImages;
}

export interface SetCategorizedStockImagesAction {
    type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_IMAGES;
    payload: SetCategorizedStockImagesActionPayload;
}
// videos

export interface GetStockVideosActionPayload {
    isLoadMore?: boolean;
}

export interface GetStockVideosAction {
    type: StockMediaActionTypes.GET_STOCK_VIDEOS;
    payload?: GetStockImagesActionPayload;
}

export interface GetStockVideosStartAction {
    type: StockMediaActionTypes.GET_STOCK_VIDEOS_START;
}

export interface GetStockVideosFailAction {
    type: StockMediaActionTypes.GET_STOCK_VIDEOS_FAIL;
    payload: any;
}

export interface SetStockVideosActionPayload {
    videos: StockMedia[];
    total: number;
}

export interface SetStockVideosAction {
    type: StockMediaActionTypes.SET_STOCK_VIDEOS;
    payload: SetStockVideosActionPayload;
}

export interface SetStockVideosPreviewsAction {
    type: StockMediaActionTypes.SET_VIDEOS_PREVIEWS;
    payload: StockMedia[];
}

export interface GetCategorizedStockVideosActionPayload {
    isOneCategory?: boolean;
}

export interface GetCategorizedStockVideosAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_VIDEOS;
    payload?: GetCategorizedStockVideosActionPayload;
}

export interface GetCategorizedStockVideosStartAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_VIDEOS_START;
}

export interface GetCategorizedStockVideosFailAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_VIDEOS_FAIL;
}

export interface SetCategorizedStockVideosActionPayload {
    categorizedVideos: CategorizedStockVideos;
}

export interface SetCategorizedStockVideosAction {
    type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_VIDEOS;
    payload: SetCategorizedStockVideosActionPayload;
}

export interface SetCategorizedStockVideosPreviewsAction {
    type: StockMediaActionTypes.SET_CATEGORIZED_VIDEOS_PREVIEWS;
    payload: SetCategorizedStockVideosActionPayload;
}

export interface SetStockVideosSearchQueryAction {
    type: StockMediaActionTypes.SET_VIDEOS_SEARCH_QUERY;
    payload: string;
}

export interface SetStockVideosListPageAction {
    type: StockMediaActionTypes.SET_VIDEOS_LIST_PAGE;
    payload: number;
}

export interface GetStockVideosThumbnailPreviewsAction {
    type: StockMediaActionTypes.GET_STOCK_VIDEOS_PREVIEWS;
}

export interface SetStockVideosThumbnailPreviewsAction {
    type: StockMediaActionTypes.SET_STOCK_VIDEOS_PREVIEWS;
    payload: any[];
}

// collections

export interface GetStockMediaCollectionsAction {
    type: StockMediaActionTypes.GET_COLLECTIONS;
}

export interface SetStockMediaCollectionsAction {
    type: StockMediaActionTypes.SET_COLLECTIONS;
    payload: StockMediaCollection[];
}

export interface SetStockVideoSelectedCollectionAction {
    type: StockMediaActionTypes.SET_SELECTED_VIDEO_COLLECTION;
    payload: StockMediaCollection | null;
}

export interface SetStockImageSelectedCollectionAction {
    type: StockMediaActionTypes.SET_SELECTED_IMAGE_COLLECTION;
    payload: StockMediaCollection | null;
}

// audio

export interface GetStockAudioSongsAction {
    type: StockMediaActionTypes.GET_STOCK_AUDIO_SONGS;
}

export interface SetStockAudioSongsAction {
    type: StockMediaActionTypes.SET_STOCK_AUDIO_SONGS;
    payload: StockSong[];
}

export interface GetCategorizedStockAudioSongsAction {
    type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_AUDIO_SONGS;
}

export interface SetCategorizedStockAudioSongsActionPayload {
    categorizedSongs: CategorizedStockSongs;
}

export interface SetCategorizedStockAudioSongsAction {
    type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_AUDIO_SONGS;
    payload: SetCategorizedStockAudioSongsActionPayload;
}

export interface SetStockAudiosSearchQueryAction {
    type: StockMediaActionTypes.SET_AUDIOS_SEARCH_QUERY;
    payload: string;
}

// playlist
export interface GetStockAudioPlaylistsAction {
    type: StockMediaActionTypes.GET_STOCK_AUDIO_PLAYLISTS;
}

export interface SetStockAudioPlaylistsAction {
    type: StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLISTS;
    payload: StockPlaylist[];
}

export interface SetStockAudioPlaylistAction {
    type: StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLIST;
    payload: StockPlaylist | null;
}

// Tags

export interface GetStockAudioTagsAction {
    type: StockMediaActionTypes.GET_STOCK_AUDIO_TAGS;
}

export interface SetStockAudioTagsAction {
    type: StockMediaActionTypes.SET_STOCK_AUDIO_TAGS;
    payload: StockSongTag[];
}
export interface SetStockAudioSelectedTagAction {
    type: StockMediaActionTypes.SET_SELECTED_STOCK_AUDIO_TAG;
    payload: StockSongTag | null;
}

// Audio effects
export interface GetStockAudioEffectsAction {
    type: StockMediaActionTypes.GET_STOCK_AUDIO_EFFECTS;
}

export interface SetStockAudioEffectsAction {
    type: StockMediaActionTypes.SET_STOCK_AUDIO_EFFECTS;
    payload: StockAudioEffect[];
}

// Categories
export interface GetStockMediaCategoriesAction {
    type: StockMediaActionTypes.GET_STOCK_MEDIA_CATEGORIES;
}

export interface SetStockMediaCategoriesAction {
    type: StockMediaActionTypes.SET_STOCK_MEDIA_CATEGORIES;
    payload: StockMediaCategory[];
}

export interface SetStockMediaSelectedCategoryAction {
    type: StockMediaActionTypes.SET_STOCK_MEDIA_SELECTED_CATEGORY;
    payload: StockMediaCategory | null;
}

//

export interface SetActiveStockAudioAction {
    type: StockMediaActionTypes.SET_ACTIVE_STOCK_AUDIO;
    payload: string | null;
}

export type StockMediaAction =
    | GetStockImagesAction
    | GetStockImagesStartAction
    | GetStockImagesFailAction
    | SetStockImagesAction
    | SetStockImagesSearchQueryAction
    | GetCategorizedStockImagesAction
    | GetCategorizedStockImagesStartAction
    | GetCategorizedStockImagesFailAction
    | SetCategorizedStockImagesAction
    | GetStockMediaCollectionsAction
    | SetStockMediaCollectionsAction
    | GetStockVideosAction
    | GetStockVideosStartAction
    | GetStockVideosFailAction
    | SetStockVideosAction
    | GetCategorizedStockVideosAction
    | GetCategorizedStockVideosStartAction
    | GetCategorizedStockVideosFailAction
    | SetCategorizedStockVideosAction
    | SetCategorizedStockVideosPreviewsAction
    | SetStockVideosSearchQueryAction
    | SetStockVideoSelectedCollectionAction
    | SetStockImageSelectedCollectionAction
    | SetStockVideosPreviewsAction
    | SetStockImagesPreviewsAction
    | SetStockImagesListPageAction
    | SetStockVideosListPageAction
    | GetStockAudioSongsAction
    | SetStockAudioSongsAction
    | GetCategorizedStockAudioSongsAction
    | SetCategorizedStockAudioSongsAction
    | SetStockAudiosSearchQueryAction
    | GetStockAudioPlaylistsAction
    | SetStockAudioPlaylistsAction
    | SetStockAudioPlaylistAction
    | GetStockAudioTagsAction
    | SetStockAudioTagsAction
    | SetStockAudioSelectedTagAction
    | SetStockVideosThumbnailPreviewsAction
    | GetStockVideosThumbnailPreviewsAction
    | GetStockAudioEffectsAction
    | SetStockAudioEffectsAction
    | GetStockMediaCategoriesAction
    | SetStockMediaCategoriesAction
    | SetStockMediaSelectedCategoryAction
    | SetActiveStockAudioAction;
