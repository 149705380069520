import { Shot, TransformedShot } from 'state/modules/shots';

export const transformShots = (shots: Array<Shot>): Array<TransformedShot> => shots.map((item: Shot, index: number) => ({
  ...item,
  id: item.id,
  labelType: item.type,
  name: `${item.type} ${item?.extraProperties?.index || index}`,
  timestamps: [
    { timestamp: item.startTimestampMillis },
    { timestamp: item.endTimestampMillis },
  ],
}));
