import { createSelector } from 'reselect';

import { State } from 'state/modules';
import { StockMediaState } from './types';

export const getMediaState = (state: State): StockMediaState =>
    state.stockMedia;

// images

export const getStockMediaImages = createSelector(
    getMediaState,
    (state) => state.images
);

export const getStockImagesPreviews = createSelector(
    getMediaState,
    (state) => state.imagesPreviews
);

export const getCategorizedStockImagesPreviews = createSelector(
    getMediaState,
    (state) => state.categorizedStockImages
);

export const getStockMediaImagesTotal = createSelector(
    getMediaState,
    (state) => state.imagesTotal
);

export const getStockMediaImagesCount = createSelector(
    getStockMediaImages,
    (images) => images.length
);

export const getStockMediaImagesLoading = createSelector(
    getMediaState,
    (state) => state.isImagesLoading
);

export const getStockImagesSearchQuery = createSelector(
    getMediaState,
    (state) => state.imagesSearchQuery
);

export const getStockImagesListPage = createSelector(
    getMediaState,
    (state) => state.imagesListPage
);

// videos

export const getStockMediaVideos = createSelector(
    getMediaState,
    (state) => state.videos
);

export const getStockVideosPreviews = createSelector(
    getMediaState,
    (state) => state.videoPreviews
);

export const getCategorizedStockVideosPreviews = createSelector(
    getMediaState,
    (state) => state.categorizedVideoPreviews
);

export const getStockMediaVideosTotal = createSelector(
    getMediaState,
    (state) => state.videosTotal
);

export const getStockMediaVideosCount = createSelector(
    getStockMediaVideos,
    (videos) => videos.length
);

export const getStockMediaVideosLoading = createSelector(
    getMediaState,
    (state) => state.isVideosLoading
);

export const getStockVideosSearchQuery = createSelector(
    getMediaState,
    (state) => state.videosSearchQuery
);

export const getStockVideosListPage = createSelector(
    getMediaState,
    (state) => state.videosListPage
);

export const getStockVideosThumbnailPreviewsList = createSelector(
    getMediaState,
    (state) => state.stockVideosPreviews
);

// collections

export const getStockMediaCollectionsList = createSelector(
    getMediaState,
    (state) => state.collections
);

export const getStockImagesCollectionsList = createSelector(
    getStockMediaCollectionsList,
    (collections) => collections.slice(0, 2)
);

export const getStockVideosCollectionsList = createSelector(
    getStockMediaCollectionsList,
    (collections) => collections.slice(2, collections.length)
);

export const getStockVideoSelectedCollection = createSelector(
    getMediaState,
    (state) => state.selectedVideoCollection
);

export const getStockImageSelectedCollection = createSelector(
    getMediaState,
    (state) => state.selectedImageCollection
);

// audio

export const getStockSongs = createSelector(
    getMediaState,
    (state) => state.songs
);

export const getCategorizedStockSongs = createSelector(
    getMediaState,
    (state) => state.categorizedStockSongs
);

export const getStockSongsSearchQuery = createSelector(
    getMediaState,
    (state) => state.songsSearchQuery
);

export const getStockAudiosPlaylists = createSelector(
    getMediaState,
    (state) => state.audioPlaylists
);

export const getStockAudiosSelectedPlaylist = createSelector(
    getMediaState,
    (state) => state.audioPlaylist
);

export const getStockAudiosSelectedTag = createSelector(
    getMediaState,
    (state) => state.selectedTag
);

export const getStockAudiosTags = createSelector(
    getMediaState,
    (state) => state.tags
);

// effects

export const getStockAudiosEffectsList = createSelector(
    getMediaState,
    (state) => state.audioEffects
);

// categories

export const getStockMediaCategoriesList = createSelector(
    getMediaState,
    (state) => state.categories
);

export const getStockMediaSelectedCategory = createSelector(
    getMediaState,
    (state) => state.selectedCategory
);

export const getActiveStockAudio = createSelector(
    getMediaState,
    (state) => state.activeStockAudio
);
