export enum VideoUploadTypes {
    STREAMABLE_LINK = 'STREAMABLE_LINK',
}

export interface VideoUploadStreamableLink {
    link: string;
}

export interface VideoUpload {
    type: VideoUploadTypes;
    streamableLink?: VideoUploadStreamableLink;
}
