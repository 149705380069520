import styled from 'styled-components';
import { useSelector } from 'react-redux';

import InviteCollaboratorForm from 'components/InviteCollaboratorForm';
import WorkspaceMembersList from 'components/WorkspaceMembersList';

import { PlanNameId, getCurrentUserPlan } from 'state/modules/payment';

const Container = styled.div`
    flex: 3 1 0;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const InviteContainer = styled.div`
`;

const ContentHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
`;

const ContentHeaderTitle = styled.div`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.625rem;
`;

const FormContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

const MembersContainer = styled.div`
    height: calc(100% - 140px);
`;

const Collaborators = () => {
    const currentPlan = useSelector(getCurrentUserPlan);

    return (
        <Container>
            {
                (currentPlan?.planNameId !== PlanNameId.INITIAL) && (
                    <InviteContainer>
                        <ContentHeader>
                            <ContentHeaderTitle>Invite People</ContentHeaderTitle>
                        </ContentHeader>
                        <FormContainer>
                            <InviteCollaboratorForm />
                        </FormContainer>
                    </InviteContainer>
                )
            }
            <MembersContainer>
                <WorkspaceMembersList />
            </MembersContainer>
        </Container>
    );
};

export default Collaborators;
