import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import prettyBytes from 'pretty-bytes';

import { NewButton, Slider } from 'components/UI';

import { getModalEvent, ModalEvent } from 'state/modules/modal';
import {
    CurrentSubscription,
    Customer,
    getAdditionalStorageAddon,
    getCurrentUserPlan,
    getCustomerInfo,
    getStorageUpdateLoading,
    getStripePromise,
    getUpcomingUserPlan,
    PaymentType,
    Price,
    Product,
    setupIntent,
    UpcomingPlan,
    UpdateAddonBody,
    updateStorage,
} from 'state/modules/payment';

import { Colors } from 'styles';
import { getLimitationsInfo } from 'utils/limitations';
import { LimitationType } from 'interfaces/limitations';
import { Elements } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';
import { PaymentForm } from '../SelectPlanModal/PaymentForm';

interface Props {
    closeButton?: JSX.Element;
}

const ManageStorageModal = (props: Props): JSX.Element => {
    const [storage, setStorage] = useState(0);

    // const { closeButton } = props;

    const modalEvent = useSelector(getModalEvent);
    const storageAddon = useSelector(getAdditionalStorageAddon) as Product;
    const isLoading = useSelector(getStorageUpdateLoading);
    const stripePromise = useSelector(getStripePromise);
    const dispatch = useDispatch();

    const customer = useSelector(getCustomerInfo) as Customer;

    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const currentSubscription = useSelector(
        getCurrentUserPlan
    ) as CurrentSubscription;

    const upcomingSubscription = useSelector(
        getUpcomingUserPlan
    ) as UpcomingPlan;

    useEffect(() => {
        if (currentSubscription?.addon?.currentStorageAmount) {
            setStorage(
                currentSubscription.addon.currentStorageAmount / 1000000000
            );
        }
    }, [currentSubscription]);

    const handleStorageChange = (value: number) => {
        setStorage(value);
    };

    const transformedAddon = useMemo(() => {
        if (storageAddon && storageAddon.prices && currentSubscription) {
            return {
                ...storageAddon,
                prices: storageAddon.prices.filter(
                    (price: Price) =>
                        price.interval === currentSubscription.price.interval
                ),
            };
        }

        return storageAddon;
    }, [storageAddon, currentSubscription]);

    const handleGoToPayment = () => {
        if (transformedAddon?.id) {
            setClientSecretLoading(true);
            dispatch(
                setupIntent((error, data) => {
                    setClientSecretLoading(false);
                    if (!error) {
                        setClientSecret(data.clientSecret);
                    }
                })
            );
        }
    };

    let price = 0;

    if (transformedAddon && transformedAddon.prices && currentSubscription) {
        price = (storage / 100) * (transformedAddon.prices[0].amount / 100);
    }

    const handleStorageAddonUpdate = () => {
        let updateBody = {
            productId: transformedAddon.id,
            priceId: transformedAddon?.prices?.[0].id || '',
            quantity: storage,
        } as UpdateAddonBody;

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            updateBody = {
                ...updateBody,
                upcomingProductId: transformedAddon.id,
                upcomingPriceId: upcomingPlanAddonPriceId,
            };
        }

        dispatch(
            updateStorage({
                data: {
                    addon: updateBody,
                },
                paymentInfo: {
                    price,
                    name: `Monthly Storage`,
                    type: 'addon',
                },
            })
        );
    };

    const handleCancelAddon = () => {
        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            dispatch(
                updateStorage({
                    data: {
                        addon: {
                            productId: currentSubscription?.addon?.productId,
                            priceId: currentSubscription?.addon?.priceId,
                            upcomingProductId:
                                currentSubscription?.addon?.productId,
                            upcomingPriceId: upcomingPlanAddonPriceId,
                            quantity: 0,
                        },
                    },
                })
            );
        }
    };

    const handleReduceStorage = () => {
        let updateStorageBody = {
            productId: currentSubscription?.addon?.productId,
            priceId: currentSubscription?.addon?.priceId,
            quantity: storage,
        } as UpdateAddonBody;

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            if (upcomingPlanAddonPrice) {
                updateStorageBody = {
                    ...updateStorageBody,
                    upcomingProductId: currentSubscription?.addon?.productId,
                    upcomingPriceId: upcomingPlanAddonPriceId,
                };
            }
        }

        dispatch(
            updateStorage({
                data: {
                    addon: updateStorageBody,
                },
            })
        );
    };

    const renderSubmitButton = () => {
        let disabled = false;

        const isReduce =
            storage * 1000000000 <
            (currentSubscription?.addon?.currentStorageAmount || 0);

        if (
            (currentSubscription?.addon?.currentStorageAmount || 0) /
                1000000000 ===
                storage &&
            (currentSubscription?.addon?.featureStorageAmount || 0) /
                1000000000 ===
                storage
        ) {
            disabled = true;
        }

        return (
            <NewButton
                color={Colors.GREY3}
                textColor={Colors.WHITE}
                bordered
                borderRadius={5}
                width={132}
                height={46}
                onClick={
                    isReduce
                        ? handleReduceStorage
                        : customer?.paymentMethod
                        ? handleStorageAddonUpdate
                        : handleGoToPayment
                }
                disabled={
                    disabled || isReduce || isLoading || clientSecretLoading
                }
                loading={isReduce || isLoading || clientSecretLoading}
            >
                {isReduce
                    ? 'Reduce storage'
                    : customer?.paymentMethod
                    ? 'Pay'
                    : 'Go to payment'}
            </NewButton>
        );
    };

    const renderCancelAddonButton = () => {
        const disabled =
            !currentSubscription.addon?.currentStorageAmount ||
            !currentSubscription.addon?.featureStorageAmount;

        return (
            <NewButton
                color={Colors.PINK}
                textColor={Colors.WHITE}
                bordered
                borderRadius={5}
                width={132}
                height={46}
                onClick={handleCancelAddon}
                disabled={isLoading || disabled}
                loading={isLoading}
            >
                Cancel add-on
            </NewButton>
        );
    };

    const renderPrice = () => {
        return (
            <div className={styles.ManageStorageModal__summaryItem}>
                <p className={styles.ManageStorageModal__summaryLabel}>Price</p>
                <p className={styles.ManageStorageModal__summaryValue}>
                    ${!(price % 1) ? price : price.toFixed(2)} /{' '}
                    {currentSubscription.price.interval}
                </p>
            </div>
        );
    };

    const renderBillingPeriod = () => {
        if (currentSubscription) {
            if (
                currentSubscription &&
                currentSubscription.billingPeriod &&
                currentSubscription.billingPeriod.billingStartDate
            ) {
                const { billingStartDate, billingEndDate } =
                    currentSubscription.billingPeriod;

                return (
                    <p className={styles.ManageStorageModal__billingPeriod}>
                        {`Billing period: ${moment
                            .unix(billingStartDate)
                            .format('DD/MM/YYYY')} -
            ${moment.unix(billingEndDate).format('DD/MM/YYYY')}`}
                    </p>
                );
            }
        }
    };

    const renderMessage = () => {
        if (modalEvent === ModalEvent.NOT_ENOUGH_STORAGE) {
            const limitationsInfo = getLimitationsInfo(
                LimitationType.NOT_ENOUGH_STORAGE
            );

            return (
                <div>
                    <p className={styles.ManageStorageModal__overLimitTitle}>
                        {limitationsInfo?.title}
                    </p>
                    {/* <p className={styles.ManageStorageModal__overLimitMessage}>
            {limitationsInfo?.message}
          </p> */}
                </div>
            );
        }

        return '';
    };

    if (clientSecret) {
        let subscriptionBody: any = {
            productId: transformedAddon.id || '',
            priceId: transformedAddon?.prices?.[0]?.id || '',
            quantity: storage,
            type: PaymentType.addon,
        };

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (_price: Price) =>
                    _price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            subscriptionBody = {
                ...subscriptionBody,
                upcomingProductId: transformedAddon.id || '',
                upcomingPriceId: upcomingPlanAddonPriceId,
                price,
                name: `Monthly Storage`,
                paymentType: 'addon',
            };
        }

        const urlParams = new URLSearchParams(subscriptionBody).toString();

        return (
            <div className={styles.ManageStorageModal}>
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    <PaymentForm
                        price={price}
                        handleClose={() => {
                            setClientSecret(null);
                        }}
                        urlParams={urlParams}
                        noPaymentMethod={!customer?.paymentMethod}
                    />
                </Elements>
                {
                    // closeButton
                }
            </div>
        );
    }

    return (
        <div className={styles.ManageStorageModal}>
            {renderMessage()}
            <p className={styles.ManageStorageModal__title}>
                Manage your storage
            </p>
            <div className={styles.ManageStorageModal__container}>
                <p className={styles.ManageStorageModal__rangeTitle}>
                    Add more storage - this will be added on top of your current
                    plan.
                </p>
                <div className={styles.ManageStorageModal__contentWrap}>
                    <div className={styles.ManageStorageModal__rangeContainer}>
                        <p className={styles.ManageStorageModal__rangeValue}>
                            {prettyBytes(storage * 1000000000)}
                        </p>
                        <Slider
                            domain={[0, 20000]}
                            step={100}
                            onChange={handleStorageChange}
                            onUpdate={handleStorageChange}
                            value={storage}
                            trackStyles={{
                                backgroundColor: 'transparent',
                            }}
                            railInnerStyle={{
                                height: 20,
                                backgroundColor: 'transparent',
                            }}
                            railOuterStyle={{
                                height: 20,
                                border: '2px solid #36396d',
                                borderRadius: 0,
                            }}
                            handleSliderStyle={{
                                background: '#6979a5',
                                width: 35,
                                height: 35,
                            }}
                        />
                    </div>
                    <div className={styles.ManageStorageModal__summary}>
                        <div className={styles.ManageStorageModal__summaryItem}>
                            <p
                                className={
                                    styles.ManageStorageModal__summaryLabel
                                }
                            >
                                Added Storage
                            </p>
                            <p
                                className={
                                    styles.ManageStorageModal__summaryValue
                                }
                            >
                                {prettyBytes(storage * 1000000000)}
                            </p>
                        </div>
                        {renderPrice()}
                    </div>
                </div>
                {renderBillingPeriod()}
            </div>
            <div className={styles.ManageStorageModal__actionsContainer}>
                {renderSubmitButton()}
                {renderCancelAddonButton()}
            </div>
            {/* {closeButton} */}
        </div>
    );
};

export default ManageStorageModal;
