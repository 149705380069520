import React, { ChangeEvent, memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  type?: string;
}

const NewInput: React.FC<Props> = memo((props: Props) => {
  const {
    value, onChange, onKeyDown, placeholder, className, disabled, type
  } = props;

  return (
    <input
      onKeyDown={onKeyDown}
      className={[styles.NewInput, className || null].join(
        ' ',
      )}
      value={value}
      onChange={onChange}
      placeholder={placeholder || ''}
      disabled={disabled}
      type={type || 'text'}
    />
  );
});

export default NewInput;
