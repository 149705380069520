import { build } from 'search-params';
import { AxiosResponse } from 'axios';

import store from 'state/store';
import $http from './instance';

class StatsClient {
  public static async getUploadStats(
    params: {
            dateFrom: number;
            dateTo: number;
        } | null,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    const query = build({
      offset: 0,
      limit: 1000,
      'time[lte]': params?.dateTo,
      'time[gte]': params?.dateFrom,
    });

    return $http.get(`/user/${userId}/statistic/upload?${query}`);
  }

  public static async getAnalyzeStats(
    params: {
            dateFrom: number;
            dateTo: number;
        } | null,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    const query = build({
      offset: 0,
      limit: 1000,
      'time[lte]': params?.dateTo,
      'time[gte]': params?.dateFrom,
    });

    return $http.get(`/user/${userId}/statistic/analyze?${query}`);
  }

  public static async getGenerationStats(
    params: {
            dateFrom: number;
            dateTo: number;
        } | null,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    const query = build({
      offset: 0,
      limit: 1000,
      'time[lte]': params?.dateTo,
      'time[gte]': params?.dateFrom,
    });

    return $http.get(`/user/${userId}/statistic/generation?${query}`);
  }
}

export default StatsClient;
