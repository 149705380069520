import { DateFilterOption } from 'interfaces/filter';
import { ProjectPreferences, UserPreferences } from 'interfaces/preferences';
import { StatsFilterCategory, UserStatsFilter } from 'interfaces/stats';
import { Invitation } from 'interfaces/invites';
import { setUserRoles } from './actions';

export interface UserState {
    userPreferences: UserPreferences | null;
    defaultUserPreferences: UserPreferences;
    userInfo: User | null;
    statsFilter: UserStatsFilter;
    analysisStatsFilter: UserStatsFilter;
    generationStatsFilter: UserStatsFilter;
    uploadStatsFilter: UserStatsFilter;
    uploadStats: Array<UploadStatsItem>;
    generationStats: Array<GenerationStatsItem>;
    analyzeStats: Array<AnalysisStatsItem>;
    isStatsLoading: boolean;
    projectPreferences: ProjectPreferences | null;
    isToggleToSmartSearchAvailable: boolean;
    isFirstLogin: boolean;
    inviteCode: string;
    isInviteCodeGenerationLoading: boolean;
    invites: Invitation[];
    ivitesTotal: number;
    isSendInviteLoading: boolean;
    isInvitesLoading: boolean;
    roles: string[];
}

export interface User {
    id: string;
    username: string;
    attributes: {
        sub?: string;
        email_verified: string;
        email: string;
        name?: string;
    };
}

export interface UploadStatsItem {
    userId: string;
    activityId: string;
    time: number;
    activityName: UploadStatsActivityName;
    activityType: UploadStatsActivityType;
    status: UploadStatsActivityStatus;
    videoId: string;
    fileSize: number;
    duration: number;
    key: string;
    videoTitle: string;
}

export enum UploadStatsActivityName {
    UPLOAD = 'UPLOAD',
}

export enum UploadStatsActivityType {
    UPLOAD = 'UPLOAD',
}

export enum UploadStatsActivityStatus {
    SUCCEEDED = 'SUCCEEDED',
}

export interface GenerationStatsItem {
    userId: string;
    activityId: string;
    time: number;
    activityName: GenerationStatsActivityName;
    activityType: GenerationStatsActivityType;
    status: GenerationStatsActivityStatus;
    videoId: string;
    fileSize: number;
    duration: number;
    key: string;
    videoTitle: string;
    trailerName: string;
    trailerVersion: number;
    clipId: string;
    videoFileKey: string;
}

export enum GenerationStatsActivityName {
    UPLOAD = 'GENERATION',
}

export enum GenerationStatsActivityType {
    UPLOAD = 'GENERATION',
}

export enum GenerationStatsActivityStatus {
    SUCCEEDED = 'SUCCEEDED',
}

export interface AnalysisStatsItem {
    userId: string;
    activityId: string;
    time: number;
    activityName: AnalysisStatsActivityName;
    activityType: AnalysisStatsActivityType;
    status: AnalysisStatsActivityStatus;
    videoId: string;
    videoSize: number;
    videoDuration: number;
    videoTitle: string;
    startTime: number;
    endTime: number;
}

export enum AnalysisStatsActivityName {
    MODERATED_LABELS = 'MODERATED_LABELS',
}

export enum AnalysisStatsActivityType {
    ANALYZE = 'ANALYZE',
}

export enum AnalysisStatsActivityStatus {
    SUCCEEDED = 'SUCCEEDED',
    COMPLETED = 'COMPLETED',
}

export enum UserActionTypes {
    GET_STATS = '@@user/GET_STATS',
    GET_STATS_START = '@@user/GET_STATS_START',
    GET_STATS_SUCCESS = '@@user/GET_STATS_SUCCESS',
    GET_STATS_FAIL = '@@user/GET_STATS_FAIL',
    SIGN_OUT = '@@user/SIGN_OUT',
    SET_USER_PREFERENCES = '@@user/SET_USER_PREFERENCES',
    GET_USER_PREFERENCES = '@@user/GET_USER_PREFERENCES',
    UPDATE_USER_PREFERENCES = '@@user/UPDATE_USER_PREFERENCES',
    GET_USER = '@@user/GET_USER',
    SET_USER = '@@user/SET_USER',
    CHANGE_STATS_FILTER = '@@user/CHANGE_STATS_FILTER',
    RESET_STATS_FILTER = '@@user/RESET_STATS_FILTER',
    SET_STATS_FILTER = '@@user/SET_STATS_FILTER',

    SET_ANALYSIS_STATS = '@@user/SET_ANALYSIS_STATS',
    SET_GENERATION_STATS = '@@user/SET_GENERATION_STATS',
    SET_UPLOAD_STATS = '@@user/SET_UPLOAD_STATS',

    SET_ANALYSIS_STATS_FILTER = '@@user/SET_ANALYSIS_STATS_FILTER',
    SET_GENERATION_STATS_FILTER = '@@user/SET_GENERATION_STATS_FILTER',
    SET_UPLOAD_STATS_FILTER = '@@user/SET_UPLOAD_STATS_FILTER',

    GET_ANALYSIS_STATS = '@@user/GET_ANALYSIS_STATS',
    GET_GENERATION_STATS = '@@user/GET_GENERATION_STATS',
    GET_UPLOAD_STATS = '@@user/GET_UPLOAD_STATS',
    SET_PROJECT_PREFERENCES = '@@user/SET_PROJECT_PREFERENCES',
    SET_TOGGLE_TO_SMART_SEARCH_AVAILABLE_STATUS = '@@user/SET_TOGGLE_TO_SMART_SEARCH_AVAILABLE_STATUS',

    SET_FIRST_LOGIN_STATUS = '@@user/SET_FIRST_LOGIN_STATUS',

    GET_INVITE_CODE = '@@user/GET_INVITE_CODE',
    GET_INVITE_CODE_START = '@@user/GET_INVITE_CODE_START',
    GET_INVITE_CODE_FAIL = '@@user/GET_INVITE_CODE_FAIL',
    SET_INVITE_CODE = '@@user/SET_INVITE_CODE',

    SEND_INVITE = '@@user/SEND_INVITE',
    SEND_INVITE_START = '@@user/SEND_INVITE_START',
    SEND_INVITE_SUCCESS = '@@user/SEND_INVITE_SUCCESS',
    SEND_INVITE_FAIL = '@@user/SEND_INVITE_FAIL',

    GET_INVITES = '@@user/GET_INVITES',
    GET_INVITES_FAIL = '@@user/GET_INVITES_FAIL',
    GET_INVITES_START = '@@user/GET_INVITES_START',

    SET_INVITES = '@@user/SET_INVITES',

    SET_USER_ROLES = '@@user/SET_USER_ROLES',
}

// Invites

export interface SetInviteCodeAction {
    type: UserActionTypes.SET_INVITE_CODE;
    payload: string;
}

export interface GetInviteCodeAction {
    type: UserActionTypes.GET_INVITE_CODE;
}

export interface GetInviteCodeStartAction {
    type: UserActionTypes.GET_INVITE_CODE_START;
}

export interface GetInviteCodeFailAction {
    type: UserActionTypes.GET_INVITE_CODE_FAIL;
}

export interface GetInvitesActionPayload {
    isLoadMore?: boolean;
}
export interface GetInvitesAction {
    type: UserActionTypes.GET_INVITES;
    payload: GetInvitesActionPayload;
}

export interface GetInvitesStartAction {
    type: UserActionTypes.GET_INVITES_START;
}

export interface GetInvitesFailAction {
    type: UserActionTypes.GET_INVITES_FAIL;
}

export interface SendInviteAction {
    type: UserActionTypes.SEND_INVITE;
    payload: string;
}

export interface SendInviteStartAction {
    type: UserActionTypes.SEND_INVITE_START;
}

export interface SendInviteSuccessAction {
    type: UserActionTypes.SEND_INVITE_SUCCESS;
}

export interface SendInviteFailAction {
    type: UserActionTypes.SEND_INVITE_FAIL;
}

export interface SetInvitesActionPayload {
    invites: Invitation[];
    total: number;
}

export interface SetInvitesAction {
    type: UserActionTypes.SET_INVITES;
    payload: SetInvitesActionPayload;
}

//

export interface GetStatsAction {
    type: UserActionTypes.GET_STATS;
}
export interface GetStatsStartAction {
    type: UserActionTypes.GET_STATS_START;
}
export interface GetStatsSuccessAction {
    type: UserActionTypes.GET_STATS_SUCCESS;
}

export interface GetStatsFailAction {
    type: UserActionTypes.GET_STATS_FAIL;
}

export interface GetUSerAction {
    type: UserActionTypes.GET_USER;
}

export interface GetUploadStatsAction {
    type: UserActionTypes.GET_UPLOAD_STATS;
}
export interface GetAnalysisStatsAction {
    type: UserActionTypes.GET_ANALYSIS_STATS;
}
export interface GetGenerationStatsAction {
    type: UserActionTypes.GET_GENERATION_STATS;
}
export interface SetUploadStatsAction {
    type: UserActionTypes.SET_UPLOAD_STATS;
    payload: Array<UploadStatsItem>;
}
export interface SetAnalysisStatsAction {
    type: UserActionTypes.SET_ANALYSIS_STATS;
    payload: Array<AnalysisStatsItem>;
}
export interface SetGenerationStatsAction {
    type: UserActionTypes.SET_GENERATION_STATS;
    payload: Array<GenerationStatsItem>;
}
export interface SetUploadStatsFilterAction {
    type: UserActionTypes.SET_UPLOAD_STATS_FILTER;
    payload: UserStatsFilter;
}
export interface SetAnalysisStatsFilterAction {
    type: UserActionTypes.SET_ANALYSIS_STATS_FILTER;
    payload: UserStatsFilter;
}
export interface SetGenerationStatsFilterAction {
    type: UserActionTypes.SET_GENERATION_STATS_FILTER;
    payload: UserStatsFilter;
}
export interface SignOutAction {
    type: UserActionTypes.SIGN_OUT;
}
export interface GetUserPreferencesAction {
    type: UserActionTypes.GET_USER_PREFERENCES;
}
export interface SetUserPreferencesAction {
    type: UserActionTypes.SET_USER_PREFERENCES;
    payload: UserPreferences;
}
export interface UpdateUserPreferencesAction {
    type: UserActionTypes.UPDATE_USER_PREFERENCES;
    payload: UserPreferences;
}
export interface SetUserAction {
    type: UserActionTypes.SET_USER;
    payload: User | null;
}
export interface ChangeStatsFilterActionPayload {
    field: string;
    value: Date | DateFilterOption;
    category: StatsFilterCategory;
}
export interface ChangeStatsFilterAction {
    type: UserActionTypes.CHANGE_STATS_FILTER;
    payload: ChangeStatsFilterActionPayload;
}
export interface SetStatsFilterAction {
    type: UserActionTypes.SET_STATS_FILTER;
    payload: UserStatsFilter;
}
export interface ResetStatsFilterAction {
    type: UserActionTypes.RESET_STATS_FILTER;
    payload: {
        category: StatsFilterCategory;
    };
}

export interface SetProjectPreferencesAction {
    type: UserActionTypes.SET_PROJECT_PREFERENCES;
    payload: ProjectPreferences;
}

export interface SetToggleToSmartSearchAvailableAction {
    type: UserActionTypes.SET_TOGGLE_TO_SMART_SEARCH_AVAILABLE_STATUS;
    payload: boolean;
}

export interface SetFirstLoginStatusAction {
    type: UserActionTypes.SET_FIRST_LOGIN_STATUS;
    payload: boolean;
}

export interface SetUserRolesAction {
    type: UserActionTypes.SET_USER_ROLES;
    payload: string[];
}

export type UserAction =
    | GetStatsAction
    | GetStatsStartAction
    | GetStatsSuccessAction
    | GetStatsFailAction
    | GetUploadStatsAction
    | GetAnalysisStatsAction
    | GetGenerationStatsAction
    | SetUploadStatsAction
    | SetAnalysisStatsAction
    | SetGenerationStatsAction
    | SetUploadStatsFilterAction
    | SetAnalysisStatsFilterAction
    | SetGenerationStatsFilterAction
    | SignOutAction
    | GetUserPreferencesAction
    | SetUserPreferencesAction
    | UpdateUserPreferencesAction
    | SetUserAction
    | ChangeStatsFilterAction
    | SetStatsFilterAction
    | ResetStatsFilterAction
    | SetProjectPreferencesAction
    | GetUSerAction
    | SetToggleToSmartSearchAvailableAction
    | SetFirstLoginStatusAction
    | GetInviteCodeAction
    | GetInvitesAction
    | GetInvitesStartAction
    | GetInvitesFailAction
    | SendInviteAction
    | SendInviteStartAction
    | SendInviteSuccessAction
    | SendInviteFailAction
    | SetInviteCodeAction
    | SetInvitesAction
    | GetInviteCodeStartAction
    | GetInviteCodeFailAction
    | SetUserRolesAction;
