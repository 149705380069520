import { NewButton } from 'components/UI';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal } from 'state/modules/modal';
import { getCustomerInfo } from 'state/modules/payment';

import novaLogo from 'assets/images/novaLogoBlue.png';

import { Colors } from 'styles';
import styles from './styles.module.scss';

const SubscriptionAllSetModal = (): JSX.Element => {
  const customer = useSelector(getCustomerInfo);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(hideModal());
  };

  return (
    <div className={styles.SubscriptionAllSetModal}>
      <div className={styles.SubscriptionAllSetModal__container}>
        <div className={styles.SubscriptionAllSetModal__content}>
          <div
            className={
              styles.SubscriptionAllSetModal__logoContainer
            }
          >
            <img
              alt="logo"
              className={styles.SubscriptionAllSetModal__logo}
              src={novaLogo}
            />
          </div>
          <p className={styles.SubscriptionAllSetModal__title}>
            {`Thanks, ${customer?.name || ''}!`}
          </p>
          <p className={styles.SubscriptionAllSetModal__message}>
            You're all set to start creating!
          </p>
          <div className={styles.SubscriptionAllSetModal__buttonWrap}>
            <NewButton
              color="#021B63"
              textColor={Colors.WHITE}
              bordered
              borderRadius={26}
              width={332}
              height={46}
              onClick={close}
            >
              Okay
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAllSetModal;
