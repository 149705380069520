import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import StockVideosPanel from 'components/StockVideosPanel';

import { Icon } from 'components/UI';

import { ModalEvent, ModalType, showModal } from 'state/modules/modal';

const Title = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
    color: rgb(24, 25, 27);
    padding-left: 1rem;
    vertical-align: middle;
    white-space: nowrap;
`;

const GoBackButton = styled.button`
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    align-items: center;
    display: flex;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.8125rem 3.5rem;
    min-width: 20vw;
    max-width: 90vw;
    max-height: 93vh;
    width: 45rem;
    background-color: rgb(255, 255, 255);
    border-radius: 1.25rem;
    height: 610px;
    z-index: 10;
`;

const StockVideosModal = () => {
    const dispatch = useDispatch();

    const handleGoBack = () => {
        dispatch(
            showModal(ModalType.PROJECT_UPLOAD, ModalEvent.PROJECT_UPLOAD)
        );
    };

    return (
        <Container>
            <Header>
                <GoBackButton onClick={handleGoBack}>
                    <Icon name="arrow-left" size={16} />
                </GoBackButton>
                <Title>Stock Videos</Title>
            </Header>
            <StockVideosPanel inModal />
        </Container>
    );
};

export default StockVideosModal;
