import { IconProps } from './index';

const FolderOutlineIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            fill="none"
            width={height}
            height={height}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m14.6663 12.6667c0 .3536-.1404.6927-.3905.9428-.25.25-.5892.3905-.9428.3905h-10.66666c-.35362 0-.69276-.1405-.94281-.3905-.25005-.2501-.39052-.5892-.39052-.9428v-9.33337c0-.35362.14047-.69276.39052-.94281.25005-.25004.58919-.39052.94281-.39052h2.26297c.6687 0 1.29317.3342 1.6641.8906l.44273.6641c.18547.2782.4977.4453.83205.4453h5.46481c.3536 0 .6928.14048.9428.39052.2501.25005.3905.58919.3905.94281z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FolderOutlineIcon;
