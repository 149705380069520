import { TransformedSentence } from 'state/modules/metadata';
import { SelectedMetadataItem } from 'state/modules/videoExplorer';
import { getRandomColor } from 'state/utils/videosUtils';
import { transformTranscriptTimestampsToScenes } from './timestampsHelpers';

export const getAllTranscriptItems = (
  selectedItems: Array<SelectedMetadataItem>,
  transcript: Array<TransformedSentence>,
): Array<SelectedMetadataItem> => {
  const selectedTranscriptItems = selectedItems.filter(
    (item: SelectedMetadataItem) => {
      const isSelected = selectedItems.find(
        (selectedItem: SelectedMetadataItem) => selectedItem.name === item.name
                    && selectedItem.type === item.type,
      );

      if (isSelected) {
        return item;
      }

      return false;
    },
  );

  const restTranscriptItems = transcript.filter(
    (item: TransformedSentence) => {
      const isSelected = selectedItems.find(
        (selectedItem: SelectedMetadataItem) => selectedItem.name === item.name
                    && selectedItem.type === item.type,
      );

      if (!isSelected) {
        return item;
      }

      return false;
    },
  );

  const changedTranscript = restTranscriptItems.map(
    (item: TransformedSentence) => {
      const color = getRandomColor();
      const scenes = transformTranscriptTimestampsToScenes(item, color);

      return {
        ...item,
        scenes,
        color,
      };
    },
  );

  return [...selectedTranscriptItems, ...changedTranscript].map((item) => ({
    ...item,
    isVisible: true,
  }));
};
