import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'components/UI';

import { getCollections } from 'state/modules/collections';
import { hideModal } from 'state/modules/modal';

import CollectionsClient from 'services/api/collections';

import styles from './styles.module.scss';

const AddCollectionModal = () => {
  const [collectionName, setCollectionName] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleSaveCollection = async () => {
    try {
      setLoading(true);

      const collectionRes = await CollectionsClient.createCollection(
        collectionName,
      );

      if (collectionRes.data) {
        setTimeout(() => {
          setLoading(false);
          dispatch(hideModal());
          dispatch(getCollections());
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSaveCollection();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [collectionName]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionName(event.target.value);
  };

  return (
    <div className={styles.AddCollectionModal}>
      <p className={styles.AddCollectionModal__title}>
        Collection name:
        {' '}
      </p>
      <div className={styles.AddCollectionModal__inputWrap}>
        <Input
          error=""
          type="text"
          value={collectionName}
          onChange={handleOnChange}
          aligned
          inputRef={inputRef}
        />
      </div>
      <Button
        red
        type="button"
        small
        onClick={handleSaveCollection}
        disabled={loading}
      >
        {loading ? (
          <Loader
            type="ThreeDots"
            color="#ffffff"
            height={30}
            width={45}
          />
        ) : (
          'Save'
        )}
      </Button>
    </div>
  );
};

export default memo(AddCollectionModal);
