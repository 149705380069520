import { useEffect } from 'react';

const useKeyboardEvent = (key: any, callback: any) => {
  useEffect(() => {
    const handler = (event: any) => {
      if (event.key === key) {
        callback();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  });
};

export default useKeyboardEvent;
