import React from 'react';

import { NewInput } from 'components/UI';
import styles from './styles.module.scss';

interface IStepOneProps {
  email: string;
  emailError: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StepOne = (props: IStepOneProps): JSX.Element => {
  const { email, emailError, handleChangeEmail } = props;

  return (
    <div className={styles.StepOne}>
      <div className={styles.StepOne__inputWrap}>
        <NewInput
          type="email"
          value={email}
          onChange={handleChangeEmail}
          placeholder="Email"
        />
        {emailError.length ? <p className={styles.StepOne__inputErrorMessage}>{emailError}</p> : null}
      </div>
      <div className={styles.StepOne__description}>
        <p className={styles.StepOne__descriptionText}>
          Enter your email address and we will send you an email
          with the account recovery code
        </p>
      </div>
    </div>
  );
};

export default StepOne;
