import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 33 / 32;

const VideoIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 32;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_419_17823)">
                <path
                    d="M28.5 2H23.1187L23.0562 2.0625L15.1187 10H20.875L20.9375 9.9375L28.8625 2.01875C28.7437 2.00625 28.625 2 28.5 2ZM32.5 10V6C32.5 5.05625 32.1688 4.18125 31.625 3.5L25.125 10H32.5ZM18.8813 2H13.1187L13.0562 2.0625L5.11875 10H10.875L10.9375 9.9375L18.875 2H18.8813ZM4.5 2C2.29375 2 0.5 3.79375 0.5 6V10H0.88125L0.94375 9.9375L8.88125 2H4.5ZM32.5 12H0.5V26C0.5 28.2062 2.29375 30 4.5 30H28.5C30.7062 30 32.5 28.2062 32.5 26V12Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_419_17823">
                    <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default VideoIcon;
