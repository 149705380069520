import { VideoTransitionType } from 'interfaces/projects';

export const isSingleImageTransition = (
    transitionType: VideoTransitionType
): boolean => {
    return (
        transitionType === VideoTransitionType.FADE_BLACK ||
        transitionType === VideoTransitionType.FADE_WHITE ||
        transitionType === VideoTransitionType.RECT_CROP ||
        transitionType === VideoTransitionType.CIRCLE_CROP ||
        transitionType === VideoTransitionType.ZOOM_IN
    );
};
