import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import {
    CurrentSubscription,
    getCurrentDubbingCredits,
    PlanNameId,
} from 'state/modules/payment';
import { ModalEvent, ModalType, showModal } from 'state/modules/modal';
import { NEUTRAL_100, NEUTRAL_200, NEUTRAL_300, NEUTRAL_600, PURPLE_PRIMARY, WHITE_PRIMARY } from '../../styles/colors';
import { Icon } from '../UI';

const AvailableDubbingMinutesContainer = styled.div<{ isFixed?: boolean, isFromMenu?: boolean }>`
    padding: 1rem;
    background-color: ${WHITE_PRIMARY};
    border: ${({ isFromMenu }) =>
            isFromMenu ? `1px solid ${NEUTRAL_100}` : 'none'};
    border-radius: 6px;
    height: ${({ isFixed, isFromMenu }) =>
            isFromMenu ? '132px' : (isFixed ? '100px' : 'auto')};
    display: flex;
    flex-direction: column;

    align-items: ${({ isFromMenu }) =>
            isFromMenu ? 'center' : 'initial'};

    @media (max-height: 860px) {
            padding: 0.625rem;
        height: ${({ isFixed, isFromMenu }) =>
                isFromMenu ? '132px' : (isFixed ? '95px' : 'auto')};
    }
`;

const IconContainer = styled.div`
    margin-bottom: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
`;

const AvailableDubbingMinutesTitle = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: ${NEUTRAL_600};
    margin-bottom: 0.25rem;
    line-height: 0.938rem;
`;

const Minutes = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.781rem;
    text-align: left;
    color: ${NEUTRAL_300};
`;

const BuyMoreTimeButton = styled.button`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
    color: ${PURPLE_PRIMARY};
    cursor: pointer;
    border: none;
    background: transparent;
    margin-top: auto;

    &:disabled {
        color: ${NEUTRAL_200};
        cursor: not-allowed;
    }
`;

interface Props {
    currentSubscription?: CurrentSubscription | null;
    hideButton?: boolean;
    isFixed?: boolean;
    isFeaturesMenu?: boolean,
    handleBuyMore?: () => void,
}

const AvailableDubbingMinutes = ({
                              currentSubscription,
                              hideButton,
                              isFixed,
                              isFeaturesMenu,
                              handleBuyMore,
                          }: Props) => {
    const location = useLocation();

    const currentFlow = useMemo(() => {
        if (location.pathname.includes('/video-editing')) {
            return 'videoEditing';
        }
        return 'smartSearch';
    }, [location]);

    const currentCredits = useSelector(getCurrentDubbingCredits) as number;

    const dispatch = useDispatch();

    const handleBuyMoreMinutes = () => {
        if(handleBuyMore) {
            handleBuyMore();
        }

        // dispatch(
        //     showModal(ModalType.ADD_MORE_DUBBING_CREDITS, ModalEvent.ADD_MORE_DUBBING_CREDITS)
        // );
    };

    const renderBuyMoreCreditsButton = () => {
        const isDisabled =
            !currentSubscription ||
            (currentSubscription.status === 'canceled' &&
                currentSubscription.stripeStatus === 'canceled') ||
            currentSubscription?.planNameId === PlanNameId.INITIAL;

        return (
            <BuyMoreTimeButton
                onClick={handleBuyMoreMinutes}
                disabled={isDisabled}
            >
                Buy more
            </BuyMoreTimeButton>
        );
    };

    return (
        <AvailableDubbingMinutesContainer
            isFixed={isFixed}
            isFromMenu={isFeaturesMenu}
        >
            {
                isFeaturesMenu && (
                    <IconContainer>
                        <Icon name="microphone" size={24} color={PURPLE_PRIMARY} />
                    </IconContainer>
                )
            }
            {
                (currentFlow === 'videoEditing') && (
                    <>
                        <AvailableDubbingMinutesTitle>
                            Dubbing time
                        </AvailableDubbingMinutesTitle>
                        <Minutes>
                            {moment
                                .duration(
                                    currentCredits * 6 * 6 * 10000,
                                    'milliseconds'
                                )
                                .format('h[h] m[m] s[s]', {
                                    useGrouping: false,
                                    trim: 'both',
                                })}
                        </Minutes>
                    </>
                )
            }
            {
                (currentFlow === 'smartSearch') && (
                    <>
                        <AvailableDubbingMinutesTitle>
                            Analysis time
                        </AvailableDubbingMinutesTitle>
                        <Minutes>
                            {moment
                                .duration(
                                    currentCredits * 6 * 6 * 10000,
                                    'milliseconds'
                                )
                                .format('h[h] m[m] s[s]', {
                                    useGrouping: false,
                                    trim: 'both',
                                })}
                        </Minutes>
                    </>
                )
            }
            {!hideButton ? renderBuyMoreCreditsButton() : null}
        </AvailableDubbingMinutesContainer>
    );
};

export default AvailableDubbingMinutes;
