import { DateFilterOption } from 'interfaces/filter';
import { StatusType } from 'interfaces/statuses';
import { MediaType, MediaFile, VideoFilter } from 'interfaces/videos';

export interface VideosState {
    analyzedVideos: Array<MediaFile>;
    audioFiles: Array<MediaFile>;
    videosIdsForDelete: Array<string>;
    key: string;
    isVideosLoading: boolean;
    isVideosDeleting: boolean;
    selectedVideos: Array<MediaFile>;
    videos: Array<MediaFile>;
    videoFilter: VideoFilter;
    totalVideos: number;
    totalAudioFiles: number;
    error: any;
    videoDetails: MediaFile | null;
    videoThumbnails: string | null;
    isVideoDetailsLoading: boolean;
    isVideoUpdateLoading: boolean;
    statusFilter: StatusType.FAILED | StatusType.SUCCEEDED | StatusType.IN_PROGRESS | null;
}

export interface GetVideoListByFilterReqParams {
    dateFrom?: string;
    dateTo?: string;
    title: string;
    description: string;
    mediaType: MediaType;
}

export enum VideosActionTypes {
    GET_VIDEOS_LIST = '@@videos/GET_VIDEOS_LIST',
    GET_VIDEOS_LIST_START = '@@videos/GET_VIDEOS_LIST_START',
    GET_VIDEOS_LIST_SUCCESS = '@@videos/GET_VIDEOS_LIST_SUCCESS',
    GET_VIDEOS_LIST_FAIL = '@@videos/GET_VIDEOS_LIST_FAIL',
    CLEAR_VIDEO_DETAILS = '@@videos/CLEAR_VIDEO_DETAILS',
    GET_VIDEO_DETAILS = '@@videos/GET_VIDEO_DETAILS',
    GET_VIDEO_DETAILS_START = '@@videos/GET_VIDEO_DETAILS_START',
    SET_VIDEO_DETAILS = '@@videos/SET_VIDEO_DETAILS',
    SELECT_VIDEO = '@@videos/SELECT_VIDEO',
    TOGGLE_ALL_VIDEOS = '@@videos/TOGGLE_ALL_VIDEOS',
    UNSELECT_VIDEO = '@@videos/UNSELECT_VIDEO',
    SET_SELECTED_VIDEOS = '@@videos/SET_SELECTED_VIDEOS',
    UPDATE_VIDEOS_STATUSES = '@@videos/UPDATE_VIDEOS_STATUSES',
    UPDATE_VIDEOS_STATUSES_START = '@@videos/UPDATE_VIDEOS_STATUSES_START',
    UPDATE_VIDEOS_STATUSES_SUCCESS = '@@videos/UPDATE_VIDEOS_STATUSES_SUCCESS',
    UPDATE_VIDEOS_STATUSES_FAIL = '@@videos/UPDATE_VIDEOS_STATUSES_FAIL',
    DELETE_SPECIFIC_VIDEO = '@@videos/DELETE_SPECIFIC_VIDEO',
    DELETE_VIDEOS = '@@videos/DELETE_VIDEOS',
    DELETE_VIDEOS_START = '@@videos/DELETE_VIDEOS_START',
    DELETE_VIDEOS_SUCCESS = '@@videos/DELETE_VIDEOS_SUCCESS',
    DELETE_VIDEOS_FAIL = '@@videos/DELETE_VIDEOS_FAIL',
    SET_VIDEOS_IDS_FOR_DELETE = '@@videos/SET_VIDEOS_IDS_FOR_DELETE',
    SET_VIDEO_VTT = '@@videos/SET_VIDEO_VTT',
    SET_VIDEO_DETAILS_LOADING = '@@videos/SET_VIDEO_DETAILS_LOADING',
    DOWNLOAD_VIDEOS = '@@videos/DOWNLOAD_VIDEOS',
    CHANGE_VIDEO_FILTER = '@@videos/CHANGE_VIDEO_FILTER',
    SET_VIDEO_FILTER = '@@videos/SET_VIDEO_FILTER',
    RESET_VIDEO_LIBRARY_FILTER = '@@videos/RESET_VIDEO_LIBRARY_FILTER',
    UPDATE_VIDEO_DETAILS = '@@videos/UPDATE_VIDEO_DETAILS',
    UPDATE_VIDEO_DETAILS_START = '@@videos/UPDATE_VIDEO_DETAILS_START',
    UPDATE_VIDEO_DETAILS_SUCCESS = '@@videos/UPDATE_VIDEO_DETAILS_SUCCESS',
    UPDATE_VIDEO_DETAILS_FAIL = '@@videos/UPDATE_VIDEO_DETAILS_FAIL',
    SYNC_MEDIA_DETAILS = '@@videos/SYNC_MEDIA_DETAILS',

    SET_MEDIA_LIST_STATUS_FILTER = '@@videos/SET_MEDIA_LIST_STATUS_FILTER',
}

export interface GetVideoListStartAction {
    type: VideosActionTypes.GET_VIDEOS_LIST_START;
}
export interface GetVideoListAction {
    type: VideosActionTypes.GET_VIDEOS_LIST;
    payload: { isLoadMore?: boolean };
}
export interface GetVideoListSuccessAction {
    type: VideosActionTypes.GET_VIDEOS_LIST_SUCCESS;
    payload: { videos: Array<MediaFile>; total: number };
}
export interface GetVideoListFailAction {
    type: VideosActionTypes.GET_VIDEOS_LIST_FAIL;
    payload: any;
}
export interface UpdateVideosStatusesAction {
    type: VideosActionTypes.UPDATE_VIDEOS_STATUSES;
}
export interface UpdateVideosStatusesStartAction {
    type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_START;
}
export interface UpdateVideosStatusesSuccessAction {
    type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_SUCCESS;
    payload: Array<MediaFile>;
}
export interface UpdateVideosStatusesFailAction {
    type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_FAIL;
    payload: any;
}
export interface GetVideoDetailsStartAction {
    type: VideosActionTypes.GET_VIDEO_DETAILS_START;
}
export interface GetVideoDetailsAction {
    type: VideosActionTypes.GET_VIDEO_DETAILS;
    payload: {
        id: string;
    };
}
export interface SetVideoDetailsAction {
    type: VideosActionTypes.SET_VIDEO_DETAILS;
    payload: MediaFile;
}
export interface SetVideoVttAction {
    type: VideosActionTypes.SET_VIDEO_VTT;
    payload: string;
}
export interface SetVideoDetailsLoadingAction {
    type: VideosActionTypes.SET_VIDEO_DETAILS_LOADING;
    payload: boolean;
}
export interface ClearDataAction {
    type: VideosActionTypes.CLEAR_VIDEO_DETAILS;
}
export interface SelectVideoAction {
    type: VideosActionTypes.SELECT_VIDEO;
    payload: {
        id: string;
    };
}
export interface UnselectVideoAction {
    type: VideosActionTypes.UNSELECT_VIDEO;
    payload: {
        id: string;
    };
}
export interface ToggleAllVideosAction {
    type: VideosActionTypes.TOGGLE_ALL_VIDEOS;
}
export interface SetSelectedVideosAction {
    type: VideosActionTypes.SET_SELECTED_VIDEOS;
    payload: Array<MediaFile>;
}
export interface DeleteSpecificVideoAction {
    type: VideosActionTypes.DELETE_SPECIFIC_VIDEO;
    payload: {
        videoId: string;
    };
}
export interface DeleteVideosAction {
    type: VideosActionTypes.DELETE_VIDEOS;
}
export interface DeleteVideosStartAction {
    type: VideosActionTypes.DELETE_VIDEOS_START;
}

export interface DeleteVideosSuccessAction {
    type: VideosActionTypes.DELETE_VIDEOS_SUCCESS;
}
export interface DeleteVideosFailAction {
    type: VideosActionTypes.DELETE_VIDEOS_FAIL;
    payload: any;
}
export interface SetVideosIdsForDeleteAction {
    type: VideosActionTypes.SET_VIDEOS_IDS_FOR_DELETE;
    payload: Array<string>;
}
export interface DownloadVideosAction {
    type: VideosActionTypes.DOWNLOAD_VIDEOS;
}
export interface ChangeVideoLibraryFilterActionPayload {
    field: string;
    value: Date | string | DateFilterOption;
}
export interface ChangeVideoLibraryFilterAction {
    type: VideosActionTypes.CHANGE_VIDEO_FILTER;
    payload: ChangeVideoLibraryFilterActionPayload;
}
export interface SetVideoLibraryFilterAction {
    type: VideosActionTypes.SET_VIDEO_FILTER;
    payload: VideoFilter;
}
export interface ResetVideoLibraryFilterAction {
    type: VideosActionTypes.RESET_VIDEO_LIBRARY_FILTER;
}
export interface UpdateVideoDetailsActionPayload {
    title: string;
    description: string;
    videoId: string;
    defaultLanguageCode: string;
}
export interface UpdateVideoDetailsAction {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS;
    payload: UpdateVideoDetailsActionPayload;
}
export interface UpdateVideoDetailsStartAction {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_START;
}
export interface UpdateVideoDetailsSuccessAction {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_SUCCESS;
}
export interface UpdateVideoDetailsFailAction {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_FAIL;
}

export interface SyncMediaDetailsAction {
    type: VideosActionTypes.SYNC_MEDIA_DETAILS;
}

export interface SetMediaListStatusFilterAction {
    type: VideosActionTypes.SET_MEDIA_LIST_STATUS_FILTER,
    payload: StatusType.FAILED | StatusType.SUCCEEDED | StatusType.IN_PROGRESS | null;
}

export type VideosAction =
    | GetVideoListStartAction
    | GetVideoListAction
    | GetVideoListSuccessAction
    | GetVideoListFailAction
    | UpdateVideosStatusesAction
    | UpdateVideosStatusesStartAction
    | UpdateVideosStatusesFailAction
    | UpdateVideosStatusesSuccessAction
    | GetVideoDetailsStartAction
    | GetVideoDetailsAction
    | SetVideoDetailsAction
    | SetVideoVttAction
    | SetVideoDetailsLoadingAction
    | ClearDataAction
    | SelectVideoAction
    | UnselectVideoAction
    | ToggleAllVideosAction
    | SetSelectedVideosAction
    | DeleteSpecificVideoAction
    | DeleteVideosAction
    | DeleteVideosStartAction
    | DeleteVideosSuccessAction
    | DeleteVideosFailAction
    | SetVideosIdsForDeleteAction
    | DownloadVideosAction
    | ChangeVideoLibraryFilterAction
    | SetVideoLibraryFilterAction
    | ResetVideoLibraryFilterAction
    | UpdateVideoDetailsAction
    | UpdateVideoDetailsStartAction
    | UpdateVideoDetailsSuccessAction
    | UpdateVideoDetailsFailAction
    | SyncMediaDetailsAction
    | SetMediaListStatusFilterAction
