import { IconProps } from './index';

const OriginalSizeIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            className="sc-bcXHqe fysLci OptionItemstyled__SocialIcon-sc-1qp2ef7-1 snsbc"
        >
            <path
                d="M19 7.75C20.7949 7.75 22.25 9.20507 22.25 11L22.25 14C22.25 15.7949 20.7949 17.25 19 17.25L5 17.25C3.20507 17.25 1.75 15.7949 1.75 14L1.75 11C1.75 9.20507 3.20507 7.75 5 7.75L19 7.75Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export default OriginalSizeIcon;
