import { ChangeEvent, CSSProperties, memo, useMemo, useState } from 'react';
import Select from 'react-select';

import { Icon, ProgressBar, Switcher } from 'components/UI';

import { languagesArray } from 'utils/language';
import { ANALYSIS_CATEGORIES_TITLES } from 'utils/constants';

import {
    UploadingStatus,
    UploadingMedia,
    UploadingVideoDataForUpdate,
} from 'interfaces/uploading';
import { TransformedCollectionForUploadingVideo } from 'interfaces/collections';
import { UserPreferences } from 'interfaces/preferences';
import { RekognitionCategory } from 'interfaces/analysis';

import styles from './styles.module.scss';

const colourStyles = {
    control: (selectStyles: CSSProperties) => ({
        ...selectStyles,
        border: 'none',
        borderRadius: 12,
        borderWidth: 0,
        background: 'transparent',
        padding: 0,
        outline: 'none',
        height: '100%',
    }),
    input: (inputStyles: CSSProperties) => ({
        ...inputStyles,
        border: 'none',
        padding: '5px 0',
        color: '#707070',
    }),
    singleValue: (singleValueStyles: CSSProperties) => ({
        ...singleValueStyles,
        color: '#707070',
    }),
    placeholder: (placeholderStyles: CSSProperties) => placeholderStyles,
    option: (optionStyles: CSSProperties) => optionStyles,
    menu: (menuStyles: CSSProperties) => ({
        ...menuStyles,
    }),
};

interface Props {
    setVideoLanguage: (
        videoId: string,
        languageForTranscript: string | null
    ) => void;
    setVideoCollection: (videoId: string, collectionId: string) => void;
    setVideoAnalysisCategories: (
        videoId: string,
        categories: Array<string>
    ) => void;
    setAutoAnalyzeStatus: (videoId: string, autoAnalyze: boolean) => void;
    onDeleteClick: (videoId: string) => void;
    statuses: UploadingStatus | null;
    file: UploadingMedia;
    collections: Array<TransformedCollectionForUploadingVideo>;
    userPreferences: UserPreferences;
    defaultUserPreferences: UserPreferences;
    changeVideoInfo: ({
        value,
        videoId,
        field,
    }: UploadingVideoDataForUpdate) => void;
    setIsAnalyzeOn: (isOn: boolean) => void;
    setSelectedCollectionId: (id: string) => void;
}

const UploadMediaFile = (props: Props) => {
    const [isSettingsActive, setIsSettingsActive] = useState(false);

    const {
        onDeleteClick,
        statuses,
        file,
        collections,
        userPreferences,
        defaultUserPreferences,
        setVideoLanguage,
        setVideoCollection,
        setVideoAnalysisCategories,
        setAutoAnalyzeStatus,
        changeVideoInfo,
        setIsAnalyzeOn,
        setSelectedCollectionId,
    } = props;

    const { defaultCategories } = defaultUserPreferences;
    const { defaultUserCollection } = userPreferences;

    const isAudio = file.type === 'audio/mpeg' || file.type === 'audio/wav';

    const collection = collections.find(
        (item: TransformedCollectionForUploadingVideo) =>
            item.id === defaultUserCollection
    );

    const [autoAnalyse, setAutoAnalyze] = useState(userPreferences.autoAnalyse);
    const [selectedCollection, setSelectedCollection] =
        useState<TransformedCollectionForUploadingVideo>(
            collection || collections[0]
        );

    const defaultLanguageValue = useMemo(
        () =>
            languagesArray.find(
                (languageItem) =>
                    languageItem.value ===
                    userPreferences?.defaultTranscriptLanguageCode
            ),
        [userPreferences]
    );

    const selectedCollectionImages = useMemo(
        () =>
            collections?.find(
                (collectionItem: TransformedCollectionForUploadingVideo) =>
                    collectionItem?.id === selectedCollection?.id
            )?.images || [],
        [collections, selectedCollection]
    );

    const handleDeleteClick = () => {
        onDeleteClick(file.id);
    };

    const handleSetVideoLanguage = (language: any) => {
        if (language) {
            setVideoLanguage(
                file.id,
                language.value === 'Autodetect' ? null : language.value
            );
        }
    };

    const handleSelectCollection = (newCollection: any) => {
        if (newCollection) {
            const selectedItem = collections.find(
                (item: TransformedCollectionForUploadingVideo) =>
                    item.id === newCollection.id
            );

            if (selectedItem) {
                setSelectedCollection(selectedItem);
                setSelectedCollectionId(selectedItem.id);
            }

            setVideoCollection(file.id, newCollection.id);
        }
    };

    const toggleSettings = () => {
        setIsSettingsActive(!isSettingsActive);
    };

    const handleToggleAutoAnalyze = () => {
        setIsAnalyzeOn(!autoAnalyse);
        setAutoAnalyze(!autoAnalyse);
        setAutoAnalyzeStatus(file.id, !autoAnalyse);
    };

    const handleToggleAutoAnalyzeCategory = (
        category: string,
        isSelected: boolean
    ) => {
        let updatedSelectedCategories: Array<string> = [...file.categories];

        if (isSelected) {
            updatedSelectedCategories = updatedSelectedCategories.filter(
                (item: string) => item !== category
            );
        } else {
            updatedSelectedCategories.push(category);
        }

        setVideoAnalysisCategories(file.id, updatedSelectedCategories);
    };

    const handleChangeVideoTitle = (event: ChangeEvent<HTMLInputElement>) => {
        changeVideoInfo({
            value: event.currentTarget.value,
            videoId: file.id,
            field: 'title',
        });
    };

    const handleChangeVideoDescription = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        changeVideoInfo({
            value: event.currentTarget.value,
            videoId: file.id,
            field: 'description',
        });
    };

    const getIconName = (category: string) => {
        switch (category) {
            case RekognitionCategory.AWS_FACES:
                return 'facial-attributes';
            case RekognitionCategory.AWS_CELEBRITIES:
                return 'celebrity';
            case RekognitionCategory.AWS_LABELS:
                return 'objects-activities';
            case RekognitionCategory.AWS_SHOTS:
                return 'shots';
            case RekognitionCategory.AWS_TRANSCRIPT:
                return 'transcript';
            case RekognitionCategory.AWS_FACE_MATCHES:
                return 'collections';
            case RekognitionCategory.AWS_MODERATED_LABELS:
                return 'attention';
            default:
                return '';
        }
    };

    const renderCategory = (category: string) => {
        const isSelected = file.categories.includes(category);

        let disabled = false;

        if (isAudio) {
            disabled = category !== RekognitionCategory.AWS_TRANSCRIPT;
        } else {
            disabled =
                category === RekognitionCategory.AWS_FACE_MATCHES &&
                !selectedCollectionImages.length;
        }

        const iconName = getIconName(category);

        return (
            <div
                key={category}
                className={styles.UploadMediaFile__analyzeCategory}
            >
                <Switcher
                    checked={isSelected}
                    rightIcon={iconName}
                    labelRight={
                        ANALYSIS_CATEGORIES_TITLES[
                            category as keyof typeof ANALYSIS_CATEGORIES_TITLES
                        ]
                    }
                    disabled={disabled}
                    onChange={() =>
                        handleToggleAutoAnalyzeCategory(category, isSelected)
                    }
                />
            </div>
        );
    };

    const renderAnalysisCategories = () => (
        <div className={styles.UploadMediaFile__analyzeCategories}>
            <p className={styles.UploadMediaFile__settingsTitle}>
                Analysis categories:
            </p>
            <div className={styles.UploadMediaFile__analyzeCategoriesList}>
                {defaultCategories.map((category: string) =>
                    renderCategory(category)
                )}
            </div>
        </div>
    );

    const renderControls = () => {
        if (!statuses) {
            return (
                <>
                    <button
                        className={styles.UploadMediaFile__settingsBtn}
                        onClick={() => toggleSettings()}
                    >
                        <Icon name="settings" />
                    </button>
                    <button
                        className={styles.UploadMediaFile__deleteBtn}
                        onClick={() => handleDeleteClick()}
                    >
                        <Icon name="trash" size={20} />
                    </button>
                </>
            );
        }
        if (statuses?.error) {
            return (
                <button
                    className={styles.UploadMediaFile__deleteBtn}
                    onClick={() => handleDeleteClick()}
                >
                    <Icon name="trash" size={20} />
                </button>
            );
        }
    };

    const renderMediaTitle = () =>
        !statuses ? (
            <input
                onChange={handleChangeVideoTitle}
                className={styles.UploadMediaFile__videoNameInput}
                value={file.title}
                placeholder="Video Title"
            />
        ) : (
            <p className={styles.UploadMediaFile__videoName}>{file.title}</p>
        );

    const renderCollectionsSettings = () =>
        !isAudio ? (
            <div className={styles.UploadMediaFile__settingsItem}>
                <p className={styles.UploadMediaFile__settingsTitle}>
                    Collection:
                </p>
                <div className={styles.UploadMediaFile__selectWrap}>
                    <Select
                        styles={colourStyles as any}
                        options={collections}
                        onChange={handleSelectCollection}
                        defaultValue={selectedCollection || collections[0]}
                        placeholder="Collection"
                        maxMenuHeight={185}
                    />
                </div>
            </div>
        ) : null;

    const renderSettings = () =>
        !statuses && isSettingsActive ? (
            <div className={styles.UploadMediaFile__settingsBlock}>
                <textarea
                    className={styles.UploadMediaFile__description}
                    value={file.description}
                    onChange={handleChangeVideoDescription}
                    placeholder="Description"
                />
                <div className={styles.UploadMediaFile__settingsItem}>
                    <p className={styles.UploadMediaFile__settingsTitle}>
                        Select language for transcript:
                    </p>
                    <div className={styles.UploadMediaFile__selectWrap}>
                        <Select
                            styles={colourStyles as any}
                            options={languagesArray}
                            onChange={handleSetVideoLanguage}
                            defaultValue={
                                defaultLanguageValue || languagesArray[0]
                            }
                            placeholder="Select"
                            maxMenuHeight={185}
                        />
                    </div>
                </div>
                {renderCollectionsSettings()}
                <div className={styles.UploadMediaFile__preference}>
                    <Switcher
                        checked={autoAnalyse}
                        labelRight="Auto analysis"
                        onChange={handleToggleAutoAnalyze}
                    />
                </div>
                {autoAnalyse ? renderAnalysisCategories() : null}
            </div>
        ) : null;

    const renderProgress = () =>
        statuses && (
            <>
                <p className={styles.UploadMediaFile__progressTitle}>
                    Uploading:
                </p>
                <div className={styles.UploadMediaFile__progressWrap}>
                    <ProgressBar
                        currentProgress={statuses.currentProgress}
                        remainingAmount={statuses.remainingAmount}
                    />
                </div>
            </>
        );

    return (
        <>
            <div className={styles.UploadMediaFile__video}>
                <div className={styles.UploadMediaFile__uploadIconWrap}>
                    <Icon name="uploaded-video" size={25} />
                </div>
                <div className={styles.UploadMediaFile__inputWrap}>
                    {renderMediaTitle()}
                </div>
                {renderControls()}
            </div>
            {renderSettings()}
            {renderProgress()}
        </>
    );
};

export default memo(UploadMediaFile);
