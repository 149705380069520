import { ChangeEvent, memo, useMemo, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import download from "downloadjs";
import { useSelector } from "react-redux";

import OffsetFormatSelect from "components/OffsetFormatSelect";
import DropdownAutocomplete from "components/DropdownAutocomplete";
import ListToExport from "components/ListToExport";

import { ButtonLoader, Icon, Tooltip } from "components/UI";

import { getModalOptionalData } from "state/modules/modal";

import {
  edlExportFpsOptions,
  edlFileSettingsOptions,
  edlMergeIntervalOptions,
  getEdlExportDataWithObjectPerFile,
  getEdlExportDataWithVideoPerFile,
} from "utils/edlExportHelpers";

import { transformTimecodeToMs } from "utils/format";

import {
  EdlExportData,
  EdlExportFpsOption,
  EdlExportItem,
  EdlExportSettingsOptionsTitle,
  EdlFileSettingsOption,
  EdlMergeIntervalOption,
  TransformedEdlExportItem,
} from "interfaces/edlExport";
import {
  OffsetSettings,
  OFFSET_MASK,
  OFFSET_MASK_STRING,
  OFFSET_TYPES,
} from "interfaces/export";

import { MediaFile } from "interfaces/videos";
import { timecodeWithFramesToMs } from "utils/export";
import { getVideosUsedForExport } from "state/modules/export";
import styles from "./styles.module.scss";

const windowFork = window;

const DEFAULT_FF_OFFSET = "00:00:00:00";
const DEFAULT_MS_OFFSET = "00:00:00.000";

interface Props {
  closeModal: () => void;
}

const EdlExportModal = (props: Props): JSX.Element => {
  const [name, setName] = useState("Default name");
  const [fps, setFps] = useState<EdlExportFpsOption>(edlExportFpsOptions[0]);
  const [fpsError, setFpsError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [fileSettings, setFileSettings] = useState<EdlFileSettingsOption>(
    edlFileSettingsOptions[0]
  );
  const [mergeInterval, setMergeInterval] = useState<EdlMergeIntervalOption>(
    edlMergeIntervalOptions[0]
  );
  const [mergeIntervalError, setMergeIntervalError] = useState<string | null>(
    null
  );
  const [offsetSettings, setOffsetSettings] = useState<Array<OffsetSettings>>(
    []
  );

  const videosFiles = useSelector(getVideosUsedForExport);
  const dataForExport = useSelector(getModalOptionalData) as EdlExportData;

  const videosData = useMemo(() => {
    const videoIds = Object.keys(dataForExport.groupedData);

    return videosFiles.filter((video: MediaFile) =>
      videoIds.includes(video.id)
    );
  }, [videosFiles, dataForExport]);

  useEffect(() => {
    const settings = videosData.map((item: MediaFile) => {
      const videoOffset =
        item?.mediaInfo?.original?.other?.[0]?.timeCodeFirstFrame;

      let offsetValue = DEFAULT_FF_OFFSET;
      const offsetType = OFFSET_TYPES.FF;

      if (videoOffset) {
        offsetValue = videoOffset;
      }

      return {
        id: item.id,
        offsetValue,
        offsetType,
        videoName: item.filename,
      };
    });

    setOffsetSettings(settings);
  }, [videosData]);

  const handleSelectMergeInterval = (value: EdlMergeIntervalOption) => {
    setMergeInterval(value);
    setMergeIntervalError(null);
  };

  const handleChageMergeIntervalValue = (value: EdlMergeIntervalOption) => {
    if (parseInt(`${value.value}`) >= 1) {
      setMergeInterval(value);
      setMergeIntervalError(null);
    }
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const validate = () => {
    let hasErrors = false;

    if (!fps.value) {
      setFpsError("Required");
      hasErrors = true;
    }

    if (!mergeInterval.value) {
      setMergeIntervalError("Required");
      hasErrors = true;
    }

    return hasErrors;
  };

  const handleExportEdl = async () => {
    const hasErrors = validate();

    if (!hasErrors) {
      setLoading(true);
      const selectedItems = dataForExport.selectedItems as Array<EdlExportItem>;

      let clips = [] as Array<TransformedEdlExportItem>;
      const transformedMergeInterval = mergeInterval.value;

      const transformedOffsetSettings = offsetSettings.map(
        (settingsItem: OffsetSettings) => ({
          value:
            (settingsItem.offsetType === OFFSET_TYPES.FF
              ? timecodeWithFramesToMs(settingsItem.offsetValue, fps)
              : transformTimecodeToMs(settingsItem.offsetValue)) || 0,
          id: settingsItem.id,
        })
      );

      if (fileSettings.value === EdlExportSettingsOptionsTitle.ObjectPerFile) {
        clips = getEdlExportDataWithObjectPerFile(
          selectedItems,
          transformedMergeInterval,
          transformedOffsetSettings
        );
      } else if (
        fileSettings.value === EdlExportSettingsOptionsTitle.VideoPerFile
      ) {
        clips = getEdlExportDataWithVideoPerFile(
          selectedItems,
          transformedMergeInterval,
          transformedOffsetSettings
        );
      }

      const updatedClips = clips.map((item: TransformedEdlExportItem) => {
        if (item.endTime === item.startTime) {
          return {
            ...item,
            endTime: item.endTime + 1000,
          };
        }
        return item;
      });

      const data = {
        fps: parseFloat(`${fps.value}`),
        timeLineName: name,
        clips: updatedClips,
      };

      try {
        const response = await fetch(
          `${windowFork.config.REACT_APP_SHORT_API_URL}/edl`,
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        );

        const blob = await response.blob();

        download(blob, "edl.export.zip");

        setLoading(false);

        if (blob.size) {
          props.closeModal();
        }
      } catch (error) {
        setLoading(false);
        console.log({ error });
      }
    }
  };

  const handleClose = () => {
    props.closeModal();
  };

  const handleSelectFileSettings = (value: EdlFileSettingsOption) => {
    setFileSettings(value);
  };

  const handleSelectFps = (value: EdlExportFpsOption) => {
    setFps(value);
    setFpsError(null);
  };

  const handleChageFpsValue = (value: EdlExportFpsOption) => {
    setFps(value);
    setFpsError(null);
  };

  const handleChangeOffset = (
    event: ChangeEvent<HTMLInputElement>,
    item: OffsetSettings
  ) => {
    const { value } = event.target;

    const formattedOffset = value.replaceAll(/[a-zA-Z]/g, "0");

    const updatedOffsetSettings = offsetSettings.map(
      (settingsItem: OffsetSettings) => {
        if (settingsItem.id === item.id) {
          return {
            ...settingsItem,
            offsetValue: formattedOffset,
          };
        }

        return settingsItem;
      }
    );

    setOffsetSettings(updatedOffsetSettings);
  };

  const handeChangeOffsetFormat = (
    offsetType: OFFSET_TYPES,
    item: OffsetSettings
  ) => {
    const updatedOffsetSettings = offsetSettings.map(
      (settingsItem: OffsetSettings) => {
        if (settingsItem.id === item.id) {
          if (offsetType === settingsItem.offsetType) return settingsItem;

          let { offsetValue } = item;

          if (item.offsetValue.length >= 8) {
            const offsetWithoutLastPart = item.offsetValue.substring(0, 8);

            const lastPart = +item.offsetValue.substring(9);

            let transformedLastPart = lastPart;

            const frameDuration = 1000 / fps.value;

            if (offsetType === OFFSET_TYPES.FF) {
              transformedLastPart = Math.round(lastPart / frameDuration);
            } else {
              transformedLastPart = Math.round(lastPart * frameDuration);
            }

            offsetValue = `${offsetWithoutLastPart}${
              offsetType === OFFSET_TYPES.FF ? ":" : "."
            }${transformedLastPart}`;
          }

          return {
            ...settingsItem,
            offsetType,
            offsetValue,
          };
        }

        return settingsItem;
      }
    );

    setOffsetSettings(updatedOffsetSettings);
  };

  const renderOffsetSettings = () =>
    offsetSettings.map((item: OffsetSettings) => (
      <div key={item.id} className={styles.EdlExportModal__settingsRow}>
        <div className={styles.EdlExportModal__settingTopLine}>
          <p className={styles.EdlExportModal__settingsItemName}>Offset</p>
          <Tooltip text="Your original video’s starting time code." />
        </div>
        <p className={styles.EdlExportModal__offsetInputTitle}>
          {item.videoName}
        </p>
        <div className={styles.EdlExportModal__offsetInputWrap}>
          <div className={styles.EdlExportModal__offsetSelectWrap}>
            <OffsetFormatSelect
              onChange={(type: OFFSET_TYPES) =>
                handeChangeOffsetFormat(type, item)
              }
              offsetType={item.offsetType}
            />
          </div>
          <InputMask
            alwaysShowMask
            mask={
              item.offsetType === OFFSET_TYPES.FF
                ? OFFSET_MASK.FF
                : OFFSET_MASK.MS
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleChangeOffset(event, item)
            }
            value={item.offsetValue}
            // maskChar=""
            placeholder={
              item.offsetType === OFFSET_TYPES.FF
                ? OFFSET_MASK_STRING.FF
                : OFFSET_MASK_STRING.MS
            }
          />
        </div>
      </div>
    ));

  const renderSelectedItems = () =>
    dataForExport ? (
      <ListToExport videosData={videosData} dataForExport={dataForExport} />
    ) : null;

  return (
    <div className={styles.EdlExportModal}>
      <p className={styles.EdlExportModal__title}>
        Export to Adobe Premiere & FCPX (EDL)
      </p>

      <div className={styles.EdlExportModal__content}>
        <div className={styles.EdlExportModal__leftSide}>
          <p className={styles.EdlExportModal__settingsTitle}>
            Timeline Settings
          </p>
          <p className={styles.EdlExportModal__description}>
            Select your settings first to get the Adobe Premier Pro EDL files
            exported
          </p>
          <div className={styles.EdlExportModal__settingsRow}>
            <div className={styles.EdlExportModal__settingTopLine}>
              <p className={styles.EdlExportModal__settingsItemName}>
                File Frame Rate
              </p>
              <Tooltip text="Your Premiere Pro project’s frame rate." />
            </div>
            <DropdownAutocomplete
              hasInput
              onSelect={handleSelectFps}
              red={Boolean(fpsError)}
              options={edlExportFpsOptions}
              value={fps}
              onChangeValue={handleChageFpsValue}
              rightLabel="Fps"
              error={fpsError}
              setError={setFpsError}
              type="number"
            />
          </div>
          <div className={styles.EdlExportModal__settingsRow}>
            <div className={styles.EdlExportModal__settingTopLine}>
              <p className={styles.EdlExportModal__settingsItemName}>
                Timeline name
              </p>
              <Tooltip text="The name of your sequence on which the clips will appear." />
            </div>
            <input
              className={styles.EdlExportModal__nameInput}
              value={name}
              onChange={handleChangeName}
            />
          </div>
          <div className={styles.EdlExportModal__settingsRow}>
            <div className={styles.EdlExportModal__settingTopLine}>
              <p className={styles.EdlExportModal__settingsItemName}>
                Merge interval
              </p>
              <Tooltip text="Merge moments to one clip if they are found within this time period." />
            </div>
            <DropdownAutocomplete
              hasInput
              onSelect={handleSelectMergeInterval}
              red={Boolean(mergeIntervalError)}
              options={edlMergeIntervalOptions}
              value={mergeInterval}
              onChangeValue={handleChageMergeIntervalValue}
              rightLabel="Seconds"
              type="number"
              error={mergeIntervalError}
              setError={setMergeIntervalError}
            />
          </div>
          <div className={styles.EdlExportModal__settingsRow}>
            <div className={styles.EdlExportModal__settingTopLine}>
              <p className={styles.EdlExportModal__settingsItemName}>
                File Settings
              </p>
              <Tooltip text="Choose if you want to have an EDL file for every label or every video" />
            </div>
            <DropdownAutocomplete
              onSelect={handleSelectFileSettings}
              options={edlFileSettingsOptions}
              value={fileSettings}
            />
          </div>
          {renderOffsetSettings()}
        </div>
        <div className={styles.EdlExportModal__rightSide}>
          {renderSelectedItems()}
        </div>
      </div>
      <div className={styles.EdlExportModal__buttonsWrap}>
        <button
          onClick={handleClose}
          className={[
            styles.EdlExportModal__cancelButton,
            styles.EdlExportModal__actionButton,
          ].join(" ")}
        >
          Cancel
        </button>
        <button
          className={[
            styles.EdlExportModal__exportButton,
            styles.EdlExportModal__actionButton,
          ].join(" ")}
          onClick={handleExportEdl}
          disabled={loading}
        >
          {loading ? <ButtonLoader /> : "Export"}
        </button>
      </div>

      <button
        className={styles.EdlExportModal__closeButton}
        onClick={handleClose}
      >
        <Icon name="close" size={12} color="#6979A5" />
      </button>
    </div>
  );
};

export default memo(EdlExportModal);
