import { AnalysisActionTypes } from 'state/modules/analysis';
import {
  ReanalysisReqData,
  ReanalyzeSpecificVideoAction,
  ReanalyzeSpecificVideoActionPayload,
  ReanalyzeVideosAction,
  ReanalyzeVideosFailAction,
  ReanalyzeVideosStartAction,
  ReanalyzeVideosSuccessAction,
  TranslateMediaAction,
} from './types';

export function reanalyzeSpecificVideo({
  videoId,
  collectionId,
  categories,
  language,
}: ReanalyzeSpecificVideoActionPayload): ReanalyzeSpecificVideoAction {
  return {
    type: AnalysisActionTypes.REANALYZE_SPECIFIC_VIDEO,
    payload: {
      videoId,
      collectionId,
      categories,
      language,
    },
  };
}

export function reanalyzeVideos(
  collectionId: string,
  categories: Array<string>,
  language: string | null,
): ReanalyzeVideosAction {
  return {
    type: AnalysisActionTypes.REANALYZE_VIDEOS,
    payload: {
      collectionId,
      categories,
      language,
    },
  };
}

export function reanalyzeVideosStart(): ReanalyzeVideosStartAction {
  return {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_START,
  };
}

export function reanalyzeVideosSuccess(): ReanalyzeVideosSuccessAction {
  return {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_SUCCESS,
  };
}

export function reanalyzeVideosFail(error: any): ReanalyzeVideosFailAction {
  return {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_FAIL,
    payload: error,
  };
}

export function translateMediaLanguage(payload: ReanalysisReqData): TranslateMediaAction {
  return {
    type: AnalysisActionTypes.TRANSLATE_MEDIA,
    payload,
  };
}
