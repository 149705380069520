import produce from 'immer';
import { ProjectSort, ProjectViewMode } from 'interfaces/projects';

import { ProjectsAction, ProjectsActionTypes, ProjectsState } from './types';

const initialState: ProjectsState = {
    projectsList: [],
    projectsTotal: 0,
    isProjectUpdateLoading: false,
    isProjectsLoading: false,
    projectDetails: null,
    lastProjectGeneration: null,
    projectVideos: [],
    projectAudios: [],
    projectMediaSources: [],
    projectAudioMediaSources: [],
    projectTempMediaSources: [],
    projectTempMediaSourcesPreviews: [],
    isProjectDetailsLoading: true,
    isProjectCreationLoading: false,
    projectsIdsForDelete: [],
    isProjectGenerationLoading: false,
    projectUpdatesHistory: [],
    transitions: [],
    projectsSort: ProjectSort.UPDATED_AT,
    elementsList: [],
    viewMode: ProjectViewMode.GRID,
    filters: [],
    isTextToSpeechGenerationLoading: false,
    projectAudioLanguagesOptions: {},
    getProjectLatestVersionLoading: false,

    projectUploads: [],
    getProjectUploadsSuccess: false,

    mediasWithCleanAudioGeneration: [],
    isChangeTextAnimationLoading: false,

    templateWorkspaceId: null,

    submittedTemplatesList: [],
    submittedTemplatesTotal: 0,
    isSubmittedTemplatesLoading: false,

    publishedTemplatesList: [],
    publishedTemplatesTotal: 0,
    isPublishedTemplatesLoading: false,

    usableTemplatesList: [],
    usableTemplatesTotal: 0,
    isUsableTemplatesLoading: false,

    useTemplateLoading: false,

    projectAudiosIdsWithDubbingGeneration: [],
};

export default (state = initialState, action: ProjectsAction): ProjectsState =>
    produce(state, (draft) => {
        switch (action.type) {
            case ProjectsActionTypes.GET_PROJECTS_START:
                draft.isProjectsLoading = true;

                return;
            case ProjectsActionTypes.GET_PROJECTS_FAIL:
                draft.isProjectsLoading = false;

                return;
            case ProjectsActionTypes.GET_PROJECTS_SUCCESS:
                draft.projectsList = action.payload.projects;
                draft.projectsTotal = action.payload.projectsTotal;
                draft.isProjectsLoading = false;

                return;
            case ProjectsActionTypes.SET_PROJECTS:
                draft.projectsList = action.payload;

                return;
            /// Project Details ////////////////////
            case ProjectsActionTypes.GET_PROJECT_DETAILS_START:
                draft.isProjectDetailsLoading = true;

                return;
            case ProjectsActionTypes.GET_PROJECT_DETAILS_FAIL:
                draft.isProjectDetailsLoading = false;

                return;
            case ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS:
                draft.projectDetails = action.payload;
                draft.isProjectDetailsLoading = false;

                return;
            case ProjectsActionTypes.SET_PROJECT_DETAILS:
                draft.projectDetails = action.payload;
                draft.isProjectDetailsLoading = false;

                return;
            case ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_START:
                draft.getProjectLatestVersionLoading = true;

                return;
            case ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_END:
                draft.getProjectLatestVersionLoading = false;

                return;
            /// Project Videos ///////////////////
            case ProjectsActionTypes.SET_PROJECT_VIDEOS:
                draft.projectVideos = action.payload;

                return;
            /// Update Project////////////////////
            case ProjectsActionTypes.UPDATE_PROJECT_START:
                draft.isProjectUpdateLoading = true;

                return;
            case ProjectsActionTypes.UPDATE_PROJECT_FAIL:
                draft.isProjectUpdateLoading = false;
                draft.isChangeTextAnimationLoading = false;
                draft.useTemplateLoading = false;

                return;
            case ProjectsActionTypes.UPDATE_PROJECT_SUCCESS:
                draft.isProjectUpdateLoading = false;
                draft.isChangeTextAnimationLoading = false;
                draft.useTemplateLoading = false;

                return;
            case ProjectsActionTypes.SET_PROJECT_UPDATEDS_HISTORY:
                draft.projectUpdatesHistory = action.payload;

                return;
            /// ////////////////////
            case ProjectsActionTypes.SET_PROJECT_MEDIA_SOURCES:
                draft.projectMediaSources = action.payload;
                return;
            /// ////////////////////
            case ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES:
                draft.projectTempMediaSources = action.payload;
                return;
            /// ////////////////////
            case ProjectsActionTypes.CREATE_PROJECT_START:
                draft.isProjectCreationLoading = true;

                return;
            case ProjectsActionTypes.CREATE_PROJECT_FAIL:
                draft.isProjectCreationLoading = false;

                return;
            case ProjectsActionTypes.CREATE_PROJECT_SUCCESS:
                draft.isProjectCreationLoading = false;

                return;
            /// ////////////////////
            case ProjectsActionTypes.SET_PROJECTS_IDS_FOR_DELETE:
                draft.projectsIdsForDelete = action.payload;

                return;
            /// generation
            case ProjectsActionTypes.GENERATE_PROJECT_START:
                draft.isProjectGenerationLoading = true;

                return;
            case ProjectsActionTypes.GENERATE_PROJECT_FAIL:
                draft.isProjectGenerationLoading = false;

                return;
            case ProjectsActionTypes.GENERATE_PROJECT_SUCCESS:
                draft.isProjectGenerationLoading = false;

                return;
            //  ////
            case ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES_PREVIEWS:
                draft.projectTempMediaSourcesPreviews = action.payload;

                return;
            //
            case ProjectsActionTypes.SET_PROJECT_AUDIO_MEDIA_SOURCES:
                draft.projectAudioMediaSources = action.payload;

                return;
            //
            case ProjectsActionTypes.SET_PROJECT_AUDIOS:
                draft.projectAudios = action.payload;

                return;
            /// clear

            case ProjectsActionTypes.SET_PROJECT_VIDEOS_TRANSITIONS:
                draft.transitions = action.payload;

                return;
            case ProjectsActionTypes.SET_PROJECT_VIDEOS_FILTERS:
                draft.filters = action.payload;

                return;
            case ProjectsActionTypes.SET_PROJECTS_SORTING:
                draft.projectsSort = action.payload;

                return;
            case ProjectsActionTypes.SET_ELEMENTS_LIST:
                draft.elementsList = action.payload;

                return;
            /// ///
            case ProjectsActionTypes.SET_VIEW_MODE:
                draft.viewMode = action.payload;

                return;
            /// ///
            case ProjectsActionTypes.SET_PROJECT_LAST_GENERATION:
                draft.lastProjectGeneration = action.payload;

                return;
            /// ///
            case ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_START:
                draft.isTextToSpeechGenerationLoading = true;

                return;
            case ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_SUCCESS:
            case ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_FAIL:
                draft.isTextToSpeechGenerationLoading = false;

                return;
            case ProjectsActionTypes.SET_PROJECT_AUDIO_OPTIONS:
                draft.projectAudioLanguagesOptions = {
                    ...draft.projectAudioLanguagesOptions,
                    ...action.payload,
                };

                return;
            //     Uploads
            case ProjectsActionTypes.SET_PROJECT_UPLOADS:
                draft.projectUploads = action.payload;
                draft.getProjectUploadsSuccess = true;

                return;

            case ProjectsActionTypes.SET_MEDIAS_WITH_CLEAN_AUDIO_GENERATION:
                draft.mediasWithCleanAudioGeneration = action.payload;

                return;
            // Text Animations
            case ProjectsActionTypes.CHANGE_TEXT_ANIMATION:
                draft.isChangeTextAnimationLoading = true;

                return;
            // Templates
            case ProjectsActionTypes.SET_TEMPLATE_WORKSPACE_ID:
                draft.templateWorkspaceId = action.payload;

                return;
            case ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_START:
                draft.isSubmittedTemplatesLoading = true;

                return;
            case ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_FAIL:
                draft.isSubmittedTemplatesLoading = false;

                return;
            case ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_SUCCESS:
                draft.submittedTemplatesList = action.payload.templates;
                draft.submittedTemplatesTotal = action.payload.templatesTotal;
                draft.isSubmittedTemplatesLoading = false;

                return;
            case ProjectsActionTypes.SET_SUBMITTED_TEMPLATES:
                draft.submittedTemplatesList = action.payload;

                return;

            case ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_START:
                draft.isPublishedTemplatesLoading = true;

                return;
            case ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_FAIL:
                draft.isPublishedTemplatesLoading = false;

                return;
            case ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_SUCCESS:
                draft.publishedTemplatesList = action.payload.templates;
                draft.publishedTemplatesTotal = action.payload.templatesTotal;
                draft.isPublishedTemplatesLoading = false;

                return;
            case ProjectsActionTypes.SET_PUBLISHED_TEMPLATES:
                draft.publishedTemplatesList = action.payload;

                return;
            case ProjectsActionTypes.GET_USABLE_TEMPLATES_START:
                draft.isUsableTemplatesLoading = true;

                return;
            case ProjectsActionTypes.GET_USABLE_TEMPLATES_FAIL:
                draft.isUsableTemplatesLoading = false;

                return;
            case ProjectsActionTypes.GET_USABLE_TEMPLATES_SUCCESS:
                draft.usableTemplatesList = action.payload.templates;
                draft.usableTemplatesTotal = action.payload.templatesTotal;
                draft.isUsableTemplatesLoading = false;

                return;
            case ProjectsActionTypes.SET_USABLE_TEMPLATES:
                draft.usableTemplatesList = action.payload.templates;

                return;
            case ProjectsActionTypes.USE_TEMPLATE:
                draft.useTemplateLoading = true;

                return;
            case ProjectsActionTypes.SET_PROJECT_AUDIOS_IDS_WITH_DUBBING_GENERATION:
                draft.projectAudiosIdsWithDubbingGeneration = action.payload;

                return;
            /// clear
            case ProjectsActionTypes.CLEAR_PROJECT_DETAILS:
                draft.projectDetails = null;
                draft.lastProjectGeneration = null;
                draft.isProjectDetailsLoading = true;
                draft.projectMediaSources = [];
                draft.projectTempMediaSources = [];
                draft.projectAudios = [];
                draft.projectVideos = [];
                draft.projectTempMediaSourcesPreviews = [];
                draft.projectUpdatesHistory = [];
                draft.projectAudioMediaSources =
                    initialState.projectAudioMediaSources;
                draft.transitions = initialState.transitions;
                draft.filters = initialState.filters;
                draft.projectAudioLanguagesOptions = {};
                draft.getProjectLatestVersionLoading = false;
                draft.getProjectUploadsSuccess = false;

                draft.projectUploads = [];

                draft.mediasWithCleanAudioGeneration = [];

                draft.isChangeTextAnimationLoading = false;

                draft.isUsableTemplatesLoading = false;
                draft.useTemplateLoading = false;
                draft.projectAudiosIdsWithDubbingGeneration = [];

                return;
            default:
                return state;
        }
    });
