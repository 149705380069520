import { IconProps } from '../index';

const SkipBackIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4966 5.25312C19.4619 4.38436 21 5.0694 21 6.36806V17.632C21 18.9306 19.4619 19.6157 18.4966 18.7469L12.2389 13.115C11.5768 12.5191 11.5768 11.4809 12.2389 10.8851L18.4966 5.25312ZM8.63984 6.28496C9.634 5.58904 11 6.30027 11 7.51381V15.7518C11 16.9654 9.634 17.6766 8.63984 16.9807L2.75553 12.8617C1.90245 12.2645 1.90245 11.0011 2.75553 10.404L8.63984 6.28496Z"
                fill={color}
            />
        </svg>
    );
};

export default SkipBackIconNew;


