export enum ModalEvent {
    INVALID_HASH = 'INVALID_HASH',
    SIGN_UP = 'SIGN_UP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    UPLOAD = 'UPLOAD',
    UPLOAD_FACES = 'UPLOAD_FACES',
    GENERATE_VIDEO = 'GENERATE_VIDEO',
    AVID_EXPORT = 'AVID_EXPORT',
    EDL_EXPORT = 'EDL_EXPORT',
    THUMBNAILS_EXPORT = 'THUMBNAILS_EXPORT',
    ADD_COLLECTION = 'ADD_COLLECTION',
    REANALISYS = 'REANALISYS',
    REANALYSIS_SPECIFIC_VIDEO = 'REANALYSIS_SPECIFIC_VIDEO',
    DELETE_COLLECTIONS_ITEMS = 'DELETE_COLLECTIONS_ITEMS',
    DELETE_COLLECTION = 'DELETE_COLLECTION',
    DELETE_VIDEOS = 'DELETE_VIDEOS',
    DELETE_SPECIFIC_VIDEO = 'DELETE_SPECIFIC_VIDEO',
    EDIT_VIDEO = 'EDIT_VIDEO',
    CREATE_TEMPLATE = 'CREATE_TEMPLATE',
    DELETE_TEMPLATE = 'DELETE_TEMPLATE',
    DELETE_TEMPLATES = 'DELETE_TEMPLATES',

    ANALYSIS_PRICE_OVER_LIMIT = 'ANALYSIS_PRICE_OVER_LIMIT',
    NOT_ENOUGH_STORAGE = 'NOT_ENOUGH_STORAGE',
    UPLOAD_VIDEO_SIZE_OVER_LIMIT = 'UPLOAD_VIDEO_SIZE_OVER_LIMIT',
    UPLOAD_VIDEO_LENGTH_OVER_LIMIT = 'UPLOAD_VIDEO_LENGTH_OVER_LIMIT',
    PROJECT_EXPORT_LENGTH_OVER_LIMIT = 'PROJECT_EXPORT_VIDEO_LENGTH_OVER_LIMIT',
    REMOVE_WATERMARK = 'REMOVE_WATERMARK',

    UPLOAD_LENGTH_OVER_LIMIT = 'UPLOAD_LENGTH_OVER_LIMIT',
    UPGRADE_PLAN = 'UPGRADE_PLAN',
    PAYMENT_CARD_SELECT = 'PAYMENT_CARD_SELECT',
    ADD_MORE_CREDITS = 'ADD_MORE_CREDITS',
    ADD_MORE_DUBBING_CREDITS = 'ADD_MORE_DUBBING_CREDITS',
    MANAGE_STORAGE = 'MANAGE_STORAGE',
    CANCEL_PLAN = 'CANCEL_PLAN',
    PLAN_CANCELED = 'PLAN_CANCELED',
    ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
    ADD_CREDIT_CARD_TO_BY_ADDON = 'ADD_CREDIT_CARD_TO_BY_ADDON',
    ADD_CREDIT_CARD_FOR_SUBSCRIPTION_ACTIVATION = 'ADD_CREDIT_CARD_FOR_SUBSCRIPTION_ACTIVATION',
    SELECT_PLAN = 'SELECT_PLAN',
    SUBSCRIPTION_ALL_SET = 'SUBSCRIPTION_ALL_SET',
    DELETE_LAST_CUSTOMER_CARD = 'DELETE_LAST_CUSTOMER_CARD',
    AUTO_RENEWAL_CONFIRM = 'AUTO_RENEWAL_CONFIRM',
    APP_INTRODUCTION_TOUR_PROPOSAL = 'APP_INTRODUCTION_TOUR_PROPOSAL',
    CLIP_PREVIEWS = 'CLIP_PREVIEWS',
    EXPORT_VIDEO_SUBTITLES = 'EXPORT_VIDEO_SUBTITLES',
    EXPORT_METADATA_AS_VIDEO = 'EXPORT_METADATA_AS_VIDEO',
    CREATE_PROJECT = 'CREATE_PROJECT',
    EDIT_PROJECT = 'EDIT_PROJECT',
    DELETE_PROJECT = 'DELETE_PROJECT',

    PROJECT_VIDEOS_UPLOADING_STATUS = 'PROJECT_VIDEOS_UPLOADING_STATUS',

    UPLOAD_FROM_EXTERNAL_SOURCE_STATUS = 'UPLOAD_FROM_EXTERNAL_SOURCE_STATUS',

    ADD_PROJECT_VIDEO = 'ADD_PROJECT_VIDEO',

    PROJECT_UPLOAD = 'PROJECT_UPLOAD',
    PROJECT_IMAGE_UPLOAD = 'PROJECT_IMAGE_UPLOAD',
    PROJECT_AUDIO_UPLOAD = 'PROJECT_AUDIO_UPLOAD',
    WELCOME_INTRO_SMART_SEARCH = 'WELCOME_INTRO_SMART_SEARCH',
    WELCOME_INTRO_VIDEO_EDITING = 'WELCOME_INTRO_VIDEO_EDITING',
    KEYBOARD_SHORTCUTS = 'KEYBOARD_SHORTCUTS',

    ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',

    RENAME_PROJECT = 'RENAME_PROJECT',

    INVITE = 'INVITE',

    CREATE_FOLDER = 'CREATE_FOLDER',
    RENAME_FOLDER = 'RENAME_FOLDER',
    MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',

    EXPORT_SUBTITLES = 'EXPORT_SUBTITLES',
    NOT_ENOUGH_CREDITS_FOR_TRANSLATE = 'NOT_ENOUGH_CREDITS_FOR_TRANSLATE',
    NOT_ENOUGH_CREDITS_FOR_SUBTITLING = 'NOT_ENOUGH_CREDITS_FOR_SUBTITLING',
    NOT_ENOUGH_CREDITS_FOR_DUBBING = 'NOT_ENOUGH_CREDITS_FOR_DUBBING',

    STOCK_VIDEO = 'STOCK_VIDEO',

    STOCK_IMAGES = 'STOCK_IMAGES',
    STOCK_VIDEOS = 'STOCK_VIDEOS',
    BUY_MORE_ASSETS = 'BUY_MORE_ASSETS',

    STOCK_MEDIA_ASSETS_OVER_LIMIT = 'STOCK_MEDIA_ASSETS_OVER_LIMIT',

    FREE_PLAN_NEW_WORKSPACE_PREVENT = 'FREE_PLAN_NEW_WORKSPACE_PREVENT',

    CRATE_PROJECT_WITH_STOCK_VIDEO = 'CRATE_PROJECT_WITH_STOCK_VIDEO',

    INVITE_COLLABORATOR = 'INVITE_COLLABORATOR',

    WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',
    CREATE_WORKSPACE = 'CREATE_WORKSPACE',

    INVOICES = 'INVOICES',

    MOVE_PROJECT_TO_WORKSPACE = 'MOVE_PROJECT_TO_WORKSPACE',

    TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW',
}

export enum ModalType {
    MESSAGE = 'MESSAGE',
    UPLOAD = 'UPLOAD',
    UPLOAD_FACES = 'UPLOAD_FACES',
    GENERATOR = 'GENERATOR',
    AVID_EXPORT = 'AVID_EXPORT',
    THUMBNAILS_EXPORT = 'THUMBNAILS_EXPORT',
    ADD_COLLECTION = 'ADD_COLLECTION',
    REANALISYS = 'REANALISYS',
    EDL_EXPORT = 'EDL_EXPORT',
    CONFIRM_DELETE = 'CONFIRM_DELETE',
    EDIT_VIDEO = 'EDIT_VIDEO',
    CREATE_TEMPLATE = 'CREATE_TEMPLATE',
    UPGRADE_PLAN = 'UPGRADE_PLAN',
    PAYMENT_CARD_SELECT = 'PAYMENT_CARD_SELECT',
    ADD_MORE_CREDITS = 'ADD_MORE_CREDITS',
    ADD_MORE_DUBBING_CREDITS = 'ADD_MORE_DUBBING_CREDITS',
    MANAGE_STORAGE = 'MANAGE_STORAGE',
    CANCEL_PLAN = 'CANCEL_PLAN',
    PLAN_CANCELED = 'PLAN_CANCELED',
    ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
    SELECT_PLAN = 'SELECT_PLAN',
    SUBSCRIPTION_ALL_SET = 'SUBSCRIPTION_ALL_SET',
    DELETE_LAST_CUSTOMER_CARD = 'DELETE_LAST_CUSTOMER_CARD',
    AUTO_RENEWAL_CONFIRM = 'AUTO_RENEWAL_CONFIRM',
    APP_INTRODUCTION_TOUR_PROPOSAL = 'APP_INTRODUCTION_TOUR_PROPOSAL',
    CLIP_PREVIEWS = 'CLIP_PREVIEWS',
    EXPORT_VIDEO_SUBTITLES = 'EXPORT_VIDEO_SUBTITLES',
    EXPORT_METADATA_AS_VIDEO = 'EXPORT_METADATA_AS_VIDEO',
    CREATE_PROJECT = 'CREATE_PROJECT',
    EDIT_PROJECT = 'EDIT_PROJECT',
    PROJECT_VIDEOS_UPLOADING_STATUS = 'PROJECT_VIDEOS_UPLOADING_STATUS',
    PROJECT_UPLOAD = 'PROJECT_UPLOAD',
    PROJECT_IMAGE_UPLOAD = 'PROJECT_IMAGE_UPLOAD',
    PROJECT_AUDIO_UPLOAD = 'PROJECT_AUDIO_UPLOAD',
    ADD_PROJECT_VIDEO = 'ADD_PROJECT_VIDEO',
    UPLOAD_FROM_EXTERNAL_SOURCE_STATUS = 'UPLOAD_FROM_EXTERNAL_SOURCE_STATUS',
    WELCOME = 'WELCOME',
    KEYBOARD_SHORTCUTS = 'KEYBOARD_SHORTCUTS',

    ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
    RENAME_PROJECT = 'RENAME_PROJECT',

    INVITE = 'INVITE',

    INVITE_COLLABORATOR = 'INVITE_COLLABORATOR',

    CREATE_FOLDER = 'CREATE_FOLDER',
    RENAME_FOLDER = 'RENAME_FOLDER',
    MOVE_TO_FOLDER = 'MOVE_TO_FOLDER',

    STOCK_IMAGES = 'STOCK_IMAGES',
    STOCK_VIDEO = 'STOCK_VIDEO',
    STOCK_VIDEOS = 'STOCK_VIDEOS',

    WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',
    CREATE_WORKSPACE = 'CREATE_WORKSPACE',

    INVOICES = 'INVOICES',

    MOVE_PROJECT_TO_WORKSPACE = 'MOVE_PROJECT_TO_WORKSPACE',

    TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW',
}

export enum ModalUploadType {
    DEFAULT = 'default',
    STREAMABLE_LINK = 'streamable_link',
}

export interface ModalRoute {
    modalType: ModalType;
    event: ModalEvent;
}

export interface ModalState {
    modalType: ModalType | null;
    event: ModalEvent | null;
    isModalVisible: boolean;
    optionalText?: string;
    optionalData?: any;
    backRoute?: ModalRoute | null;
}

export enum ModalActionTypes {
    SHOW_MODAL = '@@@modal/SHOW_MODAL',
    HIDE_MODAL = '@@@modal/HIDE_MODAL',
    SET_MODAL_VISIBILITY = '@@@modal/SET_MODAL_VISIBILITY',
    SET_MODAL_TYPE = '@@@modal/SET_MODAL_TYPE',
    SET_MODAL_EVENT = '@@@modal/SET_MODAL_EVENT',
    SET_MODAL_DATA = '@@@modal/SET_MODAL_DATA',
    SET_MODAL_TEXT = '@@@modal/SET_MODAL_TEXT',
    SET_BACK_ROUTE = '@@@modal/SET_BACK_ROUTE',
}

export interface ShowModalActionPayload {
    modalType: ModalType;
    event: ModalEvent;
    optionalText?: string;
    optionalData?: any;
}
export interface ShowModalAction {
    type: ModalActionTypes.SHOW_MODAL;
    payload: ShowModalActionPayload;
}
export interface HideModalAction {
    type: ModalActionTypes.HIDE_MODAL;
}
export interface SetModalVisibleAction {
    type: ModalActionTypes.SET_MODAL_VISIBILITY;
    payload: boolean;
}
export interface SetModalTypeAction {
    type: ModalActionTypes.SET_MODAL_TYPE;
    payload: ModalType | null;
}
export interface SetModalEventAction {
    type: ModalActionTypes.SET_MODAL_EVENT;
    payload: ModalEvent | null;
}
export interface SetModalTextAction {
    type: ModalActionTypes.SET_MODAL_TEXT;
    payload: string;
}
export interface SetModalDataAction {
    type: ModalActionTypes.SET_MODAL_DATA;
    payload: any;
}

export interface SetBackRouteAction {
    type: ModalActionTypes.SET_BACK_ROUTE;
    payload: ModalRoute | null;
}

export type ModalAction =
    | ShowModalAction
    | HideModalAction
    | SetModalVisibleAction
    | SetModalTypeAction
    | SetModalEventAction
    | SetModalTextAction
    | SetModalDataAction
    | SetBackRouteAction;


export enum BuyMoreType {
    subtitles = 'subtitles',
    dubbing = 'dubbing',
    storage = 'storage',
}