import { IconProps } from './index';

const FlashIcon = ({ size, color }: IconProps) => {
    let height = 16;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bgqQcB isQgrw"
        >
            <path
                d="M13.882 10.8h5.176c.727 0 1.18.671.811 1.205l-7.998 11.598c-.485.704-1.753.411-1.753-.404v-10H4.942c-.727 0-1.18-.67-.811-1.204L12.129.397c.485-.704 1.753-.411 1.753.404v10Z"
                fill={color}
            />
        </svg>
    );
};

export default FlashIcon;
