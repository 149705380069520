import {
    StockMediaCollection,
    StockMedia,
    StockSong,
    StockPlaylist,
    StockSongTag,
    StockMediaCategory,
} from 'interfaces/stockMedia';
import {
    SetStockImagesAction,
    GetStockImagesAction,
    GetStockImagesFailAction,
    GetStockImagesStartAction,
    StockMediaActionTypes,
    SetStockImagesActionPayload,
    GetStockImagesActionPayload,
    SetStockImagesSearchQueryAction,
    GetStockMediaCollectionsAction,
    SetStockMediaCollectionsAction,
    SetStockVideosActionPayload,
    GetStockVideosFailAction,
    GetStockVideosStartAction,
    GetStockVideosActionPayload,
    GetStockVideosAction,
    SetStockVideosAction,
    SetStockVideosSearchQueryAction,
    SetStockImageSelectedCollectionAction,
    SetStockVideoSelectedCollectionAction,
    SetStockVideosPreviewsAction,
    SetStockImagesPreviewsAction,
    SetStockImagesListPageAction,
    SetStockVideosListPageAction,
    GetStockAudioSongsAction,
    SetStockAudioSongsAction,
    GetStockAudioPlaylistsAction,
    SetStockAudioPlaylistsAction,
    SetStockAudioPlaylistAction,
    GetStockAudioTagsAction,
    SetStockAudioTagsAction,
    SetStockAudioSelectedTagAction,
    SetStockVideosThumbnailPreviewsAction,
    GetStockVideosThumbnailPreviewsAction,
    GetStockAudioEffectsAction,
    SetStockAudioEffectsAction,
    GetStockMediaCategoriesAction,
    SetStockMediaCategoriesAction,
    SetStockMediaSelectedCategoryAction,
    SetActiveStockAudioAction,
    SetCategorizedStockVideosAction,
    GetCategorizedStockVideosAction,
    SetCategorizedStockVideosActionPayload,
    GetCategorizedStockImagesAction,
    SetCategorizedStockImagesActionPayload,
    SetCategorizedStockImagesAction,
    GetCategorizedStockAudioSongsAction,
    SetCategorizedStockAudioSongsActionPayload,
    SetCategorizedStockAudioSongsAction,
    SetCategorizedStockVideosPreviewsAction,
    SetStockAudiosSearchQueryAction,
    GetCategorizedStockVideosActionPayload,
} from './types';

// images

export function getStockImages(
    payload?: GetStockImagesActionPayload
): GetStockImagesAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_IMAGES,
        payload,
    };
}

export function getStockImagesStart(): GetStockImagesStartAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_IMAGES_START,
    };
}

export function getStockImagesFail(payload: any): GetStockImagesFailAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_IMAGES_FAIL,
        payload,
    };
}

export function setStockImages(
    payload: SetStockImagesActionPayload
): SetStockImagesAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_IMAGES,
        payload,
    };
}

export function setStockImagesPreviews(
    payload: StockMedia[]
): SetStockImagesPreviewsAction {
    return {
        type: StockMediaActionTypes.SET_IMAGES_PREVIEWS,
        payload,
    };
}

export function setStockImagesSearchQuery(
    payload: string
): SetStockImagesSearchQueryAction {
    return {
        type: StockMediaActionTypes.SET_IMAGES_SEARCH_QUERY,
        payload,
    };
}

export function setStockImagesListPage(
    payload: number
): SetStockImagesListPageAction {
    return {
        type: StockMediaActionTypes.SET_IMAGES_LIST_PAGE,
        payload,
    };
}

export function getCategorizedStockImages(): GetCategorizedStockImagesAction {
    return {
        type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_IMAGES,
    };
}

export function setCategorizedStockImages(
    payload: SetCategorizedStockImagesActionPayload
): SetCategorizedStockImagesAction {
    return {
        type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_IMAGES,
        payload,
    };
}

// videos

export function getStockVideos(
    payload?: GetStockVideosActionPayload
): GetStockVideosAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_VIDEOS,
        payload,
    };
}

export function getStockVideosStart(): GetStockVideosStartAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_VIDEOS_START,
    };
}

export function getStockVideosFail(payload: any): GetStockVideosFailAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_VIDEOS_FAIL,
        payload,
    };
}

export function setStockVideos(
    payload: SetStockVideosActionPayload
): SetStockVideosAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_VIDEOS,
        payload,
    };
}

export function getCategorizedStockVideos(
    payload?: GetCategorizedStockVideosActionPayload
): GetCategorizedStockVideosAction {
    return {
        type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_VIDEOS,
        payload,
    };
}

export function setCategorizedStockVideos(
    payload: SetCategorizedStockVideosActionPayload
): SetCategorizedStockVideosAction {
    return {
        type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_VIDEOS,
        payload,
    };
}

export function setCategorizedStockVideosPreviews(
    payload: SetCategorizedStockVideosActionPayload
): SetCategorizedStockVideosPreviewsAction {
    return {
        type: StockMediaActionTypes.SET_CATEGORIZED_VIDEOS_PREVIEWS,
        payload,
    };
}

export function setStockVideosPreviews(
    payload: StockMedia[]
): SetStockVideosPreviewsAction {
    return {
        type: StockMediaActionTypes.SET_VIDEOS_PREVIEWS,
        payload,
    };
}

export function setStockVideosSearchQuery(
    payload: string
): SetStockVideosSearchQueryAction {
    return {
        type: StockMediaActionTypes.SET_VIDEOS_SEARCH_QUERY,
        payload,
    };
}

export function setStockVideosListPage(
    payload: number
): SetStockVideosListPageAction {
    return {
        type: StockMediaActionTypes.SET_VIDEOS_LIST_PAGE,
        payload,
    };
}

export function getStockVideosThumbnailPreviews(): GetStockVideosThumbnailPreviewsAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_VIDEOS_PREVIEWS,
    };
}

export function setStockVideosThumbnailPreviews(
    payload: any[]
): SetStockVideosThumbnailPreviewsAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_VIDEOS_PREVIEWS,
        payload,
    };
}

// collections

export function getStockMediaCollections(): GetStockMediaCollectionsAction {
    return {
        type: StockMediaActionTypes.GET_COLLECTIONS,
    };
}

export function setStockMediaCollections(
    payload: any[]
): SetStockMediaCollectionsAction {
    return {
        type: StockMediaActionTypes.SET_COLLECTIONS,
        payload,
    };
}

export function setStockImageSelectedCollection(
    payload: StockMediaCollection | null
): SetStockImageSelectedCollectionAction {
    return {
        type: StockMediaActionTypes.SET_SELECTED_IMAGE_COLLECTION,
        payload,
    };
}

export function setStockVideoSelectedCollection(
    payload: StockMediaCollection | null
): SetStockVideoSelectedCollectionAction {
    return {
        type: StockMediaActionTypes.SET_SELECTED_VIDEO_COLLECTION,
        payload,
    };
}

// audio

export function getStockAudioSongs(): GetStockAudioSongsAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_AUDIO_SONGS,
    };
}

export function setStockAudioSongs(
    payload: StockSong[]
): SetStockAudioSongsAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_AUDIO_SONGS,
        payload,
    };
}

export function getCategorizedStockAudioSongs(): GetCategorizedStockAudioSongsAction {
    return {
        type: StockMediaActionTypes.GET_CATEGORIZED_STOCK_AUDIO_SONGS,
    };
}

export function setCategorizedStockAudioSongs(
    payload: SetCategorizedStockAudioSongsActionPayload
): SetCategorizedStockAudioSongsAction {
    return {
        type: StockMediaActionTypes.SET_CATEGORIZED_STOCK_AUDIO_SONGS,
        payload,
    };
}

export function setStockAudiosSearchQuery(
    payload: string
): SetStockAudiosSearchQueryAction {
    return {
        type: StockMediaActionTypes.SET_AUDIOS_SEARCH_QUERY,
        payload,
    };
}

export function getStockAudioPlaylists(): GetStockAudioPlaylistsAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_AUDIO_PLAYLISTS,
    };
}

export function setStockAudioPlaylists(
    payload: StockPlaylist[]
): SetStockAudioPlaylistsAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLISTS,
        payload,
    };
}

export function setStockAudioPlaylist(
    payload: StockPlaylist | null
): SetStockAudioPlaylistAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_AUDIO_PLAYLIST,
        payload,
    };
}

// Tags

export function getStockAudioTags(): GetStockAudioTagsAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_AUDIO_TAGS,
    };
}

export function setStockAudioTags(
    payload: StockSongTag[]
): SetStockAudioTagsAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_AUDIO_TAGS,
        payload,
    };
}

export function setStockAudioSelectedTag(
    payload: StockSongTag | null
): SetStockAudioSelectedTagAction {
    return {
        type: StockMediaActionTypes.SET_SELECTED_STOCK_AUDIO_TAG,
        payload,
    };
}

// audio effects

export function getStockAudioEffects(): GetStockAudioEffectsAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_AUDIO_EFFECTS,
    };
}

export function setStockAudioEffects(
    payload: any[]
): SetStockAudioEffectsAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_AUDIO_EFFECTS,
        payload,
    };
}

// Categoreis

export function getStockMediaCategories(): GetStockMediaCategoriesAction {
    return {
        type: StockMediaActionTypes.GET_STOCK_MEDIA_CATEGORIES,
    };
}

export function setStockMediaCategories(
    payload: StockMediaCategory[]
): SetStockMediaCategoriesAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_MEDIA_CATEGORIES,
        payload,
    };
}

export function setStockMediaSelectedCategory(
    payload: StockMediaCategory | null
): SetStockMediaSelectedCategoryAction {
    return {
        type: StockMediaActionTypes.SET_STOCK_MEDIA_SELECTED_CATEGORY,
        payload,
    };
}

//

export function setActiveStockAudio(
    payload: string | null
): SetActiveStockAudioAction {
    return {
        type: StockMediaActionTypes.SET_ACTIVE_STOCK_AUDIO,
        payload,
    };
}
