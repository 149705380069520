import { TransformedSentence } from 'state/modules/metadata';
import { NotificationTypes } from 'utils/notifications';
import {
    ProjectVideo,
    TextLinesValue,
    SubtitleEffect,
    SubtitlesAlign,
    SubtitlesTextStyle,
} from './projects';

export interface FontFamilyItem {
    label: string;
    value: string;
    fontStyle?: SubtitlesTextStyle;
    fontWeight?: number;
    isChangeFontWeightDisabled?: boolean;
    isChangeTextStyleDisabled?: boolean;
}

export interface TextLinesItem {
    label: string;
    value: TextLinesValue;
}

export interface SubtitlesAlignItem {
    value: SubtitlesAlign;
    icon: string;
}

export interface SubtitlesTextStyleItem {
    type: SubtitlesTextStyle;
    icon: string;
}

export interface SubtitlesEffectItem {
    value: SubtitleEffect;
    text: React.ReactNode;
    textStyle?: React.CSSProperties;
    color?: string;
    backgroundColor: string;
    textBgColor: string;
    label: string;
    outline: number;
    outlineColor: string;
    shadow: number;
    shadowColor: string;
    preview?: string;
    // opacity?: number;
}

export interface SubtitlesSettings {
    subtitlesColor: string;
    subtitlesFontSize: number;
    align: SubtitlesAlign;
    subtitlesTextStyle?: SubtitlesTextStyle | null;
    fontFamily: string;
    width?: number;
    translationX?: number;
    translationY?: number;
    angle?: number;
    textLines?: TextLinesValue;
    fontWeight: number;
}

export enum SubtitleValidationProblemType {
    OUTSIDE_VIDEO = 'OUTSIDE_VIDEO',
    FLIPPED_SUB = 'FLIPPED_SUB',
    OVERLAP = 'OVERLAP',
}

export interface SubtitleValidationMessageProps {
    isOutsideOfVideo: boolean;
    startTime: number;
    endTime: number;
    subtitles: TransformedSentence[];
    subtitleId: string;
    frameRate: number;
}

export interface SubtitleValidationMessage {
    type: NotificationTypes;
    message: string;
    problem: SubtitleValidationProblemType;
}

export interface OverlappingSubtitlesNewPositionsProps {
    tempStartTime: number;
    tempEndTime: number;
    subtitles: TransformedSentence[];
    data: TransformedSentence;
    frameRate: number;
}

export interface OverlappingSubtitlesNewPositions {
    newStartTime: number;
    newEndTime: number;
}
