import { IconProps } from './index';
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 35 / 36;

const TemplateIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 35 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.875 0.5H13.125C8.30703 0.5 5.12422 0.5 2.56484 3.06484C2.6077e-07 5.62969 0 8.8125 0 13.625V22.375C0 27.193 2.6077e-07 30.3758 2.56484 32.9352C5.12969 35.5 8.3125 35.5 13.125 35.5H21.5305C23.5922 35.5 25.0852 35.5 26.693 34.8328C28.3008 34.1656 29.3562 33.1156 30.8109 31.6555L31.1555 31.3109C32.6156 29.8508 33.6656 28.8008 34.3328 27.193C35 25.5852 35 24.0977 35 22.0305V13.625C35 8.80703 35 5.62422 32.4352 3.06484C29.8703 0.5 26.6875 0.5 21.875 0.5ZM12.5781 31.125C8.575 31.1195 6.83594 31.0211 5.65469 29.8453C4.42422 28.6148 4.375 26.7773 4.375 22.375V15.8125H12.5781V31.125ZM4.38594 11.4375C4.42969 8.56641 4.64844 7.16094 5.65469 6.15469C6.88516 4.92422 8.72266 4.875 13.125 4.875H21.875C26.2773 4.875 28.1094 4.92422 29.3453 6.15469C30.3516 7.16094 30.5703 8.56641 30.6141 11.4375H4.38594ZM30.625 22.0305C30.625 23.8898 30.6141 24.743 30.2914 25.5141C29.9742 26.2852 29.3781 26.8977 28.0602 28.2156L27.7156 28.5602C26.4031 29.8727 25.7852 30.4688 25.0141 30.7914C24.243 31.1086 23.3898 31.125 21.5305 31.125H16.9531V15.8125H30.625V22.0305Z"
                stroke={color}
                // fill={color}
            />
        </svg>
    );
};

export default TemplateIcon;