import { IconProps } from './index';

const AudioSoundNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.682 8.08c1.584 2.514 1.808 5.86.301 8.822m-3.637-7.178c1.337 1.563 1.526 3.642.254 5.482M12 6 7.588 9.488H3v5.023l4.588-.001L12 18V6z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AudioSoundNew;
