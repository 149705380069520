import { IconProps } from './index';

const SuccessCheckIcon = ({
  size,
  color,
  secondColor,
}: IconProps): JSX.Element => {
  let height = 18;
  if (size) {
    height = size;
  }

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={color || '#5FB950'} />
      <path
        d="M14 6L8.46154 12L5 9.33333"
        stroke={secondColor || '#ffffff'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessCheckIcon;
