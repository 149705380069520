import { VideoUpload } from 'interfaces/upload';
import {
  UploadingFace,
  UploadingStatus,
  UploadingMedia,
  UploadingVideoDataForUpdate,
  UploadingMediaDurationItem,
} from 'interfaces/uploading';

import {
    UploadingActionTypes,
    SetUploadingFacesAction,
    SetUploadingVideosAction,
    SetCollectionIdAction,
    SetUploadingStatusesAction,
    UpdateUploadingStatusAction,
    ConfirmUploadingAction,
    ChangeUploadingVideoInfoAction, SetUploadingMediaWithAnalyzeCategoriesAction,
} from 'state/modules/uploading';
import { 
  ChangeUploadingMediaDurationAction, 
  ConfirmUploadingActionPayload, 
  setUploadingMediaDurationItemsItemsAction, 
  UploadVideoFromExternalSourceAction,
  UploadVideoFromExternalSourceActionPayload,
  UploadVideoFromExternalSourceFailAction,
  UploadVideoFromExternalSourceStartAction,
  UploadVideoFromExternalSourceSuccessAction
} from './types';

export function setUploadingFaces(
  faces: Array<UploadingFace>,
): SetUploadingFacesAction {
  return {
    type: UploadingActionTypes.SET_UPLOADING_FACES,
    payload: faces,
  };
}

export function setUploadingVideos(
  videos: Array<UploadingMedia>,
): SetUploadingVideosAction {
  return {
    type: UploadingActionTypes.SET_UPLOADING_VIDEOS,
    payload: videos,
  };
}

export function setUploadingMediaWithAnalyzeCategories(
    videos: Array<UploadingMedia>,
): SetUploadingMediaWithAnalyzeCategoriesAction {
    return {
        type: UploadingActionTypes.SET_UPLOADING_MEDIA_WITH_ANALYZE_CATEGORIES,
        payload: videos,
    };
}

export function setCollectionId(id: string): SetCollectionIdAction {
  return {
    type: UploadingActionTypes.SET_COLLECTION_ID,
    payload: id,
  };
}

export function setUploadingStatuses(
  statuses: Array<UploadingStatus>,
): SetUploadingStatusesAction {
  return {
    type: UploadingActionTypes.SET_UPLOADING_STATUSES,
    payload: statuses,
  };
}

export function updateUploadingStatus(
  status: UploadingStatus,
): UpdateUploadingStatusAction {
  return {
    type: UploadingActionTypes.UPDATE_UPLOADING_STATUS,
    payload: status,
  };
}

export function confirmUploading(
  payload: ConfirmUploadingActionPayload,
): ConfirmUploadingAction {
  return {
    type: UploadingActionTypes.CONFIRM_UPLOADING,
    payload,
  };
}

export function changeUploadingMediaDuration(
  data: UploadingMediaDurationItem,
): ChangeUploadingMediaDurationAction {
  return {
    type: UploadingActionTypes.CHANGE_UPLOADING_MEDIA_DURATION,
    payload: data,
  };
}

export function setUploadingMediaDurationItems(
  data: Array<UploadingMediaDurationItem>,
): setUploadingMediaDurationItemsItemsAction {
  return {
    type: UploadingActionTypes.SET_UPLOADING_MEDIA_DURATION_ITEMS,
    payload: data,
  };
}

export function changeUploadingVideoInfo({
  videoId,
  value,
  field,
}: UploadingVideoDataForUpdate): ChangeUploadingVideoInfoAction {
  return {
    type: UploadingActionTypes.CHANGE_UPLOADING_VIDEO_INFO,
    payload: {
      value,
      videoId,
      field,
    },
  };
}

export function uploadProjectVideoFromExternalSource(
  payload: UploadVideoFromExternalSourceActionPayload
): UploadVideoFromExternalSourceAction {
  return {
    type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE,
    payload
  };
}

export function uploadProjectVideoFromExternalSourceStart(): UploadVideoFromExternalSourceStartAction {
  return {
    type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_START,
  };
}

export function uploadProjectVideoFromExternalSourceFail(): UploadVideoFromExternalSourceFailAction {
  return {
    type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_FAIL,
  };
}

export function uploadProjectVideoFromExternalSourceSuccess(): UploadVideoFromExternalSourceSuccessAction {
  return {
    type: UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_SUCCESS,
  };
}