import { IconProps } from './index';

const TimerIcon = ({ size, color, secondColor }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3582_43645)">
                <path
                    d="M11.8205 4.21473C11.6253 4.40999 11.6253 4.72657 11.8205 4.92183C12.0158 5.11709 12.3324 5.11709 12.5276 4.92183L11.8205 4.21473ZM13.0842 1.98864C12.8889 1.79338 12.5723 1.79338 12.377 1.98864C12.1818 2.1839 12.1818 2.50048 12.377 2.69575L13.0842 1.98864ZM14.0466 4.36531C14.2419 4.56057 14.5585 4.56057 14.7537 4.36531C14.949 4.17005 14.949 3.85347 14.7537 3.6582L14.0466 4.36531ZM8.50016 6.56077C8.50016 6.28463 8.27631 6.06077 8.00016 6.06077C7.72402 6.06077 7.50016 6.28463 7.50016 6.56077H8.50016ZM7.50016 8.81185C7.50016 9.08799 7.72402 9.31185 8.00016 9.31185C8.27631 9.31185 8.50016 9.08799 8.50016 8.81185H7.50016ZM6.3335 0.833496C6.05735 0.833496 5.8335 1.05735 5.8335 1.3335C5.8335 1.60964 6.05735 1.8335 6.3335 1.8335V0.833496ZM9.66683 1.8335C9.94297 1.8335 10.1668 1.60964 10.1668 1.3335C10.1668 1.05735 9.94297 0.833496 9.66683 0.833496V1.8335ZM12.5276 4.92183L13.9189 3.53053L13.2118 2.82342L11.8205 4.21473L12.5276 4.92183ZM12.377 2.69575L13.2118 3.53053L13.9189 2.82342L13.0842 1.98864L12.377 2.69575ZM13.2118 3.53053L14.0466 4.36531L14.7537 3.6582L13.9189 2.82342L13.2118 3.53053ZM7.50016 6.56077V8.81185H8.50016V6.56077H7.50016ZM6.3335 1.8335H9.66683V0.833496H6.3335V1.8335ZM13.1668 8.95254C13.1668 11.8363 10.8497 14.1668 8.00016 14.1668V15.1668C11.4099 15.1668 14.1668 12.3806 14.1668 8.95254H13.1668ZM8.00016 14.1668C5.15063 14.1668 2.8335 11.8363 2.8335 8.95254H1.8335C1.8335 12.3806 4.59047 15.1668 8.00016 15.1668V14.1668ZM2.8335 8.95254C2.8335 6.06882 5.15063 3.73826 8.00016 3.73826V2.73826C4.59047 2.73826 1.8335 5.52444 1.8335 8.95254H2.8335ZM8.00016 3.73826C10.8497 3.73826 13.1668 6.06882 13.1668 8.95254H14.1668C14.1668 5.52444 11.4099 2.73826 8.00016 2.73826V3.73826Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3582_43645">
                    <rect
                        width={height}
                        height={height}
                        fill={secondColor}
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TimerIcon;


