import produce from 'immer';

import { UploadingAction, UploadingActionTypes, UploadingState } from './types';

const initialState: UploadingState = {
  isLoading: false,
  uploadingVideos: [],
  uploadingMediaDurationItems: [],
  uploadingFaces: [],
  uploadingStatuses: [],
  videosDuration: [],
  isStatusBarActive: false,
  collectionId: '',
  isUploadFromExternalSourceInProgress: false,
  uploadingMediaWithAnalyzeCategories: [],
};

export default (
  state = initialState,
  action: UploadingAction,
): UploadingState => produce(state, (draft) => {
  switch (action.type) {
    case UploadingActionTypes.SET_UPLOADING_VIDEOS:
      draft.uploadingVideos = action.payload;

      return;
    case UploadingActionTypes.SET_UPLOADING_MEDIA_WITH_ANALYZE_CATEGORIES:
        draft.uploadingMediaWithAnalyzeCategories = action.payload;

        return;
    case UploadingActionTypes.SET_UPLOADING_FACES:
      draft.uploadingFaces = action.payload;

      return;
    case UploadingActionTypes.SET_COLLECTION_ID:
      draft.collectionId = action.payload;

      return;
    case UploadingActionTypes.SET_UPLOADING_STATUSES:
      draft.uploadingStatuses = action.payload;

      return;
    case UploadingActionTypes.SET_UPLOADING_MEDIA_DURATION_ITEMS:
      draft.uploadingMediaDurationItems = action.payload;

      return;
    case UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_START:
      draft.isUploadFromExternalSourceInProgress = true;

      return;
    case UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_FAIL:
      draft.isUploadFromExternalSourceInProgress = false;

      return;
    case UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE_SUCCESS:
      draft.isUploadFromExternalSourceInProgress = false;

      return;
    default:
      return state;
  }
});
