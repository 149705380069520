import {
  ClipTemplate,
  ClipTemplateTitle,
  TransformedGenTemplate,
} from 'interfaces/generation';

export const defaultTemplates: Array<TransformedGenTemplate> = [
  {
    value: ClipTemplate.CUSTOM,
    label: ClipTemplateTitle.CUSTOM,
    config: {
      minSceneLength: 2,
    },
  },
  {
    value: ClipTemplate.CUSTOM_TEN_SEC,
    label: ClipTemplateTitle.CUSTOM_TEN_SEC,
    config: {
      minSceneLength: 1,
      maxSceneLength: 2,
      minTotalLength: 10,
      maxTotalLength: 10,
    },
  },
  {
    value: ClipTemplate.CUSTOM_TWENTY_SEC,
    label: ClipTemplateTitle.CUSTOM_TWENTY_SEC,
    config: {
      minSceneLength: 1,
      maxSceneLength: 3,
      minTotalLength: 20,
      maxTotalLength: 20,
    },
  },
  {
    value: ClipTemplate.DR,
    label: ClipTemplateTitle.DR,
  },
  {
    value: ClipTemplate.HIGHLIGHT_EXTRACTION,
    label: ClipTemplateTitle.HIGHLIGHT_EXTRACTION,
  },
];
