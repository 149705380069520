import { IconProps } from './index';

const RedoIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0462 4.80001L21.6001 9.41551M21.6001 9.41551L17.0462 14.031M21.6001 9.41551L6.4001 9.4155C4.19096 9.4155 2.4001 11.2064 2.4001 13.4155L2.4001 17.2"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RedoIconNew;


