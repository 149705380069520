import { IconProps } from './index';

const GooglePhotosIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 19;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.4585 11.7914C24.374 11.5874 24.1752 11.4545 23.9545 11.4545H18.5V5.99999C18.5 5.85544 18.4424 5.71662 18.3402 5.61435L12.8856 0.1598C12.7296 0.00380021 12.4948 -0.0428362 12.2914 0.0414366C12.0874 0.125982 11.9545 0.3248 11.9545 0.545437V5.99999H6.49999C6.35544 5.99999 6.21662 6.05753 6.11435 6.1598L0.6598 11.6144C0.5038 11.7704 0.457164 12.0049 0.541437 12.2086C0.625982 12.4126 0.8248 12.5454 1.04544 12.5454H6.49999V18C6.49999 18.1445 6.55753 18.2834 6.6598 18.3856L12.1144 23.8402C12.2185 23.9446 12.3582 24 12.5 24C12.5701 24 12.6413 23.9864 12.7086 23.9585C12.9126 23.874 13.0454 23.6752 13.0454 23.4545V18H18.5C18.6445 18 18.7834 17.9424 18.8856 17.8402L24.3402 12.3856C24.4962 12.2296 24.5428 11.9951 24.4585 11.7914ZM13.0454 1.86244L17.4091 6.22608V11.4545H15.9014L13.9975 10.5024L13.0454 8.59853V1.86244ZM12.5 14.0528L11.897 12.8468C11.8441 12.7413 11.7584 12.6556 11.6529 12.6027L10.4472 12L11.6532 11.397C11.7587 11.3441 11.8444 11.2584 11.8973 11.1529L12.5 9.94717L13.103 11.1532C13.1556 11.2587 13.2415 11.3444 13.3471 11.3973L14.5528 12L13.3468 12.603C13.2413 12.6556 13.1556 12.7415 13.1027 12.8471L12.5 14.0528ZM6.72608 7.0909H11.9545V8.59853L11.0024 10.5024L9.09853 11.4545H2.36244L6.72608 7.0909ZM11.9545 22.1378L7.5909 17.7742V12.5454H9.09853L11.0024 13.4973L11.9545 15.4014V22.1378ZM18.2742 16.9091H13.0454V15.4014L13.9973 13.4975L15.9014 12.5454H22.6378L18.2742 16.9091Z"
                fill={color}
            />
        </svg>
    );
};

export default GooglePhotosIcon;