import { IconProps } from '../index';

const TextInFallIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.918 12.134c.93-.388 1.824-.38 2.68.023.857.403 1.433 1.048 1.727 1.935.306.883.256 1.752-.147 2.609a3.361 3.361 0 0 1-1.934 1.78c-.886.33-1.753.295-2.602-.105-.843-.396-1.397-1.106-1.662-2.129l-.428.907-.874-.412 3.815-8.097.874.412-1.45 3.077zm.098 5.363a2.265 2.265 0 0 0 1.875.053c.646-.25 1.111-.678 1.396-1.282.288-.612.33-1.243.127-1.893a2.255 2.255 0 0 0-1.245-1.418c-.627-.295-1.252-.33-1.877-.106-.618.228-1.079.666-1.384 1.314-.302.64-.346 1.284-.131 1.93a2.282 2.282 0 0 0 1.24 1.402z"
                fill="#D0D0D0" />
            <path
                d="M19.918 12.134c.93-.388 1.824-.38 2.68.023.857.403 1.433 1.048 1.727 1.935.306.883.256 1.752-.147 2.609a3.361 3.361 0 0 1-1.934 1.78c-.886.33-1.753.295-2.602-.105-.843-.396-1.397-1.106-1.662-2.129l-.428.907-.874-.412 3.815-8.097.874.412-1.45 3.077zm.098 5.363a2.265 2.265 0 0 0 1.875.053c.646-.25 1.111-.678 1.396-1.282.288-.612.33-1.243.127-1.893a2.255 2.255 0 0 0-1.245-1.418c-.627-.295-1.252-.33-1.877-.106-.618.228-1.079.666-1.384 1.314-.302.64-.346 1.284-.131 1.93a2.282 2.282 0 0 0 1.24 1.402z"
                fill="#D0D0D0" />
            <path
                d="M19.918 12.134c.93-.388 1.824-.38 2.68.023.857.403 1.433 1.048 1.727 1.935.306.883.256 1.752-.147 2.609a3.361 3.361 0 0 1-1.934 1.78c-.886.33-1.753.295-2.602-.105-.843-.396-1.397-1.106-1.662-2.129l-.428.907-.874-.412 3.815-8.097.874.412-1.45 3.077zm.098 5.363a2.265 2.265 0 0 0 1.875.053c.646-.25 1.111-.678 1.396-1.282.288-.612.33-1.243.127-1.893a2.255 2.255 0 0 0-1.245-1.418c-.627-.295-1.252-.33-1.877-.106-.618.228-1.079.666-1.384 1.314-.302.64-.346 1.284-.131 1.93a2.282 2.282 0 0 0 1.24 1.402z"
                fill="#D0D0D0" />
            <path
                d="M19.918 12.134c.93-.388 1.824-.38 2.68.023.857.403 1.433 1.048 1.727 1.935.306.883.256 1.752-.147 2.609a3.361 3.361 0 0 1-1.934 1.78c-.886.33-1.753.295-2.602-.105-.843-.396-1.397-1.106-1.662-2.129l-.428.907-.874-.412 3.815-8.097.874.412-1.45 3.077zm.098 5.363a2.265 2.265 0 0 0 1.875.053c.646-.25 1.111-.678 1.396-1.282.288-.612.33-1.243.127-1.893a2.255 2.255 0 0 0-1.245-1.418c-.627-.295-1.252-.33-1.877-.106-.618.228-1.079.666-1.384 1.314-.302.64-.346 1.284-.131 1.93a2.282 2.282 0 0 0 1.24 1.402z"
                fill="#F1F1F3" />
            <path
                d="M32.615 18.1a3.311 3.311 0 0 1-1.754.234 4.094 4.094 0 0 1-1.719-.638c-.843-.542-1.37-1.274-1.581-2.196-.205-.917-.049-1.777.468-2.58a3.334 3.334 0 0 1 2.177-1.508c.94-.21 1.86-.023 2.764.558.535.345.951.778 1.249 1.3a3.1 3.1 0 0 1 .43 1.669l-.916-.008c-.08-.95-.514-1.68-1.304-2.188-.622-.4-1.273-.535-1.953-.405-.676.123-1.203.48-1.582 1.069a2.479 2.479 0 0 0-.353 1.915c.144.689.533 1.238 1.169 1.647.763.49 1.612.583 2.547.276l.358.855z"
                fill="#D0D0D0" />
            <path
                d="M32.615 18.1a3.311 3.311 0 0 1-1.754.234 4.094 4.094 0 0 1-1.719-.638c-.843-.542-1.37-1.274-1.581-2.196-.205-.917-.049-1.777.468-2.58a3.334 3.334 0 0 1 2.177-1.508c.94-.21 1.86-.023 2.764.558.535.345.951.778 1.249 1.3a3.1 3.1 0 0 1 .43 1.669l-.916-.008c-.08-.95-.514-1.68-1.304-2.188-.622-.4-1.273-.535-1.953-.405-.676.123-1.203.48-1.582 1.069a2.479 2.479 0 0 0-.353 1.915c.144.689.533 1.238 1.169 1.647.763.49 1.612.583 2.547.276l.358.855z"
                fill="#D0D0D0" />
            <path
                d="M32.615 18.1a3.311 3.311 0 0 1-1.754.234 4.094 4.094 0 0 1-1.719-.638c-.843-.542-1.37-1.274-1.581-2.196-.205-.917-.049-1.777.468-2.58a3.334 3.334 0 0 1 2.177-1.508c.94-.21 1.86-.023 2.764.558.535.345.951.778 1.249 1.3a3.1 3.1 0 0 1 .43 1.669l-.916-.008c-.08-.95-.514-1.68-1.304-2.188-.622-.4-1.273-.535-1.953-.405-.676.123-1.203.48-1.582 1.069a2.479 2.479 0 0 0-.353 1.915c.144.689.533 1.238 1.169 1.647.763.49 1.612.583 2.547.276l.358.855z"
                fill="#D0D0D0" />
            <path
                d="M32.615 18.1a3.311 3.311 0 0 1-1.754.234 4.094 4.094 0 0 1-1.719-.638c-.843-.542-1.37-1.274-1.581-2.196-.205-.917-.049-1.777.468-2.58a3.334 3.334 0 0 1 2.177-1.508c.94-.21 1.86-.023 2.764.558.535.345.951.778 1.249 1.3a3.1 3.1 0 0 1 .43 1.669l-.916-.008c-.08-.95-.514-1.68-1.304-2.188-.622-.4-1.273-.535-1.953-.405-.676.123-1.203.48-1.582 1.069a2.479 2.479 0 0 0-.353 1.915c.144.689.533 1.238 1.169 1.647.763.49 1.612.583 2.547.276l.358.855z"
                fill="#F1F1F3" />
            <path
                d="m14.05 18.64-1.005-.414-.133-3.217-3.332-1.374-2.35 2.195-1.048-.432 7.278-6.825.287.118.303 9.949zm-1.172-4.769-.102-3.23-2.36 2.216 2.462 1.014z"
                fill="#D0D0D0" />
            <path
                d="m14.05 18.64-1.005-.414-.133-3.217-3.332-1.374-2.35 2.195-1.048-.432 7.278-6.825.287.118.303 9.949zm-1.172-4.769-.102-3.23-2.36 2.216 2.462 1.014z"
                fill="#D0D0D0" />
            <path
                d="m14.05 18.64-1.005-.414-.133-3.217-3.332-1.374-2.35 2.195-1.048-.432 7.278-6.825.287.118.303 9.949zm-1.172-4.769-.102-3.23-2.36 2.216 2.462 1.014z"
                fill="#D0D0D0" />
            <path
                d="m14.05 18.64-1.005-.414-.133-3.217-3.332-1.374-2.35 2.195-1.048-.432 7.278-6.825.287.118.303 9.949zm-1.172-4.769-.102-3.23-2.36 2.216 2.462 1.014z"
                fill="#F1F1F3" />
            <path
                d="M19.98 14.512c.85-.542 1.731-.689 2.644-.439.914.25 1.592.786 2.035 1.609.453.817.554 1.682.304 2.595a3.361 3.361 0 0 1-1.598 2.087c-.816.478-1.676.593-2.582.345-.898-.246-1.566-.85-2.004-1.81l-.264.966-.933-.255 2.363-8.634.933.256-.898 3.28zM21 19.78a2.265 2.265 0 0 0 1.857-.272c.593-.357.977-.858 1.154-1.503a2.524 2.524 0 0 0-.201-1.886c-.313-.606-.803-1-1.471-1.182-.668-.183-1.29-.11-1.867.219-.569.33-.948.841-1.137 1.532-.187.683-.12 1.324.203 1.924.323.6.81.99 1.463 1.168z"
                fill="#D0D0D0" />
            <path
                d="M19.98 14.512c.85-.542 1.731-.689 2.644-.439.914.25 1.592.786 2.035 1.609.453.817.554 1.682.304 2.595a3.361 3.361 0 0 1-1.598 2.087c-.816.478-1.676.593-2.582.345-.898-.246-1.566-.85-2.004-1.81l-.264.966-.933-.255 2.363-8.634.933.256-.898 3.28zM21 19.78a2.265 2.265 0 0 0 1.857-.272c.593-.357.977-.858 1.154-1.503a2.524 2.524 0 0 0-.201-1.886c-.313-.606-.803-1-1.471-1.182-.668-.183-1.29-.11-1.867.219-.569.33-.948.841-1.137 1.532-.187.683-.12 1.324.203 1.924.323.6.81.99 1.463 1.168z"
                fill="#D0D0D0" />
            <path
                d="M19.98 14.512c.85-.542 1.731-.689 2.644-.439.914.25 1.592.786 2.035 1.609.453.817.554 1.682.304 2.595a3.361 3.361 0 0 1-1.598 2.087c-.816.478-1.676.593-2.582.345-.898-.246-1.566-.85-2.004-1.81l-.264.966-.933-.255 2.363-8.634.933.256-.898 3.28zM21 19.78a2.265 2.265 0 0 0 1.857-.272c.593-.357.977-.858 1.154-1.503a2.524 2.524 0 0 0-.201-1.886c-.313-.606-.803-1-1.471-1.182-.668-.183-1.29-.11-1.867.219-.569.33-.948.841-1.137 1.532-.187.683-.12 1.324.203 1.924.323.6.81.99 1.463 1.168z"
                fill="#D0D0D0" />
            <path
                d="M19.98 14.512c.85-.542 1.731-.689 2.644-.439.914.25 1.592.786 2.035 1.609.453.817.554 1.682.304 2.595a3.361 3.361 0 0 1-1.598 2.087c-.816.478-1.676.593-2.582.345-.898-.246-1.566-.85-2.004-1.81l-.264.966-.933-.255 2.363-8.634.933.256-.898 3.28zM21 19.78a2.265 2.265 0 0 0 1.857-.272c.593-.357.977-.858 1.154-1.503a2.524 2.524 0 0 0-.201-1.886c-.313-.606-.803-1-1.471-1.182-.668-.183-1.29-.11-1.867.219-.569.33-.948.841-1.137 1.532-.187.683-.12 1.324.203 1.924.323.6.81.99 1.463 1.168zM32.486 19.894c-.51.329-1.072.506-1.688.532a4.096 4.096 0 0 1-1.802-.332c-.924-.39-1.57-1.02-1.937-1.89-.36-.869-.354-1.743.017-2.623.37-.88 1-1.5 1.885-1.86.889-.369 1.828-.344 2.818.073a3.733 3.733 0 0 1 1.455 1.066c.39.467.628.99.712 1.57l-.905.149c-.242-.922-.795-1.565-1.66-1.93-.683-.287-1.347-.308-1.995-.063-.644.238-1.102.68-1.374 1.325a2.478 2.478 0 0 0-.018 1.948c.26.654.739 1.127 1.435 1.42.836.353 1.689.297 2.557-.166l.5.78z"
                fill="#D0D0D0" />
            <path
                d="M32.486 19.894c-.51.329-1.072.506-1.688.532a4.096 4.096 0 0 1-1.802-.332c-.924-.39-1.57-1.02-1.937-1.89-.36-.869-.354-1.743.017-2.623.37-.88 1-1.5 1.885-1.86.889-.369 1.828-.344 2.818.073a3.733 3.733 0 0 1 1.455 1.066c.39.467.628.99.712 1.57l-.905.149c-.242-.922-.795-1.565-1.66-1.93-.683-.287-1.347-.308-1.995-.063-.644.238-1.102.68-1.374 1.325a2.478 2.478 0 0 0-.018 1.948c.26.654.739 1.127 1.435 1.42.836.353 1.689.297 2.557-.166l.5.78z"
                fill="#D0D0D0" />
            <path
                d="M32.486 19.894c-.51.329-1.072.506-1.688.532a4.096 4.096 0 0 1-1.802-.332c-.924-.39-1.57-1.02-1.937-1.89-.36-.869-.354-1.743.017-2.623.37-.88 1-1.5 1.885-1.86.889-.369 1.828-.344 2.818.073a3.733 3.733 0 0 1 1.455 1.066c.39.467.628.99.712 1.57l-.905.149c-.242-.922-.795-1.565-1.66-1.93-.683-.287-1.347-.308-1.995-.063-.644.238-1.102.68-1.374 1.325a2.478 2.478 0 0 0-.018 1.948c.26.654.739 1.127 1.435 1.42.836.353 1.689.297 2.557-.166l.5.78z"
                fill="#D0D0D0" />
            <path
                d="M32.486 19.894c-.51.329-1.072.506-1.688.532a4.096 4.096 0 0 1-1.802-.332c-.924-.39-1.57-1.02-1.937-1.89-.36-.869-.354-1.743.017-2.623.37-.88 1-1.5 1.885-1.86.889-.369 1.828-.344 2.818.073a3.733 3.733 0 0 1 1.455 1.066c.39.467.628.99.712 1.57l-.905.149c-.242-.922-.795-1.565-1.66-1.93-.683-.287-1.347-.308-1.995-.063-.644.238-1.102.68-1.374 1.325a2.478 2.478 0 0 0-.018 1.948c.26.654.739 1.127 1.435 1.42.836.353 1.689.297 2.557-.166l.5.78zM14.309 22.13l-1.06-.235-.686-3.147-3.52-.778-1.936 2.566L6 20.29l5.994-7.976.303.067 2.012 9.747zm-1.976-4.496-.657-3.165-1.942 2.59 2.599.575z"
                fill="#D0D0D0" />
            <path
                d="m14.309 22.13-1.06-.235-.686-3.147-3.52-.778-1.936 2.566L6 20.29l5.994-7.976.303.067 2.012 9.747zm-1.976-4.496-.657-3.165-1.942 2.59 2.599.575z"
                fill="#D0D0D0" />
            <path
                d="m14.309 22.13-1.06-.235-.686-3.147-3.52-.778-1.936 2.566L6 20.29l5.994-7.976.303.067 2.012 9.747zm-1.976-4.496-.657-3.165-1.942 2.59 2.599.575z"
                fill="#D0D0D0" />
            <path
                d="m14.309 22.13-1.06-.235-.686-3.147-3.52-.778-1.936 2.566L6 20.29l5.994-7.976.303.067 2.012 9.747zm-1.976-4.496-.657-3.165-1.942 2.59 2.599.575z"
                fill="#D0D0D0" />
            <path
                d="M18.946 18.503c.775-.645 1.631-.902 2.569-.769.937.133 1.677.58 2.22 1.34.553.753.763 1.598.63 2.535a3.361 3.361 0 0 1-1.322 2.272c-.75.577-1.588.8-2.518.668-.922-.13-1.66-.645-2.216-1.543l-.14.992-.958-.135L18.466 15l.957.136-.477 3.367zm1.677 5.096a2.266 2.266 0 0 0 1.808-.504c.543-.429.86-.974.955-1.636a2.524 2.524 0 0 0-.438-1.846 2.255 2.255 0 0 0-1.608-.988c-.685-.096-1.293.054-1.824.453-.523.4-.834.955-.935 1.664-.1.701.049 1.329.445 1.883.395.555.928.879 1.597.974z"
                fill="#525253" />
            <path d="M21 29v6m0 0 3-3m-3 3-3-3" stroke="#525253" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M33.01 21.94c-.464.392-1 .639-1.607.742-.6.105-1.21.071-1.83-.102-.966-.27-1.686-.814-2.16-1.632-.465-.816-.57-1.683-.313-2.603a3.335 3.335 0 0 1 1.635-2.084c.835-.477 1.77-.57 2.805-.282a3.732 3.732 0 0 1 1.577.874c.447.414.748.903.904 1.467l-.879.262c-.356-.884-.986-1.452-1.89-1.705-.713-.199-1.375-.136-1.986.19-.61.317-1.008.813-1.196 1.487a2.478 2.478 0 0 0 .228 1.935c.34.615.874 1.025 1.603 1.228.873.244 1.712.082 2.515-.487l.594.71zM15.26 26.451l-1.082-.098-1.077-3.035-3.589-.329-1.598 2.79-1.129-.103 4.94-8.668.31.028 3.224 9.415zm-2.527-4.21-1.051-3.056-1.6 2.814 2.65.242z"
                fill="#525253" />
        </svg>
    );
};

export default TextInFallIcon;

