import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import RcSlider from 'rc-slider';
import { useNavigate } from 'react-router-dom';

import { Icon, NewButton } from 'components/UI';

import {
    ModalEvent,
    getModalEvent,
    getModalOptionalData,
    hideModal,
} from 'state/modules/modal';
import {
    getStockVideoIdWithUploadInProgress,
    uploadProjectStockVideo,
} from 'state/modules/projectUpload';
import { createProjectWithStockVideo } from 'state/modules/projects';

import { StockMedia } from 'interfaces/stockMedia';

const VideoContainer = styled.div`
    width: 100%;
`;

const Info = styled.div`
    width: 100%;
`;

const Container = styled.div`
    height: 699px;
    display: grid;
    gap: 24px;
    grid-template-columns: 60% 40%;
    padding: 2.8125rem 3.5rem;
    min-width: 20vw;
    max-width: 90vw;
    max-height: 93vh;
    width: 63rem;
    background-color: rgb(255, 255, 255);
    overflow: hidden auto;
    border-radius: 1.25rem;
    z-index: 1;
`;

const VideoWrap = styled.div`
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
`;

const Video = styled.video`
    border-radius: 10px;
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    position: relative;
`;

const Controls = styled.div`
    display: flex;
    align-items: center;
`;

const MediaInfo = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 500;
    color: rgb(93, 100, 123);
    margin-top: 0.3125rem;
    margin-bottom: 2rem;
`;

const PlayToggler = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
`;

const Name = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: -0.005em;
    font-weight: 600;
    margin: 0px;
`;

const ProgressContainer = styled.div`
    width: 100%;
    padding-left: 24px;
`;

const Progress = styled.div`
    position: relative;
    margin-bottom: 6px;
`;

const TimeContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Time = styled.p`
    font-family: 'Proxima Nova', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 400;
    display: flex;
    position: relative;
`;

const StockVideoModal = () => {
    const [duration, setDuration] = useState(0);
    const [dimension, setDimension] = useState<null | {
        width: number;
        height: number;
    }>(null);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isPlaying, setPlaying] = useState(false);

    const videoRef = useRef<any>(null);
    const dispatch = useDispatch();

    const media = useSelector(getModalOptionalData) as StockMedia;
    const event = useSelector(getModalEvent);

    const navigate = useNavigate();

    const inLibrary = useMemo(
        () => event === ModalEvent.CRATE_PROJECT_WITH_STOCK_VIDEO,
        [event]
    );

    const videoIdWithUploadInProgress = useSelector(
        getStockVideoIdWithUploadInProgress
    );

    const isCurrentVideoUploadInProgress = useMemo(
        () => videoIdWithUploadInProgress === media.id,
        [media, videoIdWithUploadInProgress]
    );

    const handleLoadMetadata = (
        event: SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setDuration((event.target as any).duration);
        setDimension({
            width: (event.target as any).videoWidth,
            height: (event.target as any).videoHeight,
        });
    };

    const handleTimeUpdate = (
        event: SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setCurrentProgress((event.target as any).currentTime);
    };

    const handleSliderChange = (value: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = value;
        }
    };

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const handleSubmit = () => {
        if (inLibrary) {
            dispatch(hideModal());
            dispatch(createProjectWithStockVideo({ video: media, navigate }));
        } else {
            dispatch(
                uploadProjectStockVideo({
                    media,
                })
            );
        }
    };

    const handlePause = () => {
        setPlaying(false);
    };

    const handlePlay = () => {
        setPlaying(true);
    };

    const hadnleEnded = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
        }
    };

    const renderInfo = () => {
        return (
            <Info>
                <Name>{media.title}</Name>
                {dimension && duration ? (
                    <MediaInfo>
                        {`${moment
                            .duration(duration, 'seconds')
                            .format('h[h] m[m] s[s]', {
                                useGrouping: false,
                                trim: 'both',
                            })} · ${dimension.width}x${dimension.height}`}
                    </MediaInfo>
                ) : null}
                <NewButton
                    color="rgb(24, 25, 27)"
                    textColor="#fff"
                    bordered
                    borderRadius={10}
                    fontWeight="bold"
                    height={48}
                    onClick={handleSubmit}
                    disabled={isCurrentVideoUploadInProgress}
                    loading={isCurrentVideoUploadInProgress}
                >
                    {inLibrary ? 'Create Project' : 'Add Video'}
                </NewButton>
            </Info>
        );
    };

    const renderControls = () => {
        return (
            <Controls>
                <PlayToggler onClick={togglePlay}>
                    <Icon
                        name={isPlaying ? 'pause' : 'play'}
                        size={28}
                        color="rgb(93, 100, 123)"
                    />
                </PlayToggler>
                <ProgressContainer>
                    {duration ? (
                        <Progress>
                            <RcSlider
                                min={0}
                                max={duration}
                                step={1}
                                onChange={handleSliderChange}
                                value={currentProgress}
                                handleStyle={{
                                    display: 'none',
                                }}
                                style={{
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                                railStyle={{
                                    background: 'rgb(225, 225, 227)',
                                }}
                                trackStyle={{
                                    background: 'rgb(92, 94, 101)',
                                }}
                            />
                        </Progress>
                    ) : null}
                    <TimeContainer>
                        <Time>
                            {`${moment
                                .duration(currentProgress, 'seconds')
                                .format('mm:ss', {
                                    trim: false,
                                })}`}
                        </Time>
                        <Time>
                            {`${moment
                                .duration(duration, 'seconds')
                                .format('mm:ss', {
                                    trim: false,
                                })}`}
                        </Time>
                    </TimeContainer>
                </ProgressContainer>
            </Controls>
        );
    };

    return (
        <Container>
            <VideoContainer>
                <VideoWrap>
                    <Video
                        ref={videoRef}
                        src={media.display_sizes[0].uri}
                        onLoadedMetadata={handleLoadMetadata}
                        onTimeUpdate={handleTimeUpdate}
                        controls={false}
                        onClick={togglePlay}
                        onPause={handlePause}
                        onPlay={handlePlay}
                        onEnded={hadnleEnded}
                    />
                </VideoWrap>
                {renderControls()}
            </VideoContainer>
            {renderInfo()}
        </Container>
    );
};

export default StockVideoModal;
