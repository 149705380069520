import {
  all,
  call,
  put,
  select,
  takeLatest,
  throttle,
  delay,
  take,
} from "redux-saga/effects";
import groupBy from "lodash.groupby";
import isEqual from "lodash.isequal";
import without from "lodash.without";
import { AxiosResponse } from "axios";
import moment from "moment";

import {
  getVideoId,
  getVideoInfo,
  GetVideoListByFilterReqParams,
  VideosActionTypes,
} from "state/modules/media";
import {
  checkAnalyzerVideosInProgressStatus,
  getSelectedCelebs,
  getSelectedFacialAttributes,
  getSelectedObjects,
  getSelectedSegments,
  getSelectedTranscript,
  getSelectedUserDefined,
  getSentencesToUpdate,
  getVideoExplorerMinConfidence,
  getVideoExplorerMinScaleFactor,
  getVideoExplorerSearchQuery,
  getVideoExplorerSelectedCategory,
  getVideoExplorerSelectedItems,
  getVideoExplorerVideoFilter,
  getAnalyzerVideosInProgress,
  getVideoExplorerVideoList,
  getVideoExplorerVideoListCount,
  SelectedShotItem,
  Scene,
  SelectedMetadataItem,
  VideoExplorerActionTypes,
  ChangeAnalyzerVideoFilterAction,
  ChangeItemConfidenceValueAction,
  ChangeItemScaleFactorValueAction,
  ChangeSearchQueryAction,
  DeleteSentenceAction,
  EditSentenceAction,
  GetFilteredAnalyzerVideoListAction,
  GetItemTimestampsByConfidenceValueAction,
  GetItemTimestampsByScaleFactorValueAction,
  GetTimestampsByGlobalFiltersAction,
  RemoveSceneAction,
  SelectAnalysisItemAction,
  SelectedSentence,
  SelectShotItemAction,
  SelectTranscriptItemAction,
  ToggleCategoryVisibilityAction,
  ToggleSelectedItemVisibilityAction,
  UnselectAnalysisItemAction,
  VideoExplorerInitAction,
  ChangeMediaLanguageCodeAction,
} from "state/modules/videoExplorer";
import {
  getAutocompleteItemsList,
  getCelebs,
  getUserDefined,
  getFacialAttributes,
  getLabels,
  getModeratedLabels,
  getSentencesWithSearchedQuery,
  getTranscriptList,
  TransformedSentence,
  TransformedAnalysisResultItem,
  AnalysisResultChildren,
  GetTimestampsReqMetadata,
  TransformAutocompleteItem,
  AnalysisResultParent,
  AnalysisResultTimestampItem,
  GetAnalysisResultItemTimestampsRes,
  getTimestampsForManyItems,
  getTranscriptTotal,
} from "state/modules/metadata";
import { getGlobalSearchMinConfidence } from "state/modules/globalSearch";
import { getShotsList, Shot, TransformedShot } from "state/modules/shots";

import * as metadaActions from "state/modules/metadata/actions";
import * as videoExplorerActions from "state/modules/videoExplorer/actions";
import * as videosActions from "state/modules/media/actions";
import * as generationActions from "state/modules/generation/actions";
import * as exportActions from "state/modules/export/actions";

import MetadataClient from "services/api/metadata";
import VideosClient from "services/api/videos";

import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from "interfaces/analysis";
import { MediaType, MediaFile, VideoFilter } from "interfaces/videos";
import { TransformedGlobalSearchResultItem } from "interfaces/globalSearch";

import { GetVideosResponse } from "models/videos";

import {
  transformAnalysisObject,
  transformSegmentToScenes,
  transformTimestampsToScenes,
  transformTranscriptTimestampsToScenes,
} from "utils/timestampsHelpers";
import { getRandomColor } from "state/utils/videosUtils";
import { getAllTranscriptItems } from "utils/analyzerHelpers";
import { getDateFromToRange } from "utils/filter";
import { prepareChildrensList } from "state/utils/videoExplorerHelper";

import {
  isParent,
  isSelectedAnalysisResultItem,
  isSelectedShot,
} from "utils/interfaceChecker";
import Timecode from "smpte-timecode";
import { getSentencesToDelete } from "./selectors";
import { getUploadingMediaWithAnalyzeCategories } from '../uploading';
import { UploadingMedia } from '../../../interfaces/uploading';
import { StatusType } from '../../../interfaces/statuses';
import * as uploadingActions from '../uploading/actions';
import * as analysisActions from '../analysis/actions';

export function* getTimestamps(
  videoId: string,
  labelType: AnalysisResultsType,
  name: string,
  minConfidence: number,
  minScaleFactor: number
): Generator<any, Array<AnalysisResultTimestampItem>> {
  const timestamps = [] as Array<AnalysisResultTimestampItem>;
  function* loadTimestamps(offset: number): Generator {
    const timestampsRes = (yield call(
      MetadataClient.getMetadataDetails,
      videoId,
      labelType,
      name,
      minConfidence,
      minScaleFactor,
      offset
    )) as AxiosResponse<GetAnalysisResultItemTimestampsRes>;

    const newTimestamps = timestampsRes.data.content;
    const metadata = timestampsRes.data._metadata;

    timestamps.push(...newTimestamps);

    if (metadata.totalCount > timestamps.length) {
      yield call(loadTimestamps, timestamps.length);
    }
  }

  try {
    yield call(loadTimestamps, 0);

    return timestamps;
  } catch (error) {
    console.log({ error });

    return timestamps;
  }
}

export function* handleInit(action: VideoExplorerInitAction): Generator {
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedCategory = (yield select(
    getVideoExplorerSelectedCategory
  )) as AnalysisResultsCategory;

  const searchQuery = (yield select(getVideoExplorerSearchQuery)) as string;
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;

  const { videoId } = action.payload;

  try {
    if (videoId) {
      yield put(videosActions.getVideoDetails(videoId));

      yield take(VideosActionTypes.SET_VIDEO_DETAILS);

      const mediaDetails = (yield select(getVideoInfo)) as MediaFile;

      const offset =
        mediaDetails.mediaInfo.original?.other?.[0]?.timeCodeFirstFrame;
      const fps = mediaDetails.mediaInfo.original?.video?.[0]?.framerate;

      if (offset && fps) {
        const timecodeData = Timecode(offset, fps as any);

        yield put(videoExplorerActions.setOriginalTimecodeStatus(true));
        yield put(
          videoExplorerActions.setOriginalTimecode({
            timecode: offset,
            frameCount: timecodeData.frameCount,
            ms: Math.trunc((timecodeData.frameCount / fps) * 1000),
          })
        );
      }

      yield put(metadaActions.getMetadaCategoriesTotal(videoId));
      yield put(metadaActions.getTranscript(videoId, searchQuery));

      if (selectedCategory !== AnalysisResultsCategory.TRANSCRIPT) {
        yield put(
          metadaActions.getMetadata(
            videoId,
            selectedCategory,
            minConfidence[0],
            minScaleFactor[0],
            searchQuery
          )
        );
      }

      yield put(generationActions.getTrailers(videoId));
      yield put(generationActions.getPreviews(videoId));
    }

    const videoList = (yield select(
      getVideoExplorerVideoList
    )) as Array<MediaFile>;

    if (!videoList.length) {
      yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
    }

    yield put(videoExplorerActions.setTimestampsLoading(false));
  } catch (error) {
    console.log({ error });
    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleSelectAllSegments(): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const selectedShots = (yield select(
    getSelectedSegments
  )) as Array<SelectedShotItem>;
  const shots = (yield select(getShotsList)) as Array<TransformedShot>;

  const restSelectedItems = without(selectedItems, ...selectedShots);
  const notSelectedShots = shots.filter((item: Shot) => {
    const isSelected = selectedShots.find(
      (selectedItem: Shot) => selectedItem.id === item.id
    );

    if (!isSelected) {
      return item;
    }

    return false;
  });

  const changedNewShots = notSelectedShots.map((item: TransformedShot) => {
    const color = getRandomColor();
    const scenes = transformSegmentToScenes(item, color);

    return {
      ...item,
      scenes,
      color,
      isVisible: true,
      timestamps: [
        {
          timestamp: item.startTimestampMillis,
        },
        {
          timestamp: item.endTimestampMillis,
        },
      ],
    };
  }) as Array<SelectedShotItem>;

  yield put(
    videoExplorerActions.setSelectedItems([
      ...restSelectedItems,
      ...selectedShots,
      ...changedNewShots,
    ])
  );
  yield put(exportActions.selectMultipleItemsToExport(changedNewShots));
  yield put(videoExplorerActions.setTimestampsLoading(false));
}

export function* handleChangeItemConfidenceValue(
  action: ChangeItemConfidenceValueAction
): Generator {
  const {
    item,
    value,
  }: {
    item: SelectedMetadataItem;
    value: Array<number>;
  } = action.payload;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const selectedItemIndex = selectedItems.findIndex(
    (dataItem: SelectedMetadataItem) => dataItem.id === item.id
  );

  const changedItems = [...selectedItems];

  changedItems[selectedItemIndex] = {
    ...changedItems[selectedItemIndex],
    confidenceFilter: value,
    isConfidenceChanged: true,
    isVisible: true,
  };

  yield put(videoExplorerActions.setSelectedItems(changedItems));
}

export function* handleChangeItemScaleFactorValue(
  action: ChangeItemScaleFactorValueAction
): Generator {
  const {
    item,
    value,
  }: {
    item: SelectedMetadataItem;
    value: Array<number>;
  } = action.payload;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const selectedItemIndex = selectedItems.findIndex(
    (dataItem: SelectedMetadataItem) => dataItem.id === item.id
  );

  const changedItems = [...selectedItems];

  changedItems[selectedItemIndex] = {
    ...changedItems[selectedItemIndex],
    scaleFactorFilter: value,
    isScaleFactorChanged: true,
    isVisible: true,
  };

  yield put(videoExplorerActions.setSelectedItems(changedItems));
}

export function* handleChangeSearchQuery(
  action: ChangeSearchQueryAction
): Generator {
  const value = action.payload;

  yield put(videoExplorerActions.setSearchQuery(value));

  const videoId = (yield select(getVideoId)) as string;
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;
  const selectedCategory = (yield select(
    getVideoExplorerSelectedCategory
  )) as AnalysisResultsCategory;

  if (!value.length) {
    yield put(
      metadaActions.getMetadata(
        videoId,
        selectedCategory,
        minConfidence[0],
        minScaleFactor[0],
        ""
      )
    );

    yield put(metadaActions.clearAutocompleteItems());
  } else if (selectedCategory === AnalysisResultsCategory.SHOT) {
    yield put(
      metadaActions.getAutocomplete(videoId, value, AnalysisResultsCategory.ALL)
    );
  } else if (selectedCategory !== AnalysisResultsCategory.TRANSCRIPT) {
    yield put(metadaActions.getAutocomplete(videoId, value, selectedCategory));
  } else if (selectedCategory === AnalysisResultsCategory.TRANSCRIPT) {
    yield put(metadaActions.getTranscript(videoId, value));
  }
}

export function* handleGetTimestampsByConfidenceValue(
  action: GetItemTimestampsByConfidenceValueAction
): Generator {
  const { item, value } = action.payload;
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const videoId = (yield select(getVideoId)) as string;

  try {
    const selectedItemIndex = selectedItems.findIndex(
      (dataItem: SelectedMetadataItem) => dataItem.id === item.id
    );

    const timestamps = (yield call(
      getTimestamps,
      videoId,
      item.labelType,
      item.name,
      value[0],
      item.scaleFactorFilter[0]
    )) as Array<AnalysisResultTimestampItem>;

    const selectedItem = selectedItems.find(
      (dataItem: SelectedMetadataItem) => dataItem.id === item.id
    );

    const scenes = transformTimestampsToScenes(
      timestamps,
      selectedItem?.color || ""
    );

    const changedItems = [...selectedItems];

    changedItems[selectedItemIndex] = {
      ...changedItems[selectedItemIndex],
      timestamps,
      scenes,
      isVisible: true,
    };

    yield put(videoExplorerActions.setSelectedItems(changedItems));
    yield put(exportActions.selectMultipleItemsToExport(changedItems));
    yield put(videoExplorerActions.setTimestampsLoading(false));
  } catch (error) {
    console.log({ error });

    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleGetTimestampsByScaleFactorValue(
  action: GetItemTimestampsByScaleFactorValueAction
): Generator {
  const { item, value } = action.payload;
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const videoId = (yield select(getVideoId)) as string;

  try {
    const selectedItemIndex = selectedItems.findIndex(
      (dataItem: SelectedMetadataItem) => dataItem.id === item.id
    );

    const timestamps = (yield call(
      getTimestamps,
      videoId,
      item.labelType,
      item.name,
      item.confidenceFilter[0],
      value[0]
    )) as Array<AnalysisResultTimestampItem>;

    const selectedItem = selectedItems.find(
      (dataItem: SelectedMetadataItem) => dataItem.id === item.id
    );

    const scenes = transformTimestampsToScenes(
      timestamps,
      selectedItem?.color || ""
    );

    const changedItems = [...selectedItems];

    changedItems[selectedItemIndex] = {
      ...changedItems[selectedItemIndex],
      timestamps,
      scenes,
      isVisible: true,
    };

    yield put(videoExplorerActions.setSelectedItems(changedItems));
    yield put(exportActions.selectMultipleItemsToExport(changedItems));
    yield put(videoExplorerActions.setTimestampsLoading(false));
  } catch (error) {
    console.log({ error });

    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleGetTimestampsByGlobalFilters(
  action: GetTimestampsByGlobalFiltersAction
): Generator {
  const { confidence, scaleFactor } = action.payload;
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const videoId = (yield select(getVideoId)) as string;
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;
  const selectedCategory = (yield select(
    getVideoExplorerSelectedCategory
  )) as AnalysisResultsCategory;

  try {
    yield put(
      metadaActions.getMetadata(
        videoId,
        selectedCategory,
        confidence ? confidence[0] : minConfidence[0],
        scaleFactor ? scaleFactor[0] : minScaleFactor[0],
        ""
      )
    );

    const notEditedSelectedItems = selectedItems.filter(
      (item: SelectedMetadataItem) =>
        !item.isConfidenceChanged && !item.isScaleFactorChanged
    );

    if (selectedItems.length) {
      yield put(videoExplorerActions.setTimestampsLoading(true));

      const metadata = selectedItems.map((item: SelectedMetadataItem) => ({
        name: item.name,
        type: item.labelType,
        scaleFactor: scaleFactor ? scaleFactor[0] : minScaleFactor[0],
        range: [
          {
            range: {
              confidence: {
                gte: confidence ? confidence[0] : minConfidence[0],
              },
            },
          },
        ],
      })) as Array<GetTimestampsReqMetadata>;

      const timestamps = (yield call(
        getTimestampsForManyItems,
        videoId,
        metadata
      )) as Array<AnalysisResultTimestampItem>;

      const editedSelectedItems = selectedItems.filter(
        (item: SelectedMetadataItem) =>
          item.isConfidenceChanged || item.isScaleFactorChanged
      );

      const changedSelectedItems = notEditedSelectedItems.map(
        (item: SelectedMetadataItem) => {
          const newItemTimestamps = timestamps.filter(
            (timestamp: AnalysisResultTimestampItem) =>
              timestamp.name === item.name && timestamp.type === item.labelType
          );

          const newScenes = transformTimestampsToScenes(
            newItemTimestamps,
            item.color
          );

          return {
            ...item,
            confidenceFilter: confidence || minConfidence,
            scaleFactorFilter: scaleFactor || minScaleFactor,
            timestamps: newItemTimestamps,
            scenes: newScenes,
            isVisible: true,
          };
        }
      );

      yield put(
        videoExplorerActions.setSelectedItems([
          ...editedSelectedItems,
          ...changedSelectedItems,
        ])
      );
    }
    yield put(videoExplorerActions.setTimestampsLoading(false));
  } catch (error) {
    console.log({ error });

    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleSelectShotItem(shot: TransformedShot): Generator {
  let updatedItems = [];

  const color = getRandomColor() as string;
  const videoId = (yield select(getVideoId)) as string;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const scenes = transformSegmentToScenes(shot, color) as Array<Scene>;

  const changedShotItem = {
    ...shot,
    color,
    scenes,
    videoId,
    isVisible: true,
  } as SelectedShotItem;

  updatedItems = [...selectedItems, changedShotItem];

  yield put(exportActions.selectItemToExport(changedShotItem));

  yield put(videoExplorerActions.setSelectedItems(updatedItems));
}

export function* handleUnselectShotItem(shot: SelectedShotItem): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  let updatedItems = [];

  updatedItems = selectedItems.filter(
    (segment: SelectedMetadataItem) => segment.id !== shot.id
  );

  yield put(exportActions.unselectItemFromExport(shot));

  yield put(videoExplorerActions.setSelectedItems(updatedItems));
}

export function* toggleShotItem(action: SelectShotItemAction): Generator {
  const item = action.payload;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const isSelected = selectedItems.find(
    (selectedItem: SelectedMetadataItem) => selectedItem.id === item.id
  );

  if (isSelected && isSelectedShot(item)) {
    yield call(handleUnselectShotItem, item);
  } else {
    yield call(handleSelectShotItem, item);
  }
}

export function* handleSelectAllTranscript(): Generator {
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const searchQuery = (yield select(getVideoExplorerSearchQuery)) as string;
  const sentencesWithSearchedQuery = (yield select(
    getSentencesWithSearchedQuery
  )) as Array<TransformedSentence>;
  const transcript = (yield select(
    getTranscriptList
  )) as Array<TransformedSentence>;

  let transcriptData = [];

  if (sentencesWithSearchedQuery.length && searchQuery.length) {
    transcriptData = sentencesWithSearchedQuery;
  } else {
    transcriptData = transcript;
  }

  const selectedItemWithoutTranscript = selectedItems.filter(
    (item: SelectedMetadataItem) => item.type !== AnalysisResultsType.SENTENCE
  );

  const selectedTranscript = selectedItems.filter(
    (item: SelectedMetadataItem) => item.type === AnalysisResultsType.SENTENCE
  );

  const updatedTranscript = getAllTranscriptItems(
    selectedTranscript,
    transcriptData
  );

  yield put(
    videoExplorerActions.setSelectedItems([
      ...selectedItemWithoutTranscript,
      ...updatedTranscript,
    ])
  );

  yield put(exportActions.selectMultipleItemsToExport(updatedTranscript));

  yield put(videoExplorerActions.setTimestampsLoading(false));
}

export function* handleGetChildrensMetadata(
  item: TransformedAnalysisResultItem,
  childrensList: Array<AnalysisResultChildren>
): Generator {
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;

  try {
    const childrens = prepareChildrensList(
      item,
      childrensList,
      minScaleFactor,
      minConfidence
    );

    return childrens;
  } catch (error) {
    console.log({ error });
    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleGetChildrensList(
  item: TransformedAnalysisResultItem
): Generator {
  const videoId = (yield select(getVideoId)) as string;

  try {
    const childrenRes = (yield call(
      MetadataClient.getChildrens,
      videoId,
      item.labelType,
      item.name
    )) as AxiosResponse;

    const childrensList = childrenRes.data.content;

    return childrensList;
  } catch (error) {
    console.log({ error });
    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleSelectParent(
  item: TransformedAnalysisResultItem
): Generator {
  yield put(videoExplorerActions.setTimestampsLoading(true));

  const videoId = (yield select(getVideoId)) as string;
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;

  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  // const parentTimestamps = (yield call(
  //   getTimestamps,
  //   videoId,
  //   item.labelType,
  //   item.name,
  //   minConfidence[0],
  //   minScaleFactor[0],
  // )) as Array<AnalysisResultTimestampItem>;

  const childrensList = (yield call(
    handleGetChildrensList,
    item
  )) as Array<AnalysisResultChildren>;

  const notSelectedChildrens = childrensList.filter(
    (children: AnalysisResultChildren) => {
      const isSelected = selectedItems.find(
        (selectedItem: SelectedMetadataItem) =>
          selectedItem.name === children.name &&
          selectedItem.labelType === item.labelType
      );

      if (!isSelected) {
        return children;
      }
    }
  );

  let childrens = [] as Array<SelectedMetadataItem>;

  if (notSelectedChildrens.length) {
    const childrensMetadata = (yield call(
      handleGetChildrensMetadata,
      item,
      notSelectedChildrens
    )) as Array<GetTimestampsReqMetadata>;

    let childrensTimestampsArray = [] as Array<AnalysisResultTimestampItem>;

    if (childrensMetadata.length) {
      childrensTimestampsArray = (yield call(
        getTimestampsForManyItems,
        videoId,
        childrensMetadata
      )) as Array<AnalysisResultTimestampItem>;
    }

    if (childrensTimestampsArray.length) {
      const groupedChildrensTimestamps = groupBy(
        childrensTimestampsArray,
        (children: AnalysisResultTimestampItem) => children.name
      );

      childrens = Object.keys(groupedChildrensTimestamps).map((key: string) => {
        const color = getRandomColor();
        const scenes = transformTimestampsToScenes(
          groupedChildrensTimestamps[key],
          color
        );

        return {
          ...item,
          id: key + item.labelType + videoId,
          color,
          name: key,
          labelType: item.labelType,
          confidenceFilter: minConfidence,
          scenes,
          timestamps: groupedChildrensTimestamps[key],
          scaleFactorFilter: minScaleFactor,
          parents: groupedChildrensTimestamps[key][0].parents,
          videoId,
          isVisible: true,
        };
      });
    }
  }

  const transformedAnalysisParentObject = yield call(transformAnalysisObject, {
    timestamps: [],
    item,
    minScaleFactor,
    minConfidence,
    childrensList,
    videoId,
  });

  const updatedSelectedItems = [
    ...selectedItems,
    transformedAnalysisParentObject,
    ...childrens,
  ] as Array<SelectedMetadataItem>;

  yield put(videoExplorerActions.setSelectedItems(updatedSelectedItems));

  const itemsForExport = [
    ...childrens,
    transformedAnalysisParentObject,
  ] as Array<SelectedMetadataItem>;

  yield put(exportActions.selectMultipleItemsToExport(itemsForExport));

  yield put(videoExplorerActions.setTimestampsLoading(false));
}

export function* handleSelectAllMetadataByCategory(): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  const videoId = (yield select(getVideoId)) as string;
  const minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;
  const selectedCategory = (yield select(
    getVideoExplorerSelectedCategory
  )) as AnalysisResultsCategory;
  const searchQuery = (yield select(getVideoExplorerSearchQuery)) as string;
  const autocompleteItems = (yield select(
    getAutocompleteItemsList
  )) as Array<TransformAutocompleteItem>;
  const userDefined = (yield select(
    getUserDefined
  )) as Array<TransformedAnalysisResultItem>;
  const facialAttributes = (yield select(
    getFacialAttributes
  )) as Array<TransformedAnalysisResultItem>;
  const labels = (yield select(
    getLabels
  )) as Array<TransformedAnalysisResultItem>;
  const celebs = (yield select(
    getCelebs
  )) as Array<TransformedAnalysisResultItem>;
  const moderatedLabels = (yield select(
    getModeratedLabels
  )) as Array<TransformedAnalysisResultItem>;

  let data = [];
  let parents = [] as Array<TransformAutocompleteItem>;

  if (searchQuery.length && autocompleteItems.length) {
    const parentItems = autocompleteItems.filter(
      (autocompleteItem: TransformAutocompleteItem) =>
        autocompleteItem.hasChildren
    );
    let itemsWithoutChildrens = autocompleteItems.filter(
      (autocompleteItem: TransformAutocompleteItem) =>
        !autocompleteItem.hasChildren
    );

    if (parentItems.length) {
      itemsWithoutChildrens = itemsWithoutChildrens.filter(
        (itemWithoutChildren: TransformAutocompleteItem) => {
          const isChildOfSelectedParent =
            parentItems.findIndex((parent: TransformAutocompleteItem) => {
              const parentsOfItemWithoutChildren =
                itemWithoutChildren.parents.map(
                  (item: AnalysisResultParent) => item.name
                );

              if (parentsOfItemWithoutChildren.includes(parent.name)) {
                return parent;
              }
            }) >= 0;

          if (!isChildOfSelectedParent) {
            return itemWithoutChildren;
          }
        }
      );
    }

    data = itemsWithoutChildrens;
    parents = parentItems;
  } else {
    switch (selectedCategory) {
      case AnalysisResultsCategory.CELEBRITY:
        data = celebs;

        break;
      case AnalysisResultsCategory.OBJECT:
        data = labels;

        break;
      case AnalysisResultsCategory.FACIAL_ATTRIBUTE:
        data = facialAttributes;

        break;
      case AnalysisResultsCategory.USER_DEFINED:
        data = userDefined;

        break;
      case AnalysisResultsCategory.MODERATED_LABEL:
        data = moderatedLabels;

        break;
      default:
        data = [
          ...celebs,
          ...labels,
          ...facialAttributes,
          ...userDefined,
          ...moderatedLabels,
        ];
        break;
    }
  }

  const items = data.filter((dataItem: TransformedAnalysisResultItem) => {
    const isSelected =
      selectedItems.findIndex((selectedItem: SelectedMetadataItem) => {
        const type = selectedItem.labelType || selectedItem.type;

        if (
          selectedItem.name === dataItem.name &&
          type === dataItem.labelType
        ) {
          return selectedItem;
        }
      }) >= 0;

    if (!isSelected) {
      return dataItem;
    }
  });

  const metadata = items.map((metadataItem: TransformedAnalysisResultItem) => ({
    name: metadataItem.name,
    type: metadataItem.labelType,
    scaleFactor: minScaleFactor[0],
    range: [
      {
        range: {
          confidence: {
            gte: minConfidence[0],
          },
        },
      },
    ],
  }));

  try {
    const timestamps = (yield call(
      getTimestampsForManyItems,
      videoId,
      metadata
    )) as Array<AnalysisResultTimestampItem>;

    const changedSelectedItems = items.map(
      (changedSelectedItem: TransformedAnalysisResultItem) => {
        const newItemTimestamps = timestamps.filter(
          (timestamp: AnalysisResultTimestampItem) =>
            timestamp.name === changedSelectedItem.name &&
            timestamp.type === changedSelectedItem.labelType
        );
        const color = getRandomColor();

        const newScenes = transformTimestampsToScenes(newItemTimestamps, color);

        return {
          ...changedSelectedItem,
          color,
          confidenceFilter: minConfidence,
          scaleFactorFilter: minScaleFactor,
          scenes: newScenes,
          timestamps: newItemTimestamps,
          isVisible: true,
        };
      }
    );

    const updatedData = [...changedSelectedItems, ...selectedItems];

    yield put(exportActions.selectMultipleItemsToExport(updatedData));
    yield put(videoExplorerActions.setSelectedItems(updatedData));

    if (parents.length) {
      const notSelectedParents = parents.filter(
        (parent: TransformAutocompleteItem) => {
          const isSelected = selectedItems.find(
            (selectedItem: SelectedMetadataItem) =>
              selectedItem.name === parent.name &&
              selectedItem.type === parent.type
          );

          if (!isSelected) {
            return parent;
          }
        }
      );

      yield all(
        notSelectedParents.map((selectedParent: TransformAutocompleteItem) => {
          call(handleSelectParent, selectedParent);
        })
      );
      yield put(videoExplorerActions.setTimestampsLoading(false));
    } else {
      yield put(videoExplorerActions.setTimestampsLoading(false));
    }
  } catch (error) {
    console.log({ error });
    yield put(videoExplorerActions.setTimestampsLoading(false));
  }
}

export function* handleSelectAll(): Generator {
  yield put(videoExplorerActions.setTimestampsLoading(true));
  const selectedCategory = (yield select(
    getVideoExplorerSelectedCategory
  )) as AnalysisResultsCategory;

  if (selectedCategory === AnalysisResultsCategory.SHOT) {
    return yield call(handleSelectAllSegments);
  }
  if (selectedCategory === AnalysisResultsCategory.TRANSCRIPT) {
    return yield call(handleSelectAllTranscript);
  }
  return yield call(handleSelectAllMetadataByCategory);
}

export function* handleRemoveScene(action: RemoveSceneAction): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const { labelType } = action.payload;

  const scene = action.payload;

  const selectedItem = selectedItems.find((itemObj: SelectedMetadataItem) => {
    const selectedItemType = itemObj.type || itemObj.labelType;

    return selectedItemType === labelType && scene.name === itemObj.name;
  });

  let changedSelectedItems = [];

  if (selectedItem && selectedItem.scenes && selectedItem.scenes.length === 1) {
    changedSelectedItems = selectedItems.filter(
      (item: SelectedMetadataItem) => item.id !== selectedItem.id
    );
  } else {
    changedSelectedItems = selectedItems.map((item: SelectedMetadataItem) => {
      if (scene.labelType === item.labelType && scene.name === item.name) {
        const filteredScenes = item.scenes.filter(
          (itemScene: Scene) => itemScene.id !== scene.id
        );

        return {
          ...item,
          scenes: filteredScenes,
          isVisible: true,
        };
      }
      return item;
    });
  }

  yield put(videoExplorerActions.setSelectedItems(changedSelectedItems));

  yield put(exportActions.setItemsToExport(changedSelectedItems));
}

export function* handleUnselectAnalysisItem(
  action: UnselectAnalysisItemAction
): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const item = action.payload;

  const filteredItems = selectedItems.filter(
    (selectedItem: SelectedMetadataItem) => item.id !== selectedItem.id
  );

  yield put(videoExplorerActions.setSelectedItems(filteredItems));
  yield put(exportActions.setItemsToExport(filteredItems));
}

export function* handleToggleTranscriptItem(
  action: SelectTranscriptItemAction
): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const item = action.payload;
  const { videoId } = item;

  const isSelected = selectedItems.find(
    (selectedItem: SelectedMetadataItem) => selectedItem.id === item.id
  );

  let updatedItems = [] as Array<SelectedMetadataItem>;
  let changedTranscriptItem = {
    ...item,
  } as SelectedSentence;

  if (isSelected) {
    updatedItems = selectedItems.filter(
      (selectedItem: SelectedMetadataItem) => item.id !== selectedItem.id
    );
  } else {
    const color = getRandomColor() as string;
    const scenes = transformTranscriptTimestampsToScenes(
      item,
      color
    ) as Array<Scene>;

    changedTranscriptItem = {
      ...changedTranscriptItem,
      color,
      scenes,
      videoId,
      isVisible: true,
    } as SelectedSentence;

    updatedItems = [...selectedItems, changedTranscriptItem];
  }

  yield put(videoExplorerActions.setSelectedItems(updatedItems));

  yield put(exportActions.toggleItemToExport(changedTranscriptItem));
}

export function* selectAnalysisResultItem(
  item: TransformedAnalysisResultItem | TransformedGlobalSearchResultItem,
  minConfidence: Array<number>,
  minScaleFactor: Array<number>
): Generator {
  yield put(videoExplorerActions.setTimestampsLoading(true));

  try {
    const { videoId } = item;
    // if it is a parent - select parent with childrens flow
    if (isParent(item) && item.hasChildren) {
      return yield call(handleSelectParent, item);
    }
    // select item flow

    const timestamps = (yield call(
      getTimestamps,
      videoId,
      item.labelType,
      item.name,
      minConfidence[0],
      minScaleFactor[0]
    )) as Array<AnalysisResultTimestampItem>;

    const transformedAnalysisObject = yield call(transformAnalysisObject, {
      timestamps,
      item,
      minScaleFactor,
      minConfidence,
      childrensList: null,
      videoId,
    });

    const selectedItems = (yield select(
      getVideoExplorerSelectedItems
    )) as Array<SelectedMetadataItem>;

    yield put(
      videoExplorerActions.setSelectedItems([
        ...selectedItems,
        transformedAnalysisObject as SelectedMetadataItem,
      ])
    );

    yield put(
      exportActions.selectItemToExport(
        transformedAnalysisObject as SelectedMetadataItem
      )
    );

    yield put(videoExplorerActions.setTimestampsLoading(false));
  } catch (error) {
    yield put(videoExplorerActions.setTimestampsLoading(false));
    console.log({ error });
  }
}

export function* toggleAnalysisItem(
  action: SelectAnalysisItemAction
): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const { item, isGlobalSearchResult } = action.payload;

  // check if item is selected
  const isSelected = selectedItems.find(
    (selectedItem: SelectedMetadataItem) => selectedItem.id === item.id
  );

  // unselect
  if (isSelected && isSelectedAnalysisResultItem(item)) {
    yield put(videoExplorerActions.unselectAnalysisItem(item));

    return yield put(exportActions.unselectItemFromExport(item));
    // select item flow
  }

  let minConfidence = (yield select(
    getVideoExplorerMinConfidence
  )) as Array<number>;

  if (isGlobalSearchResult) {
    minConfidence = (yield select(
      getGlobalSearchMinConfidence
    )) as Array<number>;
  }

  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor
  )) as Array<number>;

  yield call(selectAnalysisResultItem, item, minConfidence, minScaleFactor);
}

export function* handleToggleSelectedItemVisibility(
  action: ToggleSelectedItemVisibilityAction
): Generator {
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  const { id } = action.payload;

  const updatedItems = selectedItems.map(
    (selectedItem: SelectedMetadataItem) => {
      if (selectedItem.id === id) {
        return {
          ...selectedItem,
          isVisible: !selectedItem.isVisible,
        };
      }
      return selectedItem;
    }
  );

  yield put(videoExplorerActions.setSelectedItems(updatedItems));
}

export function* handleToggleSelectedItemsCategoryVisibility(
  action: ToggleCategoryVisibilityAction
): Generator {
  const { category, isAllItemsVisible } = action.payload;

  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  let itemsToUpdate = [] as Array<SelectedMetadataItem>;

  switch (category) {
    case AnalysisResultsCategory.CELEBRITY:
      itemsToUpdate = (yield select(
        getSelectedCelebs
      )) as Array<SelectedMetadataItem>;

      break;
    case AnalysisResultsCategory.OBJECT:
      itemsToUpdate = (yield select(
        getSelectedObjects
      )) as Array<SelectedMetadataItem>;

      break;
    case AnalysisResultsCategory.USER_DEFINED:
      itemsToUpdate = (yield select(
        getSelectedUserDefined
      )) as Array<SelectedMetadataItem>;

      break;
    case AnalysisResultsCategory.FACIAL_ATTRIBUTE:
      itemsToUpdate = (yield select(
        getSelectedFacialAttributes
      )) as Array<SelectedMetadataItem>;

      break;
    case AnalysisResultsCategory.SHOT:
      itemsToUpdate = (yield select(
        getSelectedSegments
      )) as Array<SelectedMetadataItem>;

      break;
    case AnalysisResultsCategory.TRANSCRIPT:
      itemsToUpdate = (yield select(
        getSelectedTranscript
      )) as Array<SelectedMetadataItem>;

      break;
    default:
      break;
  }

  const restItems = without(selectedItems, ...itemsToUpdate);
  let updatedItems = [];

  if (isAllItemsVisible) {
    updatedItems = itemsToUpdate.map((item: SelectedMetadataItem) => ({
      ...item,
      isVisible: false,
    }));
  } else {
    updatedItems = itemsToUpdate.map((item: SelectedMetadataItem) => ({
      ...item,
      isVisible: true,
    }));
  }

  yield put(
    videoExplorerActions.setSelectedItems([...restItems, ...updatedItems])
  );
}

export function* handleEditSentence(action: EditSentenceAction): Generator {
  const { videoId, id, data } = action.payload;

  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;

  let transcriptList = (yield select(
    getTranscriptList
  )) as Array<TransformedSentence>;

  let sentencesToUpdate = (yield select(getSentencesToUpdate)) as Array<string>;

  const sentencesToDelete = (yield select(
    getSentencesToDelete
  )) as Array<string>;

  if (sentencesToDelete.length) {
    yield take(VideoExplorerActionTypes.SET_SENTENCES_TO_DELETE);

    sentencesToUpdate = (yield select(getSentencesToUpdate)) as Array<string>;

    transcriptList = (yield select(
      getTranscriptList
    )) as Array<TransformedSentence>;
  }

  const updatedTranscriptList = transcriptList.map(
    (item: TransformedSentence) => {
      if (item.id === id) {
        return {
          ...item,
          data: data.data,
          name: data.data,
          startTime: data.startTime,
          endTime: data.endTime,
          items: data.items,
          timestamps: data.timestamps,
        };
      }
      return item;
    }
  ) as Array<SelectedSentence>;

  yield put(metadaActions.setTranscriptList(updatedTranscriptList));

  const updatedSelectedItems = selectedItems.map(
    (item: SelectedMetadataItem) => {
      if (item.id === id) {
        return {
          ...item,
          data: data.data,
          name: data.data,
          startTime: data.startTime,
          endTime: data.endTime,
          items: data.items,
          timestamps: data.timestamps,
        };
      }
      return item;
    }
  ) as Array<SelectedSentence>;

  yield put(videoExplorerActions.setSelectedItems(updatedSelectedItems));

  const newSentencesToUpdate = [...sentencesToUpdate, id];

  yield put(videoExplorerActions.setSentencesToUpdate(newSentencesToUpdate));

  try {
    yield call(MetadataClient.editSentence, videoId, id, {
      data: data.data,
      startTime: data.startTime,
      endTime: data.endTime,
      items: data.items,
    });

    sentencesToUpdate = (yield select(getSentencesToUpdate)) as Array<string>;

    const filteredSentencesToUpdate = sentencesToUpdate.filter(
      (item: string) => item !== id
    );

    yield put(
      videoExplorerActions.setSentencesToUpdate(filteredSentencesToUpdate)
    );

    localStorage.setItem(
      videoId,
      JSON.stringify({
        isTranscriptChanged: true,
      })
    );
  } catch (error) {
    console.log({ error });
  }
}

export function* handleDeleteSentence(action: DeleteSentenceAction): Generator {
  const { videoId, id } = action.payload;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems
  )) as Array<SelectedMetadataItem>;
  let transcriptList = (yield select(
    getTranscriptList
  )) as Array<TransformedSentence>;
  let transcriptListTotal = (yield select(getTranscriptTotal)) as number;

  const sentencesToUpdate = (yield select(
    getSentencesToUpdate
  )) as Array<string>;

  let sentencesToDelete = (yield select(getSentencesToDelete)) as Array<string>;

  if (sentencesToUpdate.length) {
    yield take(VideoExplorerActionTypes.SET_SENTENCES_TO_UPDATE);
  }

  if (sentencesToUpdate.length) {
    sentencesToDelete = (yield select(getSentencesToDelete)) as Array<string>;

    transcriptList = (yield select(
      getTranscriptList
    )) as Array<TransformedSentence>;

    transcriptListTotal = (yield select(getTranscriptTotal)) as number;
  }

  const filteredTranscriptList = transcriptList.filter(
    (item: TransformedSentence) => item.id !== id
  );

  yield put(metadaActions.setTranscriptList(filteredTranscriptList));

  yield put(metadaActions.setTranscriptListTotal(transcriptListTotal - 1));

  const newSentencesToDelete = [...sentencesToDelete, id];

  yield put(videoExplorerActions.setSentencesToDelete(newSentencesToDelete));

  try {
    yield call(MetadataClient.deleteSentence, videoId, id);

    const filteredSelectedItems = selectedItems.filter(
      (item: SelectedMetadataItem) => item.id !== id
    );

    yield put(videoExplorerActions.setSelectedItems(filteredSelectedItems));

    sentencesToDelete = (yield select(getSentencesToUpdate)) as Array<string>;

    const filteredSentencesToDelete = sentencesToDelete.filter(
      (item: string) => item !== id
    );

    yield put(
      videoExplorerActions.setSentencesToDelete(filteredSentencesToDelete)
    );

    localStorage.setItem(
      videoId,
      JSON.stringify({
        isTranscriptChanged: true,
      })
    );
  } catch (error) {
    console.log({ error });
  }
}

export function* handleChangeVideoFilter(
  action: ChangeAnalyzerVideoFilterAction
): Generator {
  const { field, value } = action.payload;

  const oldVideoFilter = (yield select(
    getVideoExplorerVideoFilter
  )) as VideoFilter;

  const updatedFilter = {
    ...oldVideoFilter,
    [field]: value,
  };

  yield put(videoExplorerActions.setAnalyzerVideoFilter(updatedFilter));
}

function* handleGetFilteredAnalyzerVideoList(
  action: GetFilteredAnalyzerVideoListAction
): Generator {
  yield put(videoExplorerActions.getFilteredAnalyzerVideoListStart());

  const { isLoadMore } = action.payload;

  const videoFilter = (yield select(
    getVideoExplorerVideoFilter
  )) as VideoFilter;
  const oldVideos = (yield select(
    getVideoExplorerVideoList
  )) as Array<MediaFile>;
  const videosCount = (yield select(getVideoExplorerVideoListCount)) as number;

  const { dateFilter } = videoFilter;

  const dateFromToRange = getDateFromToRange({
    dateFilter,
    filter: videoFilter,
  });

  let filterParams = {} as GetVideoListByFilterReqParams;

  if (dateFromToRange) {
    filterParams = {
      ...filterParams,
      dateFrom: moment(dateFromToRange.dateFrom).toISOString(),
      dateTo: moment(dateFromToRange.dateTo).toISOString(),
      title: videoFilter.title,
      description: "",
    };
  } else {
    filterParams = {
      ...filterParams,
      title: videoFilter.title,
      description: "",
    };
  }

  try {
    const res = (yield call(
      VideosClient.getVideoListByFilter,
      filterParams,
      isLoadMore ? videosCount : 0
    )) as AxiosResponse;

    let videos = res.data.content;

    if (isLoadMore) {
      videos = [...oldVideos, ...videos];
    }

    yield put(
      videoExplorerActions.getFilteredAnalyzerVideoListSuccess(
        videos,
        res.data._metadata.totalCount
      )
    );

    const hasVideosInProgress = yield select(
      checkAnalyzerVideosInProgressStatus
    );

    if (hasVideosInProgress) {
      yield put(videoExplorerActions.updateAnalyzerVideosStatuses());
    }
  } catch (err) {
    console.log({ err });
    yield put(videoExplorerActions.getFilteredAnalyzerVideoListFail(err));
  }
}

export function* handleResetAnalyzerVideoFilter(): Generator {
  yield put(videoExplorerActions.getFilteredAnalyzerVideoListStart());

  const filterParams = {
    title: "",
    description: "",
    mediaType: MediaType.VIDEO,
  };

  try {
    const res = (yield call(
      VideosClient.getVideoListByFilter,
      filterParams
    )) as AxiosResponse;

    yield put(
      videoExplorerActions.getFilteredAnalyzerVideoListSuccess(
        res.data.content,
        res.data._metadata.totalCount
      )
    );
  } catch (error) {
    console.log({ error });
    yield put(videoExplorerActions.getFilteredAnalyzerVideoListFail(error));
  }
}

export function* handleUpdateVideosStatuses(): Generator {
    const videosInProgress = (yield select(
        getAnalyzerVideosInProgress
    )) as Array<MediaFile>;
    const uploadingVideosWithAnalyze = (yield select(
        getUploadingMediaWithAnalyzeCategories
    )) as Array<UploadingMedia>;

    if (videosInProgress.length) {
        yield put(videosActions.updateVideosStatusesStart());

        const videosId = videosInProgress.map((video: MediaFile) => video.id);
        const oldVideos = (yield select(
            getVideoExplorerVideoList
        )) as Array<MediaFile>;

        try {
            const res = (yield call(
                VideosClient.getVideosStatuses,
                videosId
            )) as AxiosResponse<GetVideosResponse>;

            if (res.data) {
                const updatedVideos = oldVideos.map((oldVideo: MediaFile) => {
                    const newVideo = res.data.content.find(
                        (item: MediaFile) => item.id === oldVideo.id
                    );

                    if (newVideo) {
                        return newVideo;
                    }
                    return oldVideo;
                });

                const transcodedAnalysisVideos= uploadingVideosWithAnalyze?.filter(item => {
                    const wasInProgress = videosInProgress?.some(video => video.id === item.id);
                    const wasTranscoded = updatedVideos?.some(video =>
                        (video.id === item.id) &&
                        (video.status.transcoding.status === StatusType.SUCCEEDED)
                    );

                    return wasInProgress && wasTranscoded;
                });

                const updatedAnalyzingVideos = uploadingVideosWithAnalyze?.filter(
                    item => !transcodedAnalysisVideos?.some(video => video.id === item.id)
                );

                if(transcodedAnalysisVideos.length) {
                    yield put(uploadingActions.setUploadingMediaWithAnalyzeCategories(updatedAnalyzingVideos));

                    for(let i = 0; i < transcodedAnalysisVideos.length; i++) {
                        const currentMedia = transcodedAnalysisVideos[i];
                        const selectedCollectionId = currentMedia.collectionId;

                        if (selectedCollectionId) {
                            const analysisData = {
                                videoId: currentMedia?.id,
                                collectionId: selectedCollectionId,
                                categories: currentMedia.categories,
                                language: currentMedia.transcriptLanguage,
                            }

                            yield put(analysisActions.reanalyzeSpecificVideo(analysisData));
                        }
                    }
                }

                const isVidesEqual = isEqual(oldVideos, updatedVideos);

                if (!isVidesEqual) {
                    yield put(
                        videoExplorerActions.updateAnalyzerVideosStatusesSuccess(
                            updatedVideos
                        )
                    );
                }

                const hasVideosInProgress = yield select(
                    checkAnalyzerVideosInProgressStatus
                );

                if (hasVideosInProgress) {
                    yield delay(25000);
                    yield put(videoExplorerActions.updateAnalyzerVideosStatuses());
                }
            }
        } catch (error) {
            yield put(videosActions.updateVideosStatusesFail(error));
        }
    }
}

export function* handleChangeMediaLanguage(
  action: ChangeMediaLanguageCodeAction
): Generator {
  const selectedLanguage = action.payload;

  const searchQuery = (yield select(getVideoExplorerSearchQuery)) as string;
  const mediaInfo = (yield select(getVideoInfo)) as MediaFile;

  yield put(videoExplorerActions.setMediaLanguageCode(selectedLanguage));

  localStorage.setItem(
    mediaInfo.id,
    JSON.stringify({
      isTranscriptChanged: true,
    })
  );

  yield delay(500);

  yield put(metadaActions.getTranscript(mediaInfo.id, searchQuery));

  yield call(VideosClient.editVideoDetails, {
    videoId: mediaInfo.id,
    title: mediaInfo.title || mediaInfo.filename,
    description: mediaInfo.description || "",
    defaultLanguage: selectedLanguage,
  });
}

export function* videoExplorerSaga(): Generator {
  yield takeLatest(VideoExplorerActionTypes.VIDEO_EXPLORER_INIT, handleInit);
  yield takeLatest(
    VideoExplorerActionTypes.UNSELECT_ANALYSIS_ITEM,
    handleUnselectAnalysisItem
  );
  yield takeLatest(
    VideoExplorerActionTypes.SELECT_ALL_ANALYSIS_ITEMS,
    handleSelectAll
  );
  yield takeLatest(
    VideoExplorerActionTypes.CHANGE_SEARCH_QUERY,
    handleChangeSearchQuery
  );
  yield takeLatest(
    VideoExplorerActionTypes.GET_TIMESTAMPS_BY_GLOBAL_FILTER,
    handleGetTimestampsByGlobalFilters
  );
  yield takeLatest(
    VideoExplorerActionTypes.GET_TIMESTAMPS_BY_CONFIDENCE_VALUE,
    handleGetTimestampsByConfidenceValue
  );
  yield takeLatest(
    VideoExplorerActionTypes.GET_TIMESTAMPS_BY_SCALE_FACTOR_VALUE,
    handleGetTimestampsByScaleFactorValue
  );

  yield takeLatest(
    VideoExplorerActionTypes.CHANGE_ITEM_SCALE_FACTOR_VALUE,
    handleChangeItemScaleFactorValue
  );
  yield takeLatest(
    VideoExplorerActionTypes.CHANGE_ITEM_CONFIDENCE_VALUE,
    handleChangeItemConfidenceValue
  );
  yield throttle(
    500,
    VideoExplorerActionTypes.SELECT_ANALYSIS_ITEM,
    toggleAnalysisItem
  );
  yield takeLatest(
    VideoExplorerActionTypes.SELECT_TRANSCRIPT_ITEM,
    handleToggleTranscriptItem
  );
  yield takeLatest(VideoExplorerActionTypes.SELECT_SHOT_ITEM, toggleShotItem);
  yield takeLatest(VideoExplorerActionTypes.REMOVE_SCENE, handleRemoveScene);
  yield takeLatest(
    VideoExplorerActionTypes.TOGGLE_SELECTED_ITEM_VISIBILITY,
    handleToggleSelectedItemVisibility
  );
  yield takeLatest(
    VideoExplorerActionTypes.TOGGLE_SELECTED_ITEMS_CATEGORY_VISIBILITY,
    handleToggleSelectedItemsCategoryVisibility
  );
  yield throttle(
    500,
    VideoExplorerActionTypes.EDIT_SENTENCE,
    handleEditSentence
  );
  yield throttle(
    500,
    VideoExplorerActionTypes.DELETE_SENTENCE,
    handleDeleteSentence
  );
  yield takeLatest(
    VideoExplorerActionTypes.CHANGE_VIDEO_FILTER,
    handleChangeVideoFilter
  );
  yield takeLatest(
    VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST,
    handleGetFilteredAnalyzerVideoList
  );
  yield takeLatest(
    VideoExplorerActionTypes.RESET_ANALYZER_VIDEO_FILTER,
    handleResetAnalyzerVideoFilter
  );
  yield takeLatest(
    VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES,
    handleUpdateVideosStatuses
  );
  yield takeLatest(
    VideoExplorerActionTypes.CHANGE_MEDIA_LANGUAGE_CODE,
    handleChangeMediaLanguage
  );
}
