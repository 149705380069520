import React, { Component, memo, RefObject } from 'react';

import styles from './styles.module.scss';

interface IInputProps {
    label?: string;
    type: string;
    value: string;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        field?: string
    ) => void;
    field?: string;
    error?: string;
    icon?: JSX.Element;
    placeholder?: string;
    isPasswordVisible?: boolean;
    onIconClick?: void | (() => void);
    pattern?: string;
    onFocus?: () => void;
    aligned?: boolean;
    inputRef?: RefObject<HTMLInputElement>;
    style?: any;
    inputStyle?: any;
}

interface IInputState {
    isFocused: boolean;
    isEmpty: boolean;
}

class Input extends Component<IInputProps, IInputState> {
    public state = {
        isFocused: false,
        isEmpty: true,
    };

    public handleFocus = () => {
        const { onFocus } = this.props;

        this.setState({ isFocused: true });
        if (onFocus) {
            onFocus();
        }
    };

    public handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isFocused: false });

        if (!event.target.value.length) {
            this.setState({
                isEmpty: true,
            });
        }
    };

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, field } = this.props;

        if (field) {
            onChange(event, field);
        } else {
            onChange(event);
        }

        if (event.target.value.length > 0) {
            this.setState({
                isEmpty: false,
            });
        } else {
            this.setState({
                isEmpty: true,
            });
        }
    };

    public renderLabel = () => {
        const { label, error } = this.props;

        return error ? (
            <div
                className={[
                    styles.Input__label,
                    error && styles.Input__label_error,
                ].join(' ')}
            >
                {error}
            </div>
        ) : (
            <div className={styles.Input__label}>{label}</div>
        );
    };

    public render() {
        const {
            type,
            value,
            error,
            icon,
            onIconClick,
            pattern,
            aligned,
            inputRef,
            style,
            inputStyle,
            placeholder,
        } = this.props;

        const { isEmpty, isFocused } = this.state;

        return (
            <>
                <div className={styles.Input}>
                    <div
                        style={style}
                        className={[
                            styles.Input__inputContainer,
                            isFocused
                                ? styles.Input__inputContainer_focused
                                : '',
                            isEmpty && value.length === 0
                                ? styles.Input__inputContainer_isEmpty
                                : '',
                            error && styles.Input__inputContainer_error,
                        ].join(' ')}
                    >
                        {this.renderLabel()}

                        <input
                            style={inputStyle}
                            ref={inputRef}
                            type={type}
                            className={[
                                styles.Input__input,
                                aligned &&
                                    styles.Input__input_leftCenterAligned,
                                icon && styles.Input__input_withIcon,
                            ].join(' ')}
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            value={value}
                            pattern={pattern}
                            placeholder={placeholder || ''}
                        />
                        {icon &&
                            (onIconClick ? (
                                <div
                                    className={styles.Input__iconContainer}
                                    onClick={onIconClick}
                                >
                                    {icon}
                                </div>
                            ) : (
                                <div className={styles.Input__iconContainer}>
                                    {icon}
                                </div>
                            ))}
                    </div>
                </div>
            </>
        );
    }
}

export default memo(Input);
