import { ColorOption } from 'interfaces/colors';
import { ExportedSubtitledVideoData } from 'interfaces/export';
import { MediaFile } from 'interfaces/videos';
import {
  ExportActionTypes,
  MetadataExportItem,
  SelectMultipleItemsToExportAction,
  SelectItemToExportAction,
  ToggleItemToExportAction,
  ToggleAllItemsToExportAction,
  RemoveAllItemsToExportAction,
  SetItemsToExportAction,
  ConfirmExportListUpdateAction,
  SetExportListAction,
  ClearExportListAction,
  ClearItemsToExportListAction,
  RemoveExportItemAction,
  AddSpecificSceneToExportAction,
  UnselectItemFromExportAction,
} from 'state/modules/export';
import { Scene, SelectedMetadataItem } from 'state/modules/videoExplorer';
import { 
  ExportSelectedMetadataAsVideoActionPayload, 
  ExportSelectedMetadataAsVideoAction, 
  ExportSelectedMetadataAsVideoStartAction,
  ExportSelectedMetadataAsVideoFailAction,
  ExportSelectedMetadataAsVideoSuccessAction,
  SetExportAsVideoParamsAction,
  ExportAsVideoParams,
  UpdateExportAsVideoParamsAction,
  ExportSubtitledVideoAction,
  ExportSubtitledVideoActionPayload,
  SetSubtitledVideoDataAction,
  GetSubtitledVideoDataAction,
  SetExportSubtitledVideoLoadingAction,
  SetSubtitledVideoDataLoadingAction,
  SyncVideoSubtitlingStatusAction,
  SetSubtitlesColorAction,
  SetSubtitlesFontSizeAction,
  SetVideosUsedForExportAction,
  AddVideoUsedForExportAction,
  AddCurrentVideoToVideosUsedForExportAction,
  CancelBurningsSubtitlesJobAction,
  CancelBurningsSubtitlesJobActionPayload,
  CancelBurningsSubtitlesJobStartAction,
  CancelBurningsSubtitlesJobSuccessAction,
  CancelBurningsSubtitlesJobFailAction,
} from './types';

export function selectMultipleItemsToExport(
  items: Array<SelectedMetadataItem>,
): SelectMultipleItemsToExportAction {
  return {
    type: ExportActionTypes.SELECT_MULTIPLE_ITEMS_TO_EXPORT,
    payload: items,
  };
}

export function selectItemToExport(
  item: SelectedMetadataItem,
): SelectItemToExportAction {
  return {
    type: ExportActionTypes.SELECT_ITEM_TO_EXPORT,
    payload: item,
  };
}

export function toggleItemToExport(
  item: SelectedMetadataItem,
): ToggleItemToExportAction {
  return {
    type: ExportActionTypes.TOGGLE_ITEM_TO_EXPORT,
    payload: item,
  };
}

export function unselectItemFromExport(
  item: SelectedMetadataItem,
): UnselectItemFromExportAction {
  return {
    type: ExportActionTypes.UNSELECT_ITEM_TO_EXPORT,
    payload: item,
  };
}

export function toggleAllItemsToExport(): ToggleAllItemsToExportAction {
  return {
    type: ExportActionTypes.TOGGLE_ALL_ITEMS_TO_EXPORT,
  };
}

export function removeAllItemsToExport(): RemoveAllItemsToExportAction {
  return {
    type: ExportActionTypes.REMOVE_ALL_ITEMS_TO_EXPORT,
  };
}

export function setItemsToExport(
  items: Array<SelectedMetadataItem>,
): SetItemsToExportAction {
  return {
    type: ExportActionTypes.SET_ITEMS_TO_EXPORT,
    payload: items,
  };
}

export function confirmExportListUpdate(): ConfirmExportListUpdateAction {
  return {
    type: ExportActionTypes.CONFIRM_EXPORT_LIST_UPADATE,
  };
}

export function setExportList(
  items: Array<MetadataExportItem>,
): SetExportListAction {
  return {
    type: ExportActionTypes.SET_EXPORT_LIST,
    payload: items,
  };
}

export function clearExportList(): ClearExportListAction {
  return {
    type: ExportActionTypes.CLEAR_EXPORT_LIST,
  };
}

export function clearItemsToExportList(): ClearItemsToExportListAction {
  return {
    type: ExportActionTypes.CLEAR_ITEMS_TO_EXPORT_LIST,
  };
}

export function removeExportItem(
  item: MetadataExportItem,
): RemoveExportItemAction {
  return {
    type: ExportActionTypes.REMOVE_EXPORT_ITEM,
    payload: item,
  };
}

export function addSpecificSceneToExport(
  scene: Scene,
): AddSpecificSceneToExportAction {
  return {
    type: ExportActionTypes.ADD_SPECIFIC_SCENE_TO_EXPORT,
    payload: scene,
  };
}

export function exportSelectedMetadataAsVideo(
  payload: ExportSelectedMetadataAsVideoActionPayload
): ExportSelectedMetadataAsVideoAction {
  return {
    type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO,
    payload,
  };
}

export function exportSelectedMetadataAsVideoStart(): ExportSelectedMetadataAsVideoStartAction {
  return {
    type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_START,
  };
}

export function exportSelectedMetadataAsVideoFail(): ExportSelectedMetadataAsVideoFailAction {
  return {
    type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_FAIL,
  };
}

export function exportSelectedMetadataAsVideoSuccess(): ExportSelectedMetadataAsVideoSuccessAction {
  return {
    type: ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_SUCCESS,
  };
}

export function setExportAsVideoParams(payload: ExportAsVideoParams): SetExportAsVideoParamsAction {
  return {
    type: ExportActionTypes.SET_EXPORT_AS_VIDEO_PARAMS,
    payload
  };
}

export function updateExportAsVideoParams(): UpdateExportAsVideoParamsAction {
  return {
    type: ExportActionTypes.UPDATE_EXPORT_AS_VIDEO_PARAMS,
  };
}

export function exportSubtitledVideo(payload: ExportSubtitledVideoActionPayload): ExportSubtitledVideoAction {
  return {
    type: ExportActionTypes.EXPORT_SUBTITLED_VIDEO,
    payload
  };
}

export function setSubtitledVideoData(
    payload: ExportedSubtitledVideoData | null
  ): SetSubtitledVideoDataAction {
  return {
    type: ExportActionTypes.SET_SUBTITLED_VIDEO_DATA,
    payload
  };
}

export function getSubtitledVideoData(
  ): GetSubtitledVideoDataAction {
  return {
    type: ExportActionTypes.GET_SUBTITLED_VIDEO_DATA,
  };
}

export function setExportSubtitledVideoLoading(
  payload: boolean,
): SetExportSubtitledVideoLoadingAction {
  return {
    type: ExportActionTypes.SET_EXPORT_SUBTITLED_VIDEO_LOADING,
    payload
  };
}

export function setSubtitledVideoDataLoading(
  payload: boolean,
): SetSubtitledVideoDataLoadingAction {
  return {
    type: ExportActionTypes.SET_SUBTITLED_VIDEO_DATA_LOADING,
    payload
  };
}

export function syncVideoSubtitlingStatus(): SyncVideoSubtitlingStatusAction {
  return {
    type: ExportActionTypes.SYNC_VIDEO_SUBTITLING_STATUS,
  };
}

export function setSubtitlesFontSize(
  payload: number,
): SetSubtitlesFontSizeAction {
  return {
    type: ExportActionTypes.SET_SUBTITLES_FONT_SIZE,
    payload
  };
}

export function setSubtitlesColor(
  payload: ColorOption
): SetSubtitlesColorAction {
  return {
    type: ExportActionTypes.SET_SUBTITLES_COLOR,
    payload
  };
}

export function addCurrentVideoToVideosUsedForExport(): AddCurrentVideoToVideosUsedForExportAction {
  return {
    type: ExportActionTypes.ADD_CURRENT_VIDEO_TO_VIDEOS_USED_FOR_EXPORT,
  };
}

export function addVideoUsedForExport(
  payload: MediaFile,
): AddVideoUsedForExportAction {
  return {
    type: ExportActionTypes.ADD_VIDEO_USED_FOR_EXPORT,
    payload
  };
}

export function setVideosUsedForExport(
  payload: MediaFile[],
): SetVideosUsedForExportAction {
  return {
    type: ExportActionTypes.SET_VIDEOS_USED_FOR_EXPORT,
    payload
  };
}

export function cancelBurningsSubtitlesJob(
  payload: CancelBurningsSubtitlesJobActionPayload
): CancelBurningsSubtitlesJobAction {
  return {
    type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB,
    payload
  };
}

export function cancelBurningsSubtitlesJobStart(): CancelBurningsSubtitlesJobStartAction {
  return {
    type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_START,
  };
}
export function cancelBurningsSubtitlesJobFail(): CancelBurningsSubtitlesJobFailAction {
  return {
    type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_FAIL,
  };
}
export function cancelBurningsSubtitlesJobSuccess(): CancelBurningsSubtitlesJobSuccessAction {
  return {
    type: ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_SUCCESS,
  };
}
