import { IconProps } from '../index';

const TextInBillboardIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 20.214c.7-.772 1.54-1.159 2.518-1.159.98 0 1.803.35 2.47 1.048.675.69 1.012 1.525 1.012 2.503a3.47 3.47 0 0 1-1.025 2.516c-.683.698-1.51 1.048-2.481 1.048-.963 0-1.794-.42-2.494-1.258v1.036h-1V16.7h1v3.514zm2.457 4.97c.7 0 1.292-.26 1.778-.777.493-.518.74-1.122.74-1.813 0-.699-.238-1.307-.716-1.825-.477-.518-1.074-.777-1.79-.777s-1.317.243-1.802.728c-.478.485-.716 1.097-.716 1.837 0 .732.242 1.352.728 1.862s1.078.764 1.778.764z"
                fill="#7C7C7D" />
            <path
                d="M35.895 24.136a4.423 4.423 0 0 1-1.801 1.529c-.734.35-1.53.525-2.392.525-1.339 0-2.46-.44-3.363-1.321C27.446 23.988 27 22.91 27 21.637c0-1.274.452-2.362 1.355-3.264.903-.913 2.072-1.37 3.507-1.37.85 0 1.631.186 2.343.558a4.138 4.138 0 0 1 1.69 1.56l-1.036.653c-.776-1.008-1.79-1.513-3.045-1.513-.988 0-1.817.319-2.487.956-.67.626-1.004 1.406-1.004 2.34 0 .934.33 1.736.988 2.404.66.669 1.494 1.003 2.503 1.003 1.212 0 2.232-.51 3.06-1.528l1.021.7z"
                fill="#CECECF" />
            <path
                d="M17.52 26.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H9L13.135 17h.31l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664z"
                fill="#525253" />
            <path d="M7.707 17.364h2.828M13.364 11.707v2.829M9.828 13.828l2 2" stroke="#7C7C7D" strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    );
};

export default TextInBillboardIcon;