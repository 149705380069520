import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 2.07;

const GeneralVideosFilter = ({ size, color }: IconProps): JSX.Element => {
  let height = 15;
  if (size) {
    height = size;
  }

  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg width={width} height={height} viewBox="0 0 29 14" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="14-Library-Home-Page"
          transform="translate(-551.000000, -285.000000)"
          fill={color}
        >
          <path
            d="M570.202703,295.972973 C570.85201,295.972973 571.378378,296.499341 571.378378,297.148649 C571.378378,297.797956 570.85201,298.324324 570.202703,298.324324 L560.797297,298.324324 C560.14799,298.324324 559.621622,297.797956 559.621622,297.148649 C559.621622,296.499341 560.14799,295.972973 560.797297,295.972973 L570.202703,295.972973 Z M574.121622,290.486486 C574.770929,290.486486 575.297297,291.012854 575.297297,291.662162 C575.297297,292.31147 574.770929,292.837838 574.121622,292.837838 L556.878378,292.837838 C556.229071,292.837838 555.702703,292.31147 555.702703,291.662162 C555.702703,291.012854 556.229071,290.486486 556.878378,290.486486 L574.121622,290.486486 Z M578.824324,285 C579.473632,285 580,285.526368 580,286.175676 C580,286.824983 579.473632,287.351351 578.824324,287.351351 L552.175676,287.351351 C551.526368,287.351351 551,286.824983 551,286.175676 C551,285.526368 551.526368,285 552.175676,285 L578.824324,285 Z"
            id="General-Filter"
          />
        </g>
      </g>
    </svg>
  );
};

export default GeneralVideosFilter;
