import { FormEvent, useState } from 'react';
import {
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { hideModal } from 'state/modules/modal';
import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';
import { PlanNameId, getCurrentUserPlan } from 'state/modules/payment';

const SubmitButton = styled.button<{ isLoading: boolean }>`
    width: 100%;
    border: 1px solid rgb(0, 152, 253);
    transition: border 0.2s ease-in-out 0s;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    height: 48px;
    padding: 0px 16px;
    min-width: 87px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    display: flex;
    justify-content: ${(props) =>
        props.isLoading ? 'center' : 'space-between'};
    background: rgb(0, 152, 253);
    font-family: 'Proxima Nova Medium', sans-serif;
    & span.price {
        font-size: 16px;
        font-family: 'Proxima Nova Semibold', sans-serif;
    }
`;

const CancelButton = styled.button`
    width: 100%;
    background: rgb(238, 238, 240);
    border: 1px solid rgb(238, 238, 240);
    transition: border 0.2s ease-in-out 0s;
    color: rgb(25, 32, 51);
    border-radius: 10px;
    height: 48px;
    padding: 0px 16px;
    display: flex;
    min-width: 87px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    font-family: 'Proxima Nova Medium', sans-serif;
`;

const ActionButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 15px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 30%;
    }
`;

const ErrorMessage = styled.div`
    margin-top: 6px;
    color: red;
`;

interface Props {
    price?: number;
    isEditMode?: boolean;
    urlParams?: string;
    handleClose: () => void;
    noPaymentMethod?: boolean;
}

export const PaymentForm = (props: Props): JSX.Element => {
    const {
        price,
        urlParams,
        handleClose,
        isEditMode,
        noPaymentMethod
    } = props;

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const erroCallback = (error: StripeError) => {
        if (
            (error.type === 'card_error' ||
                error.type === 'validation_error') &&
            error.message
        ) {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occured.');
        }
    };

    const setupIntentSuccessCallback = (setupIntent: SetupIntent) => {
        const url = `/video-editing?setup_intent=${setupIntent.id}&redirect_status=${setupIntent.status}&${urlParams}`;
        navigate(url);
        dispatch(hideModal());
    };

    const paymenyIntentSuccessCallback = (paymentIntent: PaymentIntent) => {
        const url = `/video-editing?payment_intent=${paymentIntent.id}&redirect_status=${paymentIntent.status}&${urlParams}`;
        navigate(url);
        dispatch(hideModal());
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const intentParams = {
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/video-editing?${urlParams}`,
            },
            redirect: 'if_required' as any,
        };

        if(isEditMode || noPaymentMethod) {
            // setup intent

            const { error: setupIntentError, setupIntent } =
                await stripe.confirmSetup(intentParams);

            if (setupIntentError) {
                erroCallback(setupIntentError);
            } else {
                setupIntentSuccessCallback(setupIntent);
            }
        } else {
            // payment intent

            const { error: paymentIntentError, paymentIntent } =
                await stripe.confirmPayment(intentParams);

            if (paymentIntentError) {
                erroCallback(paymentIntentError);
            } else if (paymentIntent) {
                paymenyIntentSuccessCallback(paymentIntent);
            }
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {message && <ErrorMessage>{message}</ErrorMessage>}
            <ActionButtons>
                <SubmitButton
                    type="submit"
                    disabled={isLoading || !stripe || !elements}
                    isLoading={isLoading}
                >
                    {isLoading ? (
                        <Loader
                            type="Oval"
                            color="#ffffff"
                            height={20}
                            width={20}
                        />
                    ) : (
                        <>
                            {isEditMode ? (
                                <span>Save</span>
                            ) : (
                                <>
                                    <span>Subscribe Now</span>{' '}
                                    <span className="price">{`${price} $(USD)`}</span>
                                </>
                            )}
                        </>
                    )}
                </SubmitButton>
                <CancelButton
                    type="button"
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    Cancel
                </CancelButton>
            </ActionButtons>
        </form>
    );
};
