import { useRef, useState, SyntheticEvent, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { Icon } from 'components/UI';

import {
    ModalEvent,
    ModalType,
    hideModal,
    showModal,
} from 'state/modules/modal';
import {
    getStockVideoIdWithUploadInProgress,
    uploadProjectStockVideo,
} from 'state/modules/projectUpload';

import { StockMedia } from 'interfaces/stockMedia';
import { createProjectWithStockVideo } from 'state/modules/projects';

const ActionButton = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    z-index: 2;
    box-shadow: rgba(255, 255, 255, 0.376) 0 0 0 2px,
        rgba(0, 0, 0, 0.06) 0 1px 1px;
    display: none;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    outline: none;

    &:hover {
        border: 0.5px solid rgb(103, 193, 255);
        box-shadow: rgba(255, 255, 255, 0.376) 0 0 0 2px,
            rgba(0, 0, 0, 0.06) 0 1px 1px;

        g {
            fill: rgb(103, 193, 255);
        }
    }
`;

const VideoContainer = styled.button`
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;

    &:hover ${ActionButton} {
        display: flex;
    }
`;

const Duration = styled.div`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.71875rem;
    letter-spacing: 0.01em;
    font-weight: 500;
    color: rgb(255, 255, 255);
    background: rgba(25, 32, 51, 0.75);
    border-radius: 4px;
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    padding: 0.25rem;
    line-height: 1;
    z-index: 2;
    pointer-events: none;
    display: flex;
`;

const Video = styled.video<{showVideo: boolean}>`
    display: ${({ showVideo }) => (showVideo ? 'block' : 'none')};
`;

const LoaderContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

interface Props {
    media: StockMedia;
    inModal?: boolean;
    inLibrary?: boolean;
}

const StockVideo = ({ media, inModal, inLibrary }: Props) => {
    const [duration, setDuration] = useState(0);
    const [showVideo, setShowVideo] = useState(false);

    const videoIdWithUploadInProgress = useSelector(
        getStockVideoIdWithUploadInProgress
    );

    const videoRef = useRef<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isUploadInProgress = useMemo(
        () => !!videoIdWithUploadInProgress,
        [videoIdWithUploadInProgress]
    );
    const isCurrentVideoUploadInProgress = useMemo(
        () => videoIdWithUploadInProgress === media.id,
        [media, videoIdWithUploadInProgress]
    );

    const handleMouseOver = () => {
        if (videoRef?.current && duration) {
            setShowVideo(true);
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        if (videoRef?.current && duration) {
            setShowVideo(false);
            videoRef.current.pause();
        }
    };

    const handleLoadMetadata = (
        event: SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setDuration((event.target as any).duration);
    };

    const handleShowModal = () => {
        if (inLibrary) {
            return dispatch(
                showModal(
                    ModalType.STOCK_VIDEO,
                    ModalEvent.CRATE_PROJECT_WITH_STOCK_VIDEO,
                    '',
                    media
                )
            );
        }
        return dispatch(
            showModal(ModalType.STOCK_VIDEO, ModalEvent.STOCK_VIDEO, '', media)
        );
    };

    const handleDownload = () => {
        if (inLibrary) {
            dispatch(createProjectWithStockVideo({ video: media, navigate }));
        } else {
            if (inModal) {
                dispatch(hideModal());
            }

            dispatch(
                uploadProjectStockVideo({
                    media,
                })
            );
        }
    };

    const renderDuration = () => {
        return duration ? (
            <Duration>
                {moment.duration(duration, 'seconds').format('mm:ss', {
                    trim: false,
                })}
            </Duration>
        ) : null;
    };

    const renderAction = () => {
        return !isUploadInProgress ? (
            <ActionButton onClick={handleShowModal}>
                <Icon name="options" size={10} />
            </ActionButton>
        ) : null;
    };

    const renderLoader = () => {
        return isCurrentVideoUploadInProgress ? (
            <LoaderContainer>
                <Loader
                    type="Oval"
                    color="#fff"
                    secondaryColor="rgba(255, 255, 255, 0.5)"
                    height={32}
                    width={32}
                />
            </LoaderContainer>
        ) : null;
    };

    const renderVideoItem = () => {
        return (
            <Container
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
            >
                <VideoContainer
                    disabled={isUploadInProgress}
                    onClick={handleDownload}
                >
                    {
                        !showVideo && (
                            <img
                                src={media?.display_sizes?.[3]?.uri || ''}
                                alt={media?.caption || ''}
                                style={{ width: '100%', height: '100%' }}
                            />
                        )
                    }
                    <Video
                        ref={videoRef}
                        src={media.display_sizes[2].uri}
                        onLoadedMetadata={handleLoadMetadata}
                        controls={false}
                        preload="metadata"
                        loop
                        showVideo={showVideo}
                    />
                </VideoContainer>
                {renderDuration()}
                {renderAction()}
                {renderLoader()}
            </Container>
        );
    };

    return (
       <>
           {renderVideoItem()}
       </>
    );
};

export default StockVideo;
