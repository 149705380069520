import { ProjectTextDefaultSettings } from 'interfaces/preferences';
import {
    AudioFadeProps,
    AudioFadeType,
    CleanAudioGenerationMediaStatuses,
    Element,
    NewRawProject,
    Project,
    ProjectAudio,
    ProjectAudioMediaSource,
    ProjectElement,
    ProjectImage,
    ProjectMediaAudioOptions,
    ProjectSort,
    ProjectText,
    ProjectUpload,
    ProjectVideo,
    ProjectViewMode,
    TemplateStatus,
    TextAnimationTabType,
    TextAnimationType,
    UploadDetails,
    UploadsUpdateData,
    VideoFilter,
    VideoTransition,
} from 'interfaces/projects';
import { StockMedia } from 'interfaces/stockMedia';
import { UploadingMedia } from 'interfaces/uploading';
import { MediaFile, TempMediaFile } from 'interfaces/videos';
import { SelectedProjectObject } from '../projectsStudio';

export interface ProjectsState {
    projectsList: Project[];
    projectsTotal: number;
    isProjectsLoading: boolean;
    projectDetails: Project | null;
    lastProjectGeneration: Project | null;
    projectVideos: ProjectVideo[];
    projectAudios: ProjectAudio[];
    projectMediaSources: MediaFile[];
    projectAudioMediaSources: ProjectAudioMediaSource[];
    projectTempMediaSources: TempMediaFile[];
    projectTempMediaSourcesPreviews: any[];
    isProjectDetailsLoading: boolean;
    isProjectCreationLoading: boolean;
    isProjectUpdateLoading: boolean;
    projectsIdsForDelete: string[];
    isProjectGenerationLoading: boolean;
    projectUpdatesHistory: Project[];
    getProjectLatestVersionLoading: boolean;

    transitions: VideoTransition[];
    projectsSort: ProjectSort;
    elementsList: Element[];
    filters: VideoFilter[];

    viewMode: ProjectViewMode;

    isTextToSpeechGenerationLoading: boolean;
    projectAudioLanguagesOptions: ProjectMediaAudioOptions;

    projectUploads: ProjectUpload[],
    getProjectUploadsSuccess: boolean,

    mediasWithCleanAudioGeneration: CleanAudioGenerationMediaStatuses[];
    isChangeTextAnimationLoading: boolean;

    templateWorkspaceId: string | null;

    submittedTemplatesList: Project[],
    submittedTemplatesTotal: number,
    isSubmittedTemplatesLoading: boolean,

    publishedTemplatesList: Project[],
    publishedTemplatesTotal: number,
    isPublishedTemplatesLoading: boolean,

    usableTemplatesList: Project[],
    usableTemplatesTotal: number,
    isUsableTemplatesLoading: boolean,

    useTemplateLoading: boolean,

    projectAudiosIdsWithDubbingGeneration: string[];
}

export enum ProjectsActionTypes {
    GET_PROJECTS = '@@projects/GET_PROJECTS',
    GET_PROJECTS_START = '@@projects/GET_PROJECTS_START',
    GET_PROJECTS_FAIL = '@@projects/GET_PROJECTS_FAIL',
    GET_PROJECTS_SUCCESS = '@@projects/GET_PROJECTS_SUCCESS',
    SET_PROJECTS = '@@projects/SET_PROJECTS',
    SYNC_PROJECTS = '@@projects/SYNC_PROJECTS',

    GET_PROJECT_DETAILS = '@@projects/GET_PROJECT_DETAILS',
    GET_PROJECT_DETAILS_START = '@@projects/GET_PROJECT_DETAILS_START',
    GET_PROJECT_DETAILS_FAIL = '@@projects/GET_PROJECT_DETAILS_FAIL',
    GET_PROJECT_DETAILS_SUCCESS = '@@projects/GET_PROJECT_DETAILS_SUCCESS',
    SET_PROJECT_DETAILS = '@@projects/SET_PROJECT_DETAILS',
    CLEAR_PROJECT_DETAILS = '@@projects/CLEAR_PROJECT_DETAILS',
    SYNC_PROJECT_DETAILS = '@@projects/SYNC_PROJECT_DETAILS',
    GET_PROJECT_LATEST_VERSION_START = '@@projects/GET_PROJECT_LATEST_VERSION_START',
    GET_PROJECT_LATEST_VERSION_END = '@@projects/GET_PROJECT_LATEST_VERSION_END',

    SET_PROJECT_LAST_GENERATION = '@@projects/SET_PROJECT_LAST_GENERATION',

    SET_PROJECT_VIDEOS = '@@projects/SET_PROJECT_VIDEOS',

    SET_PROJECT_MEDIA_SOURCES = '@@projects/SET_PROJECT_MEDIA_SOURCES',

    SET_PROJECT_AUDIO_MEDIA_SOURCES = '@@projects/SET_PROJECT_AUDIO_MEDIA_SOURCES',

    SET_PROJECT_TEMP_MEDIA_SOURCES = '@@projects/SET_PROJECT_TEMP_MEDIA_SOURCES',
    SET_PROJECT_TEMP_MEDIA_SOURCES_PREVIEWS = '@@projects/SET_PROJECT_TEMP_MEDIA_SOURCES_PREVIEWS',

    SYNC_PROJECT_MEDIA_SOURCES = '@@projects/SYNC_PROJECT_MEDIA_SOURCES',

    CREATE_DEFAULT_PROJECT = '@@projects/CREATE_DEFAULT_PROJECT',

    CREATE_PROJECT = '@@projects/CREATE_PROJECT',
    CREATE_PROJECT_START = '@@projects/CREATE_PROJECT_START',
    CREATE_PROJECT_FAIL = '@@projects/CREATE_PROJECT_FAIL',
    CREATE_PROJECT_SUCCESS = '@@projects/CREATE_PROJECT_SUCCESS',

    UPDATE_PROJECT_VIDEO = '@@projects/UPDATE_PROJECT_VIDEO',
    UPDATE_PROJECT_VIDEO_START = '@@projects/UPDATE_PROJECT_VIDEO_START',
    UPDATE_PROJECT_VIDEO_FAIL = '@@projects/UPDATE_PROJECT_VIDEO_FAIL',

    UPDATE_PROJECT_VIDEOS = '@@projects/UPDATE_PROJECT_VIDEOS',

    DELETE_PROJECT_VIDEO = '@@projects/DELETE_PROJECT_VIDEO',
    DELETE_PROJECT_VIDEO_START = '@@projects/DELETE_PROJECT_VIDEO_START',
    DELETE_PROJECT_VIDEO_FAIL = '@@projects/DELETE_PROJECT_VIDEO_FAIL',
    DELETE_PROJECT_VIDEO_SUCCESS = '@@projects/DELETE_PROJECT_VIDEO_SUCCESS',

    UPDATE_PROJECT = '@@projects/UPDATE_PROJECT',
    UPDATE_PROJECT_START = '@@projects/UPDATE_PROJECT_START',
    UPDATE_PROJECT_FAIL = '@@projects/UPDATE_PROJECT_FAIL',
    UPDATE_PROJECT_SUCCESS = '@@projects/UPDATE_PROJECT_SUCCESS',
    SET_PROJECT_UPDATEDS_HISTORY = '@@projects/SET_PROJECT_UPDATEDS_HISTORY',

    GENERATE_PROJECT = '@@projects/GENERATE_PROJECT',
    GENERATE_PROJECT_START = '@@projects/GENERATE_PROJECT_START',
    GENERATE_PROJECT_FAIL = '@@projects/GENERATE_PROJECT_FAIL',
    GENERATE_PROJECT_SUCCESS = '@@projects/GENERATE_PROJECT_SUCCESS',

    DELETE_PROJECT = '@@projects/DELETE_PROJECT',
    DELETE_PROJECT_START = '@@projects/DELETE_PROJECT_START',
    DELETE_PROJECT_FAIL = '@@projects/DELETE_PROJECT_FAIL',
    DELETE_PROJECT_SUCCESS = '@@projects/DELETE_PROJECT_SUCCESS',
    SET_PROJECTS_IDS_FOR_DELETE = '@@projects/SET_PROJECTS_IDS_FOR_DELETE',

    CREATE_PROJECT_FROM_UPLOAD = '@@projects/CREATE_PROJECT_FROM_UPLOAD',

    CREATE_PROJECT_TEMP_VIDEO_PREVIEWS = '@@projects/CREATE_PROJECT_TEMP_VIDEO_PREVIEWS',

    ADD_VIDEOS_FROM_LIBRARY_TO_PROJECT = '@@projects/ADD_VIDEOS_FROM_LIBRARY_TO_PROJECT',

    ADD_PROJECT_TEMP_MEDIA_FILE_PREVIEW = '@@projects/ADD_PROJECT_TEMP_MEDIA_FILE_PREVIEW',

    GO_BY_PROJECT_HISTORY = '@@projects/GO_BY_PROJECT_HISTORY',

    UPDATE_PROJECT_IMAGE = '@@projects/UPDATE_PROJECT_IMAGE',
    DELETE_PROJECT_IMAGE = '@@projects/DELETE_PROJECT_IMAGE',

    DETACH_AUDIO_FROM_PROJECT_VIDEO = '@@projects/DETACH_AUDIO_FROM_PROJECT_VIDEO',
    DETACH_AUDIO_FROM_PROJECT_VIDEO_START = '@@projects/DETACH_AUDIO_FROM_PROJECT_VIDEO_START',
    DETACH_AUDIO_FROM_PROJECT_VIDEO_SUCCESS = '@@projects/DETACH_AUDIO_FROM_PROJECT_VIDEO_SUCCESS',
    DETACH_AUDIO_FROM_PROJECT_VIDEO_FAIL = '@@projects/DETACH_AUDIO_FROM_PROJECT_VIDEO_FAIL',

    SET_PROJECT_AUDIOS = '@@projects/SET_PROJECT_AUDIOS',
    UPDATE_PROJECT_AUDIO = '@@projects/UPDATE_PROJECT_AUDIO',
    UPDATE_PROJECT_AUDIOS = '@@projects/UPDATE_PROJECT_AUDIOS',

    DELETE_PROJECT_AUDIO = '@@projects/DELETE_PROJECT_AUDIO',

    CREATE_PROJECT_TEXT = '@@projects/CREATE_PROJECT_TEXT',
    DELETE_PROJECT_TEXT = '@@projects/DELETE_PROJECT_TEXT',
    UPDATE_PROJECT_TEXT = '@@projects/UPDATE_PROJECT_TEXT',

    SET_PROJECT_VIDEOS_TRANSITIONS = '@@projects/SET_PROJECT_VIDEOS_TRANSITIONS',
    UPDATE_PROJECT_VIDEO_TRANSITION = '@@projects/UPDATE_PROJECT_VIDEO_TRANSITION',

    SET_PROJECT_VIDEOS_FILTERS = '@@projects/SET_PROJECT_VIDEOS_FILTERS',
    UPDATE_PROJECT_VIDEO_FILTER = '@@projects/UPDATE_PROJECT_VIDEO_FILTER',

    UPDATE_PROJECT_PREFERENCES = '@@projects/UPDATE_PROJECT_PREFERENCES',
    RESET_PROJECT_TEXT_SETTINGS = '@@projects/RESET_PROJECT_TEXT_SETTINGS',

    SET_PROJECTS_SORTING = '@@projects/SET_PROJECTS_SORTING',

    GET_ELEMENTS_LIST = '@@projects/GET_ELEMENTS_LIST',
    GET_ELEMENTS_LIST_START = '@@projects/GET_ELEMENTS_LIST_START',
    GET_ELEMENTS_LIST_FAIL = '@@projects/GET_ELEMENTS_LIST_FAIL',
    SET_ELEMENTS_LIST = '@@projects/SET_ELEMENTS_LIST',

    CREATE_PROJECT_ELEMENT = '@@projects/CREATE_PROJECT_ELEMENT',
    UPDATE_PROJECT_ELEMENT = '@@projects/UPDATE_PROJECT_ELEMENT',
    DELETE_PROJECT_ELEMENT = '@@projects/DELETE_PROJECT_ELEMENT',

    SET_VIEW_MODE = '@@projects/SET_VIEW_MODE',

    GENERATE_TEXT_TO_SPEECH = '@@projects/GENERATE_TEXT_TO_SPEECH',
    GENERATE_TEXT_TO_SPEECH_START = '@@projects/GENERATE_TEXT_TO_SPEECH_START',
    GENERATE_TEXT_TO_SPEECH_SUCCESS = '@@projects/GENERATE_TEXT_TO_SPEECH_SUCCESS',
    GENERATE_TEXT_TO_SPEECH_FAIL = '@@projects/GENERATE_TEXT_TO_SPEECH_FAIL',

    DUPLICATE_PROJECT = '@@projects/DUPLICATE_PROJECT',
    CREATE_PROJECT_WITH_STOCK_VIDEO = '@@projects/CREATE_PROJECT_WITH_STOCK_VIDEO',

    GET_MEDIA_AUDIO_OPTIONS = '@@projects/GET_MEDIA_AUDIO_OPTIONS',
    SET_PROJECT_AUDIO_OPTIONS = '@@projects/SET_PROJECT_AUDIO_OPTIONS',
    DELETE_PROJECT_DUBBING_LANGUAGE = '@@projects/DELETE_PROJECT_DUBBING_LANGUAGE',
    CHANGE_AUDIO_LANGUAGE = '@@projects/CHANGE_AUDIO_LANGUAGE',

    GET_PROJECT_UPLOADS = '@@projects/GET_PROJECT_UPLOADS',
    CREATE_PROJECT_UPLOAD = '@@projects/CREATE_PROJECT_UPLOAD',
    UPDATE_PROJECT_UPLOAD = '@@projects/UPDATE_PROJECT_UPLOAD',
    DELETE_PROJECT_UPLOAD = '@@projects/DELETE_PROJECT_UPLOAD',
    FIND_PROJECT_UPLOAD_BY_ID = '@@projects/FIND_PROJECT_UPLOAD_BY_ID',
    SET_PROJECT_UPLOADS = '@@projects/SET_PROJECT_UPLOADS',

    GENERATE_CLEAN_AUDIO = '@@projects/GENERATE_CLEAN_AUDIO',
    SET_MEDIAS_WITH_CLEAN_AUDIO_GENERATION = '@@projects/SET_MEDIAS_WITH_CLEAN_AUDIO_GENERATION',
    CHANGE_ENHANCED_AUDIO = '@@projects/CHANGE_ENHANCED_AUDIO',

    SUBMIT_TEMPLATE = '@@projects/SUBMIT_TEMPLATE',
    REVIEW_TEMPLATE = '@@projects/REVIEW_TEMPLATE',
    PUBLISH_TEMPLATE = '@@projects/PUBLISH_TEMPLATE',
    DELETE_TEMPLATE = '@@projects/DELETE_TEMPLATE',
    UPDATE_TEMPLATE = '@@projects/UPDATE_TEMPLATE',

    GET_SUBMITTED_TEMPLATES = '@@projects/GET_SUBMITTED_TEMPLATES',
    GET_SUBMITTED_TEMPLATES_START = '@@projects/GET_SUBMITTED_TEMPLATES_START',
    GET_SUBMITTED_TEMPLATES_FAIL = '@@projects/GET_SUBMITTED_TEMPLATES_FAIL',
    GET_SUBMITTED_TEMPLATES_SUCCESS = '@@projects/GET_SUBMITTED_TEMPLATES_SUCCESS',
    SET_SUBMITTED_TEMPLATES = '@@projects/SET_SUBMITTED_TEMPLATES',
    SYNC_SUBMITTED_TEMPLATES = '@@projects/SYNC_SUBMITTED_TEMPLATES',

    SET_TEMPLATE_WORKSPACE_ID = '@@projects/SET_TEMPLATE_WORKSPACE_ID',
    GET_PUBLISHED_TEMPLATES = '@@projects/GET_PUBLISHED_TEMPLATES',
    GET_PUBLISHED_TEMPLATES_START = '@@projects/GET_PUBLISHED_TEMPLATES_START',
    GET_PUBLISHED_TEMPLATES_FAIL = '@@projects/GET_PUBLISHED_TEMPLATES_FAIL',
    GET_PUBLISHED_TEMPLATES_SUCCESS = '@@projects/GET_PUBLISHED_TEMPLATES_SUCCESS',
    SET_PUBLISHED_TEMPLATES = '@@projects/SET_PUBLISHED_TEMPLATES',
    SYNC_PUBLISHED_TEMPLATES = '@@projects/SYNC_PUBLISHED_TEMPLATES',

    GET_USABLE_TEMPLATES = '@@projects/GET_USABLE_TEMPLATES',
    GET_USABLE_TEMPLATES_START = '@@projects/GET_USABLE_TEMPLATES_START',
    GET_USABLE_TEMPLATES_FAIL = '@@projects/GET_USABLE_TEMPLATES_FAIL',
    GET_USABLE_TEMPLATES_SUCCESS = '@@projects/GET_USABLE_TEMPLATES_SUCCESS',
    SET_USABLE_TEMPLATES = '@@projects/SET_USABLE_TEMPLATES',
    USE_TEMPLATE = '@@projects/USE_TEMPLATE',

    CHANGE_TEXT_ANIMATION = '@@projects/CHANGE_TEXT_ANIMATION',
    REPLACE_TIMELINE_MEDIA = '@@projects/REPLACE_TIMELINE_MEDIA',

    SET_PROJECT_AUDIOS_IDS_WITH_DUBBING_GENERATION = '@@projects/SET_PROJECT_AUDIOS_IDS_WITH_DUBBING_GENERATION', 
    GENERATE_PROJECT_AUDIO_DUBBING = '@@projects/GENERATE_PROJECT_AUDIO_DUBBING',

    CHANGE_AUDIO_FADE = '@@projects/GENERATE_PROJECT_AUDIO_DUBBING',
    CHANGE_AUDIO_VOLUME = '@@projects/CHANGE_AUDIO_VOLUME',

    DELETE_SELECTED_ITEMS = '@@projects/DELETE_SELECTED_ITEMS',
}

export interface GetProjectsActionPayload {
    isLoadMore?: boolean;
    folderId?: string;
    isTemplate?: boolean;
}

export interface GetProjectsAction {
    type: ProjectsActionTypes.GET_PROJECTS;
    payload?: GetProjectsActionPayload;
}

export interface GetProjectsStartAction {
    type: ProjectsActionTypes.GET_PROJECTS_START;
}

export interface GetProjectsSuccessActionPayload {
    projects: Project[];
    projectsTotal: number;
}

export interface GetProjectsSuccessAction {
    type: ProjectsActionTypes.GET_PROJECTS_SUCCESS;
    payload: GetProjectsSuccessActionPayload;
}

export interface GetProjectsFailAction {
    type: ProjectsActionTypes.GET_PROJECTS_FAIL;
}

export interface SetProjectsAction {
    type: ProjectsActionTypes.SET_PROJECTS;
    payload: Project[];
}

export interface SyncProjectsAction {
    type: ProjectsActionTypes.SYNC_PROJECTS;
}

/// Project Details //////////////////////////////

export interface GetProjectDetailsAction {
    type: ProjectsActionTypes.GET_PROJECT_DETAILS;
    payload: string;
}

export interface GetProjectDetailsStartAction {
    type: ProjectsActionTypes.GET_PROJECT_DETAILS_START;
}

export interface GetProjectDetailsFailAction {
    type: ProjectsActionTypes.GET_PROJECT_DETAILS_FAIL;
}

export interface GetProjectDetailsSuccessAction {
    type: ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS;
    payload: Project;
}

export interface SetProjectDetailsAction {
    type: ProjectsActionTypes.SET_PROJECT_DETAILS;
    payload: Project;
}

export interface ClearProjectDetailsAction {
    type: ProjectsActionTypes.CLEAR_PROJECT_DETAILS;
}

export interface SyncProjectDetailsAction {
    type: ProjectsActionTypes.SYNC_PROJECT_DETAILS;
}

export interface GetProjectLatestVersionStartAction {
    type: ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_START;
}

export interface GetProjectLatestVersionEndAction {
    type: ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_END;
}

export interface SetProjectMediaSourcesAction {
    type: ProjectsActionTypes.SET_PROJECT_MEDIA_SOURCES;
    payload: MediaFile[];
}

/// ////////////////////////////////////////////////

export interface SetProjectTempMediaSourcesAction {
    type: ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES;
    payload: TempMediaFile[];
}

/// Project Videos ///////////////////////////////

export interface SetProjectVideosAction {
    type: ProjectsActionTypes.SET_PROJECT_VIDEOS;
    payload: ProjectVideo[];
}

/// ///////////////////////////////

export interface CreateProjectAction {
    type: ProjectsActionTypes.CREATE_PROJECT;
    payload: NewRawProject;
}

export interface CreateProjectStartAction {
    type: ProjectsActionTypes.CREATE_PROJECT_START;
}

export interface CreateProjectFailAction {
    type: ProjectsActionTypes.CREATE_PROJECT_FAIL;
}

export interface CreateProjectSuccessAction {
    type: ProjectsActionTypes.CREATE_PROJECT_SUCCESS;
}

/// Update Project /////////////////////////////

export interface UpdateProjectActionPayload {
    project: Project;
    skipVersionUpdate?: boolean;
    checkMediaSources?: boolean;
    isUseTemplate?: boolean;
    getSubtitles?: boolean;
    isVersionChange?: boolean;
    isChangeLanguage?: boolean;
}

export interface UpdateProjectAction {
    type: ProjectsActionTypes.UPDATE_PROJECT;
    payload: UpdateProjectActionPayload;
}

export interface UpdateProjectStartAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_START;
}

export interface UpdateProjectFailAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_FAIL;
}

export interface UpdateProjectSuccessAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_SUCCESS;
}

/// /////////////////////////////

export interface DeleteProjectAction {
    type: ProjectsActionTypes.DELETE_PROJECT;
    payload: string;
}

export interface DeleteProjectStartAction {
    type: ProjectsActionTypes.DELETE_PROJECT_START;
}

export interface DeleteProjectFailAction {
    type: ProjectsActionTypes.DELETE_PROJECT_FAIL;
}

export interface DeleteProjectSuccessAction {
    type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS;
}

export interface SetProjectsIdsForDeleteAction {
    type: ProjectsActionTypes.SET_PROJECTS_IDS_FOR_DELETE;
    payload: string[];
}

// generate

export interface GenerateProjectActionPayload {
    navigate: any;
}

export interface GenerateProjectAction {
    type: ProjectsActionTypes.GENERATE_PROJECT;
    payload: GenerateProjectActionPayload;
}

export interface GenerateProjectStartAction {
    type: ProjectsActionTypes.GENERATE_PROJECT_START;
}

export interface GenerateProjectFailAction {
    type: ProjectsActionTypes.GENERATE_PROJECT_FAIL;
}

export interface GenerateProjectSuccessAction {
    type: ProjectsActionTypes.GENERATE_PROJECT_SUCCESS;
}

// Delete Project video
export interface DeleteProjectVideoAction {
    type: ProjectsActionTypes.DELETE_PROJECT_VIDEO;
    payload: string[];
}

export interface DeleteProjectVideoStartAction {
    type: ProjectsActionTypes.DELETE_PROJECT_VIDEO_START;
}

export interface DeleteProjectVideoFailAction {
    type: ProjectsActionTypes.DELETE_PROJECT_VIDEO_FAIL;
}

export interface DeleteProjectVideoSuccessAction {
    type: ProjectsActionTypes.DELETE_PROJECT_VIDEO_SUCCESS;
}

// Create Project from upload

export interface CreateProjectFromUploadActionPayload {
    videos: UploadingMedia[];
    navigate: any;
}
export interface CreateProjectFromUploadAction {
    type: ProjectsActionTypes.CREATE_PROJECT_FROM_UPLOAD;
    payload: CreateProjectFromUploadActionPayload;
}

//
export interface CreateDefaultProjectActionPayload {
    navigate: any;
    folderId?: string;
    isStock?: boolean;
    isTemplate?: boolean;
    isFirstLogin?: boolean;
}
export interface CreateDefaultProjectAction {
    type: ProjectsActionTypes.CREATE_DEFAULT_PROJECT;
    payload: CreateDefaultProjectActionPayload;
}
//
export interface SyncProjectMediaSourcesAction {
    type: ProjectsActionTypes.SYNC_PROJECT_MEDIA_SOURCES;
}

// //

export interface CreateProjectTempVideoPreviewsAction {
    type: ProjectsActionTypes.CREATE_PROJECT_TEMP_VIDEO_PREVIEWS;
}
export interface SetProjectTempMediaSourcesPreviewsAction {
    type: ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES_PREVIEWS;
    payload: any[];
}

export interface AddVideosFromLibraryToProjectActionPayload {
    newVideos: MediaFile[],
    isFromUploads?: boolean,
    replaceableMedia?: ProjectVideo,
}

export interface AddVideosFromLibraryToProjectAction {
    type: ProjectsActionTypes.ADD_VIDEOS_FROM_LIBRARY_TO_PROJECT;
    payload: AddVideosFromLibraryToProjectActionPayload;
}

export interface AddTempMediaFilePreviewPayload {
    id: string;
    image: Blob;
    timestamp: number;
}

export interface AddTempMediaFilePreviewAction {
    type: ProjectsActionTypes.ADD_PROJECT_TEMP_MEDIA_FILE_PREVIEW;
    payload: AddTempMediaFilePreviewPayload;
}

export interface GoByProjectHistoryAction {
    type: ProjectsActionTypes.GO_BY_PROJECT_HISTORY;
    payload: 'back' | 'forward';
}

export interface SetProjectUpdatesHistoryAction {
    type: ProjectsActionTypes.SET_PROJECT_UPDATEDS_HISTORY;
    payload: Project[];
}

// Project image //
export interface UpdateProjectImageAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_IMAGE;
    payload: ProjectImage;
}

//

export interface SetProjectAudioMediaSourceAction {
    type: ProjectsActionTypes.SET_PROJECT_AUDIO_MEDIA_SOURCES;
    payload: ProjectAudioMediaSource[];
}

// audio

export interface DetouchAudioFromVideoAction {
    type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO;
    payload: ProjectVideo;
}

export interface DetouchAudioFromVideoStartAction {
    type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_START;
}

export interface DetouchAudioFromVideoFailAction {
    type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_FAIL;
}

export interface DetouchAudioFromVideoSuccessAction {
    type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_SUCCESS;
}

export interface SetProjectAudiosAction {
    type: ProjectsActionTypes.SET_PROJECT_AUDIOS;
    payload: ProjectAudio[];
}

export interface UpdateProjectAudioAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_AUDIO;
    payload: ProjectAudio;
}

export interface UpdateProjectAudiosAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_AUDIOS;
    payload: ProjectAudio[];
}

export interface DeleteProjectAudioAction {
    type: ProjectsActionTypes.DELETE_PROJECT_AUDIO;
    payload: string[];
}

export interface CreateProjectTextAction {
    type: ProjectsActionTypes.CREATE_PROJECT_TEXT;
    payload: ProjectText;
}
export interface DeleteProjectTextAction {
    type: ProjectsActionTypes.DELETE_PROJECT_TEXT;
    payload: string[];
}
export interface UpdateProjectTextAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_TEXT;
    payload: ProjectText;
}

export interface DeleteProjectImageAction {
    type: ProjectsActionTypes.DELETE_PROJECT_IMAGE;
    payload: string[];
}

export interface SetProjectVideosTransitionsAction {
    type: ProjectsActionTypes.SET_PROJECT_VIDEOS_TRANSITIONS;
    payload: VideoTransition[];
}

export interface UpdateProjectVideoTransitionAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO_TRANSITION;
    payload: VideoTransition;
}

export interface SetProjectVideosFiltersAction {
    type: ProjectsActionTypes.SET_PROJECT_VIDEOS_FILTERS;
    payload: VideoFilter[];
}

export interface UpdateProjectVideoFilterAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO_FILTER;
    payload: VideoFilter;
}

export interface UpdateProjectVideoAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO;
    payload: ProjectVideo;
}

export interface UpdateProjectVideosAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_VIDEOS;
    payload: ProjectVideo[];
}

export interface UpdateProjectPreferencesActionPayload {
    textSettings?: ProjectTextDefaultSettings;
}
export interface UpdateProjectPreferencesAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_PREFERENCES;
    payload: UpdateProjectPreferencesActionPayload;
}

export interface ResetProjectTextSettingsAction {
    type: ProjectsActionTypes.RESET_PROJECT_TEXT_SETTINGS;
}

export interface SetProjectSortAction {
    type: ProjectsActionTypes.SET_PROJECTS_SORTING;
    payload: ProjectSort;
}

export interface GetElementsListAction {
    type: ProjectsActionTypes.GET_ELEMENTS_LIST;
}

export interface GetElementsListStartAction {
    type: ProjectsActionTypes.GET_ELEMENTS_LIST_START;
}

export interface GetElementsListFailAction {
    type: ProjectsActionTypes.GET_ELEMENTS_LIST_FAIL;
}

export interface SetElementsListAction {
    type: ProjectsActionTypes.SET_ELEMENTS_LIST;
    payload: Element[];
}

export interface CreateProjectElementAction {
    type: ProjectsActionTypes.CREATE_PROJECT_ELEMENT;
    payload: Element;
}

export interface UpdateProjectElementAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_ELEMENT;
    payload: ProjectElement;
}

export interface DeleteProjectElementAction {
    type: ProjectsActionTypes.DELETE_PROJECT_ELEMENT;
    payload: string[];
}

export interface SetProjectsViewModeAction {
    type: ProjectsActionTypes.SET_VIEW_MODE;
    payload: ProjectViewMode;
}

// Last generation

export interface SetProjectLastGenerationAction {
    type: ProjectsActionTypes.SET_PROJECT_LAST_GENERATION;
    payload: Project | null;
}

// Text to Speech
export interface GenerateTextToSpeechActionPayload {
    text: string;
    voiceId: string;
    language: string;
    voiceStyle?: string;
    rate: string;
}

export interface GenerateTextToSpeechAction {
    type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH;
    payload: GenerateTextToSpeechActionPayload;
}

export interface GenerateTextToSpeechStartAction {
    type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_START;
}

export interface GenerateTextToSpeechSuccessAction {
    type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_SUCCESS;
}

export interface GenerateTextToSpeechFailAction {
    type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_FAIL;
    payload?: any;
}

// Duplicate

export interface DuplicateProjectAction {
    type: ProjectsActionTypes.DUPLICATE_PROJECT;
    payload: Project;
}

// Create with stock media

export interface CreateProjectWithStockVideoActionPayload {
    navigate: any;
    video: StockMedia;
}
export interface CreateProjectWithStockVideoAction {
    type: ProjectsActionTypes.CREATE_PROJECT_WITH_STOCK_VIDEO;
    payload: CreateProjectWithStockVideoActionPayload;
}

export interface GetMediaAudioTracksOptionsPayload {
    mediaItemsIds:  string[];
}

export interface GetMediaAudioTracksOptionsAction {
    type: ProjectsActionTypes.GET_MEDIA_AUDIO_OPTIONS;
    payload: GetMediaAudioTracksOptionsPayload;
}

export interface SetProjectAudioOptionsAction {
    type: ProjectsActionTypes.SET_PROJECT_AUDIO_OPTIONS;
    payload: ProjectMediaAudioOptions;
}

export interface DeleteProjectDubbingLanguagePayload {
    videoId: string;
    languages: string;
}

export interface DeleteProjectDubbingLanguageAction {
    type: ProjectsActionTypes.DELETE_PROJECT_DUBBING_LANGUAGE;
    payload: DeleteProjectDubbingLanguagePayload;
}

export interface ChangeAudioLanguagePayload {
    audioLanguage: string,
    videoId: string,
}

export interface ChangeAudioLanguageAction {
    type: ProjectsActionTypes.CHANGE_AUDIO_LANGUAGE;
    payload: ChangeAudioLanguagePayload;
}

// Uploads

export interface GetProjectUploadsActionPayload {
    projectId?: string,
}

export interface GetProjectUploadsAction {
    type: ProjectsActionTypes.GET_PROJECT_UPLOADS,
    payload?: GetProjectUploadsActionPayload,
}

export interface SetProjectUploadsAction {
    type: ProjectsActionTypes.SET_PROJECT_UPLOADS,
    payload: ProjectUpload[],
}

export interface CreateProjectUploadActionPayload {
    projectId?: string,
    uploadMedias: UploadDetails[];
}

export interface CreateProjectUploadAction {
    type: ProjectsActionTypes.CREATE_PROJECT_UPLOAD,
    payload: CreateProjectUploadActionPayload
}

export interface FindProjectUploadByIdActionPayload {
    projectId: string,
    uploadId: string,
}

export interface FindProjectUploadByIdAction {
    type: ProjectsActionTypes.FIND_PROJECT_UPLOAD_BY_ID,
    payload: FindProjectUploadByIdActionPayload,
}

export interface UpdateProjectUploadActionPayload {
    uploadsData: UploadsUpdateData[],
}

export interface UpdateProjectUploadAction {
    type: ProjectsActionTypes.UPDATE_PROJECT_UPLOAD,
    payload: UpdateProjectUploadActionPayload,
}

export interface DeleteProjectUploadActionPayload {
    projectId?: string,
    uploadId: string,
}

export interface DeleteProjectUploadAction {
    type: ProjectsActionTypes.DELETE_PROJECT_UPLOAD,
    payload: DeleteProjectUploadActionPayload,
}

// Enhance Audio

export interface SetMediasWithCleanAudioGenerationAction {
    type: ProjectsActionTypes.SET_MEDIAS_WITH_CLEAN_AUDIO_GENERATION,
    payload: CleanAudioGenerationMediaStatuses[],
}

export interface GenerateCleanAudioActionPayload {
    mediaId: string,
    videoId: string,
    mediaDuration: number;
}

export interface GenerateCleanAudioAction {
    type: ProjectsActionTypes.GENERATE_CLEAN_AUDIO,
    payload: GenerateCleanAudioActionPayload,
}

export interface ChangeEnhancedAudioActionPayload {
    mediaId: string,
    isSwitchToClean: boolean,
}

export interface ChangeEnhancedAudioAction {
    type: ProjectsActionTypes.CHANGE_ENHANCED_AUDIO,
    payload: ChangeEnhancedAudioActionPayload,
}

// templates

export interface SetTemplateWorkspaceIdAction {
    type: ProjectsActionTypes.SET_TEMPLATE_WORKSPACE_ID;
    payload: string | null;
}

export interface SubmitTemplatesAction {
    type: ProjectsActionTypes.SUBMIT_TEMPLATE;
    payload?: Project;
}

export interface ReviewTemplateActionPayload {
    template?: Project,
    status: TemplateStatus
}

export interface ReviewTemplateAction {
    type: ProjectsActionTypes.REVIEW_TEMPLATE;
    payload: ReviewTemplateActionPayload;
}

export interface PublishTemplateAction {
    type: ProjectsActionTypes.PUBLISH_TEMPLATE;
    payload?: Project;
}

export interface DeleteProjectTemplateAction {
    type: ProjectsActionTypes.DELETE_TEMPLATE;
    templateId?: string;
}

export interface UpdateProjectTemplateAction {
    type: ProjectsActionTypes.UPDATE_TEMPLATE;
    payload?: Project;
}

export interface GetSubmittedTemplatesActionPayload {
    isLoadMore?: boolean;
}

export interface GetSubmittedTemplatesAction {
    type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES;
    payload?: GetSubmittedTemplatesActionPayload;
}

export interface GetSubmittedTemplatesStartAction {
    type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_START;
}

export interface GetSubmittedTemplatesSuccessActionPayload {
    templates: Project[];
    templatesTotal: number;
}

export interface GetSubmittedTemplatesSuccessAction {
    type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_SUCCESS;
    payload: GetSubmittedTemplatesSuccessActionPayload;
}

export interface GetSubmittedTemplatesFailAction {
    type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_FAIL;
}

export interface SetSubmittedTemplatesAction {
    type: ProjectsActionTypes.SET_SUBMITTED_TEMPLATES;
    payload: Project[];
}

export interface SyncSubmittedTemplatesAction {
    type: ProjectsActionTypes.SYNC_SUBMITTED_TEMPLATES;
}

export interface GetPublishedTemplatesActionPayload {
    isLoadMore?: boolean;
}

export interface GetPublishedTemplatesAction {
    type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES;
    payload?: GetPublishedTemplatesActionPayload;
}

export interface GetPublishedTemplatesStartAction {
    type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_START;
}

export interface GetPublishedTemplatesSuccessActionPayload {
    templates: Project[];
    templatesTotal: number;
}

export interface GetPublishedTemplatesSuccessAction {
    type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_SUCCESS;
    payload: GetPublishedTemplatesSuccessActionPayload;
}

export interface GetPublishedTemplatesFailAction {
    type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_FAIL;
}

export interface SetPublishedTemplatesAction {
    type: ProjectsActionTypes.SET_PUBLISHED_TEMPLATES;
    payload: Project[];
}

export interface SyncPublishedTemplatesAction {
    type: ProjectsActionTypes.SYNC_PUBLISHED_TEMPLATES;
}

export interface GetUsableTemplatesActionPayload {
    isLoadMore?: boolean;
}

export interface GetUsableTemplatesAction {
    type: ProjectsActionTypes.GET_USABLE_TEMPLATES;
    payload: GetUsableTemplatesActionPayload;
}

export interface GetUsableTemplatesStartAction {
    type: ProjectsActionTypes.GET_USABLE_TEMPLATES_START;
}

export interface GetUsableTemplatesSuccessActionPayload {
    templates: Project[];
    templatesTotal: number;
}

export interface GetUsableTemplatesSuccessAction {
    type: ProjectsActionTypes.GET_USABLE_TEMPLATES_SUCCESS;
    payload: GetUsableTemplatesSuccessActionPayload;
}

export interface GetUsableTemplatesFailAction {
    type: ProjectsActionTypes.GET_USABLE_TEMPLATES_FAIL;
}

export interface SetUsableTemplatesActionPayload {
    templates: Project[];
}

export interface SetUsableTemplatesAction {
    type: ProjectsActionTypes.SET_USABLE_TEMPLATES;
    payload: SetUsableTemplatesActionPayload;
}

export interface UseTemplateInProjectActionPayload {
    template: Project,
}

export interface UseTemplateInProjectAction {
    type: ProjectsActionTypes.USE_TEMPLATE;
    payload: UseTemplateInProjectActionPayload;
}

// animations

export interface ChangeTextAnimationActionPayload {
    animationType: TextAnimationType,
    position: TextAnimationTabType,
    textObject: SelectedProjectObject,
}

export interface ChangeTextAnimationAction {
    type: ProjectsActionTypes.CHANGE_TEXT_ANIMATION,
    payload: ChangeTextAnimationActionPayload,
}

export interface ReplaceTimelineMediaActionPayload {
    newMedia: any,
    replaceableAudio?: ProjectAudio,
    replaceableVideo?: ProjectVideo,
    videos?: UploadingMedia[];
    isUpload?: boolean;
}

export interface ReplaceTimelineMediaAction {
    type: ProjectsActionTypes.REPLACE_TIMELINE_MEDIA,
    payload: ReplaceTimelineMediaActionPayload,
}

export interface SetProjectAudiosIdsWithDubbingGenerationAction {
    type: ProjectsActionTypes.SET_PROJECT_AUDIOS_IDS_WITH_DUBBING_GENERATION;
    payload: string[];
}

export interface GenerateProjectAudioDubbingActionPayload {
    sourceLanguage: string;
    targetLanguage: string;
    voiceId?: string;
    voiceStyle?: string;
    mediaDetails: MediaFile;
}

export interface GenerateProjectAudioDubbingAction {
    type: ProjectsActionTypes.GENERATE_PROJECT_AUDIO_DUBBING;
    payload: GenerateProjectAudioDubbingActionPayload;
}

export interface ChangeAudioFadeActionPayload {
    fadeProps: AudioFadeProps | null,
    media: ProjectVideo | ProjectAudio,
    isVideo: boolean,
}

export interface ChangeAudioFadeAction {
    type: ProjectsActionTypes.CHANGE_AUDIO_FADE;
    payload: ChangeAudioFadeActionPayload;
}

export interface ChangeAudioVolumeActionPayload {
    media: ProjectVideo | ProjectAudio,
    isVideo: boolean,
    newVolume: number,
}

export interface ChangeAudioVolumeAction {
    type: ProjectsActionTypes.CHANGE_AUDIO_VOLUME;
    payload: ChangeAudioVolumeActionPayload;
}

export interface DeleteTimelineSelectedItemsAction {
    type: ProjectsActionTypes.DELETE_SELECTED_ITEMS;
}

export type ProjectsAction =
    | GetProjectsAction
    | GetProjectsAction
    | GetProjectsStartAction
    | GetProjectsSuccessAction
    | GetProjectsFailAction
    | SetProjectsAction
    | SyncProjectsAction
    | SyncProjectDetailsAction
    | GetProjectLatestVersionStartAction
    | GetProjectLatestVersionEndAction
    | DeleteProjectVideoAction
    | DeleteProjectVideoStartAction
    | DeleteProjectVideoFailAction
    | DeleteProjectVideoSuccessAction
    | GetProjectDetailsAction
    | GetProjectDetailsStartAction
    | GetProjectDetailsFailAction
    | GetProjectDetailsSuccessAction
    | SetProjectDetailsAction
    | ClearProjectDetailsAction
    | SetProjectMediaSourcesAction
    | SetProjectTempMediaSourcesAction
    | CreateProjectAction
    | CreateProjectStartAction
    | CreateProjectFailAction
    | CreateProjectSuccessAction
    | DeleteProjectAction
    | DeleteProjectStartAction
    | DeleteProjectFailAction
    | DeleteProjectSuccessAction
    | SetProjectsIdsForDeleteAction
    | GenerateProjectAction
    | GenerateProjectStartAction
    | GenerateProjectFailAction
    | GenerateProjectSuccessAction
    | UpdateProjectAction
    | UpdateProjectStartAction
    | UpdateProjectFailAction
    | UpdateProjectSuccessAction
    | SetProjectUpdatesHistoryAction
    | SetProjectVideosAction
    | CreateProjectFromUploadAction
    | CreateDefaultProjectAction
    | SyncProjectMediaSourcesAction
    | CreateProjectTempVideoPreviewsAction
    | SetProjectTempMediaSourcesPreviewsAction
    | AddVideosFromLibraryToProjectAction
    | GoByProjectHistoryAction
    | DeleteProjectImageAction
    | UpdateProjectImageAction
    | SetProjectAudioMediaSourceAction
    | DetouchAudioFromVideoAction
    | DetouchAudioFromVideoStartAction
    | DetouchAudioFromVideoFailAction
    | DetouchAudioFromVideoSuccessAction
    | SetProjectAudiosAction
    | UpdateProjectAudioAction
    | DeleteProjectAudioAction
    | CreateProjectTextAction
    | DeleteProjectTextAction
    | UpdateProjectTextAction
    | SetProjectVideosTransitionsAction
    | UpdateProjectVideoTransitionAction
    | SetProjectVideosFiltersAction
    | UpdateProjectVideoFilterAction
    | UpdateProjectVideoAction
    | UpdateProjectPreferencesAction
    | ResetProjectTextSettingsAction
    | SetProjectSortAction
    | GetElementsListAction
    | GetElementsListStartAction
    | GetElementsListFailAction
    | SetElementsListAction
    | CreateProjectElementAction
    | UpdateProjectElementAction
    | DeleteProjectElementAction
    | SetProjectsViewModeAction
    | SetProjectLastGenerationAction
    | GenerateTextToSpeechAction
    | GenerateTextToSpeechStartAction
    | GenerateTextToSpeechSuccessAction
    | GenerateTextToSpeechFailAction
    | DuplicateProjectAction
    | CreateProjectWithStockVideoAction
    | UpdateProjectAudiosAction
    | GetMediaAudioTracksOptionsAction
    | SetProjectAudioOptionsAction
    | DeleteProjectDubbingLanguageAction
    | GetProjectUploadsAction
    | CreateProjectUploadAction
    | FindProjectUploadByIdAction
    | UpdateProjectUploadAction
    | DeleteProjectUploadAction
    | SetProjectUploadsAction
    | SetMediasWithCleanAudioGenerationAction
    | GenerateCleanAudioAction
    | ChangeEnhancedAudioAction
    | SubmitTemplatesAction
    | ReplaceTimelineMediaAction
    | ChangeTextAnimationAction
    | SetTemplateWorkspaceIdAction
    | ReviewTemplateAction
    | PublishTemplateAction
    | DeleteProjectTemplateAction
    | UpdateProjectTemplateAction
    | GetSubmittedTemplatesAction
    | GetSubmittedTemplatesStartAction
    | GetSubmittedTemplatesSuccessAction
    | GetSubmittedTemplatesFailAction
    | SetSubmittedTemplatesAction
    | SyncSubmittedTemplatesAction
    | GetPublishedTemplatesAction
    | GetPublishedTemplatesStartAction
    | GetPublishedTemplatesSuccessAction
    | GetPublishedTemplatesFailAction
    | SetPublishedTemplatesAction
    | SyncPublishedTemplatesAction
    | GetUsableTemplatesAction
    | GetUsableTemplatesStartAction
    | GetUsableTemplatesSuccessAction
    | GetUsableTemplatesFailAction
    | SetUsableTemplatesAction
    | UseTemplateInProjectAction
    | SetProjectAudiosIdsWithDubbingGenerationAction
    | GenerateProjectAudioDubbingAction
    | ChangeAudioFadeAction
    | ChangeAudioVolumeAction;

export interface ProjectVideoLanguages {
    videoId: string;
    languagesList: string[];
}
