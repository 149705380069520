import { IconProps } from './index';

const MergeIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 16;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.46967 10.8031C5.17678 11.096 5.17678 11.5709 5.46967 11.8637C5.76256 12.1566 6.23744 12.1566 6.53033 11.8637L5.46967 10.8031ZM8 9.33341L8.53033 8.80308C8.23744 8.51019 7.76256 8.51019 7.46967 8.80308L8 9.33341ZM9.46967 11.8637C9.76256 12.1566 10.2374 12.1566 10.5303 11.8637C10.8232 11.5709 10.8232 11.096 10.5303 10.8031L9.46967 11.8637ZM10.5303 5.19708C10.8232 4.90418 10.8232 4.42931 10.5303 4.13642C10.2374 3.84352 9.76256 3.84352 9.46967 4.13642L10.5303 5.19708ZM8 6.66675L7.46967 7.19708C7.76256 7.48997 8.23744 7.48997 8.53033 7.19708L8 6.66675ZM6.53033 4.13642C6.23744 3.84352 5.76256 3.84352 5.46967 4.13642C5.17678 4.42931 5.17678 4.90419 5.46967 5.19708L6.53033 4.13642ZM6.53033 11.8637L8.53033 9.86374L7.46967 8.80308L5.46967 10.8031L6.53033 11.8637ZM7.46967 9.86374L9.46967 11.8637L10.5303 10.8031L8.53033 8.80308L7.46967 9.86374ZM9.46967 4.13642L7.46967 6.13642L8.53033 7.19708L10.5303 5.19708L9.46967 4.13642ZM8.53033 6.13642L6.53033 4.13642L5.46967 5.19708L7.46967 7.19708L8.53033 6.13642Z"
                fill={color}
            />
        </svg>
    );
};

export default MergeIcon;
