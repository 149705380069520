import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1.555;

const UploadCloudIcon = ({ size }: IconProps): JSX.Element => {
  let height = 45;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.244 14.7931C43.1448 7.29397 37.0431 1 29.5207 1C24.7284 1 20.2284 3.59052 17.744 7.32414C16.6526 6.59741 15.3414 6.17241 13.931 6.17241C10.1224 6.17241 7.03448 9.26034 7.03448 13.069C7.03448 13.2009 7.04655 13.3293 7.05431 13.4603C7.04569 13.6172 7.03448 13.7759 7.03448 13.931C3.59655 15.5793 1 19.4974 1 23.5474C1 29.1638 5.59483 33.7586 11.2112 33.7586H32.3379C32.4569 33.7586 32.5724 33.7448 32.6897 33.7405C32.8078 33.7448 32.9233 33.7586 33.0414 33.7586H41.4922C46.7215 33.7586 51 29.4802 51 24.2517C51 19.6216 47.6431 15.6216 43.244 14.7931Z"
        fill="#6879C7"
        stroke="#313C6E"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M43.2441 14.7931C43.2441 14.7931 41.0587 14.4698 38.9312 14.7931H43.2441Z"
        fill="#7FABDA"
      />
      <path
        d="M43.2441 14.7931C43.2441 14.7931 41.0587 14.4698 38.9312 14.7931"
        stroke="#313C6E"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.03467 13.0689C7.03467 9.26029 10.1226 6.17236 13.9312 6.17236C17.7398 6.17236 20.8278 9.26029 20.8278 13.0689"
        fill="#6879C7"
      />
      <path
        d="M7.03467 13.0689C7.03467 9.26029 10.1226 6.17236 13.9312 6.17236C17.7398 6.17236 20.8278 9.26029 20.8278 13.0689"
        stroke="#313C6E"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.8862 43.2412V23.4136"
        stroke="#AAB9FF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.8862 23.4136L33.7828 30.3101"
        stroke="#AAB9FF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.9897 30.3101L26.8863 23.4136"
        stroke="#AAB9FF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UploadCloudIcon;
