export enum LimitationType {
    ANALYSIS_PRICE_OVER_LIMIT,
    NOT_ENOUGH_STORAGE,
    UPLOAD_VIDEO_SIZE_OVER_LIMIT,
    UPLOAD_VIDEO_LENGTH_OVER_LIMIT,
    PROJECT_EXPORT_LENGTH_OVER_LIMIT,
    REMOVE_WATERMARK,
    EXPORT_SUBTITLES,
    NOT_ENOUGH_CREDITS_FOR_TRANSLATE,
    NOT_ENOUGH_CREDITS_FOR_SUBTITLING,
    STOCK_MEDIA_ASSETS_OVER_LIMIT,
    NOT_ENOUGH_CREDITS_FOR_DUBBING,
    FREE_PLAN_NEW_WORKSPACE_PREVENT,
}
