import { IconProps } from '../index';

const TextInDragonflyIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.583 21.03c.399-.666.877-1 1.434-1 .558 0 1.027.302 1.407.905.384.595.576 1.315.576 2.158 0 .844-.194 1.567-.583 2.17-.39.602-.86.903-1.414.903-.548 0-1.021-.361-1.42-1.084v.893h-.57V18h.57v3.03zm1.4 4.285c.398 0 .735-.223 1.012-.67.281-.446.422-.967.422-1.562 0-.603-.136-1.127-.408-1.574-.272-.447-.612-.67-1.02-.67-.407 0-.75.21-1.026.627-.272.419-.408.947-.408 1.585 0 .63.138 1.166.415 1.605.277.44.614.66 1.012.66z"
                fill="#7C7C7D" />
            <path
                d="M20.52 26.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H12L16.135 17h.31l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664z"
                fill="#525253" />
            <path
                d="M31.5 24.732c-.128.376-.297.662-.506.86-.206.196-.43.295-.672.295-.377 0-.692-.248-.946-.743-.25-.495-.376-1.101-.376-1.817s.127-1.328.38-1.835c.255-.513.583-.77.986-.77.24 0 .459.105.66.314.202.209.36.501.474.877l-.291.367c-.218-.567-.503-.85-.856-.85-.278 0-.51.179-.699.537-.188.352-.282.79-.282 1.315 0 .525.093.976.278 1.352.185.375.42.563.703.563.34 0 .627-.286.86-.859l.287.394z"
                fill="#CECECF" />
            <path d="M10.707 17.364h2.828M16.364 11.707v2.829M12.828 13.828l2 2" stroke="#7C7C7D" strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    );
};

export default TextInDragonflyIcon;