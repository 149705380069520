import { AxiosResponse } from 'axios';
import store from 'state/store';
import $http from './instance';

class JobsClient {
  public static async getJobs(): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/jobs`);
  }

  public static async getJobsByVideoId(
    videoId: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/jobs/video/${videoId}`);
  }

  public static async getJobPreviewsById(
    videoId: string,
    jobId: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(
      `/user/${userId}/videos/${videoId}/job/${jobId}/previews`,
    );
  }

  public static async renderClipByPreview(
    videoId: string,
    jobId: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.post(
      `/user/${userId}/videos/${videoId}/job/${jobId}/previews`,
    );
  }
}

export default JobsClient;
