import { IconProps } from './index';

const CollectionsIcon = ({ color, size }: IconProps): JSX.Element => {
  let height = 26;
  if (size) {
    height = size;
  }

  return (
    <svg
      id="noun_images_2009646"
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      viewBox="0 0 26.592 26.592"
    >
      <path
        id="Контур_1"
        data-name="Контур 1"
        d="M22.683,1.477A1.4,1.4,0,0,1,24.16,2.955V20.683a1.4,1.4,0,0,1-1.477,1.477H4.955a1.4,1.4,0,0,1-1.477-1.477V2.955A1.4,1.4,0,0,1,4.955,1.477H22.683m0-1.477H4.955A2.963,2.963,0,0,0,2,2.955V20.683a2.963,2.963,0,0,0,2.955,2.955H22.683a2.963,2.963,0,0,0,2.955-2.955V2.955A2.963,2.963,0,0,0,22.683,0Z"
        transform="translate(0.955)"
        fill={color}
      />
      <path
        id="Контур_2"
        data-name="Контур 2"
        d="M22.9,24.16H2.955a1.4,1.4,0,0,1-1.477-1.477V2.739A.7.7,0,0,0,.739,2,.7.7,0,0,0,0,2.739V22.683a2.963,2.963,0,0,0,2.955,2.955H22.9a.739.739,0,1,0,0-1.477Z"
        transform="translate(0 0.955)"
        fill={color}
      />
      <path
        id="Контур_3"
        data-name="Контур 3"
        d="M7.955,4.477A1.4,1.4,0,0,1,9.432,5.955,1.4,1.4,0,0,1,7.955,7.432,1.4,1.4,0,0,1,6.477,5.955,1.4,1.4,0,0,1,7.955,4.477M7.955,3a2.955,2.955,0,1,0,2.955,2.955A2.963,2.963,0,0,0,7.955,3Z"
        transform="translate(2.387 1.432)"
        fill={color}
      />
      <path
        id="Контур_4"
        data-name="Контур 4"
        d="M5.215,15.818a1.488,1.488,0,0,1-.443-.148.714.714,0,0,1,0-1.034h0L9.2,10.2a.714.714,0,0,1,1.034,0h0L12.6,12.567l6.8-6.8a.731.731,0,0,1,1.034,1.034l-7.387,7.387a.714.714,0,0,1-1.034,0h0L9.647,11.681,5.658,15.522C5.658,15.67,5.363,15.818,5.215,15.818Z"
        transform="translate(2.172 2.649)"
        fill={color}
      />
    </svg>
  );
};

export default CollectionsIcon;
