import { ChangeEvent, memo } from "react";
import InputMask from "react-input-mask";

import DropdownAutocomplete from "components/DropdownAutocomplete";

import { Tooltip } from "components/UI";

import { OFFSET_MASK, OFFSET_MASK_STRING } from "interfaces/export";
import {
  AvidExportTrack,
  AvidExportTransformedVideo,
} from "interfaces/avidExport";

import styles from "./styles.module.scss";

interface Props {
  item: AvidExportTransformedVideo;
  changeVideoTrack: (value: AvidExportTrack, videoId: string) => void;
  changeOffset: (value: string, videoId: string) => void;
  tracks: Array<AvidExportTrack>;
}

const AvidExportVideoTimelineSettings = (props: Props) => {
  const { item, changeVideoTrack, changeOffset, tracks } = props;

  const handleChangeVideoTrack = (value: AvidExportTrack) => {
    changeVideoTrack(value, item.videoId);
  };

  const handleChangeOffset = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedOffset = value.replaceAll(/[a-zA-Z]/g, "0");

    changeOffset(formattedOffset, item.videoId);
  };

  return (
    <div className={styles.AvidExportVideoTimelineSettings}>
      <div className={styles.AvidExportVideoTimelineSettings__settingsItem}>
        <div
          className={styles.AvidExportVideoTimelineSettings__settingsInfoBlock}
        >
          <p className={styles.AvidExportVideoTimelineSettings__settingsTitle}>
            Timeline Settings
          </p>
          <Tooltip text="Which video tracks do you want your markers to appear on" />
        </div>
        <div className={styles.AvidExportVideoTimelineSettings__selectWrap}>
          <DropdownAutocomplete
            onSelect={handleChangeVideoTrack}
            hasInput={false}
            options={tracks}
            value={item.track}
            white
            color="#01092c"
          />
        </div>
      </div>
      <div className={styles.AvidExportVideoTimelineSettings__settingsItem}>
        <div
          className={styles.AvidExportVideoTimelineSettings__settingsInfoBlock}
        >
          <p className={styles.AvidExportVideoTimelineSettings__settingsTitle}>
            Offset
          </p>
          <Tooltip text="Timeline starting timecode adjustment" />
        </div>
        <div
          className={styles.AvidExportVideoTimelineSettings__offsetInputWrap}
        >
          <InputMask
            mask={OFFSET_MASK.MS}
            onChange={handleChangeOffset}
            value={item.offset}
            alwaysShowMask
            // maskChar=""
            placeholder={OFFSET_MASK_STRING.MS}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(AvidExportVideoTimelineSettings);
