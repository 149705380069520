import { IconProps } from '../index';

const FiltersIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.88889 3.25C4.87919 3.25 3.25 4.87919 3.25 6.88889V18.4444C3.25 20.4541 4.87919 22.0833 6.88889 22.0833H8.44444C8.4919 22.0833 8.53832 22.0789 8.58333 22.0705V26C8.58333 27.5188 9.81455 28.75 11.3333 28.75H17.3333H26C27.5188 28.75 28.75 27.5188 28.75 26V17.3333V11.3333C28.75 9.81455 27.5188 8.58333 26 8.58333H22.0705C22.0789 8.53832 22.0833 8.4919 22.0833 8.44444V6.88889C22.0833 4.87919 20.4541 3.25 18.4444 3.25H6.88889ZM20.5962 8.58333C20.5877 8.53832 20.5833 8.4919 20.5833 8.44444V6.88889C20.5833 5.70761 19.6257 4.75 18.4444 4.75H6.88889C5.70761 4.75 4.75 5.70761 4.75 6.88889V18.4444C4.75 19.6257 5.70761 20.5833 6.88889 20.5833H8.44444C8.4919 20.5833 8.53832 20.5877 8.58333 20.5962V11.3333C8.58333 9.81455 9.81455 8.58333 11.3333 8.58333H20.5962ZM19.144 27.25H26C26.6904 27.25 27.25 26.6904 27.25 26V19.144L19.144 27.25ZM27.25 17.0227L17.0227 27.25H11.3333C11.2723 27.25 11.2123 27.2456 11.1536 27.2372C11.1684 27.2245 11.1829 27.2111 11.197 27.197L27.197 11.197C27.2111 11.1829 27.2245 11.1684 27.2372 11.1536C27.2456 11.2123 27.25 11.2723 27.25 11.3333V17.0227ZM10.0833 26C10.0833 26.061 10.0877 26.1211 10.0962 26.1798C10.1089 26.1649 10.1223 26.1504 10.1363 26.1363L26.1363 10.1363C26.1504 10.1223 26.1649 10.1089 26.1798 10.0962C26.1211 10.0877 26.061 10.0833 26 10.0833H20.3107L10.0833 20.3107V26ZM11.3333 10.0833C10.643 10.0833 10.0833 10.643 10.0833 11.3333V18.1893L18.1893 10.0833H11.3333Z"
                fill={color}
            />
        </svg>
    );
};

export default FiltersIconNew;


