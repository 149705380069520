import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Loader from 'react-loader-spinner';

import { Icon } from 'components/UI';

import {
  ClipGenerationDataClip,
  ClipGenerationDataClipTimestamp,
} from 'state/modules/generation';

import { MediaFile } from 'interfaces/videos';

import { Colors } from 'styles';
import styles from './styles.module.scss';

const windowFork = window;

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

interface Props {
  videoDetails: MediaFile;
  shot: ClipGenerationDataClipTimestamp;
  isMuted: boolean;
  isSelected: boolean;
  onClick: (clip: ClipGenerationDataClip, isSelected: boolean) => void;
  onFinish: (index: number) => void;
  data: ClipGenerationDataClip;
  index: number;
  isAutoplayEnabled: boolean;
  isVideoFinished: boolean;
  isTogglerVisible?: boolean;
}

const ClipPreviewPlayer = (props: Props): JSX.Element => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);

  const isWaitingTimeout = useRef(null) as any;
  const isPlayingTimeout = useRef(null) as any;
  const videoRef = useRef(null) as any;

  const {
    onFinish,
    videoDetails,
    shot,
    isMuted,
    isSelected,
    onClick,
    data,
    index,
    isAutoplayEnabled,
    isVideoFinished,
    isTogglerVisible
  } = props;


  const startTracking: any = useCallback(
    () => {
      if (videoRef?.current) {
        if (videoRef.current.ended || videoRef.current.paused || isVideoFinished) {
          return;
        }

        const currentTime = videoRef.current.currentTime * 1000;

        if (currentTime >= (shot.to - 50)) {
          videoRef.current.pause();
          onFinish(index)
        }

        return requestAnimationFrame(startTracking);
      }
    },
    [videoRef, shot, onFinish, index, isVideoFinished],
  )

  useEffect(() => {
    if (isMetadataLoaded) {
      videoRef.current.currentTime = (shot.from + 50) / 1000;
    }
  }, [videoRef, shot, isMetadataLoaded])

  useEffect(() => {
    if (videoRef.current) {
      if (isAutoplayEnabled && isMetadataLoaded) {
        videoRef.current.play()
        setIsPlaying(true)
      }
    }

  }, [videoRef, isMetadataLoaded, isAutoplayEnabled])


  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const element = videoRef.current;

    const tracking = () => requestAnimationFrame(startTracking)

    element.addEventListener('play', tracking);

    return () => {
      element.removeEventListener('play', tracking)
      cancelAnimationFrame(startTracking)
    }
  }, [videoRef, startTracking, onFinish, index, shot])


  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const element = videoRef.current;

    const pauseHandler = (): void => {
      clearTimeout(isWaitingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsWaiting(false);
      }, PLAYING_DEBOUNCE_TIME);
    };

    const waitingHandler = (): void => {
      clearTimeout(isWaitingTimeout.current);

      isWaitingTimeout.current = setTimeout(() => {
        setIsWaiting(true);
      }, WAITING_DEBOUNCE_TIME);
    };

    const loadedMetadataHandler = () => {
      setIsMetadataLoaded(true)
    }

    const visibilitychangeHandler = () => {
      if (document.visibilityState === 'hidden') {
        element.pause()
        setIsPlaying(false)
      }
    }

    const timeUpdateHandler = (): void => {
      setIsWaiting(false)
    }

    element.addEventListener('waiting', waitingHandler);
    element.addEventListener('pause', pauseHandler);
    element.addEventListener('timeupdate', timeUpdateHandler);
    document.addEventListener("visibilitychange", visibilitychangeHandler);
    element.addEventListener("loadedmetadata", loadedMetadataHandler);

    return (): void => {
      element.removeEventListener('waiting', waitingHandler);
      element.removeEventListener('pause', pauseHandler);
      element.removeEventListener('timeupdate', timeUpdateHandler);
      document.removeEventListener('visibilitychange', visibilitychangeHandler);
      element.removeEventListener('loadedmetadata', loadedMetadataHandler);
    }
  }, [videoRef, onFinish])


  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      try {
        if (!videoRef.current.paused) {
          videoRef.current.pause();
          setIsPlaying(false)
        } else {
          videoRef.current.play();
          setIsPlaying(true)
        }
      } catch (error) {
        console.log({ error })
      }
    }
  };

  const renderSelectButton = () => isTogglerVisible ? (
    <div
      className={[
        styles.ClipPreviewPlayer__selectButton,
        isSelected
          ? styles.ClipPreviewPlayer__selectButton_selected
          : null,
      ].join(' ')}
      onClick={() => onClick(data, isSelected)}
    >
      <Icon
        name="success-check"
        size={26}
        secondColor={isSelected ? '#ffffff' : '#040849'}
        color={isSelected ? Colors.TURQUOISE : '#ffffff'}
      />
    </div>
  ) : null;

  const renderPlayButton = () => (
    <div
      className={[
        styles.ClipPreviewPlayer__playButton,
        isWaiting
          ? styles.ClipPreviewPlayer__playButton_disabled
          : null,
      ].join('')}
    >
      <Icon
        name={isPlaying ? 'pause' : 'play'}
        color="#ffffff"
        size={16}
      />
    </div>
  );

  const renderOverlay = () => !isVideoFinished && isMetadataLoaded && !isWaiting ? (
    <button
      className={[
        styles.ClipPreviewPlayer__overlay,
        !isPlaying
          ? styles.ClipPreviewPlayer__overlay_paused
          : null,
      ].join(' ')}
      onClick={handlePlayPauseClick}
      disabled={isWaiting}
    >
      {renderPlayButton()}
    </button>
  ) : null;

  const renderPlayer = (): JSX.Element => (
    <video
      ref={videoRef}
      className={styles.ClipPreviewPlayer__video}
      src={`${windowFork.config.REACT_APP_CLOUDFRONT_URL}${videoDetails.s3Path}`}
      controls={false}
      muted={isMuted}
      autoPlay={false}
      preload="metadata"
    />
  );

  const renderWaitingLoader = () => (isWaiting ? (
    <div className={styles.ClipPreviewPlayer__waitingLoader}>
      <p className={styles.ClipPreviewPlayer__waitingLoaderTitle}>
        Waiting...
      </p>
      <Loader type="Oval" color="#ffffff" height={20} width={20} />
    </div>
  ) : null);

  return (
    <div className={styles.ClipPreviewPlayer}>
      <div className={styles.ClipPreviewPlayer__container}>
        {renderPlayer()}
        {renderOverlay()}
        {renderSelectButton()}
        {renderWaitingLoader()}
      </div>
    </div>
  );
};

export default ClipPreviewPlayer;
