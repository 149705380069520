import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { AnalysisResultsType } from 'interfaces/analysis';
import {
    RawSentence,
    SentenceDataItem,
    TransformedSentence,
} from 'state/modules/metadata';

const checkIsPunctuation = (word: string): boolean => {
    const punctuationsMarks = ['.', '!', '?', ',', ':', ';'];

    return punctuationsMarks.includes(word);
};

const createPronunciationItem = (word: string) => ({
    startTime: '',
    endTime: '',
    type: AnalysisResultsType.PRONUNCIATION,
    alternatives: [
        {
            confidence: '1.0',
            content: word,
        },
    ],
});

const createPunctuationItem = (symbol: string) => ({
    type: AnalysisResultsType.PUNCTUATION,
    alternatives: [
        {
            confidence: '1.0',
            content: symbol,
        },
    ],
});

export const createItemsFromSentence = (
    sentence: string,
    startTimeInMs: number,
    endTimeInMs: number
): any[] => {
    const duration = Math.abs(endTimeInMs - startTimeInMs);

    let newItems = [] as any;
    let currentWord = '';
    const symbolsArr = sentence.trim().split('');

    symbolsArr.forEach((symbol: string, index: number) => {
        const prevSymbol = symbolsArr[index - 1];
        const isPunctuation = checkIsPunctuation(symbol);
        const isSpace = symbol === ' ';
        const isSymbol = !isSpace && !isPunctuation;

        if (isSpace) {
            if (prevSymbol !== ' ' && currentWord.length) {
                const pronunciationItem = createPronunciationItem(currentWord);

                newItems.push(pronunciationItem);

                currentWord = symbol;
            } else if (prevSymbol === ' ') {
                currentWord = `${currentWord}${symbol}`;
            } else {
                currentWord = symbol;
            }

            return;
        }

        if (symbol === '\n') {
            if (currentWord.length) {
                const pronunciationItem = createPronunciationItem(currentWord);
                newItems.push(pronunciationItem);

                currentWord = '';
            }

            const pronunciationItem = createPronunciationItem(symbol);
            return newItems.push(pronunciationItem);
        }

        if (isPunctuation) {
            const punctuationItem = createPunctuationItem(symbol);

            if (currentWord.length) {
                const pronunciationItem = createPronunciationItem(currentWord);
                newItems.push(pronunciationItem);
            }

            currentWord = '';

            return newItems.push(punctuationItem);
        }

        if (isSymbol && !isSpace) {
            if (prevSymbol === ' ' && currentWord.length) {
                const pronunciationItem = createPronunciationItem(currentWord);
                newItems.push(pronunciationItem);

                currentWord = '';
            }

            currentWord = `${currentWord}${symbol}`;
        }

        const isLastSymbol = index === sentence.length - 1;

        if (isLastSymbol && currentWord.length > 0) {
            const pronunciationItem = createPronunciationItem(currentWord);

            newItems.push(pronunciationItem);
        }
    });

    if (!newItems.length && currentWord === sentence) {
        newItems = [
            {
                startTime: '',
                endTime: '',
                type: AnalysisResultsType.PRONUNCIATION,
                alternatives: [
                    {
                        confidence: '1.0',
                        content: currentWord,
                    },
                ],
            },
        ];
    }

    const pronunciationItems = newItems.filter(
        (dataItem: any) => dataItem.type === AnalysisResultsType.PRONUNCIATION
    );
    const pronunciationItemsLength = pronunciationItems.length;
    const itemDuration = duration / pronunciationItemsLength;

    newItems = newItems.map((dataItem: any) => {
        const isPunctuation = dataItem.type === AnalysisResultsType.PUNCTUATION;

        if (isPunctuation) {
            return dataItem;
        }

        const pronunciationItemIndex = pronunciationItems.findIndex(
            (pronunciationItem: any) => pronunciationItem === dataItem
        );

        let itemStartTime = 0;
        let itemEndTime = 0;

        if (pronunciationItemIndex === 0) {
            itemStartTime = startTimeInMs;
            itemEndTime = startTimeInMs + itemDuration;
        } else if (pronunciationItemIndex === pronunciationItemsLength - 1) {
            itemStartTime =
                startTimeInMs + itemDuration * pronunciationItemIndex;
            itemEndTime = endTimeInMs;
        } else {
            itemStartTime =
                startTimeInMs + itemDuration * pronunciationItemIndex;
            itemEndTime = startTimeInMs + itemDuration * pronunciationItemIndex;
        }

        const formattedItemStartTime = moment
            .duration(itemStartTime)
            .format('s.SS', {
                useGrouping: false,
                trim: false,
            });
        const formattedItemEndTime = moment
            .duration(itemEndTime)
            .format('s.SS', {
                useGrouping: false,
                trim: false,
            });

        return {
            ...dataItem,
            startTime:
                formattedItemStartTime !== '00'
                    ? formattedItemStartTime
                    : `${formattedItemStartTime}.00`,
            endTime:
                formattedItemEndTime !== '00'
                    ? formattedItemEndTime
                    : `${formattedItemEndTime}.00`,
        };
    });

    return newItems;
};

interface SplitSentenceBySpecifiedTimeParams {
    sentence: TransformedSentence;
    time: number;
}

export const splitSentenceBySpecifiedTime = ({
    sentence,
    time,
}: SplitSentenceBySpecifiedTimeParams): TransformedSentence[] => {
    let firstSentenceItems = [] as SentenceDataItem[];
    let secondSentenceItems = [] as SentenceDataItem[];

    let sentenceIndex = 0;

    let preparedSentence = sentence;

    if (!sentence?.dataItems?.length) {
        let updatedItems: SentenceDataItem[] = [];

        preparedSentence.items.forEach((item, index) => {
            const prevItem = updatedItems[index - 1];
            const isPunctuation = item.type === AnalysisResultsType.PUNCTUATION;
            const isSpace = item.alternatives[0].content === ' ';
            const isSymbol = !isSpace && !isPunctuation;

            if (isSymbol && prevItem) {
                const prevItemValue = prevItem.alternatives[0].content;
                const isPrevItemSpace = prevItemValue.includes(' ');
                const isPrevItemNewLine = prevItemValue === '\n';

                if (
                    prevItem.type === AnalysisResultsType.PRONUNCIATION &&
                    !isPrevItemSpace &&
                    !isPrevItemNewLine
                ) {
                    const space = createPronunciationItem(' ');

                    updatedItems.push(space);
                }
            }

            updatedItems.push(item);
        });

        updatedItems = createItemsFromSentence(
            preparedSentence.data,
            preparedSentence.timestamps[0].timestamp,
            preparedSentence.timestamps[1].timestamp
        );

        preparedSentence = {
            ...preparedSentence,
            items: updatedItems,
        };
    }

    preparedSentence.items.forEach((item) => {
        if (+item.startTime <= time) {
            firstSentenceItems.push(item);
        }

        if (+item.startTime >= time) {
            sentenceIndex = 1;
            secondSentenceItems.push(item);
        }

        if (item.type === AnalysisResultsType.PUNCTUATION) {
            if (sentenceIndex === 0) {
                firstSentenceItems.push(item);
            } else {
                secondSentenceItems.push(item);
            }
        }
    });

    let firstSenteceDataArray: string[] = [];
    let secondSenteceDataArray: string[] = [];

    firstSentenceItems.forEach((item, index) => {
        const content = item.alternatives[0].content;

        firstSenteceDataArray.push(content);
    });

    secondSentenceItems.forEach((item, index) => {
        const content = item.alternatives[0].content;

        secondSenteceDataArray.push(content);
    });

    let currentDataItems = sentence?.dataItems?.length
        ? sentence.dataItems
        : sentence.data
              .trim()
              .replace('<br />', '\n')
              .split('')
              .map((dataItem) => ({ content: dataItem }));

    let firstSenteceData = '';
    let secondSenteceData = '';

    let firstLength = 0;

    firstSenteceDataArray.forEach((item) => {
        firstLength += item.length;
    });

    firstLength = firstLength < 0 ? 0 : firstLength;

    let firstSentenceDataItems =
        firstLength > 0 ? currentDataItems.slice(0, firstLength) : [];

    let secondSentenceDataItems = currentDataItems.slice(firstLength);

    firstSentenceDataItems.forEach((item) => {
        firstSenteceData = `${firstSenteceData}${item.content}`;
    });

    secondSentenceDataItems.forEach((item) => {
        secondSenteceData = `${secondSenteceData}${item.content}`;
    });

    const trimmedFirstSenteceData = firstSenteceData.trim();
    const trimmedSecondSenteceData = secondSenteceData.trim();

    const firstSentenceTrimedLeft = firstSenteceData.trimStart();
    const firstSentenceTrimedRight = firstSenteceData.trimEnd();

    const secondSentenceTrimedLeft = secondSenteceData.trimStart();
    const scindSentenceTrimedRight = secondSenteceData.trimEnd();

    const firstSentenceDiffLeft =
        firstSenteceData.length - firstSentenceTrimedLeft.length;
    const firstSentenceDiffRight =
        firstSenteceData.length - firstSentenceTrimedRight.length;

    const secondSentenceDiffLeft =
        secondSenteceData.length - secondSentenceTrimedLeft.length;
    const secondSentenceDiffRight =
        secondSenteceData.length - scindSentenceTrimedRight.length;

    if (firstSentenceDiffLeft) {
        firstSentenceDataItems = firstSentenceDataItems.slice(
            firstSentenceDiffLeft
        );
    }

    if (firstSentenceDiffRight) {
        firstSentenceDataItems = firstSentenceDataItems.slice(
            0,
            firstSenteceData.length - firstSentenceDiffRight
        );
    }

    if (secondSentenceDiffLeft) {
        secondSentenceDataItems = secondSentenceDataItems.slice(
            secondSentenceDiffLeft
        );
    }

    if (secondSentenceDiffRight) {
        secondSentenceDataItems = secondSentenceDataItems.slice(
            0,
            secondSenteceData.length - secondSentenceDiffRight
        );
    }

    firstSentenceItems = createItemsFromSentence(
        trimmedFirstSenteceData,
        sentence.timestamps[0].timestamp,
        time * 1000
    );
    secondSentenceItems = createItemsFromSentence(
        trimmedSecondSenteceData,
        time * 1000,
        sentence.timestamps[1].timestamp
    );

    const firstSentencePart = {
        ...sentence,
        id: uuid(),
        endTime: `${time}`,
        data: trimmedFirstSenteceData,
        [`data_${sentence.language}`]: trimmedFirstSenteceData,
        items: firstSentenceItems,
        name: trimmedFirstSenteceData,
        timestamps: [
            sentence.timestamps[0],
            {
                timestamp: time * 1000,
            },
        ],
        dataItems: firstSentenceDataItems,
    } as TransformedSentence;

    const secondSentencePart = {
        ...sentence,
        id: uuid(),
        startTime: `${time}`,
        data: trimmedSecondSenteceData,
        [`data_${sentence.language}`]: trimmedSecondSenteceData,
        items: secondSentenceItems,
        name: trimmedSecondSenteceData,
        timestamps: [
            {
                timestamp: time * 1000,
            },
            sentence.timestamps[1],
        ],
        dataItems: secondSentenceDataItems,
    } as TransformedSentence;

    return [firstSentencePart, secondSentencePart];
};

// export const transformSentencesToRawSentence = (list: TransformedSentence[]): RawSentence[] => list.map((item) => {
export const transformSentencesToRawSentence = (
    list: TransformedSentence[],
    projectId?: string
): RawSentence[] =>
    list.map((item) => {
        const data = {
            data: item.data,
            [`data_${item.language}`]: '',
            items: item.items,
            language: item.language,
            speaker: item.speaker,
            startTime: item.startTime,
            endTime: item.endTime,
            type: AnalysisResultsType.SENTENCE,
            videoId: item.videoId,
            id: item.id,
            settings: item?.settings,
            dataItems: item?.dataItems,
        } as RawSentence;

        if (projectId) {
            data.projectId = projectId;
        }

        return data;
    });

export const shiftTranscriptItemStartToSpecifiedTime = (
    transcriptItem: TransformedSentence,
    time: number,
    isNewItem?: boolean
): TransformedSentence => {
    const items = createItemsFromSentence(
        transcriptItem.data,
        time,
        +transcriptItem.endTime * 1000
    );

    let formattedItemStartTime = moment.duration(time).format('s.SS', {
        useGrouping: false,
        trim: false,
    });

    formattedItemStartTime =
        formattedItemStartTime !== '00'
            ? formattedItemStartTime
            : `${formattedItemStartTime}.00`;

    const timestamps = [
        {
            timestamp: time,
        },
        transcriptItem.timestamps[1],
    ];

    const data = {
        name: transcriptItem.name,
        originalSentence: transcriptItem.originalSentence,
        data: transcriptItem.data,
        items,
        language: transcriptItem.language,
        speaker: transcriptItem.speaker,
        startTime: formattedItemStartTime,
        endTime: transcriptItem.endTime,
        type: AnalysisResultsType.SENTENCE,
        videoId: transcriptItem.videoId,
        id: isNewItem ? uuid() : transcriptItem.id,
        timestamps,
    };

    return data;
};

export const cutTranscriptItemToSpecifiedTime = (
    transcriptItem: TransformedSentence,
    time: number,
    isNewItem?: boolean
): TransformedSentence => {
    const items = createItemsFromSentence(
        transcriptItem.data,
        +transcriptItem.startTime * 1000,
        time
    );

    let formattedItemEndTime = moment.duration(time).format('s.SS', {
        useGrouping: false,
        trim: false,
    });

    formattedItemEndTime =
        formattedItemEndTime !== '00'
            ? formattedItemEndTime
            : `${formattedItemEndTime}.00`;

    const timestamps = [
        transcriptItem.timestamps[0],
        {
            timestamp: time,
        },
    ];

    const data = {
        name: transcriptItem.name,
        originalSentence: transcriptItem.originalSentence,
        data: transcriptItem.data,
        items,
        language: transcriptItem.language,
        speaker: transcriptItem.speaker,
        startTime: transcriptItem.startTime,
        endTime: formattedItemEndTime,
        type: AnalysisResultsType.SENTENCE,
        videoId: transcriptItem.videoId,
        id: isNewItem ? uuid() : transcriptItem.id,
        timestamps,
    };

    return data;
};
