import { ChangeEvent, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

import { FormContainer, NewButton } from 'components/UI';

import { validate } from 'utils';
import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { Link } from 'react-router-dom';
import StepTwo from './StepTwo';
import StepOne from './StepOne';

import styles from './styles.module.scss';

export const ForgotPassword = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmationCodeError, setConfirmationCodeError] = useState('');
    const [step, setStep] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [isComponetMounted, setIsComponetMounted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setIsComponetMounted(true);
        return () => {
            setIsComponetMounted(false);
        };
    }, []);

    const handleSubmitEmail = async () => {
        const emailValidationErrors = validate(
            email,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        if (emailValidationErrors[0]) {
            setEmailError(emailValidationErrors[0]);
        } else {
            try {
                setIsLoading(true);

                await Auth.forgotPassword(email.replace('@', '.'));

                setStep('2');
            } catch (error) {
                if (error?.code === 'UserNotFoundException') {
                    return showNotification(
                        NotificationTypes.error,
                        'Username/email not found. Please try again or sign up as a new user.'
                    );
                }

                showNotification(
                    NotificationTypes.error,
                    (error as any).message
                );
            } finally {
                if (isComponetMounted) {
                    setIsLoading(false);
                }
            }
        }
    };

    const handleSubmitPassword = async () => {
        const passwordValidationErrors = validate(
            password,
            [ValidatorType.Required, ValidatorType.Password],
            ValidationType.OnSubmit
        );

        const confirmationCodeErrors = validate(
            confirmationCode,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        const passwordError = passwordValidationErrors[0] || '';
        const confirmationCodeError = confirmationCodeErrors[0] || '';

        if (passwordError || confirmationCodeError) {
            setPasswordError(passwordError);
            setConfirmationCodeError(confirmationCodeError);
        } else {
            try {
                setIsLoading(true);

                await Auth.forgotPasswordSubmit(
                    email.replace('@', '.'),
                    confirmationCode,
                    password
                );

                showNotification(
                    NotificationTypes.success,
                    'Your password is successfully changed'
                );

                navigate('/');
            } catch (error) {
                showNotification(
                    NotificationTypes.error,
                    (error as any).message
                );
            } finally {
                if (isComponetMounted) {
                    setIsLoading(false);
                    setEmail('');
                    setPassword('');
                    setEmailError('');
                    setPasswordError('');
                    setConfirmationCode('');
                    setConfirmationCodeError('');
                    setStep('1');
                }
            }
        }
    };

    const handleSubmit = async () => {
        if (step === '1') {
            handleSubmitEmail();
        } else {
            handleSubmitPassword();
        }
    };

    const handleResendCode = async () => {
        try {
            await Auth.forgotPassword(email);

            showNotification(
                NotificationTypes.info,
                'Confirmation code resent'
            );
        } catch (error) {
            showNotification(
                NotificationTypes.error,
                (error as any).message as string
            );
        }
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setEmail(value.toLowerCase());
        setEmailError('');
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setPassword(value);
        setPasswordError('');
    };

    const handleChangeConfirmationCode = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;

        setConfirmationCode(value);
        setConfirmationCodeError('');
    };

    const renderForm = () => {
        if (step === '1') {
            return (
                <StepOne
                    email={email}
                    emailError={emailError}
                    handleChangeEmail={handleChangeEmail}
                />
            );
        }
        return (
            <StepTwo
                password={password}
                handleChangePassword={handleChangePassword}
                passwordError={passwordError}
                handleChangeConfirmationCode={handleChangeConfirmationCode}
                confirmationCode={confirmationCode}
                confirmationCodeError={confirmationCodeError}
                handleResendCode={handleResendCode}
            />
        );
    };

    const renderTitle = (): string => {
        if (step === '1') {
            return 'Forgot password';
        }
        return 'New password';
    };

    const renderButtonText = (): string => {
        if (step === '1') {
            return 'Send';
        }
        return 'Save new password';
    };

    return (
        <FormContainer
            onSubmit={handleSubmit}
            className={styles.ForgotPassword__formContainer}
        >
            <div className={styles.ForgotPassword}>
                <div className={styles.ForgotPassword__form}>
                    <div className={styles.ForgotPassword__topLine}>
                        <h2 className={styles.ForgotPassword__formTitle}>
                            {renderTitle()}
                        </h2>
                    </div>
                    {renderForm()}
                    <div className={styles.ForgotPassword__formButtonContainer}>
                        <NewButton
                            color="rgb(0, 192, 186)"
                            type="submit"
                            disabled={isLoading}
                            loading={isLoading}
                            borderRadius={4}
                            textColor="#ffffff"
                            className={styles.ForgotPassword__submitButton}
                        >
                            {renderButtonText()}
                        </NewButton>
                    </div>
                </div>
                <div className={styles.ForgotPassword__bottomContainer}>
                    <Link to="/" className={styles.ForgotPassword__backButton}>
                        Back to Sign In
                    </Link>
                </div>
            </div>
        </FormContainer>
    );
};

export default ForgotPassword;
