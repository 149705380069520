import moment from 'moment';

import { dateFilterOptions } from 'utils/filter';

export const defaultVideoLibraryFilter = {
  dateFrom: moment().startOf('day').toDate(),
  dateTo: moment().toDate(),
  title: '',
  description: '',
  dateFilter: dateFilterOptions[0],
};