import {
  GlobalSearchActionTypes,
  GetMetadataByGlobalSearchAction,
  SetGlobalSearchResultsAction,
  GlobalSearchTranscriptItemClickAction,
  SetGlobalSearchMinConfidenceAction,
  SetGlobalSearchCategoryAction,
  SelectAllGlobalSearchResultsByVideoActionPayload,
  SelectAllGlobalSearchResultsByVideoAction,
} from 'state/modules/globalSearch';

import {
  GlobalSearchCategory,
  TransformedGlobalSearchResult,
  TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';
import { AnalysisResultsCategory } from 'interfaces/analysis';
import {
  ClearGlobalSearchResultsAction,
  GlobalSearchItemClickAction,
  SetGlobalSearchQueryAction,
  TransformedGlobalSearchSentence,
} from './types';

export const getMetadataByGlobalSearch = (
  searchQuery: string,
  minConfidence: number,
  category: AnalysisResultsCategory,
): GetMetadataByGlobalSearchAction => ({
  type: GlobalSearchActionTypes.GET_METADATA_BY_GLOBAL_SEARCH,
  payload: {
    searchQuery,
    minConfidence,
    category,
  },
});

export const setGlobalSearchResults = (
  analysisResults: Array<TransformedGlobalSearchResult>,
  transcriptResults: Array<TransformedGlobalSearchSentence>,
): SetGlobalSearchResultsAction => ({
  type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_RESULTS,
  payload: {
    analysisResults,
    transcriptResults,
  },
});

export const clearGlobalSearchResults = (): ClearGlobalSearchResultsAction => ({
  type: GlobalSearchActionTypes.CLEAR_GLOBAL_SEARCH_RESULTS,
});

export const globalSearchTranscriptItemClick = (
  item: TransformedGlobalSearchSentence,
  filename: string,
): GlobalSearchTranscriptItemClickAction => ({
  type: GlobalSearchActionTypes.GLOBAL_SEARCH_TRANSCRIPT_ITEM_CLICK,
  payload: {
    item,
    filename,
  },
});

export const globalSearchItemClick = (
  item: TransformedGlobalSearchResultItem,
): GlobalSearchItemClickAction => ({
  type: GlobalSearchActionTypes.GLOBAL_SEARCH_ITEM_CLICK,
  payload: {
    item,
  },
});

export const setGlobalSearchMinConfidence = (
  minConfidence: Array<number>,
): SetGlobalSearchMinConfidenceAction => ({
  type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_MIN_CONFIDENCE,
  payload: minConfidence,
});

export const setGlobalSearchCategory = (
  category: GlobalSearchCategory,
): SetGlobalSearchCategoryAction => ({
  type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_CATEGORY,
  payload: category,
});

export const setGlobalSearchQuery = (
  searchQuery: string,
): SetGlobalSearchQueryAction => ({
  type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_QUERY,
  payload: searchQuery,
});

export const selectAllGlobalSearchResultsByVideo = ({
  videoId,
  globalSearchResults,
  globalSearchTranscriptResults,
}: SelectAllGlobalSearchResultsByVideoActionPayload): SelectAllGlobalSearchResultsByVideoAction => ({
  type: GlobalSearchActionTypes.SELECT_ALL_GLOBAL_SEARCH_RESULTS_BY_VIDEO_ID,
  payload: { videoId, globalSearchResults, globalSearchTranscriptResults },
});
