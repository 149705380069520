interface FontWeight {
    [key: string]: number;
}

export const FontWeight: FontWeight = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};
