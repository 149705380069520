import { ChangeEvent, memo } from "react";

import DropdownAutocomplete from "components/DropdownAutocomplete";
import { ColorSelect } from "components/UI";

import { AvidExportGroup, AvidExportListItem } from "interfaces/avidExport";
import { ColorOption } from "interfaces/colors";

import styles from "./styles.module.scss";

interface Props {
  item: AvidExportListItem;
  groups: Array<AvidExportGroup>;
  colourOptions: Array<ColorOption>;
  changeItemComment: (value: string, order: number) => void;
  changeItemColor: (value: ColorOption, order: number) => void;
  changeItemGroup: (value: AvidExportGroup, order: number) => void;
  changeItemName: (value: string, order: number) => void;
}

const AvidExportFileSettingsItem = (props: Props) => {
  const {
    item,
    groups,
    changeItemComment,
    changeItemColor,
    changeItemGroup,
    changeItemName,
    colourOptions,
  } = props;

  const handleChangeItemComment = (e: ChangeEvent<HTMLInputElement>) => {
    changeItemComment(e.target.value, item.order);
  };

  const handleChangeItemName = (e: ChangeEvent<HTMLInputElement>) => {
    changeItemName(e.target.value, item.order);
  };

  const handleChangeItemColor = (value: any) => {
    if (value) {
      changeItemColor(value, item.order);
    }
  };

  const handleChangeItemGroup = (value: AvidExportGroup) => {
    changeItemGroup(value, item.order);
  };

  return (
    <div
      key={item.type + item.name + item.videoId}
      className={styles.AvidExportFileSettingsItem}
    >
      <div className={styles.AvidExportFileSettingsItem__selectWrap}>
        <ColorSelect
          options={colourOptions}
          onChange={handleChangeItemColor}
          value={item.colour}
        />
      </div>
      <input
        className={styles.AvidExportFileSettingsItem__commentInput}
        value={item.name}
        onChange={handleChangeItemName}
      />
      <input
        className={styles.AvidExportFileSettingsItem__commentInput}
        value={item.comment}
        onChange={handleChangeItemComment}
      />
      <div className={styles.AvidExportFileSettingsItem__selectWrap}>
        <DropdownAutocomplete
          onSelect={handleChangeItemGroup}
          hasInput={false}
          options={groups}
          value={item.group}
          white
          color="#01092c"
        />
      </div>
    </div>
  );
};

export default memo(AvidExportFileSettingsItem);
