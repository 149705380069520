import { TemplateDataItemType } from 'interfaces/templateMakerTool';

export interface TemplatesState {
    templatesList: Array<TemplateBody>;
    total: number;
    templatesIdsForDelete: Array<string>;
    selectedTemplates: Array<string>;
    templateDetails: TemplateBody | null;
    isLoading: boolean;
    isTemplateDetailsLoading: boolean;
    isTemplateUpdateLoading: boolean;
    isTemplateCreationLoading: boolean;
}

export interface TemplateBody {
    id?: string;
    ownerId?: string;
    name?: string;
    templateData?: Array<TemplateData>;
}

export interface TemplateData {
    percent?: number;
    items?: Array<TemplateDataItem>;
    id?: string;
}

export interface TemplateDataItem {
    type: TemplateDataItemType;
    value?: string;
    id?: string;
}

export enum TemplatesActionTypes {
    GET_TEMPLATES = '@@templates/GET_TEMPLATES',
    GET_TEMPLATES_START = '@@templates/GET_TEMPLATES_START',
    GET_TEMPLATES_SUCCESS = '@@templates/GET_TEMPLATES_SUCCESS',
    GET_TEMPLATES_FAIL = '@@templates/GET_TEMPLATES_FAIL',

    GET_TEMPLATE_DETAILS = '@@templates/GET_TEMPLATE_DETAILS',
    GET_TEMPLATE_DETAILS_START = '@@templates/GET_TEMPLATE_DETAILS_START',
    GET_TEMPLATE_DETAILS_SUCCESS = '@@templates/GET_TEMPLATE_DETAILS_SUCCESS',
    GET_TEMPLATE_DETAILS_FAIL = '@@templates/GET_TEMPLATE_DETAILS_FAIL',

    UPDATE_TEMPLATE_DETAILS = '@@templates/UPDATE_TEMPLATE_DETAILS',
    UPDATE_TEMPLATE_DETAILS_START = '@@templates/UPDATE_TEMPLATE_DETAILS_START',
    UPDATE_TEMPLATE_DETAILS_SUCCESS = '@@templates/UPDATE_TEMPLATE_DETAILS_SUCCESS',
    UPDATE_TEMPLATE_DETAILS_FAIL = '@@templates/UPDATE_TEMPLATE_DETAILS_FAIL',

    CREATE_TEMPLATE = '@@templates/CREATE_TEMPLATE',
    CREATE_TEMPLATE_START = '@@templates/CREATE_TEMPLATE_START',
    CREATE_TEMPLATE_SUCCESS = '@@templates/CREATE_TEMPLATE_SUCCESS',
    CREATE_TEMPLATE_FAIL = '@@templates/CREATE_TEMPLATE_FAIL',

    DELETE_TEMPLATE = '@@templates/DELETE_TEMPLATE',
    DELETE_TEMPLATES = '@@templates/DELETE_TEMPLATES',
    DELETE_TEMPLATE_START = '@@templates/DELETE_TEMPLATE_START',
    DELETE_TEMPLATE_SUCCESS = '@@templates/DELETE_TEMPLATE_SUCCESS',
    DELETE_TEMPLATE_FAIL = '@@templates/DELETE_TEMPLATE_FAIL',

    SET_TEMPLATES_IDS_FOR_DELETE = '@@templates/SET_TEMPLATES_IDS_FOR_DELETE',

    UNSELECT_TEMPLATE = '@@templates/UNSELECT_TEMPLATE',
    SELECT_TEMPLATE = '@@templates/SELECT_TEMPLATE',
    SET_SELECTED_TEMPLATES = '@@templates/SET_SELECTED_TEMPLATES',
}

export interface GetTemplatesAction {
    type: TemplatesActionTypes.GET_TEMPLATES;
}
export interface GetTemplatesStartAction {
    type: TemplatesActionTypes.GET_TEMPLATES_START;
}
export interface GetTemplatesFailAction {
    type: TemplatesActionTypes.GET_TEMPLATES_FAIL;
    payload: any;
}
export interface GetTemplatesSuccessAction {
    type: TemplatesActionTypes.GET_TEMPLATES_SUCCESS;
    payload: {
        templates: Array<TemplateBody>;
        total: number;
    };
}
export interface GetTemplateByIdAction {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS;
    payload: string;
}
export interface GetTemplateDetailsStartAction {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_START;
}
export interface GetTemplateDetailsFailAction {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_FAIL;
    payload: string;
}
export interface GetTemplateDetailsSuccessAction {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_SUCCESS;
    payload: TemplateBody;
}
export interface UpdateTemplateDetailsStartAction {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_START;
}
export interface UpdateTemplateDetailsFailAction {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_FAIL;
    payload: any;
}
export interface UpdateTemplateDetailsSuccessAction {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_SUCCESS;
}
export interface CreateTemplateAction {
    type: TemplatesActionTypes.CREATE_TEMPLATE;
    payload: TemplateBody;
}
export interface CreateTemplateFailAction {
    type: TemplatesActionTypes.CREATE_TEMPLATE_FAIL;
    payload: any;
}
export interface CreateTemplateStartAction {
    type: TemplatesActionTypes.CREATE_TEMPLATE_START;
}
export interface CreateTemplateSuccessAction {
    type: TemplatesActionTypes.CREATE_TEMPLATE_SUCCESS;
}
export interface DeleteTemplatesAction {
    type: TemplatesActionTypes.DELETE_TEMPLATES;
}
export interface DeleteTemplateAction {
    type: TemplatesActionTypes.DELETE_TEMPLATE;
    payload: string;
}

export interface DeleteTemplateStartAction {
    type: TemplatesActionTypes.DELETE_TEMPLATE_START;
}
export interface DeleteTemplateSuccessAction {
    type: TemplatesActionTypes.DELETE_TEMPLATE_SUCCESS;
}
export interface DeleteTemplateFailAction {
    type: TemplatesActionTypes.DELETE_TEMPLATE_FAIL;
    payload: any;
}
export interface SetTemplatesIdsForDeleteAction {
    type: TemplatesActionTypes.SET_TEMPLATES_IDS_FOR_DELETE;
    payload: Array<string>;
}
export interface UnselectTemplateAction {
    type: TemplatesActionTypes.UNSELECT_TEMPLATE;
    payload: string;
}
export interface SelectTemplateAction {
    type: TemplatesActionTypes.SELECT_TEMPLATE;
    payload: string;
}
export interface SetSelectTemplatesAction {
    type: TemplatesActionTypes.SET_SELECTED_TEMPLATES;
    payload: Array<string>;
}

export type TemplatesAction =
    | GetTemplatesAction
    | GetTemplatesStartAction
    | GetTemplatesFailAction
    | GetTemplatesSuccessAction
    | GetTemplateByIdAction
    | GetTemplateDetailsStartAction
    | GetTemplateDetailsFailAction
    | GetTemplateDetailsSuccessAction
    | UpdateTemplateDetailsStartAction
    | UpdateTemplateDetailsFailAction
    | UpdateTemplateDetailsSuccessAction
    | CreateTemplateAction
    | CreateTemplateFailAction
    | CreateTemplateStartAction
    | CreateTemplateSuccessAction
    | DeleteTemplatesAction
    | DeleteTemplateAction
    | DeleteTemplateStartAction
    | DeleteTemplateSuccessAction
    | DeleteTemplateFailAction
    | SetTemplatesIdsForDeleteAction
    | UnselectTemplateAction
    | SelectTemplateAction
    | SetSelectTemplatesAction;
