import uniqBy from 'lodash.uniqby';

import { AnalysisResultsType } from 'interfaces/analysis';
import { Scene } from 'state/modules/videoExplorer';
import { ClipShot } from 'state/modules/generation';

export const getFilteredScenesByMinSceneLength = (
  timestamps: Array<Scene>,
  minSceneLength: number,
): Array<Scene> => {
  const filteredItems = timestamps.filter((item: Scene) => item.duration >= minSceneLength);

  return filteredItems;
};
export const getFilteredScenesByMaxSceneLength = (
  timestamps: Array<Scene>,
  maxSceneLength: number,
): Array<Scene> => {
  const filteredItems = timestamps.filter((item: Scene) => item.duration <= maxSceneLength);

  return filteredItems;
};

export const getCalculatedClipLength = (scenes: Array<Scene>): number => {
  const durationArray = scenes.map((item: Scene) => item.duration);

  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;

  return durationArray.reduce(reducer, 0);
};

export const getCuttedScenes = (
  timestamps: Array<Scene>,
  maxTotalLength: number,
): Array<Scene> => {
  const cuttedScenes = [] as Array<Scene>;

  timestamps.forEach((item: Scene) => {
    const totalLength = getCalculatedClipLength(cuttedScenes);
    const diff = maxTotalLength - totalLength;
    const { duration } = item;

    if (diff > duration) {
      cuttedScenes.push(item);
    }
  });

  return cuttedScenes;
};

export const getFilteredScenesByScaleFactor = (
  timestamps: Array<Scene>,
  settings: {
        minScaleFactor: number;
    },
): Array<Scene> => {
  const { minScaleFactor } = settings;

  return timestamps.filter(
    (item: Scene) => (item.scaleFactor || 0) >= minScaleFactor,
  );
};

export const getFilteredScenesByConfidence = (
  timestamps: Array<Scene>,
  settings: {
        minConfidence: number;
    },
): Array<Scene> => {
  const { minConfidence } = settings;

  const sentencesItems = timestamps.filter(
    (item: Scene) => item.type === AnalysisResultsType.SENTENCE,
  );
  const restItems = timestamps.filter(
    (item: Scene) => item.type !== AnalysisResultsType.SENTENCE,
  );

  const transformedTimestamps = restItems.map((item: Scene) => ({
    ...item,
    from: item.from > 50 ? item.from : 50,
    to: item.to,
    duration: item.to - item.from,
  }));

  const filteredItems = transformedTimestamps.filter(
    (item: Scene) => (item.confidence || 0) >= minConfidence,
  );

  return [...filteredItems, ...sentencesItems];
};

export const getScenesWithoutDublicates = (
  timestamps: Array<Scene>,
): Array<Scene> => uniqBy(timestamps, (item: Scene) => item.from && item.to);

export const getScenesWithoutInnerScenes = (
  timestamps: Array<Scene>,
): Array<Scene> => {
  const filtered = [] as Array<Scene>;

  timestamps.forEach((item: Scene) => {
    const outerItems = timestamps.filter(
      (tmstmp: Scene) => item.from > tmstmp.from && item.to < tmstmp.to,
    );

    if (outerItems.length === 0) {
      filtered.push(item);
    }
  });

  return filtered;
};

export const shuffle = (data: Array<Scene>): Array<Scene> => {
  // tslint:disable-next-line: one-variable-per-declaration
  const array = [...data];
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const getMixedScenes = (
  selectedTimestamps: Array<Scene>,
): Array<Scene> => {
  let changedArray = shuffle(selectedTimestamps);

  while (selectedTimestamps[0] === changedArray[0]) {
    changedArray = shuffle(selectedTimestamps);
  }

  return changedArray;
};

export const getFormattedScenesForGeneration = (
  scenes: Array<Scene>,
): Array<ClipShot> => {
  const formatedScenes = scenes.map((item: Scene) => ({
    from: item.from,
    to: item.to,
    metadata: item,
  }));

  return formatedScenes;
};

export const getScenesWithUsedScenes = (
  scenes: Array<Scene>,
  usedScenes: Array<Scene>,
  minTotalLength: number,
  maxTotalLength: number,
): Array<Scene> => {
  let currentLength = getCalculatedClipLength(scenes);
  let mixedScenes = scenes;

  scenes.forEach(() => {
    if (currentLength <= minTotalLength) {
      let changedArray = shuffle(usedScenes);

      while (usedScenes[0] === changedArray[0]) {
        changedArray = shuffle(usedScenes);
      }

      mixedScenes.push(changedArray[0]);
      currentLength = getCalculatedClipLength(mixedScenes);
    }

    if (currentLength >= maxTotalLength) {
      mixedScenes = getCuttedScenes(mixedScenes, maxTotalLength);
      currentLength = getCalculatedClipLength(mixedScenes);
    }

    if (
      currentLength <= minTotalLength
            && currentLength >= maxTotalLength
    ) {
      // console.log({ currentLength });
      // console.log('Scenes filtered');
    }
  });

  return mixedScenes;
};

export const getFilteredDataForGeneration = (
  scenes: Array<Scene>,
  settings: {
        minScaleFactor: number;
        minConfidence: number;
        minSceneLength: number;
        maxSceneLength: number;
        isRandomOrder: boolean;
        isCustomWithLimit: boolean;
        maxClipLength: number;
    },
): Array<Scene> => {
  const {
    minScaleFactor,
    minConfidence,
    minSceneLength,
    maxSceneLength,
    isRandomOrder,
    isCustomWithLimit,
    maxClipLength,
  } = settings;

  const scenesWithDuration = scenes.filter((item: Scene) => item.duration);

  const labelsWithScaleFactor = scenesWithDuration.filter(
    (item: Scene) => (item.scaleFactor || 0) > 0
            && item.type === AnalysisResultsType.OBJECT,
  );

  const labelsWithoutScaleFactor = scenesWithDuration.filter(
    (item: Scene) => item.type === AnalysisResultsType.OBJECT && item.scaleFactor === 0,
  );

  let scenesWithoutScaleFactor = scenesWithDuration.filter(
    (item: Scene) => item.type === AnalysisResultsType.SENTENCE
            || item.type === AnalysisResultsType.FACIAL_ATTRIBUTE,
  );

  let scenesWithoutFilters = scenesWithDuration.filter(
    (item: Scene) => item.type === AnalysisResultsType.SHOT
            || item.type === AnalysisResultsType.BLACK_FRAME
            || item.type === AnalysisResultsType.END_CREDITS
            || item.type === AnalysisResultsType.CONTENT
  );

  if (scenesWithoutFilters.length && scenesWithoutFilters[0].from < 50) {
    scenesWithoutFilters = scenesWithoutFilters.map(
      (item: Scene, index: number) => {
        if (index === 0) {
          return {
            ...item,
            from: 50,
          };
        }
        return item;
      },
    );
  }

  let scenesWithScaleFactor = scenesWithDuration.filter(
    (item: Scene) => item.type !== AnalysisResultsType.SENTENCE
            && item.type !== AnalysisResultsType.OBJECT
            && item.type !== AnalysisResultsType.FACIAL_ATTRIBUTE,
  );

  scenesWithoutScaleFactor = [
    ...scenesWithoutScaleFactor,
    ...labelsWithoutScaleFactor,
  ];

  scenesWithScaleFactor = [
    ...scenesWithScaleFactor,
    ...labelsWithScaleFactor,
  ];

  const filteredByScaleFactor = scenesWithScaleFactor.length > 0
    ? getFilteredScenesByScaleFactor(scenesWithScaleFactor, {
      minScaleFactor,
    })
    : [];

  const filteredByConfidence = getFilteredScenesByConfidence(
    [...filteredByScaleFactor, ...scenesWithoutScaleFactor],
    {
      minConfidence,
    },
  );

  const withoutDublicates = getScenesWithoutDublicates([
    ...scenesWithoutFilters,
    ...filteredByConfidence,
  ]);

  const withoutInnerScenes = getScenesWithoutInnerScenes(withoutDublicates);

  const filteredScenesByMinSceneLength = getFilteredScenesByMinSceneLength(
    withoutInnerScenes,
    minSceneLength,
  );

  const filteredScenesByMaxSceneLength = getFilteredScenesByMaxSceneLength(
    filteredScenesByMinSceneLength,
    maxSceneLength,
  );

  const scenesWithoutDublicates = getScenesWithoutDublicates(
    filteredScenesByMaxSceneLength,
  );

  const scenesWithoutInnerScenes = getScenesWithoutInnerScenes(
    scenesWithoutDublicates,
  );

  let timestamps = scenesWithoutInnerScenes;

  const filteredScenesTotalLength = getCalculatedClipLength(timestamps);

  if (isCustomWithLimit && filteredScenesTotalLength > maxClipLength + 1000) {
    timestamps = getCuttedScenes(timestamps, maxClipLength + 1000);
  } else if (
    !isCustomWithLimit
        && filteredScenesTotalLength > maxClipLength + 600
  ) {
    timestamps = getCuttedScenes(timestamps, maxClipLength + 600);
  }

  if (timestamps.length > 1) {
    timestamps = getMixedScenes(timestamps);
  }

  if (!isRandomOrder) {
    const sortedTimestamps = timestamps.sort(
      (a: Scene, b: Scene) => a.from - b.from,
    );
    timestamps = sortedTimestamps;
  }

  return timestamps;
};
