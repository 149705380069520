import {
  MetadataActionTypes,
  TransformedAnalysisResultItem,
  MetadaCategoriesTotalPayload,
  TransformAutocompleteItem,
  TransformedSentence,
  SetObjectsAction,
  SetCelebsAction,
  SetUserDefinedAction,
  SetFacialAttributesAction,
  SetModeratedLabelsAction,
  SetAfterKeyAction,
  GetMetadataAction,
  GetMetadataFailAction,
  GetMetadataStartAction,
  GetTranscriptAction,
  GetTranscriptStartAction,
  GetTranscriptSuccessAction,
  ClearMetadataAction,
  GetTranscriptFailAction,
  GetAutocompleteAction,
  GetMetadaCategoriesTotalAction,
  GetMetadaCategoriesTotalSuccessAction,
  GetAutocompleteStartAction,
  GetAutocompleteSuccessAction,
  GetAutocompleteFailAction,
  ClearAutocompleteItemsAction,
  GetTranscriptBySearchQuerySuccessAction,
  CreateNewSentenceAction,
  SetTranscriptListAction,
  SetTranscriptListTotalAction
} from './types';

export const setObjects = (
  objects: Array<TransformedAnalysisResultItem>,
): SetObjectsAction => ({
  type: MetadataActionTypes.SET_OBJECTS,
  payload: objects,
});

export const setCelebs = (
  celebs: Array<TransformedAnalysisResultItem>,
): SetCelebsAction => ({
  type: MetadataActionTypes.SET_CELEBS,
  payload: celebs,
});

export const setUserDefined = (
  userDefined: Array<TransformedAnalysisResultItem>,
): SetUserDefinedAction => ({
  type: MetadataActionTypes.SET_USER_DEFINED,
  payload: userDefined,
});

export const setFacialAttributes = (
  facialAttributes: Array<TransformedAnalysisResultItem>,
): SetFacialAttributesAction => ({
  type: MetadataActionTypes.SET_FACIAL_ATTRIBUTES,
  payload: facialAttributes,
});

export const setModeratedLabels = (
  moderatedLabels: Array<TransformedAnalysisResultItem>,
): SetModeratedLabelsAction => ({
  type: MetadataActionTypes.SET_MODERATED_LABELS,
  payload: moderatedLabels,
});

export const setAfterKey = (setAfterKey: string): SetAfterKeyAction => ({
  type: MetadataActionTypes.SET_AFTER_KEY,
  payload: setAfterKey,
});

export const getMetadata = (
  id: string,
  category: string,
  confidence: number,
  scaleFactor: number,
  afterKey: string,
  size?: number,
): GetMetadataAction => ({
  type: MetadataActionTypes.GET_METADATA,
  payload: {
    id,
    category,
    confidence,
    scaleFactor,
    afterKey,
    size,
  },
});

export const getMetadataStart = (
  isLoadingMore: boolean,
): GetMetadataStartAction => ({
  type: MetadataActionTypes.GET_METADATA_START,
  payload: isLoadingMore,
});

export const getMetadataFail = (error: any): GetMetadataFailAction => ({
  type: MetadataActionTypes.GET_METADATA_FAIL,
  payload: error,
});

export const getTranscript = (
  id: string,
  query: string,
  offset = 0,
): GetTranscriptAction => ({
  type: MetadataActionTypes.GET_TRANSCRIPT,
  payload: {
    id,
    query,
    offset,
  },
});

export const getTranscriptStart = (): GetTranscriptStartAction => ({
  type: MetadataActionTypes.GET_TRANSCRIPT_START,
});

export const getTranscriptSuccess = (
  transcript: Array<TransformedSentence>,
  total: number,
): GetTranscriptSuccessAction => ({
  type: MetadataActionTypes.GET_TRANSCRIPT_SUCCESS,
  payload: {
    transcript,
    total,
  },
});

export const getTranscriptFail = (error: any): GetTranscriptFailAction => ({
  type: MetadataActionTypes.GET_TRANSCRIPT_FAIL,
  payload: error,
});

export const clearMetadata = (): ClearMetadataAction => ({
  type: MetadataActionTypes.CLEAR_METADATA,
});

export const getMetadaCategoriesTotal = (
  id: string,
): GetMetadaCategoriesTotalAction => ({
  type: MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL,
  payload: {
    id,
  },
});

export const getMetadaCategoriesTotalSuccess = (
  data: MetadaCategoriesTotalPayload,
): GetMetadaCategoriesTotalSuccessAction => ({
  type: MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL_SUCCESS,
  payload: data,
});

export const getAutocomplete = (
  id: string,
  query: string,
  type: string,
): GetAutocompleteAction => ({
  type: MetadataActionTypes.GET_AUTOCOMPLETE,
  payload: {
    id,
    query,
    type,
  },
});

export const getAutocompleteStart = (): GetAutocompleteStartAction => ({
  type: MetadataActionTypes.GET_AUTOCOMPLETE_START,
});

export const getAutocompleteSuccess = (
  autocomplete: Array<TransformAutocompleteItem>,
): GetAutocompleteSuccessAction => ({
  type: MetadataActionTypes.GET_AUTOCOMPLETE_SUCCESS,
  payload: {
    autocomplete,
  },
});

export const getAutocompleteFail = (error: any): GetAutocompleteFailAction => ({
  type: MetadataActionTypes.GET_AUTOCOMPLETE_FAIL,
  payload: error,
});

export const clearAutocompleteItems = (): ClearAutocompleteItemsAction => ({
  type: MetadataActionTypes.CLEAR_AUTOCOMPLETE_ITEMS,
});

export const createNewSentence = (payload: TransformedSentence): CreateNewSentenceAction => ({
  type: MetadataActionTypes.CREATE_NEW_SENTENCE,
  payload,
});

export const getTranscriptBySearchQuerySuccess = (
  transcript: Array<TransformedSentence>,
): GetTranscriptBySearchQuerySuccessAction => ({
  type: MetadataActionTypes.GET_TRANSCRIPT_BY_SEARCH_QUERY_SUCCESS,
  payload: transcript,
});

export const setTranscriptList = (payload: Array<TransformedSentence>): SetTranscriptListAction => ({
  type: MetadataActionTypes.SET_TRANSCRIPT_LIST,
  payload,
});

export const setTranscriptListTotal = (payload: number): SetTranscriptListTotalAction => ({
  type: MetadataActionTypes.SET_TRANSCRIPT_LIST_TOTAL,
  payload,
});
