import { IconProps } from '../index';

const TextOutScaleIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.513 20.23c.603-.666 1.327-1 2.172-1 .844 0 1.554.302 2.129.905.582.595.873 1.315.873 2.158 0 .844-.295 1.567-.884 2.17a2.882 2.882 0 0 1-2.14.903c-.83 0-1.547-.361-2.15-1.084v.893h-.863V17.2h.863v3.03zm2.118 4.285c.604 0 1.115-.223 1.533-.67.426-.446.64-.967.64-1.563 0-.602-.207-1.127-.618-1.573a2.012 2.012 0 0 0-1.544-.67c-.617 0-1.136.21-1.554.627-.412.418-.618.947-.618 1.585 0 .63.21 1.166.628 1.605.419.44.93.66 1.533.66z"
                fill="#7C7C7D" />
            <path
                d="M14.65 25.282h-.765l-.951-2.059h-2.542l-.942 2.06h-.8l2.912-6.396h.219l2.87 6.395zm-2.045-2.79-.934-2.076-.942 2.076h1.877z"
                fill="#525253" />
            <path
                d="M33.365 23.488a4.333 4.333 0 0 1-1.765 1.497c-.718.343-1.5.515-2.343.515-1.311 0-2.41-.431-3.295-1.295-.874-.863-1.312-1.918-1.312-3.166s.443-2.314 1.328-3.198c.885-.894 2.03-1.341 3.436-1.341.833 0 1.598.182 2.295.546.708.364 1.26.873 1.656 1.529l-1.015.639c-.76-.988-1.755-1.482-2.983-1.482-.969 0-1.78.312-2.437.936-.655.614-.983 1.378-.983 2.293 0 .915.322 1.7.968 2.355.645.655 1.463.983 2.452.983 1.187 0 2.186-.5 2.998-1.497l1 .686z"
                fill="#CECECF" />
        </svg>
    );
};

export default TextOutScaleIcon;