import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Slider, NewButton, NewInput } from 'components/UI';

import { getModalEvent, ModalEvent } from 'state/modules/modal';
import {
    addCredits,
    Customer,
    getAddCreditsLoading,
    getCreditsAddon,
    getCustomerInfo,
    getStripePromise,
    PaymentType,
    setupIntent,
} from 'state/modules/payment';

import { Colors } from 'styles';
import { Elements } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';
import { PaymentForm } from '../SelectPlanModal/PaymentForm';

interface Props {
    closeButton?: JSX.Element;
}

const AddMoreCreditsModal = (props: Props): JSX.Element => {
    const [credits, setCredits] = useState(0);
    const creditsAddon = useSelector(getCreditsAddon);
    const modalEvent = useSelector(getModalEvent);
    const stripePromise = useSelector(getStripePromise);
    const customer = useSelector(getCustomerInfo) as Customer;

    const isAddCreditsLoading = useSelector(getAddCreditsLoading);
    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    // const { closeButton } = props;

    const dispatch = useDispatch();

    const handleCreditsChange = (value: number) => {
        setCredits(value);
    };

    const handleGoToPayment = () => {
        if (creditsAddon?.id) {
            setClientSecretLoading(true);
            dispatch(
                setupIntent((error, data) => {
                    setClientSecretLoading(false);
                    if (!error) {
                        setClientSecret(data.clientSecret);
                    }
                })
            );
        }
    };

    const handleCreditsAddonUpdate = () => {
        if (creditsAddon) {
            const addonName =
                modalEvent === ModalEvent.BUY_MORE_ASSETS ||
                modalEvent === ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT
                    ? 'stock media assets'
                    : 'subtitle time';

            dispatch(
                addCredits({
                    data: {
                        credits: {
                            productId: creditsAddon.id,
                            priceId: creditsAddon?.prices?.[0]?.id || '',
                            quantity: credits,
                        },
                    },
                    paymentInfo: {
                        price: credits,
                        name: `Monthly Credits`,
                        type: 'addon',
                        addonName,
                    },
                })
            );
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const numValue = +value;

        if (Number.isNaN(numValue)) {
            return;
        }

        if (!value) {
            setCredits(0);
        } else {
            setCredits(numValue);
        }
    };

    const renderTitle = () => {
        if (modalEvent === ModalEvent.ANALYSIS_PRICE_OVER_LIMIT) {
            return (
                <p
                    className={[
                        styles.AddMoreCreditsModal__title,
                        styles.AddMoreCreditsModal__title_accent,
                    ].join(' ')}
                >
                    Analysis operation price over your limit
                </p>
            );
        }

        return (
            <p className={styles.AddMoreCreditsModal__title}>
                Add more credits
            </p>
        );
    };

    const renderMessage = () => {
        if (modalEvent === ModalEvent.ANALYSIS_PRICE_OVER_LIMIT) {
            return (
                <p className={styles.AddMoreCreditsModal__message}>
                    The analysis operation price is larger than the available
                    minutes. If you’d like to proceed, please buy more minutes
                </p>
            );
        }

        // if (modalEvent === ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT) {
        //   return (
        //     <p className={styles.AddMoreCreditsModal__message}>

        //     </p>
        //   ;
        // }

        return <></>;
    };

    const renderSummary = () => {
        if (
            modalEvent === ModalEvent.BUY_MORE_ASSETS ||
            modalEvent === ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT
        ) {
            return (
                <p className={styles.AddMoreCreditsModal__summary}>
                    Buying {Math.floor(credits / 5)} Stock media assets for $
                    {credits}
                </p>
            );
        }

        return (
            <p className={styles.AddMoreCreditsModal__summary}>
                Buying{' '}
                {moment
                    .duration(credits * 15 * 6 * 10000, 'milliseconds')
                    .format('h[h] m[m] s[s]', {
                        useGrouping: false,
                        trim: 'both',
                    })}{' '}
                subtitling time for ${credits}
            </p>
        );
    };

    if (clientSecret) {
        const urlParams = new URLSearchParams({
            productId: creditsAddon?.id || '',
            priceId: creditsAddon?.prices?.[0]?.id || '',
            quantity: credits.toString(),
            type: PaymentType.credits,
            price: credits.toString(),
            name: `Monthly Credits`,
            paymentType: 'addon',
        }).toString();

        return (
            <div className={styles.AddMoreCreditsModal}>
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    <PaymentForm
                        price={credits}
                        handleClose={() => {
                            setClientSecret(null);
                        }}
                        urlParams={urlParams}
                        noPaymentMethod={!customer?.paymentMethod}
                    />
                </Elements>
                {
                    // closeButton
                }
            </div>
        );
    }

    return (
        <div className={styles.AddMoreCreditsModal}>
            {
                // renderTitle()
            }
            {
                // renderMessage()
            }
            <div className={styles.AddMoreCreditsModal__container}>
                <p className={styles.AddMoreCreditsModal__rangeTitle}>
                    Select an amount
                </p>
                <div className={styles.AddMoreCreditsModal__inputWrap}>
                    <p className={styles.AddMoreCreditsModal__rangeValue}>$</p>
                    <NewInput
                        value={credits}
                        className={styles.AddMoreCreditsModal__input}
                        onChange={handleInputChange}
                    />
                </div>
                <div className={styles.AddMoreCreditsModal__rangeContainer}>
                    <Slider
                        domain={[0, 1000]}
                        onChange={handleCreditsChange}
                        onUpdate={handleCreditsChange}
                        value={+credits}
                        trackStyles={{
                            backgroundColor: 'transparent',
                        }}
                        railInnerStyle={{
                            height: 20,
                            backgroundColor: 'transparent',
                        }}
                        railOuterStyle={{
                            height: 20,
                            border: '2px solid #36396d',
                            borderRadius: 0,
                        }}
                        handleSliderStyle={{
                            background: '#6979a5',
                            width: 35,
                            height: 35,
                        }}
                    />
                </div>
                {renderSummary()}
                {/* <p className={styles.AddMoreCreditsModal__summary}>
                    Buying{' '}
                    {moment
                        .duration(credits * 15 * 6 * 10000, 'milliseconds')
                        .format('h[h] m[m] s[s]', {
                            useGrouping: false,
                            trim: 'both',
                        })}{' '}
                    subtitling time for ${credits}
                </p> */}
            </div>
            <div className={styles.AddMoreCreditsModal__saveButtonContainer}>
                <NewButton
                    color={Colors.GREY3}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={
                        customer?.paymentMethod
                            ? handleCreditsAddonUpdate
                            : handleGoToPayment
                    }
                    disabled={
                        !credits || clientSecretLoading || isAddCreditsLoading
                    }
                    loading={clientSecretLoading || isAddCreditsLoading}
                >
                    {customer?.paymentMethod ? 'Pay' : 'Go to payment'}
                </NewButton>
            </div>
            {/* {closeButton} */}
        </div>
    );
};

export default AddMoreCreditsModal;
