import { createSelector } from 'reselect';
import { State } from 'state/modules';

import { AppState } from './types';

export const getAppState = (state: State): AppState => state.app;

export const getIsStatusBarActiveStatus = createSelector(
    getAppState,
    (state) => state.isStatusBarActive
);

export const getIsBrowserRecommendationActiveStatus = createSelector(
    getAppState,
    (state) => state.isBrowserRecommendationActive
);

export const getAppTourStatus = createSelector(
    getAppState,
    (state) => state.userTourStatus
);

export const getVideoEditingTourStatus = createSelector(
    getAppState,
    (state) => state.videoEditingTourStatus
);

export const getBurningSubtitlesTourStatus = createSelector(
    getAppState,
    (state) => state.burningSubtitlesTourStatus
);

export const getAppInitLoading = createSelector(
    getAppState,
    (state) => state.isAppInitLoading
);
