import React, { Component } from 'react';

import { Icon, NewInput, PasswordInput } from 'components/UI';
import styles from './styles.module.scss';

interface IStepTwoState {
    isPasswordVisible: boolean;
    isConfirmPasswordVisible: boolean;
}

interface IStepTwoProps {
    password: string;
    passwordError: string;
    confirmationCode: string;
    confirmationCodeError: string;
    handleChangeConfirmationCode: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    handleChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleResendCode: () => void;
}

const StepTwo = ({
    passwordError,
    handleChangePassword,
    password,
    handleChangeConfirmationCode,
    confirmationCode,
    confirmationCodeError,
    handleResendCode,
}: IStepTwoProps): JSX.Element => (
    <div className={styles.StepTwo}>
        <div className={styles.StepTwo__inputWrap}>
            <NewInput
                type="text"
                value={confirmationCode}
                onChange={handleChangeConfirmationCode}
                placeholder="Confirmation Code"
            />
            {confirmationCodeError.length ? (
                <p className={styles.StepTwo__inputErrorMessage}>
                    {confirmationCodeError}
                </p>
            ) : null}
        </div>
        <div className={styles.StepTwo__inputWrap}>
            <PasswordInput
                type="password"
                value={password}
                onChange={handleChangePassword}
                placeholder="New password"
            />
            {passwordError.length ? (
                <p className={styles.StepTwo__inputErrorMessage}>
                    {passwordError}
                </p>
            ) : null}
        </div>
        <button
            onClick={handleResendCode}
            className={styles.StepTwo__resendButton}
            type="button"
        >
            Resend code
        </button>
    </div>
);

export default StepTwo;
