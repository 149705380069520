import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import groupBy from 'lodash.groupby';
import without from 'lodash.without';

import * as collectionsActions from 'state/modules/collections/actions';
import * as modalActions from 'state/modules/modal/actions';

import CollectionsClient from 'services/api/collections';

import {
    CollectionsActionTypes,
    getCollectionTempItems,
    getCollectionsItemsListBySearch,
    getCollectionsList,
    getImagesForDelete,
    getSelectedCollectionsForDelete,
    DeleteCollectionAction,
    GetCollectionByIdAction,
    SetCollectionTempItemAction,
    GetCollectionsItemsBySearchAction,
    UploadCollectionItemsAction,
    AddCollectionForDeleteAction,
    RemoveCollectionForDeleteAction,
} from 'state/modules/collections';

import {
    DeleteCollectionsImagesRes,
    GetCollectionsItemsByIdsResponse,
    GetCollectiosItemsBySearchResponse,
    GetCollectiosResponse,
    LoadedCollections,
} from 'models/collections';

import {
    Collection,
    CollectionImage,
    NewCollectionImage,
    CollectionItemToUpload,
    CollectionItemBySearch,
} from 'interfaces/collections';
import { UploadingProgress } from 'interfaces/uploading';

import {
    getTrasformedMetadaForImageUpload,
    imageFileExtensionsMap,
    imageFileTypesMap,
} from 'utils/upload';
import { getTransformedCollections } from 'utils/collectionsHelpers';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { DeleteCollectionsItemsAction } from './types';

const windowFork = window;

export function* loadCollections(): Generator {
    try {
        const res = (yield call(
            CollectionsClient.getCollections
        )) as AxiosResponse<GetCollectiosResponse>;

        if (res.data) {
            const transformedCollection = getTransformedCollections(
                res.data.content
            );

            return {
                collections: transformedCollection,
                total: res.data._metadata.totalCount,
            };
        }
    } catch (err) {
        return {
            collections: [],
            total: 0,
        };
    }
}

export function* createDefaultCollection(): Generator {
    try {
        yield call(CollectionsClient.createCollection, 'Default collection');
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetCollections(): Generator {
    yield put(collectionsActions.getCollectionsStart());

    try {
        const data = (yield call(loadCollections)) as LoadedCollections;

        if (!data.collections.length) {
            yield call(createDefaultCollection);

            yield delay(1000);

            const updatedData = (yield call(
                loadCollections
            )) as LoadedCollections;

            yield put(
                collectionsActions.getCollectionsSuccess(
                    updatedData.collections,
                    updatedData.total
                )
            );
        } else {
            yield put(
                collectionsActions.getCollectionsSuccess(
                    data.collections,
                    data.total
                )
            );
        }
    } catch (err) {
        yield put(collectionsActions.getCollectionsFail(err));
    }
}

export function* handleGetCollectionById(
    action: GetCollectionByIdAction
): Generator {
    yield put(collectionsActions.getCollectionByIdStart());

    try {
        yield call(
            CollectionsClient.getCollectionById,
            action.payload.id,
            action.payload.name
        );
    } catch (err) {
        yield put(collectionsActions.getCollectionByIdFail(err));
    }
}

export function* handleDeleteCollection(
    action: DeleteCollectionAction
): Generator {
    yield put(collectionsActions.deleteCollectionStart());

    const collectionForDelete = action.payload;

    try {
        yield put(
            collectionsActions.addCollectionForDelete(collectionForDelete)
        );
        yield put(collectionsActions.setCollectionDeleting(true));
        yield put(modalActions.hideModal());

        const res = (yield call(
            CollectionsClient.deleteCollection,
            collectionForDelete
        )) as AxiosResponse;

        if (res.data) {
            yield delay(1500);

            yield put(collectionsActions.deleteCollectionSuccess());
            yield put(collectionsActions.getCollections());
            yield delay(2500);
            yield put(collectionsActions.setCollectionDeleting(false));
            yield put(
                collectionsActions.removeCollectionForDelete(
                    collectionForDelete
                )
            );
        }
    } catch (err) {
        yield put(collectionsActions.deleteCollectionFail(err));
        yield put(collectionsActions.setCollectionDeleting(false));
    }
}

export function* deleteCollectionsImages(
    collectionItems: Array<CollectionImage>
): Generator {
    yield put(collectionsActions.deleteCollectionsItemsStart());

    const collections = (yield select(getCollectionsList)) as Array<Collection>;

    const collectionItemsIds = collectionItems.map(
        (collectionItem: CollectionImage) => collectionItem.externalImageId
    );
    const { collectionId } = collectionItems[0];

    try {
        const res = (yield call(
            CollectionsClient.deleteCollectionsImages,
            collectionId,
            collectionItemsIds
        )) as AxiosResponse<DeleteCollectionsImagesRes>;

        if (res.data) {
            const updatedImages = res.data.content.images;

            const updatedCollections = collections.map(
                (collection: Collection) => {
                    if (collection.id === collectionId) {
                        return {
                            ...collection,
                            images: updatedImages,
                        };
                    }
                    return collection;
                }
            );

            yield yield put(
                collectionsActions.deleteCollectionsItemsSuccess(
                    updatedCollections
                )
            );

            const collectionsItemsBySearch = (yield select(
                getCollectionsItemsListBySearch
            )) as Array<CollectionItemBySearch>;

            const filteredCollectionsItemsBySearch =
                collectionsItemsBySearch.map(
                    (item: CollectionItemBySearch) => ({
                        ...item,
                        images: item.images.filter(
                            (image: CollectionImage) =>
                                !collectionItemsIds.includes(
                                    image.externalImageId
                                )
                        ),
                    })
                );
            yield put(
                collectionsActions.getCollectionsItemsBySearchSuccess(
                    filteredCollectionsItemsBySearch
                )
            );
        }
    } catch (err) {
        yield put(collectionsActions.deleteCollectionsItemsFail(err));
    }
}

export function* handleDeleteCollectionsItems(
    action: DeleteCollectionsItemsAction
): Generator {
    try {
        const collectionItems = action.payload;

        const transformedNewImagesForDelete = collectionItems.map(
            (item: CollectionImage) => item.externalImageId
        );

        const oldRemovableImages = (yield select(
            getImagesForDelete
        )) as Array<string>;

        const newRemovableImages = [
            ...oldRemovableImages,
            ...transformedNewImagesForDelete,
        ];

        yield put(
            collectionsActions.addCollectionImagesIdsForDelete(
                newRemovableImages
            )
        );

        const grouped = groupBy(
            collectionItems,
            (collectionItem: CollectionImage) => collectionItem.collectionId
        );

        const transformedGroupedItems = Object.keys(grouped).map(
            (key: string) => ({
                collectionId: key,
                data: grouped[key],
            })
        );

        for (const item of transformedGroupedItems) {
            yield call(deleteCollectionsImages, item.data);
        }

        yield yield put(collectionsActions.setCollectionImagesIdsForDelete([]));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleSetCollectionTempItem(
    action: SetCollectionTempItemAction
): Generator {
    const oldCollectionTempItem = (yield select(
        getCollectionTempItems
    )) as Array<NewCollectionImage>;

    yield put(
        collectionsActions.setCollectionTempItem([
            ...oldCollectionTempItem,
            ...action.payload,
        ])
    );
}

export function* handleGetCollectionsImagesBySearch(
    action: GetCollectionsItemsBySearchAction
): Generator {
    yield put(collectionsActions.getCollectionsItemsBySearchStart());

    try {
        const res = (yield call(
            CollectionsClient.getCollectiosItemsBySearch,
            action.payload
        )) as AxiosResponse<GetCollectiosItemsBySearchResponse>;

        const transformedItems = res.data.content.map(
            (item: CollectionItemBySearch) => ({
                ...item,
                images: item.images.map((image: CollectionImage) => ({
                    ...image,
                    collectionId: item.collectionId,
                })),
            })
        );

        if (res.data) {
            yield put(
                collectionsActions.getCollectionsItemsBySearchSuccess(
                    transformedItems
                )
            );
        }
    } catch (error) {
        yield put(collectionsActions.getCollectionsItemsBySearchFail(error));
    }
}

export function* handleUpdateCollectionsItems(): Generator {
    const collections = (yield select(getCollectionsList)) as Array<Collection>;
    const itemsForUpdate = (yield select(
        getCollectionTempItems
    )) as Array<NewCollectionImage>;
    const { collectionId } = itemsForUpdate[0];
    const itemsIds = itemsForUpdate.map(
        (item: NewCollectionImage) => item.externalImageId
    );
    const imagesIdsString = itemsIds.join(',');

    try {
        const res = (yield call(
            CollectionsClient.getCollectionsItemsByIds,
            collectionId,
            imagesIdsString
        )) as AxiosResponse<GetCollectionsItemsByIdsResponse>;

        if (res.data) {
            const newCollectionImages = res.data.content as any;

            const updatedCollections = collections.map(
                (collection: Collection) => {
                    const transformedNewCollectionImages =
                        newCollectionImages.map(
                            (imageItem: CollectionImage) => ({
                                ...imageItem,
                                collectionId: collection.id,
                            })
                        );

                    if (collection.id === collectionId) {
                        return {
                            ...collection,
                            images: [
                                ...collection.images,
                                ...transformedNewCollectionImages,
                            ],
                        };
                    }
                    return collection;
                }
            );

            const filteredItemsForUpdate = itemsForUpdate.filter(
                (item: NewCollectionImage) => {
                    const isThisItemUploaded =
                        newCollectionImages.findIndex(
                            (imageItem: CollectionImage) =>
                                imageItem.externalImageId ===
                                item.externalImageId
                        ) >= 0;

                    if (!isThisItemUploaded) {
                        return item;
                    }
                }
            );

            yield yield put(
                collectionsActions.setCollectionTempItem(filteredItemsForUpdate)
            );
            yield yield put(
                collectionsActions.updateCollectionsItemsSuccess(
                    updatedCollections
                )
            );

            if (filteredItemsForUpdate.length) {
                yield delay(1500);
                yield put(collectionsActions.updateCollectionsItems());
            }
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* uploadCollectionItem(
    item: CollectionItemToUpload,
    collectionId: string
): Generator {
    try {
        const { name } = item.file;

        const splittedNameString = name.split('.');
        const fileNameExt =
            splittedNameString && splittedNameString.length > 0
                ? splittedNameString.pop()
                : '';
        const rawLowCasefileExt = fileNameExt ? fileNameExt.toLowerCase() : '';

        const contentType =
            imageFileTypesMap[
                rawLowCasefileExt as keyof typeof imageFileTypesMap
            ];
        const fileExt =
            imageFileExtensionsMap[
                rawLowCasefileExt as keyof typeof imageFileExtensionsMap
            ];

        const filename = [
            'collection',
            item.id,
            item.title.trim().split(' ').join('_'),
        ].join('/');

        const transformedMetadata = getTrasformedMetadaForImageUpload({
            imageName: item.title,
            collectionId,
        });

        yield call(Storage.put as any, `${filename}.${fileExt}`, item.file, {
            level: 'private',
            bucket: `${windowFork.config.REACT_APP_DOWNLOAD_TRAILER_BUCKET}`,
            contentType,
            metadata: transformedMetadata,
            progressCallback: (progress: UploadingProgress) => {
                if (progress.loaded === progress.total) {
                    showNotification(
                        NotificationTypes.success,
                        `${item.title} successfully uploaded`
                    );
                }
            },
        });
    } catch (error) {
        console.log({ error });
    }
}

export function* handleUploadCollectionItems(
    action: UploadCollectionItemsAction
): Generator {
    yield put(collectionsActions.uploadCollectionItemsStart());
    const { items, collectionId } = action.payload;

    const transformedItemsToUpdate = items.map(
        (item: CollectionItemToUpload) => ({
            name: item.title,
            externalImageId: item.id,
            collectionId,
        })
    );

    yield put(
        collectionsActions.addCollectionTempItems(transformedItemsToUpdate)
    );

    yield put(modalActions.hideModal());

    try {
        yield all(
            items.map((item: CollectionItemToUpload) =>
                call(uploadCollectionItem, item, collectionId)
            )
        );

        yield put(collectionsActions.uploadCollectionItemsSuccess());

        yield delay(3000);
        yield put(collectionsActions.updateCollectionsItems());
    } catch (error) {
        yield put(collectionsActions.uploadCollectionItemsFail(error));
    }
}

export function* handleAddCollectionForDelete(
    action: AddCollectionForDeleteAction
): Generator {
    const oldCollectionsForDelete = (yield select(
        getSelectedCollectionsForDelete
    )) as Array<string>;

    const updatedCollectionForDelete: Array<string> = [
        ...oldCollectionsForDelete,
        action.payload,
    ];

    yield put(
        collectionsActions.setCollectionsForDelete(updatedCollectionForDelete)
    );
}

export function* handleRemoveCollectionsForDelete(
    action: RemoveCollectionForDeleteAction
): Generator {
    const oldCollectionsForDelete = (yield select(
        getSelectedCollectionsForDelete
    )) as Array<string>;
    const filteredCollectionsForDelete = without(
        oldCollectionsForDelete,
        ...(action.payload as any)
    );

    yield put(
        collectionsActions.setCollectionsForDelete(filteredCollectionsForDelete)
    );
}

export function* collectionsSaga(): Generator {
    yield takeLatest(
        CollectionsActionTypes.GET_COLLECTIONS,
        handleGetCollections
    );
    yield takeLatest(
        CollectionsActionTypes.GET_COLLECTION_BY_ID,
        handleGetCollectionById
    );
    yield takeLatest(
        CollectionsActionTypes.DELETE_COLLECTION,
        handleDeleteCollection
    );
    yield takeLatest(
        CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS,
        handleDeleteCollectionsItems
    );
    yield takeLatest(
        CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_UPDATE,
        handleSetCollectionTempItem
    );

    yield takeLatest(
        CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH,
        handleGetCollectionsImagesBySearch
    );
    yield takeLatest(
        CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS,
        handleUpdateCollectionsItems
    );
    yield takeLatest(
        CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS,
        handleUploadCollectionItems
    );
    yield takeLatest(
        CollectionsActionTypes.ADD_COLLECTION_FOR_DELETE,
        handleAddCollectionForDelete
    );
    yield takeLatest(
        CollectionsActionTypes.REMOVE_COLLECTION_FOR_DELETE,
        handleRemoveCollectionsForDelete
    );
}
