import groupBy from "lodash.groupby";
import { createSelector } from "reselect";

import { State } from "state/modules";
import { SelectedMetadataItem } from "state/modules/videoExplorer";
import {
  ExportState,
  GroupedByVideoExportItem,
  MetadataExportItem,
} from "./types";

export const getExportState = (state: State): ExportState => state.export;

export const getItemsToExport = createSelector(
  getExportState,
  (state) => state.itemsToExport
);

export const getItemToExportSelectedStatus = (id: string) =>
  createSelector(
    getItemsToExport,
    (itemsToExport) =>
      itemsToExport.findIndex(
        (itemToExport: SelectedMetadataItem) => itemToExport.id === id
      ) >= 0
  );

export const getExportList = createSelector(
  getExportState,
  (state) => state.exportList
);

export const getExportListCount = createSelector(
  getExportState,
  (state) => state.exportList.length
);

export const getGroupedExportList = createSelector(getExportState, (state) => {
  const exportList = state.exportList;

  const groupedItems = groupBy(
    exportList,
    (item: SelectedMetadataItem) => item.videoId
  );

  return Object.keys(groupedItems).map((key: string) => ({
    videoName: groupedItems[key][0].videoName,
    data: groupedItems[key],
    videoId: groupedItems[key][0].videoId,
  })) as Array<GroupedByVideoExportItem>;
});

export const getExportListItemIds = createSelector(
  getExportList,
  (exportList) => exportList.map((item: MetadataExportItem) => item.id)
);

export const getItemsToExportByVideoId = (id: string) =>
  createSelector(getExportState, (state) => {
    const itemsToExport = state.itemsToExport;
    return itemsToExport.filter(
      (itemToExport: SelectedMetadataItem) => itemToExport.videoId === id
    );
  });

export const getExportListEmptyStatus = createSelector(
  getExportList,
  (exportList) => exportList.length > 0
);

export const getExportAsVideoLoading = createSelector(
  getExportState,
  (state) => state.isExportAsVideoLoading
);

export const getExportAsVideoParams = createSelector(
  getExportState,
  (state) => state.exportAsVideoParams
);

export const getExportAsVideoLink = createSelector(
  getExportAsVideoParams,
  (params) => params?.clipDownloadLink
);

export const getExportAsVideoClipDownloadedStatus = createSelector(
  getExportAsVideoParams,
  (params) => params?.isClipDownloaded
);

export const getIsExportSubtitledVideoLoading = createSelector(
  getExportState,
  (state) => state.isExportSubtitledVideoLoading
);

export const getIsSubtitledVideoDataLoading = createSelector(
  getExportState,
  (state) => state.isSubtitledVideoDataLoading
);

export const getSubtitledVideoDataInfo = createSelector(
  getExportState,
  (state) => state.subtitledVideoData
);

export const getSubtitlesExportColor = createSelector(
  getExportState,
  (state) => state.subtitlesExportColor
);

export const getSubtitlesExportFontSize = createSelector(
  getExportState,
  (state) => state.subtitlesExportFontSize
);

export const getVideosUsedForExport = createSelector(
  getExportState,
  (state) => state.videosUsedForExport
);

export const getCancelBurningSubtitlesLoading = createSelector(
  getExportState,
  (state) => state.isBurningSubtitlesCancelLoading
);
