import React, { FormEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { NewButton } from 'components/UI';

import { getModalOptionalData, hideModal } from 'state/modules/modal';
import { getFolderUpdateLoading, updateFolder } from 'state/modules/folders';

const CancelButton = styled.div`
    margin-right: 1rem;
`;

const Title = styled.p`
    /* font-family: Inter, sans-serif; */
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
    color: rgb(25, 32, 51);
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
`;

const InputContainer = styled.div``;

const Input = styled.input`
    width: 100%;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(223, 224, 229);
    box-shadow: rgb(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 10px;
    height: 3rem;
    padding: 1rem;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 400;
    margin: 0.5rem 0 1rem;
`;

const Container = styled.form`
    padding: 1.5rem;
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 20px;
    background-color: #fff;
    z-index: 10;
    width: 300px;
`;

const RenameFolderModal = () => {
    const [name, setName] = useState('');

    const { folder } = useSelector(getModalOptionalData);

    const isLoading = useSelector(getFolderUpdateLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        setName(folder.name);
    }, [folder]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleUpdateFolder = (event: any) => {
        event.preventDefault();

        if (!isLoading) {
            dispatch(
                updateFolder({
                    id: folder.id,
                    data: {
                        ...folder,
                        isTemp: false,
                        name,
                    },
                    hideModal: true,
                })
            );
        }
    };

    return (
        <Container onSubmit={handleUpdateFolder}>
            <Title>Rename Folder</Title>
            <InputContainer>
                <Input value={name} onChange={handleChangeName} />
            </InputContainer>
            <Actions>
                <CancelButton>
                    <NewButton
                        disabled={isLoading}
                        borderRadius={10}
                        color="rgb(247, 247, 248)"
                        textColor="rgb(25, 32, 51)"
                        hoverTextColor="rgb(0, 152, 253)"
                        hoverBoxShadow="rgb(0, 0, 0, 0.1) 0px 1px 2px"
                        hoverBorder="1px solid rgb(103, 193, 255)"
                        height={48}
                        onClick={handleClose}
                    >
                        Cancel
                    </NewButton>
                </CancelButton>
                <NewButton
                    loading={isLoading}
                    disabled={isLoading}
                    borderRadius={10}
                    color="rgb(25, 32, 51)"
                    textColor="rgb(255, 255, 255)"
                    height={48}
                    hoverBoxShadow="rgb(0, 0, 0, 0.2) 0px 5px 6px"
                    type="submit"
                >
                    Rename
                </NewButton>
            </Actions>
        </Container>
    );
};

export default RenameFolderModal;
