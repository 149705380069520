import {
  all, call, delay, put, select, takeLatest,
} from 'redux-saga/effects';

import * as analysisActions from 'state/modules/analysis/actions';
import * as videosActions from 'state/modules/media/actions';
import * as modalActions from 'state/modules/modal/actions';
import * as videoExplorerActions from 'state/modules/videoExplorer/actions';
import * as paymentActions from 'state/modules/payment/actions';

import { AnalysisActionTypes, ReanalysisReqData } from 'state/modules/analysis';
import { getSelectedVideos, getVideoInfo } from 'state/modules/media';

import AnalysisClient from 'services/api/analysis';

import { MediaFile } from 'interfaces/videos';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { ReanalyzeSpecificVideoAction, ReanalyzeVideosAction, TranslateMediaAction } from './types';

function* reanalyzeVideo(
  videoId: string,
  collectionId: string,
  categories: Array<string>,
  language: string | null,
): Generator {
  const data = {
    collectionId,
    categories,
    language,
  } as ReanalysisReqData;
  
  try {
    return yield call(AnalysisClient.reanalyze, videoId, data);
  } catch (error) {
    console.log({ error });
    yield call(
      showNotification,
      NotificationTypes.error,
      (error as any)?.response?.data?.message,
    );
  }
}

function* handleReanalyzeVideos(action: ReanalyzeVideosAction): Generator {
  yield put(analysisActions.reanalyzeVideosStart());
  const selectedVideos = (yield select(
    getSelectedVideos,
  )) as Array<MediaFile>;

  const { collectionId, categories, language } = action.payload;

  const filteredCategories = categories.filter(
    (item: string) => item !== 'face_matches' && item !== 'moderated_labels',
  );

  try {
    yield all(
      selectedVideos.map((video: MediaFile) => call(
        reanalyzeVideo,
        video.id,
        collectionId,
        filteredCategories,
        language,
      )),
    );

    yield delay(3500);

    yield put(videosActions.getVideoList());
    yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
    yield put(paymentActions.getUserUsage());
    yield put(analysisActions.reanalyzeVideosSuccess());
    yield put(videosActions.setSelectedVideos([]));
    yield put(modalActions.hideModal());
  } catch (err) {
    yield put(analysisActions.reanalyzeVideosFail(err));
    yield call(
      showNotification,
      NotificationTypes.error,
      (err as any)?.response?.data?.message,
    );
  }
}

function* handleReanalyzeSpecificVideo(
  action: ReanalyzeSpecificVideoAction,
): Generator {
  yield put(analysisActions.reanalyzeVideosStart());
  
  const selectedVideos = (yield select(
    getSelectedVideos,
  )) as Array<MediaFile>;
  const {
    collectionId, 
    categories, 
    language, 
    videoId,
  } = action.payload;

  const filteredCategories = categories.filter(
    (item: string) => item !== 'face_matches' && item !== 'moderated_labels',
  );

  const filteredSelectedVideos = selectedVideos.filter(
    (item: MediaFile) => item.id !== videoId,
  );
 
  try {
    yield call(
      reanalyzeVideo,
      videoId,
      collectionId,
      filteredCategories,
      language,
    );

    yield delay(3500);

    yield put(videosActions.getVideoList());
    yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
    yield put(analysisActions.reanalyzeVideosSuccess());
    yield put(videosActions.setSelectedVideos(filteredSelectedVideos));
    yield put(paymentActions.getUserUsage());

    yield put(modalActions.hideModal());
  } catch (err) {
    console.log({ err });
    yield put(analysisActions.reanalyzeVideosFail(err));
    yield call(
      showNotification,
      NotificationTypes.error,
      (err as any)?.response?.data?.message,
    );
  }
}

function* handleTranslateMedia(
  action: TranslateMediaAction,
): Generator {
  const mediaInfo = (yield select(
    getVideoInfo,
  )) as MediaFile;

  try {
    yield call(AnalysisClient.reanalyze, mediaInfo.id, action.payload);

    yield delay(3500);

    yield put(videosActions.getVideoList());
    yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
  } catch (error) {
    console.log({ error });
  }
}

export function* analysisSaga(): Generator {
  yield takeLatest(
    AnalysisActionTypes.TRANSLATE_MEDIA,
    handleTranslateMedia,
  );
  yield takeLatest(
    AnalysisActionTypes.REANALYZE_VIDEOS,
    handleReanalyzeVideos,
  );
  yield takeLatest(
    AnalysisActionTypes.REANALYZE_SPECIFIC_VIDEO,
    handleReanalyzeSpecificVideo,
  );
}
