import groupBy from 'lodash.groupby';

import { MetadataExportItem } from 'state/modules/export';
import { AnalysisResultTimestamp } from 'state/modules/metadata';
import { Scene } from 'state/modules/videoExplorer';

import {
  EdlExportItem,
  EdlExportData,
  GroupedByTypeEdlExportData,
  TempTransformedEdlExportItem,
  EdlExportScene,
  TransformedEdlExportItem,
  EdlExportSettingsOptionsTitle,
  EdlFileSettingsOption,
  EdlExportFpsOption,
  EdlMergeIntervalOption,
  TransformedOffsetSettings,
} from 'interfaces/edlExport';
import { isSentenceExportItem } from './interfaceChecker';

export const edlMergeIntervalOptions = [
  {
    value: 1,
    id: 1,
  },
  {
    value: 2,
    id: 2,
  },
  {
    value: 3,
    id: 3,
  },
  {
    value: 5,
    id: 5,
  },
  {
    value: 10,
    id: 10,
  },
  {
    value: 20,
    id: 20,
  },
  {
    value: 30,
    id: 30,
  },
] as Array<EdlMergeIntervalOption>;

export const edlExportFpsOptions = [
  {
    value: 23.976,
    id: 1,
  },
  {
    value: 24,
    id: 2,
  },
  {
    value: 25,
    id: 3,
  },
  {
    value: 30,
    id: 4,
  },
  {
    value: 60,
    id: 5,
  },
] as Array<EdlExportFpsOption>;

export const edlFileSettingsOptions = [
  {
    value: EdlExportSettingsOptionsTitle.ObjectPerFile,
    id: 1,
  },
  {
    value: EdlExportSettingsOptionsTitle.VideoPerFile,
    id: 2,
  },
] as Array<EdlFileSettingsOption>;





export const getEdlGroupedTimestampsByMergeInterval = (
  timestamps: Array<number>,
  mergeInterval: number,
): Array<Array<number>> => {
  const sortedTimestamps = timestamps.slice().sort((a: number, b: number) => a - b);

  const result = [] as Array<Array<number>>;

  sortedTimestamps.reduce(
    (accumulator: number, currentValue: number, currentIndex) => {
      if (currentIndex === 0) {
        result.push([currentValue]);
      } else if (+currentValue - accumulator <= mergeInterval * 1000) {
        result[result.length - 1].push(currentValue);
      } else {
        result.push([currentValue]);
      }

      return currentValue;
    },
    0,
  );

  return result;
};

/// ////////////

export const transformDataForEdlExport = (
  selectedItems: Array<MetadataExportItem>,
): EdlExportData => {
  const transformedItems = selectedItems.map((item: MetadataExportItem) => ({
    ...item,
    timestamps: item.timestamps.map(
      (timestampItem: AnalysisResultTimestamp) => timestampItem.timestamp,
    ),
  })) as Array<EdlExportItem>;

  const groupedByVideo = groupBy(
    transformedItems,
    (item: MetadataExportItem) => item.videoId,
  );

  const dataForExport = {
    selectedItems: transformedItems,
    groupedData: {},
  } as EdlExportData;

  Object.keys(groupedByVideo).forEach((key: string) => {
    const groupedItemsByType = groupBy(
      groupedByVideo[key],
      (item: EdlExportItem) => item.type,
    ) as any;
    // ) as GroupedByTypeEdlExportData;

    dataForExport.groupedData[key] = groupedItemsByType;
  });

  return dataForExport;
};

export const getEdlTransformedAnalyzerDataForExport = (
  selectedItems: Array<EdlExportItem>,
  mergeInterval: number,
  offsetSettings: Array<TransformedOffsetSettings>
): Array<TempTransformedEdlExportItem> => {
  const data = [] as Array<TempTransformedEdlExportItem>;

  selectedItems.forEach((item: EdlExportItem) => {
    const sortedScenes = item.scenes.slice().sort((a: Scene, b: Scene) => a.from - b.from);

    const result = [] as Array<Array<EdlExportScene>>;

    sortedScenes.reduce(
      (
        accumulator: null | Scene,
        currentValue: Scene,
        currentIndex: number,
      ) => {
        if (currentIndex === 0 || !accumulator?.to) {
          result.push([
            {
              from: currentValue.from,
              to: currentValue.to,
            },
          ]);
        } else if (
          currentValue.from - accumulator.to
                    <= mergeInterval * 1000
        ) {
          result[result.length - 1].push({
            from: currentValue.from,
            to: currentValue.to,
          });
        } else {
          result.push([
            {
              from: currentValue.from,
              to: currentValue.to,
            },
          ]);
        }

        return currentValue;
      },
      null,
    );

    const offset = offsetSettings.find((offsetSettingsItem) => offsetSettingsItem.id === item.videoId);
    
    const name = item.name || (isSentenceExportItem(item) && item.data) || '';
    const originalFileName = item.videoName;

    result.forEach((scenes: Array<EdlExportScene>) => {
      const startTime = scenes[0].from + (offset?.value || 0);
      const endTime = scenes[scenes.length - 1].to + (offset?.value || 0);

      data.push({
        startTime,
        endTime,
        name,
        originalFileName,
      });
    });
  });

  return data;
};

export const getTransformedEdlDataForExport = (
  selectedItems: Array<EdlExportItem>,
  mergeInterval: number,
  offsetSettings: Array<TransformedOffsetSettings>
): Array<TempTransformedEdlExportItem> => {
  const data = getEdlTransformedAnalyzerDataForExport(
    selectedItems,
    mergeInterval,
    offsetSettings
  ) as Array<TempTransformedEdlExportItem>;

  return data;
};

export const getEdlExportDataWithObjectPerFile = (
  selectedItems: Array<EdlExportItem>,
  mergeInterval: number,
  offsetSettings: Array<TransformedOffsetSettings>,
): Array<TransformedEdlExportItem> => {

  const data = getTransformedEdlDataForExport(
    selectedItems,
    mergeInterval,
    offsetSettings,
  ) as Array<TempTransformedEdlExportItem>;

  return data.map((item: TempTransformedEdlExportItem) => ({
    ...item,
    FileName: `${item.originalFileName}.${item.name.substring(
      0,
      5,
    )}.edl`,
  }));
};

export const getEdlExportDataWithVideoPerFile = (
  selectedItems: Array<EdlExportItem>,
  mergeInterval: number,
  offsetSettings: Array<TransformedOffsetSettings>,
): Array<TransformedEdlExportItem> => {
  const data = getTransformedEdlDataForExport(selectedItems, mergeInterval, offsetSettings);

  return data.map((item: TempTransformedEdlExportItem) => {
    const itemsfromSameVideo = selectedItems
      .filter(
        (selectedItem: EdlExportItem) => selectedItem.videoName === item.originalFileName,
      )
      .map(
        (filteredItem: EdlExportItem) => filteredItem.name
                    || (isSentenceExportItem(filteredItem) && filteredItem.data),
      ) as Array<string>;

    let FileName = item.originalFileName;

    itemsfromSameVideo.forEach((itemNameItem: string, index: number) => {
      if (index <= 5) {
        FileName = `${FileName}.${itemNameItem
          .replace(' ', '')
          .substring(0, 5)}`;
      }
    });

    return {
      ...item,
      FileName: `${FileName}.edl`,
    };
  });
};
