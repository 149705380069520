import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import prettyBytes from 'pretty-bytes';

import BillingPeriodSelect from 'components/BillingPeriodSelect';
import PaymentMethods from 'components/PaymentMethods';

import { NewButton } from 'components/UI';

import { getModalEvent, ModalEvent, hideModal } from 'state/modules/modal';
import {
    createSubscriptionForCustomer,
    CurrentSubscription,
    getAdditionalStorageAddon,
    getCurrentUserPlan,
    getCustomerInfo,
    getSelectedPlan,
    getSubscriptionCreationLoading,
    Price,
    Product,
    UpdateSubscriptionBody,
} from 'state/modules/payment';

import { useStripe } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const UpgradePlanModal = (props: Props): JSX.Element => {
    const planData = useSelector(getSelectedPlan) as Product;
    const currentPlan = useSelector(getCurrentUserPlan) as CurrentSubscription;
    const customer = useSelector(getCustomerInfo);
    const storageAddon = useSelector(getAdditionalStorageAddon) as Product;

    const [periods, setPeriods] = useState<Array<Price>>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<Price | null>(null);
    const [selectedCardId, setSelectedCardId] = useState('');

    const [isTermsConditionsAccepted, setTermsConditionsAccepted] =
        useState(false);

    const stripe = useStripe();

    const { closeButton } = props;

    const modalEvent = useSelector(getModalEvent);
    const isSubscriptionCreationLoading = useSelector(
        getSubscriptionCreationLoading
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setPeriods(planData?.prices || []);

        if (!currentPlan?.isTrial && planData) {
            const price = (planData.prices as Array<Price>).find(
                (item: Price) => item.id === currentPlan?.price?.id
            ) as Price;

            setSelectedPeriod(price);
        } else if (planData?.prices) {
            setSelectedPeriod(planData.prices[0]);
        }
    }, [planData, currentPlan]);

    const handleChangeBillingPeriod = (period: Price) => {
        setSelectedPeriod(period);
    };

    const toggleTermsConditionsAccepted = () => {
        setTermsConditionsAccepted(!isTermsConditionsAccepted);
    };

    const cancel = () => {
        dispatch(hideModal());
    };

    const handleSubmit = () => {
        let subscriptionBody = {
            productId: planData.id,
            priceId: selectedPeriod?.id,
            isTrial: Boolean(planData.isTrial),
            cardId: selectedCardId,
        } as UpdateSubscriptionBody;

        if (currentPlan?.addon?.priceId && storageAddon.prices) {
            const newAddonPrice = storageAddon.prices.find(
                (price: Price) => price.interval === selectedPeriod?.interval
            );

            subscriptionBody = {
                ...subscriptionBody,
                addonProductId: currentPlan.addon?.productId,
                addonPriceId: newAddonPrice?.id,
            };
        }

        dispatch(
            createSubscriptionForCustomer({
                data: {
                    subscription: subscriptionBody,
                },
                paymentInfo: {
                    price: 0,
                    name: '',
                    type: 'plan',
                },
                stripe,
            })
        );
    };

    const getUpgradePlanDescription = () => {
        let description = '';

        const storageDescription = `${
            planData?.availableStorage
                ? prettyBytes(Number(planData?.availableStorage) || 0)
                : 0
        } storage`;
        let creditsDescription = '';

        if (selectedPeriod?.interval === 'month') {
            creditsDescription = `${
                selectedPeriod.amount / 100
            } A.I. credits / month`;
        } else if (selectedPeriod?.interval === 'year') {
            creditsDescription = `${selectedPeriod.amount / 100} A.I. credits`;
        }

        description = `Unlimited video length. ${storageDescription}. ${creditsDescription}`;

        return description;
    };

    const getDescription = () => {
        let description = '';

        if (modalEvent === ModalEvent.UPLOAD_LENGTH_OVER_LIMIT) {
            description =
                'You are trying to upload a file that is longer than your free trial’s limit. If you’d like to proceed, please sign up to a Pro account, or try to upload a file shorter than 5 minutes.';
        } else if (modalEvent === ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT) {
            let availableStorage = 0;

            if (currentPlan?.availableStorage) {
                availableStorage = +currentPlan?.availableStorage;
            }

            description = `You are trying to upload a file that is bigger than your free trial’s limit. If you’d like to proceed, please upgrade to a Pro account, or try to upload a file smaller than ${prettyBytes(
                availableStorage
            )}.`;
        } else if (modalEvent === ModalEvent.UPGRADE_PLAN) {
            description = getUpgradePlanDescription();
        }

        return description;
    };

    const getTitle = () => {
        let title = '';

        if (modalEvent === ModalEvent.UPLOAD_LENGTH_OVER_LIMIT) {
            title = 'UPLOAD LENGTH OVER YOUR FREE LIMIT';
        } else if (modalEvent === ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT) {
            title = 'UPLOAD SIZE OVER YOUR FREE LIMIT';
        } else if (modalEvent === ModalEvent.UPGRADE_PLAN) {
            title = `Upgrade to a ${planData?.name} account`;
        }

        return title;
    };

    const handleCardClick = (id: string) => {
        setSelectedCardId(id);
    };

    const renderPaymentMethodBlock = () => (
        <div className={styles.UpgradePlanModal__paymentMethodContainer}>
            <PaymentMethods
                showFooter
                centered
                onClick={handleCardClick}
                selectedCardId={selectedCardId}
            />
        </div>
    );

    const renderTitle = () => {
        const title = getTitle();

        return (
            <p
                className={[
                    styles.UpgradePlanModal__title,
                    modalEvent === ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT ||
                    modalEvent === ModalEvent.UPLOAD_LENGTH_OVER_LIMIT
                        ? styles.UpgradePlanModal__title_danger
                        : null,
                ].join(' ')}
            >
                {title}
            </p>
        );
    };

    const renderDescription = () => {
        const description = getDescription();

        return (
            <p className={styles.UpgradePlanModal__description}>
                {description}
            </p>
        );
    };

    const renderUpgradeButton = () => (
        <NewButton
            color="#6979A5"
            textColor="white"
            borderRadius={5}
            width={132}
            height={46}
            disabled={
                !isTermsConditionsAccepted ||
                isSubscriptionCreationLoading ||
                !customer?.cards?.length ||
                selectedPeriod?.id === currentPlan?.price?.id
            }
            loading={isSubscriptionCreationLoading}
            onClick={handleSubmit}
        >
            Upgrade
        </NewButton>
    );

    const renderBillingPeriodSelect = () => (
        <BillingPeriodSelect
            periods={periods}
            selectedPeriod={selectedPeriod}
            changePeriod={handleChangeBillingPeriod}
            isTermsConditionsAccepted={isTermsConditionsAccepted}
            toggleTermsConditionsAccepted={toggleTermsConditionsAccepted}
            showTermsConditionsToggler
            description={`Includes ${prettyBytes(
                Number(planData?.availableStorage) || 0
            )} of storage. Cancel anytime.`}
        />
    );

    return (
        <div className={styles.UpgradePlanModal}>
            {renderTitle()}
            {renderDescription()}
            {renderPaymentMethodBlock()}
            {renderBillingPeriodSelect()}
            <div className={styles.UpgradePlanModal__buttonsContainer}>
                {renderUpgradeButton()}
                <NewButton
                    color="white"
                    textColor="#000829"
                    borderColor="#6979A5"
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={cancel}
                >
                    Cancel
                </NewButton>
            </div>
            {closeButton}
        </div>
    );
};

export default UpgradePlanModal;
