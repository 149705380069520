import React, { memo, CSSProperties, RefObject } from 'react';
import styled from 'styled-components';

import { ButtonLoader } from 'components/UI';

import { Sizes, Typography } from 'styles';

const LeftContainer = styled.div`
    margin-right: auto;
`;

const Children = styled.div`
    margin: 0 auto;
    flex: 3;
`;

const Container = styled.button<{
    hoverColor?: string;
    hoverTextColor?: string;
    hoverBoxShadow?: string;
    hoverBorder?: string;
}>`
    position: relative;
    outline: none;
    font-size: 14px;
    border: 0;
    cursor: pointer;
    min-height: 27px;
    min-width: 75px;
    font-family: 'Inter', sans-serif;
    padding: 3px 5px;
    width: 100%;
    display: flex;
    align-items: center;
    transition: border 0.2s ease-in-out 0s;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        ${({ hoverColor }) =>
            hoverColor ? `background-color: ${hoverColor}!important;` : ''}
        ${({ hoverTextColor }) =>
            hoverTextColor ? `color: ${hoverTextColor}!important;` : ''}
        ${({ hoverBoxShadow }) =>
            hoverBoxShadow ? `box-shadow: ${hoverBoxShadow}!important;` : ''}
        ${({ hoverBorder }) =>
            hoverBorder ? `border: ${hoverBorder}!important;` : ''}
    }
`;

interface ButtonProps {
    children?: JSX.Element | string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    leftContainer?: JSX.Element;
    loading?: boolean;
    color: string;
    textColor?: string;
    size?: string;
    fontWeight?: string;
    bordered?: boolean;
    borderColor?: string;
    borderRadius?: number;
    borderWidth?: number;
    width?: number;
    height?: number;
    className?: string;
    id?: string;
    refObject?: RefObject<HTMLButtonElement>;
    type?: 'button' | 'submit' | 'reset' | undefined;
    hoverColor?: string;
    textAlign?:
        | 'start'
        | 'end'
        | 'left'
        | 'right'
        | 'center'
        | 'justify'
        | 'match-parent';
    hoverTextColor?: string;
    hoverBoxShadow?: string;
    hoverBorder?: string;
}

/**
 * Button component
 * ================
 * Renders custom button
 */
// const NewButton: React.FC<ButtonProps> = memo(
const NewButton: React.FC<ButtonProps> = memo(
    ({
        children,
        onClick,
        disabled,
        leftContainer,
        loading,
        color,
        size,
        textColor,
        fontWeight,
        bordered,
        borderColor,
        borderRadius,
        width,
        height,
        className,
        id,
        refObject,
        type,
        borderWidth,
        textAlign,
        hoverColor,
        hoverTextColor,
        hoverBoxShadow,
        hoverBorder,
    }) => {
        const buttonClasses = [].join(' ');

        let buttonStyles: CSSProperties = {
            backgroundColor: color,
            color: textColor,
        };

        if (size) {
            const { width, height } = Sizes.ButtonSizes[size as string];
            const borderRadius = Sizes.ButtonBorderRadius[size as string];

            buttonStyles = {
                ...buttonStyles,
                minWidth: width,
                minHeight: height,
                borderRadius,
            };
        }

        if (height) {
            buttonStyles.height = height;
        }

        if (width) {
            buttonStyles.width = width;
        }

        if (fontWeight) {
            buttonStyles.fontWeight =
                Typography.FontWeight[fontWeight as string];
        }

        if (bordered) {
            buttonStyles = {
                ...buttonStyles,
                borderWidth: 1,
                borderStyle: 'solid',
            };
        }

        if (borderColor) {
            buttonStyles.borderColor = borderColor;
        }

        if (borderRadius) {
            buttonStyles.borderRadius = borderRadius;
        }

        if (borderWidth) {
            buttonStyles.borderWidth = borderWidth;
        }

        if (textAlign) {
            buttonStyles.textAlign = textAlign || 'center';
        }

        if (disabled) {
            buttonStyles = {
                ...buttonStyles,
                backgroundColor: '#8e9bbc',
                borderColor: 'transparent',
                color: '#ffffff',
            };
        }

        return (
            <Container
                ref={refObject}
                onClick={onClick}
                className={[buttonClasses, className || null].join(' ')}
                type={type || 'button'}
                disabled={disabled}
                style={buttonStyles}
                id={id || `${Math.random()}`}
                hoverColor={hoverColor}
                hoverTextColor={hoverTextColor}
                hoverBoxShadow={hoverBoxShadow}
                hoverBorder={hoverBorder}
            >
                {leftContainer ? (
                    <LeftContainer>{leftContainer}</LeftContainer>
                ) : null}
                <Children>{loading ? <ButtonLoader /> : children}</Children>
            </Container>
        );
    }
);

export default NewButton;
