import {
    InviteToWorkspace,
    InviteToWorkspaceStatuses,
    Workspace,
} from 'interfaces/workspaces';
import { IUpdateWorkspace } from 'models/workspaces';

export interface WorkspacesState {
    isLoading: boolean;
    items: Workspace[];
    currentWorkspace: Workspace | null;
    isUpdateLoading: boolean;

    inviteeWorkspaces: Workspace[];
    isInviteeWorkspacesLoading: boolean;

    isInviteModalVisible: boolean;
    isSendInviteLoading: boolean;
    inviteeInvites: InviteToWorkspace[];
    isInviteeInvitesLoading: boolean;
    inviterInvites: InviteToWorkspace[];
    isInviterInvitesLoading: boolean;
    isPinInviteLoading: boolean;
    isUpdateInviteeInviteLoading: boolean;
    isUpdateInviterInviteLoading: boolean;

    isRevokeInviteLoading: boolean;

    isWorkspaceFlowLoading: boolean;

    // create
    isCreateLoading: boolean;
}

export enum WorkspaceActionTypes {
    // workspaces
    GET_WORKSPACES = '@@workspaces/GET_WORKSPACES',
    GET_WORKSPACES_START = '@@workspaces/GET_WORKSPACES_START',
    GET_WORKSPACES_FAIL = '@@workspaces/GET_WORKSPACES_FAIL',
    SET_WORKSPACES = '@@workspaces/SET_WORKSPACES',
    // invitee workspaces
    GET_INVITEE_WORKSPACES = '@@workspaces/GET_INVITEE_WORKSPACES',
    GET_INVITEE_WORKSPACES_START = '@@workspaces/GET_INVITEE_WORKSPACES_START',
    GET_INVITEE_WORKSPACES_FAIL = '@@workspaces/GET_INVITEE_WORKSPACES_FAIL',
    SET_INVITEE_WORKSPACES = '@@workspaces/SET_INVITEE_WORKSPACES',
    // current workspace
    GET_CURRENT_WORKSPACE = '@@workspaces/GET_CURRENT_WORKSPACE',
    GET_CURRENT_WORKSPACE_START = '@@workspaces/GET_CURRENT_WORKSPACE_START',
    GET_CURRENT_WORKSPACE_FAIL = '@@workspaces/GET_CURRENT_WORKSPACE_FAIL',
    SET_CURRENT_WORKSPACE = '@@workspaces/SET_CURRENT_WORKSPACE',
    //
    UPDATE_WORKSPACE = '@@workspaces/UPDATE_WORKSPACE',
    UPDATE_WORKSPACE_START = '@@workspaces/UPDATE_WORKSPACE_START',
    UPDATE_WORKSPACE_FAIL = '@@workspaces/UPDATE_WORKSPACE_FAIL',
    UPDATE_WORKSPACE_SUCCESS = '@@workspaces/UPDATE_WORKSPACE_SUCCESS',
    // invite modal
    SET_WORKSPACE_INVITE_MODAL_VISIBLE = '@@workspaces/SET_WORKSPACE_INVITE_MODAL_VISIBLE',
    // send invite
    SEND_WORKSPACE_INVITE = '@@workspaces/SEND_WORKSPACE_INVITE',
    SEND_WORKSPACE_INVITE_START = '@@workspaces/SEND_WORKSPACE_INVITE_START',
    SEND_WORKSPACE_INVITE_FAIL = '@@workspaces/SEND_WORKSPACE_INVITE_FAIL',
    SEND_WORKSPACE_INVITE_SUCCESS = '@@workspaces/SEND_WORKSPACE_INVITE_SUCCESS',
    // get invitee invites
    GET_INVITEE_WORKSPACE_INVITES = '@@workspaces/GET_INVITEE_WORKSPACE_INVITES',
    GET_INVITEE_WORKSPACE_INVITES_START = '@@workspaces/GET_INVITEE_WORKSPACE_INVITES_START',
    GET_INVITEE_WORKSPACE_INVITES_FAIL = '@@workspaces/GET_INVITEE_WORKSPACE_INVITES_FAIL',
    GET_INVITEE_WORKSPACE_INVITES_SUCCESS = '@@workspaces/GET_INVITEE_WORKSPACE_INVITES_SUCCESS',
    // get inviter invites
    GET_INVITER_WORKSPACE_INVITES = '@@workspaces/GET_INVITER_WORKSPACE_INVITES',
    GET_INVITER_WORKSPACE_INVITES_START = '@@workspaces/GET_INVITER_WORKSPACE_INVITES_START',
    GET_INVITER_WORKSPACE_INVITES_FAIL = '@@workspaces/GET_INVITER_WORKSPACE_INVITES_FAIL',
    GET_INVITER_WORKSPACE_INVITES_SUCCESS = '@@workspaces/GET_INVITER_WORKSPACE_INVITES_SUCCESS',
    // Pin invite with email
    PIN_INVITE_WITH_EMAIL = '@@workspaces/PIN_INVITE_WITH_EMAIL',
    PIN_INVITE_WITH_EMAIL_START = '@@workspaces/PIN_INVITE_WITH_EMAIL_START',
    PIN_INVITE_WITH_EMAIL_FAIL = '@@workspaces/PIN_INVITE_WITH_EMAIL_FAIL',
    PIN_INVITE_WITH_EMAIL_SUCCESS = '@@workspaces/PIN_INVITE_WITH_EMAIL_SUCCESS',
    // Update invitee invite status
    UPDATE_INVITEE_INVITE_STATUS = '@@workspaces/UPDATE_INVITEE_INVITE_STATUS_STATUS',
    UPDATE_INVITEE_INVITE_STATUS_START = '@@workspaces/UPDATE_INVITEE_INVITE_STATUS_START',
    UPDATE_INVITEE_INVITE_STATUS_FAIL = '@@workspaces/UPDATE_INVITEE_INVITE_STATUS_FAIL',
    UPDATE_INVITEE_INVITE_STATUS_SUCCESS = '@@workspaces/UPDATE_INVITEE_INVITE_STATUS_SUCCESS',
    // Update inviter invite status
    UPDATE_INVITER_INVITE_STATUS = '@@workspaces/UPDATE_INVITER_INVITE_STATUS_STATUS',
    UPDATE_INVITER_INVITE_STATUS_START = '@@workspaces/UPDATE_INVITER_INVITE_STATUS_START',
    UPDATE_INVITER_INVITE_STATUS_FAIL = '@@workspaces/UPDATE_INVITER_INVITE_STATUS_FAIL',
    UPDATE_INVITER_INVITE_STATUS_SUCCESS = '@@workspaces/UPDATE_INVITER_INVITE_STATUS_SUCCESS',
    // Chenge workspace
    CHANGE_WORKSPACE = '@@workspaces/CHANGE_WORKSPACE',
    // Delete invite
    DELETE_INVITE = '@@workspaces/DELETE_INVITE',
    DELETE_INVITE_START = '@@workspaces/DELETE_INVITE_START',
    DELETE_INVITE_FAIL = '@@workspaces/DELETE_INVITE_FAIK',
    DELETE_INVITE_SUCCESS = '@@workspaces/DELETE_INVITE_SUCCESS',
    // Accept and navigate
    ACCEPT_INVITE_AND_CHANGE_WORKSPACE = '@@workspaces/ACCEPT_INVITE_AND_CHANGE_WORKSPACE',
    // Leave
    LEAVE_WORKSPACE = '@@workspaces/LEAVE_WORKSPACE',
    // Delete
    DELETE_WORKSPACE = '@@workspaces/DELETE_WORKSPACE',
    // Loading
    SET_WORKSPACE_FLOW_LOADING = '@@workspaces/SET_WORKSPACE_FLOW_LOADING',
    // Create
    CREATE_WORKSPACE = '@@workspaces/CREATE_WORKSPACE',
    CREATE_WORKSPACE_START = '@@workspaces/CREATE_WORKSPACE_START',
    CREATE_WORKSPACE_SUCCESS = '@@workspaces/CREATE_WORKSPACE_SUCCESS',
    CREATE_WORKSPACE_FAIL = '@@workspaces/CREATE_WORKSPACE_FAIL',
}

export interface GetWorkspacesAction {
    type: WorkspaceActionTypes.GET_WORKSPACES;
}

export interface GetWorkspacesStartAction {
    type: WorkspaceActionTypes.GET_WORKSPACES_START;
}

export interface GetWorkspacesFailAction {
    type: WorkspaceActionTypes.GET_WORKSPACES_FAIL;
    payload: any;
}

export interface SetWorkspacesAction {
    type: WorkspaceActionTypes.SET_WORKSPACES;
    payload: Workspace[];
}

// Create

export interface CreateWorkspaceActionPayload {
    name: string;
    navigate: any;
}
export interface CreateWorkspaceAction {
    type: WorkspaceActionTypes.CREATE_WORKSPACE;
    payload: CreateWorkspaceActionPayload;
}

export interface CreateWorkspaceStartAction {
    type: WorkspaceActionTypes.CREATE_WORKSPACE_START;
}

export interface CreateWorkspaceFailAction {
    type: WorkspaceActionTypes.CREATE_WORKSPACE_FAIL;
    payload: any;
}

export interface CreateWorkspaceSuccessAction {
    type: WorkspaceActionTypes.CREATE_WORKSPACE_SUCCESS;
}

//

export interface GetCurrentWorkspaceAction {
    type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE;
}

export interface GetCurrentWorkspaceStartAction {
    type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE_START;
}

export interface GetCurrentWorkspaceFailAction {
    type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE_FAIL;
    payload: any;
}

export interface SetCurrentWorkspaceAction {
    type: WorkspaceActionTypes.SET_CURRENT_WORKSPACE;
    payload: Workspace;
}

//

export interface UpdateWorkspaceActionPayload {
    data: IUpdateWorkspace;
    id: string;
}
export interface UpdateWorkspaceAction {
    type: WorkspaceActionTypes.UPDATE_WORKSPACE;
    payload: UpdateWorkspaceActionPayload;
}

export interface UpdateWorkspaceStartAction {
    type: WorkspaceActionTypes.UPDATE_WORKSPACE_START;
}

export interface UpdateWorkspaceFailAction {
    type: WorkspaceActionTypes.UPDATE_WORKSPACE_FAIL;
    payload: any;
}

export interface UpdateWorkspaceSuccessAction {
    type: WorkspaceActionTypes.UPDATE_WORKSPACE_SUCCESS;
}

// send invite

export interface SendWorkspaceInviteActionPayload {
    inviteeEmail: string;
}
export interface SendWorkspaceInviteAction {
    type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE;
    payload: SendWorkspaceInviteActionPayload;
}

export interface SendWorkspaceInviteStartAction {
    type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_START;
}

export interface SendWorkspaceInviteFailAction {
    type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_FAIL;
    payload: any;
}

export interface SendWorkspaceInviteSuccessAction {
    type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_SUCCESS;
}

// get invitee invites

export interface GetInviteeInvitesAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES;
}

export interface GetInviteeInvitesStartAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_START;
}

export interface GetInviteeInvitesFailAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_FAIL;
    payload: any;
}

export interface GetInviteeInvitesSuccessActionPayload {
    invites: InviteToWorkspace[];
    total: number;
}

export interface GetInviteeInvitesSuccessAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_SUCCESS;
    payload: GetInviteeInvitesSuccessActionPayload;
}

// get inviter invites

export interface GetInviterInvitesAction {
    type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES;
}

export interface GetInviterInvitesStartAction {
    type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_START;
}

export interface GetInviterInvitesFailAction {
    type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_FAIL;
    payload: any;
}

export interface GetInviterInvitesSuccessActionPayload {
    invites: InviteToWorkspace[];
    total: number;
}

export interface GetInviterInvitesSuccessAction {
    type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_SUCCESS;
    payload: GetInviterInvitesSuccessActionPayload;
}

// Pin invite with email
export interface PinInviteWithEmailActionPayload {
    inviteeEmail: string;
}
export interface PinInviteWithEmailAction {
    type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL;
    payload: PinInviteWithEmailActionPayload;
}

export interface PinInviteWithEmailStartAction {
    type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_START;
}

export interface PinInviteWithEmailFailAction {
    type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_FAIL;
    payload: any;
}

export interface PinInviteWithEmailSuccessAction {
    type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_SUCCESS;
}

// Update invitee invite status

export interface UpdateInviteeWorkspaceInviteStatusActionPayload {
    inviteId: string;
    status: InviteToWorkspaceStatuses;
}
export interface UpdateInviteeWorkspaceInviteStatusAction {
    type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS;
    payload: UpdateInviteeWorkspaceInviteStatusActionPayload;
}

export interface UpdateInviteeWorkspaceInviteStatusStartAction {
    type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_START;
}

export interface UpdateInviteeWorkspaceInviteStatusFailAction {
    type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_FAIL;
    payload: any;
}

export interface UpdateInviteeWorkspaceInviteStatusSuccessAction {
    type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS;
}

// Update inviter invite status
export interface UpdateInviterWorkspaceInviteStatusActionPayload {
    inviteId: string;
    workspaceId: string;
    status: InviteToWorkspaceStatuses;
}

export interface UpdateInviterWorkspaceInviteStatusAction {
    type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS;
    payload: UpdateInviterWorkspaceInviteStatusActionPayload;
}

export interface UpdateInviterWorkspaceInviteStatusStartAction {
    type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_START;
}

export interface UpdateInviterWorkspaceInviteStatusFailAction {
    type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_FAIL;
    payload: any;
}

export interface UpdateInviterWorkspaceInviteStatusSuccessAction {
    type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_SUCCESS;
}

// invite modal
export interface SetWorkspaceInviteModalVisibleAction {
    type: WorkspaceActionTypes.SET_WORKSPACE_INVITE_MODAL_VISIBLE;
    payload: boolean;
}

// invitee workspaces

export interface GetInviteeWorkspacesAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES;
}

export interface GetInviteeWorkspacesStartAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES_START;
}
export interface GetInviteeWorkspacesFailAction {
    type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES_FAIL;
    payload: any;
}
export interface SetInviteeWorkspacesAction {
    type: WorkspaceActionTypes.SET_INVITEE_WORKSPACES;
    payload: Workspace[];
}

// Change workspace

export interface ChangeWorkspaceActionPayload {
    navigate?: any;
    workspace: Workspace;
}

export interface ChangeWorkspaceAction {
    type: WorkspaceActionTypes.CHANGE_WORKSPACE;
    payload: ChangeWorkspaceActionPayload;
}

// Delete Invite
export interface DeleteInviteActionPayload {
    inviteId: string;
    workspaceId: string;
}
export interface DeleteInviteAction {
    type: WorkspaceActionTypes.DELETE_INVITE;
    payload: DeleteInviteActionPayload;
}

export interface DeleteInviteStartAction {
    type: WorkspaceActionTypes.DELETE_INVITE_START;
}

export interface DeleteInviteFailAction {
    type: WorkspaceActionTypes.DELETE_INVITE_FAIL;
    payload: any;
}

export interface DeleteInviteSuccessAction {
    type: WorkspaceActionTypes.DELETE_INVITE_SUCCESS;
}

//
export interface AcceptInviteAndChangeWorkspaceActionPayload {
    inviteId: string;
    workspaceId: string;
    navigate: any;
}

export interface AcceptInviteAndChangeWorkspaceAction {
    type: WorkspaceActionTypes.ACCEPT_INVITE_AND_CHANGE_WORKSPACE;
    payload: AcceptInviteAndChangeWorkspaceActionPayload;
}

// Leave
export interface LeaveWorkspaceActionPayload {
    workspaceId: string;
    navigate: any;
}

export interface LeaveWorkspaceAction {
    type: WorkspaceActionTypes.LEAVE_WORKSPACE;
    payload: LeaveWorkspaceActionPayload;
}

// Delete
export interface DeleteWorkspaceActionPayload {
    workspaceId: string;
    navigate: any;
}

export interface DeleteWorkspaceAction {
    type: WorkspaceActionTypes.DELETE_WORKSPACE;
    payload: DeleteWorkspaceActionPayload;
}

// Loading
export interface SetWorkspaceFlowLoadingAction {
    type: WorkspaceActionTypes.SET_WORKSPACE_FLOW_LOADING;
    payload: boolean;
}

export type WorkspaceAction =
    | GetWorkspacesAction
    | GetWorkspacesStartAction
    | GetWorkspacesFailAction
    | SetWorkspacesAction
    //
    | GetInviteeWorkspacesAction
    | GetInviteeWorkspacesStartAction
    | GetInviteeWorkspacesFailAction
    | SetInviteeWorkspacesAction
    //
    | GetCurrentWorkspaceAction
    | GetCurrentWorkspaceStartAction
    | GetCurrentWorkspaceFailAction
    | SetCurrentWorkspaceAction
    | UpdateWorkspaceAction
    | UpdateWorkspaceStartAction
    | UpdateWorkspaceFailAction
    | UpdateWorkspaceSuccessAction
    // send invite
    | SendWorkspaceInviteAction
    | SendWorkspaceInviteStartAction
    | SendWorkspaceInviteFailAction
    | SendWorkspaceInviteSuccessAction
    // get invitee invites
    | GetInviteeInvitesAction
    | GetInviteeInvitesStartAction
    | GetInviteeInvitesFailAction
    | GetInviteeInvitesSuccessAction
    // get inviter invites
    | GetInviterInvitesAction
    | GetInviterInvitesStartAction
    | GetInviterInvitesFailAction
    | GetInviterInvitesSuccessAction
    // Pin invite with email
    | PinInviteWithEmailAction
    | PinInviteWithEmailStartAction
    | PinInviteWithEmailFailAction
    | PinInviteWithEmailSuccessAction
    // Update invitee invite status
    | UpdateInviteeWorkspaceInviteStatusAction
    | UpdateInviteeWorkspaceInviteStatusStartAction
    | UpdateInviteeWorkspaceInviteStatusFailAction
    | UpdateInviteeWorkspaceInviteStatusSuccessAction
    // Update inviter invite status
    | UpdateInviterWorkspaceInviteStatusAction
    | UpdateInviterWorkspaceInviteStatusStartAction
    | UpdateInviterWorkspaceInviteStatusFailAction
    | UpdateInviterWorkspaceInviteStatusSuccessAction
    // invite modal
    | SetWorkspaceInviteModalVisibleAction
    // change workspace
    | ChangeWorkspaceAction
    // delete invite
    | DeleteInviteAction
    | DeleteInviteStartAction
    | DeleteInviteSuccessAction
    | DeleteInviteFailAction
    // Accept invite and change workspace
    | AcceptInviteAndChangeWorkspaceAction
    // leave
    | LeaveWorkspaceAction
    // Loading
    | SetWorkspaceFlowLoadingAction
    // Create
    | CreateWorkspaceAction
    | CreateWorkspaceStartAction
    | CreateWorkspaceSuccessAction
    | CreateWorkspaceFailAction
    // Delete
    | DeleteWorkspaceAction;
