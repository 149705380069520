import { createSelector } from 'reselect';

import { State } from 'state/modules';
import { ShotsState } from 'state/modules/shots';

export const getShotsState = (state: State): ShotsState => state.shots;

export const getSearchFilter = createSelector(
  getShotsState,
  (state) => state.searchFilter,
);

export const getSearchFilterItemsLength = createSelector(
  getSearchFilter,
  (filterItems) => filterItems.length,
);

export const getFilteredShotsLoading = createSelector(
  getShotsState,
  (state) => state.isFilteredShotsLoading,
);

export const getShotsList = createSelector(
  getShotsState,
  (state) => state.shotsList,
);

export const getShotsCount = createSelector(
  getShotsList,
  (shots) => shots.length,
);

export const getShotsTotal = createSelector(
  getShotsState,
  (state) => state.shotsTotal,
);

export const getShotsLoading = createSelector(
  getShotsState,
  (state) => state.isLoading,
);

export const getShotsCategory = createSelector(
  getShotsState,
  (state) => state.shotsCategory,
);
