import React, { memo } from 'react';

import styles from './styles.module.scss';

interface IProgressBarProps {
    currentProgress: string | number;
    remainingAmount: string | number;
    withoutOffset?: boolean;
}

const ProgressBar: React.FC<IProgressBarProps> = memo(
  ({ currentProgress, remainingAmount }) => (
    <div
      className={[
        styles.ProgressBar,
        styles.ProgressBar_withoutOffset,
      ].join(' ')}
    >
      <div
        className={styles.ProgressBar__currentProgress}
        style={{
          width: `${currentProgress}%`,
        }}
      >
        {currentProgress}
        %
      </div>
      <span className={styles.ProgressBar__remainingAmount}>
        {remainingAmount}
        %
      </span>
    </div>
  ),
);

export default ProgressBar;
