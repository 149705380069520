import { IconProps } from "./index";

export const AudioDubbingIcon = ({ size, color }: IconProps) => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 40 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.9839 7.98387C27.9839 5.86642 27.1428 3.83569 25.6455 2.33842C24.1482 0.841155 22.1175 0 20 0C17.8826 0 15.8519 0.841155 14.3546 2.33842C12.8573 3.83569 12.0162 5.86642 12.0162 7.98387V19.5968C12.0162 21.7142 12.8573 23.745 14.3546 25.2422C15.8519 26.7395 17.8826 27.5806 20 27.5806C22.1175 27.5806 24.1482 26.7395 25.6455 25.2422C27.1428 23.745 27.9839 21.7142 27.9839 19.5968V7.98387ZM23.6291 19.5968C23.6291 20.5593 23.2467 21.4823 22.5662 22.1629C21.8856 22.8435 20.9625 23.2258 20 23.2258C19.0376 23.2258 18.1145 22.8435 17.4339 22.1629C16.7533 21.4823 16.371 20.5593 16.371 19.5968V7.98387C16.371 7.02139 16.7533 6.09833 17.4339 5.41776C18.1145 4.73718 19.0376 4.35484 20 4.35484C20.9625 4.35484 21.8856 4.73718 22.5662 5.41776C23.2467 6.09833 23.6291 7.02139 23.6291 7.98387V19.5968Z"
                fill={color}
            />
            <path
                d="M22.1774 39.0774L22.8016 38.9903C27.4573 38.2996 31.7115 35.9635 34.7927 32.4056C37.874 28.8477 39.5783 24.3034 39.5968 19.5968C39.5968 19.0193 39.3674 18.4655 38.959 18.0571C38.5507 17.6488 37.9968 17.4194 37.4194 17.4194H37.0637C36.5805 17.4194 36.1172 17.6113 35.7755 17.9529C35.4339 18.2946 35.2419 18.758 35.2419 19.2411C35.2419 27.5298 28.6661 34.5194 20.5879 34.8387C18.5374 34.9179 16.492 34.5821 14.5744 33.8517C12.6567 33.1212 10.9063 32.0111 9.42814 30.5878C7.94993 29.1644 6.77435 27.4573 5.97187 25.5687C5.16939 23.68 4.75654 21.6488 4.75807 19.5968C4.75807 19.0193 4.52866 18.4655 4.12032 18.0571C3.71197 17.6488 3.15814 17.4194 2.58065 17.4194C2.00316 17.4194 1.44933 17.6488 1.04098 18.0571C0.632634 18.4655 0.403229 19.0193 0.403229 19.5968C0.421694 24.3034 2.12605 28.8477 5.20729 32.4056C8.28852 35.9635 12.5427 38.2996 17.1984 38.9903L17.8226 39.0774V42.8226C17.8226 43.4001 18.052 43.9539 18.4603 44.3623C18.8687 44.7706 19.4225 45 20 45C20.5775 45 21.1313 44.7706 21.5397 44.3623C21.948 43.9539 22.1774 43.4001 22.1774 42.8226V39.0774Z"
                fill={color}
            />
        </svg>
    );
};

export default AudioDubbingIcon;