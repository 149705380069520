/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
import styled from 'styled-components';
import { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, NewButton } from 'components/UI';

import {
    getInviterInvitesList,
    getSendWorkspaceInviteLoading,
    sendWorkspaceInvite,
} from 'state/modules/workspaces';
import { getUserInfo } from 'state/modules/user';

import { NotificationTypes, showNotification } from 'utils/notifications';
import { InviteToWorkspaceStatuses } from 'interfaces/workspaces';
import { NEUTRAL_500, PURPLE_200, WHITE_PRIMARY } from '../../styles/colors';

const Form = styled.form`
    align-items: center;
    width: 100%;
    padding-bottom: 0.625rem;
`;

const InputContainer = styled.div`
    margin-bottom: 1rem;
`;

const EmailError = styled.p`
    display: inline-block;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.71875rem;
    line-height: 0.75rem;
    letter-spacing: 0.01em;
    margin: 0.5rem 0;
    order: 3;
    color: #ff5454;
`;

const mailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InviteCollaboratorForm = () => {
    const dispatch = useDispatch();

    const isInviteLoading = useSelector(getSendWorkspaceInviteLoading);
    const user = useSelector(getUserInfo);
    const invitesList = useSelector(getInviterInvitesList);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const usedEmails = useMemo(
        () =>
            invitesList
                .filter(
                    (item) =>
                        item.status === InviteToWorkspaceStatuses.PENDING ||
                        item.status === InviteToWorkspaceStatuses.ACCEPTED
                )
                .map((item) => item.inviteeEmail),
        [invitesList]
    );

    const userEmail = useMemo(() => user?.attributes?.email || '', [user]);

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setEmailError('');
        setEmail(value);
    };

    const handleSendInvite = (e: any) => {
        e.preventDefault();

        if (!email.match(mailFormat)) {
            return setEmailError('Invalid email address.');
        }

        if (email === userEmail) {
            return showNotification(
                NotificationTypes.error,
                "You can't invite yourself."
            );
        }

        if (usedEmails.includes(email)) {
            return showNotification(
                NotificationTypes.error,
                'User already invited.'
            );
        }

        if (
            confirm(
                `Are you sure you want to invite ${email} to your workspace? \n User will have the same plan as you.`
            )
        ) {
            dispatch(
                sendWorkspaceInvite({
                    inviteeEmail: email,
                })
            );

            setEmail('');
        }
    };

    return (
        <Form onSubmit={handleSendInvite}>
            <InputContainer>
                <Input
                    placeholder="Enter email address"
                    type="text"
                    value={email}
                    onChange={handleChangeEmail}
                    style={{
                        marginBottom: 0,
                        height: '3rem',
                    }}
                    inputStyle={{
                        padding: '0 1.5rem',
                        color: NEUTRAL_500,
                    }}
                />
                {
                    emailError && <EmailError>{emailError}</EmailError>
                }
            </InputContainer>
            <NewButton
                borderRadius={10}
                textColor={WHITE_PRIMARY}
                color={PURPLE_200}
                height={48}
                type="submit"
                loading={isInviteLoading}
                disabled={isInviteLoading}
            >
                Invite
            </NewButton>
        </Form>
    );
};

export default InviteCollaboratorForm;
