import produce from 'immer';

import {
    PaymentAction,
    PaymentActionTypes,
    PaymentState,
} from 'state/modules/payment';
import { SignOutAction, UserActionTypes } from 'state/modules/user';

const initialState: PaymentState = {
    plans: [],
    addons: [],
    usage: null,
    customer: null,
    currentPlan: null,
    upcomingPlan: null,
    selectedPlan: null,
    isPlansLoading: false,
    isUsageLoading: false,
    isCustomerCreationLoading: false,
    isCustomerLoading: true,
    isCurrentPlanLoading: false,
    isUpcomingPlanLoading: false,
    isAddonsLoading: false,
    isAddCreditsLoading: false,
    isAddDubbingCreditsLoading: false,
    isStorageUpdateLoading: false,
    isSubscriptionCancelationLoading: false,
    isSubscriptionCreationLoading: false,
    isAddCustomerCardLoading: false,
    isSetPreferredCardLoading: false,
    isPaymentFlowLoading: false,
    isSubscriptionActivationLoading: false,
    cardIdToRemove: '',
    hasIssueWithSubscription: false,
    stripePromise: Promise.resolve(null),
    isConfirmSetupLoading: false,
    invoices: [],
    isInvoicesLoading: false,
};

export default (
    state = initialState,
    action: PaymentAction | SignOutAction
): PaymentState =>
    produce(state, (draft) => {
        switch (action.type) {
            case PaymentActionTypes.GET_PLANS_START:
                draft.isPlansLoading = true;

                return;
            case PaymentActionTypes.GET_PLANS_FAIL:
                draft.isPlansLoading = false;

                return;
            case PaymentActionTypes.GET_PLANS_SUCCESS:
                draft.isPlansLoading = false;
                draft.plans = action.payload.plans;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_USAGE_START:
                draft.isUsageLoading = true;

                return;
            case PaymentActionTypes.GET_USAGE_FAIL:
                draft.isUsageLoading = false;

                return;
            case PaymentActionTypes.GET_USAGE_SUCCESS:
                draft.isUsageLoading = false;
                draft.usage = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_CUSTOMER_START:
                draft.isCustomerLoading = true;

                return;
            case PaymentActionTypes.GET_CUSTOMER_FAIL:
                draft.isCustomerLoading = false;

                return;
            case PaymentActionTypes.GET_CUSTOMER_SUCCESS:
                draft.isCustomerLoading = false;
                draft.customer = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_USER_SUBSCRIPTION_START:
                draft.isCurrentPlanLoading = true;

                return;
            case PaymentActionTypes.GET_USER_SUBSCRIPTION_FAIL:
                draft.isCurrentPlanLoading = false;
                draft.currentPlan = initialState.currentPlan;
                draft.hasIssueWithSubscription = true;

                return;
            case PaymentActionTypes.GET_USER_SUBSCRIPTION_SUCCESS:
                draft.isCurrentPlanLoading = false;
                draft.currentPlan = action.payload;
                draft.hasIssueWithSubscription = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_START:
                draft.isUpcomingPlanLoading = true;

                return;
            case PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_FAIL:
                draft.isUpcomingPlanLoading = false;
                draft.upcomingPlan = initialState.upcomingPlan;

                return;
            case PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_SUCCESS:
                draft.isUpcomingPlanLoading = false;
                draft.upcomingPlan = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.UPDATE_STORAGE_START:
                draft.isStorageUpdateLoading = true;

                return;
            case PaymentActionTypes.UPDATE_STORAGE_FAIL:
                draft.isStorageUpdateLoading = false;

                return;
            case PaymentActionTypes.UPDATE_STORAGE_SUCCESS:
                draft.isStorageUpdateLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_ADDONS_START:
                draft.isAddonsLoading = true;

                return;
            case PaymentActionTypes.GET_ADDONS_FAIL:
                draft.isAddonsLoading = false;

                return;
            case PaymentActionTypes.GET_ADDONS_SUCCESS:
                draft.isAddonsLoading = false;
                draft.addons = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.ADD_CREDITS_START:
                draft.isAddCreditsLoading = true;

                return;
            case PaymentActionTypes.ADD_CREDITS_FAIL:
                draft.isAddCreditsLoading = false;

                return;
            case PaymentActionTypes.ADD_CREDITS_SUCCESS:
                draft.isAddCreditsLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.ADD_DUBBING_CREDITS_START:
                draft.isAddDubbingCreditsLoading = true;

                return;
            case PaymentActionTypes.ADD_DUBBING_CREDITS_FAIL:
                draft.isAddDubbingCreditsLoading = false;

                return;
            case PaymentActionTypes.ADD_DUBBING_CREDITS_SUCCESS:
                draft.isAddDubbingCreditsLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_START:
                draft.isSubscriptionCancelationLoading = true;

                return;
            case PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_FAIL:
                draft.isSubscriptionCancelationLoading = false;

                return;
            case PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_SUCCESS:
                draft.isSubscriptionCancelationLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_START:
                draft.isSubscriptionCreationLoading = true;

                return;
            case PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_FAIL:
                draft.isSubscriptionCreationLoading = false;

                return;
            case PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_SUCCESS:
                draft.isSubscriptionCreationLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.ADD_CUSTOMER_CARD_START:
                draft.isAddCustomerCardLoading = true;

                return;
            case PaymentActionTypes.ADD_CUSTOMER_CARD_FAIL:
                draft.isAddCustomerCardLoading = false;

                return;
            case PaymentActionTypes.ADD_CUSTOMER_CARD_SUCCESS:
                draft.isAddCustomerCardLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.SET_SELECTED_PLAN:
                draft.selectedPlan = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.SET_PREFERRED_CARD_START:
                draft.isSetPreferredCardLoading = true;

                return;
            case PaymentActionTypes.SET_PREFERRED_CARD_FAIL:
                draft.isSetPreferredCardLoading = false;

                return;
            case PaymentActionTypes.SET_PREFERRED_CARD_SUCCESS:
                draft.isSetPreferredCardLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.PAYMENT_FLOW_INIT_START:
                draft.isPaymentFlowLoading = true;

                return;
            case PaymentActionTypes.PAYMENT_FLOW_INIT_FAIL:
                draft.isPaymentFlowLoading = false;

                return;
            case PaymentActionTypes.PAYMENT_FLOW_INIT_SUCCESS:
                draft.isPaymentFlowLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.ACTIVATE_SUBSCRIPTION_START:
                draft.isSubscriptionActivationLoading = true;

                return;
            case PaymentActionTypes.ACTIVATE_SUBSCRIPTION_FAIL:
                draft.isSubscriptionActivationLoading = false;

                return;
            case PaymentActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS:
                draft.isSubscriptionActivationLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_START:
                draft.isAddCustomerCardLoading = true;
                draft.isSubscriptionCreationLoading = true;

                return;
            case PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_FAIL:
                draft.isAddCustomerCardLoading = false;
                draft.isSubscriptionCreationLoading = false;

                return;
            case PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS:
                draft.isAddCustomerCardLoading = false;
                draft.isSubscriptionCreationLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.SET_CARD_ID_TO_REMOVE:
                draft.cardIdToRemove = action.payload;

                return;
            case PaymentActionTypes.SET_STRIPE_PROMISE:
                draft.stripePromise = action.payload;

                return;
            /// ///////////////////////////////////////////////////////
            case PaymentActionTypes.CONFIRM_SETUP_START:
                draft.isConfirmSetupLoading = true;

                return;
            case PaymentActionTypes.CONFIRM_SETUP_FAIL:
                draft.isConfirmSetupLoading = false;

                return;
            case PaymentActionTypes.CONFIRM_SETUP_SUCCESS:
                draft.isConfirmSetupLoading = false;

                return;
            /// invoices ///////////////////////////////////////////////////////
            case PaymentActionTypes.GET_INVOICES_START:
                draft.isInvoicesLoading = true;

                return;
            case PaymentActionTypes.GET_INVOICES_SUCCESS:
                draft.invoices = action.payload;
                draft.isInvoicesLoading = false;

                return;
            case PaymentActionTypes.GET_INVOICES_FAIL:
                draft.isInvoicesLoading = false;

                return;
            /// ///////////////////////////////////////////////////////
            case UserActionTypes.SIGN_OUT:
                return initialState;
            default:
                return state;
        }
    });
