import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { NewButton } from "components/UI";

import { AppTour, updateAppIntroductoryTourStatus } from "state/modules/app";
import {
  createDefaultProject,
  getProjectCreationLoading,
} from "state/modules/projects";
import { hideModal } from "state/modules/modal";
import { getPreferences, updateUserPreferences } from "state/modules/user";

import { ProductPrepareTypes } from "interfaces/preferences";

import { Colors } from "styles";
import styles from "./styles.module.scss";

interface Props {
  closeButton: JSX.Element;
  closeModal: () => void;
}

const AppTourProposalModal = (props: Props) => {
  const { closeButton, closeModal } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userPreferences = useSelector(getPreferences);
  const isProjectCreationLoading = useSelector(getProjectCreationLoading);

  const handleUpdatePreferences = (productType: ProductPrepareTypes) => {
    if (userPreferences) {
      dispatch(
        updateUserPreferences({
          ...userPreferences,
          productPrepareType: productType,
        })
      );
    }
  };

  const startAppTour = (appTourSection: AppTour) => {
    if (appTourSection === AppTour.VIDEO_EDITING_DASHBOARD) {
      dispatch(
        createDefaultProject({
          navigate,
        })
      );

      dispatch(updateAppIntroductoryTourStatus({}, true));

      handleUpdatePreferences(ProductPrepareTypes.PROJECTS);
    }

    if (appTourSection === AppTour.BURNING_SUBTITLES) {
      navigate("/smart-search");

      dispatch(
        updateAppIntroductoryTourStatus(
          {
            [appTourSection]: {
              isFinished: false,
              isRunning: false,
            },
          },
          true
        )
      );

      dispatch(hideModal());
      handleUpdatePreferences(ProductPrepareTypes.LOGING);
    }
  };

  // const skipTour = () => {
  //   dispatch(
  //     updateAppIntroductoryTourStatus(
  //       {},
  //       true
  //     ),
  //   );
  //   dispatch(hideModal());
  // }

  // const renderSkipButton = () => (
  //   <button
  //     className={styles.AppTourProposalModal__skipButton}
  //     onClick={skipTour}
  //   >
  //     Skip Tour
  //   </button>
  // )

  return (
    <div className={styles.AppTourProposalModal}>
      <p className={styles.AppTourProposalModal__title}>Welcome to NOVA A.I</p>
      <p className={styles.AppTourProposalModal__description}>
        What would you like to do today?
      </p>
      <div className={styles.AppTourProposalModal__actions}>
        <NewButton
          color={Colors.GREY3}
          textColor={Colors.WHITE}
          bordered
          borderRadius={6}
          height={55}
          onClick={() => startAppTour(AppTour.BURNING_SUBTITLES)}
          disabled={isProjectCreationLoading}
          // fontWeight="300"
        >
          <p className={styles.AppTourProposalModal__actionButtonTitle}>
            <span
              className={styles.AppTourProposalModal__actionButtonTitle_accent}
            >
              Smart Search
            </span>
            <br />
            (viewing + logging + smart metadata)
          </p>
        </NewButton>
        <NewButton
          color={Colors.GREY3}
          textColor={Colors.WHITE}
          bordered
          borderRadius={6}
          height={55}
          onClick={() => startAppTour(AppTour.VIDEO_EDITING_DASHBOARD)}
          disabled={isProjectCreationLoading}
          loading={isProjectCreationLoading}
          // fontWeight="300"
        >
          <p className={styles.AppTourProposalModal__actionButtonTitle}>
            <span
              className={styles.AppTourProposalModal__actionButtonTitle_accent}
            >
              Video Editing
            </span>
            <br />
            (Subtitles + Translation + Cutting + Trimming)
          </p>
        </NewButton>
      </div>
    </div>
  );
};

export default memo(AppTourProposalModal);
