import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify, Analytics } from 'aws-amplify';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import 'index.css';
import 'assets/scss/main.scss';

declare global {
    interface Window {
        config: {
            REACT_APP_API_URL: string;
            REACT_APP_SHORT_API_URL: string;
            REACT_APP_CLOUDFRONT_URL: string;
            REACT_APP_SOLUTION_REGION: string;
            REACT_APP_SOLUTION_USERPOOLID: string;
            REACT_APP_SOLUTION_USERPOOLWEBCLIENTID: string;
            REACT_APP_SOLUTION_IDENTITYPOOLID: string;
            REACT_APP_SOLUTION_BUCKET: string;
            REACT_APP_DOWNLOAD_TRAILER_BUCKET: string;
            REACT_APP_SOLUTION_ENDPOINT: string;
            REACT_APP_SOLUTION_CONSOLE_LINK: string;
            REACT_APP_SFTP_SERVER_URL: string;
            REACT_APP_GOOGLE_SIGIN_DOMAIN: string;
            REACT_APP_GOOGLE_SIGIN_SCOPE: Array<string>;
            REACT_APP_GOOGLE_SIGIN_RESPONSE_TYPE: string;
            REACT_APP_GOOGLE_SIGIN_REDIRECT_SIGN_IN: string;
            REACT_APP_GOOGLE_SIGIN_REDIRECT_SIGN_OUT: string;
            REACT_APP_PINPOINT_APP_CLIENT_ID: string;
            REACT_APP_STRIPE_PUBLIC_KEY: string;
            REACT_APP_AMPLITUDE_API_KEY: string;
        };
        CRISP_WEBSITE_ID: string;
        $crisp: any[];
        dataLayer: {
            event: string;
            enhanced_conversion_data: {
                email: string;
            };
        }[];
        surferidze: any;
    }
}

const windowFork = window;

momentDurationFormatSetup(moment as any);

Amplify.configure({
    Auth: {
        region: windowFork.config.REACT_APP_SOLUTION_REGION,
        userPoolId: windowFork.config.REACT_APP_SOLUTION_USERPOOLID,
        userPoolWebClientId:
            windowFork.config.REACT_APP_SOLUTION_USERPOOLWEBCLIENTID,
        identityPoolId: windowFork.config.REACT_APP_SOLUTION_IDENTITYPOOLID,
    },
    Storage: {
        bucket: windowFork.config.REACT_APP_SOLUTION_BUCKET,
        region: windowFork.config.REACT_APP_SOLUTION_REGION,
        identityPoolId: windowFork.config.REACT_APP_SOLUTION_IDENTITYPOOLID,
    },
    API: {
        endpoints: [
            {
                name: 'MediaAnalysisApi',
                region: windowFork.config.REACT_APP_SOLUTION_REGION,
                endpoint: windowFork.config.REACT_APP_SOLUTION_ENDPOINT,
            },
        ],
    },
    oauth: {
        domain: windowFork.config.REACT_APP_GOOGLE_SIGIN_DOMAIN,
        scope: windowFork.config.REACT_APP_GOOGLE_SIGIN_SCOPE,
        responseType: windowFork.config.REACT_APP_GOOGLE_SIGIN_RESPONSE_TYPE,
        redirectSignIn:
            windowFork.config.REACT_APP_GOOGLE_SIGIN_REDIRECT_SIGN_IN,
        redirectSignOut:
            windowFork.config.REACT_APP_GOOGLE_SIGIN_REDIRECT_SIGN_OUT,
    },
    Analytics: {
        AWSPinpoint: {
            // Amazon Pinpoint App Client ID
            appId: '217fb6adf21041bdb68b9afcd23c2b30',
            // Amazon service region
            region: 'eu-west-1',
            mandatorySignIn: false,
        },
    },
});

Analytics.configure({
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '217fb6adf21041bdb68b9afcd23c2b30',
        // Amazon service region
        region: 'eu-west-1',
        mandatorySignIn: false,
    },
});

googleAnalytics.initialize();
amplitudeAnalytics.initialize();

if (window.location.hostname !== 'localhost') {
    Sentry.init({
        dsn: 'https://47d04f04f9c54b8ba8c18a64ecda8db4@o1145601.ingest.sentry.io/6213209',
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'console') {
                return null;
            }
            return breadcrumb;
        },
        integrations: [new BrowserTracing()],
        environment: (
                windowFork.config.REACT_APP_API_URL ===
                'https://api.wearenova.ai/v1'
            ) ? 'prod' : (
                windowFork.config.REACT_APP_API_URL ===
                'https://api.testpromomii.com/v1'
            ) ? 'stage' : 'dev',

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
}

if (window.location.hostname.includes('app.wearenova.ai')) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'e2eb71f6-8515-408a-82d0-a94ac8459786';
    (function () {
        const d = document;
        const s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1 as any;
        d.getElementsByTagName('head')[0].appendChild(s);
    })();
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
