import { actionChannel, all, call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import * as diff from 'fast-array-diff';
import { Storage } from 'aws-amplify';
import difference from 'lodash.difference';

import ProjectsClient from 'services/api/projects';
import VideosClient from 'services/api/videos';
import ProjectSubtitlesClient from 'services/api/subtitles';

import { defaultProjectSubtitlesSettings } from 'utils/projects';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { reportErrorToSentry } from 'utils/errorTracking';
import {
    Project,
    ProjectMediaSourcesSettings,
    ProjectTransformedSentence,
    ProjectVideo,
    SubtitleEffect,
    TimelineMedias,
} from 'interfaces/projects';
import MetadataClient from 'services/api/metadata';
import {
    createItemsFromSentence,
    splitSentenceBySpecifiedTime,
    transformSentencesToRawSentence,
} from 'utils/transcript';
import { MediaFile, MediaType } from 'interfaces/videos';
import { SentenceTypes } from 'interfaces/sentences';
import { secondsToFrameNumber } from 'utils/timeline';
import {
    calculatePriceInCreditsForSubtitlingOperation,
    calculatePriceInCreditsForTranslateOperation,
} from 'utils/calc';
import { AnalysisResultsType, RekognitionCategory } from 'interfaces/analysis';
import AnalysisClient from 'services/api/analysis';
import { analyticsUtil } from 'utils/analytics';
import { buffers } from 'redux-saga';
import {
    AddOrEditSubtitleAction,
    DeleteSubtitleAction,
    GetProjectAllSubtitlesListAction,
    ProjectSubtitlesActionTypes,
    ProjectVideoLanguages,
    DeleteProjectSubtitlesLanguageAction,
    CheckVideoEmptyTranscriptListAction,
    CheckVideoEmptyTranscriptListActionPayload,
    UpdateProjectTranscriptItemAction,
    MergeSubtitlesAction,
    MergeSentencesAction,
    GenerateProjectVideoTranscriptAction,
    GenerateProjectVideoTranslateAction,
    ChangeProjectVideoLanguageAction,
    ManualCreateProjectSentenceAction,
    CreateProjectTranscriptItemBySpecificTimeAction,
    CancelProjectMediaSourceTranslateAction,
    ResetProjectSubtitlesSettingsAction,
    ReplaceProjectSubtitlesTextAction,
    UploadProjectTranscriptAction,
    ExportSubtitlesAction,
    ExportVideoSubtitlesAction,
    AddGeneratedSubtitlesAction,
    DeleteVideoSubtitlesAction,
    AddLibraryVideoSubtitlesAction,
    GenerateProjectVideoAutoTranslateAction,
} from './typesNew';
import { adaptGetProjectAllSubtitlesData } from './adapter';
import * as ProjectsActions from '../projects/actions';
import * as subtitlesActions from './actionsNew';
import {
    getMedias,
    getProjectAudiosIdsWithDubbingGeneration,
    getProjectDetailsInfo,
    getProjectMediaSources,
    getProjectVideos,
    ProjectsActionTypes,
    syncProjectMedias,
} from '../projects';

import {
    getEditableSentenceId,
    getProjectSubtitlesSearchQuery,
    getProjectVideosIdsWithTranscriptGeneration,
    getProjectVideosIdsWithTranslateGeneration,
    getProjectVideosLanguages,
    getProjectSubtitlesList,
    getProjectDeletedUnsyncedSubtitlesList,
    getProjectUnsyncedSubtitlesList,
    getAddGeneratedSubtitlesLoading,
} from './selectorsNew';
import { RawSentence, TransformedSentence } from '../metadata';
import {
    getProjectTimelineTime,
    getSelectedShape,
    getTimelineSelectedObjects,
    SelectedProjectObject,
} from '../projectsStudio';
import * as ProjectsStudioActions from '../projectsStudio/actions';
import { getUserId } from '../user';
import {
    CurrentSubscription,
    getCurrentCredits,
    getCurrentUserPlan,
    handleSubtitlingPaymentLimitation,
    handleTranslatePaymentLimitation,
} from '../payment';
import { ReanalysisReqData } from '../analysis';
import { transformTranscript } from '../../utils/metadataUtils';
import { DeletedSentence } from '../../../interfaces/transcript';
import { StatusType } from '../../../interfaces/statuses';
import { ConnectedScripts } from '../../../constants/subtitles';
import { subtitleEffects } from '../../../utils/subtitles';
import { WHITE_PRIMARY } from '../../../styles/colors';

export function* handleGetProjectAllSubtitles(
    action: GetProjectAllSubtitlesListAction
): Generator {
    const userId = (yield select(getUserId)) as string;
    if(!userId) {
        return;
    }

    const { projectDetails, isVersionChange } = action.payload;
    const currentProjectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const usableProjectDetails = projectDetails || currentProjectDetails;

    try {
        if(!usableProjectDetails) {
            return;
        }

        const { id: projectId, version } = usableProjectDetails;

        const res =  (yield call(
            ProjectSubtitlesClient.getProjectAllSentences,
            projectId,
            version
        )) as AxiosResponse;

        let subtitles = [] as TransformedSentence[];

        if(res?.data?.subtitlesURL) {
            const link = res?.data?.subtitlesURL;
            const subtitlesFileRes = (yield call(
                axios.get,
                `${window.config.REACT_APP_CLOUDFRONT_URL}${encodeURI(link).replaceAll('?', '%3F')}`,
                {
                    responseType: 'blob',
                }
            )) as AxiosResponse;


            const subtitleDataString = yield subtitlesFileRes?.data?.text();
            const subtitleData = JSON.parse((subtitleDataString as string || ''));

            if(subtitleData?.subtitles) {
                subtitles = subtitleData.subtitles;
            }
        } else if(res?.data?.subtitles) {
            subtitles = res?.data?.subtitles;
        }

        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesList = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        const adaptedData = adaptGetProjectAllSubtitlesData(
            projectUnsyncedSubtitlesList,
            projectDeletedUnsyncedSubtitlesList,
            subtitles,
            isVersionChange,
        );

        const {
            currentSyncedSubtitles,
            currentUnsyncedSubtitles,
            currentDeletedIds,
            highestEndTime,
        } = adaptedData;

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: currentSyncedSubtitles,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: currentUnsyncedSubtitles,
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(
            currentDeletedIds
        ));

        if(res?.data?.project) {
            yield put(ProjectsActions.setProjectDetail(res?.data?.project));
        }

        const upToDateProjectDetails = (yield select(getProjectDetailsInfo)) as Project;

        if(
            upToDateProjectDetails &&
            (highestEndTime > upToDateProjectDetails?.duration) &&
            upToDateProjectDetails?.subtitlesNotAttached
        ) {
            const updatedProject = {
                ... upToDateProjectDetails,
                duration: Math.ceil(highestEndTime),
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                    skipVersionUpdate: true,
                })
            );
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleGetProjectAllSubtitles', {
            projectDetails: usableProjectDetails,
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            'An error occurred while fetching your subtitles data.'
        );
    }
}

// add or edit subtitles
function* handleAddOrEditSubtitle(
    action: AddOrEditSubtitleAction
): Generator {
    try {
        const {
            projectDetails,
            subtitles,
            subtitleIds,
        } = action.payload;
        const {version, id: projectId} = projectDetails;

        yield call(
            ProjectSubtitlesClient.addOrEditSubtitleToProject,
            projectId, version, subtitles, subtitleIds
        );

        yield put(subtitlesActions.addOrEditSentencesSuccess());
    } catch (error) {
        reportErrorToSentry(error, 'handleAddOrEditSubtitle', {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while ${action.payload?.subtitleIds ? 'editing' : 'adding'} a subtitle.`
        );
    }
}

// create manual subtitles
function* handleManualCreateProjectSentence(
    action: ManualCreateProjectSentenceAction
): Generator {
    yield put(subtitlesActions.setProjectTranscriptCreationLoading(true));

    const { videoId, language } = action.payload;

    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    try {
        const data = {
            data: '',
            items: [],
            language: language || '',
            speaker: '',
            startTime: `${0}`,
            endTime: `${1}`,
            type: AnalysisResultsType.SENTENCE,
            videoId: videoId || '',
            id: uuid(),
            projectId: projectDetails.id,
        } as RawSentence;

        let updatedProject = {
            ...projectDetails,
            sentences: [
                ...(projectDetails?.sentences || []),
                data.id,
            ]
        } as Project;

        if (videoId?.length) {
            const updatedMediaSourcesSettings =
                updatedProject.mediaSourcesSettings.map((item) => {
                    if (item.mediaSourceId === videoId) {
                        return {
                            ...item,
                            language,
                        };
                    }

                    return item;
                });

            updatedProject = {
                ...updatedProject,
                subtitlesNotAttached: false,
                mediaSourcesSettings: updatedMediaSourcesSettings,
            };
        } else {
            updatedProject = {
                ...updatedProject,
                subtitlesNotAttached: true,
                isSubtitling: true,
                language,
            };
        }

        yield put(subtitlesActions.addOrEditSubtitle({
            projectDetails,
            subtitles: [data],
        }));

        yield take(ProjectSubtitlesActionTypes.ADD_OR_EDIT_SENTENCES_SUCCESS);

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);

        analyticsUtil.manualTranscriptCreate({
            userId,
            currentPlan,
            projectDetails,
            language,
        });

        yield put(ProjectsActions.getProjectDetails(projectDetails.id));

        yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

        yield delay(1500);

        yield put(
            subtitlesActions.setProjectTranscriptCreationLoading(false)
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleManualCreateProjectSentence', {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while adding a manual subtitle.`
        );
        yield put(
            subtitlesActions.setProjectTranscriptCreationLoading(false)
        );
    }
}

// create new sentence

function* handleSetScrollableTranscriptIndex(id: string): Generator {
    let projectVideos = (yield select(getProjectVideos)) as ProjectVideo[];
    projectVideos = sortBy(projectVideos, 'startTime');
    const currentSubtitlesList = (yield select(
        getProjectSubtitlesList
    )) as TransformedSentence[];
    const unsyncedSubtitlesList = (yield select(
        getProjectUnsyncedSubtitlesList
    )) as TransformedSentence[];

    const transcriptList = [...currentSubtitlesList, ...unsyncedSubtitlesList];

    const groupedList = groupBy(transcriptList, 'videoId');
    const sortedVideos = projectVideos;
    const list = [] as ProjectTransformedSentence[];

    sortedVideos.forEach((projectVideo) => {
        const videoTranscriptList =
            groupedList[projectVideo.mediaSourcesId] || [];

        const filteredList = videoTranscriptList.filter(
            (sentence) =>
                +sentence.startTime >= projectVideo.trimStart &&
                +sentence.endTime <= projectVideo.trimEnd
        );

        const transformedList = filteredList.map((sentence) => {
            const startTime =
                projectVideo.startTime +
                +sentence.startTime -
                projectVideo.trimStart;
            const duration = +sentence.endTime - +sentence.startTime;

            return {
                ...sentence,
                timelineStartTime: startTime,
                timelineEndTime: startTime + duration,
            };
        });

        list.push(...transformedList);
    });

    const sortedList = sortBy(list, 'timelineStartTime', 'timelineEndTime');

    const selectedIndex = sortedList.findIndex((item) => item.id === id);

    yield put(
        subtitlesActions.setScrollableTranscriptIndex(selectedIndex)
    );
}

function* handleCreateProjectTranscriptItemBySpecificTime(
    action: CreateProjectTranscriptItemBySpecificTimeAction
): Generator {
    try {
        const { time, mediaType } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const medias = (yield select(getMedias as any)) as TimelineMedias;
        const { frameRate, subtitles, videos, audios } = medias;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        // //////////////

        let selectedMedia: ProjectVideo | undefined;
        let nextSubtitle: TransformedSentence | undefined;

        if (!projectDetails.subtitlesNotAttached) {
            const getSelectedMedia = (list: ProjectVideo[]) =>
                list.find(
                    (item) =>
                        secondsToFrameNumber(time, frameRate) >=
                        secondsToFrameNumber(item.startTime, frameRate) &&
                        secondsToFrameNumber(time, frameRate) <
                        secondsToFrameNumber(item.endTime, frameRate)
                );

            if (!mediaType) {
                selectedMedia = getSelectedMedia(videos);
                if (!selectedMedia) {
                    selectedMedia = getSelectedMedia(audios);
                }
            } else {
                if (mediaType === MediaType.VIDEO) {
                    selectedMedia = getSelectedMedia(videos);
                } else if (mediaType === MediaType.AUDIO) {
                    selectedMedia = getSelectedMedia(audios);
                }
            }

            if (selectedMedia) {
                nextSubtitle = subtitles.find(
                    (subtitle) =>
                        secondsToFrameNumber(
                            subtitle.timeline.startTime,
                            frameRate
                        ) >= secondsToFrameNumber(time, frameRate) &&
                        subtitle.videoId === selectedMedia?.mediaSourcesId
                );
            }
        } else {
            nextSubtitle = subtitles.find(
                (subtitle) =>
                    secondsToFrameNumber(subtitle.timeline.startTime, frameRate) >=
                    secondsToFrameNumber(time, frameRate)
            );
        }

        // ///////////////

        let newSubtitleStartTime = time;
        let newSubtitleEndTime = newSubtitleStartTime + 1;
        if (nextSubtitle && nextSubtitle.timeline.startTime < newSubtitleEndTime) {
            newSubtitleEndTime = nextSubtitle.timeline.startTime;
        }

        if (!projectDetails.subtitlesNotAttached) {
            if (selectedMedia && selectedMedia.endTime < newSubtitleEndTime) {
                newSubtitleEndTime = selectedMedia.endTime;
            }
        } else {
            // if (projectDetails.duration < newSubtitleEndTime) {
            //     newSubtitleEndTime = projectDetails.duration;
            // }
            // if (newSubtitleStartTime >= projectDetails.duration) { // todo: should instead make the duration longer
            //     return;
            // }
        }

        if (
            secondsToFrameNumber(newSubtitleStartTime, frameRate) ===
            secondsToFrameNumber(newSubtitleEndTime, frameRate)
        ) {
            showNotification(
                NotificationTypes.warning,
                `There's no available space for a new sentence to be added. 
                Please adjust the timings or merge adjacent sentences to create new space.`
            );
            return;
        }

        const selectedSubtitle = subtitles.find(
            (subtitle) =>
                secondsToFrameNumber(time, frameRate) >=
                secondsToFrameNumber(subtitle.timeline.startTime, frameRate) &&
                secondsToFrameNumber(time, frameRate) <
                secondsToFrameNumber(subtitle.timeline.endTime, frameRate)
        );

        if (!projectDetails.subtitlesNotAttached) {
            if (selectedMedia) {
                newSubtitleStartTime =
                    selectedMedia.trimStart -
                    selectedMedia.startTime +
                    newSubtitleStartTime;
                newSubtitleEndTime =
                    selectedMedia.trimStart -
                    selectedMedia.startTime +
                    newSubtitleEndTime;
            }
        }

        // //////////////
        let language;
        if (!projectDetails.subtitlesNotAttached) {
            const mediaSourceSettings = projectDetails.mediaSourcesSettings.find(
                (settings) =>
                    settings.mediaSourceId === selectedMedia?.mediaSourcesId
            );
            language = mediaSourceSettings?.language;
        } else {
            language = projectDetails.language;
        }

        const speaker = nextSubtitle?.speaker || '';
        const id = uuid();

        const data = {
            data: '',
            items: [],
            language:
                language ||
                selectedSubtitle?.language ||
                nextSubtitle?.language ||
                '',
            speaker,
            startTime: `${newSubtitleStartTime}`,
            endTime: `${newSubtitleEndTime}`,
            type: AnalysisResultsType.SENTENCE,
            videoId: !projectDetails.subtitlesNotAttached
                ? selectedMedia?.mediaSourcesId || ''
                : '',
            id,
            projectId: projectDetails.id,
        } as RawSentence;

        // /////////////////

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        const newSentences = [] as RawSentence[];

        // /////////////////
        newSentences.push(data);

        if (selectedSubtitle) {
            const newSelectedSubtitle = transformSentencesToRawSentence(
                [
                    {
                        ...selectedSubtitle,
                        endTime: `${newSubtitleStartTime}`,
                        timestamps: [
                            ...selectedSubtitle.timestamps,
                            {
                                timestamp: newSubtitleStartTime * 1000,
                            },
                        ],
                        id: uuid(),
                    },
                ],
                projectDetails.id
            );

            newSentences.push(newSelectedSubtitle[0]);
        }

        // /////////////////
        if(selectedSubtitle) {
            updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                item => item.id !== selectedSubtitle.id
            );
            updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                item => item.id !== selectedSubtitle.id
            );
            updatedDeletedUnsyncedSubtitlesIds = [
                ...updatedDeletedUnsyncedSubtitlesIds,
                selectedSubtitle.id,
            ];
        }

        const transformedNewSentences = transformTranscript(
            newSentences
        ) as Array<TransformedSentence>;

        updatedUnsyncedSubtitlesList = [
            ...updatedUnsyncedSubtitlesList,
            ...transformedNewSentences,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // /////////////////
        const filteredSentencesIds = selectedSubtitle ?
            projectDetails.sentences?.filter(id => id !== selectedSubtitle.id) :
            projectDetails.sentences;
        const newSentencesIds = newSentences.map(item => item.id);

        const updatedProject = {
            ...projectDetails,
            sentences: [
                ...(filteredSentencesIds || []),
                ...newSentencesIds,
            ],
        };

        // /////////////////

        yield put(subtitlesActions.addOrEditSubtitle({
            projectDetails,
            subtitles: newSentences,
        }));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        // /////////////////

        analyticsUtil.specificTimeSubtitlesAdded();

        // ////////////////

        yield call(handleSetScrollableTranscriptIndex, id);
        yield put(
            ProjectsStudioActions.setSelectedShape({
                id: data.id,
            })
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleCreateProjectTranscriptItemBySpecificTime',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while adding a manual subtitle.`
        );
    }
}

// edit subtitles
function* handleUpdateProjectTranscriptItem(
    action: UpdateProjectTranscriptItemAction
): Generator {
    try {
        const sentence = action.payload as TransformedSentence;

        const updatedItems = createItemsFromSentence(
            sentence.data,
            sentence.timestamps[0].timestamp,
            sentence.timestamps[1].timestamp
        );

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const medias = (yield select(getMedias)) as TimelineMedias;

        let projectDuration = projectDetails.duration;

        if(
            sentence.endTime && medias.frameRate && projectDuration &&
            (
                secondsToFrameNumber(+sentence.endTime, medias.frameRate) >
                secondsToFrameNumber(projectDuration, medias.frameRate)
            )
        ) {
            projectDuration = +sentence.endTime;
        }

        const selectedShape = (yield select(getSelectedShape)) as string | null;
        const editableSentenceId = (yield select(
            getEditableSentenceId
        )) as string;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        // //////////////////

        const updatedSentence = {
            ...sentence,
            id: uuid(),
            projectId: projectDetails.id,
            items: updatedItems,
            dataItems: sentence.dataItems,
        } as TransformedSentence;

        // ///////////////////

        if (selectedShape === sentence.id) {
            yield put(
                ProjectsStudioActions.setSelectedShape({
                    id: updatedSentence.id,
                })
            );
        }
        if (editableSentenceId === sentence.id) {
            yield put(
                subtitlesActions.setProjectEditableSentenceId(
                    updatedSentence.id
                )
            );
        }

        // /////////////////////
        const removableSentenceId = sentence.id;

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => item.id !== removableSentenceId
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => item.id !== removableSentenceId
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            removableSentenceId,
        ];

        updatedUnsyncedSubtitlesList = [
            ...updatedUnsyncedSubtitlesList,
            updatedSentence,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // ////////////////////

        const filteredSentences = projectDetails.sentences?.filter(
            item => item !== removableSentenceId
        );
        const transformedNewSentence = transformSentencesToRawSentence(
            [updatedSentence],
            projectDetails.id
        ) as RawSentence[];

        const updatedProject = {
            ...projectDetails,
            duration: projectDuration,
            sentences: [
                ...(filteredSentences || []),
                updatedSentence.id,
            ],
        };

        yield put(
            subtitlesActions.addOrEditSubtitle({
                projectDetails,
                subtitles: transformedNewSentence,
                subtitleIds: [removableSentenceId],
            })
        );

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

         // ///////////////////////////

        const selectedObjects = (yield select(
            getTimelineSelectedObjects
        )) as SelectedProjectObject[];

        const isSelected = !!selectedObjects.find(
            (item) => item.object.id === sentence.id
        );

        if (isSelected) {
            const updatedSelectedItems = selectedObjects.map((item) => {
                if (item.object.id === sentence.id) {
                    return {
                        ...item,
                        object: updatedSentence,
                    };
                }

                return item;
            });

            yield put(
                ProjectsStudioActions.setProjectSelectedObjects(
                    updatedSelectedItems
                )
            );
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleUpdateProjectTranscriptItem',  {
            transformedSentence: action?.payload || null,
        });

        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while updating your subtitle data.`
        );
    }
}

const checkDiffAndUpdateTempItems = (
    item: TransformedSentence,
    newValue: string
) => {
    const currentTitleArr = item.data.split('');
    const tempItems = item.dataItems?.length
        ? item.dataItems
        : item.data
            .replace('<br />', '\n')
            .split('')
            .map((dataItem) => ({ content: dataItem }));

    let updatedTempItems = [...tempItems];
    const cleanNewValue = newValue.replace(/(<([^>]+)>)/gi, '');
    const newValueArr = cleanNewValue.split('');

    const diffValues = diff.getPatch(currentTitleArr, newValueArr);

    const removeDiff = diffValues.filter(
        (diffValueItem) => diffValueItem.type === 'remove'
    );
    const addDiff = diffValues.filter(
        (diffValueItem) => diffValueItem.type === 'add'
    );

    const indexesToRemove: number[] = [];

    removeDiff.map((removeValue) => {
        removeValue.items.forEach((removeValueItem, index) =>
            indexesToRemove.push(removeValue.oldPos + index)
        );
    });

    updatedTempItems = updatedTempItems.filter(
        (valueItem, index) => !indexesToRemove.includes(index)
    );

    addDiff.forEach((addValue) => {
        addValue.items.forEach((addValueItem, index) => {
            updatedTempItems.splice(addValue.newPos + index, 0, {
                content: addValueItem,
            });
        });
    });

    return updatedTempItems;
};

function* handleReplaceText(
    action: ReplaceProjectSubtitlesTextAction
): Generator {
    try {
        const { newText, oldText } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const searchQuery = (yield select(
            getProjectSubtitlesSearchQuery
        )) as string;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        const transcriptList = [...projectCurrentSubtitles, ...projectUnsyncedSubtitlesList];
        // ///////////////////////
        const filteredSubtitles = transcriptList.filter((item) =>
            item.data.toLowerCase().includes(searchQuery.toLowerCase())
        );
        const removableSentenceIds = filteredSubtitles.map((item) => item.id);

        const updatedSentences = filteredSubtitles.map((item) => {
            const regEx = new RegExp(oldText, 'ig');

            const updatedTitle = item.data.replaceAll(regEx, newText);
            const updatedDataItems = checkDiffAndUpdateTempItems(
                item,
                updatedTitle
            );
            const newItems = createItemsFromSentence(
                updatedTitle,
                item.timestamps[0].timestamp,
                item.timestamps[1].timestamp
            );

            return {
                ...item,
                id: uuid(),
                data: updatedTitle,
                dataItems: updatedDataItems,
                items: newItems,
            };
        }) as TransformedSentence[];
        const updatedSentencesIds = updatedSentences.map((item) => item.id);

        // ///////////////////////

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => !removableSentenceIds.some(id => id === item.id)
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => !removableSentenceIds.some(id => id === item.id)
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            ...removableSentenceIds,
        ];

        updatedUnsyncedSubtitlesList = [
            ...updatedUnsyncedSubtitlesList,
            ...updatedSentences,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        yield put(subtitlesActions.setProjectSubtitlesSearchQuery(''));

        // ////////////////////////////
        const filteredSentences = projectDetails.sentences?.filter(
            item => !removableSentenceIds.some(id => id === item)
        );
        const transformedNewSentences = transformSentencesToRawSentence(
            updatedSentences,
            projectDetails.id
        ) as RawSentence[];

        const updatedProject = {
            ...projectDetails,
            sentences: [
                ...(filteredSentences || []),
                ...updatedSentencesIds,
            ]
        };

        yield put(subtitlesActions.addOrEditSubtitle({
            projectDetails,
            subtitles: transformedNewSentences,
            subtitleIds: removableSentenceIds,
        }));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

    } catch( error ) {
        reportErrorToSentry(error, 'handleReplaceText',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while replacing subtitles text.`
        );
    }
}

// reset settings

function* handleResetProjectSubtitlesSettings(
    action: ResetProjectSubtitlesSettingsAction,
): Generator {
    try {
        const mediaId = action?.payload?.mediaId;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        const projectTranscript = [...projectCurrentSubtitles, ...projectUnsyncedSubtitlesList];

        // //////////////////

        const transcriptWithStyles = projectTranscript.filter((item) => {
            const dataItems = item.dataItems || [];
            const dataItemsWithStyles = dataItems.filter(
                (dataItem) => dataItem.styles
            );

            return dataItemsWithStyles?.length > 0;
        });

        const transcriptWithStylesIds = transcriptWithStyles.map(
            (item) => item.id
        );
        const updatedStyledItems = transcriptWithStyles.map((item) => ({
            ...item,
            id: uuid(),
            dataItems: [],
        }));

        // //////////////////////////////
        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => !transcriptWithStylesIds.some(id => id === item.id)
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => !transcriptWithStylesIds.some(id => id === item.id)
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            ...transcriptWithStylesIds,
        ];

        updatedUnsyncedSubtitlesList = [
            ...updatedUnsyncedSubtitlesList,
            ...updatedStyledItems,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // ///////////////
        let updatedProject = {} as Project;
        const mediaSubtitlesSettings = projectDetails?.settings?.mediaSubtitlesSettings || [];
        const mediaHasSettings = mediaSubtitlesSettings.some(item => item.mediaId === mediaId)

        if(
            mediaId &&
            mediaSubtitlesSettings &&
            mediaHasSettings
        ) {
            const restMediaSettings = mediaSubtitlesSettings.filter(
                item => item.mediaId !== mediaId,
            );

            updatedProject = {
                ...projectDetails,
                settings: {
                    ...projectDetails.settings,
                    mediaSubtitlesSettings: [
                        ...restMediaSettings,
                        {
                            mediaId,
                            settings: defaultProjectSubtitlesSettings
                        }
                    ],
                },
            }
        } else {
            let updatedMediaSubtitlesSettings = mediaSubtitlesSettings.map(
                item => ({
                    ...item,
                    settings: defaultProjectSubtitlesSettings
                }),
            );

            updatedProject = {
                ...projectDetails,
                settings: {
                    ...projectDetails.settings,
                    subtitles: defaultProjectSubtitlesSettings,
                    mediaSubtitlesSettings: updatedMediaSubtitlesSettings
                },
            };
        }

        // /////////////////
        const transformedNewSentences = transformSentencesToRawSentence(
            updatedStyledItems,
            projectDetails.id
        );
        const filteredSentences = projectDetails.sentences?.filter(item =>
            !transcriptWithStylesIds.some(id => id === item)
        );
        const updatedStyledItemsIds = transcriptWithStyles.map(
            (item) => item.id
        );

        updatedProject = {
            ...updatedProject,
            sentences: [
                ...(filteredSentences || []),
                ...updatedStyledItemsIds,
            ]
        }

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield put(subtitlesActions.addOrEditSubtitle({
            projectDetails: updatedProject,
            subtitles: transformedNewSentences,
            subtitleIds: transcriptWithStylesIds,
        }));

    } catch (error) {
        reportErrorToSentry(error, 'handleResetProjectSubtitlesSettings',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while resetting subtitles settings.`
        );
    }
}

// create subtitles
function* handleSyncProjectSentencesCreation(): Generator {
    const createProjectSentencesChannel = yield actionChannel(
        ProjectSubtitlesActionTypes.ADD_OR_EDIT_SUBTITLE,
    ) as any;

    while (true) {
        const action = yield take(createProjectSentencesChannel as any);

        yield call(handleAddOrEditSubtitle as any, action);
    }
}

// delete subtitle

function* checkLastSubtitlesItemDelete(
    action: CheckVideoEmptyTranscriptListAction
): Generator {
    const data = action.payload;

    const medias = (yield select(getMedias)) as TimelineMedias;
    const mediaSources = (yield select(getProjectMediaSources)) as MediaFile[];
    const { subtitles } = medias;

    yield all(
        data.map((item) => {
            const filteredSubtitles = subtitles.filter(
                (subtitle) =>
                    subtitle?.videoId === item.videoId &&
                    !item.ids.includes(subtitle.id)
            );

            const selectedMediaSource = mediaSources.find(
                (mediaSource) => mediaSource.id === item.videoId
            );

            if (!filteredSubtitles?.length) {
                return put(
                    subtitlesActions.deleteProjectSubtitlesLanguage({
                        languages: [item.language],
                        videoId: selectedMediaSource ? item.videoId : '',
                    })
                );
            }
        })
    );
}

function* handleDeleteSubtitles(
    action: DeleteSubtitleAction
): Generator {
    try {
        const { items } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const { version, id: projectId } = projectDetails;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        const itemsIds = items.map((item) => item.id);

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => !itemsIds.some(id => id === item.id)
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => !itemsIds.some(id => id === item.id)
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            ...itemsIds,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // ///////////////////

        const filteredSentences = projectDetails?.sentences?.filter(id => {
            return !itemsIds.some(item => item === id);
        });

        const updatedProject = {
            ...projectDetails,
            sentences: filteredSentences,
        };

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield call(
            ProjectSubtitlesClient.deleteSubtitleFromProject,
            projectId, version, itemsIds
        );

        // selected timeline objects
        const selectedTimelineObjects = (yield select(
            getTimelineSelectedObjects
        )) as SelectedProjectObject[];

        const updatedSelectedTimelineObjects = selectedTimelineObjects.filter(
            (item) => !itemsIds.includes(item.object.id)
        );

        yield put(
            ProjectsStudioActions.setProjectSelectedObjects(
                updatedSelectedTimelineObjects
            )
        );

        yield put(ProjectsStudioActions.removeSelectedObjects(itemsIds));

        const groupedByVideoId = groupBy(items, 'videoId');

        const groupedArr = [] as CheckVideoEmptyTranscriptListActionPayload[];

        Object.keys(groupedByVideoId).map((key) =>
            groupedArr.push({
                videoId: key,
                ids: groupedByVideoId[key].map((item) => item.id),
                language: items[0].language,
            })
        );

        yield put(
            subtitlesActions.checkVideoEmptyTranscriptList(groupedArr)
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleDeleteSubtitles',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while deleting subtitles.`
        );
    }
}

export function* handleDeleteSelectedSubtitles(
    updatedProject: Project,
    items: DeletedSentence[],
): Generator {
    try {
        const { version, id: projectId } = updatedProject;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        const itemsIds = items.map((item) => item.id);

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => !itemsIds.some(id => id === item.id)
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => !itemsIds.some(id => id === item.id)
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            ...itemsIds,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // ///////////////////

        const filteredSentences = updatedProject?.sentences?.filter(id => {
            return !itemsIds.some(item => item === id);
        });

        updatedProject.sentences = filteredSentences
        // const updatedProject = {
        //     ...projectDetails,
        //     sentences: filteredSentences,
        // };

        // yield put(
        //     ProjectsActions.updateProject({
        //         project: updatedProject,
        //     })
        // );

        yield call(
            ProjectSubtitlesClient.deleteSubtitleFromProject,
            projectId, version, itemsIds
        );

        // selected timeline objects
        // const selectedTimelineObjects = (yield select(
        //     getTimelineSelectedObjects
        // )) as SelectedProjectObject[];

        // const updatedSelectedTimelineObjects = selectedTimelineObjects.filter(
        //     (item) => !itemsIds.includes(item.object.id)
        // );

        // yield put(
        //     ProjectsStudioActions.setProjectSelectedObjects(
        //         updatedSelectedTimelineObjects
        //     )
        // );

        yield put(ProjectsStudioActions.removeSelectedObjects(itemsIds));

        const groupedByVideoId = groupBy(items, 'videoId');

        const groupedArr = [] as CheckVideoEmptyTranscriptListActionPayload[];

        Object.keys(groupedByVideoId).map((key) =>
            groupedArr.push({
                videoId: key,
                ids: groupedByVideoId[key].map((item) => item.id),
                language: items[0].language,
            })
        );

        yield put(
            subtitlesActions.checkVideoEmptyTranscriptList(groupedArr)
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleDeleteSubtitles',  {
            ...({items, updatedProject} || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while deleting subtitles.`
        );
    }
}

function* handleSyncProjectSentenceDelete(): Generator {
    const channel = yield actionChannel(
        ProjectSubtitlesActionTypes.CHECK_EMPTY_VIDEO_TRANSCRIPT_LIST
    ) as any;

    while (true) {
        const action = yield take(channel as any);

        yield call(checkLastSubtitlesItemDelete as any, action);
    }
}

// delete language

function* handleDeleteProjectSubtitlesLanguage(
    action: DeleteProjectSubtitlesLanguageAction
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    if(!projectDetails || !projectDetails.id) {
        return;
    }

    const { languages, videoId } = action.payload;

    const languagesString = languages.join(',');

    try {
        // if (!projectDetails.subtitlesNotAttached) {
        //     const res1 = (yield call(MetadataClient.deleteSentenceLanguages, {
        //         projectId: projectDetails.id,
        //         language: languagesString,
        //         videoId,
        //         sentenceType: SentenceTypes.ORIGINAL,
        //         projectVersion: projectDetails.version
        //     })) as AxiosResponse;
        // }

        const res = (yield call(MetadataClient.deleteSentenceLanguages, {
            projectId: projectDetails.id,
            language: languagesString,
            videoId,
            sentenceType:
                projectDetails?.subtitlesNotAttached ?
                    SentenceTypes.PROJECT :
                    SentenceTypes.ORIGINAL,
            projectVersion: projectDetails.version
        })) as AxiosResponse;

        let changedSentences = [] as string[];

        if(res?.data?.length) {
            const deletedIds = res?.data as string[];

            const updatedProjectData = (yield select(getProjectDetailsInfo)) as Project;
            const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
            const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
            const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

            let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
            let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
            let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

            updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                item => !deletedIds.some(id => id === item.id)
            );
            updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                item => !deletedIds.some(id => id === item.id)
            );
            updatedDeletedUnsyncedSubtitlesIds = [
                ...updatedDeletedUnsyncedSubtitlesIds,
                ...deletedIds,
            ];

            yield put(subtitlesActions.setProjectAllSubtitlesListAction({
                subtitles: updatedCurrentSubtitlesList,
            }));
            yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
                subtitles: updatedUnsyncedSubtitlesList
            }));
            yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

            // //////////////////////////////////

            const filteredIds = updatedProjectData?.sentences?.filter(
                item => !deletedIds.some(id => id === item)
            );

            changedSentences = (filteredIds || []);
        }

        const videosLanguages = (yield select(
            getProjectVideosLanguages
        )) as ProjectVideoLanguages[];

        let filteredLanguagesList = [] as string[];

        const updatedVideosLanguages = videosLanguages.map((item) => {
            if (item.videoId === videoId) {
                const updatedLanguages = item.languagesList.filter(
                    (languageItem) => !languages.includes(languageItem)
                );

                filteredLanguagesList = updatedLanguages;

                return {
                    ...item,
                    languagesList: updatedLanguages,
                };
            }

            return item;
        });

        let updatedProject;

        if (projectDetails.subtitlesNotAttached) {
            let language = projectDetails.language;
            if (!filteredLanguagesList.includes(language)) {
                language = filteredLanguagesList[0];
            }
            updatedProject = {
                ...projectDetails,
                language: language || '',
            } as Project;
        } else {
            const updateProjectMediaSourcesSettings =
                projectDetails.mediaSourcesSettings.map((settings) => {
                    if (settings.mediaSourceId === videoId) {
                        let language = settings.language;
                        if (!filteredLanguagesList.includes(language)) {
                            language = filteredLanguagesList[0];
                        }
                        return {
                            ...settings,
                            language: language || '',
                        };
                    }

                    return settings;
                });

            updatedProject = {
                ...projectDetails,
                mediaSourcesSettings: updateProjectMediaSourcesSettings,
            } as Project;
        }

        yield put(
            subtitlesActions.setProjectVideosLanguages(
                updatedVideosLanguages
            )
        );

        yield put(ProjectsActions.setProjectUpdatesHistory([]));

        updatedProject = {
            ...updatedProject,
            sentences: changedSentences,
        };

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
                getSubtitles: true,
            })
        );

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);

        yield put(ProjectsActions.setProjectUpdatesHistory([]));
    } catch (error) {
        reportErrorToSentry(error, 'handleDeleteProjectSubtitlesLanguage',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while deleting subtitles language.`
        );
    }
}

// split subtitles

export function* handleSplitSubtitles(): Generator {
    try {
        const projectDetails = (yield select(
            getProjectDetailsInfo
        )) as Project;

        const timelineTime = (yield select(getProjectTimelineTime)) as number;
        const medias = (yield select(getMedias as any)) as TimelineMedias;
        const { frameRate, videoSubtitles, audioSubtitles } = medias;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        // ///////////////////

        let selectedSentence = videoSubtitles.find(
            (subtitle) =>
                secondsToFrameNumber(timelineTime, frameRate) >
                secondsToFrameNumber(
                    subtitle.timeline.startTime,
                    frameRate
                ) &&
                secondsToFrameNumber(timelineTime, frameRate) <
                secondsToFrameNumber(subtitle.timeline.endTime, frameRate)
        );

        if (!selectedSentence) {
            selectedSentence = audioSubtitles.find(
                (subtitle) =>
                    secondsToFrameNumber(timelineTime, frameRate) >
                    secondsToFrameNumber(
                        subtitle.timeline.startTime,
                        frameRate
                    ) &&
                    secondsToFrameNumber(timelineTime, frameRate) <
                    secondsToFrameNumber(
                        subtitle.timeline.endTime,
                        frameRate
                    )
            );
        }

        if (selectedSentence) {
            const sentenceTimelineTime =
                timelineTime -
                selectedSentence.timeline.initialStartTime +
                +selectedSentence.startTime;

            const splittedSentences = splitSentenceBySpecifiedTime({
                sentence: selectedSentence,
                time: sentenceTimelineTime,
            });

            const removableId = selectedSentence.id;

            let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
            let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
            let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

            updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                item => item.id !== removableId
            );
            updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                item => item.id !== removableId
            );
            updatedDeletedUnsyncedSubtitlesIds = [
                ...updatedDeletedUnsyncedSubtitlesIds,
                removableId,
            ];

            updatedUnsyncedSubtitlesList = [
                ...updatedUnsyncedSubtitlesList,
                ...splittedSentences,
            ];

            yield put(subtitlesActions.setProjectAllSubtitlesListAction({
                subtitles: updatedCurrentSubtitlesList,
            }));
            yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
                subtitles: updatedUnsyncedSubtitlesList
            }));
            yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

            // ////////////////////////////
            const filteredSentences = projectDetails.sentences?.filter(item =>
                item !== removableId
            );
            const transformedNewSentences = transformSentencesToRawSentence(
                splittedSentences,
                projectDetails.id
            );
            const updatedSentencesIds = transformedNewSentences.map(item => item.id);

            const updatedProject = {
                ...projectDetails,
                sentences: [
                    ...(filteredSentences || []),
                    ...updatedSentencesIds,
                ]
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                })
            );

            yield put(subtitlesActions.addOrEditSubtitle({
                projectDetails,
                subtitles: transformedNewSentences,
                subtitleIds: [removableId],
            }));
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleSplitSubtitles', {});
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while splitting subtitles.`
        );
    }
}

// merge subtitles

function* handleMergeSentences(action: MergeSentencesAction): Generator {
    try {
        const { item, nextItem } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
        const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
        const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

        // ////////////////////////////

        const newTransformedSentence = {
            data: `${item.data} ${nextItem.data}`,
            id: uuid(),
            items: [...item.items, ...nextItem.items],
            language: item.language,
            speaker: item.speaker,
            startTime: item.startTime,
            endTime: nextItem.endTime,
            type: item.type,
            videoId: item.videoId,
            settings: item.settings,
            projectId: item.projectId,
            name: `${item.name} ${nextItem.name}`,
            originalSentence: `${item.originalSentence} ${nextItem.originalSentence}`,
            timestamps: [
                {
                    timestamp: item.timestamps[0].timestamp,
                },
                {
                    timestamp: nextItem.timestamps[1].timestamp,
                },
            ],
        } as TransformedSentence;

        const removableIds = [item.id, nextItem.id];

        // /////////////////////////////

        let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
        let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
        let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

        updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
            item => !removableIds.some(id => id === item.id)
        );
        updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
            item => !removableIds.some(id => id === item.id)
        );
        updatedDeletedUnsyncedSubtitlesIds = [
            ...updatedDeletedUnsyncedSubtitlesIds,
            ...removableIds,
        ];

        updatedUnsyncedSubtitlesList = [
            ...updatedUnsyncedSubtitlesList,
            newTransformedSentence,
        ];

        yield put(subtitlesActions.setProjectAllSubtitlesListAction({
            subtitles: updatedCurrentSubtitlesList,
        }));
        yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
            subtitles: updatedUnsyncedSubtitlesList
        }));
        yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

        // //////////////////////////
        const filteredSentences = projectDetails.sentences?.filter(item =>
            !removableIds.some(id => id === item)
        );
        const newSentences = transformSentencesToRawSentence(
            [newTransformedSentence],
            projectDetails.id
        );

        const updatedProject = {
            ...projectDetails,
            sentences: [
                ...(filteredSentences || []),
                newSentences[0].id,
            ]
        };

        yield put(subtitlesActions.addOrEditSubtitle({
            projectDetails,
            subtitles: newSentences,
            subtitleIds: removableIds,
        }));

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleMergeSentences',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while merging subtitles.`
        );
    }
}

// upload srt

function* handleUploadProjectTranscript(
    action: UploadProjectTranscriptAction
): Generator {
    try {
        yield put(subtitlesActions.setProjectTranscriptCreationLoading(true));

        const { file, language, videoId } = action.payload;

        const userId = (yield select(getUserId)) as string;
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const currentPlan = (yield select(
            getCurrentUserPlan
        )) as CurrentSubscription;

        const id = uuid();

        const uploadRes = (yield call(
            Storage.put,
            `srt/${id}/${file.name}`,
            file,
            {
                level: 'private',
                bucket: `${window.config.REACT_APP_DOWNLOAD_TRAILER_BUCKET}`,
            }
        )) as any;

        const path = `private/${userId}/${uploadRes.key}`;

        (yield call(ProjectSubtitlesClient.uploadSrt, {
            data: {
                language,
                srtKey: path,
            },
            projectId: projectDetails.id,
            videoId,
        }));

        yield put(ProjectsActions.getProjectDetails(projectDetails.id));
        yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

        const updatedProjectDetails = (yield select(
            getProjectDetailsInfo
        )) as Project;

        const updatedProject = {
            ...updatedProjectDetails,
            subtitlesNotAttached: !videoId,
            language,
            isSubtitling: true,
        } as Project;

        yield put(
            ProjectsActions.updateProject({
                project: updatedProject,
            })
        );

        yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);

        yield put(ProjectsActions.setProjectUpdatesHistory([]));

        analyticsUtil.srtUpload({
            language,
            userId,
            currentPlan,
            projectDetails,
        });

        yield put(ProjectsActions.getProjectDetails(updatedProjectDetails.id));
        yield take(ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS);

        yield delay(1500);
        const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];

        if(!projectCurrentSubtitles.length) {
            yield delay(3000);
        }


        yield put(
            subtitlesActions.setProjectTranscriptCreationLoading(false)
        );
    } catch (error) {
        reportErrorToSentry(error, 'handleUploadProjectTranscript',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            'An error occurred while uploading your SRT.'
        );

        yield put(
            subtitlesActions.setProjectTranscriptCreationLoading(false)
        );
    }
}

// download subtitles

function* handleDownloadSubtitles(
    action: ExportSubtitlesAction
): Generator {
    try {
        const { type } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const link = document.createElement('a');
        link.download = '';
        link.target = '_blank';

        const subtitlesLink = (yield call (ProjectSubtitlesClient.getProjectSubtitlesLink,
                type,
                projectDetails?.id || '',
                projectDetails?.version || ''
            )) as AxiosResponse;

        link.href = subtitlesLink.data.url;

        document.body.appendChild(link);
        link.click();

        analyticsUtil.downloadSubtitles({type});

        yield put (subtitlesActions.setExportSubtitlesStatus({
            isSuccess: true,
        }));
    } catch (error) {
        reportErrorToSentry(error, 'handleDownloadSubtitles',  {
            ...(action?.payload || {}),
        });

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            `An error occurred while downloading your subtitles data.`
        );

        yield put (subtitlesActions.setExportSubtitlesStatus({
            isSuccess: false,
        }));
    }
}

function* handleDownloadVideoSubtitles(
    action: ExportVideoSubtitlesAction
): Generator {
    try {
        const { type, video, language } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        const link = document.createElement('a');
        link.download = video ? (video.title || video.filename) : projectDetails.name;
        link.target = '_blank';

        const subtitlesLink = (yield call (ProjectSubtitlesClient.getProjectVideoSubtitlesLink,
            type,
            projectDetails?.id || '',
            projectDetails?.version || '',
            video?.id || '',
            language
        )) as AxiosResponse;

        link.href = subtitlesLink.data.url;

        document.body.appendChild(link);
        link.click();

        analyticsUtil.downloadSubtitles({type});
    } catch (error) {
        reportErrorToSentry(error, 'handleDownloadSubtitles',  {
            ...(action?.payload || {}),
        });

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            `An error occurred while downloading your subtitles data.`
        );
    }
}

// project language

function* handleChangeProjectVideoLanguage(
    action: ChangeProjectVideoLanguageAction
): Generator {
    try {
        const { videoId, language, videoNewLanguages } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const effect = subtitleEffects.find(
            item => item.value === SubtitleEffect.BLOCK
        );
        const isRtl = ConnectedScripts.includes(language.split('-')[0]);

        let updatedProjectDetails;
        let hasUpdate = false;
        let updatedSettings = projectDetails.settings;

        if (!projectDetails.subtitlesNotAttached) {
            let updatedMediaSourcesSettings = [] as ProjectMediaSourcesSettings[];

            if(videoNewLanguages && videoNewLanguages.length) {
                updatedMediaSourcesSettings = projectDetails.mediaSourcesSettings.map((settings) => {
                    const matchIndex = videoNewLanguages.findIndex((item) => item.videoId === settings.mediaSourceId);
                    if (matchIndex !== -1) {
                        hasUpdate = true;
                        return {
                            ...settings,
                            language: videoNewLanguages[matchIndex].languagesList[0],
                        };
                    }
                    return settings;
                });
            } else {
                updatedMediaSourcesSettings =
                    projectDetails.mediaSourcesSettings.map((settings) => {
                        if (settings.mediaSourceId === videoId) {
                            hasUpdate = true;
                            return {
                                ...settings,
                                language,
                            };
                        }

                        return settings;
                    });
            }

            updatedProjectDetails = {
                ...projectDetails,
                mediaSourcesSettings: updatedMediaSourcesSettings,
            } as Project;

            const mediaSubtitlesSettings = projectDetails.settings.mediaSubtitlesSettings;

            if(effect && isRtl && videoId) {
                if(!mediaSubtitlesSettings?.length) {
                    const currentEffect = projectDetails.settings.subtitles.subtitleEffect;
                    const shouldResetForRtl = (
                        currentEffect === SubtitleEffect.KARAOKE ||
                        currentEffect === SubtitleEffect.BOX_HIGHLIGHT
                    );

                    if(shouldResetForRtl) {
                        updatedSettings = {
                            ...updatedSettings,
                            subtitles: {
                                ...updatedSettings.subtitles,
                                subtitleEffect: effect.value,
                                outline: effect.outline,
                                outlineColor: effect.outlineColor,
                                subtitlesColor: effect.color || WHITE_PRIMARY,
                                angle: 0,
                                shadow: 1,
                                shadowColor: effect.shadowColor,
                            }
                        }
                    }
                } else {
                    const currentMediaId = videoId;
                    const currentMediaSettings = mediaSubtitlesSettings?.find(
                        item => item.mediaId === currentMediaId
                    );
                    if(currentMediaSettings) {
                        const currentEffect = currentMediaSettings?.settings?.subtitleEffect;
                        const shouldResetForRtl = (
                            currentEffect === SubtitleEffect.KARAOKE ||
                            currentEffect === SubtitleEffect.BOX_HIGHLIGHT
                        );
                        if(shouldResetForRtl && mediaSubtitlesSettings) {
                            const updatedMediaSettings = mediaSubtitlesSettings.map(item => {
                                if(item.mediaId === currentMediaId) {
                                    return {
                                        ...item,
                                        subtitleEffect: effect.value,
                                        outline: effect.outline,
                                        outlineColor: effect.outlineColor,
                                        subtitlesColor: effect.color || WHITE_PRIMARY,
                                        angle: 0,
                                        shadow: 1,
                                        shadowColor: effect.shadowColor,
                                    }
                                }
                                return item;
                            })
                            updatedSettings = {
                                ...updatedSettings,
                                mediaSubtitlesSettings: updatedMediaSettings
                            }
                        }
                    }
                }
            }
        } else {
            hasUpdate = true;

            updatedProjectDetails = {
                ...projectDetails,
                language,
            };

            if(isRtl && effect) {
                const currentEffect = projectDetails.settings.subtitles.subtitleEffect;
                const shouldResetForRtl = (
                    currentEffect === SubtitleEffect.KARAOKE ||
                    currentEffect === SubtitleEffect.BOX_HIGHLIGHT
                );
                if(shouldResetForRtl) {
                    updatedSettings = {
                        ...updatedSettings,
                        subtitles: {
                            ...updatedSettings.subtitles,
                            subtitleEffect: effect.value,
                            outline: effect.outline,
                            outlineColor: effect.outlineColor,
                            subtitlesColor: effect.color || WHITE_PRIMARY,
                            angle: 0,
                            shadow: 1,
                            shadowColor: effect.shadowColor,
                        }
                    }
                }
            }
        }

        updatedProjectDetails = {
            ...updatedProjectDetails,
            settings: updatedSettings,
        };

        if(hasUpdate) {
            yield put(
                ProjectsActions.updateProject({
                    project: updatedProjectDetails,
                    getSubtitles: !!updatedProjectDetails.sentences,
                    skipVersionUpdate: true,
                    isChangeLanguage: true,
                }),
            );
        }

        yield put(subtitlesActions.addOrEditSentencesSuccess());
    } catch (error) {
        reportErrorToSentry(error, 'handleChangeProjectVideoLanguage',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while changing the language.`
        );
    }
}

function* handleCheckVideosLanguagesList(
    languagesList: ProjectVideoLanguages[],
    oldLanguagesList: ProjectVideoLanguages[]
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const mediaSourceSettings = projectDetails.mediaSourcesSettings;

    const videoLanguagesList = [] as ProjectVideoLanguages[];

    for (const item of languagesList) {
        const list = item.languagesList;

        const oldList =
            oldLanguagesList.find(
                (listItem) => listItem.videoId === item.videoId
            )?.languagesList || [];

        const currentMediaSettings = mediaSourceSettings.find(
            (settingsItem) => settingsItem.mediaSourceId === item.videoId
        );

        const currentLanguage = currentMediaSettings?.language;

        if (list?.length > oldList?.length && oldList?.length > 0) {
            const listDiff = difference(list, oldList);

            if (listDiff?.length && listDiff[0] !== currentLanguage) {
                videoLanguagesList.push({
                    videoId: item.videoId,
                    languagesList: [listDiff[0]]
                });
                // return yield put(
                //     subtitlesActions.changeProjectVideoLanguage({
                //         videoId: item.videoId,
                //         language: listDiff[0],
                //     })
                // );
            }
        } else if (currentLanguage && !list.includes(currentLanguage)) {
            // yield put(
            //     subtitlesActions.changeProjectVideoLanguage({
            //         videoId: item.videoId,
            //         language: list?.[0] || '',
            //     })
            // );
            videoLanguagesList.push({
                videoId: item.videoId,
                languagesList: [list?.[0] || '']
            });
        }
    }

    if(videoLanguagesList) {
        yield put(
            subtitlesActions.changeProjectVideoLanguage({
                language: '',
                videoNewLanguages: videoLanguagesList
            })
        );
    }
}

function* handleCheckProjectLanguagesList(
    list: string[],
    oldList: string[],
    currentLanguage: string
): Generator {
    if (list?.length > oldList?.length && oldList?.length > 0) {
        const listDiff = difference(list, oldList);

        if (listDiff?.length && listDiff[0] !== currentLanguage) {
            yield put(
                subtitlesActions.changeProjectVideoLanguage({
                    language: listDiff[0],
                })
            );
        }
    } else if (
        currentLanguage &&
        !list?.includes(currentLanguage) &&
        list?.length
    ) {
        yield put(
            subtitlesActions.changeProjectVideoLanguage({
                language: list[0],
            })
        );
    }
}

export function* handleGetProjectVideosLanguages(
    isInitProject?: boolean
): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const oldLanguagesList = (yield select(
        getProjectVideosLanguages
    )) as ProjectVideoLanguages[];

    if (!projectDetails.subtitlesNotAttached) {
        const languagesResArray = (yield all(
            projectDetails.mediaSources.map((mediaSource) =>
                call(
                    MetadataClient.getAvailableLanguages,
                    mediaSource,
                    projectDetails.id
                )
            )
        )) as AxiosResponse[];

        if(isInitProject) {
            let projectVideosNotAddedLanguages = languagesResArray.map(
                (languagesRes, index: number) => {
                    const videoLanguages = languagesRes.data.content?.videoLanguages;

                    return ({
                        videoId: projectDetails.mediaSources[index],
                        languagesList: videoLanguages,
                    });
                }
            ) as ProjectVideoLanguages[];

            projectVideosNotAddedLanguages = projectVideosNotAddedLanguages?.filter(
                item => item.languagesList.length > 0
            );

            const addGeneratedSubtitlesLoading = (yield select(getAddGeneratedSubtitlesLoading));

            if(projectDetails?.sentences && !addGeneratedSubtitlesLoading) {
                yield put(subtitlesActions.addGeneratedSubtitles({
                    videosWithLanguages: projectVideosNotAddedLanguages,
                    projectDetails
                }));
            }
        }


        const projectVideosLanguages = languagesResArray.map(
            (languagesRes, index: number) => {
                const languages = languagesRes.data.content?.languages;
                const videoLanguages = languagesRes.data.content?.videoLanguages;

                const projectAllLanguages = languages.concat(videoLanguages.filter(
                    (lang: string) => !languages.includes(lang)
                ));

                return ({
                    videoId: projectDetails.mediaSources[index],
                    languagesList: projectAllLanguages,
                });
            }
        ) as ProjectVideoLanguages[];

        yield put(
            subtitlesActions.setProjectVideosLanguages(
                projectVideosLanguages
            )
        );

        yield call(
            handleCheckVideosLanguagesList,
            projectVideosLanguages,
            oldLanguagesList
        );
    } else {
        const languagesRes = (yield call(
            MetadataClient.getAvailableLanguages,
            '',
            projectDetails.id
        )) as AxiosResponse;

        const languagesObj = languagesRes.data.content;

        const languages = languagesObj.languages;
        const videoLanguages = languagesObj.videoLanguages;

        const list = languages;

        const oldList =
            oldLanguagesList.find((item) => item.videoId === '')
                ?.languagesList || [];
        const currentLanguage = projectDetails.language;

        yield put(
            subtitlesActions.setProjectVideosLanguages([
                {
                    videoId: '',
                    languagesList: list,
                },
            ])
        );

        yield call(
            handleCheckProjectLanguagesList,
            list,
            oldList,
            currentLanguage
        );
    }
}

// add library video subtitles to project

function* handleAddLibraryVideoSubtitles(
    action: AddLibraryVideoSubtitlesAction
): Generator {
    try {
        const {videoIds } = action.payload;

        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        const { id: projectId, version } = projectDetails;

        const res = (yield call(
            ProjectSubtitlesClient.addLibraryVideoSubtitlesToProject,
            projectId, version, videoIds
        )) as AxiosResponse;

        if(res?.data?.length) {
            const updatedProjectData = (yield select(getProjectDetailsInfo)) as Project;

            const newSentences = res?.data as string[];

            const updatedProject = {
                ...updatedProjectData,
                sentences: [
                    ...(updatedProjectData.sentences || []),
                    ...newSentences,
                ]
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                    getSubtitles: true,
                    skipVersionUpdate: true,
                })
            );
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleAddLibraryVideoSubtitles',  {
            ...(action?.payload || {}),
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            'An error occurred while adding library video subtitles.'
        );
    }
}

// add auto generated subtitles to project

function* handleAddGeneratedSubtitles(
    action: AddGeneratedSubtitlesAction
): Generator {
    try {
        const {
            videos,
            projectDetails,
            languages,
            videosWithLanguages
        } = action.payload;
        const { id: projectId, version } = projectDetails;

        let allNewSentences: string[] = [];
        let lastExecutionDetails = {} as any;

        if(projectDetails.subtitlesNotAttached && languages && languages.length) {
            const type = 'translate';

            const language = languages.join(',');

            const res = (yield call(
                ProjectSubtitlesClient.addGeneratedSubtitlesToProject,
                projectId, version, language || '', type
            )) as AxiosResponse;

            if (res?.data?.length) {
                allNewSentences = allNewSentences.concat(res?.data);
            }

            if(
                projectDetails.latestAnalyzeJobId &&
                (projectDetails.latestAnalyzeJobId !== projectDetails.lastExecutionArn)
            ) {
                lastExecutionDetails.lastExecutionArn = projectDetails.latestAnalyzeJobId;
            }
        } else if(videos && videos.length) {
            for (const video of videos) {
                const videoId = video.id;

                const isTranscript = (video.status.analyze.transcript?.addedToProject === false);
                const isTranslate = (video.status.analyze.translate?.addedToProject === false);

                if(isTranscript) {
                    const language = video.status.analyze.transcript?.language;
                    const type = 'transcript';

                    const res = (yield call(
                        ProjectSubtitlesClient.addGeneratedSubtitlesToProject,
                        projectId, version, language || '', type, videoId
                    )) as AxiosResponse;

                    if (res?.data?.length) {
                        allNewSentences = allNewSentences.concat(res?.data);
                    }
                }

                if(isTranslate) {
                   const language = video.status.analyze.translate?.language;
                   const type = 'translate';

                   const res = (yield call(
                       ProjectSubtitlesClient.addGeneratedSubtitlesToProject,
                       projectId, version, language || '', type, videoId
                   )) as AxiosResponse;

                   if (res?.data?.length) {
                       allNewSentences = allNewSentences.concat(res?.data);
                   }
               }
            }

            const projectVideosIdsWithTranslateGeneration = (yield select(
                getProjectVideosIdsWithTranslateGeneration
            )) as string[];

            const updatedProjectVideosIdsWithTranslateGeneration = projectVideosIdsWithTranslateGeneration.filter(
                item => !videos.some(video => video.id === item)
            );

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                    updatedProjectVideosIdsWithTranslateGeneration
                )
            );
        } else if(videosWithLanguages && videosWithLanguages.length) {
            for (const item of videosWithLanguages) {
                const videoId = item.videoId;

                const language = item.languagesList.join(',');
                const type = 'transcript';

                const res = (yield call(
                    ProjectSubtitlesClient.addGeneratedSubtitlesToProject,
                    projectId, version, language || '', type, videoId
                )) as AxiosResponse;

                if (res?.data?.length) {
                    allNewSentences = allNewSentences.concat(res?.data);
                }
            }
        }

        if (allNewSentences.length > 0) {
            const updatedProjectData = (yield select(getProjectDetailsInfo)) as Project;
            let updateProjectMediaSourcesSettings = updatedProjectData.mediaSourcesSettings;

            if(videos && videos.length === 1) {
                const isTranscript = (videos[0].status.analyze.transcript?.addedToProject === false);
                const isTranslate = (videos[0].status.analyze.translate?.addedToProject === false);

                if(isTranscript && isTranslate) {
                    updateProjectMediaSourcesSettings =
                        updatedProjectData.mediaSourcesSettings.map((settings) => {
                            if (settings.mediaSourceId === videos[0].id) {
                                let language = videos[0].status.analyze.translate?.language || settings?.language;
                                return {
                                    ...settings,
                                    language: language || '',
                                };
                            }

                            return settings;
                        });
                }
            }

            const updatedProject = {
                ...updatedProjectData,
                sentences: [
                    ...(updatedProjectData.sentences || []),
                    ...allNewSentences,
                ],
                ...lastExecutionDetails,
                mediaSourcesSettings: updateProjectMediaSourcesSettings
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                    getSubtitles: true,
                    skipVersionUpdate: true,
                    isVersionChange: true,
                })
            );

            yield take(ProjectsActionTypes.UPDATE_PROJECT_SUCCESS);
        }

        yield put(subtitlesActions.addProjectSubtitlesSuccess());
    } catch (error) {
        reportErrorToSentry(error, 'handleAddGeneratedSubtitles',  {
            ...(action?.payload || {}),
        });

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            'An error occurred while adding generated subtitles.'
        );
        yield put(subtitlesActions.addProjectSubtitlesFailure());
    }
}

// generate translate

function* handleGenerateTranslate(
    action: GenerateProjectVideoTranslateAction
): Generator {
    const { languages, sourceLanguage, mediaDetails } = action.payload;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (!projectDetails.subtitlesNotAttached) {
        if (mediaDetails) {
            const projectVideosIdsWithTranslateGeneration = (yield select(
                getProjectVideosIdsWithTranslateGeneration
            )) as string[];

            let updatedProjectVideosIdsWithTranslateGeneration = [
                ...projectVideosIdsWithTranslateGeneration,
                mediaDetails.id,
            ];

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                    updatedProjectVideosIdsWithTranslateGeneration
                )
            );

            const { duration } = (
                mediaDetails.mediaInfo?.proxy ||
                mediaDetails.mediaInfo?.original
            ).container;

            const operationPrice = (yield call(
                calculatePriceInCreditsForTranslateOperation,
                duration * 1000,
                {
                    targetLanguages: languages,
                }
            )) as number;

            const currentSubtitlingCredits = (yield select(
                getCurrentCredits
            )) as number;

            const isOperationAvailable =
                currentSubtitlingCredits >= operationPrice;

            if (!isOperationAvailable) {
                updatedProjectVideosIdsWithTranslateGeneration =
                    updatedProjectVideosIdsWithTranslateGeneration.filter(
                        (id) => id !== mediaDetails.id
                    );

                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                        updatedProjectVideosIdsWithTranslateGeneration
                    )
                );
                yield call(handleTranslatePaymentLimitation);
            } else {
                try {
                    const analysisData = {
                        categories: [RekognitionCategory.AWS_TRANSLATE],
                        translate: {
                            sourceLanguage,
                            targetLanguages: languages.join(','),
                            projectId: projectDetails.id,
                            projectVersion: projectDetails.version,
                        },
                    } as ReanalysisReqData;

                    yield call(
                        AnalysisClient.reanalyze,
                        mediaDetails.id,
                        analysisData
                    );

                    analyticsUtil.translateGenerate({
                        sourceLanguage,
                        userId,
                        currentPlan,
                        projectDetails,
                        languages,
                    })

                    yield delay(15000);

                    yield call(syncProjectMedias);

                    yield delay(15000);

                    let currentProjectVideosIdsWithTranslateGeneration = (yield select(
                        getProjectVideosIdsWithTranslateGeneration
                    )) as string[];

                    currentProjectVideosIdsWithTranslateGeneration =
                        currentProjectVideosIdsWithTranslateGeneration.filter(
                            (id) => id !== mediaDetails.id
                        );

                    yield put(
                        subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                            currentProjectVideosIdsWithTranslateGeneration
                        )
                    );
                } catch (error) {
                    reportErrorToSentry(error, 'handleGenerateTranslate',  {
                        ...(action?.payload || {}),
                    });
                    console.log({ error });
                    showNotification(
                        NotificationTypes.error,
                        `An error occurred while translating the subtitles.`
                    );
                }
            }
        }
    } else {
        yield put(
            subtitlesActions.setProjectSubtitlesTranslateLoading(true)
        );
        const operationPrice = (yield call(
            calculatePriceInCreditsForTranslateOperation,
            projectDetails.duration * 1000,
            {
                targetLanguages: languages,
            }
        )) as number;

        const currentSubtitlingCredits = (yield select(
            getCurrentCredits
        )) as number;

        const isOperationAvailable = currentSubtitlingCredits >= operationPrice;

        if (!isOperationAvailable) {
            yield call(handleTranslatePaymentLimitation);
        } else {
            try {
                const analysisData = {
                    categories: [RekognitionCategory.AWS_TRANSLATE],
                    translate: {
                        sourceLanguage,
                        targetLanguages: languages.join(','),
                        projectId: projectDetails.id,
                        projectVersion: projectDetails.version,
                    },
                } as ReanalysisReqData;

                yield call(AnalysisClient.reanalyze, '', analysisData);

                analyticsUtil.translateGenerate({
                    sourceLanguage,
                    userId,
                    currentPlan,
                    projectDetails,
                    languages,
                })

                yield delay(4000);

                yield put(ProjectsActions.syncProjectDetails());

                yield delay(5000);

                yield call(syncProjectMedias);

                yield delay(15000);
            } catch (error) {
                reportErrorToSentry(error, 'handleGenerateTranslate',  {
                    ...(action?.payload || {}),
                });
                console.log({ error });
                showNotification(
                    NotificationTypes.error,
                    `An error occurred while translating the subtitles.`
                );
            }
        }
    }
}

function* handleGenerateAutoTranslate(
    action: GenerateProjectVideoAutoTranslateAction
): Generator {
    const {
        sourceLanguage,
        targetLanguage,
        mediaDetails
    } = action.payload;
    const currVideoId = mediaDetails?.id;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (mediaDetails) {
        const { duration } = (
            mediaDetails.mediaInfo?.proxy ||
            mediaDetails.mediaInfo?.original
        ).container;

        const operationPrice = (yield call(
            calculatePriceInCreditsForTranslateOperation,
            duration * 1000,
            {
                targetLanguages: [targetLanguage],
            }
        )) as number;

        const currentSubtitlingCredits = (yield select(
            getCurrentCredits
        )) as number;

        const isOperationAvailable =
            currentSubtitlingCredits >= (2 * operationPrice);

        if (!isOperationAvailable) {
            yield call(handleTranslatePaymentLimitation);
        } else {
            const videosIdsWithTranscriptGeneration = (yield select(
                getProjectVideosIdsWithTranscriptGeneration
            )) as string[];
            const videosIdsWithTranslateGeneration = (yield select(
                getProjectVideosIdsWithTranslateGeneration
            )) as string[];

            let updatedMediaIdsWithTranscriptGeneration = [
                ...videosIdsWithTranscriptGeneration,
                currVideoId,
            ];

            let updatedMediaIdsWithTranslateGeneration: string[] = [
                ...videosIdsWithTranslateGeneration,
                currVideoId,
            ];

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                    updatedMediaIdsWithTranscriptGeneration
                )
            );

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                    updatedMediaIdsWithTranslateGeneration
                )
            );

            try {
                const analysisData = {
                    categories: [RekognitionCategory.AWS_TRANSCRIPT, RekognitionCategory.AWS_TRANSLATE],
                    translate: {
                        sourceLanguage,
                        targetLanguages: targetLanguage,
                        projectId: projectDetails.id,
                        projectVersion: projectDetails.version,
                    },
                    language: sourceLanguage
                } as ReanalysisReqData;

                yield call(
                    AnalysisClient.reanalyze,
                    mediaDetails.id,
                    analysisData
                );

                analyticsUtil.translateGenerate({
                    sourceLanguage,
                    userId,
                    currentPlan,
                    projectDetails,
                    languages: [targetLanguage],
                })

                yield delay(2000);

                let statusesResponse = (yield call(
                    VideosClient.getVideosByIds,
                    currVideoId
                )) as AxiosResponse;

                let projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

                while(projectVideoMediaSources[0].status?.analyze?.translate?.status === StatusType.IN_PROGRESS)  {
                    yield delay(5000);
                    statusesResponse = (yield call(
                        VideosClient.getVideosByIds,
                        currVideoId
                    )) as AxiosResponse;

                    projectVideoMediaSources = statusesResponse.data.content as MediaFile[];

                    if(updatedMediaIdsWithTranscriptGeneration.some(item => item === currVideoId )) {
                        if(statusesResponse?.data?.content[0].status?.analyze?.transcript?.status === StatusType.FAILED) {
                            break;
                        }
                    }
                }

                yield call(syncProjectMedias);

                if(projectVideoMediaSources[0].status?.analyze?.translate?.status === StatusType.FAILED) {
                    showNotification(
                        NotificationTypes.error,
                        'Translation generation failed'
                    );
                } else if(projectVideoMediaSources[0].status?.analyze?.transcript?.status === StatusType.FAILED) {
                    showNotification(
                        NotificationTypes.error,
                        'Subtitles generation failed'
                    );
                }

                updatedMediaIdsWithTranscriptGeneration =
                    updatedMediaIdsWithTranscriptGeneration.filter((item) => item !== currVideoId);
                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                        updatedMediaIdsWithTranscriptGeneration
                    )
                );

                updatedMediaIdsWithTranslateGeneration =
                    updatedMediaIdsWithTranslateGeneration?.filter((item) => item !== currVideoId);
                yield put(
                    subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                        updatedMediaIdsWithTranslateGeneration
                    )
                );
            } catch (error) {
                reportErrorToSentry(error, 'handleGenerateAutoTranslate', {
                    ...(action?.payload || {}),
                });
                console.log({ error });
                showNotification(
                    NotificationTypes.error,
                    `An error occurred while translating your media.`
                );
            }
        }
    }
}

// cancel translate

function* handleCancelProjectMediaSourceTranslate(
    action: CancelProjectMediaSourceTranslateAction
): Generator {
    const id = action.payload;

    try {
        yield call(VideosClient.cancelTranslateJob, id);

        const videosIdsWithTranscriptGeneration = (yield select(
            getProjectVideosIdsWithTranscriptGeneration
        )) as string[];
        const videosIdsWithTranslateGeneration = (yield select(
            getProjectVideosIdsWithTranslateGeneration
        )) as string[];
        const projectAudiosIdsWithDubbingGeneration = (yield select(
            getProjectAudiosIdsWithDubbingGeneration
        )) as string[];

        const filteredVideosIdsWithTranscriptGeneration =
            videosIdsWithTranscriptGeneration.filter((item) => item !== id);
        const filteredVideosIdsWithTranslateGeneration =
            videosIdsWithTranslateGeneration.filter((item) => item !== id);
        const filteredAudiosIdsWithDubbingGeneration =
            projectAudiosIdsWithDubbingGeneration.filter((item) => item !== id);

        yield put(
            subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                filteredVideosIdsWithTranscriptGeneration
            )
        );
        yield put(
            subtitlesActions.setProjectVideosIdsWithTranslateGeneration(
                filteredVideosIdsWithTranslateGeneration
            )
        );
        yield put(
            ProjectsActions.setProjectAudiosIdsWithDubbingGeneration(
                filteredAudiosIdsWithDubbingGeneration
            )
        );

        yield delay(2000);

        yield call(syncProjectMedias);
    } catch (error) {
        reportErrorToSentry(error, 'handleCancelProjectMediaSourceTranslate',  {
            payload: action?.payload || null
        });
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while cancelling subtitles translate.`
        );
    }
}

function* handleCancelProjectTranslate(): Generator {
    try {
        const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

        if (projectDetails?.latestAnalyzeJobId) {
            yield call(ProjectsClient.cancelProjectAnalyze, {
                projectId: projectDetails.id,
                data: {
                    stepFunctionsExecutionArn:
                    projectDetails.latestAnalyzeJobId,
                },
            });

            yield put(
                subtitlesActions.setProjectSubtitlesTranslateLoading(
                    false
                )
            );

            yield delay(2000);

            yield call(syncProjectMedias);
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleCancelProjectTranslate', {});
        console.log({ error });
        showNotification(
            NotificationTypes.error,
            `An error occurred while cancelling subtitles translate.`
        );
    }
}

// delete deleted video subtitles

function* handleDeleteVideoSubtitles(
    action: DeleteVideoSubtitlesAction
): Generator {
    try {
        let { projectDetails, videoIds } = action.payload;

        if(!projectDetails) {
            projectDetails = (yield select(getProjectDetailsInfo)) as Project;
        }

        const { id: projectId, version } = projectDetails;

        const res =  (yield call(
            ProjectSubtitlesClient.deleteVideoSubtitlesFromProject,
            projectId, version, videoIds
        )) as AxiosResponse;

        if(res?.data?.length) {
            const deletedIds = res?.data as string[];

            const projectCurrentSubtitles = (yield select(getProjectSubtitlesList)) as TransformedSentence[];
            const projectUnsyncedSubtitlesList = (yield select(getProjectUnsyncedSubtitlesList)) as TransformedSentence[];
            const projectDeletedUnsyncedSubtitlesIds = (yield select(getProjectDeletedUnsyncedSubtitlesList)) as string[];

            let updatedCurrentSubtitlesList = [...projectCurrentSubtitles];
            let updatedUnsyncedSubtitlesList = [...projectUnsyncedSubtitlesList];
            let updatedDeletedUnsyncedSubtitlesIds = [...projectDeletedUnsyncedSubtitlesIds];

            updatedCurrentSubtitlesList = updatedCurrentSubtitlesList.filter(
                item => !deletedIds.some(id => id === item.id)
            );
            updatedUnsyncedSubtitlesList = updatedUnsyncedSubtitlesList.filter(
                item => !deletedIds.some(id => id === item.id)
            );
            updatedDeletedUnsyncedSubtitlesIds = [
                ...updatedDeletedUnsyncedSubtitlesIds,
                ...deletedIds,
            ];

            yield put(subtitlesActions.setProjectAllSubtitlesListAction({
                subtitles: updatedCurrentSubtitlesList,
            }));
            yield put(subtitlesActions.setProjectUnsyncedSubtitlesListAction({
                subtitles: updatedUnsyncedSubtitlesList
            }));
            yield put(subtitlesActions.setProjectDeletedUnsyncedSubtitlesListAction(updatedDeletedUnsyncedSubtitlesIds));

            // ///////////////////////////
            const updatedProjectData = (yield select(getProjectDetailsInfo)) as Project;

            const filteredIds = updatedProjectData?.sentences?.filter(
                item => !deletedIds.some(id => id === item)
            );

            const updatedProject = {
                ...updatedProjectData,
                sentences: [
                    ...(filteredIds || [])
                ]
            };

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                    getSubtitles: true,
                    skipVersionUpdate: true,
                })
            );
        }
    } catch (error) {
        reportErrorToSentry(error, 'handleDeleteVideoSubtitles',  {
            ...(action?.payload || {}),
        });

        console.log({ error });

        showNotification(
            NotificationTypes.error,
            'An error occurred while deleting video subtitles.'
        );
    }
}

// generate transcript

function* handleGenerateTranscript(
    action: GenerateProjectVideoTranscriptAction
): Generator {
    const { language, mediaDetails } = action.payload;

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    let updatedProjectVideosIdsWithTranscriptGeneration = (yield select(
        getProjectVideosIdsWithTranscriptGeneration
    )) as string[];

    const { duration } = (
        mediaDetails.mediaInfo?.proxy || mediaDetails.mediaInfo?.original
    ).container;

    const operationPrice = (yield call(
        calculatePriceInCreditsForSubtitlingOperation,
        duration * 1000
    )) as number;

    const currentSubtitlingCredits = (yield select(
        getCurrentCredits
    )) as number;

    const isOperationAvailable = currentSubtitlingCredits >= operationPrice;

    if (!isOperationAvailable) {
        yield call(handleSubtitlingPaymentLimitation);

        updatedProjectVideosIdsWithTranscriptGeneration =
            updatedProjectVideosIdsWithTranscriptGeneration.filter(
                (id) => id !== mediaDetails.id
            );
        yield put(
            subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                updatedProjectVideosIdsWithTranscriptGeneration
            )
        );
    } else {
        try {
            const data = {
                collectionId: '',
                categories: [RekognitionCategory.AWS_TRANSCRIPT],
                language,
            } as ReanalysisReqData;

            yield call(AnalysisClient.reanalyze, mediaDetails.id, data);

            const updatedMediaSourcesSettings =
                projectDetails.mediaSourcesSettings.map((settings) => {
                    if (settings.mediaSourceId === mediaDetails.id) {
                        return {
                            ...settings,
                            language,
                        };
                    }

                    return settings;
                });

            const updatedProject = {
                ...projectDetails,
                mediaSourcesSettings: updatedMediaSourcesSettings,
                isSubtitling: true,
                subtitlesNotAttached: false,
            } as Project;

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                    skipVersionUpdate: true,
                    getSubtitles: true,
                })
            );

            analyticsUtil.transcriptGenerate({
                language,
                userId,
                currentPlan,
                projectDetails,
            });

            yield delay(15000);

            yield call(syncProjectMedias);

            // yield delay(10000);

            const currentProjectVideosIdsWithTranscriptGeneration =
                (yield select(
                    getProjectVideosIdsWithTranscriptGeneration
                )) as string[];

            updatedProjectVideosIdsWithTranscriptGeneration =
                currentProjectVideosIdsWithTranscriptGeneration.filter(
                    (id) => id !== mediaDetails.id
                );

            yield put(
                subtitlesActions.setProjectVideosIdsWithTranscriptGeneration(
                    updatedProjectVideosIdsWithTranscriptGeneration
                )
            );
        } catch (error) {
            reportErrorToSentry(error, 'handleGenerateTranscript',  {
                ...(action?.payload || {}),
            });
            console.log({ error });
            showNotification(
                NotificationTypes.error,
                `An error occurred while generating subtitles.`
            );
        }
    }
}

export function* handleSyncProjectSubtitlesCreation(): Generator {
    const channel = yield actionChannel(
        ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSCRIPT
    ) as any;

    while (true) {
        const action = (yield take(channel as any)) as any;

        yield call(handleGenerateTranscript as any, action);
    }
}

export function* projectSubtitlesSagaNew(): Generator {
    yield takeLatest(
        ProjectSubtitlesActionTypes.GET_PROJECT_ALL_SUBTITLES_LIST,
        handleGetProjectAllSubtitles
    );
    // yield takeLatest(
    //     ProjectSubtitlesActionTypes.ADD_OR_EDIT_SUBTITLE,
    //     handleAddOrEditSubtitle
    // );
    yield takeLatest(
        ProjectSubtitlesActionTypes.MANUAL_CREATE_PROJECT_SENTENCE,
        handleManualCreateProjectSentence
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.CREATE_PROJECT_TRANSCRIPT_BY_SPECIFIC_TIME,
        handleCreateProjectTranscriptItemBySpecificTime
    );
    yield takeEvery(
        ProjectSubtitlesActionTypes.UPDATE_PROJECT_TRANSCRIPT_ITEM,
        handleUpdateProjectTranscriptItem
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.REPLACE_SUBTITLES_TEXT,
        handleReplaceText
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.DELETE_SUBTITLE,
        handleDeleteSubtitles
    );
    yield takeEvery(
        ProjectSubtitlesActionTypes.DELETE_PROJECT_SUBTITLES_LANGUAGE,
        handleDeleteProjectSubtitlesLanguage
    );
    // yield takeLatest(
    //     ProjectSubtitlesActionTypes.SPLIT_SUBTITLE,
    //     handleSplitSubtitle
    // );
    yield takeLatest(
        ProjectSubtitlesActionTypes.MERGE_SENTENCES,
        handleMergeSentences
    );
    // yield takeLatest(
    //     ProjectSubtitlesActionTypes.MERGE_SUBTITLES,
    //     handleMergeSubtitles
    // );
    yield takeLatest(
        ProjectSubtitlesActionTypes.UPLOAD_TRANSCRIPT,
        handleUploadProjectTranscript
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.EXPORT_SUBTITLES,
        handleDownloadSubtitles
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.EXPORT_VIDEO_SUBTITLES,
        handleDownloadVideoSubtitles
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.ADD_LIBRARY_VIDEO_SUBTITLES,
        handleAddLibraryVideoSubtitles
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.ADD_GENERATED_SUBTITLES,
        handleAddGeneratedSubtitles
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.DELETE_VIDEO_SUBTITLES,
        handleDeleteVideoSubtitles
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSLATE,
        handleGenerateTranslate
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.CANCEL_PROJECT_TRANSLATE,
        handleCancelProjectTranslate
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.CANCEL_PROJECT_MEDIA_SOURCE_TRANSLATE,
        handleCancelProjectMediaSourceTranslate
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.CHANGE_PROJECT_VIDEO_LANGUAGE,
        handleChangeProjectVideoLanguage
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.RESET_PROJECT_SUBTITLES_SETTINGS,
        handleResetProjectSubtitlesSettings
    );
    yield takeLatest(
        ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_AUTO_TRANSLATE,
        handleGenerateAutoTranslate
    );

    yield all([
        call(handleSyncProjectSentenceDelete),
        call(handleSyncProjectSentencesCreation),
        call(handleSyncProjectSubtitlesCreation),
    ]);
}
