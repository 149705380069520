import produce from 'immer';
import {
    ProjectsUploadAction,
    ProjectsUploadActionTypes,
    ProjectUploadState,
} from './types';

const initialState: ProjectUploadState = {
    isImageUploadInProgress: false,
    stockVideoIdWithUploadInProgress: null,
    stockAudioIdWithUploadInProgress: null,
    stockSoundEffectIdWithUploadInProgress: null,
    stockImageIdWithUploadInProgress: null,
};

export default (
    state = initialState,
    action: ProjectsUploadAction
): ProjectUploadState =>
    produce(state, (draft) => {
        switch (action.type) {
            // image upload
            case ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_START:
                draft.isImageUploadInProgress = true;

                return;
            case ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_SUCCESS:
                draft.isImageUploadInProgress = false;

                return;
            case ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_FAIL:
                draft.isImageUploadInProgress = false;
                return;

            // stock video
            case ProjectsUploadActionTypes.SET_PROJECT_STOCK_VIDEO_ID_FOR_UPLOAD:
                draft.stockVideoIdWithUploadInProgress = action.payload;
                return;

            // stock audio
            case ProjectsUploadActionTypes.SET_PROJECT_STOCK_AUDIO_ID_FOR_UPLOAD:
                draft.stockAudioIdWithUploadInProgress = action.payload;
                return;

            // stock sound effect
            case ProjectsUploadActionTypes.SET_PROJECT_STOCK_SOUND_EFFECT_ID_FOR_UPLOAD:
                draft.stockSoundEffectIdWithUploadInProgress = action.payload;
                return;

            // stock audio
            case ProjectsUploadActionTypes.SET_PROJECT_STOCK_IMAGE_ID_FOR_UPLOAD:
                draft.stockImageIdWithUploadInProgress = action.payload;
                return;
            default:
                return state;
        }
    });
