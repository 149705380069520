import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1.25;

const UploadedVideoIcon = ({ size }: IconProps): JSX.Element => {
  let height = 40;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.1379 0.862305H0.862061V38.7933H49.1379V0.862305Z"
        fill="#313C6E"
      />
      <path
        d="M50 39.6552H0V0H50V39.6552ZM1.72414 37.931H48.2759V1.72414H1.72414V37.931Z"
        fill="#29325C"
      />
      <path
        d="M21.5518 25.8624V19.7883V13.7935L31.0345 19.8279L21.5518 25.8624Z"
        fill="white"
      />
      <path
        d="M9.48275 0.862305H0.862061V10.3451H9.48275V0.862305Z"
        fill="#415091"
      />
      <path
        d="M10.3448 11.2069H0V0H10.3448V11.2069ZM1.72414 9.48276H8.62069V1.72414H1.72414V9.48276Z"
        fill="#29325C"
      />
      <path
        d="M9.48275 10.3447H0.862061V19.8275H9.48275V10.3447Z"
        fill="#415091"
      />
      <path
        d="M10.3448 20.6893H0V9.48242H10.3448V20.6893ZM1.72414 18.9652H8.62069V11.2066H1.72414V18.9652Z"
        fill="#29325C"
      />
      <path
        d="M9.48275 19.8276H0.862061V29.3104H9.48275V19.8276Z"
        fill="#415091"
      />
      <path
        d="M10.3448 30.1722H0V18.9653H10.3448V30.1722ZM1.72414 28.4481H8.62069V20.6895H1.72414V28.4481Z"
        fill="#29325C"
      />
      <path
        d="M9.48275 29.3101H0.862061V38.7928H9.48275V29.3101Z"
        fill="#415091"
      />
      <path
        d="M10.3448 39.6547H0V28.4478H10.3448V39.6547ZM1.72414 37.9305H8.62069V30.1719H1.72414V37.9305Z"
        fill="#29325C"
      />
      <path
        d="M49.138 0.862305H40.5173V10.3451H49.138V0.862305Z"
        fill="#415091"
      />
      <path
        d="M49.9999 11.2069H39.655V0H49.9999V11.2069ZM41.3792 9.48276H48.2757V1.72414H41.3792V9.48276Z"
        fill="#29325C"
      />
      <path
        d="M49.138 10.3447H40.5173V19.8275H49.138V10.3447Z"
        fill="#415091"
      />
      <path
        d="M49.9999 20.6893H39.655V9.48242H49.9999V20.6893ZM41.3792 18.9652H48.2757V11.2066H41.3792V18.9652Z"
        fill="#29325C"
      />
      <path
        d="M49.138 19.8276H40.5173V29.3104H49.138V19.8276Z"
        fill="#415091"
      />
      <path
        d="M49.9999 30.1722H39.655V18.9653H49.9999V30.1722ZM41.3792 28.4481H48.2757V20.6895H41.3792V28.4481Z"
        fill="#29325C"
      />
      <path
        d="M49.138 29.3101H40.5173V38.7928H49.138V29.3101Z"
        fill="#415091"
      />
      <path
        d="M49.9999 39.6547H39.655V28.4478H49.9999V39.6547ZM41.3792 37.9305H48.2757V30.1719H41.3792V37.9305Z"
        fill="#29325C"
      />
    </svg>
  );
};

export default UploadedVideoIcon;
