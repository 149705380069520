import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useMemo } from 'react';

import { StockMedia } from 'interfaces/stockMedia';

import {
    getStockImageIdWithUploadInProgress,
    uploadProjectStockImage,
} from 'state/modules/projectUpload';

const Image = styled.div`
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Item = styled.button`
    border: 1px solid;
    background-color: gray;
    color: white;
    flex-basis: 142px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 10px;
    overflow: hidden;
    height: 80px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    outline: none;
`;

const LoaderContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
`;

interface Props {
    item: StockMedia;
}

const StockImage = ({ item }: Props) => {
    const { id } = item;

    const imageIdWithUploadInProgress = useSelector(
        getStockImageIdWithUploadInProgress
    );

    const isUploadInProgress = useMemo(
        () => !!imageIdWithUploadInProgress,
        [imageIdWithUploadInProgress]
    );

    const isCurrentImageUploadInProgress = useMemo(
        () => imageIdWithUploadInProgress === id,
        [id, imageIdWithUploadInProgress]
    );

    const dispatch = useDispatch();

    const handleDownload = async () => {
        dispatch(uploadProjectStockImage(item));
    };

    const renderLoader = () => {
        return (
            <LoaderContainer>
                <Loader
                    type="Oval"
                    color="rgb(0,152,253)"
                    secondaryColor="rgba(255, 255, 255, 0.5)"
                    height={32}
                    width={32}
                />
            </LoaderContainer>
        );
    };

    return (
        <Item onClick={handleDownload} disabled={isUploadInProgress}>
            <Image
                style={{
                    backgroundImage: `url(${item.display_sizes[0].uri})`,
                }}
            />
            {isCurrentImageUploadInProgress ? renderLoader() : null}
        </Item>
    );
};

export default StockImage;
