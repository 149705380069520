import { IconProps } from './index';

const CreateFolderSecondIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bcXHqe ihSzce"
        >
            <g clipPath="url(#a)">
                <path
                    d="M12 22H3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5.333a2 2 0 0 1 1.2.4l3.2 2.4c.173.13.38.2.597.2H21a2 2 0 0 1 2 2v2.5"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.75 13.5a.75.75 0 0 0-1.5 0v2.75H15.5a.75.75 0 0 0 0 1.5h2.75v2.75a.75.75 0 0 0 1.5 0v-2.75h2.75a.75.75 0 0 0 0-1.5h-2.75V13.5Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill={color} d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CreateFolderSecondIcon;
