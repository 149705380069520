import { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';

import { Icon } from 'components/UI';

import { checkBrowserInfo } from 'state/modules/app';
import { hideModal } from 'state/modules/modal';
import {
    getProjectImageUploadStatus,
    uploadProjectImage,
} from 'state/modules/projectUpload';

import styles from './styles.module.scss';

const ProjectImageUploadModal = (): JSX.Element => {
    const isUploadInProgress = useSelector(getProjectImageUploadStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkBrowserInfo());
    }, [dispatch]);

    const closeModal = () => {
        dispatch(hideModal());
    };

    const handleUploadLocalFile = (files: File[]) => {
        if (files.length) {
            const canvas = document.getElementById('ProjectPlayerCanvas');

            if (canvas) {
                const canvasHeight = canvas.clientHeight;
                const canvasWidth = canvas.clientWidth;

                dispatch(
                    uploadProjectImage({
                        images: files,
                        canvasWidth,
                        canvasHeight,
                    })
                );
            }
        }
    };

    const renderDropzoneContent = () => (
        <>
            <Icon name="upload-cloud" />
            <p className={styles.ProjectUploadModal__dropzoneDescription}>
                <span
                    className={
                        styles.ProjectUploadModal__dropzoneDescription_accent
                    }
                >
                    Click to Upload
                </span>
                or Drag and Drop
            </p>
        </>
    );

    const renderDropzone = () => (
        <Dropzone
            onDrop={handleUploadLocalFile}
            multiple
            accept={{
                'image/*': [],
            }}
        >
            {({ getRootProps, getInputProps }): JSX.Element => (
                <section className={styles.ProjectUploadModal__dropzoneWrap}>
                    <div
                        {...getRootProps()}
                        className={styles.ProjectUploadModal__dropzoneRoot}
                    >
                        <input
                            {...getInputProps()}
                            className={styles.ProjectUploadModal__dropzone}
                            // disabled={isUploadFromExternalSourceInProgress}
                        />
                        {renderDropzoneContent()}
                    </div>
                </section>
            )}
        </Dropzone>
    );

    const renderCloseButton = () => (
        <button
            className={styles.ProjectUploadModal__closeBtn}
            onClick={closeModal}
        >
            <Icon name="close" size={15} />
        </button>
    );

    const renderStatus = () => (
        <div className={styles.ProjectUploadModal__loaderWrap}>
            <span className={styles.ProjectUploadModal__loaderTitle}>
                Uploading...
            </span>
            <Loader type="Oval" color="#fd0b50" height={20} width={20} />
        </div>
    );

    return (
        <div className={styles.ProjectUploadModal}>
            <div className={styles.ProjectUploadModal__topLine}>
                <p className={styles.ProjectUploadModal__title}>Upload image</p>
                {renderCloseButton()}
            </div>
            <div className={styles.ProjectUploadModal__content}>
                {renderDropzone()}
            </div>
        </div>
    );
};

export default ProjectImageUploadModal;
