import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox, Icon } from 'components/UI';

import {
    getModalEvent,
    getModalOptionalData,
    hideModal,
    ModalEvent,
} from 'state/modules/modal';

const Title = styled.p`
    font-size: 26px;
    font-family: 'Proxima Nova Medium', sans-serif;
    margin-bottom: 20px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
`;

const SkipTogglerContainer = styled.div`
    margin-top: 25px;
`;

const Container = styled.div`
    z-index: 100;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding: 35px 40px;
`;

const WelcomeModal = () => {
    const [skipVideoEditingIntro, setSkipVideoEditingIntro] = useState(false);
    const [skipSmartSearchIntro, setSkipSmartSearchIntro] = useState(false);

    const modalEvent = useSelector(getModalEvent);
    const modalData = useSelector(getModalOptionalData);

    const isTogglerHidden = modalData?.isTogglerHidden;

    const dispatch = useDispatch();

    useEffect(() => {
        const shouldSkipVideoEditingIntro = localStorage.getItem(
            'skipVideoEditingIntro'
        );
        const shouldSkipSmartSearchIntro = localStorage.getItem(
            'skipSmartSearchIntro'
        );

        if (shouldSkipVideoEditingIntro) {
            setSkipVideoEditingIntro(true);
        }

        if (shouldSkipSmartSearchIntro) {
            setSkipSmartSearchIntro(true);
        }
    }, []);

    const closeModal = () => {
        dispatch(hideModal());
    };

    const renderCloseButton = () => (
        <CloseButton onClick={closeModal}>
            <Icon name="close" size={15} />
        </CloseButton>
    );

    const renderSmartSearchIntro = () => (
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/y4oF9VeDImw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );

    const renderVideoEditingIntro = () => (
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Ua1tfBEsark"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );

    const renderContent = () => {
        if (modalEvent === ModalEvent.WELCOME_INTRO_SMART_SEARCH) {
            return renderSmartSearchIntro();
        }

        if (modalEvent === ModalEvent.WELCOME_INTRO_VIDEO_EDITING) {
            return renderVideoEditingIntro();
        }
    };

    const toggleSkipSmartSearchIntro = () => {
        setSkipSmartSearchIntro(!skipSmartSearchIntro);

        if (skipSmartSearchIntro) {
            localStorage.removeItem('skipSmartSearchIntro');
        } else {
            localStorage.setItem(
                'skipSmartSearchIntro',
                JSON.stringify(!skipSmartSearchIntro)
            );
        }
    };

    const toggleSkipVideoEditingIntro = () => {
        setSkipVideoEditingIntro(!skipVideoEditingIntro);

        if (skipVideoEditingIntro) {
            localStorage.removeItem('skipVideoEditingIntro');
        } else {
            localStorage.setItem(
                'skipVideoEditingIntro',
                JSON.stringify(!skipVideoEditingIntro)
            );
        }
    };

    const renderSkipVEIntro = () => (
        <Checkbox
            title="Do not show again"
            checked={skipVideoEditingIntro}
            onChange={toggleSkipVideoEditingIntro}
        />
    );
    const renderSkipSSIntro = () => (
        <Checkbox
            title="Do not show again"
            checked={skipSmartSearchIntro}
            onChange={toggleSkipSmartSearchIntro}
        />
    );

    const renderSkipButton = () => {
        if (modalEvent === ModalEvent.WELCOME_INTRO_SMART_SEARCH) {
            return renderSkipSSIntro();
        }

        if (modalEvent === ModalEvent.WELCOME_INTRO_VIDEO_EDITING) {
            return renderSkipVEIntro();
        }
    };

    return (
        <Container>
            <Title>Watch how to use Nova</Title>
            {renderContent()}
            {renderCloseButton()}
            {!isTogglerHidden ? (
                <SkipTogglerContainer>
                    {renderSkipButton()}
                </SkipTogglerContainer>
            ) : null}
        </Container>
    );
};

export default WelcomeModal;
