import { v4 as uuid } from "uuid";
import {
  TemplateTempDataItem,
  TemplateDataItemType,
  TemplateParsedLabelValue,
  TemplateTempDataItemAttributes,
} from "interfaces/templateMakerTool";

import { TemplateData, TemplateDataItem } from "state/modules/templates";
import { isTemplateFacialAttributeDataItem } from "./interfaceChecker";

export const getOptionTitleByCategoryKey = (key: string): string => {
  switch (key) {
    case TemplateDataItemType.CELEBRITY:
      return "Celebrity";
    case TemplateDataItemType.MOST_APPEARING_CELEBRITIES:
      return "Three Most Appearing Celebrities";
    case "attribute":
    case TemplateDataItemType.FACIAL_ATTRIBUTE:
      return "Facial Attribute";
    case TemplateDataItemType.MODERATED_LABEL:
      return "Moderated Label";
    case TemplateDataItemType.OBJECT:
      return "Object";
    case TemplateDataItemType.USER_DEFINED:
      return "Collection";
    case TemplateDataItemType.EMOTION:
      return "Emotion";
    case TemplateDataItemType.TEXT:
      return "Speech";
    default:
      return "";
  }
};

export const getChipTitleByType = (
  name: string,
  type: TemplateDataItemType
): string => {
  if (
    type === TemplateDataItemType.FACIAL_ATTRIBUTE ||
    type === TemplateDataItemType.EMOTION
  ) {
    const upperCaseWords = name.match(/\B[A-Z]\B/g);

    if (upperCaseWords) {
      name = name.replace(
        upperCaseWords[0],
        ` ${upperCaseWords[0].toLowerCase()}`
      );
    }

    name = name.charAt(0).toUpperCase() + name.slice(1);
  }

  return name.replace(/_/g, " ");
};

export const parseTemplateLabelValue = (
  value = ""
): TemplateParsedLabelValue => {
  if (value?.length) {
    const splittedValue = value
      .replace("{", "")
      .replace("}", "")
      .split(",")
      .map((commaMappedValue) => {
        const newValue = commaMappedValue
          .split(":")
          .map((doubleDotsMappedValue) => `"${doubleDotsMappedValue.trim()}"`)
          .join(":");

        return newValue;
      })
      .join(",");

    return JSON.parse(`{${splittedValue}}`);
  }
  return {
    name: "",
  };
};

export const tramsformNewLabelItems = (
  currentValue: Array<TemplateTempDataItem>
): Array<TemplateDataItem> => {
  const transformedItems = currentValue.map((item: TemplateTempDataItem) => {
    if (item.type === TemplateDataItemType.CELEBRITY) {
      return {
        type: item.type,
        id: item.id || uuid(),
        value: `{name: ${item.value}}`,
      };
    }
    if (
      item.type === TemplateDataItemType.FACIAL_ATTRIBUTE &&
      isTemplateFacialAttributeDataItem(item.value)
    ) {
      return {
        type: item.type,
        id: item.id || uuid(),
        value: `{emotion: ${item?.value?.emotion || ""}, attribute: ${
          item.value?.attribute || ""
        }}`,
      };
    }
    return {
      type: item.type,
      id: item.id || uuid(),
      value: item?.value,
    };
  }) as Array<TemplateDataItem>;

  return transformedItems;
};

export const getTemplateMakerInputChipTitle = (
  item: TemplateTempDataItem
): string => {
  if (item.type === TemplateDataItemType.MOST_APPEARING_CELEBRITIES) {
    return getOptionTitleByCategoryKey(item.type);
  }
  if (item.value) {
    if (item.type === TemplateDataItemType.FACIAL_ATTRIBUTE) {
      let title = "";

      Object.keys(item.value).map((key: string, index: number) => {
        if (
          isTemplateFacialAttributeDataItem(item.value) &&
          item.value[key as keyof TemplateTempDataItemAttributes]
        ) {
          title = `${title}${getOptionTitleByCategoryKey(key)}: ${
            item.value[key as keyof TemplateTempDataItemAttributes]
          }`;

          if (index < Object.keys(item.value).length - 1) {
            title = `${title}, `;
          }
        }
      });

      return title;
    }
    return `${getOptionTitleByCategoryKey(item.type)}:${
      item
        ? getChipTitleByType(
            typeof item.value === "string" ? item.value : "",
            item.type
          )
        : ""
    }`;
  }
  return "";
};

export const getTemplateLabelItemsTitles = (
  label: TemplateDataItem
): Array<string> => {
  const labelTitles = [] as Array<string>;

  if (label.type === TemplateDataItemType.MOST_APPEARING_CELEBRITIES) {
    const title = getOptionTitleByCategoryKey(label.type);

    labelTitles.push(title);
  } else if (label.type === TemplateDataItemType.FACIAL_ATTRIBUTE) {
    const parsed = parseTemplateLabelValue(label.value);

    Object.keys(parsed).map((key: string) => {
      if (parsed[key as keyof typeof parsed]) {
        labelTitles.push(
          `${getOptionTitleByCategoryKey(key)}: ${
            parsed[key as keyof typeof parsed]
          }`
        );
      }
    });
  } else {
    let { value } = label;
    let title = "";

    if (label.type === TemplateDataItemType.CELEBRITY) {
      const parsed = parseTemplateLabelValue(
        label.value
      ) as TemplateParsedLabelValue;

      value = parsed?.name || "";
    }

    title = `${getOptionTitleByCategoryKey(label.type)}: ${value}`;

    labelTitles.push(title);
  }

  return labelTitles;
};

export const transformTemplateData = (
  data: Array<TemplateData>
): Array<TemplateData> =>
  data.map((item: TemplateData) => ({
    ...item,
    items: (item?.items || []).map((subItem: TemplateDataItem) => {
      if (subItem.type === TemplateDataItemType.MOST_APPEARING_CELEBRITIES) {
        return {
          ...subItem,
          type: TemplateDataItemType.CELEBRITY,
        };
      }
      return subItem;
    }),
  }));

export const transformReceivedTemplateData = (
  data: Array<TemplateData>
): Array<TemplateData> =>
  data.map((item: TemplateData) => ({
    ...item,
    items: (item.items || []).map((subItem: TemplateDataItem) => {
      if (subItem.type === TemplateDataItemType.CELEBRITY && !subItem.value) {
        return {
          ...subItem,
          type: TemplateDataItemType.MOST_APPEARING_CELEBRITIES,
        };
      }
      if (
        subItem.type === TemplateDataItemType.CELEBRITY &&
        subItem.value &&
        !subItem.value.includes("name:")
      ) {
        const transformedValue = `{name: ${subItem.value}}`;

        return {
          ...subItem,
          value: transformedValue,
        };
      }
      if (
        subItem.type === TemplateDataItemType.FACIAL_ATTRIBUTE &&
        subItem.value &&
        !subItem.value.includes("emotion:") &&
        !subItem.value.includes("attribute:")
      ) {
        const transformedValue = `{attribute: ${subItem.value}}`;

        return {
          ...subItem,
          value: transformedValue,
        };
      }
      return subItem;
    }),
  }));
