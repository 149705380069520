import produce from "immer";

import { defaultVideoLibraryFilter } from "state/utils/filters";

import { SignOutAction, UserActionTypes } from "state/modules/user/types";
import { VideosAction, VideosActionTypes, VideosState } from "./types";

export const initialState: VideosState = {
  videos: [],
  audioFiles: [],
  selectedVideos: [],
  videosIdsForDelete: [],
  totalVideos: 0,
  totalAudioFiles: 0,
  analyzedVideos: [],
  videoFilter: defaultVideoLibraryFilter,
  videoDetails: null,
  isVideoDetailsLoading: true,
  isVideosLoading: false,
  isVideosDeleting: false,
  isVideoUpdateLoading: false,
  key: "",
  error: null,
  videoThumbnails: null,
  statusFilter: null,
};

export default (
  state: VideosState = initialState,
  action: VideosAction | SignOutAction
): VideosState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VideosActionTypes.GET_VIDEOS_LIST_START:
        draft.isVideosLoading = true;

        return;
      case VideosActionTypes.GET_VIDEOS_LIST_SUCCESS:
        draft.videos = action.payload.videos;
        draft.totalVideos = action.payload.total;
        draft.isVideosLoading = false;

        return;
      case VideosActionTypes.GET_VIDEOS_LIST_FAIL:
        draft.isVideosLoading = false;
        draft.error = action.payload;

        return;
      case VideosActionTypes.SET_VIDEO_DETAILS:
        draft.videoDetails = action.payload;

        return;
      case VideosActionTypes.SET_VIDEO_VTT:
        draft.videoThumbnails = action.payload;

        return;
      case VideosActionTypes.SET_VIDEO_DETAILS_LOADING:
        draft.isVideoDetailsLoading = action.payload;

        return;
      case VideosActionTypes.UNSELECT_VIDEO:
        return;
      case VideosActionTypes.UPDATE_VIDEOS_STATUSES_SUCCESS:
        draft.videos = action.payload;

        return;
      case VideosActionTypes.SET_SELECTED_VIDEOS:
        draft.selectedVideos = action.payload;

        return;
      case VideosActionTypes.SET_VIDEOS_IDS_FOR_DELETE:
        draft.videosIdsForDelete = action.payload;

        return;
      case VideosActionTypes.DELETE_VIDEOS_START:
        draft.isVideosDeleting = true;

        return;
      case VideosActionTypes.DELETE_VIDEOS_SUCCESS:
        draft.isVideosDeleting = false;

        return;
      case VideosActionTypes.DELETE_VIDEOS_FAIL:
        draft.isVideosDeleting = false;

        return;
      case VideosActionTypes.CLEAR_VIDEO_DETAILS:
        draft.videoDetails = null;
        draft.videoThumbnails = null;
        draft.isVideoDetailsLoading = true;

        return;
      case VideosActionTypes.SET_VIDEO_FILTER:
        draft.videoFilter = action.payload;

        return;
      case VideosActionTypes.UPDATE_VIDEO_DETAILS_START:
        draft.isVideoUpdateLoading = true;

        return;
      case VideosActionTypes.UPDATE_VIDEO_DETAILS_SUCCESS:
        draft.isVideoUpdateLoading = false;

        return;
      case VideosActionTypes.UPDATE_VIDEO_DETAILS_FAIL:
        draft.isVideoUpdateLoading = false;

        return;
      case VideosActionTypes.SET_MEDIA_LIST_STATUS_FILTER:
        draft.statusFilter = action.payload;

        return;
      case UserActionTypes.SIGN_OUT:
        return initialState;
      default:
        return state;
    }
  });
