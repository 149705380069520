/**
 * Notification util with react-toastify
 * https://www.npmjs.com/package/react-toastify
 */

import { toast } from 'react-toastify';

export enum NotificationTypes {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error',
}

/**
 * Throws notification popup
 * @param notificationType given type of notification
 * @param message notification text content
 */
export const showNotification = (
    notificationType: NotificationTypes,
    message: string,
    customOptions?: any
) => {
    const options = customOptions || {
        autoClose: 6000,
        hideProgressBar: false,
    };
    switch (notificationType) {
        case NotificationTypes.success:
            return toast.success(message || '', options);

        case NotificationTypes.info:
            return toast.info(message || '', options);

        case NotificationTypes.warning:
            return toast.warn(message || '', options);

        case NotificationTypes.error:
            return toast.error(message || '', options);

        default:
            return toast(message || '', options);
    }
};
