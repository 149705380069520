import { IconProps } from '../index';

const TextIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.25C6.27208 3.25 3.25 6.27208 3.25 10V22C3.25 25.7279 6.27208 28.75 10 28.75H22C25.7279 28.75 28.75 25.7279 28.75 22V10C28.75 6.27208 25.7279 3.25 22 3.25H10ZM4.75 10C4.75 7.10051 7.10051 4.75 10 4.75H22C24.8995 4.75 27.25 7.10051 27.25 10V22C27.25 24.8995 24.8995 27.25 22 27.25H10C7.10051 27.25 4.75 24.8995 4.75 22V10ZM10.6667 9.91667H9.91667V10.6667V13.6296H11.4167V11.4167H15.25V20.5833H13.6296V22.0833H16H18.3704V20.5833H16.75V11.4167H20.5833V13.6296H22.0833V10.6667V9.91667H21.3333H16H10.6667Z"
                fill={color}
            />
        </svg>
    );
};

export default TextIconNew;


