import {IconProps} from "./index";

const AutofitIcon = ({size, color}: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            height={height}
            width={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color}>
                <path d="m20 6.24219c0-1.24264-1.0074-2.25-2.25-2.25h-11.5c-1.24264 0-2.25 1.00736-2.25 2.25v4.00001c0 .4142.33579.75.75.75s.75-.3358.75-.75v-4.00001c0-.41422.33579-.75.75-.75h11.5c.4142 0 .75.33578.75.75v4.00001c0 .4142.3358.75.75.75s.75-.3358.75-.75z"/>
                <path d="m17.2188 13.2197c.2663-.2663.683-.2905.9766-.0726l.0841.0726 2.3701 2.3674c.2084.1257.3504.3758.3504.6638 0 .2521-.1089.4751-.2758.6111l-.0749.0528-2.3698 2.3672-.0841.0726c-.261.1936-.6192.196-.8826.0072l-.094-.0798-.0726-.0841c-.1936-.261-.196-.6192-.0071-.8826l.0797-.094 1.2212-1.2213h-3.7838l-.0891-.0069c-.3203-.0497-.5671-.3635-.5671-.7432s.247-.6934.5673-.7431l.089-.0068h3.7837l-1.2212-1.2197-.0726-.0841c-.2178-.2936-.1936-.7103.0726-.9765z"/>
                <path d="m6.78115 13.2226c.26627.2663.29047.6829.07262.9765l-.07262.0841-1.22115 1.2197h3.78367c.36243 0 .65633.3357.65633.7499 0 .3797-.24681.6936-.56712.7433l-.08905.0068h-3.78383l1.22115 1.2213c.26627.2663.29047.683.07262.9766l-.07262.0841c-.26627.2663-.68293.2905-.97654.0726l-.08412-.0726-2.36977-2.3672c-.20853-.1256-.35072-.3758-.35072-.6639 0-.288.14201-.5381.35039-.6638l2.3701-2.3674c.29289-.2929.76777-.2929 1.06066 0z"/>
                 
                {/* <path d="m5.99388 4.18558c.31173.27276.34331.74659.07055 1.05831l-.66159.7561h2.86172c.41421 0 .75.33579.75.75s-.33579.75-.75.75h-2.86175l.66163.75614c.27275.31173.24116.78556-.07056 1.05832-.31173.27276-.78555.24116-1.05831-.07056l-1.75-2.00003c-.24743-.28277-.24742-.70498 0-.98775l1.75-1.99998c.27276-.31173.74659-.34331 1.05831-.07055z"/> */}
                {/* <path d="m17.9502 5.24389c-.2727-.31172-.2412-.78555.0706-1.05831.3117-.27276.7855-.24118 1.0583.07055l1.75 1.99998c.2474.28277.2474.70498 0 .98775l-1.75 2.00003c-.2728.31172-.7466.34332-1.0583.07056-.3118-.27276-.3433-.74659-.0706-1.05832l.6616-.75614h-2.8617c-.4142 0-.75-.33579-.75-.75s.3358-.75.75-.75h2.8617z"/> */}
                {/* <path d="m6 15.75c0-.4142.33579-.75.75-.75h10.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-10.5c-.41421 0-.75-.3358-.75-.75z"/> */}
                {/* <path d="m5.75 11c-1.51878 0-2.75 1.2312-2.75 2.75v4c0 1.5188 1.23122 2.75 2.75 2.75h12.5c1.5188 0 2.75-1.2312 2.75-2.75v-4c0-1.5188-1.2312-2.75-2.75-2.75zm-1.25 2.75c0-.6904.55964-1.25 1.25-1.25h12.5c.6904 0 1.25.5596 1.25 1.25v4c0 .6904-.5596 1.25-1.25 1.25h-12.5c-.69036 0-1.25-.5596-1.25-1.25z"/> */}
            </g>
        </svg>
    );
};

export default AutofitIcon;