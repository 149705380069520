import { IconProps } from './index';

const AutomaticDurationIcon = ({ size, color }: IconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.378 8.378L23 12l-3.622 3.622M22.945 12h-10.89M4.622 15.622L1 12l3.622-3.622M1.055 12h10.89"
        />
    </svg>
);

export default AutomaticDurationIcon;
