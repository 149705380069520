import { createSelector } from 'reselect';

import { State } from 'state/modules';

import { StatusType } from 'interfaces/statuses';
import { Trailer } from 'interfaces/generation';

import { GenerationState } from './types';

export const getState = (state: State): GenerationState => state.generation;

export const getTrailersList = createSelector(
  getState,
  (state) => state.trailers,
);

export const getFilteredTrailersList = createSelector(
  getTrailersList,
  (trailers) => trailers.filter(
    (trailer: Trailer) => trailer.status.result !== StatusType.IN_PROGRESS,
  ),
);

export const getTrailersTotal = createSelector(
  getTrailersList,
  (trailers) => trailers.length,
);

export const getTrailersLoading = createSelector(
  getState,
  (state) => state.isTrailersLoading,
);

export const getGenerationLoading = createSelector(
  getState,
  (state) => state.isGenerationLoading,
);

export const getClipsRenderingLoading = createSelector(
  getState,
  (state) => state.isClipsRenderingLoading,
);

export const getPreviewsList = createSelector(
  getState,
  (state) => state.previews,
);

export const getPreviewsCount = createSelector(
  getPreviewsList,
  (previews) => previews.length,
);

export const getPreviewsLoading = createSelector(
  getState,
  (state) => state.isPreviewsLoading,
);
