import {
  memo, useEffect, useMemo, useState,
} from 'react';
import download from 'downloadjs';
import { useSelector } from 'react-redux';

import AvidExportFileSettingsItem from 'components/AvidExportFileSettingsItem';
import AvidExportVideoTimelineSettings from 'components/AvidExportVideoTimelineSettings';
import ListToExport from 'components/ListToExport';
import DropdownAutocomplete from 'components/DropdownAutocomplete';

import { ButtonLoader, Icon, Tooltip } from 'components/UI';

import { getModalOptionalData } from 'state/modules/modal';
import { getVideoInfo } from 'state/modules/media';

import {
  AvidExportData,
  AvidExportGroup,
  AvidExportListItem,
  AvidExportTransformedVideo,
  AvidExportTrack,
  AvidExportMergeIntervalsOption,
  AvidExportFilesSettingsOption,
  AvidExportFpsOption,
} from 'interfaces/avidExport';
import { ColorOption } from 'interfaces/colors';

import {
  avidExportFilesSettingsOptions,
  avidExportFpsOptions,
  avidExportMergeIntervalsOptions,
  transformAvidExportGroupedItemstoVideos,
  transformAvidExportItemsForDisplay,
  transformAvidExportItemstoGroups,
  avidExportColourOptions,
  transformAvidExportItemsToAvidMarkers,
} from 'utils/avidExportHelpers';

import { getVideosUsedForExport } from 'state/modules/export';
import styles from './styles.module.scss';

const windowFork = window;

interface Props {
  closeModal: () => void;
}

const AvidExportModal = (props: Props): JSX.Element => {
  const [fps, setFps] = useState<AvidExportFpsOption>(
    avidExportFpsOptions[0],
  );
  const [fpsError, setFpsError] = useState<string | null>(null);
  const [mergeInterval, seTmergeInterval] = useState<AvidExportMergeIntervalsOption>(
    avidExportMergeIntervalsOptions[0],
  );
  const [items, setItems] = useState<Array<AvidExportListItem>>([]);
  const [groups, setGroups] = useState<Array<AvidExportGroup>>([]);
  const [videos, setVideos] = useState<Array<AvidExportTransformedVideo>>([]);
  const [tracks, setTracks] = useState<Array<AvidExportTrack>>([]);
  const [loading, setLoading] = useState(false);
  const [filesSettings, setFilesSettings] = useState<AvidExportFilesSettingsOption>(
    avidExportFilesSettingsOptions[0],
  );

  const dataForExport = useSelector(getModalOptionalData) as AvidExportData;
  const videosFiles = useSelector(getVideosUsedForExport);
  const selectedVideoDetails = useSelector(getVideoInfo);

  const { closeModal } = props;

  const videosData = useMemo(() => {
    const files = [...videosFiles];

    if (selectedVideoDetails) {
      files.push(selectedVideoDetails);
    }

    return files;
  }, [videosFiles, selectedVideoDetails]);

  useEffect(() => {
    if (dataForExport) {
      const { selectedItems } = dataForExport;

      const transformedItems = transformAvidExportItemsForDisplay(
        selectedItems,
        avidExportColourOptions,
      ) as Array<AvidExportListItem>;

      setItems(transformedItems);

      const transformedGroups = transformAvidExportItemstoGroups(
        selectedItems,
      ) as Array<AvidExportGroup>;

      const transformedTracks = [] as Array<AvidExportTrack>;

      for (let i = 1; i < 64; i++) {
        transformedTracks[i - 1] = {
          label: `V${i}`,
          value: `V${i}`,
          id: `V${i}`,
        };
      }


      const transformedVideos = transformAvidExportGroupedItemstoVideos(
        dataForExport.groupedData,
        videosData,
      ) as Array<AvidExportTransformedVideo>;

      setVideos(transformedVideos);
      setGroups(transformedGroups);
      setTracks(transformedTracks);
      setFps(avidExportFpsOptions[0])
    }
  }, [dataForExport, selectedVideoDetails]);

  const handleSelectMergeInterval = (
    value: AvidExportMergeIntervalsOption,
  ) => {
    seTmergeInterval(value);
  };

  const handleChangeItemComment = (value: string, order: number) => {
    const changedItems = [...items] as Array<AvidExportListItem>;

    changedItems[order].comment = value;
    setItems(changedItems);
  };

  const handleChangeItemName = (value: string, order: number) => {
    const changedItems = [...items] as Array<AvidExportListItem>;

    changedItems[order].name = value;
    setItems(changedItems);
  };

  const handleChangeItemGroup = (value: AvidExportGroup, order: number) => {
    const changedItems = [...items] as Array<AvidExportListItem>;

    changedItems[order].group = value;
    setItems(changedItems);
  };

  const validate = () => {
    let hasErrors = false;

    if (!fps.value) {
      setFpsError('Required');
      hasErrors = true;
    }

    return hasErrors;
  };


  const handleGetAvidMarkers = async () => {
    const hasErrors = validate();

    if (!hasErrors) {
      setLoading(true);

      const objects = transformAvidExportItemsToAvidMarkers(
        items,
        videos,
        fps
      );

      const data = {
        fps: parseFloat(`${fps.value}`),
        exportName: 'avidData.zip',
        merge: {
          enabled: mergeInterval.value !== 'No',
          interval:
            mergeInterval.value === 'No'
              ? 1000
              : mergeInterval.value,
        },
        objects,
      };

      try {
        const response = await fetch(
          `${windowFork.config.REACT_APP_SHORT_API_URL}/avid`,
          {
            method: 'POST',
            body: JSON.stringify(data),
          },
        );

        const blob = await response.blob();
        download(blob, 'avid.zip');
        setLoading(false);

        if (blob.size) {
          props.closeModal();
        }
      } catch (error) {
        setLoading(false);
        console.log({ error });
      }
    }
  };

  const handleChangeItemColor = (value: ColorOption, order: number) => {
    const changedItems = [...items];

    changedItems[order].colour = value;

    setItems(changedItems);
  };

  const renderFileSettings = () => items.map((item: AvidExportListItem, index: number) => (
    <AvidExportFileSettingsItem
      key={item.type + item.name + item.videoId + index}
      item={item}
      groups={groups}
      changeItemComment={handleChangeItemComment}
      changeItemName={handleChangeItemName}
      changeItemColor={handleChangeItemColor}
      changeItemGroup={handleChangeItemGroup}
      colourOptions={avidExportColourOptions}
    />
  ));

  const handleChangeVideoTrack = (
    value: AvidExportTrack,
    videoId: string,
  ) => {
    const updatedVideos = videos.map((item: AvidExportTransformedVideo) => {
      if (item.videoId === videoId) {
        return {
          ...item,
          track: value,
        };
      }
      return item;
    });

    setVideos(updatedVideos);
  };

  const handleChangeVideoOffset = (value: string, videoId: string) => {
    const updatedVideos = videos.map((item: AvidExportTransformedVideo) => {
      if (item.videoId === videoId) {
        return {
          ...item,
          offset: value,
        };
      }
      return item;
    });
    setVideos(updatedVideos);
  };

  const handleSelectFps = (value: AvidExportFpsOption) => {
    setFps(value);
    setFpsError(null);
  };

  const handleChangeFpsValue = (value: AvidExportFpsOption) => {
    setFps(value);
    setFpsError(null);
  };

  const renderExportVideoTimelineSettings = () => videos.map((item: AvidExportTransformedVideo) => (
    <AvidExportVideoTimelineSettings
      key={item.videoId}
      item={item}
      tracks={tracks}
      changeVideoTrack={handleChangeVideoTrack}
      changeOffset={handleChangeVideoOffset}
    />
  ));

  const handleClose = () => {
    closeModal();
  };

  const renderSelectedItems = () => (dataForExport ? (
    <ListToExport
      videosData={videosData}
      dataForExport={dataForExport}
    />
  ) : null);

  const handleChangeFilesSettings = (
    value: AvidExportFilesSettingsOption,
  ) => {
    setFilesSettings(value);
  };

  return (
    <div className={styles.AvidExportModal}>
      <p className={styles.AvidExportModal__title}>Export to AVID</p>
      <p className={styles.AvidExportModal__subTitle}>Marker Settings</p>
      <p className={styles.AvidExportModal__description}>
        Select your settings first to get the AVID files exported
      </p>
      <div className={styles.AvidExportModal__content}>
        <div className={styles.AvidExportModal__leftSide}>
          <div className={styles.AvidExportModal__settingsRow}>
            <div
              className={
                styles.AvidExportModal__settingsInfoBlock
              }
            >
              <p
                className={
                  styles.AvidExportModal__settingsItemName
                }
              >
                File Frame Rate
              </p>
              <Tooltip
                text="The frame rate of your AVID project that you will be importing
                  to."
              />
            </div>
            <DropdownAutocomplete
              onSelect={handleSelectFps}
              hasInput
              options={avidExportFpsOptions}
              value={fps}
              white
              red={Boolean(fpsError)}
              onChangeValue={handleChangeFpsValue}
              rightLabel="Fps"
              error={fpsError}
              type="number"
              color="#01092c"
            />
          </div>
          <div className={styles.AvidExportModal__settingsRow}>
            <div
              className={
                styles.AvidExportModal__settingsInfoBlock
              }
            >
              <p
                className={
                  styles.AvidExportModal__settingsItemName
                }
              >
                Merge Marker Intervals
              </p>
              <Tooltip text="Merge instances that appear close to each other." />
            </div>
            <DropdownAutocomplete
              onSelect={handleSelectMergeInterval}
              hasInput={false}
              options={avidExportMergeIntervalsOptions}
              value={mergeInterval}
              white
              rightLabel="Sec"
              color="#01092c"
            />
          </div>
          <div className={styles.AvidExportModal__settingsRow}>
            {renderExportVideoTimelineSettings()}
          </div>
          <div className={styles.AvidExportModal__settingsRow}>
            <div
              className={
                styles.AvidExportModal__settingsInfoBlock
              }
            >
              <p
                className={
                  styles.AvidExportModal__settingsItemName
                }
              >
                File Settings
              </p>
              <Tooltip
                text="Number of files based on your label or video number. Select
                  custom for additional settings."
              />
            </div>
            <div>
              <DropdownAutocomplete
                onSelect={handleChangeFilesSettings}
                hasInput={false}
                options={avidExportFilesSettingsOptions}
                value={filesSettings}
                white
                color="#01092c"
              />
            </div>
          </div>
          {filesSettings.value === 'Custom' ? (
            <div className={styles.AvidExportModal__fileSettings}>
              <div
                className={[
                  styles.AvidExportModal__columnHeaders,
                ].join(' ')}
              >
                <p
                  className={
                    styles.AvidExportModal__columnHeader
                  }
                >
                  Colour
                </p>
                <p
                  className={
                    styles.AvidExportModal__columnHeader
                  }
                >
                  Name
                </p>
                <p
                  className={
                    styles.AvidExportModal__columnHeader
                  }
                >
                  Comment
                </p>
                <p
                  className={
                    styles.AvidExportModal__columnHeader
                  }
                >
                  File
                </p>
              </div>
              {renderFileSettings()}
            </div>
          ) : null}
        </div>
        <div className={styles.AvidExportModal__rightSide}>
          {renderSelectedItems()}
        </div>
      </div>
      <div className={styles.AvidExportModal__buttonsWrap}>
        <button
          onClick={handleClose}
          className={[
            styles.AvidExportModal__cancelButton,
            styles.AvidExportModal__actionButton,
          ].join(' ')}
        >
          Cancel
        </button>
        <button
          className={[
            styles.AvidExportModal__exportButton,
            styles.AvidExportModal__actionButton,
          ].join(' ')}
          onClick={handleGetAvidMarkers}
          disabled={loading}
        >
          {loading ? <ButtonLoader /> : 'Export'}
        </button>
      </div>
      <button
        className={styles.AvidExportModal__closeButton}
        onClick={handleClose}
      >
        <Icon name="close" size={12} color="#6979A5" />
      </button>
    </div>
  );
};

export default memo(AvidExportModal);
