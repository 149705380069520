import { put, select, takeLatest } from 'redux-saga/effects';

import {
    ModalActionTypes,
    ModalEvent,
    ModalType,
    ShowModalAction,
} from 'state/modules/modal';

import * as modalActions from 'state/modules/modal/actions';
import * as stockMediaActions from 'state/modules/stockMedia/actions';

import {
    // getPaymentPlans,
    // Product,
    CurrentSubscription,
    getCurrentUserPlan,
    // PlanNameId,
} from 'state/modules/payment';
import { getModalBackRoute, getModalType } from './selectors';
import { ModalRoute } from './types';

export function* handleShowModal(action: ShowModalAction): Generator {
    const currentModalType = yield select(getModalType);

    if (currentModalType === ModalType.STOCK_IMAGES) {
        yield put(stockMediaActions.setStockImagesSearchQuery(''));
        yield put(stockMediaActions.setStockImagesListPage(0));
    }

    if (currentModalType === ModalType.STOCK_VIDEO) {
        yield put(stockMediaActions.setStockVideosSearchQuery(''));
        yield put(stockMediaActions.setStockVideosListPage(0));
    }

    const { modalType, event, optionalText, optionalData } = action.payload;

    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    if (optionalText) {
        yield put(modalActions.setModalText(optionalText));
    }

    if (optionalData) {
        yield put(modalActions.setModalData(optionalData));
    }

    if (
        event === ModalEvent.UPLOAD ||
        event === ModalEvent.UPLOAD_FACES ||
        event === ModalEvent.PROJECT_UPLOAD
    ) {
        if (
            !currentPlan ||
            (currentPlan.status === 'canceled' &&
                currentPlan.stripeStatus === 'canceled')
        ) {
            yield put(
                modalActions.showModal(
                    ModalType.SELECT_PLAN,
                    ModalEvent.SELECT_PLAN
                )
            );
        }
    }

    // if (event === ModalEvent.NOT_ENOUGH_STORAGE) {
    //   const plans = (yield select(getPaymentPlans)) as Array<Product>;

    //   if (currentPlan.isTrial || currentPlan.planNameId === PlanNameId.INITIAL) {
    //     const proPlan = plans.find(
    //       (plan: Product) => plan?.planNameId === PlanNameId.PROFESSIONAL
    //     ) as Product;

    //     yield put(paymentActions.setSelectedPlan(proPlan));
    //   } else {
    //     yield put(
    //       modalActions.showModal(
    //         ModalType.MANAGE_STORAGE,
    //         ModalEvent.MANAGE_STORAGE,
    //       ),
    //     );
    //   }
    // }

    yield put(modalActions.setModalEvent(event));
    yield put(modalActions.setModalType(modalType));
    yield put(modalActions.setModalVisible(true));
}

export function* handleHideModal(): Generator {
    const modalType = yield select(getModalType);

    if (modalType === ModalType.STOCK_IMAGES) {
        yield put(stockMediaActions.setStockImagesSearchQuery(''));
        yield put(stockMediaActions.setStockImagesListPage(0));
    }

    if (modalType === ModalType.STOCK_VIDEO) {
        yield put(stockMediaActions.setStockVideosSearchQuery(''));
        yield put(stockMediaActions.setStockVideosListPage(0));
    }

    yield put(modalActions.setModalEvent(null));
    yield put(modalActions.setModalType(null));
    yield put(modalActions.setModalText(''));
    yield put(modalActions.setModalData(null));
    yield put(modalActions.setModalVisible(false));

    const modalBackRoute = (yield select(getModalBackRoute)) as ModalRoute;

    if (modalBackRoute) {
        yield put(
            modalActions.showModal(
                modalBackRoute.modalType,
                modalBackRoute.event
            )
        );

        yield put(modalActions.setModalBackRoute(null));
    }
}

export function* modalSaga(): Generator {
    yield takeLatest(ModalActionTypes.SHOW_MODAL, handleShowModal);
    yield takeLatest(ModalActionTypes.HIDE_MODAL, handleHideModal);
}
