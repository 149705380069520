import { useState } from 'react';

import ClipPreviewPlayer from 'components/ClipPreviewPlayer';

import {
    ClipGenerationDataClip,
    ClipGenerationDataClipTimestamp,
} from 'state/modules/generation';

import { MediaFile } from 'interfaces/videos';

import { Icon } from 'components/UI';
import Loader from 'react-loader-spinner';
import styles from './styles.module.scss';

interface Props {
    videoDetails: MediaFile;
    shots: Array<ClipGenerationDataClipTimestamp>;
    isMuted: boolean;
    shotsDuration: number;
    title?: string;
    isSelected?: boolean;
    onClick?: (clip: ClipGenerationDataClip, isSelected: boolean) => void;
    data: ClipGenerationDataClip;
    isTogglerVisible?: boolean;
}

const ClipPreview = (props: Props): JSX.Element => {
    const [isVideoFinished, setIsVideoFinished] = useState(false);
    const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(false);
    const [currentShotIndex, setCurrentShotIndex] = useState(0);

    const {
        videoDetails,
        shots,
        isMuted,
        shotsDuration,
        title,
        isSelected,
        onClick,
        data,
        isTogglerVisible,
    } = props;

    const lastShotIndex = shots.length - 1;

    const onShotFinish = (index: number) => {
        if (index === lastShotIndex) {
            setIsVideoFinished(true);
            setIsAutoplayEnabled(false);
        } else {
            const nextShot = currentShotIndex + 1;
            setCurrentShotIndex(nextShot);
            setIsAutoplayEnabled(true);
        }
    };

    const handleRestartVideo = () => {
        setCurrentShotIndex(0);

        setTimeout(() => {
            setIsAutoplayEnabled(true);
            setIsVideoFinished(false);
        }, 1000);
    };

    const handleOnClick = (
        clip: ClipGenerationDataClip,
        isSelected: boolean
    ) => {
        if (onClick) {
            onClick(clip, isSelected);
        }
    };

    const renderTitle = () =>
        shotsDuration ? (
            <p className={styles.ClipPreview__title}>
                {`${title || 'Clip preview'}${
                    shotsDuration ? ` (${shotsDuration / 1000}s)` : null
                }`}
            </p>
        ) : null;

    const renderPlayButton = () => (
        <div className={[styles.ClipPreview__playButton].join('')}>
            <Icon name="play" color="#ffffff" size={16} />
        </div>
    );

    const renderRestartOverlay = () =>
        currentShotIndex !== 0 && isVideoFinished ? (
            <div
                className={styles.ClipPreview__restartOverlay}
                onClick={handleRestartVideo}
            >
                {renderPlayButton()}
            </div>
        ) : null;

    const renderPlayer = (
        shot: ClipGenerationDataClipTimestamp,
        index: number
    ) => (
        <div key={shot.from} className={styles.ClipPreview__playerWrap}>
            <ClipPreviewPlayer
                videoDetails={videoDetails}
                shot={shot}
                isAutoplayEnabled={
                    isAutoplayEnabled && currentShotIndex === index
                }
                index={index}
                isMuted={isMuted}
                isSelected={isSelected || false}
                data={data}
                onClick={handleOnClick}
                onFinish={onShotFinish}
                isVideoFinished={isVideoFinished}
                isTogglerVisible={isTogglerVisible}
            />
        </div>
    );

    const renderWaitingLoader = () => (
        <div className={styles.ClipPreview__waitingLoader}>
            <p className={styles.ClipPreview__waitingLoaderTitle}>Waiting...</p>
            <Loader type="Oval" color="#ffffff" height={20} width={20} />
        </div>
    );

    return (
        <div className={styles.ClipPreview}>
            {renderTitle()}
            <div className={styles.ClipPreview__container}>
                {renderWaitingLoader()}
                {renderPlayer(shots[currentShotIndex], currentShotIndex)}
                {renderRestartOverlay()}
            </div>
        </div>
    );
};

export default ClipPreview;
