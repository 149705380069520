export const VIDEO_CATEGORIES = [
    'Summer',
    'Vacation',
    'Aerials',
    'Nature',
    'Art and Culture',
    'Backgrounds',
];

export const AUDIO_CATEGORIES = [
    'Summery',
    'Cinematic',
    'Ambient',
    'Happy',
    'Inspiring',
];

export const IMAGE_CATEGORIES = [
    'Summer',
    'Vacation',
    'Aerials',
    'Nature',
    'Art and Culture',
    'Backgrounds',
];

export const VIDEO_TAGS = {
    visible: [
        'Lifestyle',
        'Travel',
        'Animals'
    ],
    hidden: [
        'People',
        'Fashion',
        'Entertainment',
        'Education',
        'Technology',
        'Business'
    ],
};

export const AUDIO_TAGS = {
    visible: ['summer', 'cinematic', 'ambient'],
    hidden: ['commercial', 'celebrations', 'happy', 'inspiring', 'Sound effects']
};

export const IMAGE_TAGS = {
    visible: [
        'Lifestyle',
        'Travel',
        'Animals'
    ],
    hidden: [
        'People',
        'Fashion',
        'Entertainment',
        'Education',
        'Technology',
        'Business'
    ],
};

export const STOCK_TYPES = {
    VIDEO: 'video',
    AUDIO: 'audio',
    IMAGE: 'image',
}