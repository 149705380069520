import { IconProps } from './index';

const ChevronRightIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bcXHqe mRSTP BreadcrumbsStyled__SeparatorIcon-sc-37f2eg-5 hZZusd"
        >
            <path
                d="M10.2852 16.2863L14.5709 12.0006L10.2852 7.71484"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ChevronRightIcon;
