import {
    NewRawProject,
    Project,
    ProjectVideo,
    ProjectImage,
    ProjectAudioMediaSource,
    ProjectAudio,
    ProjectText,
    VideoTransition,
    ProjectSort,
    Element,
    ProjectElement,
    ProjectViewMode,
    VideoFilter,
    ProjectMediaAudioOptions,
    ProjectUpload,
    CleanAudioGenerationMediaStatuses,
} from 'interfaces/projects';
import { MediaFile, TempMediaFile } from 'interfaces/videos';
import {
    AddVideosFromLibraryToProjectActionPayload, ChangeAudioFadeAction, ChangeAudioFadeActionPayload,
    ChangeAudioLanguageAction,
    ChangeAudioLanguagePayload, ChangeAudioVolumeAction, ChangeAudioVolumeActionPayload,
    ChangeEnhancedAudioAction,
    ChangeEnhancedAudioActionPayload,
    ChangeTextAnimationAction,
    ChangeTextAnimationActionPayload,
    CreateProjectUploadAction,
    CreateProjectUploadActionPayload,
    DeleteProjectDubbingLanguageAction,
    DeleteProjectDubbingLanguagePayload,
    DeleteProjectTemplateAction,
    DeleteProjectUploadAction,
    DeleteProjectUploadActionPayload,
    DeleteTimelineSelectedItemsAction,
    FindProjectUploadByIdAction,
    FindProjectUploadByIdActionPayload,
    GenerateCleanAudioAction,
    GenerateCleanAudioActionPayload,
    GetMediaAudioTracksOptionsAction,
    GetMediaAudioTracksOptionsPayload,
    GetProjectLatestVersionEndAction,
    GetProjectLatestVersionStartAction,
    GetProjectUploadsAction,
    GetProjectUploadsActionPayload,
    GetPublishedTemplatesAction,
    GetPublishedTemplatesActionPayload,
    GetPublishedTemplatesFailAction,
    GetPublishedTemplatesStartAction,
    GetPublishedTemplatesSuccessAction,
    GetPublishedTemplatesSuccessActionPayload,
    GetSubmittedTemplatesAction,
    GetSubmittedTemplatesActionPayload,
    GetSubmittedTemplatesFailAction,
    GetSubmittedTemplatesStartAction,
    GetSubmittedTemplatesSuccessAction,
    GetSubmittedTemplatesSuccessActionPayload,
    GetUsableTemplatesAction,
    GetUsableTemplatesActionPayload,
    GetUsableTemplatesFailAction,
    GetUsableTemplatesStartAction, GetUsableTemplatesSuccessAction,
    GetUsableTemplatesSuccessActionPayload,
    ProjectsActionTypes,
    PublishTemplateAction, ReplaceTimelineMediaAction, ReplaceTimelineMediaActionPayload,
    ReviewTemplateAction,
    ReviewTemplateActionPayload,
    SetMediasWithCleanAudioGenerationAction,
    SetProjectAudioOptionsAction,
    SetProjectUploadsAction,
    SetPublishedTemplatesAction,
    SetSubmittedTemplatesAction,
    SetTemplateWorkspaceIdAction,
    SetUsableTemplatesAction,
    SetUsableTemplatesActionPayload,
    SubmitTemplatesAction,
    SyncPublishedTemplatesAction,
    SyncSubmittedTemplatesAction,
    UpdateProjectTemplateAction,
    UpdateProjectUploadAction,
    UpdateProjectUploadActionPayload, UseTemplateInProjectAction, UseTemplateInProjectActionPayload,
} from 'state/modules/projects';
import {
    AddTempMediaFilePreviewAction,
    AddTempMediaFilePreviewPayload,
    AddVideosFromLibraryToProjectAction,
    ClearProjectDetailsAction,
    CreateDefaultProjectAction,
    CreateDefaultProjectActionPayload,
    CreateProjectAction,
    CreateProjectElementAction,
    CreateProjectFailAction,
    CreateProjectFromUploadAction,
    CreateProjectFromUploadActionPayload,
    CreateProjectStartAction,
    CreateProjectSuccessAction,
    CreateProjectTempVideoPreviewsAction,
    CreateProjectTextAction,
    CreateProjectWithStockVideoAction,
    CreateProjectWithStockVideoActionPayload,
    DeleteProjectAction,
    DeleteProjectAudioAction,
    DeleteProjectElementAction,
    DeleteProjectFailAction,
    DeleteProjectImageAction,
    DeleteProjectStartAction,
    DeleteProjectSuccessAction,
    DeleteProjectTextAction,
    DeleteProjectVideoAction,
    DetouchAudioFromVideoAction,
    DetouchAudioFromVideoFailAction,
    DetouchAudioFromVideoStartAction,
    DetouchAudioFromVideoSuccessAction,
    DuplicateProjectAction,
    GenerateProjectAction,
    GenerateProjectActionPayload,
    GenerateProjectFailAction,
    GenerateProjectStartAction,
    GenerateProjectSuccessAction,
    GenerateTextToSpeechAction,
    GenerateTextToSpeechActionPayload,
    GenerateTextToSpeechFailAction,
    GenerateTextToSpeechStartAction,
    GenerateTextToSpeechSuccessAction,
    GetElementsListAction,
    GetElementsListFailAction,
    GetElementsListStartAction,
    GetProjectDetailsAction,
    GetProjectDetailsFailAction,
    GetProjectDetailsStartAction,
    GetProjectDetailsSuccessAction,
    GetProjectsAction,
    GetProjectsActionPayload,
    GetProjectsFailAction,
    GetProjectsStartAction,
    GetProjectsSuccessAction,
    GetProjectsSuccessActionPayload,
    GoByProjectHistoryAction,
    ResetProjectTextSettingsAction,
    SetElementsListAction,
    SetProjectAudioMediaSourceAction,
    SetProjectAudiosAction,
    SetProjectDetailsAction,
    SetProjectLastGenerationAction,
    SetProjectMediaSourcesAction,
    SetProjectsAction,
    SetProjectsIdsForDeleteAction,
    SetProjectSortAction,
    SetProjectsViewModeAction,
    SetProjectTempMediaSourcesAction,
    SetProjectTempMediaSourcesPreviewsAction,
    SetProjectUpdatesHistoryAction,
    SetProjectVideosAction,
    SetProjectVideosFiltersAction,
    SetProjectVideosTransitionsAction,
    SyncProjectDetailsAction,
    SyncProjectMediaSourcesAction,
    SyncProjectsAction,
    UpdateProjectAction,
    UpdateProjectActionPayload,
    UpdateProjectAudioAction,
    UpdateProjectAudiosAction,
    UpdateProjectElementAction,
    UpdateProjectFailAction,
    UpdateProjectImageAction,
    UpdateProjectPreferencesAction,
    UpdateProjectPreferencesActionPayload,
    UpdateProjectStartAction,
    UpdateProjectSuccessAction,
    UpdateProjectTextAction,
    UpdateProjectVideoAction,
    UpdateProjectVideoFilterAction,
    UpdateProjectVideosAction,
    UpdateProjectVideoTransitionAction,
    SetProjectAudiosIdsWithDubbingGenerationAction,
    GenerateProjectAudioDubbingAction,
    GenerateProjectAudioDubbingActionPayload,
} from './types';

export function getProjects(
    payload?: GetProjectsActionPayload
): GetProjectsAction {
    return {
        type: ProjectsActionTypes.GET_PROJECTS,
        payload,
    };
}

export function getProjectsStart(): GetProjectsStartAction {
    return {
        type: ProjectsActionTypes.GET_PROJECTS_START,
    };
}

export function getProjectsFail(): GetProjectsFailAction {
    return {
        type: ProjectsActionTypes.GET_PROJECTS_FAIL,
    };
}

export function getProjectsSuccess(
    payload: GetProjectsSuccessActionPayload
): GetProjectsSuccessAction {
    return {
        type: ProjectsActionTypes.GET_PROJECTS_SUCCESS,
        payload,
    };
}

export function setProjects(payload: Project[]): SetProjectsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECTS,
        payload,
    };
}

export function syncProjectsList(): SyncProjectsAction {
    return {
        type: ProjectsActionTypes.SYNC_PROJECTS,
    };
}

/// /////////////////////

export function getProjectDetails(payload: string): GetProjectDetailsAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_DETAILS,
        payload,
    };
}

export function getProjectDetailsStart(): GetProjectDetailsStartAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_DETAILS_START,
    };
}

export function getProjectLatestVersionStart(): GetProjectLatestVersionStartAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_START,
    }
}

export function getProjectLatestVersionEnd(): GetProjectLatestVersionEndAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_LATEST_VERSION_END,
    }
}

export function getProjectDetailsFail(): GetProjectDetailsFailAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_DETAILS_FAIL,
    };
}

export function getProjectDetailsSuccess(
    payload: Project
): GetProjectDetailsSuccessAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_DETAILS_SUCCESS,
        payload,
    };
}

export function setProjectDetail(payload: Project): SetProjectDetailsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_DETAILS,
        payload,
    };
}

export function clearProjectDetails(): ClearProjectDetailsAction {
    return {
        type: ProjectsActionTypes.CLEAR_PROJECT_DETAILS,
    };
}

export function syncProjectDetails(): SyncProjectDetailsAction {
    return {
        type: ProjectsActionTypes.SYNC_PROJECT_DETAILS,
    };
}

/// Project Media Sources ////////////////////////

export function setProjectMediaSources(
    payload: MediaFile[]
): SetProjectMediaSourcesAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_MEDIA_SOURCES,
        payload,
    };
}

export function setProjectTempMediaSources(
    payload: TempMediaFile[]
): SetProjectTempMediaSourcesAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES,
        payload,
    };
}

/// ////////////////////////

export function setProjectVideos(
    payload: ProjectVideo[]
): SetProjectVideosAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_VIDEOS,
        payload,
    };
}
/// ////////////////////////

export function syncProjectMediaSources(): SyncProjectMediaSourcesAction {
    return {
        type: ProjectsActionTypes.SYNC_PROJECT_MEDIA_SOURCES,
    };
}

/// ////////////////////////

export function createProject(payload: NewRawProject): CreateProjectAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT,
        payload,
    };
}

export function createProjectStart(): CreateProjectStartAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_START,
    };
}

export function createProjectSuccess(): CreateProjectSuccessAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_SUCCESS,
    };
}

export function createProjectFail(): CreateProjectFailAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_FAIL,
    };
}

/// /////////////////////////////

export function deleteProject(payload: string): DeleteProjectAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT,
        payload,
    };
}

export function deleteProjectStart(): DeleteProjectStartAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_START,
    };
}

export function deleteProjectSuccess(): DeleteProjectSuccessAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS,
    };
}

export function deleteProjectFail(): DeleteProjectFailAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_FAIL,
    };
}

export function setProjectsIdsForDelete(
    payload: string[]
): SetProjectsIdsForDeleteAction {
    return {
        type: ProjectsActionTypes.SET_PROJECTS_IDS_FOR_DELETE,
        payload,
    };
}

/// ////////////////////////////

export function updateProject(
    payload: UpdateProjectActionPayload
): UpdateProjectAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT,
        payload,
    };
}

export function updateProjectStart(): UpdateProjectStartAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_START,
    };
}

export function updateProjectSuccess(): UpdateProjectSuccessAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_SUCCESS,
    };
}

export function updateProjectFail(): UpdateProjectFailAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_FAIL,
    };
}

/// generate

export function generateProject(
    payload: GenerateProjectActionPayload
): GenerateProjectAction {
    return {
        type: ProjectsActionTypes.GENERATE_PROJECT,
        payload,
    };
}

export function generateProjectStart(): GenerateProjectStartAction {
    return {
        type: ProjectsActionTypes.GENERATE_PROJECT_START,
    };
}

export function generateProjectFail(): GenerateProjectFailAction {
    return {
        type: ProjectsActionTypes.GENERATE_PROJECT_FAIL,
    };
}

export function generateProjectSuccess(): GenerateProjectSuccessAction {
    return {
        type: ProjectsActionTypes.GENERATE_PROJECT_SUCCESS,
    };
}

/// ////////////////////////////

export function deleteProjectVideo(
    payload: string[]
): DeleteProjectVideoAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_VIDEO,
        payload,
    };
}

// Create Project from upload

export function createProjectFromUpload(
    payload: CreateProjectFromUploadActionPayload
): CreateProjectFromUploadAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_FROM_UPLOAD,
        payload,
    };
}

// //////////

export function createDefaultProject(
    payload: CreateDefaultProjectActionPayload
): CreateDefaultProjectAction {
    return {
        type: ProjectsActionTypes.CREATE_DEFAULT_PROJECT,
        payload,
    };
}

// ///////////

export function createProjectTempVideoPreviews(): CreateProjectTempVideoPreviewsAction {
    // payload: CreateProjectTempVideoPreviewsActionPayload
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_TEMP_VIDEO_PREVIEWS,
        // payload
    };
}
// ///////////

export function setProjectTempMediaSourcesPreviews(
    payload: any[]
): SetProjectTempMediaSourcesPreviewsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_TEMP_MEDIA_SOURCES_PREVIEWS,
        payload,
    };
}
// ///////////

export function addVideosFromLibraryToProject(
    payload: AddVideosFromLibraryToProjectActionPayload
): AddVideosFromLibraryToProjectAction {
    return {
        type: ProjectsActionTypes.ADD_VIDEOS_FROM_LIBRARY_TO_PROJECT,
        payload,
    };
}

export function addTempMediaFilePreview(
    payload: AddTempMediaFilePreviewPayload
): AddTempMediaFilePreviewAction {
    return {
        type: ProjectsActionTypes.ADD_PROJECT_TEMP_MEDIA_FILE_PREVIEW,
        payload,
    };
}

export function goByProjectHistory(
    payload: 'back' | 'forward'
): GoByProjectHistoryAction {
    return {
        type: ProjectsActionTypes.GO_BY_PROJECT_HISTORY,
        payload,
    };
}

export function setProjectUpdatesHistory(
    payload: Project[]
): SetProjectUpdatesHistoryAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_UPDATEDS_HISTORY,
        payload,
    };
}

export function updateProjectImage(
    payload: ProjectImage
): UpdateProjectImageAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_IMAGE,
        payload,
    };
}

export function deleteProjectImage(
    payload: string[]
): DeleteProjectImageAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_IMAGE,
        payload,
    };
}

//

export function setProjectAudioMediaSources(
    payload: ProjectAudioMediaSource[]
): SetProjectAudioMediaSourceAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_AUDIO_MEDIA_SOURCES,
        payload,
    };
}

//

export function detachAudioFromVideo(
    payload: ProjectVideo
): DetouchAudioFromVideoAction {
    return {
        type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO,
        payload,
    };
}

export function detachAudioFromVideoStart(): DetouchAudioFromVideoStartAction {
    return {
        type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_START,
    };
}

export function detachAudioFromVideoSuccess(): DetouchAudioFromVideoSuccessAction {
    return {
        type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_SUCCESS,
    };
}

export function detachAudioFromVideoFail(): DetouchAudioFromVideoFailAction {
    return {
        type: ProjectsActionTypes.DETACH_AUDIO_FROM_PROJECT_VIDEO_FAIL,
    };
}

export function setProjectAudios(
    payload: ProjectAudio[]
): SetProjectAudiosAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_AUDIOS,
        payload,
    };
}

export function updateProjectAudio(
    payload: ProjectAudio
): UpdateProjectAudioAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_AUDIO,
        payload,
    };
}

export function updateProjectAudios(
    payload: ProjectAudio[]
): UpdateProjectAudiosAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_AUDIOS,
        payload,
    };
}

export function deleteProjectAudio(
    payload: string[]
): DeleteProjectAudioAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_AUDIO,
        payload,
    };
}

//

export function createProjectText(
    payload: ProjectText
): CreateProjectTextAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_TEXT,
        payload,
    };
}

export function deleteProjectText(payload: string[]): DeleteProjectTextAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_TEXT,
        payload,
    };
}

export function updateProjectText(
    payload: ProjectText
): UpdateProjectTextAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_TEXT,
        payload,
    };
}

export function setProjectVideosTransitions(
    payload: VideoTransition[]
): SetProjectVideosTransitionsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_VIDEOS_TRANSITIONS,
        payload,
    };
}

export function setProjectVideosFilters(
    payload: VideoFilter[]
): SetProjectVideosFiltersAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_VIDEOS_FILTERS,
        payload,
    };
}

export function updateProjectVideoTransition(
    payload: VideoTransition
): UpdateProjectVideoTransitionAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO_TRANSITION,
        payload,
    };
}

export function updateProjectVideoFilter(
    payload: VideoFilter
): UpdateProjectVideoFilterAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO_FILTER,
        payload,
    };
}

export function updateProjectVideo(
    payload: ProjectVideo
): UpdateProjectVideoAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_VIDEO,
        payload,
    };
}

export function updateProjectVideos(
    payload: ProjectVideo[]
): UpdateProjectVideosAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_VIDEOS,
        payload,
    };
}

export function updateProjectPreferences(
    payload: UpdateProjectPreferencesActionPayload
): UpdateProjectPreferencesAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_PREFERENCES,
        payload,
    };
}

export function resetProjectTextSettings(): ResetProjectTextSettingsAction {
    return {
        type: ProjectsActionTypes.RESET_PROJECT_TEXT_SETTINGS,
    };
}

export function setProjectSort(payload: ProjectSort): SetProjectSortAction {
    return {
        type: ProjectsActionTypes.SET_PROJECTS_SORTING,
        payload,
    };
}

export function getElementsList(): GetElementsListAction {
    return {
        type: ProjectsActionTypes.GET_ELEMENTS_LIST,
    };
}

export function getElementsListStart(): GetElementsListStartAction {
    return {
        type: ProjectsActionTypes.GET_ELEMENTS_LIST_START,
    };
}

export function getElementsListFail(): GetElementsListFailAction {
    return {
        type: ProjectsActionTypes.GET_ELEMENTS_LIST_FAIL,
    };
}

export function setElementsList(payload: Element[]): SetElementsListAction {
    return {
        type: ProjectsActionTypes.SET_ELEMENTS_LIST,
        payload,
    };
}

export function createProjectElement(
    payload: Element
): CreateProjectElementAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_ELEMENT,
        payload,
    };
}

export function updateProjectElement(
    payload: ProjectElement
): UpdateProjectElementAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_ELEMENT,
        payload,
    };
}

export function deleteProjectElement(
    payload: string[]
): DeleteProjectElementAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_ELEMENT,
        payload,
    };
}

export function setProjectsViewMode(
    payload: ProjectViewMode
): SetProjectsViewModeAction {
    return {
        type: ProjectsActionTypes.SET_VIEW_MODE,
        payload,
    };
}

// Last Project generation

export function setProjectLastGeneration(
    payload: Project | null
): SetProjectLastGenerationAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_LAST_GENERATION,
        payload,
    };
}
// Last Project generation

export function generateTextToSpeech(
    payload: GenerateTextToSpeechActionPayload
): GenerateTextToSpeechAction {
    return {
        type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH,
        payload,
    };
}
export function generateTextToSpeechStart(): GenerateTextToSpeechStartAction {
    return {
        type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_START,
    };
}
export function generateTextToSpeechFail(
    payload?: any
): GenerateTextToSpeechFailAction {
    return {
        type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_FAIL,
        payload,
    };
}

export function generateTextToSpeechSuccess(): GenerateTextToSpeechSuccessAction {
    return {
        type: ProjectsActionTypes.GENERATE_TEXT_TO_SPEECH_SUCCESS,
    };
}

// Duplicate
export function duplicateProject(payload: Project): DuplicateProjectAction {
    return {
        type: ProjectsActionTypes.DUPLICATE_PROJECT,
        payload,
    };
}

// Create
export function createProjectWithStockVideo(
    payload: CreateProjectWithStockVideoActionPayload
): CreateProjectWithStockVideoAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_WITH_STOCK_VIDEO,
        payload,
    };
}


// Audio Dubbing
export function getMediaAudioTracksOptions(
    payload: GetMediaAudioTracksOptionsPayload
): GetMediaAudioTracksOptionsAction {
    return {
        type: ProjectsActionTypes.GET_MEDIA_AUDIO_OPTIONS,
        payload,
    };
}

export function setProjectAudioOptions(
    payload: ProjectMediaAudioOptions
): SetProjectAudioOptionsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_AUDIO_OPTIONS,
        payload,
    };
}

export function deleteProjectDubbingLanguage(
    payload: DeleteProjectDubbingLanguagePayload
): DeleteProjectDubbingLanguageAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_DUBBING_LANGUAGE,
        payload,
    };
}

export function changeAudioLanguage(
    payload: ChangeAudioLanguagePayload
): ChangeAudioLanguageAction {
    return {
        type: ProjectsActionTypes.CHANGE_AUDIO_LANGUAGE,
        payload,
    };
}

// Uploads

export function getProjectUploads(
    payload?: GetProjectUploadsActionPayload
): GetProjectUploadsAction {
    return {
        type: ProjectsActionTypes.GET_PROJECT_UPLOADS,
        payload,
    };
}

export function setProjectUploads(
    payload: ProjectUpload[]
): SetProjectUploadsAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_UPLOADS,
        payload,
    };
}

export function createProjectUpload(
    payload: CreateProjectUploadActionPayload
): CreateProjectUploadAction {
    return {
        type: ProjectsActionTypes.CREATE_PROJECT_UPLOAD,
        payload,
    };
}

export function findProjectUploadById(
    payload: FindProjectUploadByIdActionPayload
): FindProjectUploadByIdAction {
    return {
        type: ProjectsActionTypes.FIND_PROJECT_UPLOAD_BY_ID,
        payload,
    };
}

export function updateProjectUpload(
    payload: UpdateProjectUploadActionPayload
): UpdateProjectUploadAction {
    return {
        type: ProjectsActionTypes.UPDATE_PROJECT_UPLOAD,
        payload,
    };
}

export function deleteProjectUpload(
    payload: DeleteProjectUploadActionPayload
): DeleteProjectUploadAction {
    return {
        type: ProjectsActionTypes.DELETE_PROJECT_UPLOAD,
        payload,
    };
}

export function setMediasWithCleanAudioGeneration(
    payload: CleanAudioGenerationMediaStatuses[],
): SetMediasWithCleanAudioGenerationAction {
    return {
        type: ProjectsActionTypes.SET_MEDIAS_WITH_CLEAN_AUDIO_GENERATION,
        payload,
    };
}

export function generateCleanAudio(
    payload: GenerateCleanAudioActionPayload
): GenerateCleanAudioAction {
    return {
        type: ProjectsActionTypes.GENERATE_CLEAN_AUDIO,
        payload,
    };
}

export function enhancedAudioSwitch(
    payload: ChangeEnhancedAudioActionPayload
): ChangeEnhancedAudioAction {
    return {
        type: ProjectsActionTypes.CHANGE_ENHANCED_AUDIO,
        payload,
    };
}

// templates

export function setTemplateWorkspaceId(
    payload: string | null,
): SetTemplateWorkspaceIdAction {
    return {
        type: ProjectsActionTypes.SET_TEMPLATE_WORKSPACE_ID,
        payload,
    };
}

export function submitTemplate(
    payload?: Project,
): SubmitTemplatesAction {
    return {
        type: ProjectsActionTypes.SUBMIT_TEMPLATE,
        payload,
    };
}

export function reviewTemplate(
    payload: ReviewTemplateActionPayload,
): ReviewTemplateAction {
    return {
        type: ProjectsActionTypes.REVIEW_TEMPLATE,
        payload,
    };
}

export function publishTemplate(
    payload?: Project,
): PublishTemplateAction {
    return {
        type: ProjectsActionTypes.PUBLISH_TEMPLATE,
        payload,
    };
}

export function deleteProjectTemplate(
    templateId?: string,
): DeleteProjectTemplateAction {
    return {
        type: ProjectsActionTypes.DELETE_TEMPLATE,
        templateId,
    };
}

export function updateProjectTemplate(
    payload?: Project,
): UpdateProjectTemplateAction {
    return {
        type: ProjectsActionTypes.UPDATE_TEMPLATE,
        payload,
    };
}

export function getSubmittedTemplates(
    payload?: GetSubmittedTemplatesActionPayload
): GetSubmittedTemplatesAction {
    return {
        type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES,
        payload,
    };
}

export function getSubmittedTemplatesStart(): GetSubmittedTemplatesStartAction {
    return {
        type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_START,
    };
}

export function getSubmittedTemplatesFail(): GetSubmittedTemplatesFailAction {
    return {
        type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_FAIL,
    };
}

export function getSubmittedTemplatesSuccess(
    payload: GetSubmittedTemplatesSuccessActionPayload
): GetSubmittedTemplatesSuccessAction {
    return {
        type: ProjectsActionTypes.GET_SUBMITTED_TEMPLATES_SUCCESS,
        payload,
    };
}

export function setSubmittedTemplates(
    payload: Project[]
): SetSubmittedTemplatesAction {
    return {
        type: ProjectsActionTypes.SET_SUBMITTED_TEMPLATES,
        payload,
    };
}

export function syncSubmittedTemplatesList(): SyncSubmittedTemplatesAction {
    return {
        type: ProjectsActionTypes.SYNC_SUBMITTED_TEMPLATES,
    };
}

export function getPublishedTemplates(
    payload?: GetPublishedTemplatesActionPayload
): GetPublishedTemplatesAction {
    return {
        type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES,
        payload,
    };
}

export function getPublishedTemplatesStart(): GetPublishedTemplatesStartAction {
    return {
        type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_START,
    };
}

export function getPublishedTemplatesFail(): GetPublishedTemplatesFailAction {
    return {
        type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_FAIL,
    };
}

export function getPublishedTemplatesSuccess(
    payload: GetPublishedTemplatesSuccessActionPayload
): GetPublishedTemplatesSuccessAction {
    return {
        type: ProjectsActionTypes.GET_PUBLISHED_TEMPLATES_SUCCESS,
        payload,
    };
}

export function setPublishedTemplates(
    payload: Project[]
): SetPublishedTemplatesAction {
    return {
        type: ProjectsActionTypes.SET_PUBLISHED_TEMPLATES,
        payload,
    };
}

export function syncPublishedList(): SyncPublishedTemplatesAction {
    return {
        type: ProjectsActionTypes.SYNC_PUBLISHED_TEMPLATES,
    };
}

export function getUsableTemplates(
    payload: GetUsableTemplatesActionPayload
): GetUsableTemplatesAction {
    return {
        type: ProjectsActionTypes.GET_USABLE_TEMPLATES,
        payload,
    }
}

export function getUsableTemplatesStart(): GetUsableTemplatesStartAction {
    return {
        type: ProjectsActionTypes.GET_USABLE_TEMPLATES_START,
    };
}

export function getUsableTemplatesFail(): GetUsableTemplatesFailAction {
    return {
        type: ProjectsActionTypes.GET_USABLE_TEMPLATES_FAIL,
    };
}

export function getUsableTemplatesSuccess(
    payload: GetUsableTemplatesSuccessActionPayload
): GetUsableTemplatesSuccessAction {
    return {
        type: ProjectsActionTypes.GET_USABLE_TEMPLATES_SUCCESS,
        payload,
    };
}

export function setUsableTemplates(
    payload: SetUsableTemplatesActionPayload
): SetUsableTemplatesAction {
    return {
        type: ProjectsActionTypes.SET_USABLE_TEMPLATES,
        payload,
    }
}


export function replaceProjectWithTemplate(
    payload: UseTemplateInProjectActionPayload
): UseTemplateInProjectAction {
    return {
        type: ProjectsActionTypes.USE_TEMPLATE,
        payload,
    }
}


// animations

export function changeTextAnimation(
    payload: ChangeTextAnimationActionPayload
): ChangeTextAnimationAction {
    return {
        type: ProjectsActionTypes.CHANGE_TEXT_ANIMATION,
        payload,
    };
}

export function replaceTimelineMedia(
    payload: ReplaceTimelineMediaActionPayload
): ReplaceTimelineMediaAction {
    return {
        type: ProjectsActionTypes.REPLACE_TIMELINE_MEDIA,
        payload,
    };
}

export function setProjectAudiosIdsWithDubbingGeneration(
    payload: string[]
): SetProjectAudiosIdsWithDubbingGenerationAction {
    return {
        type: ProjectsActionTypes.SET_PROJECT_AUDIOS_IDS_WITH_DUBBING_GENERATION,
        payload,
    };
}

export function generateProjectAudioDubbing(
    payload: GenerateProjectAudioDubbingActionPayload
): GenerateProjectAudioDubbingAction {
    return {
        type: ProjectsActionTypes.GENERATE_PROJECT_AUDIO_DUBBING,
        payload,
    };
}

export function changeAudioFade(
    payload: ChangeAudioFadeActionPayload
): ChangeAudioFadeAction {
    return {
        type: ProjectsActionTypes.CHANGE_AUDIO_FADE,
        payload,
    };
}

export function changeMediaVolume(
    payload: ChangeAudioVolumeActionPayload
): ChangeAudioVolumeAction {
    return {
        type: ProjectsActionTypes.CHANGE_AUDIO_VOLUME,
        payload,
    };
}

export function handleDeleteTimelineSelectedItems(
): DeleteTimelineSelectedItemsAction {
    return {
        type: ProjectsActionTypes.DELETE_SELECTED_ITEMS,
    };
}