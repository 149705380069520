export const TEMPLATES_TITLES = {
  CUSTOM: 'Custom',
  DR: 'DR TV',
};

export enum TAB {
    VIDEOS = 'videos',
    AUDIO = 'audio',
    TRAILERS = 'trailers',
    COLLECTIONS = 'collections',
    TEMPLATES = 'templates',
    PROJECTS = 'projects'
}

export const ANALYSIS_CATEGORIES_TITLES = {
  labels: 'Objects',
  celebs: 'Celebrities',
  faces: 'Facial Attributes',
  faceMatches: 'Collections',
  transcript: 'Transcript',
  shots: 'Shots',
  moderatedLabels: 'Moderated Labels',
};
