import { NewButton } from 'components/UI';
import { useDispatch } from 'react-redux';

import { hideModal } from 'state/modules/modal';
import { cancelUserSubscription } from 'state/modules/payment';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const AutoRenewalConfirmModal = (props: Props): JSX.Element => {
  const { closeButton } = props;

  const dispatch = useDispatch();

  const close = () => {
    dispatch(hideModal());
  };

  const handleConfirm = () => {
    dispatch(cancelUserSubscription());
    dispatch(hideModal());
  };

  return (
    <div className={styles.AutoRenewalConfirmModal}>
      <div className={styles.AutoRenewalConfirmModal__container}>
        <div className={styles.AutoRenewalConfirmModal__content}>
          <p className={styles.AutoRenewalConfirmModal__title}>
            Are you sure?
          </p>

          <div
            className={styles.AutoRenewalConfirmModal__buttonsWrap}
          >
            <NewButton
              color={Colors.TURQUOISE}
              textColor={Colors.WHITE}
              bordered
              borderRadius={26}
              width={232}
              height={46}
              onClick={close}
            >
              Keep
            </NewButton>
            <NewButton
              color={Colors.PINK}
              textColor={Colors.WHITE}
              bordered
              borderRadius={26}
              width={232}
              height={46}
              onClick={handleConfirm}
            >
              Cancel
            </NewButton>
          </div>
        </div>
      </div>
      {closeButton}
    </div>
  );
};

export default AutoRenewalConfirmModal;
