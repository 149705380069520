import {
  ClipTemplate,
  // ClipTemplateTitle,
  Trailer,
  TransformedGenTemplate,
} from 'interfaces/generation';
// import { MediaFile } from 'interfaces/videos';
import { Scene } from 'state/modules/videoExplorer';

export interface GenerationState {
    trailers: Array<Trailer>;
    isTrailersLoading: boolean;
    isGenerationLoading: boolean;
    isClipsRenderingLoading: boolean;
    previews: Array<ClipGenerationData>;
    isPreviewsLoading: boolean;
}

export interface ClipSettings {
    numberOfClips: number;
    minSceneLength: number;
    maxSceneLength: number;
    minClipLength: number;
    maxClipLength: number;
    confidence: number;
    boundingBox: number;
    order: ClipOrder;
    isMuted: boolean;
    diffFactor: number;
}

export enum ClipOrder {
    RANDOM = 'RANDOM',
    LOGICAL = 'LOGICAL',
}

export interface ClipGenRawData {
    selectedTemplate: TransformedGenTemplate;
    selectedTimestamps: Array<Scene>;
    minSceneLength: number;
    maxSceneLength: number;
    minClipLength: number;
    maxClipLength: number;
    // videoDetails: MediaFile;
    videoId: string;
    numberOfClips: number;
    order: ClipOrder;
    minConfidence: number;
    minScaleFactor: number;
    isMuted: boolean;
    diffFactor: number;
    startTimeInterval: number;
    endTimeInterval: number;
}

export interface ClipCustomGenRawData extends ClipGenRawData {
    isCustomWithLimit: boolean;
}

export interface ClipTemplateGenData {
    videoId: string;
    template: ClipTemplate;
    clipData: {
        numberOfClips: number;
        minSceneLength: number;
        maxSceneLength: number;
        minClipLength: number;
        maxClipLength: number;
        confidence: number;
        boundingBox: number;
        order: ClipOrder;
        isMuted: boolean;
        diffFactor: number;
        startTimeInterval?: number;
        endTimeInterval?: number;
    };
}

export interface ClipCustomGenData {
    videoId: string;
    template: ClipTemplate;
    customClipData: {
        clipShots: Array<ClipShot>;
        minSceneLength: number;
        maxSceneLength: number;
        minClipLength: number;
        maxClipLength: number;
        isMuted: boolean;
        order: ClipOrder;
        minConfidence: number;
        minScaleFactor: number;
        numberOfClips: number;
    };
}

export interface ClipShot {
    from: number;
    to: number;
    metadata: Scene;
}

export enum GenerationActionTypes {
    GET_TRAILERS = '@@generation/GET_TRAILERS',
    GET_TRAILERS_START = '@@generation/GET_TRAILERS_START',
    GET_TRAILERS_FAIL = '@@generation/GET_TRAILERS_FAIL',
    GET_TRAILERS_SUCCESS = '@@generation/GET_TRAILERS_SUCCESS',
    GENERATE_CLIP_PREVIEWS = '@@generation/GENERATE_CLIP_PREVIEWS',
    GENERATE_CLIP_PREVIEWS_START = '@@generation/GENERATE_CLIP_PREVIEWS_START',
    GENERATE_CLIP_PREVIEWS_SUCCESS = '@@generation/GENERATE_CLIP_PREVIEWS_SUCCESS',
    GENERATE_CLIP_PREVIEWS_FAIL = '@@generation/GENERATE_CLIP_PREVIEWS_FAIL',
    GET_PREVIEWS = '@@generation/GET_PREVIEWS',
    GET_PREVIEWS_START = '@@generation/GET_PREVIEWS_START',
    GET_PREVIEWS_FAIL = '@@generation/GET_PREVIEWS_FAIL',
    GET_PREVIEWS_SUCCESS = '@@generation/GET_PREVIEWS_SUCCESS',
    GENERATE_CLIPS = '@@generation/GENERATE_CLIPS',
    GENERATE_CLIPS_START = '@@generation/GENERATE_CLIPS_START',
    GENERATE_CLIPS_FAIL = '@@generation/GENERATE_CLIPS_FAIL',
    GENERATE_CLIPS_SUCCESS = '@@generation/GENERATE_CLIPS_SUCCESS',
}

export interface GetTrailersAction {
    type: GenerationActionTypes.GET_TRAILERS;
    payload: string;
}

export interface GetTrailersSuccessAction {
    type: GenerationActionTypes.GET_TRAILERS_SUCCESS;
    payload: {
        trailers: Array<Trailer>;
    };
}
export interface GetTrailersStartAction {
    type: GenerationActionTypes.GET_TRAILERS_START;
}

export interface GetTrailersFailAction {
    type: GenerationActionTypes.GET_TRAILERS_FAIL;
    payload: any;
}
export interface GenerateClipPreviewsAction {
    type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS;
    payload: ClipGenRawData;
}
export interface GenerateClipPreviewsStartAction {
    type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_START;
}
export interface GenerateClipPreviewsSuccessAction {
    type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_SUCCESS;
}
export interface GenerateClipPreviewsFailAction {
    type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_FAIL;
    payload: any;
}
export interface GetPreviewsAction {
    type: GenerationActionTypes.GET_PREVIEWS;
    payload: string;
}
export interface GetPreviewsStartAction {
    type: GenerationActionTypes.GET_PREVIEWS_START;
}
export interface GetPreviewsSuccessAction {
    type: GenerationActionTypes.GET_PREVIEWS_SUCCESS;
    payload: {
        previews: Array<ClipGenerationData>;
    };
}
export interface GetPreviewsFailAction {
    type: GenerationActionTypes.GET_PREVIEWS_FAIL;
    payload: any;
}

export interface GenerateClipsAction {
    type: GenerationActionTypes.GENERATE_CLIPS;
    payload: {
        selectedClips: Array<ClipGenerationDataClip>;
        previewsData: ClipGenerationData;
        videoId: string;
    };
}

export interface GenerateClipsStartAction {
    type: GenerationActionTypes.GENERATE_CLIPS_START;
}

export interface GenerateClipsSuccessAction {
    type: GenerationActionTypes.GENERATE_CLIPS_SUCCESS;
}

export interface GenerateClipsFailAction {
    type: GenerationActionTypes.GENERATE_CLIPS_FAIL;
    payload: any;
}

export type GenerationAction =
    | GetTrailersAction
    | GetTrailersSuccessAction
    | GetTrailersStartAction
    | GetTrailersFailAction
    | GenerateClipPreviewsAction
    | GenerateClipPreviewsStartAction
    | GenerateClipPreviewsSuccessAction
    | GenerateClipPreviewsFailAction
    | GetPreviewsAction
    | GetPreviewsStartAction
    | GetPreviewsSuccessAction
    | GetPreviewsFailAction
    | GenerateClipsAction
    | GenerateClipsStartAction
    | GenerateClipsSuccessAction
    | GenerateClipsFailAction;

export interface ClipGenerationData {
    id: string;
    videoId: string;
    ownerId: string;
    jobId: string;
    type: string;
    template: string;
    clips: Array<ClipGenerationDataClip>;
    creationDate: number;
    minClipLength: number;
    minSceneLength: number;
    maxClipLength: number;
    maxSceneLength: number;
    order: ClipOrder;
    diffFactor: number;
    fps: number
}

export interface ClipGenerationDataClip { 
    timestamps: Array<ClipGenerationDataClipTimestamp>;
    clipId: string;
    requestId: string;
    videoId: string;
    ownerId: string;
    jobId: string;
    type: string;
    clipVersion: number;
    genMetadata: ClipGenerationDataClipGenMetadata;
    isMuted: boolean;
}

export interface ClipGenerationDataClipGenMetadata {
    shotsMetadata: Array<ClipGenerationDataClipGenMetadataShotMetadata>;
    clipMetaData: any;
}

export interface ClipGenerationDataClipGenMetadataShotMetadata {
    origin: ClipGenerationDataClipGenMetadataShotMetadataOrigin;
}

export interface ClipGenerationDataClipGenMetadataShotMetadataOrigin {
    shotId: string;
    collectionId: string;
    customized: boolean;
}

export interface ClipGenerationDataClipTimestamp {
    from: number;
    to: number;
}

export interface ClipRenderData {
    videoId: string;
    ownerId: string;
    userId?: string;
    jobId: string;
    type: string;
    template: string;
    clip: ClipGenerationDataClip;
}
