export const transformAudioTrack = (audioTrack: any) => ({
    bitrate: +audioTrack?.BitRate,
    bitrateMode: audioTrack?.BitRate_Mode,
    frameCount: +audioTrack?.FrameCount,
    channels: +audioTrack?.Channels,
    codec: audioTrack?.CodecID,
    duration: +audioTrack?.Duration,
    samplePerFrame: +audioTrack?.SamplesPerFrame,
    samplingRate: +audioTrack?.SamplingRate,
});

export const transformVideoTrack = (videoTrack: any, generalTrack: any) => {
    const fps = +videoTrack?.FrameRate || +videoTrack?.FrameRate_Nominal || 30;
    const videoDuration = +videoTrack?.Duration || +generalTrack?.Duration;
    const frameCount =
        +videoTrack?.FrameCount || Math.ceil(fps * videoDuration);
    let width = +videoTrack?.Width || 1920;
    let height = +videoTrack?.Height || 1080;

    let aspectRatio =
        videoTrack?.DisplayAspectRatio_Original ||
        videoTrack?.DisplayAspectRatio;
    const rotation = +videoTrack?.Rotation;

    if (rotation) {
        width = +videoTrack.Height;
        height = +videoTrack.Width;

        aspectRatio = width / height;
    }

    return {
        aspectRatio,
        bitDepth: +videoTrack?.BitDepth,
        bitrate: +videoTrack?.BitRate,
        codec: videoTrack?.CodecID,
        colorSpace: videoTrack?.ColorSpace,
        duration: videoDuration,
        frameCount,
        framerate: fps,
        height,
        profile: videoTrack?.Format_Profile,
        scanType: videoTrack?.ScanType,
        width,
        rotation,
    };
};

export const transformGeneralTrack = (generalTrack: any) => ({
    duration: +generalTrack.Duration,
    fileSize: +generalTrack.FileSize,
    format: generalTrack.Format,
    totalBitrate: +generalTrack.OverallBitRate,
});

export const transformMediaInfoTracks = (tracks: any) => {
    const audioTrack = tracks.find((track: any) => track[`@type`] === 'Audio');
    const videoTrack = tracks.find((track: any) => track[`@type`] === 'Video');
    const generalTrack = tracks.find(
        (track: any) => track[`@type`] === 'General'
    );

    const transformedGeneralTrack = transformGeneralTrack(generalTrack);

    let trasformedTracks = {
        container: transformedGeneralTrack,
    } as any;

    if (audioTrack) {
        const transformedAudioTrack = transformAudioTrack(audioTrack);

        trasformedTracks = {
            ...trasformedTracks,
            audio: [transformedAudioTrack],
        };
    }

    if (videoTrack) {
        const transformedVideoTrack = transformVideoTrack(
            videoTrack,
            generalTrack
        );

        trasformedTracks = {
            ...trasformedTracks,
            video: [transformedVideoTrack],
        };
    }

    return trasformedTracks;
};
