export enum ValidatorType {
    Email,
    Password,
    Required,
    FaceTitle,
}

export enum ValidationType {
    OnSubmit,
    OnInput,
}

/**
 * Store for validation functions
 * Each function:
 * @param value value to validate
 * @returns validatin errors array
 */
export const validators = {
  Email: {
    OnSubmit: (value: string): Array<string> => {
      const errors: Array<string> = [];

      const validationPassed = value.match(
        /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@((([a-zA-Z0-9]([a-zA-Z0-9-]{1,62})?[a-zA-Z0-9]\.)+(([a-zA-Z]{4,62}|([a-vx-zA-VX-Z][a-df-zA-DF-Z][ac-zAC-Z])|([a-zA-Z]{2}))))|((((25[0-5])|(2[0-4][0-9])|(1?[0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1?[0-9]{1,2}))))$/g,
      );

      if (!validationPassed) {
        errors.push('Unsupported format');
      }

      return errors;
    },
    OnInput: (value: string): Array<string> => {
      const errors: Array<string> = [];

      const validationPassed = value.match(/^[a-zA-Z0-9@_.+-]*$/);
      if (!validationPassed) {
        errors.push('Unsupported character');
      }

      return errors;
    },
  },
  FaceTitle: {
    OnInput: (value: string): Array<string> => {
      const errors: Array<string> = [];

      const validationPassed = value.match(
        /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
      );

      if (!validationPassed) {
        errors.push('Unsupported character');
      }

      return errors;
    },
  },
  Password: {
    OnSubmit: (value: string): Array<string> => {
      const errors: Array<string> = [];
      const minWidthPassed = value.length < 6;

      if (minWidthPassed) {
        errors.push(
          'The minimum password length - 6 characters',
        );
      }

      return errors;
    },
    OnInput: (value: string): Array<string> => {
      const errors: Array<string> = [];

      const validationPassed = value.match(/^[A-Za-z\d]*$/);
      if (!validationPassed) {
        errors.push('Unsupported character');
      }

      return errors;
    },
  },
  Required: {
    OnSubmit: (value: string): Array<string> => {
      const errors: Array<string> = [];

      const validationPassed = value.match(/^(?!\s*$).+/);
      if (!validationPassed) {
        errors.push("Field shouldn't be empty");
      }

      return errors;
    },
    OnInput: (): Array<string> => [],
  },
};

/**
 * Selects validator to use
 * @param value value to validate
 * @param validatorTypes array of types that indicates validation functions to use
 * @param validationType type that indicstes the case in which every validator used
 * @returns validator function
 */
export const selectValidator = (
  validatorType: ValidatorType,
  validationType: ValidationType,
): ((value: string) => Array<string>
) | undefined => {
  switch (validatorType) {
    case ValidatorType.Email:
      switch (validationType) {
        case ValidationType.OnSubmit:
          return validators.Email.OnSubmit;
        case ValidationType.OnInput:
          return validators.Email.OnInput;
        default:
          return () => [''];
      }
    case ValidatorType.Password:
      switch (validationType) {
        case ValidationType.OnSubmit:
          return validators.Password.OnSubmit;
        case ValidationType.OnInput:
          return validators.Password.OnInput;
        default:
          return () => [''];
      }
    case ValidatorType.FaceTitle:
      switch (validationType) {
        case ValidationType.OnInput:
          return validators.FaceTitle.OnInput;
        default:
          return () => [''];
      }
    case ValidatorType.Required:
      switch (validationType) {
        case ValidationType.OnSubmit:
          return validators.Required.OnSubmit;
        case ValidationType.OnInput:
          return validators.Required.OnInput;
        default:
          return () => [''];
      }
      break;
    default:
      break;
  }
};

/**
 * Triggers validators
 * @param value value to validate
 * @param validatorTypes array of types that indicates validation functions to use
 * @param validationType type that indicstes the case in which every validator used
 * @returns array of errors
 */
export default (
  value: string,
  validatorTypes: Array<ValidatorType>,
  validationType: ValidationType,
): Array<string> => validatorTypes
  .reduce((accumulator, currentValue) => {
    const validate = selectValidator(currentValue, validationType);

    if (validate) {
      const validationErrors = validate(value);

      return [...accumulator, ...validationErrors];
    }

    return accumulator;
  }, [] as Array<string>)
  .filter((validationError) => !!validationError);
