import { IconProps } from './index';

const EditIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 25;
  if (size) {
    height = size;
  }

  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 325 325.37515"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="m114.6875 284.675781-73.800781-73.800781 178.5-178.5 73.800781 73.800781zm-80.699219-60.800781 67.699219 67.699219-101.5 33.800781zm281.898438-140.300781-12.800781 12.800781-73.898438-73.898438 12.800781-12.800781c12.894531-12.902343 33.804688-12.902343 46.699219 0l27.199219 27.199219c12.800781 12.9375 12.800781 33.765625 0 46.699219zm0 0"
      />
    </svg>
  );
};

export default EditIcon;
