import { IconProps } from '../index';

const TextBlockIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.51 25.083h-1.086l-1.349-2.924h-3.604l-1.337 2.924H9L13.13 16h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662zM19.53 19.533c.676-.748 1.487-1.122 2.434-1.122.947 0 1.742.338 2.387 1.014.652.669.979 1.476.979 2.423 0 .947-.33 1.759-.99 2.435-.661.676-1.461 1.014-2.4 1.014-.93 0-1.734-.405-2.41-1.217v1.003h-.968V16.13h.967v3.402zm2.374 4.81c.677 0 1.25-.251 1.719-.752.477-.502.716-1.086.716-1.755 0-.676-.23-1.265-.692-1.766a2.254 2.254 0 0 0-1.73-.752c-.693 0-1.274.235-1.743.704-.462.47-.692 1.062-.692 1.779 0 .708.234 1.308.704 1.802.47.493 1.042.74 1.718.74zM33 23.758a3.312 3.312 0 0 1-1.35 1.146 4.094 4.094 0 0 1-1.79.393c-1.002 0-1.841-.33-2.518-.99-.668-.66-1.002-1.468-1.002-2.423 0-.955.338-1.77 1.014-2.447.677-.684 1.552-1.026 2.626-1.026.637 0 1.221.14 1.755.418A3.1 3.1 0 0 1 33 19.999l-.776.489c-.581-.756-1.34-1.134-2.28-1.134-.74 0-1.36.239-1.862.716-.5.47-.752 1.054-.752 1.754s.247 1.301.74 1.802c.494.502 1.118.752 1.874.752.907 0 1.671-.381 2.292-1.145l.764.525z"
                fill="#525253" />
            <path d="M35 13H8v15h27V13z" fill="url(#prd25iah5a)" />
            <defs>
                <linearGradient id="prd25iah5a" x1="35" y1="19.618" x2="8" y2="19.618" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default TextBlockIcon;