import styled from 'styled-components';
import { useMemo, useRef } from 'react';

import StockVideo from 'components/StockVideo';
import { Icon } from 'components/UI';

import { useDispatch, useSelector } from 'react-redux';
import { CategorizedStockVideos, StockMedia } from '../../interfaces/stockMedia';
import { getCategorizedStockVideosPreviews } from '../../state/modules/stockMedia';
import { VIDEO_CATEGORIES } from '../../constants/stock';
import { ModalEvent, ModalType, showModal } from '../../state/modules/modal';
import { setProjectNavRoute } from '../../state/modules/projectsStudio';
import { ProjectNavRoute } from '../../interfaces/projects';

const Carousel = styled.div`
    display: flex;
    flex-wrap: nowrap;
    height: 80px;

    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Item = styled.div`
    border: 1px solid;
    background-color: gray;
    color: white;
    flex-basis: 142px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 10px;
    overflow: hidden;
    height: 80px;
    margin-right: 5px;
`;

const NavButton = styled.button<{ left?: boolean; right?: boolean }>`
    position: absolute;
    right: -12px;
    bottom: 20px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out 0s;
    border: 1px solid rgb(223, 224, 229);
    z-index: 5;
    background: none rgb(247, 247, 248);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    height: 35px;
    width: 35px;
    cursor: pointer;

    ${({ right }) => (right ? 'right: 5px' : '')}
    ${({ left }) => (left ? 'left: 5px' : '')}
`;

const CarouselContainer = styled.div<{inModal: boolean}>`
    position: relative;
    width: ${({ inModal }) =>
            inModal ? '100%' : '95%'};

    &:hover ${NavButton} {
        opacity: 1;
    }
`;

const SearchIcon = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
`;

const Title = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
`;

const SearchButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0;
    //font-weight: 500;
    color: #000;

    &:hover {
        color: #C24FFF;
    }
`;

interface Props {
    inModal?: boolean;
    inLibrary?: boolean;
    videos?: StockMedia[];
}

const StockVideosCategorizedCarousel = ({ inModal, inLibrary, videos }: Props) => {
    const dispatch = useDispatch();

    const listRef = useRef<any>(null);

    const videosPreviews = useSelector(getCategorizedStockVideosPreviews) as CategorizedStockVideos;

    const videosList = useMemo(() => {
        if(videos?.length) {
            return videos;
        }

        if(videosPreviews?.[VIDEO_CATEGORIES[0]]?.videos?.length) {
            return videosPreviews?.[VIDEO_CATEGORIES[0]]?.videos?.filter(video => !video.title.toLowerCase().includes("green"))
        }
        return [] as StockMedia[];
    }, [videos, videosPreviews]);

    const handleScrollLeft = () => {
        if (listRef?.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -100,
                behavior: 'smooth',
            });
        }
    };

    const handleScrollRight = () => {
        if (listRef?.current) {
            listRef.current.scrollBy({
                top: 0,
                left: +100,
                behavior: 'smooth',
            });
        }
    };

    const handleShowPanel = () => {
        if (inModal) {
            dispatch(
                showModal(ModalType.STOCK_VIDEOS, ModalEvent.STOCK_VIDEOS)
            );
        } else {
            dispatch(setProjectNavRoute(ProjectNavRoute.STOCK_VIDEOS));
        }
    };

    const renderNavButtons = () => {
        return (
            <>
                <NavButton left onClick={handleScrollLeft}>
                    <Icon
                        name="arrow-left"
                        size={12}
                        color="rgb(93, 100, 123)"
                    />
                </NavButton>
                <NavButton right onClick={handleScrollRight}>
                    <Icon
                        name="arrow-right"
                        size={12}
                        color="rgb(93, 100, 123)"
                    />
                </NavButton>
            </>
        );
    };

    const renderSearchButton = () => {
        return !inLibrary ? (
            <SearchButton onClick={handleShowPanel}>
                <SearchIcon>
                    <Icon name="search" size={16} color="rgb(144, 148, 165)" />
                </SearchIcon>
                Search
            </SearchButton>
        ) : null;
    };

    return (
        <CarouselContainer inModal>
            {
                inModal && (
                    <Header>
                        <Title>Stock videos</Title>
                        {renderSearchButton()}
                    </Header>
                )
            }
            <Carousel ref={listRef}>
                {videosList?.map((item, index) => (
                    <Item key={index}>
                        <StockVideo
                            media={item}
                            inModal={inModal}
                            inLibrary={inLibrary}
                        />
                    </Item>
                ))}
            </Carousel>
            {renderNavButtons()}
        </CarouselContainer>
    );
};

export default StockVideosCategorizedCarousel;
