import { StatusType } from 'interfaces/statuses';
import { MediaFile, VideoFilter } from 'interfaces/videos';
import {
  VideosActionTypes,
  GetVideoListStartAction,
  GetVideoListAction,
  GetVideoListSuccessAction,
  UpdateVideosStatusesAction,
  UpdateVideosStatusesStartAction,
  UpdateVideosStatusesFailAction,
  GetVideoListFailAction,
  UpdateVideosStatusesSuccessAction,
  GetVideoDetailsAction,
  GetVideoDetailsStartAction,
  SetVideoDetailsAction,
  SetVideoVttAction,
  SetVideoDetailsLoadingAction,
  ClearDataAction,
  SelectVideoAction,
  UnselectVideoAction,
  ToggleAllVideosAction,
  SetSelectedVideosAction,
  DeleteVideosAction,
  DeleteVideosStartAction,
  DeleteSpecificVideoAction,
  SetVideosIdsForDeleteAction,
  DeleteVideosFailAction,
  DeleteVideosSuccessAction,
  DownloadVideosAction,
  ChangeVideoLibraryFilterAction,
  ChangeVideoLibraryFilterActionPayload,
  SetVideoLibraryFilterAction,
  ResetVideoLibraryFilterAction,
  UpdateVideoDetailsActionPayload,
  UpdateVideoDetailsAction,
  UpdateVideoDetailsStartAction,
  UpdateVideoDetailsSuccessAction,
  UpdateVideoDetailsFailAction,
  SyncMediaDetailsAction,
  SetMediaListStatusFilterAction,
} from './types';

export const getVideoListStart = (): GetVideoListStartAction => ({
  type: VideosActionTypes.GET_VIDEOS_LIST_START,
});

export const getVideoList = (isLoadMore?: boolean): GetVideoListAction => ({
  type: VideosActionTypes.GET_VIDEOS_LIST,
  payload: { isLoadMore },
});

export const getVideoListSuccess = (
  videos: Array<MediaFile>,
  total: number,
): GetVideoListSuccessAction => ({
  type: VideosActionTypes.GET_VIDEOS_LIST_SUCCESS,
  payload: {
    videos,
    total,
  },
});

export const getVideoListFail = (err: any): GetVideoListFailAction => ({
  type: VideosActionTypes.GET_VIDEOS_LIST_FAIL,
  payload: err,
});

export const updateVideosStatuses = (): UpdateVideosStatusesAction => ({
  type: VideosActionTypes.UPDATE_VIDEOS_STATUSES,
});

export const updateVideosStatusesStart = (): UpdateVideosStatusesStartAction => ({
  type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_START,
});

export const updateVideosStatusesSuccess = (
  data: Array<MediaFile>,
): UpdateVideosStatusesSuccessAction => ({
  type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_SUCCESS,
  payload: data,
});

export const updateVideosStatusesFail = (
  err: any,
): UpdateVideosStatusesFailAction => ({
  type: VideosActionTypes.UPDATE_VIDEOS_STATUSES_FAIL,
  payload: err,
});

export const getVideoDetailsStart = (): GetVideoDetailsStartAction => ({
  type: VideosActionTypes.GET_VIDEO_DETAILS_START,
});

export const getVideoDetails = (id: string): GetVideoDetailsAction => ({
  type: VideosActionTypes.GET_VIDEO_DETAILS,
  payload: {
    id,
  },
});

export const setVideoDetails = (
  videoDetails: MediaFile,
): SetVideoDetailsAction => ({
  type: VideosActionTypes.SET_VIDEO_DETAILS,
  payload: videoDetails,
});

export const setVideoVtt = (videoThumbnails: string): SetVideoVttAction => ({
  type: VideosActionTypes.SET_VIDEO_VTT,
  payload: videoThumbnails,
});

export const setVideoDetailsLoading = (
  isLoading: boolean,
): SetVideoDetailsLoadingAction => ({
  type: VideosActionTypes.SET_VIDEO_DETAILS_LOADING,
  payload: isLoading,
});

export const clearData = (): ClearDataAction => ({
  type: VideosActionTypes.CLEAR_VIDEO_DETAILS,
});

export const selectVideo = (id: string): SelectVideoAction => ({
  type: VideosActionTypes.SELECT_VIDEO,
  payload: {
    id,
  },
});

export const unselectVideo = (id: string): UnselectVideoAction => ({
  type: VideosActionTypes.UNSELECT_VIDEO,
  payload: { id },
});

export const toggleAllVideos = (): ToggleAllVideosAction => ({
  type: VideosActionTypes.TOGGLE_ALL_VIDEOS,
});

export const setSelectedVideos = (
  selectedVideos: Array<MediaFile>,
): SetSelectedVideosAction => ({
  type: VideosActionTypes.SET_SELECTED_VIDEOS,
  payload: selectedVideos,
});

export const deleteSpecificVideo = (
  videoId: string,
): DeleteSpecificVideoAction => ({
  type: VideosActionTypes.DELETE_SPECIFIC_VIDEO,
  payload: { videoId },
});

export const deleteVideos = (): DeleteVideosAction => ({
  type: VideosActionTypes.DELETE_VIDEOS,
});

export const deleteVideosStart = (): DeleteVideosStartAction => ({
  type: VideosActionTypes.DELETE_VIDEOS_START,
});

export const deleteVideosSuccess = (): DeleteVideosSuccessAction => ({
  type: VideosActionTypes.DELETE_VIDEOS_SUCCESS,
});

export const deleteVideosFail = (error: any): DeleteVideosFailAction => ({
  type: VideosActionTypes.DELETE_VIDEOS_FAIL,
  payload: error,
});

export const setVideosIdsForDelete = (
  ids: Array<string>,
): SetVideosIdsForDeleteAction => ({
  type: VideosActionTypes.SET_VIDEOS_IDS_FOR_DELETE,
  payload: ids,
});

export const downloadVideos = (): DownloadVideosAction => ({
  type: VideosActionTypes.DOWNLOAD_VIDEOS,
});

export function changeVideoLibraryFilter({
  field,
  value,
}: ChangeVideoLibraryFilterActionPayload): ChangeVideoLibraryFilterAction {
  return {
    type: VideosActionTypes.CHANGE_VIDEO_FILTER,
    payload: {
      field,
      value,
    },
  };
}

export function setVideoLibraryFilter(
  videoFilter: VideoFilter,
): SetVideoLibraryFilterAction {
  return {
    type: VideosActionTypes.SET_VIDEO_FILTER,
    payload: videoFilter,
  };
}

export function resetVideoLibraryFilter(): ResetVideoLibraryFilterAction {
  return {
    type: VideosActionTypes.RESET_VIDEO_LIBRARY_FILTER,
  };
}
 
export function updateVideoDetails({
  title,
  description,
  videoId,
  defaultLanguageCode
}: UpdateVideoDetailsActionPayload): UpdateVideoDetailsAction {
  return {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS,
    payload: {
      videoId,
      title,
      description,
      defaultLanguageCode
    },
  };
}

export function updateVideoDetailsStart(): UpdateVideoDetailsStartAction {
  return {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_START,
  };
}

export function updateVideoDetailsSuccess(): UpdateVideoDetailsSuccessAction {
  return {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_SUCCESS,
  };
}

export function updateVideoDetailsFail(): UpdateVideoDetailsFailAction {
  return {
    type: VideosActionTypes.UPDATE_VIDEO_DETAILS_FAIL,
  };
}

export function syncMediaDetails(): SyncMediaDetailsAction {
  return {
    type: VideosActionTypes.SYNC_MEDIA_DETAILS,
  };
}

export function setMediaListStatusFilter(
  payload: StatusType.FAILED | StatusType.SUCCEEDED | StatusType.IN_PROGRESS | null
): SetMediaListStatusFilterAction {
  return {
    type: VideosActionTypes.SET_MEDIA_LIST_STATUS_FILTER,
    payload,
  };
}


