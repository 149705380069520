import { IconProps } from './index';

const ArrowDown = ({ size, color }: IconProps): JSX.Element => {
  let height = 23;
  if (size) {
    height = size;
  }

  return (
    <svg
      width={height}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 306 306"
    >
      <g>
        <g id="expand-less">
          <polygon
            fill={color}
            points="153,58.65 0,211.65 35.7,247.35 153,130.05 270.3,247.35 306,211.65 		"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default ArrowDown;
