import { IconProps } from '../index';

const TextOutRollIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.57 16.03c.398-.666.876-1 1.434-1 .557 0 1.026.302 1.406.905.384.595.576 1.315.576 2.158 0 .844-.194 1.567-.583 2.17-.39.602-.86.903-1.413.903-.549 0-1.022-.361-1.42-1.084v.893H19V13h.57v3.03zm1.399 4.285c.398 0 .735-.223 1.012-.67.281-.446.422-.967.422-1.562 0-.603-.136-1.127-.408-1.574-.272-.447-.612-.67-1.02-.67-.407 0-.75.21-1.026.627-.272.419-.408.947-.408 1.585 0 .63.139 1.166.415 1.605.277.44.614.66 1.013.66z"
                fill="#7C7C7D" />
            <path
                d="M17 21.083h-.766l-.95-2.28h-2.542l-.943 2.28H11L13.912 14h.218L17 21.083zm-2.045-3.09-.934-2.3-.943 2.3h1.877z"
                fill="#CECECF" />
            <path
                d="M31.5 19.491a3.348 3.348 0 0 1-1.316 1.241 3.67 3.67 0 0 1-1.748.427c-.978 0-1.797-.358-2.457-1.073-.653-.715-.979-1.59-.979-2.624s.33-1.918.99-2.65c.66-.741 1.515-1.112 2.563-1.112.621 0 1.192.15 1.712.453.528.301.94.723 1.235 1.266l-.757.53c-.567-.818-1.309-1.228-2.225-1.228-.722 0-1.328.259-1.817.776-.49.508-.734 1.142-.734 1.9 0 .759.24 1.41.722 1.952.482.543 1.091.815 1.829.815.885 0 1.63-.414 2.236-1.241l.746.568z"
                fill="#525253" />
            <path d="M26.5 28H15m0 0 3 3m-3-3 3-3" stroke="url(#d50bgcmb4a)" strokeLinecap="round"
                  strokeLinejoin="round" />
            <defs>
                <linearGradient id="d50bgcmb4a" x1="15" y1="28" x2="26.5" y2="28" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#525253" />
                    <stop offset="1" stopColor="#525253" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default TextOutRollIcon;