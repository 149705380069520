import { IconProps } from './index';

const EditPenIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.39909 2.5358H3.60809C2.35187 2.5358 1.3335 3.55414 1.3335 4.81033V12.3922C1.3335 13.6483 2.35187 14.6667 3.60809 14.6667H11.1901C12.4463 14.6667 13.4647 13.6483 13.4647 12.3922L13.4647 8.60124M5.12449 10.8757L7.88306 10.3199C8.02951 10.2904 8.16397 10.2183 8.26958 10.1126L14.4449 3.93412C14.741 3.63789 14.7408 3.15772 14.4445 2.86174L13.1363 1.5551C12.8401 1.25925 12.3602 1.25945 12.0642 1.55555L5.88823 7.73472C5.78283 7.84017 5.71086 7.97436 5.68133 8.12049L5.12449 10.8757Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default EditPenIcon;


