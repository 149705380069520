import { IconProps } from './index';

const GridIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4.5H12.75V11.25H19.5V6C19.5 5.17157 18.8284 4.5 18 4.5ZM21 11.25V12.75V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H11.25H12.75H18C19.6569 3 21 4.34315 21 6V11.25ZM19.5 12.75H12.75V19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V12.75ZM11.25 11.25V4.5H6C5.17157 4.5 4.5 5.17157 4.5 6V11.25H11.25ZM4.5 12.75H11.25V19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V12.75Z"
                fill={color}
            />
        </svg>
    );
};

export default GridIcon;
