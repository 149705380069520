import { IconProps } from './index';

const LogoutIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.333 20H5.778A1.778 1.778 0 014 18.222V5.778A1.778 1.778 0 015.778 4h3.555m6.223 12.444L20 12l-4.444-4.444M20 12H9.333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LogoutIcon;
