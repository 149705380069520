import { memo } from 'react';

import ArrowIcon from './ArrowIcon';
import CloseIcon from './CloseIcon';
import DropdownIcon from './DropdownIcon';
import EyeIcon from './EyeIcon';
import CloseEyeIcon from './CloseEyeIcon';
import CheckIcon from './CheckIcon';
import ProfileIcon from './ProfileIcon';
import DeleteIcon from './DeleteIcon';
import UploadCloudIcon from './UploadCloudIcon';
import SearchIcon from './SearchIcon';
import UploadedVideoIcon from './UploadedVideoIcon';
import TrashIcon from './TrashIcon';
import TrashBinIcon from './TrashBinIcon';
import SuccessCheckIcon from './SuccessCheckIcon';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import StatusBarIcon from './StatusBarIcon';
import DownloadIcon from './DownloadIcon';
import Triangle from './Triangle';
import CollectionsIcon from './CollectionsIcon';
import AttentionIcon from './AttentionIcon';
import ExportIcon from './ExportIcon';
import PlayIcon from './PlayIcon';
import PauseIcon from './PauseIcon';
import SettingsIcon from './SettingsIcon';
import CaptionsIcon from './Captions';
import UndoIcon from './UndoIcon';
import OptionsIcon from './OptionsIcon';
import AnalyzeIcon from './AnalyzeIcon';
import VideosIcon from './VideosIcon';
import FacialAttributesIcon from './FacialAttributesIcon';
import CelebrityIcon from './CelebrityIcon';
import ObjectsActivitiesIcon from './ObjectsActivitiesIcon';
import TranscriptIcon from './TranscriptIcon';
import ShotsIcon from './ShotsIcon';
import PlusIcon from './PlusIcon';
import MakeClipIcon from './MakeClipIcon';
import EditIcon from './EditIcon';
import Info from './Info';
import VideosFilter from './VideosFilter';
import Calendar from './Calendar';
import GeneralVideosFilter from './GeneralVideosFilter';
import ZoomInIcon from './ZoomInIcon';
import ZoomOutIcon from './ZoomOutIcon';
import HelpIcon from './HelpIcon';
import SftpIcon from './SftpIcon';
import MusicIcon from './MusicIcon';
import GoogleIcon from './GoogleIcon';
import Sound from './Sound';
import SoundOff from './SoundOff';
import CutIcon from './CutIcon';
import ProjectIcon from './ProjectIcon';
import HamburgerIcon from './HamburgerIcon';
import YoutubeIcon from './YoutubeIcon';
import GoBackIcon from './GoBackIcon';
import ClockIcon from './ClockIcon';
import CenterIcon from './CenterIcon';
import AutomaticDurationIcon from './AutomaticDurationIcon';
import RemoveIcon from './RemoveIcon';
import ManualSubtitleIcon from './ManualSubtitleIcon';
import UploadSubtitlesIcon from './UploadSubtitlesIcon';
import AutoSubtitleIcon from './AutoSubtitleIcon';
import GridIcon from './GridIcon';
import ListIcon from './ListIcon';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import BillingIcon from './BillingIcon';
import QuestionIcon from './QuestionIcon';
import RequestFeatureIcon from './RequestFeatureIcon';
import MergeIcon from './MergeIcon';
import VideoIcon from './VideoIcon';
import ImageIcon from './ImageIcon';
import AudioIcon from './AudioIcon';
import LogoutIcon from './LogoutIcon';
import HomeIcon from './HomeIcon';
import RedoIcon from './RedoIcon';
import AddUserIcon from './AddUserIcon';
import HeadPhonesIcon from './HeadPhonesIcon';
import UpdateIcon from './UpdateIcon';
import ChatIcon from './ChatIcon';
import CodeIcon from './CodeIcon';
import ColorIcon from './ColorIcon';
import OriginalSizeIcon from './OriginalSizeIcon';
import LandscapeIcon from './LandscapeIcon';
import TikTokSecondIcon from './TikTokSecondIcon';
import FolderIcon from './FolderIcon';
import CreateFolderIcon from './CreateFolderIcon';
import CreateFolderSecondIcon from './CreateFolderSecondIcon';
import ChevronRightIcon from './ChevronRightIcon';
import FolderOutlineIcon from './FolderOutlineIcon';
import MoveFolderIcon from './MoveFolderIcon';
import TextToSpeechIcon from './TextToSpeechIcon';
import BellIcon from './BellIcon';
import DuplicateIcon from './DuplicateIcon';
import FlashIcon from './FlashIcon';
import BrandKitIcon from './BrandKitIcon';
import CollaboratorsIcon from './CollaboratorsIcon';
import AutofitIcon from "./AutofitIcon";
import AudioDubbingIcon from './AudioDubbingIcon';
import UploadMediaIcon from './UploadMediaIcon';
import LibraryIcon from './LibraryIcon';
import GoogleDriveIcon from './GoogleDriveIcon';
import ImageMediaIcon from './ImageMediaIcon';
import NoteIcon from './NoteIcon';
import GooglePhotosIcon from './GooglePhotosIcon';
import DropboxIcon from './DropboxIcon';
import LinkIcon from './LinkIcon';
import BlankIcon from './BlankIcon';
import AudioLines from './AudioLines';
import PremiumIcon from './PremiumIcon';
import TemplateIcon from './TemplateIcon';

// text animations
import TextSlideUpIcon from './TextAnimationIcons/TextSlideUpIcon';
import TextSlideDownIcon from './TextAnimationIcons/TextSlideDownIcon';
import TextSlideLeftIcon from './TextAnimationIcons/TextSlideLeftIcon';
import TextSlideRightIcon from './TextAnimationIcons/TextSlideRightIcon';
import TextDropIcon from './TextAnimationIcons/TextDropIcon';
import TextCompressIcon from './TextAnimationIcons/TextCompressIcon';
import TextFadeIcon from './TextAnimationIcons/TextFadeIcon';
import TextZoomInIcon from './TextAnimationIcons/TextZoomInIcon';
import TextBlockIcon from './TextAnimationIcons/TextBlockIcon';
import TextTypewriterIcon from './TextAnimationIcons/TextTypewriterIcon';
import TextRiseIcon from './TextAnimationIcons/TextRiseIcon';
import TextInPopIcon from './TextAnimationIcons/TextInPopIcon';
import TextBounceIcon from './TextAnimationIcons/TextBounceIcon';
import TextWaveIcon from './TextAnimationIcons/TextWaveIcon';
import TextInFallIcon from './TextAnimationIcons/TextInFallIcon';
import TextInSkidIcon from './TextAnimationIcons/TextInSkidIcon';
import TextInFlipboardIcon from './TextAnimationIcons/TextInFlipboardIcon';
import TextInScaleIcon from './TextAnimationIcons/TextInScaleIcon';
import TextInDragonflyIcon from './TextAnimationIcons/TextInDragonfly';
import TextInBillboardIcon from './TextAnimationIcons/TextInBillboardIcon';
import TextInRollIcon from './TextAnimationIcons/TextInRollIcon';
import TextZoomOutIcon from './TextAnimationIcons/TextZoomOutIcon';
import TextSinkIcon from './TextAnimationIcons/TextSinkIcon';
import TextOutPopIcon from './TextAnimationIcons/TextOutPopIcon';
import TextDecompressIcon from './TextAnimationIcons/TextDecompressIcon';
import TextOutFallIcon from './TextAnimationIcons/TextOutFallIcon';
import TextOutSkidIcon from './TextAnimationIcons/TextOutSkidIcon';
import TextOutFlipboardIcon from './TextAnimationIcons/TextOutFlipboardIcon';
import TextOutBillboardIcon from './TextAnimationIcons/TextOutBillboardIcon';
import TextOutDragonflyIcon from './TextAnimationIcons/TextOutDragonflyIcon';
import TextOutRollIcon from './TextAnimationIcons/TextOutRollIcon';
import TextOutScaleIcon from './TextAnimationIcons/TextOutScaleIcon';
import AnimationsIcon from './TextAnimationIcons/AnimationsIcon';
import PlayNewIcon from './PlayNewIcon';
import AudioSoundNew from './AudioSoundNew';
import AudioSoundOffNew from './AudioSoundOffNew';
import AudioWaveForm from './AudioWaveform';
import AudioDetachIcon from './AudioDetachIcon';
import MediaReplaceIcon from './MediaReplaceIcon';
import PlayheadIcon from './PlayheadIcon';
import DropdownIconNew from './DropdownIconNew';
import OutsideLinkIcon from './OutsideLinkIcon';
import CheckMarkNew from './CheckMarkNew';

import MenuIconNew from './EditorSidebarIcons/MenuIcon';
import MediaIconNew from './EditorSidebarIcons/MediaIconNew';
import AudioIconNew from './EditorSidebarIcons/AudioIconNew';
import ElementsIconNew from './EditorSidebarIcons/ElementsIconNew';
import FiltersIconNew from './EditorSidebarIcons/FiltersIconNew';
import TemplatesIconNew from './EditorSidebarIcons/TemplatesIconNew';
import TextIconNew from './EditorSidebarIcons/TextIconNew';
import TransitionsIconNew from './EditorSidebarIcons/TransitionsIconNew';
import SubtitlesIconNew from './EditorSidebarIcons/SubtitlesIconNew';
import SettingsIconNew from './EditorSidebarIcons/SettingsIconNew';
import StockIconNew from './EditorSidebarIcons/StockIconNew';
import ExportIconNew from './ExportIconNew';
import RedoIconNew from './RedoIconNew';
import UndoIconNew from './UndoIconNew';
import ZoomOutIconNew from './EditorTopLine/ZoomOutIconNew';
import ZoomInIconNew from './EditorTopLine/ZoomInIconNew';
import SoundWaveIcon from './EditorTopLine/SoundWaveIconNew';
import SoundOnIconNew from './EditorTopLine/SoundOnIconNew';
import SoundOffIconNew from './EditorTopLine/SoundOffIconNew';
import PauseIconNew from './EditorTopLine/PauseIconNew';
import PlayIconNew from './EditorTopLine/PlayIconNew';
import SkipBackIconNew from './EditorTopLine/SkipBackIconNew';
import SkipForwardIconNew from './EditorTopLine/SkipForwardIconNew';
import TrashcanIconNew from './EditorTopLine/TrashcanIconNew';
import SplitIconNew from './EditorTopLine/SplitIconNew';
import SelectAllIconNew from './EditorTopLine/SelectAllIconNew';
import FitIconNew from './EditorTopLine/FitIconNew';
import AddMediaIconNew from './EditorTopLine/AddMediaIconNew';
import CloudIcon from './CloudIcon';
import MicIcon from './MicIcon';
import CloseIconNew from './CloseIconNew';
import EditPenIcon from './EditPenIcon';
import DollarSign from './DollarSign';
import TimerIcon from './TimerIcon';
import MagicIcon from './MagicIcon';
import UploadIcon from './UploadIcon';
import TranslateIcon from './TranslateIcon';
import AutoSubtitlingIcon from './AutoSubtitlingIcon';
import ManualSettingsIcon from './ManualSettingsIcon';

export interface IconProps {
    size?: number; // icon height in pixels
    color?: string; // any valid color string (rgb, hex, etc)
    secondColor?: string; // any valid color string (rgb, hex, etc)
    hoverColor?: string; // any valid color string (rgb, hex, etc)
}

export interface NamedIconProps extends IconProps {
    name: string;
}
/**
 * Icon component
 * ==============
 * Renders <svg> icon depending on its name
 */
const Icon = memo(
    ({ color, size, name, secondColor }: NamedIconProps): JSX.Element => {
        if (!color) {
            color = '#323E47';
        }

        switch (name) {
            case 'google':
                return <GoogleIcon color={color} size={size} />;
            case 'color':
                return <ColorIcon color={color} size={size} />;
            case 'headphones':
                return <HeadPhonesIcon color={color} size={size} />;
            case 'chat':
                return <ChatIcon color={color} size={size} />;
            case 'update':
                return <UpdateIcon color={color} size={size} />;
            case 'analyze':
                return <AnalyzeIcon color={color} size={size} />;
            case 'music':
                return <MusicIcon color={color} size={size} />;
            case 'image-media':
                return <ImageMediaIcon color={color} size={size} />;
            case 'music-note':
                return <NoteIcon color={color} size={size} />;
            case 'sftp':
                return <SftpIcon color={color} size={size} />;
            case 'arrow':
                return <ArrowIcon color={color} size={size} />;
            case 'arrowUp':
                return <ArrowUp color={color} size={size} />;
            case 'arrowDown':
                return <ArrowDown color={color} size={size} />;
            case 'close':
                return <CloseIcon color={color} size={size} />;
            case 'dropdown':
                return <DropdownIcon color={color} size={size} />;
            case 'dropdown-new':
                return <DropdownIconNew color={color} size={size} />;
            case 'eye':
                return <EyeIcon color={color} size={size} />;
            case 'close-eye':
                return <CloseEyeIcon color={color} size={size} />;
            case 'check':
                return <CheckIcon color={color} size={size} />;
            case 'success-check':
                return (
                    <SuccessCheckIcon
                        color={color}
                        secondColor={secondColor}
                        size={size}
                    />
                );
            case 'video-analyzer':
                return <VideosIcon color={color} size={size} />;
            case 'library':
                return <LibraryIcon color={color} size={size} />;
            case 'profile':
                return <ProfileIcon color={color} size={size} />;
            case 'delete':
                return <DeleteIcon color={color} size={size} />;
            case 'search':
                return <SearchIcon color={color} size={size} />;
            case 'trash':
                return <TrashIcon color={color} size={size} />;
            case 'trash-bin':
                return <TrashBinIcon color={color} size={size} />;
            case 'upload-cloud':
                return <UploadCloudIcon color={color} size={size} />;
            case 'uploaded-video':
                return <UploadedVideoIcon color={color} size={size} />;
            case 'status-bar':
                return <StatusBarIcon color={color} size={size} />;
            case 'download':
                return <DownloadIcon color={color} size={size} />;
            case 'triangle':
                return <Triangle color={color} size={size} />;
            case 'collections':
                return <CollectionsIcon color={color} size={size} />;
            case 'attention':
                return <AttentionIcon color={color} size={size} />;
            case 'export':
                return <ExportIcon color={color} size={size} />;
            case 'play':
                return <PlayIcon color={color} size={size} />;
            case 'play-new':
                return <PlayNewIcon color={color} size={size} />;
            case 'pause':
                return <PauseIcon color={color} size={size} />;
            case 'settings':
                return <SettingsIcon color={color} size={size} />;
            case 'captions':
                return <CaptionsIcon color={color} size={size} />;
            case 'undo':
                return <UndoIcon color={color} size={size} />;
            case 'redo':
                return <RedoIcon color={color} size={size} />;
            case 'options':
                return <OptionsIcon color={color} size={size} />;
            case 'facial-attributes':
                return <FacialAttributesIcon color={color} size={size} />;
            case 'celebrity':
                return <CelebrityIcon color={color} size={size} />;
            case 'objects-activities':
                return <ObjectsActivitiesIcon color={color} size={size} />;
            case 'transcript':
                return <TranscriptIcon color={color} size={size} />;
            case 'shots':
                return <ShotsIcon color={color} size={size} />;
            case 'plus':
                return <PlusIcon color={color} size={size} />;
            case 'make-clip':
                return <MakeClipIcon color={color} size={size} />;
            case 'edit':
                return <EditIcon color={color} size={size} />;
            case 'info':
                return <Info color={color} size={size} />;
            case 'videos-filter':
                return <VideosFilter color={color} size={size} />;
            case 'calendar':
                return <Calendar color={color} size={size} />;
            case 'general-video-filter':
                return <GeneralVideosFilter color={color} size={size} />;
            case 'zoom-in':
                return <ZoomInIcon color={color} size={size} />;
            case 'zoom-out':
                return <ZoomOutIcon color={color} size={size} />;
            case 'help':
                return <HelpIcon color={color} size={size} />;
            case 'sound':
                return <Sound color={color} size={size} />;
            case 'sound-new':
                return <AudioSoundNew color={color} size={size} />;
            case 'sound-off':
                return <SoundOff color={color} size={size} />;
            case 'sound-off-new':
                return <AudioSoundOffNew color={color} size={size} />;
            case 'cut':
                return <CutIcon color={color} size={size} />;
            case 'project':
                return <ProjectIcon color={color} size={size} />;
            case 'hamburger':
                return <HamburgerIcon color={color} size={size} />;
            case 'youtube':
                return <YoutubeIcon color={color} size={size} />;
            case 'goBack':
                return <GoBackIcon color={color} size={size} />;
            case 'clock':
                return <ClockIcon color={color} size={size} />;
            case 'center':
                return <CenterIcon color={color} size={size} />;
            case 'automatic-duration':
                return <AutomaticDurationIcon color={color} size={size} />;
            case 'remove':
                return <RemoveIcon color={color} size={size} />;
            case 'manual-subtitles':
                return <ManualSubtitleIcon color={color} size={size} />;
            case 'upload-subtitles':
                return <UploadSubtitlesIcon color={color} size={size} />;
            case 'auto-subtitles':
                return <AutoSubtitleIcon color={color} size={size} />;
            case 'grid':
                return <GridIcon color={color} size={size} />;
            case 'list':
                return <ListIcon color={color} size={size} />;
            case 'arrow-left':
                return <ArrowLeft color={color} size={size} />;
            case 'arrow-right':
                return <ArrowRight color={color} size={size} />;
            case 'upload-media':
                return <UploadMediaIcon color={color} size={size} />;
            case 'billing':
                return <BillingIcon color={color} size={size} />;
            case 'question':
                return <QuestionIcon color={color} size={size} />;
            case 'request-feature':
                return <RequestFeatureIcon color={color} size={size} />;
            case 'merge':
                return <MergeIcon color={color} size={size} />;
            case 'video':
                return <VideoIcon color={color} size={size} />;
            case 'image':
                return <ImageIcon color={color} size={size} />;
            case 'audio':
                return <AudioIcon color={color} size={size} />;
            case 'logout':
                return <LogoutIcon color={color} size={size} />;
            case 'home':
                return <HomeIcon color={color} size={size} />;
            case 'addUser':
                return <AddUserIcon color={color} size={size} />;
            case 'code':
                return <CodeIcon color={color} size={size} />;
            case 'original-size':
                return <OriginalSizeIcon color={color} size={size} />;
            case 'landscape':
                return <LandscapeIcon color={color} size={size} />;
            case 'tiktok-second':
                return <TikTokSecondIcon color={color} size={size} />;
            case 'folder':
                return <FolderIcon color={color} size={size} />;
            case 'create-folder':
                return <CreateFolderIcon color={color} size={size} />;
            case 'folder-outline':
                return <FolderOutlineIcon color={color} size={size} />;
            case 'move-folder':
                return <MoveFolderIcon color={color} size={size} />;
            case 'create-folder-second':
                return <CreateFolderSecondIcon color={color} size={size} />;
            case 'chevron-right':
                return <ChevronRightIcon color={color} size={size} />;
            case 'text-to-speech':
                return <TextToSpeechIcon color={color} size={size} />;
            case 'bell':
                return <BellIcon color={color} size={size} />;
            case 'duplicate':
                return <DuplicateIcon color={color} size={size} />;
            case 'flash': // todo
                return <FlashIcon color={color} size={size} />;
            case 'brand-kit':
                return <BrandKitIcon color={color} size={size} />;
            case 'collaborators':
                return <CollaboratorsIcon color={color} size={size} />;
            case 'autofit':
                return <AutofitIcon color={color} size={size} />;
            case 'audio-dubbing':
                return <AudioDubbingIcon color={color} size={size} />
            case 'drive':
                return <GoogleDriveIcon color={color} size={size} />;
            case 'google-photos':
                return <GooglePhotosIcon color={color} size={size} />
            case 'dropbox':
                return <DropboxIcon color={color} size={size} />
            case 'link':
                return <LinkIcon color={color} size={size} />
            case 'outer-link':
                return <OutsideLinkIcon color={color} size={size} />
            case 'blank':
                return <BlankIcon color={color} size={size} />
            case 'audio-lines':
                return <AudioLines color={color} size={size} />
            case 'audio-waveform':
                return <AudioWaveForm color={color} size={size} />
            case 'premium':
                return <PremiumIcon color={color} size={size} secondColor={secondColor}/>
            case 'template':
                return <TemplateIcon color={color} size={size} />
            case 'text-in-fade':
            case 'text-out-fade':
                return <TextFadeIcon color={color} size={size} />
            case 'text-in-slide_up':
            case 'text-out-slide_up':
                return <TextSlideUpIcon color={color} size={size} />
            case 'text-in-slide_down':
            case 'text-out-slide_down':
                return <TextSlideDownIcon color={color} size={size} />
            case 'text-in-slide_left':
            case 'text-out-slide_left':
                return <TextSlideLeftIcon color={color} size={size} />
            case 'text-in-slide_right':
            case 'text-out-slide_right':
                return <TextSlideRightIcon color={color} size={size} />
            case 'text-in-block':
            case 'text-out-block':
                return <TextBlockIcon color={color} size={size} />
            case 'text-in-zoom_in':
                return <TextZoomInIcon color={color} size={size} />
            case 'text-out-zoom_out':
                return <TextZoomOutIcon color={color} size={size} />
            case 'text-in-typewriter':
                return <TextTypewriterIcon color={color} size={size} />
            case 'text-in-rise':
                return <TextRiseIcon color={color} size={size} />
            case 'text-in-pop':
                return <TextInPopIcon color={color} size={size} />
            case 'text-out-pop':
                return <TextOutPopIcon color={color} size={size} />
            case 'text-in-drop':
            case 'text-out-drop':
                return <TextDropIcon color={color} size={size} />
            case 'text-in-compress':
                return <TextCompressIcon color={color} size={size} />
            case 'text-out-decompress':
                return <TextDecompressIcon color={color} size={size} />
            case 'text-in-bounce':
                return <TextBounceIcon color={color} size={size} />
            case 'text-in-wave':
                return <TextWaveIcon color={color} size={size} />
            case 'text-in-fall':
                return <TextInFallIcon color={color} size={size} />
            case 'text-out-fall':
                return <TextOutFallIcon color={color} size={size} />
            case 'text-in-skid':
                return <TextInSkidIcon color={color} size={size} />
            case 'text-out-skid':
                return <TextOutSkidIcon color={color} size={size} />
            case 'text-in-flipboard':
                return <TextInFlipboardIcon color={color} size={size} />
            case 'text-out-flipboard':
                return <TextOutFlipboardIcon color={color} size={size} />
            case 'text-in-scale':
                return <TextInScaleIcon color={color} size={size} />
            case 'text-out-scale':
                return <TextOutScaleIcon color={color} size={size} />
            case 'text-in-dragonfly':
                return <TextInDragonflyIcon color={color} size={size} />
            case 'text-out-dragonfly':
                return <TextOutDragonflyIcon color={color} size={size} />
            case 'text-in-billboard':
                return <TextInBillboardIcon color={color} size={size} />
            case 'text-out-billboard':
                return <TextOutBillboardIcon color={color} size={size} />
            case 'text-in-roll':
                return <TextInRollIcon color={color} size={size} />
            case 'text-out-roll':
                return <TextOutRollIcon color={color} size={size} />
            case 'text-out-sink':
                return <TextSinkIcon color={color} size={size} />
            case 'animations':
                return <AnimationsIcon color={color} size={size} />
            case 'audio-detach':
                return <AudioDetachIcon color={color} size={size} />
            case 'media-replace':
                return <MediaReplaceIcon color={color} size={size} />
            case 'playhead':
                return <PlayheadIcon color={color} size={size} />
            case 'check-new':
                return <CheckMarkNew color={color} size={size} secondColor={secondColor}/>
            case 'cloud':
                return <CloudIcon color={color} size={size}/>
            case 'microphone':
                return <MicIcon color={color} size={size}/>
            case 'close-new':
                return <CloseIconNew color={color} size={size} />;
            case 'edit-pen':
                return <EditPenIcon color={color} size={size} />;
            case 'dollar-sign':
                return <DollarSign color={color} size={size} secondColor={secondColor}/>;
            case 'timer-icon':
                return <TimerIcon color={color} size={size} secondColor={secondColor}/>;
            case 'magic':
                return <MagicIcon color={color} size={size} />;
            case 'upload':
                return <UploadIcon color={color} size={size} secondColor={secondColor} />;
            case 'translate':
                return <TranslateIcon color={color} size={size} />;
            case 'auto-subtitling':
                return <AutoSubtitlingIcon color={color} size={size} secondColor={secondColor} />
            case 'manual-settings':
                return <ManualSettingsIcon color={color} size={size} secondColor={secondColor} />;

            // editor sidebar
            case 'menu-icon-new':
                return <MenuIconNew color={color} size={size}/>
            case 'settings-icon-new':
                return <SettingsIconNew color={color} size={size}/>
            case 'media-icon-new':
                return <MediaIconNew color={color} size={size}/>
            case 'audio-icon-new':
                return <AudioIconNew color={color} size={size}/>
            case 'subtitles-icon-new':
                return <SubtitlesIconNew color={color} size={size}/>
            case 'elements-icon-new':
                return <ElementsIconNew color={color} size={size}/>
            case 'filters-icon-new':
                return <FiltersIconNew color={color} size={size}/>
            case 'templates-icon-new':
                return <TemplatesIconNew color={color} size={size}/>
            case 'text-icon-new':
                return <TextIconNew color={color} size={size}/>
            case 'transitions-icon-new':
                return <TransitionsIconNew color={color} size={size}/>
            case 'stock-icon-new':
                return <StockIconNew color={color} size={size}/>

            // editor top line
            case 'export-icon-new':
                return <ExportIconNew color={color} size={size}/>
            case 'undo-icon-new':
                return <UndoIconNew color={color} size={size}/>
            case 'redo-icon-new':
                return <RedoIconNew color={color} size={size}/>

            // editor timeline top line
            case 'zoom-out-icon-new':
                return <ZoomOutIconNew color={color} size={size}/>
            case 'zoom-in-icon-new':
                return <ZoomInIconNew color={color} size={size}/>
            case 'sound-wave-icon-new':
                return <SoundWaveIcon color={color} size={size}/>
            case 'sound-on-icon-new':
                return <SoundOnIconNew color={color} size={size}/>
            case 'sound-off-icon-new':
                return <SoundOffIconNew color={color} size={size}/>
            case 'pause-icon-new':
                return <PauseIconNew color={color} size={size}/>
            case 'play-icon-new':
                return <PlayIconNew color={color} size={size}/>
            case 'skip-back-icon-new':
                return <SkipBackIconNew color={color} size={size}/>
            case 'skip-forward-icon-new':
                return <SkipForwardIconNew color={color} size={size}/>
            case 'trashcan-icon-new':
                return <TrashcanIconNew color={color} size={size}/>
            case 'split-icon-new':
                return <SplitIconNew color={color} size={size}/>
            case 'select-all-icon-new':
                return <SelectAllIconNew color={color} size={size}/>
            case 'fit-icon-new':
                return <FitIconNew color={color} size={size}/>
            case 'add-media-icon-new':
                return <AddMediaIconNew color={color} size={size}/>

            default:
                return <></>;
        }
    }
);

export default Icon;
