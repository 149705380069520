import { GlobalSearchTranscriptItem } from 'interfaces/globalSearch';
import {
  AnalysisResultChildren,
  AnalysisResultTimestampItem,
  TransformedAnalysisResultItem,
  TransformedSentence,
} from 'state/modules/metadata';
import { TransformedShot } from 'state/modules/shots';
import { Scene, SelectedMetadataItem } from 'state/modules/videoExplorer';
import { getRandomColor } from 'state/utils/videosUtils';

const average = (...args: Array<number>) => args.reduce((a: number, b: number) => a + b) / args.length;

const scenesSorting = (timestamps: Array<AnalysisResultTimestampItem>) => {
  let to = 0;

  const result = [] as Array<Array<AnalysisResultTimestampItem>>;

  timestamps.forEach((item: AnalysisResultTimestampItem, index: number) => {
    try {
      if (index === 0) {
        return result.push([item]);
      }
      if (+item.timestamp - to <= 1099) {
        return result[result.length - 1].push(item);
      }

      return result.push([item]);
    } finally {
      to = +item.timestamp;
    }
  });

  return result;
};

export const transformTranscriptTimestampsToScenes = (
  item: GlobalSearchTranscriptItem | TransformedSentence,
  color: string,
): Array<Scene> => [
    {
      from: Math.trunc(Number(item.startTime) * 1000),
      to: Math.trunc(Number(item.endTime) * 1000),
      name: item.data,
      color,
      type: item.type,
      id: item.id,
      duration:
        Math.trunc(Number(item.endTime) * 1000)
        - Math.trunc(Number(item.startTime) * 1000),
    },
  ];

export const transformSegmentToScenes = (
  item: TransformedShot,
  color: string,
): Array<Scene> => [
  {
    from: item.startTimestampMillis,
    to: item.endTimestampMillis,
    name: item.name,
    color,
    type: item.type,
    id: item.id,
    duration: item.durationMillis,
  },
];

export const transformTimestampsToScenes = (
  timestamps: Array<AnalysisResultTimestampItem>,
  color: string,
): Array<Scene> => {
  const sortedTimestamps = timestamps.sort(
    (a: AnalysisResultTimestampItem, b: AnalysisResultTimestampItem) => a.timestamp - b.timestamp,
  );

  const gropedBySecond = scenesSorting(sortedTimestamps);

  const scenes = gropedBySecond.map(
    (sceneTimestamps: Array<AnalysisResultTimestampItem>) => {
      const confidenceCollection = sceneTimestamps.map(
        (item: AnalysisResultTimestampItem) => item.confidence,
      );

      const sceneTimestampsLength = sceneTimestamps.length;

      const scaleFactorCollection = sceneTimestamps.map(
        (item: AnalysisResultTimestampItem) => item.scaleFactor,
      );
      const confidenceAverage = average(...confidenceCollection);

      const scaleFactorAverage = average(...scaleFactorCollection);
      const firstItem = sceneTimestamps[0];

      return {
        extra_properties: firstItem.extra_properties,
        id: firstItem.id,
        confidence: confidenceAverage,
        scaleFactor: scaleFactorAverage,
        name: firstItem.name,
        ownerId: firstItem.ownerId,
        parents: firstItem.parents,
        type: firstItem.type,
        videoId: firstItem.videoId,
        from: firstItem.timestamp,
        to: sceneTimestamps[sceneTimestampsLength - 1].timestamp,
        duration:
                    sceneTimestamps[sceneTimestampsLength - 1].timestamp
                    - firstItem.timestamp,
        color,
      };
    },
  );

  return scenes;
};

interface AnalysisObjectData {
    timestamps: Array<AnalysisResultTimestampItem>;
    item: TransformedAnalysisResultItem;
    minScaleFactor: Array<number>;
    minConfidence: Array<number>;
    childrensList: Array<AnalysisResultChildren> | null;
    videoId: string;
}

export const transformAnalysisObject = (
  data: AnalysisObjectData,
): SelectedMetadataItem => {
  const {
    timestamps,
    item,
    minScaleFactor,
    minConfidence,
    childrensList,
    videoId,
  } = data;

  const color = getRandomColor();

  const scenes = transformTimestampsToScenes(timestamps, color);

  if (childrensList) {
    return {
      ...item,
      timestamps,
      color,
      scenes,
      scaleFactorFilter: minScaleFactor,
      confidenceFilter: minConfidence,
      childrensList,
      videoId,
      isVisible: true,
    };
  }
  return {
    ...item,
    timestamps,
    color,
    scenes,
    scaleFactorFilter: minScaleFactor,
    confidenceFilter: minConfidence,
    videoId,
    isVisible: true,
  };
};
