import { createSelector } from 'reselect';

import { State } from 'state/modules';

import { AnalysisStatsItem, UserState } from 'state/modules/user';

export const getUserState = (state: State): UserState => state.user;

export const getUserAnalysisStats = createSelector(
    getUserState,
    (appState) => appState.analyzeStats
);

export const getAnalysisStatsByCategory = (category: string) =>
    createSelector(getUserAnalysisStats, (stats) => {
        let transformedCategory = category;

        switch (category) {
            case 'celebs':
                transformedCategory = 'CELEBS';

                break;
            case 'objects':
                transformedCategory = 'LABELS';

                break;
            case 'moderated-labels':
                transformedCategory = 'MODERATED_LABELS';

                break;
            case 'transcript':
                transformedCategory = 'TRANSCRIBE';

                break;
            case 'shots':
                transformedCategory = 'SHOTS';

                break;
            case 'faces':
                transformedCategory = 'FACES';

                break;
            case 'collections':
                transformedCategory = 'USER_DEFINED';

                break;
            default:
                break;
        }

        return stats.filter((item: AnalysisStatsItem) => {
            if (transformedCategory === item.activityName) {
                return item;
            }
        });
    });

export const getUserGenerationStats = createSelector(
    getUserState,
    (appState) => appState.generationStats
);

export const getUserUploadStats = createSelector(
    getUserState,
    (appState) => appState.uploadStats
);

export const getStatsFilter = createSelector(
    getUserState,
    (appState) => appState.statsFilter
);

export const getAnalysisStatsFilter = createSelector(
    getUserState,
    (appState) => appState.analysisStatsFilter
);

export const getGenerationStatsFilter = createSelector(
    getUserState,
    (appState) => appState.generationStatsFilter
);

export const getUploadStatsFilter = createSelector(
    getUserState,
    (appState) => appState.uploadStatsFilter
);

export const getStatsLoading = createSelector(
    getUserState,
    (appState) => appState.isStatsLoading
);

export const getProjectPreferences = createSelector(
    getUserState,
    (appState) => appState.projectPreferences
);

export const getPreferences = createSelector(
    getUserState,
    (appState) => appState.userPreferences
);

export const getDefaultPreferences = createSelector(
    getUserState,
    (appState) => appState.defaultUserPreferences
);

export const getUserInfo = createSelector(
    getUserState,
    (userState) => userState.userInfo
);

export const getUserId = createSelector(
    getUserState,
    (userState) => userState?.userInfo?.id || ''
);

export const getToggleToSmartSearchAvailableStatus = createSelector(
    getUserState,
    (userState) => userState.isToggleToSmartSearchAvailable
);

export const getFirstLoginStatus = createSelector(
    getUserState,
    (userState) => userState.isFirstLogin
);

// Invite

export const getInvitationCode = createSelector(
    getUserState,
    (userState) => userState.inviteCode
);

export const getSendInviteCodeLoading = createSelector(
    getUserState,
    (userState) => userState.isSendInviteLoading
);

export const getInvitesList = createSelector(
    getUserState,
    (userState) => userState.invites
);

export const getInvitesCount = createSelector(
    getUserState,
    (userState) => userState.invites.length
);

export const getInvitesTotal = createSelector(
    getUserState,
    (userState) => userState.ivitesTotal
);

export const getInvitesLoading = createSelector(
    getUserState,
    (userState) => userState.isInvitesLoading
);


export const getUserRoles = createSelector(
    getUserState,
    (userState) => userState.roles
);

export const getInviteCodeGenerationLoading = createSelector(
    getUserState,
    (userState) => userState.isInviteCodeGenerationLoading
);
