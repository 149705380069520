import { memo, useEffect, useMemo, useState, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import sortBy from "lodash.sortby";
import moment from "moment";

import { NewButton, Switcher } from "components/UI";

import useKeyboardEvent from "hooks/useKeyboardEvent";

import { getCollections, getCollectionsList } from "state/modules/collections";
import {
  getReAnalysisLoading,
  reanalyzeSpecificVideo,
  reanalyzeVideos,
} from "state/modules/analysis";
import { getDefaultPreferences, getPreferences } from "state/modules/user";
import {
  getModalEvent,
  getModalOptionalData,
  ModalEvent,
} from "state/modules/modal";
import {
  getUserUsage,
  getUserUsageData,
  UserUsageAndTotalSubscriptionData,
} from "state/modules/payment";
import { getSelectedVideos } from "state/modules/media";

import { MediaFile, MediaType } from "interfaces/videos";
import { Collection, TransformedCollection } from "interfaces/collections";
import { RekognitionCategory } from "interfaces/analysis";

import { languagesArray } from "utils/language";
import { ANALYSIS_CATEGORIES_TITLES } from "utils/constants";
import {
  calculatePriceInCreditsForAnalysisOperation,
  calculatePriceInCreditsForSubtitlingOperation,
  reducer,
} from "utils/calc";

import { Colors } from "styles";
import styles from "./styles.module.scss";

const colourStyles = {
  control: (selectStyles: CSSProperties) => ({
    ...selectStyles,
    border: "none",
    borderRadius: 12,
    borderWidth: 0,
    background: "transparent",
    padding: 0,
    outline: "none",
    height: "100%",
  }),
  input: (inputStyles: CSSProperties) => ({
    ...inputStyles,
    border: "none",
    padding: "5px 0",
    color: "#707070",
  }),
  singleValue: (singleValueStyles: CSSProperties) => ({
    ...singleValueStyles,
    color: "#707070",
  }),
  placeholder: (placeholderStyles: CSSProperties) => placeholderStyles,
  option: (optionStyles: CSSProperties) => optionStyles,
  menu: (menuStyles: CSSProperties) => ({
    ...menuStyles,
  }),
};

const ReanalisysModal = () => {
  const userPreferences = useSelector(getPreferences);

  const [selectedAnalysisCategories, setSelectedAnalysisCategories] = useState<
    Array<string>
  >([]);
  const [language, setLanguage] = useState<null | string>(null);

  const defaultUserPreferences = useSelector(getDefaultPreferences);
  const collections = useSelector(getCollectionsList);
  const reAnalysisLoading = useSelector(getReAnalysisLoading);
  const optionalData = useSelector(getModalOptionalData);
  const currentUsage = useSelector(
    getUserUsageData
  ) as UserUsageAndTotalSubscriptionData;
  const event = useSelector(getModalEvent);
  const selectedVideos = useSelector(getSelectedVideos) as Array<MediaFile>;
  const transformedCollections = collections
    ? collections.map((collectionItem) => ({
        value: collectionItem.name,
        label: collectionItem.name,
        id: collectionItem.id,
      }))
    : [];

  const collection = transformedCollections.find(
    (item: TransformedCollection) =>
      item.id === userPreferences?.defaultUserCollection
  );

  const [selectedCollection, setSelectedCollection] = useState(
    collection || transformedCollections[0]
  );

  const { defaultCategories } = defaultUserPreferences;

  const languagesArrayWithoutAutodetect = languagesArray.slice(1);
  let sortedLanguagesArray = sortBy(languagesArrayWithoutAutodetect, "label");
  sortedLanguagesArray = [languagesArray[0], ...sortedLanguagesArray];

  const dispatch = useDispatch();

  const isAudio =
    optionalData?.videoData?.mediaInfo?.original?.container?.format ===
      "MPEG Audio" ||
    optionalData?.videoData?.mediaInfo?.original?.container?.format ===
      "Wave" ||
    optionalData?.videoData?.mediaType === MediaType.AUDIO;

  const defaultLanguageValue = useMemo(
    () =>
      sortedLanguagesArray.find(
        (languageItem) =>
          languageItem.value === userPreferences?.defaultTranscriptLanguageCode
      ),
    [sortedLanguagesArray, userPreferences]
  );

  const selectedCollectionImages = useMemo(
    () =>
      collections.find(
        (collection: Collection) => collection?.id === selectedCollection?.id
      )?.images || [],
    [collections, selectedCollection]
  );

  useEffect(() => {
    const categories = [] as string[];

    defaultCategories.forEach((category) => {
      if (category !== RekognitionCategory.AWS_FACE_MATCHES) {
        categories.push(category);
      } else {
        if (selectedCollectionImages.length) {
          categories.push(category);
        }
      }
    });

    setSelectedAnalysisCategories(categories);
  }, [defaultCategories, selectedCollectionImages]);

  const handleReanalyze = () => {
    if (selectedCollection) {
      if (event === ModalEvent.REANALYSIS_SPECIFIC_VIDEO) {
        dispatch(
          reanalyzeSpecificVideo({
            videoId: optionalData?.videoData.id,
            collectionId: selectedCollection.id,
            categories: selectedAnalysisCategories,
            language,
          })
        );
      } else {
        dispatch(
          reanalyzeVideos(
            selectedCollection.id,
            selectedAnalysisCategories,
            language
          )
        );
      }
    }
  };

  useKeyboardEvent("Enter", () => {
    handleReanalyze();
  });

  useEffect(() => {
    dispatch(getCollections());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserUsage());
  }, [dispatch]);

  const currentAnalysisCredits = useMemo(() => {
    let credits = 0;

    if (currentUsage) {
      const { total, usage } = currentUsage;

      const diff =
        total.subscriptionAndAddonCredits - usage.credits.rekognition;

      if (diff < 0) {
        credits = total.userCredits;
      } else {
        credits = diff + total.userCredits;
      }
    }

    return credits;
  }, [currentUsage]);

  const currentSubtitlingCredits = useMemo(() => {
    let credits = 0;

    if (currentUsage) {
      const { total, usage } = currentUsage;

      const diff = total.subscriptionAndAddonCredits - usage.credits.transcribe;

      if (diff < 0) {
        credits = total.userCredits;
      } else {
        credits = diff + total.userCredits;
      }
    }

    return credits;
  }, [currentUsage]);

  const videoDuration = useMemo(() => {
    let duration = 0;

    if (event === ModalEvent.REANALYSIS_SPECIFIC_VIDEO) {
      duration =
        optionalData?.videoData?.mediaInfo?.original?.container?.duration;
    } else {
      const selectedVideosDurationArray = selectedVideos.map(
        (item: MediaFile) => item?.mediaInfo?.original?.container?.duration
      );
      duration = selectedVideosDurationArray.reduce(reducer, 0);
    }

    return duration;
  }, [event, optionalData, selectedVideos]);

  // const operationPrice = useMemo(
  //     () =>
  //         calculatePriceInCreditsForCurrentOperation(
  //             selectedAnalysisCategories,
  //             videoDuration * 1000 || 0
  //         ),
  //     [selectedAnalysisCategories, videoDuration]
  // );

  const analysisOperationPrice = useMemo(() => {
    let price = 0;

    const filteredCategories = selectedAnalysisCategories.filter(
      (category) => category !== RekognitionCategory.AWS_TRANSCRIPT
    );

    price += calculatePriceInCreditsForAnalysisOperation(
      filteredCategories,
      videoDuration * 1000
    );

    return price;
  }, [selectedAnalysisCategories, videoDuration]);

  const subtitlingOperationPrice = useMemo(() => {
    let price = 0;

    const hasTranscriptCategoty = selectedAnalysisCategories.find(
      (category) => category === RekognitionCategory.AWS_TRANSCRIPT
    );

    if (hasTranscriptCategoty) {
      price += calculatePriceInCreditsForSubtitlingOperation(
        videoDuration * 1000
      );
    }

    return price;
  }, [selectedAnalysisCategories, videoDuration]);

  const handleSelectCollection = (newCollection: any) => {
    if (newCollection) {
      setSelectedCollection(newCollection);
    }
  };

  const toggleAutoAnalyzeCategory = (category: string, isSelected: boolean) => {
    let updatedSelectedCategories = [...selectedAnalysisCategories];

    if (isSelected) {
      updatedSelectedCategories = selectedAnalysisCategories.filter(
        (item: string) => item !== category
      );
    } else {
      updatedSelectedCategories.push(category);
    }

    setSelectedAnalysisCategories(updatedSelectedCategories);
  };

  const handleSetLanguage = (language: any) => {
    if (language) {
      setLanguage(language.value === "Autodetect" ? null : language.value);
    }
  };

  const getIconName = (category: string): string => {
    switch (category) {
      case RekognitionCategory.AWS_FACES:
        return "facial-attributes";
      case RekognitionCategory.AWS_CELEBRITIES:
        return "celebrity";
      case RekognitionCategory.AWS_LABELS:
        return "objects-activities";
      case RekognitionCategory.AWS_SHOTS:
        return "shots";
      case RekognitionCategory.AWS_TRANSCRIPT:
        return "transcript";
      case RekognitionCategory.AWS_FACE_MATCHES:
        return "collections";
      case RekognitionCategory.AWS_MODERATED_LABELS:
        return "attention";
      default:
        return "";
    }
  };

  const renderCreditsBlock = () => (
    <div className={styles.ReanalisysModal__creditsContainer}>
      <p className={styles.ReanalisysModal__availableCredits}>
        Available analysis time:{" "}
        {moment
          .duration(currentAnalysisCredits * 6 * 6 * 10000, "milliseconds")
          .format("h[h] m[m] s[s]", {
            useGrouping: false,
            trim: "both",
          })}
      </p>
      <p className={styles.ReanalisysModal__availableCredits}>
        Available subtitling time:{" "}
        {moment
          .duration(currentSubtitlingCredits * 6 * 15 * 10000, "milliseconds")
          .format("h[h] m[m] s[s]", {
            useGrouping: false,
            trim: "both",
          })}
      </p>
      <p>
        Analysis cost:{" "}
        <span
          className={[
            styles.ReanalisysModal__analysisCost,
            analysisOperationPrice > currentAnalysisCredits
              ? styles.ReanalisysModal__analysisCost_overLimit
              : null,
          ].join(" ")}
        >
          {moment
            .duration(analysisOperationPrice * 6 * 6 * 10000, "milliseconds")
            .format("h[h] m[m] s[s]", {
              useGrouping: false,
              trim: "both",
            })}
        </span>{" "}
      </p>
      <p>
        Subtitling cost:{" "}
        <span
          className={[
            styles.ReanalisysModal__analysisCost,
            subtitlingOperationPrice > currentSubtitlingCredits
              ? styles.ReanalisysModal__analysisCost_overLimit
              : null,
          ].join(" ")}
        >
          {moment
            .duration(subtitlingOperationPrice * 6 * 15 * 10000, "milliseconds")
            .format("h[h] m[m] s[s]", {
              useGrouping: false,
              trim: "both",
            })}
        </span>{" "}
      </p>
    </div>
  );

  const renderAnalyzeCategories = () => (
    <div className={styles.ReanalisysModal__analyzeCategories}>
      <p className={styles.ReanalisysModal__settingsTitle}>
        Analysis categories:
      </p>
      <div className={styles.ReanalisysModal__analyzeCategoriesList}>
        {defaultCategories.map((category: string) => {
          const isSelected = selectedAnalysisCategories.includes(category);

          let disabled = false;

          if (isAudio) {
            disabled = category !== RekognitionCategory.AWS_TRANSCRIPT;
          } else {
            disabled =
              category === RekognitionCategory.AWS_FACE_MATCHES &&
              !selectedCollectionImages.length;
          }

          const iconName = getIconName(category);

          return (
            <div
              key={category}
              className={styles.ReanalisysModal__analyzeCategory}
            >
              <Switcher
                checked={isSelected && !disabled}
                rightIcon={iconName}
                labelRight={
                  ANALYSIS_CATEGORIES_TITLES[
                    category as keyof typeof ANALYSIS_CATEGORIES_TITLES
                  ]
                }
                disabled={disabled}
                onChange={() => toggleAutoAnalyzeCategory(category, isSelected)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className={styles.ReanalisysModal}>
      <div className={styles.ReanalisysModal__selectsWrap}>
        <div>
          <p className={styles.ReanalisysModal__selectTitle}>
            Collection name:{" "}
          </p>
          <div className={styles.ReanalisysModal__selectWrap}>
            <Select
              styles={colourStyles as any}
              options={transformedCollections}
              onChange={handleSelectCollection}
              defaultValue={selectedCollection}
              placeholder="Select"
              maxMenuHeight={185}
            />
          </div>
        </div>
        <div>
          <p className={styles.ReanalisysModal__selectTitle}>
            Select language for transcript:
          </p>
          <div className={styles.ReanalisysModal__selectWrap}>
            <Select
              styles={colourStyles as any}
              options={sortedLanguagesArray}
              onChange={handleSetLanguage}
              defaultValue={defaultLanguageValue || sortedLanguagesArray[0]}
              placeholder="Select"
              maxMenuHeight={185}
            />
          </div>
        </div>
      </div>
      {renderAnalyzeCategories()}
      {renderCreditsBlock()}
      <NewButton
        color={Colors.PINK}
        textColor="#ffffff"
        bordered
        borderRadius={5}
        fontWeight="medium"
        width={132}
        height={40}
        onClick={handleReanalyze}
        disabled={
          reAnalysisLoading ||
          analysisOperationPrice > currentAnalysisCredits ||
          subtitlingOperationPrice > currentSubtitlingCredits ||
          !selectedAnalysisCategories.length
        }
        loading={reAnalysisLoading}
      >
        Analyse
      </NewButton>
    </div>
  );
};

export default memo(ReanalisysModal);
