import { Invitation } from 'interfaces/invites';
import { ProjectPreferences, UserPreferences } from 'interfaces/preferences';
import { StatsFilterCategory, UserStatsFilter } from 'interfaces/stats';
import {
    GetInviteCodeAction,
    GetInviteCodeFailAction,
    GetInviteCodeStartAction,
    GetInvitesAction,
    GetInvitesActionPayload,
    GetInvitesFailAction,
    GetInvitesStartAction,
    GetUSerAction,
    SendInviteAction,
    SendInviteFailAction,
    SendInviteStartAction,
    SendInviteSuccessAction,
    SetFirstLoginStatusAction,
    SetInviteCodeAction,
    SetInvitesAction,
    SetInvitesActionPayload,
    SetProjectPreferencesAction,
    SetToggleToSmartSearchAvailableAction,
    SetUserRolesAction,
} from '.';
import {
    UserActionTypes,
    GetStatsAction,
    GetStatsStartAction,
    GetStatsSuccessAction,
    GetStatsFailAction,
    GetUploadStatsAction,
    GetAnalysisStatsAction,
    GetGenerationStatsAction,
    UploadStatsItem,
    AnalysisStatsItem,
    GenerationStatsItem,
    SetUploadStatsAction,
    SetAnalysisStatsAction,
    SetGenerationStatsAction,
    SetUploadStatsFilterAction,
    SetAnalysisStatsFilterAction,
    SetGenerationStatsFilterAction,
    SignOutAction,
    GetUserPreferencesAction,
    SetUserPreferencesAction,
    UpdateUserPreferencesAction,
    User,
    SetUserAction,
    ChangeStatsFilterActionPayload,
    ChangeStatsFilterAction,
    SetStatsFilterAction,
    ResetStatsFilterAction,
} from './types';

export const getStats = (): GetStatsAction => ({
    type: UserActionTypes.GET_STATS,
});

export const getStatsStart = (): GetStatsStartAction => ({
    type: UserActionTypes.GET_STATS_START,
});

export const getStatsFail = (): GetStatsFailAction => ({
    type: UserActionTypes.GET_STATS_FAIL,
});

export const getStatsSuccess = (): GetStatsSuccessAction => ({
    type: UserActionTypes.GET_STATS_SUCCESS,
});

export const getUploadStats = (): GetUploadStatsAction => ({
    type: UserActionTypes.GET_UPLOAD_STATS,
});

export const getAnalysisStats = (): GetAnalysisStatsAction => ({
    type: UserActionTypes.GET_ANALYSIS_STATS,
});

export const getGenerationStats = (): GetGenerationStatsAction => ({
    type: UserActionTypes.GET_GENERATION_STATS,
});

export const setUploadStats = (
    stats: Array<UploadStatsItem>
): SetUploadStatsAction => ({
    type: UserActionTypes.SET_UPLOAD_STATS,
    payload: stats,
});

export const setAnalysisStats = (
    stats: Array<AnalysisStatsItem>
): SetAnalysisStatsAction => ({
    type: UserActionTypes.SET_ANALYSIS_STATS,
    payload: stats,
});

export const setGenerationStats = (
    stats: Array<GenerationStatsItem>
): SetGenerationStatsAction => ({
    type: UserActionTypes.SET_GENERATION_STATS,
    payload: stats,
});

export const setUploadStatsFilter = (
    filter: UserStatsFilter
): SetUploadStatsFilterAction => ({
    type: UserActionTypes.SET_UPLOAD_STATS_FILTER,
    payload: filter,
});

export const setAnalysisStatsFilter = (
    filter: UserStatsFilter
): SetAnalysisStatsFilterAction => ({
    type: UserActionTypes.SET_ANALYSIS_STATS_FILTER,
    payload: filter,
});

export const setGenerationStatsFilter = (
    filter: UserStatsFilter
): SetGenerationStatsFilterAction => ({
    type: UserActionTypes.SET_GENERATION_STATS_FILTER,
    payload: filter,
});

export const signOut = (): SignOutAction => ({
    type: UserActionTypes.SIGN_OUT,
});

export const getUserPreferences = (): GetUserPreferencesAction => ({
    type: UserActionTypes.GET_USER_PREFERENCES,
});

export const setUserPreferences = (
    userPreferences: UserPreferences
): SetUserPreferencesAction => ({
    type: UserActionTypes.SET_USER_PREFERENCES,
    payload: userPreferences,
});

export const updateUserPreferences = (
    newUserPreferences: UserPreferences
): UpdateUserPreferencesAction => ({
    type: UserActionTypes.UPDATE_USER_PREFERENCES,
    payload: newUserPreferences,
});

export const getUser = (): GetUSerAction => ({
    type: UserActionTypes.GET_USER,
});

export const setUser = (user: User | null): SetUserAction => ({
    type: UserActionTypes.SET_USER,
    payload: user,
});

export function changeStatsFilter({
    field,
    value,
    category,
}: ChangeStatsFilterActionPayload): ChangeStatsFilterAction {
    return {
        type: UserActionTypes.CHANGE_STATS_FILTER,
        payload: {
            field,
            value,
            category,
        },
    };
}

export function setStatsFilter(
    statsFilter: UserStatsFilter
): SetStatsFilterAction {
    return {
        type: UserActionTypes.SET_STATS_FILTER,
        payload: statsFilter,
    };
}

export function resetStatsFilter(
    category: StatsFilterCategory
): ResetStatsFilterAction {
    return {
        type: UserActionTypes.RESET_STATS_FILTER,
        payload: {
            category,
        },
    };
}

export function setProjectPreferences(
    payload: ProjectPreferences
): SetProjectPreferencesAction {
    return {
        type: UserActionTypes.SET_PROJECT_PREFERENCES,
        payload,
    };
}

export function setToggleToSmartSearchAvailable(
    payload: boolean
): SetToggleToSmartSearchAvailableAction {
    return {
        type: UserActionTypes.SET_TOGGLE_TO_SMART_SEARCH_AVAILABLE_STATUS,
        payload,
    };
}

export function setFirstLoginStatus(
    payload: boolean
): SetFirstLoginStatusAction {
    return {
        type: UserActionTypes.SET_FIRST_LOGIN_STATUS,
        payload,
    };
}

// Invites

export function getInviteCode(): GetInviteCodeAction {
    return {
        type: UserActionTypes.GET_INVITE_CODE,
    };
}
export function getInviteCodeStart(): GetInviteCodeStartAction {
    return {
        type: UserActionTypes.GET_INVITE_CODE_START,
    };
}
export function getInviteCodeFail(): GetInviteCodeFailAction {
    return {
        type: UserActionTypes.GET_INVITE_CODE_FAIL,
    };
}

export function getInvites(payload: GetInvitesActionPayload): GetInvitesAction {
    return {
        type: UserActionTypes.GET_INVITES,
        payload,
    };
}

export function getInvitesStart(): GetInvitesStartAction {
    return {
        type: UserActionTypes.GET_INVITES_START,
    };
}

export function getInvitesFail(): GetInvitesFailAction {
    return {
        type: UserActionTypes.GET_INVITES_FAIL,
    };
}

export function setInvites(payload: SetInvitesActionPayload): SetInvitesAction {
    return {
        type: UserActionTypes.SET_INVITES,
        payload,
    };
}

export function sendInvite(payload: string): SendInviteAction {
    return {
        type: UserActionTypes.SEND_INVITE,
        payload,
    };
}

export function sendInviteStart(): SendInviteStartAction {
    return {
        type: UserActionTypes.SEND_INVITE_START,
    };
}

export function sendInviteSuccess(): SendInviteSuccessAction {
    return {
        type: UserActionTypes.SEND_INVITE_SUCCESS,
    };
}

export function sendInviteFail(): SendInviteFailAction {
    return {
        type: UserActionTypes.SEND_INVITE_FAIL,
    };
}

export function setInviteCode(payload: string): SetInviteCodeAction {
    return {
        type: UserActionTypes.SET_INVITE_CODE,
        payload,
    };
}

export function setUserRoles(payload: string[]): SetUserRolesAction{
    return {
        type: UserActionTypes.SET_USER_ROLES,
        payload,
    }
}
