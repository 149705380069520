import { IconProps } from './index';

const PlayNewIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill={color}
               stroke="none"
            >
                <path d="M620 5110 c-71 -15 -151 -60 -206 -115 -86 -85 -137 -210 -154 -375 -13 -129 -13 -3991 0 -4120 17 -165 68 -290 154 -375 149 -149 373 -163 619 -39 76 37 3457 1975 3546 2031 31 20 90 70 131 112 159 161 196 340 107 521 -37 76 -152 198 -238 253 -89 56 -3470 1994 -3546 2031 -37 19 -97 44 -133 56 -74 24 -214 34 -280 20z" />
            </g>
        </svg>
    );
};

export default PlayNewIcon;
