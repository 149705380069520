import StripeLogo from 'assets/images/stripe.svg';

import styles from './styles.module.scss';

interface Props {
  color?: string;
}

const StripeLogoFooter = (props: Props): JSX.Element => {
  const { color } = props;

  return (
    <div className={styles.StripeLogoFooter}>
      <p
        className={styles.StripeLogoFooter__title}
      >
        Powered by
      </p>
      <div className={styles.StripeLogoFooter__stripeLink}>
        <img
          alt="logo"
          className={styles.StripeLogoFooter__stripeLogo}
          src={StripeLogo}
        />
      </div>
    </div>
  );
};

export default StripeLogoFooter;
