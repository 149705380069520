import { IconProps } from './index';

const UploadSubtitlesIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 29;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.1667 15.7083L24.1667 21.7499C24.1667 23.0846 23.0847 24.1666 21.75 24.1666L7.24999 24.1666C5.9153 24.1666 4.83332 23.0846 4.83332 21.7499L4.83332 15.7083"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3333 9.66659L14.5 4.83325M14.5 4.83325L9.66667 9.66658M14.5 4.83325L14.5 19.3332"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UploadSubtitlesIcon;
