import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import moment from 'moment';

import { Button, Icon } from 'components/UI';

import {
    getCurrentUserPlan,
    getSubscriptionIssueStatus,
} from 'state/modules/payment/selectors';
import {
    Product,
    setSelectedPlan,
    PlanNameId,
    getUserUsage,
    Price,
    getCustomer, PaymentPeriodType,
} from 'state/modules/payment';
import { signOut } from 'state/modules/user';
import {
    getModalEvent,
    ModalEvent,
} from 'state/modules/modal';

import { LimitationType } from 'interfaces/limitations';

import { getLimitationsInfo } from 'utils/limitations';

import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import styles from './styles.module.scss';
import { PlansList } from './PlansList';
import { PlanDetails } from './PlanDetails';
import { PURPLE_200 } from '../../../styles/colors';

const SeeMoreLinkContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    //margin-top: 1.625rem;
    margin-top: 0.938rem;
`;

const SeeMoreLink = styled.a`
    text-align: left;
    display: inline-flex;
    
    &:hover {
        & div > svg > path {
            stroke: ${PURPLE_200}
        }
    }
`;

const LinkText = styled.span`
    font-family: 'Inter Thin', sans-serif;
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.313rem;
    color: rgba(109, 116, 136);
`;

const IconContainer = styled.div`
    margin-left: 0.438rem;
`;

interface Props {
    closeButton: JSX.Element;
}

const SelectPlanModal = (props: Props): JSX.Element => {
    const { closeButton } = props;

    const dispatch = useDispatch();

    const modalEvent = useSelector(getModalEvent);
    const currentPlan = useSelector(getCurrentUserPlan);

    const hasIssueWithSubscription = useSelector(getSubscriptionIssueStatus);

    const [selectedPlan, setSelectedPlanData] = useState<Product | null>(null);

    const [isPaymentManagementActive, setPaymentManagementActive] =
        useState(false);

    const [selectedBillingPeriod, setSelectedBillingPeriod] =
        useState<Price | null>(null);

    const [selectedPeriodOption, setSelectedPeriodOption] = useState(PaymentPeriodType.YEARLY);

    const paywallType: string = useMemo(() => {
        const paywallTypes: { [key in string]: string } = {
            [ModalEvent.NOT_ENOUGH_CREDITS_FOR_SUBTITLING]:
                'NOT_ENOUGH_CREDITS_FOR_SUBTITLING',
            [ModalEvent.NOT_ENOUGH_CREDITS_FOR_TRANSLATE]:
                'NOT_ENOUGH_CREDITS_FOR_TRANSLATE',
            [ModalEvent.NOT_ENOUGH_CREDITS_FOR_DUBBING]:
                'NOT_ENOUGH_CREDITS_FOR_DUBBING',
            [ModalEvent.ANALYSIS_PRICE_OVER_LIMIT]: 'ANALYSIS_PRICE_OVER_LIMIT',
            [ModalEvent.NOT_ENOUGH_STORAGE]: 'NOT_ENOUGH_STORAGE',
            [ModalEvent.UPLOAD_VIDEO_SIZE_OVER_LIMIT]:
                'UPLOAD_VIDEO_SIZE_OVER_LIMIT',
            [ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT]:
                'UPLOAD_VIDEO_LENGTH_OVER_LIMIT',
            [ModalEvent.PROJECT_EXPORT_LENGTH_OVER_LIMIT]:
                'PROJECT_EXPORT_LENGTH_OVER_LIMIT',
            [ModalEvent.REMOVE_WATERMARK]: 'REMOVE_WATERMARK',
            [ModalEvent.EXPORT_SUBTITLES]: 'EXPORT_SUBTITLES',
            [ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT]:
                'STOCK_MEDIA_ASSETS_OVER_LIMIT',
        };
        return paywallTypes[modalEvent ?? ''] || '';
    }, [modalEvent]);

    const currentPlanName = useMemo(() => {
        if (currentPlan) {
            if (currentPlan?.planNameId === PlanNameId.INITIAL) {
                return 'Free Plan';
            }

            return currentPlan.name;
        }
        return '';
    }, [currentPlan]);

    const renewDate = useMemo(() => {
        if (currentPlan && currentPlan?.planNameId !== PlanNameId.INITIAL) {
            if (currentPlan?.status === 'canceled') {
                return `Downgrades: ${moment
                    .unix(currentPlan?.billingPeriod?.billingEndDate)
                    .format('DD.MM.YYYY')}`;
            }
            return `Renews: ${moment
                .unix(currentPlan?.billingPeriod?.billingEndDate)
                .format('DD.MM.YYYY')}`;
        }
    }, [currentPlan]);

    const isNotModalMainView = useMemo(() => {
        return ((selectedPlan && selectedPlan?.planNameId) || isPaymentManagementActive);
    }, [selectedPlan, isPaymentManagementActive]);

    useEffect(() => {
        dispatch(getUserUsage());
        dispatch(getCustomer());
    }, [dispatch]);

    useEffect(() => {
        if (paywallType) {
            amplitudeAnalytics.paywallAppeared({
                paywall_type: paywallType,
            });
        }
    }, [paywallType]);

    const showPaymentManagement = () => {
        setPaymentManagementActive(true);
    };

    const hidePaymentManagement = () => {
        setPaymentManagementActive(false);
    };

    const handleSelectPlan = useCallback(
        (plan: Product | null) => {
            dispatch(setSelectedPlan(plan));
            setSelectedPlanData(plan);

            if (plan?.id === currentPlan?.id) {
                const notSelectedPrice = (plan?.prices || []).find(
                    (price) => price.id !== currentPlan?.price.id
                );

                if (notSelectedPrice) {
                    setSelectedBillingPeriod(notSelectedPrice);
                }
            } else {
                const price = plan?.prices?.find(
                    item => item.interval === selectedPeriodOption
                );

                if(price) {
                    setSelectedBillingPeriod(price);
                } else {
                    setSelectedBillingPeriod(plan?.prices?.[0] || null);
                }
            }
        },
        [
            dispatch,
            currentPlan,
            selectedPlan,
            selectedPeriodOption
        ]
    );

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            dispatch(signOut());
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error signing out: ', { error });
        }
    };

    const getLimitations = () => {
        const exportDuration = Number(
            currentPlan?.exportVideoDurationTime || 1
        );
        const availableVideoLength = Number(
            currentPlan?.availableVideoLength || 1
        );
        const availableStockMedia = currentPlan?.availableStocksItems || '0';

        switch (modalEvent) {
            case ModalEvent.NOT_ENOUGH_CREDITS_FOR_SUBTITLING:
                return getLimitationsInfo(
                    LimitationType.NOT_ENOUGH_CREDITS_FOR_SUBTITLING
                );
            case ModalEvent.NOT_ENOUGH_CREDITS_FOR_TRANSLATE:
                return getLimitationsInfo(
                    LimitationType.NOT_ENOUGH_CREDITS_FOR_TRANSLATE
                );
            case ModalEvent.NOT_ENOUGH_CREDITS_FOR_DUBBING:
                return getLimitationsInfo(
                    LimitationType.NOT_ENOUGH_CREDITS_FOR_DUBBING
                );
            case ModalEvent.ANALYSIS_PRICE_OVER_LIMIT:
                return getLimitationsInfo(
                    LimitationType.ANALYSIS_PRICE_OVER_LIMIT
                );
            case ModalEvent.NOT_ENOUGH_STORAGE:
                return getLimitationsInfo(LimitationType.NOT_ENOUGH_STORAGE);
            case ModalEvent.UPLOAD_VIDEO_SIZE_OVER_LIMIT:
                return getLimitationsInfo(
                    LimitationType.UPLOAD_VIDEO_SIZE_OVER_LIMIT
                );
            case ModalEvent.UPLOAD_VIDEO_LENGTH_OVER_LIMIT:
                return getLimitationsInfo(
                    LimitationType.UPLOAD_VIDEO_LENGTH_OVER_LIMIT,
                    `${availableVideoLength / 1000 / 60}min`
                );
            case ModalEvent.PROJECT_EXPORT_LENGTH_OVER_LIMIT:
                return getLimitationsInfo(
                    LimitationType.PROJECT_EXPORT_LENGTH_OVER_LIMIT,
                    `${exportDuration / 1000 / 60}min`
                );
            case ModalEvent.REMOVE_WATERMARK:
                return getLimitationsInfo(LimitationType.REMOVE_WATERMARK);
            case ModalEvent.EXPORT_SUBTITLES:
                return getLimitationsInfo(LimitationType.EXPORT_SUBTITLES);
            case ModalEvent.STOCK_MEDIA_ASSETS_OVER_LIMIT:
                return getLimitationsInfo(
                    LimitationType.STOCK_MEDIA_ASSETS_OVER_LIMIT,
                    availableStockMedia
                );
            case ModalEvent.FREE_PLAN_NEW_WORKSPACE_PREVENT:
                return getLimitationsInfo(
                    LimitationType.FREE_PLAN_NEW_WORKSPACE_PREVENT
                );
            default:
                return null;
        }
    };

    const handleChangePaymentPeriodType = () => {
        if(selectedPeriodOption === PaymentPeriodType.MONTHLY) {
            setSelectedPeriodOption(PaymentPeriodType.YEARLY);
        } else {
            setSelectedPeriodOption(PaymentPeriodType.MONTHLY);
        }
    };


    const renderContent = () => {
        if (selectedPlan && selectedPlan?.planNameId) {
            return (
                <PlanDetails
                    onSelectPlan={handleSelectPlan}
                    selectedPlan={selectedPlan}
                    selectedBillingPeriod={selectedBillingPeriod}
                    onSelectBillingPeriod={setSelectedBillingPeriod}
                    paywallType={paywallType}
                    selectedPeriodOption={selectedPeriodOption}
                />
            );
        }

        return (
            <PlansList
                onSelectPlan={handleSelectPlan}
                onShowPaymentManagement={showPaymentManagement}
                onHidePaymentManagement={hidePaymentManagement}
                selectedPeriodOption={selectedPeriodOption}
                currentPlanName={currentPlanName}
                renewInfo={renewDate}
            />
        );
    };

    const renderPaymentPeriodToggle = () => {
        const isMonthSelected = selectedPeriodOption === PaymentPeriodType.MONTHLY;

        return (
            <div className={styles.SelectPlanModal__paymentPeriodContainer}>
                <div
                    className={`${styles.periodOption} ${isMonthSelected ? styles['periodOption--selected'] : ''}`}
                >
                    Monthly
                </div>
                <div
                    className={`${styles.periodToggle} ${isMonthSelected ? styles['periodToggle--left'] : ''}`}
                    onClick={handleChangePaymentPeriodType}
                    data-tip
                    data-for="audio-toggle"
                />
                <div
                    className={`${styles.periodOption} ${!isMonthSelected ? styles['periodOption--selected'] : ''}`}
                >
                    Annual (save up to 25%)
                </div>
            </div>
        );
    };

    const renderTopLine = () => {
        const limitationsInfo = getLimitations();

        if (limitationsInfo && !selectedPlan) {
            return (
                <div className={styles.SelectPlanModal__subTitleText}>
                    <div className={styles.SelectPlanModal__subTextPart}>
                        {limitationsInfo.title}
                    </div>
                    <div className={styles.SelectPlanModal__subTextPart}>
                        {limitationsInfo.message}
                    </div>
                </div>
            );
        }

        if(!isNotModalMainView) {
            return (
                <div className={styles.SelectPlanModal__subTitleText}>
                    <div className={styles.SelectPlanModal__subTextPart}>
                        Upgrade your workspace to access advanced editing features, additional subtitle options, language translations,
                    </div>
                    <div className={styles.SelectPlanModal__subTextPart}>
                        expanded storage capacity, teamwork facilitation tools and beyond.
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderTitle = () => {
        if (isNotModalMainView) {
            return (
                <div className={styles.SelectPlanModal__currentPlanInfo}>
                    <div className={styles.SelectPlanModal__currentPlanName}>
                        {`Current plan: ${currentPlanName || ''}`}
                    </div>
                    <div className={styles.SelectPlanModal__currentPlanDate}>
                        {renewDate}
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.SelectPlanModal__primaryTitle}>
                <div className={styles.SelectPlanModal__currentPlanName}>
                    Upgrade Workspace
                </div>
            </div>
        );
    };

    const renderSeeAllPlanFeaturesLink = () => (
        <SeeMoreLinkContainer>
            <SeeMoreLink
                className={styles.PlanCard__moreInfo}
                href="https://wearenova.ai/pricing"
                target="_blank"
                rel="noreferrer"
            >
                <LinkText>See all plan features</LinkText>
                <IconContainer>
                    <Icon name="outer-link" size={12} color={'rgba(109, 116, 136)'}/>
                </IconContainer>
            </SeeMoreLink>
        </SeeMoreLinkContainer>
    );

    const renderContentContainer = () => {
        return (
            <>
                {renderTitle()}
                {renderTopLine()}
                {
                    !isNotModalMainView && renderPaymentPeriodToggle()
                }
                {renderSeeAllPlanFeaturesLink()}
                {renderContent()}
            </>
        );
    };

    const renderCloseButton = () => {
        if(!hasIssueWithSubscription) {
            return closeButton;
        }

        return null;
    };

    const renderLogoutButton = () => {
        return (
            <Button
                type="button"
                small
                red
                onClick={handleLogout}
                className={styles.SelectPlanModal__logoutButton}
            >
                Logout
            </Button>
        );
    };

    return (
        <div className={styles.SelectPlanModal}>
            <div className={styles.SelectPlanModal__content}>
                {renderContentContainer()}
                {
                    !hasIssueWithSubscription && renderCloseButton()
                }
                {
                    hasIssueWithSubscription && renderLogoutButton()
                }
            </div>
        </div>
    );
};

export default SelectPlanModal;
