import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CreditCard from 'components/CreditCard';
import CardPlaceholder from 'components/CardPlaceholder';

import { NewButton } from 'components/UI';

import {
    addCredits,
    CurrentSubscription,
    Customer,
    CustomerCard,
    getAddCreditsLoading,
    getAdditionalStorageAddon,
    getCreditsAddon,
    getCurrentUserPlan,
    getCustomerInfo,
    getStorageUpdateLoading,
    getUpcomingUserPlan,
    Price,
    Product,
    UpcomingPlan,
    UpdateAddonBody,
    updateStorage,
} from 'state/modules/payment';

import { getModalOptionalData } from 'state/modules/modal';

import { Colors } from 'styles';
import { useStripe } from '@stripe/react-stripe-js';
import PaymentClient from 'services/api/payment';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

interface OrderData {
    value: number;
    addon: Product;
}

const PaymentCardSelectModal = (props: Props): JSX.Element => {
    const [selectedCard, setSelectedCard] = useState('');

    const { closeButton } = props;

    const customer = useSelector(getCustomerInfo) as Customer;
    const orderData = useSelector(getModalOptionalData) as OrderData;
    const creditsAddon = useSelector(getCreditsAddon);
    const storageAddon = useSelector(getAdditionalStorageAddon);

    const isAddCreditsLoading = useSelector(getAddCreditsLoading);
    const isStorageUpdateLoading = useSelector(getStorageUpdateLoading);

    const currentSubscription = useSelector(
        getCurrentUserPlan
    ) as CurrentSubscription;
    const upcomingSubscription = useSelector(
        getUpcomingUserPlan
    ) as UpcomingPlan;

    const dispatch = useDispatch();

    const stripe = useStripe();

    useEffect(() => {
        if (customer?.cards?.length) {
            const preferredCard = customer.cards.find(
                (card: CustomerCard) => card.isPrefer
            ) as CustomerCard;

            if (preferredCard) {
                setSelectedCard(preferredCard.id || '');
            } else {
                setSelectedCard(customer.cards[0].id || '');
            }
        }
    }, [customer]);

    const handleCardClick = (id: string) => {
        setSelectedCard(id);
    };

    const handleCreditsAddonUpdate = async (
        selectedAddon: Product,
        value: number
    ) => {
        try {
            if (selectedAddon) {
                dispatch(
                    addCredits({
                        data: {
                            credits: {
                                productId: selectedAddon.id,
                                priceId: selectedAddon?.prices?.[0]?.id || '',
                                quantity: value,
                                cardId: selectedCard,
                            },
                        },
                    })
                );

                // const res = await PaymentClient.addCredits({
                //     credits: {
                //         productId: selectedAddon.id,
                //         priceId: selectedAddon?.prices?.[0]?.id || '',
                //         quantity: value,
                //         cardId: selectedCard,
                //     },
                // });

                // console.log('handleCreditsAddonUpdate', { res });

                // if (stripe) {
                //     const stripeRes = await stripe.confirmCardPayment(
                //         res.data.clientSecret
                //     );

                //     console.log({ stripeRes });
                // }
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const handleStorageAddonUpdate = (
        selectedAddon: Product,
        value: number
    ) => {
        let updateBody = {
            productId: selectedAddon.id,
            priceId: selectedAddon?.prices?.[0].id || '',
            quantity: value,
            cardId: selectedCard,
        } as UpdateAddonBody;

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            updateBody = {
                ...updateBody,
                upcomingProductId: selectedAddon.id,
                upcomingPriceId: upcomingPlanAddonPriceId,
            };
        }

        dispatch(
            updateStorage({
                data: {
                    addon: updateBody,
                },
            })
        );
    };

    const handleSubmit = () => {
        const { addon, value } = orderData;

        if (addon && addon.prices) {
            if (addon.name === creditsAddon?.name) {
                handleCreditsAddonUpdate(addon, value);
            } else if (addon && addon.name === storageAddon?.name) {
                handleStorageAddonUpdate(addon, value);
            }
        }
    };

    const renderMethods = () => {
        if (customer) {
            return customer.cards.map((card: CustomerCard, index: number) => {
                const hiddenNumber = `************${card.number}`;

                return (
                    <CreditCard
                        key={card.number + index}
                        cvc=""
                        expiry={`${
                            card.expMonth < 10
                                ? `0${card.expMonth}`
                                : card.expMonth
                        }/${card.expYear}`}
                        focused="name"
                        name={customer.name || ''}
                        number={hiddenNumber}
                        preview
                        isPreferred={Boolean(card.isPrefer)}
                        size="medium"
                        onClick={handleCardClick}
                        id={card.id || ''}
                        isSelected={
                            selectedCard.length
                                ? selectedCard === card.id
                                : card.isPrefer
                        }
                        cardType={card.brand || ''}
                        header={
                            card.isPrefer ? (
                                <p
                                    style={{
                                        position: 'absolute',
                                        top: -25,
                                        left: 0,
                                        fontSize: 18,
                                    }}
                                >
                                    Preferred card
                                </p>
                            ) : null
                        }
                    />
                );
            });
        }
    };

    const renderPrice = () => {
        let price = orderData.value;

        if (orderData?.addon?.name === 'Additional Storage') {
            let addonPrice = 0;

            if (currentSubscription?.addon?.currentStorageAmount) {
                addonPrice =
                    currentSubscription.addon?.currentStorageAmount /
                    1000000000;
            }

            price = (orderData.value - addonPrice) / 100;
        }

        return (
            <p className={styles.PaymentCardSelectModal__price}>
                Price: ${price}
            </p>
        );
    };

    return (
        <div className={styles.PaymentCardSelectModal}>
            <p className={styles.PaymentCardSelectModal__title}>
                Select your card:
            </p>
            <div className={styles.PaymentCardSelectModal__cards}>
                {renderMethods()}
                <CardPlaceholder size="medium" navigateBack />
            </div>
            {renderPrice()}
            <div className={styles.PaymentCardSelectModal__saveButtonContainer}>
                <NewButton
                    color={Colors.GREY3}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={handleSubmit}
                    loading={isAddCreditsLoading || isStorageUpdateLoading}
                    disabled={
                        isAddCreditsLoading ||
                        isStorageUpdateLoading ||
                        !customer.cards.length
                    }
                >
                    Pay
                </NewButton>
            </div>
            {closeButton}
        </div>
    );
};

export default PaymentCardSelectModal;
