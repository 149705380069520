import { IconProps } from '../index';

const TrashcanIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 1H17M1 5H25M22.3333 5L21.3983 19.0257C21.258 21.13 21.1879 22.1822 20.7334 22.98C20.3332 23.6824 19.7297 24.247 19.0022 24.5996C18.176 25 17.1215 25 15.0125 25H10.9875C8.87854 25 7.82404 25 6.99778 24.5996C6.27034 24.247 5.66678 23.6824 5.26664 22.98C4.81215 22.1822 4.742 21.13 4.60171 19.0257L3.66667 5M10.3333 11V17.6667M15.6667 11V17.6667"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TrashcanIconNew;


