import {
  AnalysisResultChildren,
  GetTimestampsReqMetadata,
  TransformedAnalysisResultItem,
} from 'state/modules/metadata';

export const prepareChildrensList = (
  item: TransformedAnalysisResultItem,
  childrensList: Array<AnalysisResultChildren>,
  minScaleFactor: Array<number>,
  minConfidence: Array<number>,
): Array<GetTimestampsReqMetadata> => {
  if (childrensList.length) {
    const childrensMetadata = childrensList.map(
      (notSelectedСhildren: AnalysisResultChildren) => ({
        name: notSelectedСhildren.name,
        type: item.labelType,
        scaleFactor: minScaleFactor[0],
        range: [
          {
            range: {
              confidence: {
                gte: minConfidence[0],
              },
            },
          },
        ],
      }),
    );

    return childrensMetadata;
  }
  return [];
};
