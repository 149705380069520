import { IconProps } from '../index';

const TextOutPopIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 25h-.766l-.95-1.932h-2.542L10.299 25H9.5l2.912-6h.218l2.87 6zm-2.045-2.618-.934-1.947-.943 1.947h1.877z"
                fill="url(#qutn11ytja)" />
            <path
                d="M17.357 19.889c.6-.663 1.32-.995 2.159-.995.84 0 1.545.3 2.116.9.579.592.868 1.308.868 2.148 0 .84-.293 1.559-.878 2.159-.586.6-1.295.899-2.127.899-.826 0-1.538-.36-2.138-1.08v.89H16.5v-7.937h.857v3.016zm2.106 4.265c.6 0 1.107-.223 1.524-.667.423-.445.635-.963.635-1.556 0-.6-.205-1.121-.614-1.566a1.999 1.999 0 0 0-1.534-.667c-.614 0-1.13.209-1.545.625-.41.416-.614.942-.614 1.576 0 .628.208 1.161.624 1.598.416.438.924.657 1.524.657z"
                fill="url(#gy8g6zufwb)" />
            <path
                d="M31.973 23.803a4.215 4.215 0 0 1-1.716 1.458 5.21 5.21 0 0 1-2.277.5c-1.276 0-2.344-.42-3.204-1.26-.85-.84-1.276-1.867-1.276-3.082 0-1.215.43-2.253 1.29-3.113.861-.87 1.975-1.306 3.341-1.306.81 0 1.554.177 2.233.532.688.354 1.224.85 1.61 1.488l-.988.622c-.739-.962-1.706-1.442-2.9-1.442-.942 0-1.731.303-2.369.91-.638.598-.957 1.342-.957 2.233 0 .89.314 1.655.942 2.293.627.638 1.422.957 2.384.957 1.154 0 2.126-.486 2.915-1.458l.972.668z"
                fill="url(#vyrp539jrc)" />
            <path d="m23.5 12 2 2M31.5 12l-2 2M27.5 11v2.828M31.5 30l-2-2M23.5 30l2-2M27.5 31v-2.828" stroke="#7C7C7D"
                  strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="qutn11ytja" x1="31.5" y1="22" x2="7.5" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#525253" />
                    <stop offset="1" stopColor="#525253" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="gy8g6zufwb" x1="31.5" y1="22" x2="7.5" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#525253" />
                    <stop offset="1" stopColor="#525253" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="vyrp539jrc" x1="31.5" y1="22" x2="7.5" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#525253" />
                    <stop offset="1" stopColor="#525253" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default TextOutPopIcon;