import { createSelector } from 'reselect';

import { State } from 'state/modules';

import {
    UploadingStatus,
    UploadingMedia,
    UploadingMediaDurationItem,
} from 'interfaces/uploading';

import { UploadingState } from 'state/modules/uploading';
import { reducer } from 'utils/calc';

export const getUploadingState = (state: State): UploadingState =>
    state.uploading;

export const getUploadingVideos = createSelector(
    getUploadingState,
    (state) => state.uploadingVideos
);

export const getUploadingMediaWithAnalyzeCategories = createSelector(
    getUploadingState,
    (state) => state.uploadingMediaWithAnalyzeCategories
);

export const getUploadingMediaDurationItems = createSelector(
    getUploadingState,
    (state) => state.uploadingMediaDurationItems
);

export const getUploadingMediaItemsTotalDuration = createSelector(
    getUploadingMediaDurationItems,
    (items: Array<UploadingMediaDurationItem>) =>
        items
            .map((item: UploadingMediaDurationItem) => item.duration || 0)
            .reduce(reducer, 0)
);

export const getUploadingFaces = createSelector(
    getUploadingState,
    (state) => state.uploadingFaces
);

export const getUploadingStatuses = createSelector(
    getUploadingState,
    (state) => state.uploadingStatuses
);

export const getUploadFromExternalSourceInProgressStatus = createSelector(
    getUploadingState,
    (state) => state.isUploadFromExternalSourceInProgress
);

export const getUploadingStatusById = (id: string) =>
    createSelector(getUploadingStatuses, (statuses) =>
        statuses.find((status: UploadingStatus) => status.id === id)
    );

export const getCollectionId = createSelector(
    getUploadingState,
    (state) => state.collectionId
);

export const getUploadingsInProgress = createSelector(
    [getUploadingVideos, getUploadingStatuses],
    (videos, statuses) =>
        videos.filter((video: UploadingMedia) => {
            const uploadingInProgress = statuses.find(
                (status: UploadingStatus) => status.id === video.id
            );

            if (uploadingInProgress) {
                return video;
            }
        })
);
