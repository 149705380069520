/* eslint-disable prefer-destructuring */
import sortBy from 'lodash.sortby';
import { actionChannel, all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';

import {
    Project,
    ProjectAudio,
    ProjectElement,
    ProjectImage,
    ProjectNavRoute,
    ProjectText,
    ProjectVideo,
    TimelineMedias,
    VideoFilter,
} from 'interfaces/projects';

import * as ProjectsStudioActions from 'state/modules/projectsStudio/actions';
import * as StockMediaActions from 'state/modules/stockMedia/actions';

import { checkEmptyVideos } from 'utils/projects';
import { toFixedWithoutRounding } from 'utils/calc';
import {
    AUDIO_TRACKS_LIMIT,
    mediasToTrackMediasAdapter,
    pasteAudioHasOverlap,
    pasteAudioWithSubtitlesHasOverlap, pasteElementHasOverlap,
    pasteImageHasOverlap, pasteTextHasOverlap,
    pasteVideoHasOverlap,
    secondsToFrameNumber,
    TIMELINE_FRAME_RATE,
    VIDEO_TRACKS_LIMIT,
} from 'utils/timeline';
import { nanoid } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
    getMedias,
    getProjectAudiosList,
    getProjectDetailsInfo,
    getProjectFilters,
    getProjectVideos,
} from '../projects/selectors';
import {
    AddPreparedMediaSourceAction,
    CopyProjectMediaAction,
    FinishProjectVideoAction,
    ProjectsStudioActionTypes,
    RemoveSelectedObjectsAction,
    SelectedProjectObject,
    SelectedProjectObjectType,
    SelectProjectObjectAction,
    SetPojectPlayingAction,
    SetProjectNavRouteAction,
    SetTimelineTimeAction,
    SkipProjectFrameAction,
    SkipProjectTimeAction,
    SkipTimelineCutAction,
    SliderProjectTimelineAction,
    UpdateProjectTimeAction,
} from './types';

import {
    getCopiedMedia,
    getPreparedMediaSources,
    getProjectNavRoute,
    getProjectPlaying,
    getProjectSelectedObject,
    getProjectTimelineTime,
    getTimelineSelectedObjects,
} from './selectors';
import { getProjectTranscriptList, handleSplitSubtitles } from '../projectSubtitles';
import { getProjectItemsMaxZIndex, handleSplitMediaItems } from '../projects';
import { getStockImagesSearchQuery, getStockSongsSearchQuery, getStockVideosSearchQuery } from '../stockMedia';
import { TransformedSentence } from '../metadata';
import { NotificationTypes, showNotification } from '../../../utils/notifications';
import * as ProjectsActions from '../projects/actions';


function* getVideos(): Generator<any, ProjectVideo[]> {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const medias = (yield select(getMedias)) as TimelineMedias;

    const { duration } = projectDetails;

    let filterdVideos = medias.videos.filter(
        (video) => video.startTime < projectDetails.duration
    );

    const lastVideo = filterdVideos[filterdVideos.length - 1];

    if (lastVideo?.endTime > duration) {
        filterdVideos = filterdVideos.map((video) => {
            if (video.id === lastVideo.id) {
                const diff = video.endTime - duration;

                return {
                    ...video,
                    endTime: duration,
                    trimEnd: video.trimEnd - diff,
                };
            }

            return video;
        });
    }

    const videosWithDummy = checkEmptyVideos(
        filterdVideos,
        projectDetails.duration
    );

    return sortBy(videosWithDummy, 'startTime');
}

function* handleUpdateTimelineSliderValue(
    action: SetTimelineTimeAction
): Generator {
    const updatedTime = action.payload;

    const videos = (yield call(getVideos)) as ProjectVideo[];

    const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;

    const numberOfFrame = updatedTime * frameRate;

    yield put(
        ProjectsStudioActions.setProjectTimelineSliderValue(numberOfFrame)
    );
}

function* checkPlayStatusBeforeSplit(): Generator {
    const activeRoute = yield select(getProjectNavRoute);
    const selectedProjectObject = (yield select(
        getProjectSelectedObject
    )) as SelectedProjectObject;
    const timelineTime = (yield select(getProjectTimelineTime)) as number;
    const videos = (yield call(getVideos)) as ProjectVideo[];
    const medias = (yield select(getMedias)) as TimelineMedias;

    if (
        activeRoute !== ProjectNavRoute.SUBTITLES ||
        !selectedProjectObject ||
        selectedProjectObject?.type === SelectedProjectObjectType.VIDEO ||
        selectedProjectObject?.type ===
            SelectedProjectObjectType.AUDIO_WITH_SUBTITLES
    ) {
        const selectedVideoIndex = videos.findIndex(
            (video) =>
                timelineTime >= video.startTime && timelineTime <= video.endTime
        );
        const selectedAudios = (medias?.audios || []).filter(
            (audio) =>
                timelineTime >= audio.startTime && timelineTime <= audio.endTime
        );

        const selectedVideo = videos[selectedVideoIndex];
        const prevVideo = videos[selectedVideoIndex - 1];
        const nextProjectVideo = videos[selectedVideoIndex + 1];
        const isVideosSeparationZone =
            nextProjectVideo?.startTime === timelineTime ||
            prevVideo?.endTime === timelineTime;

        if (selectedVideo) {
            yield put(ProjectsStudioActions.setPojectPlaying(false));
        }

        if (
            selectedVideo &&
            !isVideosSeparationZone &&
            !selectedVideo.isDummy
        ) {
            const selector = `${selectedVideo.mediaSourcesId}`;
            const videoElem = document.getElementById(selector) as any;

            if (videoElem) {
                videoElem.pause();
            }
        }

        if (selectedAudios.length) {
            selectedAudios.forEach((selectedAudio) => {
                const selector = `${selectedAudio.mediaSourcesId}`;
                const elem = document.getElementById(selector) as any;

                if (elem) {
                    elem.pause();
                }
            });
        }
    }
}

function* handleSplitProjectTimeline(): Generator {
    const activeRoute = yield select(getProjectNavRoute);
    const time = (yield select(getProjectTimelineTime)) as number;

    yield call(checkPlayStatusBeforeSplit);

    if (activeRoute !== ProjectNavRoute.SUBTITLES) {
        yield call(handleSplitMediaItems, time);
    }

    if (activeRoute === ProjectNavRoute.SUBTITLES) {
        return yield call(handleSplitSubtitles);
    }
}

function handlePlayNextVideo(mediaSourceId: string): void {
    const videoElem = document.getElementById(mediaSourceId) as any;

    if (videoElem) {
        videoElem.play();
    }
}

function* handleFinishProjectVideo(
    action: FinishProjectVideoAction
): Generator {
    const { id } = action.payload;

    const currentVideoElem = document.getElementById(id) as any;
    const videos = (yield call(getVideos)) as ProjectVideo[];

    const finishedVideoIndex = videos.findIndex((video) => video.id === id);

    if (currentVideoElem) {
        currentVideoElem.pause();
    }

    let nextVideoObj = videos[finishedVideoIndex + 1] as ProjectVideo;

    if (!nextVideoObj) {
        nextVideoObj = videos[0];
    }

    const isPlaying = yield select(getProjectPlaying);
    let isPlayNextAvailable = false;

    if (isPlaying) {
        const pauseAction = (yield take(
            ProjectsStudioActionTypes.SET_PLAYING
        )) as SetPojectPlayingAction;

        isPlayNextAvailable = !pauseAction.payload;
    } else {
        isPlayNextAvailable = true;
    }

    if (isPlayNextAvailable) {
        if (nextVideoObj.isDummy) {
            yield put(
                ProjectsStudioActions.setProjectTimelineTime(
                    // toFixedWithoutRounding(nextVideoObj.startTime, 3)
                    nextVideoObj.startTime + 0.01
                )
            );
            yield put(ProjectsStudioActions.setPojectPlaying(true));
        } else {
            const selector = `${nextVideoObj.mediaSourcesId}`;
            const videoElem = document.getElementById(selector) as any;

            videoElem.currentTime = nextVideoObj.trimStart + 0.01;

            yield put(
                ProjectsStudioActions.setProjectTimelineTime(
                    nextVideoObj.startTime + 0.01
                )
            );

            yield delay(100);

            yield call(handlePlayNextVideo, nextVideoObj.mediaSourcesId);
        }
    }
}

function* handleUpdateProjectTime(action: UpdateProjectTimeAction): Generator {
    const { time, video } = action.payload;
    let updatedTime = 0;
    const videoStartTime = video?.startTime || 0;
    updatedTime = videoStartTime + (time - (video?.trimStart || 0));
    yield put(
        ProjectsStudioActions.setProjectTimelineTime(
            toFixedWithoutRounding(updatedTime < 0 ? 0 : updatedTime, 3)
        )
    );
}

function* handleToggleProjectPlayer(): Generator {
    const isPlaying = (yield select(getProjectPlaying)) as boolean;
    yield put(ProjectsStudioActions.setPojectPlaying(!isPlaying));
}

function* handleAddPreparedMediaSource(id: string): Generator {
    const preparedMediaSources = (yield select(
        getPreparedMediaSources
    )) as string[];

    const updatedList = [...preparedMediaSources, id];

    yield put(ProjectsStudioActions.setPreparedMediaSources(updatedList));
}

export function* hadlePauseCurrentVideo(): Generator {
    const videos = (yield call(getVideos)) as ProjectVideo[];
    const timelineTime = (yield select(getProjectTimelineTime)) as number;

    try {
        const selectedVideo = videos.find(
            (video) =>
                timelineTime >= video.startTime && video.endTime >= timelineTime
        );

        if (selectedVideo) {
            const selector = `${selectedVideo.mediaSourcesId}`;
            const videoElem = document.getElementById(selector) as any;

            if (selectedVideo.isDummy) {
                yield put(ProjectsStudioActions.setPojectPlaying(false));
            } else if (videoElem) {
                videoElem.pause();
            }
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleSlideProjectTimeline(
    action: SliderProjectTimelineAction
): Generator {
    const videos = (yield call(getVideos)) as ProjectVideo[];
    const sliderVal = action.payload;
    const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;
    const timeInSec = sliderVal / frameRate;
    yield put(ProjectsStudioActions.setPojectPlaying(false));

    yield put(
        ProjectsStudioActions.setProjectTimelineTime(
            toFixedWithoutRounding(timeInSec, 3)
        )
    );
}

export function* handleSkipProjectTime(
    action: SkipProjectTimeAction
): Generator {
    const seconds = action.payload;

    const videos = (yield call(getVideos)) as ProjectVideo[];
    const timelineTime = (yield select(getProjectTimelineTime)) as number;
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;
    const projectDuration = projectDetails.duration;

    const sum = timelineTime + seconds;
    let updatedTimelineTime = sum > 0 ? sum : 0;

    if (sum < 0) {
        updatedTimelineTime = 0;
    }

    if (sum > projectDuration) {
        updatedTimelineTime = projectDuration;
    }

    const numberOfFrame = updatedTimelineTime * frameRate;

    yield put(ProjectsStudioActions.slideProjectTimeline(numberOfFrame));
}

export function* handleSkipProjectFrame(
    action: SkipProjectFrameAction
): Generator {
    const frame = action.payload;

    const videos = (yield call(getVideos)) as ProjectVideo[];
    const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;

    const timelineTime = (yield select(getProjectTimelineTime)) as number;
    const currentFrameNumber = secondsToFrameNumber(timelineTime, frameRate);

    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    const projectDuration = projectDetails.duration;
    const totalFrameNumber = secondsToFrameNumber(projectDuration, frameRate);

    const sum = currentFrameNumber + frame;
    let updatedFrameNumber = sum > 0 ? sum : 0;

    if (sum < 0) {
        updatedFrameNumber = 0;
    }

    if (sum > totalFrameNumber) {
        updatedFrameNumber = totalFrameNumber;
    }

    yield put(ProjectsStudioActions.slideProjectTimeline(updatedFrameNumber));
}

export function* handleSkipTimelineCut(
    action: SkipTimelineCutAction
): Generator {
    const isNext = action.payload;

    const videos = (yield call(getVideos)) as ProjectVideo[];
    const timelineTime = (yield select(getProjectTimelineTime)) as number;

    const selectedVideoIndex = videos.findIndex(
        (video) =>
            timelineTime >= video.startTime && timelineTime <= video.endTime
    );
    const selectedVideo = videos[selectedVideoIndex];
    const prevVideo = videos[selectedVideoIndex - 1];
    const nextProjectVideo = videos[selectedVideoIndex + 1];

    const frameRate = videos[0]?.mediaInfo.framerate || TIMELINE_FRAME_RATE;

    let curentFrameNumber = secondsToFrameNumber(timelineTime, frameRate);

    if (selectedVideo) {
        if (isNext) {
            let updatedFrameNumber = curentFrameNumber;

            if (nextProjectVideo) {
                updatedFrameNumber = secondsToFrameNumber(
                    timelineTime === nextProjectVideo.startTime
                        ? nextProjectVideo.endTime
                        : nextProjectVideo.startTime,
                    frameRate
                );
            } else {
                if (timelineTime < selectedVideo.endTime) {
                    updatedFrameNumber = secondsToFrameNumber(
                        selectedVideo.endTime,
                        frameRate
                    );
                }
            }

            yield put(
                ProjectsStudioActions.slideProjectTimeline(updatedFrameNumber)
            );
        } else if (!isNext) {
            let updatedFrameNumber = curentFrameNumber;

            if (prevVideo) {
                updatedFrameNumber = secondsToFrameNumber(
                    prevVideo.endTime,
                    frameRate
                );
            } else {
                updatedFrameNumber = 0;
            }

            yield put(
                ProjectsStudioActions.slideProjectTimeline(updatedFrameNumber)
            );
        }
    }
}

export function* handleChangeProjectNavRoute(
    action: SetProjectNavRouteAction
): Generator {
    const stockVideosSearchQuery = (yield select(
        getStockVideosSearchQuery
    )) as string;
    const stockImagesSearchQuery = (yield select(
        getStockImagesSearchQuery
    )) as string;
    const stockSongsSearchQuery = (yield select(
        getStockSongsSearchQuery
    )) as string;

    const route = action.payload;

    if (
        route !== ProjectNavRoute.STOCK_IMAGES &&
        stockImagesSearchQuery.length
    ) {
        yield put(StockMediaActions.setStockImagesSearchQuery(''));
        yield put(StockMediaActions.getStockImages());
    }

    if (
        route !== ProjectNavRoute.STOCK_VIDEOS &&
        stockVideosSearchQuery.length
    ) {
        yield put(StockMediaActions.setStockVideosSearchQuery(''));
        yield put(StockMediaActions.getStockVideos());
    }

    if (
        route !== ProjectNavRoute.STOCK_AUDIO &&
        stockSongsSearchQuery.length
    ) {
        yield put(StockMediaActions.setStockAudiosSearchQuery(''));
        yield put(StockMediaActions.getStockAudioSongs());
    }
}

export function* handleSelectProjectTimelineObject(
    action: SelectProjectObjectAction
): Generator {
    const timelineSelectedObjects = (yield select(
        getTimelineSelectedObjects
    )) as SelectedProjectObject[];

    const { object, isSelected, isAdd } = action.payload;

    let updatedObjects = [...timelineSelectedObjects];

    if (isSelected) {
        if(timelineSelectedObjects.length > 1) {
            updatedObjects = [object];
        } else {
            updatedObjects = updatedObjects.filter(
                (item) => item.object.id !== object.object.id
            );
        }
    } else {
        if (isAdd) {
            updatedObjects.push(object);
        } else {
            updatedObjects = [object];
        }
    }

    yield put(ProjectsStudioActions.setProjectSelectedObjects(updatedObjects));
}

export function* handleSelectAllTimelineObjects() {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const transcriptList = (yield select(
        getProjectTranscriptList
    )) as TransformedSentence[];
    const projectAudios = (yield select(
        getProjectAudiosList
    )) as ProjectAudio[];
    const projectVideos = (yield select(
        getProjectVideos
    )) as ProjectVideo[];
    const projectNavRoute = (yield select(getProjectNavRoute)) as ProjectNavRoute;

    const selectedObjects = [] as SelectedProjectObject[];

    if (projectNavRoute !== ProjectNavRoute.SUBTITLES) {
        (projectAudios || []).forEach((item) =>
            selectedObjects.push({
                type: SelectedProjectObjectType.AUDIO,
                object: item,
            })

        );
        (projectVideos || []).forEach((item) =>
            selectedObjects.push({
                type: item.isAudio ? SelectedProjectObjectType.AUDIO_WITH_SUBTITLES : SelectedProjectObjectType.VIDEO,
                object: item,
            })
        );
        (projectDetails.elements || []).forEach((item) =>
            selectedObjects.push({
                type: SelectedProjectObjectType.ELEMENT,
                object: item,
            })
        );
        (projectDetails.images || []).forEach((item) =>
            selectedObjects.push({
                type: SelectedProjectObjectType.IMAGE,
                object: item,
            })
        );
        (projectDetails.texts || []).forEach((item) =>
            selectedObjects.push({
                type: SelectedProjectObjectType.TEXT,
                object: item,
            })
        );
    } else {
        transcriptList.forEach((item) =>
            selectedObjects.push({
                type: SelectedProjectObjectType.SENTENCE,
                object: item,
            })
        );
    }

    yield put(ProjectsStudioActions.setProjectSelectedObjects(selectedObjects));
}

export function* handleDeleteSelectedObjects(itemsToRemoveIds: string[]) {
    const selectedObjects = [] as SelectedProjectObject[];

    const filteredSelectedObjects = selectedObjects.filter(
        (item) => !itemsToRemoveIds.includes(item.object.id)
    );

    yield put(
        ProjectsStudioActions.setProjectSelectedObjects(filteredSelectedObjects)
    );
}

export function* handleCopyTimelineMedia(
    action: CopyProjectMediaAction
): Generator {
    const { isCut } = action.payload;

    const timelineSelectedObjects = (yield select(getTimelineSelectedObjects)) as SelectedProjectObject[];
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;

    if(!timelineSelectedObjects.length) {
        showNotification(
            NotificationTypes.warning,
            'No item selected'
        );
    } else {
        const selectedObject = timelineSelectedObjects[0];

        if(timelineSelectedObjects?.length === 1) {
            if(
                selectedObject.type === SelectedProjectObjectType.TRANSITION ||
                selectedObject.type === SelectedProjectObjectType.SENTENCE
            ) {
                // showNotification(
                //     NotificationTypes.warning,
                //     'Cannot copy this item'
                // );
            } else {
                yield put(
                    ProjectsStudioActions.setCopiedMedia(timelineSelectedObjects[0])
                );

                showNotification(
                    NotificationTypes.success,
                    'Copied to clipboard'
                );
            }
        } else if(timelineSelectedObjects?.length > 1) {
            showNotification(
                NotificationTypes.warning,
                'You can copy only one item'
            );
        }

        if(isCut && selectedObject) {
            const updatedProject = {
                ...projectDetails
            } as Project;

            if(
                selectedObject.type === SelectedProjectObjectType.VIDEO ||
                selectedObject.type === SelectedProjectObjectType.AUDIO_WITH_SUBTITLES
            ) {
                const videos = projectDetails.videos;

                const filteredVideos = videos.filter(item =>
                    item.id !== selectedObject.object.id
                );

                updatedProject.videos = filteredVideos;

                yield put(ProjectsActions.setProjectVideos(filteredVideos));
            }

            if(selectedObject.type === SelectedProjectObjectType.AUDIO) {
                const audios = projectDetails.audios;

                const filteredAudios = audios.filter(item =>
                    item.id !== selectedObject.object.id
                );

                updatedProject.audios = filteredAudios;

                yield put(ProjectsActions.setProjectAudios(filteredAudios));
            }

            if(selectedObject.type === SelectedProjectObjectType.IMAGE) {
                const images = projectDetails.images;

                const filteredImages = images.filter(item =>
                    item.id !== selectedObject.object.id
                );

                updatedProject.images = filteredImages;
            }

            if(selectedObject.type === SelectedProjectObjectType.TEXT) {
                const texts = projectDetails.texts;

                const filteredTexts = texts.filter(item =>
                    item.id !== selectedObject.object.id
                );

                updatedProject.texts = filteredTexts;
            }

            if(selectedObject.type === SelectedProjectObjectType.ELEMENT) {
                const elements = projectDetails.elements;

                const filteredElements = elements.filter(item =>
                    item.id !== selectedObject.object.id
                );

                updatedProject.elements = filteredElements;
            }

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                })
            );
        }
    }
}

export function* handlePasteTimelineMedia(): Generator {
    const projectDetails = (yield select(getProjectDetailsInfo)) as Project;
    const timelineTime = (yield select(getProjectTimelineTime)) as number;
    const copiedMedia = (yield select(getCopiedMedia)) as SelectedProjectObject;
    const maxZIndex = (yield getProjectItemsMaxZIndex()) as number;

    try {
        if(copiedMedia) {
            const updatedProject = {
                ...projectDetails
            } as Project;

            if(copiedMedia.type === SelectedProjectObjectType.VIDEO) {
                let oldProjectFilters = (yield select(
                    getProjectFilters
                )) as VideoFilter[];
                let oldProjectVideos = (yield select(
                    getProjectVideos
                )) as ProjectVideo[];
                const filteredVideos = oldProjectVideos.filter(video => !video.isAudio);
                const videoTracks = mediasToTrackMediasAdapter(filteredVideos);
                const trackKeys = Object.keys(videoTracks).sort((a, b) => +b - +a);
                const topKey = +trackKeys[0];

                const updatedProjectVideos = [];
                let updatedProjectFilters = [...oldProjectFilters];

                const currVideo = {
                    ...copiedMedia.object
                } as ProjectVideo;

                if(currVideo) {
                    const hasOverlap = pasteVideoHasOverlap(
                        oldProjectVideos,
                        timelineTime,
                        currVideo,
                    );

                    if(hasOverlap && trackKeys.length >= VIDEO_TRACKS_LIMIT) {
                        showNotification(
                            NotificationTypes.error,
                            'The action will result in an overlap'
                        );
                    } else {
                        const newId = nanoid();

                        updatedProjectVideos.push(...oldProjectVideos, {
                            ...currVideo,
                            id: newId,
                            startTime: timelineTime,
                            endTime: timelineTime + (currVideo.endTime - currVideo.startTime),
                            zIndex: hasOverlap ? (topKey + 1) : currVideo.zIndex,
                        });

                        const filter = updatedProjectFilters.find(filter => filter.videoId === currVideo.id);

                        if(filter) {
                            updatedProjectFilters.push({
                                ...filter,
                                videoId: newId,
                            })
                        }

                        updatedProject.videos = updatedProjectVideos;
                        updatedProject.filters = updatedProjectFilters;

                        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
                    }
                }
            }

            if(copiedMedia.type === SelectedProjectObjectType.AUDIO_WITH_SUBTITLES) {
                let oldProjectVideos = (yield select(
                    getProjectVideos
                )) as ProjectVideo[];
                const filteredAudios = oldProjectVideos.filter(video => video.isAudio);
                const audioTracks = mediasToTrackMediasAdapter(filteredAudios);
                const trackKeys = Object.keys(audioTracks).sort((a, b) => +b - +a);
                const topKey = +trackKeys[0];

                const updatedProjectVideos = [];

                const currAudio = {
                    ...copiedMedia.object
                } as ProjectVideo;

                if(currAudio) {
                    const hasOverlap = pasteAudioWithSubtitlesHasOverlap(
                        filteredAudios,
                        timelineTime,
                        currAudio,
                    );

                    if(hasOverlap  && trackKeys.length >= AUDIO_TRACKS_LIMIT) {
                        showNotification(
                            NotificationTypes.error,
                            'The action will result in an overlap'
                        );
                    } else {
                        const newId = nanoid();

                        updatedProjectVideos.push(...oldProjectVideos, {
                            ...currAudio,
                            id: newId,
                            startTime: timelineTime,
                            endTime: timelineTime + (currAudio.endTime - currAudio.startTime),
                            zIndex: hasOverlap ? (topKey + 1) : currAudio.zIndex,
                        });

                        updatedProject.videos = updatedProjectVideos;

                        yield put(ProjectsActions.setProjectVideos(updatedProjectVideos));
                    }
                }
            }

            if(copiedMedia.type === SelectedProjectObjectType.AUDIO) {
                let oldProjectAudios = (yield select(
                    getProjectAudiosList
                )) as ProjectAudio[];

                const updatedProjectAudios = [];

                const currAudio = {
                    ...copiedMedia.object
                } as ProjectAudio;

                if(currAudio) {
                    const hasOverlap = pasteAudioHasOverlap(
                        oldProjectAudios,
                        timelineTime,
                        currAudio,
                    );

                    updatedProjectAudios.push(...oldProjectAudios, {
                        ...currAudio,
                        id: nanoid(),
                        startTime: timelineTime,
                        endTime: timelineTime + (currAudio.endTime - currAudio.startTime),
                        projectTrackId: hasOverlap ? uuid() : currAudio.projectTrackId,
                    });
                }

                updatedProject.audios = updatedProjectAudios;

                yield put(ProjectsActions.setProjectAudios(updatedProjectAudios));
            }

            if(copiedMedia.type === SelectedProjectObjectType.IMAGE) {
                const images = projectDetails.images;

                const updatedProjectImages = [];

                const currImage = {
                    ...copiedMedia.object
                } as ProjectImage;

                if(currImage) {
                    const hasOverlap = pasteImageHasOverlap(
                        images,
                        timelineTime,
                        currImage,
                    );

                    updatedProjectImages.push(...images, {
                        ...currImage,
                        id: nanoid(),
                        startTime: timelineTime,
                        endTime: timelineTime + (currImage.endTime - currImage.startTime),
                        zIndex: hasOverlap ? (maxZIndex + 1) : currImage.zIndex,
                    });
                }

                updatedProject.images = updatedProjectImages;
            }

            if(copiedMedia.type === SelectedProjectObjectType.TEXT) {
                const texts = projectDetails.texts;

                const updatedProjectTexts = [];

                const currSelectedTexts = copiedMedia.object;

                const currText = {
                    ...copiedMedia.object
                } as ProjectText;


                if(currText) {
                    const hasOverlap = pasteTextHasOverlap(
                        texts,
                        timelineTime,
                        currText,
                    );

                    updatedProjectTexts.push(...texts, {
                        ...currText,
                        id: nanoid(),
                        startTime: timelineTime,
                        endTime: timelineTime + (currText.endTime - currText.startTime),
                        zIndex: hasOverlap ? (maxZIndex + 1) : currText.zIndex,
                    });
                }

                updatedProject.texts = updatedProjectTexts;
            }

            if(copiedMedia.type === SelectedProjectObjectType.ELEMENT) {
                const elements = projectDetails.elements;

                const updatedProjectElements = [];

                const currElement = {
                    ...copiedMedia.object
                } as ProjectElement;

                if(currElement) {
                    const hasOverlap = pasteElementHasOverlap(
                        elements,
                        timelineTime,
                        currElement,
                    );

                    updatedProjectElements.push(...elements, {
                        ...currElement,
                        id: nanoid(),
                        startTime: timelineTime,
                        endTime: timelineTime + (currElement.endTime - currElement.startTime),
                        zIndex: hasOverlap ? (maxZIndex + 1) : currElement.zIndex,
                    });
                }

                updatedProject.elements = updatedProjectElements;
            }

            yield put(
                ProjectsActions.updateProject({
                    project: updatedProject,
                })
            );
        }
    } catch ({error}) {
        showNotification(
            NotificationTypes.error,
            'An error occurred while pasting the media'
        );
    }
}

export function* handleSyncPreparedMediaSources(): Generator {
    const syncProjectMediaSourcesChannel = yield actionChannel(
        ProjectsStudioActionTypes.ADD_PREPARED_MEDIA_SOURCE
    ) as any;

    while (true) {
        const action = (yield take(
            syncProjectMediaSourcesChannel as any
        )) as AddPreparedMediaSourceAction;

        yield call(handleAddPreparedMediaSource, action.payload);
    }
}

export function* handleSyncDeleteSelectedObjects(): Generator {
    const syncSelectedObjectsDeleteChannel = yield actionChannel(
        ProjectsStudioActionTypes.REMOVE_SELECTED_OBJECTS
    ) as any;

    while (true) {
        const action = (yield take(
            syncSelectedObjectsDeleteChannel as any
        )) as RemoveSelectedObjectsAction;

        yield call(handleDeleteSelectedObjects, action.payload);
    }
}

export function* projectsStudioSaga(): Generator {
    yield takeLatest(
        ProjectsStudioActionTypes.SPLIT_PROJECT_TIMELINE,
        handleSplitProjectTimeline
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SET_TIMELINE_TIME,
        handleUpdateTimelineSliderValue
    );
    yield takeLatest(
        ProjectsStudioActionTypes.FINISH_PROJECT_VIDEO,
        handleFinishProjectVideo
    );
    yield takeLatest(
        ProjectsStudioActionTypes.UPDATE_PROJECT_TIME,
        handleUpdateProjectTime
    );
    yield takeLatest(
        ProjectsStudioActionTypes.TOGGLE_PROJECT_PLAYER,
        handleToggleProjectPlayer
    );
    yield takeLatest(
        ProjectsStudioActionTypes.ADD_PREPARED_MEDIA_SOURCE,
        handleSyncPreparedMediaSources
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SKIP_TIME,
        handleSkipProjectTime
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SKIP_FRAME,
        handleSkipProjectFrame
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SKIP_TIMELINE_CUT,
        handleSkipTimelineCut
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SLIDE_PROJECT_TIMELINE,
        handleSlideProjectTimeline
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SET_PROJECT_NAV_ROUTE,
        handleChangeProjectNavRoute
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SELECT_PTOJECT_OBJECT,
        handleSelectProjectTimelineObject
    );
    yield takeLatest(
        ProjectsStudioActionTypes.SELECT_ALL_OBJECTS,
        handleSelectAllTimelineObjects
    );
    yield takeLatest(
        ProjectsStudioActionTypes.COPY_PROJECT_MEDIA,
        handleCopyTimelineMedia
    );
    yield takeLatest(
        ProjectsStudioActionTypes.PASTE_PROJECT_MEDIA,
        handlePasteTimelineMedia
    );

    yield all([
        call(handleSyncPreparedMediaSources),
        call(handleSyncDeleteSelectedObjects),
    ]);
}
