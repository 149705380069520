import { IconProps } from './index';


const OutsideLinkIcon = ({ size, color }: IconProps): JSX.Element => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.63636 1H15M15 1V7.125M15 1L7.78788 8M14.5758 10.1875V13C14.5758 14.1046 13.6803 15 12.5758 15H1V3C1 1.89543 1.89543 1 3 1H5.66667"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    );
};

export default OutsideLinkIcon;