import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { getCurrentWorkspaceInfo } from 'state/modules/workspaces';
import { getModalOptionalData, hideModal } from 'state/modules/modal';
import { getUserInfo } from 'state/modules/user';

import WorkspaceCard from 'components/WorkspaceCard';
import InvoicesList from 'components/InvoicesList';
import { Icon } from 'components/UI';

import WorkspaceSettings from './WorkspaceSettings';
import Collaborators from './Collaborators';
import PlanFeaturesLimits from './PlanFeaturesLimits';
import { Customer, getCustomerInfo } from '../../../state/modules/payment';
import PaymentMethod from './PaymentMethod';
import { NEUTRAL_100, NEUTRAL_400, NEUTRAL_500, NEUTRAL_600, WHITE_PRIMARY } from '../../../styles/colors';

const Container = styled.div`
    width: 50vw;
    height: 60.53vh;
    min-width: 720px;
    min-height: 520px;
    padding: 1.5rem;
    border-radius: 6px;
    z-index: 100;
    background-color: ${WHITE_PRIMARY};
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 16px;
    overflow: hidden;
    transition: color 0.15s ease-in-out 0s,
        background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
        box-shadow 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;
    position: relative;
`;

const ContentWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
`;

const SidePanel = styled.div`
    width: 15.28vw;
    min-width: 220px;
    min-height: 50vh;
    border-right: 1px solid ${NEUTRAL_100};
    padding-right: 1.5rem;
    z-index: 2;
    background: white;
`;

const Title = styled.p`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
    margin-bottom: 1rem;
    color: ${NEUTRAL_600};
`;

const Content = styled.div`
    flex: 3 1 0;
    padding: 0;
    overflow-y: hidden;
    height: 100%;
`;

const Menu = styled.div`
    margin-top: 1rem;
`;

const MenuItemLabel = styled.p``;

const MenuItem = styled.div<{ isActive?: boolean }>`
    font-family: ${({ isActive }) =>
            isActive ? `'Inter SemiBold', sans-serif` : `'Inter', sans-serif`};
    font-weight: ${({ isActive }) =>
            isActive ? 600 : 400};
    
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
    
    padding: 0.75rem 0.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0;

    color: ${({ isActive }) =>
            isActive ? NEUTRAL_600 : NEUTRAL_400};
    background: ${({ isActive }) =>
            isActive ? NEUTRAL_100 : 'transparent'};

    border-radius: 6px;
    cursor: pointer;
    
    min-height: 4.89vh;
    
    &:hover {
        color: ${({ isActive }) =>
            isActive ? NEUTRAL_600 : NEUTRAL_400};
        background: ${({ isActive }) =>
            isActive ? NEUTRAL_100 : NEUTRAL_100};
        text-decoration: none;
    }
`;

const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1rem;
    width: 1rem;
`;

interface IMenuItem {
    label: string;
    value: string;
}

const menuItems: IMenuItem[] = [
    {
        label: 'Settings',
        value: 'settings',
    },
    {
        label: 'Plan limits',
        value: 'plan_limits',
    },
    {
        label: 'Collaborators',
        value: 'collaborators',
    },
    {
        label: 'Payment Method',
        value: 'payment_method',
    },
    {
        label: 'Invoices',
        value: 'invoices',
    },
];

const WorkspaceSettingsModal = () => {
    const dispatch = useDispatch();

    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);
    const user = useSelector(getUserInfo);
    const optionalData = useSelector(getModalOptionalData);
    const customer = useSelector(getCustomerInfo) as Customer;

    const [activeRoute, setActiveRoute] = useState(menuItems[0]);

    const isCurrentUserOwner = useMemo(
        () => currentWorkspace?.userId === user?.id,
        [currentWorkspace, user]
    );

    const filteredRoutes = useMemo(
        () =>
            menuItems.filter((item) => {
                if (
                    !isCurrentUserOwner &&
                    (item.value === 'collaborators' ||
                        item.value === 'invoices')
                ) {
                    return false;
                }
                return item;
            }),
        [isCurrentUserOwner]
    );

    useEffect(() => {
        const defaultRoute = optionalData?.defaultRoute;

        if (defaultRoute) {
            const defaultOption = menuItems.find(item => item.value === defaultRoute);
            if(defaultOption) {
                setActiveRoute(defaultOption);
            } else {
                setActiveRoute(menuItems[0]);
            }
        }
    }, [optionalData]);

    const selectMenuItem = (item: IMenuItem) => {
        setActiveRoute(item);
    };

    const seePlanLimitsInfo = () => {
        const featuresItem =
            menuItems.find(item => item.value === 'plan_limits') ||
            menuItems[1];
        selectMenuItem(featuresItem);
    };

    const closeModal = () => {
        dispatch(hideModal());
    };

    const renderWorkspace = () => {
        return currentWorkspace ? (
            <WorkspaceCard disabled workspace={currentWorkspace} />
        ) : null;
    };

    const renderMenuItem = (menuItem: IMenuItem) => {
        const isActive = menuItem.value === activeRoute.value;

        return (
            <MenuItem
                key={menuItem.label}
                isActive={isActive}
                onClick={() => selectMenuItem(menuItem)}
            >
                <MenuItemLabel>{menuItem.label}</MenuItemLabel>
            </MenuItem>
        );
    };

    const renderMenu = () => {
        return (
            <Menu>
                {filteredRoutes.map((menuItem) => renderMenuItem(menuItem))}
            </Menu>
        );
    };

    const renderContent = () => {
        switch (activeRoute.value) {
            case 'settings':
                return (
                    <WorkspaceSettings
                        isCurrentUserOwner={isCurrentUserOwner}
                        seePlanLimitsInfo={seePlanLimitsInfo}
                    />
                );
            case 'plan_limits':
                return <PlanFeaturesLimits />;
            case 'collaborators':
                return <Collaborators />;
            case 'payment_method':
                if (!customer?.paymentMethod) {
                    return null;
                }
                return <PaymentMethod />;
            case 'invoices':
                return <InvoicesList />;
            default:
                return <></>;
        }
    };

    const renderCloseButton = () => (
        <CloseButton onClick={closeModal}>
            <Icon name="close-new" size={16} color={NEUTRAL_500} />
        </CloseButton>
    );

    return (
        <Container>
            <ContentWrap>
                <SidePanel>
                    <Title>Workspace Settings</Title>
                    {renderWorkspace()}
                    {renderMenu()}
                </SidePanel>
                <Content>{renderContent()}</Content>
            </ContentWrap>
            {renderCloseButton()}
        </Container>
    );
};

export default WorkspaceSettingsModal;
