import { ChangeEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner';

import StockImage from 'components/StockImage';

import { Icon } from 'components/UI';

import {
    getStockImages,
    getStockMediaImages,
    getStockMediaImagesCount,
    getStockMediaImagesLoading,
    getStockMediaImagesTotal,
    getStockImagesSearchQuery,
    setStockImagesSearchQuery,
    getStockImageSelectedCollection,
} from 'state/modules/stockMedia';

import useDebounce from 'hooks/useDebounce';

const Container = styled.div`
    padding-top: 15px;
    height: 100%;
`;

const IconContainer = styled.div`
    padding: 0 12px;
`;

const ImagesContainer = styled.div`
    overflow-y: auto;
    height: calc(100% - 80px);
`;

const InputContainer = styled.div`
    border-radius: 10px;
    border: 0.5px solid rgb(223, 224, 229);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 15px;
    position: relative;
`;

const Input = styled.input`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.8125rem;
    letter-spacing: 0px;
    font-weight: 500;
    background: transparent;
    border-radius: 10px;
    border: none;
    outline: none;
    flex-grow: 1;
    line-height: 1.25;
    height: 100%;
`;

const Grid = styled.div<{ inModal?: boolean }>`
    display: grid;
    gap: 0.5rem;
    grid-auto-rows: 5.9375rem;
    grid-template-columns: ${({ inModal }) =>
        inModal
            ? 'repeat(auto-fill, calc(24.75% - 0.3rem))'
            : 'repeat(auto-fill, calc(50% - 0.3rem))'};
    margin-bottom: 0.5rem;
`;

const LoaderContainer = styled.div`
    display: flex;
    padding: 25px;
`;

const ClearButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 24px;
    height: 24px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: rgb(238, 238, 240);
    border-radius: 100%;

    &:hover {
        path {
            fill: rgb(103, 193, 255);
        }
    }
`;

const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3.125rem;
`;

const EmptyMessage = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.8125rem;
    letter-spacing: 0px;
    font-weight: 500;
    color: rgb(93, 100, 123);
    margin-top: 3.5rem;
    line-height: 1.0625rem;
    text-align: center;
`;

interface Props {
    inModal?: boolean;
}

const StockImagesPanel = ({ inModal }: Props) => {
    const searchQuery = useSelector(getStockImagesSearchQuery);
    const images = useSelector(getStockMediaImages);
    const imagesCount = useSelector(getStockMediaImagesCount);
    const imagesTotal = useSelector(getStockMediaImagesTotal);
    const isLoading = useSelector(getStockMediaImagesLoading);
    const collection = useSelector(getStockImageSelectedCollection);

    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    useEffect(() => {
        dispatch(getStockImages());
    }, [debouncedSearchQuery, dispatch]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        dispatch(setStockImagesSearchQuery(value));
    };

    const handleLoadMore = () => {
        if (!isLoading) {
            dispatch(getStockImages({ isLoadMore: true }));
        }
    };

    const handleClear = () => {
        dispatch(setStockImagesSearchQuery(''));
        dispatch(getStockImages());
    };

    const renderImages = () => {
        return (
            <Grid inModal={inModal}>
                {images.map((item) => (
                    <StockImage key={item.id} item={item} />
                ))}
            </Grid>
        );
    };

    const renderInput = () => {
        return !collection ? (
            <InputContainer>
                <IconContainer>
                    <Icon name="search" size={16} />
                </IconContainer>
                <Input
                    ref={inputRef}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleChange}
                />
                {searchQuery.length ? (
                    <ClearButton onClick={handleClear}>
                        <Icon name="close" size={10} />
                    </ClearButton>
                ) : null}
            </InputContainer>
        ) : null;
    };

    const renderLoader = () => {
        return isLoading ? (
            <LoaderContainer>
                <Loader type="Bars" color="#179bdb" height={60} width={100} />
            </LoaderContainer>
        ) : (
            <></>
        );
    };

    const renderEmptyMessage = () => {
        return !images.length && !isLoading ? (
            <EmptyMessageContainer>
                <Icon name="search" size={64} color="rgb(223, 224, 229)" />
                <EmptyMessage>
                    We couldn't find any Stock Images <br /> matching your
                    search terms
                </EmptyMessage>
            </EmptyMessageContainer>
        ) : null;
    };

    return (
        <Container>
            {renderInput()}
            <ImagesContainer id="stockImages">
                <InfiniteScroll
                    dataLength={imagesCount}
                    next={handleLoadMore}
                    hasMore={imagesCount < imagesTotal && !isLoading}
                    scrollableTarget="stockImages"
                    loader={renderLoader()}
                    style={{
                        paddingBottom: 50,
                    }}
                >
                    {renderImages()}
                    {renderEmptyMessage()}
                    {renderLoader()}
                </InfiniteScroll>
            </ImagesContainer>
        </Container>
    );
};

export default StockImagesPanel;
