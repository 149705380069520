import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CardPlaceholder from 'components/CardPlaceholder';
import CreditCard from 'components/CreditCard';
import { Icon } from 'components/UI';

import { CustomerCard, getCustomerInfo } from 'state/modules/payment';

import StripeLogo from 'assets/images/stripe.svg';

import styles from './styles.module.scss';

const Title = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 35px;
`;

const GoBackButton = styled.button`
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
`;

interface Props {
    showFooter?: boolean;
    centered?: boolean;
    removalAvailable?: boolean;
    selectedCardId?: string;
    onClick: (id: string) => void;
    onCreateClick?: () => void;
    goBack?: () => void;
}

const PaymentMethods = (props: Props): JSX.Element => {
    const {
        showFooter,
        centered,
        removalAvailable,
        selectedCardId,
        onClick,
        onCreateClick,
        goBack,
    } = props;

    const customer = useSelector(getCustomerInfo);

    const handleCardClick = (id: string) => {
        onClick(id);
    };

    const renderMethods = () => {
        if (customer) {
            return customer?.cards?.map((card: CustomerCard, index: number) => {
                const hiddenNumber = `************${card.number}`;

                return (
                    <CreditCard
                        key={card.id}
                        cardType={card.brand || ''}
                        cvc=""
                        expiry={`${
                            card.expMonth < 10
                                ? `0${card.expMonth}`
                                : card.expMonth
                        }/${card.expYear}`}
                        focused="name"
                        id={card.id || ''}
                        name={customer.name || ''}
                        number={hiddenNumber}
                        preview
                        isPreferred={Boolean(card.isPrefer)}
                        isSelected={
                            selectedCardId
                                ? selectedCardId === card.id
                                : card.isPrefer
                        }
                        size="medium"
                        removalAvailable={removalAvailable}
                        header={
                            card.isPrefer ? (
                                <p
                                    style={{
                                        position: 'absolute',
                                        top: -25,
                                        left: 0,
                                        fontSize: 18,
                                    }}
                                >
                                    Preferred card
                                </p>
                            ) : null
                        }
                        onClick={handleCardClick}
                    />
                );
            });
        }
    };

    const renderGoBack = () => {
        if (goBack) {
            return (
                <GoBackButton onClick={goBack}>
                    <Icon name="arrow-left" size={16} />
                </GoBackButton>
            );
        }
    };

    return (
        <div className={styles.PaymentMethods}>
            <Title>
                {renderGoBack()}
                <p className={styles.PaymentMethods__title}>Payment method</p>
            </Title>
            <div
                className={[
                    styles.PaymentMethods__cards,
                    centered ? styles.PaymentMethods__cards_centered : null,
                ].join(' ')}
            >
                {renderMethods()}
                <CardPlaceholder size="medium" onClick={onCreateClick} />
            </div>
            {showFooter ? (
                <div
                    className={[
                        styles.PaymentMethods__footer,
                        centered
                            ? styles.PaymentMethods__footer_centered
                            : null,
                    ].join(' ')}
                >
                    <p>Powered by</p>
                    <div className={styles.PaymentMethods__stripeLink}>
                        <img
                            alt="stripe"
                            className={styles.PaymentMethods__stripeLogo}
                            src={StripeLogo}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default PaymentMethods;
