import { Folder } from 'interfaces/folder';
import {
    UpdateFolderAction,
    FoldersActionTypes,
    GetFoldersAction,
    GetFoldersFailAction,
    GetFoldersStartAction,
    SetFoldersAction,
    CreateFolderAction,
    CreateFolderStartAction,
    CreateFolderFailAction,
    CreateFolderSuccessAction,
    UpdateFolderStartAction,
    UpdateFolderActionPayload,
    UpdateFolderFailAction,
    UpdateFolderSuccessAction,
    DeleteFolderAction,
    DeleteFolderSuccessAction,
    DeleteFolderFailAction,
    DeleteFolderStartAction,
    GetFoldersActionPayload,
    SetFoldersActionPayload,
    SetFoldersHistoryAction,
    SetCurrentFolderAction,
    GetCurrentFolderAction,
    AddFolderToHistoryAction,
    MoveToFolderAction,
    MoveToFolderActionPayload,
    MoveToFolderSuccessAction,
    MoveToFolderFailAction,
    MoveToFolderStartAction,
} from 'state/modules/folders';

// Get folders

export function getFolders(
    payload?: GetFoldersActionPayload
): GetFoldersAction {
    return {
        type: FoldersActionTypes.GET_FOLDERS,
        payload,
    };
}

export function getFoldersStart(): GetFoldersStartAction {
    return {
        type: FoldersActionTypes.GET_FOLDERS_START,
    };
}

export function getFoldersFail(): GetFoldersFailAction {
    return {
        type: FoldersActionTypes.GET_FOLDERS_FAIL,
    };
}

export function setFolders(payload: SetFoldersActionPayload): SetFoldersAction {
    return {
        type: FoldersActionTypes.SET_FOLDERS,
        payload,
    };
}

// Move to folder

export function moveToFolder(
    payload: MoveToFolderActionPayload
): MoveToFolderAction {
    return {
        type: FoldersActionTypes.MOVE_TO_FOLDER,
        payload,
    };
}

export function moveToFolderStart(): MoveToFolderStartAction {
    return {
        type: FoldersActionTypes.MOVE_TO_FOLDER_START,
    };
}

export function moveToFolderFail(): MoveToFolderFailAction {
    return {
        type: FoldersActionTypes.MOVE_TO_FOLDER_FAIL,
    };
}

export function moveToFolderSuccess(): MoveToFolderSuccessAction {
    return {
        type: FoldersActionTypes.MOVE_TO_FOLDER_SUCCESS,
    };
}

// Create folder

export function createFolder(payload: Folder): CreateFolderAction {
    return {
        type: FoldersActionTypes.CREATE_FOLDER,
        payload,
    };
}

export function createFolderStart(): CreateFolderStartAction {
    return {
        type: FoldersActionTypes.CREATE_FOLDER_START,
    };
}

export function createFolderFail(): CreateFolderFailAction {
    return {
        type: FoldersActionTypes.CREATE_FOLDER_FAIL,
    };
}

export function createFolderSuccess(): CreateFolderSuccessAction {
    return {
        type: FoldersActionTypes.CREATE_FOLDER_SUCCESS,
    };
}

// Update folder

export function updateFolder(
    payload: UpdateFolderActionPayload
): UpdateFolderAction {
    return {
        type: FoldersActionTypes.UPDATE_FOLDER,
        payload,
    };
}

export function updateFolderStart(): UpdateFolderStartAction {
    return {
        type: FoldersActionTypes.UPDATE_FOLDER_START,
    };
}

export function updateFolderFail(): UpdateFolderFailAction {
    return {
        type: FoldersActionTypes.UPDATE_FOLDER_FAIL,
    };
}

export function updateFolderSuccess(): UpdateFolderSuccessAction {
    return {
        type: FoldersActionTypes.UPDATE_FOLDER_SUCCESS,
    };
}

// Update folder

export function deleteFolder(payload: string): DeleteFolderAction {
    return {
        type: FoldersActionTypes.DELETE_FOLDER,
        payload,
    };
}

export function deleteFolderStart(): DeleteFolderStartAction {
    return {
        type: FoldersActionTypes.DELETE_FOLDER_START,
    };
}

export function deleteFolderFail(): DeleteFolderFailAction {
    return {
        type: FoldersActionTypes.DELETE_FOLDER_FAIL,
    };
}

export function deleteFolderSuccess(): DeleteFolderSuccessAction {
    return {
        type: FoldersActionTypes.DELETE_FOLDER_SUCCESS,
    };
}

// Folder History

export function setFolderHistory(payload: Folder[]): SetFoldersHistoryAction {
    return {
        type: FoldersActionTypes.SET_FOLDERS_HISTORY,
        payload,
    };
}

export function addFolderToHistory(payload: Folder): AddFolderToHistoryAction {
    return {
        type: FoldersActionTypes.ADD_FOLDER_TO_HISTORY,
        payload,
    };
}

// Current folder

export function setCurrentFolder(
    payload: Folder | null
): SetCurrentFolderAction {
    return {
        type: FoldersActionTypes.SET_CURRENT_FOLDER,
        payload,
    };
}

export function getCurrentFolder(payload: string): GetCurrentFolderAction {
    return {
        type: FoldersActionTypes.GET_CURRENT_FOLDER,
        payload,
    };
}
