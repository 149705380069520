import { AxiosResponse } from 'axios';
import {
    NewRawProject,
    Project,
    ProjectAnalyzeBody,
    ProjectCopingType,
    ProjectSort,
    ProjectStatuses,
    ProjectUpload,
    TemplateStatus,
} from 'interfaces/projects';
import { build, omit } from 'search-params';

import store from 'state/store';

import $http from './instance';

class ProjectsClient {
    public static async getProjects({
        offset,
        sort,
        folder,
        isTemplate,
    }: {
        offset: number;
        sort: ProjectSort;
        folder?: string;
        isTemplate?: boolean;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user?.userInfo?.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            isSelected: true,
            limit: 15,
            offset,
            sortBy: `-${sort}`,
            folder,
            isTemplate,
        });

        if (!folder) {
            query = omit(query, ['folder']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects?${query}`
        );
    }

    public static async getProjectById(
        projectId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            isSelected: true,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}?${query}`
        );
    }

    public static async getProjectByIdHistory(
        projectId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            statuses: `${ProjectStatuses.IN_PROGRESS},${ProjectStatuses.PREPARING},${ProjectStatuses.FAILED},${ProjectStatuses.SUCCEEDED}`,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}?${query}`
        );
    }

    public static async getProjectBySprecificVersion(
        projectId: string,
        version: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            version,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}?${query}`
        );
    }

    public static async getProjectVersions(
        projectId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/versions`
        );
    }

    public static async createProject(
        projectData: NewRawProject
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects`,
            projectData
        );
    }

    public static async updateProject(
        project: Project,
        isUndoOrRedo?: boolean,
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        if(isUndoOrRedo) {
            return $http.put(
                `/user/${userId}/workspace/${workspaceId}/projects/${project.id}/version/${project.version}/?isUndoOrRedo=${isUndoOrRedo}`,
                project
            );
        }

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/projects/${project.id}/version/${project.version}`,
            project
        );
    }

    public static async deleteProject(
        projectId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}`
        );
    }

    public static async generateProject(
        projectId: string,
        version: string,
        userEmail: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/generate`,
            {
                userEmail,
            }
        );
    }

    public static async duplicateProject(
        projectId: string,
        version: string,
        isTemplate?: boolean,
        targetWorkspace?: string,
        copingType?: ProjectCopingType,
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            targetWorkspace,
            copingType,
        });

        if (targetWorkspace) {
            return $http.post(
                `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/duplicate?${query}`
            );
        }

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/duplicate`,
            {isTemplate}
        );
    }

    public static async projectDownload(
        projectId: string,
        version: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/download`
        );
    }

    public static async getProjectsByIds(
        ids: string[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            ids: ids.join(),
            isSelected: true,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects?${query}`
        );
    }

    public static async getElements(): Promise<AxiosResponse> {
        return $http.get(`/system/elements`);
    }

    public static async getSharingUrl(params: {
        projectId: string;
        version: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, version } = params;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/share`
        );
    }

    public static async getProjectAnalyzeStatus(params: {
        projectId: string;
        data: ProjectAnalyzeBody;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, data } = params;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/analyze/status`,
            data
        );
    }

    public static async cancelProjectAnalyze(params: {
        projectId: string;
        data: ProjectAnalyzeBody;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, data } = params;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/analyze/cancel`,
            data
        );
    }

    // Uploads Section
    public static async getProjectUploads(
        projectId: string,
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(`/user/${userId}/workspace/${workspaceId}/projects/${projectId}/uploads`);
    }

    public static async createProjectUpload(params: {
        projectId?: string,
        projectUpload: ProjectUpload,
    }){
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, projectUpload } = params;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/uploads`,
            projectUpload
        );
    }

    public static async findProjectUploadById(params: {
        projectId?: string,
        uploadId: string,
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, uploadId } = params;

        return $http.get(`/user/${userId}/workspace/${workspaceId}/projects/${projectId}/uploads/${uploadId}`);
    }

    public static async updateProjectUpload(params: {
        projectId?: string,
        uploadId: string,
        projectUpload: ProjectUpload,
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, uploadId, projectUpload } = params;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/uploads/${uploadId}`,
            projectUpload
        );
    }

    public static async deleteProjectUpload(params: {
        projectId: string,
        uploadId: string,
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, uploadId } = params;

        return $http.delete(`/user/${userId}/workspace/${workspaceId}/projects/${projectId}/uploads/${uploadId}`);
    }

    public static async submitTemplate(params: {
        projectId: string,
        version: string,
    }): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        const { projectId, version } = params;

        return $http.post(`/editor/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/review`);
    }

    public static async reviewTemplate(params: {
        projectId: string,
        version: string,
        status: TemplateStatus
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { projectId, version, status } = params;

        return $http.post(`/product-owner/${userId}/projects/${projectId}/version/${version}/review`,
            { status }
        );
    }

    public static async publishTemplate(params: {
        projectId: string,
        version: string,
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { projectId, version } = params;

        return $http.post(`/product-owner/${userId}/projects/${projectId}/version/${version}/publish`);
    }

    public static async getPublishedTemplates(params: {
        offset: number,
        sort: string,
        isPublished?: boolean,
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { offset, sort, isPublished = true } = params;

        let query = build({
            isPublished,
            limit: 15,
            offset,
            sortBy: `-${sort}`,
        });

        return $http.get(`/product-owner/${userId}/templates?${query}`);
    }

    public static async getSubmittedTemplates(params: {
        offset: number,
        sort: string
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { offset, sort } = params;

        let query = build({
            limit: 15,
            offset,
            sortBy: `-${sort}`,
        });

        return $http.get(`/product-owner/${userId}/project-templates?${query}`);
    }

    public static async deleteTemplate(params: {
        templateId: string,
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { templateId } = params;

        return $http.delete(`/product-owner/${userId}/templates/${templateId}`);
    }

    public static async updateTemplate(params: {
        templateId: string,
        templateData: Project,
    }): Promise<AxiosResponse> {
        const { user } = store.getState();

        const userId = user.userInfo.id;

        const { templateId, templateData } = params;

        return $http.put(`/product-owner/${userId}/templates/${templateId}`,
            { ...templateData }
        );
    }

    public static async getUsableTemplates(params: {
        offset: number,
        sort: string
    }): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;

        const workspaceId = workspaces.currentWorkspace.id;


        const { offset, sort } = params;

        let query = build({
            limit: 15,
            offset,
            // sortBy: `-${sort}`,
        });

        return $http.get(`/user/${userId}/workspace/${workspaceId}/projects/templates?${query}`);
    }
}

export default ProjectsClient;
