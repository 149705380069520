import Redux from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";

import sagaMiddleware from "state/middlewares/saga";
import { rootReducer } from "state/modules";

const middlewares: Array<Redux.Middleware> = [sagaMiddleware];

//  const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middlewares)),
// );
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(...middlewares),
});

export default store;
