import { createSelector } from 'reselect';
import { State } from 'state/modules';
import { MetadataState } from './types';

export const getMetadataState = (state: State): MetadataState => state.metadata;

export const getCelebs = createSelector(
    getMetadataState,
    (state) => state.celebs
);

export const getCelebsCount = createSelector(
    getCelebs,
    (celebs) => celebs.length
);

export const getCelebsTotal = createSelector(
    getMetadataState,
    (state) => state.celebsTotal
);

export const getCelebsLoading = createSelector(
    getMetadataState,
    (state) => state.isCelebsLoading
);

export const getModeratedLabels = createSelector(
    getMetadataState,
    (state) => state.moderatedLabels
);

export const getModeratedLabelsCount = createSelector(
    getModeratedLabels,
    (moderatedLabels) => moderatedLabels.length
);

export const getModeratedLabelsTotal = createSelector(
    getMetadataState,
    (state) => state.moderatedLabelsTotal
);

export const getModeratedLabelsLoading = createSelector(
    getMetadataState,
    (state) => state.isModeratedLabelsLoading
);

export const getFacesTotal = createSelector(getMetadataState, () => 0);

export const getFacialAttributes = createSelector(
    getMetadataState,
    (state) => state.facialAttributes
);

export const getFacialAttributesCount = createSelector(
    getFacialAttributes,
    (facialAttributes) => facialAttributes.length
);

export const getFacialAttributesTotal = createSelector(
    getMetadataState,
    (state) => state.facialAttributesTotal
);

export const getFacialAttributesLoading = createSelector(
    getMetadataState,
    (state) => state.isFacialAttributesLoading
);

export const getUserDefined = createSelector(
    getMetadataState,
    (state) => state.userDefined
);

export const getUserDefinedCount = createSelector(
    getUserDefined,
    (userDefined) => (userDefined ? userDefined.length : 0)
);

export const getUserDefinedTotal = createSelector(
    getMetadataState,
    (state) => state.userDefinedTotal
);

export const getUserDefinedLoading = createSelector(
    getMetadataState,
    (state) => state.isUserDefinedLoading
);

export const getSegmetsTotal = createSelector(
    getMetadataState,
    (state) => state.shotsTotal
);

export const getLabels = createSelector(
    getMetadataState,
    (state) => state.labels
);

export const getLabelsCount = createSelector(
    getLabels,
    (labels) => labels.length
);

export const getLabelsTotal = createSelector(
    getMetadataState,
    (state) => state.labelsTotal
);

export const getLabelsLoading = createSelector(
    getMetadataState,
    (state) => state.isObjectsLoading
);

export const getTranscriptList = createSelector(
    getMetadataState,
    (state) => state.transcript
);

export const getSentencesWithSearchedQuery = createSelector(
    getMetadataState,
    (state) => state.sentencesWithSearchedQuery
);

export const getSentencesWithSearchedQueryLength = createSelector(
    getSentencesWithSearchedQuery,
    (sentencesWithSearchedQuery) => sentencesWithSearchedQuery.length
);

export const getTranscriptLoading = createSelector(
    getMetadataState,
    (state) => state.isTranscriptLoading
);

export const getTranscriptTotal = createSelector(
    getMetadataState,
    (metadata) => metadata.transcriptTotal
);

export const getTranscriptCount = createSelector(
    getTranscriptList,
    (transcript) => transcript.length
);

export const getAfterKey = createSelector(
    getMetadataState,
    (state) => state.afterKey
);

export const getMetadataCount = createSelector(
    [getLabels, getUserDefined, getCelebs, getModeratedLabels],
    (labels, faceMatches, celebs, moderatedLabels) =>
        labels.length +
        faceMatches.length +
        celebs.length +
        moderatedLabels.length
);

export const getMetadataTotal = createSelector(
    getMetadataState,
    (state) => state.metadataTotal
);

export const getMetadataLoading = createSelector(
    getMetadataState,
    (state) => state.isMetadataLoading
);

export const getAutocompleteItemsList = createSelector(
    getMetadataState,
    (state) => state.autocompleteItems
);

export const getAutocompleteItemsLength = createSelector(
    getAutocompleteItemsList,
    (autocompleteItems) => autocompleteItems.length
);

export const getSearchLoading = createSelector(
    getMetadataState,
    (state) => state.isSearchLoading
);
