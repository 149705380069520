import { IconProps } from '../index';

const TextInPopIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.51 25.083h-1.086l-1.349-2.924h-3.604l-1.337 2.924H11L15.13 16h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662zM22.633 21.227c.443-.49.974-.735 1.594-.735.62 0 1.14.221 1.562.664.427.438.64.966.64 1.586a2.2 2.2 0 0 1-.648 1.594 2.114 2.114 0 0 1-1.57.664c-.61 0-1.135-.266-1.578-.797v.656H22V19h.633v2.227zm1.555 3.148c.442 0 .817-.164 1.125-.492.312-.328.468-.711.468-1.149 0-.442-.15-.828-.453-1.156a1.476 1.476 0 0 0-1.133-.492 1.55 1.55 0 0 0-1.14.46c-.302.308-.453.696-.453 1.165 0 .463.153.857.46 1.18.308.323.683.484 1.125.484zM31.45 23.992a2.168 2.168 0 0 1-.883.75 2.68 2.68 0 0 1-1.172.258c-.656 0-1.205-.216-1.648-.648a2.144 2.144 0 0 1-.656-1.586c0-.625.221-1.16.664-1.602.443-.448 1.015-.672 1.719-.672.416 0 .8.091 1.148.274.354.182.63.437.828.765l-.508.32c-.38-.494-.877-.742-1.492-.742-.484 0-.89.157-1.218.47-.329.306-.493.69-.493 1.148 0 .458.162.851.485 1.18.323.327.731.491 1.226.491.594 0 1.094-.25 1.5-.75l.5.344z"
                fill="#525253" />
            <path d="m11 12 2 2M19 12l-2 2M15 11v2.828M19 30l-2-2M11 30l2-2M15 31v-2.828" stroke="#7C7C7D"
                  strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TextInPopIcon;