import { AnalysisResultsCategory } from 'interfaces/analysis';
import { Trailer } from 'interfaces/generation';
import {
  GlobalSearchTranscriptItem,
  TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';
import { MediaFile, VideoFilter } from 'interfaces/videos';
import {
  TransformedAnalysisResultItem,
  TransformedSentence,
} from 'state/modules/metadata';
import { TransformedShot } from 'state/modules/shots';
import {
  VideoExplorerActionTypes,
  SelectedMetadataItem,
  SelectedAnalysisResultItem,
  ChangeSearchQueryAction,
  SetSearchQueryAction,
  ChangeScaleFactorAction,
  ChangeConfidenceAction,
  SetCategoryAction,
  SelectAnalysisItemAction,
  UnselectAnalysisItemAction,
  SetSelectedItemsAction,
  VideoExplorerInitAction,
  SetTimestampsLoadingAction,
  SelectAllAnalysisItemsAction,
  UnselectAllAnalysisItemsAction,
  GetTimestampsByGlobalFiltersActionPayload,
  GetTimestampsByGlobalFiltersAction,
  GetItemTimestampsByConfidenceValueAction,
  GetItemTimestampsByScaleFactorValueAction,
  ChangeItemConfidenceValueAction,
  ChangeItemScaleFactorValueAction,
  SelectTranscriptItemAction,
  SelectShotItemAction,
  RemoveSceneActionPayload,
  RemoveSceneAction,
  SetSelectedTrailerAction,
  ToggleSelectedItemVisibilityAction,
  ToggleCategoryVisibilityAction,
  EditSentenceAction,
  EditSentenceActionPayload,
  DeleteSentenceActionPayload,
  DeleteSentenceAction,
  SetSentencesToUpdateAction,
  SetSentencesToDeleteAction,
  ChangeAnalyzerVideoFilterAction,
  ChangeAnalyzerVideoFilterActionPayload,
  SetAnalyzerVideoFilterAction,
  GetFilteredAnalyzerVideoListAction,
  GetFilteredAnalyzerVideoListFailAction,
  GetFilteredAnalyzerVideoListStartAction,
  GetFilteredAnalyzerVideoListSuccessAction,
  ResetAnalyzerVideoFilterAction,
  UpdateAnalyzerVideosStatusesAction,
  UpdateAnalyzerVideosStatusesSuccessAction,
  ChangeMediaLanguageCodeAction,
  SetMediaLanguageCodeAction,
  SetOriginalTimecodeAction,
  SetOriginalTimecodeStatusAction,
  OriginalTimecodeData,
} from './types';

export function changeSearchQuery(query: string): ChangeSearchQueryAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_SEARCH_QUERY,
    payload: query,
  };
}

export function setSearchQuery(query: string): SetSearchQueryAction {
  return {
    type: VideoExplorerActionTypes.SET_SEARCH_QUERY,
    payload: query,
  };
}

export function changeScaleFactor(
  value: Array<number>,
): ChangeScaleFactorAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_SCALE_FACTOR,
    payload: value,
  };
}

export function changeConfidence(value: Array<number>): ChangeConfidenceAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_CONFIDENCE,
    payload: value,
  };
}

export function setCategory(
  category: AnalysisResultsCategory,
): SetCategoryAction {
  return {
    type: VideoExplorerActionTypes.SET_CATEGORY,
    payload: category,
  };
}

export function selectAnalysisItem(
  item:
        | TransformedAnalysisResultItem
        | TransformedGlobalSearchResultItem
        | SelectedAnalysisResultItem,
  isGlobalSearchResult?: boolean,
): SelectAnalysisItemAction {
  return {
    type: VideoExplorerActionTypes.SELECT_ANALYSIS_ITEM,
    payload: { item, isGlobalSearchResult },
  };
}

export function unselectAnalysisItem(
  item: SelectedMetadataItem,
): UnselectAnalysisItemAction {
  return {
    type: VideoExplorerActionTypes.UNSELECT_ANALYSIS_ITEM,
    payload: item,
  };
}

export function setSelectedItems(
  items: Array<SelectedMetadataItem>,
): SetSelectedItemsAction {
  return {
    type: VideoExplorerActionTypes.SET_SELECTED_ITEMS,
    payload: items,
  };
}

export function videoExplorerInit(
  videoId: string | null,
): VideoExplorerInitAction {
  return {
    type: VideoExplorerActionTypes.VIDEO_EXPLORER_INIT,
    payload: {
      videoId,
    },
  };
}

export function setTimestampsLoading(
  isLoading: boolean,
): SetTimestampsLoadingAction {
  return {
    type: VideoExplorerActionTypes.SET_TIMESTAMPS_LOADING,
    payload: isLoading,
  };
}

export function selectAllAnalysisItems(): SelectAllAnalysisItemsAction {
  return {
    type: VideoExplorerActionTypes.SELECT_ALL_ANALYSIS_ITEMS,
  };
}

export function unselectAllAnalysisItems(): UnselectAllAnalysisItemsAction {
  return {
    type: VideoExplorerActionTypes.UNSELECT_ALL_ANALYSIS_ITEMS,
  };
}

export function getTimestampsByGlobalFilters({
  confidence,
  scaleFactor,
}: GetTimestampsByGlobalFiltersActionPayload): GetTimestampsByGlobalFiltersAction {
  return {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_GLOBAL_FILTER,
    payload: {
      confidence,
      scaleFactor,
    },
  };
}

export function getItemTimestampsByConfidenceValue(
  value: Array<number>,
  item: SelectedAnalysisResultItem,
): GetItemTimestampsByConfidenceValueAction {
  return {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_CONFIDENCE_VALUE,
    payload: {
      value,
      item,
    },
  };
}

export function getItemTimestampsByScaleFactorValue(
  value: Array<number>,
  item: SelectedAnalysisResultItem,
): GetItemTimestampsByScaleFactorValueAction {
  return {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_SCALE_FACTOR_VALUE,
    payload: {
      value,
      item,
    },
  };
}

export function changeItemConfidenceValue(
  value: Array<number>,
  item: SelectedAnalysisResultItem,
): ChangeItemConfidenceValueAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_ITEM_CONFIDENCE_VALUE,
    payload: {
      value,
      item,
    },
  };
}

export function changeItemScaleFactorValue(
  value: Array<number>,
  item: SelectedAnalysisResultItem,
): ChangeItemScaleFactorValueAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_ITEM_SCALE_FACTOR_VALUE,
    payload: {
      value,
      item,
    },
  };
}

export function selectTranscriptItem(
  item: TransformedSentence | GlobalSearchTranscriptItem,
): SelectTranscriptItemAction {
  return {
    type: VideoExplorerActionTypes.SELECT_TRANSCRIPT_ITEM,
    payload: item,
  };
}

export function selectShotItem(item: TransformedShot): SelectShotItemAction {
  return {
    type: VideoExplorerActionTypes.SELECT_SHOT_ITEM,
    payload: item,
  };
}

export function removeScene(item: RemoveSceneActionPayload): RemoveSceneAction {
  return {
    type: VideoExplorerActionTypes.REMOVE_SCENE,
    payload: item,
  };
}

export function setSelectedTrailer(
  tailer: Trailer | null,
): SetSelectedTrailerAction {
  return {
    type: VideoExplorerActionTypes.SET_SELECTED_TRAILER,
    payload: tailer,
  };
}

export function toggleSelectedItemVisibility(
  id: string,
): ToggleSelectedItemVisibilityAction {
  return {
    type: VideoExplorerActionTypes.TOGGLE_SELECTED_ITEM_VISIBILITY,
    payload: { id },
  };
}

export function toggleCategoryVisibility(
  category: AnalysisResultsCategory,
  isAllItemsVisible: boolean,
): ToggleCategoryVisibilityAction {
  return {
    type: VideoExplorerActionTypes.TOGGLE_SELECTED_ITEMS_CATEGORY_VISIBILITY,
    payload: { category, isAllItemsVisible },
  };
}

export function editSentence({
  videoId,
  id,
  data,
}: EditSentenceActionPayload): EditSentenceAction {
  return {
    type: VideoExplorerActionTypes.EDIT_SENTENCE,
    payload: { videoId, id, data },
  };
}

export function deleteSentence({
  videoId,
  id,
}: DeleteSentenceActionPayload): DeleteSentenceAction {
  return {
    type: VideoExplorerActionTypes.DELETE_SENTENCE,
    payload: { videoId, id },
  };
}

export function setSentencesToUpdate(
  data: Array<string>,
): SetSentencesToUpdateAction {
  return {
    type: VideoExplorerActionTypes.SET_SENTENCES_TO_UPDATE,
    payload: data,
  };
}

export function setSentencesToDelete(
  data: Array<string>,
): SetSentencesToDeleteAction {
  return {
    type: VideoExplorerActionTypes.SET_SENTENCES_TO_DELETE,
    payload: data,
  };
}

export function changeAnalyzerVideoFilter({
  field,
  value,
}: ChangeAnalyzerVideoFilterActionPayload): ChangeAnalyzerVideoFilterAction {
  return {
    type: VideoExplorerActionTypes.CHANGE_VIDEO_FILTER,
    payload: {
      field,
      value,
    },
  };
}

export function setAnalyzerVideoFilter(
  videoFilter: VideoFilter,
): SetAnalyzerVideoFilterAction {
  return {
    type: VideoExplorerActionTypes.SET_VIDEO_FILTER,
    payload: videoFilter,
  };
}

export const getFilteredAnalyzerVideoList = (
  isLoadMore?: boolean,
): GetFilteredAnalyzerVideoListAction => ({
  type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST,
  payload: {
    isLoadMore,
  },
});

export const getFilteredAnalyzerVideoListStart = (): GetFilteredAnalyzerVideoListStartAction => ({
  type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_START,
});

export const getFilteredAnalyzerVideoListFail = (
  error: any,
): GetFilteredAnalyzerVideoListFailAction => ({
  type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_FAIL,
  payload: error,
});

export const getFilteredAnalyzerVideoListSuccess = (
  videos: Array<MediaFile>,
  total: number,
): GetFilteredAnalyzerVideoListSuccessAction => ({
  type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_SUCCESS,
  payload: { videos, total },
});

export const resetAnalyzerVideoFilter = (): ResetAnalyzerVideoFilterAction => ({
  type: VideoExplorerActionTypes.RESET_ANALYZER_VIDEO_FILTER,
});

/// // update statuses

export const updateAnalyzerVideosStatuses = (): UpdateAnalyzerVideosStatusesAction => ({
  type: VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES,
});

export const updateAnalyzerVideosStatusesSuccess = (
  data: Array<MediaFile>,
): UpdateAnalyzerVideosStatusesSuccessAction => ({
  type: VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES_SUCCESS,
  payload: data,
});

export const changeMediaLanguageCode = (
  payload: string,
): ChangeMediaLanguageCodeAction => ({
  type: VideoExplorerActionTypes.CHANGE_MEDIA_LANGUAGE_CODE,
  payload,
});

export const setMediaLanguageCode = (
  payload: string,
): SetMediaLanguageCodeAction => ({
  type: VideoExplorerActionTypes.SET_MEDIA_LANGUAGE_CODE,
  payload,
});

export const setOriginalTimecode = (
  payload: OriginalTimecodeData,
): SetOriginalTimecodeAction => ({
  type: VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE,
  payload,
});

export const setOriginalTimecodeStatus = (
  payload: boolean,
): SetOriginalTimecodeStatusAction => ({
  type: VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE_STATUS,
  payload,
});
