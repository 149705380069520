import { IconProps } from './index';

const ListIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 5H3V6.55556H4.5V5ZM21 5H7.5V6.55556H21V5ZM3 11.2222H4.5V12.7778H3V11.2222ZM21 11.2222H7.5V12.7778H21V11.2222ZM3 17.4444H4.5V19H3V17.4444ZM21 17.4444H7.5V19H21V17.4444Z"
                fill={color}
            />
        </svg>
    );
};

export default ListIcon;
