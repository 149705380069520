import { Observable } from 'rxjs';
import { addTempMediaFilePreview } from 'state/modules/projects';
import store from 'state/store';

/* eslint-disable no-void */
const isSafari =
    typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// interface SmarTime {
//   start: number;
//   middle: number;
//   end: number;
// }

// const getSmartTime = (time: number, duration: number) => {
//   if (duration === void 0) {
//     duration = 0;
//   }
//   const smartTimes = {
//     start: 0,
//     middle: duration / 2,
//     end: duration,
//   } as any;

//   return smartTimes[time];
// };

// const loadVideo = (time: number, path: string) => new Promise((resolve, reject) => {
//   const video = document.createElement('video');
//   video.crossOrigin = 'anonymous';
//   video.preload = 'metadata';
//   video.src = path;
//   video.muted = true;
//   if (time === 0) {
//     video.play();
//   } else {
//     if (typeof time === 'number') {
//       video.currentTime = time;
//     } else if (typeof time === 'string') {
//       const { duration } = video;
//       const smartTime = getSmartTime(time, duration);

//       video.currentTime = smartTime;
//     }
//     // if (isSafari) {
//     //   // Safari needs to always play the video
//     //   video.play();
//     // }
//   }
//   // loadedmetadata, loadeddata, play, playing
//   video.addEventListener('timeupdate', function timeupdateHandler() {
//     video.removeEventListener('timeupdate', timeupdateHandler);
//     video.pause();

//     video.remove();
//     video.srcObject = null;

//     resolve(video);
//   });
//   video.addEventListener('error', () => {
//     reject(new Error('failed to load video'));
//   });
// });

// export const takeSnapshot = async (time: number, path: string) => {
//   const video = (await loadVideo(time, path)) as HTMLVideoElement;

//   // console.log('///////////////')
//   // console.log({video})
//   // console.log('videoHeight', video.videoHeight)
//   // console.log('videoWidth', video.videoWidth)

//   // const h = video.videoHeight;
//   // const w = video.videoWidth;
//   const h = 600;
//   const w = 360;

//   const canvas = document.createElement('canvas') as HTMLCanvasElement;
//   // if (!video?.videoWidth && !video?.videoHeight) {
//   //   // throw new Error('error retrieving video dimensions');
//   //   console.log('error retrieving video dimensions')
//   // }
//   canvas.width = w;
//   canvas.height = h;
//   const context = canvas.getContext('2d');
//   if (!context) {
//     // throw new Error('error creating canvas context');
//     console.log('error creating canvas context')

//     return null
//   }

//   context.drawImage(video, 0, 0, canvas.width, canvas.height);

//   return canvas;
// };

// export const getLocalVideosPreviewImages = async (timestamps: number[], path: string) => []

// const getPreparedCanvas= () => {
//   const canvas = document.createElement('canvas')
//   const context = canvas.getContext('2d')
//   canvas.style.display = 'none'
//   document.body.appendChild(canvas)

//   if (!context) {
//     console.log(`Couldn't retrieve context 2d`)
//     // obs.complete()
//     // rej()
//     return null
//   }

//   return {
//     canvas,
//     context,
//   }
// }

// const getPreparedVideo = (path: string) => {
//   const video = document.createElement('video');
//   const source = document.createElement('source');
//   video.style.display = 'none'
//   source.setAttribute('src', path)
//   video.setAttribute('crossorigin', 'anonymous')
//   video.appendChild(source)
//   document.body.appendChild(video)

//   return video;
// }

// const drawImageFromVideoToCanvas = ({
//   canvas,
//   context,
//   video
// }: any) => new Promise((res) => {
//   context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

//   canvas.toBlob((blob: any) => {
//     if (!blob) {
//       return
//     }

//     const imageUrl = URL.createObjectURL(blob);

//     console.log({imageUrl})

//     return res(URL.createObjectURL(blob))
//       // new File([blob], options.filename || 'test', {
//       //   type: options.fileType || 'image/png',
//       // })
//     // )

//   }, 'image/png');
// });

// export const prepareCanvasAndPlayer = (urlRef: string): any => new Promise((res) => {
//   const video = getPreparedVideo(urlRef);
//   const preparedCanvas = getPreparedCanvas();

//   if (preparedCanvas) {
//     const { canvas, context } = preparedCanvas;

//     return {
//       canvas,
//       context,
//       video
//     }
//   }
// });

const imageFromFrame = (path: string, timestamp: number, id: string) =>
    new Promise((res, rej) => {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        const source = document.createElement('source');
        const context = canvas.getContext('2d');
        const urlRef = path;

        video.style.display = 'none';
        canvas.style.display = 'none';

        source.setAttribute('src', urlRef);
        video.setAttribute('crossorigin', 'anonymous');

        video.appendChild(source);
        document.body.appendChild(canvas);
        document.body.appendChild(video);

        if (!context) {
            // throwError(`Couldn't retrieve context 2d`)
            console.log("Couldn't retrieve context 2d");
            rej();
            // return
        }

        video.currentTime = timestamp;
        video.load();

        video.addEventListener('loadedmetadata', () => {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        });

        video.addEventListener('error', (error) => {
            console.log({ error });
        });

        video.addEventListener('loadeddata', () => {
            if (context && video) {
                context.drawImage(
                    video,
                    0,
                    0,
                    video.videoWidth,
                    video.videoHeight
                );
            }

            canvas.toBlob((blob) => {
                if (!blob) {
                    return;
                }

                video.remove();
                canvas.remove();

                return res({
                    id,
                    image: blob,
                    timestamp,
                });
            }, 'image/png');
        });
    });

// export const getLocalVideosPreviewImages = async (timestamps: number[], path: string) => {
export const getLocalVideosPreviewImages = async (preview: any) => {
    const { path, timestamps, id } = preview;

    if (!isSafari) {
        return Promise.all(
            timestamps.map((timestamp: number) =>
                imageFromFrame(path, timestamp, id)
            )
        );
    }

    return [];
};

export const getStockVideosPreviewImages = async (preview: any) => {
    const { path, timestamps, id } = preview;

    if (!isSafari) {
        const images = await Promise.all(
            timestamps.map((timestamp: number) =>
                imageFromFrame(path, timestamp, id)
            )
        );

        return {
            id,
            images,
        };
    }

    return null;
};
