import { useMemo } from 'react';
import styled from 'styled-components';

import { User } from 'state/modules/user';
import { NEUTRAL_300, NEUTRAL_500, PINK_100, WHITE_PRIMARY } from '../../styles/colors';

const Container = styled.div`
    display: flex;
    overflow: hidden;
`;

const Avatar = styled.div<{ avatarSize?: number }>`
    width: ${({ avatarSize }) => (avatarSize ? `${avatarSize}rem` : '2rem')};
    height: ${({ avatarSize }) => (avatarSize ? `${avatarSize}rem` : '32px')};
    background: ${PINK_100};
    border-radius: 50%;;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const AvatarPlaceholder = styled.span`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.375rem;
    text-align: center;
    color: ${WHITE_PRIMARY};
    text-transform: uppercase;
`;

const Name = styled.p`
    font-family: 'Inter Semibold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${NEUTRAL_500};
    width: 100%;
    line-break: anywhere;
`;

const Email = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0;
    color: ${NEUTRAL_300};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
`;

const Info = styled.div`
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
`;

interface Props {
    user: User;
    avatarSize?: number;
}

export const UserProfileCard = ({ user, avatarSize }: Props) => {
    const name = useMemo(() => {
        if (user) {
            if (user?.attributes?.name) {
                return user?.attributes?.name;
            }
            return user?.username?.split('.')?.[0];
        }

        return '';
    }, [user]);

    const namePlaceholder = useMemo(() => {
        return name[0];
    }, [name]);

    return (
        <Container>
            <Avatar avatarSize={avatarSize}>
                <AvatarPlaceholder>{namePlaceholder}</AvatarPlaceholder>
            </Avatar>
            <Info>
                <Name>{name}</Name>
                <Email>{user?.attributes?.email}</Email>
            </Info>
        </Container>
    );
};
