import {
  TemplateBody,
  TemplatesActionTypes,
  GetTemplatesAction,
  GetTemplatesStartAction,
  GetTemplatesFailAction,
  GetTemplatesSuccessAction,
  GetTemplateByIdAction,
  GetTemplateDetailsStartAction,
  GetTemplateDetailsFailAction,
  GetTemplateDetailsSuccessAction,
  UpdateTemplateDetailsStartAction,
  UpdateTemplateDetailsFailAction,
  UpdateTemplateDetailsSuccessAction,
  CreateTemplateAction,
  CreateTemplateFailAction,
  CreateTemplateStartAction,
  CreateTemplateSuccessAction,
  DeleteTemplatesAction,
  DeleteTemplateAction,
  DeleteTemplateStartAction,
  DeleteTemplateSuccessAction,
  DeleteTemplateFailAction,
  SetTemplatesIdsForDeleteAction,
  UnselectTemplateAction,
  SelectTemplateAction,
  SetSelectTemplatesAction,
} from './types';

export function getTemplates(): GetTemplatesAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATES,
  };
}

export function getTemplatesSuccess(
  templates: Array<TemplateBody>,
  total: number,
): GetTemplatesSuccessAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATES_SUCCESS,
    payload: {
      templates,
      total,
    },
  };
}

export function getTemplatesStart(): GetTemplatesStartAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATES_START,
  };
}

export function getTemplatesFail(error: any): GetTemplatesFailAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATES_FAIL,
    payload: error,
  };
}

export function getTemplateById(id: string): GetTemplateByIdAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS,
    payload: id,
  };
}

export function getTemplateDetailsStart(): GetTemplateDetailsStartAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_START,
  };
}

export function getTemplateDetailsFail(
  error: any,
): GetTemplateDetailsFailAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_FAIL,
    payload: error,
  };
}

export function getTemplateDetailsSuccess(
  templateDetails: TemplateBody,
): GetTemplateDetailsSuccessAction {
  return {
    type: TemplatesActionTypes.GET_TEMPLATE_DETAILS_SUCCESS,
    payload: templateDetails,
  };
}

export function updateTemplateDetailsStart(): UpdateTemplateDetailsStartAction {
  return {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_START,
  };
}

export function updateTemplateDetailsFail(
  error: any,
): UpdateTemplateDetailsFailAction {
  return {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_FAIL,
    payload: error,
  };
}

export function updateTemplateDetailsSuccess(): UpdateTemplateDetailsSuccessAction {
  return {
    type: TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_SUCCESS,
  };
}

export function createTemplate(data: TemplateBody): CreateTemplateAction {
  return {
    type: TemplatesActionTypes.CREATE_TEMPLATE,
    payload: data,
  };
}

export function createTemplateStart(): CreateTemplateStartAction {
  return {
    type: TemplatesActionTypes.CREATE_TEMPLATE_START,
  };
}

export function createTemplateFail(error: any): CreateTemplateFailAction {
  return {
    type: TemplatesActionTypes.CREATE_TEMPLATE_FAIL,
    payload: error,
  };
}

export function createTemplateSuccess(): CreateTemplateSuccessAction {
  return {
    type: TemplatesActionTypes.CREATE_TEMPLATE_SUCCESS,
  };
}

export function deleteTemplates(): DeleteTemplatesAction {
  return {
    type: TemplatesActionTypes.DELETE_TEMPLATES,
  };
}

export function deleteTemplate(id: string): DeleteTemplateAction {
  return {
    type: TemplatesActionTypes.DELETE_TEMPLATE,
    payload: id,
  };
}

export function deleteTemplateStart(): DeleteTemplateStartAction {
  return {
    type: TemplatesActionTypes.DELETE_TEMPLATE_START,
  };
}

export function deleteTemplateFail(error: any): DeleteTemplateFailAction {
  return {
    type: TemplatesActionTypes.DELETE_TEMPLATE_FAIL,
    payload: error,
  };
}

export function deleteTemplateSuccess(): DeleteTemplateSuccessAction {
  return {
    type: TemplatesActionTypes.DELETE_TEMPLATE_SUCCESS,
  };
}

export function setTemplatesIdsForDelete(
  ids: Array<string>,
): SetTemplatesIdsForDeleteAction {
  return {
    type: TemplatesActionTypes.SET_TEMPLATES_IDS_FOR_DELETE,
    payload: ids,
  };
}

export function unselectTemplate(id: string): UnselectTemplateAction {
  return {
    type: TemplatesActionTypes.UNSELECT_TEMPLATE,
    payload: id,
  };
}

export function selectTemplate(id: string): SelectTemplateAction {
  return {
    type: TemplatesActionTypes.SELECT_TEMPLATE,
    payload: id,
  };
}

export function setSelectTemplates(
  ids: Array<string>,
): SetSelectTemplatesAction {
  return {
    type: TemplatesActionTypes.SET_SELECTED_TEMPLATES,
    payload: ids,
  };
}
