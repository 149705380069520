import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";

import { v4 as uuid } from "uuid";

import DropdownAutocomplete from "components/DropdownAutocomplete";

import { Button, Icon, Input } from "components/UI";

import {
  getCollections,
  getCollectionsList,
  getUploadingStatus,
  uploadCollectionItems,
} from "state/modules/collections";
import {
  getCollectionId,
  getUploadingFaces,
  setCollectionId,
  setUploadingFaces,
} from "state/modules/uploading";

import { UploadingFace } from "interfaces/uploading";
import {
  Collection,
  CollectionItemToUpload,
  TransformedCollection,
} from "interfaces/collections";

import { validate } from "utils";
import { ValidationType, ValidatorType } from "utils/validate";

import styles from "./styles.module.scss";

interface Props {
  closeModal: () => void;
  title: JSX.Element;
  closeButton: JSX.Element;
}

const UploadFacesModal = (props: Props): JSX.Element => {
  const { closeButton, title } = props;

  const [showError, setShowError] = useState(false);
  const collections = useSelector(getCollectionsList);
  const isUploading = useSelector(getUploadingStatus);
  const uploadingFaces = useSelector(
    getUploadingFaces
  ) as Array<CollectionItemToUpload>;
  const collectionId = useSelector(getCollectionId);

  const dispatch = useDispatch();

  const transformedCollections = collections.map(
    (collectionItem: Collection) => ({
      value: collectionItem.name,
      label: collectionItem.name,
      id: collectionItem.id,
    })
  );

  const [selectedCollection, setSelectedCollection] = useState(
    transformedCollections[0]
  );

  useEffect(() => {
    dispatch(getCollections());
  }, []);

  useEffect(() => {
    if (collections.length) {
      setSelectedCollection(transformedCollections[0]);

      dispatch(setCollectionId(transformedCollections[0].id));
    }
  }, [collections]);

  const handleDeleteFace = (faceID: string) => {
    const filteredFaces = uploadingFaces.filter(
      (item: UploadingFace) => item.id !== faceID
    );

    dispatch(setUploadingFaces(filteredFaces));
  };

  const handleOnDrop = async (files: Array<File>) => {
    const changedFaces = files.map((file: File) => ({
      file,
      title: "",
      id: uuid(),
    }));

    if (uploadingFaces.length > 0) {
      dispatch(setUploadingFaces([...changedFaces, ...uploadingFaces]));
    } else {
      dispatch(setUploadingFaces(changedFaces));
    }
  };

  const handleConfirmUploadFaces = () => {
    const facesWithoutName = uploadingFaces.find(
      (item: CollectionItemToUpload) => !item.title.length
    );
    if (facesWithoutName) {
      setShowError(true);
    } else {
      setShowError(false);
      try {
        dispatch(uploadCollectionItems(uploadingFaces, collectionId));
        dispatch(setUploadingFaces([]));
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const renderDropzoneContent = () => (
    <>
      <Icon name="upload-cloud" />
      <p className={styles.UploadModal__dropzoneDescription}>
        <span className={styles.UploadModal__dropzoneDescription_accent}>
          Click to Upload
        </span>
        or Drag and Drop
      </p>
    </>
  );

  const renderButtonText = () => "Confirm";

  const handleFocus = () => {
    setShowError(false);
  };

  const handleSelectCollection = (collection: TransformedCollection) => {
    setSelectedCollection(collection);

    dispatch(setCollectionId(collection.id));
  };

  const handleChangeFaceTitle = (
    event: ChangeEvent<HTMLInputElement>,
    faceId?: string
  ) => {
    setShowError(false);

    const changedFaces = uploadingFaces.map((item: CollectionItemToUpload) => {
      if (item.id === faceId) {
        if (!event.target.value) {
          return {
            ...item,
            title: event.target.value,
          };
        }
        const validationErrors = validate(
          event.target.value,
          [ValidatorType.FaceTitle],
          ValidationType.OnInput
        );

        if (!validationErrors.length) {
          return {
            ...item,
            title: event.target.value,
          };
        }
      }

      return item;
    });

    dispatch(setUploadingFaces(changedFaces));
  };

  const renderFaces = () =>
    uploadingFaces &&
    uploadingFaces.length > 0 &&
    uploadingFaces.map((face: UploadingFace) => (
      <div key={face.id} className={styles.UploadModal__face}>
        <img
          src={URL.createObjectURL(face.file)}
          className={styles.UploadModal__faceImg}
          alt="face"
        />
        <div className={styles.UploadModal__inputWrap}>
          <Input
            onFocus={handleFocus}
            field={face.id}
            label="Who is this ?"
            type="text"
            value={face.title}
            onChange={handleChangeFaceTitle}
            placeholder="Enter your password"
            error={showError && !face.title.length ? "Required" : ""}
            pattern="[a-zA-Z0-9_.-]+"
          />
        </div>
        <button
          className={styles.UploadModal__deleteBtn}
          onClick={() => handleDeleteFace(face.id)}
        >
          <Icon name="trash" />
        </button>
      </div>
    ));

  return (
    <div className={styles.UploadModal}>
      <div className={styles.UploadModal__topLine}>
        {title}
        {closeButton}
      </div>
      <div className={styles.UploadModal__content}>
        <Dropzone
          onDrop={handleOnDrop}
          multiple
          accept={{
            "image/png": [".png"],
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className={styles.UploadModal__dropzoneWrap}>
              <div
                {...getRootProps()}
                className={styles.UploadModal__dropzoneRoot}
              >
                <input
                  {...getInputProps()}
                  className={styles.UploadModal__dropzone}
                />
                {renderDropzoneContent()}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <p className={styles.UploadModal__imgDropZoneDescription}>
        Supported Image Formats: JPEG, PNG
      </p>
      {uploadingFaces && uploadingFaces.length > 0 && (
        <p className={styles.UploadModal__description}>
          Please provide a name for this face ('[a-zA-Z0-9]')
        </p>
      )}

      {renderFaces()}
      {uploadingFaces.length ? (
        <div className={styles.UploadModal__collection}>
          <div className={styles.UploadModal__autocompleteWrap}>
            <DropdownAutocomplete
              onSelect={handleSelectCollection}
              blue
              options={transformedCollections}
              value={selectedCollection}
              rightLabel="Collection"
              type="text"
            />
          </div>
        </div>
      ) : null}
      <div className={styles.UploadModal__buttonWrap}>
        <Button
          type="button"
          blue
          large
          onClick={handleConfirmUploadFaces}
          disabled={isUploading || !uploadingFaces.length}
          loading={isUploading}
        >
          {renderButtonText()}
        </Button>
      </div>
    </div>
  );
};

export default UploadFacesModal;
