/* eslint-disable @typescript-eslint/ban-types */
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';

import { Icon, NewButton } from 'components/UI';

import { MediaFile, MediaType } from 'interfaces/videos';

import VideosClient from 'services/api/videos';

import {
    getProjectUpdateLoading,
    addVideosFromLibraryToProject,
    getProjectDetailsInfo,
} from 'state/modules/projects';
import { GetVideoListByFilterReqParams } from 'state/modules/media';

import { Colors } from 'styles';
import styles from './styles.module.scss';
import { getModalOptionalData } from '../../../state/modules/modal';

const Container = styled.div`
    width: 450px;
    max-height: 95vh;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
    overflow-y: auto;
    position: relative;
    padding: 50px 30px;
`;

const ThumbnailContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: #919191;
    height: 100%;
    aspect-ratio: 16 / 9;
    margin-right: 14px;
`;

const Thumbnail = styled.img`
    border-radius: 6px;
    width: auto;
    height: 100%;
    max-width: 100%;
`;

const CustomOptionContainer = styled.div<{isDisabled: boolean}>`
    height: 42px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
    

    &:hover {
        background: rgba(100, 93, 246, 0.5) !important;
    }
`;

const selectStyles = {
    control: (selectStyles: CSSProperties) => ({
        ...selectStyles,
        border: '0.5px solid rgb(223, 224, 229) !important',
        boxShadow: 'none !important',
        borderRadius: 12,

        borderWidth: 0,
        background: 'transparent',
        padding: 0,
        outline: 'none',
        height: '100%',
    }),
    input: (inputStyles: CSSProperties) => ({
        ...inputStyles,
        border: 'none',
        padding: '5px 0',
        color: '#707070',
    }),
    singleValue: (singleValueStyles: CSSProperties) => ({
        ...singleValueStyles,
        color: '#707070',
    }),
    placeholder: (placeholderStyles: CSSProperties) => placeholderStyles,
    option: (optionStyles: CSSProperties) => optionStyles,
    menu: (menuStyles: CSSProperties) => ({
        ...menuStyles,
    }),
};

export interface VideoOption {
    id: string;
    value: string;
    label: string;
    data: MediaFile | null;
}

const CustomOption = ({ innerProps, label, data, isDisabled }: any): JSX.Element => {
    const { id, mediaInfo, mediaType } = data.data;
    const videoMediaInfo = mediaInfo?.proxy || mediaInfo?.original;
    const isAudio =
        videoMediaInfo?.original?.container?.format === 'MPEG Audio' ||
        videoMediaInfo?.original?.container?.format === 'Wave' ||
        mediaType === MediaType.AUDIO;

    return (
        <CustomOptionContainer
            {...innerProps}
            style={{
                fontFamily:
                    label === 'Autodetect'
                        ? 'Proxima Nova Bold'
                        : 'Proxima Nova',
            }}
            isDisabled={isDisabled}
            onClick={isDisabled ? () => null : innerProps.onClick}
        >
            <ThumbnailContainer>
                {isAudio || !videoMediaInfo ? (
                    <Icon name="music" size={24} color="#ffffff" />
                ) : (
                    <Thumbnail
                        alt="thumbnail"
                        src={`${
                            window.config.REACT_APP_CLOUDFRONT_URL
                        }Stage/thumbnail?videoId=${id}&timestamp=${
                            Math.ceil(videoMediaInfo.container.duration * 0.2) *
                            1000
                        }&width=200`}
                    />
                )}
            </ThumbnailContainer>

            {label}
        </CustomOptionContainer>
    );
};

interface Props {
    closeButton: JSX.Element;
}

const AddProjectVideoFromLibraryModal = (props: Props): JSX.Element => {
    const [videos, setVideos] = useState<MediaFile[]>([]);
    const [isVideosLoading, setVideosLoading] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState<VideoOption[]>([
        {
            id: `${Math.random()}`,
            value: '',
            label: '',
            data: null,
        },
    ]);

    const { closeButton } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(getProjectUpdateLoading);
    const projectDetails = useSelector(getProjectDetailsInfo);
    const modalData = useSelector(getModalOptionalData);

    const loadVideos = async (loadedVideos: MediaFile[]): Promise<any> => {
        try {
            const res = (await VideosClient.getVideoListByFilter(
                { title: '', description: '' } as GetVideoListByFilterReqParams,
                loadedVideos.length,
                100
            )) as AxiosResponse;

            const { content, _metadata } = res.data;
            const { totalCount } = _metadata;

            const updatedVideos = [...loadedVideos, ...content] as MediaFile[];
            const updatedVideosCount = updatedVideos.length;

            if (updatedVideosCount < totalCount) {
                return loadVideos(updatedVideos);
            }

            setVideos(updatedVideos);
            setVideosLoading(false);

            return;
        } catch (error) {
            console.log({ error });
            setVideosLoading(false);
        }
    };

    const getVideos = async () => {
        setVideosLoading(true);

        await loadVideos([]);
    };

    useEffect(() => {
        getVideos();
    }, []);

    const filteredVideos = useMemo(
        () => {
            let mediaItems = videos;

            return mediaItems.filter(
                (video) => !projectDetails?.mediaSources?.includes(video.id)
            );
        },
        [projectDetails, videos],
    );

    const handleSave = () => {
        const filteredSelectedVideos = selectedVideos.filter(
            (video) => video.data
        );
        const transformedSelectedVideos = filteredSelectedVideos.map(
            (video) => video.data
        ) as MediaFile[];

        dispatch(addVideosFromLibraryToProject({
            newVideos: transformedSelectedVideos,
            isFromUploads: false,
            replaceableMedia: modalData?.replaceableMedia,
        }));
    };

    const transformedVideos = useMemo(
        () =>
            filteredVideos.map((video: MediaFile) => ({
                id: `${Math.random()}`,
                value: video.id,
                label: video.title || video.filename,
                data: video,
            })),
        [filteredVideos]
    );

    const handleSelectVideo = (video: any, videoId: string) => {
        if (video) {
            const updatedVideos = selectedVideos.map((selectedVideo) => {
                if (selectedVideo.id === videoId) {
                    return {
                        ...selectedVideo,
                        label: video.label,
                        value: video.id,
                        data: video.data,
                    };
                }

                return selectedVideo;
            });

            setSelectedVideos(updatedVideos);
        }
    };

    const handlAddVideo = () => {
        setSelectedVideos([
            ...selectedVideos,
            {
                id: `${Math.random()}`,
                value: '',
                label: '',
                data: null,
            },
        ]);
    };

    const handleDeleteSelectedVideo = (id: string) => {
        const updatedVideosList = selectedVideos.filter(
            (video) => video.id !== id
        );

        setSelectedVideos(updatedVideosList);
    };

    const isIncorrectType = (item: any) => {
        if(!modalData?.replaceMediaType) {
            return false;
        }

        return item?.data?.mediaType !== modalData?.replaceMediaType;
    };

    const renderSelectedVideos = () =>
        selectedVideos.map((video) => (
            <div
                className={styles.CreateProjectModal__selectWrap}
                key={video.id}
            >
                <Select
                    styles={selectStyles as any}
                    options={transformedVideos}
                    onChange={(option) => {
                        handleSelectVideo(option, video.id);
                    }}
                    placeholder="Select"
                    maxMenuHeight={150}
                    isLoading={isVideosLoading}
                    value={video as any}
                    components={{
                        Option: (comProps) => (
                            <CustomOption {...comProps} isDisabled={isIncorrectType(comProps.data)} />
                        ),
                    }}
                />
                <button
                    className={styles.CreateProjectModal__deleteVideoButton}
                    onClick={() => handleDeleteSelectedVideo(video.id)}
                >
                    <Icon name="trash" size={18} />
                </button>
            </div>
        ));

    const renderAddVideoButton = () => {
        if(modalData?.replaceMediaType) {
            return null;
        }

        return (
            <NewButton
                color={Colors.PINK}
                textColor={Colors.WHITE}
                bordered
                borderRadius={5}
                fontWeight="bold"
                width={111}
                height={40}
                onClick={handlAddVideo}
                leftContainer={
                    <div>
                        <Icon name="plus" size={14} color="#fff" />
                    </div>
                }
                disabled={isLoading}
            >
                Add Video
            </NewButton>
        );
    };

    const renderSaveButton = () => (
        <div className={styles.CreateProjectModal__saveButton}>
            <NewButton
                color={Colors.PINK}
                textColor={Colors.WHITE}
                bordered
                borderRadius={5}
                fontWeight="bold"
                width={111}
                height={40}
                onClick={handleSave}
                disabled={
                    isLoading ||
                    !selectedVideos.length ||
                    !selectedVideos?.[0]?.data
                }
                loading={isLoading}
            >
                Save
            </NewButton>
        </div>
    );

    return (
        <Container>
            <p className={styles.CreateProjectModal__title}>Add video</p>
            <div className={styles.CreateProjectModal__form}>
                {renderSelectedVideos()}
                {renderAddVideoButton()}
            </div>
            {renderSaveButton()}
            {closeButton}
        </Container>
    );
};

export default AddProjectVideoFromLibraryModal;
