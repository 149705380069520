import { AxiosResponse } from 'axios';
import { ProjectAudioMediaSource } from 'interfaces/projects';
import { DetouchAudioFromVideoFileReqParams } from 'models/media';

import store from 'state/store';

import $http from './instance';

class MediaClient {
    public static async detouchAudioFromVideoFile(
        params: DetouchAudioFromVideoFileReqParams
    ): Promise<AxiosResponse> {
        const { videoId, audioId, audioData } = params;

        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/audios/${audioId}`,
            audioData
        );
    }

    public static async createAudioMediasSource(
        audioData: ProjectAudioMediaSource,
        isGenerated = false
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/audios`,
            audioData,
            {
                params: { isGenerated },
            }
        );
    }

    public static async getAudioMediaSourceByIds(
        ids: string
    ): Promise<AxiosResponse> {
        const { user, workspaces , projects} = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/audios/many?ids=${ids}`
        );
    }
}

export default MediaClient;
