import produce from 'immer';

import { ModalState, ModalActionTypes, ModalAction } from './types';

const initialState: ModalState = {
  modalType: null,
  event: null,
  isModalVisible: false,
  optionalText: '',
  optionalData: null,
  backRoute: null,
};

export default (state = initialState, action: ModalAction): ModalState => produce(state, (draft) => {
  switch (action.type) {
    case ModalActionTypes.SET_MODAL_VISIBILITY:
      draft.isModalVisible = action.payload;

      return;
    case ModalActionTypes.SET_MODAL_TEXT:
      draft.optionalText = action.payload || initialState.optionalText;

      return;
    case ModalActionTypes.SET_MODAL_DATA:
      draft.optionalData = action.payload || initialState.optionalData;

      return;
    case ModalActionTypes.SET_MODAL_TYPE:
      draft.modalType = action.payload || initialState.modalType;

      return;
    case ModalActionTypes.SET_MODAL_EVENT:
      draft.event = action.payload || initialState.event;

      return;
    case ModalActionTypes.SET_BACK_ROUTE:
      draft.backRoute = action.payload;

      return;
    default:
      return state;
  }
});
