import produce from 'immer';
import moment from 'moment';

import { RekognitionCategory } from 'interfaces/analysis';
import { ProductPrepareTypes } from 'interfaces/preferences';

import { dateFilterOptions } from 'utils/filter';
import { UserAction, UserActionTypes, UserState } from './types';

export const defaultStatsFilter = {
    dateFrom: moment().startOf('day').toDate(),
    dateTo: moment().toDate(),
    dateFilter: dateFilterOptions[0],
};

export const initialState: UserState = {
    userPreferences: null,
    defaultUserPreferences: {
        productPrepareType: ProductPrepareTypes.PROJECTS,
        autoAnalyse: true,
        defaultUserCollection: '',
        defaultCategories: [
            RekognitionCategory.AWS_LABELS,
            RekognitionCategory.AWS_CELEBRITIES,
            RekognitionCategory.AWS_FACES,
            RekognitionCategory.AWS_FACE_MATCHES,
            RekognitionCategory.AWS_TRANSCRIPT,
            RekognitionCategory.AWS_SHOTS,
            RekognitionCategory.AWS_MODERATED_LABELS,
        ],
        defaultTranscriptLanguageCode: null,
    },
    userInfo: null,
    uploadStats: [],
    generationStats: [],
    analyzeStats: [],
    statsFilter: defaultStatsFilter,
    uploadStatsFilter: defaultStatsFilter,
    analysisStatsFilter: defaultStatsFilter,
    generationStatsFilter: defaultStatsFilter,
    isStatsLoading: true,
    projectPreferences: null,
    isToggleToSmartSearchAvailable: false,
    isFirstLogin: false,
    inviteCode: '',
    isInviteCodeGenerationLoading: false,
    isSendInviteLoading: false,
    invites: [],
    isInvitesLoading: false,
    ivitesTotal: 0,
    roles: []
};

export default (
    state: UserState = initialState,
    action: UserAction
): UserState =>
    produce(state, (draft) => {
        switch (action.type) {
            case UserActionTypes.GET_STATS_START:
                draft.isStatsLoading = true;

                return;
            case UserActionTypes.GET_STATS_SUCCESS:
                draft.isStatsLoading = false;

                return;
            case UserActionTypes.GET_STATS_FAIL:
                draft.isStatsLoading = false;

                return;
            case UserActionTypes.SET_ANALYSIS_STATS:
                draft.analyzeStats = action.payload;

                return;
            case UserActionTypes.SET_GENERATION_STATS:
                draft.generationStats = action.payload;

                return;
            case UserActionTypes.SET_UPLOAD_STATS:
                draft.uploadStats = action.payload;

                return;
            case UserActionTypes.SET_ANALYSIS_STATS_FILTER:
                draft.analysisStatsFilter = action.payload;

                return;
            case UserActionTypes.SET_GENERATION_STATS_FILTER:
                draft.generationStatsFilter = action.payload;

                return;
            case UserActionTypes.SET_UPLOAD_STATS_FILTER:
                draft.uploadStatsFilter = action.payload;

                return;
            case UserActionTypes.SET_USER_PREFERENCES:
                draft.userPreferences = action.payload;

                return;
            case UserActionTypes.UPDATE_USER_PREFERENCES:
                draft.userPreferences = action.payload;

                return;
            case UserActionTypes.SET_USER:
                draft.userInfo = action.payload;

                return;
            case UserActionTypes.SET_STATS_FILTER:
                draft.statsFilter = action.payload;

                return;
            case UserActionTypes.SET_PROJECT_PREFERENCES:
                draft.projectPreferences = action.payload;

                return;
            case UserActionTypes.SET_TOGGLE_TO_SMART_SEARCH_AVAILABLE_STATUS:
                draft.isToggleToSmartSearchAvailable = action.payload;

                return;
            case UserActionTypes.SET_FIRST_LOGIN_STATUS:
                draft.isFirstLogin = action.payload;

                return;

            case UserActionTypes.SEND_INVITE_START:
                draft.isSendInviteLoading = true;

                return;
            case UserActionTypes.SEND_INVITE_SUCCESS:
            case UserActionTypes.SEND_INVITE_FAIL:
                draft.isSendInviteLoading = false;

                return;
            case UserActionTypes.GET_INVITES_START:
                draft.isInvitesLoading = true;
                return;
            case UserActionTypes.GET_INVITES_FAIL:
                draft.isInvitesLoading = false;

                return;
            case UserActionTypes.SET_INVITES:
                draft.invites = action.payload.invites;
                draft.ivitesTotal = action.payload.total;
                draft.isInvitesLoading = false;

                return;

            case UserActionTypes.GET_INVITE_CODE_START:
                draft.isInviteCodeGenerationLoading = true;

                return;
            case UserActionTypes.GET_INVITE_CODE_FAIL:
                draft.isInviteCodeGenerationLoading = false;

                return;

            case UserActionTypes.SET_INVITE_CODE:
                draft.inviteCode = action.payload;
                draft.isInviteCodeGenerationLoading = false;

                return;

            case UserActionTypes.SET_USER_ROLES:
                draft.roles = action.payload;
                return;

            case UserActionTypes.SIGN_OUT:
                return initialState;
            default:
                return state;
        }
    });
