import styled from 'styled-components';

import {
    cancelUserSubscription,
    CurrentSubscription, getConfirmSetupLoading,
    getCurrentUserPlan,
    getSubscriptionCancelationLoading,
    getSubscriptionCreationLoading,
    getUpcomingUserPlan,
    PlanNameId,
    Product,
} from 'state/modules/payment';

import freePlanImg from 'assets/images/plans/free.webp';
import basicPlanImg from 'assets/images/plans/basic.webp';
import proPlanImg from 'assets/images/plans/pro.webp';
import enterprisePlanImg from 'assets/images/plans/enterprise.png';
import UpgradeButton from 'components/UpgradeButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    BLUE_100,
    NEUTRAL_150,
    NEUTRAL_300, NEUTRAL_400,
    NEUTRAL_600,
    PURPLE_200,
    PURPLE_300,
    WHITE_PRIMARY,
} from '../../styles/colors';

const Container = styled.div`
    border-radius: 8px;
    border: 1px solid ${NEUTRAL_150};
    padding: 1.5rem;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const PlanImage = styled.img`
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
`;

const Info = styled.div``;

const InfoHeader = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.p`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: ${NEUTRAL_600};
`;

const MoreInfo = styled.a`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    margin-left: 0.5rem;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${PURPLE_200};
`;

const Description = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;    
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    color: ${NEUTRAL_600};
`;

const FeaturesInfo = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    color: ${NEUTRAL_300};
    margin: 8px 0 0;
`;

const FeaturesMoreInfo = styled.span`
    cursor: pointer;
    color: ${PURPLE_200};

    &:hover {
        color: ${PURPLE_300};
    }
`;

const UpgradeButtonContainer = styled.div`
    margin-left: 10px;
`;

const CancelButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const CancelButton = styled.button`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    color: ${NEUTRAL_300};
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    margin-top: 1rem;

    &:disabled {
        color: ${NEUTRAL_400};
        cursor: not-allowed;
    }
`;

interface Props {
    data: Product;
    onSelect?: (plan: Product) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    currentUserPlan?: CurrentSubscription | null;
    isCanceled?: boolean;
    isCurrentUserOwner?: boolean;
    seePlanLimitsInfo: () => void;
}

const WorkspacePlanCard = ({
    data,
    isLoading,
    isCurrentUserOwner,
    seePlanLimitsInfo,
}: Props): JSX.Element => {
    const { planNameId } = data;

    const dispatch = useDispatch();

    const upcomingPlan = useSelector(getUpcomingUserPlan);
    const isSubscriptionCancelationLoading = useSelector(
        getSubscriptionCancelationLoading
    );
    const isSubscriptionCreationLoading = useSelector(
        getSubscriptionCreationLoading
    );
    const currentPlan = useSelector(getCurrentUserPlan);
    const isConfirmSetupLoading = useSelector(getConfirmSetupLoading);

    const getTitle = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'Free';
            case PlanNameId.BASIC:
                return 'Basic';
            case PlanNameId.PROFESSIONAL:
                return 'Professional';
            case PlanNameId.BUSINESS:
                return 'Business';
            default:
                return '';
        }
    };

    const getDescription = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return 'For quick projects and short content without leaving credit card details.';
            case PlanNameId.BASIC:
                return 'Perfect for individuals that need simple video editing capabilities.';
            case PlanNameId.PROFESSIONAL:
                return 'For professionals and teams working with video and audio content daily.';
            case PlanNameId.BUSINESS:
                return 'For companies managing large volumes of video and audio content.';
            default:
                return '';
        }
    };

    const handleDowngradeToFree = () => { // todo: replace with an actual pop-up
        if (
            // eslint-disable-next-line no-alert
            window.confirm('Are you sure you want to downgrade to Free Plan?')
        ) {
            dispatch(cancelUserSubscription());
        }
    };

    const renderImage = () => {
        switch (planNameId) {
            case PlanNameId.INITIAL:
                return <PlanImage src={freePlanImg} alt="free" />;
            case PlanNameId.BASIC:
                return <PlanImage src={basicPlanImg} alt="basic" />;
            case PlanNameId.PROFESSIONAL:
                return <PlanImage src={proPlanImg} alt="pro" />;
            case PlanNameId.BUSINESS:
                return <PlanImage src={enterprisePlanImg} alt="business" />;
            default:
                return <></>;
        }
    };

    const renderTitle = () => {
        const title = getTitle();

        return <Title>{title}</Title>;
    };

    const renderMoreInfoLink = () => (
        <MoreInfo
            href="https://promomii.com/pricing/"
            target="_blank"
            rel="noreferrer"
        >
            Compare plans
        </MoreInfo>
    );

    const renderDescription = () => {
        const description = getDescription();

        return <Description>{description}</Description>;
    };

    const renderFeaturesInfo = () => {
        return (
            <FeaturesInfo>
                To find more details about your plan limits,&nbsp;
                <FeaturesMoreInfo onClick={seePlanLimitsInfo}>
                    click here
                </FeaturesMoreInfo>.
            </FeaturesInfo>
        );
    }

    const renderCancelPlan = () => {
        return (
            <>
                {
                    upcomingPlan?.planNameId !== PlanNameId.INITIAL &&
                    upcomingPlan?.name !== 'Initial product' ? (
                        <CancelButtonContainer>
                            <CancelButton
                                onClick={handleDowngradeToFree}
                                disabled={
                                    isSubscriptionCancelationLoading ||
                                    isSubscriptionCreationLoading ||
                                    isLoading ||
                                    !currentPlan ||
                                    !upcomingPlan ||
                                    currentPlan?.planNameId === PlanNameId.INITIAL ||
                                    isConfirmSetupLoading
                                }
                            >
                                Cancel plan
                            </CancelButton>
                        </CancelButtonContainer>
                    ) : null
                }
            </>
        );
    };

    const renderUpgradeButton = () => {
        return isCurrentUserOwner ? (
            <UpgradeButtonContainer>
                <UpgradeButton
                    bgColor={BLUE_100}
                    textColor={WHITE_PRIMARY}
                    isFromWorkspaceSettings
                />
            </UpgradeButtonContainer>
        ) : null;
    };

    return (
        <Container>
            <Header>
                {renderImage()}
                <Info>
                    <InfoHeader>
                        {renderTitle()}
                        {renderMoreInfoLink()}
                    </InfoHeader>
                    {renderDescription()}
                    {renderFeaturesInfo()}
                </Info>
                {renderUpgradeButton()}
            </Header>
            {renderCancelPlan()}
        </Container>
    );
};

export default WorkspacePlanCard;
