import { IconProps } from './index';

const ZoomOutIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;
    if (size) {
        height = size;
    }
    const width = height;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_424_4339)">
                <path
                    d="M16.25 8.125C16.25 9.91797 15.668 11.5742 14.6875 12.918L19.6328 17.8672C20.1211 18.3555 20.1211 19.1484 19.6328 19.6367C19.1445 20.125 18.3516 20.125 17.8633 19.6367L12.918 14.6875C11.5742 15.6719 9.91797 16.25 8.125 16.25C3.63672 16.25 0 12.6133 0 8.125C0 3.63672 3.63672 0 8.125 0C12.6133 0 16.25 3.63672 16.25 8.125ZM5.3125 7.1875C4.79297 7.1875 4.375 7.60547 4.375 8.125C4.375 8.64453 4.79297 9.0625 5.3125 9.0625H10.9375C11.457 9.0625 11.875 8.64453 11.875 8.125C11.875 7.60547 11.457 7.1875 10.9375 7.1875H5.3125Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_424_4339">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ZoomOutIcon;
