import { AxiosResponse } from 'axios';

import { TemplateBody } from 'state/modules/templates';
import store from 'state/store';
import $http from './instance';

class TemplateClient {
  public static async getTemplates(): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/templates`);
  }

  public static async getTemplateDetails(id: string): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/templates/${id}`);
  }

  public static async updateTemplateDetails(
    id: string,
    data: TemplateBody,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    const updatedTemplate = {
      ...data,
      ownerId: userId,
    };

    return $http.put(`/user/${userId}/templates/${id}`, updatedTemplate);
  }

  public static async createTemplate(
    data: TemplateBody,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.post(`/user/${userId}/templates`, data);
  }

  public static async deleteTemplate(id: string): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.delete(`/user/${userId}/templates/${id}`);
  }
}

export default TemplateClient;
