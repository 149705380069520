import { AnalysisResultTimestamp } from 'state/modules/metadata';
import { ShotsCategory } from 'interfaces/shots';
import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from 'interfaces/analysis';

export interface ShotsState {
    shotsList: Array<TransformedShot>;
    shotsTotal: number;
    isLoading: boolean;
    shotsCategory: ShotsCategory;
    isFilteredShotsLoading: boolean;
    searchFilter: Array<SearchFilterItem>;
}

export interface Shot {
    confidence: number;
    durationMillis: number;
    endTimestampMillis: number;
    extraProperties: { index: number };
    index: number;
    id: string;
    ownerId: string;
    startTimestampMillis: number;
    type: AnalysisResultsType;
    videoId: string;
}
export interface TransformedShot extends Shot {
    id: string;
    labelType: AnalysisResultsType;
    name: string;
    timestamps: Array<AnalysisResultTimestamp>;
}

export interface SearchFilterItem {
    title?: string | AnalysisResultsCategory;
    type?: string;
    id?: number | string;
    facialAttribute?: string | null;
    emotion?: string | null;
}

export enum ShotsActionTypes {
    GET_SHOTS = '@@shots/GET_SHOTS',
    GET_SHOTS_START = '@@shots/GET_SHOTS_START',
    GET_SHOTS_SUCCESS = '@@shots/GET_SHOTS_SUCCESS',
    GET_SHOTS_FAIL = '@@shots/GET_SHOTS_FAIL',

    GET_SHOTS_BY_SEARCH = '@@shots/GET_SHOTS_BY_SEARCH',

    GET_FILTERED_SHOTS = '@@shots/GET_FILTERED_SHOTS',
    GET_FILTERED_SHOTS_START = '@@shots/GET_FILTERED_SHOTS_START',
    GET_FILTERED_SHOTS_FAIL = '@@shots/GET_FILTERED_SHOTS_FAIL',
    GET_FILTERED_SHOTS_SUCCESS = '@@shots/GET_FILTERED_SHOTS_SUCCESS',

    SET_SHOTS_CATEGORY = '@@shots/SET_SHOTS_CATEGORY',

    CHANGE_SEARCH_FILTER = '@@shots/CHANGE_SEARCH_FILTER',
    SET_SEARCH_FILTER = '@@shots/SET_SEARCH_FILTER',

    UPDATE_SEARCH_FILTER_ITEM = '@@shots/UPDATE_SEARCH_FILTER_ITEM',
}

export interface GetShotsAction {
    type: ShotsActionTypes.GET_SHOTS;
    payload: {
        videoId: string;
        offset: number;
    };
}
export interface GetShotsStartAction {
    type: ShotsActionTypes.GET_SHOTS_START;
}
export interface GetShotsSuccessAction {
    type: ShotsActionTypes.GET_SHOTS_SUCCESS;
    payload: {
        shots: Array<TransformedShot>;
        total: number;
    };
}
export interface GetShotsFailAction {
    type: ShotsActionTypes.GET_SHOTS_FAIL;
    payload: any;
}
export interface SetShotsCategoryAction {
    type: ShotsActionTypes.SET_SHOTS_CATEGORY;
    payload: ShotsCategory;
}
export interface GetShotsBySearchAction {
    type: ShotsActionTypes.GET_SHOTS_BY_SEARCH;
    payload: {
        videoId: string;
    };
}
export interface GetFilteredShotsAction {
    type: ShotsActionTypes.GET_FILTERED_SHOTS;
    payload: {
        videoId: string;
        query: string;
    };
}
export interface GetFilteredShotsStartAction {
    type: ShotsActionTypes.GET_FILTERED_SHOTS_START;
}
export interface GetFilteredShotsFailAction {
    type: ShotsActionTypes.GET_FILTERED_SHOTS_FAIL;
    payload: any;
}
export interface GetFilteredShotsSuccessAction {
    type: ShotsActionTypes.GET_FILTERED_SHOTS_SUCCESS;
    payload: {
        shots: Array<TransformedShot>;
        total: number;
    };
}
export interface SetSearchFilterAction {
    type: ShotsActionTypes.SET_SEARCH_FILTER;
    payload: Array<SearchFilterItem>;
}
export interface ChangeShotsFilterActionPayload {
    reason: string;
    option: SearchFilterItem;
    videoId: string;
}

export interface ChangeShotsFilterAction {
    type: ShotsActionTypes.CHANGE_SEARCH_FILTER;
    payload: ChangeShotsFilterActionPayload;
}
export interface UpdateShotsFilterItemAction {
    type: ShotsActionTypes.UPDATE_SEARCH_FILTER_ITEM;
    payload: {
        item: SearchFilterItem;
        videoId: string;
    };
}

export type ShotsAction =
    | GetShotsAction
    | GetShotsStartAction
    | GetShotsSuccessAction
    | GetShotsFailAction
    | SetShotsCategoryAction
    | GetShotsBySearchAction
    | GetFilteredShotsAction
    | GetFilteredShotsStartAction
    | GetFilteredShotsFailAction
    | GetFilteredShotsSuccessAction
    | SetSearchFilterAction
    | ChangeShotsFilterAction
    | UpdateShotsFilterItemAction;
