import { AxiosResponse } from 'axios';
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import orderBy from 'lodash.orderby';
import moment from 'moment';
import {
  MetadataActionTypes,
  GetMetadataRes,
  getFacialAttributes,
  getModeratedLabels,
  getTranscriptList,
  getCelebs,
  getUserDefined,
  getLabels,
  GetMetadataCategoriesTotalRes,
  MetadataCategoryTotalItem,
  GetTranscriptRes,
  TransformedAnalysisResultItem,
  TransformedSentence,
  GetAutocompleteRes,
  AnalysisResultTimestampItem,
  GetTimestampsReqMetadata,
  GetMetadaCategoriesTotalAction,
  GetMetadataAction,
  GetTranscriptAction,
  GetAutocompleteAction,
  getTranscriptTotal
} from 'state/modules/metadata';

import * as metadataActions from 'state/modules/metadata/actions';

import MetadataClient from 'services/api/metadata';

import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from 'interfaces/analysis';

import { transformAutocompleteResults } from 'utils/analysisResults';
import {
  transformAnalysisResults,
  transformTranscript,
} from 'state/utils/metadataUtils';
// import { createItemsFromSentence } from 'utils/transcript';

import { CreateNewSentenceAction } from './types';
import { getMediaLanguagesCodes } from '../transcript';
import { getMediaLanguageCode } from '../videoExplorer';

export function* getTimestampsForManyItems(
  videoId: string,
  metadata: Array<GetTimestampsReqMetadata>,
): Generator<any, Array<AnalysisResultTimestampItem>> {
  const timestamps = [] as Array<AnalysisResultTimestampItem>;

  function* loadTimestamps(offset: number): Generator {
    const res = (yield call(
      MetadataClient.getTimestampsForManyItems,
      videoId,
      metadata,
      offset,
    )) as AxiosResponse;

    const newTimestamps = res.data.content;
    const resMetadata = res.data._metadata;

    timestamps.push(...newTimestamps);

    if (resMetadata.totalCount > timestamps.length) {
      yield call(loadTimestamps, timestamps.length);
    }
  }

  try {
    yield call(loadTimestamps, 0);

    return timestamps;
  } catch (error) {
    console.log({ error });

    return timestamps;
  }
}

export function* updateObjects(
  newObjects: Array<TransformedAnalysisResultItem>,
  afterKey: string,
): Generator {
  const oldObjects = (yield select(
    getLabels,
  )) as Array<TransformedAnalysisResultItem>;
  let objects = [] as Array<TransformedAnalysisResultItem>;

  if (afterKey.length) {
    objects = [...oldObjects, ...newObjects];
  } else {
    objects = newObjects;
  }

  yield put(metadataActions.setObjects(objects));
}

export function* updateCelebs(
  newCelebs: Array<TransformedAnalysisResultItem>,
  afterKey: string,
): Generator {
  const oldCelebs = (yield select(
    getCelebs,
  )) as Array<TransformedAnalysisResultItem>;
  let updatedCelebs = [] as Array<TransformedAnalysisResultItem>;

  if (afterKey.length) {
    updatedCelebs = [...oldCelebs, ...newCelebs];
  } else {
    updatedCelebs = newCelebs;
  }

  updatedCelebs = orderBy(updatedCelebs, ['count'], ['desc']);

  yield put(metadataActions.setCelebs(updatedCelebs));
}

export function* updateUserDefined(
  newUserDefined: Array<TransformedAnalysisResultItem>,
  afterKey: string,
): Generator {
  const oldUserDefined = (yield select(
    getUserDefined,
  )) as Array<TransformedAnalysisResultItem>;
  let updatedUserDefined = [] as Array<TransformedAnalysisResultItem>;

  if (afterKey.length) {
    updatedUserDefined = [...oldUserDefined, ...newUserDefined];
  } else {
    updatedUserDefined = newUserDefined;
  }

  yield put(metadataActions.setUserDefined(updatedUserDefined));
}

export function* updateFacialAttributes(
  newFacialAttributes: Array<TransformedAnalysisResultItem>,
  afterKey: string,
): Generator {
  const oldFacialAttributes = (yield select(
    getFacialAttributes,
  )) as Array<TransformedAnalysisResultItem>;
  let updatedFacialAttributes = [] as Array<TransformedAnalysisResultItem>;

  if (afterKey.length) {
    updatedFacialAttributes = [
      ...oldFacialAttributes,
      ...newFacialAttributes,
    ];
  } else {
    updatedFacialAttributes = newFacialAttributes;
  }

  yield put(metadataActions.setFacialAttributes(updatedFacialAttributes));
}

export function* updateModeratedLabels(
  newModeratedLabels: Array<TransformedAnalysisResultItem>,
  afterKey: string,
): Generator {
  const oldModeratedLabels = (yield select(
    getModeratedLabels,
  )) as Array<TransformedAnalysisResultItem>;
  let updatedModeratedLabels = [] as Array<TransformedAnalysisResultItem>;

  if (afterKey.length) {
    updatedModeratedLabels = [...oldModeratedLabels, ...newModeratedLabels];
  } else {
    updatedModeratedLabels = newModeratedLabels;
  }

  yield put(metadataActions.setModeratedLabels(updatedModeratedLabels));
}

export function* handleGetMetadata(action: GetMetadataAction): Generator {
  const {
    id, confidence, scaleFactor, afterKey, category, size,
  } = action.payload;
  const isLoadingMore = afterKey.length > 0;
  yield put(metadataActions.getMetadataStart(isLoadingMore));

  try {
    let types = category;

    if (category === AnalysisResultsCategory.FACIAL_ATTRIBUTE) {
      types = `${category},emotion`;
    }

    const res = (yield call(MetadataClient.getMetadata, {
      videoId: id,
      types,
      confidence,
      scaleFactor,
      afterKey,
      size,
    })) as AxiosResponse<GetMetadataRes>;

    if (res.data) {
      const analysisResults = transformAnalysisResults(
        res.data.content,
        id,
      );

      if (category === AnalysisResultsCategory.OBJECT) {
        yield call(updateObjects, analysisResults, afterKey);
      }

      if (category === AnalysisResultsCategory.CELEBRITY) {
        yield call(updateCelebs, analysisResults, afterKey);
      }

      if (category === AnalysisResultsCategory.USER_DEFINED) {
        yield call(updateUserDefined, analysisResults, afterKey);
      }

      if (category === AnalysisResultsCategory.FACIAL_ATTRIBUTE) {
        yield call(updateFacialAttributes, analysisResults, afterKey);
      }

      if (category === AnalysisResultsCategory.MODERATED_LABEL) {
        yield call(updateModeratedLabels, analysisResults, afterKey);
      }

      yield put(
        metadataActions.setAfterKey(res.data._metadata.afterKey || ''),
      );
    }
  } catch (error) {
    yield put(metadataActions.getMetadataFail(error));
  }
}

export function* handleGetTranscript(action: GetTranscriptAction): Generator {
  yield put(metadataActions.getTranscriptStart());

  const { id, query, offset } = action.payload;
  const isLoadMore = offset > 0;

  const mediaLanguageCode = (yield select(
    getMediaLanguageCode,
  )) as string; 

  try {
    const res = (yield call(
      MetadataClient.getTranscript,
      id,
      query,
      offset,
      mediaLanguageCode || ''
    )) as AxiosResponse<GetTranscriptRes>;

    const oldTranscriptList = (yield select(
      getTranscriptList,
    )) as Array<TransformedSentence>;

    if (res.status === 200) {
      let transcript = transformTranscript(
        res.data.content,
      ) as Array<TransformedSentence>;

      const total = res.data._metadata.totalCount;

      if (action.payload.query.length) {
        yield put(
          metadataActions.getTranscriptBySearchQuerySuccess(
            transcript,
          ),
        );
      } else {
        if (isLoadMore) {
          transcript = [...oldTranscriptList, ...transcript];
        }

        yield put(
          metadataActions.getTranscriptSuccess(transcript, total),
        );

        const transcriptCount = transcript.length;

        if (transcriptCount < total) {
          yield put(
            metadataActions.getTranscript(
              id,
              query,
              transcriptCount,
            ),
          );
        }
      }
    }
  } catch (error) {
    yield put(metadataActions.getTranscriptFail(error));
  }
}

export function* handleGetMetadataCategoriesTotal(
  action: GetMetadaCategoriesTotalAction,
): Generator {
  const { id } = action.payload;

  try {
    const res = (yield call(
      MetadataClient.getMetadataCategoriesTotal,
      id,
    )) as AxiosResponse<GetMetadataCategoriesTotalRes>;

    if (res.data) {
      const labels = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.OBJECT,
      );

      const celebs = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.CELEBRITY,
      );

      const userDefined = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.USER_DEFINED,
      );

      const facialAttributes = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.FACIAL_ATTRIBUTE,
      );

      const emotions = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.EMOTION,
      );

      const moderatedLabels = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.MODERATED_LABEL,
      );

      const shots = res.data.content.find(
        (item: MetadataCategoryTotalItem) => item.name === AnalysisResultsType.SHOT,
      );

      yield put(
        metadataActions.getMetadaCategoriesTotalSuccess({
          labelsTotal: labels ? labels.count : 0,
          celebsTotal: celebs ? celebs.count : 0,
          userDefinedTotal: userDefined ? userDefined.count : 0,
          facesTotal: 0,
          facialAttributesTotal:
                        (facialAttributes ? facialAttributes.count : 0)
                        + (emotions ? emotions.count : 0),
          moderatedLabelsTotal: moderatedLabels
            ? moderatedLabels.count
            : 0,
          shotsTotal: shots ? shots.count : 0,
        }),
      );
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* handleGetAutocomplete(
  action: GetAutocompleteAction,
): Generator {
  yield put(metadataActions.getAutocompleteStart());

  try {
    const { type, id, query } = action.payload;
    let types = action.payload.type;

    if (type === AnalysisResultsType.FACIAL_ATTRIBUTE) {
      types = `${types},emotion`;
    }

    const res = (yield call(
      MetadataClient.getAutocomplete,
      id,
      query,
      types,
    )) as AxiosResponse<GetAutocompleteRes>;

    if (res.status === 200) {
      const transformedAutocompleteItems = transformAutocompleteResults(
        res.data.content,
        id,
      );

      yield put(
        metadataActions.getAutocompleteSuccess(
          transformedAutocompleteItems,
        ),
      );
    }
  } catch (error) {
    yield put(metadataActions.getAutocompleteFail(error));
  }
}

export function* handleCreateNewSentence(
  action: CreateNewSentenceAction,
): Generator { 
  const currentSentence = action.payload;

  const mediaLanguageCode = (yield select(
    getMediaLanguagesCodes,
  )) as string;
  const transcriptList = (yield select(
    getTranscriptList,
  )) as Array<TransformedSentence>;
  const transcriptTotal = (yield select(
    getTranscriptTotal,
  )) as number;

  const currentSentenceIndex = transcriptList.findIndex(transcriptItem => transcriptItem.id === currentSentence.id);
  const nextSentenceItem = transcriptList[currentSentenceIndex + 1] || currentSentence;

  const nextSentenceItemStartTime = nextSentenceItem.startTime;

  // const startTimeInMs = moment.duration(currentSentence.endTime).as('milliseconds');
  // const endTimeInMs = moment.duration(nextSentenceItemStartTime).as('millisecond');

  // const formattedStartTime = moment.duration(currentSentence.endTime).format("ss.SS") as any;
  // const formattedEndTime = moment.duration(nextSentenceItemStartTime).format("ss.SS") as any;
 
  let startTime = currentSentence.endTime;
  let endTime = nextSentenceItemStartTime

  startTime = startTime !== '00' ? startTime : `${startTime}.00`;
  endTime = endTime !== '00' ? endTime : `${endTime}.00`;

  // console.log({startTime})
  // console.log({endTime})

  // console.log(Number(startTime))
  // console.log(Number(endTime))
  // console.log(Number(startTime) * 1000)
  // console.log(Number(endTime)  * 1000)

  // const testStart = Number(startTime) * 1000 + 5;
  // const testEnd = Number(endTime) * 1000 - 5;

  // const formattedStartTime = moment.duration(testStart).format("ss.SS") as any;
  //     const formattedEndTime = moment.duration(testEnd).format("ss.SS") as any;

  //     console.log({formattedStartTime})
  //     console.log({formattedEndTime})

  const data = {
    data: '',
    [`data_${mediaLanguageCode}`]: '',
    items: [],
    language: mediaLanguageCode,
    speaker: currentSentence.speaker,
    startTime,
    endTime,
    type: AnalysisResultsType.SENTENCE,
    videoId: currentSentence.videoId,
  } as any;

  try {
    const res = (yield call(
      MetadataClient.createSentence,
      currentSentence.videoId,
      data,
    )) as AxiosResponse;

    data.id = res.data.content.newSentenceId;
 
    const transformedSentence = transformTranscript([data])[0];

    const updatedTranscriptList = [...transcriptList];

    updatedTranscriptList.splice( currentSentenceIndex + 1, 0, transformedSentence );

    yield put(
      metadataActions.getTranscriptSuccess(
        updatedTranscriptList,
        transcriptTotal + 1,
      ),
    );
    localStorage.setItem(currentSentence.videoId, JSON.stringify({
      isTranscriptChanged: true,
    }))
  } catch (error) {
    console.log({error})
  }
}

export function* metadataSaga(): Generator {
  yield takeLatest(
    MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL,
    handleGetMetadataCategoriesTotal,
  );
  yield takeLatest(MetadataActionTypes.GET_METADATA, handleGetMetadata);
  yield takeLatest(MetadataActionTypes.GET_TRANSCRIPT, handleGetTranscript);
  yield takeLatest(
    MetadataActionTypes.GET_AUTOCOMPLETE,
    handleGetAutocomplete,
  );
  yield takeLatest(
    MetadataActionTypes.CREATE_NEW_SENTENCE,
    handleCreateNewSentence,
  );
}
