import axios, { AxiosResponse } from 'axios';
import { build, omit } from 'search-params';

import $http from './instance';

class StockMediaClient {
    public static async getImages({
        offset,
        phrase,
        collection,
    }: {
        offset: number;
        phrase: string;
        collection?: string | null;
    }): Promise<AxiosResponse> {
        let query = build({
            limit: 30,
            offset,
            phrase,
            collection_code: collection,
        });

        if (!collection?.length) {
            query = omit(query, ['collection_code']).querystring;
        }

        if (!phrase?.length) {
            query = omit(query, ['phrase']).querystring;
        }

        // return $http.get(`/gettyimages/images/creative?${query}`);
        return $http.get(`/gettyimages/images?${query}`);
    }

    public static async getVideos({
        offset,
        phrase,
        collection,
    }: {
        offset: number;
        phrase: string;
        collection?: string | null;
    }): Promise<AxiosResponse> {
        let query = build({
            limit: 30,
            offset,
            phrase,
            collection_code: collection,
        });

        if (!phrase?.length) {
            query = omit(query, ['phrase']).querystring;
        }

        if (!collection?.length) {
            query = omit(query, ['collection_code']).querystring;
        }

        // return $http.get(`/gettyimages/videos/creative?${query}`);
        return $http.get(`/gettyimages/videos?${query}`);
    }

    public static async getCollections(): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/collections?limit=100`);
    }

    public static async downloadStockVideo(
        id: string,
        size: string
    ): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/videos/${id}/download?size=${size}`);
    }

    public static async getStockVideoSizes(id: string): Promise<AxiosResponse> {
        return $http.get(
            `/gettyimages/videos/${id}?fields=download_sizes,clip_length,display_set`
        );
    }

    public static async downloadStockImage(
        id: string,
        height: number
    ): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/images/${id}/download?height=${height}`);
    }

    public static async getStockImageSizes(id: string): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/images/${id}?fields=download_sizes`);
    }

    public static async getAudioPlaylists(
        page = 1,
        size = 10
    ): Promise<AxiosResponse> {
        // let query = build({
        //     include: 'songs,songs.audio_files',
        // });

        // return $http.get(
        //     `/gettyimages/playlists?limit=${size}&offset=${page}&query_params=${query}`
        // );

        return $http.get(`/gettyimages/playlists?limit=${size}&offset=${page}`);
    }

    public static async getAudioPlaylistById(
        id: string,
        page = 1,
        size = 3
    ): Promise<AxiosResponse> {
        return $http.get(
            `/gettyimages/playlists/${id}?limit=${size}&offset=${page}&query_params=${encodeURI(
                `include=songs,songs.audio_files,songs.artists`
            )}
            `
        );
    }

    public static async getAudioPlaylistCategories(): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/playlist_categories?offset=1&limit=10`);
    }

    public static async getAudioPlaylistCategoryById(
        id: string
    ): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/playlist_categories/${id}`);
    }

    public static async getAudioTags(offset = 1): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/tags?offset=${offset}&limit=100`);
    }

    public static async getAudioTagById(id: string): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/tags/${id}`);
    }

    public static async getSongs({
        search,
        page = 1,
        size = 5,
        tags,
    }: {
        search?: string;
        page?: number;
        size?: number;
        tags?: {
            value: string;
            type: string;
        };
    }): Promise<AxiosResponse> {
        let query_params = ``;

        if (tags) {
            query_params = `&query_params=${encodeURI(
                `filter[tags][${tags.type}]=${tags.value}`
            )}`;
        }

        if (search) {
            query_params = `&query_params=${encodeURI(`filter[q]=${search}`)}`;
        }

        return $http.get(
            `/gettyimages/songs?limit=${size}&offset=${page}${query_params}`
        );
    }

    public static async getSongById(id: string): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/songs/${id}`);
    }

    public static async getSoundEffects({
        search,
        page = 1,
        size = 5,
        category,
    }: {
        search?: string;
        category?: string;
        page?: number;
        size?: number;
    }): Promise<AxiosResponse> {
        let query_params = ``;

        if (search) {
            query_params = `&query_params=${encodeURI(`filter[q]=${search}`)}`;
        }

        if (category) {
            query_params = `&query_params=${encodeURI(
                `filter[categories]=${category}`
            )}`;
        }

        return $http.get(
            `/gettyimages/sound_effects?limit=${size}&offset=${page}${query_params}`
        );
    }

    public static async getSoundEffectById(id: string): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/sound_effects/${id}`);
    }

    public static async getCategories(offset = 1): Promise<AxiosResponse> {
        return $http.get(`/gettyimages/categories?offset=${offset}&limit=100`);
    }
}

export default StockMediaClient;
