import { memo } from 'react';
import Switch from 'react-switch';
import { Colors } from 'styles';
import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props {
    checked: boolean;
    labelRight?: string;
    labelLeft?: string;
    onChange: () => void;
    disabled?: boolean;
    leftIcon?: string;
    rightIcon?: string;
    leftIconColor?: string;
    rightIconCoor?: string;
}

const Switcher = (props: Props) => {
    const {
        checked,
        labelRight,
        labelLeft,
        onChange,
        disabled,
        leftIcon,
        leftIconColor,
        rightIconCoor,
        rightIcon,
    } = props;

    const handleChange = () => {
        onChange();
    };

    return (
        <label
            className={[
                styles.Switch,
                disabled ? styles.Switch_disabled : null,
            ].join(' ')}
        >
            {leftIcon ? (
                <Icon name={leftIcon} color={leftIconColor} size={20} />
            ) : null}

            {labelLeft ? (
                <span className={styles.Switch__labelLeft}>{labelLeft}</span>
            ) : null}
            <Switch
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#93C4D3"
                offColor="#f9f9f9"
                offHandleColor="#93C4D3"
                className={styles.Switch__toggler}
                // boxShadow="inset 0px 0px 1px rgba(0, 0, 0, 0.25)"
                // boxShadow="15px 2px 1px red"
            />
            {labelRight ? (
                <span className={styles.Switch__labelRight}>{labelRight}</span>
            ) : null}
            {rightIcon ? (
                <div className={styles.Switch__rightIcon}>
                    <Icon name={rightIcon} color={rightIconCoor} size={20} />
                </div>
            ) : null}
        </label>
    );
};

export default memo(Switcher);
