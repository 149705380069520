import { useSelector } from 'react-redux';
import styled from 'styled-components';
import prettyBytes from 'pretty-bytes';

import {
    CurrentSubscription, getCurrentStorage,
    getUserUsageData,
    PlanNameId,
    UserUsageAndTotalSubscriptionData,
} from 'state/modules/payment';
import { NEUTRAL_100, NEUTRAL_200, NEUTRAL_300, NEUTRAL_600, PURPLE_PRIMARY, WHITE_PRIMARY } from '../../styles/colors';
import { Icon } from '../UI';

const Container = styled.div<{isFromMenu?: boolean}>`
    background-color: ${WHITE_PRIMARY};
    padding: 1rem;
    height: ${({ isFromMenu }) => isFromMenu ? '132px' : '100px'};
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ isFromMenu }) =>
    isFromMenu ? `1px solid ${NEUTRAL_100}` : 'none'};
    align-items: ${({ isFromMenu }) =>
    isFromMenu ? 'center' : 'initial'};


    @media (max-height: 860px) {
        padding: 0.625rem;
        height: ${({ isFromMenu }) => isFromMenu ? '132px' : '95px'};
    }
`;

const IconContainer = styled.div`
    margin-bottom: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
`;

const AvailableMinutesTitle = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: ${NEUTRAL_600};
    margin-bottom: 0.25rem;
    line-height: 0.938rem;
`;

const Storage = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.781rem;
    text-align: left;
    color: ${NEUTRAL_300};
`;

const BuyMoreTimeButton = styled.button`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
    color: ${PURPLE_PRIMARY};
    cursor: pointer;
    border: none;
    background: transparent;
    margin-top: auto;

    &:disabled {
        color: ${NEUTRAL_200};
        cursor: not-allowed;
    }
`;

interface Props {
    currentSubscription?: CurrentSubscription | null;
    hideButton?: boolean;
    isFeaturesMenu?: boolean,
    handleBuyMore?: () => void,
}

const AvailableStorage = (props: Props) => {
    const {
        currentSubscription,
        hideButton,
        isFeaturesMenu,
        handleBuyMore,
    } = props;
    const currentUsage = useSelector(
        getUserUsageData
    ) as UserUsageAndTotalSubscriptionData;
    const availableStorage = useSelector(getCurrentStorage);

    const handleAddStorage = () => {
        if(handleBuyMore) {
            handleBuyMore();
        }
    };

    const renderBuyMoreCreditsButton = () => {
        const isDisabled =
            !currentSubscription ||
            (currentSubscription.status === 'canceled' &&
                currentSubscription.stripeStatus === 'canceled') ||
            currentSubscription?.planNameId === PlanNameId.INITIAL;

        return (
            <BuyMoreTimeButton onClick={handleAddStorage} disabled={isDisabled}>
                Buy more
            </BuyMoreTimeButton>
        );
    };

    return (
        <Container
            isFromMenu={isFeaturesMenu}
        >
            {
                isFeaturesMenu && (
                    <IconContainer>
                        <Icon name="cloud" size={24} color={PURPLE_PRIMARY} />
                    </IconContainer>
                )
            }
            <AvailableMinutesTitle>Storage</AvailableMinutesTitle>
            <Storage>{` ${prettyBytes(availableStorage || 0)} / ${prettyBytes(currentUsage?.total?.storage || 0)}`}</Storage>
            {!hideButton && renderBuyMoreCreditsButton()}
        </Container>
    );
};

export default AvailableStorage;
