import React, { memo } from 'react';

import { Icon } from 'components/UI';

import styles from './styles.module.scss';

interface Props {
    text: string;
}

const Tooltip: React.FC<Props> = memo(({ text }) => (
    <div className={styles.Tooltip}>
        <Icon name="question" size={12} color="rgba(49, 49, 49, 0.4)" />
        <div className={styles.Tooltip__info}>
            <p className={styles.Tooltip__text}>{text}</p>
        </div>
    </div>
));

export default Tooltip;
