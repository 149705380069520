import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import groupBy from 'lodash.groupby';
import axios, { AxiosResponse } from 'axios';

import { NewButton, NewInput } from 'components/UI';

import { getModalOptionalData } from 'state/modules/modal';
import { getVideoFiles, getVideoInfo } from 'state/modules/media';
import { MetadataExportItem } from 'state/modules/export';
import { AnalysisResultTimestamp } from 'state/modules/metadata';
import { Scene } from 'state/modules/videoExplorer';

import { MediaInfo, MediaFile } from 'interfaces/videos';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface Props {
  closeButton: JSX.Element;
  closeModal: () => void;
}

interface GenerateThumbnailsBody {
  videos: Array<VideoThumbnail>;
  width: number;
  height?: number;
}
interface VideoThumbnail {
  mediaInfo: MediaInfo;
  name: string;
  srcKey: string;
  timestamps: Array<number>;
}

const windowFork = window;

const ThumbnailsExportModal = (props: Props): JSX.Element => {
  const [width, setWidth] = useState<number>(400);
  const [isLoading, setLoading] = useState(false);

  const dataForExport = useSelector(
    getModalOptionalData,
  ) as Array<MetadataExportItem>;
  const videos = useSelector(getVideoFiles) as Array<MediaFile>;
  const videoDetails = useSelector(getVideoInfo) as MediaFile;

  const { closeButton, closeModal } = props;

  const downloadThumbnails = async () => {
    setLoading(true);

    try {
      let timestampsString = '';

      dataForExport.forEach((item: MetadataExportItem) => {
        item.timestamps.forEach(
          (timestampItem: AnalysisResultTimestamp) => {
            if (!timestampsString.length) {
              timestampsString = `${timestampItem.timestamp}`;
            } else {
              timestampsString = `${timestampsString}, ${timestampItem.timestamp}`;
            }
          },
        );
      });

      const groupedByVideoId = groupBy(dataForExport, 'videoId');

      const transformedVideos = [] as Array<VideoThumbnail>;

      Object.keys(groupedByVideoId).forEach((key: string) => {
        const video = (videos.find(
          (item: MediaFile) => item.id === key,
        ) || videoDetails) as MediaFile;
        const timestamps = [] as Array<number>;

        groupedByVideoId[key].forEach((item: MetadataExportItem) => {
          item.scenes.forEach((scene: Scene) => timestamps.push(scene.from));
        });

        const videoObj = {
          mediaInfo: video.mediaInfo,
          name: video.title || video.filename,
          srcKey: video.s3Path,
          timestamps,
        };

        transformedVideos.push(videoObj);
      });

      const data = {
        videos: transformedVideos,
        width,
        height: (width / 16) * 9,
      } as GenerateThumbnailsBody;

      const response = (await axios.post(
        `${windowFork.config.REACT_APP_SHORT_API_URL}/thumbnails`,
        data,
      )) as AxiosResponse;

      const link = document.createElement('a');
      link.download = videoDetails.filename;
      link.target = '_blank';

      link.href = response.data.url;

      document.body.appendChild(link);
      link.click();

      setLoading(false);
      closeModal();
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  const changeWidth = (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;

    if (!Number.isNaN(value)) {
      setWidth(value);
    }
  };

  return (
    <div className={styles.ThumbnailsExportModal}>
      <p className={styles.ThumbnailsExportModal__title}>Export images</p>

      <div className={styles.ThumbnailsExportModal__container}>
        <p className={styles.ThumbnailsExportModal__aspectRatio}>
          Aspect ratio: 16:9
        </p>
        <div className={styles.ThumbnailsExportModal__inputWrap}>
          <p className={styles.ThumbnailsExportModal__inputLabel}>
            Width:
          </p>
          <NewInput value={width} onChange={changeWidth} />
        </div>
      </div>
      <div className={styles.ThumbnailsExportModal__actionButtons}>
        <NewButton
          color={Colors.PINK}
          textColor={Colors.WHITE}
          borderRadius={5}
          width={132}
          height={46}
          onClick={downloadThumbnails}
          disabled={isLoading}
          loading={isLoading}
        >
          Export
        </NewButton>
      </div>
      {closeButton}
    </div>
  );
};

export default ThumbnailsExportModal;
