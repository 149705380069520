import Loader from 'react-loader-spinner';
import { Colors } from 'styles';

import styles from './styles.module.scss';

interface Props {
  title: string;
  message: string;
}

const SubtitlesLoader = (props: Props): JSX.Element => {
  const {
    title,
    message,
  } = props;

  return (
    <div className={styles.SubtitlesLoader}>
      <div className={styles.SubtitlesLoader__topLine}>
        <p className={styles.SubtitlesLoader__title}>
          {title}
        </p>
        <Loader type="Oval" color={Colors.TURQUOISE} height={20} width={20} />
      </div>
      <p className={styles.SubtitlesLoader__description}>
        {message}
      </p>
    </div>
  )
}

export default SubtitlesLoader;