import { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { NewButton } from 'components/UI';

import { getModalOptionalData } from 'state/modules/modal';
import { getVideoInfo } from 'state/modules/media';
import {
  ClipGenerationData,
  ClipGenerationDataClip,
  ClipGenerationDataClipTimestamp,
  ClipOrder,
  generateClips,
  getClipsRenderingLoading,
} from 'state/modules/generation';

import { Colors } from 'styles';
import { getJobDetails } from 'state/modules/jobs';
import ClipPreview from 'components/ClipPreview';
import styles from './styles.module.scss';

const PreviewsModal = (): JSX.Element => {
  const [selectedClips, setSelectedClips] = useState<
    Array<ClipGenerationDataClip>
  >([]);

  const previewsData = useSelector(getModalOptionalData);
  const videoDetails = useSelector(getVideoInfo);
  const clipsRenderingLoading = useSelector(getClipsRenderingLoading);

  const jobDetails = useSelector(getJobDetails(previewsData.jobId));

  const dispatch = useDispatch();

  const toggleClipSelectedStatus = (
    clip: ClipGenerationDataClip,
    isSelected: boolean,
  ) => {
    let updatedSelectedClips = [...selectedClips];

    if (isSelected) {
      updatedSelectedClips = updatedSelectedClips.filter(
        (item: ClipGenerationDataClip) => item.clipId !== clip.clipId,
      );
    } else {
      updatedSelectedClips.push(clip);
    }

    setSelectedClips(updatedSelectedClips);
  };

  const handleGenerate = () => {
    if (videoDetails) {
      dispatch(
        generateClips({
          videoId: videoDetails.id,
          previewsData,
          selectedClips,
        }),
      );
    }
  };

  const renderBottomPanel = () => (selectedClips.length ? (
    <div className={styles.PreviewsModal__bottomPanel}>
      <NewButton
        color={Colors.ULTRAVIOLET}
        borderRadius={12}
        height={36}
        width={132}
        textColor="#ffffff"
        onClick={handleGenerate}
        loading={clipsRenderingLoading}
        disabled={clipsRenderingLoading}
      >
        Generate Clips
      </NewButton>
    </div>
  ) : null);

  const renderClipPreviews = (clips: Array<ClipGenerationDataClip>) => (
    <div className={styles.PreviewsModal__clips}>
      {videoDetails
        ? [clips[0]].map((clip: ClipGenerationDataClip) => {

          const shotsDurationArray = clip.timestamps.map(
            (item: ClipGenerationDataClipTimestamp) => item.to - item.from,
          );

          const shotsDuration = shotsDurationArray.reduce(
            (accumulator: number, current: number): number => accumulator + current,
            0,
          );

          const isSelected = selectedClips.includes(clip);

          return (
            <div
              key={clip.clipId}
              className={styles.PreviewsModal__clip}
            >
              <ClipPreview
                videoDetails={videoDetails}
                shots={clip.timestamps}
                isMuted={clip.isMuted}
                shotsDuration={shotsDuration}
                title={`Clip V${clip.clipVersion}`}
                isSelected={isSelected}
                data={clip}
                onClick={toggleClipSelectedStatus}
                isTogglerVisible
              />
            </div>
          );
        })
        : null}
    </div>
  );

  const renderPreviewsData = (previewsData: ClipGenerationData) => {
    const settings = {
      minClipLength:
        previewsData.minClipLength
        || jobDetails?.clipData?.minClipLength,
      maxClipLength:
        previewsData.maxClipLength
        || jobDetails?.clipData?.maxClipLength,
      minSceneLength:
        previewsData.minSceneLength
        || jobDetails?.clipData?.minSceneLength,
      maxSceneLength:
        previewsData.maxSceneLength
        || jobDetails?.clipData?.maxSceneLength,
      order: previewsData.order || jobDetails?.clipData?.order,
      diffFactor:
        previewsData.diffFactor || jobDetails?.clipData?.diffFactor,
    };

    return (
      <div className={styles.PreviewsModal__info}>
        <p className={styles.PreviewsModal__infoTitle}>Settings:</p>
        <div className={styles.PreviewsModal__infoContainer}>
          <p className={styles.PreviewsModal__infoItem}>
            Template:
            {' '}
            {previewsData.template}
          </p>
          {settings.minClipLength ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Min clip length: ${settings.minClipLength / 1000
                }s`}
            </p>
          ) : null}
          {settings.maxClipLength ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Max clip length: ${settings.maxClipLength / 1000
                }s`}
            </p>
          ) : null}
          {settings.minSceneLength ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Min scene length: ${settings.minSceneLength / 1000
                }s`}
            </p>
          ) : null}
          {settings.maxSceneLength ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Max scene length: ${settings.maxSceneLength / 1000
                }s`}
            </p>
          ) : null}
          <p className={styles.PreviewsModal__infoItem}>
            {`Sound: ${previewsData.clips[0].isMuted ? 'Off' : 'On'
              }`}
          </p>
          {settings.order ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Order: ${settings.order === ClipOrder.LOGICAL
                ? 'Logical'
                : 'Random'
                }`}
            </p>
          ) : null}
          {settings.diffFactor ? (
            <p className={styles.PreviewsModal__infoItem}>
              {`Shots difference: ${settings.diffFactor * 100}%`}
            </p>
          ) : null}
          <p className={styles.PreviewsModal__infoItem}>
            {`Creation date: ${moment(
              previewsData.creationDate,
            ).format('MMM Do YYYY, h:mm')}`}
          </p>
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <div className={styles.PreviewsModal__content}>
      {previewsData && videoDetails
        ? renderClipPreviews(previewsData.clips)
        : null}
    </div>
  );

  return (
    <div className={styles.PreviewsModal}>
      <div className={styles.PreviewsModal__topLine}>
        <p className={styles.PreviewsModal__title}>Clips previews</p>
        {previewsData ? renderPreviewsData(previewsData) : null}
      </div>
      {renderContent()}
      {renderBottomPanel()}
    </div>
  );
};

export default PreviewsModal;
