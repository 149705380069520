/* eslint-disable no-return-await */
import styled from 'styled-components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Icon, NewButton } from 'components/UI';

import { hideModal } from 'state/modules/modal';
import {
    getInvitationCode,
    getInviteCode,
    getInviteCodeGenerationLoading,
    getInvites,
    getInvitesTotal,
    getSendInviteCodeLoading,
    sendInvite,
} from 'state/modules/user';

const Label = styled.p`
    color: rgb(93, 100, 123);
    margin-bottom: 5px;
`;

const LinkValue = styled.p`
    flex: 1;
    padding: 0 16px;
    color: #313131;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid rgb(223, 224, 229);
    border-radius: 0.5rem;
`;

const Input = styled.input`
    border-radius: 0.5rem;
    padding: 16px;
    font-size: inherit;
    border: 1px solid rgb(223, 224, 229);
    color: rgb(93, 100, 123);
    height: 3rem;
    margin-right: 15px;
    flex: 1;
    color: #313131;

    &:hover,
    &:focus {
        border-color: rgb(103, 193, 255);
    }

    &::placeholder {
        color: #313131;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const InputContainer = styled.form`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
`;

const AvailableInvitesCount = styled.span<{ isOverlimit?: boolean }>`
    color: ${(isOverlimit) => (isOverlimit ? '#ff0000' : '#000000')};
`;

const AvailableInvites = styled.p`
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
    font-family: 'Proxima Nova Medium', sans-serif;
`;

const Message = styled.p`
    font-size: 16px;
    font-family: 'Proxima Nova', sans-serif;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;

    & > span {
        font-family: 'Proxima Nova Semibold', sans-serif;
        text-decoration: underline;
    }
`;

const InvitesLink = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Proxima Nova Semibold', sans-serif;
    text-decoration: underline;
    margin-top: 25px;
    font-size: 16px;
`;

const Title = styled.p`
    font-size: 26px;
    font-family: 'Proxima Nova Medium', sans-serif;
    margin-bottom: 20px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
`;

const Container = styled.div`
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 36rem;
    min-width: initial;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    border-radius: 1.25rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px;
    transition: box-shadow 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;
    padding: 1.5rem;
    position: relative;
`;

const URL = `https://app.wearenova.ai`;

const InviteModal = () => {
    const [email, setEmail] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    const inviteCode = useSelector(getInvitationCode);
    const isLoading = useSelector(getSendInviteCodeLoading);
    const isGenerationLoading = useSelector(getInviteCodeGenerationLoading);
    const invitesTotal = useSelector(getInvitesTotal);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInvites({}));
    }, [dispatch]);

    const isInvitesDisabled = useMemo(() => invitesTotal >= 3, [invitesTotal]);

    const link = useMemo(
        () =>
            inviteCode.length ? `${URL}/signup?invitation=${inviteCode}` : '',
        [inviteCode]
    );

    const closeModal = () => {
        dispatch(hideModal());
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setEmail(value);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        dispatch(sendInvite(email));
    };

    const handleGenerateCode = () => {
        dispatch(getInviteCode());
    };

    const navigateToInvites = () => {
        navigate('/video-editing/invites');
        closeModal();
    };

    const copyTextToClipboard = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(link);
        }
        return document.execCommand('copy', true, link);
    };

    const handleCopy = async () => {
        copyTextToClipboard().then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        });
    };

    const renderCloseButton = () => (
        <CloseButton onClick={closeModal}>
            <Icon name="close" size={15} />
        </CloseButton>
    );

    const renderLinkButton = () => {
        return (
            <NewButton
                color="rgb(5,27,90)"
                textColor="#fff"
                type="button"
                height={48}
                width={128}
                borderRadius={8}
                onClick={link.length ? handleCopy : handleGenerateCode}
                loading={isGenerationLoading}
                disabled={isGenerationLoading || isInvitesDisabled}
            >
                {link.length ? (isCopied ? 'Copied!' : 'Copy') : 'Generate'}
            </NewButton>
        );
    };

    return (
        <Container>
            <Title>Invite a friend</Title>
            <Message>
                Invite a friend and you both get{' '}
                <span>2 hours of Subtitling/Translation</span> <br /> as a bonus
                when they sign up
            </Message>
            <Label>Invite via email</Label>
            <InputContainer onSubmit={handleSubmit}>
                <Input
                    placeholder="Enter email address here"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    height={36}
                    disabled={isInvitesDisabled}
                />
                <NewButton
                    color="rgb(5,27,90)"
                    textColor="#fff"
                    type="submit"
                    height={48}
                    width={128}
                    borderRadius={8}
                    disabled={isLoading || !email.length || isInvitesDisabled}
                    loading={isLoading}
                >
                    Invite
                </NewButton>
            </InputContainer>
            <Label>Copy your invitation link</Label>
            <LinkContainer>
                <LinkValue>{link}</LinkValue>
                {renderLinkButton()}
            </LinkContainer>
            <InvitesLink onClick={navigateToInvites}>
                See your active invites here
            </InvitesLink>
            <AvailableInvites>
                {`Available invites: `}
                <AvailableInvitesCount
                    isOverlimit={isInvitesDisabled}
                >{`${Math.max(3 - invitesTotal, 0)}`}</AvailableInvitesCount>
                {` / 3`}
            </AvailableInvites>
            {renderCloseButton()}
        </Container>
    );
};

export default InviteModal;
