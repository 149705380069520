import { createSelector } from 'reselect';

import { State } from 'state/modules';

import { TranscriptState,  } from './types';

export const getTranscriptState = (state: State): TranscriptState => state.transcript;

export const getMediaLanguagesCodes = createSelector(
  getTranscriptState,
  (state) => state.mediaLanguagesCodes,
);

export const getTranscriptGenerationLoading = createSelector(
  getTranscriptState,
  (state) => state.isTranscriptGenerationLoading,
);

export const getTranslateGenerationLoading = createSelector(
  getTranscriptState,
  (state) => state.isTranslateGenerationLoading,
);

export const getCancelTranslateLoading = createSelector(
  getTranscriptState,
  (state) => state.isCancelTranslateLoading,
);
