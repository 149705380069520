import { ProductPrepareTypes } from 'interfaces/preferences';

export interface BurningSubtitlesTourStatus {
    isUploadVisible: boolean;
    isControlsVisible: boolean;
}
export interface VideoEditingTourStatus {
    isUploadVisible: boolean;
    isControlsVisible: boolean;
}

export interface AppState {
    isStatusBarActive: boolean;
    isBrowserRecommendationActive: boolean;
    userTourStatus: TourStatusesBody | null;
    burningSubtitlesTourStatus: BurningSubtitlesTourStatus;
    videoEditingTourStatus: VideoEditingTourStatus;
    isAppInitLoading: boolean;
}

export enum AppActionTypes {
    SET_STATUS_BAR_ACTIVE_STATUS = '@@app/SET_STATUS_BAR_ACTIVE_STATUS',
    APP_INIT = '@@app/APP_INIT',
    APP_INIT_START = '@@app/APP_INIT_START',
    APP_INIT_SUCCESS = '@@app/APP_INIT_SUCCESS',
    APP_INIT_FAIL = '@@app/APP_INIT_FAIL',
    CHECK_BROWSER_INFO = '@@app/CHECK_BROWSER_INFO',
    SET_BROWSER_RECOMMENDATION_VISIBILITY = '@@app/SET_BROWSER_RECOMMENDATION_VISIBILITY',
    UPDATE_APP_INTRODUCTORY_TOUR_STATUS = '@@app/UPDATE_APP_INTRODUCTORY_TOUR_STATUS',
    SET_APP_INTRODUCTORY_TOUR_STATUS = '@@app/SET_APP_INTRODUCTORY_TOUR_STATUS',
    CHECK_APP_INTRODUCTION_TOUR_STATUS = '@@app/CHECK_APP_INTRODUCTION_TOUR_STATUS',
    SET_BURNING_SUBTITLES_TOUR_STATUS = '@@app/SET_BURNING_SUBTITLES_TOUR_STATUS',
    SET_VIDEO_EDITING_TOUR_STATUS = '@@app/SET_VIDEO_EDITING_TOUR_STATUS',

    CHANGE_LOCATION = '@@app/CHANGE_LOCATION',
}

export enum AppTour {
    VIDEO_LIBRARY = 'videoLibraryTourStatus',
    BURNING_SUBTITLES = 'burningSubtitlesTourStatus',
    COLLECTIONS_LIBRARY = 'collectionsLibraryTourStatus',
    TEMPLATES_LIBRARY = 'templatesLibraryTourStatus',
    VIDEO_EDITING_DASHBOARD = 'videoEditingDashboardTourStatus',
}

export interface TourPartBody {
    isFinished: boolean;
    isRunning: boolean;
}

export interface TourStatusesBody {
    burningSubtitlesTourStatus?: TourPartBody;
    videoLibraryTourStatus?: TourPartBody;
    collectionsLibraryTourStatus?: TourPartBody;
    templatesLibraryTourStatus?: TourPartBody;
    videoEditingDashboardTourStatus?: TourPartBody;
    isAppTourSkipped?: boolean;
}

export interface UserTourStatus {
    userTourStatus: TourStatusesBody;
}

export interface UpdateUserTourBody {
    statuses: UserTourStatus;
}

export interface SetStatusBarActiveStatusAction {
    type: AppActionTypes.SET_STATUS_BAR_ACTIVE_STATUS;
    payload: boolean;
}

export interface AppInitActionPayload {
    navigate: any;
    location: any;
}
export interface AppInitAction {
    type: AppActionTypes.APP_INIT;
    payload: AppInitActionPayload;
}

export interface AppInitStartAction {
    type: AppActionTypes.APP_INIT_START;
}

export interface AppInitSuccessAction {
    type: AppActionTypes.APP_INIT_SUCCESS;
}

export interface AppInitFailAction {
    type: AppActionTypes.APP_INIT_FAIL;
}

export interface CheckBrowserInfoAction {
    type: AppActionTypes.CHECK_BROWSER_INFO;
}

export interface SetBrowserRecommendationVisibilityAction {
    type: AppActionTypes.SET_BROWSER_RECOMMENDATION_VISIBILITY;
    payload: boolean;
}

export interface UpdateAppIntroductoryTourStatusAction {
    type: AppActionTypes.UPDATE_APP_INTRODUCTORY_TOUR_STATUS;
    payload: {
        statuses: TourStatusesBody;
        isAppTourSkipped?: boolean;
    };
}
export interface SetAppIntroductoryTourStatusAction {
    type: AppActionTypes.SET_APP_INTRODUCTORY_TOUR_STATUS;
    payload: TourStatusesBody;
}

export interface CheckAppIntroductionTourStatusAction {
    type: AppActionTypes.CHECK_APP_INTRODUCTION_TOUR_STATUS;
}

export interface SetBurningSubtitlesTourStatusAction {
    type: AppActionTypes.SET_BURNING_SUBTITLES_TOUR_STATUS;
    payload: BurningSubtitlesTourStatus;
}
export interface SetVideoEditingTourStatusAction {
    type: AppActionTypes.SET_VIDEO_EDITING_TOUR_STATUS;
    payload: VideoEditingTourStatus;
}

export interface ChangeLocationAction {
    type: AppActionTypes.CHANGE_LOCATION;
    payload: ProductPrepareTypes;
}

export type AppAction =
    | SetStatusBarActiveStatusAction
    | AppInitAction
    | CheckBrowserInfoAction
    | SetBrowserRecommendationVisibilityAction
    | UpdateAppIntroductoryTourStatusAction
    | SetAppIntroductoryTourStatusAction
    | CheckAppIntroductionTourStatusAction
    | SetBurningSubtitlesTourStatusAction
    | SetVideoEditingTourStatusAction
    | ChangeLocationAction
    | AppInitStartAction
    | AppInitSuccessAction
    | AppInitFailAction;
