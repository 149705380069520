/* eslint-disable @typescript-eslint/ban-types */
import { ChangeEvent, useState } from 'react';
import prettyBytes from 'pretty-bytes';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { NewButton, NewInput, Icon } from 'components/UI';

import { getModalOptionalData } from 'state/modules/modal';
import { getVideoUpdateLoading, updateVideoDetails } from 'state/modules/media';

import { secondsToHms } from 'utils/files';

import { StatusType } from 'interfaces/statuses';
import { MediaFile, MediaType } from 'interfaces/videos';

import { Colors } from 'styles';
import styles from './styles.module.scss';

const windowFork = window;
const IMG_WIDTH = 184;

interface Props {
    closeButton: JSX.Element;
}

const EditVideoModal = (props: Props): JSX.Element => {
    const { closeButton } = props;
    const optionalData = useSelector(getModalOptionalData);
    const {
        videoDetails,
    }: {
        videoDetails: MediaFile;
    } = optionalData;
    const [title, setTitle] = useState(videoDetails.title);
    const [description, setDescription] = useState(
        videoDetails.description || ''
    );
    const isUpdateLoading = useSelector(getVideoUpdateLoading);

    const dispatch = useDispatch();

    const { mediaInfo } = videoDetails;

    const isChanged =
        title !== videoDetails.title ||
        description !== videoDetails.description;

    const videoMediaInfo = mediaInfo?.proxy || mediaInfo?.original;

    const isAudio =
        mediaInfo?.original?.container?.format === 'MPEG Audio' ||
        mediaInfo?.original?.container?.format === 'Wave' ||
        videoDetails?.mediaType === MediaType.AUDIO;

    const changeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.currentTarget.value);
    };

    const changeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.currentTarget.value);
    };

    const handleSave = () => {
        dispatch(
            updateVideoDetails({
                title,
                description,
                videoId: videoDetails.id,
                defaultLanguageCode:
                    videoDetails.defaultLanguage || videoDetails.language,
            })
        );
    };

    const renderThumbnail = () =>
        videoDetails.status?.transcoding?.status === StatusType.SUCCEEDED ||
        videoDetails.status?.analyze ? (
            <img
                alt="preview"
                className={styles.EditVideoModal__fileImg}
                src={`${
                    windowFork.config.REACT_APP_CLOUDFRONT_URL
                }Stage/thumbnail?videoId=${videoDetails.id}&timestamp=${
                    Math.ceil(videoMediaInfo.container.duration * 0.2) * 1000
                }&width=${IMG_WIDTH}`}
            />
        ) : (
            <Loader type="Oval" color="#fd0b50" height={25} width={25} />
        );

    const renderIcon = () => <Icon name="music" size={25} color="#ffffff" />;

    const renderPreview = () => (
        <div
            className={styles.EditVideoModal__imgWrap}
            style={{
                height: `${(IMG_WIDTH / 16) * 9}px`,
                width: `${IMG_WIDTH}px`,
            }}
        >
            {isAudio ? renderIcon() : renderThumbnail()}
        </div>
    );

    const renderMediaInfo = () => (
        <div className={styles.EditVideoModal__mediaInfo}>
            <p className={styles.EditVideoModal__mediaInfoTitle}>Media Info:</p>
            <p className={styles.EditVideoModal__mediaInfoItem}>
                Duration:{' '}
                {secondsToHms(videoMediaInfo?.container?.duration || 0)}
            </p>
            <p className={styles.EditVideoModal__mediaInfoItem}>
                Size: {prettyBytes(videoMediaInfo?.container?.fileSize || 0)}
            </p>
            <p className={styles.EditVideoModal__mediaInfoItem}>
                Format: {mediaInfo?.original?.container?.format || ''}
            </p>
        </div>
    );

    return (
        <div className={styles.EditVideoModal}>
            <div className={styles.EditVideoModal__topLine}>
                {renderPreview()}
                {renderMediaInfo()}
            </div>
            <div>
                <div className={styles.EditVideoModal__inputWrap}>
                    <NewInput
                        onChange={changeTitle}
                        value={title}
                        placeholder="Video Title"
                    />
                </div>

                <div className={styles.EditVideoModal__textareaWrap}>
                    <textarea
                        className={styles.EditVideoModal__description}
                        value={description}
                        onChange={changeDescription}
                        placeholder="Description"
                    />
                </div>
            </div>
            <div className={styles.EditVideoModal__saveButton}>
                <NewButton
                    color={Colors.PINK}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    fontWeight="bold"
                    width={111}
                    height={40}
                    onClick={handleSave}
                    disabled={!isChanged || isUpdateLoading}
                    loading={isUpdateLoading}
                >
                    Save
                </NewButton>
            </div>
            {closeButton}
        </div>
    );
};

export default EditVideoModal;
