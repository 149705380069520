import { useEffect, useMemo, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import prettyBytes from 'pretty-bytes';

import { Icon } from 'components/UI';

import {
    changeUploadingMediaDuration,
    getUploadingStatuses,
    getUploadingVideos,
    setUploadingVideos,
} from 'state/modules/uploading';
import { checkBrowserInfo } from 'state/modules/app';
import { getPreferences } from 'state/modules/user';
import {
    getUserUsage,
    getUserUsageData,
    UserUsageAndTotalSubscriptionData,
} from 'state/modules/payment';
import { hideModal } from 'state/modules/modal';
import { getProjectDetailsInfo } from 'state/modules/projects';
import { uploadProjectVideo } from 'state/modules/projectUpload';

import { UploadingMedia } from 'interfaces/uploading';

import styles from './styles.module.scss';

const ProjectAudioUploadModal = (): JSX.Element => {
    const uploadingStatuses = useSelector(getUploadingStatuses);
    const uploadingVideos = useSelector(getUploadingVideos);

    const userPreferences = useSelector(getPreferences);
    const projectsDetails = useSelector(getProjectDetailsInfo);

    const userUsage = useSelector(
        getUserUsageData
    ) as UserUsageAndTotalSubscriptionData;

    const dispatch = useDispatch();

    const timeOutIdRef = useRef(null) as any;

    useEffect(
        () => () => {
            clearTimeout(timeOutIdRef?.current);
        },
        [timeOutIdRef]
    );

    const mediaSourcesIds = useMemo(
        () => projectsDetails?.mediaSources || [],
        [projectsDetails]
    );

    const projectUploadingVideos = useMemo(
        () =>
            uploadingVideos.filter((video) =>
                mediaSourcesIds.includes(video.id)
            ),
        [uploadingVideos, mediaSourcesIds]
    );

    const projectUploadingStatuses = useMemo(
        () =>
            uploadingStatuses.filter((status) =>
                mediaSourcesIds.includes(status.id)
            ),
        [uploadingStatuses, mediaSourcesIds]
    );

    const availableStorage = useMemo(() => {
        if (userUsage) {
            const { total, usage } = userUsage;

            return total.storage - usage.storage;
        }
        return 0;
    }, [userUsage]);

    useEffect(() => {
        dispatch(checkBrowserInfo());
        dispatch(getUserUsage());
    }, [dispatch]);

    const handleSetUploadingVideos = (newVideos: Array<UploadingMedia>) => {
        dispatch(setUploadingVideos(newVideos));
    };

    const closeModal = () => {
        dispatch(hideModal());
    };

    const setVideoDuration = (file: any, id: string) => {
        const audio = document.createElement('audio');
        const fileURL = URL.createObjectURL(file);
        audio.src = fileURL;

        audio.ondurationchange = () => {
            const duration = audio.duration || 0;

            dispatch(
                changeUploadingMediaDuration({
                    duration,
                    id,
                })
            );

            URL.revokeObjectURL(fileURL);
        };
    };

    const handleConfirmUpload = (files: UploadingMedia[]) => {
        dispatch(
            uploadProjectVideo({
                videos: files,
            })
        );
    };

    const handleUploadLocalFile = (files: Array<any>) => {
        if (files.length) {
            const changedFiles = files.map((file: File) => {
                const id = uuid();

                setVideoDuration(file, id);

                return {
                    file,
                    description: '',
                    title: file.name,
                    lastModifiedDate: file.lastModified,
                    size: file.size,
                    type: file.type,
                    id,
                    transcriptLanguage:
                        userPreferences?.defaultTranscriptLanguageCode,
                    collectionId: userPreferences?.defaultUserCollection,
                    autoAnalyze: false,
                    categories: [],
                };
            }) as Array<UploadingMedia>;

            if (projectUploadingVideos.length > 0) {
                handleSetUploadingVideos([...changedFiles, ...uploadingVideos]);
            } else {
                handleSetUploadingVideos(changedFiles);
            }

            handleConfirmUpload(changedFiles);
        }
    };

    const renderDropzoneContent = () => (
        <>
            <Icon name="upload-media" size={30} />
            <p
                className={
                    styles.ProjectUploadModal__dropzoneTitle
                }
            >
                Upload an audio file
            </p>
            <p className={styles.ProjectUploadModal__dropzoneDescription}>
                <span
                    className={
                        styles.ProjectUploadModal__dropzoneDescription_accent
                    }
                >
                    Click to Upload
                </span>
                or Drag and Drop
            </p>
        </>
    );

    const renderDropzone = () =>
        (
            <Dropzone
                onDrop={handleUploadLocalFile}
                multiple
                // disabled={isUploadFromExternalSourceInProgress}
                accept={{
                    'audio/*': [],
                }}
            >
                {({ getRootProps, getInputProps }): JSX.Element => (
                    <section
                        className={styles.ProjectUploadModal__dropzoneWrap}
                    >
                        <div
                            {...getRootProps()}
                            className={styles.ProjectUploadModal__dropzoneRoot}
                        >
                            <input
                                {...getInputProps()}
                                className={styles.ProjectUploadModal__dropzone}
                                // disabled={isUploadFromExternalSourceInProgress}
                            />
                            {renderDropzoneContent()}
                        </div>
                    </section>
                )}
            </Dropzone>
        );

    const renderAvailableStorage = () => (
        <div className={styles.ProjectUploadModal__availableStorage}>
            <p>
                Available storage:
                {` ${prettyBytes(availableStorage)}`}
            </p>
        </div>
    );

    const renderCloseButton = () => (
        <button
            className={styles.ProjectUploadModal__closeBtn}
            onClick={closeModal}
        >
            <Icon name="close" size={15} />
        </button>
    );

    return (
        <div className={styles.ProjectUploadModal}>
            <div className={styles.ProjectUploadModal__topLine}>
                <p className={styles.ProjectUploadModal__title}>Upload audio</p>
                {renderAvailableStorage()}
                {renderCloseButton()}
            </div>
            <div className={styles.ProjectUploadModal__content}>
                {renderDropzone()}
            </div>
        </div>
    );
};

export default ProjectAudioUploadModal;
