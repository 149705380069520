import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1.333; // 16px(default width) / 12px(default height) = 1.333

const CheckIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 12;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2609 0.499126C14.9688 0.206997 14.4953 0.206997 14.2031 0.499126L5.2416 9.46071L1.79695 6.01606C1.50485 5.72393 1.03127 5.72396 0.739116 6.01606C0.446987 6.30816 0.446987 6.78173 0.739116 7.07386L4.71269 11.0474C5.0047 11.3395 5.47863 11.3393 5.77052 11.0474L15.2609 1.55696C15.5531 1.26486 15.553 0.791255 15.2609 0.499126Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
