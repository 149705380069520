import { IconProps } from '../index';

const PlayIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.75 4.67629C5.75 3.68566 6.84736 3.08867 7.67906 3.62683L18.9975 10.9505C19.7588 11.4431 19.7588 12.5569 18.9975 13.0495L7.67906 20.3732C6.84736 20.9113 5.75 20.3143 5.75 19.3237V4.67629Z"
                fill={color}
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PlayIconNew;


