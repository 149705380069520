import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { Analytics, Auth } from 'aws-amplify';

import UserClient from 'services/api/user';
import StatsClient from 'services/api/stats';

import * as userActions from 'state/modules/user/actions';
import * as appActions from 'state/modules/app/actions';

import {
    getAnalysisStatsFilter,
    getDefaultPreferences,
    getGenerationStatsFilter,
    getPreferences,
    getStatsFilter,
    getUploadStatsFilter,
    UserActionTypes,
    defaultStatsFilter,
    AnalysisStatsItem,
    GenerationStatsItem,
    UploadStatsItem,
    ChangeStatsFilterAction,
    GetStatsAction,
    ResetStatsFilterAction,
    GetUploadStatsAction,
    GetAnalysisStatsAction,
    GetGenerationStatsAction,
    GetInvitesAction,
    getInvitesCount,
    getInvitesList,
    getInvitationCode,
    getUserId,
} from 'state/modules/user';

import { UserPreferences } from 'interfaces/preferences';
import { StatsFilterCategory, UserStatsFilter } from 'interfaces/stats';
import { Invitation } from 'interfaces/invites';

import { getDateFromToRange } from 'utils/filter';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import { getFileNameByKey } from 'utils/files';
import { NotificationTypes, showNotification } from 'utils/notifications';

import { SendInviteAction } from './types';
import { CurrentSubscription, getCurrentUserPlan } from '../payment';

export function updateStatsCategory(
    items: Array<UploadStatsItem | GenerationStatsItem | AnalysisStatsItem>
): Array<UploadStatsItem | GenerationStatsItem | AnalysisStatsItem> {
    return items.map(
        (item: UploadStatsItem | GenerationStatsItem | AnalysisStatsItem) => {
            let itemKey = '';

            if ('key' in item) {
                itemKey = item.key;
            }

            if ('videoFileKey' in item) {
                itemKey = item.videoFileKey;
            }

            if (item.videoTitle) {
                return item;
            }
            return {
                ...item,
                videoTitle: itemKey
                    ? getFileNameByKey(itemKey)
                    : 'Deleted video',
            };
        }
    );
}

export function* loadUserUploadStats(
    params: {
        dateFrom: number;
        dateTo: number;
    } | null
): Generator {
    const uploadStats = (yield call(
        StatsClient.getUploadStats,
        params
    )) as AxiosResponse;

    const updatedUploadStats = (yield call(
        updateStatsCategory,
        uploadStats.data.content
    )) as Array<UploadStatsItem>;

    yield put(userActions.setUploadStats(updatedUploadStats));
}

export function* loadUserGenerationStats(
    params: {
        dateFrom: number;
        dateTo: number;
    } | null
): Generator {
    const generationStats = (yield call(
        StatsClient.getGenerationStats,
        params
    )) as AxiosResponse;

    const updatedGenerationStats = (yield call(
        updateStatsCategory,
        generationStats.data.content
    )) as Array<GenerationStatsItem>;

    yield put(userActions.setGenerationStats(updatedGenerationStats));
}

export function* loadUserAnalysisStats(
    params: {
        dateFrom: number;
        dateTo: number;
    } | null
): Generator {
    const analyzeStats = (yield call(
        StatsClient.getAnalyzeStats,
        params
    )) as AxiosResponse;

    const updatedAnalyzeStats = (yield call(
        updateStatsCategory,
        analyzeStats.data.content
    )) as Array<AnalysisStatsItem>;

    yield put(userActions.setAnalysisStats(updatedAnalyzeStats));
}

export function* handleGetStats(
    action:
        | GetStatsAction
        | GetUploadStatsAction
        | GetAnalysisStatsAction
        | GetGenerationStatsAction
): Generator {
    yield put(userActions.getStatsStart());

    let statsFilter = (yield select(getStatsFilter)) as UserStatsFilter;

    if (action.type === UserActionTypes.GET_ANALYSIS_STATS) {
        statsFilter = (yield select(getAnalysisStatsFilter)) as UserStatsFilter;
    }
    if (action.type === UserActionTypes.GET_GENERATION_STATS) {
        statsFilter = (yield select(
            getGenerationStatsFilter
        )) as UserStatsFilter;
    }
    if (action.type === UserActionTypes.GET_UPLOAD_STATS) {
        statsFilter = (yield select(getUploadStatsFilter)) as UserStatsFilter;
    }

    const { dateFilter } = statsFilter;

    const dateFromToRange = getDateFromToRange({
        dateFilter,
        filter: statsFilter,
    }) as {
        dateFrom: Moment;
        dateTo: Moment;
    };

    let filterParams = null;

    if (dateFromToRange) {
        filterParams = {
            dateFrom: moment(dateFromToRange.dateFrom).toDate().getTime(),
            dateTo: moment(dateFromToRange.dateTo).toDate().getTime(),
        };
    }

    try {
        if (action.type === UserActionTypes.GET_STATS) {
            yield call(loadUserUploadStats, filterParams);
            yield call(loadUserAnalysisStats, filterParams);
            yield call(loadUserGenerationStats, filterParams);
        }

        if (action.type === UserActionTypes.GET_ANALYSIS_STATS) {
            yield call(loadUserAnalysisStats, filterParams);
        }
        if (action.type === UserActionTypes.GET_GENERATION_STATS) {
            yield call(loadUserGenerationStats, filterParams);
        }
        if (action.type === UserActionTypes.GET_UPLOAD_STATS) {
            yield call(loadUserUploadStats, filterParams);
        }

        yield put(userActions.getStatsSuccess());
    } catch (err) {
        console.log(err);
        yield put(userActions.getStatsFail());
    }
}

export function* setDefaultPreferences(): Generator {
    try {
        const defaultUserPreferences = (yield select(
            getDefaultPreferences
        )) as UserPreferences;

        yield call(UserClient.updateUserPreference, defaultUserPreferences);

        yield put(userActions.getUserPreferences());
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetUserPreferences(): Generator {
    try {
        const response = (yield call(
            UserClient.getUserPreference
        )) as AxiosResponse;

        // console.log('handleGetUserPreferences', { response });

        let userPreference = response.data.content?.workflowPreferences;

        userPreference = {
            ...userPreference,
            // isFirstLogin: response.data.content?.isFirstLogin,
        };

        yield put(
            userActions.setFirstLoginStatus(
                response.data.content?.isFirstLogin || false
            )
        );

        const projectPreferences = response.data.content?.projectPreferences;
        // const isToggleToSmartSearchAvailable = true;
        const isToggleToSmartSearchAvailable =
            response.data.content?.isToggleToSmartSearchAvailable;

        if (
            !userPreference ||
            !userPreference.hasOwnProperty('autoAnalyse') ||
            !userPreference.hasOwnProperty('defaultTranscriptLanguageCode') ||
            !userPreference.hasOwnProperty('defaultCategories')
        ) {
            yield call(setDefaultPreferences);
        } else if (
            userPreference &&
            userPreference.defaultTranscriptLanguageCode === 'Autodetect'
        ) {
            yield put(
                userActions.setUserPreferences({
                    ...userPreference,
                    defaultTranscriptLanguageCode: null,
                })
            );
        } else {
            yield put(userActions.setUserPreferences(userPreference));
        }

        yield put(
            appActions.setAppIntroductoryTourStatus(
                response.data.content.userTourStatus
            )
        );
        yield put(
            userActions.setToggleToSmartSearchAvailable(
                Boolean(isToggleToSmartSearchAvailable)
            )
        );

        if (projectPreferences) {
            yield put(userActions.setProjectPreferences(projectPreferences));
        }

        // if (!response.data.content?.invitationCode) {
        //     yield put(userActions.getInviteCode());
        // } else {
        //     yield put(
        //         userActions.setInviteCode(response.data.content.invitationCode)
        //     );
        // }
        if (response.data.content?.invitationCode) {
            yield put(
                userActions.setInviteCode(response.data.content.invitationCode)
            );
        }

        return userPreference;
    } catch (err) {
        console.log({ err });
    }
}

export function* handleUpdateUserPreferences(): Generator {
    try {
        yield delay(500);
        const newUserPreferences = (yield select(
            getPreferences
        )) as UserPreferences;
        yield call(UserClient.updateUserPreference, newUserPreferences);
        const response = (yield call(
            UserClient.getUserPreference
        )) as AxiosResponse;

        const userPreference = response.data.content?.workflowPreferences;
        yield put(userActions.setUserPreferences(userPreference));
    } catch (err) {
        console.log(err);
    }
}

export function* handleLogout(): Generator {
    try {
        yield put(userActions.setUser(null));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleChangeStatsFilter(
    action: ChangeStatsFilterAction
): Generator {
    const { field, value, category } = action.payload;
    let statsFilter = (yield select(getStatsFilter)) as UserStatsFilter;

    if (category === StatsFilterCategory.ANALYSIS) {
        statsFilter = (yield select(getAnalysisStatsFilter)) as UserStatsFilter;
    } else if (category === StatsFilterCategory.GENERATION) {
        statsFilter = (yield select(
            getGenerationStatsFilter
        )) as UserStatsFilter;
    } else if (category === StatsFilterCategory.UPLOAD) {
        statsFilter = (yield select(getUploadStatsFilter)) as UserStatsFilter;
    }

    const updatedFilter = {
        ...statsFilter,
        [field]: value,
    };

    if (category === StatsFilterCategory.ANALYSIS) {
        yield put(userActions.setAnalysisStatsFilter(updatedFilter));
    } else if (category === StatsFilterCategory.GENERATION) {
        yield put(userActions.setGenerationStatsFilter(updatedFilter));
    } else if (category === StatsFilterCategory.UPLOAD) {
        yield put(userActions.setUploadStatsFilter(updatedFilter));
    } else {
        yield put(userActions.setStatsFilter(updatedFilter));
    }
}

export function* handleResetStatsFilter(
    action: ResetStatsFilterAction
): Generator {
    const { category } = action.payload;

    if (category === StatsFilterCategory.ANALYSIS) {
        yield put(userActions.setAnalysisStatsFilter(defaultStatsFilter));
        yield put(userActions.getAnalysisStats());
    } else if (category === StatsFilterCategory.GENERATION) {
        yield put(userActions.setGenerationStatsFilter(defaultStatsFilter));
        yield put(userActions.getGenerationStats());
    } else if (category === StatsFilterCategory.UPLOAD) {
        yield put(userActions.setUploadStatsFilter(defaultStatsFilter));
        yield put(userActions.getUploadStats());
    } else {
        yield put(userActions.setStatsFilter(defaultStatsFilter));
        yield put(userActions.getStats());
    }
}

export function* handleGetUser(): Generator {
    try {
        const currentUserInfo: any = yield call([Auth, 'currentUserInfo']);

        let rolesData: string[] = [];

        yield Auth.currentSession()
            .then((session) => {
                const payload = session?.getAccessToken()?.decodePayload();
                if(payload['cognito:groups']) {
                    rolesData = payload['cognito:groups'];
                }
            }).catch((error) => {
                console.log({error})
            });

        yield put(userActions.setUser(currentUserInfo));
        yield put(userActions.setUserRoles(rolesData));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetInviteCode(): Generator {
    yield put(userActions.getInviteCodeStart());

    try {
        const res = (yield call(UserClient.getInviteCode)) as AxiosResponse;

        yield put(userActions.setInviteCode(res.data.newUserInvitationsCode));
    } catch (error) {
        console.log({ error });
        yield put(userActions.getInviteCodeFail());
    }
}

export function* handleSendInvite(action: SendInviteAction): Generator {
    yield put(userActions.sendInviteStart());

    const link = (yield select(getInvitationCode)) as string;
    const userId = (yield select(getUserId)) as string;
    const currentPlan = (yield select(
        getCurrentUserPlan
    )) as CurrentSubscription;

    try {
        if (!link?.length) {
            // (yield call(UserClient.getInviteCode)) as AxiosResponse;
            yield put(userActions.getInviteCode());

            yield take(UserActionTypes.SET_INVITE_CODE);

            yield delay(1500);
        }

        (yield call(UserClient.sendInvite, action.payload)) as AxiosResponse;

        yield put(userActions.sendInviteSuccess());

        yield call(
            showNotification,
            NotificationTypes.success,
            'Your invitation has been successfully sent'
        );

        Analytics.record({
            name: 'invite_a_friend',
            attributes: {
                userId,
                plan: currentPlan.planNameId,
                email: action.payload,
            },
        });

        amplitudeAnalytics.friendReferred();
    } catch (error) {
        console.log({ error });
        yield put(userActions.sendInviteFail());
        yield call(
            showNotification,
            NotificationTypes.error,
            error?.response?.data?.message || ''
        );
    }
}

export function* handleGetInvites(action: GetInvitesAction): Generator {
    yield put(userActions.getInvitesStart());

    const { isLoadMore } = action.payload;

    const count = (yield select(getInvitesCount)) as number;
    const oldInvites = (yield select(getInvitesList)) as Invitation[];

    try {
        const res = (yield call(
            UserClient.getInvitesList,
            isLoadMore ? count : 0
        )) as AxiosResponse;

        let invites = res.data.content;
        const total = res.data._metadata.totalCount;

        if (isLoadMore) {
            invites = [...oldInvites, ...invites];
        }

        yield put(
            userActions.setInvites({
                invites,
                total,
            })
        );
    } catch (error) {
        console.log({ error });
        yield put(userActions.getInvitesFail());
    }
}

export function* userSaga(): Generator {
    yield takeLatest(UserActionTypes.GET_STATS, handleGetStats);
    yield takeLatest(UserActionTypes.GET_ANALYSIS_STATS, handleGetStats);
    yield takeLatest(UserActionTypes.GET_GENERATION_STATS, handleGetStats);
    yield takeLatest(UserActionTypes.GET_UPLOAD_STATS, handleGetStats);
    yield takeLatest(
        UserActionTypes.GET_USER_PREFERENCES,
        handleGetUserPreferences
    );
    yield takeLatest(
        UserActionTypes.UPDATE_USER_PREFERENCES,
        handleUpdateUserPreferences
    );
    yield takeLatest(UserActionTypes.SIGN_OUT, handleLogout);
    yield takeLatest(
        UserActionTypes.CHANGE_STATS_FILTER,
        handleChangeStatsFilter
    );
    yield takeLatest(
        UserActionTypes.RESET_STATS_FILTER,
        handleResetStatsFilter
    );
    yield takeLatest(UserActionTypes.GET_USER, handleGetUser);
    yield takeLatest(UserActionTypes.GET_INVITE_CODE, handleGetInviteCode);
    yield takeLatest(UserActionTypes.SEND_INVITE, handleSendInvite);

    yield takeLatest(UserActionTypes.GET_INVITES, handleGetInvites);
}
