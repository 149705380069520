import { IconProps } from '../index';

const TransitionsIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25 4.84375C5.47335 4.84375 4.84375 5.47335 4.84375 6.25V11.5C4.84375 11.966 4.46599 12.3438 4 12.3438C3.53401 12.3438 3.15625 11.966 3.15625 11.5V6.25C3.15625 4.54137 4.54137 3.15625 6.25 3.15625H11.5C11.966 3.15625 12.3438 3.53401 12.3438 4C12.3438 4.46599 11.966 4.84375 11.5 4.84375H6.25ZM13.197 12.1363C13.4899 12.4292 13.4899 12.9041 13.197 13.197L11.144 15.25H20.856L18.803 13.197C18.5101 12.9041 18.5101 12.4292 18.803 12.1363C19.0959 11.8434 19.5708 11.8434 19.8637 12.1363L23.197 15.4697C23.4899 15.7626 23.4899 16.2374 23.197 16.5303L19.8637 19.8637C19.5708 20.1566 19.0959 20.1566 18.803 19.8637C18.5101 19.5708 18.5101 19.0959 18.803 18.803L20.856 16.75H11.144L13.197 18.803C13.4899 19.0959 13.4899 19.5708 13.197 19.8637C12.9041 20.1566 12.4292 20.1566 12.1363 19.8637L8.803 16.5303C8.51011 16.2374 8.51011 15.7626 8.803 15.4697L12.1363 12.1363C12.4292 11.8434 12.9041 11.8434 13.197 12.1363ZM4.84375 25.75C4.84375 26.5266 5.47335 27.1562 6.25 27.1562H11.5C11.966 27.1562 12.3438 27.534 12.3438 28C12.3438 28.466 11.966 28.8438 11.5 28.8438H6.25C4.54137 28.8438 3.15625 27.4586 3.15625 25.75V20.5C3.15625 20.034 3.53401 19.6562 4 19.6562C4.46599 19.6562 4.84375 20.034 4.84375 20.5V25.75ZM27.1562 6.25C27.1562 5.47335 26.5266 4.84375 25.75 4.84375H20.5C20.034 4.84375 19.6562 4.46599 19.6562 4C19.6562 3.53401 20.034 3.15625 20.5 3.15625H25.75C27.4586 3.15625 28.8438 4.54137 28.8438 6.25V11.5C28.8438 11.966 28.466 12.3438 28 12.3438C27.534 12.3438 27.1562 11.966 27.1562 11.5V6.25ZM25.75 27.1562C26.5266 27.1562 27.1562 26.5266 27.1562 25.75V20.5C27.1562 20.034 27.534 19.6562 28 19.6562C28.466 19.6562 28.8438 20.034 28.8438 20.5V25.75C28.8438 27.4586 27.4586 28.8438 25.75 28.8438H20.5C20.034 28.8438 19.6562 28.466 19.6562 28C19.6562 27.534 20.034 27.1562 20.5 27.1562H25.75Z"
                fill={color}
            />
        </svg>
    );
};

export default TransitionsIconNew;


