import { IconProps } from '../index';

const TextBounceIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.18 19.633h-1.087l-1.348-2.924H11.14l-1.336 2.924H8.67l4.13-9.083h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.661zM33.33 29.897a3.312 3.312 0 0 1-1.349 1.146 4.095 4.095 0 0 1-1.79.394c-1.003 0-1.842-.33-2.519-.991-.668-.66-1.002-1.468-1.002-2.423 0-.955.338-1.77 1.014-2.447.677-.684 1.552-1.026 2.626-1.026.637 0 1.221.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.581-.756-1.34-1.134-2.28-1.134-.74 0-1.36.239-1.862.716-.5.47-.752 1.054-.752 1.755 0 .7.247 1.3.74 1.802.494.501 1.118.752 1.874.752.907 0 1.671-.382 2.292-1.146l.764.525z"
                fill="#F0F0F2" />
            <path
                d="M33.33 27.897a3.312 3.312 0 0 1-1.349 1.146 4.095 4.095 0 0 1-1.79.394c-1.003 0-1.842-.33-2.519-.991-.668-.66-1.002-1.468-1.002-2.423 0-.955.338-1.77 1.014-2.447.677-.684 1.552-1.026 2.626-1.026.637 0 1.221.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.581-.756-1.34-1.134-2.28-1.134-.74 0-1.36.239-1.862.716-.5.47-.752 1.054-.752 1.755 0 .7.247 1.3.74 1.802.494.501 1.118.752 1.874.752.907 0 1.671-.382 2.292-1.146l.764.525zM17.18 23.633h-1.087l-1.348-2.924H11.14l-1.336 2.924H8.67l4.13-9.083h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.661z"
                fill="#D0D0D0" />
            <path
                d="M17.38 26.983h-1.086l-1.349-2.925h-3.604l-1.337 2.925H8.87L13 17.9h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662zM19.4 21.433c.676-.748 1.487-1.122 2.434-1.122.947 0 1.743.338 2.387 1.014.653.669.979 1.476.979 2.423 0 .947-.33 1.759-.99 2.435-.661.676-1.46 1.015-2.4 1.015-.93 0-1.734-.406-2.41-1.218v1.003h-.967V18.03h.966v3.402zm2.374 4.81c.677 0 1.25-.251 1.72-.752.477-.502.715-1.086.715-1.755 0-.676-.23-1.265-.692-1.766a2.254 2.254 0 0 0-1.73-.752c-.693 0-1.274.235-1.743.704-.462.47-.692 1.062-.692 1.779 0 .708.234 1.308.704 1.802.47.493 1.042.74 1.718.74zM32.87 25.658a3.312 3.312 0 0 1-1.349 1.146 4.095 4.095 0 0 1-1.79.394c-1.003 0-1.842-.33-2.518-.991-.669-.66-1.003-1.468-1.003-2.423 0-.955.338-1.77 1.014-2.447.677-.684 1.552-1.026 2.626-1.026.637 0 1.222.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.58-.756-1.34-1.134-2.28-1.134-.74 0-1.36.238-1.861.716-.502.47-.752 1.054-.752 1.754s.246 1.301.74 1.803c.493.5 1.117.752 1.873.752.907 0 1.671-.383 2.292-1.146l.764.525z"
                fill="#525253" />
        </svg>
    );
};

export default TextBounceIcon;