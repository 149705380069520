import produce from 'immer';

import { JobsActionTypes, JobsState, JobsAction } from 'state/modules/jobs';
import { ClearDataAction, VideosActionTypes } from 'state/modules/media';
import { SignOutAction, UserActionTypes } from 'state/modules/user';

const initialState: JobsState = {
  jobsList: [],
  videoJobs: [],
};

export default (
  state = initialState,
  action: JobsAction | SignOutAction | ClearDataAction,
): JobsState => produce(state, (draft) => {
  switch (action.type) {
    case JobsActionTypes.SET_VIDEO_JOBS:
      draft.videoJobs = action.payload;

      return;

    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      return initialState;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
