import { IconProps } from './index';

const ZoomInIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;
    if (size) {
        height = size;
    }
    const width = height;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_424_4332)">
                <path
                    d="M16.25 8.125C16.25 9.91797 15.668 11.5742 14.6875 12.918L19.6328 17.8672C20.1211 18.3555 20.1211 19.1484 19.6328 19.6367C19.1445 20.125 18.3516 20.125 17.8633 19.6367L12.918 14.6875C11.5742 15.6719 9.91797 16.25 8.125 16.25C3.63672 16.25 0 12.6133 0 8.125C0 3.63672 3.63672 0 8.125 0C12.6133 0 16.25 3.63672 16.25 8.125ZM7.1875 11.5625C7.1875 12.082 7.60547 12.5 8.125 12.5C8.64453 12.5 9.0625 12.082 9.0625 11.5625V9.0625H11.5625C12.082 9.0625 12.5 8.64453 12.5 8.125C12.5 7.60547 12.082 7.1875 11.5625 7.1875H9.0625V4.6875C9.0625 4.16797 8.64453 3.75 8.125 3.75C7.60547 3.75 7.1875 4.16797 7.1875 4.6875V7.1875H4.6875C4.16797 7.1875 3.75 7.60547 3.75 8.125C3.75 8.64453 4.16797 9.0625 4.6875 9.0625H7.1875V11.5625Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_424_4332">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ZoomInIcon;
