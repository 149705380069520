import { createSelector } from 'reselect';
import { State } from 'state/modules';
import { TemplatesState } from './types';

export const getTemplatesState = (state: State): TemplatesState =>
    state.templates;

export const getTemplatesList = createSelector(
    getTemplatesState,
    (state) => state.templatesList
);

export const getTemplatesIdsForDelete = createSelector(
    getTemplatesState,
    (state) => state.templatesIdsForDelete
);

export const getTemplatesListTotal = createSelector(
    getTemplatesState,
    (templatesState) => templatesState.total
);

export const getTemplateDetails = createSelector(
    getTemplatesState,
    (state) => state.templateDetails
);

export const getTemplateListLoading = createSelector(
    getTemplatesState,
    (state) => state.isLoading
);

export const getTemplateDetailsLoading = createSelector(
    getTemplatesState,
    (state) => state.isTemplateDetailsLoading
);

export const getTemplateUpdateLoading = createSelector(
    getTemplatesState,
    (state) => state.isTemplateUpdateLoading
);

export const getTemplateCreationLoading = createSelector(
    getTemplatesState,
    (state) => state.isTemplateCreationLoading
);

export const checkIsTemplateDeleting = (id: string) =>
    createSelector(
        getTemplatesIdsForDelete,
        (templatesIdsForDelete) =>
            templatesIdsForDelete.findIndex(
                (templateId: string) => templateId === id
            ) >= 0
    );

export const getSelectedTemplates = createSelector(
    getTemplatesState,
    (state) => state.selectedTemplates
);

export const getTemplateIsSelectedStatus = (id: string) =>
    createSelector(
        getSelectedTemplates,
        (selectedTemplates) =>
            selectedTemplates.findIndex((item: string) => item === id) >= 0
    );
