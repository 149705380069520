import { AxiosResponse } from 'axios';
import { Folder } from 'interfaces/folder';
import { build, omit } from 'search-params';
import store from 'state/store';
import $http from './instance';

class FoldersClient {
    public static async getFolders(
        offset: number,
        subFolderId?: string
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user?.userInfo?.id;
        const workspaceId = workspaces?.currentWorkspace?.id;

        let query = build({
            limit: 500,
            offset,
            subFolderId,
        });

        if (!subFolderId) {
            query = omit(query, ['subFolderId']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/folders?${query}`
        );
    }

    public static async getFolderById(id: string): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/folders/${id}`
        );
    }

    public static async getFolderBySubfolderId(
        id: string
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/folders/folder/${id}`
        );
    }

    public static async createFolder(data: Folder): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/folders`,
            data
        );
    }

    public static async updateFolder(
        id: string,
        data: Folder
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/folders/${id}`,
            data
        );
    }

    public static async deleteFolder(id: string): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/folders/${id}`
        );
    }
}

export default FoldersClient;
