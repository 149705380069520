import React, { memo } from 'react';

import styles from './styles.module.scss';

const ButtonLoader = ({ color = '#fff', size = '4' }) => (
  <svg
    className={styles.ButtonLoader}
    width="70"
    height="26"
    viewBox="0 0 92 26"
  >
    <circle cx="9" cy="13" r={size} fill={color} />
    <circle cx="32" cy="13" r={size} fill={color} />
    <circle cx="55" cy="13" r={size} fill={color} />
    <circle cx="78" cy="13" r={size} fill={color} />
  </svg>
);

export default memo(ButtonLoader);
