import styled from 'styled-components';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from 'components/UI';

import { Folder } from 'interfaces/folder';

const ErrorMessage = styled.p`
    margin-top: 10px;
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: #ff0000;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Header = styled.div<{ isActive: boolean }>`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    user-select: none;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background: ${({ isActive }) =>
        isActive ? 'rgb(239, 249, 255)' : 'white'};
    color: ${({ isActive }) =>
        isActive ? 'rgb(0, 152, 253)' : 'rgb(93, 100, 123)'};
    cursor: pointer;

    ${TitleContainer} {
        & svg path {
            stroke: ${({ isActive }) =>
                isActive ? 'rgb(0, 152, 253)' : 'rgb(93, 100, 123)'};
        }
    }

    &:hover {
        background: rgb(239, 249, 255);

        ${TitleContainer} {
            color: rgb(0, 152, 253);

            & svg path {
                stroke: rgb(0, 152, 253);
            }
        }
    }
`;

const TitleInput = styled.input`
    margin-left: 10px;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgb(144, 148, 165);
`;

interface Props {
    selectedFolderId?: string;
    folder: Folder;
    onSelect: () => void;
    onConfirmCreate: (folder: Folder) => void;
    hasError?: boolean;
    onClearError?: () => void;
}

const TempNewFolder = ({
    selectedFolderId,
    folder,
    onSelect,
    onConfirmCreate,
    hasError,
    onClearError,
}: Props) => {
    const [name, setName] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const isActive = useMemo(
        () => selectedFolderId === folder.id,
        [selectedFolderId, folder]
    );

    const handleKeyDown = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            onConfirmCreate({
                ...folder,
                name: (event.target as any).value,
            });

            setDisabled(true);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setName(value);

        if (hasError && onClearError) {
            onClearError();
        }
    };

    return (
        <>
            <Header onClick={onSelect} isActive={isActive}>
                <TitleContainer>
                    <Icon
                        name="folder-outline"
                        size={20}
                        color="rgb(93, 100, 123)"
                    />
                    <TitleInput
                        value={name}
                        onChange={handleChange}
                        disabled={isDisabled}
                        ref={inputRef}
                        placeholder="Enter name"
                        onKeyDown={handleKeyDown}
                        data-id="temp-folder-input"
                    />
                </TitleContainer>
            </Header>
            {hasError ? <ErrorMessage>Enter a folder name</ErrorMessage> : null}
        </>
    );
};

export default TempNewFolder;
