import {
  RawAnalysisResultItem,
  TransformAutocompleteItem,
} from 'state/modules/metadata';

export const transformAutocompleteResults = (
  results: Array<RawAnalysisResultItem>,
  videoId: string,
): Array<TransformAutocompleteItem> => results.map((item: RawAnalysisResultItem) => ({
  ...item,
  type: item.labelType,
  id: item.name + item.labelType + videoId,
  videoId,
}));
