/* eslint-disable no-restricted-globals */
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';

import { Icon } from 'components/UI';

import {
    deleteWorkspace,
    getCurrentWorkspaceInfo,
    getWorkspaceUpdateLoading,
    leaveWorkspace,
    updateWorkspace,
} from 'state/modules/workspaces';
import { getCurrentUserPlan, PlanNameId } from 'state/modules/payment';
import {
    ModalEvent,
    ModalType,
    hideModal,
    showModal,
} from 'state/modules/modal';
import WorkspacePlanCard from 'components/WorkspacePlanCard';
import { useNavigate } from 'react-router-dom';
import {
    BLUE_100,
    NEUTRAL_100,
    NEUTRAL_200,
    NEUTRAL_300,
    NEUTRAL_400,
    NEUTRAL_500,
    NEUTRAL_600,
    PURPLE_200,
    PURPLE_PRIMARY,
    RED_200,
    WHITE_PRIMARY,
} from '../../../styles/colors';

const Container = styled.div`
    flex: 3 1 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 1.5rem;
    padding-top: 2.25rem;
`;

const WorkspaceInfo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const Avatar = styled.div`
    background: ${PURPLE_PRIMARY};
    width: 3.875rem;
    height: 3.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 8px;
`;

const AvatarPlaceholder = styled.span`
    font-family: 'Inter Bold', sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    color: ${WHITE_PRIMARY};
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2rem;
`;

const NameContainer = styled.div`
    margin-left: 1rem;
`;

const NameLabel = styled.p`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 0.625rem;
    text-align: left;
    line-height: 0.781rem;
    letter-spacing: 0;
    color: ${NEUTRAL_300};
    margin-bottom: 0.25rem;
`;

const NameInputContainer = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${NEUTRAL_100};
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    font-size: .8125rem;
`;

const Input = styled.input`
    border: none;
    background: inherit;
    width: 80%;
    caret-color: ${BLUE_100};
    outline: none;
    color: ${NEUTRAL_500};
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
`;

const ActionButton = styled.button<{ isPrimary?: boolean }>`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    color: ${({ isPrimary }) =>
    isPrimary ? PURPLE_200 : NEUTRAL_300};
    padding: 1rem 0.5rem 1rem 0;
    cursor: pointer;
    background: none;
    border: none;

    &:disabled {
        cursor: not-allowed;
    }
`;

const NameValueContainer = styled.div`
    display: flex;
`;

const NameValue = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: left;
    color: ${NEUTRAL_600};
`;

const EditButton = styled.div`
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
`;

const Info = styled.div`
    margin-left: auto;
`;

const InfoLabel = styled.p`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 0.781rem;
    text-align: left;
    letter-spacing: 0;
    color: ${NEUTRAL_300};
    margin-bottom: 0.25rem;
`;

const DateCreated = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.781rem;
    text-align: left;
    color: ${NEUTRAL_600};
    letter-spacing: 0;
`;

const PlanContainer = styled.div`
    padding-top: 3rem;
`;

const CurrentPlanInfoContainer = styled.div`
    display: flex;
    background-color: ${WHITE_PRIMARY};
    border-radius: 0.625rem;
    padding: 0.875rem 0;

    & > :first-child {
        margin-right: 1.25rem;
    }
`;

const CurrentPlanInfo = styled.div`
    font-size: 0.813rem;
`;

const CurrentPlanInfoHeader = styled.p`
    margin-bottom: 0.313rem;
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
`;

const CurrentPlanInfoData = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
`;

const LeaveButton = styled.button`
    cursor: pointer;
    background-color: ${NEUTRAL_200};
    border: none;
    outline: none;
    display: inline-flex;
    justify-self: end;
    align-self: end;
    color: ${WHITE_PRIMARY};
    font-family: 'Inter Medium', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 6px;
    align-items: center;
    margin-top: auto;

    &:hover {
        opacity: 0.85;
    }

    span {
        margin-left: 8px;
    }
`;

const DeleteButton = styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    display: inline-flex;
    justify-self: end;
    align-self: end;
    color: ${NEUTRAL_300};
    font-family: 'Inter Medium', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 6px;
    align-items: center;
    margin-top: auto;
    background: transparent;

    &:hover {
        background: ${RED_200};
        color: ${WHITE_PRIMARY};

        path {
            fill: ${RED_200};
            stroke: ${WHITE_PRIMARY}
        }
    }

    span {
        margin-left: 8px;
    }
`;

const ButtonLabel = styled.span`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
`;

interface Props {
    isCurrentUserOwner: boolean;
    seePlanLimitsInfo: () => void;
}

const WorkspaceSettings = ({ isCurrentUserOwner, seePlanLimitsInfo }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);
    const isWorkspaceUpdateLoading = useSelector(getWorkspaceUpdateLoading);
    const currentPlan = useSelector(getCurrentUserPlan);

    const [isEditActive, setEditActive] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        if (currentWorkspace) {
            setName(currentWorkspace.name);
        }
    }, [currentWorkspace]);

    const workspaceName = useMemo(() => {
        return name || '';
    }, [name]);

    const namePlaceholder = useMemo(() => {
        return workspaceName[0];
    }, [workspaceName]);

    const renewInfo = useMemo(() => {
        if (currentPlan && currentPlan?.planNameId !== PlanNameId.INITIAL) {
            if (currentPlan?.status === 'canceled') {
                return `Downgrades: ${moment
                    .unix(currentPlan?.billingPeriod?.billingEndDate)
                    .format('DD.MM.YYYY')}`;
            }
            return `Renews: ${moment
                .unix(currentPlan?.billingPeriod?.billingEndDate)
                .format('DD.MM.YYYY')}`;
        }
    }, [currentPlan]);

    const toggleEdit = () => {
        setEditActive(!isEditActive);
    };

    const handleSave = () => {
        dispatch(
            updateWorkspace({
                id: currentWorkspace?.id || '',
                data: { name },
            })
        );

        setEditActive(false);
    };

    const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setName(value);
    };

    const handleLeaveWorkspace = () => {
        if (confirm('Are you sure you want to leave the workspace?')) {
            dispatch(
                leaveWorkspace({
                    navigate,
                    workspaceId: currentWorkspace?.id || '',
                })
            );
            dispatch(hideModal());
        }
    };

    const handleDleteWorkspace = () => {
        if (confirm('Are you sure you want to delete workspace?')) {
            dispatch(
                deleteWorkspace({
                    navigate,
                    workspaceId: currentWorkspace?.id || '',
                })
            );
            dispatch(hideModal());
        }
    };

    const handleUpgrade = () => {
        dispatch(showModal(ModalType.SELECT_PLAN, ModalEvent.SELECT_PLAN));
    };

    const renderName = () => {
        if (isEditActive) {
            return (
                <NameInputContainer>
                    <Input value={name} onChange={handleChangeName} />
                    <Actions>
                        <ActionButton
                            disabled={isWorkspaceUpdateLoading}
                            isPrimary
                            onClick={handleSave}
                        >
                            {isWorkspaceUpdateLoading ? (
                                <Loader
                                    type="Oval"
                                    color="rgb(5,27,90)"
                                    height={12}
                                    width={12}
                                />
                            ) : (
                                'Save'
                            )}
                        </ActionButton>
                        <ActionButton
                            disabled={isWorkspaceUpdateLoading}
                            onClick={toggleEdit}
                        >
                            Cancel
                        </ActionButton>
                    </Actions>
                </NameInputContainer>
            );
        }
        return (
            <NameValueContainer>
                <NameValue>{name}</NameValue>
                {
                    isCurrentUserOwner && (
                        <EditButton onClick={toggleEdit}>
                            <Icon name="edit-pen" size={16} color={NEUTRAL_400}/>
                        </EditButton>
                    )
                }
            </NameValueContainer>
        );
    };

    const renderLeaveButton = () => {
        if (!isCurrentUserOwner) {
            return (
                <LeaveButton onClick={handleLeaveWorkspace}>
                    <Icon name="logout" size={18} color="#fff" />
                    <ButtonLabel>Leave Workspace</ButtonLabel>
                </LeaveButton>
            );
        }
        return (
            <DeleteButton onClick={handleDleteWorkspace}>
                <Icon name="trashcan-icon-new" size={18} color={NEUTRAL_200} />
                <ButtonLabel>Delete Workspace</ButtonLabel>
            </DeleteButton>
        );
    };

    const renderPlan = () => {
        if (currentPlan) {
            return (
                <WorkspacePlanCard
                    data={currentPlan}
                    onSelect={handleUpgrade}
                    currentUserPlan={currentPlan}
                    isCurrentUserOwner={isCurrentUserOwner}
                    seePlanLimitsInfo={seePlanLimitsInfo}
                />
            );
        }
    };

    const renderPlanRenewDate = () => {
        const renewInfoSplitted = renewInfo?.split(':');

        const renewText = renewInfoSplitted?.[0];
        const renewDate = renewInfoSplitted?.[1];


        return (
            <CurrentPlanInfoContainer>
                {/* <CurrentPlanInfo> */}
                {/*    <CurrentPlanInfoHeader>Current Plan:</CurrentPlanInfoHeader> */}
                {/*    <CurrentPlanInfoData>{currentPlanName}</CurrentPlanInfoData> */}
                {/* </CurrentPlanInfo> */}
                {
                    renewInfo && (
                        <CurrentPlanInfo>
                            <CurrentPlanInfoHeader>{renewText}:</CurrentPlanInfoHeader>
                            <CurrentPlanInfoData>{renewDate}</CurrentPlanInfoData>
                        </CurrentPlanInfo>
                    )
                }
            </CurrentPlanInfoContainer>
        );
    };

    return (
        <Container>
            <WorkspaceInfo>
                <Avatar>
                    <AvatarPlaceholder>{namePlaceholder}</AvatarPlaceholder>
                </Avatar>
                <NameContainer>
                    <NameLabel>Name</NameLabel>
                    {renderName()}
                </NameContainer>
                <Info>
                    <InfoLabel>Date Created</InfoLabel>
                    <DateCreated>
                        {moment(currentWorkspace?.createdDate).format(
                            'MMMM MM, YYYY'
                        )}
                    </DateCreated>
                </Info>
            </WorkspaceInfo>
            <PlanContainer>{renderPlan()}</PlanContainer>
            {renderPlanRenewDate()}
            {renderLeaveButton()}
        </Container>
    );
};

export default WorkspaceSettings;
