import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { NewButton } from 'components/UI';

import { hideModal } from 'state/modules/modal';
import {
    cancelUserSubscription,
    getCurrentUserPlan,
    getSubscriptionCancelationLoading,
} from 'state/modules/payment';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const cancelationReasons = [
    'Plan too expensive',
    'I don’t find the service useful',
    'It didn’t give me what I expected',
    'Other',
];

const CancelPlanModal = (props: Props): JSX.Element => {
    const [selectedReason, setSelectedReason] = useState<null | string>(null);

    const { closeButton } = props;

    const dispatch = useDispatch();
    const isLoading = useSelector(getSubscriptionCancelationLoading);
    const currentPlan = useSelector(getCurrentUserPlan);

    const selectReason = (reason: string) => {
        setSelectedReason(reason);
    };

    const handleCancelPlan = () => {
        dispatch(cancelUserSubscription());
    };

    const close = () => {
        dispatch(hideModal());
    };

    return (
        <div className={styles.CancelPlanModal}>
            <p className={styles.CancelPlanModal__title}>
                {`Cancels on ${moment(
                    (currentPlan?.billingPeriod.billingEndDate || 0) * 1000
                ).format('DD/MM/YYYY')}`}
            </p>
            <p className={styles.CancelPlanModal__title}>
                Before you go, please give us your reason of leaving.
            </p>

            <div className={styles.CancelPlanModal__container}>
                {cancelationReasons.map((item: string) => {
                    const isSelected = selectedReason === item;

                    return (
                        <button
                            onClick={() => selectReason(item)}
                            className={[
                                styles.CancelPlanModal__cancelReason,
                                isSelected
                                    ? styles.CancelPlanModal__cancelReason_selected
                                    : null,
                            ].join(' ')}
                            key={item}
                        >
                            {item}
                        </button>
                    );
                })}
            </div>
            <div className={styles.CancelPlanModal__actionButtons}>
                <NewButton
                    color={Colors.TURQUOISE}
                    textColor={Colors.WHITE}
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={close}
                    disabled={isLoading}
                >
                    Keep account
                </NewButton>
                <NewButton
                    color={Colors.PINK}
                    textColor={Colors.WHITE}
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={handleCancelPlan}
                    disabled={!selectedReason || isLoading}
                    loading={isLoading}
                >
                    Cancel plan
                </NewButton>
            </div>
            {closeButton}
        </div>
    );
};

export default CancelPlanModal;
