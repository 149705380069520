import { createSelector } from 'reselect';

import { State } from 'state/modules';
import { WorkspacesState } from './types';

export const getWorkspacesState = (state: State): WorkspacesState =>
    state.workspaces;

export const getWorkspacesList = createSelector(
    getWorkspacesState,
    (state) => state.items
);

export const getInviteeWorkspacesList = createSelector(
    getWorkspacesState,
    (state) => state.inviteeWorkspaces
);

export const getCurrentWorkspaceInfo = createSelector(
    getWorkspacesState,
    (state) => state.currentWorkspace
);

export const getWorkspaceUpdateLoading = createSelector(
    getWorkspacesState,
    (state) => state.isUpdateLoading
);

export const getSendWorkspaceInviteLoading = createSelector(
    getWorkspacesState,
    (state) => state.isSendInviteLoading
);

export const getInviteeInvitesList = createSelector(
    getWorkspacesState,
    (state) => state.inviteeInvites
);

export const getInviteeInvitesLoading = createSelector(
    getWorkspacesState,
    (state) => state.isInviteeInvitesLoading
);

export const getInviterInvitesList = createSelector(
    getWorkspacesState,
    (state) => state.inviterInvites
);

export const getInviterInvitesLoading = createSelector(
    getWorkspacesState,
    (state) => state.isInviterInvitesLoading
);

export const getPinInviteLoading = createSelector(
    getWorkspacesState,
    (state) => state.isPinInviteLoading
);

export const getUpdateInviteeInviteLoading = createSelector(
    getWorkspacesState,
    (state) => state.isUpdateInviteeInviteLoading
);

export const getUpdateInviterInviteLoading = createSelector(
    getWorkspacesState,
    (state) => state.isUpdateInviterInviteLoading
);

export const getWorkspaceInviteModalVisible = createSelector(
    getWorkspacesState,
    (state) => state.isInviteModalVisible
);

export const getRevokeInviteLoading = createSelector(
    getWorkspacesState,
    (state) => state.isRevokeInviteLoading
);

export const getWorkspaceFlowLoading = createSelector(
    getWorkspacesState,
    (state) => state.isWorkspaceFlowLoading
);

// create

export const getWorkspaceCreateLoading = createSelector(
    getWorkspacesState,
    (state) => state.isCreateLoading
);
