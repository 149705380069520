import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from 'components/UI';

import { setBrowserRecommendationVisibility } from 'state/modules/app';
import styles from './styles.module.scss';

const BrowserRecommendation: React.FC = memo(() => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setBrowserRecommendationVisibility(false));
  };

  return (
    <div className={styles.BrowserRecommendation}>
      <p className={styles.BrowserRecommendation__title}>
        We recommend to use Google Chrome to upload large files
      </p>
      <button
        className={styles.BrowserRecommendation__closeButton}
        onClick={handleClose}
      >
        <Icon name="close" size={12} color="#fff" />
      </button>
    </div>
  );
});

export default BrowserRecommendation;
