import { Auth } from 'aws-amplify';
import {
    call,
    delay,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import Bowser from 'bowser';

import {
    AppActionTypes,
    TourStatusesBody,
    getAppTourStatus,
    UpdateAppIntroductoryTourStatusAction,
} from 'state/modules/app';
import {
    getPreferences,
    getToggleToSmartSearchAvailableStatus,
    getUserInfo,
    handleGetUserPreferences,
    getFirstLoginStatus,
} from 'state/modules/user';
import { handlePaymentFlowInit } from 'state/modules/payment';

import * as modalActions from 'state/modules/modal/actions';
import * as userActions from 'state/modules/user/actions';
import * as videosActions from 'state/modules/media/actions';
import * as collectionsActions from 'state/modules/collections/actions';
import * as appActions from 'state/modules/app/actions';
import * as templatesActions from 'state/modules/templates/actions';
// import * as workspacesActions from 'state/modules/workspaces/actions';
// import * as projectsActions from 'state/modules/projects/actions';
// import * as foldersActions from 'state/modules/folders/actions';

import UserClient from 'services/api/user';
import AuthClient from 'services/api/auth';

import { ProductPrepareTypes, UserPreferences } from 'interfaces/preferences';

import {
    AppInitAction,
    AppTour,
    BurningSubtitlesTourStatus,
    ChangeLocationAction,
    SetBurningSubtitlesTourStatusAction,
    SetVideoEditingTourStatusAction,
    VideoEditingTourStatus,
} from './types';
import {
    getAppInitLoading,
    getBurningSubtitlesTourStatus,
    getVideoEditingTourStatus,
} from './selectors';
import { ModalEvent, ModalType } from '../modal';
import {
    // handleGetCurrentWorkspace,
    // handleGetInviteeWorkspaces,
    handleInitInvitesFlow,
    handleInitWorkspaceFlow,
} from '../workspaces';

export function* handleBrowserDetect(): Generator {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();

    if (browserName === 'Chrome') {
        yield put(appActions.setBrowserRecommendationVisibility(false));
    } else {
        yield put(appActions.setBrowserRecommendationVisibility(true));
    }
}

export function* handleCheckAppIntroductionTourStatus(): Generator {
    const appTourStatus = (yield select(getAppTourStatus)) as TourStatusesBody;

    if (!appTourStatus?.isAppTourSkipped) {
        // yield put(
        //   modalActions.showModal(
        //     ModalType.APP_INTRODUCTION_TOUR_PROPOSAL,
        //     ModalEvent.APP_INTRODUCTION_TOUR_PROPOSAL,
        //   ),
        // );
        // yield put(
        //   appActions.updateAppIntroductoryTourStatus(
        //     {
        //       [AppTour.BURNING_SUBTITLES]: {
        //         isFinished: false,
        //         isRunning: true,
        //       },
        //     },
        //     true
        // ));

        yield put(
            appActions.setVideoEditingTourStatusAction({
                isControlsVisible: true,
                isUploadVisible: true,
            })
        );
        yield put(
            appActions.setBurningSubtitlesTourStatus({
                isControlsVisible: true,
                isUploadVisible: true,
            })
        );
    }
}

export function* handleUpdateAppIntroductoryTourStatus(
    action: UpdateAppIntroductoryTourStatusAction
): Generator {
    const { statuses, isAppTourSkipped } = action.payload;

    const appTourStatus = (yield select(getAppTourStatus)) as TourStatusesBody;

    let updatedAppTourStatus = {} as TourStatusesBody;

    updatedAppTourStatus = {
        ...appTourStatus,
        ...statuses,
    };

    if (isAppTourSkipped) {
        updatedAppTourStatus = {
            ...updatedAppTourStatus,
            isAppTourSkipped,
        };
    }

    yield put(appActions.setAppIntroductoryTourStatus(updatedAppTourStatus));

    try {
        yield call(UserClient.updateUserTourStatus, {
            statuses: {
                userTourStatus: updatedAppTourStatus,
            },
        });

        if (statuses.burningSubtitlesTourStatus) {
            yield delay(10000);

            yield put(
                appActions.setBurningSubtitlesTourStatus({
                    isControlsVisible: false,
                    isUploadVisible: false,
                })
            );
        }

        if (statuses.videoEditingDashboardTourStatus) {
            yield delay(10000);

            yield put(
                appActions.setVideoEditingTourStatusAction({
                    isControlsVisible: false,
                    isUploadVisible: false,
                })
            );
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleCheckAppFlow({
    userPreferences,
    navigate,
    location,
}: any): Generator {
    try {
        const isToggleToSmartSearchAvailable = yield select(
            getToggleToSmartSearchAvailableStatus
        );

        const isCurrentLocationLogging =
            location.pathname.includes('smart-search');
        const isCurrentLocationProjects =
            location.pathname.includes('video-editing');

        if (userPreferences && userPreferences.productPrepareType) {
            const isLogging =
                userPreferences.productPrepareType ===
                ProductPrepareTypes.LOGING;
            const isProjects =
                userPreferences.productPrepareType ===
                ProductPrepareTypes.PROJECTS;

            if (isLogging) {
                yield put(videosActions.getVideoList());
                yield put(collectionsActions.getCollections());
                yield put(templatesActions.getTemplates());

                if (
                    !isCurrentLocationLogging &&
                    isToggleToSmartSearchAvailable
                ) {
                    navigate('/smart-search');
                }
            }

            if (isProjects) {
                yield put(videosActions.getVideoList());

                if (!isCurrentLocationProjects) {
                    navigate('/video-editing');
                }
            }
        } else {
            navigate('/video-editing');

            yield put(
                modalActions.showModal(
                    ModalType.WELCOME,
                    ModalEvent.WELCOME_INTRO_VIDEO_EDITING
                )
            );
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* checkProvider(
    userAttributes: any,
    currentUserInfo: any
): Generator {
    if (userAttributes?.identities) {
        const userIdentities = JSON.parse(userAttributes?.identities)?.[0];

        if (userIdentities.providerType === 'Google') {
            const isFirstLogin = yield select(getFirstLoginStatus);

            let invitationCode = localStorage.getItem('invitationCode');

            yield call(
                AuthClient.confirmGoogleFederatedSignIn,
                userAttributes.email,
                currentUserInfo.id,
                currentUserInfo.username,
                isFirstLogin && invitationCode ? invitationCode : null
            );

            localStorage.removeItem('invitationCode');
        }
    }
}

export function* handleAppInit(action: AppInitAction): Generator {
    const isLoading = yield select(getAppInitLoading);
    const userPreferences = yield select(getPreferences);

    if (isLoading || userPreferences) return;

    yield put(appActions.appInitStart());

    const { navigate, location } = action.payload as any;

    try {
        const session: any = yield call([Auth, 'currentSession']);
        const currentUserInfo: any = yield select(getUserInfo);
        const userAttributes = currentUserInfo?.attributes;
        const isAppSumoUser = localStorage.getItem('isAppSumoUser');

        if (isAppSumoUser) {
            const res = yield call(
                AuthClient.confirmAppSumoUser,
                userAttributes.email,
                currentUserInfo.id
            );

            yield delay(2000);
        }

        yield call(checkProvider, userAttributes, currentUserInfo);

        if (session && currentUserInfo) {
            const idToken = session.getIdToken();

            if (idToken) {
                const currentUserPreferences = (yield call(
                    handleGetUserPreferences
                )) as UserPreferences;

                yield call(handleInitInvitesFlow);
                yield call(handleInitWorkspaceFlow);
                yield call(handlePaymentFlowInit);

                yield call(handleCheckAppFlow, {
                    userPreferences: currentUserPreferences,
                    navigate,
                    location,
                });

                localStorage.removeItem('isAppSumoUser');

                yield put(userActions.getStats());
                yield put(appActions.checkAppIntroductionTourStatus());
                yield put(appActions.appInitSuccess());
            }
        } else {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
        }
    } catch (error) {
        console.log({ error });
        yield put(appActions.appInitFail());

        // yield call(Auth.signOut);
        // yield put(userActions.signOut());
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }
}

export function* handleSetBurningSubtitlesTourStatus(
    action: SetBurningSubtitlesTourStatusAction
): Generator {
    const { isControlsVisible, isUploadVisible } = action.payload;

    const burningSubtitlesTourStatus = (yield select(
        getBurningSubtitlesTourStatus
    )) as BurningSubtitlesTourStatus;

    if (
        ((burningSubtitlesTourStatus.isControlsVisible ||
            burningSubtitlesTourStatus.isUploadVisible) &&
            !isControlsVisible &&
            burningSubtitlesTourStatus.isUploadVisible) ||
        (!isUploadVisible && burningSubtitlesTourStatus.isControlsVisible) ||
        (!isControlsVisible && !isUploadVisible)
    ) {
        yield put(
            appActions.updateAppIntroductoryTourStatus({
                [AppTour.BURNING_SUBTITLES]: {
                    isFinished: true,
                    isRunning: false,
                },
            })
        );
    }
}

export function* handleSetVideoEditingTourStatusAction(
    action: SetVideoEditingTourStatusAction
): Generator {
    const { isControlsVisible, isUploadVisible } = action.payload;

    const videoEditingTourStatus = (yield select(
        getVideoEditingTourStatus
    )) as VideoEditingTourStatus;

    if (
        ((videoEditingTourStatus.isControlsVisible ||
            videoEditingTourStatus.isUploadVisible) &&
            !isControlsVisible &&
            videoEditingTourStatus.isUploadVisible) ||
        (!isUploadVisible && videoEditingTourStatus.isControlsVisible) ||
        (!isControlsVisible && !isUploadVisible)
    ) {
        yield put(
            appActions.updateAppIntroductoryTourStatus({
                [AppTour.VIDEO_EDITING_DASHBOARD]: {
                    isFinished: true,
                    isRunning: false,
                },
            })
        );
    }
}

export function* handleChangeLocation(action: ChangeLocationAction): Generator {
    const userPreferences = (yield select(getPreferences)) as UserPreferences;

    const route = action.payload;

    if (userPreferences && userPreferences.productPrepareType !== route) {
        yield put(
            userActions.updateUserPreferences({
                ...userPreferences,
                productPrepareType: route,
            })
        );
    }
}

export function* appSaga(): Generator {
    // yield takeLatest(AppActionTypes.APP_INIT, handleAppInit);
    yield takeEvery(AppActionTypes.APP_INIT, handleAppInit);
    yield takeLatest(AppActionTypes.CHECK_BROWSER_INFO, handleBrowserDetect);
    yield takeLatest(
        AppActionTypes.UPDATE_APP_INTRODUCTORY_TOUR_STATUS,
        handleUpdateAppIntroductoryTourStatus
    );
    yield takeLatest(
        AppActionTypes.SET_BURNING_SUBTITLES_TOUR_STATUS,
        handleSetBurningSubtitlesTourStatus
    );
    yield takeLatest(
        AppActionTypes.CHECK_APP_INTRODUCTION_TOUR_STATUS,
        handleCheckAppIntroductionTourStatus
    );
    yield takeLatest(AppActionTypes.CHANGE_LOCATION, handleChangeLocation);
}
