import { RekognitionCategory } from 'interfaces/analysis';
import { StatusesCategory, StatusType } from 'interfaces/statuses';
import { Status, MediaFile, AnalyzeStatuses } from 'interfaces/videos';

export const checkIsTranslateInProgress = (statuses: AnalyzeStatuses): boolean => {
  let isInProgress = false;

  if (statuses?.translate) {
    const statusValue = statuses.translate.status;

    isInProgress = statusValue === StatusType.IN_PROGRESS || 
      statusValue === StatusType.PREPARING ||
      statusValue === StatusType.WAITING;
  }

  return isInProgress;
}

export const checkIsTranscriptInProgress = (statuses: AnalyzeStatuses): boolean => {
  let isInProgress = false;

  if (statuses?.transcript) {
    const statusValue = statuses.transcript.status;

    isInProgress = statusValue === StatusType.IN_PROGRESS || 
      statusValue === StatusType.PREPARING ||
      statusValue === StatusType.WAITING;
  }

  return isInProgress;
}

export const checkIsTranslateNotAddedToProject = (statuses: AnalyzeStatuses): boolean => {
    let isNotAdded = false;

    if (statuses?.translate) {
        isNotAdded = statuses?.translate?.addedToProject === false;
    }

    return isNotAdded;
}

export const checkIsTranscriptNotAddedToProject = (statuses: AnalyzeStatuses): boolean => {
    let isNotAdded = false;

    if (statuses?.transcript) {
        isNotAdded = statuses?.transcript?.addedToProject === false;
    }

    return isNotAdded;
}

export const checkIsDubbingInProgress = (statuses: AnalyzeStatuses): boolean => {
    let isInProgress = false;

    if (statuses?.dubbing) {
        const statusValue = statuses.dubbing.status;

        isInProgress = statusValue === StatusType.IN_PROGRESS ||
            statusValue === StatusType.PREPARING ||
            statusValue === StatusType.WAITING;
    }

    return isInProgress;
}

export const checkIsVideoInProgress = (items: Array<StatusType>): boolean => {
  if (
    (
      items.includes(StatusType.IN_PROGRESS)
        || items.includes(StatusType.INPROGRESS)
        || items.includes(StatusType.PREPARING)
        || items.includes(StatusType.WAITING)
        || items.includes(StatusType.SAVING)
    )
        && !items.includes(StatusType.ERROR)
  ) {
    return true;
  }

  return false;
};

export const checkIsStatusesHasError = (items: Array<StatusType>): boolean => {
  if (
      items.includes(StatusType.FAILED) || items.includes(StatusType.ERROR)
  ) {
    return true;
  }

  return false;
};

export const getAnalysisStatuses = (statuses: any) => {
  const categories = Object.values(RekognitionCategory);

  const statusesArray = [] as any[];

  if (statuses?.status) {
    statusesArray.push(statuses.status)
  }

  if (statuses) { 
    Object.keys( 
      statuses, 
    ).forEach((statusKey: string) => {
      if (categories.includes(statusKey as any)) {
        statusesArray.push(statuses[statusKey].status);
      }
    });
  }

  return statusesArray;
}

export const getInProgressStatus = (video: MediaFile): string | undefined => Object.keys(video?.status).find((key) => {
  let innerStatuses = [] as Array<StatusType>;
  const statuses = video?.status[key as keyof MediaFile['status']];

  if (key === StatusesCategory.GENERATION) {
    innerStatuses = Object.values( 
      statuses,
    ).map((statusObj: Status) => statusObj?.status);
  } else if (key === StatusesCategory.ANALYZE) {
    innerStatuses = getAnalysisStatuses(statuses);
  } else {
    innerStatuses = [
      statuses
      ?.status as StatusType,
    ];
  }
  
  const hasInProgress = checkIsVideoInProgress(innerStatuses);

  if (hasInProgress) {
    return key;
  }
});

export const checkAllCategoriesSucceeded = (analysisStatus: AnalyzeStatuses) => {
    let categoriesStatusObjects = [] as any;

    if(analysisStatus.celebs) {
        const isCelebsSucceeded = analysisStatus?.celebs?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'celebs',
            isSuccess: isCelebsSucceeded
        });
    }
    if(analysisStatus.faceMatches) {
        const isFaceMatchesSucceeded = analysisStatus?.faceMatches?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'faceMatches',
            isSuccess: isFaceMatchesSucceeded
        });
    }
    if(analysisStatus.faces) {
        const isFacesSucceeded = analysisStatus?.faces?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'faces',
            isSuccess: isFacesSucceeded
        });
    }
    if(analysisStatus.labels) {
        const isLabelsSucceeded = analysisStatus?.labels?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'labels',
            isSuccess: isLabelsSucceeded
        });
    }
    if(analysisStatus.modaratedLabels) {
        const isModeratedLabelsSucceeded = analysisStatus?.modaratedLabels?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'modaratedLabels',
            isSuccess: isModeratedLabelsSucceeded
        });
    }
    if(analysisStatus.shots) {
        const isShotsSucceeded = analysisStatus?.shots?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'shots',
            isSuccess: isShotsSucceeded
        });
    }
    if(analysisStatus.transcript) {
        const isTranscriptSucceeded = analysisStatus?.transcript?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'transcript',
            isSuccess: isTranscriptSucceeded
        });
    }
    if(analysisStatus.translate) {
        const isTranslateSucceeded = analysisStatus?.translate?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'translate',
            isSuccess: isTranslateSucceeded
        });
    }
    if(analysisStatus.dubbing) {
        const isDubbingSucceeded = analysisStatus?.dubbing?.status === StatusType.SUCCEEDED
        categoriesStatusObjects.push({
            label: 'dubbing',
            isSuccess: isDubbingSucceeded
        });
    }

    let allAreSucceeded = categoriesStatusObjects?.every((item: any) => item?.isSuccess);

    return {
        allAreSucceeded,
        wasAnalysed: !!categoriesStatusObjects.length
    };
}

