import produce from 'immer';
import { ProjectSubtitlesAction, ProjectSubtitlesActionTypes, ProjectSubtitlesState } from './typesNew';
import { ProjectsAction, ProjectsActionTypes } from '../projects';

const initialState: ProjectSubtitlesState = {
    projectCurrentSubtitlesList: [],
    projectUnsyncedSubtitlesList: [],
    projectDeletedUnsyncedSubtitlesIds: [],
    projectAllSubtitlesList: [],

    projectVideosIdsWithTranscriptGeneration: [],
    projectVideosIdsWithTranslateGeneration: [],

    projectVideosLanguages: [],
    projectLanguages: [],

    editableSentenceId: '',
    selectedSentenceId: '',
    scrollableTranscriptIndex: null,

    searchQuery: '',

    getProjectSubtitlesListLoading: false,
    getProjectSubtitlesListSuccess: false,

    isProjectTranscriptCreationLoading: false,
    isProjectSubtitlesTranslateLoading: false,

    subtitlesExportStatus: {
        isLoading: false,
        isSuccess: false,
    },

    addGeneratedSubtitlesLoading: false,
};

export default (
    state = initialState,
    action: ProjectSubtitlesAction | ProjectsAction
): ProjectSubtitlesState =>
    produce(state, (draft) => {
        switch (action.type) {
            case ProjectSubtitlesActionTypes.GET_PROJECT_ALL_SUBTITLES_LIST:
                draft.getProjectSubtitlesListLoading = true;
                draft.getProjectSubtitlesListSuccess = false;
                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_ALL_SUBTITLES_LIST:
                draft.getProjectSubtitlesListLoading = false;
                draft.getProjectSubtitlesListSuccess = true;
                draft.projectCurrentSubtitlesList = action.payload.subtitles;
                // draft.projectAllSubtitlesList = action.payload.projectAllSubtitles
                return;
            case ProjectSubtitlesActionTypes.SET_UNSYNCED_SUBTITLES_LIST:
                draft.projectUnsyncedSubtitlesList = action.payload.subtitles;
                return
            case ProjectSubtitlesActionTypes.SET_DELETED_UNSYNCED_SUBTITLES_LIST:
                draft.projectDeletedUnsyncedSubtitlesIds = action.payload;
                return

            case ProjectSubtitlesActionTypes.EXPORT_SUBTITLES:
                draft.subtitlesExportStatus = {
                    isLoading: true,
                    isSuccess: false,
                };
                return;
            case ProjectSubtitlesActionTypes.SET_EXPORT_SUBTITLES_STATUS:
                draft.subtitlesExportStatus = {
                    isLoading: false,
                    isSuccess: action.payload.isSuccess,
                };
                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_LANGUAGES:
                draft.projectVideosLanguages = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_SEARCH_QUERY:
                draft.searchQuery = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_TRANSCRIPT_CREATION_LOADING:
                draft.isProjectTranscriptCreationLoading = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_SCROLLABLE_TRANSCRIPT_INDEX:
                draft.scrollableTranscriptIndex = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSCRIPT_GENERATION:
                draft.projectVideosIdsWithTranscriptGeneration = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSLATE_GENERATION:
                draft.projectVideosIdsWithTranslateGeneration = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_PROJECT_SUBTITLES_TRANSLATE_LOADING:
                draft.isProjectSubtitlesTranslateLoading = action.payload;

                return;
            case ProjectSubtitlesActionTypes.SET_EDITABLE_SENTENCE_ID:
                draft.editableSentenceId = action.payload;

                return;
            case ProjectSubtitlesActionTypes.ADD_GENERATED_SUBTITLES:
                draft.addGeneratedSubtitlesLoading = true;

                return;
            case ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_SUCCESS:
            case ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_FAILURE:
                draft.addGeneratedSubtitlesLoading = false;

                return;
            /// clear
            case ProjectsActionTypes.CLEAR_PROJECT_DETAILS:
                draft.projectCurrentSubtitlesList = [];
                draft.projectUnsyncedSubtitlesList = [];
                draft.projectDeletedUnsyncedSubtitlesIds = [];
                draft.projectAllSubtitlesList = [];

                draft.projectVideosIdsWithTranscriptGeneration = [];
                draft.projectVideosIdsWithTranslateGeneration = [];

                draft.projectVideosLanguages = [];

                draft.editableSentenceId = '';
                draft.selectedSentenceId = '';
                draft.scrollableTranscriptIndex = null;

                draft.searchQuery = initialState.searchQuery;


                draft.getProjectSubtitlesListLoading = false;
                draft.getProjectSubtitlesListSuccess = false;

                draft.isProjectSubtitlesTranslateLoading = false;
                draft.isProjectTranscriptCreationLoading = false;

                draft.subtitlesExportStatus =  {
                    isLoading: false,
                    isSuccess: false,
                };

                draft.addGeneratedSubtitlesLoading = false;

                return;
            default:
                return state;
        }
    });