import {
  Collection,
  CollectionImage,
  CollectionItemBySearch,
  NewCollectionImage,
  CollectionItemToUpload,
} from 'interfaces/collections';
import {
  CollectionsActionTypes,
  GetCollectionsAction,
  GetCollectionsStartAction,
  GetCollectionsFailAction,
  GetCollectionsSuccessAction,
  GetCollectionByIdAction,
  GetCollectionByIdStartAction,
  GetCollectionByIdFailAction,
  DeleteCollectionStartAction,
  DeleteCollectionAction,
  DeleteCollectionFailAction,
  DeleteCollectionSuccessAction,
  DeleteCollectionsItemsAction,
  DeleteCollectionsItemsStartAction,
  DeleteCollectionsItemsFailAction,
  DeleteCollectionsItemsSuccessAction,
  AddCollectionTempItemAction,
  SetCollectionTempItemAction,
  GetCollectionsItemsBySearchAction,
  GetCollectionsItemsBySearchStartAction,
  GetCollectionsItemsBySearchFailAction,
  GetCollectionsItemsBySearchSuccessAction,
  UpdateCollectionsItemsAction,
  UpdateCollectionsItemsSuccessAction,
  AddCollectionImagesIdsForDeleteAction,
  SetCollectionImagesIdsForDeleteAction,
  UploadCollectionItemsAction,
  UploadCollectionItemsStartAction,
  UploadCollectionItemsFailAction,
  UploadCollectionItemsSuccessAction,
  SetSelectedCollectionsItemsAction,
  SetCollectionDeletingAction,
  SetCollectionsForDeleteAction,
  AddCollectionForDeleteAction,
  RemoveCollectionForDeleteAction,
  SetCollectionsSearchQueryAction,
} from './types';

export const getCollections = (): GetCollectionsAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS,
});

export const getCollectionsStart = (): GetCollectionsStartAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_START,
});

export const getCollectionsFail = (error: any): GetCollectionsFailAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_FAIL,
  payload: error,
});

export const getCollectionsSuccess = (
  collections: Array<Collection>,
  total: number,
): GetCollectionsSuccessAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_SUCCESS,
  payload: {
    collections,
    total,
  },
});

export const getCollectionById = (
  id: string,
  name: string,
): GetCollectionByIdAction => ({
  type: CollectionsActionTypes.GET_COLLECTION_BY_ID,
  payload: { id, name },
});

export const getCollectionByIdStart = (): GetCollectionByIdStartAction => ({
  type: CollectionsActionTypes.GET_COLLECTION_BY_ID_START,
});

export const getCollectionByIdFail = (
  error: any,
): GetCollectionByIdFailAction => ({
  type: CollectionsActionTypes.GET_COLLECTION_BY_ID_FAIL,
  payload: error,
});

export const deleteCollection = (id: string): DeleteCollectionAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTION,
  payload: id,
});

export const deleteCollectionStart = (): DeleteCollectionStartAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTION_START,
});

export const deleteCollectionFail = (
  error: any,
): DeleteCollectionFailAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTION_FAIL,
  payload: error,
});

export const deleteCollectionSuccess = (): DeleteCollectionSuccessAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTION_SUCCESS,
});

export const deleteCollectionsItems = (
  collectionsItems: Array<CollectionImage>,
): DeleteCollectionsItemsAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS,
  payload: collectionsItems,
});

export const deleteCollectionsItemsStart = (): DeleteCollectionsItemsStartAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_START,
});

export const deleteCollectionsItemsFail = (
  error: any,
): DeleteCollectionsItemsFailAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_FAIL,
  payload: error,
});

export const deleteCollectionsItemsSuccess = (
  updatedCollections: Array<Collection>,
): DeleteCollectionsItemsSuccessAction => ({
  type: CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_SUCCESS,
  payload: updatedCollections,
});

export const addCollectionTempItems = (
  imagesIds: Array<NewCollectionImage>,
): AddCollectionTempItemAction => ({
  type: CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_UPDATE,
  payload: imagesIds,
});

export const setCollectionTempItem = (
  imagesIds: Array<NewCollectionImage>,
): SetCollectionTempItemAction => ({
  type: CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_UPDATE,
  payload: imagesIds,
});

export const getCollectionsItemsBySearch = (
  query: string,
): GetCollectionsItemsBySearchAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH,
  payload: query,
});

export const getCollectionsItemsBySearchStart = (): GetCollectionsItemsBySearchStartAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_START,
});

export const getCollectionsItemsBySearchFail = (
  error: any,
): GetCollectionsItemsBySearchFailAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_FAIL,
  payload: error,
});

export const getCollectionsItemsBySearchSuccess = (
  data: Array<CollectionItemBySearch>,
): GetCollectionsItemsBySearchSuccessAction => ({
  type: CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_SUCCESS,
  payload: data,
});

export const updateCollectionsItems = (): UpdateCollectionsItemsAction => ({
  type: CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS,
});

export const updateCollectionsItemsSuccess = (
  updatedCollection: Array<Collection>,
): UpdateCollectionsItemsSuccessAction => ({
  type: CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS_SUCCESS,
  payload: updatedCollection,
});

export const addCollectionImagesIdsForDelete = (
  imagesIds: Array<string>,
): AddCollectionImagesIdsForDeleteAction => ({
  type: CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_DELETE,
  payload: imagesIds,
});

export const setCollectionImagesIdsForDelete = (
  imagesIds: Array<string>,
): SetCollectionImagesIdsForDeleteAction => ({
  type: CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_DELETE,
  payload: imagesIds,
});

export const uploadCollectionItems = (
  items: Array<CollectionItemToUpload>,
  collectionId: string,
): UploadCollectionItemsAction => ({
  type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS,
  payload: { items, collectionId },
});

export const uploadCollectionItemsStart = (): UploadCollectionItemsStartAction => ({
  type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_START,
});

export const uploadCollectionItemsFail = (
  error: any,
): UploadCollectionItemsFailAction => ({
  type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_FAIL,
  payload: error,
});

export const uploadCollectionItemsSuccess = (): UploadCollectionItemsSuccessAction => ({
  type: CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_SUCCESS,
});

export const setSelectedCollectionsItems = (
  items: Array<CollectionImage>,
): SetSelectedCollectionsItemsAction => ({
  type: CollectionsActionTypes.SET_SELECTED_COLLECTIONS_ITEMS,
  payload: items,
});

export const setCollectionDeleting = (
  isDeleting: boolean,
): SetCollectionDeletingAction => ({
  type: CollectionsActionTypes.SET_COLLECTION_DELETING,
  payload: isDeleting,
});

export const setCollectionsForDelete = (
  collections: Array<string>,
): SetCollectionsForDeleteAction => ({
  type: CollectionsActionTypes.SET_COLLECTIONS_FOR_DELETE,
  payload: collections,
});

export const addCollectionForDelete = (
  collection: string,
): AddCollectionForDeleteAction => ({
  type: CollectionsActionTypes.ADD_COLLECTION_FOR_DELETE,
  payload: collection,
});

export const removeCollectionForDelete = (
  collection: string,
): RemoveCollectionForDeleteAction => ({
  type: CollectionsActionTypes.REMOVE_COLLECTION_FOR_DELETE,
  payload: collection,
});

export const setCollectionsSearchQuery = (
  query: string,
): SetCollectionsSearchQueryAction => ({
  type: CollectionsActionTypes.SET_COLLECTIONS_SEARCH_QUERY,
  payload: query,
});
