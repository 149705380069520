import {
    TextLinesValue,
    ProjectSize,
    ProjectSubtitlesSettingsBox,
    ProjectVideoSettings,
    SubtitleEffect,
    SubtitlesAlign,
    SubtitlesTextStyle,
} from './projects';

export enum ProductPrepareTypes {
    PROJECTS = 'PROJECTS',
    LOGING = 'LOGING',
}

export interface UserPreferences {
    // isFirstLogin?: boolean;
    autoAnalyse: boolean;
    defaultUserCollection: string;
    defaultCategories: Array<string>;
    defaultTranscriptLanguageCode: string | null | undefined;
    productPrepareType: ProductPrepareTypes;
    selectedWorkspaceId?: string;
}

export interface ProjectPreferences {
    textSettings: ProjectTextDefaultSettings;
    subtitlesSettings: ProjectSubtitlesDefaultSettings;
    videoSettings: ProjectVideoSettings;
    aspectRatio: ProjectSize;
}

export interface ProjectTextDefaultSettings {
    textFontSize: number;
    translationY?: number; // 0-1
    translationX?: number; // 0-1
    angle: number;
    width: number; // 0-1
    box?: ProjectSubtitlesSettingsBox;
    align: SubtitlesAlign;
    textEffect: SubtitleEffect;
    textStyle?: SubtitlesTextStyle | null;
    fontFamily: string;
    outline: number;
    outlineColor: string;
    shadowColor: string;
    spacing: number;
    shadow: number;
    textColor: string;
}

export interface ProjectSubtitlesDefaultSettings {
    subtitlesColor: string;
    subtitlesFontSize: number;
    translationY?: number; // 0-1
    translationX?: number; // 0-1
    box: ProjectSubtitlesSettingsBox;
    angle: number;
    width: number; // 0-1
    align: SubtitlesAlign;
    subtitleEffect: SubtitleEffect;
    subtitlesTextStyle?: SubtitlesTextStyle | null;
    fontFamily: string;
    spacing: number;
    lineHeight: number;
    outline: number;
    outlineColor: string;
    shadowColor: string;
    shadow: number;
    textLines: TextLinesValue;
    fontWeight: number;
}
