/* eslint-disable no-restricted-globals */
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { BuyMoreType, ModalEvent, ModalType, showModal } from 'state/modules/modal';
import { useEffect, useState } from 'react';
import { NEUTRAL_300, PURPLE_200, PURPLE_300 } from '../../../styles/colors';
import { getCurrentUserPlan, getUserUsage, PlanNameId } from '../../../state/modules/payment';
import AvailableMinutes from '../../AvailableMinutes';
import AvailableDubbingCredits from '../../AvailableDubbingCredits';
import AvailableStorage from '../../AvailableStorage';
import BuyMoreAddons from './BuyMoreAddons';

const Container = styled.div`
    flex: 3 1 0;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const TopLine = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
`;

const Title = styled.div`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.625rem;
`;

const SubTitle = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.625rem;
    color: ${NEUTRAL_300}
`;

const FeaturesInfo = styled.p`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    color: ${NEUTRAL_300};
    margin: 8px 0 0;
`;

const FeaturesMoreInfo = styled.span`
    cursor: pointer;
    color: ${PURPLE_200};

    &:hover {
        color: ${PURPLE_300};
    }
`;

const LimitsContainer = styled.div`
    width: 100%;
`;

const Limits = styled.div`
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    grid-auto-flow: column;
    row-gap: 0;
    width: 100%;
    align-content: center;
    gap: 1rem;
`;

const MainContent = styled.div``;

const PlanFeaturesLimits = () => {
    const dispatch = useDispatch();

    const currentPlan = useSelector(getCurrentUserPlan);

    const [buyMoreType, setBuyMoreType] = useState<BuyMoreType | null>(null);

    useEffect(() => {
        dispatch(getUserUsage());
    }, [dispatch]);

    const upgradePlan = () => {
        dispatch(showModal(
            ModalType.SELECT_PLAN,
            ModalEvent.UPGRADE_PLAN,
            '',
            {
                isFromWorkspaceSettings: true,
                defaultRoute: 'plan_limits',
            }
        ));
    };

    const handleBuyMoreTypeChange = (type: BuyMoreType | null) => {
        setBuyMoreType(type);
    }

    const renderAvailableLimits = () => {
        return (
            <LimitsContainer>
                <Limits>
                    {
                        (currentPlan?.planNameId !== PlanNameId.ENTERPRISE) && (
                            <>
                                <AvailableMinutes
                                    isFixed
                                    currentSubscription={currentPlan}
                                    isFeaturesMenu
                                    handleBuyMore={() => handleBuyMoreTypeChange(BuyMoreType.subtitles)}
                                />
                                <AvailableDubbingCredits
                                    currentSubscription={currentPlan}
                                    isFeaturesMenu
                                    handleBuyMore={() => handleBuyMoreTypeChange(BuyMoreType.dubbing)}
                                />
                                <AvailableStorage
                                    currentSubscription={currentPlan}
                                    isFeaturesMenu
                                    handleBuyMore={() => handleBuyMoreTypeChange(BuyMoreType.storage)}
                                />
                            </>
                        )
                    }
                </Limits>
            </LimitsContainer>
        );
    };

    return (
        <Container>
            {
                buyMoreType ? (
                    <BuyMoreAddons
                        type={buyMoreType}
                        handleCancel={() => handleBuyMoreTypeChange(null)}
                    />
                ) : (
                    <>
                        <TopLine>
                            <Title>
                                Upgrade plan limits
                            </Title>
                            {
                                (currentPlan?.planNameId === PlanNameId.INITIAL)  && (
                                    <SubTitle>
                                        <FeaturesInfo>
                                            To increase your plan limits, please&nbsp;
                                            <FeaturesMoreInfo onClick={upgradePlan}>
                                                upgrade your plan
                                            </FeaturesMoreInfo>.
                                        </FeaturesInfo>
                                    </SubTitle>
                                )
                            }
                        </TopLine>
                        <MainContent>
                            {renderAvailableLimits()}
                        </MainContent>
                    </>
                )
            }
        </Container>
    );
};

export default PlanFeaturesLimits;
