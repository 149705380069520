import { AxiosResponse } from 'axios';
import store from 'state/store';
import { build, omit } from 'search-params';

import { RawSentence, TransformedSentence } from 'state/modules/metadata';

import { UploadSrtBody } from 'interfaces/projects';

import $http from './instance';

class ProjectSubtitlesClient {
    public static async getProjectAllSentences(
        projectId: string,
        version: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/transcript`
        );
    }

    public static async addGeneratedSubtitlesToProject(
        projectId: string,
        version: string,
        language: string,
        type: 'transcript' | 'translate',
        videoId?: string,
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({ videoId, language, type });

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/add-transcript?${query}`,
            {videoId, language, type}
        );
    }

    public static async addLibraryVideoSubtitlesToProject(
        projectId: string,
        version: string,
        videoIds: string[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const videoIdsString = videoIds.join(',');

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/videos/?videoIds=${videoIdsString}`
        );
    }

    public static async deleteVideoSubtitlesFromProject(
        projectId: string,
        version: string,
        videoIds: string[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({ videoIds: videoIds.join(',') });

        const body = {
            videoIds: videoIds.join(','),
            projectId,
        };

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/videos?${query}`,
            { data: body }
        );
    }

    public static async addOrEditSubtitleToProject(
        projectId: string,
        version: string,
        subtitles: RawSentence[] | TransformedSentence[],
        subtitleIds?: string[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = subtitleIds ? build({ subtitleIds: subtitleIds.join(',') }) : '';

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/transcript/add?${query}`,
            {
                subtitles,
                subtitleIds,
            }
        );
    }

    public static async deleteSubtitleFromProject(
        projectId: string,
        version: string,
        subtitleIds: string[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({ subtitleIds: subtitleIds.join(',') });

        const data = { subtitleIds: subtitleIds.join(',') };

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/transcript?${query}`,
            { data },
        );
    }

    public static async mergeSubtitlesInProject(
        projectId: string,
        version: string,
        subtitleIds: string[],
        mergedSubtitle: RawSentence
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({ subtitleIds: subtitleIds.join(',') });

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/transcript/merge?${query}`,
            mergedSubtitle
        );
    }

    public static async splitSubtitleInProject(
        projectId: string,
        version: string,
        subtitleId: string,
        splitSubtitles: TransformedSentence[] | RawSentence[]
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();
        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({ subtitleId });

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/transcript/split?${query}`,
            splitSubtitles
        );
    }

    public static async uploadSrt(params: {
        data: UploadSrtBody;
        projectId: string;
        videoId: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { projectId, data, videoId } = params;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/transcript/srt?videoId=${videoId}`,
            data
        );
    }

    public static async getProjectSubtitlesLink(
        type: string,
        projectId: string,
        version: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/subtitles?type=${type}`
        );
    }

    public static async getProjectVideoSubtitlesLink(
        type: string,
        projectId: string,
        version: string,
        videoId: string,
        language: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            type,
            videoId,
            language,
        });

        if (!videoId) {
            query = omit(query, ['videoId']).querystring;
        }

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/projects/${projectId}/version/${version}/subtitles?${query}`
        );
    }
}

export default ProjectSubtitlesClient;
