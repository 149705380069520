import { AnalysisResultsType } from 'interfaces/analysis';
import { SubtitlesSettings } from 'interfaces/subtitles';
import { SentenceItemsTypes } from 'interfaces/transcript';

export interface MetadataState {
    isObjectsLoading: boolean;
    isCelebsLoading: boolean;
    isUserDefinedLoading: boolean;
    isFacesLoading: boolean;
    isTranscriptLoading: boolean;
    isFacialAttributesLoading: boolean;
    isMetadataLoading: boolean;
    isSearchLoading: boolean;
    isModeratedLabelsLoading: boolean;
    labels: Array<TransformedAnalysisResultItem>;
    celebs: Array<TransformedAnalysisResultItem>;
    userDefined: Array<TransformedAnalysisResultItem>;
    transcript: Array<TransformedSentence>;
    facialAttributes: Array<TransformedAnalysisResultItem>;
    moderatedLabels: Array<TransformedAnalysisResultItem>;
    autocompleteItems: Array<TransformAutocompleteItem>;
    sentencesWithSearchedQuery: Array<TransformedSentence>;
    labelsTotal: number;
    celebsTotal: number;
    userDefinedTotal: number;
    facesTotal: number;
    metadataTotal: number;
    facialAttributesTotal: number;
    transcriptTotal: number;
    moderatedLabelsTotal: number;
    shotsTotal: number;
    afterKey: string;
}

export interface GetTranscriptRes {
    content: Array<RawSentence>;
    _metadata: GetTranscriptResMetadata;
}
export interface GetTranscriptResMetadata {
    limit: number;
    offset: number;
    totalCount: number;
}

export interface ResMetadata {
    afterKey: string;
    totalCount: number;
}

export interface GetMetadataRes {
    content: Array<RawAnalysisResultItem>;
    _metadata: ResMetadata;
}

export interface GetAutocompleteRes {
    content: Array<RawAnalysisResultItem>;
}

export interface GetMetadataCategoriesTotalRes {
    content: Array<MetadataCategoryTotalItem>;
}

export interface GetAnalysisResultItemTimestampsRes {
    content: Array<AnalysisResultTimestampItem>;
    _metadata: {
        limit: number;
        offset: number;
        totalCount: number;
    };
}

export interface MetadataCategoryTotalItem {
    count: number;
    name: AnalysisResultsType;
    totalCategoryTimestampsCount: number;
}

export interface RawAnalysisResultItem {
    count: number;
    hasChildren: boolean;
    labelType: AnalysisResultsType;
    name: string;
    parents: Array<AnalysisResultParent>;
}

export interface TransformedAnalysisResultItem extends RawAnalysisResultItem {
    videoId: string;
    id: string;
}

export interface TransformAutocompleteItem extends RawAnalysisResultItem {
    type: string;
    videoId: string;
    id: string;
}

// export interface SubtitlesSettings {
//   subtitlesColor: string;
//   subtitlesFontSize: number;
//   translationY?: number;
//   translationX?: number;
//   box: SubtitlesSettingsBox;
//   angle?: number;
// }

export interface RawSentence {
    data: string;
    id: string;
    items: Array<SentenceDataItem>;
    dataItems?: any[];
    language: string;
    speaker: string;
    startTime: string;
    endTime: string;
    type: AnalysisResultsType;
    videoId: string;
    data_ar_AE?: string;
    data_ar_SA?: string;
    data_da_DK?: string;
    data_de_DE?: string;
    data_en_AB?: string;
    data_en_AU?: string;
    data_en_GB?: string;
    data_en_IE?: string;
    data_en_IN?: string;
    data_en_WL?: string;
    data_es_ES?: string;
    data_es_US?: string;
    data_fr_CA?: string;
    data_fr_FR?: string;
    data_ga_IE?: string;
    data_hi_IN?: string;
    data_it_NL?: string;
    data_pt_BR?: string;
    data_pt_PT?: string;
    data_ru_RU?: string;
    data_tr_TR?: string;
    settings?: SubtitlesSettings;
    projectId?: string;
}

export interface TransformedSentence extends RawSentence {
    name: string;
    originalSentence: string;
    timestamps: Array<AnalysisResultTimestamp>;
    timelineEndTime?: number;
    timelineStartTime?: number;
    timeline?: any;
    isDefault?: boolean;
    originalId?: string;
}

export interface SentenceDataItem {
    startTime: string;
    endTime: string;
    alternatives: Array<SentenceDataAlternativeItem>;
    type?: AnalysisResultsType;
}

export interface SentenceDataAlternativeItem {
    confidence: string;
    content: string;
}

export interface AnalysisResultChildren {
    name: string;
}
export interface AnalysisResultParent {
    name: string;
}

export interface AnalysisResultTimestamp {
    timestamp: number;
}

export interface AnalysisResultTimestampItem {
    confidence: number;
    extra_properties: any;
    id: string;
    instances: Array<any>;
    name: string;
    ownerId: string;
    parents: Array<AnalysisResultParent>;
    scaleFactor: number;
    timestamp: number;
    type: AnalysisResultsType;
    videoId: string;
}

export interface GetTimestampsReqMetadata {
    name: string;
    scaleFactor: number;
    type: AnalysisResultsType;
    range: Array<{
        range: {
            confidence: {
                gte: number;
            };
        };
    }>;
}

export interface MetadaCategoriesTotalPayload {
    labelsTotal: number;
    celebsTotal: number;
    userDefinedTotal: number;
    facesTotal: number;
    facialAttributesTotal: number;
    moderatedLabelsTotal: number;
    shotsTotal: number;
}

export enum MetadataActionTypes {
    SET_OBJECTS = '@@metadata/SET_OBJECTS',
    SET_CELEBS = '@@metadata/SET_CELEBS',
    SET_CAPTIONS = '@@metadata/SET_CAPTIONS',
    SET_USER_DEFINED = '@@metadata/SET_USER_DEFINED',
    SET_FACES = '@@metadata/SET_FACES',
    SET_FACIAL_ATTRIBUTES = '@@metadata/SET_FACIAL_ATTRIBUTES',
    SET_MODERATED_LABELS = '@@metadata/SET_MODERATED_LABELS',
    SET_AFTER_KEY = '@@metadata/SET_AFTER_KEY',
    SET_ALL_METADATA = '@@metadata/SET_ALL_METADATA',
    GET_METADATA = '@@metadata/GET_METADATA',
    GET_METADATA_CATEGORIES_TOTAL = '@@metadata/GET_METADATA_CETEGORIES_TOTAL',
    GET_METADATA_CATEGORIES_TOTAL_SUCCESS = '@@metadata/GET_METADATA_CATEGORIES_TOTAL_SUCCESS',
    GET_METADATA_START = '@@metadata/GET_METADATA_START',
    GET_METADATA_FAIL = '@@metadata/GET_METADATA_FAIL',
    GET_TRANSCRIPT = '@@metadata/GET_TRANSCRIPT',
    GET_TRANSCRIPT_START = '@@metadata/GET_TRANSCRIPT_START',
    GET_TRANSCRIPT_SUCCESS = '@@metadata/GET_TRANSCRIPT_SUCCESS',
    GET_TRANSCRIPT_FAIL = '@@metadata/GET_TRANSCRIPT_FAIL',
    CLEAR_METADATA = '@@metadata/CLEAR_METADATA',
    SET_STATUSES = '@@metadata/SET_STATUSES',
    SET_STATUSES_SUCCESS = '@@metadata/SET_STATUSES_SUCCESS',
    GET_AUTOCOMPLETE = '@@metadata/GET_AUTOCOMPLETE',
    GET_AUTOCOMPLETE_START = '@@metadata/GET_AUTOCOMPLETE_START',
    GET_AUTOCOMPLETE_FAIL = '@@metadata/GET_AUTOCOMPLETE_FAIL',
    GET_AUTOCOMPLETE_SUCCESS = '@@metadata/GET_AUTOCOMPLETE_SUCCESS',
    GET_TRANSCRIPT_BY_SEARCH_QUERY_SUCCESS = '@@metadata/GET_TRANSCRIPT_BY_SEARCH_QUERY_SUCCESS',
    CLEAR_AUTOCOMPLETE_ITEMS = '@@metadata/CLEAR_AUTOCOMPLETE_ITEMS',
    CREATE_NEW_SENTENCE = '@@metadata/CREATE_NEW_SENTENCE',
    SET_TRANSCRIPT_LIST = '@@metadata/SET_TRANSCRIPT_LIST',
    SET_TRANSCRIPT_LIST_TOTAL = '@@metadata/SET_TRANSCRIPT_LIST_TOTAL',
}

export interface GetMetadataActionPayload {
    id: string;
    category: string;
    confidence: number;
    scaleFactor: number;
    afterKey: string;
    size?: number;
}

export interface SetObjectsAction {
    type: MetadataActionTypes.SET_OBJECTS;
    payload: Array<TransformedAnalysisResultItem>;
}
export interface SetCelebsAction {
    type: MetadataActionTypes.SET_CELEBS;
    payload: Array<TransformedAnalysisResultItem>;
}
export interface SetUserDefinedAction {
    type: MetadataActionTypes.SET_USER_DEFINED;
    payload: Array<TransformedAnalysisResultItem>;
}
export interface SetFacialAttributesAction {
    type: MetadataActionTypes.SET_FACIAL_ATTRIBUTES;
    payload: Array<TransformedAnalysisResultItem>;
}
export interface SetModeratedLabelsAction {
    type: MetadataActionTypes.SET_MODERATED_LABELS;
    payload: Array<TransformedAnalysisResultItem>;
}
export interface SetAfterKeyAction {
    type: MetadataActionTypes.SET_AFTER_KEY;
    payload: string;
}

export interface GetMetadataAction {
    type: MetadataActionTypes.GET_METADATA;
    payload: GetMetadataActionPayload;
}
export interface GetMetadataStartAction {
    type: MetadataActionTypes.GET_METADATA_START;
    payload: boolean;
}
export interface GetMetadataFailAction {
    type: MetadataActionTypes.GET_METADATA_FAIL;
    payload: any;
}
export interface GetTranscriptAction {
    type: MetadataActionTypes.GET_TRANSCRIPT;
    payload: {
        id: string;
        query: string;
        offset: number;
    };
}
export interface GetTranscriptStartAction {
    type: MetadataActionTypes.GET_TRANSCRIPT_START;
}
export interface GetTranscriptSuccessAction {
    type: MetadataActionTypes.GET_TRANSCRIPT_SUCCESS;
    payload: {
        transcript: Array<TransformedSentence>;
        total: number;
    };
}
export interface GetTranscriptFailAction {
    type: MetadataActionTypes.GET_TRANSCRIPT_FAIL;
    payload: any;
}
export interface ClearMetadataAction {
    type: MetadataActionTypes.CLEAR_METADATA;
}
export interface GetMetadaCategoriesTotalAction {
    type: MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL;
    payload: {
        id: string;
    };
}
export interface GetMetadaCategoriesTotalSuccessAction {
    type: MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL_SUCCESS;
    payload: MetadaCategoriesTotalPayload;
}
export interface GetAutocompleteAction {
    type: MetadataActionTypes.GET_AUTOCOMPLETE;
    payload: {
        id: string;
        query: string;
        type: string;
    };
}
export interface GetAutocompleteStartAction {
    type: MetadataActionTypes.GET_AUTOCOMPLETE_START;
}
export interface GetAutocompleteSuccessAction {
    type: MetadataActionTypes.GET_AUTOCOMPLETE_SUCCESS;
    payload: { autocomplete: Array<TransformAutocompleteItem> };
}
export interface GetAutocompleteFailAction {
    type: MetadataActionTypes.GET_AUTOCOMPLETE_FAIL;
    payload: any;
}
export interface ClearAutocompleteItemsAction {
    type: MetadataActionTypes.CLEAR_AUTOCOMPLETE_ITEMS;
}
export interface GetTranscriptBySearchQuerySuccessAction {
    type: MetadataActionTypes.GET_TRANSCRIPT_BY_SEARCH_QUERY_SUCCESS;
    payload: Array<TransformedSentence>;
}

export interface CreateNewSentenceAction {
    type: MetadataActionTypes.CREATE_NEW_SENTENCE;
    payload: TransformedSentence;
}

export interface SetTranscriptListAction {
    type: MetadataActionTypes.SET_TRANSCRIPT_LIST;
    payload: Array<TransformedSentence>;
}

export interface SetTranscriptListTotalAction {
    type: MetadataActionTypes.SET_TRANSCRIPT_LIST_TOTAL;
    payload: number;
}

export type MetadataAction =
    | SetObjectsAction
    | SetCelebsAction
    | SetUserDefinedAction
    | SetFacialAttributesAction
    | SetModeratedLabelsAction
    | SetAfterKeyAction
    | GetMetadataAction
    | GetMetadataStartAction
    | GetMetadataFailAction
    | GetTranscriptAction
    | GetTranscriptStartAction
    | GetTranscriptSuccessAction
    | GetTranscriptFailAction
    | ClearMetadataAction
    | GetMetadaCategoriesTotalAction
    | GetMetadaCategoriesTotalSuccessAction
    | GetAutocompleteAction
    | GetAutocompleteStartAction
    | GetAutocompleteSuccessAction
    | GetAutocompleteFailAction
    | ClearAutocompleteItemsAction
    | GetTranscriptBySearchQuerySuccessAction
    | CreateNewSentenceAction
    | SetTranscriptListAction
    | SetTranscriptListTotalAction;
