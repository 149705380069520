import { IconProps } from './index';

const DuplicateIcon = ({ size, color }: IconProps) => {
    let height = 15;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="PopoverStyled__OptionIcon-sc-hgryqi-3 dgurSi"
        >
            <path
                d="M8.8125 5.6875H18.5625C19.671 5.6875 20.5 6.50793 20.5 7.4375V20.9375C20.5 21.8671 19.671 22.6875 18.5625 22.6875H8.8125C7.70401 22.6875 6.875 21.8671 6.875 20.9375V7.4375C6.875 6.50793 7.70401 5.6875 8.8125 5.6875Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.5"
                d="M14.25 1.8125H5.25C4.00736 1.8125 3 2.81986 3 4.0625V17.5625"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DuplicateIcon;
