import { IconProps } from '../index';

const StockIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.40039 8.99995V19.7999H15.2004M16.5 10L12 13.0311V6.96891L16.5 10ZM21.6004 15.2V5.19995C21.6004 4.64767 21.1527 4.19995 20.6004 4.19995H7.24039C6.68811 4.19995 6.24039 4.64767 6.24039 5.19995V15.1999C6.24039 15.7522 6.68811 16.1999 7.24039 16.1999H20.6004C21.1527 16.1999 21.6004 15.7522 21.6004 15.2Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default StockIconNew;


