import { memo } from 'react';
import Loader from 'react-loader-spinner';
import styles from './styles.module.scss';

const LoaderWithOverlay = ({ color }: {
  color: string
}): JSX.Element => (
  <div className={styles.LoaderWithOverlay}>
    <Loader
      type="ThreeDots"
      color={color}
      height={100}
      width={100}
    />
  </div>
);

export default memo(LoaderWithOverlay);
