import React, { memo } from "react";

import styles from "./styles.module.scss";

/**
 * ScreenContent component
 * =======================
 * Renders app page content
 */
const ScreenContent = memo(
  ({ children }: { children: JSX.Element | JSX.Element[] }) => (
    <div id="screenContent" className={styles.ScreenContent}>
      {children}
    </div>
  )
);

export default ScreenContent;
