import { IconProps } from './index';

const AnalyzeIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 21 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Rev-4"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Nova-Enterprise---Home-Page-v2--Selected"
                    transform="translate(-1107.000000, -331.000000)"
                    // fill="#8E9BBC"
                    fill={color}
                    fillRule="nonzero"
                >
                    <g
                        id="Bulk-Actions"
                        transform="translate(1107.000000, 329.000000)"
                    >
                        <path
                            d="M0,15.6 C0,14.275 1.08452885,13.2 2.42307692,13.2 C2.67467308,13.2 2.91213462,13.24845 3.14086298,13.31875 L5.25318029,10.5281 C4.99678846,10.14845 4.84615385,9.69065 4.84615385,9.2 C4.84615385,7.875 5.93149038,6.8 7.26923077,6.8 C8.60697115,6.8 9.69230769,7.875 9.69230769,9.2 C9.69230769,9.45 9.64339183,9.6844 9.57160817,9.91095 L12.3882837,12.00315 C12.7731995,11.75 13.2338365,11.6 13.7307692,11.6 C13.8317308,11.6 13.9263822,11.6172 14.0242139,11.6297 L16.8353365,6.0625 C16.4156899,5.63125 16.1538462,5.04685 16.1538462,4.4 C16.1538462,3.075 17.2391827,2 18.5769231,2 C19.9146635,2 21,3.075 21,4.4 C21,5.725 19.9146635,6.8 18.5769231,6.8 C18.4759615,6.8 18.3813101,6.7828 18.2834784,6.7703 L15.4739207,12.3375 C15.8935673,12.76875 16.1538462,13.35315 16.1538462,14 C16.1538462,15.325 15.0685096,16.4 13.7307692,16.4 C12.3930288,16.4 11.3076923,15.325 11.3076923,14 C11.3076923,13.75155 11.3566082,13.5156 11.4275841,13.28905 L8.61090865,11.19685 C8.22680048,11.45 7.76535577,11.6 7.26923077,11.6 C7.01763462,11.6 6.78017308,11.55155 6.5522524,11.48125 L4.43917788,14.2703 C4.69551923,14.65155 4.84615385,15.1078 4.84615385,15.6 C4.84615385,16.925 3.761625,18 2.42307692,18 C1.08452885,18 0,16.925 0,15.6 Z M18,4.5 C18,4.776375 18.223625,5 18.5,5 C18.776375,5 19,4.776375 19,4.5 C19,4.223625 18.776375,4 18.5,4 C18.223625,4 18,4.223625 18,4.5 Z M13,14.5 C13,14.776375 13.223625,15 13.5,15 C13.776375,15 14,14.776375 14,14.5 C14,14.223625 13.776375,14 13.5,14 C13.223625,14 13,14.223625 13,14.5 Z M7,9.5 C7,9.776375 7.223625,10 7.5,10 C7.776375,10 8,9.776375 8,9.5 C8,9.223625 7.776375,9 7.5,9 C7.223625,9 7,9.223625 7,9.5 Z M2,15.5 C2,15.776375 2.223625,16 2.5,16 C2.776375,16 3,15.776375 3,15.5 C3,15.223625 2.776375,15 2.5,15 C2.223625,15 2,15.223625 2,15.5 Z"
                            id="Analytics"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AnalyzeIcon;
