import {
  ModalActionTypes,
  ShowModalAction,
  ModalEvent,
  ModalType,
  HideModalAction,
  SetModalTypeAction,
  SetModalVisibleAction,
  SetModalEventAction,
  SetModalTextAction,
  SetModalDataAction,
} from 'state/modules/modal';
import { ModalRoute, SetBackRouteAction } from './types';

export function showModal(
  modalType: ModalType,
  event: ModalEvent,
  optionalText?: string,
  optionalData?: any,
): ShowModalAction {
  return {
    type: ModalActionTypes.SHOW_MODAL,
    payload: {
      modalType,
      event,
      optionalText,
      optionalData,
    },
  };
}

export function hideModal(): HideModalAction {
  return {
    type: ModalActionTypes.HIDE_MODAL,
  };
}

export function setModalVisible(visibility: boolean): SetModalVisibleAction {
  return {
    type: ModalActionTypes.SET_MODAL_VISIBILITY,
    payload: visibility,
  };
}

export function setModalType(
  modalType: ModalType | null,
): SetModalTypeAction {
  return {
    type: ModalActionTypes.SET_MODAL_TYPE,
    payload: modalType,
  };
}

export function setModalEvent(
  modalEvent: ModalEvent | null,
): SetModalEventAction {
  return {
    type: ModalActionTypes.SET_MODAL_EVENT,
    payload: modalEvent,
  };
}

export function setModalText(text: string): SetModalTextAction {
  return {
    type: ModalActionTypes.SET_MODAL_TEXT,
    payload: text,
  };
}

export function setModalData(data: any): SetModalDataAction {
  return {
    type: ModalActionTypes.SET_MODAL_DATA,
    payload: data,
  };
}

export function setModalBackRoute(payload: ModalRoute | null): SetBackRouteAction {
  return {
    type: ModalActionTypes.SET_BACK_ROUTE,
    payload,
  };
}
