import styles from './styles.module.scss';

const VideoPreviewPlaceholder = (): JSX.Element => (
  <div className={styles.VideoPreviewPlaceholder}>
    <div className={styles.VideoPreviewPlaceholder__checkBox} />
    <div className={styles.VideoPreviewPlaceholder__content}>
      <div
        className={
                        styles.VideoPreviewPlaceholder__trailerPlaceholderImage
                    }
      />
      <div
        className={
                        styles.VideoPreviewPlaceholder__trailerPlaceholderInfo
                    }
      >
        <div
          className={
                            styles.VideoPreviewPlaceholder__trailerPlaceholderTitle
                        }
        />
      </div>
    </div>
    <div className={styles.VideoPreviewPlaceholder__options} />
  </div>
);

export default VideoPreviewPlaceholder;
