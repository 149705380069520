import { IconProps } from '.';

const ChatIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="m557.38 202.89h-264.69v182h159.36l74.922 74.047-0.10938-74.047h30.516zm-71.859 117.8h-122.5v-14.328h122.61v14.328zm0-25.266h-122.5v-14.328h122.61v14.328zm0-25.266h-122.5v-14.328h122.61v14.328z" />
                <path d="m142.62 101.06v179.92h33.906l-0.10938 66.5 67.375-66.5h39.812v-60.156h-72.297v-15.859h72.297v-9.625-0.98438-0.54688-0.21875c0-0.21875-0.10938-0.4375-0.10938-0.65625h-72.406v-15.859h136.28v15.859h-0.21875c0.10938 0.32812 0.10938 0.65625 0.10938 0.98438h66.938v-92.859zm204.86 63.656h-136.28v-15.969h136.28z" />
                <use x="70" y="576.40625" xlinkHref="#u" />
                <use x="74.011719" y="576.40625" xlinkHref="#c" />
                <use x="76.710938" y="576.40625" xlinkHref="#a" />
                <use x="80.417969" y="576.40625" xlinkHref="#i" />
                <use x="84.109375" y="576.40625" xlinkHref="#b" />
                <use x="86.722656" y="576.40625" xlinkHref="#a" />
                <use x="90.433594" y="576.40625" xlinkHref="#h" />
                <use x="96.25" y="576.40625" xlinkHref="#g" />
                <use x="100.167969" y="576.40625" xlinkHref="#f" />
                <use x="105.636719" y="576.40625" xlinkHref="#e" />
                <use x="109.574219" y="576.40625" xlinkHref="#t" />
                <use x="113.742187" y="576.40625" xlinkHref="#s" />
                <use x="117.472656" y="576.40625" xlinkHref="#e" />
                <use x="70" y="581.875" xlinkHref="#r" />
                <use x="72.378906" y="581.875" xlinkHref="#c" />
                <use x="75.078125" y="581.875" xlinkHref="#d" />
                <use x="78.832031" y="581.875" xlinkHref="#q" />
                <use x="86.4375" y="581.875" xlinkHref="#b" />
                <use x="89.050781" y="581.875" xlinkHref="#p" />
                <use x="92.941406" y="581.875" xlinkHref="#a" />
                <use x="98.554688" y="581.875" xlinkHref="#o" />
                <use x="103.132812" y="581.875" xlinkHref="#d" />
                <use x="106.890625" y="581.875" xlinkHref="#n" />
                <use x="110.785156" y="581.875" xlinkHref="#m" />
                <use x="116.582031" y="581.875" xlinkHref="#l" />
                <use x="120.589844" y="581.875" xlinkHref="#c" />
                <use x="123.285156" y="581.875" xlinkHref="#d" />
                <use x="127.042969" y="581.875" xlinkHref="#k" />
                <use x="128.917969" y="581.875" xlinkHref="#a" />
                <use x="132.625" y="581.875" xlinkHref="#j" />
                <use x="135.867188" y="581.875" xlinkHref="#b" />
            </g>
        </svg>
    );
};

export default ChatIcon;
