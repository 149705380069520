export const PINK = '#EB2F7E';
export const WHITE = '#ffffff';
export const GREY = '#686B92';
export const GREY2 = '#43588F';
export const GREY3 = '#6979A5';
export const BLUE_GREY = '#DEE7F2';
export const BLUE_DARK = '#040849';
export const TURQUOISE = '#00C0BA';
export const ULTRAVIOLET = '#645DF6';
export const ORANGE = '#FFA31A';
export const RED = '#ED3705';
export const PURPLE = '#b84bf2';

// Primary
export const WHITE_PRIMARY = '#ffffff';
export const PURPLE_PRIMARY = '#C250FF';
export const PINK_PRIMARY = '#F724B0';
export const BLUE_PRIMARY = '#34CFFF';
export const ORANGE_PRIMARY = '#FF9636';

// Blacks (Neutral)
export const NEUTRAL_BLACK = '#000000';
export const NEUTRAL_600 = '#080809';
export const NEUTRAL_500 = '#171718';
export const NEUTRAL_400 = '#525253';
export const NEUTRAL_300 = '#7C7C7D';
export const NEUTRAL_200 = '#CECECF';
export const NEUTRAL_150 = '#E4E4E4';
export const NEUTRAL_100 = '#F3F4F5';

// Purple
export const PURPLE_300 = '#7F00C2';
export const PURPLE_200 = '#B84BF2';
export const PURPLE_100 = '#C24FFF';
export const PURPLE_50 = '#ECC9FF';

// Pink
export const PINK_200 = '#EB22A7';
export const PINK_100 = '#F724B0';
export const PINK_50 = '#FFC6EC';

// Blue
export const BLUE_300 = '#0084AC';
export const BLUE_200 = '#31C5F2';
export const BLUE_100 = '#34CFFF';
export const BLUE_50 = '#B0ECFF';

// Orange
export const ORANGE_300 = '#E16B00';
export const ORANGE_200 = '#F28E33';
export const ORANGE_100 = '#FF9636';
export const ORANGE_50 = '#FFD5AF';

// Red
export const RED_300 = '#EF2D00';
export const RED_200 = '#EF2D00';

// Green
export const GREEN_300 = '#02BF48';
export const GREEN_200 = '#13E461';
export const GREEN_100 = '#14F066';
export const GREEN_50 = '#B8FDD3';
// Yellow
export const YELLOW_300 = '#DAB501';
export const YELLOW_200 = '#F2C900';
export const YELLOW_100 = '#FFD400';
export const YELLOW_50 = '#FBEDA9';






