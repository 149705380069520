import { State } from 'state/modules';
import { createSelector } from 'reselect';
import { ProjectUploadState } from './types';

export const getProjectUploadState = (state: State): ProjectUploadState =>
    state.projectUpload;

export const getProjectImageUploadStatus = createSelector(
    getProjectUploadState,
    (state) => state.isImageUploadInProgress
);

// stock video
export const getStockVideoIdWithUploadInProgress = createSelector(
    getProjectUploadState,
    (state) => state.stockVideoIdWithUploadInProgress
);

// stock audio
export const getStockAudioIdWithUploadInProgress = createSelector(
    getProjectUploadState,
    (state) => state.stockAudioIdWithUploadInProgress
);

// stock sound effect
export const getStockSoundEffectIdWithUploadInProgress = createSelector(
    getProjectUploadState,
    (state) => state.stockSoundEffectIdWithUploadInProgress
);

// stock image
export const getStockImageIdWithUploadInProgress = createSelector(
    getProjectUploadState,
    (state) => state.stockImageIdWithUploadInProgress
);
