import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import urlencode from 'urlencode';
import uniqBy from 'lodash.uniqby';

import {
    getSearchFilter,
    getShotsCategory,
    getShotsList,
    ChangeShotsFilterAction,
    GetFilteredShotsAction,
    GetShotsAction,
    GetShotsBySearchAction,
    UpdateShotsFilterItemAction,
    ShotsActionTypes,
    SearchFilterItem,
    Shot,
    TransformedShot,
} from 'state/modules/shots';
import { getVideoInfo } from 'state/modules/media';

import MetadataClient from 'services/api/metadata';

import { buildQueryString } from 'utils/booleanSearchQueryBuilder';

import { ShotsCategory, ShotType } from 'interfaces/shots';

import * as shotsActions from 'state/modules/shots/actions';
import { transformShots } from 'state/utils/shotsHelper';

import { ChangeShotsFilterReason } from 'interfaces/booleanSearch';
import { MediaFile } from 'interfaces/videos';

export function* handleGetShots(action: GetShotsAction): Generator {
    yield put(shotsActions.getShotsStart());
    const shotsCategory = (yield select(getShotsCategory)) as ShotsCategory;
    const isLoadingMore = action.payload.offset;
    const { videoId } = action.payload;

    let type = '';

    if (shotsCategory === ShotsCategory.SHOTS) {
        type = ShotType.SHOT;
    } else if (shotsCategory === ShotsCategory.BLACK_FRAMES) {
        type = ShotType.BLACK_FRAME;
    } else if (shotsCategory === ShotsCategory.END_CREDITS) {
        type = ShotType.END_CREDIT;
    } else if (shotsCategory === ShotsCategory.OPENING_CREDITS) {
        type = ShotType.OPENING_CREDITS;
    } else if (shotsCategory === ShotsCategory.CONTENT) {
        type = ShotType.CONTENT;
    } else if (shotsCategory === ShotsCategory.STUDIO_LOGO) {
        type = ShotType.STUDIO_LOGO;
    }

    try {
        const res = (yield call(
            MetadataClient.getSegments,
            videoId,
            action.payload.offset,
            type
        )) as AxiosResponse;

        if (res.status === 200) {
            let shots = [];
            const transformedShots = transformShots(
                res.data.content
            ) as Array<TransformedShot>;

            if (isLoadingMore) {
                const oldShots = (yield select(
                    getShotsList
                )) as Array<TransformedShot>;

                shots = [...oldShots, ...transformedShots];
            } else {
                shots = transformedShots;
            }

            const total = res.data._metadata.totalCount;

            yield put(shotsActions.getShotsSuccess(shots, total));
        }
    } catch (error) {
        yield put(shotsActions.getShotsFail(error));
    }
}

export function* handleGetFilteredShots(
    action: GetFilteredShotsAction
): Generator {
    yield put(shotsActions.getFilteredShotsStart());

    const { videoId, query } = action.payload;

    const encodedQuery = urlencode(query);

    const shots = [] as Array<TransformedShot>;

    let callsCount = 0;

    function* loadShots(): Generator {
        const res = (yield call(
            MetadataClient.getShotsBySearch,
            videoId,
            encodedQuery,
            callsCount ? callsCount * 500 : 0
        )) as AxiosResponse;

        const data = res.data.content.map((item: Shot, index: number) => ({
            ...item,
            id: item.id,
            labelType: item.type,
            name: `${item.type} ${index}`,
            timestamps: [
                { timestamp: item.startTimestampMillis },
                { timestamp: item.endTimestampMillis },
            ],
        }));

        callsCount += 1;

        if (data.length > 0) {
            shots.push(...data);

            yield call(loadShots);
        }
    }

    try {
        yield call(loadShots);

        const uniqShots = uniqBy(shots, 'id');

        yield put(
            shotsActions.getFilteredShotsSuccess(uniqShots, uniqShots.length)
        );
    } catch (error) {
        console.log({ error });
        yield put(shotsActions.getFilteredShotsFail(error));
    }
}

export function* handleSelectFilter(selectedItem: SearchFilterItem): Generator {
    const searchFilters = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const updatedFilters = [...searchFilters, selectedItem];
    yield put(shotsActions.setSearchFilter(updatedFilters));
}

export function* handeSelectFilterValue(
    selectedItem: SearchFilterItem,
    videoId: string
): Generator {
    const searchFilters = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const updatedFilters = [...searchFilters, selectedItem];

    yield put(shotsActions.setSearchFilter(updatedFilters));

    const values = updatedFilters.filter(
        (item: SearchFilterItem) => item.type !== 'filter'
    );

    const query = buildQueryString(values);

    yield put(shotsActions.getFilteredShots(videoId, query));
}

export function* handleSelectFilterOption(
    option: SearchFilterItem,
    videoId: string
): Generator {
    if (option.type === 'filter') {
        yield call(handleSelectFilter, option);
    } else {
        yield call(handeSelectFilterValue, option, videoId);
    }
}

export function* handleCreateFilterValue(
    option: SearchFilterItem,
    videoId: string
): Generator {
    const searchFilters = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const updatedFilters = [
        ...searchFilters,
        option,
    ] as Array<SearchFilterItem>;

    yield put(shotsActions.setSearchFilter(updatedFilters));

    const values = updatedFilters.filter(
        (item: SearchFilterItem) => item.type !== 'filter'
    );

    const query = buildQueryString(values);

    yield put(shotsActions.getFilteredShots(videoId, query));
}

export function* handleRemoveFilterValue(
    option: SearchFilterItem,
    videoId: string
): Generator {
    const searchFilters = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const itemIndex = searchFilters.findIndex(
        (item: SearchFilterItem) => item.id === option.id
    );

    const itemsToRemoveIndex = [itemIndex];

    if (option.type !== 'filter') {
        itemsToRemoveIndex.push(itemIndex - 1);
    }

    const filteredItems = searchFilters.filter(
        (item: SearchFilterItem, index: number) =>
            !itemsToRemoveIndex.includes(index)
    );

    yield put(shotsActions.setSearchFilter(filteredItems));

    if (filteredItems.length) {
        const values = filteredItems.filter(
            (item: SearchFilterItem) => item.type !== 'filter'
        );

        const query = buildQueryString(values);

        yield put(shotsActions.getFilteredShots(videoId, query));
    } else {
        yield put(shotsActions.getShots(videoId, 0));
    }
}

export function* handleChangeShotsCategory(): Generator {
    const videoInfo = (yield select(getVideoInfo)) as MediaFile;

    yield put(shotsActions.getShots(videoInfo?.id || '', 0));
}

/// //

export function* handleChangeShotsFilter(
    action: ChangeShotsFilterAction
): Generator {
    const { option, videoId, reason } = action.payload;

    if (reason === ChangeShotsFilterReason.SELECT_OPTION) {
        yield call(handleSelectFilterOption, option, videoId);
    }

    if (reason === ChangeShotsFilterReason.CREATE_OPTION) {
        yield call(handleCreateFilterValue, option, videoId);
    }

    if (reason === ChangeShotsFilterReason.CLEAR) {
        yield put(shotsActions.setSearchFilter([]));
        yield put(shotsActions.getShots(videoId, 0));
    }

    if (reason === ChangeShotsFilterReason.REMOVE_OPTION) {
        yield call(handleRemoveFilterValue, option, videoId);
    }
}

export function* handleGetShotsBySearch(
    action: GetShotsBySearchAction
): Generator {
    const { videoId } = action.payload;

    const filterParams = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const values = filterParams.filter(
        (item: SearchFilterItem) => item.type !== 'filter'
    );

    const query = buildQueryString(values);

    yield put(shotsActions.getFilteredShots(videoId, query));
}

export function* handleUpdateShotsFilterItem(
    action: UpdateShotsFilterItemAction
): Generator {
    const { item, videoId } = action.payload;

    const filterParams = (yield select(
        getSearchFilter
    )) as Array<SearchFilterItem>;

    const updatedFilterParams = filterParams.map(
        (filterItem: SearchFilterItem) => {
            if (filterItem.id === item.id) {
                return item;
            }
            return filterItem;
        }
    );

    yield put(shotsActions.setSearchFilter(updatedFilterParams));

    const values = updatedFilterParams.filter(
        (item: SearchFilterItem) => item.type !== 'filter'
    );

    const query = buildQueryString(values);

    yield put(shotsActions.getFilteredShots(videoId, query));
}

export function* shotsSaga(): Generator {
    yield takeLatest(ShotsActionTypes.GET_SHOTS, handleGetShots);
    yield takeLatest(
        ShotsActionTypes.GET_SHOTS_BY_SEARCH,
        handleGetShotsBySearch
    );
    yield takeLatest(
        ShotsActionTypes.GET_FILTERED_SHOTS,
        handleGetFilteredShots
    );
    yield takeLatest(
        ShotsActionTypes.CHANGE_SEARCH_FILTER,
        handleChangeShotsFilter
    );
    yield takeLatest(
        ShotsActionTypes.UPDATE_SEARCH_FILTER_ITEM,
        handleUpdateShotsFilterItem
    );
    yield takeLatest(
        ShotsActionTypes.SET_SHOTS_CATEGORY,
        handleChangeShotsCategory
    );
}
