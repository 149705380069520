import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';

import { NewButton } from 'components/UI';

import { getModalOptionalData } from 'state/modules/modal';
import ProjectsClient from 'services/api/projects';
import { getProjects, setTemplateWorkspaceId } from 'state/modules/projects';

const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 25px;
`;

const Container = styled.form`
    display: flex;
    height: auto;
    overflow: auto;
    padding: 1.5rem;
    z-index: 10;
    position: relative;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    min-width: 280px;
`;

const Title = styled.p`
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

const Input = styled.input`
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(223, 224, 229);
    box-shadow: rgb(0, 0, 0, 0, 10) 0px 1px 2px;
    border-radius: 10px;
    height: 3rem;
    padding: 1rem;
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
`;

interface Props {
    closeModal: () => void;
}

const RenameProjectModal = ({ closeModal }: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const { project } = useSelector(getModalOptionalData);

    const dispatch = useDispatch();

    useEffect(() => {
        if (project) {
            setName(project.name);
        }
    }, [project]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleRename = async (event: any) => {
        event.preventDefault();

        try {
            setLoading(true);

            const updatedProject = {
                ...project,
                name,
            };

            await ProjectsClient.updateProject(updatedProject, true);

            setTimeout(() => {
                dispatch(getProjects({isTemplate: project?.isTemplate}));
                setLoading(false);
                closeModal();
            }, 1500);
        } catch (error) {
            console.log({ error });
            setLoading(false);
        }

        dispatch(setTemplateWorkspaceId(null));
    };

    return (
        <Container onSubmit={handleRename}>
            <Title>Rename Project</Title>
            <Input value={name} onChange={handleChange} />
            <Actions>
                <NewButton
                    disabled={isLoading}
                    color=""
                    borderRadius={4}
                    height={48}
                    onClick={closeModal}
                >
                    Cancel
                </NewButton>
                <NewButton
                    color="#051B5A"
                    textColor="#Fff"
                    bordered
                    borderRadius={6}
                    height={48}
                    // onClick={handleRename}
                    disabled={isLoading}
                    loading={isLoading}
                    type="submit"
                >
                    Rename
                </NewButton>
            </Actions>
        </Container>
    );
};

export default RenameProjectModal;
