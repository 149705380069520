import { IconProps } from './index';

const MakeClipIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 16;
  if (size) {
    height = size;
  }

  return (
    <svg width={height} height={height} viewBox="0 0 16 16" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="07-Nova-Enterprise---Multiple-Search"
          transform="translate(-810.000000, -514.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g
            id="Control-Panel"
            transform="translate(525.000000, 477.000000)"
          >
            <g
              id="Make-a-Clip"
              transform="translate(272.000000, 25.000000)"
            >
              <path
                d="M16.24,24.32 L25.32,24.32 L25.32,15.24 L16.24,15.24 L16.24,24.32 Z M16.24,25.61 L16.24,27.61 L14.95,27.61 L14.95,25.61 L13,25.61 L13,24.32 L14.95,24.32 L14.95,13.95 L25.32,13.95 L25.32,12 L26.61,12 L26.61,13.95 L28.61,13.95 L28.61,15.24 L26.61,15.24 L26.61,25.61 L16.24,25.61 Z"
                id="Clip"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MakeClipIcon;
