import { memo, useState, useRef, useEffect, ChangeEvent } from "react";
import { v4 as uuid } from "uuid";
import Loader from "react-loader-spinner";

import { Icon } from "components/UI";

import { useOnClickOutside } from "hooks/useOnClickOutside";

import styles from "./styles.module.scss";

type Type = any;

interface Props {
  options: Array<Type>;
  value?: {
    id: string | number;
    value: string | number | null;
    label?: string | number;
  } | null;
  onSelect: (value: Type) => void;
  hasInput?: boolean;
  hasClearButton?: boolean;
  onChangeValue?: (value: Type) => void;
  rightLabel?: string;
  error?: string | null;
  setError?: (error: any) => void;
  type?: string;
  color?: string;
  placeholder?: string;
  blue?: boolean;
  red?: boolean;
  white?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  boxShadowed?: boolean;
  extraSmall?: boolean;
  isLoading?: boolean;
}

const DropdownAutocomplete = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(false);
  const drop = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const {
    options,
    value,
    hasInput,
    onSelect,
    onChangeValue,
    rightLabel,
    error,
    setError,
    type,
    color,
    placeholder,
    hasClearButton,
    isLoading,
    blue,
    red,
    white,
    large,
    medium,
    small,
    boxShadowed,
    extraSmall,
  } = props;

  useOnClickOutside(drop, () => setOpen(false));

  const handleSelect = (item: {
    id: string | number;
    value: string | number;
    label?: string | number;
  }) => {
    onSelect(item);
    setOpen(false);
  };

  let timer: any = null;

  useEffect(() => {
    if (listRef && listRef.current) {
      if (listRef.current.scrollTop < 115 && isAutoScrollEnabled) {
        try {
          timer = setInterval(() => {
            const listElem = listRef?.current as any;

            if (listElem) {
              const updatedScrollLength = listElem.scrollTop + 10;

              listElem.scrollTop = updatedScrollLength;
            }
          }, 100);
        } catch (error) {
          console.log({ error });
        }
      }
    }
  }, [isAutoScrollEnabled, listRef, listRef.current, timer]);

  const enableAutoScroll = () => {
    setIsAutoScrollEnabled(true);
  };

  const disableAutoScroll = () => {
    clearInterval(timer);
    setIsAutoScrollEnabled(false);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) {
      if (type === "number") {
        const validationPassed = event.target.value.match(/^[0-9]*\.?[0-9]*$/);

        if (validationPassed) {
          onChangeValue({
            id: uuid(),
            value: event.target.value,
          });
        }
      } else {
        onChangeValue({
          id: uuid(),
          value: event.target.value,
        });
      }
    }
  };

  const toggle = () => {
    if (!isFocused) {
      setOpen(!open);
    }
  };

  const handleFocus = () => {
    setOpen(true);
    setIsFocused(true);

    if (error && setError) {
      setError(null);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleClear = () => {
    onSelect(null);
  };

  const renderDropdownContent = () =>
    options.map((item: Type) => {
      const isSelected = item.id === value?.id;

      return (
        <div
          onClick={() => handleSelect(item)}
          className={[
            styles.DropdownAutocomplete__item,
            isSelected && styles.DropdownAutocomplete__item_selected,
          ].join(" ")}
          key={item.id}
        >
          {item?.label || item?.value}
        </div>
      );
    });

  const renderValue = () => {
    if (hasInput) {
      return (
        <input
          value={value?.value || ""}
          className={[
            styles.DropdownAutocomplete__input,
            error && styles.DropdownAutocomplete__input_error,
          ].join(" ")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          placeholder={placeholder || ""}
          onClick={toggle}
        />
      );
    }
    const autocompleteValue = value?.label || value?.value;

    return autocompleteValue ? (
      <p className={styles.DropdownAutocomplete__value} onClick={toggle}>
        {autocompleteValue}
      </p>
    ) : (
      <p className={styles.DropdownAutocomplete__placeholder} onClick={toggle}>
        {placeholder || ""}
      </p>
    );
  };

  const renderError = () => (
    <p className={styles.DropdownAutocomplete__error}>{error}</p>
  );

  const renderClearButton = () => {
    if (value?.id && hasClearButton) {
      return (
        <button
          className={styles.DropdownAutocomplete__clearButton}
          onClick={handleClear}
        >
          <Icon size={10} name="close" />
        </button>
      );
    }
  };

  const renderToggler = () => (
    <div className={styles.DropdownAutocomplete__toggler} onClick={toggle}>
      <Icon
        size={10}
        color={color || "#43588F"}
        name={open ? "arrowUp" : "arrowDown"}
      />
    </div>
  );

  const renderLabel = () =>
    rightLabel &&
    !value && (
      <p
        className={styles.DropdownAutocomplete__label}
        style={{
          color: color || "#B5B5B5",
        }}
      >
        {rightLabel}
      </p>
    );

  const renderLoader = () => (
    <div className={styles.DropdownAutocomplete__loader}>
      <Loader type="Oval" color="#fd0b50" height={20} width={20} />
    </div>
  );

  const renderRightSide = () => (
    <div className={styles.DropdownAutocomplete__rightSide}>
      {renderLabel()}
      {isLoading ? renderLoader() : renderClearButton()}
      {renderToggler()}
    </div>
  );

  const renderInteractionBlock = () => (
    <div
      className={styles.DropdownAutocomplete__interactionBlock}
      onMouseOver={enableAutoScroll}
      onMouseLeave={disableAutoScroll}
    >
      <div className={styles.DropdownAutocomplete__interactionIndicator} />
    </div>
  );

  const renderOptions = () =>
    options?.length ? (
      renderDropdownContent()
    ) : (
      <p className={styles.DropdownAutocomplete__emptyListMessage}>Emty list</p>
    );

  const renderOptionsLoader = () => (
    <div className={styles.DropdownAutocomplete__optionsLoader}>
      <span className={styles.DropdownAutocomplete__optionsLoaderTitle}>
        Loading...
      </span>
      <Loader type="Oval" color="#fd0b50" height={20} width={20} />
    </div>
  );

  const renderOptionsDropdown = () =>
    open ? (
      <div
        className={styles.DropdownAutocomplete__dropdownContent}
        ref={listRef}
      >
        {isLoading ? (
          renderOptionsLoader()
        ) : (
          <>
            {renderOptions()}
            {renderInteractionBlock()}
          </>
        )}
      </div>
    ) : null;

  return (
    <div className={styles.DropdownAutocomplete} ref={drop}>
      <div
        className={[
          styles.DropdownAutocomplete__button,
          blue && styles.DropdownAutocomplete__button_blue,
          red && styles.DropdownAutocomplete__button_red,
          white && styles.DropdownAutocomplete__button_white,
          large ? styles.DropdownAutocomplete__button_large : "",
          medium ? styles.DropdownAutocomplete__button_medium : "",
          small ? styles.DropdownAutocomplete__button_small : "",
          boxShadowed ? styles.DropdownAutocomplete__button_boxShadowed : "",
          extraSmall ? styles.DropdownAutocomplete__button_extraSmall : "",
        ].join(" ")}
      >
        {renderValue()}
        {error && renderError()}
        {renderRightSide()}
      </div>
      {renderOptionsDropdown()}
    </div>
  );
};

export default memo(DropdownAutocomplete);
