import { AxiosResponse } from 'axios';

import store from 'state/store';
import { UpdateUserTourBody } from 'state/modules/app/types';

import { ProjectPreferences, UserPreferences } from 'interfaces/preferences';

import { build } from 'search-params';
import $http from './instance';

class UserClient {
    public static async getUserPreference(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/preferences`);
    }

    public static async updateUserProjectPreferences(
        data: ProjectPreferences
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(`/user/${userId}/preferences/project`, data);
    }

    public static async updateUserPreference(
        data: UserPreferences
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(
            `/user/${userId}/preferences/workflowPreferences`,
            data
        );
    }

    public static async updateUserTourStatus(
        data: UpdateUserTourBody
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(`/user/${userId}/preferences/tour`, data);
    }

    public static async updateFirstLoginStatus(data: {
        isFirstLogin: boolean;
    }): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(`/user/${userId}/preferences/firstLogin`, data);
    }

    // Get invites list
    public static async getInvitesList(
        offset = 0,
        limit?: number
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        const query = build({
            limit: limit || 10,
            offset,
            sortBy: '-createdDate',
        });

        return $http.get(`/user/${userId}/invitations?${query}`);
    }

    // Create new invite
    public static async sendInvite(
        emailForInvite: string
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/invitations`, {
            emailForInvite,
        });
    }

    // Generate user invitation code
    public static async getInviteCode(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/preferences/invitationCode`);
    }
}

export default UserClient;
