import { IconProps } from '../index';

const TextInScaleIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.876 20.03c.603-.666 1.327-1 2.172-1 .845 0 1.554.302 2.13.905.581.595.872 1.315.872 2.158 0 .844-.294 1.567-.884 2.17a2.882 2.882 0 0 1-2.14.903c-.83 0-1.547-.361-2.15-1.084v.893h-.862V17h.862v3.03zm2.119 4.285c.603 0 1.114-.223 1.533-.67.425-.446.639-.967.639-1.562 0-.603-.206-1.127-.618-1.574a2.012 2.012 0 0 0-1.544-.67c-.617 0-1.135.21-1.554.627-.412.419-.617.947-.617 1.585 0 .63.209 1.166.628 1.605.418.44.93.66 1.533.66z"
                fill="#7C7C7D" />
            <path
                d="M18.52 25.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H10L14.135 16h.31l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664z"
                fill="#525253" />
            <path
                d="M32.5 23.732a2.487 2.487 0 0 1-1.012.86c-.413.196-.86.295-1.345.295-.752 0-1.383-.248-1.89-.743a2.455 2.455 0 0 1-.753-1.817 2.5 2.5 0 0 1 .762-1.835c.507-.513 1.165-.77 1.971-.77.478 0 .917.105 1.317.314.406.209.723.501.95.877l-.582.367c-.436-.567-1.007-.85-1.712-.85-.555 0-1.021.179-1.398.537-.376.352-.564.79-.564 1.315 0 .525.185.976.555 1.352.37.375.84.563 1.407.563.681 0 1.255-.286 1.72-.859l.574.394z"
                fill="#CECECF" />
        </svg>
    );
};

export default TextInScaleIcon;