import {
  call, put, takeLatest, delay, select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import isEqual from 'lodash.isequal';

import * as generationActions from 'state/modules/generation/actions';
import * as jobsActions from 'state/modules/jobs/actions';
import * as videosActions from 'state/modules/media/actions';
import * as modalActions from 'state/modules/modal/actions';

import {
  ClipOrder,
  GenerationActionTypes,
  getTrailersList,
  ClipCustomGenData,
  ClipCustomGenRawData,
  ClipTemplateGenData,
  GetTrailersAction,
  GenerateClipPreviewsAction,
} from 'state/modules/generation';
import { Scene } from 'state/modules/videoExplorer';

import { getTrailersResponse } from 'models/generation';

import TrailersClient from 'services/api/trailers';

import { ClipTemplate } from 'interfaces/generation';

import {
  getCalculatedClipLength,
  getFilteredDataForGeneration,
  getFormattedScenesForGeneration,
  getMixedScenes,
} from 'utils/generatorHelpres';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { NotificationsMassage } from 'utils/messages';
import { MediaFile } from 'interfaces/videos';
import {
  ClipGenerationDataClip,
  ClipRenderData,
  GenerateClipsAction,
  GetPreviewsAction,
} from './types';
import { getUserId } from '../user';
import { getVideoInfo } from '../media';
import { getModalEvent, ModalEvent, ModalType } from '../modal';

function* handleGetTrailers(action: GetTrailersAction): Generator {
  try {
    const oldTrailers = yield select(getTrailersList);

    const res = (yield call(
      TrailersClient.getTrailers,
      action.payload,
    )) as AxiosResponse<getTrailersResponse>;

    if (res.status === 200) {
      const newTrailers = res.data.content;
      const isTrailersEqual = isEqual(oldTrailers, newTrailers);

      if (!isTrailersEqual || !res.data.content.length) {
        yield put(generationActions.getTrailersSuccess(newTrailers));
      }

      yield put(jobsActions.getVideoJobs(action.payload));
      yield put(generationActions.getPreviews(action.payload));
    }
  } catch (error) {
    yield put(generationActions.getTrailersFail(error));
  }
}

export function* generateClipPreviewsBySelectedTemplate(
  data: ClipCustomGenData | ClipTemplateGenData,
): Generator { 
  try {
    const generationRes = (yield call(
      TrailersClient.generate,
      data,
    )) as AxiosResponse;
    const modalEvent  = (yield select(getModalEvent)) as ModalEvent;

    if (generationRes.data) {
      yield call(
        showNotification,
        NotificationTypes.info,
        'Clips previews generation started. This may take some time',
      ); 

      
      yield delay(8000);
      yield put(videosActions.getVideoList());
      yield put(generationActions.getTrailers(data.videoId));
      yield put(jobsActions.getVideoJobs(data.videoId));
      yield put(generationActions.getPreviews(data.videoId));

      if (modalEvent === ModalEvent.GENERATE_VIDEO) {
        yield put(modalActions.hideModal());
      }
      
      yield put(generationActions.generateClipPreviewsSuccess());

      return generationRes.data.jobId;
    }
  } catch (error) {
    // console.log({ error });
    yield call(
      showNotification,
      NotificationTypes.error,
      (error as any)?.response.data?.message || 'Something went wrong',
    );
    yield put(generationActions.generateClipPreviewsFail(error));

    return '';
  }
}

function* customGeneration(data: ClipCustomGenRawData) {
  const {
    selectedTimestamps,
    minSceneLength,
    maxSceneLength,
    minClipLength,
    maxClipLength,
    videoId,
    order,
    minConfidence,
    minScaleFactor,
    isMuted,
    isCustomWithLimit,
    numberOfClips,
  } = data;

  let scenes = [...selectedTimestamps] as Array<Scene>;

  if (order === ClipOrder.RANDOM && scenes.length > 1) {
    scenes = getMixedScenes(scenes);
  }

  const timestamps = getFilteredDataForGeneration(scenes, {
    maxSceneLength,
    minSceneLength,
    minConfidence,
    minScaleFactor: minScaleFactor || 0,
    isCustomWithLimit,
    isRandomOrder: order === ClipOrder.RANDOM,
    maxClipLength,
  });

  if (timestamps?.length) {
    const totalLength = getCalculatedClipLength(timestamps);

    if (
      (isCustomWithLimit && totalLength < minClipLength - 1000)
            || (!isCustomWithLimit && totalLength < minClipLength)
    ) {
      yield call(
        showNotification,
        NotificationTypes.error,
        NotificationsMassage.NOT_ENOUGH_SCENES,
      );

      return;
    }

    const formatedScenes = getFormattedScenesForGeneration(timestamps);

    const clipGenData = {
      videoId,
      template: ClipTemplate.CUSTOM,
      customClipData: {
        clipShots: formatedScenes,
        minSceneLength,
        maxSceneLength,
        minClipLength,
        maxClipLength,
        isMuted,
        order,
        minConfidence,
        minScaleFactor,
        numberOfClips,
      },
    } as ClipCustomGenData;

    yield call(generateClipPreviewsBySelectedTemplate, clipGenData);
  } else {
    yield put(
      generationActions.generateClipPreviewsFail(
        NotificationsMassage.NOT_ENOUGH_SCENES,
      ),
    );

    yield call(
      showNotification,
      NotificationTypes.error,
      NotificationsMassage.NOT_ENOUGH_SCENES,
    );
  }
}

function* handleGenerateClipPreviews(
  action: GenerateClipPreviewsAction,
): Generator {
  yield put(generationActions.generateClipPreviewsStart());

  const {
    selectedTemplate,
    selectedTimestamps,
    minSceneLength,
    maxSceneLength,
    minClipLength,
    maxClipLength,
    videoId,
    numberOfClips,
    order,
    minConfidence,
    minScaleFactor,
    isMuted,
    diffFactor,
    startTimeInterval,
    endTimeInterval,
  } = action.payload;

  const isCustomWithLimit = selectedTemplate.value === ClipTemplate.CUSTOM_TEN_SEC
        || selectedTemplate.value === ClipTemplate.CUSTOM_TWENTY_SEC;

  const isCustom = selectedTemplate.value === ClipTemplate.CUSTOM || isCustomWithLimit;

  try {
    if (!isCustom) {
      const generationParams = {
        videoId,
        template: selectedTemplate.value,
        clipData: {
          numberOfClips,
          minSceneLength,
          maxSceneLength,
          minClipLength,
          maxClipLength,
          confidence: minConfidence,
          boundingBox: minScaleFactor,
          order,
          isMuted,
          diffFactor,
          startTimeInterval,
          endTimeInterval,
        },
      } as ClipTemplateGenData;

      return yield call(
        generateClipPreviewsBySelectedTemplate,
        generationParams,
      );
    }
    
    const customGenerationParams = {
      selectedTimestamps,
      minSceneLength,
      maxSceneLength,
      minClipLength,
      maxClipLength,
      videoId,
      order,
      minConfidence,
      minScaleFactor,
      isMuted,
      isCustomWithLimit,
      numberOfClips,
    } as ClipCustomGenRawData;

    yield call(customGeneration, customGenerationParams);
  } catch (error) {
    console.log({ error });
    yield put(generationActions.generateClipPreviewsFail(error));
    yield call(
      showNotification,
      NotificationTypes?.error,
      'Something went wrong',
    );
  }
}

function* handleGetPreviews(action: GetPreviewsAction): Generator {
  yield put(generationActions.getPreviewsStart());

  const videoId = action.payload;

  try {
    const res = (yield call(
      TrailersClient.getClipPreviews,
      videoId,
    )) as AxiosResponse;

    const previews = res.data.content;

    yield put(generationActions.getPreviewsSuccess({ previews }));
  } catch (error) {
    console.log({ error });
    yield put(generationActions.getPreviewsFail(error));
  }
}

function* renderClip(
  videoId: string,
  previewId: string,
  data: ClipRenderData,
): Generator {
    (yield call(
      TrailersClient.renderClipByPreview,
      videoId,
      previewId,
      data,
    )) as AxiosResponse;
}

function* handleGenerateClips(action: GenerateClipsAction): Generator {
  yield put(generationActions.generateClipsStart());

  const { previewsData, selectedClips, videoId } = action.payload;

  const userId = (yield select(getUserId)) as string;
  const videoDetails = (yield select(getVideoInfo)) as MediaFile;
  const modalEvent  = (yield select(getModalEvent)) as ModalEvent;

  const renderData = selectedClips.map((item: ClipGenerationDataClip) => ({
    videoId: item.videoId,
    ownerId: item.ownerId,
    userId: userId || '',
    jobId: item.jobId,
    type: item.type,
    template: previewsData.template,
    clip: item,
    fps: videoDetails?.mediaInfo?.proxy?.video?.[0]?.framerate || 
        videoDetails?.mediaInfo?.original?.video?.[0]?.framerate,
  }));

  try {
    for (const renderDataItem of renderData) {
      yield call(renderClip, videoId, previewsData.id, renderDataItem);
    }


    yield delay(2500);
    yield put(generationActions.generateClipsSuccess());

    if (modalEvent === ModalEvent.GENERATE_VIDEO) {
      yield put(modalActions.hideModal());
    }
  } catch (error) {
    console.log({ error });
    yield put(generationActions.generateClipsFail(error));
  }
}

export function* generationSaga(): Generator {
  yield takeLatest(GenerationActionTypes.GET_TRAILERS, handleGetTrailers);
  yield takeLatest(
    GenerationActionTypes.GENERATE_CLIP_PREVIEWS,
    handleGenerateClipPreviews,
  );
  yield takeLatest(GenerationActionTypes.GET_PREVIEWS, handleGetPreviews);
  yield takeLatest(GenerationActionTypes.GENERATE_CLIPS, handleGenerateClips);
}
