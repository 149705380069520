import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { NewButton, Switcher } from 'components/UI';

import {
  exportSelectedMetadataAsVideo,
  getExportAsVideoClipDownloadedStatus,
  getExportAsVideoLink,
  getExportAsVideoLoading,
  getExportAsVideoParams,
  getGroupedExportList,
  setExportAsVideoParams,
  updateExportAsVideoParams
} from 'state/modules/export';
import { ClipOrder } from 'state/modules/generation';

import { Colors } from 'styles';
import { getVideoInfo } from 'state/modules/media';
import { MediaFile } from 'interfaces/videos';
import ClipPreview from 'components/ClipPreview';
import { reducer } from 'utils/calc';
import { secondsToHms } from 'utils/files';
import styles from './styles.module.scss';

interface Props {
  closeButton: JSX.Element;
  closeModal: () => void;
}

const ExportAsVideoModal = (props: Props): JSX.Element => {
  const [order, setOrder] = useState(ClipOrder.LOGICAL);

  const intervalId = useRef(null) as any;

  const exportParams = useSelector(getExportAsVideoParams);
  const exportList = useSelector(getGroupedExportList)
  const currentMediaDetails = useSelector(getVideoInfo) as MediaFile;
  const isExportAsVideoLoading = useSelector(getExportAsVideoLoading);
  const clipLink = useSelector(getExportAsVideoLink);
  const isDownLoaded = useSelector(getExportAsVideoClipDownloadedStatus);

  const dispatch = useDispatch();

  const { closeButton, closeModal } = props;

  const currentVideoExportItems = exportList.find((item) => item.videoId === currentMediaDetails.id);

  const isLoading: boolean = useMemo(() => {
    if (exportParams) {
      const {
        isClipGenerationInProgress,
        isPreviewGenerationInProgress
      } = exportParams;

      return isExportAsVideoLoading || isClipGenerationInProgress || isPreviewGenerationInProgress;
    }


    return isExportAsVideoLoading;
  }, [exportParams, isExportAsVideoLoading])

  const downloadClip = useCallback(
    async () => {
      if (clipLink && exportParams) {
        try {
          dispatch(setExportAsVideoParams({
            ...exportParams,
            isClipDownloaded: true,
          }))

          const link = document.createElement('a');
          link.download = 'clip.mp4';
          link.target = '_blank';

          link.href = clipLink;

          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.log({ error })
        }
      }
    }, [clipLink, dispatch, exportParams],
  )


  useEffect(() => {
    if (clipLink && !isDownLoaded) {
      downloadClip();
    }
  }, [clipLink, downloadClip, isDownLoaded])

  useEffect(() => () => {
    if (intervalId?.current) {
      clearTimeout(intervalId);
      clearTimeout(intervalId?.current);
      intervalId.current = null;
    }
  }, [intervalId])

  useEffect(() => {
    if (intervalId?.current && !isExportAsVideoLoading) {
      clearTimeout(intervalId);
      clearTimeout(intervalId.current);
      intervalId.current = null;
    }
  }, [intervalId, isExportAsVideoLoading])


  useEffect(() => {
    if (!intervalId?.current && isLoading) {
      intervalId.current = setInterval(() => {
        dispatch(updateExportAsVideoParams())
      }, 30000);
    }
  }, [dispatch, isLoading, intervalId])

  useEffect(() => {
    dispatch(updateExportAsVideoParams());
  }, [dispatch])

  const handleExport = () => {
    if (exportParams?.clip) {
      return downloadClip()
    }

    dispatch(exportSelectedMetadataAsVideo({
      order,
    }));
  };

  const handleChangeOrder = () => {
    if (order === ClipOrder.LOGICAL) {
      setOrder(ClipOrder.RANDOM);
    } else {
      setOrder(ClipOrder.LOGICAL);
    }
  };

  const renderPreview = () => {
    if (currentVideoExportItems) {
      const scenes = currentVideoExportItems.data.map(currentVideoExportItem =>
        currentVideoExportItem.scenes).flat();
      const scenesDurationArray = scenes.map(scene => scene.duration);

      const shotsDuration = scenesDurationArray.reduce(reducer, 0);

      const timestamps = scenes.map((scene) => ({
        from: scene.from,
        to: scene.to,
      }))

      const clip = {
        clipId: '',
        requestId: '',
        videoId: currentMediaDetails.id,
        ownerId: '',
        jobId: '',
        type: '',
        clipVersion: 1,
        isMuted: false,
        timestamps,
      } as any;

      return (
        <ClipPreview
          videoDetails={currentMediaDetails}
          shots={clip.timestamps}
          isMuted={false}
          shotsDuration={shotsDuration}
          title="Clip Preview"
          isSelected
          data={clip}
        />
      )
    }
  }

  const renderControls = () => (
    <div className={styles.ExportAsVideoModal__inputWrap}>
      <p className={styles.ExportAsVideoModal__inputLabel}>
        Order:
      </p>
      <Switcher
        checked={order === ClipOrder.LOGICAL}
        labelLeft="In Selected Order"
        labelRight="Сhronological"
        onChange={handleChangeOrder}
      />
    </div>
  )

  const renderSubmitButton = () => (
    <NewButton
      color={Colors.PINK}
      textColor={Colors.WHITE}
      borderRadius={5}
      width={132}
      height={46}
      onClick={handleExport}
      disabled={isLoading}
    >
      {isLoading ? 'Exporting...' : 'Export'}
    </NewButton>
  )

  const renderEstimatedTime = () => {
    const videoMediaInfo = currentMediaDetails?.mediaInfo?.proxy || currentMediaDetails?.mediaInfo?.original;

    if (videoMediaInfo && currentVideoExportItems) {
      const scenes = currentVideoExportItems.data.map(currentVideoExportItem =>
        currentVideoExportItem.scenes).flat();
      const scenesDurationArray = scenes.map(scene => scene.duration);

      const shotsDuration = scenesDurationArray.reduce(reducer, 0);
      const shotsDurationInSec = shotsDuration / 1000;

      const minTime = videoMediaInfo.container.duration > 180 ?
        secondsToHms(videoMediaInfo.container.duration - 180 + shotsDurationInSec + 30) :
        secondsToHms(180 + shotsDurationInSec + 30);
      const maxTime = secondsToHms(videoMediaInfo.container.duration + 180 + shotsDurationInSec + 60);

      return (
        <div className={styles.ExportAsVideoModal__estimatedTime}>
          Estimated time: ~ {`${minTime} - ${maxTime}`}
        </div>
      )
    }
  }

  return (
    <div className={styles.ExportAsVideoModal}>
      <p className={styles.ExportAsVideoModal__title}>Export as video</p>
      <div className={styles.ExportAsVideoModal__container}>
        {renderPreview()}
        {!currentVideoExportItems ? renderControls() : null}
      </div>
      {isLoading ? renderEstimatedTime() : null}
      <div className={styles.ExportAsVideoModal__actionButtons}>
        {renderSubmitButton()}
      </div>
      {closeButton}
    </div>
  );
};

export default ExportAsVideoModal;
