import styled from 'styled-components';
import React, { ChangeEvent, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import prettyBytes from 'pretty-bytes';
import { BuyMoreType } from '../../../state/modules/modal';
import {
    BLUE_50,
    BLUE_PRIMARY,
    NEUTRAL_100,
    NEUTRAL_150,
    NEUTRAL_300,
    NEUTRAL_400,
    NEUTRAL_600,
    PURPLE_PRIMARY,
    WHITE_PRIMARY,
} from '../../../styles/colors';
import { Icon, Slider } from '../../UI';
import {
    addCredits,
    addDubbingCredits,
    CurrentSubscription,
    Customer,
    getAddCreditsLoading,
    getAddDubbingCreditsLoading,
    getAdditionalStorageAddon,
    getCreditsAddon,
    getCurrentUserPlan,
    getCustomerInfo,
    getDubbingCreditsAddon,
    getStorageUpdateLoading,
    getStripePromise,
    getUpcomingUserPlan,
    PaymentType,
    Price,
    Product,
    setupIntent,
    UpcomingPlan,
    UpdateAddonBody,
    updateStorage,
} from '../../../state/modules/payment';
import { PaymentForm } from '../SelectPlanModal/PaymentForm';
import ButtonLoader from '../../UI/ButtonLoader';

const Container = styled.div`
    flex: 3 1 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
`;

const PaymentFormContainer = styled.div`
    margin-top: 0.5rem;
`;

const TopLine = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
`;

const Title = styled.div`
    font-family: 'Inter SemiBold', sans-serif;
    font-weight: 600;
    font-size: 1rem;
`;

const ContentWrapper = styled.div`
`;

const Label = styled.div`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    line-height: 15px;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0.5rem;
`;

const AmountSliderContainer = styled.div`
    border: 1px solid ${NEUTRAL_150};
    border-radius: 4px;
    padding: 13px 12px 13px 14px;
    display: flex;
    position: relative;
    height: 42px;
    margin-bottom: 0.75rem;
`;

const AmountSlider = styled.div`
    display: flex;
    width: 98%;
    justify-content: center;
    align-items: center;
`;

const InputsContainer = styled.div`
    display: flex;
`;

const DollarWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const DollarInput = styled.input<{isDisabled: boolean}>`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.75rem;
    border-radius: 4px;
    height: 2.625rem;
    padding: 0.938rem 0.25rem 0.938rem 2.25rem;  
    border: 1px solid ${NEUTRAL_300};
    width: 5.5rem;
    cursor: ${props => props.isDisabled ? 'default' : 'text'};
    color: ${NEUTRAL_600};
    
    &:focus {
        outline: none;
        border: 1px solid ${NEUTRAL_300};
    }
`;

// const DollarSign = styled.span`
//     font-family: 'Inter Medium', sans-serif;
//     font-weight: 500;
//     font-size: 0.75rem;
//     line-height: 0.75rem;
//     position: absolute;
//     left: 0.75rem;
//     top: 50%;
//     transform: translateY(-50%);
//     pointer-events: none;
// `;

const IconContainer = styled.div`
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    height: 1rem;
    width: 1rem;
`;

const AmountWrapper = styled.div`
    display: flex;
    //align-items: center;
    position: relative;
    margin-left: 0.75rem;
`;

const AmountInput = styled.input`
    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-align: left;

    border-radius: 4px;
    height: 2.625rem;
    padding: 0.938rem 0.25rem 0.938rem 2.25rem;
    border: 1px solid ${NEUTRAL_150}; 
    width: 6.5rem;
    cursor: default;
    color: ${NEUTRAL_300};

    &:focus {
        outline: none;
        border: 1px solid ${NEUTRAL_150};
    }
`;


const ButtonsContainer = styled.div`
    display: inline-flex;
    justify-self: end;
    align-self: end;
    justify-content: flex-end;
    margin-top: auto;
`;

const BackButton = styled.button`
    background-color: ${NEUTRAL_150};
    color: ${NEUTRAL_600};
    height: 2.625rem;
    padding: 0 1.438rem;
    border-radius: 6px;
    border: none;

    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
    margin-left: 0.625rem;
    cursor: pointer;
`;

const BuyButton = styled.button`
    background-color: ${PURPLE_PRIMARY};
    color: ${WHITE_PRIMARY};
    height: 2.625rem;
    padding: 0 1.281rem;
    border-radius: 6px;
    border: none;

    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
    margin-left: 0.625rem;
    text-transform: capitalize;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const CancelAddOnButton = styled.button`
    background-color: ${NEUTRAL_400};
    color: ${WHITE_PRIMARY};
    height: 2.625rem;
    padding: 0 1.438rem;
    border-radius: 6px;
    border: none;

    font-family: 'Inter Medium', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    text-align: center;
    margin-left: 0.625rem;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const BillingInfo = styled.div`
    margin-top: 1.25rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.75rem;
    text-align: left;
`;

const BillingPeriod = styled.p`
    margin-bottom: 0.625rem;
`;

const details = {
    [BuyMoreType.subtitles]: {
        title: 'Subtitling credits',
        subtitle: 'Select an amount',
        domain: [0, 1000],
        step: 1,
    },
    [BuyMoreType.dubbing]: {
        title: 'Dubbing credits',
        subtitle: 'Select an amount',
        domain: [0, 1000],
        step: 1,
    },
    [BuyMoreType.storage]: {
        title: 'Storage',
        subtitle: 'Manage your storage',
        domain: [0, 20000],
        step: 100,
    },
};

interface Props {
    type: BuyMoreType;
    handleCancel: () => void;
}

const BuyMoreAddons = (props: Props) => {
    const {
        type,
        handleCancel,
    } = props;

    const dispatch = useDispatch();
    
    const customer = useSelector(getCustomerInfo) as Customer;
    const isAddSubtitleCreditsLoading = useSelector(getAddCreditsLoading);
    const isAddDubbingCreditsLoading = useSelector(getAddDubbingCreditsLoading);
    const isStorageUpdateLoading = useSelector(getStorageUpdateLoading);

    const subtitlesCreditsAddon = useSelector(getCreditsAddon);
    const dubbingCreditsAddon = useSelector(getDubbingCreditsAddon);
    const storageAddon = useSelector(getAdditionalStorageAddon) as Product;

    const stripePromise = useSelector(getStripePromise);

    const currentSubscription = useSelector(
        getCurrentUserPlan
    ) as CurrentSubscription;

    const upcomingSubscription = useSelector(
        getUpcomingUserPlan
    ) as UpcomingPlan;

    const [storage, setStorage] = useState(0);
    const [credits, setCredits] = useState(0);
    const [clientSecretLoading, setClientSecretLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const isStorageType = useMemo(() => {
        return (type === BuyMoreType.storage);
    }, [type]);

    const amount = useMemo(() => {
        if(type === BuyMoreType.subtitles) {
            return (
                moment
                    .duration(credits * 15 * 6 * 10000, 'milliseconds')
                    .format('hh:mm:ss', {
                        useGrouping: false,
                        trim: false,
                    })
            );
        }

        if(type === BuyMoreType.dubbing) {
            return (
                moment
                    .duration(credits * 6 * 6 * 10000, 'milliseconds')
                    .format('hh:mm:ss', {
                        useGrouping: false,
                        trim: false,
                    })
            );
        }

        return 0;
    }, [credits, type]);

    const transformedAddon = useMemo(() => {
        if (storageAddon && storageAddon.prices && currentSubscription) {
            return {
                ...storageAddon,
                prices: storageAddon.prices.filter(
                    (price: Price) =>
                        price.interval === currentSubscription.price.interval
                ),
            };
        }

        return storageAddon;
    }, [storageAddon, currentSubscription]);

    const creditsAddon = useMemo(() => {
        return subtitlesCreditsAddon || dubbingCreditsAddon || transformedAddon;
    }, [subtitlesCreditsAddon, dubbingCreditsAddon, transformedAddon]);

    const isAddCreditsLoading = useMemo(() => {
        return isAddSubtitleCreditsLoading || isAddDubbingCreditsLoading || isStorageUpdateLoading;
    }, [isAddSubtitleCreditsLoading, isAddDubbingCreditsLoading, isStorageUpdateLoading]);

    let price = 0;

    if (transformedAddon && transformedAddon.prices && currentSubscription) {
        price = (storage / 100) * (transformedAddon.prices[0].amount / 100);
    }

    const handleChangeAmount = (value: number) => {
        if(isStorageType) {
            setStorage(value);
        } else {
            setCredits(value);
        }
    };

    const handleChangeAmountInput = (event: ChangeEvent<HTMLInputElement>) => {
        if(isStorageType) {
            return;
        }

        const { value } = event.target;
        const numValue = +value;

        if (Number.isNaN(numValue)) {
            return;
        }

        if (!value) {
            setCredits(0);
        } else {
            setCredits(numValue);
        }
    };

    const handleGoToPayment = () => {
        if (creditsAddon?.id) {
            setClientSecretLoading(true);
            dispatch(
                setupIntent((error, data) => {
                    setClientSecretLoading(false);
                    if (!error) {
                        setClientSecret(data.clientSecret);
                    }
                })
            );
        }
    };

    const handleCreditsAddonUpdate = () => {
        if(type === BuyMoreType.subtitles) {
            if (creditsAddon) {
                const addonName = 'subtitle time';

                dispatch(
                    addCredits({
                        data: {
                            credits: {
                                productId: creditsAddon.id,
                                priceId: creditsAddon?.prices?.[0]?.id || '',
                                quantity: credits,
                            },
                        },
                        paymentInfo: {
                            price: credits,
                            name: `Monthly Credits`,
                            type: 'addon',
                            addonName,
                        },
                    })
                );
            }
        }

        if(type === BuyMoreType.dubbing) {
            if (creditsAddon) {
                const addonName = 'dubbing time';

                dispatch(
                    addDubbingCredits({
                        data: {
                            credits: {
                                productId: creditsAddon.id,
                                priceId: creditsAddon?.prices?.[0]?.id || '',
                                quantity: credits,
                                type: 'dubbingCredits',
                            },
                        },
                        paymentInfo: {
                            price: credits,
                            name: `Monthly Dubbing Credits`,
                            type: 'addon',
                            addonName,
                        },
                    })
                );
            }
        }
    };

    const handleStorageAddonUpdate = () => {
        let updateBody = {
            productId: transformedAddon.id,
            priceId: transformedAddon?.prices?.[0].id || '',
            quantity: storage,
        } as UpdateAddonBody;

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            updateBody = {
                ...updateBody,
                upcomingProductId: transformedAddon.id,
                upcomingPriceId: upcomingPlanAddonPriceId,
            };
        }

        dispatch(
            updateStorage({
                data: {
                    addon: updateBody,
                },
                paymentInfo: {
                    price,
                    name: `Monthly Storage`,
                    type: 'addon',
                },
            })
        );
    };

    const handleCancelAddon = () => {
        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            dispatch(
                updateStorage({
                    data: {
                        addon: {
                            productId: currentSubscription?.addon?.productId,
                            priceId: currentSubscription?.addon?.priceId,
                            upcomingProductId:
                            currentSubscription?.addon?.productId,
                            upcomingPriceId: upcomingPlanAddonPriceId,
                            quantity: 0,
                        },
                    },
                })
            );
        }
    };

    const handleReduceStorage = () => {
        let updateStorageBody = {
            productId: currentSubscription?.addon?.productId,
            priceId: currentSubscription?.addon?.priceId,
            quantity: storage,
        } as UpdateAddonBody;

        if (upcomingSubscription && storageAddon && storageAddon.prices) {
            const upcomingPlanAddonPrice = storageAddon.prices.find(
                (price: Price) =>
                    price.interval === upcomingSubscription.billingInterval
            );

            const upcomingPlanAddonPriceId = upcomingPlanAddonPrice?.id;

            if (upcomingPlanAddonPrice) {
                updateStorageBody = {
                    ...updateStorageBody,
                    upcomingProductId: currentSubscription?.addon?.productId,
                    upcomingPriceId: upcomingPlanAddonPriceId,
                };
            }
        }

        dispatch(
            updateStorage({
                data: {
                    addon: updateStorageBody,
                },
            })
        );
    };

    const renderSlider = () => {
        return (
            <AmountSliderContainer>
                <AmountSlider>
                    <Slider
                        domain={details[type].domain}
                        step={details[type].step}
                        value={isStorageType ? storage : +credits}
                        onChange={handleChangeAmount}
                        onUpdate={handleChangeAmount}
                        railInnerStyle={{
                            backgroundColor: NEUTRAL_100,
                            height: 2,
                        }}
                        trackStyles={{
                            background: BLUE_50,
                            border: `1px solid ${BLUE_PRIMARY}`,
                            borderRadius: '7px',
                            height: '6px',
                            opacity: 0.6,
                        }}
                        handleSliderStyle={{
                            background: WHITE_PRIMARY,
                            width: 12,
                            height: 12,
                            borderRadius: '100%',
                            border: `3px solid ${BLUE_PRIMARY}`,
                            boxShadow: 'none'
                        }}
                    />
                </AmountSlider>
            </AmountSliderContainer>
        );
    };

    const renderPrice = () => {
        const priceValue = (isStorageType) ? (
            !(price % 1) ? price : price.toFixed(2)
        ) : credits;


        return (
            <DollarWrapper>
                <IconContainer>
                    <Icon name='dollar-sign' size={16} color={NEUTRAL_600} secondColor={WHITE_PRIMARY}/>
                </IconContainer>
                <DollarInput
                    value={priceValue}
                    readOnly={isStorageType}
                    isDisabled={isStorageType}
                    onChange={handleChangeAmountInput}
                />
            </DollarWrapper>
        );
    };

    const renderAddonAmount = () => {
        const amountValue = (isStorageType) ? (
            prettyBytes(storage * 1000000000)
        ) : amount;
        
        return (
            <AmountWrapper>
                {
                    !isStorageType && (
                        <IconContainer>
                            <Icon name='timer-icon' size={16} color={NEUTRAL_300} secondColor={WHITE_PRIMARY}/>
                        </IconContainer>
                    )
                }
                <AmountInput
                    type="text"
                    value={amountValue}
                    readOnly
                />
            </AmountWrapper>
        );
    }

    const renderInputs = () => {
        return (
            <InputsContainer>
                {renderPrice()}
                {renderAddonAmount()}
            </InputsContainer>
        );
    };

    const renderButtonText = () => {
        if(clientSecretLoading || isAddCreditsLoading) {
            return <ButtonLoader />;
        }

        if(customer?.paymentMethod) {
            return (`Buy ${details[type].title}`);
        }

        return 'Go to payment';
    };

    const renderPaymentForm = () => {
        if(clientSecret) {
            const urlParams = new URLSearchParams({
                productId: creditsAddon?.id || '',
                priceId: creditsAddon?.prices?.[0]?.id || '',
                quantity: credits.toString(),
                type: PaymentType.credits,
                price: credits.toString(),
                name: `Monthly Credits`,
                paymentType: 'addon',
            }).toString();

            return (
                <PaymentFormContainer>
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                        }}
                    >
                        <PaymentForm
                            price={credits}
                            handleClose={() => {
                                setClientSecret(null);
                            }}
                            urlParams={urlParams}
                            noPaymentMethod={!customer?.paymentMethod}
                        />
                    </Elements>
                </PaymentFormContainer>
            );
        }
    };

    const renderManageStorageButton = () => {
        let disabled = false;

        const isReduce =
            storage * 1000000000 <
            (currentSubscription?.addon?.currentStorageAmount || 0);

        if (
            (currentSubscription?.addon?.currentStorageAmount || 0) /
            1000000000 ===
            storage &&
            (currentSubscription?.addon?.featureStorageAmount || 0) /
            1000000000 ===
            storage
        ) {
            disabled = true;
        }

        const isLoading = isStorageUpdateLoading || clientSecretLoading;

        return (
            <BuyButton
                onClick={
                    isReduce
                        ? handleReduceStorage
                        : customer?.paymentMethod
                            ? handleStorageAddonUpdate
                            : handleGoToPayment
                }
                disabled={
                    disabled || isReduce || isStorageUpdateLoading || clientSecretLoading
                }
            >
                {
                    isLoading ? (
                        <ButtonLoader />
                    ) : (
                        isReduce ?
                            'Reduce storage' :
                            customer?.paymentMethod
                                ? 'Pay'
                                : 'Go to payment'
                    )
                }
            </BuyButton>
        );
    };

    const renderCancelAddonButton = () => {
        const disabled =
            !currentSubscription.addon?.currentStorageAmount ||
            !currentSubscription.addon?.featureStorageAmount;

        return (
            <CancelAddOnButton
                onClick={handleCancelAddon}
                disabled={isStorageUpdateLoading || disabled}
            >
                {
                    isStorageUpdateLoading ? (
                        <ButtonLoader />
                    ) : (
                        'Cancel add-on'
                    )
                }
            </CancelAddOnButton>
        );
    };

    const renderBuyCreditsButton = () => {
        return (
            <BuyButton
                onClick={
                    customer?.paymentMethod
                        ? handleCreditsAddonUpdate
                        : handleGoToPayment
                }
                disabled={
                    !credits || clientSecretLoading || isAddCreditsLoading
                }
            >
                {
                    renderButtonText()
                }
            </BuyButton>
        );
    };

    const renderBillingPeriod = () => {
        if (currentSubscription) {
            if (
                currentSubscription &&
                currentSubscription.billingPeriod &&
                currentSubscription.billingPeriod.billingStartDate
            ) {
                const { billingStartDate, billingEndDate } =
                    currentSubscription.billingPeriod;

                return (
                    <BillingInfo>
                        <BillingPeriod>
                            Billed {currentSubscription.price.interval}ly
                        </BillingPeriod>
                        <BillingPeriod>
                            {`Billing period: ${moment
                                .unix(billingStartDate)
                                .format('DD.MM.YYYY')} -
            ${moment.unix(billingEndDate).format('DD.MM.YYYY')}`}
                        </BillingPeriod>
                    </BillingInfo>
                );
            }
        }
    };

    return (
        <Container>
            {
                clientSecret ? (
                    renderPaymentForm()
                ) : (
                    <>
                        <TopLine>
                            <Title>
                                Add more {details[type].title}
                            </Title>
                        </TopLine>
                        <ContentWrapper>
                            <Label>{details[type].subtitle}</Label>
                            {renderSlider()}
                            {renderInputs()}
                            {
                                (isStorageType) && (
                                    renderBillingPeriod()
                                )
                            }
                        </ContentWrapper>
                        <ButtonsContainer>
                            <BackButton onClick={handleCancel}>Go back</BackButton>
                            {
                                (isStorageType) && (
                                    renderCancelAddonButton()
                                )
                            }
                            {
                                isStorageType ? (
                                    renderManageStorageButton()
                                ) : (
                                    renderBuyCreditsButton()
                                )
                            }
                        </ButtonsContainer>
                    </>
                )
            }
        </Container>
    );
}

export default BuyMoreAddons;