import { IconProps } from './index';

const DollarSign = ({ size, color, secondColor }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3582_43601)">
                <path
                    d="M11.1852 5.0372C11.0377 4.61886 10.7685 4.25421 10.4121 3.99019C10.0556 3.72617 9.62834 3.57485 9.18518 3.55572H6.22222C5.63285 3.55572 5.06762 3.78984 4.65087 4.20659C4.23413 4.62334 4 5.18857 4 5.77794C4 6.36731 4.23413 6.93254 4.65087 7.34929C5.06762 7.76604 5.63285 8.00016 6.22222 8.00016H9.18518C9.77455 8.00016 10.3398 8.23429 10.7565 8.65104C11.1733 9.06778 11.4074 9.63302 11.4074 10.2224C11.4074 10.8118 11.1733 11.377 10.7565 11.7937C10.3398 12.2105 9.77455 12.4446 9.18518 12.4446H6.22222C5.77907 12.4255 5.35178 12.2742 4.99536 12.0101C4.63893 11.7461 4.36966 11.3815 4.22222 10.9631M7.7037 1.3335V3.55572M7.7037 12.4446V14.6668"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath
                    id="clip0_3582_43601"
                >
                    <rect
                        width={height}
                        height={height}
                        fill={secondColor}
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DollarSign;


