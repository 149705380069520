import { IconProps } from './index';

const HomeIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bcXHqe dsXdKK"
        >
            <path
                d="M2 17.75v-7.747a4.25 4.25 0 011.545-3.278l5.75-4.744a4.25 4.25 0 015.41 0l5.75 4.744A4.25 4.25 0 0122 10.003v7.747A4.25 4.25 0 0117.75 22H6.25A4.25 4.25 0 012 17.75z"
                stroke={color}
            />
            <path d="M7.5 17h9" stroke={color} strokeLinecap="round" />
        </svg>
    );
};

export default HomeIcon;
