import { getInfo } from 'react-mediainfo';

import { MediaFile, MediaType, TempMediaFile } from 'interfaces/videos';

import { User } from 'state/modules/user';

import { transformMediaInfoTracks } from './mediaInfoHelpers';
import {
    checkIsTranscriptInProgress,
    checkIsTranscriptNotAddedToProject,
    checkIsTranslateInProgress,
    checkIsTranslateNotAddedToProject,
    checkIsVideoInProgress,
} from './statuses';

export const transformFilesToMediaFiles = async (
    videos: any[],
    userInfo: User,
    fps?: number
): Promise<TempMediaFile[]> => {
    try {
        const rawMediaInfoArray = await Promise.all(
            videos.map((video) => getInfo(video.file))
        );

        const mediaInfoTracks = rawMediaInfoArray.map(
            (item) => item.media.track
        );

        const transformedTracks = mediaInfoTracks.map((tracks) =>
            transformMediaInfoTracks(tracks)
        );

        const transformedVideos = videos.map((video, index) => {
            const originalMediaInfo = transformedTracks[index];

            const isAudio = video.type.includes('audio') || !originalMediaInfo.video;

            let mediaInfo = {
                original: {
                    ...originalMediaInfo,
                    filename: video.file.name,
                    other: [
                        {
                            timeCodeFirstFrame: '',
                        },
                    ],
                },
                proxy: {
                    ...originalMediaInfo,
                    filename: video.file.name,
                    other: [
                        {
                            timeCodeFirstFrame: '',
                        },
                    ],
                },
            };

            if (!isAudio) {
                mediaInfo = {
                    ...mediaInfo,
                    proxy: {
                        ...mediaInfo.proxy,
                        video: [
                            {
                                ...originalMediaInfo.video[0],
                                frameCount: Math.ceil(
                                    (fps ||
                                        originalMediaInfo.video[0].framerate) *
                                        originalMediaInfo.video[0].duration
                                ),
                            },
                        ],
                    },
                };
            }

            return {
                id: video.id,
                s3Path: URL.createObjectURL(video.file),
                filename: video.file.name,
                mediaType: isAudio ? MediaType.AUDIO : MediaType.VIDEO,
                title: video.title,
                lastModified: `${video.lastModifiedDate}`,
                size: video.file.size,
                mediaInfo,
                description: video.description,
                language: '',
                defaultLanguage: '',
                userId: userInfo.id,
            };
        });

        return transformedVideos;
    } catch (error) {
        console.log({ error });
        return [];
    }
};

export const getVideosWithInProgressStatus = (
    projectVideos: MediaFile[]
): MediaFile[] => {
    const videosWithInProgressStatus = [] as MediaFile[];

    projectVideos.forEach((video: MediaFile) => {
        if (video?.status?.analyze && video?.status?.transcoding) {
            const isTranslateInProgress = checkIsTranslateInProgress(
                video.status.analyze
            );
            const isTranscriptInProgress = checkIsTranscriptInProgress(
                video.status.analyze
            );
            const isTranscodingInProgress = checkIsVideoInProgress([
                video.status.transcoding?.status,
            ]);

            if (
                isTranslateInProgress ||
                isTranscriptInProgress ||
                isTranscodingInProgress
            ) {
                videosWithInProgressStatus.push(video);
            }
        }
    });

    return videosWithInProgressStatus;
};

export const getVideosWithNotAddedSubtitles = (
    projectVideos: MediaFile[]
): MediaFile[] => {
    const videosWithNotAddedSubtitles = [] as MediaFile[];

    projectVideos.forEach((video: MediaFile) => {
        if (video?.status?.analyze && video?.status?.transcoding) {
            const isTranslateNotAdded = checkIsTranslateNotAddedToProject(
                video.status.analyze
            );
            const isTranscriptNotAdded = checkIsTranscriptNotAddedToProject(
                video.status.analyze
            );

            const language = isTranscriptNotAdded ?
                video.status.analyze?.transcript?.language :
                video.status.analyze?.translate?.language;

            if (
                isTranslateNotAdded ||
                isTranscriptNotAdded
            ) {
                videosWithNotAddedSubtitles.push({
                    ...video,
                    language: language || ''
                });
            }
        }
    });

    return videosWithNotAddedSubtitles;
};