import { v4 as uuid } from 'uuid';

import store from 'state/store';
import { getCurrentUserPlan } from 'state/modules/payment';
import { getUserInfo } from 'state/modules/user';

class GoogleAnalytics {
    initialize = () => {
        let prevUser = getUserInfo(store.getState());
        let prevUserPlan = getCurrentUserPlan(store.getState());

        this.setDataLayer({
            user_id: prevUser?.id || null,
            user_plan: prevUserPlan?.planNameId || null,
        });

        store.subscribe(() => {
            const user = getUserInfo(store.getState());
            const userPlan = getCurrentUserPlan(store.getState());
            if (user !== prevUser) {
                this.setDataLayer({
                    user_id: user?.id || null,
                });
                prevUser = user;
            }
            if (userPlan !== prevUserPlan) {
                this.setDataLayer({
                    user_plan: userPlan?.planNameId || null,
                });
                prevUserPlan = userPlan;
            }
        });
    };

    setDataLayer = (...args: any) => {
        window.dataLayer?.push(...args);
    };

    login = (options: { method: 'google' | 'email' }) => {
        this.setDataLayer({ event: 'login', ...options });
    };

    signUp = (options: { method: 'google' | 'email' }) => {
        this.setDataLayer({ event: 'sign_up', ...options });
    };

    purchase = (options: {
        purchase_type: 'plan' | 'addon';
        name: string;
        coupon?: string;
        currency?: 'USD';
        value: number;
    }) => {
        const {
            purchase_type,
            coupon,
            currency = 'USD',
            value,
            name,
        } = options;

        const transaction_id = uuid();

        const data = {
            purchase_type,
            transaction_id,
            currency,
            value,
            items: [
                {
                    id: transaction_id,
                    name,
                    price: value,
                    coupon,
                },
            ],
        };

        this.setDataLayer({ event: 'purchase', ...data });
    };

    createProject = () => {
        this.setDataLayer({ event: 'project_created' });
    };

    uploadMedia = (options: {
        media_type: 'video' | 'audio' | 'image';
        media_source:
            | 'PC'
            | 'Link'
            | 'Stock'
            | 'Stock_Music'
            | 'Stock_Effect';
    }) => {
        this.setDataLayer({ event: 'media_uploaded', ...options });
    };

    exportVideo = (options: { resolution: string; export_option: string }) => {
        this.setDataLayer({ event: 'project_exported', ...options });
    };

    inviteFriend = () => {
        this.setDataLayer({ event: 'friend_invited' });
    };

    dubbingGenerated = (options: {
        from: string,
        to: string,
    }) => {
        this.setDataLayer({ event: 'dubbing_generated', ...options });
    }

    cleanAudioGenerated = (options: {
        audio_duration: number
    }) => {
        this.setDataLayer({ event: 'clean_audio_generated', ...options });
    }

    textToSpeechGenerated = (options: {
        text_length: number,
        language: string,
    }) => {
        this.setDataLayer({ event: 'tts_generated', ...options });
    }

    textAnimationsApplied = (options: {
        type: string
    }) => {
        this.setDataLayer({ event: 'text_animation_applied', ...options});
    }

    templatesUsed = (options: {
        template_id: string,
        template_name: string,
        template_creator: string
    }) => {
        this.setDataLayer({ event: 'template_used', ...options });
    }
}

export const googleAnalytics = new GoogleAnalytics();
