import {
  TemplateParsedLabelValue,
  TemplateTempDataItemAttributes,
} from 'interfaces/templateMakerTool';
import { VideoAnalysisCategoryStatus } from 'interfaces/videos';
import { SentenceExportItem } from 'state/modules/export';
import { TransformedAnalysisResultItem } from 'state/modules/metadata';
import {
  Scene,
  SelectedAnalysisResultItem,
  SelectedShotItem,
} from 'state/modules/videoExplorer';

export function isShotExportItem(u: unknown): u is SelectedShotItem {
  if (u && typeof u === 'object') return 'startTimestampMillis' in u && 'endTimestampMillis' in u;
  return false;
}

export function isParent(
  u: unknown,
): u is SelectedAnalysisResultItem | TransformedAnalysisResultItem {
  if (u && typeof u === 'object') return 'hasChildren' in u;
  return false;
}

export function isSelectedShot(u: unknown): u is SelectedShotItem {
  if (u && typeof u === 'object') return 'isVisible' in u && 'color' in u;
  return false;
}

export function isSelectedAnalysisResultItem(
  u: unknown,
): u is SelectedAnalysisResultItem {
  if (u && typeof u === 'object') return 'isVisible' in u && 'color' in u;
  return false;
}

export function isSentenceExportItem(u: unknown): u is SentenceExportItem {
  if (u && typeof u === 'object') return 'data' in u;
  return false;
}

export function isSceneWithFilters(u: unknown): u is Scene {
  if (u && typeof u === 'object') return 'scaleFactor' in u && 'confidence' in u;
  return false;
}

export function isTemplateFacialAttributeDataItem(
  u: unknown,
): u is TemplateTempDataItemAttributes {
  if (u && typeof u === 'object') return 'attribute' in u || 'emotion' in u;
  return false;
}

export function isTemplateParsedLabelValue(
  u: unknown,
): u is TemplateParsedLabelValue {
  if (u && typeof u === 'object') return 'name' in u || 'attribute' in u || 'emotion' in u;
  return false;
}

export function isVideoAnalysisCategoryStatus(
  u: unknown,
): u is VideoAnalysisCategoryStatus {
  if (u && typeof u === 'object') return 'status' in u;
  return false;
}
