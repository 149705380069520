import { IconProps } from './index';

const RemoveIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 25;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            className="sc-bdvvtL dfyOOG"
        >
            <path
                d="M32 17c0-8.284-6.716-15-15-15C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15zM7 6l20 21"
                stroke="currentColor"
                strokeWidth="3"
            />
        </svg>
    );
};

export default RemoveIcon;
