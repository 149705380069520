import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';

import { TransformedSentence } from 'state/modules/metadata';
import { getSelectedTrailer } from 'state/modules/videoExplorer';

import styles from './styles.module.scss';

export type DraggableEvent =
  | React.MouseEvent<HTMLElement | SVGElement>
  | React.TouchEvent<HTMLElement | SVGElement>
  | MouseEvent
  | TouchEvent;

export interface DraggableData {
  node: HTMLElement;
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
  lastX: number;
  lastY: number;
}

type Direction =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft'
  | 'topLeft';

export declare type ResizableDelta = {
  width: number;
  height: number;
};

export declare type Position = {
  x: number;
  y: number;
};
interface Props {
  currentTime: number;
  transcript: Array<TransformedSentence>;
  videoRef: any;
  setActiveSentenceId: (id: string | null) => void;
  activeSentenceId: string | null;
  zIndex?: number;
  fontSize?: number;
  withLayout?: boolean;
  light?: boolean;
  dark?: boolean;
  defaultCoords?: {
    x?: number;
    y?: number;
  }
}

const Subtitles = (props: Props): JSX.Element | null => {
  const selectedTrailer = useSelector(getSelectedTrailer);
  const {
    currentTime,
    transcript,
    videoRef,
    setActiveSentenceId,
    activeSentenceId,
    zIndex,
    fontSize,
    light,
    dark,
    withLayout,
    defaultCoords
  } = props;
  const [coords, setCoords] = useState({
    x: defaultCoords?.x || 0,
    y: defaultCoords?.y || videoRef.current.clientHeight - 85,
  });
  const [size, setSize] = useState<{
    width: string | number;
    height: string | number;
  }>({
    width: 'auto',
    height: 'auto',
  });

  const ref = useRef<HTMLParagraphElement>(null);
  const rndRef = useRef<any>(null);

  const checkIsTextInContainer = useCallback(
    () => {
      if (!rndRef?.current || !videoRef.current) {
        return
      }

      const currentHeight = rndRef.current.resizableElement.current.offsetHeight || 0;

      const currentPositionX = rndRef.current.props.position.x;
      const currentPositionY = rndRef.current.props.position.y;

      const videoHeight = videoRef.current.clientHeight;

      const currentBottomYPosition = currentPositionY + currentHeight;
      const isTextOutsideContainer = currentBottomYPosition > videoHeight;

      if (isTextOutsideContainer) {
        const isTextLargerThenContainer = currentHeight > videoHeight;

        if (isTextLargerThenContainer) {
          setCoords({
            x: currentPositionX,
            y: 0,
          })
        } else {
          const heightDiff = currentBottomYPosition - videoHeight;
          const newYPosition = currentPositionY - heightDiff - 10;

          setCoords({
            x: currentPositionX,
            y: newYPosition,
          })
        }
      }
    },
    [rndRef, videoRef],
  )

  useEffect(() => {
    if (fontSize) {
      checkIsTextInContainer();
    }
  }, [checkIsTextInContainer, fontSize])

  const sentenceToRender = useMemo(() => transcript.find((item: TransformedSentence) => {
    const currentTimeInMs = currentTime * 1000;

    const start = +item.startTime * 1000;
    const end = +item.endTime * 1000;

    if (start === end) {
      return currentTimeInMs >= start
        && currentTimeInMs <= end
    }

    return (currentTimeInMs >= start) && (currentTimeInMs < end)
  }), [currentTime, transcript]);


  useEffect(() => {
    if (sentenceToRender && sentenceToRender.id !== activeSentenceId) {
      setActiveSentenceId(sentenceToRender.id);
    } else if (!sentenceToRender && activeSentenceId) {
      setActiveSentenceId(null);
    }
  }, [setActiveSentenceId, sentenceToRender, activeSentenceId]);

  useEffect(() => {
    if (rndRef?.current && videoRef?.current) {

      const x = (videoRef.current.clientWidth - rndRef.current.resizableElement.current.offsetWidth) / 2;
      const { y } = rndRef.current.props.position;

      setCoords({
        x,
        y,
      });
    }
  }, [
    rndRef,
    videoRef,
    sentenceToRender
  ]);

  const onCaptionsDragStop = (e: DraggableEvent, d: DraggableData) => {
    setCoords({
      x: d.x,
      y: d.y,
    });
  };

  const onResize = (
    e: MouseEvent | TouchEvent,
    direction: Direction,
    rndRef: HTMLElement,
    delta: ResizableDelta,
    position: Position,
  ) => {
    setSize({
      width: rndRef.offsetWidth,
      height: rndRef.offsetHeight,
    });
    setCoords(position);
  };

  const position = useMemo(() => ({
    x: coords.x,
    y: coords.y,
  }), [coords]);

  const rndSize = useMemo(() => ({ width: size.width, height: size.height }), [size]);


  return sentenceToRender && !selectedTrailer ? (
    <Rnd
      position={position}
      size={rndSize}
      onResize={onResize}
      maxWidth="75%"
      maxHeight="75%"
      bounds="parent"
      onDragStop={onCaptionsDragStop}
      className={styles.Subtitles}
      style={{
        zIndex: zIndex || 1,
        backgroundColor: withLayout ? 'rgba(0, 0, 0, 0.75)' : 'none',
      }}
      ref={rndRef}
    >
      <div className={[styles.Subtitles__subtitles, 'handle'].join(' ')}>
        <p
          className={[
            styles.Subtitles__subtitlesItem,
            dark ? styles.Subtitles__subtitlesItem_dark : null,
            light ? styles.Subtitles__subtitlesItem_light : null
          ].join(' ')}
          ref={ref}
          style={{
            fontSize: fontSize || 16,
          }}
        >
          {sentenceToRender.data}
        </p>
      </div>
    </Rnd>
  ) : null;
};

export default Subtitles;
