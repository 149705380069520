import produce from 'immer';
import { ProjectNavRoute } from 'interfaces/projects';
import { ProjectsAction, ProjectsActionTypes } from '../projects/types';
import { ProjectsStudioAction, ProjectsStudioActionTypes, ProjectsStudioState } from './types';

const MIN_TIMELINE_HEIGHT = 236;

const initialState: ProjectsStudioState = {
    projectNavRoute: ProjectNavRoute.SETTINGS,
    timelineTime: 0,
    selectedShape: null,
    isPlaying: false,
    timelineSliderValue: 0,
    timelineHeight: MIN_TIMELINE_HEIGHT,
    preparedMediaSources: [],
    isAudioWaveVisible: false,
    timelineSelectedObjects: [],
    navigateToRoute: '',

    copiedMedia: null,
};

export default (
    state = initialState,
    action: ProjectsAction | ProjectsStudioAction
): ProjectsStudioState =>
    produce(state, (draft) => {
        switch (action.type) {
            case ProjectsStudioActionTypes.SET_TIMELINE_TIME:
                draft.timelineTime = action.payload;

                return;
            case ProjectsStudioActionTypes.SET_TIMELINE_SLIDER_VALUE:
                draft.timelineSliderValue = action.payload;

                return;
            //
            case ProjectsStudioActionTypes.SET_PROJECT_NAV_ROUTE:
                draft.projectNavRoute = action.payload;

                return;
            //
            case ProjectsStudioActionTypes.SET_SELECTED_SHAPE:
                draft.selectedShape = action.payload;

                return;
            ///
            case ProjectsStudioActionTypes.SET_PLAYING:
                draft.isPlaying = action.payload;

                return;
            case ProjectsStudioActionTypes.SET_TIMELINE_HEIGHT:
                draft.timelineHeight = action.payload;

                return;
            case ProjectsStudioActionTypes.SET_PREPARED_MEDIA_SOURCES:
                draft.preparedMediaSources = action.payload;

                return;
            case ProjectsStudioActionTypes.SET_AUDIO_WAVE_VISIBLE:
                draft.isAudioWaveVisible = action.payload;

                return;
            //
            case ProjectsStudioActionTypes.SET_SELECTED_PTOJECT_OBJECTS:
                draft.timelineSelectedObjects = action.payload;

                return;
            case ProjectsStudioActionTypes.NAVIGATE_TO_ROUTE:
                draft.navigateToRoute = action.payload;

                return;
            case ProjectsStudioActionTypes.SET_COPIED_MEDIA:
                draft.copiedMedia = action.payload;

                return;
            /// clear
            case ProjectsActionTypes.CLEAR_PROJECT_DETAILS:
                draft.timelineTime = initialState.timelineTime;
                draft.projectNavRoute = initialState.projectNavRoute;
                draft.selectedShape = initialState.selectedShape;
                draft.timelineSliderValue = initialState.timelineSliderValue;
                draft.timelineSelectedObjects =
                    initialState.timelineSelectedObjects;
                draft.isAudioWaveVisible = initialState.isAudioWaveVisible;
                draft.navigateToRoute = initialState.navigateToRoute;
                draft.copiedMedia = initialState.copiedMedia;

                return;
            default:
                return state;
        }
    });
