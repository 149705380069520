export const formatBytes = (bytes: number, decimals: number): string => {
  if (bytes === 0) {
    return '0 GB';
  }
  if (Number.isNaN(parseInt(`${bytes}`))) {
    return `${bytes}`;
  }
  if (typeof bytes === 'string') {
    bytes = parseInt(bytes);
  }
  if (bytes === 0) {
    return '0';
  }
  const k = 1000;
  const dm = decimals + 1 || 3;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const transformTimecodeToMs = (timecode: string): number => {
  const offsetWithoutMS = timecode.substring(0, 8);
  const offsetWithoutMSArray = offsetWithoutMS.split(':');

  const ms = Number(timecode.split('.')[1]);
  const hoursInMs = Number(offsetWithoutMSArray[0]) * 60 * 60 * 1000;
  const minutesInMs = Number(offsetWithoutMSArray[1]) * 60 * 1000;
  const secondsInMs = Number(offsetWithoutMSArray[2]) * 1000;

  
  const sum = hoursInMs + minutesInMs + secondsInMs + ms;
  // console.log({sum}, 'transformTimecodeToMs', {ms})

  return sum;
};
