import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import uniq from 'lodash.uniq';
import without from 'lodash.without';

import * as globalSearchActions from 'state/modules/globalSearch/actions';
import * as videoExplorerActions from 'state/modules/videoExplorer/actions';
import * as exportActions from 'state/modules/export/actions';

import MetadataClient from 'services/api/metadata';

import {
  GlobalSearchActionTypes,
  getGlobalSearchSelectedCategory,
  GetMetadataByGlobalSearchRes,
  GetTranscriptByGlobalSearchRes,
  RawGlobalSearchSentence,
  TransformedGlobalSearchSentence,
  GetMetadataByGlobalSearchAction,
  GlobalSearchItemClickAction,
  GlobalSearchTranscriptItemClickAction,
  SelectAllGlobalSearchResultsByVideoAction,
} from 'state/modules/globalSearch';
import {
  getVideoExplorerMinConfidence,
  getVideoExplorerMinScaleFactor,
  getVideoExplorerSelectedItems,
  getVideoExplorerVideoList,
  SelectedMetadataItem,
} from 'state/modules/videoExplorer';
import {
  TransformedAnalysisResultItem,
  AnalysisResultTimestampItem,
  getTimestampsForManyItems,
} from 'state/modules/metadata';

import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from 'interfaces/analysis';
import { TransformedGlobalSearchResult } from 'interfaces/globalSearch';

import { getRandomColor } from 'state/utils/videosUtils';
import {
  transformRawGlobalSearchResults,
  transformGlobalSearchTranscriptResults,
  transformGlobalSearchResultsToAnalysisResults,
} from 'state/utils/globalSearchUtils';
import {
  transformTimestampsToScenes,
  transformTranscriptTimestampsToScenes,
} from 'utils/timestampsHelpers';

import { MediaFile } from 'interfaces/videos';
import VideosClient from 'services/api/videos';

export function* loadAnalysResultsByGlobalSearch(
  searchQuery: string,
  minConfidence: number,
): Generator<any, Array<TransformedGlobalSearchResult>> {
  const selectedCategory = (yield select(
    getGlobalSearchSelectedCategory,
  )) as {
        value: string;
    };

  let data = [] as Array<TransformedGlobalSearchResult>;

  try {
    let types = selectedCategory.value;

    if (
      selectedCategory.value === AnalysisResultsCategory.FACIAL_ATTRIBUTE
    ) {
      types = `${selectedCategory.value},${AnalysisResultsType.EMOTION}`;
    }

    const res = (yield call(
      MetadataClient.getMetadataByGlobalSearch,
      searchQuery,
      types,
      minConfidence,
    )) as AxiosResponse<GetMetadataByGlobalSearchRes>;

    const globalSearchItems = res.data.content;

    const transformedGlobalSearchResults = transformRawGlobalSearchResults(
      globalSearchItems,
    ) as Array<TransformedGlobalSearchResult>;

    data = [...transformedGlobalSearchResults];

    return data;
  } catch (error) {
    console.log({ error });

    return data;
  }
}

export function* getTranscriptByGlobalSearch(
  searchQuery: string,
): Generator<any, Array<TransformedGlobalSearchSentence>> {
  let data = [] as Array<TransformedGlobalSearchSentence>;

  function* loadTranscriptByGlobalSearch(
    searchQuery: string,
    offset: number,
  ): Generator {
    try {
      const res = (yield call(
        MetadataClient.getTranscriptByGlobalSearch,
        searchQuery,
        offset,
      )) as AxiosResponse<GetTranscriptByGlobalSearchRes>;

      const newTranscriptItems = res.data
        .content as Array<RawGlobalSearchSentence>;

      const transformedTranscript = transformGlobalSearchTranscriptResults(newTranscriptItems);

      data.push(...transformedTranscript);

      const resMetadata = res.data._metadata;

      const currentCount = data.length;

      if (currentCount < resMetadata.totalCount) {
        loadTranscriptByGlobalSearch(searchQuery, currentCount);
      }
    } catch (error) {
      console.log({ error });
      data = [];
    }
  }

  yield call(loadTranscriptByGlobalSearch, searchQuery, 0);

  return data;
}

export function* getVideosByIds(ids: Array<string>): Generator {
  const videos = (yield select(
    getVideoExplorerVideoList,
  )) as Array<MediaFile>;
  const currentVideosIds = videos.map((video: MediaFile) => video.id);

  const videosIdsWithResult = without(ids, ...currentVideosIds);

  if (videosIdsWithResult.length) {
    let idsString = '';

    videosIdsWithResult.forEach((id: string) => {
      if (idsString.length) {
        idsString = `${idsString},${id}`;
      } else {
        idsString = id;
      }
    });

    const newVideosRes = (yield call(
      VideosClient.getVideosByIds,
      idsString,
    )) as AxiosResponse;

    const newVideos = newVideosRes.data.content;

    const updatedVideos = [...videos, ...newVideos];

    yield put(
      videoExplorerActions.getFilteredAnalyzerVideoListSuccess(
        updatedVideos,
        0,
      ),
    );
  }
}

export function* handleGetMetadataByGlobalSearch(
  action: GetMetadataByGlobalSearchAction,
): Generator {
  const { searchQuery, minConfidence, category } = action.payload;

  const videoIds = [];
  let analysisResults = [] as Array<TransformedGlobalSearchResult>;
  let transcriptResults = [] as Array<TransformedGlobalSearchSentence>;

  if (category === AnalysisResultsCategory.ALL) {
    analysisResults = (yield call(
      loadAnalysResultsByGlobalSearch,
      searchQuery,
      minConfidence,
    )) as Array<TransformedGlobalSearchResult>;

    transcriptResults = (yield call(
      getTranscriptByGlobalSearch,
      searchQuery,
    )) as Array<TransformedGlobalSearchSentence>;
  } else if (category === AnalysisResultsCategory.TRANSCRIPT) {
    transcriptResults = (yield call(
      getTranscriptByGlobalSearch,
      searchQuery,
    )) as Array<TransformedGlobalSearchSentence>;
  } else {
    analysisResults = (yield call(
      loadAnalysResultsByGlobalSearch,
      searchQuery,
      minConfidence,
    )) as Array<TransformedGlobalSearchResult>;
  }

  const arrayIdsOfVideosWithTranscriptResults = transcriptResults.map(
    (item: TransformedGlobalSearchSentence) => item.videoId,
  );

  const arrayIdsOfVideosWithResults = analysisResults.map(
    (item: TransformedGlobalSearchResult) => item.id,
  );

  videoIds.push(
    ...arrayIdsOfVideosWithTranscriptResults,
    ...arrayIdsOfVideosWithResults,
  );

  const uniqIds = uniq(videoIds);

  yield call(getVideosByIds, uniqIds);

  yield put(
    globalSearchActions.setGlobalSearchResults(
      analysisResults,
      transcriptResults,
    ),
  );
}

export function* handleGlobalSearchItemClick(
  action: GlobalSearchItemClickAction,
): Generator {
  const { item } = action.payload;

  yield put(videoExplorerActions.selectAnalysisItem(item, true));
}

export function* handleGlobalSearchTranscriptItemClick(
  action: GlobalSearchTranscriptItemClickAction,
): Generator {
  const { item } = action.payload;

  yield put(videoExplorerActions.selectTranscriptItem(item));
}

export function* handleSelectAllGlobalSearchResultsByVideo(
  action: SelectAllGlobalSearchResultsByVideoAction,
): Generator {
  const { videoId, globalSearchResults, globalSearchTranscriptResults } = action.payload;

  const minConfidence = (yield select(
    getVideoExplorerMinConfidence,
  )) as Array<number>;
  const minScaleFactor = (yield select(
    getVideoExplorerMinScaleFactor,
  )) as Array<number>;
  const selectedItems = (yield select(
    getVideoExplorerSelectedItems,
  )) as Array<SelectedMetadataItem>;

  const transformedGlobalSearchResults = transformGlobalSearchResultsToAnalysisResults(
    globalSearchResults?.data || [],
    videoId,
  ) as Array<TransformedAnalysisResultItem>;

  const filteredGlobalSearchResults = transformedGlobalSearchResults.filter(
    (item: TransformedAnalysisResultItem) => {
      const isSelected = selectedItems.findIndex(
        (selectedItem: SelectedMetadataItem) => selectedItem.id === item.id,
      ) >= 0;

      if (!isSelected) {
        return item;
      }
    },
  );

  const filteredGlobalSearchTranscriptResults = globalSearchTranscriptResults.filter(
    (item: TransformedGlobalSearchSentence) => {
      const isSelected = selectedItems.findIndex(
        (selectedItem: SelectedMetadataItem) => selectedItem.id === item.id,
      ) >= 0;

      if (!isSelected) {
        return item;
      }
    },
  );

  const transformdGlobalSearchTranscriptResults = filteredGlobalSearchTranscriptResults.map(
    (item: TransformedGlobalSearchSentence) => {
      const color = getRandomColor();
      const scenes = transformTranscriptTimestampsToScenes(
        item,
        color,
      );

      const changedTranscriptItem = {
        ...item,
        color,
        scenes,
      };

      return changedTranscriptItem;
    },
  );

  let updatedItems = [
    ...selectedItems,
    ...transformdGlobalSearchTranscriptResults,
  ] as Array<SelectedMetadataItem>;

  if (filteredGlobalSearchResults.length) {
    try {
      const metadata = filteredGlobalSearchResults.map(
        (metadataItem: TransformedAnalysisResultItem) => ({
          name: metadataItem.name,
          type: metadataItem.labelType,
          scaleFactor: minScaleFactor[0],
          range: [
            {
              range: {
                confidence: {
                  gte: minConfidence[0],
                },
              },
            },
          ],
        }),
      );

      const timestamps = (yield call(
        getTimestampsForManyItems,
        videoId,
        metadata,
      )) as Array<AnalysisResultTimestampItem>;

      const changedSelectedItems = filteredGlobalSearchResults.map(
        (changedSelectedItem: TransformedAnalysisResultItem) => {
          const newItemTimestamps = timestamps.filter(
            (timestamp: AnalysisResultTimestampItem) => timestamp.name === changedSelectedItem.name
                            && timestamp.type === changedSelectedItem.labelType,
          );
          const color = getRandomColor();

          const newScenes = transformTimestampsToScenes(
            newItemTimestamps,
            color,
          );

          return {
            ...changedSelectedItem,
            color,
            confidenceFilter: minConfidence,
            scaleFactorFilter: minScaleFactor,
            scenes: newScenes,
            timestamps: newItemTimestamps,
            isVisible: true,
          };
        },
      );

      updatedItems = [...updatedItems, ...changedSelectedItems];
    } catch (error) {
      console.log({ error });
    }
  }

  yield put(videoExplorerActions.setSelectedItems(updatedItems));

  yield put(exportActions.setItemsToExport(updatedItems));
}

export function* globalSearchSaga(): Generator {
  yield takeLatest(
    GlobalSearchActionTypes.GET_METADATA_BY_GLOBAL_SEARCH,
    handleGetMetadataByGlobalSearch,
  );
  yield takeLatest(
    GlobalSearchActionTypes.GLOBAL_SEARCH_TRANSCRIPT_ITEM_CLICK,
    handleGlobalSearchTranscriptItemClick,
  );
  yield takeEvery(
    GlobalSearchActionTypes.GLOBAL_SEARCH_ITEM_CLICK,
    handleGlobalSearchItemClick,
  );
  yield takeLatest(
    GlobalSearchActionTypes.SELECT_ALL_GLOBAL_SEARCH_RESULTS_BY_VIDEO_ID,
    handleSelectAllGlobalSearchResultsByVideo,
  );
}
