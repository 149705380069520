import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MySignIn from 'components/SignIn';
import MySignUp from 'components/SignUp';
import ForgotPassword from 'components/ForgotPassword';
import ResendConfirmationEmail from 'components/ResendConfirmationEmail';
import AppSumoSignIn from 'components/AppSumoSignIn';
import Modal from 'components/Modal';

import novaLogoBlue from 'assets/images/novaLogoBlue.png';
import appSumoLogo from 'assets/images/appsumo.svg';

import styles from './styles.module.scss';

const AppSumoLogo = styled.img`
    width: 176px;
    height: 28px;
    position: absolute;
    left: 45px;
    bottom: 38px;
`;

const Entry = (): JSX.Element => {
    const location = useLocation();

    const renderAppsumoLogo = () => {
        if (location.pathname.includes('app-sumo-login')) {
            return <AppSumoLogo src={appSumoLogo} />;
        }
    };

    return (
        <div className={styles.Entry}>
            <div className={styles.Entry__container}>
                <div className={styles.Entry__content}>
                    <div className={styles.Entry__formContainer}>
                        <Routes>
                            <Route index element={<MySignIn />} />
                            <Route
                                path="/app-sumo-login"
                                element={<AppSumoSignIn />}
                            />
                            <Route path="/signup" element={<MySignUp />} />
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="/resend-confirmation-email"
                                element={<ResendConfirmationEmail />}
                            />

                            <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                            />
                        </Routes>
                    </div>
                    {renderAppsumoLogo()}
                </div>
                <div className={styles.Entry__decor}>
                    <div className={styles.Entry__decorImg} />
                </div>
            </div>
            <Modal />
        </div>
    );
};

export default Entry;
// export default memo(Entry);
