import { AxiosResponse } from 'axios';
import store from 'state/store';

import {
    AddCustomerCardBody,
    UpdateUserSubscription,
    UpdateUserCredits,
    UpdateUserAddon,
    ConfirmSetupBody,
} from 'state/modules/payment';
import $http from './instance';

class PaymentClient {
    public static async getCustomer(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/customer`
        );
    }

    public static async getProducts(type?: string): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/products?type=${type}`
        );
    }

    public static async getUserCurrentSubscription(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/subscription`
        );
    }

    public static async getUsage(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/usage`
        );
    }

    public static async createSubscriptionForCustomer(
        data: UpdateUserSubscription
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/subscription`,
            data
        );
    }

    public static async setupIntent(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/setup-intent`
        );
    }

    public static async paymentIntent(
        data: UpdateUserSubscription
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/payment-intent`,
            data
        );
    }

    public static async confirmSetup(
        data: ConfirmSetupBody
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/confirm-setup`,
            data
        );
    }

    public static async addCustomerCard(
        data: AddCustomerCardBody
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/payments/customer/card`,
            data
        );
    }

    // public static async cancelUserSubscription(): Promise<AxiosResponse> {
    //   const userId: string = store.getState().user.userInfo.id;

    //   return $http.delete(`/user/${userId}/payments/subscription/cancel`);
    // }

    public static async setPreferredCard(
        cardId: string
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(
            `/user/${userId}/payments/customer/card/${cardId}/prefer`
        );
    }

    public static async deleteCard(cardId: string): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.delete(`/user/${userId}/payments/customer/card/${cardId}`);
    }

    public static async addCredits(
        data: UpdateUserCredits
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/credits`,
            data
        );
    }

    public static async updateStorage(
        data: UpdateUserAddon
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/addon`,
            data
        );
    }

    public static async activateSubscription(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/payments/subscription/activate`);
    }

    public static async getUpcomingSubscription(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/subscription/upcoming`
        );
    }

    public static async validatePromocode(
        code: string,
        productId: string
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/promo-code/${code}?productId=${productId}`
        );
    }

    public static async getInvoices(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;
        const workspaceId: string =
            store.getState().workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/payments/v2/invoices`
        );
    }
}

export default PaymentClient;
