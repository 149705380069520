import { useEffect, useMemo, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import prettyBytes from 'pretty-bytes';
import styled from 'styled-components';

import { Icon } from 'components/UI';

import StreamableLinkInput from 'components/StreamableLinkInput';

import {
    changeUploadingMediaDuration,
    getUploadFromExternalSourceInProgressStatus,
    getUploadingStatuses,
    getUploadingVideos,
    setUploadingVideos,
    uploadProjectVideoFromExternalSource,
} from 'state/modules/uploading';
import { checkBrowserInfo } from 'state/modules/app';
import { getPreferences } from 'state/modules/user';
import {
    getUserUsage,
    getUserUsageData,
    UserUsageAndTotalSubscriptionData,
} from 'state/modules/payment';
import {
    getModalOptionalData,
    hideModal,
    ModalEvent,
    ModalType,
    ModalUploadType,
    showModal,
} from 'state/modules/modal';
import {
    getProjectDetailsInfo,
    replaceTimelineMedia,
} from 'state/modules/projects';
import {
    getProjectImageUploadStatus,
    uploadProjectImage,
    uploadProjectVideo,
} from 'state/modules/projectUpload';
import { getVideoFiles } from 'state/modules/media';

import { UploadingMedia } from 'interfaces/uploading';
import { VideoUploadTypes } from 'interfaces/upload';

import ReactTooltip from 'react-tooltip';
import styles from './styles.module.scss';
import StockVideosCategorizedCarousel from '../../StockVideosCategoriesCarousel';
import { MediaType } from '../../../interfaces/videos';

const StockMediaContainer = styled.div`
    padding-top: 15px;
`;

const Grid = styled.div<{ columns: number; rows?: number }>`
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
    grid-template-rows: ${({ rows }) => `repeat(${rows}, 1fr)`};
    gap: 9px;
    margin-bottom: ${({ rows }) => (rows ? '0' : '15px')};
`;

const UploadCard = styled.button<{ size: string; isToCome?: boolean }>`
    background: #ffffff;
    border: 1px solid rgba(49, 49, 49, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    display: flex;
    //flex-direction: ${({ size }) => (size === 'sm' ? 'row' : 'column')};
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: ${({ size }) => {
        if (size === 'lg') {
            return '104px';
        }

        if (size === 'md') {
            return '80px';
        }

        return '35px';
    }};

    &:hover {
        border: 1px solid rgba(184, 75, 242);
    }

    &:disabled {
        cursor: ${({ isToCome }) => (isToCome ? 'default' : 'not-allowed')};
        border: 1px solid rgba(49, 49, 49, 0.1);
        color: rgba(49, 49, 49, 0.1);
    }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CardTitle = styled.p<{ isInline?: boolean; isDisabled?: boolean }>`
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: ${({ isDisabled }) =>
        isDisabled ? 'rgba(49, 49, 49, 0.1)' : '#525253'};
    margin-left: ${({ isInline }) => (!isInline ? '0' : '5px')};
    margin-top: ${({ isInline }) => (!isInline ? '5px' : '0')};
`;

const TooltipText = styled.p`
    position: relative;
    z-index: 10;
`;

const ProjectUploadModal = (): JSX.Element => {
    const [streamableLink, setStreamableLink] = useState('');

    const uploadingStatuses = useSelector(getUploadingStatuses);
    const uploadingVideos = useSelector(getUploadingVideos);
    const userPreferences = useSelector(getPreferences);
    const projectsDetails = useSelector(getProjectDetailsInfo);
    const mediaFiles = useSelector(getVideoFiles);
    const isUploadFromExternalSourceInProgress = useSelector(
        getUploadFromExternalSourceInProgressStatus
    );
    const isImageUploadInProgress = useSelector(getProjectImageUploadStatus);

    const userUsage = useSelector(
        getUserUsageData
    ) as UserUsageAndTotalSubscriptionData;

    const modalData = useSelector(getModalOptionalData);

    const dispatch = useDispatch();

    const timeOutIdRef = useRef(null) as any;

    useEffect(
        () => () => {
            clearTimeout(timeOutIdRef?.current);
        },
        [timeOutIdRef]
    );

    const acceptedTypes = useMemo(() => {
        if (modalData?.replaceMediaType) {
            if (modalData.replaceMediaType === MediaType.AUDIO) {
                return {
                    'audio/*': [],
                };
            }

            if (modalData.replaceMediaType === MediaType.VIDEO) {
                return {
                    'video/*': [],
                    'video/quicktime': ['.mov'],
                };
            }
        }

        if (modalData?.uploadType === ModalUploadType.DEFAULT) {
            return {
                'audio/*': [],
                'video/*': [],
                'image/*': [],
                'video/quicktime': ['.mov'],
            };
        }
        return {
            'audio/*': [],
            'video/*': [],
            'video/quicktime': ['.mov'],
        };
    }, [modalData]);

    const mediaSourcesIds = useMemo(
        () => projectsDetails?.mediaSources || [],
        [projectsDetails]
    );

    const projectUploadingVideos = useMemo(
        () =>
            uploadingVideos.filter((video) =>
                mediaSourcesIds.includes(video.id)
            ),
        [uploadingVideos, mediaSourcesIds]
    );

    const projectUploadingStatuses = useMemo(
        () =>
            uploadingStatuses.filter((status) =>
                mediaSourcesIds.includes(status.id)
            ),
        [uploadingStatuses, mediaSourcesIds]
    );

    const availableStorage = useMemo(() => {
        if (userUsage) {
            const { total, usage } = userUsage;

            return total.storage - usage.storage;
        }
        return 0;
    }, [userUsage]);

    useEffect(() => {
        dispatch(checkBrowserInfo());
        dispatch(getUserUsage());
    }, [dispatch]);

    const handleSetUploadingVideos = (newVideos: Array<UploadingMedia>) => {
        dispatch(setUploadingVideos(newVideos));
    };

    const closeModal = () => {
        dispatch(hideModal());
    };

    const setVideoDuration = (file: any, id: string) => {
        const video = document.createElement('video');
        const fileURL = URL.createObjectURL(file);
        video.src = fileURL;

        video.ondurationchange = () => {
            const duration = video.duration || 0;

            dispatch(
                changeUploadingMediaDuration({
                    duration,
                    id,
                })
            );

            URL.revokeObjectURL(fileURL);
        };
    };

    const handleConfirmUpload = (videos: UploadingMedia[]) => {
        dispatch(
            uploadProjectVideo({
                videos,
                replaceableMedia: modalData?.replaceableMedia,
            })
        );
    };

    const handleUploadImages = (files: File[], videos?: UploadingMedia[]) => {
        const canvas = document.getElementById('ProjectPlayerCanvas');

        if (canvas) {
            const canvasHeight = canvas.clientHeight;
            const canvasWidth = canvas.clientWidth;

            dispatch(
                uploadProjectImage({
                    images: files,
                    canvasWidth,
                    canvasHeight,
                    videosToUpload: videos,
                })
            );

            // dispatch(
            //     showModal(
            //         ModalType.PROJECT_IMAGE_UPLOAD,
            //         ModalEvent.PROJECT_IMAGE_UPLOAD
            //     )
            // );
        }
    };

    const handleUploadLocalFile = (files: Array<any>) => {
        if (files.length) {
            const images = files.filter((item) => item.type.includes('image'));
            const resFiles = files.filter(
                (item) => !item.type.includes('image')
            );

            if (!resFiles.length && images.length) {
                return handleUploadImages(images);
            }

            const changedFiles = resFiles.map((file: File) => {
                const id = uuid();

                setVideoDuration(file, id);

                return {
                    file,
                    description: '',
                    title: file.name,
                    lastModifiedDate: file.lastModified,
                    size: file.size,
                    type: file.type,
                    id,
                    transcriptLanguage:
                        userPreferences?.defaultTranscriptLanguageCode,
                    collectionId: userPreferences?.defaultUserCollection,
                    autoAnalyze: false,
                    categories: [],
                };
            }) as Array<UploadingMedia>;

            if (projectUploadingVideos.length > 0) {
                handleSetUploadingVideos([...changedFiles, ...uploadingVideos]);
            } else {
                handleSetUploadingVideos(changedFiles);
            }

            if (images.length) {
                handleUploadImages(images, changedFiles);
            } else {
                handleConfirmUpload(changedFiles);
            }
        }
    };

    const handleShowMediaLibrary = () => {
        dispatch(
            showModal(ModalType.ADD_PROJECT_VIDEO, ModalEvent.ADD_PROJECT_VIDEO)
        );
    };

    const handleChangeStreamableLink = (value: string) => {
        setStreamableLink(value);
    };

    const handleUploadStreamableVideo = () => {
        if (!streamableLink) {
            return;
        }

        dispatch(
            uploadProjectVideoFromExternalSource({
                data: {
                    type: VideoUploadTypes.STREAMABLE_LINK,
                    streamableLink: {
                        link: streamableLink,
                    },
                },
                isProjectUpload: true,
            })
        );
    };

    const handleShowStreamabaleLinkUploadModal = () => {
        dispatch(
            showModal(ModalType.PROJECT_UPLOAD, ModalEvent.PROJECT_UPLOAD, '', {
                uploadType: ModalUploadType.STREAMABLE_LINK,
            })
        );
    };

    const renderDropzoneContent = () => (
        <>
            <Icon name="upload-media" size={30} />
            <p className={styles.ProjectUploadModal__dropzoneTitle}>
                Upload a file
            </p>
            <p className={styles.ProjectUploadModal__dropzoneDescription}>
                <span
                    className={
                        styles.ProjectUploadModal__dropzoneDescription_accent
                    }
                >
                    Click to browse or
                </span>
                Drag and Drop a file here
            </p>
        </>
    );

    const renderDropzone = () => (
        <Dropzone
            onDrop={handleUploadLocalFile}
            multiple={!modalData?.replaceMediaType}
            disabled={isUploadFromExternalSourceInProgress}
            accept={acceptedTypes as any}
        >
            {({ getRootProps, getInputProps }): JSX.Element => (
                <section className={styles.ProjectUploadModal__dropzoneWrap}>
                    <div
                        {...getRootProps()}
                        className={styles.ProjectUploadModal__dropzoneRoot}
                    >
                        <input
                            {...getInputProps()}
                            className={styles.ProjectUploadModal__dropzone}
                            disabled={isUploadFromExternalSourceInProgress}
                        />
                        {renderDropzoneContent()}
                    </div>
                </section>
            )}
        </Dropzone>
    );

    const renderAvailableStorage = () => (
        <div className={styles.ProjectUploadModal__availableStorage}>
            <p>
                Available storage:
                {` ${prettyBytes(availableStorage)}`}
            </p>
        </div>
    );

    const renderCloseButton = () => (
        <button
            className={styles.ProjectUploadModal__closeBtn}
            onClick={closeModal}
        >
            <Icon name="close" size={15} />
        </button>
    );

    const renderUploadFromLibraryButton = () => {
        let isButtonDisabled = false;

        if (!mediaFiles.length) {
            isButtonDisabled = true;
        }

        return (
            <UploadCard
                disabled={
                    isUploadFromExternalSourceInProgress ||
                    isButtonDisabled ||
                    isImageUploadInProgress
                }
                onClick={handleShowMediaLibrary}
                size="md"
            >
                <div>
                    <Icon name="library" color="#525253" size={24} />
                    <CardTitle>Library</CardTitle>
                </div>
            </UploadCard>
        );
    };

    // const renderUploadFromStreamableLink = () => {
    //     if (
    //         modalData?.uploadType === ModalUploadType.STREAMABLE_LINK ||
    //         modalData?.uploadType === ModalUploadType.DEFAULT ||
    //         (!modalData?.uploadType && !mediaFiles.length)
    //     ) {
    //         return (
    //             <StreamableLinkInput
    //                 onSubmit={handleUploadStreamableVideo}
    //                 onChange={handleChangeStreamableLink}
    //                 link={streamableLink}
    //                 disabled={
    //                     isUploadFromExternalSourceInProgress ||
    //                     isImageUploadInProgress
    //                 }
    //                 isLoading={isUploadFromExternalSourceInProgress}
    //             />
    //         );
    //     }
    // };

    const renderBlank = () => {
        return (
            <UploadCard onClick={closeModal} size="md">
                <div>
                    <Icon name="blank" size={22} color="#525253" />
                    <CardTitle>Blank</CardTitle>
                </div>
            </UploadCard>
        );
    };

    const renderUploadFromDropbox = () => {
        return (
            <>
                <UploadCard
                    onClick={() => null}
                    size="md"
                    isToCome
                    disabled
                    data-tip
                    data-for="dropbox"
                >
                    <div>
                        <Icon name="dropbox" size={22} color="#CECECF" />
                        <CardTitle isDisabled>Dropbox</CardTitle>
                    </div>
                </UploadCard>
                <ReactTooltip id="dropbox" delayShow={100}>
                    <TooltipText>Coming Soon</TooltipText>
                </ReactTooltip>
            </>
        );
    };

    const renderUploadFromDrive = () => {
        return (
            <>
                <UploadCard
                    onClick={() => null}
                    size="md"
                    isToCome
                    disabled
                    data-tip
                    data-for="drive"
                >
                    <div>
                        <Icon name="drive" size={22} color="#CECECF" />
                        <CardTitle isDisabled>Google Drive</CardTitle>
                    </div>
                </UploadCard>
                <ReactTooltip id="drive" delayShow={100}>
                    <TooltipText>Coming Soon</TooltipText>
                </ReactTooltip>
            </>
        );
    };

    const renderUploadFromGooglePhotos = () => {
        return (
            <>
                <UploadCard
                    onClick={() => null}
                    size="md"
                    isToCome
                    disabled
                    data-tip
                    data-for="google-photos"
                >
                    <div>
                        <Icon name="google-photos" size={22} color="#CECECF" />
                        <CardTitle isDisabled>Google Photos</CardTitle>
                    </div>
                </UploadCard>
                <ReactTooltip id="google-photos" delayShow={100}>
                    <TooltipText>Coming Soon</TooltipText>
                </ReactTooltip>
            </>
        );
    };

    // const renderUploadStreamableLink = () => {
    //     return (
    //         <UploadCard
    //             disabled={isUploadFromExternalSourceInProgress || isImageUploadInProgress}
    //             onClick={handleShowStreamabaleLinkUploadModal} size="md"
    //         >
    //             <div>
    //                 <Icon name="link" size={22} />
    //                 <CardTitle>Link</CardTitle>
    //             </div>
    //         </UploadCard>
    //     );
    // };

    const renderUploadChoicesBlock = () => {
        // if(modalData?.uploadType === ModalUploadType.STREAMABLE_LINK) {
        //     return (
        //         <>
        //             {renderUploadFromStreamableLink()}
        //         </>
        //     )
        // }

        if (
            !modalData?.uploadType ||
            modalData?.uploadType === ModalUploadType.DEFAULT
        ) {
            return (
                <Grid columns={5}>
                    {renderBlank()}
                    {/* {renderUploadStreamableLink()} */}
                    {renderUploadFromLibraryButton()}
                    {renderUploadFromDropbox()}
                    {/* <Grid columns={1} rows={2}> */}
                    {/*    {renderUploadFromDrive()} */}
                    {/*    {renderUploadFromGooglePhotos()} */}
                    {/* </Grid> */}
                    {renderUploadFromDrive()}
                    {renderUploadFromGooglePhotos()}
                </Grid>
            );
        }

        return (
            <Grid columns={4}>
                {renderBlank()}
                {/* {renderUploadStreamableLink()} */}
                {renderUploadFromDropbox()}
                {renderUploadFromDrive()}
                {renderUploadFromGooglePhotos()}
            </Grid>
        );
    };

    return (
        <div className={styles.ProjectUploadModal}>
            <div className={styles.ProjectUploadModal__topLine}>
                <p className={styles.ProjectUploadModal__title}>Add Media</p>
                {renderAvailableStorage()}
                {renderCloseButton()}
            </div>
            <div className={styles.ProjectUploadModal__content}>
                {!modalData?.uploadType ||
                modalData?.uploadType === ModalUploadType.DEFAULT ? (
                    <>{renderDropzone()}</>
                ) : null}
            </div>
            {renderUploadChoicesBlock()}
            {!(modalData && modalData?.replaceableMedia) && (
                <StockMediaContainer>
                    <StockVideosCategorizedCarousel inModal />
                </StockMediaContainer>
            )}
        </div>
    );
};

export default ProjectUploadModal;
