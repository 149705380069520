import { LimitationType } from 'interfaces/limitations';
import { UploadingMedia } from 'interfaces/uploading';
import { TempMediaFile } from 'interfaces/videos';
import { UserUsageAndTotalSubscriptionData } from 'state/modules/payment';
import { reducer } from './calc';

export const getLimitationsInfo = (
    limitationType: LimitationType,
    extraInfo?: string
): {
    title: string;
    message: string;
} | null => {
    switch (limitationType) {
        case LimitationType.NOT_ENOUGH_CREDITS_FOR_SUBTITLING:
            return {
                title: 'Not enough minutes',
                message: `You don’t have enough minutes for the subtitling. If you’d like to proceed, please upgrade your account, or buy more minutes.`,
            };
        case LimitationType.NOT_ENOUGH_CREDITS_FOR_TRANSLATE:
            return {
                title: 'Not enough minutes',
                message: `You don’t have enough minutes for this translation. If you’d like to proceed, please upgrade your account, or buy more minutes.`,
            };
        case LimitationType.NOT_ENOUGH_CREDITS_FOR_DUBBING:
            return {
                title: 'Not enough minutes',
                message: `You don’t have enough minutes for this dubbing. If you’d like to proceed, please upgrade your account, or buy more minutes.`,
            };
        case LimitationType.ANALYSIS_PRICE_OVER_LIMIT:
            return {
                title: 'Video analysis Not enough minutes',
                message: `The analysis operation price is larger than the available minutes. If you'd like to proceed, please upgrade to a Pro/Basic account, or try to upload a smaller file`,
            };
        case LimitationType.NOT_ENOUGH_STORAGE:
            return {
                title: 'Storage size is over your limit',
                message: `You do not have enough storage to upload the file. If you'd like to proceed, please upgrade to a Pro/Basic account, try to upload a smaller file or delete existing projects/files`,
            };
        case LimitationType.UPLOAD_VIDEO_SIZE_OVER_LIMIT:
            return {
                title: 'Upload file size over your limit',
                message: `You are trying to upload a file that is bigger than your limit. If you'd like to proceed, please upgrade to a Pro/Basic account, or try to upload a smaller file`,
            };
        case LimitationType.UPLOAD_VIDEO_LENGTH_OVER_LIMIT:
            return {
                title: `Upload Max ${extraInfo || ''} per video`,
                message: `You are trying to upload a file that is longer than your limit. If you'd like to proceed, please upgrade to a Pro/Basic account, or try to upload a shorter file`,
            };
        case LimitationType.PROJECT_EXPORT_LENGTH_OVER_LIMIT:
            return {
                title: `Export Max ${extraInfo || ''} per project`,
                message: `You are trying to export a project that is longer than your limit. If you'd like to proceed, please upgrade to a Pro/Basic account, or try to export a shorter project`,
            };
        case LimitationType.REMOVE_WATERMARK:
            return {
                title: 'Watermark removal',
                message: `You are trying to remove the watermark. If you'd like to proceed, please upgrade to a Pro/Basic account`,
            };
        case LimitationType.EXPORT_SUBTITLES:
            return {
                title: 'Download subtitles',
                message: `You are trying to download subtitles. If you'd like to proceed, please upgrade to a Paid Plan`,
            };
        case LimitationType.STOCK_MEDIA_ASSETS_OVER_LIMIT:
            return {
                title: `Upgrade your workspace to get unlimited stock media.`,
                message: ``,
            };
        case LimitationType.FREE_PLAN_NEW_WORKSPACE_PREVENT:
            return {
                title: `Upgrade your plan to create new workspaces.`,
                message: ``,
            };
        default:
            return null;
    }
};

export const сheckStorageLimitations = (
    videos: UploadingMedia[],
    userUsage: UserUsageAndTotalSubscriptionData
): boolean => {
    const uploadingMediaTotalSizeArr = videos.map((video) => video.file.size);
    const uploadingMediaTotalSize = uploadingMediaTotalSizeArr.reduce(
        reducer,
        0
    );

    const total = (userUsage?.total.storage || 0) as number;
    const current = userUsage?.usage.storage;
    const currentSum = uploadingMediaTotalSize + current;

    return currentSum > total;
};

export const сheckVideosDurationLimitations = (
    videos: TempMediaFile[],
    availableVideoLength: number | string
): boolean => {
    return (
        videos.findIndex(
            (video) =>
                video.mediaInfo.original.container.duration * 1000 >
                +availableVideoLength
        ) >= 0
    );
};

export const сheckVideoSizeLimitations = (
    videos: UploadingMedia[],
    limit: number
): boolean => videos.findIndex((video) => video.size > +limit) >= 0;
