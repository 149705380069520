import { useMemo } from 'react';
import styled from 'styled-components';

import {
    InviteToWorkspace,
    InviteToWorkspaceStatuses,
} from 'interfaces/workspaces';
import { GREEN_200, NEUTRAL_100, NEUTRAL_300, PINK_50, RED_300, WHITE_PRIMARY } from '../../styles/colors';

const Container = styled.div<{ isPending?: boolean }>`
    display: flex;
    opacity: ${({ isPending }) => (isPending ? '0.5' : '1')};
`;

const Avatar = styled.div<{ avatarSize?: number }>`
    width: ${({ avatarSize }) => (avatarSize ? `${avatarSize}rem` : '2rem')};
    height: ${({ avatarSize }) => (avatarSize ? `${avatarSize}rem` : '2rem')};
    background: ${PINK_50};
    border-radius: 50%;;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const AvatarPlaceholder = styled.span`
    font-family: 'Inter Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.375rem;
    text-align: center;
    color: ${NEUTRAL_100};
    text-transform: uppercase;
`;

const Name = styled.p`
    font-family: 'Inter Semibold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.text};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const Status = styled.p<{ color?: string }>`
    font-family: 'Inter Medium', sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0;
    color: ${({ color }) => color};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
`;

const Info = styled.div`
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
`;

interface Props {
    avatarSize?: number;
    data: InviteToWorkspace;
}

const WorkspaceInviteeCard = ({ data, avatarSize }: Props) => {
    const { inviteeEmail, status } = data;

    const namePlaceholder = useMemo(() => {
        return inviteeEmail.slice(0, 2);
    }, [inviteeEmail]);

    const statusString = useMemo(() => {
        switch (status) {
            case InviteToWorkspaceStatuses.ACCEPTED:
                return 'Invite accepted';
            case InviteToWorkspaceStatuses.PENDING:
                return '(Invite pending)';
            case InviteToWorkspaceStatuses.REJECTED:
                return 'Invite rejected';
            default:
                return '';
        }
    }, [status]);

    const statusColor = useMemo(() => {
        switch (status) {
            case InviteToWorkspaceStatuses.ACCEPTED:
                return GREEN_200;
            case InviteToWorkspaceStatuses.PENDING:
                return NEUTRAL_300;
            case InviteToWorkspaceStatuses.REJECTED:
                return RED_300;
            default:
                return '';
        }
    }, [status]);

    return (
        <Container isPending={status === InviteToWorkspaceStatuses.PENDING}>
            <Avatar avatarSize={avatarSize}>
                <AvatarPlaceholder>{namePlaceholder}</AvatarPlaceholder>
            </Avatar>
            <Info>
                <Name>{inviteeEmail}</Name>
                <Status color={statusColor}>{statusString}</Status>
            </Info>
        </Container>
    );
};

export default WorkspaceInviteeCard;
