import React from 'react';

import styles from './styles.module.scss';

interface Props {
  children: any;
  hasNavbar?: boolean;
}

const AppContainer: React.FC<Props> = ({ children, hasNavbar }) => (
  <div className={[
    styles.AppContainer,
    hasNavbar ? styles.AppContainer_withOffser : null
  ].join(' ')}>{children}</div>
);

export default AppContainer;
