import { IconProps } from './index';

const Celebrity = ({ size, color }: IconProps): JSX.Element => {
  let height = 23;
  if (size) {
    height = size;
  }

  return (
    <svg width={height} height={height} viewBox="0 0 30 29" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-Nova-Enterprise---All-none-selected"
          transform="translate(-128.000000, -186.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Tab-2" transform="translate(95.000000, 172.000000)">
            <path
              d="M47.9999741,38.240696 L39.1699555,42.9525132 C38.9712023,43.0585706 38.7253756,42.9810102 38.6208853,42.7792772 C38.5792762,42.698946 38.5649179,42.6069331 38.5800324,42.5174831 L40.2664169,32.5376938 L33.1227808,25.4699678 C32.9619877,25.3108817 32.9586965,25.049613 33.115434,24.8864076 C33.1778456,24.8214184 33.2596266,24.7791246 33.348112,24.766074 L43.2203729,23.3100423 L47.6353801,14.2301303 C47.7347577,14.0257523 47.9785516,13.9418397 48.1799109,14.0427063 C48.260092,14.0828718 48.3249939,14.1487458 48.3645661,14.2301303 L52.7795754,23.3100423 L62.6518342,24.766074 C62.8740469,24.7988475 63.0280116,25.0082555 62.9957212,25.2338 C62.9828632,25.3236129 62.9411952,25.406619 62.8771654,25.4699678 L55.7335313,32.5376938 L57.4199138,42.5174831 C57.4578718,42.742116 57.3092333,42.9554498 57.0879192,42.9939774 C56.9997895,43.0093193 56.909135,42.994746 56.8299907,42.9525132 L47.9999741,38.240696 Z M51.0545638,32.1153053 C50.2855255,32.9152046 49.2757766,33.4018683 48.1567191,33.4018683 C46.9848596,33.4018683 45.932866,32.8681941 45.1516362,32 C43.8383979,32.6951134 43,33.8526639 43,35.1501934 C43,35.3194225 43.014283,35.4874219 43.0425851,35.6533865 C43.0766872,35.8533761 43.2542617,36 43.4623638,36 L52.5376362,36 C52.7457383,36 52.9233128,35.8533761 52.9574149,35.6533865 C52.9857149,35.4874219 53,35.3194225 53,35.1501934 C53,33.9243692 52.2513404,32.8205844 51.0545638,32.1153053 Z M48.5,32 C50.4208002,32 52,30.0019612 52,27.5 C52,24.998039 50.4208002,23 48.5,23 C46.5792019,23 45,24.998039 45,27.5 C45,30.0019612 46.5792019,32 48.5,32 Z"
              id="Celebrities"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Celebrity;
