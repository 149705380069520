export interface Workspace {
    id: string;
    name: string;
    userId: string;
    isDefault: boolean;
    description: string;
    createdDate: Date;
    updatedDate: Date;
    deletedDate: Date;
    membersCount: number;
    planName: string;
}

export enum InviteToWorkspaceStatuses {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export interface InviteToWorkspace {
    inviter: string;
    createdDate: string;
    id: string;
    inviterEmail?: string;
    inviteeEmail: string;
    inviterId: string;
    status: InviteToWorkspaceStatuses;
    workspaceId: string;
    workspaceName?: string;
}

export enum WorkspacePlanNames {
    INITIAL = 'Initial product',
    BASIC = 'Basic',
    PRO = 'Professional',
    BUSINESS = 'Business',
}
