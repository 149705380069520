import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Loader from "react-loader-spinner";

import { addVideosFromLibraryToProject } from "state/modules/projects";
import { getModalOptionalData } from "state/modules/modal";
import { getVideoFiles } from "state/modules/media";

import { StatusType } from "interfaces/statuses";

import { Colors } from "styles";

const Container = styled.div`
  width: 600px;
  max-width: 95vw;
  background: #ffffff;
  box-shadow: 0px 4px 65px rgba(69, 139, 148, 0.066);
  position: relative;
  z-index: 1;
  padding: 50px 30px;
  max-height: 90vh;
  padding-bottom: 80px;
  overflow-y: auto;
  border-radius: 12px;
`;

const Title = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-size: 22px;
  color: #000829;
  margin-bottom: 35px;
  text-align: center;
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VideoTitle = styled.p`
  margin-right: 15px;
`;

const ProjectExternalSourceUploadStatus = (): JSX.Element => {
  const dispatch = useDispatch();
  const videoId = useSelector(getModalOptionalData);
  const videos = useSelector(getVideoFiles);

  const currentVideo = useMemo(
    () => videos.find((mediaSource) => mediaSource.id === videoId),
    [videos, videoId]
  );

  useEffect(() => {
    if (currentVideo?.status?.transcoding?.status === StatusType.SUCCEEDED) {
      dispatch(addVideosFromLibraryToProject({
          newVideos: [currentVideo],
          isFromUploads: false,
      }));
    }
  }, [currentVideo, dispatch]);

  const renderTitle = () => {
    if (currentVideo?.status?.upload?.status === StatusType.IN_PROGRESS) {
      return <Title>Uploading in Progress</Title>;
    }
    if (currentVideo?.status?.ingest?.status === StatusType.IN_PROGRESS) {
      return <Title>Uploading in Progress</Title>;
    }
    if (currentVideo?.status?.transcoding?.status === StatusType.IN_PROGRESS) {
      return <Title>Transcoding in Progress</Title>;
    }

    return "";
  };

  const renderVideo = () => {
    if (currentVideo) {
      return (
        <VideoContainer>
          <VideoTitle>
            {currentVideo.title || currentVideo.filename}...
          </VideoTitle>
          <Loader type="Oval" color={Colors.TURQUOISE} height={20} width={20} />
        </VideoContainer>
      );
    }
  };

  return (
    <Container>
      {renderTitle()}
      {renderVideo()}
    </Container>
  );
};

export default ProjectExternalSourceUploadStatus;
