import React, { memo } from 'react';
import { Icon } from 'components/UI';
import styles from './styles.module.scss';

const PlayerPlaceholder = () => (
  <div className={styles.VideoPreviewPlaceholder}>
    <div className={styles.VideoPreviewPlaceholder__content}>
      <div className={styles.VideoPreviewPlaceholder__iconWrap}>
        <Icon name="files" size={48} color="#ffffff" />
      </div>
    </div>
  </div>
);

export default memo(PlayerPlaceholder);
