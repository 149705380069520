import { AxiosResponse } from 'axios';
import { build } from 'search-params';
import { GetCollectiosItemsBySearchResponse } from 'models/collections';
import store from 'state/store';
import $http from './instance';

class CollectionsClient {
  public static async getCollections(): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/collections?sortBy=+creationTime`);
  }

  public static async createCollection(name: string): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.post(`/user/${userId}/collections`, {
      name,
      desription: '',
    });
  }

  public static async deleteCollection(id: string): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.delete(`/user/${userId}/collections/${id}`);
  }

  public static async updateCollection(
    id: string,
    data: {
            name: string;
        },
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.put(`/user/${userId}/collections/${id}`, data);
  }

  public static async getCollectionById(
    id: string,
    name: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/collections/${id}?name=${name}`);
  }

  public static async getCollectionImage(
    id: string,
    name?: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    if (name) {
      const query = build({
        name,
      });

      return $http.get(
        `/user/${userId}/collections/${id}/images?${query}`,
      );
    }
    return $http.get(`/user/${userId}/collections/${id}/images`);
  }

  public static async deleteCollectionsImages(
    id: string,
    imageIds: Array<string>,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;
    const imageIdsString = imageIds.join(',');

    return $http.delete(
      `/user/${userId}/collections/${id}/images/${imageIdsString}`,
    );
  }

  public static async updateCollectionImage(
    id: string,
    imageId: string,
    data: {
            name: string;
        },
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.put(
      `/user/${userId}/collections/${id}/images/${imageId}`,
      data,
    );
  }

  public static async getCollectionsItemsByIds(
    collectionId: string,
    imagesIds: string,
  ): Promise<AxiosResponse> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(
      `/user/${userId}/collections/${collectionId}/images/${imagesIds}`,
    );
  }

  public static async getCollectiosItemsBySearch(
    name: string,
  ): Promise<AxiosResponse<GetCollectiosItemsBySearchResponse>> {
    const userId: string = store.getState().user.userInfo.id;

    return $http.get(`/user/${userId}/collections/global?name=${name}`);
  }
}

export default CollectionsClient;
