import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 21 / 20;

const ArrowLeft = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.440613 8.94072C-0.145325 9.52666 -0.145325 10.4782 0.440613 11.0642L7.94061 18.5642C8.52655 19.1501 9.47811 19.1501 10.0641 18.5642C10.65 17.9782 10.65 17.0267 10.0641 16.4407L5.11874 11.5001H19.5C20.3297 11.5001 21 10.8298 21 10.0001C21 9.17041 20.3297 8.5001 19.5 8.5001H5.12343L10.0594 3.55947C10.6453 2.97353 10.6453 2.02197 10.0594 1.43604C9.47343 0.850098 8.52186 0.850098 7.93593 1.43604L0.435925 8.93604L0.440613 8.94072Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowLeft;
