import produce from 'immer';

import { AnalysisAction, AnalysisActionTypes, AnalysisState } from './types';

const initialState: AnalysisState = {
  isReAnalysisLoading: false,
};

export default (
  state = initialState,
  action: AnalysisAction,
): AnalysisState => produce(state, (draft) => {
  switch (action.type) {
    case AnalysisActionTypes.REANALYZE_VIDEOS_START:
      draft.isReAnalysisLoading = true;

      return;
    case AnalysisActionTypes.REANALYZE_VIDEOS_SUCCESS:
      draft.isReAnalysisLoading = false;

      return;
    case AnalysisActionTypes.REANALYZE_VIDEOS_FAIL:
      draft.isReAnalysisLoading = false;

      return;
    default:
      return state;
  }
});
