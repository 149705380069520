import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 26 / 20;

const Sound = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.6775 1.26956C24.3141 3.33206 26 6.47659 26 10C26 13.5235 24.3141 16.6719 21.6775 18.7305C21.2591 19.0586 20.6456 18.9961 20.3044 18.5938C19.9631 18.1914 20.0281 17.6016 20.4466 17.2735C22.6484 15.5547 24.05 12.9375 24.05 10C24.05 7.06253 22.6484 4.44534 20.4466 2.72268C20.0281 2.39456 19.9672 1.80472 20.3044 1.40237C20.6416 1.00003 21.2591 0.941434 21.6775 1.26565V1.26956ZM19.2197 4.17971C20.9747 5.55471 22.1 7.65237 22.1 10C22.1 12.3477 20.9747 14.4453 19.2197 15.8203C18.8013 16.1485 18.1878 16.086 17.8466 15.6836C17.5053 15.2813 17.5703 14.6914 17.9887 14.3633C19.3091 13.3321 20.15 11.7617 20.15 10C20.15 8.23831 19.3091 6.668 17.9887 5.63284C17.5703 5.30471 17.5094 4.71487 17.8466 4.31253C18.1837 3.91018 18.8013 3.85159 19.2197 4.17581V4.17971ZM16.7619 7.08987C17.6353 7.77737 18.2 8.82425 18.2 10C18.2 11.1758 17.6353 12.2227 16.7619 12.9102C16.3434 13.2383 15.73 13.1758 15.3887 12.7735C15.0475 12.3711 15.1125 11.7813 15.5309 11.4532C15.9697 11.1094 16.25 10.586 16.25 10C16.25 9.41409 15.9697 8.89065 15.5309 8.543C15.1125 8.21487 15.0516 7.62503 15.3887 7.22268C15.7259 6.82034 16.3434 6.76175 16.7619 7.08596V7.08987ZM12.2322 1.3594C12.6994 1.56253 13 2.00784 13 2.50003V17.5C13 17.9922 12.6994 18.4375 12.2322 18.6407C11.765 18.8438 11.2166 18.7617 10.8347 18.4336L5.35437 13.75H2.6C1.16594 13.75 0 12.6289 0 11.25V8.75003C0 7.37112 1.16594 6.25003 2.6 6.25003H5.35437L10.8347 1.56643C11.2166 1.23831 11.765 1.16018 12.2322 1.3594Z"
                fill={color}
            />
        </svg>
    );
};

export default Sound;
