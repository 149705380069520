import { createSelector } from "reselect";

import { Job, JobsState } from "state/modules/jobs";
import { State } from "state/modules";

import { StatusType } from "interfaces/statuses";
import { VideoStatusesDestinations } from "./types";

export const getJobsState = (state: State): JobsState => state.jobs;

export const getVideoJobsList = createSelector(
  getJobsState,
  (state) => state.videoJobs
);

export const getBurningSubtitlesJobs = createSelector(
  getVideoJobsList,
  (jobs) =>
    jobs.filter((item: Job) => {
      const type = item.jobGenerationType || item.type;

      return type === VideoStatusesDestinations.BURNING_SUBTITLES;
    })
);

export const getBurningSubtitlesInProgressJobs = createSelector(
  getJobsState,
  (state) => {
    const jobs = state.videoJobs;

    return jobs
      .filter((item: Job) => {
        const type = item.jobGenerationType || item.type;

        return type === VideoStatusesDestinations.BURNING_SUBTITLES;
      })
      .filter(
        (item: Job) =>
          item.status === StatusType.IN_PROGRESS ||
          item.status === StatusType.WAITING
      );
  }
);

export const getClipPreviewsGenerationJobs = createSelector(
  getJobsState,
  (state) => {
    const jobs = state.videoJobs;

    return jobs.filter((item: Job) => {
      const type = item.jobGenerationType || item.type;

      return type === VideoStatusesDestinations.GENERATION;
    });
  }
);

export const getClipPreviewsGenerationInProgressJobs = createSelector(
  getJobsState,
  (state) => {
    const jobs = state.videoJobs;

    return jobs
      .filter((item: Job) => {
        const type = item.jobGenerationType || item.type;

        return type === VideoStatusesDestinations.GENERATION;
      })
      .filter(
        (item: Job) =>
          item.status === StatusType.IN_PROGRESS ||
          item.status === StatusType.WAITING
      );
  }
);

export const getClipRenderingJobs = createSelector(getJobsState, (state) => {
  const jobs = state.videoJobs;

  return jobs.filter((item: Job) => {
    const type = item.jobGenerationType || item.type;

    return type === VideoStatusesDestinations.RENDERING;
  });
});

export const getClipRenderingInProgressJobs = createSelector(
  getJobsState,
  (state) => {
    const jobs = state.videoJobs;

    return jobs
      .filter((item: Job) => {
        const type = item.jobGenerationType || item.type;

        return type === VideoStatusesDestinations.RENDERING;
      })
      .filter(
        (item: Job) =>
          item.status === StatusType.IN_PROGRESS ||
          item.status === StatusType.WAITING
      );
  }
);

export const getJobDetails = (id: string) =>
  createSelector(getJobsState, (state) => {
    const jobs = state.videoJobs;

    return jobs.find((job: Job) => job.jobId === id);
  });
