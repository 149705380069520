import { StockAudioEffect, StockMedia, StockSong } from 'interfaces/stockMedia';
import { UploadingMedia } from 'interfaces/uploading';
import { ProjectAudio, ProjectVideo } from '../../../interfaces/projects';

export interface ProjectUploadState {
    isImageUploadInProgress: boolean;
    stockVideoIdWithUploadInProgress: string | null;
    stockAudioIdWithUploadInProgress: string | null;
    stockImageIdWithUploadInProgress: string | null;
    stockSoundEffectIdWithUploadInProgress: string | null;
}

export enum ProjectsUploadActionTypes {
    UPLOAD_PROJECT_AUDIO = '@@projectUpload/UPLOAD_PROJECT_AUDIO',
    UPLOAD_PROJECT_AUDIO_SUCCESS = '@@projectUpload/UPLOAD_PROJECT_AUDIO_SUCCESS',

    UPLOAD_PROJECT_VIDEO = '@@projectUpload/UPLOAD_PROJECT_VIDEO',

    UPLOAD_PROJECT_IMAGE = '@@projectUpload/UPLOAD_PROJECT_IMAGE',
    UPLOAD_PROJECT_IMAGE_START = '@@projectUpload/UPLOAD_PROJECT_IMAGE_START',
    UPLOAD_PROJECT_IMAGE_SUCCESS = '@@projectUpload/UPLOAD_PROJECT_IMAGE_SUCCESS',
    UPLOAD_PROJECT_IMAGE_FAIL = '@@projectUpload/UPLOAD_PROJECT_IMAGE_FAIL',
    // stock image
    UPLOAD_PROJECT_STOCK_IMAGE = '@@projectUpload/UPLOAD_PROJECT_STOCK_IMAGE',
    SET_PROJECT_STOCK_IMAGE_ID_FOR_UPLOAD = '@@projectUpload/SET_PROJECT_STOCK_IMAGE_ID_FOR_UPLOAD',
    // stock audio
    UPLOAD_PROJECT_STOCK_AUDIO = '@@projectUpload/UPLOAD_PROJECT_STOCK_AUDIO',
    SET_PROJECT_STOCK_AUDIO_ID_FOR_UPLOAD = '@@projectUpload/SET_PROJECT_STOCK_AUDIO_ID_FOR_UPLOAD',
    // stock sound effect
    UPLOAD_PROJECT_STOCK_SOUND_EFFECT = '@@projectUpload/UPLOAD_PROJECT_STOCK_SOUND_EFFECT',
    SET_PROJECT_STOCK_SOUND_EFFECT_ID_FOR_UPLOAD = '@@projectUpload/SET_PROJECT_STOCK_SOUND_EFFECT_ID_FOR_UPLOAD',
    // stock video
    UPLOAD_PROJECT_STOCK_VIDEO = '@@projectUpload/UPLOAD_PROJECT_STOCK_VIDEO',
    SET_PROJECT_STOCK_VIDEO_ID_FOR_UPLOAD = '@@projectUpload/SET_PROJECT_STOCK_VIDEO_ID_FOR_UPLOAD',
}

// Upload Project video

export interface UploadProjectVideoActionPayload {
    videos: UploadingMedia[];
    isStockMedia?: boolean;
    replaceableMedia?: ProjectVideo,
}

export interface UploadProjectAudioAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO;
    payload: UploadingMedia;
}

export interface UploadProjectAudioSuccessAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO_SUCCESS;
}

export interface UploadProjectVideoAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_VIDEO;
    payload: UploadProjectVideoActionPayload;
}

export interface UploadProjectImageActionPayload {
    images: File[];
    canvasWidth: number;
    canvasHeight: number;
    isStockMedia?: boolean;
    videosToUpload?: UploadingMedia[];
    id?: string;
}

export interface UploadProjectImageAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE;
    payload: UploadProjectImageActionPayload;
}
export interface UploadProjectImageStartAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_START;
}

export interface UploadProjectImageSuccessAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_SUCCESS;
}

export interface UploadProjectImageFailAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_FAIL;
}

// Stock Video

export interface UploadProjectStockVideoActionPayload {
    media: StockMedia;
}

export interface UploadProjectStockVideoAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_VIDEO;
    payload: UploadProjectStockVideoActionPayload;
}

export interface SetProjectStockVideoIdForUploadAction {
    type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_VIDEO_ID_FOR_UPLOAD;
    payload: string | null;
}

// Stock Audio

export interface UploadProjectStockAudioAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_AUDIO;
    payload: StockSong;
}

export interface SetProjectStockAudioIdForUploadAction {
    type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_AUDIO_ID_FOR_UPLOAD;
    payload: string | null;
}
// Stock Audio

export interface UploadProjectStockSoundEffectAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_SOUND_EFFECT;
    payload: StockAudioEffect;
}

export interface SetProjectStocSoundEffectIdForUploadAction {
    type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_SOUND_EFFECT_ID_FOR_UPLOAD;
    payload: string | null;
}

// Stock Image

export interface UploadProjectStockImageAction {
    type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_IMAGE;
    payload: StockMedia;
}

export interface SetProjectStockImageIdForUploadAction {
    type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_IMAGE_ID_FOR_UPLOAD;
    payload: string | null;
}

export type ProjectsUploadAction =
    | UploadProjectImageAction
    | UploadProjectImageStartAction
    | UploadProjectImageSuccessAction
    | UploadProjectImageFailAction
    | UploadProjectAudioAction
    | UploadProjectAudioSuccessAction
    | UploadProjectStockVideoAction
    | SetProjectStockVideoIdForUploadAction
    | UploadProjectStockAudioAction
    | SetProjectStockAudioIdForUploadAction
    | UploadProjectStockImageAction
    | SetProjectStockImageIdForUploadAction
    | UploadProjectStockSoundEffectAction
    | SetProjectStocSoundEffectIdForUploadAction;
