export enum ShotsCategory {
    ALL_SHOTS = 'allShots',
    SHOTS = 'shots',
    END_CREDITS = 'endCredits',
    BLACK_FRAMES = 'blackFrames',
    CONTENT = 'Content',
    OPENING_CREDITS = 'OpeningCredits',
    STUDIO_LOGO = 'StudioLogo',
}

export enum ShotType {
    CONTENT = 'Content',
    SHOT = 'shot',
    BLACK_FRAME = 'BlackFrames',
    END_CREDIT = 'EndCredits',
    OPENING_CREDITS = 'OpeningCredits',
    STUDIO_LOGO = 'StudioLogo'
}
