import { ClipTemplate } from 'interfaces/generation';
import { ClipGenerationDataClip, ClipOrder } from '../generation';

export interface JobsState {
    jobsList: Array<Job>;
    // videoJobs: Array<Job | ClipRenderingJob>;
    videoJobs: Array<any>;
}

export interface ClipRenderingJob {
    jobGenerationType: VideoStatusesDestinations | string;
    fps: number;
    jobId: string;
    message: string;
    status: string;
    statusHistory: Array<StatusHistory>;
    template: ClipTemplate;
    type: string;
    userId: string;
    videoId: string;
    clip: ClipGenerationDataClip;
}

export interface Job {
    clipData: {
        minClipLength: number;
        maxClipLength: number;
        clipShots: Array<JobClipShot>;
        numberOfClips: number;
        diffFactor: number;
        isMuted: boolean;
        maxSceneLength: number;
        minSceneLength: number;
        order: ClipOrder;
    };
    jobId: string;
    jobName: string;
    message: string;
    status: string;
    statusHistory: Array<StatusHistory>;
    template: ClipTemplate;
    type: string;
    userId: string;
    videoId: string;
    jobGenerationType: VideoStatusesDestinations | string;
}

export interface StatusHistory {
    message: string;
    status: string;
    updatedAt: string;
}

export interface JobClipShot {
    from: string;
    to: string;
    metadata: {
        color: string;
        confidence: number;
        duration: number;
        from: number;
        id: string;
        name: string;
        ownerId: string;
        scaleFactor: number;
        to: number;
        type: string;
        videoId: string;
    };
}

export enum JobsActionTypes {
    GET_JOBS = '@@jobs/GET_JOBS',
    GET_JOBS_BY_VIDEO_ID = '@@jobs/GET_JOBS_BY_VIDEO_ID',
    SET_VIDEO_JOBS = '@@jobs/SET_VIDEO_JOBS',
}

export interface GetJobsAction {
    type: JobsActionTypes.GET_JOBS;
}
export interface GetVideoJobsAction {
    type: JobsActionTypes.GET_JOBS_BY_VIDEO_ID;
    payload: string;
}
export interface SetVideoJobsAction {
    type: JobsActionTypes.SET_VIDEO_JOBS;
    payload: Array<Job>;
}

export enum VideoStatusesDestinations {
    GENERATION = 'GENERATION',
    RENDERING = 'RENDERING',
    BURNING_SUBTITLES = 'BURNING_SUBTITLES',
}

export type JobsAction =
    | GetJobsAction
    | GetVideoJobsAction
    | SetVideoJobsAction;
