import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 25 / 22;

const DropboxIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.0622 4.27063C12.8935 4.16738 12.8795 3.97858 13.0316 3.85149L17.0862 0.468421C17.2383 0.341334 17.5004 0.323616 17.6684 0.428707L24.3662 4.8132C24.5336 4.91829 24.5458 5.10647 24.3918 5.23173L20.3434 8.52682C20.1894 8.65207 19.9255 8.66918 19.7568 8.56592L13.0622 4.27063Z"
                fill={color} />
            <path
                d="M20.4637 9.37036C20.3134 9.24083 20.0519 9.21883 19.8821 9.32087L13.54 13.1439C13.3707 13.2459 13.3542 13.4359 13.5045 13.5642L17.0257 16.5899C17.176 16.7194 17.4393 16.7432 17.6104 16.6436L24.0417 12.9068C24.2128 12.8072 24.2305 12.6196 24.0802 12.4901L20.4637 9.37036Z"
                fill={color} />
            <path
                d="M13.0319 13.91C12.8798 13.7829 12.7551 13.8415 12.7551 14.0395V21.4221C12.7551 21.6201 12.8938 21.6971 13.0625 21.5938L19.5347 17.6175C19.7033 17.5136 19.842 17.2668 19.842 17.0688V16.3595C19.842 16.1615 19.7021 16.0808 19.531 16.181L17.6266 17.2949C17.4555 17.3945 17.1909 17.3731 17.0388 17.246L13.0319 13.91Z"
                fill={color} />
            <path
                d="M11.9375 4.27063C12.1067 4.16738 12.1201 3.97858 11.9686 3.85149L7.91406 0.468421C7.76192 0.341334 7.49981 0.323616 7.33179 0.428707L0.634073 4.8132C0.46605 4.91829 0.454441 5.10647 0.607801 5.23173L4.65686 8.52682C4.81022 8.65207 5.07478 8.66918 5.24341 8.56592L11.9375 4.27063Z"
                fill={color} />
            <path
                d="M4.53592 9.37036C4.68562 9.24083 4.94773 9.21883 5.11698 9.32087L11.4591 13.1439C11.6289 13.2459 11.6448 13.4359 11.4945 13.5642L7.97337 16.5899C7.82368 16.7194 7.55973 16.7432 7.38865 16.6436L0.957336 12.9068C0.786257 12.8072 0.768539 12.6196 0.918843 12.4901L4.53592 9.37036Z"
                fill={color} />
            <path
                d="M11.9681 13.91C12.1202 13.7829 12.2449 13.8415 12.2449 14.0395V21.4221C12.2449 21.6201 12.1068 21.6971 11.9382 21.5938L5.46529 17.6175C5.29726 17.5136 5.15857 17.2668 5.15857 17.0688V16.3595C5.15857 16.1615 5.29849 16.0808 5.46956 16.181L7.37403 17.2949C7.5445 17.3945 7.80967 17.3731 7.96181 17.246L11.9681 13.91Z"
                fill={color} />
        </svg>
    );
};

export default DropboxIcon;