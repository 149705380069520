import { IconProps } from './index';

const ExportIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 2H2M12 8.66667L8 4.66667M8 4.66667L4 8.66667M8 4.66667V14"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ExportIconNew;


