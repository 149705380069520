import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 0.92;

const ObjectsActivities = ({ size, color }: IconProps): JSX.Element => {
  let height = 25;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg width={width} height={height} viewBox="0 0 23 25" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-Nova-Enterprise---All-none-selected"
          transform="translate(-131.000000, -280.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Tab-3" transform="translate(95.000000, 269.000000)">
            <path
              d="M48.144837,11 L48.144837,18.0242731 L54.8681039,18.0242731 L54.8681039,28.5442784 L48.144837,28.5442784 L48.144837,35.0952381 L46.855163,35.0952381 L46.855163,28.5442784 L40.1318961,28.5442784 L40.1318961,18.0242731 L46.855163,18.0242731 L46.855163,11 L48.144837,11 Z M43.9976689,15.074548 L43.9976689,17.261793 L38.2097326,17.261793 L38.2097326,29.3065976 L43.9976689,29.3065976 L43.9976689,31.4940035 L36,31.4940035 L36,15.074548 L43.9976689,15.074548 Z M59,15.074548 L59,31.4940035 L51.002656,31.4940035 L51.002656,29.3065976 L56.7902674,29.3065976 L56.7902674,17.261793 L51.002656,17.261793 L51.002656,15.074548 L59,15.074548 Z M53.4523544,19.4257182 L41.5476456,19.4257182 L41.5476456,27.1428333 L53.4523544,27.1428333 L53.4523544,19.4257182 Z M47.5,21.0529642 C48.5386604,21.0529642 49.3805716,21.8863688 49.3805716,22.9145348 C49.3805716,23.9427008 48.5386604,24.7761055 47.5,24.7761055 C46.4613396,24.7761055 45.6194284,23.9427008 45.6194284,22.9145348 C45.6194284,21.8863688 46.4613396,21.0529642 47.5,21.0529642 Z"
              id="Objects-Activities"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ObjectsActivities;
