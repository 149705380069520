import { IconProps } from '../index';

const PauseIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="14.75" y="3.25" width="4.5" height="17.5" rx="1.25"
                  fill={color}
                  stroke={color}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <rect x="4.75" y="3.25" width="4.5" height="17.5" rx="1.25"
                  fill={color}
                  stroke={color}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
        </svg>
    );
};

export default PauseIconNew;


