import { IconProps } from './index';

const MicIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.39795 11.1772C4.66378 13.0037 5.57833 14.6734 6.9743 15.8809C8.37026 17.0884 10.1543 17.753 12.0001 17.753M12.0001 17.753C13.8458 17.753 15.6298 17.0884 17.0258 15.8809C18.4218 14.6734 19.3363 13.0037 19.6022 11.1772M12.0001 17.753V21.6M12.0011 2.40002C11.1282 2.40002 10.291 2.7468 9.67376 3.36406C9.05649 3.98132 8.70972 4.81851 8.70972 5.69145V10.08C8.70972 10.953 9.05649 11.7902 9.67376 12.4074C10.291 13.0247 11.1282 13.3715 12.0011 13.3715C12.8741 13.3715 13.7113 13.0247 14.3285 12.4074C14.9458 11.7902 15.2926 10.953 15.2926 10.08V5.69145C15.2926 4.81851 14.9458 3.98132 14.3285 3.36406C13.7113 2.7468 12.8741 2.40002 12.0011 2.40002Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default MicIcon;


