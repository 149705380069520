import { CSSProperties } from 'react';
import {
    GetRailProps,
    GetHandleProps,
    GetTrackProps,
    SliderItem,
    Slider,
    Rail,
    Handles,
    Tracks,
    TrackItem,
} from 'react-compound-slider';

const defaultSliderStyle = {
    position: 'relative' as const,
    width: '100%',
    touchAction: 'none',
};

const defaultRailOuterStyle = {
    position: 'absolute' as const,
    width: '100%',
    height: 42,
    transform: 'translate(0%, -50%)',
    borderRadius: 7,
    cursor: 'pointer',
};

const defaultRailInnerStyle = {
    position: 'absolute' as const,
    width: '100%',
    height: 14,
    transform: 'translate(0%, -50%)',
    borderRadius: 7,
    pointerEvents: 'none' as const,
    backgroundColor: 'rgb(155,155,155)',
};

const defaultTrackStyle = {
    position: 'absolute' as const,
    transform: 'translate(0%, -50%)',
    height: 14,
    zIndex: 1,
    backgroundColor: '#607E9E',
    borderRadius: 7,
    cursor: 'pointer',
};

const defaultHandleSliderStyle = {
    position: 'absolute' as const,
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    width: 24,
    height: 24,
    borderRadius: '50%',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
    backgroundColor: '#9BBFD4',
};

interface Props {
    domain: Array<number>;
    value: number;
    onChange: (value: number) => void;
    onUpdate?: (value: number) => void;
    sliderStyle?: CSSProperties;
    railOuterStyle?: CSSProperties;
    railInnerStyle?: CSSProperties;
    trackStyles?: CSSProperties;
    handleSliderStyle?: CSSProperties;
    step?: number;
}

export const CustomSlider = (props: Props): JSX.Element => {
    const {
        domain,
        value,
        onChange,
        onUpdate,
        sliderStyle,
        railOuterStyle,
        railInnerStyle,
        trackStyles,
        handleSliderStyle,
        step,
    } = props;

    const handleOnUpdate = (update: ReadonlyArray<number>) => {
        if (onUpdate) {
            onUpdate(update[0]);
        }
    };

    const handleOnChange = (values: ReadonlyArray<number>) => {
        onChange(values[0]);
    };

    const renderSliderRail = (getRailProps: GetRailProps) => (
        <>
            <div
                style={{ ...defaultRailOuterStyle, ...railOuterStyle }}
                {...getRailProps()}
            />
            <div style={{ ...defaultRailInnerStyle, ...railInnerStyle }} />
        </>
    );

    const renderTrack = (
        { id, source, target }: TrackItem,
        getTrackProps: GetTrackProps
    ) => (
        <div
            key={id}
            style={{
                ...defaultTrackStyle,
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
                ...trackStyles,
            }}
            {...getTrackProps()}
        />
    );

    const renderHandle = (
        handle: SliderItem,
        getHandleProps: GetHandleProps
    ) => {
        const { id, value, percent } = handle;

        return (
            <div key={id}>
                <div
                    style={{
                        left: `${percent}%`,
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                        zIndex: 5,
                        width: 28,
                        height: 42,
                        cursor: 'pointer',
                        backgroundColor: 'none',
                    }}
                    {...getHandleProps(id)}
                    onKeyDown={() => false}
                />
                <div
                    role="slider"
                    aria-valuemin={domain[0]}
                    aria-valuemax={domain[1]}
                    aria-valuenow={value}
                    style={{
                        left: `${percent}%`,
                        ...defaultHandleSliderStyle,
                        ...handleSliderStyle,
                    }}
                />
            </div>
        );
    };

    return (
        <Slider
            mode={1}
            step={step || 1}
            domain={domain}
            rootStyle={{ ...defaultSliderStyle, ...sliderStyle }}
            onUpdate={handleOnUpdate}
            onChange={handleOnChange}
            values={[value]}
        >
            <Rail>{({ getRailProps }) => renderSliderRail(getRailProps)}</Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                        {handles.map((handle) =>
                            renderHandle(handle, getHandleProps)
                        )}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                    <div className="slider-tracks">
                        {tracks.map((track: TrackItem) =>
                            renderTrack(track, getTrackProps)
                        )}
                    </div>
                )}
            </Tracks>
        </Slider>
    );
};

export default CustomSlider;
