import { Workspace } from 'interfaces/workspaces';
import {
    WorkspaceActionTypes,
    GetWorkspacesAction,
    GetWorkspacesStartAction,
    GetWorkspacesFailAction,
    SetWorkspacesAction,
    SetCurrentWorkspaceAction,
    GetCurrentWorkspaceAction,
    GetCurrentWorkspaceStartAction,
    GetCurrentWorkspaceFailAction,
    UpdateWorkspaceSuccessAction,
    UpdateWorkspaceFailAction,
    UpdateWorkspaceAction,
    UpdateWorkspaceStartAction,
    SendWorkspaceInviteAction,
    SendWorkspaceInviteActionPayload,
    SendWorkspaceInviteStartAction,
    SendWorkspaceInviteFailAction,
    SendWorkspaceInviteSuccessAction,
    GetInviteeInvitesAction,
    GetInviteeInvitesStartAction,
    GetInviteeInvitesFailAction,
    GetInviteeInvitesSuccessAction,
    GetInviterInvitesAction,
    GetInviterInvitesStartAction,
    GetInviterInvitesFailAction,
    GetInviterInvitesSuccessAction,
    PinInviteWithEmailAction,
    PinInviteWithEmailStartAction,
    PinInviteWithEmailFailAction,
    PinInviteWithEmailSuccessAction,
    PinInviteWithEmailActionPayload,
    UpdateInviteeWorkspaceInviteStatusActionPayload,
    UpdateInviteeWorkspaceInviteStatusAction,
    UpdateInviteeWorkspaceInviteStatusStartAction,
    UpdateInviteeWorkspaceInviteStatusFailAction,
    UpdateInviteeWorkspaceInviteStatusSuccessAction,
    UpdateInviterWorkspaceInviteStatusAction,
    UpdateInviterWorkspaceInviteStatusActionPayload,
    UpdateInviterWorkspaceInviteStatusStartAction,
    UpdateInviterWorkspaceInviteStatusFailAction,
    UpdateInviterWorkspaceInviteStatusSuccessAction,
    SetWorkspaceInviteModalVisibleAction,
    GetInviterInvitesSuccessActionPayload,
    UpdateWorkspaceActionPayload,
    GetInviteeInvitesSuccessActionPayload,
    GetInviteeWorkspacesAction,
    GetInviteeWorkspacesStartAction,
    GetInviteeWorkspacesFailAction,
    SetInviteeWorkspacesAction,
    ChangeWorkspaceAction,
    ChangeWorkspaceActionPayload,
    DeleteInviteAction,
    DeleteInviteSuccessAction,
    DeleteInviteFailAction,
    DeleteInviteStartAction,
    DeleteInviteActionPayload,
    AcceptInviteAndChangeWorkspaceAction,
    AcceptInviteAndChangeWorkspaceActionPayload,
    LeaveWorkspaceAction,
    LeaveWorkspaceActionPayload,
    SetWorkspaceFlowLoadingAction,
    CreateWorkspaceAction,
    CreateWorkspaceStartAction,
    CreateWorkspaceFailAction,
    CreateWorkspaceSuccessAction,
    DeleteWorkspaceAction,
    DeleteWorkspaceActionPayload,
    CreateWorkspaceActionPayload,
} from './types';

// workspaces

export function getWorkspaces(): GetWorkspacesAction {
    return {
        type: WorkspaceActionTypes.GET_WORKSPACES,
    };
}

export function getWorkspacesStart(): GetWorkspacesStartAction {
    return {
        type: WorkspaceActionTypes.GET_WORKSPACES_START,
    };
}

export function getWorkspacesFail(payload: any): GetWorkspacesFailAction {
    return {
        type: WorkspaceActionTypes.GET_WORKSPACES_FAIL,
        payload,
    };
}

export function setWorkspaces(payload: Workspace[]): SetWorkspacesAction {
    return {
        type: WorkspaceActionTypes.SET_WORKSPACES,
        payload,
    };
}

// invitee workspaces

export function getInviteeWorkspaces(): GetInviteeWorkspacesAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES,
    };
}

export function getInviteeWorkspacesStart(): GetInviteeWorkspacesStartAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES_START,
    };
}

export function getInviteeWorkspacesFail(
    payload: any
): GetInviteeWorkspacesFailAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACES_FAIL,
        payload,
    };
}

export function setInviteeWorkspaces(
    payload: Workspace[]
): SetInviteeWorkspacesAction {
    return {
        type: WorkspaceActionTypes.SET_INVITEE_WORKSPACES,
        payload,
    };
}

//

export function getCurrentWorkspace(): GetCurrentWorkspaceAction {
    return {
        type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE,
    };
}

export function getCurrentWorkspaceStart(): GetCurrentWorkspaceStartAction {
    return {
        type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE_START,
    };
}

export function getCurrentWorkspaceFail(
    payload: any
): GetCurrentWorkspaceFailAction {
    return {
        type: WorkspaceActionTypes.GET_CURRENT_WORKSPACE_FAIL,
        payload,
    };
}

export function setCurrentWorkspace(
    payload: Workspace
): SetCurrentWorkspaceAction {
    return {
        type: WorkspaceActionTypes.SET_CURRENT_WORKSPACE,
        payload,
    };
}

//

export function updateWorkspace(
    payload: UpdateWorkspaceActionPayload
): UpdateWorkspaceAction {
    return {
        type: WorkspaceActionTypes.UPDATE_WORKSPACE,
        payload,
    };
}

export function updateWorkspaceStart(): UpdateWorkspaceStartAction {
    return {
        type: WorkspaceActionTypes.UPDATE_WORKSPACE_START,
    };
}

export function updateWorkspaceFail(payload: any): UpdateWorkspaceFailAction {
    return {
        type: WorkspaceActionTypes.UPDATE_WORKSPACE_FAIL,
        payload,
    };
}

export function updateWorkspaceSuccess(): UpdateWorkspaceSuccessAction {
    return {
        type: WorkspaceActionTypes.UPDATE_WORKSPACE_SUCCESS,
    };
}

// send invite

export function sendWorkspaceInvite(
    payload: SendWorkspaceInviteActionPayload
): SendWorkspaceInviteAction {
    return {
        type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE,
        payload,
    };
}

export function sendWorkspaceInviteStart(): SendWorkspaceInviteStartAction {
    return {
        type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_START,
    };
}

export function sendWorkspaceInviteFail(
    payload: any
): SendWorkspaceInviteFailAction {
    return {
        type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_FAIL,
        payload,
    };
}

export function sendWorkspaceInviteSuccess(): SendWorkspaceInviteSuccessAction {
    return {
        type: WorkspaceActionTypes.SEND_WORKSPACE_INVITE_SUCCESS,
    };
}

// get invitee invites

export function getInviteeInvites(): GetInviteeInvitesAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES,
    };
}

export function getInviteeInvitesStart(): GetInviteeInvitesStartAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_START,
    };
}

export function getInviteeInvitesFail(
    payload: any
): GetInviteeInvitesFailAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_FAIL,
        payload,
    };
}

export function getInviteeInvitesSuccess(
    payload: GetInviteeInvitesSuccessActionPayload
): GetInviteeInvitesSuccessAction {
    return {
        type: WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_SUCCESS,
        payload,
    };
}

// get inviter invites

export function getInviterInvites(): GetInviterInvitesAction {
    return {
        type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES,
    };
}

export function getInviterInvitesStart(): GetInviterInvitesStartAction {
    return {
        type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_START,
    };
}

export function getInviterInvitesFail(
    payload: any
): GetInviterInvitesFailAction {
    return {
        type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_FAIL,
        payload,
    };
}

export function getInviterInvitesSuccess(
    payload: GetInviterInvitesSuccessActionPayload
): GetInviterInvitesSuccessAction {
    return {
        type: WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_SUCCESS,
        payload,
    };
}

// Pin invite with email

export function pinInviteWithEmail(
    payload: PinInviteWithEmailActionPayload
): PinInviteWithEmailAction {
    return {
        type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL,
        payload,
    };
}

export function pinInviteWithEmailStart(): PinInviteWithEmailStartAction {
    return {
        type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_START,
    };
}

export function pinInviteWithEmailFail(
    payload: any
): PinInviteWithEmailFailAction {
    return {
        type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_FAIL,
        payload,
    };
}

export function pinInviteWithEmailSuccess(): PinInviteWithEmailSuccessAction {
    return {
        type: WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_SUCCESS,
    };
}

// Update invitee invite status

export function updateInviteeWorkspaceInviteStatus(
    payload: UpdateInviteeWorkspaceInviteStatusActionPayload
): UpdateInviteeWorkspaceInviteStatusAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS,
        payload,
    };
}

export function updateInviteeWorkspaceInviteStatusStart(): UpdateInviteeWorkspaceInviteStatusStartAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_START,
    };
}

export function updateInviteeWorkspaceInviteStatusFail(
    payload: any
): UpdateInviteeWorkspaceInviteStatusFailAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_FAIL,
        payload,
    };
}

export function updateInviteeWorkspaceInviteStatusSuccess(): UpdateInviteeWorkspaceInviteStatusSuccessAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS,
    };
}

// Update inviter invite status

export function updateInviterWorkspaceInviteStatus(
    payload: UpdateInviterWorkspaceInviteStatusActionPayload
): UpdateInviterWorkspaceInviteStatusAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS,
        payload,
    };
}

export function updateInviterWorkspaceInviteStatusStart(): UpdateInviterWorkspaceInviteStatusStartAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_START,
    };
}

export function updateInviterWorkspaceInviteStatusFail(
    payload: any
): UpdateInviterWorkspaceInviteStatusFailAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_FAIL,
        payload,
    };
}

export function updateInviterWorkspaceInviteStatusSuccess(): UpdateInviterWorkspaceInviteStatusSuccessAction {
    return {
        type: WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_SUCCESS,
    };
}

// invite modal

export function setWorkspaceInviteModalVisible(
    payload: boolean
): SetWorkspaceInviteModalVisibleAction {
    return {
        type: WorkspaceActionTypes.SET_WORKSPACE_INVITE_MODAL_VISIBLE,
        payload,
    };
}

// change workspace
export function changeWorkspace(
    payload: ChangeWorkspaceActionPayload
): ChangeWorkspaceAction {
    return {
        type: WorkspaceActionTypes.CHANGE_WORKSPACE,
        payload,
    };
}

// delete invite
export function deleteInvite(
    payload: DeleteInviteActionPayload
): DeleteInviteAction {
    return {
        type: WorkspaceActionTypes.DELETE_INVITE,
        payload,
    };
}

export function deleteInviteStart(): DeleteInviteStartAction {
    return {
        type: WorkspaceActionTypes.DELETE_INVITE_START,
    };
}

export function deleteInviteFail(payload: any): DeleteInviteFailAction {
    return {
        type: WorkspaceActionTypes.DELETE_INVITE_FAIL,
        payload,
    };
}

export function deleteInviteSuccess(): DeleteInviteSuccessAction {
    return {
        type: WorkspaceActionTypes.DELETE_INVITE_SUCCESS,
    };
}

// Accept invite and change workspace

export function acceptInviteAndChangeWorkspace(
    payload: AcceptInviteAndChangeWorkspaceActionPayload
): AcceptInviteAndChangeWorkspaceAction {
    return {
        type: WorkspaceActionTypes.ACCEPT_INVITE_AND_CHANGE_WORKSPACE,
        payload,
    };
}

// Leave workspace

export function leaveWorkspace(
    payload: LeaveWorkspaceActionPayload
): LeaveWorkspaceAction {
    return {
        type: WorkspaceActionTypes.LEAVE_WORKSPACE,
        payload,
    };
}

// Delete workspace

export function deleteWorkspace(
    payload: DeleteWorkspaceActionPayload
): DeleteWorkspaceAction {
    return {
        type: WorkspaceActionTypes.DELETE_WORKSPACE,
        payload,
    };
}

// Loading
export function setWorkspaceFlowLoading(
    payload: boolean
): SetWorkspaceFlowLoadingAction {
    return {
        type: WorkspaceActionTypes.SET_WORKSPACE_FLOW_LOADING,
        payload,
    };
}

// Create

export function createWorkspace(
    payload: CreateWorkspaceActionPayload
): CreateWorkspaceAction {
    return {
        type: WorkspaceActionTypes.CREATE_WORKSPACE,
        payload,
    };
}

export function createWorkspaceStart(): CreateWorkspaceStartAction {
    return {
        type: WorkspaceActionTypes.CREATE_WORKSPACE_START,
    };
}

export function createWorkspaceFail(payload: any): CreateWorkspaceFailAction {
    return {
        type: WorkspaceActionTypes.CREATE_WORKSPACE_FAIL,
        payload,
    };
}

export function createWorkspaceSuccess(): CreateWorkspaceSuccessAction {
    return {
        type: WorkspaceActionTypes.CREATE_WORKSPACE_SUCCESS,
    };
}
