import {
  ShotsActionTypes,
  SearchFilterItem,
  GetShotsAction,
  GetShotsStartAction,
  GetShotsSuccessAction,
  GetShotsFailAction,
  SetShotsCategoryAction,
  GetShotsBySearchAction,
  GetFilteredShotsAction,
  GetFilteredShotsStartAction,
  GetFilteredShotsFailAction,
  GetFilteredShotsSuccessAction,
  SetSearchFilterAction,
  ChangeShotsFilterAction,
  ChangeShotsFilterActionPayload,
  UpdateShotsFilterItemAction,
  TransformedShot,
} from 'state/modules/shots/types';

import { ShotsCategory } from 'interfaces/shots';

export function getShots(videoId: string, offset: number): GetShotsAction {
  return {
    type: ShotsActionTypes.GET_SHOTS,
    payload: {
      videoId,
      offset,
    },
  };
}

export function getShotsStart(): GetShotsStartAction {
  return {
    type: ShotsActionTypes.GET_SHOTS_START,
  };
}

export function getShotsSuccess(
  shots: Array<TransformedShot>,
  total: number,
): GetShotsSuccessAction {
  return {
    type: ShotsActionTypes.GET_SHOTS_SUCCESS,
    payload: {
      shots,
      total,
    },
  };
}

export function getShotsFail(error: any): GetShotsFailAction {
  return {
    type: ShotsActionTypes.GET_SHOTS_FAIL,
    payload: error,
  };
}

export const setShotsCategory = (
  category: ShotsCategory,
): SetShotsCategoryAction => ({
  type: ShotsActionTypes.SET_SHOTS_CATEGORY,
  payload: category,
});

export const getShotsBySearch = (videoId: string): GetShotsBySearchAction => ({
  type: ShotsActionTypes.GET_SHOTS_BY_SEARCH,
  payload: {
    videoId,
  },
});

export const getFilteredShots = (
  videoId: string,
  query: string,
): GetFilteredShotsAction => ({
  type: ShotsActionTypes.GET_FILTERED_SHOTS,
  payload: {
    videoId,
    query,
  },
});

export const getFilteredShotsStart = (): GetFilteredShotsStartAction => ({
  type: ShotsActionTypes.GET_FILTERED_SHOTS_START,
});

export const getFilteredShotsFail = (
  error: any,
): GetFilteredShotsFailAction => ({
  type: ShotsActionTypes.GET_FILTERED_SHOTS_FAIL,
  payload: error,
});

export const getFilteredShotsSuccess = (
  shots: Array<TransformedShot>,
  total: number,
): GetFilteredShotsSuccessAction => ({
  type: ShotsActionTypes.GET_FILTERED_SHOTS_SUCCESS,
  payload: { shots, total },
});

export const setSearchFilter = (
  params: Array<SearchFilterItem>,
): SetSearchFilterAction => ({
  type: ShotsActionTypes.SET_SEARCH_FILTER,
  payload: params,
});

export const changeShotsFilter = ({
  reason,
  option,
  videoId,
}: ChangeShotsFilterActionPayload): ChangeShotsFilterAction => ({
  type: ShotsActionTypes.CHANGE_SEARCH_FILTER,
  payload: { reason, option, videoId },
});

export const updateShotsFilterItem = (
  item: SearchFilterItem,
  videoId: string,
): UpdateShotsFilterItemAction => ({
  type: ShotsActionTypes.UPDATE_SEARCH_FILTER_ITEM,
  payload: { item, videoId },
});
