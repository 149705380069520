import { IconProps } from './index';

const LandscapeIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            className="sc-bcXHqe fysLci OptionItemstyled__SocialIcon-sc-1qp2ef7-1 snsbc"
        >
            <path
                d="M19 4.75C20.7949 4.75 22.25 6.20507 22.25 8L22.25 16.5C22.25 18.2949 20.7949 19.75 19 19.75L5 19.75C3.20507 19.75 1.75 18.2949 1.75 16.5L1.75 8C1.75 6.20507 3.20507 4.75 5 4.75L19 4.75Z"
                stroke="currentColor"
            />
        </svg>
    );
};

export default LandscapeIcon;
