export enum RekognitionCategory {
    AWS_LABELS = 'labels',
    AWS_CELEBRITIES = 'celebs',
    AWS_FACES = 'faces',
    AWS_FACE_MATCHES = 'faceMatches',
    AWS_TRANSCRIPT = 'transcript',
    AWS_SHOTS = 'shots',
    AWS_MODERATED_LABELS = 'moderatedLabels',
    AWS_TRANSLATE = 'translate',
    AWS_DUBBING = 'dubbing',
}

export enum AnalysisResultsCategory {
    ALL = 'all', 
    FACIAL_ATTRIBUTE = 'facialAttribute',
    CELEBRITY = 'celebrity',
    USER_DEFINED = 'userDefined',
    FACES = 'faces',
    OBJECT = 'object',
    TRANSCRIPT = 'transcript',
    SHOT = 'shot',
    MODERATED_LABEL = 'moderatedLabel',
}
 
export enum AnalysisResultsType {
    OPENING_CREDITS = 'OpeningCredits',
    STUDIO_LOGO = 'StudioLogo',
    CONTENT = 'Content',
    BLACK_FRAME = 'BlackFrames',
    SHOT = 'shot',
    END_CREDITS = 'EndCredits',
    SENTENCE = 'sentence',
    PUNCTUATION = 'punctuation',
    PRONUNCIATION = 'pronunciation',
    FACIAL_ATTRIBUTE = 'facialAttribute',
    CELEBRITY = 'celebrity',
    USER_DEFINED = 'userDefined',
    OBJECT = 'object',
    EMOTION = 'emotion',
    FACE = 'face',
    MODERATED_LABEL = 'moderatedLabel',
}
