import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 1.615;

const VideosFilter = ({ size, color }: IconProps): JSX.Element => {
  let height = 21;
  if (size) {
    height = size;
  }

  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg width={width} height={height} viewBox="0 0 21 13" version="1.1">
      <g
        id="Rev-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="10-Nova-Enterprise---Filter-3"
          transform="translate(-1397.000000, -202.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g
            id="Right-Side-Panel"
            transform="translate(1163.000000, 28.000000)"
          >
            <g
              id="Movie-List"
              transform="translate(1.000000, 174.537260)"
            >
              <path
                d="M236.721053,0.307945328 C237.142105,-0.113107304 237.794737,-0.0920546725 238.215789,0.307945328 L238.215789,0.307945328 L241.163158,3.23426112 C241.584211,3.65531375 241.584211,4.30794533 241.163158,4.72899796 C240.973684,4.93952427 240.7,5.04478743 240.426316,5.04478743 C240.173684,5.04478743 239.9,4.93952427 239.689474,4.72899796 L239.689474,4.72899796 L238.552632,3.59215585 L238.552632,10.497419 C238.552632,11.0868927 238.089474,11.5500506 237.5,11.5500506 C236.910526,11.5500506 236.447368,11.0868927 236.447368,10.497419 L236.447368,10.497419 L236.447368,3.59215585 L235.310526,4.72899796 C234.889474,5.15005059 234.215789,5.15005059 233.815789,4.72899796 C233.394737,4.30794533 233.394737,3.65531375 233.815789,3.23426112 L233.815789,3.23426112 Z M252.447368,9.44478743 C253.036842,9.44478743 253.5,9.90794533 253.5,10.497419 C253.5,11.0868927 253.036842,11.5500506 252.447368,11.5500506 L252.447368,11.5500506 L244.173684,11.5500506 C243.584211,11.5500506 243.121053,11.0868927 243.121053,10.497419 C243.121053,9.90794533 243.584211,9.44478743 244.173684,9.44478743 L244.173684,9.44478743 Z M250.342105,4.72899796 C250.931579,4.72899796 251.394737,5.19215585 251.394737,5.78162954 C251.394737,6.37110322 250.931579,6.83426112 250.342105,6.83426112 L250.342105,6.83426112 L244.173684,6.83426112 C243.584211,6.83426112 243.121053,6.37110322 243.121053,5.78162954 C243.121053,5.19215585 243.584211,4.72899796 244.173684,4.72899796 L244.173684,4.72899796 Z M248.257895,0.0132084854 C248.847368,0.0132084854 249.310526,0.47636638 249.310526,1.06584006 C249.310526,1.65531375 248.847368,2.11847164 248.257895,2.11847164 L248.257895,2.11847164 L244.173684,2.11847164 C243.584211,2.11847164 243.121053,1.65531375 243.121053,1.06584006 C243.121053,0.47636638 243.584211,0.0132084854 244.173684,0.0132084854 L244.173684,0.0132084854 Z"
                id="Videos-Filter"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VideosFilter;
