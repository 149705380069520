import { IconProps } from './index';
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 20 / 23;

const TrashBinIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 23;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={height}
            height={width}
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.8889 3.72222C19.1836 3.72222 19.4662 3.83928 19.6746 4.04766C19.8829 4.25603 20 4.53865 20 4.83333C20 5.12802 19.8829 5.41063 19.6746 5.619C19.4662 5.82738 19.1836 5.94444 18.8889 5.94444H17.7778L17.7744 6.02333L16.7378 20.5467C16.6979 21.1073 16.447 21.632 16.0357 22.0151C15.6244 22.3981 15.0832 22.6111 14.5211 22.6111H5.47778C4.91571 22.6111 4.37452 22.3981 3.96321 22.0151C3.55189 21.632 3.30102 21.1073 3.26111 20.5467L2.22444 6.02444C2.22276 5.99781 2.22202 5.97113 2.22222 5.94444H1.11111C0.816426 5.94444 0.533811 5.82738 0.325437 5.619C0.117063 5.41063 0 5.12802 0 4.83333C0 4.53865 0.117063 4.25603 0.325437 4.04766C0.533811 3.83928 0.816426 3.72222 1.11111 3.72222H18.8889ZM15.5522 5.94444H4.44778L5.47889 20.3889H14.5211L15.5522 5.94444ZM12.2222 0.388885C12.5169 0.388885 12.7995 0.505949 13.0079 0.714322C13.2163 0.922696 13.3333 1.20531 13.3333 1.5C13.3333 1.79468 13.2163 2.0773 13.0079 2.28567C12.7995 2.49404 12.5169 2.61111 12.2222 2.61111H7.77778C7.48309 2.61111 7.20048 2.49404 6.9921 2.28567C6.78373 2.0773 6.66667 1.79468 6.66667 1.5C6.66667 1.20531 6.78373 0.922696 6.9921 0.714322C7.20048 0.505949 7.48309 0.388885 7.77778 0.388885H12.2222Z"
                fill={color}
            />
        </svg>
    );
};

export default TrashBinIcon;