import { IconProps } from '../index';

const TextInSkidIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18 30h6m0 0-3-3m3 3-3 3" stroke="#525253" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M17.582 18.401c.677-.747 1.49-1.121 2.438-1.121.948 0 1.745.338 2.39 1.014.653.668.98 1.476.98 2.423 0 .947-.33 1.758-.992 2.435-.661.676-1.462 1.014-2.402 1.014-.932 0-1.737-.406-2.414-1.217v1.002h-.968V15h.968v3.401zm2.378 4.81c.677 0 1.251-.25 1.721-.752.478-.5.717-1.086.717-1.754 0-.676-.231-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.274.234-1.744.704-.463.47-.694 1.062-.694 1.778 0 .708.236 1.31.706 1.802.47.494 1.043.74 1.72.74zM33.295 22.347a3.315 3.315 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.004 0-1.844-.33-2.521-.991-.67-.66-1.004-1.468-1.004-2.423 0-.955.339-1.77 1.016-2.447.677-.684 1.553-1.026 2.629-1.026.637 0 1.223.14 1.756.418.542.278.964.668 1.267 1.17l-.777.489c-.581-.756-1.342-1.134-2.282-1.134-.741 0-1.363.239-1.864.716-.502.47-.753 1.054-.753 1.754s.247 1.301.74 1.803c.495.5 1.12.752 1.877.752.908 0 1.673-.382 2.294-1.146l.765.525z"
                fill="#F0F0F2" />
            <path
                d="M19.585 18.401c.677-.747 1.49-1.121 2.438-1.121.948 0 1.744.338 2.39 1.014.653.668.98 1.476.98 2.423 0 .947-.33 1.758-.992 2.435-.661.676-1.462 1.014-2.402 1.014-.932 0-1.737-.406-2.414-1.217v1.002h-.968V15h.968v3.401zm2.378 4.81c.677 0 1.25-.25 1.72-.752.479-.5.718-1.086.718-1.754 0-.676-.231-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.275.234-1.745.704-.462.47-.693 1.062-.693 1.778 0 .708.235 1.31.705 1.802.47.494 1.044.74 1.721.74z"
                fill="#D0D0D0" />
            <path
                d="M21.588 18.401c.677-.747 1.49-1.121 2.438-1.121.948 0 1.744.338 2.39 1.014.653.668.98 1.476.98 2.423 0 .947-.331 1.758-.992 2.435-.661.676-1.462 1.014-2.402 1.014-.932 0-1.737-.406-2.414-1.217v1.002h-.968V15h.968v3.401zm2.378 4.81c.677 0 1.25-.25 1.72-.752.479-.5.718-1.086.718-1.754 0-.676-.231-1.265-.693-1.766-.462-.502-1.04-.752-1.733-.752-.693 0-1.275.234-1.745.704-.462.47-.693 1.062-.693 1.778 0 .708.235 1.31.705 1.802.47.494 1.044.74 1.721.74z"
                fill="#525253" />
            <path
                d="M13.12 24.083h-1.088l-1.35-2.924H7.073l-1.338 2.924H4.6L8.734 15h.31l4.076 9.083zm-2.904-3.963L8.89 17.172 7.55 20.12h2.665z"
                fill="#F0F0F2" />
            <path
                d="M15.123 24.083h-1.088l-1.35-2.924H9.076l-1.338 2.924H6.603L10.737 15h.31l4.076 9.083zm-2.904-3.963-1.326-2.948-1.339 2.948h2.665z"
                fill="#D0D0D0" />
            <path
                d="M18.127 24.083h-1.088l-1.35-2.924H12.08l-1.338 2.924H9.606L13.741 15h.31l4.076 9.083zm-2.904-3.963-1.327-2.948-1.338 2.948h2.665z"
                fill="#525253" />
            <path
                d="M35.298 22.347a3.316 3.316 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.004 0-1.844-.33-2.521-.991-.67-.66-1.004-1.468-1.004-2.423 0-.955.338-1.77 1.016-2.447.677-.684 1.553-1.026 2.629-1.026.637 0 1.223.14 1.756.418.542.278.964.668 1.267 1.17l-.777.489c-.581-.756-1.342-1.134-2.282-1.134-.741 0-1.362.239-1.864.716-.502.47-.753 1.054-.753 1.754s.247 1.301.74 1.803c.495.5 1.12.752 1.877.752.908 0 1.673-.382 2.294-1.146l.765.525z"
                fill="#D0D0D0" />
            <path
                d="M37.3 22.347a3.314 3.314 0 0 1-1.35 1.146 4.104 4.104 0 0 1-1.793.394c-1.004 0-1.844-.33-2.521-.991-.67-.66-1.004-1.468-1.004-2.423 0-.955.338-1.77 1.015-2.447.678-.684 1.554-1.026 2.63-1.026.637 0 1.222.14 1.756.418.542.278.964.668 1.267 1.17l-.777.489c-.582-.756-1.342-1.134-2.282-1.134-.741 0-1.363.239-1.865.716-.501.47-.752 1.054-.752 1.754s.247 1.301.74 1.803c.494.5 1.12.752 1.877.752.908 0 1.673-.382 2.294-1.146l.765.525z"
                fill="#525253" />
        </svg>
    );
};

export default TextInSkidIcon;