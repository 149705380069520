import { IconProps } from '../index';

const SubtitlesIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 2.5C4.51472 2.5 2.5 4.51472 2.5 7V17C2.5 19.4853 4.51472 21.5 7 21.5H17C19.4853 21.5 21.5 19.4853 21.5 17V7C21.5 4.51472 19.4853 2.5 17 2.5H7ZM3.5 7C3.5 5.067 5.067 3.5 7 3.5H17C18.933 3.5 20.5 5.067 20.5 7V17C20.5 18.933 18.933 20.5 17 20.5H7C5.067 20.5 3.5 18.933 3.5 17V7ZM7 12.5C6.72386 12.5 6.5 12.7239 6.5 13C6.5 13.2761 6.72386 13.5 7 13.5H12C12.2761 13.5 12.5 13.2761 12.5 13C12.5 12.7239 12.2761 12.5 12 12.5H7ZM15 12.5C14.7239 12.5 14.5 12.7239 14.5 13C14.5 13.2761 14.7239 13.5 15 13.5H17.5C17.7761 13.5 18 13.2761 18 13C18 12.7239 17.7761 12.5 17.5 12.5H15ZM7 16.5C6.72386 16.5 6.5 16.7239 6.5 17C6.5 17.2761 6.72386 17.5 7 17.5H15C15.2761 17.5 15.5 17.2761 15.5 17C15.5 16.7239 15.2761 16.5 15 16.5H7Z"
                fill={color}
            />
        </svg>
    );
};

export default SubtitlesIconNew;


