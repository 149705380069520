import groupBy from 'lodash.groupby';
import Timecode, {FRAMERATE} from 'smpte-timecode';

import { MetadataExportItem } from 'state/modules/export';
import { AnalysisResultTimestamp } from 'state/modules/metadata';

import {
  AvidExportData,
  AvidExportGroup,
  AvidExportItem,
  AvidExportListItem,
  AvidExportTransformedVideo,
  GroupedByTypeAvidExportData,
  GroupedByVideoIdAvidExportData,
  AvidExportMergeIntervalsOption,
  AvidExportFilesSettingsOption,
  AvidExportFpsOption,
  AvidExportTimestampInstance,
  AvidMarker,
} from 'interfaces/avidExport';
import { ColorOption } from 'interfaces/colors';
import { AnalysisResultsType } from 'interfaces/analysis';
import { MediaFile } from 'interfaces/videos';
import { isShotExportItem, isSentenceExportItem } from './interfaceChecker';
import { transformTimecodeToMs } from './format';

const DEFAULT_MS_OFFSET = '00:00:00.000';

export const avidExportMergeIntervalsOptions = [
  {
    value: 'No',
    label: 'No',
    id: 1,
  },
  {
    value: 2000,
    label: '2 seconds',
    id: 2,
  },
  {
    value: 3000,
    label: '3 seconds',
    id: 3,
  },
  {
    value: 5000,
    label: '5 seconds',
    id: 4,
  },
  {
    value: 10000,
    label: '10 seconds',
    id: 5,
  },
] as Array<AvidExportMergeIntervalsOption>;

export const avidExportFpsOptions = [
  {
    value: 23.976,
    label: '23.976',
    id: 1,
  },
  {
    value: 24,
    label: '24',
    id: 2,
  },
  {
    value: 25,
    label: '25',
    id: 3,
  },
  {
    value: 30,
    label: '30',
    id: 4,
  },
  {
    value: 60,
    label: '60',
    id: 5,
  },
] as Array<AvidExportFpsOption>;

export const avidExportColourOptions = [
  {
    value: 'green',
    label: '1',
    id: 1,
  },
  {
    value: 'red',
    label: '1',
    id: 2,
  },
  {
    value: 'yellow',
    label: '1',
    id: 3,
  },
  {
    value: 'blue',
    label: '1',
    id: 4,
  },
  {
    value: 'magenta',
    label: '1',
    id: 5,
  },
  {
    value: 'cyan',
    label: '1',
    id: 6,
  },
  {
    value: 'black',
    label: '1',
    id: 7,
  },
  {
    value: 'white',
    label: '1',
    id: 8,
  },
] as Array<ColorOption>;

export const avidExportFilesSettingsOptions = [
  {
    id: 1,
    value: 'File Per Label',
    label: 'File Per Label',
  },
  {
    id: 2,
    value: 'Custom',
    label: 'Custom',
  },
] as Array<AvidExportFilesSettingsOption>;

export const transformDataForAvidExport = (
  selectedItems: Array<MetadataExportItem>,
  videos: Array<MediaFile>,
  videoDetails: MediaFile,
): AvidExportData => {
  const transformedSentences = [] as Array<AvidExportItem>;
  const transformedItems = [] as Array<AvidExportItem>;

  const transcript = selectedItems.filter(
    (item: MetadataExportItem) => item.labelType === AnalysisResultsType.SENTENCE,
  );
  const restExportItems = selectedItems.filter(
    (item: MetadataExportItem) => item.labelType !== AnalysisResultsType.SENTENCE,
  );

  transcript.forEach((item: MetadataExportItem) => {
    const itemVideo = (videos.find(
      (video: MediaFile) => video.id === item.videoId,
    ) || videoDetails) as MediaFile;

    let timestamps = [] as Array<number>;

    timestamps = item.timestamps.map(
      (timestampItem: AnalysisResultTimestamp) => timestampItem.timestamp,
    );

    if (isSentenceExportItem(item)) {
      transformedSentences.push({
        ...item,
        videoName: itemVideo.filename,
        timestamps,
      });
    }
  });

  restExportItems.forEach((item: MetadataExportItem) => {
    const type = item.labelType;

    const itemVideo = (videos.find(
      (video: MediaFile) => video.id === item.videoId,
    ) || videoDetails) as MediaFile;
    let timestamps = [] as Array<number>;

    if (type === AnalysisResultsType.SHOT && isShotExportItem(item)) {
      timestamps = [item.startTimestampMillis, item.endTimestampMillis];
    } else {
      timestamps = item.timestamps.map(
        (timestampItem: AnalysisResultTimestamp) => timestampItem.timestamp,
      );
    }

    transformedItems.push({
      ...item,
      videoName: itemVideo.filename,
      timestamps,
    });
  });

  const filteredItems = transformedItems.filter(
    (item: AvidExportItem) => item.scenes.length,
  );

  const allItems = [
    ...filteredItems,
    ...transformedSentences,
  ] as Array<AvidExportItem>;

  const groupedByVideo = groupBy(
    allItems,
    (item: AvidExportItem) => item.videoId,
  );

  const dataForExport = {
    selectedItems: allItems,
    groupedData: {},
  } as AvidExportData;

  Object.keys(groupedByVideo).forEach((key: string) => {
    const groupedItemsByType = groupBy(
      groupedByVideo[key],
      (item: AvidExportItem) => item.labelType,
    ) as any;
    // ) as GroupedByTypeAvidExportData;

    dataForExport.groupedData[key] = groupedItemsByType;
  });

  return dataForExport;
};

export const transformAvidExportItemsForDisplay = (
  selectedItems: Array<AvidExportItem>,
  colourOptions: Array<ColorOption>,
): Array<AvidExportListItem> => {
  let transformedItems = [] as Array<AvidExportListItem>;

  transformedItems = selectedItems.map(
    (item: AvidExportItem, index: number) => ({
      ...item,
      comment:
        item.labelType === AnalysisResultsType.SHOT
        && 'data' in item
          ? item.data || item.name
          : item.name,
      name: 'PromoMii',
      colour: colourOptions[0],
      group: {
        label: `File ${index + 1}`,
        value: `File${index + 1}`,
        id: `${index}`,
      },
      order: index,
    }),
  );

  return transformedItems;
};

export const transformAvidExportItemstoGroups = (
  selectedItems: Array<AvidExportItem>,
): Array<AvidExportGroup> => selectedItems.map((item: AvidExportItem, index: number) => ({
  label: `File ${index + 1}`,
  value: `File${index + 1}`,
  id: index,
}));

export const transformAvidExportGroupedItemstoVideos = (
  groupedData: GroupedByVideoIdAvidExportData,
  videosData: Array<MediaFile>,
): Array<AvidExportTransformedVideo> => {
  const transformedVideos = [] as Array<AvidExportTransformedVideo>;

  Object.keys(groupedData).forEach((key: string, index: number) => {
    const selectedVideo = videosData.find(
      (item: MediaFile) => item.id === key,
    );

      const videoOffset = selectedVideo?.mediaInfo?.original?.other?.[0]?.timeCodeFirstFrame;
      let offset = '';

      if (videoOffset) {
        const frameRate = selectedVideo?.mediaInfo?.original?.video?.[0]?.framerate;
        const timecode = Timecode(videoOffset, (frameRate || avidExportFpsOptions[0]) as FRAMERATE);
        const transformedTimecode = timecode.toString();

        offset = transformedTimecode;
      }


    if (selectedVideo) {
      transformedVideos.push({
        videoId: key,
        videoName: selectedVideo.filename,
        track: {
          label: `V${index + 1}`,
          value: `V${index + 1}`,
          id: `V${index}`,
        },
        offset: offset.length ? offset : DEFAULT_MS_OFFSET,
      });
    }
  });

  return transformedVideos;
};

const getAvidItemsWithFramesOffset = (
  items: Array<AvidExportTimestampInstance>,
  offset: string,
  fps: AvidExportFpsOption
) => {
  const offsetWithoutFrames = offset.substring(0, 8);
  const offsetWithoutFramesArray = offsetWithoutFrames.split(':');
  const frames = +offset.substring(9, 11);
 

  const hoursInMs = Number(offsetWithoutFramesArray[0]) * 60 * 60 * 1000;
  const minutesInMs = Number(offsetWithoutFramesArray[1]) * 60 * 1000;
  const secondsInMs = Number(offsetWithoutFramesArray[2]) * 1000;
  const framesInMs = (1000 / fps.value) * frames;

  const sum = hoursInMs + minutesInMs + secondsInMs + framesInMs;

  return items.map(
    (instance: AvidExportTimestampInstance) => ({
      ...instance,
      timestamp: instance.timestamp + sum,
    }),
  );
};

export const transformAvidExportItemsToAvidMarkers = (
  items: Array<AvidExportListItem>,
  videos: Array<AvidExportTransformedVideo>,
  fps: AvidExportFpsOption
): Array<AvidMarker> => {
  const markers = [] as Array<AvidMarker>;

  items.forEach((item: AvidExportListItem) => {
    const underscoredName = item.videoName.split(' ').join('_');
    const video = videos.find(
      (videoObj: AvidExportTransformedVideo) => videoObj.videoName === item.videoName,
    );

    const itemsWithSameGroupe = items.filter(
      (itemObj: AvidExportListItem) => itemObj.group.value === item.group.value,
    );

    let nameAfter = '';

    itemsWithSameGroupe.forEach((itemObj: AvidExportListItem) => {
      nameAfter = `${nameAfter}_${itemObj.comment.substring(0, 5)}`;
    });

    let instances = [] as Array<AvidExportTimestampInstance>;

    if (video) {
      instances = item.timestamps.map((timestamp: number) => ({
        timestamp,
        filename: `${underscoredName}_${nameAfter}.txt`,
        track: video.track.value,
      }));

      instances = instances.filter(
        (instance: AvidExportTimestampInstance) => instance.timestamp,
      );

      if (video.offset) {
        instances = getAvidItemsWithFramesOffset(instances, video.offset, fps)

        // const offsetInSec = timecode.frameCount / timecode.frameRate;
        // const offsetInMs = Math.trunc( offsetInSec * 1000 )

        // const test = msToTimecode(offsetInMs, fps.value);
        


        // console.log({test})

        
        
      //   console.log({timecode})

      // // const offset = transformTimecodeToMs(video.offset);

      // const hoursInMs = timecode.hours * 60 * 60 * 1000;
      // const minutesInMs = timecode.minutes * 60 * 1000;
      // const secondsInMs = timecode.seconds * 1000;

      // const sum = hoursInMs + minutesInMs + secondsInMs;

      // const testSum = 

      //   // const timecodeValue = timecode.valueOf()

      //   console.log({sum})
      //   console.log({video})
        // console.log({instances})
      //   // console.log({offset})

      //   console.log()

      // const prevTest = instances[0].timestamp
      // const testTT2 = msToTimecode(sum, fps.value);

      // console.log({testTT2})

      //   instances = instances.map(
      //     (instance: AvidExportTimestampInstance) => ({
      //       ...instance,
      //       timestamp: instance.timestamp + sum,
      //     }),
      //   );
      }

      // const testTT = msToTimecode(instances[0].timestamp, fps.value);

      // console.log({testTT})

      markers.push({
        name: item.name,
        comment: item.comment,
        color: item.colour.value,
        instances,
      });
    }
  });

  return markers;
};
