/* eslint-disable no-return-await */
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import InvoicesList from 'components/InvoicesList';

import { Icon } from 'components/UI';

import { hideModal } from 'state/modules/modal';

const Title = styled.p`
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 500;
    margin: 0px auto 0px 0px;
    cursor: default;
`;

const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
    align-self: flex-end;
    padding: 0px;
`;

const Container = styled.div`
    width: 50rem;
    height: 50rem;
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;
    border-radius: 1.25rem;
    background-color: rgb(255, 255, 255);
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px;
    transition: color 0.15s ease-in-out 0s,
        background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
        box-shadow 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;
`;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.25rem;
`;

const InvoicesListContainer = styled.div`
    height: calc(100% - 40px);
    overflow: hidden;
`;

const Header = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 1rem;
`;

const InvoicesModal = () => {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(hideModal());
    };

    const renderCloseButton = () => (
        <CloseButton onClick={closeModal}>
            <Icon name="close" size={15} />
        </CloseButton>
    );

    return (
        <Container>
            <Header>
                <HeaderContent>
                    <Title>Invoices</Title>
                    {renderCloseButton()}
                </HeaderContent>
            </Header>
            <InvoicesListContainer>
                <InvoicesList />
            </InvoicesListContainer>
        </Container>
    );
};

export default InvoicesModal;
