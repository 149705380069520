import * as Sentry from '@sentry/react';

export const reportErrorToSentry = (
    error: any,
    where: string,
    extras: any,
) => {
    Sentry.withScope((scope) => {
        if (where) {
            scope.setTag("where", where);
        }

        if(extras) {
            Object.keys(extras).forEach(key => {
                scope.setExtra(key, extras[key]);
            });
        }

        Sentry.captureException(error);
    });
};