import produce from 'immer';

import { ShotsCategory } from 'interfaces/shots';

import { ClearDataAction, VideosActionTypes } from 'state/modules/media';
import { SignOutAction, UserActionTypes } from 'state/modules/user';
import {
  ClearMetadataAction,
  MetadataActionTypes,
} from 'state/modules/metadata';
import { ShotsAction, ShotsActionTypes, ShotsState } from './types';

const initialState: ShotsState = {
  shotsList: [],
  shotsTotal: 0,
  isLoading: false,
  isFilteredShotsLoading: false,
  shotsCategory: ShotsCategory.ALL_SHOTS,
  searchFilter: [],
};

export default (
  state = initialState,
  action: ShotsAction | ClearDataAction | ClearMetadataAction | SignOutAction,
): ShotsState => produce(state, (draft) => {
  switch (action.type) {
    case ShotsActionTypes.GET_SHOTS_START:
      draft.isLoading = true;

      return;
    case ShotsActionTypes.GET_SHOTS_SUCCESS:
      draft.shotsList = action.payload.shots;
      draft.shotsTotal = action.payload.total;
      draft.isLoading = false;

      return;
    case ShotsActionTypes.GET_SHOTS_FAIL:
      draft.isLoading = false;

      return;
    case ShotsActionTypes.GET_FILTERED_SHOTS_START:
      draft.isFilteredShotsLoading = true;

      return;
    case ShotsActionTypes.GET_FILTERED_SHOTS_SUCCESS:
      draft.shotsList = action.payload.shots;
      draft.shotsTotal = action.payload.total;
      draft.isLoading = false;
      draft.isFilteredShotsLoading = false;

      return;
    case ShotsActionTypes.GET_FILTERED_SHOTS_FAIL:
      draft.isFilteredShotsLoading = false;

      return;
    case ShotsActionTypes.SET_SHOTS_CATEGORY:
      draft.shotsCategory = action.payload;

      return;
    case ShotsActionTypes.SET_SEARCH_FILTER:
      draft.searchFilter = action.payload;

      return;
    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      return initialState;
    case MetadataActionTypes.CLEAR_METADATA:
      return initialState;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
