import { AnalyzeTranslate } from "interfaces/transcript";

export interface AnalysisState {
    isReAnalysisLoading: boolean;
}

export enum AnalysisActionTypes {
    REANALYZE_SPECIFIC_VIDEO = '@@analysis/REANALYZE_SPECIFIC_VIDEO',
    REANALYZE_VIDEOS = '@@analysis/REANALYZE_VIDEOS',
    REANALYZE_VIDEOS_START = '@@analysis/REANALYZE_VIDEOS_START',
    REANALYZE_VIDEOS_SUCCESS = '@@analysis/REANALYZE_VIDEOS_SUCCESS',
    REANALYZE_VIDEOS_FAIL = '@@analysis/REANALYZE_VIDEOS_FAIL',
    TRANSLATE_MEDIA = '@@analysis/TRANSLATE_MEDIA',
}

export interface AnalyzeDubbing {
    sourceLanguage: string;
    targetLanguages: string;
    voiceId?: string;
    voiceStyle?: string;
    projectId?: string;
    projectVersion?: string;
    creditType?: 'dubbingCredits' | 'credits',
}

export interface ReanalysisReqData {
  categories: Array<string>;    
  collectionId?: string;
  language?: string;
  translate?: AnalyzeTranslate;
  dubbing?: AnalyzeDubbing,
  creditType?: 'dubbingCredits' | 'credits',
}


export interface ReanalyzeSpecificVideoActionPayload {
    videoId: string;
    collectionId: string;
    categories: Array<string>;
    language: string | null;
}

export interface ReanalyzeSpecificVideoAction {
    type: AnalysisActionTypes.REANALYZE_SPECIFIC_VIDEO;
    payload: ReanalyzeSpecificVideoActionPayload;
}

export interface ReanalyzeVideosAction {
    type: AnalysisActionTypes.REANALYZE_VIDEOS;
    payload: {
        collectionId: string;
        categories: Array<string>;
        language: string | null;
    };
}

export interface ReanalyzeVideosStartAction {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_START;
}

export interface ReanalyzeVideosSuccessAction {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_SUCCESS;
}

export interface ReanalyzeVideosFailAction {
    type: AnalysisActionTypes.REANALYZE_VIDEOS_FAIL;
    payload: any;
}

export interface TranslateMediaAction {
    type: AnalysisActionTypes.TRANSLATE_MEDIA;
    payload: ReanalysisReqData;
}

export type AnalysisAction =
    | ReanalyzeSpecificVideoAction
    | ReanalyzeVideosAction
    | ReanalyzeVideosStartAction
    | ReanalyzeVideosSuccessAction
    | ReanalyzeVideosFailAction;
