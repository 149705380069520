import produce from 'immer';
import { colorsOptions } from 'utils/colors';
import { VideosAction, VideosActionTypes } from '../media';

import { CollectionsAction, ExportActionTypes, ExportState } from './types';

const initialState: ExportState = {
  itemsToExport: [],
  exportList: [],
  isExportAsVideoLoading: false,
  exportAsVideoParams: null,

  isSubtitledVideoDataLoading: false,
  subtitledVideoData: null,
  isExportSubtitledVideoLoading: false,

  subtitlesExportFontSize: 18,
  subtitlesExportColor: colorsOptions[0],

  videosUsedForExport: [],

  isBurningSubtitlesCancelLoading: false,
};

export default (
  state = initialState,
  action: 
    | CollectionsAction 
    | VideosAction,
): ExportState => produce(state, (draft) => {
  switch (action.type) {
    case ExportActionTypes.SET_ITEMS_TO_EXPORT:
      draft.itemsToExport = action.payload;

      return;
    case ExportActionTypes.SET_EXPORT_LIST:
      draft.exportList = action.payload;
      draft.exportAsVideoParams = initialState.exportAsVideoParams;

      return;
    case ExportActionTypes.CLEAR_EXPORT_LIST:
      draft.exportList = initialState.exportList;
      draft.exportAsVideoParams = initialState.exportAsVideoParams;
      draft.videosUsedForExport = initialState.videosUsedForExport;

      return;
    case ExportActionTypes.CLEAR_ITEMS_TO_EXPORT_LIST:
      draft.itemsToExport = initialState.itemsToExport;

      return;
    case ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_START:
      draft.isExportAsVideoLoading = true;

      return;
    case ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_FAIL:
      draft.isExportAsVideoLoading = false;

      return;
    case ExportActionTypes.EXPORT_SELECTED_METADATA_AS_VIDEO_SUCCESS:
      draft.isExportAsVideoLoading = false;

      return;
    case ExportActionTypes.SET_EXPORT_AS_VIDEO_PARAMS:
      draft.exportAsVideoParams = action.payload;

      return;
    case ExportActionTypes.SET_SUBTITLED_VIDEO_DATA:
      draft.subtitledVideoData = action.payload;

      return;
    case ExportActionTypes.SET_EXPORT_SUBTITLED_VIDEO_LOADING:
      draft.isExportSubtitledVideoLoading = action.payload;

      return;
    case ExportActionTypes.SET_SUBTITLES_FONT_SIZE:
      draft.subtitlesExportFontSize = action.payload;

      return;
    case ExportActionTypes.SET_SUBTITLES_COLOR:
      draft.subtitlesExportColor = action.payload;

      return;
    case ExportActionTypes.SET_VIDEOS_USED_FOR_EXPORT:
      draft.videosUsedForExport = action.payload;

      return;
    case ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_START:
      draft.isBurningSubtitlesCancelLoading = true;

      return;
    case ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_SUCCESS:
      draft.isBurningSubtitlesCancelLoading = false;

      return;
    case ExportActionTypes.CANCEL_BURNING_SUBTITLES_JOB_FAIL:
      draft.isBurningSubtitlesCancelLoading = false;

      return;
    case VideosActionTypes.CLEAR_VIDEO_DETAILS:
      draft.itemsToExport = initialState.itemsToExport;
      draft.isExportAsVideoLoading = initialState.isExportAsVideoLoading;
      draft.exportAsVideoParams = initialState.exportAsVideoParams;
      draft.isSubtitledVideoDataLoading = initialState.isSubtitledVideoDataLoading;
      draft.subtitledVideoData = initialState.subtitledVideoData;
      draft.isExportSubtitledVideoLoading = initialState.isExportSubtitledVideoLoading;
      draft.subtitlesExportFontSize = initialState.subtitlesExportFontSize;
      draft.subtitlesExportColor = initialState.subtitlesExportColor;
      draft.isBurningSubtitlesCancelLoading = initialState.isBurningSubtitlesCancelLoading;

      return 
    default:
      return state;
  }
});
