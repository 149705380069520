import { AxiosResponse } from 'axios';
import store from 'state/store';
import { build, omit } from 'search-params';

import { ReanalysisReqData } from 'state/modules/analysis';

import $http from './instance';

class AnalysisClient {
    public static async reanalyze(
        videoId: string,
        data: ReanalysisReqData
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            videoId,
        });

        if (!videoId) {
            query = omit(query, ['videoId']).querystring;
        }

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/analyze?${query}`,
            data
        );
    }
}

export default AnalysisClient;
