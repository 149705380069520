import { IconProps } from '../index';

const TemplatesIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 7C2.5 4.51472 4.51472 2.5 7 2.5H17C19.4853 2.5 21.5 4.51472 21.5 7V17C21.5 19.4853 19.4853 21.5 17 21.5H7C4.51472 21.5 2.5 19.4853 2.5 17V7ZM7 3.5C5.067 3.5 3.5 5.067 3.5 7V17C3.5 18.933 5.067 20.5 7 20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5H7Z"
                fill={color}
            />
            <path d="M6 6H18"
                  stroke={color}
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5 15.4486C18.5 14.6202 17.8284 13.9486 17 13.9486L14 13.9486C13.1716 13.9486 12.5 14.6202 12.5 15.4486L12.5 16.8898C12.5 17.7182 13.1716 18.3898 14 18.3898L17 18.3898C17.8284 18.3898 18.5 17.7182 18.5 16.8898L18.5 15.4486ZM17 14.9486C17.2761 14.9486 17.5 15.1724 17.5 15.4486L17.5 16.8898C17.5 17.1659 17.2761 17.3898 17 17.3898L14 17.3898C13.7239 17.3898 13.5 17.1659 13.5 16.8898L13.5 15.4486C13.5 15.1724 13.7239 14.9486 14 14.9486L17 14.9486ZM11.5 10C11.5 9.17157 10.8284 8.5 10 8.5L7 8.5C6.17157 8.5 5.5 9.17157 5.5 10L5.5 17C5.5 17.8284 6.17157 18.5 7 18.5L10 18.5C10.8284 18.5 11.5 17.8284 11.5 17L11.5 10ZM10 9.5C10.2761 9.5 10.5 9.72386 10.5 10L10.5 17C10.5 17.2761 10.2761 17.5 10 17.5L7 17.5C6.72386 17.5 6.5 17.2761 6.5 17L6.5 10C6.5 9.72386 6.72386 9.5 7 9.5L10 9.5Z"
                fill={color}
            />
            <path
                d="M18 11.4412L18 10C18 9.44772 17.5523 9 17 9L14 9C13.4477 9 13 9.44771 13 10L13 11.4412C13 11.9935 13.4477 12.4412 14 12.4412L17 12.4412C17.5523 12.4412 18 11.9935 18 11.4412Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TemplatesIconNew;


