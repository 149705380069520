import { IconProps } from './index';


const MagicIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14.5 36L17.187 42.4544L24 45L17.187 47.5456L14.5 54L11.813 47.5456L5 45L11.813 42.4544L14.5 36Z"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinejoin="round"
            />
            <path
                d="M51.5 12L53.3385 16.6615L58 18.5L53.3385 20.3385L51.5 25L49.6615 20.3385L45 18.5L49.6615 16.6615L51.5 12Z"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <rect x="30.1001" y="23.334" width="54.4879" height="6.27083" transform="rotate(45 30.1001 23.334)"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinejoin="round"
            />
            <line x1="40.3936" y1="34.4923" x2="36.2131" y2="38.6728" stroke={color} strokeWidth="2" />
        </svg>
    );
};

export default MagicIcon;