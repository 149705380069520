import axios, { AxiosResponse } from 'axios';
import { GrantType } from 'interfaces/auth';
import $http from './instance';

class AuthClient {
    public static async signIn({
        email,
        password,
        name,
        application,
        invitationCode,
    }: {
        email: string;
        password: string;
        name: string;
        application?: string;
        invitationCode?: string | null;
    }): Promise<AxiosResponse> {
        const data = {
            grant_type: GrantType.AUTH_CODE,
            email,
            name,
            password,
        } as {
            grant_type: GrantType;
            email: string;
            password: string;
            name: string;
            application?: string;
            invitationCode?: string;
        };

        if (application) {
            data.application = application;
        }

        if (invitationCode) {
            data.invitationCode = invitationCode;
        }

        return axios.post(
            `${window.config.REACT_APP_SHORT_API_URL}/oauth2/token`,
            data
        );
    }

    public static async registerCheck(email: string): Promise<AxiosResponse> {
        return $http.post(
            `${window.config.REACT_APP_SHORT_API_URL}/oauth2/token`,
            {
                email,
                grant_type: GrantType.REGISTER_CHECK,
            }
        );
    }

    public static async confirmAppSumoUser(
        email: string,
        id: string
    ): Promise<AxiosResponse> {
        return $http.put(`/user/${id}/appsumo`, {
            userEmail: email,
        });
    }

    public static async confirmGoogleFederatedSignIn(
        email: string,
        id: string,
        userNameGoogle: string,
        invitationCode?: string | null
    ): Promise<AxiosResponse> {
        let data = {
            grant_type: GrantType.GOOGLE,
            email,
            id,
            userNameGoogle,
        } as {
            grant_type: GrantType;
            email: string;
            id: string;
            userNameGoogle: string;
            invitationCode?: string;
        };

        if (invitationCode) {
            data.invitationCode = invitationCode;
        }

        return axios.post(
            `${window.config.REACT_APP_SHORT_API_URL}/oauth2/token`,
            data
        );
    }

    public static async resendConfirmationEmail(
        name: string
    ): Promise<AxiosResponse> {
        return axios.post(
            `${window.config.REACT_APP_SHORT_API_URL}/oauth2/token`,
            {
                grant_type: GrantType.RESEND_CODE,
                name,
            }
        );
    }
}

export default AuthClient;
