import {
  put, call, takeLatest, delay, select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import without from 'lodash.without';

import TemplateClient from 'services/api/templates';

import {
  TemplatesActionTypes,
  getSelectedTemplates,
  getTemplatesIdsForDelete,
  TemplateBody,
} from 'state/modules/templates';
import * as templatesActions from 'state/modules/templates/actions';

import { transformReceivedTemplateData } from 'utils/templateCreationTool';
import {
  DeleteTemplateAction,
  GetTemplateByIdAction,
  SelectTemplateAction,
  UnselectTemplateAction,
} from './types';

export function* handleGetTemplates(): Generator {
  yield put(templatesActions.getTemplatesStart());

  try {
    const res = (yield call(TemplateClient.getTemplates)) as AxiosResponse;

    let templates = res.data.content;
    const total = res.data._metadata.totalCount;

    templates = templates.map((item: TemplateBody) => ({
      ...item,
      templateData: item.templateData
        ? transformReceivedTemplateData(item.templateData)
        : null,
    }));

    yield put(templatesActions.getTemplatesSuccess(templates, total));
  } catch (error) {
    console.log({ error });
    yield put(templatesActions.getTemplatesFail(error));
  }
}

export function* handleGetTemplateDetails(
  action: GetTemplateByIdAction,
): Generator {
  yield put(templatesActions.getTemplateDetailsStart());

  try {
    const res = (yield call(
      TemplateClient.getTemplateDetails,
      action.payload,
    )) as AxiosResponse;

    let templateDetails = res.data.content;

    if (templateDetails.templateData) {
      templateDetails = {
        ...templateDetails,
        templateData: transformReceivedTemplateData(
          templateDetails.templateData,
        ),
      };
    }

    yield put(templatesActions.getTemplateDetailsSuccess(templateDetails));
  } catch (error) {
    console.log({ error });
    yield put(templatesActions.getTemplateDetailsFail(error));
  }
}

function* updateTemplatesIdsForDelete(action: string, ids: Array<string>): Generator {
  const templatesIdsForDelete = (yield select(
    getTemplatesIdsForDelete,
  )) as Array<string>;

  if (action === 'add') {
    yield put(
      templatesActions.setTemplatesIdsForDelete([
        ...templatesIdsForDelete,
        ...ids,
      ]),
    );
  } else if (action === 'delete') {
    const selectedTemplates = (yield select(
      getSelectedTemplates,
    )) as Array<string>;

    const filteredTemplatesIdsForDelete = without(
      templatesIdsForDelete,
      ...ids,
    );
    const filteredSectedTemplates = without(selectedTemplates, ...ids);

    yield put(
      templatesActions.setTemplatesIdsForDelete(
        filteredTemplatesIdsForDelete,
      ),
    );
    yield put(templatesActions.setSelectTemplates(filteredSectedTemplates));
  }
}

export function* handleDeleteTemplate(action: DeleteTemplateAction): Generator {
  yield put(templatesActions.deleteTemplateStart());

  try {
    const templateId = action.payload;

    yield call(updateTemplatesIdsForDelete, 'add', [templateId]);

    const res = (yield call(
      TemplateClient.deleteTemplate,
      templateId,
    )) as AxiosResponse;

    if (res.status === 201 || res.status === 200) {
      yield put(templatesActions.deleteTemplateSuccess());

      yield delay(1000);

      yield put(templatesActions.getTemplates());

      yield call(updateTemplatesIdsForDelete, 'delete', [templateId]);
      yield put(templatesActions.unselectTemplate(templateId));
    }
  } catch (error) {
    console.log({ error });
    yield put(templatesActions.deleteTemplateFail(error));
  }
}

export function* handleDeleteTemplates(): Generator {
  yield put(templatesActions.deleteTemplateStart());

  const selectedTemplates = (yield select(
    getSelectedTemplates,
  )) as Array<string>;
  let templatesIdsString = '';

  selectedTemplates.forEach((item: string) => {
    if (!templatesIdsString.length) {
      templatesIdsString = `${item}`;
    } else {
      templatesIdsString = `${templatesIdsString},${item}`;
    }
  });

  try {
    yield call(updateTemplatesIdsForDelete, 'add', selectedTemplates);

    const res = (yield call(
      TemplateClient.deleteTemplate,
      templatesIdsString,
    )) as AxiosResponse;

    if (res.status === 201 || res.status === 200) {
      yield put(templatesActions.deleteTemplateSuccess());

      yield delay(1000);

      yield put(templatesActions.getTemplates());

      yield call(
        updateTemplatesIdsForDelete,
        'delete',
        selectedTemplates,
      );
    }
  } catch (error) {
    console.log({ error });
    yield put(templatesActions.deleteTemplateFail(error));
  }
}

export function* handleSelectTemplate(action: SelectTemplateAction): Generator {
  const templateId = action.payload;
  const templates = (yield select(getSelectedTemplates)) as Array<string>;

  const updatedSelectedTemplates = [...templates, templateId];

  yield put(templatesActions.setSelectTemplates(updatedSelectedTemplates));
}

export function* handleUnselectTemplate(
  action: UnselectTemplateAction,
): Generator {
  const templateId = action.payload;
  const templates = (yield select(getSelectedTemplates)) as Array<string>;

  const filteredTemplates = templates.filter(
    (item: string) => item !== templateId,
  );

  yield put(templatesActions.setSelectTemplates(filteredTemplates));
}

export function* templatesSaga(): Generator {
  yield takeLatest(TemplatesActionTypes.GET_TEMPLATES, handleGetTemplates);
  yield takeLatest(
    TemplatesActionTypes.GET_TEMPLATE_DETAILS,
    handleGetTemplateDetails,
  );
  yield takeLatest(
    TemplatesActionTypes.DELETE_TEMPLATE,
    handleDeleteTemplate,
  );
  yield takeLatest(
    TemplatesActionTypes.DELETE_TEMPLATES,
    handleDeleteTemplates,
  );
  yield takeLatest(
    TemplatesActionTypes.SELECT_TEMPLATE,
    handleSelectTemplate,
  );
  yield takeLatest(
    TemplatesActionTypes.UNSELECT_TEMPLATE,
    handleUnselectTemplate,
  );
}
