import { AxiosResponse } from 'axios';

import store from 'state/store';

import {
    ClipCustomGenData,
    ClipRenderData,
    ClipTemplateGenData,
} from 'state/modules/generation';

import $http from './instance';

class TrailersClient {
    public static async getTrailers(videoId: string): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/trailers?sortBy=-createdDate`
        );
    }

    public static async generate(
        data: ClipCustomGenData | ClipTemplateGenData
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/${data.videoId}/trailers`,
            data
        );
    }

    public static async downloadVideo(videoId: string): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/download`
        );
    }

    public static async downloadTrailer(
        videoId: string,
        trailerId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/trailers/${trailerId}/download`
        );
    }

    public static async deleteTrailer(
        videoId: string,
        trailerId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/trailers/${trailerId}`
        );
    }

    public static async getClipPreviews(
        videoId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/previews`
        );
    }

    public static async renderClipByPreview(
        videoId: string,
        previewId: string,
        data: ClipRenderData
    ): Promise<AxiosResponse> {
        const { user, workspaces } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/previews/${previewId}/render`,
            data
        );
    }
}

export default TrailersClient;
