/* eslint-disable react/no-invalid-html-attribute */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import { Icon } from 'components/UI';

import { getUser } from 'state/modules/user';

import novaLogoHorizontal from 'assets/images/novaLogoHorizontal.png';

import {Helmet} from "react-helmet";
import styles from './styles.module.scss';

const Player = styled.video`
    border-radius: 12px;
    max-height: 80%;
    max-width: 80%;
    height: auto;
    width: auto;
    object-fit: contain;
`;

const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    max-height: 100%;
    position: relative;
    width: 100%;
    height: calc(100vh - 120px);
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 30px;
`;

const TopLine = styled.div`
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
`;

const Logo = styled.img`
    width: 148px;
    height: auto;
    display: block;
    cursor: pointer;
`;

const ProjectSharing = (): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams({});

    // const user = useSelector(getUserInfo);

    const link = searchParams.get('link') as string;
    const projectName = searchParams.get('projectName') as string;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const s3Path = useMemo(() => {
        const parts = link.split('/private');

        return `private${parts[1]}`;
    }, [link]);

    const thumbLink = useMemo(() => {
        return `${window.config.REACT_APP_CLOUDFRONT_URL}Stage/thumbnail?fileKey=${s3Path}&timestamp=3000&width=600`;
    }, [s3Path]);

    const handleDownload = () => {
        try {
            const downloadLink = document.createElement('a');

            downloadLink.download = projectName;
            downloadLink.target = '_blank';
            const href = link;
            downloadLink.href = href;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        } catch (error) {
            console.log({ error });
        }
    };

    const handleGoHome = () => {
        navigate(`/`);
    };

    const renderMeta = () => {
        return (
            <Helmet>
                <title>{projectName}</title>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={projectName} />
                <meta property="og:image" content={thumbLink} />
                <meta property="og:image:secure_url" content={thumbLink} />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:description" content={projectName} />
                <meta property="og:site_name" content="NOVA" />
                <meta property="og:video" content={link} />
                <meta property="og:video:type" content="text/html" />
                <meta property="og:video:url" content={link} />
                <meta property="og:video:secure_url" content={link} />
                <meta property="og:type" content="website" />
                <link
                    href={link}
                    media="(aspect-ratio: 1280:720)"
                    rel="iframely player"
                    title={projectName}
                    type="text/html"
                />
            </Helmet>
        );
    };

    const renderDownloadButton = () => (
        <button
            className={styles.ProjectGenerationResult__downloadButton}
            onClick={handleDownload}
        >
            <Icon name="download" size={16} />
            <p className={styles.ProjectGenerationResult__downloadButtonTitle}>
                Download
            </p>
        </button>
    );

    const renderProjectName = () => (
        <p className={styles.ProjectGenerationResult__projectName}>
            {projectName}
        </p>
    );

    const renderEditButton = () => (
        <button
            className={styles.ProjectGenerationResult__editButton}
            // onClick={handleEditProject}
        >
            <p className={styles.ProjectGenerationResult__editButtonTitle}>
                {/* Edit Video */}
            </p>
            {/* <Icon name="cut" size={14} /> */}
        </button>
    );

    const renderTopLine = () => (
        <TopLine>
            <Logo onClick={handleGoHome} src={novaLogoHorizontal} alt="Logo" />
            {renderProjectName()}
            {renderEditButton()}
        </TopLine>
    );

    const renderPlayer = () => {
        return <Player src={link || ''} autoPlay={false} controls />;
    };

    return (
        <div className={styles.ProjectGenerationResult}>
            {renderMeta()}
            {renderTopLine()}
            <PlayerContainer>{renderPlayer()}</PlayerContainer>
            <Footer>{renderDownloadButton()}</Footer>
        </div>
    );
};

export default ProjectSharing;
