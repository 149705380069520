import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 0.235;

const OptionsIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 17;
    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 4 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Rev-4"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Nova-Enterprise---Home-Page-v2-Search-1"
                    transform="translate(-1387.000000, -734.000000)"
                    fill={color}
                >
                    <g id="Table" transform="translate(101.000000, 634.000000)">
                        <path
                            d="M1288,112.5 C1286.896,112.5 1286,113.396 1286,114.5 C1286,115.604 1286.896,116.5 1288,116.5 C1289.104,116.5 1290,115.604 1290,114.5 C1290,113.396 1289.104,112.5 1288,112.5 M1288,106.5 C1286.896,106.5 1286,107.396 1286,108.5 C1286,109.604 1286.896,110.5 1288,110.5 C1289.104,110.5 1290,109.604 1290,108.5 C1290,107.396 1289.104,106.5 1288,106.5 M1290,102.5 C1290,103.604 1289.104,104.5 1288,104.5 C1286.896,104.5 1286,103.604 1286,102.5 C1286,101.396 1286.896,100.5 1288,100.5 C1289.104,100.5 1290,101.396 1290,102.5"
                            id="more_vt"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default OptionsIcon;
