import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';

import WorkspacesClient from 'services/api/workspaces';

import * as workspacesActions from 'state/modules/workspaces/actions';
import * as userActions from 'state/modules/user/actions';
import * as modalActions from 'state/modules/modal/actions';

import {
    InviteToWorkspace,
    InviteToWorkspaceStatuses,
    Workspace,
} from 'interfaces/workspaces';
import { UserPreferences } from 'interfaces/preferences';
import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import { NotificationTypes, showNotification } from 'utils/notifications';
import {
    AcceptInviteAndChangeWorkspaceAction,
    ChangeWorkspaceAction,
    CreateWorkspaceAction,
    DeleteInviteAction,
    DeleteWorkspaceAction,
    LeaveWorkspaceAction,
    PinInviteWithEmailAction,
    SendWorkspaceInviteAction,
    UpdateInviteeWorkspaceInviteStatusAction,
    UpdateInviterWorkspaceInviteStatusAction,
    UpdateWorkspaceAction,
    WorkspaceActionTypes,
} from './types';
import {
    getCurrentWorkspaceInfo,
    getInviteeInvitesList,
    getInviteeWorkspacesList,
    getWorkspacesList,
} from './selectors';
import {
    User,
    UserActionTypes,
    getPreferences,
    getUserId,
    getUserInfo,
} from '../user';
import { handlePaymentFlowInit } from '../payment';

// workspaces
function* handleGetWorkspaces(): Generator {
    try {
        yield put(workspacesActions.getWorkspacesStart());

        const res = (yield call(
            WorkspacesClient.getWorkspaces
        )) as AxiosResponse;

        const workspaces = res.data.content;

        yield put(workspacesActions.setWorkspaces(workspaces));
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.getWorkspacesFail(error));
    }
}

// invitee workspaces

export function* handleGetInviteeWorkspaces(): Generator {
    try {
        yield put(workspacesActions.getInviteeWorkspacesStart());

        const res = (yield call(
            WorkspacesClient.getInviteeWorkspaces
        )) as AxiosResponse;

        const workspaces = res.data.content;

        yield put(workspacesActions.setInviteeWorkspaces(workspaces));
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.getInviteeWorkspacesFail(error));
    }
}

// current workspace

export function* handleGetCurrentWorkspace(): Generator {
    try {
        let workspace;

        const userPreferences = (yield select(
            getPreferences
        )) as UserPreferences;

        if (userPreferences?.selectedWorkspaceId) {
            const id = userPreferences.selectedWorkspaceId;
            const res = (yield call(
                WorkspacesClient.getWorkspaces
            )) as AxiosResponse;

            const workspaces = res.data.content as Workspace[];

            workspace = workspaces.find((item) => item.id === id);
        } else {
            const res = (yield call(
                WorkspacesClient.getCurrentWorkspace
            )) as AxiosResponse;

            workspace = res.data.content[0];
        }

        if (workspace) {
            yield put(workspacesActions.setCurrentWorkspace(workspace));
        } else {
            yield call(Auth.signOut);
            yield put(userActions.signOut());
        }
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.getCurrentWorkspaceFail(error));

        if (error?.response?.status === 404) {
            yield call(Auth.signOut);
            yield put(userActions.signOut());
        }

        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }
}

// update
export function* handleUpdateWorkspace(
    action: UpdateWorkspaceAction
): Generator {
    try {
        yield put(workspacesActions.updateWorkspaceStart());

        const { data, id } = action.payload;

        const res = (yield call(
            WorkspacesClient.updateWorkspace,
            id,
            data
        )) as AxiosResponse;

        yield put(workspacesActions.updateWorkspaceSuccess());

        yield delay(1000);

        yield put(workspacesActions.getCurrentWorkspace());
        yield put(workspacesActions.getWorkspaces());
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.updateWorkspaceFail(error));
    }
}

// send invite
export function* handleSendWorkspaceInvite(
    action: SendWorkspaceInviteAction
): Generator {
    try {
        yield put(workspacesActions.sendWorkspaceInviteStart());

        const { inviteeEmail } = action.payload;

        const currentWorkspace = (yield select(
            getCurrentWorkspaceInfo
        )) as Workspace;

        const user = (yield select(getUserInfo)) as User;

        const res = yield call(
            WorkspacesClient.sendWorkspaceInvite,
            currentWorkspace.id,
            inviteeEmail,
            user.attributes.email
        );

        googleAnalytics.inviteFriend();
        amplitudeAnalytics.collaboratorInvited();

        yield put(workspacesActions.sendWorkspaceInviteSuccess());

        yield delay(1500);

        yield put(workspacesActions.getInviterInvites());
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.sendWorkspaceInviteFail(error));
    }
}

// get invitee invites
export function* handleGetInviteeInvites(): Generator {
    try {
        yield put(workspacesActions.getInviteeInvitesStart());

        const res = (yield call(
            WorkspacesClient.getInviteeInvitesList
        )) as AxiosResponse;

        yield put(
            workspacesActions.getInviteeInvitesSuccess({
                invites: res.data.content,
                total: res.data._metadata.totalCount,
            })
        );

        yield put(
            workspacesActions.getInviteeInvitesSuccess({
                invites: res.data.content,
                total: res.data._metadata.totalCount,
            })
        );
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.getInviteeInvitesFail(error));
    }
}

// get inviter invites

export function* handleGetInviterInvites(): Generator {
    try {
        yield put(workspacesActions.getInviterInvitesStart());

        const res = (yield call(
            WorkspacesClient.getInviterInvitesList
        )) as AxiosResponse;

        yield put(
            workspacesActions.getInviterInvitesSuccess({
                invites: res.data.content,
                total: res.data._metadata.totalCount,
            })
        );
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.getInviterInvitesFail(error));
    }
}

// Pin invite with email

export function* handlePinInviteWithEmail(
    action: PinInviteWithEmailAction
): Generator {
    try {
        yield put(workspacesActions.pinInviteWithEmailStart());

        const { inviteeEmail } = action.payload;

        const res = yield call(
            WorkspacesClient.pinInviteWithEmail,
            inviteeEmail
        );

        yield put(workspacesActions.pinInviteWithEmailSuccess());
    } catch (error) {
        console.log({ error });

        yield put(workspacesActions.getInviteeInvitesFail(error));
    }
}

// Update invitee invite status

export function* handleUpdateInviteeWorkspaceInviteStatus(
    action: UpdateInviteeWorkspaceInviteStatusAction
): Generator {
    try {
        yield put(workspacesActions.updateInviteeWorkspaceInviteStatusStart());

        const { inviteId, status } = action.payload;

        const res = yield call(
            WorkspacesClient.updateInviteeWorkspaceInviteStatus,
            inviteId,
            status
        );

        yield put(
            workspacesActions.updateInviteeWorkspaceInviteStatusSuccess()
        );
    } catch (error) {
        console.log({ error });
        yield put(
            workspacesActions.updateInviteeWorkspaceInviteStatusFail(error)
        );
    }
}

// Update inviter invite status

export function* handleUpdateInviterWorkspaceInviteStatus(
    action: UpdateInviterWorkspaceInviteStatusAction
): Generator {
    try {
        yield put(workspacesActions.updateInviterWorkspaceInviteStatusStart());

        const { inviteId, workspaceId, status } = action.payload;

        yield call(
            WorkspacesClient.updateWorkspaceInviteStatus,
            inviteId,
            workspaceId,
            status
        );

        yield put(
            workspacesActions.updateInviterWorkspaceInviteStatusSuccess()
        );
    } catch (error) {
        console.log({ error });
        yield put(
            workspacesActions.updateInviterWorkspaceInviteStatusFail(error)
        );
    }
}

export function* handleAcceptInvite(inviteId: string): Generator {
    try {
        yield put(workspacesActions.getInviteeInvites());

        yield take(WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_SUCCESS);

        const invitesList = (yield select(
            getInviteeInvitesList
        )) as InviteToWorkspace[];

        const selectedInvite = invitesList.find((item) => item.id === inviteId);

        if (
            selectedInvite &&
            selectedInvite.status === InviteToWorkspaceStatuses.PENDING
        ) {
            yield put(
                workspacesActions.updateInviteeWorkspaceInviteStatus({
                    status: InviteToWorkspaceStatuses.ACCEPTED,
                    inviteId,
                })
            );

            localStorage.removeItem('workspaceInviteId');

            yield take(
                WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS
            );

            yield delay(1000);

            yield put(workspacesActions.getWorkspaces());
        }
    } catch (error) {
        console.log({ error });

        localStorage.removeItem('workspaceInviteId');
    }
}

export function* handleInitInvitesFlow(): Generator {
    const currentUserInfo: any = yield select(getUserInfo);

    yield put(
        workspacesActions.pinInviteWithEmail({
            inviteeEmail: currentUserInfo.attributes.email,
        })
    );

    yield take(
        WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_SUCCESS ||
            WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_FAIL
    );

    yield call(handleGetInviteeInvites);
    // yield call(handleGetInviterInvites);

    const workspaceInviteId = localStorage.getItem('workspaceInviteId');

    if (workspaceInviteId) {
        yield call(handleAcceptInvite, workspaceInviteId);
    }
}

export function* handleChangeWorkspace(
    action: ChangeWorkspaceAction
): Generator {
    yield put(workspacesActions.setWorkspaceFlowLoading(true));
    const { workspace, navigate } = action.payload;

    const userPreferences = (yield select(getPreferences)) as UserPreferences;

    yield put(workspacesActions.setCurrentWorkspace(workspace));

    yield put(
        userActions.updateUserPreferences({
            ...userPreferences,
            selectedWorkspaceId: workspace.id,
        })
    );

    yield call(handlePaymentFlowInit);

    if (navigate) {
        navigate('/video-editing');
    }

    yield put(workspacesActions.setWorkspaceFlowLoading(false));
}

export function* handleInitWorkspaceFlow(): Generator {
    try {
        yield put(workspacesActions.setWorkspaceFlowLoading(true));
        yield call(handleGetWorkspaces);
        yield call(handleGetInviteeWorkspaces);
        const workspaces = (yield select(getWorkspacesList)) as Workspace[];
        const inviteeWorkspaces = (yield select(
            getInviteeWorkspacesList
        )) as Workspace[];

        const userPreferences = (yield select(
            getPreferences
        )) as UserPreferences;

        const selectedWorkspaceId = userPreferences.selectedWorkspaceId;

        const combinedWorkspaces = [...workspaces, ...inviteeWorkspaces];

        let currentWorkspace = combinedWorkspaces[0];

        if (selectedWorkspaceId) {
            const selectedWorkspace = combinedWorkspaces.find(
                (item) => item.id === selectedWorkspaceId
            );

            if (selectedWorkspace) {
                currentWorkspace = selectedWorkspace;
            }
        }

        yield put(workspacesActions.setCurrentWorkspace(currentWorkspace));
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    } catch (error) {
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    }
}

// Delete invite

export function* handleDeleteInvite(action: DeleteInviteAction): Generator {
    const { inviteId, workspaceId } = action.payload;

    yield put(workspacesActions.deleteInviteStart());

    try {
        yield put(
            workspacesActions.updateInviterWorkspaceInviteStatus({
                status: InviteToWorkspaceStatuses.REJECTED,
                inviteId,
                workspaceId,
            })
        );

        yield take(
            WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_SUCCESS ||
                WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_FAIL
        );

        yield delay(1500);

        yield put(workspacesActions.getInviterInvites());
        yield put(workspacesActions.deleteInviteSuccess());
    } catch (error) {
        yield put(workspacesActions.deleteInviteFail(error));
    }
}

// Create workspace

export function* handleCreateWorkspace(
    action: CreateWorkspaceAction
): Generator {
    const userId = (yield select(getUserId)) as string;

    const { name, navigate } = action.payload;

    yield put(workspacesActions.createWorkspaceStart());

    try {
        const res = (yield call(WorkspacesClient.createWorkspace, {
            name,
            userId,
        } as any)) as AxiosResponse;

        const newWorkspace = res.data.newWorkspace;

        yield delay(1500);

        yield put(
            workspacesActions.changeWorkspace({
                workspace: newWorkspace,
                navigate,
            })
        );

        yield put(workspacesActions.createWorkspaceSuccess());

        yield put(modalActions.hideModal());

        yield take(UserActionTypes.SET_USER_PREFERENCES);

        yield put(workspacesActions.getWorkspaces());
        yield put(workspacesActions.getCurrentWorkspace());
    } catch (error) {
        yield put(workspacesActions.createWorkspaceFail(error));
    }
}

//

export function* handleAcceptInviteAndChangeWorkspace(
    action: AcceptInviteAndChangeWorkspaceAction
): Generator {
    try {
        yield put(workspacesActions.setWorkspaceFlowLoading(true));

        const { workspaceId, inviteId, navigate } = action.payload;

        yield put(
            workspacesActions.updateInviteeWorkspaceInviteStatus({
                status: InviteToWorkspaceStatuses.ACCEPTED,
                inviteId,
            })
        );

        yield take(WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS);

        yield delay(1500);
        yield call(handleGetInviteeWorkspaces);

        const inviteeWorkspaces = (yield select(
            getInviteeWorkspacesList
        )) as Workspace[];

        const selectedWorkspace = inviteeWorkspaces.find(
            (item) => item.id === workspaceId
        );

        if (selectedWorkspace) {
            yield put(
                workspacesActions.changeWorkspace({
                    navigate,
                    workspace: selectedWorkspace,
                })
            );
        }

        yield put(workspacesActions.getInviteeInvites());
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    }
}

// Leave

export function* handleLeaveWorkspace(action: LeaveWorkspaceAction): Generator {
    try {
        yield put(workspacesActions.setWorkspaceFlowLoading(true));
        const { workspaceId, navigate } = action.payload;

        const invitesList = (yield select(
            getInviteeInvitesList
        )) as InviteToWorkspace[];

        const selectedInvite = invitesList.find(
            (item) =>
                item.workspaceId === workspaceId &&
                item.status === InviteToWorkspaceStatuses.ACCEPTED
        );

        if (selectedInvite) {
            yield put(
                workspacesActions.updateInviteeWorkspaceInviteStatus({
                    status: InviteToWorkspaceStatuses.REJECTED,
                    inviteId: selectedInvite.id,
                })
            );

            yield take(
                WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS
            );

            const workspaces = (yield select(getWorkspacesList)) as Workspace[];

            const selectedWorkspace = workspaces[0];

            if (selectedWorkspace) {
                yield put(
                    workspacesActions.changeWorkspace({
                        navigate,
                        workspace: selectedWorkspace,
                    })
                );
            }

            yield delay(1500);

            yield put(workspacesActions.getInviteeInvites());
            yield put(workspacesActions.getInviteeWorkspaces());
        }

        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    }
}

// Delete

export function* handleDeleteWorkspace(
    action: DeleteWorkspaceAction
): Generator {
    try {
        yield put(workspacesActions.setWorkspaceFlowLoading(true));
        const { workspaceId, navigate } = action.payload;

      try {
        yield call(WorkspacesClient.deleteWorkspace, workspaceId);
      } catch (error) {
        yield call(
          showNotification,
          NotificationTypes.error,
          (error as any)?.response?.data?.message,
        );
      }

        const workspaces = (yield select(getWorkspacesList)) as Workspace[];

        const filteredWorkspaces = workspaces.filter(
            (item) => item.id !== workspaceId
        );

        const selectedWorkspace = filteredWorkspaces[0];

        if (selectedWorkspace) {
            yield put(
                workspacesActions.changeWorkspace({
                    navigate,
                    workspace: selectedWorkspace,
                })
            );

            yield take(UserActionTypes.SET_USER_PREFERENCES);
        }

        // yield delay(1500);

        yield put(workspacesActions.getWorkspaces());
        yield put(workspacesActions.getCurrentWorkspace());
        yield put(workspacesActions.getInviteeInvites());
        yield put(workspacesActions.getInviteeWorkspaces());

        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    } catch (error) {
        console.log({ error });
        yield put(workspacesActions.setWorkspaceFlowLoading(false));
    }
}

export function* workspacesSaga(): Generator {
    // workspaces
    yield takeLatest(WorkspaceActionTypes.GET_WORKSPACES, handleGetWorkspaces);
    // invitee workspaces
    yield takeLatest(
        WorkspaceActionTypes.GET_INVITEE_WORKSPACES,
        handleGetInviteeWorkspaces
    );
    // current workspace
    yield takeLatest(
        WorkspaceActionTypes.GET_CURRENT_WORKSPACE,
        handleGetCurrentWorkspace
    );
    yield takeLatest(
        WorkspaceActionTypes.UPDATE_WORKSPACE,
        handleUpdateWorkspace
    );
    // send invite
    yield takeLatest(
        WorkspaceActionTypes.SEND_WORKSPACE_INVITE,
        handleSendWorkspaceInvite
    );
    // get invitee invites
    yield takeLatest(
        WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES,
        handleGetInviteeInvites
    );
    // get inviter invites
    yield takeLatest(
        WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES,
        handleGetInviterInvites
    );
    // Pin invite with email
    yield takeLatest(
        WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL,
        handlePinInviteWithEmail
    );
    // Update invitee invite status
    yield takeLatest(
        WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS,
        handleUpdateInviteeWorkspaceInviteStatus
    );
    // Update inviter invite status
    yield takeLatest(
        WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS,
        handleUpdateInviterWorkspaceInviteStatus
    );
    // Change workspace
    yield takeLatest(
        WorkspaceActionTypes.CHANGE_WORKSPACE,
        handleChangeWorkspace
    );
    // Delete invite
    yield takeLatest(WorkspaceActionTypes.DELETE_INVITE, handleDeleteInvite);
    // Accept invite and change workspace
    yield takeLatest(
        WorkspaceActionTypes.ACCEPT_INVITE_AND_CHANGE_WORKSPACE,
        handleAcceptInviteAndChangeWorkspace
    );
    // Leave workspace
    yield takeLatest(
        WorkspaceActionTypes.LEAVE_WORKSPACE,
        handleLeaveWorkspace
    );
    // Delete workspace
    yield takeLatest(
        WorkspaceActionTypes.DELETE_WORKSPACE,
        handleDeleteWorkspace
    );
    // Create workspace
    yield takeLatest(
        WorkspaceActionTypes.CREATE_WORKSPACE,
        handleCreateWorkspace
    );
}
