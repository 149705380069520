import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 21 / 20;

const ArrowRight = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.5594 8.94072C21.1453 9.52666 21.1453 10.4782 20.5594 11.0642L13.0594 18.5642C12.4734 19.1501 11.5219 19.1501 10.9359 18.5642C10.35 17.9782 10.35 17.0267 10.9359 16.4407L15.8813 11.5001H1.50001C0.670324 11.5001 1.14441e-05 10.8298 1.14441e-05 10.0001C1.14441e-05 9.17041 0.670324 8.5001 1.50001 8.5001H15.8766L10.9406 3.55947C10.3547 2.97353 10.3547 2.02197 10.9406 1.43604C11.5266 0.850098 12.4781 0.850098 13.0641 1.43604L20.5641 8.93604L20.5594 8.94072Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowRight;
