import { ChangeEvent, useEffect, useState } from 'react';
import { Analytics, Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { FormContainer, NewButton } from 'components/UI';

import { validate } from 'utils';
import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';

import { ModalEvent, ModalType } from 'state/modules/modal/types';
import { showModal } from 'state/modules/modal/actions';

import { googleAnalytics } from 'services/api/googleAnalytics';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import AuthClient from 'services/api/auth';

import StepOne from './StepOne';

import styles from './styles.module.scss';

const CustomSignUp = (): JSX.Element => {
    const [isComponetMounted, setIsComponetMounted] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsComponetMounted(true);
        return () => {
            setIsComponetMounted(false);
        };
    }, []);

    const signUp = async () => {
        const passwordValidationErrors = validate(
            password,
            [ValidatorType.Required, ValidatorType.Password],
            ValidationType.OnSubmit
        );

        const passwordError = passwordValidationErrors[0] || '';

        const emailValidationErrors = validate(
            email,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        if (emailValidationErrors[0] || passwordError) {
            setEmailError(emailValidationErrors[0] || '');
            setPasswordError(passwordError);
        } else {
            try {
                setLoading(true);

                const checkRes = await AuthClient.registerCheck(email);

                if (checkRes?.data?.code === 400) {
                    setLoading(false);

                    return showNotification(
                        NotificationTypes.error,
                        checkRes?.data?.message || ''
                    );
                }

                await Auth.signUp({
                    attributes: {
                        email,
                    },
                    username: email.replace('@', '.'),
                    password,
                });

                googleAnalytics.signUp({
                    method: 'email',
                });

                amplitudeAnalytics.signUp({
                    login_method: 'email',
                });

                Analytics.record({
                    name: 'sign_up',
                    attributes: {
                        email,
                    },
                });

                dispatch(
                    showModal(ModalType.MESSAGE, ModalEvent.SIGN_UP, email)
                );

                localStorage.setItem(
                    'isUserWithoutProjectUploadedVideo',
                    JSON.stringify(true)
                );

                navigate('/');
            } catch (error) {
                showNotification(
                    NotificationTypes.error,
                    (error as any).message
                );
            } finally {
                if (isComponetMounted) {
                    setLoading(false);
                }
            }
        }
    };

    const handleSubmit = () => {
        signUp();
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setEmail(value.toLowerCase());
        setEmailError('');
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setPassword(value);
        setPasswordError('');
    };

    const renderForm = () => (
        <StepOne
            email={email}
            emailError={emailError}
            handleChangeEmail={handleChangeEmail}
            password={password}
            handleChangePassword={handleChangePassword}
            passwordError={passwordError}
        />
    );

    return (
        <FormContainer onSubmit={handleSubmit} className={styles.SignUp}>
            <div className={styles.SignUp__container}>
                <div className={styles.SignUp__form}>
                    <div className={styles.SignUp__topLine}>
                        <h2 className={styles.SignUp__formTitle}>Join NOVA</h2>
                    </div>
                    {renderForm()}
                    <div className={styles.SignUp__formButtonContainer}>
                        <NewButton
                            color="rgb(0, 192, 186)"
                            type="submit"
                            disabled={isLoading}
                            loading={isLoading}
                            borderRadius={4}
                            textColor="#ffffff"
                            className={styles.SignUp__submitButton}
                        >
                            Continue
                        </NewButton>
                    </div>
                    <Link to="/" className={styles.SignUp__signInButton}>
                        Already have an account?
                    </Link>
                </div>
                <p className={styles.SignUp__privacyPolicy}>
                    By signing up I accept the{' '}
                    <a
                        href="https://wearenova.ai/legal/terms-of-service"
                        target="_blank"
                        className={styles.SignUp__privacyPolicy_accent}
                        rel="noreferrer"
                    >
                        Terms & Conditions
                    </a>{' '}
                    and the{' '}
                    <a
                        href="https://wearenova.ai/legal/privacy-policy"
                        target="_blank"
                        className={styles.SignUp__privacyPolicy_accent}
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>{' '}
                    of NOVA A.I
                </p>
            </div>
        </FormContainer>
    );
};

export default CustomSignUp;
