import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1.3913; // 32px(default width) / 23px(default height) = 1.3913

const ArrowIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 23;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2737 0.333C10.7177 -0.111 11.4167 -0.111 11.8607 0.333C12.2897 0.762 12.2897 1.476 11.8607 1.904L3.81375 9.951H30.3678C30.9867 9.951 31.4947 10.443 31.4947 11.062C31.4947 11.681 30.9867 12.189 30.3678 12.189H3.81375L11.8607 20.221C12.2897 20.665 12.2897 21.38 11.8607 21.808C11.4167 22.252 10.7177 22.252 10.2737 21.808L0.32175 11.856C-0.10725 11.427 -0.10725 10.713 0.32175 10.285L10.2737 0.333Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
