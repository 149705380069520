export interface TranscriptState {
  mediaLanguagesCodes: string[];
  isTranscriptGenerationLoading: boolean;
  isTranslateGenerationLoading: boolean;
  isCancelTranslateLoading: boolean;
}

export enum TranscriptActionTypes {
  GENERATE_TRANSCRIPT = '@@transcript/GENERATE_TRANSCRIPT',
  SET_TRANSCRIPT_GENERATION_LOADING = '@@transcript/SET_TRANSCRIPT_GENERATION_LOADING',
  GENERATE_TRANSLATE = '@@transcript/GENERATE_TRANSLATE',
  SET_TRANSLATE_GENERATION_LOADING = '@@transcript/SET_TRANSLATE_GENERATION_LOADING',
  SET_MEDIA_LANGUAGES_CODES = '@@transcript/SET_MEDIA_LANGUAGES_CODES',
  CANCEL_TRANSLATE = '@@transcript/CANCEL_TRANSLATE',
  CANCEL_TRANSLATE_START = '@@transcript/CANCEL_TRANSLATE_START',
  CANCEL_TRANSLATE_SUCCESS = '@@transcript/CANCEL_TRANSLATE_SUCCESS',
  CANCEL_TRANSLATE_FAIL = '@@transcript/CANCEL_TRANSLATE_FAIL',
}

export interface GenerateTranscriptActionPayload {
  language: string | null;
}

export interface GenerateTranscriptAction {
  type: TranscriptActionTypes.GENERATE_TRANSCRIPT;
  payload: GenerateTranscriptActionPayload;
}

export interface GenerateTranslateActionPayload {
  sourceLanguage: string;
  language: string;
}

export interface GenerateTranslateAction {
  type: TranscriptActionTypes.GENERATE_TRANSLATE;
  payload: GenerateTranslateActionPayload;
}

export interface SetMediaLanguagesCodesAction {
  type: TranscriptActionTypes.SET_MEDIA_LANGUAGES_CODES;
  payload: string[];
}

export interface SetTranscriptGenerationLoadingAction {
  type: TranscriptActionTypes.SET_TRANSCRIPT_GENERATION_LOADING;
  payload: boolean;
}

export interface SetTranslateGenerationLoadingAction {
  type: TranscriptActionTypes.SET_TRANSLATE_GENERATION_LOADING;
  payload: boolean;
}

export interface CancelTranslateAction {
  type: TranscriptActionTypes.CANCEL_TRANSLATE;
}
export interface CancelTranslateStartAction {
  type: TranscriptActionTypes.CANCEL_TRANSLATE_START;
}
export interface CancelTranslateFailAction {
  type: TranscriptActionTypes.CANCEL_TRANSLATE_FAIL;
}
export interface CancelTranslateSuccessAction {
  type: TranscriptActionTypes.CANCEL_TRANSLATE_SUCCESS;
}


export type TranscriptAction =
    | SetMediaLanguagesCodesAction
    | SetTranscriptGenerationLoadingAction
    | SetTranslateGenerationLoadingAction
    | GenerateTranscriptAction
    | GenerateTranslateAction
    | CancelTranslateAction
    | CancelTranslateStartAction
    | CancelTranslateFailAction 
    | CancelTranslateSuccessAction
