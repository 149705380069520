import { IconProps } from './index';


const LinkIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_94_31)">
                <path
                    d="M14.2959 1.74481C12.6388 0.0877486 9.94256 0.0877486 8.28553 1.74481L5.28031 4.75003L5.98746 5.45712L8.99262 2.4519C9.60212 1.84241 10.4288 1.5 11.2907 1.50001C11.7175 1.50001 12.1401 1.58407 12.5344 1.7474C12.9288 1.91073 13.287 2.15013 13.5888 2.45192C13.8906 2.75371 14.13 3.11199 14.2933 3.5063C14.4567 3.90061 14.5407 4.32323 14.5407 4.75002C14.5407 5.17682 14.4567 5.59944 14.2933 5.99375C14.13 6.38806 13.8906 6.74633 13.5888 7.04812L10.5836 10.0533L11.2906 10.7604L14.2958 7.75522C15.9531 6.09816 15.9531 3.4019 14.2959 1.74481ZM7.04806 13.5889C6.43857 14.1984 5.61192 14.5408 4.74996 14.5408C3.88801 14.5408 3.06136 14.1984 2.45187 13.5889C1.84238 12.9794 1.49997 12.1527 1.49997 11.2908C1.49997 10.4288 1.84238 9.60218 2.45187 8.99269L5.28034 6.16422L4.57325 5.45712L1.74475 8.28559C1.34856 8.6799 1.03404 9.14849 0.819214 9.66453C0.604386 10.1806 0.493464 10.7339 0.492801 11.2929C0.492137 11.8519 0.601745 12.4055 0.815347 12.922C1.02895 13.4386 1.34235 13.9079 1.7376 14.3031C2.13285 14.6984 2.60219 15.0118 3.11874 15.2254C3.63529 15.439 4.1889 15.5486 4.74787 15.5479C5.30683 15.5473 5.86018 15.4363 6.37622 15.2215C6.89225 15.0067 7.36084 14.6922 7.75515 14.296L10.5836 11.4675L9.8765 10.7604L7.04806 13.5889Z"
                    fill={color} />
                <path
                    d="M4.04285 11.2908L11.1139 4.21967L11.821 4.92679L4.74994 11.9979L4.04285 11.2908Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_94_31">
                    <rect
                        width={height}
                        height={height}
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkIcon;