import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Icon, NewButton } from 'components/UI';

import { hideModal } from 'state/modules/modal';
import { createFolder, getFolderCreationLoading } from 'state/modules/folders';
import { getUserId } from 'state/modules/user';

import { Folder, FolderStatuses } from 'interfaces/folder';

const CancelButton = styled.div`
    margin-right: 1rem;
`;

const InputLabel = styled.p`
    /* font-family: Inter, sans-serif; */
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 500;
    margin-bottom: 0.25rem;
`;

const Title = styled.p`
    /* font-family: Inter, sans-serif; */
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    font-weight: 600;
    color: rgb(25, 32, 51);
`;

const LeftSide = styled.div`
    width: 300px;
`;

const RightSide = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
`;

const InputContainer = styled.div``;

const Input = styled.input`
    width: 100%;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(223, 224, 229);
    box-shadow: rgb(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 10px;
    height: 3rem;
    padding: 1rem;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 400;
`;

const Container = styled.form`
    padding: 64px;
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 20px;
    background-color: #fff;
    z-index: 10;
    display: flex;
`;

const CreateFolderModal = () => {
    const [name, setName] = useState('');

    const userId = useSelector(getUserId);
    const isLoading = useSelector(getFolderCreationLoading);

    const dispatch = useDispatch();
    const inputRef = useRef<any>();
    const location = useLocation();

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const folderId = useMemo(() => {
        const ids = location.pathname.split('folder/')?.[1];

        if (ids?.length) {
            const idsArray = ids.split('/');

            return idsArray[idsArray.length - 1];
        }

        return '';
    }, [location]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleCreateFolder = (event: any) => {
        event.preventDefault();

        let defaultFolder = {
            status: FolderStatuses.ACTIVE,
            name: name.length > 0 ? name : 'Untitled Folder',
            userId,
            isDefault: true,
            description: '',
            folderId: folderId || '',
        } as Folder;

        dispatch(createFolder(defaultFolder));
    };

    return (
        <Container onSubmit={handleCreateFolder}>
            <LeftSide>
                <Icon name="folder" size={220} color="#FFE6A7" />
            </LeftSide>
            <RightSide>
                <Title>Create a New Folder</Title>
                <InputContainer>
                    <InputLabel>Folder Name</InputLabel>
                    <Input
                        ref={inputRef}
                        placeholder="Untitled Folder"
                        value={name}
                        onChange={handleChangeName}
                    />
                </InputContainer>
                <Actions>
                    <CancelButton>
                        <NewButton
                            disabled={isLoading}
                            borderRadius={10}
                            color="rgb(247, 247, 248)"
                            textColor="rgb(25, 32, 51)"
                            hoverTextColor="rgb(0, 152, 253)"
                            hoverBoxShadow="rgb(0, 0, 0, 0.1) 0px 1px 2px"
                            hoverBorder="1px solid rgb(103, 193, 255)"
                            height={48}
                            onClick={handleClose}
                        >
                            Cancel
                        </NewButton>
                    </CancelButton>
                    <NewButton
                        loading={isLoading}
                        disabled={isLoading}
                        borderRadius={10}
                        color="rgb(25, 32, 51)"
                        textColor="rgb(255, 255, 255)"
                        height={48}
                        type="submit"
                        hoverBoxShadow="rgb(0, 0, 0, 0.2) 0px 5px 6px"
                    >
                        Create Folder
                    </NewButton>
                </Actions>
            </RightSide>
        </Container>
    );
};

export default CreateFolderModal;
