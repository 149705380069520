import produce from 'immer';
import { UserAction, UserActionTypes } from '../user';

import {
    WorkspaceAction,
    WorkspaceActionTypes,
    WorkspacesState,
} from './types';

const initialState: WorkspacesState = {
    items: [],
    isLoading: false,
    currentWorkspace: null,
    isUpdateLoading: false,

    inviteeWorkspaces: [],
    isInviteeWorkspacesLoading: false,

    isSendInviteLoading: false,
    inviteeInvites: [],
    isInviteeInvitesLoading: false,
    inviterInvites: [],
    isInviterInvitesLoading: false,
    isPinInviteLoading: false,
    isUpdateInviteeInviteLoading: false,
    isUpdateInviterInviteLoading: false,
    isInviteModalVisible: false,

    isRevokeInviteLoading: false,
    isWorkspaceFlowLoading: false,

    // create
    isCreateLoading: false,
};

export default (
    state = initialState,
    action: WorkspaceAction | UserAction
): WorkspacesState =>
    produce(state, (draft) => {
        switch (action.type) {
            // workspaces
            case WorkspaceActionTypes.GET_WORKSPACES_START:
                draft.isLoading = true;

                return;
            case WorkspaceActionTypes.GET_WORKSPACES_FAIL:
                draft.isLoading = false;

                return;
            case WorkspaceActionTypes.SET_WORKSPACES:
                draft.items = action.payload;
                draft.isLoading = false;

                return;
            case WorkspaceActionTypes.SET_CURRENT_WORKSPACE:
                draft.currentWorkspace = action.payload;

                return;
            // invitee workspaces
            case WorkspaceActionTypes.GET_INVITEE_WORKSPACES_START:
                draft.isInviteeWorkspacesLoading = true;

                return;
            case WorkspaceActionTypes.GET_INVITEE_WORKSPACES_FAIL:
                draft.isInviteeWorkspacesLoading = false;

                return;
            case WorkspaceActionTypes.SET_INVITEE_WORKSPACES:
                draft.inviteeWorkspaces = action.payload;
                draft.isInviteeWorkspacesLoading = false;

                return;
            // update
            case WorkspaceActionTypes.UPDATE_WORKSPACE_START:
                draft.isUpdateLoading = true;

                return;
            case WorkspaceActionTypes.UPDATE_WORKSPACE_SUCCESS:
                draft.isUpdateLoading = false;

                return;
            case WorkspaceActionTypes.UPDATE_WORKSPACE_FAIL:
                draft.isUpdateLoading = false;

                return;
            // send invite
            case WorkspaceActionTypes.SEND_WORKSPACE_INVITE_START:
                draft.isSendInviteLoading = true;

                return;
            case WorkspaceActionTypes.SEND_WORKSPACE_INVITE_SUCCESS:
                draft.isSendInviteLoading = false;

                return;
            case WorkspaceActionTypes.SEND_WORKSPACE_INVITE_FAIL:
                draft.isSendInviteLoading = false;

                return;
            // get invitee invites
            case WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_START:
                draft.isInviteeInvitesLoading = true;

                return;
            case WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_SUCCESS:
                draft.isInviteeInvitesLoading = false;
                draft.inviteeInvites = action.payload.invites;

                return;
            case WorkspaceActionTypes.GET_INVITEE_WORKSPACE_INVITES_FAIL:
                draft.isInviteeInvitesLoading = false;

                return;
            // get inviter invites
            case WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_START:
                draft.isInviterInvitesLoading = true;

                return;
            case WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_SUCCESS:
                draft.isInviterInvitesLoading = false;
                draft.inviterInvites = action.payload.invites;

                return;
            case WorkspaceActionTypes.GET_INVITER_WORKSPACE_INVITES_FAIL:
                draft.isInviterInvitesLoading = false;

                return;
            // Pin invite with email
            case WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_START:
                draft.isPinInviteLoading = true;

                return;
            case WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_SUCCESS:
                draft.isPinInviteLoading = false;

                return;
            case WorkspaceActionTypes.PIN_INVITE_WITH_EMAIL_FAIL:
                draft.isPinInviteLoading = false;

                return;
            // Update invitee invite status
            case WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_START:
                draft.isUpdateInviteeInviteLoading = true;

                return;
            case WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_SUCCESS:
                draft.isUpdateInviteeInviteLoading = false;

                return;
            case WorkspaceActionTypes.UPDATE_INVITEE_INVITE_STATUS_FAIL:
                draft.isUpdateInviteeInviteLoading = false;

                return;
            // Update inviter invite status
            case WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_START:
                draft.isUpdateInviterInviteLoading = true;

                return;
            case WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_SUCCESS:
                draft.isUpdateInviterInviteLoading = false;

                return;
            case WorkspaceActionTypes.UPDATE_INVITER_INVITE_STATUS_FAIL:
                draft.isUpdateInviterInviteLoading = false;

                return;

            // invite modal
            case WorkspaceActionTypes.SET_WORKSPACE_INVITE_MODAL_VISIBLE:
                draft.isInviteModalVisible = action.payload;

                return;
            // revoke invite
            case WorkspaceActionTypes.DELETE_INVITE_START:
                draft.isRevokeInviteLoading = true;

                return;
            case WorkspaceActionTypes.DELETE_INVITE_SUCCESS:
                draft.isRevokeInviteLoading = false;

                return;
            case WorkspaceActionTypes.DELETE_INVITE_FAIL:
                draft.isRevokeInviteLoading = false;

                return;
            // loading
            case WorkspaceActionTypes.SET_WORKSPACE_FLOW_LOADING:
                draft.isWorkspaceFlowLoading = false;

                return;
            // create
            case WorkspaceActionTypes.CREATE_WORKSPACE_START:
                draft.isCreateLoading = true;

                return;
            case WorkspaceActionTypes.CREATE_WORKSPACE_SUCCESS:
            case WorkspaceActionTypes.CREATE_WORKSPACE_FAIL:
                draft.isCreateLoading = false;

                return;
            //
            case UserActionTypes.SIGN_OUT:
                return initialState;
            default:
                return state;
        }
    });
