import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import * as foldersActions from 'state/modules/folders/actions';
import * as modalsActions from 'state/modules/modal/actions';
import * as projectsActions from 'state/modules/projects/actions';

import FoldersClient from 'services/api/folders';
import { Folder } from 'interfaces/folder';
import { Workspace } from 'interfaces/workspaces';
import { Project } from 'interfaces/projects';
import {
    AddFolderToHistoryAction,
    CreateFolderAction,
    DeleteFolderAction,
    FoldersActionTypes,
    GetCurrentFolderAction,
    GetFoldersAction,
    MoveToFolderAction,
    UpdateFolderAction,
} from './types';
import {
    getCurrentFolderInfo,
    getFoldersCount,
    getFoldersHistory,
    getFoldersList,
} from './selectors';
import { ProjectsActionTypes } from '../projects';
import { getCurrentWorkspaceInfo } from '../workspaces';

export function* handleGetFolders(action: GetFoldersAction): Generator {
    yield put(foldersActions.getFoldersStart());

    const isLoadMore = action.payload?.isLoadMore;
    const parentFolderId = action?.payload?.id;

    try {
        let folders = (yield select(getFoldersList)) as Folder[];
        const foldersCount = (yield select(getFoldersCount)) as number;

        const res = (yield call(
            FoldersClient.getFolders,
            isLoadMore ? foldersCount : 0,
            parentFolderId || ''
        )) as AxiosResponse;

        if (res.data) {
            const newFolders = res.data.content;

            if (isLoadMore) {
                folders = [...folders, ...newFolders];
            } else {
                folders = newFolders;
            }

            yield put(
                foldersActions.setFolders({
                    total: res.data._metadata.totalCount,
                    folders,
                })
            );
        }
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.getFoldersFail());
    }
}

export function* handleCreateFolder(action: CreateFolderAction): Generator {
    yield put(foldersActions.createFolderStart());

    const currentFolder = (yield select(getCurrentFolderInfo)) as Folder;
    const currentWorkspace = (yield select(
        getCurrentWorkspaceInfo
    )) as Workspace;

    const data = {
        ...action.payload,
        workspaceId: currentWorkspace?.id || '',
    } as Folder;

    try {
        (yield call(FoldersClient.createFolder, data as any)) as AxiosResponse;

        yield delay(1500);

        if (currentFolder) {
            yield put(
                foldersActions.getFolders({
                    id: currentFolder?.id,
                })
            );
        } else {
            yield put(foldersActions.getFolders());
        }

        yield put(foldersActions.createFolderSuccess());
        yield put(modalsActions.hideModal());

        // yield put(foldersActions.getFolders());
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.createFolderFail());
    }
}

export function* handleDeleteFolder(action: DeleteFolderAction): Generator {
    yield put(foldersActions.deleteFolderStart());

    const id = action.payload;

    const currentFolder = (yield select(getCurrentFolderInfo)) as Folder;

    try {
        const res = (yield call(
            FoldersClient.deleteFolder,
            id
        )) as AxiosResponse;

        yield delay(1000);

        if (currentFolder) {
            yield put(
                foldersActions.getFolders({
                    id: currentFolder?.id,
                })
            );
        } else {
            yield put(foldersActions.getFolders());
        }

        yield put(foldersActions.deleteFolderSuccess());
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.deleteFolderFail());
    }
}

export function* handleUpdateFolder(action: UpdateFolderAction): Generator {
    yield put(foldersActions.updateFolderStart());

    const { id, data, hideModal } = action.payload;

    const currentFolder = (yield select(getCurrentFolderInfo)) as Folder;

    try {
        const res = (yield call(
            FoldersClient.updateFolder,
            id,
            data
        )) as AxiosResponse;

        yield delay(1000);

        if (currentFolder) {
            yield put(
                foldersActions.getFolders({
                    id: currentFolder?.id,
                })
            );
        } else {
            yield put(foldersActions.getFolders());
        }

        yield put(foldersActions.updateFolderSuccess());

        if (hideModal) {
            yield put(modalsActions.hideModal());
        }
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.updateFolderFail());
    }
}

export function* handleGetCurrentFolder(
    action: GetCurrentFolderAction
): Generator {
    const id = action.payload;

    try {
        const res = (yield call(
            FoldersClient.getFolderById,
            id
        )) as AxiosResponse;

        const data = res.data.content;

        yield put(foldersActions.setCurrentFolder(data));

        const foldersHistory = (yield select(getFoldersHistory)) as Folder[];

        const currentFolderHistoryIndex = foldersHistory.findIndex(
            (item) => item.id === id
        );

        const filteredHistory =
            currentFolderHistoryIndex >= 0
                ? foldersHistory.slice(0, currentFolderHistoryIndex)
                : foldersHistory;

        const updatedHistory = [...filteredHistory, data];

        yield put(foldersActions.setFolderHistory(updatedHistory));
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.updateFolderFail());
    }
}

export function* handleAddFolderToHistory(
    action: AddFolderToHistoryAction
): Generator {
    const folder = action.payload;

    const folders = (yield select(getFoldersHistory)) as Folder[];

    const updatedFolders = [...folders, folder];

    yield put(foldersActions.setFolderHistory(updatedFolders));
}

export function* handleMoveToFolder(action: MoveToFolderAction): Generator {
    yield put(foldersActions.moveToFolderStart());

    try {
        const { folder, project, selectedFolderId, currentFolderId } =
            action.payload;

        if (project) {
            const updatedProject = {
                ...project,
                version: `${
                    Math.round(
                        (parseFloat(`${project.version}`) + parseFloat('0.1')) *
                            100
                    ) / 100
                }`,
                folder: selectedFolderId || '',
            } as Project;

            if (!selectedFolderId) {
                delete updatedProject.folder;
            }

            yield put(
                projectsActions.updateProject({
                    project: updatedProject,
                    skipVersionUpdate: true,
                })
            );

            yield take(
                ProjectsActionTypes.UPDATE_PROJECT_SUCCESS ||
                    ProjectsActionTypes.UPDATE_PROJECT_FAIL
            );

            yield delay(1000);

            yield put(
                projectsActions.getProjects({
                    folderId: currentFolderId,
                })
            );
        }

        if (folder) {
            const updatedFolder = {
                ...folder,
                folderId: selectedFolderId || '',
            } as Folder;

            yield put(
                foldersActions.updateFolder({
                    id: updatedFolder.id,
                    data: updatedFolder,
                })
            );

            yield take(
                FoldersActionTypes.UPDATE_FOLDER_SUCCESS ||
                    FoldersActionTypes.UPDATE_FOLDER_FAIL
            );

            yield delay(1000);
        }

        yield put(
            foldersActions.getFolders({
                id: currentFolderId,
            })
        );

        yield put(foldersActions.moveToFolderSuccess());
        yield put(modalsActions.hideModal());
    } catch (error) {
        console.log({ error });
        yield put(foldersActions.moveToFolderFail());
    }
}

export function* foldersSaga(): Generator {
    yield takeLatest(FoldersActionTypes.GET_FOLDERS, handleGetFolders);
    yield takeLatest(FoldersActionTypes.CREATE_FOLDER, handleCreateFolder);
    yield takeLatest(FoldersActionTypes.DELETE_FOLDER, handleDeleteFolder);
    yield takeLatest(FoldersActionTypes.UPDATE_FOLDER, handleUpdateFolder);
    yield takeLatest(
        FoldersActionTypes.GET_CURRENT_FOLDER,
        handleGetCurrentFolder
    );
    yield takeLatest(
        FoldersActionTypes.ADD_FOLDER_TO_HISTORY,
        handleAddFolderToHistory
    );
    yield takeLatest(FoldersActionTypes.MOVE_TO_FOLDER, handleMoveToFolder);
}
