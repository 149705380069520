import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NewButton } from 'components/UI';

import { hideModal } from 'state/modules/modal';
import {
    createWorkspace,
    getWorkspaceCreateLoading,
} from 'state/modules/workspaces';

const Avatar = styled.div`
    width: 108px;
    height: 108px;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    background: rgb(5, 27, 90);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const AvatarPlaceholder = styled.span`
    font-family: 'Proxima Nova Bold', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.75rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
`;

const CancelButton = styled.div`
    margin-right: 1rem;
`;

const Title = styled.p`
    font-size: 1.5rem;
    letter-spacing: 0px;
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-weight: 600;
    color: rgb(25, 32, 51);
    text-align: center;
    margin-bottom: 2rem;
`;

const LeftSide = styled.div`
    margin-right: 1rem;
`;

const RightSide = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
`;

const InputContainer = styled.div``;

const Input = styled.input`
    width: 100%;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(223, 224, 229);
    box-shadow: rgb(0, 0, 0, 0.1) 0px 1px 2px;
    border-radius: 10px;
    height: 3rem;
    padding: 1rem;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0px;
    font-weight: 400;
`;

const Container = styled.form`
    padding: 2rem;
    box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 16px;
    border-radius: 20px;
    background-color: #fff;
    z-index: 10;
`;

const Content = styled.div`
    display: flex;
`;

const CreateWorkspaceModal = () => {
    const [name, setName] = useState('');

    const isLoading = useSelector(getWorkspaceCreateLoading);

    const dispatch = useDispatch();
    const inputRef = useRef<any>();
    const navigate = useNavigate();

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const namePlaceholder = useMemo(() => {
        return name[0] || 'W';
    }, [name]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleCreate = (event: any) => {
        event.preventDefault();

        dispatch(createWorkspace({ name, navigate }));
    };

    return (
        <Container onSubmit={handleCreate}>
            <Title>Create a Workspace</Title>
            <Content>
                <LeftSide>
                    <Avatar>
                        <AvatarPlaceholder>{namePlaceholder}</AvatarPlaceholder>
                    </Avatar>
                </LeftSide>
                <RightSide>
                    <InputContainer>
                        <Input
                            ref={inputRef}
                            placeholder="Workspace"
                            value={name}
                            onChange={handleChangeName}
                        />
                    </InputContainer>
                    <Actions>
                        <CancelButton>
                            <NewButton
                                disabled={isLoading}
                                borderRadius={10}
                                color="rgb(247, 247, 248)"
                                textColor="rgb(25, 32, 51)"
                                hoverTextColor="rgb(0, 152, 253)"
                                hoverBoxShadow="rgb(0, 0, 0, 0.1) 0px 1px 2px"
                                hoverBorder="1px solid rgb(103, 193, 255)"
                                height={48}
                                onClick={handleClose}
                            >
                                Cancel
                            </NewButton>
                        </CancelButton>
                        <NewButton
                            loading={isLoading}
                            disabled={isLoading || !name.length}
                            borderRadius={10}
                            color="rgb(25, 32, 51)"
                            textColor="rgb(255, 255, 255)"
                            height={48}
                            type="submit"
                            hoverBoxShadow="rgb(0, 0, 0, 0.2) 0px 5px 6px"
                        >
                            Create
                        </NewButton>
                    </Actions>
                </RightSide>
            </Content>
        </Container>
    );
};

export default CreateWorkspaceModal;
