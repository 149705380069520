export enum CardFormInputKey {
    CARD_NUMBER,
    EXPIRY,
    CVC,
}

export interface Coupon {
    amount_off: null;
    created: number;
    currency: null;
    duration: string;
    duration_in_months: null;
    id: string;
    livemode: boolean;
    max_redemptions: null;
    metadata: any;
    name: string;
    object: string;
    percent_off: number;
    redeem_by: null;
    times_redeemed: number;
    valid: boolean;
}

export interface Promocode {
    active: boolean;
    code: string;
    coupon: Coupon;
    created: number;
    customer: null;
    expires_at: null;
    id: string;
    livemode: boolean;
    max_redemptions: null;
    metadata: any;
    object: string;
    restrictions: {
        first_time_transaction: boolean;
        minimum_amount: null;
        minimum_amount_currency: null;
    };
    times_redeemed: number;
}

export interface IInvoice {
    id: string;
    object: string;
    account_country: string;
    account_name: string;
    account_tax_ids: null;
    amount_due: number;
    amount_paid: number;
    amount_remaining: number;
    amount_shipping: number;
    application: null;
    application_fee_amount: null;
    attempt_count: number;
    attempted: false;
    auto_advance: false;
    automatic_tax: {
        enabled: false;
        status: null;
    };
    billing_reason: string;
    charge: null;
    collection_method: string;
    created: number;
    currency: string;
    custom_fields: null;
    customer: string;
    customer_address: null;
    customer_email: string;
    customer_name: null;
    customer_phone: null;
    customer_shipping: null;
    customer_tax_exempt: string;
    customer_tax_ids: [];
    default_payment_method: null;
    default_source: null;
    default_tax_rates: [];
    description: null;
    discount: null;
    discounts: [];
    due_date: null;
    effective_at: null;
    ending_balance: null;
    footer: null;
    from_invoice: null;
    hosted_invoice_url: null;
    invoice_pdf: null;
    last_finalization_error: null;
    latest_revision: null;
    lines: {
        object: string;
        data: [
            {
                id: string;
                object: string;
                amount: number;
                amount_excluding_tax: number;
                currency: string;
                description: string;
                discount_amounts: [];
                discountable: true;
                discounts: [];
                invoice_item: string;
                livemode: false;
                metadata: {};
                period: {
                    end: number;
                    start: number;
                };
                price: {
                    id: string;
                    object: string;
                    active: true;
                    billing_scheme: string;
                    created: number;
                    currency: string;
                    custom_unit_amount: null;
                    livemode: false;
                    lookup_key: null;
                    metadata: {
                        pricePlanId: string;
                        [key: string]: any;
                    };
                    nickname: null;
                    product: string;
                    recurring: null;
                    tax_behavior: string;
                    tiers_mode: null;
                    transform_quantity: null;
                    type: string;
                    unit_amount: number;
                    unit_amount_decimal: string;
                };
                proration: false;
                proration_details: {
                    credited_items: null;
                };
                quantity: 1;
                subscription: null;
                tax_amounts: [];
                tax_rates: [];
                type: string;
                unit_amount_excluding_tax: string;
            }
        ];
        has_more: false;
        url: string;
    };
    livemode: false;
    metadata: {};
    next_payment_attempt: null;
    number: null;
    on_behalf_of: null;
    paid: false;
    paid_out_of_band: false;
    payment_intent: null;
    payment_settings: {
        default_mandate: null;
        payment_method_options: null;
        payment_method_types: null;
    };
    period_end: number;
    period_start: number;
    post_payment_credit_notes_amount: number;
    pre_payment_credit_notes_amount: number;
    quote: null;
    receipt_number: null;
    redaction: null;
    rendering_options: null;
    shipping_cost: null;
    shipping_details: null;
    starting_balance: number;
    statement_descriptor: null;
    status: string;
    status_transitions: {
        finalized_at: null;
        marked_uncollectible_at: null;
        paid_at: null;
        voided_at: null;
    };
    subscription: null;
    subscription_details: {
        metadata: null;
    };
    subtotal: number;
    subtotal_excluding_tax: number;
    tax: null;
    test_clock: null;
    total: number;
    total_discount_amounts: [];
    total_excluding_tax: number;
    total_tax_amounts: [];
    transfer_data: null;
    webhooks_delivered_at: null;
}
