import { IconProps } from '../index';

const TextInFlipboardIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.361 18.227c.678-.49 1.49-.735 2.438-.735.948 0 1.745.221 2.39.664.654.438.98.966.98 1.586 0 .62-.33 1.151-.992 1.594-.661.443-1.462.664-2.402.664-.932 0-1.736-.266-2.413-.797v.656h-.968V16h.968v2.227zm2.378 3.148c.678 0 1.251-.164 1.721-.492.478-.328.717-.711.717-1.149 0-.442-.23-.828-.693-1.156-.462-.328-1.04-.492-1.733-.492-.693 0-1.274.154-1.744.46-.462.308-.693.696-.693 1.165 0 .463.235.857.705 1.18.47.323 1.043.484 1.72.484zM17.52 24.083h-1.087l-1.35-2.924h-3.61l-1.338 2.924H9L13.135 15h.31l4.075 9.083zm-2.904-3.963-1.326-2.948-1.338 2.948h2.664zM33.062 20.33c-.343.218-.793.384-1.35.498a8.8 8.8 0 0 1-1.793.172c-1.004 0-1.845-.144-2.522-.431-.669-.288-1.003-.64-1.003-1.056 0-.416.338-.771 1.015-1.066.677-.298 1.554-.447 2.63-.447.637 0 1.222.06 1.756.182.542.121.964.291 1.267.51l-.777.213c-.582-.33-1.342-.494-2.283-.494-.74 0-1.362.104-1.864.312-.502.204-.753.459-.753.764s.247.567.741.785c.494.219 1.12.328 1.876.328.909 0 1.673-.167 2.295-.5l.765.23z"
                fill="#525253" />
            <path d="m22.991 12-1.995 1.995L19 12" stroke="#CECECF" strokeWidth=".7" strokeLinecap="round"
                  strokeLinejoin="round" />
            <path
                d="M25.651 15.986v-3.658a2.328 2.328 0 1 0-4.656 0V13.5M21.677 28.476a2.328 2.328 0 0 0 3.974-1.647V16.216"
                stroke="#CECECF" strokeWidth=".7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TextInFlipboardIcon;