import { IconProps } from './index';

const HamburgerIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 16;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 8h12M2 4h12M2 12h12"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default HamburgerIcon;
