import {
  Job,
  JobsActionTypes,
  GetJobsAction,
  GetVideoJobsAction,
  SetVideoJobsAction,
} from 'state/modules/jobs';

export function getJobs(): GetJobsAction {
  return {
    type: JobsActionTypes.GET_JOBS,
  };
}

export function getVideoJobs(videoId: string): GetVideoJobsAction {
  return {
    type: JobsActionTypes.GET_JOBS_BY_VIDEO_ID,
    payload: videoId,
  };
}

export function setVideoJobs(jobs: Array<Job>): SetVideoJobsAction {
  return {
    type: JobsActionTypes.SET_VIDEO_JOBS,
    payload: jobs,
  };
}
