import {
    SubtitleEffect,
    SubtitlesAlign,
    SubtitlesTextStyle,
    TextLinesValue,
} from 'interfaces/projects';
import React, { CSSProperties } from 'react';
import {
    FontFamilyItem,
    SubtitlesAlignItem,
    SubtitlesEffectItem,
    SubtitlesTextStyleItem,
    SubtitleValidationMessage,
    SubtitleValidationMessageProps,
    TextLinesItem,
    OverlappingSubtitlesNewPositions,
    OverlappingSubtitlesNewPositionsProps,
    SubtitleValidationProblemType,
} from 'interfaces/subtitles';

import {frameNumberToSeconds, secondsToFrameNumber} from 'utils/timeline';
import { Range } from 'utils/range';
import { NotificationTypes } from 'utils/notifications';

import alignLeftImg from 'assets/images/subtitles/alignLeft.png';
import alignCenterImg from 'assets/images/subtitles/alignCenter.png';
import alignRightImg from 'assets/images/subtitles/alignRight.png';

import BoldTextImg from 'assets/images/subtitles/boldText.png';
import ItalicTextImg from 'assets/images/subtitles/italicText.png';
import _findLastIndex from 'lodash/findLastIndex';
import {TransformedSentence} from "../state/modules/metadata";


export const fontFamilies: FontFamilyItem[] = [
    {
        value: 'HelveticaNowTextExtraLight',
        label: 'HelveticaNowTextExtraLight',
        fontWeight: 200,
        isChangeFontWeightDisabled: true,
        // isChangeTextStyleDisabled: true,
    },
    {
        value: 'HelveticaNowTextLight',
        label: 'HelveticaNowTextLight',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
        // isChangeTextStyleDisabled: true,
    },
    {
        value: 'HelveticaNowTextMedium',
        label: 'HelveticaNowTextMedium',
        fontWeight: 500,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'HelveticaNowText',
        label: 'HelveticaNowText',
        fontWeight: 400,
    },
    {
        value: 'HelveticaNowTextBlack',
        label: 'HelveticaNowTextBlack',
        fontWeight: 800,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'Calibri',
        label: 'Calibri',
        fontWeight: 400,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'Calibri-Light',
        label: 'Calibri-Light',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'Gilroy',
        label: 'Gilroy',
        fontWeight: 400,
    },
    {
        value: 'Gilroy-Black',
        label: 'Gilroy-Black',
        isChangeFontWeightDisabled: true,
        fontWeight: 800,
    },
    {
        value: 'Gilroy-Heavy',
        label: 'Gilroy-Heavy',
        fontWeight: 900,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'Gilroy-Light',
        label: 'Gilroy-Light',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'Gilroy-Medium',
        label: 'Gilroy-Medium',
        fontWeight: 500,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'Gilroy-UltraLight',
        label: 'Gilroy-UltraLight',
        fontWeight: 200,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'AvenirLTPro-Black',
        label: 'AvenirLTPro-Black',
        isChangeFontWeightDisabled: true,
        fontWeight: 800,
    },
    {
        value: 'AvenirLTPro-Oblique',
        label: 'AvenirLTPro-Oblique',
        isChangeFontWeightDisabled: true,
        fontWeight: 400,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'AvenirLTPro-LightOblique',
        label: 'AvenirLTPro-LightOblique',
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
        fontWeight: 300,
    },
    {
        value: 'AvenirLTPro-BlackOblique',
        label: 'AvenirLTPro-BlackOblique',
        fontWeight: 800,
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'AvenirLTPro-Roman',
        label: 'AvenirLTPro-Roman',
        fontWeight: 400,
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'AvenirLTPro-Light',
        label: 'AvenirLTPro-Light',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'TSTARPRO',
        label: 'TSTARPRO',
        fontWeight: 400,
    },
    {
        value: 'TSTARPRO-Heavy',
        label: 'TSTARPRO-Heavy',
        fontWeight: 900,
        isChangeFontWeightDisabled: true,
        isChangeTextStyleDisabled: true,
    },
    {
        value: 'TSTARPRO-Light',
        label: 'TSTARPRO-Light',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'TSTARPRO-Medium',
        label: 'TSTARPRO-Medium',
        fontWeight: 500,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'Abril Fatface',
        label: 'Abril Fatface',
    },
    {
        value: 'Aclonica',
        label: 'Aclonica',
    },
    {
        value: 'Alex Brush',
        label: 'Alex Brush',
    },
    {
        value: 'Alfa Slab One',
        label: 'Alfa Slab One',
    },
    {
        value: 'Amatic SC',
        label: 'Amatic SC',
    },
    {
        value: 'Anton',
        label: 'Anton',
    },
    {
        value: 'Archivo',
        label: 'Archivo',
    },
    {
        value: 'Archivo Black',
        label: 'Archivo Black',
        fontWeight: 800,
    },
    {
        value: 'Arimo',
        label: 'Arimo (Arial)',
    },
    {
        value: 'Bad Script',
        label: 'Bad Script',
    },
    {
        value: 'Bullet',
        label: 'Bullet',
    },
    {
        value: 'Bangers',
        label: 'Bangers',
    },
    {
        value: 'Bebas Neue',
        label: 'Bebas Neue',
    },
    {
        value: 'BIZ UDGothic',
        label: 'BIZ UDGothic',
    },
    {
        value: 'BIZ UDMincho',
        label: 'BIZ UDMincho',
    },
    {
        value: 'Bungee',
        label: 'Bungee',
    },
    {
        value: 'Bungee Hairline',
        label: 'Bungee Hairline',
    },
    {
        value: 'Bungee Inline',
        label: 'Bungee Inline',
    },
    {
        value: 'Bungee Shade',
        label: 'Bungee Shade',
    },
    {
        value: 'Cabin',
        label: 'Cabin',
    },
    // {
    //     value: 'Cabin Sketch',
    //     label: 'Cabin Sketch',
    // },
    {
        value: 'Calistoga',
        label: 'Calistoga',
    },
    {
        value: 'Castoro',
        label: 'Castoro',
    },
    {
        value: 'Chewy',
        label: 'Chewy',
    },
    {
        value: 'Cinzel',
        label: 'Cinzel',
    },
    {
        value: 'Cinzel Decorative',
        label: 'Cinzel Decorative',
    },
    {
        value: 'Coda',
        label: 'Coda',
    },
    {
        value: 'Deg Dis',
        label: 'Deg Dis',
    },
    {
        value: 'Dela Gothic One',
        label: 'Dela Gothic One',
    },
    {
        value: 'Finger Paint',
        label: 'Finger Paint',
    },
    {
        value: 'Fira Code',
        label: 'Fira Code',
    },
    {
        value: 'Fjalla One',
        label: 'Fjalla One',
    },
    {
        value: 'Fredoka One',
        label: 'Fredoka One',
    },
    {
        value: 'Homemade Apple',
        label: 'Homemade Apple',
    },
    {
        value: 'Iceberg',
        label: 'Iceberg',
    },
    {
        value: 'Just Another Hand',
        label: 'Just Another Hand',
    },
    // {
    //     value: 'Kumar One Outline',
    //     label: 'Kumar One Outline',
    // },
    {
        value: 'Kosugi Maru',
        label: 'Kosugi Maru',
    },
    {
        value: 'Lalezar',
        label: 'Lalezar',
    },
    {
        value: 'League Script',
        label: 'League Script',
    },
    {
        value: 'Licorice',
        label: 'Licorice',
    },
    {
        value: 'Lobster',
        label: 'Lobster',
    },
    {
        value: 'Meow Script',
        label: 'Meow Script',
    },
    {
        value: 'Merriweather',
        label: 'Merriweather',
    },
    {
        value: 'Modak',
        label: 'Modak',
    },
    {
        value: 'Monoton',
        label: 'Monoton',
    },
    {
        value: 'Montserrat',
        label: 'Montserrat',
    },
    {
        value: 'Mr De Haviland',
        label: 'Mr De Haviland',
    },
    {
        value: 'M PLUS Rounded 1c',
        label: 'M PLUS Rounded 1c',
    },
    {
        value: 'New Rocker',
        label: 'New Rocker',
    },
    {
        value: 'Noto Sans',
        label: 'Noto Sans',
    },
    // {
    //     value: 'Noto Sans Japanese',
    //     label: 'Noto Sans Japanese',
    // },
    // {
    //     value: 'Noto Sans Korean',
    //     label: 'Noto Sans Korean',
    // },
    {
        value: 'Noto Serif',
        label: 'Noto Serif',
    },
    // {
    //     value: 'Noto Serif Japanese',
    //     label: 'Noto Serif Japanese',
    // },
    // {
    //     value: 'Noto Serif Korean',
    //     label: 'Noto Serif Korean',
    // },
    {
        value: 'Oleo Script',
        label: 'Oleo Script',
    },
    {
        value: 'Oooh Baby',
        label: 'Oooh Baby',
    },
    {
        value: 'Open Sans',
        label: 'Open Sans',
    },
    {
        value: 'Oswald',
        label: 'Oswald',
    },
    {
        value: 'Pacifico',
        label: 'Pacifico',
    },
    {
        value: 'Patrick Hand',
        label: 'Patrick Hand',
    },
    {
        value: 'Permanent Marker',
        label: 'Permanent Marker',
    },
    {
        value: 'Playball',
        label: 'Playball',
    },
    {
        value: 'Playfair Display',
        label: 'Playfair Display',
    },
    {
        value: 'Poppins',
        label: 'Poppins',
    },
    {
        value: 'Press Start 2P',
        label: 'Press Start 2P',
    },
    {
        value: 'Racing Sans One',
        label: 'Racing Sans One',
    },
    {
        value: 'Rakkas',
        label: 'Rakkas',
    },
    {
        value: 'Roboto',
        label: 'Roboto',
    },
    {
        value: 'Russo One',
        label: 'Russo One',
    },
    {
        value: 'Rye',
        label: 'Rye',
    },
    {
        value: 'Sacramento',
        label: 'Sacramento',
    },
    {
        value: 'Shrikhand',
        label: 'Shrikhand',
    },
    {
        value: 'Song Myung',
        label: 'Song Myung',
    },
    {
        value: 'Source Code Pro',
        label: 'Source Code Pro',
    },
    {
        value: 'Squada One',
        label: 'Squada One',
    },
    {
        value: 'Titan One',
        label: 'Titan One',
    },
    {
        value: 'Uncial Antiqua',
        label: 'Uncial Antiqua',
    },
    {
        value: 'Work Sans',
        label: 'Work Sans',
    },
    {
        value: 'Yeseva One',
        label: 'Yeseva One',
    },
    {
        value: 'ZCOOL KuaiLe',
        label: 'ZCOOL KuaiLe',
    },
    {
        value: 'Zen Maru Gothic',
        label: 'Zen Maru Gothic',
    },
    {
        value: 'Zilla Slab',
        label: 'Zilla Slab',
    },
    {
        value: 'Zilla Slab Highlight',
        label: 'Zilla Slab Highlight',
    },
    {
        value: 'IranSansUltraLight',
        label: 'IranSansUltraLight',
        fontWeight: 200,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'IranSansLight',
        label: 'IranSansLight',
        fontWeight: 300,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'IranSansRegular',
        label: 'IranSansRegular',
        fontWeight: 400,
        isChangeFontWeightDisabled: true,
    },
    {
        value: 'IranSansMedium',
        label: 'IranSansMedium',
        fontWeight: 500,
        isChangeFontWeightDisabled: true,
    },
];

export const textLinesItems: TextLinesItem[] = [
    {
        value: TextLinesValue.NONE,
        label: 'None',
    },
    {
        value: TextLinesValue.ONE,
        label: '1',
    },
    {
        value: TextLinesValue.TWO,
        label: '2',
    },
    {
        value: TextLinesValue.THREE,
        label: '3',
    },
];

export const subtitlesAlignItems = [
    {
        value: SubtitlesAlign.LEFT,
        icon: alignLeftImg,
    },
    {
        value: SubtitlesAlign.CENTER,
        icon: alignCenterImg,
    },
    {
        value: SubtitlesAlign.RIGHT,
        icon: alignRightImg,
    },
] as SubtitlesAlignItem[];

export const subtitlesTextStyleItems = [
    {
        type: SubtitlesTextStyle.BOLD,
        icon: BoldTextImg,
    },
    {
        type: SubtitlesTextStyle.ITALIC,
        icon: ItalicTextImg,
    },
] as SubtitlesTextStyleItem[];

export const textEffects: SubtitlesEffectItem[] = [
    {
        value: SubtitleEffect.DEFAULT,
        text: 'This is the Default Subtitle Style',
        textStyle: {
            color: 'white',
        },
        color: '#ffffff',
        backgroundColor: 'transparent',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Default',
        outline: 0,
        shadow: 0,
        shadowColor: 'transparent',
        // opacity: 0.99,
    },
    {
        value: SubtitleEffect.NORMAL,
        text: 'This is the Basic Subtitle Style',
        textStyle: {
            color: 'black',
        },
        color: '#000000',
        backgroundColor: 'transparent',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Basic',
        outline: 0,
        shadow: 0,
        shadowColor: 'transparent',
        // opacity: 0.99,
    },
    {
        value: SubtitleEffect.OUTLINE_ONLY,
        text: 'This is the Hollow Subtitle Style',
        textStyle: {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0)',
            WebkitTextStroke: '0.3px rgb(3, 2, 2)',
        },
        backgroundColor: 'transparent',
        textBgColor: 'transparent',
        outlineColor: '#030202',
        label: 'Outline Only',
        outline: 1,
        shadow: 0,
        shadowColor: 'transparent',
        // opacity: 0,
        color: '#ffffff00',
    },
    {
        value: SubtitleEffect.OUTLINE_FILLED,
        text: 'This is the Styistic Subtitle Style',
        textStyle: {
            color: 'rgb(252, 227, 113)',
            WebkitTextFillColor: 'rgb(252, 227, 113)',
            WebkitTextStroke: '0.3px rgb(3, 2, 2)',
        },
        color: '#FCE371',
        backgroundColor: 'transparent',
        textBgColor: 'transparent',
        outlineColor: '#030202',
        label: 'Outline (Filled)',
        outline: 1,
        shadow: 0,
        shadowColor: 'transparent',
        // opacity: 0.99,
    },
    {
        value: SubtitleEffect.DROP_SHADOW,
        text: 'This is the Drop Subtitle Style',
        textStyle: {
            textShadow: 'rgb(0, 0, 0) 2px 2px 3px',
            color: 'white',
        },
        color: '#ffffff',
        backgroundColor: '#000000',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Drop Shadow',
        outline: 0,
        shadow: 1,
        shadowColor: '#000000',
    },
    {
        value: SubtitleEffect.BLOCK,
        text: 'This is the Block Subtitle Style',
        textStyle: {
            backgroundColor: '#6e6e6e',
            color: 'white',
            borderRadius: 3,
        },
        color: '#ffffff',
        backgroundColor: '#000000',
        textBgColor: 'transparent',
        outlineColor: '#000000',
        label: 'Block',
        outline: 0,
        shadow: 0,
        shadowColor: '#00000055',
    },
];

export const subtitleEffects: SubtitlesEffectItem[] = [
    ...textEffects,
    {
        value: SubtitleEffect.BOX_HIGHLIGHT,
        text: (
            <>
                This is the{' '}
                <span style={{ backgroundColor: '#6e6e6e', color: 'white' }}>
                    Highlight
                </span>{' '}
                Subtitle Style
            </>
        ),
        color: '#ffffff',
        backgroundColor: '#000000',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Karaoke Highlight',
        outline: 0,
        shadow: 1,
        shadowColor: '#000000',
    },
    {
        value: SubtitleEffect.KARAOKE,
        text: (
            <>
                <span style={{ backgroundColor: '#6e6e6e', color: 'white' }}>
                    This is the Karaoke
                </span>{' '}
                Subtitle Style
            </>
        ),
        color: '#ffffff',
        backgroundColor: '#000000',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Karaoke',
        outline: 0,
        shadow: 1,
        shadowColor: '#000000',
    },
    {
        value: SubtitleEffect.REVEAL,
        text: <>This is the Reveal Subtitle Style</>,
        color: '#ffffff',
        backgroundColor: '#000000',
        textBgColor: 'transparent',
        outlineColor: 'transparent',
        label: 'Reveal',
        outline: 0,
        shadow: 1,
        shadowColor: '#000000',
    },
];

export const isSubtitleAnimatedEffect = (effect?: SubtitleEffect) => {
    return (
        effect === SubtitleEffect.BOX_HIGHLIGHT ||
        effect === SubtitleEffect.KARAOKE ||
        effect === SubtitleEffect.REVEAL
    );
};

export const subtitleWordIsAnimated = (
    start: number,
    end: number,
    currentTime: number,
    frameRate: number,
    effect: SubtitleEffect | undefined
): boolean => {
    if (effect === SubtitleEffect.BOX_HIGHLIGHT) {
        return (
            secondsToFrameNumber(start, frameRate) <=
                secondsToFrameNumber(currentTime, frameRate) &&
            secondsToFrameNumber(end, frameRate) >
                secondsToFrameNumber(currentTime, frameRate)
        );
    }
    if (effect === SubtitleEffect.KARAOKE || effect === SubtitleEffect.REVEAL) {
        return (
            secondsToFrameNumber(start, frameRate) <=
            secondsToFrameNumber(currentTime, frameRate)
        );
    }
    return false;
};
export const getSubtitleAnimatedEffectStyles = (
    effect: SubtitleEffect | undefined,
    color: string
): CSSProperties => {
    const styles: CSSProperties = {};

    if (effect === SubtitleEffect.BOX_HIGHLIGHT) {
        styles.backgroundColor = color;
    } else if (effect === SubtitleEffect.KARAOKE) {
        styles.color = color;
    } else if (effect === SubtitleEffect.REVEAL) {
        styles.opacity = 1;
    }

    return styles;
};

export const isExceededMaxLine = (element: HTMLElement, linesCount = 2) => {
    const truncatedBlock: any = element.cloneNode(true);
    truncatedBlock.style.display = '-webkit-box';
    truncatedBlock.style['-webkit-line-clamp'] = linesCount;
    truncatedBlock.style['-webkit-box-orient'] = 'vertical';
    document.body.append(truncatedBlock);

    const info = element.getBoundingClientRect();
    const truncatedInfo = truncatedBlock.getBoundingClientRect();

    truncatedBlock.remove();

    return info.height > truncatedInfo.height;
};

export const syncSubtitleBlockSize = (
    element: any,
    linesCount: number,
    scale = 1
) => {
    if (!linesCount) return;

    const minFontSize = 4;

    while (
        isExceededMaxLine(element, linesCount) &&
        parseFloat(element.style.fontSize) / scale > minFontSize
    ) {
        const unScaledFontSize = parseFloat(element.style.fontSize) / scale;

        const fontSizeLowerPercentage = 100 / unScaledFontSize;

        element.style.fontSize = `${
            ((unScaledFontSize * (100 - fontSizeLowerPercentage)) / 100) * scale
        }px`;

        if (element.childNodes?.length > 0) {
            for (let i = 0; i < element.childNodes.length; i++) {
                const childElement = element.childNodes[i];
                if (childElement.nodeType !== Node.TEXT_NODE) {
                    const unScaledChildFontSize =
                        parseFloat(childElement.style.fontSize) / scale;
                    childElement.style.fontSize = `${
                        ((unScaledChildFontSize *
                            (100 - fontSizeLowerPercentage)) /
                            100) *
                        scale
                    }px`;
                }
            }
        }
    }
};

export const getSubtitleValidationMessage = (
    props: SubtitleValidationMessageProps
): SubtitleValidationMessage | undefined => {
    const {
        isOutsideOfVideo,
        startTime,
        endTime,
        subtitles,
        frameRate,
        subtitleId,
    } = props;

    let validationMessage: SubtitleValidationMessage | undefined;

    if (isOutsideOfVideo) {
        validationMessage = {
            type: NotificationTypes.warning,
            message: "Subtitle time can't be outside of the video range",
            problem: SubtitleValidationProblemType.OUTSIDE_VIDEO,
        };

        return validationMessage;
    }

    if (
        secondsToFrameNumber(endTime, frameRate) <=
        secondsToFrameNumber(startTime, frameRate)
    ) {
        validationMessage = {
            type: NotificationTypes.warning,
            message: 'Subtitle start time needs to be before end time',
            problem: SubtitleValidationProblemType.FLIPPED_SUB,
        };

        return validationMessage;
    }

    const range1 = new Range(
        secondsToFrameNumber(startTime, frameRate),
        secondsToFrameNumber(endTime, frameRate)
    );
    const isOverlapping = subtitles.some((subtitle) => {
        if (subtitle.id !== subtitleId) {
            const subtitleStartTimeFrame = secondsToFrameNumber(
                subtitle.timeline?.startTime,
                frameRate
            );
            const subtitleEndTimeFrame = secondsToFrameNumber(
                subtitle.timeline?.endTime,
                frameRate
            );

            if (subtitleEndTimeFrame <= subtitleStartTimeFrame) {
                return false;
            }

            const range2 = new Range(
                subtitleStartTimeFrame,
                subtitleEndTimeFrame
            );
            const intersection = range1.intersection(range2);
            return intersection.length !== 0;
        }
        return false;
    });

    if (isOverlapping) {
        validationMessage = {
            type: NotificationTypes.warning,
            message: "Subtitle blocks can't overlap",
            problem: SubtitleValidationProblemType.OVERLAP,
        };

        return validationMessage;
    }

    return undefined;
};

const isOneSideOnEmptyTimeline = (
    subtitles:  TransformedSentence[],
    frameRate: number,
    data: TransformedSentence,
    tempTime: number
) => {
    return (
        !subtitles.some(
            item => {
                const currStartTimeFrames = secondsToFrameNumber(+item.timeline.startTime, frameRate);
                const currEndTimeFrames = secondsToFrameNumber(+item.timeline.endTime, frameRate);

                return (
                    (item.id !== data.id) &&
                    (currStartTimeFrames <= tempTime) &&
                    (currEndTimeFrames >= tempTime)
                );
            }
        )
    );
};

const isOverlappingAfterMoved = (
    subtitles:  TransformedSentence[],
    frameRate: number,
    data: TransformedSentence,
    tempStartTime: number,
    tempEndTime: number,
    relativeItem: TransformedSentence,
) => {
    return (
        subtitles.some(
            item => {
                const currStartTimeFrames = secondsToFrameNumber(+item.timeline.startTime, frameRate);
                const currEndTimeFrames = secondsToFrameNumber(+item.timeline.endTime, frameRate);
                const range1 = new Range(
                    currStartTimeFrames,
                    currEndTimeFrames
                );
                const range2 = new Range(
                    tempStartTime,
                    tempEndTime
                );


                return (
                    (item.id !== data.id) &&
                    (item.id !== relativeItem.id) &&
                    (
                        (range1.intersection(range2)).length > 0
                    )
                );
            }
        )
    );
};

const areBothSidesOnEmptyTimeline = (
    subtitles:  TransformedSentence[],
    frameRate: number,
    data: TransformedSentence,
    tempStartTime: number,
    tempEndTime: number,
) => {
    return (
        subtitles.some(
            item => {
                const currStartTimeFrames = secondsToFrameNumber(+item.timeline.startTime, frameRate);
                const currEndTimeFrames = secondsToFrameNumber(+item.timeline.endTime, frameRate);

                return (
                    (item.id !== data.id) &&
                    (currStartTimeFrames >= tempStartTime) &&
                    (currEndTimeFrames <= tempEndTime)
                );
            }
        )
    );
};

export const getOverlappingSubtitleNewPositions = (
    props: OverlappingSubtitlesNewPositionsProps
): OverlappingSubtitlesNewPositions | undefined => {
    const {
        tempStartTime,
        tempEndTime,
        subtitles,
        data,
        frameRate,
    } = props;

    const tempStartTimeInFrames = secondsToFrameNumber(tempStartTime, frameRate);
    const tempEndTimeInFrames = secondsToFrameNumber(tempEndTime, frameRate);

    const isStartTimeOnEmptyTimeline = isOneSideOnEmptyTimeline(
        subtitles,
        frameRate,
        data,
        tempStartTimeInFrames
    );

    const isEndTimeOnEmptyTimeline = isOneSideOnEmptyTimeline(
        subtitles,
        frameRate,
        data,
        tempEndTimeInFrames
    );

    const isOverAnotherSentence = areBothSidesOnEmptyTimeline(
        subtitles,
        frameRate,
        data,
        tempStartTimeInFrames,
        tempEndTimeInFrames
    );

    if(!isStartTimeOnEmptyTimeline && !isEndTimeOnEmptyTimeline && !isOverAnotherSentence) {
        return;
    }

    const lastIndex = subtitles.length - 1;
    const itemDurationInSeconds = +data.timeline.endTime - +data.timeline.startTime;
    const itemDurationInFrame = secondsToFrameNumber(itemDurationInSeconds, frameRate);

    if(isStartTimeOnEmptyTimeline) {
        const relativeRightItemIndex = subtitles.findIndex(
            item => (
                (item.id !== data.id) &&
                (+item.timeline.startTime > tempStartTime)
            )
        );


        const leftItemEndTime = +subtitles[relativeRightItemIndex].timeline.startTime;
        const newEndFrame = secondsToFrameNumber(leftItemEndTime, frameRate);
        const newStartFrame = newEndFrame - itemDurationInFrame;

        const hasOverlappingItemFromLeft = isOverlappingAfterMoved(
            subtitles,
            frameRate,
            data,
            newStartFrame,
            newEndFrame,
            subtitles[relativeRightItemIndex],
        );

        const isLessThanTimelineStart = newStartFrame < 0;

        if(hasOverlappingItemFromLeft || isLessThanTimelineStart) {
            return;
        }

        const newStartTime = frameNumberToSeconds(newStartFrame, frameRate);
        const newEndTime = frameNumberToSeconds(newEndFrame, frameRate);

        return ({
            newStartTime,
            newEndTime
        });
    }

    if(isEndTimeOnEmptyTimeline) {
        const relativeLeftItemIndex = _findLastIndex(subtitles,
            item => (
                (item.id !== data.id) &&
                (+item.timeline.endTime < tempEndTime)
            )
        );

        const leftItemEndTime = +subtitles[relativeLeftItemIndex].timeline.endTime;
        const newStartFrame = secondsToFrameNumber(leftItemEndTime, frameRate); // + 1;
        const newEndFrame = newStartFrame + itemDurationInFrame;

        const hasOverlappingItemFromRight = isOverlappingAfterMoved(
            subtitles,
            frameRate,
            data,
            newStartFrame,
            newEndFrame,
            subtitles[relativeLeftItemIndex],
        );

        const timelineMaxTime = subtitles[lastIndex].timeline.maxEndTime;
        const timelineLastFrame = secondsToFrameNumber(timelineMaxTime, frameRate);
        const isMoreThanTimelineEnd = newEndFrame > timelineLastFrame;

        if(hasOverlappingItemFromRight || isMoreThanTimelineEnd) {
            return;
        }

        const newStartTime = frameNumberToSeconds(newStartFrame, frameRate);
        const newEndTime = frameNumberToSeconds(newEndFrame, frameRate);

        return ({
            newStartTime,
            newEndTime
        });
    }
};

export const getResizeSubtitleNewPosition = (
    props: OverlappingSubtitlesNewPositionsProps
): OverlappingSubtitlesNewPositions | undefined => {
    const {
        tempStartTime,
        tempEndTime,
        subtitles,
        data,
    } = props;

    if(tempStartTime < 0) {
        return;
    }

    if(tempStartTime === data.timeline.startTime) {
        const relativeRightItemIndex = subtitles.findIndex(
            item => (
                (item.id !== data.id) &&
                (+item.timeline.startTime > tempStartTime)
            )
        );

        if(relativeRightItemIndex !== -1) {
            const newEndTime = subtitles[relativeRightItemIndex].timeline.startTime;

            return {
                newStartTime: tempStartTime,
                newEndTime,
            };
        }
    }

    if(tempEndTime === data.timeline.endTime) {
        const relativeLeftItemIndex = _findLastIndex(subtitles,
            item => (
                (item.id !== data.id) &&
                (+item.timeline.endTime < tempEndTime)
            )
        );

        if(relativeLeftItemIndex !== -1) {
            const newStartTime = subtitles[relativeLeftItemIndex].timeline.endTime;

            return {
                newStartTime,
                newEndTime: tempEndTime,
            };
        }
    }
};