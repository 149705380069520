import { createSelector } from 'reselect';
import { State } from 'state/modules';
import {
    ProjectsStudioState,
    SelectedProjectObject,
    SelectedProjectObjectType,
} from './types';

export const getProjectsStudioState = (state: State): ProjectsStudioState =>
    state.projectsStudio;

export const getProjectNavRoute = createSelector(
    getProjectsStudioState,
    (state) => state.projectNavRoute
);

export const getProjectTimelineTime = createSelector(
    getProjectsStudioState,
    (state) => state.timelineTime
);

export const getProjectTimelineHeight = createSelector(
    getProjectsStudioState,
    (state) => state.timelineHeight
);

export const getProjectTimelineSliderValue = createSelector(
    getProjectsStudioState,
    (state) => state.timelineSliderValue
);

export const getSelectedShape = createSelector(
    getProjectsStudioState,
    (state) => state.selectedShape
);

export const getProjectPlaying = createSelector(
    getProjectsStudioState,
    (state) => state.isPlaying
);

export const getPreparedMediaSources = createSelector(
    getProjectsStudioState,
    (state) => state.preparedMediaSources
);

export const getAudioWaveVisibleStatus = createSelector(
    getProjectsStudioState,
    (state) => state.isAudioWaveVisible
);

export const getTimelineSelectedObjects = createSelector(
    getProjectsStudioState,
    (state) => state.timelineSelectedObjects
);

export const getProjectSelectedObject = createSelector(
    getTimelineSelectedObjects,
    (objects: SelectedProjectObject[]) => objects?.[objects?.length - 1]
);

export const getTimelineSelectedAudios = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.AUDIO)
);

export const getTimelineSelectedAudiosWithSubtitles = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.AUDIO_WITH_SUBTITLES)
);

export const getTimelineSelectedVideos = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.VIDEO)
);

export const getTimelineSelectedElements = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.ELEMENT)
);

export const getTimelineSelectedImages = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.IMAGE)
);

export const getTimelineSelectedSentences = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.SENTENCE)
);

export const getTimelineSelectedText = createSelector(
    getTimelineSelectedObjects,
    (items) =>
        items.filter((item) => item.type === SelectedProjectObjectType.TEXT)
);

export const getNavigateToRoute = createSelector(
    getProjectsStudioState,
    (state) => state.navigateToRoute
);

export const getCopiedMedia = createSelector(
    getProjectsStudioState,
    (state) => state.copiedMedia
);
