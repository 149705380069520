import { createSelector } from 'reselect';

import { State } from 'state/modules';
import { AnalysisState } from 'state/modules/analysis';

export const getAnalysisState = (state: State): AnalysisState => state.analysis;

export const getReAnalysisLoading = createSelector(
  getAnalysisState,
  (state) => state.isReAnalysisLoading,
);
