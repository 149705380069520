import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1.85714; // 13px(default width) / 7px(default height) = 1.85714

const BookIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 7;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.867 0.134381C12.6878 -0.0447937 12.3967 -0.0447937 12.2175 0.134381L6.50628 5.85677L0.78389 0.134381C0.604715 -0.0447937 0.313556 -0.0447937 0.134381 0.134381C-0.0447937 0.313556 -0.0447937 0.604715 0.134381 0.78389L6.17033 6.81984C6.25992 6.90943 6.3719 6.95422 6.49508 6.95422C6.60707 6.95422 6.73025 6.90943 6.81984 6.81984L12.8558 0.78389C13.0462 0.604715 13.0462 0.313556 12.867 0.134381Z"
        fill={color}
      />
    </svg>
  );
};

export default BookIcon;
