import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';

import { Checkbox } from 'components/UI';

import {
  CurrentSubscription,
  getAdditionalStorageAddon,
  getCurrentUserPlan,
  Price,
  Product,
} from 'state/modules/payment';

import styles from './styles.module.scss';

interface Props {
  periods: Array<Price>;
  selectedPeriod: Price | null;
  changePeriod: (period: Price) => void;
  isTermsConditionsAccepted?: boolean;
  toggleTermsConditionsAccepted?: () => void;
  description?: string;
  showTermsConditionsToggler?: boolean;
}

const BillingPeriodSelect = (props: Props): JSX.Element => {
  const {
    selectedPeriod,
    periods,
    changePeriod,
    isTermsConditionsAccepted,
    description,
    toggleTermsConditionsAccepted,
    showTermsConditionsToggler,
  } = props;

  const storageAddon = useSelector(getAdditionalStorageAddon) as Product;
  const currentSubscription = useSelector(
    getCurrentUserPlan,
  ) as CurrentSubscription;

  const getAddonPrice = (price: Price, currentStorageAmount: number) => {
    if (currentSubscription?.addon) {
      const newAddonPriceObj = storageAddon?.prices?.find(
        (item: Price) => item.interval === price?.interval,
      ) as Price;

      if (newAddonPriceObj) {
        const currentStorageAmountGb = currentStorageAmount / 1000000000;

        const addonPrice = ((newAddonPriceObj.amount / 100) * currentStorageAmountGb)
          / 100;

        return addonPrice;
      }
      return '0';
    }
  };

  const getPeriodPrice = (item: Price) => {
    let price = '';
    const currency = item.currency === 'usd' ? '$' : item.currency;

    const mainPrice = `${currency}${item.amount / 100}`;

    if (currentSubscription?.addon?.currentStorageAmount) {
      const { currentStorageAmount } = currentSubscription.addon;

      const addonPrice = getAddonPrice(item, currentStorageAmount);

      price = `${mainPrice} + ${currency}${addonPrice} (${prettyBytes(
        currentStorageAmount,
      )} additional storage) / ${item.interval}`;
    } else {
      price = `${mainPrice} / ${item.interval}`;
    }

    return price;
  };

  const renderPeriods = () => periods.map((item: Price) => {
    let periodTitle = item.interval as string;

    if (item.interval === 'month') {
      periodTitle = 'Monthly';
    }

    if (item.interval === 'year') {
      periodTitle = 'Annual';
    }

    const price = getPeriodPrice(item);

    return (
      <div
        className={styles.BillingPeriodSelect__periodItem}
        key={item.id}
      >
        <Checkbox
          checked={item === selectedPeriod}
          title={periodTitle}
          onChange={() => changePeriod(item)}
        />
        <p className={styles.BillingPeriodSelect__itemDescription}>
          {price}
        </p>
      </div>
    );
  });

  const renderTermsConditions = () => {
    if (showTermsConditionsToggler) {
      return (
        <div className={styles.BillingPeriodSelect__termsConditions}>
          <Checkbox
            checked={isTermsConditionsAccepted}
            title="Accept terms and conditions"
            onChange={toggleTermsConditionsAccepted}
          />
          <p
            className={
              styles.BillingPeriodSelect__termsConditionsLink
            }
          >
            (
            <a
              href="https://promomii.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Read here
            </a>
            )
          </p>
        </div>
      );
    }
  };

  return (
    <div className={styles.BillingPeriodSelect}>
      <p className={styles.BillingPeriodSelect__title}>Billing period</p>
      <div className={styles.BillingPeriodSelect__container}>
        {renderPeriods()}
      </div>
      {description ? (
        <p className={styles.BillingPeriodSelect__description}>
          {description}
        </p>
      ) : null}
      {renderTermsConditions()}
    </div>
  );
};

export default BillingPeriodSelect;
