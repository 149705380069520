import produce from 'immer';

import { SignOutAction, UserActionTypes } from 'state/modules/user';
import {
  MetadataAction,
  MetadataActionTypes, 
  MetadataState,
} from './types';


export const initialState: MetadataState = {
  isObjectsLoading: false,
  isCelebsLoading: false,
  isUserDefinedLoading: false,
  isFacesLoading: false,
  isTranscriptLoading: false,
  isFacialAttributesLoading: false,
  isMetadataLoading: false,
  isSearchLoading: false,
  isModeratedLabelsLoading: false,
  labels: [],
  celebs: [],
  userDefined: [],
  facialAttributes: [],
  transcript: [],
  moderatedLabels: [],
  sentencesWithSearchedQuery: [],
  labelsTotal: 0,
  celebsTotal: 0,
  userDefinedTotal: 0,
  facialAttributesTotal: 0,
  facesTotal: 0,
  metadataTotal: 0,
  transcriptTotal: 0,
  shotsTotal: 0,
  moderatedLabelsTotal: 0,
  afterKey: '',
  autocompleteItems: [],
};

export default (
  state: MetadataState = initialState,
  action: MetadataAction | SignOutAction,
): MetadataState => produce(state, (draft) => {
  switch (action.type) {
    case MetadataActionTypes.SET_OBJECTS:
      draft.labels = action.payload;
      draft.isObjectsLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_CELEBS:
      draft.celebs = action.payload;
      draft.isCelebsLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_USER_DEFINED:
      draft.userDefined = action.payload;
      draft.isUserDefinedLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_FACIAL_ATTRIBUTES:
      draft.facialAttributes = action.payload;
      draft.isFacialAttributesLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_MODERATED_LABELS:
      draft.moderatedLabels = action.payload;
      draft.isModeratedLabelsLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_AFTER_KEY:
      draft.afterKey = action.payload;

      return;
    case MetadataActionTypes.GET_TRANSCRIPT_START:
      draft.isMetadataLoading = true;
      draft.isTranscriptLoading = true;

      return;
    case MetadataActionTypes.GET_TRANSCRIPT_FAIL:
      draft.isMetadataLoading = false;
      draft.isTranscriptLoading = false;

      return;
    case MetadataActionTypes.GET_TRANSCRIPT_SUCCESS:
      draft.transcript = action.payload.transcript;
      
      draft.transcriptTotal = action.payload.total;
      draft.isTranscriptLoading = false;
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.SET_TRANSCRIPT_LIST:
      draft.transcript = action.payload;

      return;
    case MetadataActionTypes.SET_TRANSCRIPT_LIST_TOTAL:
      draft.transcriptTotal = action.payload;

      return;
    case MetadataActionTypes.GET_METADATA_START:
      draft.isMetadataLoading = !action.payload;

      return;
    case MetadataActionTypes.GET_METADATA_FAIL:
      draft.isMetadataLoading = false;

      return;
    case MetadataActionTypes.GET_METADATA_CATEGORIES_TOTAL_SUCCESS:
      draft.labelsTotal = action.payload.labelsTotal;
      draft.celebsTotal = action.payload.celebsTotal;
      draft.userDefinedTotal = action.payload.userDefinedTotal;
      draft.facesTotal = action.payload.facesTotal;
      draft.facialAttributesTotal = action.payload.facialAttributesTotal;
      draft.moderatedLabelsTotal = action.payload.moderatedLabelsTotal;
      draft.shotsTotal = action.payload.shotsTotal;

      return;
    case MetadataActionTypes.CLEAR_METADATA:
      return initialState;
    case MetadataActionTypes.GET_AUTOCOMPLETE_START:
      return {
        ...state,
        isMetadataLoading: true,
        isSearchLoading: true,
      };
    case MetadataActionTypes.GET_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autocompleteItems: action.payload.autocomplete,
        isMetadataLoading: false,
        isSearchLoading: false,
      };
    case MetadataActionTypes.GET_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        isMetadataLoading: false,
        isSearchLoading: false,
      };
    case MetadataActionTypes.CLEAR_AUTOCOMPLETE_ITEMS:
      return {
        ...state,
        autocompleteItems: [],
      };

    case MetadataActionTypes.GET_TRANSCRIPT_BY_SEARCH_QUERY_SUCCESS:
      draft.sentencesWithSearchedQuery = action.payload;
      draft.isTranscriptLoading = false;
      draft.isMetadataLoading = false;

      return;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
