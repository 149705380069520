import { IInvoice } from 'interfaces/payment';
import {
    Customer,
    PaymentActionTypes,
    Product,
    UpcomingPlan,
    UserUsageAndTotalSubscriptionData,
    GetPlansAction,
    GetPlansStartAction,
    GetPlansFailAction,
    GetPlansSuccessAction,
    GetUserUsageAction,
    GetUserUsageStartAction,
    GetUserUsageFailAction,
    GetUserUsageSuccessAction,
    GetCustomerAction,
    GetCustomerStartAction,
    GetCustomerFailAction,
    GetCustomerSuccessAction,
    CreateSubscriptionForCustomerAction,
    CreateSubscriptionForCustomerStartAction,
    CreateSubscriptionForCustomerFailAction,
    CreateSubscriptionForCustomerSuccessAction,
    GetUserSubscriptionAction,
    GetUserSubscriptionStartAction,
    GetUserSubscriptionFailAction,
    GetUserSubscriptionSuccessAction,
    AddCustomerCardAction,
    AddCustomerCardStartAction,
    AddCustomerCardFailAction,
    AddCustomerCardSuccessAction,
    CancelUserSubscriptionAction,
    CancelUserSubscriptionStartAction,
    CancelUserSubscriptionFailAction,
    CancelUserSubscriptionSuccessAction,
    AddCreditsAction,
    AddCreditsStartAction,
    AddCreditsFailAction,
    AddCreditsSuccessAction,
    AddDubbingCreditsAction,
    AddDubbingCreditsStartAction,
    AddDubbingCreditsFailAction,
    AddDubbingCreditsSuccessAction,
    UpdateStorageAction,
    UpdateStorageStartAction,
    UpdateStorageFailAction,
    UpdateStorageSuccessAction,
    GetAddonsAction,
    GetAddonsStartAction,
    GetAddonsFailAction,
    GetAddonsSuccessAction,
    SetPreferredCardAction,
    SetPreferredCardStartAction,
    SetPreferredCardFailAction,
    SetPreferredCardSuccessAction,
    PaymentFlowInitAction,
    PaymentFlowInitStartAction,
    PaymentFlowInitFailAction,
    PaymentFlowInitSuccessAction,
    SetSelectedPlanAction,
    ToggleSubscriptionActiveStatusAction,
    GetUpcomingSubscriptionAction,
    GetUpcomingSubscriptionStartAction,
    GetUpcomingSubscriptionFailAction,
    GetUpcomingSubscriptionSuccessAction,
    AddCustomerPaymentMethodAndSubscriptionAction,
    CreateCustomerWithSubscriptionStartAction,
    CreateCustomerWithSubscriptionFailAction,
    CreateCustomerWithSubscriptionSuccessAction,
    SetCardIdToRemoveAction,
    DeleteCustomerCardAction,
    DeleteCustomerCardStartAction,
    DeleteCustomerCardFailAction,
    DeleteCustomerCardSuccessAction,
    CurrentSubscription,
    AddCustomerCardActionPayload,
    CreateSubscriptionForCustomerActionPayload,
    AddCustomerPaymentMethodAndSubscriptionActionPayload,
    SetStripePromiseAction,
    SetupIntentAction,
    ConfirmSetupAction,
    ConfirmSetupBody,
    ConfirmSetupStartAction,
    ConfirmSetupFailAction,
    ConfirmSetupSuccessAction,
    SubscriptionPlanPaymentInfo,
    UpdateUserAddon,
    UpdateUserCredits,
    GetInvoicesAction,
    GetInvoicesStartAction,
    GetInvoicesSuccessAction,
    GetInvoicesFailAction,
    PaymentIntentAction,
    UpdateUserSubscription,
} from './types';

export function getPlans(): GetPlansAction {
    return {
        type: PaymentActionTypes.GET_PLANS,
    };
}

export function getPlansStart(): GetPlansStartAction {
    return {
        type: PaymentActionTypes.GET_PLANS_START,
    };
}

export function getPlansFail(error: any): GetPlansFailAction {
    return {
        type: PaymentActionTypes.GET_PLANS_FAIL,
        payload: error,
    };
}

export function getPlansSuccess(plans: Array<Product>): GetPlansSuccessAction {
    return {
        type: PaymentActionTypes.GET_PLANS_SUCCESS,
        payload: {
            plans,
        },
    };
}

/// ///////////////////////////////////////////////////////////////////

export function getUserUsage(): GetUserUsageAction {
    return {
        type: PaymentActionTypes.GET_USAGE,
    };
}

export function getUserUsageStart(): GetUserUsageStartAction {
    return {
        type: PaymentActionTypes.GET_USAGE_START,
    };
}

export function getUserUsageFail(error: any): GetUserUsageFailAction {
    return {
        type: PaymentActionTypes.GET_USAGE_FAIL,
        payload: error,
    };
}

export function getUserUsageSuccess(
    usageData: UserUsageAndTotalSubscriptionData
): GetUserUsageSuccessAction {
    return {
        type: PaymentActionTypes.GET_USAGE_SUCCESS,
        payload: usageData,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function getCustomer(): GetCustomerAction {
    return {
        type: PaymentActionTypes.GET_CUSTOMER,
    };
}

export function getCustomerStart(): GetCustomerStartAction {
    return {
        type: PaymentActionTypes.GET_CUSTOMER_START,
    };
}

export function getCustomerFail(error: any): GetCustomerFailAction {
    return {
        type: PaymentActionTypes.GET_CUSTOMER_FAIL,
        payload: error,
    };
}

export function getCustomerSuccess(
    customer: Customer
): GetCustomerSuccessAction {
    return {
        type: PaymentActionTypes.GET_CUSTOMER_SUCCESS,
        payload: customer,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function createSubscriptionForCustomer(
    payload: CreateSubscriptionForCustomerActionPayload
): CreateSubscriptionForCustomerAction {
    return {
        type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER,
        payload,
    };
}

export function createSubscriptionForCustomerStart(): CreateSubscriptionForCustomerStartAction {
    return {
        type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_START,
    };
}

export function createSubscriptionForCustomerFail(
    error: any
): CreateSubscriptionForCustomerFailAction {
    return {
        type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_FAIL,
        payload: error,
    };
}

export function createSubscriptionForCustomerSuccess(): CreateSubscriptionForCustomerSuccessAction {
    return {
        type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function getUserSubscription(): GetUserSubscriptionAction {
    return {
        type: PaymentActionTypes.GET_USER_SUBSCRIPTION,
    };
}

export function getUserSubscriptionStart(): GetUserSubscriptionStartAction {
    return {
        type: PaymentActionTypes.GET_USER_SUBSCRIPTION_START,
    };
}

export function getUserSubscriptionFail(
    error: any
): GetUserSubscriptionFailAction {
    return {
        type: PaymentActionTypes.GET_USER_SUBSCRIPTION_FAIL,
        payload: error,
    };
}

export function getUserSubscriptionSuccess(
    subscription: CurrentSubscription
): GetUserSubscriptionSuccessAction {
    return {
        type: PaymentActionTypes.GET_USER_SUBSCRIPTION_SUCCESS,
        payload: subscription,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function addCustomerCard(
    payload: AddCustomerCardActionPayload
): AddCustomerCardAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_CARD,
        payload,
    };
}

export function addCustomerCardStart(): AddCustomerCardStartAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_CARD_START,
    };
}

export function addCustomerCardFail(error: any): AddCustomerCardFailAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_CARD_FAIL,
        payload: error,
    };
}

export function addCustomerCardSuccess(): AddCustomerCardSuccessAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_CARD_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function cancelUserSubscription(
    showCanceledStatus?: boolean
): CancelUserSubscriptionAction {
    return {
        type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION,
        payload: { showCanceledStatus },
    };
}

export function cancelUserSubscriptionStart(): CancelUserSubscriptionStartAction {
    return {
        type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_START,
    };
}

export function cancelUserSubscriptionFail(
    error: any
): CancelUserSubscriptionFailAction {
    return {
        type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_FAIL,
        payload: error,
    };
}

export function cancelUserSubscriptionSuccess(): CancelUserSubscriptionSuccessAction {
    return {
        type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function addCredits(payload: {
    data: UpdateUserCredits;
    paymentInfo?: SubscriptionPlanPaymentInfo;
}): AddCreditsAction {
    return {
        type: PaymentActionTypes.ADD_CREDITS,
        payload,
    };
}

export function addCreditsStart(): AddCreditsStartAction {
    return {
        type: PaymentActionTypes.ADD_CREDITS_START,
    };
}

export function addCreditsFail(error: any): AddCreditsFailAction {
    return {
        type: PaymentActionTypes.ADD_CREDITS_FAIL,
        payload: error,
    };
}

export function addCreditsSuccess(): AddCreditsSuccessAction {
    return {
        type: PaymentActionTypes.ADD_CREDITS_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function addDubbingCredits(payload: {
    data: UpdateUserCredits;
    paymentInfo?: SubscriptionPlanPaymentInfo;
}): AddDubbingCreditsAction {
    return {
        type: PaymentActionTypes.ADD_DUBBING_CREDITS,
        payload,
    };
}

export function addDubbingCreditsStart(): AddDubbingCreditsStartAction {
    return {
        type: PaymentActionTypes.ADD_DUBBING_CREDITS_START,
    };
}

export function addDubbingCreditsFail(error: any): AddDubbingCreditsFailAction {
    return {
        type: PaymentActionTypes.ADD_DUBBING_CREDITS_FAIL,
        payload: error,
    };
}

export function addDubbingCreditsSuccess(): AddDubbingCreditsSuccessAction {
    return {
        type: PaymentActionTypes.ADD_DUBBING_CREDITS_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function updateStorage(payload: {
    data: UpdateUserAddon;
    paymentInfo?: SubscriptionPlanPaymentInfo;
}): UpdateStorageAction {
    return {
        type: PaymentActionTypes.UPDATE_STORAGE,
        payload,
    };
}

export function updateStorageStart(): UpdateStorageStartAction {
    return {
        type: PaymentActionTypes.UPDATE_STORAGE_START,
    };
}

export function updateStorageFail(error: any): UpdateStorageFailAction {
    return {
        type: PaymentActionTypes.UPDATE_STORAGE_FAIL,
        payload: error,
    };
}

export function updateStorageSuccess(): UpdateStorageSuccessAction {
    return {
        type: PaymentActionTypes.UPDATE_STORAGE_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function getAddons(): GetAddonsAction {
    return {
        type: PaymentActionTypes.GET_ADDONS,
    };
}

export function getAddonsStart(): GetAddonsStartAction {
    return {
        type: PaymentActionTypes.GET_ADDONS_START,
    };
}

export function getAddonsFail(error: any): GetAddonsFailAction {
    return {
        type: PaymentActionTypes.GET_ADDONS_FAIL,
        payload: error,
    };
}

export function getAddonsSuccess(
    addons: Array<Product>
): GetAddonsSuccessAction {
    return {
        type: PaymentActionTypes.GET_ADDONS_SUCCESS,
        payload: addons,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function setPreferredCard(cardId: string): SetPreferredCardAction {
    return {
        type: PaymentActionTypes.SET_PREFERRED_CARD,
        payload: cardId,
    };
}

export function setPreferredCardStart(): SetPreferredCardStartAction {
    return {
        type: PaymentActionTypes.SET_PREFERRED_CARD_START,
    };
}

export function setPreferredCardFail(error: any): SetPreferredCardFailAction {
    return {
        type: PaymentActionTypes.SET_PREFERRED_CARD_FAIL,
        payload: error,
    };
}

export function setPreferredCardSuccess(): SetPreferredCardSuccessAction {
    return {
        type: PaymentActionTypes.SET_PREFERRED_CARD_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function paymentFlowInit(): PaymentFlowInitAction {
    return {
        type: PaymentActionTypes.PAYMENT_FLOW_INIT,
    };
}

export function paymentFlowInitStart(): PaymentFlowInitStartAction {
    return {
        type: PaymentActionTypes.PAYMENT_FLOW_INIT_START,
    };
}

export function paymentFlowInitFail(error: any): PaymentFlowInitFailAction {
    return {
        type: PaymentActionTypes.PAYMENT_FLOW_INIT_FAIL,
        payload: error,
    };
}

export function paymentFlowInitSuccess(): PaymentFlowInitSuccessAction {
    return {
        type: PaymentActionTypes.PAYMENT_FLOW_INIT_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

// export function selectPlan(plan: Product | null): SelectPlanAction {
//     return {
//         type: PaymentActionTypes.SELECT_PLAN,
//         payload: plan,
//     };
// }

export function setSelectedPlan(plan: Product | null): SetSelectedPlanAction {
    return {
        type: PaymentActionTypes.SET_SELECTED_PLAN,
        payload: plan,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function toggleSubscriptionActiveStatus(): ToggleSubscriptionActiveStatusAction {
    return {
        type: PaymentActionTypes.TOGGLE_SUBSCRIPTION_ACTIVE_STATUS,
    };
}

/// ///////////////////////////////////////////////////////////////////

// export function activateSubscription(): ActivateSubscriptionAction {
//     return {
//         type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION,
//     };
// }

// export function activateSubscriptionStart(): ActivateSubscriptionStartAction {
//     return {
//         type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_START,
//     };
// }

// export function activateSubscriptionFail(
//     error: any
// ): ActivateSubscriptionFailAction {
//     return {
//         type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_FAIL,
//         payload: error,
//     };
// }

// export function activateSubscriptionSuccess(): ActivateSubscriptionSuccessAction {
//     return {
//         type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS,
//     };
// }

/// ///////////////////////////////////////////////////////////////////

export function getUpcomingSubscription(): GetUpcomingSubscriptionAction {
    return {
        type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION,
    };
}

export function getUpcomingSubscriptionStart(): GetUpcomingSubscriptionStartAction {
    return {
        type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_START,
    };
}

export function getUpcomingSubscriptionFail(
    error: any
): GetUpcomingSubscriptionFailAction {
    return {
        type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_FAIL,
        payload: error,
    };
}

export function getUpcomingSubscriptionSuccess(
    subscription: UpcomingPlan
): GetUpcomingSubscriptionSuccessAction {
    return {
        type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_SUCCESS,
        payload: subscription,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function createCustomerWithSubscription(
    payload: AddCustomerPaymentMethodAndSubscriptionActionPayload
): AddCustomerPaymentMethodAndSubscriptionAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION,
        payload,
    };
}

export function createCustomerWithSubscriptionStart(): CreateCustomerWithSubscriptionStartAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_START,
    };
}

export function createCustomerWithSubscriptionFail(
    error: any
): CreateCustomerWithSubscriptionFailAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_FAIL,
        payload: error,
    };
}

export function createCustomerWithSubscriptionSuccess(): CreateCustomerWithSubscriptionSuccessAction {
    return {
        type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function setCardIdToRemove(id: string): SetCardIdToRemoveAction {
    return {
        type: PaymentActionTypes.SET_CARD_ID_TO_REMOVE,
        payload: id,
    };
}

/// ///////////////////////////////////////////////////////////////////

export function deleteCustomerCard(
    cardId: string,
    isDeletionConfirmed?: boolean
): DeleteCustomerCardAction {
    return {
        type: PaymentActionTypes.DELETE_CUSTOMER_CARD,
        payload: { cardId, isDeletionConfirmed },
    };
}

export function deleteCustomerCardStart(): DeleteCustomerCardStartAction {
    return {
        type: PaymentActionTypes.DELETE_CUSTOMER_CARD_START,
    };
}

export function deleteCustomerCardFail(
    error: any
): DeleteCustomerCardFailAction {
    return {
        type: PaymentActionTypes.DELETE_CUSTOMER_CARD_FAIL,
        payload: error,
    };
}

export function deleteCustomerCardSuccess(): DeleteCustomerCardSuccessAction {
    return {
        type: PaymentActionTypes.DELETE_CUSTOMER_CARD_SUCCESS,
    };
}

export function setupIntent(
    cb: (error: string | null, data?: any) => void
): SetupIntentAction {
    return {
        type: PaymentActionTypes.SETUP_INTENT,
        cb,
    };
}

export function paymentIntent(
    cb: (error: string | null, data?: any) => void,
    payload: UpdateUserSubscription
): PaymentIntentAction {
    return {
        type: PaymentActionTypes.PAYMENT_INTENT,
        cb,
        payload,
    };
}

export function confirmSetup(payload: {
    data: ConfirmSetupBody;
    paymentInfo?: SubscriptionPlanPaymentInfo;
}): ConfirmSetupAction {
    return {
        type: PaymentActionTypes.CONFIRM_SETUP,
        payload,
    };
}

export function confirmSetupStart(): ConfirmSetupStartAction {
    return {
        type: PaymentActionTypes.CONFIRM_SETUP_START,
    };
}

export function confirmSetupFail(error: any): ConfirmSetupFailAction {
    return {
        type: PaymentActionTypes.CONFIRM_SETUP_FAIL,
        payload: error,
    };
}

export function confirmSetupSuccess(): ConfirmSetupSuccessAction {
    return {
        type: PaymentActionTypes.CONFIRM_SETUP_SUCCESS,
    };
}

export function setStripePromise(payload: any): SetStripePromiseAction {
    return {
        type: PaymentActionTypes.SET_STRIPE_PROMISE,
        payload,
    };
}

// invoices

export function getInvoices(): GetInvoicesAction {
    return {
        type: PaymentActionTypes.GET_INVOICES,
    };
}

export function getInvoicesStart(): GetInvoicesStartAction {
    return {
        type: PaymentActionTypes.GET_INVOICES_START,
    };
}

export function getInvoicesSuccess(
    payload: IInvoice[]
): GetInvoicesSuccessAction {
    return {
        type: PaymentActionTypes.GET_INVOICES_SUCCESS,
        payload,
    };
}

export function getInvoicesFail(payload: any): GetInvoicesFailAction {
    return {
        type: PaymentActionTypes.GET_INVOICES_FAIL,
        payload,
    };
}
