import { IconProps } from '../index';

const SkipForwardIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.50345 18.7469C4.53816 19.6156 3 18.9306 3 17.6319L3 6.36803C3 5.06937 4.53817 4.38434 5.50345 5.25309L11.7612 10.885C12.4232 11.4809 12.4232 12.5191 11.7612 13.1149L5.50345 18.7469ZM15.3602 17.3479C14.366 18.0438 13 17.3325 13 16.119L13 7.88097C13 6.66744 14.366 5.95621 15.3602 6.65212L21.2445 10.7711C22.0976 11.3683 22.0976 12.6317 21.2445 13.2288L15.3602 17.3479Z"
                fill={color}
            />
        </svg>
    );
};

export default SkipForwardIconNew;


