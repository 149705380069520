import { IconProps } from './index';

const PremiumIcon = ({ size, color, secondColor }: IconProps): JSX.Element => {
    let height = 18;

    if(size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width={16}
                height={16}
                rx={height / 2}
                fill={color}
                fillOpacity=".5"
            />
            <path
                d="m5.196 8.804 3.042-4.767c.169-.265.58-.124.552.189l-.28 3.11a.1.1 0 0 0 .1.108h2.005a.2.2 0 0 1 .164.315l-3.017 4.298c-.178.253-.576.105-.544-.203l.27-2.633a.1.1 0 0 0-.099-.11H5.365a.2.2 0 0 1-.169-.307z"
                fill={secondColor}
            />
        </svg>
    );
};

export default PremiumIcon;