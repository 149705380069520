import { IconProps } from './index';

const AudioWaveForm = ({ size, color }: IconProps): JSX.Element => {
    let height = 22;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 15V9M12 20V4M8 17V7M20 9V15M16 7L16 17"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AudioWaveForm;