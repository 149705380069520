import { ProductPrepareTypes } from 'interfaces/preferences';
import {
    AppActionTypes,
    SetStatusBarActiveStatusAction,
    TourStatusesBody,
    AppInitAction,
    CheckBrowserInfoAction,
    SetBrowserRecommendationVisibilityAction,
    UpdateAppIntroductoryTourStatusAction,
    SetAppIntroductoryTourStatusAction,
    CheckAppIntroductionTourStatusAction,
    SetBurningSubtitlesTourStatusAction,
    BurningSubtitlesTourStatus,
    SetVideoEditingTourStatusAction,
    VideoEditingTourStatus,
    ChangeLocationAction,
    AppInitStartAction,
    AppInitSuccessAction,
    AppInitFailAction,
    AppInitActionPayload,
} from './types';

export function setStatusBarActiveStatus(
    isActive: boolean
): SetStatusBarActiveStatusAction {
    return {
        type: AppActionTypes.SET_STATUS_BAR_ACTIVE_STATUS,
        payload: isActive,
    };
}

export function appInit(payload: AppInitActionPayload): AppInitAction {
    return {
        type: AppActionTypes.APP_INIT,
        payload,
    };
}

export function appInitStart(): AppInitStartAction {
    return {
        type: AppActionTypes.APP_INIT_START,
    };
}

export function appInitSuccess(): AppInitSuccessAction {
    return {
        type: AppActionTypes.APP_INIT_SUCCESS,
    };
}

export function appInitFail(): AppInitFailAction {
    return {
        type: AppActionTypes.APP_INIT_FAIL,
    };
}

export function checkBrowserInfo(): CheckBrowserInfoAction {
    return {
        type: AppActionTypes.CHECK_BROWSER_INFO,
    };
}

export function setBrowserRecommendationVisibility(
    isVisible: boolean
): SetBrowserRecommendationVisibilityAction {
    return {
        type: AppActionTypes.SET_BROWSER_RECOMMENDATION_VISIBILITY,
        payload: isVisible,
    };
}

export function updateAppIntroductoryTourStatus(
    statuses: TourStatusesBody,
    isAppTourSkipped?: boolean
): UpdateAppIntroductoryTourStatusAction {
    return {
        type: AppActionTypes.UPDATE_APP_INTRODUCTORY_TOUR_STATUS,
        payload: {
            statuses,
            isAppTourSkipped,
        },
    };
}

export function setAppIntroductoryTourStatus(
    tourStatus: TourStatusesBody
): SetAppIntroductoryTourStatusAction {
    return {
        type: AppActionTypes.SET_APP_INTRODUCTORY_TOUR_STATUS,
        payload: tourStatus,
    };
}

export function checkAppIntroductionTourStatus(): CheckAppIntroductionTourStatusAction {
    return {
        type: AppActionTypes.CHECK_APP_INTRODUCTION_TOUR_STATUS,
    };
}

export function setBurningSubtitlesTourStatus(
    payload: BurningSubtitlesTourStatus
): SetBurningSubtitlesTourStatusAction {
    return {
        type: AppActionTypes.SET_BURNING_SUBTITLES_TOUR_STATUS,
        payload,
    };
}

export function setVideoEditingTourStatusAction(
    payload: VideoEditingTourStatus
): SetVideoEditingTourStatusAction {
    return {
        type: AppActionTypes.SET_VIDEO_EDITING_TOUR_STATUS,
        payload,
    };
}

export function changeLocation(
    payload: ProductPrepareTypes
): ChangeLocationAction {
    return {
        type: AppActionTypes.CHANGE_LOCATION,
        payload,
    };
}
