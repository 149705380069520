import { IconProps } from "./index";
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 33 / 32;

export const ManualSettingsIcon = ({ size, color, secondColor }: IconProps) => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.7002 6.40039L16.5002 6.40039M3.7002 16.0004H16.5002M3.7002 25.6004H10.1002M16.5002 25.6004L29.3002 25.6004M22.9002 16.0004H29.3002M22.9002 6.40039L29.3002 6.40039"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9004 9.60019V6.4002V3.2002M16.5004 19.2002V16.0002V12.8002M10.9004 28.8002V22.4002"
                stroke={secondColor}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ManualSettingsIcon;