import { IconProps } from './index';

const UndoIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.95377 4.80001L2.3999 9.4155M2.3999 9.4155L6.95377 14.031M2.3999 9.4155L17.5999 9.4155C19.809 9.41551 21.5999 11.2064 21.5999 13.4155L21.5999 17.2"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UndoIconNew;


