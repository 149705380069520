export interface UploadingProgress {
    loaded: number;
    total: number;
}

export enum UploadMediaType {
  VIDEO,
  IMAGE,
  AUDIO
}
export interface UploadingMedia {
    autoAnalyze: boolean;
    categories: Array<string>;
    collectionId: string;
    file: File;
    description: string;
    id: string;
    lastModifiedDate: string | number | Date;
    size: number;
    title: string;
    transcriptLanguage: string | null;
    type: string;
}

export interface UploadingStatus {
    currentProgress: number;
    id: string;
    isLoadig?: boolean;
    remainingAmount: number;
    error?: string;
}

export interface UploadingFace {
    file: File;
    title: string;
    id: string;
}

export interface RawUploadingFileMetadata {
    transcriptLanguage?: string | null;
    autoAnalyze?: boolean;
    categories?: Array<string>;
    collectionId: string;
    imageName: string;
    videoTitle?: string;
    videoDescription?: string;
}

export interface TransformedUploadingFileMetadata {
    'audio-language'?: string;
    'auto-analyze'?: string;
    categories?: string;
    'collection-id'?: string;
    'image-name'?: string;
    'video-title'?: string;
    'video-description'?: string;
    'video-fps'?: number | string;
}

export interface UploadingVideoDataForUpdate {
    value: string;
    videoId: string;
    field: string;
}

export interface UploadingMediaDurationItem {
  duration: number;
  id: string;
}
