import { EdlExportFpsOption } from "./edlExport";

export enum OFFSET_TYPES {
    MS = 'MS',
    FF = 'FF',
}

export enum OFFSET_MASK {
    MS = '99:99:99.999',
    FF = '99:99:99:99',
}

export enum OFFSET_MASK_STRING {
    MS = 'HH:mm:ss.SSS',
    FF = 'HH:mm:ss:FF',
}

export interface OffsetSettings {
  id: string;
  offsetType: OFFSET_TYPES;
  offsetValue: string;
  videoName: string;
}
 
export interface ExportedSubtitledVideoData {
  signetUrl: string;
  s3FilePath: string;
  lastJobSettings: {
    color: string;
    fontSize: string;
  }
}
