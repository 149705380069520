import { IconProps } from './index';

const SettingsIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 30;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.4937 10.4125C31.6937 10.9563 31.525 11.5625 31.0937 11.95L28.3875 14.4125C28.4562 14.9313 28.4937 15.4625 28.4937 16C28.4937 16.5375 28.4562 17.0688 28.3875 17.5875L31.0937 20.05C31.525 20.4375 31.6937 21.0437 31.4937 21.5875C31.2187 22.3312 30.8875 23.0438 30.5062 23.7313L30.2125 24.2375C29.8 24.925 29.3375 25.575 28.8312 26.1875C28.4625 26.6375 27.85 26.7875 27.3 26.6125L23.8187 25.5063C22.9812 26.15 22.0562 26.6875 21.0687 27.0938L20.2875 30.6625C20.1625 31.2313 19.725 31.6812 19.15 31.775C18.2875 31.9187 17.4 31.9937 16.4937 31.9937C15.5875 31.9937 14.7 31.9187 13.8375 31.775C13.2625 31.6812 12.825 31.2313 12.7 30.6625L11.9187 27.0938C10.9312 26.6875 10.0062 26.15 9.16874 25.5063L5.69374 26.6187C5.14374 26.7937 4.53124 26.6375 4.16249 26.1938C3.65624 25.5813 3.19374 24.9312 2.78124 24.2437L2.48749 23.7375C2.10624 23.05 1.77499 22.3375 1.49999 21.5938C1.29999 21.05 1.46874 20.4438 1.89999 20.0562L4.60624 17.5938C4.53749 17.0688 4.49999 16.5375 4.49999 16C4.49999 15.4625 4.53749 14.9313 4.60624 14.4125L1.89999 11.95C1.46874 11.5625 1.29999 10.9563 1.49999 10.4125C1.77499 9.66875 2.10624 8.95625 2.48749 8.26875L2.78124 7.7625C3.19374 7.075 3.65624 6.425 4.16249 5.8125C4.53124 5.3625 5.14374 5.2125 5.69374 5.3875L9.17499 6.49375C10.0125 5.85 10.9375 5.3125 11.925 4.90625L12.7062 1.3375C12.8312 0.76875 13.2687 0.31875 13.8437 0.225C14.7062 0.075 15.5937 0 16.5 0C17.4062 0 18.2937 0.075 19.1562 0.21875C19.7312 0.3125 20.1687 0.7625 20.2937 1.33125L21.075 4.9C22.0625 5.30625 22.9875 5.84375 23.825 6.4875L27.3062 5.38125C27.8562 5.20625 28.4687 5.3625 28.8375 5.80625C29.3437 6.41875 29.8062 7.06875 30.2187 7.75625L30.5125 8.2625C30.8937 8.95 31.225 9.6625 31.5 10.4062L31.4937 10.4125ZM16.5 21C19.2625 21 21.5 18.7625 21.5 16C21.5 13.2375 19.2625 11 16.5 11C13.7375 11 11.5 13.2375 11.5 16C11.5 18.7625 13.7375 21 16.5 21Z"
                fill={color}
            />
        </svg>
    );
};

export default SettingsIcon;
