import { createSelector } from 'reselect';

import {
    TransformedGlobalSearchResult,
    TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';

import { MediaFile } from 'interfaces/videos';

import { State } from 'state/modules';

import { getVideoExplorerVideoList } from 'state/modules/videoExplorer';
import { GlobalSearchState } from 'state/modules/globalSearch';
import { TransformedGlobalSearchSentence } from './types';

export const getGlobalSearchState = (state: State): GlobalSearchState =>
    state.globalSearch;

export const getGlobalSearchResultsList = createSelector(
    getGlobalSearchState,
    (state) => state.globalSearchResults
);

export const getTranscriptGlobalSearchResultsList = createSelector(
    getGlobalSearchState,
    (state) => state.transcriptGlobalSearchResults
);

export const getGlobalSearchResultsById = (id: string) =>
    createSelector(
        getGlobalSearchResultsList,
        (globalSearchResult: Array<TransformedGlobalSearchResult>) =>
            globalSearchResult.find(
                (globalSearchResultItem: TransformedGlobalSearchResult) =>
                    globalSearchResultItem.id === id
            )
    );

export const getTranscriptGlobalSearchResultsById = (id: string) =>
    createSelector(
        getTranscriptGlobalSearchResultsList,
        (transcriptGlobalSearchResults) =>
            transcriptGlobalSearchResults.filter(
                (
                    transcriptGlobalSearchResult: TransformedGlobalSearchSentence
                ) => transcriptGlobalSearchResult.videoId === id
            )
    );

export const getTotalAmountOfGlobalSearchResultsById = (id: string) =>
    createSelector(
        getGlobalSearchResultsById(id) as any,
        getTranscriptGlobalSearchResultsById(id),
        (globalSearchResult, globalSearchTranscriptResult) => {
            let count = 0;

            if (globalSearchResult) {
                const resultsCountArray = globalSearchResult.data.map(
                    (item: TransformedGlobalSearchResultItem) => item.count
                );

                const reducer = (accumulator: number, currentValue: number) =>
                    accumulator + currentValue;

                count = resultsCountArray.reduce(reducer, 0);
            }

            if (globalSearchTranscriptResult) {
                count += globalSearchTranscriptResult.length;
            }

            return count || 0;
        }
    );

export const getGlobalSearchResultsLoading = createSelector(
    getGlobalSearchState,
    (state) => state.isGlobalSearchLoading
);

export const getGlobalSearchSelectedCategory = createSelector(
    getGlobalSearchState,
    (state) => state.selectedCategory
);

export const getGlobalSearchMinConfidence = createSelector(
    getGlobalSearchState,
    (state) => state.minConfidence
);

export const getGlobalSearchSearchQuery = createSelector(
    getGlobalSearchState,
    (state) => state.searchQuery
);

export const getGlobalSearchSearchCategories = createSelector(
    getGlobalSearchState,
    (state) => state.categories
);

export const getVideosWithGlobalSearchResults = createSelector(
    [
        getVideoExplorerVideoList,
        getGlobalSearchResultsList,
        getTranscriptGlobalSearchResultsList,
    ],
    (videos, globalSearchResults, globalSearchTranscriptResults) =>
        videos.filter((video: MediaFile) => {
            const hasGlobalSearchResult = globalSearchResults.find(
                (globalSearchResult: TransformedGlobalSearchResult) =>
                    video.id === globalSearchResult.id
            );

            if (hasGlobalSearchResult) {
                return video;
            }

            const hasGlobalSearchTranscriptResult =
                globalSearchTranscriptResults.find(
                    (
                        globalSearchTranscriptResult: TransformedGlobalSearchSentence
                    ) => video.id === globalSearchTranscriptResult.videoId
                );

            if (hasGlobalSearchTranscriptResult) {
                return video;
            }
        })
);

export const getVideoIdsWithGlobalSearchResults = createSelector(
    getGlobalSearchState,
    (state) => state.videoIdsWithGlobalSearchResults
);

export const getVideoIdsWithTranscriptGlobalSearchResults = createSelector(
    getGlobalSearchState,
    (state) => state.videoIdsWithTranscriptGlobalSearchResults
);
