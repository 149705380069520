import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import uniqBy from 'lodash.uniqby';

import StockMediaClient from 'services/api/stockMedia';

import * as stockMediaActions from 'state/modules/stockMedia/actions';

import {
    StockMediaCollection,
    StockMedia,
    StockSong,
    StockMediaCategory,
    StockSongTag,
    CategorizedStockVideos,
    CategorizedStockImages,
    CategorizedStockSongs,
} from 'interfaces/stockMedia';
import { ProjectVideo } from 'interfaces/projects';
import {
    GetCategorizedStockVideosAction,
    GetStockImagesAction,
    GetStockVideosAction,
    StockMediaActionTypes,
} from './types';
import {
    getStockMediaImages,
    getStockImagesSearchQuery,
    getStockVideosSearchQuery,
    getStockMediaVideos,
    getStockImageSelectedCollection,
    getStockVideoSelectedCollection,
    getStockVideosPreviews,
    getStockImagesPreviews,
    getStockImagesListPage,
    getStockVideosListPage,
    getStockVideosThumbnailPreviewsList,
    getStockMediaCategoriesList,
    getStockAudiosTags,
    getStockSongsSearchQuery,
    // getStockAudiosPlaylists,
} from './selectors';
import { getProjectStockVideos } from '../projects';
import {
    AUDIO_CATEGORIES,
    IMAGE_CATEGORIES,
    VIDEO_CATEGORIES
} from '../../../constants/stock';

// images

export function* handleGetStockImages(action: GetStockImagesAction): Generator {
    yield put(stockMediaActions.getStockImagesStart());

    const isLoadMore = action?.payload?.isLoadMore;

    try {
        let page = (yield select(getStockImagesListPage)) as number;
        const oldImages = (yield select(getStockMediaImages)) as StockMedia[];
        const searchQuery = (yield select(getStockImagesSearchQuery)) as string;
        const collection = (yield select(
            getStockImageSelectedCollection
        )) as StockMediaCollection | null;
        const previews = (yield select(getStockImagesPreviews)) as StockMedia[];

        if (isLoadMore) {
            page += 1;
        } else {
            page = 1;
        }

        const res = (yield call(StockMediaClient.getImages, {
            offset: page,
            phrase: searchQuery,
            collection: collection?.code,
        })) as AxiosResponse;

        let images = res.data.images;
        const total = res.data.result_count;

        if (!previews.length) {
            yield put(stockMediaActions.setStockImagesPreviews(images));
        }

        if (isLoadMore) {
            images = uniqBy([...oldImages, ...images], 'id');
        }

        yield put(
            stockMediaActions.setStockImages({
                images,
                total,
            })
        );
        yield put(stockMediaActions.setStockImagesListPage(page));
    } catch (error) {
        console.log({ error });
        yield put(stockMediaActions.getStockImagesFail(error));
    }
}

export function* handleGetCategorizedStockImages(): Generator {
    yield put(stockMediaActions.getStockImagesStart());

    try {
        const fetchSagas = IMAGE_CATEGORIES.map(category => {
            try {
                const response = call(StockMediaClient.getImages, {
                    offset: 1,
                    phrase: category,
                });
                return response;
            } catch (error) {
                console.error(`Error fetching videos for category ${category}:`, error);
                return null;
            }
        });

        const responses = (yield all(fetchSagas)) as any[];

        const results = {} as CategorizedStockImages;
        for (let i = 0; i < IMAGE_CATEGORIES.length; i++) {
            results[IMAGE_CATEGORIES[i]] = responses[i].data;
        }

        yield put(
            stockMediaActions.setCategorizedStockImages({
                categorizedImages: results,
            })
        );
    } catch(error) {
        console.log({ error });
        yield put(stockMediaActions.getStockImagesFail(error));
    }
}

// videos

export function* handleGetStockVideos(action: GetStockVideosAction): Generator {
    yield put(stockMediaActions.getStockVideosStart());

    const isLoadMore = action?.payload?.isLoadMore;

    try {
        let page = (yield select(getStockVideosListPage)) as number;
        const oldVideos = (yield select(getStockMediaVideos)) as any[];
        const searchQuery = (yield select(getStockVideosSearchQuery)) as string;
        const previews = (yield select(getStockVideosPreviews)) as StockMedia[];
        const collection = (yield select(
            getStockVideoSelectedCollection
        )) as StockMediaCollection | null;

        if (isLoadMore) {
            page += 1;
        } else {
            page = 1;
        }

        const res = (yield call(StockMediaClient.getVideos, {
            offset: page,
            phrase: searchQuery,
            collection: collection?.code,
        })) as AxiosResponse;

        let videos = res.data.videos;
        const total = res.data.result_count;

        if (!previews.length) {
            yield put(stockMediaActions.setStockVideosPreviews(videos));
        }

        if (isLoadMore) {
            videos = uniqBy([...oldVideos, ...videos], 'id');
        }

        yield put(
            stockMediaActions.setStockVideos({
                videos,
                total,
            })
        );
        yield put(stockMediaActions.setStockVideosListPage(page));
    } catch (error) {
        console.log({ error });
        yield put(stockMediaActions.getStockVideosFail(error));
    }
}

export function* handleGetCategorizedStockVideos(
    action: GetCategorizedStockVideosAction
): Generator {
    yield put(stockMediaActions.getStockVideosStart());

    try {
        const isOneCategory = !!action?.payload?.isOneCategory;

        const categories = isOneCategory ? [VIDEO_CATEGORIES[0]] : VIDEO_CATEGORIES;

        const fetchSagas = categories.map(category => {
            try {
                const response = call(StockMediaClient.getVideos, {
                    offset: 1,
                    phrase: category,
                });
                return response;
            } catch (error) {
                console.error(`Error fetching videos for category ${category}:`, error);
                return null;
            }
        });

        const responses = (yield all(fetchSagas)) as any[];

        const results = {} as CategorizedStockVideos;
        for (let i = 0; i < categories.length; i++) {
            results[categories[i]] = responses[i].data;
        }

        yield put(
            stockMediaActions.setCategorizedStockVideosPreviews({
                categorizedVideos: results,
            })
        );

        yield put(
            stockMediaActions.setCategorizedStockVideos({
                categorizedVideos: results,
            })
        );
    } catch(error) {
        console.log({ error });
        yield put(stockMediaActions.getStockVideosFail(error));
    }
}

// collections

export function* handleGetCollections(): Generator {
    try {
        const res = (yield call(
            StockMediaClient.getCollections
        )) as AxiosResponse;

        const data = res.data.collections;

        yield put(stockMediaActions.setStockMediaCollections(data));
    } catch (error) {
        console.log({ error });
    }
}

// audio

export function* handleGetStockAudioSongs(): Generator {
    try {
        const stockSongsSearchQuery = (yield select(
            getStockSongsSearchQuery,
        )) as string;

        const songsRes = (yield call(StockMediaClient.getSongs, {
            search: stockSongsSearchQuery || '',
            page: 1,
            size: 3,
        })) as AxiosResponse;

        const songs = songsRes.data.data as StockSong[];
        const included = songsRes.data.included;

        const transformedSongs = songs.map((item) => {
            const audioFileId = item.relationships.audio_files.data[0].id;
            const artistId = item.relationships.artists.data[0].id;

            const audio = included.find(
                (inclededItem: any) => inclededItem.id === audioFileId
            );

            const artist = included.find(
                (inclededItem: any) => inclededItem.id === artistId
            );

            return {
                ...item,
                audio,
                artist,
            };
        });

        yield put(stockMediaActions.setStockAudioSongs(transformedSongs));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetCategorizedStockAudioSongs(): Generator {
    try {
        const fetchSagas = AUDIO_CATEGORIES.map(category => {
                try {
                    const response = call(StockMediaClient.getSongs, {
                        search: category,
                        page: 1,
                        size: 3,
                    })
                    return response;
                } catch (error) {
                    console.error(`Error fetching videos for category ${category}:`, error);
                    return null;
                }
            }
        );

        const responses = (yield all(fetchSagas)) as any[];

        const results = {} as CategorizedStockSongs;

        for (let i = 0; i < AUDIO_CATEGORIES.length; i++) {
            const songs = responses[i].data.data as StockSong[];
            const included = responses[i].data.included;

            const transformedSongs = songs.map((item) => {
                const audioFileId = item.relationships.audio_files.data[0].id;
                const artistId = item.relationships.artists.data[0].id;

                const audio = included.find(
                    (includedItem: any) => includedItem.id === audioFileId
                );

                const artist = included.find(
                    (includedItem: any) => includedItem.id === artistId
                );

                return {
                    ...item,
                    audio,
                    artist,
                };
            });

            results[AUDIO_CATEGORIES[i]] = transformedSongs;
        }

        yield put(
            stockMediaActions.setCategorizedStockAudioSongs({
                categorizedSongs: results,
            })
        );
    } catch(error) {
        console.log({ error });
    }
}

// sound-effects

export function* handleGetStockSoundEffects(): Generator {
    try {
        const effectsRes = (yield call(StockMediaClient.getSoundEffects, {
            search: '',
            page: 1,
            size: 3,
        })) as AxiosResponse;

        const effectsList = effectsRes.data.data;

        yield put(stockMediaActions.setStockAudioEffects(effectsList));
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetStockAudioPlaylists(): Generator {
    try {
        const playlistsRes = (yield call(
            StockMediaClient.getAudioPlaylists
        )) as AxiosResponse;

        yield put(
            stockMediaActions.setStockAudioPlaylists(playlistsRes.data.data)
        );

        // const oldList = (yield select(
        //     getStockAudiosPlaylists
        // )) as StockPlaylist[];
        // const currentCount = oldList.length;
        // const page = Math.ceil(currentCount / 100) + 1;

        // const res = (yield call(
        //     StockMediaClient.getAudioPlaylists,
        //     page
        // )) as AxiosResponse;

        // const data = res.data.data;
        // const total = res.data.links.meta.total_count;

        // const updatedList = [...oldList, ...data];
        // const count = updatedList.length;

        // yield put(stockMediaActions.setStockAudioPlaylists(updatedList));

        // if (count < total) {
        //     yield put(stockMediaActions.getStockAudioPlaylists());
        // }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetStockAudioTags(): Generator {
    try {
        const oldList = (yield select(getStockAudiosTags)) as StockSongTag[];
        const currentCount = oldList.length;
        const page = Math.ceil(currentCount / 100) + 1;

        const res = (yield call(
            StockMediaClient.getAudioTags,
            page
        )) as AxiosResponse;

        const data = res.data.data;
        const total = res.data.links.meta.total_count;

        const updatedList = [...oldList, ...data];
        const count = updatedList.length;

        yield put(stockMediaActions.setStockAudioTags(updatedList));

        if (count < total) {
            yield put(stockMediaActions.getStockAudioTags());
        }
    } catch (error) {
        console.log({ error });
    }
}

export const getStockVideoPreviewTimestamps = (video: ProjectVideo) => {
    const mediaInfo = video.mediaInfo;

    const timestampsArray = [] as number[];

    const { frameCount, framerate, duration } = mediaInfo;

    const count = duration > 20 ? 20 : Math.trunc(duration);

    const framesInOneImage = frameCount / count;

    let currentFramesCount = 0;

    for (let index = 0; index < count; index++) {
        if (index === 0) {
            timestampsArray.push(0.01);
        } else {
            timestampsArray.push(currentFramesCount / framerate);
        }

        currentFramesCount += framesInOneImage;
    }

    return {
        id: video.mediaSourcesId,
        timestamps: timestampsArray,
        path: video.stockMediaUrl || '',
    };
};

export const getStockVideosPreviewsTimestamps = (videos: ProjectVideo[]) => {
    const videosPreviewsTimestamps = [] as any;

    videos.forEach((video) => {
        const timestamps = getStockVideoPreviewTimestamps(video);

        videosPreviewsTimestamps.push(timestamps);
    });

    return videosPreviewsTimestamps;
};

export function* createStockVideosPreviewImages(): Generator {
    try {
        const videos = (yield select(getProjectStockVideos)) as ProjectVideo[];

        const previwes = (yield select(
            getStockVideosThumbnailPreviewsList
        )) as any[];
        const previwesIds = previwes.map((item) => item.id);

        let uniqVideos = uniqBy(videos, 'mediaSourcesId').filter(
            (video) => !previwesIds.includes(video.mediaSourcesId)
        );

        const previewsTimestamps = getStockVideosPreviewsTimestamps(uniqVideos);

        // const images = (yield all(
        //     previewsTimestamps.map((preview: any) =>
        //         getStockVideosPreviewImages(preview)
        //     )
        // )) as any[];

        const updatedImages = [...previwes, ...previewsTimestamps];

        if (previewsTimestamps) {
            yield put(
                stockMediaActions.setStockVideosThumbnailPreviews(updatedImages)
            );
        }
    } catch (error) {
        console.log({ error });
    }
}

// Categories

export function* handleGetStockMediaCategoreis(): Generator {
    try {
        const oldList = (yield select(
            getStockMediaCategoriesList
        )) as StockMediaCategory[];
        const currentCount = oldList.length;
        const page = Math.ceil(currentCount / 100) + 1;

        const res = (yield call(
            StockMediaClient.getCategories,
            page
        )) as AxiosResponse;

        const data = res.data.data;
        const total = res.data.links.meta.total_count;

        const updatedList = [...oldList, ...data];
        const count = updatedList.length;

        yield put(stockMediaActions.setStockMediaCategories(updatedList));

        if (count < total) {
            yield put(stockMediaActions.getStockMediaCategories());
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* stockMediaSaga(): Generator {
    // images
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_IMAGES,
        handleGetStockImages
    );
    yield takeLatest(
        StockMediaActionTypes.GET_CATEGORIZED_STOCK_IMAGES,
        handleGetCategorizedStockImages
    );
    // videos
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_VIDEOS,
        handleGetStockVideos
    );
    yield takeLatest(
        StockMediaActionTypes.GET_CATEGORIZED_STOCK_VIDEOS,
        handleGetCategorizedStockVideos
    );
    // collections
    yield takeLatest(
        StockMediaActionTypes.GET_COLLECTIONS,
        handleGetCollections
    );
    // audio
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_AUDIO_SONGS,
        handleGetStockAudioSongs
    );
    yield takeLatest(
        StockMediaActionTypes.GET_CATEGORIZED_STOCK_AUDIO_SONGS,
        handleGetCategorizedStockAudioSongs,
    );
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_AUDIO_PLAYLISTS,
        handleGetStockAudioPlaylists
    );
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_AUDIO_TAGS,
        handleGetStockAudioTags
    );
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_VIDEOS_PREVIEWS,
        createStockVideosPreviewImages
    );
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_AUDIO_EFFECTS,
        handleGetStockSoundEffects
    );
    // categories
    yield takeLatest(
        StockMediaActionTypes.GET_STOCK_MEDIA_CATEGORIES,
        handleGetStockMediaCategoreis
    );
}
