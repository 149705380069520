import { calculateSideByRatio } from 'utils';

import { IconProps } from './index';

const SIDES_RATIO = 1;

const ImageMediaIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;
    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width={width}
                height={height}
                rx="1"
                fill="none"
            />
            <path
                d="M9.80769 1.69226H2.19231C2.0087 1.69226 1.83261 1.7652 1.70277 1.89503C1.57294 2.02487 1.5 2.20096 1.5 2.38457V8.61534C1.5 8.79895 1.57294 8.97504 1.70277 9.10487C1.83261 9.23471 2.0087 9.30765 2.19231 9.30765H9.80769C9.9913 9.30765 10.1674 9.23471 10.2972 9.10487C10.4271 8.97504 10.5 8.79895 10.5 8.61534V2.38457C10.5 2.20096 10.4271 2.02487 10.2972 1.89503C10.1674 1.7652 9.9913 1.69226 9.80769 1.69226ZM9.80769 2.38457V6.83048L8.67966 5.70289C8.61537 5.63858 8.53905 5.58757 8.45504 5.55277C8.37104 5.51797 8.281 5.50006 8.19007 5.50006C8.09914 5.50006 8.00911 5.51797 7.9251 5.55277C7.8411 5.58757 7.76477 5.63858 7.70048 5.70289L6.8351 6.56827L4.93125 4.66442C4.80143 4.53469 4.62541 4.46181 4.44187 4.46181C4.25834 4.46181 4.08232 4.53469 3.9525 4.66442L2.19231 6.42462V2.38457H9.80769ZM2.19231 7.4038L4.44231 5.1538L7.90385 8.61534H2.19231V7.4038ZM9.80769 8.61534H8.88303L7.32534 7.05764L8.19072 6.19226L9.80769 7.80966V8.61534ZM6.69231 4.28841C6.69231 4.18572 6.72276 4.08533 6.77981 3.99995C6.83687 3.91456 6.91796 3.84801 7.01284 3.80871C7.10772 3.76941 7.21211 3.75913 7.31284 3.77916C7.41356 3.7992 7.50607 3.84865 7.57869 3.92126C7.65131 3.99388 7.70076 4.0864 7.72079 4.18712C7.74083 4.28784 7.73054 4.39224 7.69125 4.48712C7.65195 4.58199 7.58539 4.66309 7.50001 4.72014C7.41462 4.77719 7.31423 4.80765 7.21154 4.80765C7.07383 4.80765 6.94176 4.75294 6.84439 4.65557C6.74701 4.55819 6.69231 4.42612 6.69231 4.28841Z"
                fill={color}
            />
        </svg>
    );
};

export default ImageMediaIcon;
