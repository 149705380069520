import { DateFilterOption } from './filter';
import { StatusType } from './statuses';

export interface MediaFile {
    mediaType: MediaType;
    filename: string;
    title: string;
    id: string;
    lastModified: string;
    s3Path: string;
    size: number;
    mediaInfo: MediaInfo;
    description: string | null;
    status: VideoStatusData;
    clipRequests: Array<ClipRequest>;
    language: string;
    defaultLanguage: string;
    userId: string;
    movS3path?: string;
    isDubbingAvailable?: boolean;
    isEnhanceReady?: boolean;
    enhancedAudioPath?: string;
}

export interface VideoStatusData {
    analyze: AnalyzeStatuses;
    upload: Status;
    ingest: Status;
    transcoding: Status;
    generation: {
        status: Status;
    };
    rendering: {
        status: Status;
    };
}

export interface Status {
    statusUpdatedAt: number;
    errorReason: string;
    status: StatusType;
    jobPercentComplete?: number;
}

export interface ClipRequest {
    id: string;
    status: StatusType;
    metadata: any;
}

export interface VideoAnalysisCategoryStatus {
    error_reason: string;
    statusUpdatedAt: number;
    status: StatusType;
    addedToProject?: boolean;
    language?: string;
}

export interface AnalyzeStatuses {
    faceMatches?: VideoAnalysisCategoryStatus;
    error_reason: string;
    errorReason: string;
    transcript?: VideoAnalysisCategoryStatus;
    translate?: VideoAnalysisCategoryStatus;
    dubbing?: VideoAnalysisCategoryStatus;
    enhance?: VideoAnalysisCategoryStatus;
    modaratedLabels?: VideoAnalysisCategoryStatus;
    statusUpdatedAt: number;
    faces?: VideoAnalysisCategoryStatus;
    shots?: VideoAnalysisCategoryStatus;
    celebs?: VideoAnalysisCategoryStatus;
    status?: StatusType;
    labels?: VideoAnalysisCategoryStatus;
    code: number;
}

export interface MediaInfo {
    original: MediaInfoObj;
    proxy: MediaInfoObj;
}

export interface AdditionalMediaInfo {
    timeCodeFirstFrame: string;
}

export interface MediaInfoObj {
    audio: Array<MediaInfoAudio>;
    video: Array<MediaInfoVideo>;
    container: MediaInfoContainer;
    filename: string;
    other: Array<AdditionalMediaInfo>;
}

export interface MediaInfoAudio {
    bitrate: number;
    bitrateMode: string;
    channels: number;
    codec: string;
    duration: number;
    frameCount: number;
    samplePerFrame: number;
    samplingRate: number;
}

export interface MediaInfoVideo {
    aspectRatio: string;
    bitDepth: number;
    bitrate: number;
    codec: string;
    colorSpace: string;
    duration: number;
    frameCount: number;
    framerate: number;
    height: number;
    profile: string;
    scanType: string;
    width: number;
}

export interface MediaInfoContainer {
    duration: number;
    fileSize: number;
    format: string;
    totalBitrate: number;
}

export interface VideoFilter {
    dateFrom: Date;
    dateTo: Date;
    title: string;
    description: string;
    dateFilter: DateFilterOption;
}

export interface VideoBurningSubtitles {
    s3FilePath: string;
    signetUrl: string;
    lastJobSettings?: {
        color: string;
        fontSize: string;
    };
}

export enum MediaType {
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export interface TempMediaFile {
    mediaType: MediaType;
    // file: any;
    filename: string;
    title: string;
    id: string;
    lastModified: string;
    s3Path: string;
    size: number;
    mediaInfo: MediaInfo;
    description: string | null;
    language: string;
    defaultLanguage: string;
    userId: string;
    movS3path?: string;
    isDubbingAvailable?: boolean;
    isEnhanceReady?: boolean;
    enhancedAudioPath?: string;
}
