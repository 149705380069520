import { IconProps } from '../index';

const TextOutBillboardIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.4 20.214c.7-.772 1.54-1.159 2.519-1.159.98 0 1.802.35 2.47 1.048.674.69 1.011 1.525 1.011 2.503a3.47 3.47 0 0 1-1.024 2.516c-.683.698-1.51 1.048-2.482 1.048-.963 0-1.794-.42-2.494-1.258v1.036h-1V16.7h1v3.514zm2.457 4.97c.7 0 1.292-.26 1.778-.777.494-.518.74-1.122.74-1.813 0-.699-.238-1.307-.715-1.825-.478-.518-1.074-.777-1.79-.777s-1.317.243-1.803.728c-.477.485-.716 1.097-.716 1.837 0 .732.243 1.352.728 1.862.486.51 1.079.764 1.778.764z"
                fill="#7C7C7D" />
            <path
                d="M32.4 24.574a3.48 3.48 0 0 1-1.417 1.203 4.308 4.308 0 0 1-1.882.413c-1.054 0-1.936-.346-2.647-1.04-.702-.693-1.054-1.54-1.054-2.543 0-1.002.356-1.859 1.067-2.569.71-.718 1.63-1.077 2.76-1.077.669 0 1.283.146 1.844.438a3.255 3.255 0 0 1 1.33 1.228l-.816.514c-.61-.794-1.41-1.19-2.396-1.19-.778 0-1.43.25-1.957.751-.527.493-.79 1.107-.79 1.842a2.6 2.6 0 0 0 .777 1.892c.519.526 1.175.79 1.97.79.953 0 1.756-.401 2.409-1.203l.802.55z"
                fill="#525253" />
            <path
                d="M16.396 26.083H15.07l-1.647-3.568H9.018l-1.633 3.568H6L11.045 15h.38l4.971 11.083zm-3.543-4.835-1.618-3.598-1.633 3.598h3.251z"
                fill="#CECECF" />
            <path d="m28.25 14.078.906 2.679M35.422 17.624l-2.68.906M32.28 14.954l-1.255 2.536" stroke="#7C7C7D"
                  strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TextOutBillboardIcon;