import {
  SetMediaLanguagesCodesAction,
  TranscriptActionTypes,
  SetTranscriptGenerationLoadingAction,
  SetTranslateGenerationLoadingAction
} from 'state/modules/transcript';
import { 
  CancelTranslateAction,
  CancelTranslateFailAction,
  CancelTranslateStartAction,
  CancelTranslateSuccessAction,
  GenerateTranscriptAction, 
  GenerateTranscriptActionPayload, 
  GenerateTranslateAction, 
  GenerateTranslateActionPayload 
} from './types';

export function setMediaLanguagesCodes(payload: string[]): SetMediaLanguagesCodesAction {
  return {
    type: TranscriptActionTypes.SET_MEDIA_LANGUAGES_CODES,
    payload
  };
}

export function setTranscriptGenerationLoading(payload: boolean): SetTranscriptGenerationLoadingAction {
  return {
    type: TranscriptActionTypes.SET_TRANSCRIPT_GENERATION_LOADING,
    payload
  };
}

export function setTranslateGenerationLoading(payload: boolean): SetTranslateGenerationLoadingAction {
  return {
    type: TranscriptActionTypes.SET_TRANSLATE_GENERATION_LOADING,
    payload
  };
}

export function generateTranscript(payload: GenerateTranscriptActionPayload): GenerateTranscriptAction {
  return {
    type: TranscriptActionTypes.GENERATE_TRANSCRIPT,
    payload
  };
}

export function generateTranslate(payload: GenerateTranslateActionPayload): GenerateTranslateAction {
  return {
    type: TranscriptActionTypes.GENERATE_TRANSLATE,
    payload
  };
}

export function cancelTranslate(): CancelTranslateAction {
  return {
    type: TranscriptActionTypes.CANCEL_TRANSLATE,
  };
}

export function cancelTranslateStart(): CancelTranslateStartAction {
  return {
    type: TranscriptActionTypes.CANCEL_TRANSLATE_START,
  };
}

export function cancelTranslateFail(): CancelTranslateFailAction {
  return {
    type: TranscriptActionTypes.CANCEL_TRANSLATE_FAIL,
  };
}

export function cancelTranslateSuccess(): CancelTranslateSuccessAction {
  return {
    type: TranscriptActionTypes.CANCEL_TRANSLATE_SUCCESS,
  };
}