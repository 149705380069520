export const getPercentageValue = (percent: number, total: number): number =>
    (percent / 100) * total;

export const getPercent = (value: number, total: number): number => {
    if (!value || !total) {
        return 0;
    }
    return (value / total) * 100;
};

export const reducer = (accumulator: number, currentValue: number): number =>
    accumulator + currentValue;

export const calculatePriceInCreditsForAnalysisOperation = (
    aiOptions: Array<string>,
    videoDuration: number
): number => {
    const videoDurationInMinutes = videoDuration / 60 / 1000;
    const costOfAnalysisInCredits = videoDurationInMinutes / 6;

    return aiOptions.length * costOfAnalysisInCredits;
};

export const calculatePriceInCreditsForSubtitlingOperation = (
    videoDuration: number
): number => {
    const videoDurationInMinutes = videoDuration / 60 / 1000;
    const costOfAnalysisInCredits = videoDurationInMinutes / 15;

    return costOfAnalysisInCredits;
};

export const calculatePriceInCreditsForTranslateOperation = (
    videoDuration: number,
    translate?: any
): number => {
    const videoDurationInMinutes: number = videoDuration / 1000 / 60;

    const translateMultiplier: number = translate?.targetLanguages?.length
        ? translate.targetLanguages.length
        : 1;

    const videoDurationInCreditsForTranscribe: number =
        videoDurationInMinutes / 15;

    const totalTranscribePricePerOperation: number =
        translateMultiplier * videoDurationInCreditsForTranscribe;

    return totalTranscribePricePerOperation;
};

export const calculatePriceInCreditsForDubbingOperation = ({
    videoDuration,
}: {
    videoDuration: number;
}): number => {
    const videoDurationInMinutes = videoDuration / 60 / 1000;
    const costOfAnalysisInCredits = videoDurationInMinutes / 6;

    return costOfAnalysisInCredits;
};

export const toFixedWithoutRounding = (n: number, fixed: number): number =>
    +(
        `${typeof n === 'number' ? n : 0}`.match(
            new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`)
        ) as string[]
    )?.[0] || 0;

export const addFloatNumbers = (
    firstValue: number,
    secondValue: number,
    x: number
): number =>
    Math.round(
        (parseFloat(`${firstValue}`) + parseFloat(`${secondValue}`)) * x
    ) / x;

export const parseFloatNumber = (number: number | string): number =>
    Math.round(parseFloat(`${number}`) * 100) / 100;
