import { IconProps } from './index';
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 18 / 17;

const BlankIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.9307 16.5H4.55556C2.87911 16.5 2.04178 16.5 1.52089 15.9791C1 15.4582 1 14.6209 1 12.9444V4.05556C1 2.37911 1 1.54178 1.52089 1.02089C2.04178 0.5 2.87911 0.5 4.55556 0.5H13.4444C15.1209 0.5 15.9582 0.5 16.4791 1.02089C17 1.54178 17 2.37911 17 4.05556V10.4307C17 10.7933 17 10.9756 16.9324 11.1391C16.8649 11.3018 16.7369 11.4307 16.4791 11.6876L12.1876 15.9791C11.9298 16.2369 11.8018 16.3649 11.6391 16.4324C11.4756 16.5 11.2933 16.5 10.9307 16.5Z"
                stroke={color}
            />
            <path
                d="M10.7778 16.5001V12.3516C10.7778 11.3738 10.7778 10.8849 11.0818 10.5818C11.3849 10.2778 11.8738 10.2778 12.8516 10.2778H17"
                stroke={color}
            />
        </svg>
    );
};

export default BlankIcon;