import { IconProps } from './index';

const UndoIcon = ({ color, size }: IconProps): JSX.Element => {
    let height = 24;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 8L4 12L8 16"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 16C19 14.9391 18.5786 13.9217 17.8284 13.1716C17.0783 12.4214 16.0609 12 15 12H4"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UndoIcon;
