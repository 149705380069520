import { IconProps } from '../index';

const SelectAllIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.55606 2.74892C3.32649 2.66167 3.06709 2.71695 2.89305 2.89022C2.719 3.0635 2.66256 3.32264 2.7488 3.5526L7.7488 16.8859C7.84176 17.1338 8.08085 17.2963 8.34557 17.2914C8.61029 17.2865 8.8432 17.1153 8.92693 16.8641L10.6905 11.5735L15.3921 16.2751C15.6361 16.5192 16.0319 16.5192 16.2759 16.2751C16.52 16.031 16.52 15.6353 16.2759 15.3912L11.5802 10.6954L16.8357 8.98582C17.0878 8.90382 17.2607 8.67171 17.2671 8.4067C17.2736 8.1417 17.1122 7.90144 16.8644 7.80726L3.55606 2.74892ZM8.29934 14.794L4.40487 4.40879L14.7627 8.34568L10.224 9.82214C10.0354 9.88349 9.88712 10.0307 9.82441 10.2188L8.29934 14.794Z"
                fill={color}
            />
        </svg>
    );
};

export default SelectAllIconNew;


