import { UploadingMedia } from 'interfaces/uploading';
import { StockAudioEffect, StockMedia, StockSong } from 'interfaces/stockMedia';
import {
    UploadProjectAudioAction,
    UploadProjectAudioSuccessAction,
    UploadProjectImageAction,
    UploadProjectImageActionPayload,
    UploadProjectImageFailAction,
    UploadProjectImageStartAction,
    UploadProjectImageSuccessAction,
    UploadProjectVideoAction,
    UploadProjectVideoActionPayload,
    ProjectsUploadActionTypes,
    UploadProjectStockVideoAction,
    UploadProjectStockVideoActionPayload,
    SetProjectStockVideoIdForUploadAction,
    UploadProjectStockAudioAction,
    SetProjectStockAudioIdForUploadAction,
    UploadProjectStockImageAction,
    SetProjectStockImageIdForUploadAction,
    SetProjectStocSoundEffectIdForUploadAction,
    UploadProjectStockSoundEffectAction,
} from './types';

// ///////////

export function uploadProjectVideo(
    payload: UploadProjectVideoActionPayload
): UploadProjectVideoAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_VIDEO,
        payload,
    };
}

// Project image

export function uploadProjectImage(
    payload: UploadProjectImageActionPayload
): UploadProjectImageAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE,
        payload,
    };
}

export function uploadProjectImageStart(): UploadProjectImageStartAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_START,
    };
}

export function uploadProjectImageSuccess(): UploadProjectImageSuccessAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_SUCCESS,
    };
}

export function uploadProjectImageFail(): UploadProjectImageFailAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_IMAGE_FAIL,
    };
}

export function uploadProjectAudio(
    payload: UploadingMedia
): UploadProjectAudioAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO,
        payload,
    };
}

export function uploadProjectAudioSuccess(): UploadProjectAudioSuccessAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_AUDIO_SUCCESS,
    };
}

// Stock Video

export function uploadProjectStockVideo(
    payload: UploadProjectStockVideoActionPayload
): UploadProjectStockVideoAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_VIDEO,
        payload,
    };
}

export function setProjectStockVideoIdForUpload(
    payload: string | null
): SetProjectStockVideoIdForUploadAction {
    return {
        type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_VIDEO_ID_FOR_UPLOAD,
        payload,
    };
}

// Stock Audio

export function uploadProjectStockAudio(
    payload: StockSong
): UploadProjectStockAudioAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_AUDIO,
        payload,
    };
}

export function setProjectStockAudioIdForUpload(
    payload: string | null
): SetProjectStockAudioIdForUploadAction {
    return {
        type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_AUDIO_ID_FOR_UPLOAD,
        payload,
    };
}

// Stock Sound effect

export function uploadProjectStockSoundEffect(
    payload: StockAudioEffect
): UploadProjectStockSoundEffectAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_SOUND_EFFECT,
        payload,
    };
}

export function setProjectStocSoundEffectIdForUpload(
    payload: string | null
): SetProjectStocSoundEffectIdForUploadAction {
    return {
        type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_SOUND_EFFECT_ID_FOR_UPLOAD,
        payload,
    };
}

// Stock Image

export function uploadProjectStockImage(
    payload: StockMedia
): UploadProjectStockImageAction {
    return {
        type: ProjectsUploadActionTypes.UPLOAD_PROJECT_STOCK_IMAGE,
        payload,
    };
}

export function setProjectStockImageIdForUpload(
    payload: string | null
): SetProjectStockImageIdForUploadAction {
    return {
        type: ProjectsUploadActionTypes.SET_PROJECT_STOCK_IMAGE_ID_FOR_UPLOAD,
        payload,
    };
}
