import { IconProps } from '../index';

const FitIconNew = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 10H15M5 10L6.66667 7.5M5 10L6.66667 12.5M15 10L13.3333 7.5M15 10L13.3333 12.5M17.5 17.5V2.5M2.5 17.5V2.5"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FitIconNew;


