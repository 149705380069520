import { IconProps } from './index';

const CloudIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 24;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.375 33.25C7.02576 33.25 3.5 29.7242 3.5 25.375C3.5 21.2738 6.63514 17.9048 10.6395 17.5339C11.4587 12.5512 15.7854 8.75 21 8.75C26.2146 8.75 30.5413 12.5512 31.3605 17.5339C35.3649 17.9048 38.5 21.2738 38.5 25.375C38.5 29.7242 34.9742 33.25 30.625 33.25C22.9428 33.25 18.1008 33.25 11.375 33.25Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloudIcon;


