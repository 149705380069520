import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from 'interfaces/analysis';
import {
  GlobalSearchCategory,
  GlobalSearchItem,
  TransformedGlobalSearchResult,
  TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';

import { SentenceDataItem } from 'state/modules/metadata';

export interface GlobalSearchState {
    isGlobalSearchLoading: boolean;
    searchQuery: string;
    globalSearchResults: Array<TransformedGlobalSearchResult>;
    transcriptGlobalSearchResults: Array<TransformedGlobalSearchSentence>;
    minConfidence: Array<number>;
    videoIdsWithTranscriptGlobalSearchResults: Array<string>;
    videoIdsWithGlobalSearchResults: Array<string>;
    selectedCategory: GlobalSearchCategory;
    categories: ReadonlyArray<GlobalSearchCategory>;
}

export interface RawGlobalSearchSentence {
    data: string;
    'data_en-US': string;
    id: string;
    items: Array<SentenceDataItem>;
    language: string;
    speaker: string;
    startTime: string;
    endTime: string;
    type: AnalysisResultsType;
    videoId: string;
    highlights: Array<any>;
}

export interface TransformedGlobalSearchSentence
    extends RawGlobalSearchSentence {
    labelType: AnalysisResultsType;
    name: string;
    originalSentence: string;
    timestamps: Array<GlobalSearchSentenceTimestamp>;
}

export interface GlobalSearchSentenceTimestamp {
    timestamp: number;
}

export interface GetTranscriptByGlobalSearchRes {
    content: Array<RawGlobalSearchSentence>;
    _metadata: {
        totalCount: number;
    };
}
export interface GetMetadataByGlobalSearchRes {
    content: Array<GlobalSearchItem>;
    _metadata: {
        totalCount: number;
    };
}

export enum GlobalSearchActionTypes {
    GET_METADATA_BY_GLOBAL_SEARCH = '@@globalSearch/GET_METADATA_BY_GLOBAL_SEARCH',
    SET_GLOBAL_SEARCH_RESULTS = '@@globalSearch/SET_GLOBAL_SEARCH_RESULTS',
    CLEAR_GLOBAL_SEARCH_RESULTS = '@@globalSearch/CLEAR_GLOBAL_SEARCH_RESULTS',
    GLOBAL_SEARCH_TRANSCRIPT_ITEM_CLICK = '@@globalSearch/GLOBAL_SEARCH_TRANSCRIPT_ITEM_CLICK',
    GLOBAL_SEARCH_ITEM_CLICK = '@@globalSearch/GLOBAL_SEARCH_ITEM_CLICK',
    SET_GLOBAL_SEARCH_MIN_CONFIDENCE = '@@globalSearch/SET_GLOBAL_SEARCH_MIN_CONFIDENCE',
    SET_GLOBAL_SEARCH_CATEGORY = '@@globalSearch/SET_GLOBAL_SEARCH_CATEGORY',
    SET_GLOBAL_SEARCH_QUERY = '@@globalSearch/SET_GLOBAL_SEARCH_QUERY',
    SELECT_ALL_GLOBAL_SEARCH_RESULTS_BY_VIDEO_ID = '@@globalSearch/SELECT_ALL_GLOBAL_SEARCH_RESULTS_BY_VIDEO_ID',
}

export interface SelectAllGlobalSearchResultsByVideoActionPayload {
    videoId: string;
    globalSearchResults: TransformedGlobalSearchResult;
    globalSearchTranscriptResults: Array<TransformedGlobalSearchSentence>;
}

export interface GetMetadataByGlobalSearchAction {
    type: GlobalSearchActionTypes.GET_METADATA_BY_GLOBAL_SEARCH;
    payload: {
        searchQuery: string;
        minConfidence: number;
        category: AnalysisResultsCategory;
    };
}
export interface SetGlobalSearchResultsAction {
    type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_RESULTS;
    payload: {
        analysisResults: Array<TransformedGlobalSearchResult>;
        transcriptResults: Array<TransformedGlobalSearchSentence>;
    };
}

export interface ClearGlobalSearchResultsAction {
    type: GlobalSearchActionTypes.CLEAR_GLOBAL_SEARCH_RESULTS;
}
export interface GlobalSearchTranscriptItemClickAction {
    type: GlobalSearchActionTypes.GLOBAL_SEARCH_TRANSCRIPT_ITEM_CLICK;
    payload: {
        item: TransformedGlobalSearchSentence;
        filename: string;
    };
}
export interface GlobalSearchItemClickAction {
    type: GlobalSearchActionTypes.GLOBAL_SEARCH_ITEM_CLICK;
    payload: {
        item: TransformedGlobalSearchResultItem;
    };
}
export interface SetGlobalSearchMinConfidenceAction {
    type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_MIN_CONFIDENCE;
    payload: Array<number>;
}

export interface SetGlobalSearchCategoryAction {
    type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_CATEGORY;
    payload: GlobalSearchCategory;
}
export interface SetGlobalSearchQueryAction {
    type: GlobalSearchActionTypes.SET_GLOBAL_SEARCH_QUERY;
    payload: string;
}

export interface SelectAllGlobalSearchResultsByVideoAction {
    type: GlobalSearchActionTypes.SELECT_ALL_GLOBAL_SEARCH_RESULTS_BY_VIDEO_ID;
    payload: SelectAllGlobalSearchResultsByVideoActionPayload;
}

export type GlobalSearchAction =
    | GetMetadataByGlobalSearchAction
    | SetGlobalSearchResultsAction
    | ClearGlobalSearchResultsAction
    | GlobalSearchItemClickAction
    | GlobalSearchTranscriptItemClickAction
    | SetGlobalSearchMinConfidenceAction
    | SetGlobalSearchCategoryAction
    | SetGlobalSearchQueryAction
    | SelectAllGlobalSearchResultsByVideoAction;
