/* eslint-disable @typescript-eslint/ban-types */
import {
    ChangeEvent,
    CSSProperties,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AxiosResponse } from 'axios';
// import { v4 as uuid } from "uuid";;

import { Icon, NewButton, NewInput } from 'components/UI';

import {
    MediaFile,
    // MediaInfoVideo
} from 'interfaces/videos';
// import { NewRawProject, Project, ProjectMediaSourcesSettings, ProjectVideo } from 'interfaces/projects';

import VideosClient from 'services/api/videos';

import {
    createProject,
    getProjectCreationLoading,
} from 'state/modules/projects';
import { GetVideoListByFilterReqParams } from 'state/modules/media';

// import { reducer } from 'utils/calc';

import { Colors } from 'styles';
// import { duration } from 'moment';
import { getPreparedNewProjectData } from 'utils/projects';
import { getCurrentWorkspaceInfo } from 'state/modules/workspaces';
import styles from './styles.module.scss';

const selectStyles = {
    control: (selectStyles: CSSProperties) => ({
        ...selectStyles,
        border: '0.5px solid rgb(223, 224, 229) !important',
        boxShadow: 'none !important',
        borderRadius: 12,
        borderWidth: 0,
        background: 'transparent',
        padding: 0,
        outline: 'none',
        height: '100%',
    }),
    input: (inputStyles: CSSProperties) => ({
        ...inputStyles,
        border: 'none',
        padding: '5px 0',
        color: '#707070',
    }),
    singleValue: (singleValueStyles: CSSProperties) => ({
        ...singleValueStyles,
        color: '#707070',
    }),
    placeholder: (placeholderStyles: CSSProperties) => placeholderStyles,
    option: (optionStyles: CSSProperties) => optionStyles,
    menu: (menuStyles: CSSProperties) => ({
        ...menuStyles,
    }),
};

export interface VideoOption {
    id: string;
    value: string;
    label: string;
    data: MediaFile | null;
}

interface Props {
    closeButton: JSX.Element;
}

const CreateProjectModal = (props: Props): JSX.Element => {
    const [name, setName] = useState('');
    const [videos, setVideos] = useState<MediaFile[]>([]);
    const [isVideosLoading, setVideosLoading] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState<VideoOption[]>([]);

    const { closeButton } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(getProjectCreationLoading);
    const currentWorkspace = useSelector(getCurrentWorkspaceInfo);

    const loadVideos = async (loadedVideos: MediaFile[]): Promise<any> => {
        try {
            const res = (await VideosClient.getVideoListByFilter(
                { title: '', description: '' } as GetVideoListByFilterReqParams,
                loadedVideos.length,
                100
            )) as AxiosResponse;

            const { content, _metadata } = res.data;
            const { totalCount } = _metadata;

            const updatedVideos = [...loadedVideos, ...content] as MediaFile[];
            const updatedVideosCount = updatedVideos.length;

            if (updatedVideosCount < totalCount) {
                return loadVideos(updatedVideos);
            }

            setVideos(updatedVideos);
            setVideosLoading(false);

            return;
        } catch (error) {
            console.log({ error });
            setVideosLoading(false);
        }
    };

    const getVideos = async () => {
        setVideosLoading(true);

        await loadVideos([]);
    };

    useEffect(() => {
        getVideos();
    }, []);

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSave = () => {
        const filteredSelectedVideos = selectedVideos.filter(
            (video) => video.data
        );
        const mediaSources = filteredSelectedVideos.map(
            (video) => (video.data as MediaFile).id
        );
        const transformedSelectedVideos = filteredSelectedVideos.map(
            (video) => video.data
        ) as MediaFile[];

        const data = getPreparedNewProjectData(
            transformedSelectedVideos,
            mediaSources,
            name,
            currentWorkspace?.id || ''
        );

        dispatch(createProject(data));
    };

    const transformedVideos = useMemo(
        () =>
            videos.map((video: MediaFile) => ({
                id: `${Math.random()}`,
                value: video.id,
                label: video.title,
                data: video,
            })),
        [videos]
    );

    const handleSelectVideo = (video: any, videoId: string) => {
        if (video) {
            const updatedVideos = selectedVideos.map((selectedVideo) => {
                if (selectedVideo.id === videoId) {
                    return {
                        ...selectedVideo,
                        label: video.label,
                        value: video.id,
                        data: video.data,
                    };
                }

                return selectedVideo;
            });

            setSelectedVideos(updatedVideos);
        }
    };

    const handlAddVideo = () => {
        setSelectedVideos([
            ...selectedVideos,
            {
                id: `${Math.random()}`,
                value: '',
                label: '',
                data: null,
            },
        ]);
    };

    const handleDeleteSelectedVideo = (id: string) => {
        const filteredVideos = selectedVideos.filter(
            (video) => video.id !== id
        );

        setSelectedVideos(filteredVideos);
    };

    const renderSelectedVideos = () =>
        selectedVideos.map((video) => (
            <div
                className={styles.CreateProjectModal__selectWrap}
                key={video.id}
            >
                <Select
                    styles={selectStyles as any}
                    options={transformedVideos}
                    onChange={(option) => {
                        handleSelectVideo(option, video.id);
                    }}
                    placeholder="Select"
                    maxMenuHeight={150}
                    isLoading={isVideosLoading}
                    value={video as any}
                />
                <button
                    className={styles.CreateProjectModal__deleteVideoButton}
                    onClick={() => handleDeleteSelectedVideo(video.id)}
                >
                    <Icon name="trash" size={18} />
                </button>
            </div>
        ));

    const renderAddVideoButton = () => (
        <NewButton
            color={Colors.PINK}
            textColor={Colors.WHITE}
            bordered
            borderRadius={5}
            fontWeight="bold"
            width={111}
            height={40}
            onClick={handlAddVideo}
            leftContainer={
                <div>
                    <Icon name="plus" size={14} color="#fff" />
                </div>
            }
            disabled={isLoading}
        >
            Add Video
        </NewButton>
    );

    const renderNameInput = () => (
        <div className={styles.CreateProjectModal__inputWrap}>
            <NewInput
                onChange={handleChangeName}
                value={name}
                placeholder="Project Name"
            />
        </div>
    );

    const renderSaveButton = () => (
        <div className={styles.CreateProjectModal__saveButton}>
            <NewButton
                color={Colors.PINK}
                textColor={Colors.WHITE}
                bordered
                borderRadius={5}
                fontWeight="bold"
                width={111}
                height={40}
                onClick={handleSave}
                // disabled={!selectedVideos.length || !name.length || isLoading}
                disabled={!name.length || isLoading}
                loading={isLoading}
            >
                Save
            </NewButton>
        </div>
    );

    return (
        <div className={styles.CreateProjectModal}>
            <p className={styles.CreateProjectModal__title}>Create Project</p>
            <div className={styles.CreateProjectModal__form}>
                {renderNameInput()}
                {renderSelectedVideos()}
                {renderAddVideoButton()}
            </div>
            {renderSaveButton()}
            {closeButton}
        </div>
    );
};

export default CreateProjectModal;
