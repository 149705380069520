/* eslint-disable @typescript-eslint/ban-types */
import React, { PureComponent } from 'react';
import { Button } from 'components/UI';
import styles from './styles.module.scss';

interface IMessageModalProps {
    text: JSX.Element;
    closeButton: JSX.Element;
    title: JSX.Element;
    closeModal: () => void;
}

export default class MessageModal extends PureComponent<
    IMessageModalProps,
    {}
> {
    public handleModalClick = (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
    };

    public render(): JSX.Element {
      const {
        closeButton, text, title, closeModal,
      } = this.props;
      return (
        <div className={styles.MessageModal}>
          <div className={styles.MessageModal__topLine}>
            {title}
            {closeButton}
          </div>
          <div className={styles.MessageModal__content}>
            {text}
            <div className={styles.MessageModal__buttonWrap}>
              <Button
                type="button"
                blue
                large
                onClick={closeModal}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      );
    }
}
