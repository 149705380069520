import {
  AnalysisResultsCategory,
  AnalysisResultsType,
} from 'interfaces/analysis';

import { Trailer } from 'interfaces/generation';
import { MediaFile, VideoFilter } from 'interfaces/videos';

import { TransformedShot } from 'state/modules/shots';
import {
  AnalysisResultParent,
  TransformedAnalysisResultItem,
  TransformedSentence,
  AnalysisResultTimestampItem,
} from 'state/modules/metadata';
import {
  GlobalSearchTranscriptItem,
  TransformedGlobalSearchResultItem,
} from 'interfaces/globalSearch';
import { DateFilterOption } from 'interfaces/filter';
import { SentenceUpdateBody } from 'interfaces/transcript';

export interface OriginalTimecodeData {
  timecode: string;
  frameCount: number;
  ms: number;
}

export interface VideoExplorerState {
    selectedCategory: AnalysisResultsCategory;
    selectedItems: Array<SelectedMetadataItem>;
    sentencesToUpdate: Array<string>;
    sentencesToDelete: Array<string>;
    selectedTrailer: Trailer | null;
    searchQuery: string;
    minConfidence: Array<number>;
    minScaleFactor: Array<number>;
    isTimestampsLoading: boolean;
    videos: Array<MediaFile>;
    videoListTotal: number;
    videoFilter: VideoFilter;
    isVideoLoading: boolean;
    mediaLanguageCode: null | string;
    isOriginalTimecodeEnabled: boolean;
    originalTimecode?: OriginalTimecodeData | null;
}

export interface Scene {
    from: number;
    to: number;
    name: string;
    color: string;
    type: AnalysisResultsType;
    id: string;
    duration: number;
    scaleFactor?: number;
    confidence?: number;
}

export interface SelectedAnalysisResultItemParent {
    count: number;
    hasChildren: boolean;
    id: string;
    labelType: AnalysisResultsType;
    name: string;
    parents: Array<AnalysisResultParent>;
    type: AnalysisResultsType;
    videoId: string;
}

export interface SelectedShotItem extends TransformedShot {
    scenes: Array<Scene>;
    color: string;
    videoId: string;
    isVisible: boolean;
    isConfidenceChanged?: boolean;
    isScaleFactorChanged?: boolean;
    confidenceFilter?: Array<number>;
    scaleFactorFilter?: Array<number>;
    childrensList?: Array<any>;
    hasChildren?: boolean;
    parents?: Array<SelectedAnalysisResultItemParent>;
}

export interface SelectedAnalysisResultItem
    extends TransformedAnalysisResultItem {
    scenes: Array<Scene>;
    color: string;
    videoId: string;
    isVisible: boolean;
    isConfidenceChanged?: boolean;
    isScaleFactorChanged?: boolean;
    confidenceFilter: Array<number>;
    scaleFactorFilter: Array<number>;
    type?: AnalysisResultsType;
    timestamps: Array<AnalysisResultTimestampItem>;
    childrensList?: Array<any>;
}

export interface SelectedSentence extends TransformedSentence {
    scenes: Array<Scene>;
    color: string;
    videoId: string;
    isVisible: boolean;
    isConfidenceChanged?: boolean;
    isScaleFactorChanged?: boolean;
    confidenceFilter?: Array<number>;
    scaleFactorFilter?: Array<number>;
    labelType?: AnalysisResultsType;
    childrensList?: Array<any>;
    hasChildren?: boolean;
    parents?: Array<SelectedAnalysisResultItemParent>;
}

export type SelectedMetadataItem =
    | SelectedShotItem
    | SelectedAnalysisResultItem
    | SelectedSentence;

export interface GetTimestampsForManyAnalysisResultItemsRes {
    content: Array<AnalysisResultTimestampItem>;
}

export enum VideoExplorerActionTypes {
    CHANGE_SEARCH_QUERY = '@@videoExplorer/CHANGE_SEARCH_QUERY',
    CHANGE_SCALE_FACTOR = '@@videoExplorer/CHANGE_SCALE_FACTOR',
    CHANGE_CONFIDENCE = '@@videoExplorer/CHANGE_CONFIDENCE',
    SET_CATEGORY = '@@videoExplorer/SET_CATEGORY',
    SELECT_ANALYSIS_ITEM = '@@videoExplorer/SELECT_ANALYSIS_ITEM',
    SET_SELECTED_ITEMS = '@@videoExplorer/SET_SELECTED_ITEMS',
    VIDEO_EXPLORER_INIT = '@@videoExplorer/VIDEO_EXPLORER_INIT',
    UNSELECT_ANALYSIS_ITEM = '@@videoExplorer/UNSELECT_ANALYSIS_ITEM',
    SET_SEARCH_QUERY = '@@videoExplorer/SET_SEARCH_QUERY',
    SET_TIMESTAMPS_LOADING = '@@videoExplorer/SET_TIMESTAMPS_LOADING',
    SELECT_ALL_ANALYSIS_ITEMS = '@@videoExplorer/SELECT_ALL_ANALYSIS_ITEMS',
    UNSELECT_ALL_ANALYSIS_ITEMS = '@@videoExplorer/UNSELECT_ALL_ANALYSIS_ITEMS',
    GET_TIMESTAMPS_BY_GLOBAL_FILTER = '@@videoExplorer/GET_TIMESTAMPS_BY_GLOBAL_FILTER',
    GET_TIMESTAMPS_BY_CONFIDENCE_VALUE = '@@videoExplorer/GET_TIMESTAMPS_BY_CONFIDENCE_VALUE',
    GET_TIMESTAMPS_BY_SCALE_FACTOR_VALUE = '@@videoExplorer/GET_TIMESTAMPS_BY_SCALE_FACTOR_VALUE',
    CHANGE_ITEM_SCALE_FACTOR_VALUE = '@@videoExplorer/CHANGE_ITEM_SCALE_FACTOR_VALUE',
    CHANGE_ITEM_CONFIDENCE_VALUE = '@@videoExplorer/CHANGE_ITEM_CONFIDENCE_VALUE',
    SELECT_TRANSCRIPT_ITEM = '@@videoExplorer/SELECT_TRANSCRIPT_ITEM',
    SELECT_SHOT_ITEM = '@@videoExplorer/SELECT_SHOT_ITEM',
    REMOVE_SCENE = '@@videoExplorer/REMOVE_SCENE',
    SET_SELECTED_TRAILER = '@@videoExplorer/SET_SELECTED_TRAILER',
    TOGGLE_SELECTED_ITEM_VISIBILITY = '@@videoExplorer/TOGGLE_SELECTED_ITEM_VISIBILITY',
    TOGGLE_SELECTED_ITEMS_CATEGORY_VISIBILITY = '@@videoExplorer/TOGGLE_SELECTED_ITEMS_CATEGORY_VISIBILITY',
    EDIT_SENTENCE = '@@videoExplorer/EDIT_SENTENCE',
    SET_SENTENCES_TO_UPDATE = '@@videoExplorer/SET_SENTENCES_TO_UPDATE',
    DELETE_SENTENCE = '@@videoExplorer/DELETE_SENTENCE',
    SET_SENTENCES_TO_DELETE = '@@videoExplorer/SET_SENTENCES_TO_DELETE',
    CHANGE_VIDEO_FILTER = '@@videoExplorer/CHANGE_VIDEO_FILTER',
    SET_VIDEO_FILTER = '@@videoExplorer/SET_VIDEO_FILTER',
    GET_FILTERED_ANALYZER_VIDEO_LIST = '@@videoExplorer/GET_FILTERED_ANALYZER_VIDEO_LIST',
    GET_FILTERED_ANALYZER_VIDEO_LIST_START = '@@videoExplorer/GET_FILTERED_ANALYZER_VIDEO_LIST_START',
    GET_FILTERED_ANALYZER_VIDEO_LIST_SUCCESS = '@@videoExplorer/GET_FILTERED_ANALYZER_VIDEO_LIST_SUCCESS',
    GET_FILTERED_ANALYZER_VIDEO_LIST_FAIL = '@@videoExplorer/GET_FILTERED_ANALYZER_VIDEO_LIST_FAIL',
    RESET_ANALYZER_VIDEO_FILTER = '@@videoExplorer/RESET_ANALYZER_VIDEO_FILTER',
    UPDATE_ANALYZER_VIDEOS_STATUSES = '@@videoExplorer/UPDATE_ANALYZER_VIDEOS_STATUSES',
    UPDATE_ANALYZER_VIDEOS_STATUSES_SUCCESS = '@@videoExplorer/UPDATE_ANALYZER_VIDEOS_STATUSES_SUCCESS',
    CHANGE_MEDIA_LANGUAGE_CODE = '@@videoExplorer/CHANGE_MEDIA_LANGUAGE_CODE',
    SET_MEDIA_LANGUAGE_CODE = '@@videoExplorer/SET_MEDIA_LANGUAGE_CODE',

    SET_ORIGINAL_TIMECODE_STATUS = '@@videoExplorer/SET_ORIGINAL_TIMECODE_STATUS',
    SET_ORIGINAL_TIMECODE = '@@videoExplorer/SET_ORIGINAL_TIMECODE',
}


export interface ChangeMediaLanguageCodeAction {
    type: VideoExplorerActionTypes.CHANGE_MEDIA_LANGUAGE_CODE;
    payload: string;
}

export interface SetMediaLanguageCodeAction {
    type: VideoExplorerActionTypes.SET_MEDIA_LANGUAGE_CODE;
    payload: string;
}

export interface ChangeSearchQueryAction {
    type: VideoExplorerActionTypes.CHANGE_SEARCH_QUERY;
    payload: string;
}
export interface SetSearchQueryAction {
    type: VideoExplorerActionTypes.SET_SEARCH_QUERY;
    payload: string;
}

export interface ChangeScaleFactorAction {
    type: VideoExplorerActionTypes.CHANGE_SCALE_FACTOR;
    payload: Array<number>;
}
export interface ChangeConfidenceAction {
    type: VideoExplorerActionTypes.CHANGE_CONFIDENCE;
    payload: Array<number>;
}
export interface SetCategoryAction {
    type: VideoExplorerActionTypes.SET_CATEGORY;
    payload: AnalysisResultsCategory;
}
export interface SelectAnalysisItemAction {
    type: VideoExplorerActionTypes.SELECT_ANALYSIS_ITEM;
    payload: {
        item:
            | TransformedAnalysisResultItem
            | TransformedGlobalSearchResultItem
            | SelectedAnalysisResultItem;
        isGlobalSearchResult?: boolean;
    };
}
export interface UnselectAnalysisItemAction {
    type: VideoExplorerActionTypes.UNSELECT_ANALYSIS_ITEM;
    payload: SelectedMetadataItem;
}
export interface SetSelectedItemsAction {
    type: VideoExplorerActionTypes.SET_SELECTED_ITEMS;
    payload: Array<SelectedMetadataItem>;
}
export interface VideoExplorerInitAction {
    type: VideoExplorerActionTypes.VIDEO_EXPLORER_INIT;
    payload: {
        videoId: string | null;
    };
}
export interface SetTimestampsLoadingAction {
    type: VideoExplorerActionTypes.SET_TIMESTAMPS_LOADING;
    payload: boolean;
}
export interface SelectAllAnalysisItemsAction {
    type: VideoExplorerActionTypes.SELECT_ALL_ANALYSIS_ITEMS;
}
export interface UnselectAllAnalysisItemsAction {
    type: VideoExplorerActionTypes.UNSELECT_ALL_ANALYSIS_ITEMS;
}
export interface GetTimestampsByGlobalFiltersActionPayload {
    confidence: Array<number> | null;
    scaleFactor: Array<number> | null;
}
export interface GetTimestampsByGlobalFiltersAction {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_GLOBAL_FILTER;
    payload: {
        confidence: Array<number> | null;
        scaleFactor: Array<number> | null;
    };
}
export interface GetItemTimestampsByConfidenceValueActionPayload {
    value: Array<number>;
    item: SelectedAnalysisResultItem;
}
export interface GetItemTimestampsByConfidenceValueAction {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_CONFIDENCE_VALUE;
    payload: GetItemTimestampsByConfidenceValueActionPayload;
}

export interface GetItemTimestampsByScaleFactorValueActionPayload {
    value: Array<number>;
    item: SelectedAnalysisResultItem;
}
export interface GetItemTimestampsByScaleFactorValueAction {
    type: VideoExplorerActionTypes.GET_TIMESTAMPS_BY_SCALE_FACTOR_VALUE;
    payload: GetItemTimestampsByScaleFactorValueActionPayload;
}
export interface ChangeItemFilterValueActionPayload {
    value: Array<number>;
    item: SelectedAnalysisResultItem;
}
export interface ChangeItemConfidenceValueAction {
    type: VideoExplorerActionTypes.CHANGE_ITEM_CONFIDENCE_VALUE;
    payload: ChangeItemFilterValueActionPayload;
}
export interface ChangeItemScaleFactorValueAction {
    type: VideoExplorerActionTypes.CHANGE_ITEM_SCALE_FACTOR_VALUE;
    payload: ChangeItemFilterValueActionPayload;
}
export interface SelectTranscriptItemAction {
    type: VideoExplorerActionTypes.SELECT_TRANSCRIPT_ITEM;
    payload: TransformedSentence | GlobalSearchTranscriptItem;
}
export interface SelectShotItemAction {
    type: VideoExplorerActionTypes.SELECT_SHOT_ITEM;
    payload: TransformedShot | SelectedShotItem;
}

export interface RemoveSceneActionPayload {
    id: string;
    labelType: AnalysisResultsType;
    name: string;
}
export interface RemoveSceneAction {
    type: VideoExplorerActionTypes.REMOVE_SCENE;
    payload: RemoveSceneActionPayload;
}
export interface SetSelectedTrailerAction {
    type: VideoExplorerActionTypes.SET_SELECTED_TRAILER;
    payload: Trailer | null;
}
export interface ToggleSelectedItemVisibilityAction {
    type: VideoExplorerActionTypes.TOGGLE_SELECTED_ITEM_VISIBILITY;
    payload: {
        id: string;
    };
}
export interface ToggleCategoryVisibilityAction {
    type: VideoExplorerActionTypes.TOGGLE_SELECTED_ITEMS_CATEGORY_VISIBILITY;
    payload: {
        category: AnalysisResultsCategory;
        isAllItemsVisible: boolean;
    };
}
export interface EditSentenceActionPayload {
    videoId: string;
    id: string;
    data: SentenceUpdateBody;
}
export interface EditSentenceAction {
    type: VideoExplorerActionTypes.EDIT_SENTENCE;
    payload: EditSentenceActionPayload;
}
export interface DeleteSentenceActionPayload {
    videoId: string;
    id: string;
}

export interface DeleteSentenceAction {
    type: VideoExplorerActionTypes.DELETE_SENTENCE;
    payload: DeleteSentenceActionPayload;
}
export interface SetSentencesToUpdateAction {
    type: VideoExplorerActionTypes.SET_SENTENCES_TO_UPDATE;
    payload: Array<string>;
}
export interface SetSentencesToDeleteAction {
    type: VideoExplorerActionTypes.SET_SENTENCES_TO_DELETE;
    payload: Array<string>;
}
export interface ChangeAnalyzerVideoFilterActionPayload {
    field: string;
    value: string | DateFilterOption | Date;
}
export interface ChangeAnalyzerVideoFilterAction {
    type: VideoExplorerActionTypes.CHANGE_VIDEO_FILTER;
    payload: ChangeAnalyzerVideoFilterActionPayload;
}
export interface SetAnalyzerVideoFilterAction {
    type: VideoExplorerActionTypes.SET_VIDEO_FILTER;
    payload: VideoFilter;
}
export interface GetFilteredAnalyzerVideoListAction {
    type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST;
    payload: { isLoadMore?: boolean };
}
export interface GetFilteredAnalyzerVideoListStartAction {
    type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_START;
}
export interface GetFilteredAnalyzerVideoListFailAction {
    type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_FAIL;
    payload: any;
}
export interface GetFilteredAnalyzerVideoListSuccessAction {
    type: VideoExplorerActionTypes.GET_FILTERED_ANALYZER_VIDEO_LIST_SUCCESS;
    payload: {
        videos: Array<MediaFile>;
        total: number;
    };
}
export interface ResetAnalyzerVideoFilterAction {
    type: VideoExplorerActionTypes.RESET_ANALYZER_VIDEO_FILTER;
}
export interface UpdateAnalyzerVideosStatusesAction {
    type: VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES;
}
export interface UpdateAnalyzerVideosStatusesSuccessAction {
    type: VideoExplorerActionTypes.UPDATE_ANALYZER_VIDEOS_STATUSES_SUCCESS;
    payload: Array<MediaFile>;
}


export interface SetOriginalTimecodeStatusAction {
    type: VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE_STATUS;
    payload: boolean;
}

export interface SetOriginalTimecodeAction {
    type: VideoExplorerActionTypes.SET_ORIGINAL_TIMECODE;
    payload: OriginalTimecodeData;
}

export type VideoExplorerAction =
    | ChangeSearchQueryAction
    | SetSearchQueryAction
    | ChangeScaleFactorAction
    | ChangeConfidenceAction
    | SetCategoryAction
    | SelectAnalysisItemAction
    | UnselectAnalysisItemAction
    | SetSelectedItemsAction
    | VideoExplorerInitAction
    | SetTimestampsLoadingAction
    | SelectAllAnalysisItemsAction
    | UnselectAllAnalysisItemsAction
    | GetTimestampsByGlobalFiltersAction
    | GetItemTimestampsByConfidenceValueAction
    | GetItemTimestampsByScaleFactorValueAction
    | ChangeItemConfidenceValueAction
    | ChangeItemScaleFactorValueAction
    | SelectTranscriptItemAction
    | SelectTranscriptItemAction
    | SelectShotItemAction
    | RemoveSceneAction
    | SetSelectedTrailerAction
    | ToggleSelectedItemVisibilityAction
    | ToggleCategoryVisibilityAction
    | EditSentenceAction
    | DeleteSentenceAction
    | SetSentencesToUpdateAction
    | SetSentencesToDeleteAction
    | ChangeAnalyzerVideoFilterAction
    | SetAnalyzerVideoFilterAction
    | GetFilteredAnalyzerVideoListAction
    | GetFilteredAnalyzerVideoListStartAction
    | GetFilteredAnalyzerVideoListFailAction
    | GetFilteredAnalyzerVideoListSuccessAction
    | ResetAnalyzerVideoFilterAction
    | UpdateAnalyzerVideosStatusesAction
    | UpdateAnalyzerVideosStatusesSuccessAction
    | ChangeMediaLanguageCodeAction
    | SetMediaLanguageCodeAction
    | SetOriginalTimecodeStatusAction
    | SetOriginalTimecodeAction
