import produce from 'immer';
import { AppActionTypes, AppState, AppAction } from 'state/modules/app/types';

const initialState: AppState = {
    isStatusBarActive: false,
    isBrowserRecommendationActive: false,
    userTourStatus: null,
    isAppInitLoading: false,
    burningSubtitlesTourStatus: {
        isControlsVisible: false,
        isUploadVisible: false,
    },
    videoEditingTourStatus: {
        isControlsVisible: false,
        isUploadVisible: false,
    },
};

export default (state = initialState, action: AppAction): AppState =>
    produce(state, (draft) => {
        switch (action.type) {
            case AppActionTypes.APP_INIT_START:
                draft.isAppInitLoading = true;

                return;
            case AppActionTypes.APP_INIT_FAIL:
            case AppActionTypes.APP_INIT_SUCCESS:
                draft.isAppInitLoading = false;

                return;
            case AppActionTypes.SET_STATUS_BAR_ACTIVE_STATUS:
                draft.isStatusBarActive = action.payload;

                return;
            case AppActionTypes.SET_BROWSER_RECOMMENDATION_VISIBILITY:
                draft.isBrowserRecommendationActive = action.payload;

                return;
            case AppActionTypes.SET_APP_INTRODUCTORY_TOUR_STATUS:
                draft.userTourStatus = action.payload;

                return;
            case AppActionTypes.SET_BURNING_SUBTITLES_TOUR_STATUS:
                draft.burningSubtitlesTourStatus = action.payload;

                return;
            case AppActionTypes.SET_VIDEO_EDITING_TOUR_STATUS:
                draft.videoEditingTourStatus = action.payload;

                return;
            default:
                return state;
        }
    });
