import Axios, { AxiosResponse } from 'axios';
import { build } from 'search-params';

import { StatusType } from 'interfaces/statuses';
import { VideoUpload } from 'interfaces/upload';

import { EditVideoDetailsReqParams } from 'models/videos';

import { GetVideoListByFilterReqParams } from 'state/modules/media';
import store from 'state/store';

import $http from './instance';

class VideosClient {
    public static async getVideoDetails(id: string): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${id}`
        );
    }

    public static async getVideoVtt(id: string): Promise<AxiosResponse> {
        return Axios.get(
            `${window.config.REACT_APP_SHORT_API_URL}/vtt?videoId=${id}`
        );
    }

    public static async getVideosStatuses(
        videos: Array<string>
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;
        const ids = videos.join();

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/statuses?ids=${ids}`
        );
    }

    public static async deleteVideo(videoId: string): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}`
        );
    }

    public static async getVideoListByFilter(
        params: GetVideoListByFilterReqParams,
        offset = 0,
        limit?: number,
        status?:
            | StatusType.FAILED
            | StatusType.SUCCEEDED
            | StatusType.IN_PROGRESS
            | null
    ): Promise<AxiosResponse> {
        const { dateFrom, dateTo, title, mediaType } = params;

        const query = build({
            sortBy: '-uploadedAt',
            offset,
            limit: limit || 10,
            dateFrom,
            dateTo,
            title,
            description: title,
            mediaType,
            status,
        });

        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/search?${query}`
        );
    }

    public static async editVideoDetails(
        data: EditVideoDetailsReqParams
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const { videoId, title, description, defaultLanguage } = data;

        return $http.put(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/details`,
            {
                title,
                description,
                defaultLanguage,
            }
        );
    }

    public static async getVideosByIds(ids: string): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/many?ids=${ids}`
        );
    }

    public static async deleteDubbingLanguage(videoId: string, language: string): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/audios/language?videoId=${videoId}&language=${language}`,
        );
    }

    public static async startBurningSubtitles(
        id: string,
        color: string,
        fontSize: string | number,
        language: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const query = build({
            color,
            fontSize,
            language,
        });

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/${id}/burn-subtitles?${query}`
        );
    }

    public static async getVideoSubtitles(id: string): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${id}/subtitles`
        );
    }

    public static async cancelBurningSubtitles(
        videoId: string,
        jobId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/burn-subtitles/${jobId}/cancel`
        );
    }

    public static async cancelTranslateJob(
        videoId: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.delete(
            `/user/${userId}/workspace/${workspaceId}/videos/${videoId}/translate/cancel`
        );
    }

    public static async uploadExternalVideo(
        data: VideoUpload
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/upload`,
            data
        );
    }

    public static async getVideoAudioTracks(
        id: string
    ): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/videos/${id}/audios`
        );
    }

    public static async getYoutubeVideoInfo(data: {
        link: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/videos/youtube/info`,
            data
        );
    }
}

export default VideosClient;
