import { memo, useEffect, useMemo, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';

import {
    changeUploadingMediaDuration,
    changeUploadingVideoInfo,
    confirmUploading,
    getUploadingMediaDurationItems, getUploadingMediaWithAnalyzeCategories,
    getUploadingStatuses,
    getUploadingVideos,
    setUploadingMediaDurationItems,
    setUploadingMediaWithAnalyzeCategories,
    setUploadingStatuses,
    setUploadingVideos,
} from 'state/modules/uploading';
import { checkBrowserInfo } from 'state/modules/app';
import { getDefaultPreferences, getPreferences } from 'state/modules/user';
import { getCollectionsList } from 'state/modules/collections';
import {
    getCurrentCredits,
    getCurrentUserPlan,
    getUserUsage,
    getUserUsageData,
    PlanNameId,
    UserUsageAndTotalSubscriptionData,
} from 'state/modules/payment';
import {
    ModalEvent,
    ModalType,
    setModalBackRoute,
    showModal,
} from 'state/modules/modal';

import { Button, Icon, NewButton, Switcher } from 'components/UI';
import UploadMediaFile from 'components/UploadMediaFile';

import {
    UploadingStatus,
    UploadingMedia,
    UploadingVideoDataForUpdate,
    UploadingMediaDurationItem,
    UploadMediaType,
} from 'interfaces/uploading';

import {
    calculatePriceInCreditsForAnalysisOperation,
    calculatePriceInCreditsForSubtitlingOperation,
    reducer,
} from 'utils/calc';

import {
    Collection,
    TransformedCollectionForUploadingVideo,
} from 'interfaces/collections';
import { RekognitionCategory } from 'interfaces/analysis';

import { Colors } from 'styles';
import { createProjectFromUpload } from 'state/modules/projects';
import { useLocation, useNavigate } from 'react-router';
import styles from './styles.module.scss';

interface Props {
    closeModal: () => void;
}

const UploadModal = (props: Props): JSX.Element => {
    const { closeModal } = props;
    const [isProjectShouldBeCreated, setProjectShouldBeCreated] =
        useState(false);
    const [isLimitsHidden, setLimitsHidden] = useState(false);

    const currentUserPlan = useSelector(getCurrentUserPlan);
    const uploadingStatuses = useSelector(getUploadingStatuses);
    const uploadingVideos = useSelector(getUploadingVideos);
    const collections = useSelector(getCollectionsList);
    const defaultUserPreferences = useSelector(getDefaultPreferences);
    const userPreferences = useSelector(getPreferences);
    const uploadingMediaDurationItems = useSelector(
        getUploadingMediaDurationItems
    );
    const userUsage = useSelector(
        getUserUsageData
    ) as UserUsageAndTotalSubscriptionData;
    const currentCredits = useSelector(getCurrentCredits) as number;
    const videosToAnalyse = useSelector(getUploadingMediaWithAnalyzeCategories) as Array<UploadingMedia>;

    const [isAnalyzeOn, setIsAnalyzeOn] = useState<boolean | null>(null);
    const [selectedCollectionId, setSelectedCollectionId] = useState<string | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const timeOutIdRef = useRef(null) as any;

    useEffect(
        () => () => {
            clearTimeout(timeOutIdRef?.current);
        },
        [timeOutIdRef]
    );

    useEffect(() => {
        if((isAnalyzeOn === null) && !!userPreferences) {
            setIsAnalyzeOn(userPreferences.autoAnalyse);
        }
    }, [userPreferences, isAnalyzeOn]);

    useEffect(() => {
        if (location.pathname.includes('video-editing')) {
            setLimitsHidden(true);
        } else {
            setLimitsHidden(false);
        }
    }, [location]);

    const hasMediaWithEnabledAnalysis =
        uploadingVideos.filter((media) => media.autoAnalyze).length > 0;

    const availableStorage = useMemo(() => {
        if (userUsage) {
            const { total, usage } = userUsage;

            return total.storage - usage.storage;
        }
        return 0;
    }, [userUsage]);

    const uploadingMediaTotalSize = useMemo(() => {
        const uploadingVideosSizeArray = uploadingVideos.map(
            (item: UploadingMedia) => item.size
        );

        return uploadingVideosSizeArray.reduce(reducer, 0);
    }, [uploadingVideos]);

    const analysisOperationPrice = useMemo(() => {
        let price = 0;

        const videosWithAutoAnalysis = uploadingVideos.filter(
            (video) => video.autoAnalyze
        );

        videosWithAutoAnalysis.forEach((video) => {
            const videoAnalysisCategories = video.categories;
            const filteredCategories = videoAnalysisCategories.filter(
                (category) => category !== RekognitionCategory.AWS_TRANSCRIPT
            );
            const videoDuration =
                uploadingMediaDurationItems.find(
                    (durationItem) => durationItem.id === video.id
                )?.duration || 0;

            price += calculatePriceInCreditsForAnalysisOperation(
                filteredCategories,
                videoDuration * 1000
            );
        });

        return price;
    }, [uploadingVideos, uploadingMediaDurationItems]);

    const subtitlingOperationPrice = useMemo(() => {
        let price = 0;

        const videosWithAutoAnalysis = uploadingVideos.filter(
            (video) => video.autoAnalyze
        );

        videosWithAutoAnalysis.forEach((video) => {
            const videoAnalysisCategories = video.categories;

            const videoDuration =
                uploadingMediaDurationItems.find(
                    (durationItem) => durationItem.id === video.id
                )?.duration || 0;

            const hasTranscriptCategoty = videoAnalysisCategories.find(
                (category) => category === RekognitionCategory.AWS_TRANSCRIPT
            );

            if (hasTranscriptCategoty) {
                price += calculatePriceInCreditsForSubtitlingOperation(
                    videoDuration * 1000
                );
            }
        });

        return price;
    }, [uploadingVideos, uploadingMediaDurationItems]);

    const isUploadingMediaTotalSizeOverLimit =
        currentUserPlan?.planNameId !== PlanNameId.ENTERPRISE &&
        uploadingMediaTotalSize > availableStorage;

    const isUploadingMediaDurationOverLimit = useMemo(
        () =>
            currentUserPlan?.planNameId !== PlanNameId.ENTERPRISE &&
            uploadingMediaDurationItems.findIndex(
                (item) =>
                    item.duration * 1000 >
                    +(currentUserPlan?.availableVideoLength || 1)
            ) >= 0,
        [uploadingMediaDurationItems, currentUserPlan]
    );

    const isAnalysisOperationPriceOverLimit =
        currentUserPlan?.planNameId !== PlanNameId.ENTERPRISE &&
        analysisOperationPrice > currentCredits;

    const isSubtitlingOperationPriceOverLimit =
        currentUserPlan?.planNameId !== PlanNameId.ENTERPRISE &&
        subtitlingOperationPrice > currentCredits;

    const transformedCollections: Array<TransformedCollectionForUploadingVideo> =
        collections
            ? collections.map((collectionItem: Collection) => ({
                  value: collectionItem.name,
                  label: collectionItem.name,
                  id: collectionItem.id,
                  images: collectionItem.images,
              }))
            : [];

    useEffect(() => {
        if((selectedCollectionId === null) && transformedCollections?.length) {
            setSelectedCollectionId(transformedCollections[0].id);
        }
    }, [transformedCollections, selectedCollectionId]);

    useEffect(() => {
        dispatch(checkBrowserInfo());
        dispatch(getUserUsage());
    }, [dispatch]);

    const handleSetUploadingVideos = (newVideos: Array<UploadingMedia>) => {
        dispatch(setUploadingVideos(newVideos));
    };

    const setVideoDuration = (file: any, id: string) => {
        const video = document.createElement('video');
        const fileURL = URL.createObjectURL(file);
        video.src = fileURL;

        video.ondurationchange = () => {
            const duration = video.duration || 0;

            dispatch(
                changeUploadingMediaDuration({
                    duration,
                    id,
                })
            );

            URL.revokeObjectURL(fileURL);
        };
    };

    const toggleCreateDefaultProject = () => {
        setProjectShouldBeCreated(!isProjectShouldBeCreated);
    };

    // const handleUploadLocalFile = async (localVideos: Array<File>) => {
    const handleUploadLocalFile = (localVideos: Array<any>) => {
        let videoAnalysisCategories =
            userPreferences?.defaultCategories as Array<string>;

        const selectedCollection =
            collections.find(
                (collection: Collection) =>
                    collection.id === userPreferences?.defaultUserCollection
            ) || collections[0];

        if (!selectedCollection?.images?.length) {
            videoAnalysisCategories = videoAnalysisCategories?.filter(
                (category: string) =>
                    category !== RekognitionCategory.AWS_FACE_MATCHES
            );
        }

        const changedFiles = localVideos.map((file: File) => {
            const isAudio =
                file.type === 'audio/mpeg' || file.type === 'audio/wav';
            const id = uuid();

            setVideoDuration(file, id);

            let filteredCategories = [...videoAnalysisCategories];

            if (isAudio) {
                filteredCategories = filteredCategories.filter(
                    (category: string) =>
                        category === RekognitionCategory.AWS_TRANSCRIPT
                );
            }

            if (currentUserPlan?.planNameId === PlanNameId.INITIAL) {
                filteredCategories = filteredCategories.filter(
                    (category: string) =>
                        category === RekognitionCategory.AWS_TRANSCRIPT
                );
            }

            return {
                file,
                description: '',
                title: file.name,
                lastModifiedDate: file.lastModified,
                size: file.size,
                type: file.type,
                id,
                transcriptLanguage:
                    userPreferences?.defaultTranscriptLanguageCode,
                collectionId: userPreferences?.defaultUserCollection,
                categories: filteredCategories,
                autoAnalyze: Boolean(userPreferences?.autoAnalyse),
            };
        }) as Array<UploadingMedia>;

        if (uploadingVideos.length > 0) {
            handleSetUploadingVideos([...changedFiles, ...uploadingVideos]);
        } else {
            handleSetUploadingVideos(changedFiles);
        }
    };

    const handleSelectVideoCollection = (
        videoId: string,
        collectionId: string
    ) => {
        const selectedCollection = collections.find(
            (collection: Collection) => collection.id === collectionId
        ) as Collection;

        const changedVideos = uploadingVideos.map((item: UploadingMedia) => {
            let { categories } = item;

            if (!selectedCollection.images.length) {
                categories = categories.filter(
                    (category: string) =>
                        category !== RekognitionCategory.AWS_FACE_MATCHES
                );
            }

            if (item.id === videoId) {
                return {
                    ...item,
                    collectionId,
                    categories,
                };
            }
            return item;
        });

        handleSetUploadingVideos(changedVideos);
    };

    const handleSelectVideoLanguage = (
        videoId: string,
        languageForTranscript: string | null
    ) => {
        const changedVideos = uploadingVideos.map((item: UploadingMedia) => {
            if (item.id === videoId) {
                return {
                    ...item,
                    transcriptLanguage: languageForTranscript,
                };
            }
            return item;
        });

        handleSetUploadingVideos(changedVideos);
    };

    const handleSetVideoAnalysisCategories = (
        videoId: string,
        categories: Array<string>
    ) => {
        const changedVideos = uploadingVideos.map((item: UploadingMedia) => {
            if (item.id === videoId) {
                return {
                    ...item,
                    categories,
                };
            }
            return item;
        });

        handleSetUploadingVideos(changedVideos);
    };

    const handleSetAutoAnalyzeStatus = (
        videoId: string,
        autoAnalyze: boolean
    ) => {
        const changedVideos = uploadingVideos.map((item: UploadingMedia) => {
            if (item.id === videoId) {
                return {
                    ...item,
                    autoAnalyze,
                };
            }
            return item;
        });

        handleSetUploadingVideos(changedVideos);
    };

    const handleSetUploadingStatuses = (
        newStatuses: Array<UploadingStatus>
    ) => {
        dispatch(setUploadingStatuses(newStatuses));
    };

    const handleDeleteVideo = (videoId: string) => {
        const filteredVideos = uploadingVideos.filter(
            (item: UploadingMedia) => item.id !== videoId
        );
        const filteredStatuses = uploadingStatuses.filter(
            (item: UploadingStatus) => item.id !== videoId
        );
        const filteredUploadingMediaDurationItems =
            uploadingMediaDurationItems.filter(
                (item: UploadingMediaDurationItem) => item.id !== videoId
            );

        handleSetUploadingVideos(filteredVideos);
        handleSetUploadingStatuses(filteredStatuses);
        dispatch(
            setUploadingMediaDurationItems(filteredUploadingMediaDurationItems)
        );
    };

    const handleConfirmUpload = async () => {
        if (userUsage) {
            try {
                const total = (userUsage?.total.storage || 0) as number;
                const current = userUsage?.usage.storage;
                const currentSum = uploadingMediaTotalSize + current;

                if (currentSum > total || !total) {
                    dispatch(
                        showModal(
                            ModalType.UPGRADE_PLAN,
                            ModalEvent.NOT_ENOUGH_STORAGE
                        )
                    );
                } else {
                    if (isProjectShouldBeCreated) {
                        dispatch(
                            createProjectFromUpload({
                                videos: uploadingVideos,
                                navigate,
                            })
                        );
                    }

                    await Auth.currentSession();

                    const transformedStatuses = uploadingVideos.map(
                        (item: UploadingMedia) => ({
                            id: item.id,
                            currentProgress: 0,
                            remainingAmount: 100,
                            isLoadig: true,
                        })
                    );

                    handleSetUploadingStatuses(transformedStatuses);

                    dispatch(
                        confirmUploading({
                            media: uploadingVideos,
                            showNotifications: !isProjectShouldBeCreated,
                            mediaType: UploadMediaType.VIDEO,
                        })
                    );

                    if(isAnalyzeOn) {
                        const normalizedNewVideos = uploadingVideos.map(item => {
                            if(item.collectionId) {
                                return item;
                            }
                            return {
                                ...item,
                                collectionId: selectedCollectionId || transformedCollections[0].id
                            }
                        });
                        dispatch(setUploadingMediaWithAnalyzeCategories([...videosToAnalyse, ...normalizedNewVideos]));
                    }

                    if (!isProjectShouldBeCreated) {
                        timeOutIdRef.current = setTimeout(() => {
                            const shouldSkipSmartSearchIntroIntro =
                                localStorage.getItem('skipSmartSearchIntro');

                            if (shouldSkipSmartSearchIntroIntro) {
                                closeModal();
                            } else {
                                dispatch(
                                    showModal(
                                        ModalType.WELCOME,
                                        ModalEvent.WELCOME_INTRO_SMART_SEARCH
                                    )
                                );
                            }
                        }, 6500);
                    }
                }
            } catch (error) {
                console.log({ error });
            }
        }
    };

    const changeVideoInfo = ({
        value,
        videoId,
        field,
    }: UploadingVideoDataForUpdate) => {
        dispatch(
            changeUploadingVideoInfo({
                value,
                videoId,
                field,
            })
        );
    };

    const handleUpgradePlan = () => {
        dispatch(
            setModalBackRoute({
                modalType: ModalType.UPLOAD,
                event: ModalEvent.UPLOAD,
            })
        );
        dispatch(showModal(ModalType.SELECT_PLAN, ModalEvent.SELECT_PLAN));

        // const isInitialPlan = currentUserPlan?.planNameId === PlanNameId.INITIAL;

        // if (isInitialPlan) {
        //   dispatch(
        //     showModal(ModalType.SELECT_PLAN, ModalEvent.SELECT_PLAN),
        //   );
        // } else {
        //   dispatch(
        //     showModal(ModalType.UPGRADE_PLAN, ModalEvent.UPLOAD_SIZE_OVER_LIMIT),
        //   );
        // }
    };

    const handleAddMoreCredits = () => {
        dispatch(
            setModalBackRoute({
                modalType: ModalType.UPLOAD,
                event: ModalEvent.UPLOAD,
            })
        );
        dispatch(
            showModal(ModalType.ADD_MORE_CREDITS, ModalEvent.ADD_MORE_CREDITS)
        );
    };

    const handleAddMoreStorage = () => {
        dispatch(
            setModalBackRoute({
                modalType: ModalType.UPLOAD,
                event: ModalEvent.UPLOAD,
            })
        );
        dispatch(
            showModal(ModalType.MANAGE_STORAGE, ModalEvent.MANAGE_STORAGE)
        );
    };

    const renderDropzoneContent = () => (
        <>
            <Icon name="upload-cloud" />
            <p className={styles.UploadModal__dropzoneDescription}>
                <span
                    className={styles.UploadModal__dropzoneDescription_accent}
                >
                    Click to Upload
                </span>
                or Drag and Drop
            </p>
        </>
    );

    const renderButtonText = () => 'Confirm';

    const renderUploadingStatuses = () =>
        uploadingStatuses.length === 0 && (
            <Dropzone
                onDrop={handleUploadLocalFile}
                multiple
                accept={
                    '.avi,.flv,.wmv,.mpg,.mkv,.webm,.mxf,video/mp4,video/x-m4v,video/*,video/quicktime,application/mxf,.mp3,audio/*' as any
                }
            >
                {({ getRootProps, getInputProps }): JSX.Element => (
                    <section className={styles.UploadModal__dropzoneWrap}>
                        <div
                            {...getRootProps()}
                            className={styles.UploadModal__dropzoneRoot}
                        >
                            <input
                                {...getInputProps()}
                                className={styles.UploadModal__dropzone}
                            />
                            {renderDropzoneContent()}
                        </div>
                    </section>
                )}
            </Dropzone>
        );

    const renderVideos = () =>
        uploadingVideos &&
        userPreferences &&
        uploadingVideos.length > 0 && (
            <div className={styles.UploadModal__videos}>
                {uploadingVideos.map((video: UploadingMedia) => {
                    const statuses = uploadingStatuses.find(
                        (item: UploadingStatus) => item.id === video.id
                    );

                    return (
                        <UploadMediaFile
                            setVideoLanguage={handleSelectVideoLanguage}
                            setVideoCollection={handleSelectVideoCollection}
                            setVideoAnalysisCategories={
                                handleSetVideoAnalysisCategories
                            }
                            setAutoAnalyzeStatus={handleSetAutoAnalyzeStatus}
                            changeVideoInfo={changeVideoInfo}
                            key={video.id}
                            file={video}
                            onDeleteClick={handleDeleteVideo}
                            statuses={statuses || null}
                            collections={transformedCollections}
                            defaultUserPreferences={defaultUserPreferences}
                            userPreferences={userPreferences}
                            setIsAnalyzeOn={setIsAnalyzeOn}
                            setSelectedCollectionId={setSelectedCollectionId}
                        />
                    );
                })}
            </div>
        );

    const renderAvailableStorage = () => (
        <div className={styles.UploadModal__availableStorage}>
            <p>
                Available storage:
                {` ${prettyBytes(availableStorage)}`}
            </p>
        </div>
    );

    const renderAvailableCredits = () => (
        <div className={styles.UploadModal__availableStorage}>
            <p className={styles.UploadModal__availableCredits}>
                Available analysis time:{' '}
                {moment
                    .duration(currentCredits * 6 * 6 * 10000, 'milliseconds')
                    .format('h[h] m[m] s[s]', {
                        useGrouping: false,
                        trim: 'both',
                    })}
            </p>
            <p className={styles.UploadModal__availableCredits}>
                Available subtitling time:{' '}
                {moment
                    .duration(currentCredits * 6 * 15 * 10000, 'milliseconds')
                    .format('h[h] m[m] s[s]', {
                        useGrouping: false,
                        trim: 'both',
                    })}
            </p>
        </div>
    );

    const renderAvailableUploadVideoDurationLimit = () => (
        <div className={styles.UploadModal__availableStorage}>
            <p className={styles.UploadModal__availableCredits}>
                Available video duration:{' '}
                {moment
                    .duration(
                        currentUserPlan?.availableVideoLength,
                        'milliseconds'
                    )
                    .format('h[h] m[m] s[s]', {
                        useGrouping: false,
                        trim: 'both',
                    })}
            </p>
        </div>
    );

    const renderAnalysisOperationPriceOverLimitMessage = () =>
        hasMediaWithEnabledAnalysis && isAnalysisOperationPriceOverLimit ? (
            <>
                <p className={styles.UploadModal__overLimitMessage}>
                    The analysis operation price is larger than the available
                    minutes
                </p>
            </>
        ) : null;

    const renderSubtitlingOperationPriceOverLimitMessage = () =>
        hasMediaWithEnabledAnalysis && isSubtitlingOperationPriceOverLimit ? (
            <>
                <p className={styles.UploadModal__overLimitMessage}>
                    The subtitling operation price is larger than the available
                    minutes
                </p>
            </>
        ) : null;

    const renderAnalysisOperationPrice = () =>
        uploadingVideos.length && hasMediaWithEnabledAnalysis ? (
            <div className={styles.UploadModal__operationsPrice}>
                <p>
                    Total analysis operation price:{' '}
                    <span
                        className={[
                            styles.UploadModal__operationsPriceValue,
                            analysisOperationPrice > currentCredits
                                ? styles.UploadModal__operationsPriceValue_overLimit
                                : null,
                        ].join(' ')}
                    >
                        {moment
                            .duration(
                                analysisOperationPrice * 6 * 6 * 10000,
                                'milliseconds'
                            )
                            .format('h[h] m[m] s[s]', {
                                useGrouping: false,
                                trim: 'both',
                            })}
                    </span>
                </p>
                {isAnalysisOperationPriceOverLimit
                    ? renderAnalysisOperationPriceOverLimitMessage()
                    : null}
                <p>
                    Total subtitling operation price:{' '}
                    <span
                        className={[
                            styles.UploadModal__operationsPriceValue,
                            subtitlingOperationPrice > currentCredits
                                ? styles.UploadModal__operationsPriceValue_overLimit
                                : null,
                        ].join(' ')}
                    >
                        {moment
                            .duration(
                                subtitlingOperationPrice * 6 * 15 * 10000,
                                'milliseconds'
                            )
                            .format('h[h] m[m] s[s]', {
                                useGrouping: false,
                                trim: 'both',
                            })}
                    </span>
                </p>
                {isSubtitlingOperationPriceOverLimit
                    ? renderSubtitlingOperationPriceOverLimitMessage()
                    : null}
            </div>
        ) : null;

    const renderUploadingMediaTotalSizeOverLimitMessage = () =>
        isUploadingMediaTotalSizeOverLimit && hasMediaWithEnabledAnalysis ? (
            <>
                <p className={styles.UploadModal__overLimitMessage}>
                    The total size of uploading videos is larger than the
                    available storage
                </p>
            </>
        ) : null;

    const renderUploadingMediaDurationOverLimitMessage = () =>
        isUploadingMediaDurationOverLimit ? (
            <>
                <p className={styles.UploadModal__overLimitMessage}>
                    The duration of uploading video is larger than the available
                    limit
                </p>
            </>
        ) : null;

    const renderTotalUploadingFilesSize = () =>
        uploadingVideos.length ? (
            <div className={styles.UploadModal__totalSize}>
                <p className={styles.UploadModal__totalSizeTitle}>
                    Uploading media total size:{' '}
                    <span
                        className={[
                            styles.UploadModal__totalSizeValue,
                            isUploadingMediaTotalSizeOverLimit
                                ? styles.UploadModal__totalSizeValue_overLimit
                                : null,
                        ].join(' ')}
                    >
                        {prettyBytes(uploadingMediaTotalSize)}
                    </span>
                </p>
                {renderUploadingMediaTotalSizeOverLimitMessage()}
            </div>
        ) : null;

    const renderCloseButton = () => (
        <button className={styles.UploadModal__closeBtn} onClick={closeModal}>
            <Icon name="close" size={15} />
        </button>
    );

    const renderSubmitButton = () => {
        let isDisabled = false;

        if (
            (currentUserPlan?.planNameId !== PlanNameId.ENTERPRISE &&
                uploadingStatuses.length > 0) ||
            isUploadingMediaDurationOverLimit ||
            !uploadingVideos.length ||
            (hasMediaWithEnabledAnalysis &&
                (isUploadingMediaTotalSizeOverLimit ||
                    isAnalysisOperationPriceOverLimit))
        ) {
            isDisabled = true;
        }

        return (
            <div className={styles.UploadModal__buttonWrap}>
                <Button
                    type="button"
                    blue
                    large
                    onClick={handleConfirmUpload}
                    disabled={isDisabled}
                >
                    {renderButtonText()}
                </Button>
            </div>
        );
    };

    const renderUpgradePlanButton = () =>
        (hasMediaWithEnabledAnalysis && isAnalysisOperationPriceOverLimit) ||
        isUploadingMediaTotalSizeOverLimit ||
        isUploadingMediaDurationOverLimit ? (
            <div className={styles.UploadModal__upgradePlanWrap}>
                <NewButton
                    color={Colors.GREY3}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={handleUpgradePlan}
                >
                    Upgrade Plan
                </NewButton>
            </div>
        ) : null;

    const renderByCreditsButton = () =>
        hasMediaWithEnabledAnalysis &&
        isAnalysisOperationPriceOverLimit &&
        currentUserPlan?.planNameId === PlanNameId.PROFESSIONAL ? (
            <div className={styles.UploadModal__upgradePlanWrap}>
                <NewButton
                    color={Colors.GREY3}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={handleAddMoreCredits}
                >
                    Buy more Credits
                </NewButton>
            </div>
        ) : null;

    const renderByStorageButton = () =>
        isUploadingMediaTotalSizeOverLimit &&
        currentUserPlan?.planNameId === PlanNameId.PROFESSIONAL ? (
            <div className={styles.UploadModal__upgradePlanWrap}>
                <NewButton
                    color={Colors.GREY3}
                    textColor={Colors.WHITE}
                    bordered
                    borderRadius={5}
                    width={132}
                    height={46}
                    onClick={handleAddMoreStorage}
                >
                    Buy more Storage
                </NewButton>
            </div>
        ) : null;

    const renderProjectToggler = () =>
        uploadingVideos.length > 0 && !uploadingStatuses.length ? (
            <div className={styles.UploadModal__projectToggler}>
                <Switcher
                    labelLeft="Create Project"
                    checked={isProjectShouldBeCreated}
                    onChange={toggleCreateDefaultProject}
                />
            </div>
        ) : null;

    return (
        <div className={styles.UploadModal}>
            <div className={styles.UploadModal__topLine}>
                <p className={styles.UploadModal__title}>Upload media</p>
                {renderAvailableStorage()}
                {!isLimitsHidden ? (
                    <>
                        {renderAvailableCredits()}
                        {renderAvailableUploadVideoDurationLimit()}
                        {renderCloseButton()}
                    </>
                ) : null}
            </div>
            <div className={styles.UploadModal__content}>
                {renderUploadingStatuses()}
            </div>
            {renderVideos()}
            {renderAnalysisOperationPrice()}
            {renderTotalUploadingFilesSize()}
            {renderUploadingMediaDurationOverLimitMessage()}
            {renderUpgradePlanButton()}
            {renderByCreditsButton()}
            {renderByStorageButton()}
            {renderProjectToggler()}
            {renderSubmitButton()}
        </div>
    );
};

export default memo(UploadModal);
