import { Stripe } from '@stripe/stripe-js';
import { IInvoice } from 'interfaces/payment';

export interface PaymentState {
    plans: Array<Product>;
    usage: UserUsageAndTotalSubscriptionData | null;
    customer: Customer | null;
    currentPlan: CurrentSubscription | null;
    hasIssueWithSubscription: boolean;
    upcomingPlan: UpcomingPlan | null;
    selectedPlan: Product | null;
    addons: Array<Product>;
    cardIdToRemove: string;
    isCustomerCreationLoading: boolean;
    isUpcomingPlanLoading: boolean;
    isUsageLoading: boolean;
    isCustomerLoading: boolean;
    isCurrentPlanLoading: boolean;
    isPlansLoading: boolean;
    isAddonsLoading: boolean;
    isAddCreditsLoading: boolean;
    isAddDubbingCreditsLoading: boolean;
    isStorageUpdateLoading: boolean;
    isSubscriptionCancelationLoading: boolean;
    isSubscriptionCreationLoading: boolean;
    isAddCustomerCardLoading: boolean;
    isSetPreferredCardLoading: boolean;
    isPaymentFlowLoading: boolean;
    isSubscriptionActivationLoading: boolean;
    stripePromise: Promise<Stripe | null>;
    isConfirmSetupLoading: boolean;
    invoices: IInvoice[];
    isInvoicesLoading: boolean;
}

export interface Customer {
    email?: string;
    cards: Array<CustomerCard>;
    userId: string;
    phone?: string;
    name?: string;
    address?: any;
    isTrialAvailable?: boolean;
    stripeCustomerId?: string;
    paymentMethod?: any;
}

export interface CreateCustomerBody {
    customerData: Customer;
}

export interface Subscription {
    status?: string;
    productId?: string;
    priceId?: string;
    subscriptionId?: string;
    isTrialAvailable?: boolean;
}

export interface Addon {
    status?: string;
    productId?: string;
    priceId?: string;
}

export interface Credit {
    amount: number;
}

export interface UpdateSubscriptionBody {
    productId?: string;
    priceId?: string;
    addonProductId?: string;
    addonPriceId?: string;
    cardId?: string;
    isTrial?: boolean;
    coupon?: string;
}

export interface UserSubscription {
    id?: string;
    subscription?: Subscription;
    addons?: Addon;
    userId?: string;
    stripeCustomerId?: string;
    credits?: Credit;
    discount?: any;
    // sTrial?: boolean;
}

export interface UpdateUserSubscription {
    subscription: UpdateSubscriptionBody;
}

export interface SubscriptionPlanPaymentInfo {
    type: 'plan' | 'addon';
    plan?: string;
    interval?: 'monthly' | 'annual';
    paywallType?: string;
    name: string;
    price: number;
    couponName?: string;
    addonName?: 'subtitle time' | 'storage' | 'stock media assets' | 'dubbing time';
}

export interface SubscriptionPaymentDetailsBody {
    productId?: string;
    priceId?: string;
    isTrial?: boolean;
    coupon?: string;
}

export interface AddonPaymentDetailsBody {
    productId?: string;
    upcomingProductId?: string;
    priceId?: string;
    upcomingPriceId?: string;
    quantity?: number;
}

export type CreditsPaymentDetailsBody = AddonPaymentDetailsBody;

export enum PaymentType {
    subscription = 'subscription',
    addon = 'addon',
    credits = 'credits',
    dubbingCredits = 'dubbingCredits',
}
export interface ConfirmSetupBody {
    paymentIntent?: string;
    setupIntent?: string;
    type?: PaymentType;
    subscription?: SubscriptionPaymentDetailsBody;
    addon?: AddonPaymentDetailsBody;
    credits?: CreditsPaymentDetailsBody;
}

export interface Product {
    id?: string;
    name?: string;
    description?: string;
    availableStorage?: string;
    availableVideoLength?: string;
    exportVideoDurationTime?: string;
    prices?: Array<Price>;
    isTrial?: boolean;
    planNameId?: PlanNameId;
}

export enum PlanNameId {
    TRIAL = 'trial',
    ENTERPRISE = 'enterprise',
    PROFESSIONAL = 'professional',
    BASIC = 'basic',
    INITIAL = 'initial',
    BUSINESS = 'business',
}

export enum PlanType {
    PLAN = 'plan',
    APP_SUMO_PLAN = 'appSumoPlan',
}

export enum PaymentPeriodType {
    MONTHLY = 'month',
    YEARLY = 'year',
}

export interface CurrentSubscription {
    id: string;
    name: string;
    description: string;
    availableStorage?: string;
    availableVideoLength?: string;
    availableStocksItems?: string;
    billingPeriod: BillingPeriod;
    status: string;
    stripeStatus: string;
    planNameId: PlanNameId;
    type: PlanType;
    price: {
        id: string;
        currency: string;
        amount: number;
        interval: string;
    };
    addon?: {
        priceId: string;
        productId: string;
        currency: string;
        amount: number;
        interval: string;
        currentStorageAmount: number;
        featureStorageAmount: number;
    };
    isTrial?: boolean;
    exportVideoDurationTime?: string;
}

export interface BillingPeriod {
    billingStartDate: number;
    billingEndDate: number;
}

export interface Price {
    id?: string;
    currency?: string;
    amount: number;
    interval?: 'day' | 'week' | 'month' | 'year';
}

export interface TotalAnalyzeDurationInCredits {
    rekognition: number;
    transcribe: number;
}

export interface UserData {
    storage: number;
    credits: TotalAnalyzeDurationInCredits;
    assets: number;
    dubbingCredits?: number;
}

export interface UserUsageAndTotalSubscriptionData {
    usage: UserData;
    total: {
        storage: number;
        userCredits: number;
        subscriptionAndAddonCredits: number;
        subscriptionAndAddonDubbingCredits?: number;
        dubbingCredits?: number;
    };
}

export interface AddCustomerCardBody {
    card: CustomerCard;
}

export interface CustomerCard {
    number: string;
    expMonth: number;
    expYear: number;
    cvcCode?: string;
    isPrefer?: boolean;
    id?: string;
    brand?: string;
}

export interface UpdateUserCredits {
    credits: UpdateAddonBody;
}

export interface UpdateUserAddon {
    addon: UpdateAddonBody;
}

export interface UpdateAddonBody {
    productId?: string;
    upcomingProductId?: string;
    priceId?: string;
    upcomingPriceId?: string;
    quantity?: number;
    cardId?: string;
    type?: 'credits' | 'dubbingCredits';
}

export interface UpcomingPlan {
    name: string;
    upcomingSubscriptionStorage: number;
    upcomingSubscriptionPrice: number;
    billingDate: number;
    billingInterval: 'year' | 'week' | 'month';
    upcomingAddonPrice: number;
    upcomingAddonStorage: number;
    upcomingCredits: number;
    refund: number | null;
    planNameId?: PlanNameId;
}

export enum PaymentActionTypes {
    PAYMENT_FLOW_INIT = '@@payment/PAYMENT_FLOW_INIT',
    PAYMENT_FLOW_INIT_START = '@@payment/PAYMENT_FLOW_INIT_START',
    PAYMENT_FLOW_INIT_SUCCESS = '@@payment/PAYMENT_FLOW_INIT_SUCCESS',
    PAYMENT_FLOW_INIT_FAIL = '@@payment/PAYMENT_FLOW_INIT_FAIL',

    GET_PLANS = '@@payment/GET_PLANS',
    GET_PLANS_START = '@@payment/GET_PLANS_START',
    GET_PLANS_SUCCESS = '@@payment/GET_PLANS_SUCCESS',
    GET_PLANS_FAIL = '@@payment/GET_PLANS_FAIL',

    GET_USAGE = '@@payment/GET_USAGE',
    GET_USAGE_START = '@@payment/GET_USAGE_START',
    GET_USAGE_SUCCESS = '@@payment/GET_USAGE_SUCCESS',
    GET_USAGE_FAIL = '@@payment/GET_USAGE_FAIL',

    GET_CUSTOMER = '@@payment/GET_CUSTOMER',
    GET_CUSTOMER_START = '@@payment/GET_CUSTOMER_START',
    GET_CUSTOMER_SUCCESS = '@@payment/GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAIL = '@@payment/GET_CUSTOMER_FAIL',

    CREATE_SUBSCRIPTION_FOR_CUSTOMER = '@@payment/CREATE_SUBSCRIPTION_FOR_CUSTOMER',
    CREATE_SUBSCRIPTION_FOR_CUSTOMER_START = '@@payment/CREATE_SUBSCRIPTION_FOR_CUSTOMER_START',
    CREATE_SUBSCRIPTION_FOR_CUSTOMER_SUCCESS = '@@payment/CREATE_SUBSCRIPTION_FOR_CUSTOMER_SUCCESS',
    CREATE_SUBSCRIPTION_FOR_CUSTOMER_FAIL = '@@payment/CREATE_SUBSCRIPTION_FOR_CUSTOMER_FAIL',

    GET_USER_SUBSCRIPTION = '@@payment/GET_USER_SUBSCRIPTION',
    GET_USER_SUBSCRIPTION_START = '@@payment/GET_USER_SUBSCRIPTION_START',
    GET_USER_SUBSCRIPTION_FAIL = '@@payment/GET_USER_SUBSCRIPTION_FAIL',
    GET_USER_SUBSCRIPTION_SUCCESS = '@@payment/GET_USER_SUBSCRIPTION_SUCCESS',

    ADD_CUSTOMER_CARD = '@@payment/ADD_CUSTOMER_CARD',
    ADD_CUSTOMER_CARD_START = '@@payment/ADD_CUSTOMER_CARD_START',
    ADD_CUSTOMER_CARD_FAIL = '@@payment/ADD_CUSTOMER_CARD_FAIL',
    ADD_CUSTOMER_CARD_SUCCESS = '@@payment/ADD_CUSTOMER_CARD_SUCCESS',

    CANCEL_USER_SUBSCRIPTION = '@@payment/CANCEL_USER_SUBSCRIPTION',
    CANCEL_USER_SUBSCRIPTION_START = '@@payment/CANCEL_USER_SUBSCRIPTION_START',
    CANCEL_USER_SUBSCRIPTION_FAIL = '@@payment/CANCEL_USER_SUBSCRIPTION_FAIL',
    CANCEL_USER_SUBSCRIPTION_SUCCESS = '@@payment/CANCEL_USER_SUBSCRIPTION_SUCCESS',

    ADD_CREDITS = '@@payment/ADD_CREDITS',
    ADD_CREDITS_START = '@@payment/ADD_CREDITS_START',
    ADD_CREDITS_FAIL = '@@payment/ADD_CREDITS_FAIL',
    ADD_CREDITS_SUCCESS = '@@payment/ADD_CREDITS_SUCCESS',

    ADD_DUBBING_CREDITS = '@@payment/ADD_DUBBING_CREDITS',
    ADD_DUBBING_CREDITS_START = '@@payment/ADD_DUBBING_CREDITS_START',
    ADD_DUBBING_CREDITS_FAIL = '@@payment/ADD_DUBBING_CREDITS_FAIL',
    ADD_DUBBING_CREDITS_SUCCESS = '@@payment/ADD_DUBBING_CREDITS_SUCCESS',

    UPDATE_STORAGE = '@@payment/UPDATE_STORAGE',
    UPDATE_STORAGE_START = '@@payment/UPDATE_STORAGE_START',
    UPDATE_STORAGE_FAIL = '@@payment/UPDATE_STORAGE_FAIL',
    UPDATE_STORAGE_SUCCESS = '@@payment/UPDATE_STORAGE_SUCCESS',

    GET_ADDONS = '@@payment/GET_ADDONS',
    GET_ADDONS_START = '@@payment/GET_ADDONS_START',
    GET_ADDONS_FAIL = '@@payment/GET_ADDONS_FAIL',
    GET_ADDONS_SUCCESS = '@@payment/GET_ADDONS_SUCCESS',

    SET_PREFERRED_CARD = '@@payment/SET_PREFERRED_CARD',
    SET_PREFERRED_CARD_START = '@@payment/SET_PREFERRED_CARD_START',
    SET_PREFERRED_CARD_FAIL = '@@payment/SET_PREFERRED_CARD_FAIL',
    SET_PREFERRED_CARD_SUCCESS = '@@payment/SET_PREFERRED_CARD_SUCCESS',

    SELECT_PLAN = '@@payment/SELECT_PLAN',
    SET_SELECTED_PLAN = '@@payment/SET_SELECTED_PLAN',

    TOGGLE_SUBSCRIPTION_ACTIVE_STATUS = '@@payment/TOGGLE_SUBSCRIPTION_ACTIVE_STATUS',

    ACTIVATE_SUBSCRIPTION = '@@payment/ACTIVATE_SUBSCRIPTION',
    ACTIVATE_SUBSCRIPTION_START = '@@payment/ACTIVATE_SUBSCRIPTION_START',
    ACTIVATE_SUBSCRIPTION_FAIL = '@@payment/ACTIVATE_SUBSCRIPTION_FAIL',
    ACTIVATE_SUBSCRIPTION_SUCCESS = '@@payment/ACTIVATE_SUBSCRIPTION_SUCCESS',

    SET_CARD_ID_TO_REMOVE = '@@payment/SET_CARD_ID_TO_REMOVE',
    DELETE_CUSTOMER_CARD = '@@payment/DELETE_CUSTOMER_CARD',
    DELETE_CUSTOMER_CARD_START = '@@payment/DELETE_CUSTOMER_CARD_START',
    DELETE_CUSTOMER_CARD_FAIL = '@@payment/DELETE_CUSTOMER_CARD_FAIL',
    DELETE_CUSTOMER_CARD_SUCCESS = '@@payment/DELETE_CUSTOMER_CARD_SUCCESS',

    GET_UPCOMING_SUBSCRIPTION = '@@payment/GET_UPCOMING_SUBSCRIPTION',
    GET_UPCOMING_SUBSCRIPTION_START = '@@payment/GET_UPCOMING_SUBSCRIPTION_START',
    GET_UPCOMING_SUBSCRIPTION_FAIL = '@@payment/GET_UPCOMING_SUBSCRIPTION_FAIL',
    GET_UPCOMING_SUBSCRIPTION_SUCCESS = '@@payment/GET_UPCOMING_SUBSCRIPTION_SUCCESS',

    ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION = '@@payment/ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION',
    ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_START = '@@payment/ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_START',
    ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_FAIL = '@@payment/ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_FAIL',
    ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS = '@@payment/ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS',

    GET_SUBSCRIPTION_PAYMENT_DETAILS = '@@payment/GET_SUBSCRIPTION_PAYMENT_DETAILS',
    GET_ADDON_PAYMENT_DETAILS = '@@payment/GET_ADDON_PAYMENT_DETAILS',
    GET_CREDITS_PAYMENT_DETAILS = '@@payment/GET_CREDITS_PAYMENT_DETAILS',
    SETUP_INTENT = '@@payment/SETUP_INTENT',
    PAYMENT_INTENT = '@@payment/PAYMENT_INTENT',

    CONFIRM_SETUP = '@@payment/CONFIRM_SETUP',
    CONFIRM_SETUP_START = '@@payment/CONFIRM_SETUP_START',
    CONFIRM_SETUP_FAIL = '@@payment/CONFIRM_SETUP_FAIL',
    CONFIRM_SETUP_SUCCESS = '@@payment/CONFIRM_SETUP_SUCCESS',

    SET_STRIPE_PROMISE = '@payment/SET_STRIPE_PROMISE',

    GET_INVOICES = '@payment/GET_INVOICES',
    GET_INVOICES_START = '@payment/GET_INVOICES_START',
    GET_INVOICES_SUCCESS = '@payment/GET_INVOICES_SUCCESS',
    GET_INVOICES_FAIL = '@payment/GET_INVOICES_FAIL',
}

export interface GetPlansAction {
    type: PaymentActionTypes.GET_PLANS;
}
export interface GetPlansStartAction {
    type: PaymentActionTypes.GET_PLANS_START;
}
export interface GetPlansFailAction {
    type: PaymentActionTypes.GET_PLANS_FAIL;
    payload: any;
}
export interface GetPlansSuccessAction {
    type: PaymentActionTypes.GET_PLANS_SUCCESS;
    payload: {
        plans: Array<Product>;
    };
}
export interface GetUserUsageAction {
    type: PaymentActionTypes.GET_USAGE;
}
export interface GetUserUsageStartAction {
    type: PaymentActionTypes.GET_USAGE_START;
}
export interface GetUserUsageFailAction {
    type: PaymentActionTypes.GET_USAGE_FAIL;
    payload: any;
}
export interface GetUserUsageSuccessAction {
    type: PaymentActionTypes.GET_USAGE_SUCCESS;
    payload: UserUsageAndTotalSubscriptionData;
}
export interface GetCustomerAction {
    type: PaymentActionTypes.GET_CUSTOMER;
}
export interface GetCustomerStartAction {
    type: PaymentActionTypes.GET_CUSTOMER_START;
}
export interface GetCustomerFailAction {
    type: PaymentActionTypes.GET_CUSTOMER_FAIL;
    payload: any;
}
export interface GetCustomerSuccessAction {
    type: PaymentActionTypes.GET_CUSTOMER_SUCCESS;
    payload: Customer;
}
export interface CreateSubscriptionForCustomerActionPayload {
    data: UpdateUserSubscription;
    paymentInfo: SubscriptionPlanPaymentInfo;
    stripe?: any;
}
export interface CreateSubscriptionForCustomerAction {
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER;
    payload: CreateSubscriptionForCustomerActionPayload;
}
export interface CreateSubscriptionForCustomerStartAction {
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_START;
}
export interface CreateSubscriptionForCustomerFailAction {
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_FAIL;
    payload: any;
}
export interface CreateSubscriptionForCustomerSuccessAction {
    type: PaymentActionTypes.CREATE_SUBSCRIPTION_FOR_CUSTOMER_SUCCESS;
}
export interface GetUserSubscriptionAction {
    type: PaymentActionTypes.GET_USER_SUBSCRIPTION;
}
export interface GetUserSubscriptionStartAction {
    type: PaymentActionTypes.GET_USER_SUBSCRIPTION_START;
}
export interface GetUserSubscriptionFailAction {
    type: PaymentActionTypes.GET_USER_SUBSCRIPTION_FAIL;
    payload: any;
}
export interface GetUserSubscriptionSuccessAction {
    type: PaymentActionTypes.GET_USER_SUBSCRIPTION_SUCCESS;
    payload: CurrentSubscription;
}
export interface AddCustomerCardActionPayload {
    cardBody: AddCustomerCardBody;
    activateСurrentSubscription?: boolean;
    stripe?: any;
}
export interface AddCustomerCardAction {
    type: PaymentActionTypes.ADD_CUSTOMER_CARD;
    payload: AddCustomerCardActionPayload;
}
export interface AddCustomerCardStartAction {
    type: PaymentActionTypes.ADD_CUSTOMER_CARD_START;
}
export interface AddCustomerCardFailAction {
    type: PaymentActionTypes.ADD_CUSTOMER_CARD_FAIL;
    payload: any;
}
export interface AddCustomerCardSuccessAction {
    type: PaymentActionTypes.ADD_CUSTOMER_CARD_SUCCESS;
}
export interface CancelUserSubscriptionAction {
    type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION;
    payload: {
        showCanceledStatus?: boolean;
    };
}
export interface CancelUserSubscriptionStartAction {
    type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_START;
}
export interface CancelUserSubscriptionFailAction {
    type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_FAIL;
    payload: any;
}
export interface CancelUserSubscriptionSuccessAction {
    type: PaymentActionTypes.CANCEL_USER_SUBSCRIPTION_SUCCESS;
}

export interface AddCreditsActionPayload {
    data: UpdateUserCredits;
}
export interface AddCreditsAction {
    type: PaymentActionTypes.ADD_CREDITS;
    payload: {
        data: UpdateUserCredits;
        paymentInfo?: SubscriptionPlanPaymentInfo;
    };
}
export interface AddCreditsStartAction {
    type: PaymentActionTypes.ADD_CREDITS_START;
}
export interface AddCreditsFailAction {
    type: PaymentActionTypes.ADD_CREDITS_FAIL;
    payload: any;
}
export interface AddCreditsSuccessAction {
    type: PaymentActionTypes.ADD_CREDITS_SUCCESS;
}

export interface AddDubbingCreditsAction {
    type: PaymentActionTypes.ADD_DUBBING_CREDITS;
    payload: {
        data: UpdateUserCredits;
        paymentInfo?: SubscriptionPlanPaymentInfo;
    };
}
export interface AddDubbingCreditsStartAction {
    type: PaymentActionTypes.ADD_DUBBING_CREDITS_START;
}
export interface AddDubbingCreditsFailAction {
    type: PaymentActionTypes.ADD_DUBBING_CREDITS_FAIL;
    payload: any;
}
export interface AddDubbingCreditsSuccessAction {
    type: PaymentActionTypes.ADD_DUBBING_CREDITS_SUCCESS;
}


export interface UpdateStorageActionPayload {
    data: UpdateUserAddon;
}
export interface UpdateStorageAction {
    type: PaymentActionTypes.UPDATE_STORAGE;
    payload: {
        data: UpdateUserAddon;
        paymentInfo?: SubscriptionPlanPaymentInfo;
    };
}
export interface UpdateStorageStartAction {
    type: PaymentActionTypes.UPDATE_STORAGE_START;
}
export interface UpdateStorageFailAction {
    type: PaymentActionTypes.UPDATE_STORAGE_FAIL;
    payload: any;
}
export interface UpdateStorageSuccessAction {
    type: PaymentActionTypes.UPDATE_STORAGE_SUCCESS;
}
export interface GetAddonsAction {
    type: PaymentActionTypes.GET_ADDONS;
}
export interface GetAddonsStartAction {
    type: PaymentActionTypes.GET_ADDONS_START;
}
export interface GetAddonsFailAction {
    type: PaymentActionTypes.GET_ADDONS_FAIL;
    payload: any;
}
export interface GetAddonsSuccessAction {
    type: PaymentActionTypes.GET_ADDONS_SUCCESS;
    payload: Array<Product>;
}
export interface SetPreferredCardAction {
    type: PaymentActionTypes.SET_PREFERRED_CARD;
    payload: string;
}
export interface SetPreferredCardStartAction {
    type: PaymentActionTypes.SET_PREFERRED_CARD_START;
}
export interface SetPreferredCardFailAction {
    type: PaymentActionTypes.SET_PREFERRED_CARD_FAIL;
    payload: any;
}
export interface SetPreferredCardSuccessAction {
    type: PaymentActionTypes.SET_PREFERRED_CARD_SUCCESS;
}

export interface PaymentFlowInitAction {
    type: PaymentActionTypes.PAYMENT_FLOW_INIT;
}
export interface PaymentFlowInitStartAction {
    type: PaymentActionTypes.PAYMENT_FLOW_INIT_START;
}
export interface PaymentFlowInitFailAction {
    type: PaymentActionTypes.PAYMENT_FLOW_INIT_FAIL;
    payload: any;
}
export interface PaymentFlowInitSuccessAction {
    type: PaymentActionTypes.PAYMENT_FLOW_INIT_SUCCESS;
}
export interface SelectPlanAction {
    type: PaymentActionTypes.SELECT_PLAN;
    payload: Product | null;
}
export interface SetSelectedPlanAction {
    type: PaymentActionTypes.SET_SELECTED_PLAN;
    payload: Product | null;
}
export interface ToggleSubscriptionActiveStatusAction {
    type: PaymentActionTypes.TOGGLE_SUBSCRIPTION_ACTIVE_STATUS;
}

export interface ActivateSubscriptionAction {
    type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION;
}
export interface ActivateSubscriptionStartAction {
    type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_START;
}
export interface ActivateSubscriptionFailAction {
    type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_FAIL;
    payload: any;
}
export interface ActivateSubscriptionSuccessAction {
    type: PaymentActionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS;
}
export interface GetUpcomingSubscriptionAction {
    type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION;
}
export interface GetUpcomingSubscriptionStartAction {
    type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_START;
}
export interface GetUpcomingSubscriptionFailAction {
    type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_FAIL;
    payload: any;
}
export interface GetUpcomingSubscriptionSuccessAction {
    type: PaymentActionTypes.GET_UPCOMING_SUBSCRIPTION_SUCCESS;
    payload: UpcomingPlan;
}
export interface AddCustomerPaymentMethodAndSubscriptionActionPayload {
    customer: CreateCustomerBody;
    subscriptionBody: UpdateSubscriptionBody;
    stripe?: any;
}

export interface AddCustomerPaymentMethodAndSubscriptionAction {
    type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION;
    payload: AddCustomerPaymentMethodAndSubscriptionActionPayload;
}
export interface CreateCustomerWithSubscriptionStartAction {
    type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_START;
}
export interface CreateCustomerWithSubscriptionFailAction {
    type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_FAIL;
    payload: any;
}
export interface CreateCustomerWithSubscriptionSuccessAction {
    type: PaymentActionTypes.ADD_CUSTOMER_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS;
}

export interface SetCardIdToRemoveAction {
    type: PaymentActionTypes.SET_CARD_ID_TO_REMOVE;
    payload: string;
}
export interface DeleteCustomerCardAction {
    type: PaymentActionTypes.DELETE_CUSTOMER_CARD;
    payload: {
        cardId: string;
        isDeletionConfirmed?: boolean;
    };
}

export interface DeleteCustomerCardStartAction {
    type: PaymentActionTypes.DELETE_CUSTOMER_CARD_START;
}

export interface DeleteCustomerCardFailAction {
    type: PaymentActionTypes.DELETE_CUSTOMER_CARD_FAIL;
    payload: any;
}
export interface DeleteCustomerCardSuccessAction {
    type: PaymentActionTypes.DELETE_CUSTOMER_CARD_SUCCESS;
}

export interface SetupIntentAction {
    type: PaymentActionTypes.SETUP_INTENT;
    cb: (error: string | null, data?: any) => void;
}

export interface PaymentIntentAction {
    type: PaymentActionTypes.PAYMENT_INTENT;
    cb: (error: string | null, data?: any) => void;
    payload: UpdateUserSubscription;
}

export interface ConfirmSetupAction {
    type: PaymentActionTypes.CONFIRM_SETUP;
    payload: {
        data: ConfirmSetupBody;
        paymentInfo?: SubscriptionPlanPaymentInfo;
    };
}
export interface ConfirmSetupStartAction {
    type: PaymentActionTypes.CONFIRM_SETUP_START;
}
export interface ConfirmSetupFailAction {
    type: PaymentActionTypes.CONFIRM_SETUP_FAIL;
    payload: string;
}
export interface ConfirmSetupSuccessAction {
    type: PaymentActionTypes.CONFIRM_SETUP_SUCCESS;
}

export interface SetStripePromiseAction {
    type: PaymentActionTypes.SET_STRIPE_PROMISE;
    payload: any;
}

// invoices
export interface GetInvoicesAction {
    type: PaymentActionTypes.GET_INVOICES;
}
export interface GetInvoicesStartAction {
    type: PaymentActionTypes.GET_INVOICES_START;
}
export interface GetInvoicesSuccessAction {
    type: PaymentActionTypes.GET_INVOICES_SUCCESS;
    payload: IInvoice[];
}

export interface GetInvoicesFailAction {
    type: PaymentActionTypes.GET_INVOICES_FAIL;
    payload: any;
}

export type PaymentAction =
    | DeleteCustomerCardAction
    | DeleteCustomerCardStartAction
    | DeleteCustomerCardFailAction
    | DeleteCustomerCardSuccessAction
    | SetCardIdToRemoveAction
    | AddCustomerPaymentMethodAndSubscriptionAction
    | CreateCustomerWithSubscriptionStartAction
    | CreateCustomerWithSubscriptionFailAction
    | CreateCustomerWithSubscriptionSuccessAction
    | GetUpcomingSubscriptionAction
    | GetUpcomingSubscriptionStartAction
    | GetUpcomingSubscriptionFailAction
    | GetUpcomingSubscriptionSuccessAction
    | ActivateSubscriptionAction
    | ActivateSubscriptionStartAction
    | ActivateSubscriptionFailAction
    | ActivateSubscriptionSuccessAction
    | ToggleSubscriptionActiveStatusAction
    | SelectPlanAction
    | SetSelectedPlanAction
    | PaymentFlowInitAction
    | PaymentFlowInitStartAction
    | PaymentFlowInitFailAction
    | PaymentFlowInitSuccessAction
    | SetPreferredCardAction
    | SetPreferredCardStartAction
    | SetPreferredCardFailAction
    | SetPreferredCardSuccessAction
    | GetAddonsAction
    | GetAddonsStartAction
    | GetAddonsFailAction
    | GetAddonsSuccessAction
    | UpdateStorageAction
    | UpdateStorageStartAction
    | UpdateStorageFailAction
    | UpdateStorageSuccessAction
    | AddCreditsAction
    | AddCreditsStartAction
    | AddCreditsFailAction
    | AddCreditsSuccessAction
    | AddDubbingCreditsAction
    | AddDubbingCreditsStartAction
    | AddDubbingCreditsFailAction
    | AddDubbingCreditsSuccessAction
    | CancelUserSubscriptionAction
    | CancelUserSubscriptionStartAction
    | CancelUserSubscriptionFailAction
    | CancelUserSubscriptionSuccessAction
    | AddCustomerCardAction
    | AddCustomerCardStartAction
    | AddCustomerCardFailAction
    | AddCustomerCardSuccessAction
    | GetUserSubscriptionAction
    | GetUserSubscriptionStartAction
    | GetUserSubscriptionFailAction
    | GetUserSubscriptionSuccessAction
    | CreateSubscriptionForCustomerAction
    | CreateSubscriptionForCustomerStartAction
    | CreateSubscriptionForCustomerFailAction
    | CreateSubscriptionForCustomerSuccessAction
    | GetCustomerAction
    | GetCustomerStartAction
    | GetCustomerFailAction
    | GetCustomerSuccessAction
    | GetPlansAction
    | GetPlansStartAction
    | GetPlansFailAction
    | GetPlansSuccessAction
    | GetUserUsageAction
    | GetUserUsageStartAction
    | GetUserUsageFailAction
    | GetUserUsageSuccessAction
    | SetStripePromiseAction
    | ConfirmSetupAction
    | ConfirmSetupStartAction
    | ConfirmSetupSuccessAction
    | ConfirmSetupFailAction
    | GetInvoicesAction
    | GetInvoicesStartAction
    | GetInvoicesFailAction
    | GetInvoicesSuccessAction;
