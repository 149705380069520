export enum StatusType {
    SAVING = 'SAVING',
    WAITING = 'WAITING',
    SUCCEEDED = 'SUCCEEDED',
    COMPLETED = 'COMPLETED',
    PREPARING = 'PREPARING',
    ERROR = 'ERROR',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
    INPROGRESS = 'INPROGRESS',
}

export enum StatusesCategory {
    TRANSCODING = 'transcoding',
    ANALYZE = 'analyze',
    INGEST = 'ingest',
    GENERATION = 'generation',
}
