import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 1;

const SftpIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512;"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              d="M266.667,298.667h170.667c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667H266.667
        c-5.888,0-10.667,4.779-10.667,10.667S260.779,298.667,266.667,298.667z"
              fill={color}
            />
            <path
              fill={color}
              d="M512,202.667V160c0-10.304-3.072-19.84-8.149-28.011c-0.128-0.256-0.149-0.555-0.299-0.789L437.589,25.173
				C427.797,9.429,410.859,0,392.299,0H119.723c-18.581,0-35.499,9.429-45.291,25.173L8.469,131.2
				c-0.128,0.213-0.128,0.448-0.256,0.683C3.093,140.053,0,149.653,0,160v42.667c0,17.493,8.597,32.939,21.675,42.667
				C8.597,255.061,0,270.485,0,288v42.667c0,17.493,8.597,32.939,21.675,42.667C8.597,383.061,0,398.485,0,416v42.667
				C0,488.064,23.936,512,53.333,512h139.861c5.909,0,10.667-4.779,10.667-10.667c0-5.888-4.779-10.667-10.667-10.667H53.333
				c-17.643,0-32-14.357-32-32V416c0-17.664,14.357-32,32-32h178.432c5.888,0,10.667-4.779,10.667-10.667
				c0-5.888-4.779-10.667-10.667-10.667H53.333c-17.643,0-32-14.357-32-32V288c0-17.664,14.357-32,32-32h405.333
				c17.643,0,32,14.357,32,32v42.667c0,4.693-1.152,9.408-3.371,13.952c-2.581,5.291-0.405,11.669,4.885,14.272
				c5.312,2.603,11.691,0.427,14.272-4.885c3.691-7.509,5.547-15.339,5.547-23.339V288c0-17.493-8.597-32.939-21.675-42.667
				C503.403,235.584,512,220.16,512,202.667z M92.544,36.437c5.867-9.451,16.021-15.104,27.157-15.104h272.576
				c11.136,0,21.291,5.653,27.179,15.104l44.011,70.72c-1.6-0.171-3.157-0.491-4.8-0.491H53.333c-1.643,0-3.2,0.32-4.8,0.469
				L92.544,36.437z M490.667,202.667c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32V160c0-17.664,14.357-32,32-32
				h405.333c17.643,0,32,14.336,32,32V202.667z"
            />
            <path
              fill={color}
              d="M42.667,437.333c0,17.643,14.357,32,32,32c17.643,0,32-14.357,32-32c0-17.643-14.357-32-32-32
				C57.024,405.333,42.667,419.669,42.667,437.333z M85.333,437.333c0,5.888-4.779,10.667-10.667,10.667S64,443.221,64,437.333
				c0-5.888,4.779-10.667,10.667-10.667S85.333,431.445,85.333,437.333z"
            />
            <path
              fill={color}
              d="M437.333,362.667c-1.941,0-3.883,0.107-5.781,0.277C413.781,336.277,383.936,320,352,320
				c-49.664,0-90.645,37.909-95.509,86.315c-24.555,4.736-43.157,26.411-43.157,52.352c0,29.397,23.936,53.333,53.333,53.333
				h170.667C478.507,512,512,478.507,512,437.333S478.507,362.667,437.333,362.667z M437.333,490.667H266.667
				c-17.643,0-32-14.357-32-32s14.357-32,32-32c5.888,0,10.667-4.779,10.667-10.667c0-41.173,33.493-74.667,74.667-74.667
				c26.667,0,51.52,14.699,64.811,38.357c2.219,3.947,6.613,6.037,11.179,5.269c3.051-0.533,6.144-0.96,9.344-0.96
				c29.397,0,53.333,23.936,53.333,53.333S466.731,490.667,437.333,490.667z"
            />
            <path
              fill={color}
              d="M437.333,149.333H266.667c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h170.667
				c5.888,0,10.667-4.779,10.667-10.667S443.221,149.333,437.333,149.333z"
            />
            <path
              fill={color}
              d="M437.333,192H266.667c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h170.667
				c5.888,0,10.667-4.779,10.667-10.667S443.221,192,437.333,192z"
            />
            <path
              fill={color}
              d="M74.667,149.333c-17.643,0-32,14.336-32,32s14.357,32,32,32c17.643,0,32-14.357,32-32
				C106.667,163.691,92.309,149.333,74.667,149.333z M74.667,192C68.779,192,64,187.221,64,181.333s4.779-10.667,10.667-10.667
				s10.667,4.779,10.667,10.667S80.555,192,74.667,192z"
            />
            <path
              fill={color}
              d="M42.667,309.333c0,17.643,14.357,32,32,32c17.643,0,32-14.357,32-32c0-17.643-14.357-32-32-32
				C57.024,277.333,42.667,291.669,42.667,309.333z M85.333,309.333c0,5.888-4.779,10.667-10.667,10.667S64,315.221,64,309.333
				s4.779-10.667,10.667-10.667S85.333,303.445,85.333,309.333z"
            />
          </g>
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default SftpIcon;
