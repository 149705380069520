import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Invoice from 'components/Invoice';

import {
    getInvoices,
    getInvoicesList,
    // getInvoicesLoading,
} from 'state/modules/payment';

import { IInvoice } from 'interfaces/payment';

const Container = styled.div`
    padding-top: 1.5rem;
    height: 100%;
    overflow: hidden;
    padding-left: 1.5rem;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr 0.75fr 1fr 0.25fr;
    border-bottom: 1px solid rgb(128, 128, 128);
    //padding-left: 1.5rem;
    //padding-right: 1.5rem;
    height: 36px;
    align-items: center;
`;

const HeaderItem = styled.div`
    text-transform: uppercase;
    color: rgb(26, 27, 37);
    font-family: 'Proxima Nova Medium', sans-serif;
    font-size: 14px;
`;

const List = styled.div`
    padding-bottom: 1.5rem;
    height: calc(100% - 36px);
    overflow-y: auto;
`;

const InvoicesList = () => {
    const invoices = useSelector(getInvoicesList);
    // const isLoading = useSelector(getInvoicesLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInvoices());
    }, [dispatch]);

    const renderItem = (item: IInvoice, index: number) => {
        return (
            <Invoice
                key={item.id}
                data={item}
                bordered={index < invoices.length}
            />
        );
    };

    return (
        <Container>
            <Header>
                <HeaderItem>AMOUNT</HeaderItem>
                <HeaderItem>INVOICE NUMBER</HeaderItem>
                <HeaderItem>Due</HeaderItem>
                <HeaderItem>CREATED</HeaderItem>
            </Header>
            <List>
                {invoices.map((item, index) => renderItem(item, index))}
            </List>
        </Container>
    );
};

export default InvoicesList;
