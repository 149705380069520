import produce from 'immer';

import { TemplatesAction, TemplatesActionTypes, TemplatesState } from './types';

const initialState: TemplatesState = {
  templatesList: [],
  total: 0,
  templatesIdsForDelete: [],
  selectedTemplates: [],
  templateDetails: null,
  isLoading: true,
  isTemplateDetailsLoading: true,
  isTemplateUpdateLoading: false,
  isTemplateCreationLoading: false,
};

export default (
  state = initialState,
  action: TemplatesAction,
): TemplatesState => produce(state, (draft) => {
  switch (action.type) {
    case TemplatesActionTypes.GET_TEMPLATES_START:
      draft.isLoading = true;

      return;
    case TemplatesActionTypes.GET_TEMPLATES_SUCCESS:
      draft.templatesList = action.payload.templates;
      draft.total = action.payload.total;
      draft.isLoading = false;

      return;
    case TemplatesActionTypes.GET_TEMPLATES_FAIL:
      draft.isLoading = false;

      return;
    case TemplatesActionTypes.GET_TEMPLATE_DETAILS_SUCCESS:
      draft.templateDetails = action.payload;
      draft.isTemplateDetailsLoading = false;

      return;
    case TemplatesActionTypes.GET_TEMPLATE_DETAILS_START:
      draft.isTemplateDetailsLoading = true;

      return;
    case TemplatesActionTypes.GET_TEMPLATE_DETAILS_FAIL:
      draft.isTemplateDetailsLoading = false;

      return;
    case TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_START:
      draft.isTemplateUpdateLoading = true;

      return;
    case TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_SUCCESS:
      draft.isTemplateUpdateLoading = false;

      return;
    case TemplatesActionTypes.UPDATE_TEMPLATE_DETAILS_FAIL:
      draft.isTemplateUpdateLoading = false;

      return;
    case TemplatesActionTypes.CREATE_TEMPLATE_START:
      draft.isTemplateCreationLoading = true;

      return;
    case TemplatesActionTypes.CREATE_TEMPLATE_SUCCESS:
      // draft.templateDetails = action.payload;
      draft.isTemplateCreationLoading = false;

      return;
    case TemplatesActionTypes.CREATE_TEMPLATE_FAIL:
      draft.isTemplateCreationLoading = false;

      return;

    case TemplatesActionTypes.SET_TEMPLATES_IDS_FOR_DELETE:
      draft.templatesIdsForDelete = action.payload;

      return;
    case TemplatesActionTypes.SET_SELECTED_TEMPLATES:
      draft.selectedTemplates = action.payload;

      return;
    default:
      return state;
  }
});
