import { ChangeEvent, useState } from "react";

import { Icon } from "components/UI";

import styles from "./styles.module.scss";

interface Props {
  placeholder?: string;
  onChange: (value: string) => void;
  value: string;
  color?: string;
  blue?: boolean;
  onSubmit?: () => void;
}

const SearchInput = (props: Props): JSX.Element => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const { value, placeholder, color, blue, onSubmit, onChange } = props;

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (!newValue.length) {
      setIsEmpty(true);
    } else if (newValue && isEmpty) {
      setIsEmpty(false);
    }

    onChange(newValue);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div
        className={[
          styles.SearchInput,
          blue ? styles.SearchInput_blue : "",
        ].join(" ")}
      >
        <button
          onClick={handleSubmit}
          className={styles.SearchInput__searchButton}
        >
          <Icon name="search" size={16} color={color || "#8E9BBC"} />
        </button>
        <div
          className={[
            styles.SearchInput__inputContainer,
            isFocused ? styles.SearchInput__inputContainer_focused : "",
            isEmpty ? styles.SearchInput__inputContainer_isEmpty : "",
          ].join(" ")}
        >
          <input
            className={styles.SearchInput__input}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleKeyPress}
            style={{
              color: color || "#919191",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SearchInput;
