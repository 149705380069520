import {
    ConfirmSetupBody,
    PaymentType,
    SubscriptionPlanPaymentInfo,
} from 'state/modules/payment';

export const getPaymentMethodName = (type: string) => {
    switch (type) {
        case 'paypal':
            return 'PayPal';
        case 'ideal':
            return 'iDEAL';
        case 'eps':
            return 'EPS';
        case 'sofort':
            return 'Sofort';
        case 'bancontact':
            return 'Bancontact';
        case 'giropay':
            return 'giropay';
        case 'p24':
            return 'Przelewy24';
        case 'alipay':
            return 'Alipay';
        default:
            return type;
    }
};

export const getPaymentMethodSetupBody = (
    search: string
): {
    data: ConfirmSetupBody;
    paymentInfo?: SubscriptionPlanPaymentInfo;
} => {
    const searchParams = new URLSearchParams(search);

    const setupIntent: string | undefined =
        searchParams.get('setup_intent') ?? undefined;

    const paymentIntent: string | undefined =
        searchParams.get('payment_intent') ?? undefined;

    if (!setupIntent && !paymentIntent)
        return {
            data: {},
        } as {
            data: ConfirmSetupBody;
            paymentInfo: SubscriptionPlanPaymentInfo;
        };

    const type: PaymentType | undefined =
        (searchParams.get('type') as PaymentType) ?? undefined;
    const productId: string | undefined =
        searchParams.get('productId') ?? undefined;
    const upcomingProductId: string | undefined =
        searchParams.get('upcomingProductId') ?? undefined;
    const priceId: string | undefined =
        searchParams.get('priceId') ?? undefined;
    const upcomingPriceId: string | undefined =
        searchParams.get('upcomingPriceId') ?? undefined;
    const coupon: string | undefined = searchParams.get('coupon') ?? undefined;
    const isTrial = !!searchParams.get('isTrial');
    let quantity: any = searchParams.get('quantity') ?? undefined;

    const name: string | undefined = searchParams.get('name') ?? '';
    const plan: string | undefined = searchParams.get('plan') ?? '';
    const interval: string | undefined = searchParams.get('interval') ?? '';
    const paywallType: string | undefined =
        searchParams.get('paywallType') ?? '';

    const couponName: string | undefined =
        searchParams.get('couponName') ?? undefined;
    const price: string | undefined = searchParams.get('price') ?? '0';
    const paymentType: string | undefined =
        searchParams.get('paymentType') ?? undefined;

    if (quantity) {
        quantity = +quantity;
    }

    const body: ConfirmSetupBody = {
        // setupIntent,
        type,
    };

    if (setupIntent) {
        body.setupIntent = setupIntent;
    }

    if (paymentIntent) {
        body.paymentIntent = paymentIntent;
    }

    if (type === PaymentType.addon) {
        body.addon = {
            productId,
            upcomingProductId,
            priceId,
            upcomingPriceId,
            quantity,
        };
    } else if (type === PaymentType.credits) {
        body.credits = {
            productId,
            upcomingProductId,
            priceId,
            upcomingPriceId,
            quantity,
        };
    } else if (type === PaymentType.subscription) {
        body.subscription = {
            productId,
            priceId,
            isTrial,
            coupon,
        };
    }

    let paymentInfo;
    if (paymentType === 'plan' || paymentType === 'addon') {
        paymentInfo = {
            name,
            couponName,
            price: +price,
            type: paymentType as 'plan' | 'addon',
            paywallType,
            interval: interval as 'monthly' | 'annual',
            plan,
        };
    }

    return {
        data: body,
        paymentInfo,
    };
};
