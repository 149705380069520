export enum ADDITIONAL_FILTER_OPTIONS {
    TEXT = 'text',
    MOST_APPEARING_CELEBRITIES = 'celebrities',
}

export enum TemplateDataItemType {
    FACIAL_ATTRIBUTE = 'facialAttribute',
    EMOTION = 'emotion',
    CELEBRITY = 'celebrity',
    USER_DEFINED = 'userDefined',
    FACES = 'faces',
    OBJECT = 'object',
    TRANSCRIPT = 'transcript',
    SHOT = 'shot',
    MODERATED_LABEL = 'moderatedLabel',
    TEXT = 'text',
    MOST_APPEARING_CELEBRITIES = 'celebrities',
}

export interface TemplateMakerActiveLabel {
    id: string;
    items: Array<TemplateMakerActiveLabelsItem>;
}

export interface TemplateMakerActiveLabelsItem {
    id: string;
    type: TemplateDataItemType;
}

export interface TemplateTempDataItemAttributes {
    attribute: string;
    emotion: string;
}
export interface TemplateTempDataItem {
    id?: string;
    type: TemplateDataItemType;
    value?: TemplateTempDataItemAttributes | string;
}

export interface TemplateParsedLabelValue {
    name: string;
    attribute?: string;
    emotion?: string;
}
