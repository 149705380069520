import { Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes, useLocation } from 'react-router';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';

import Entry from 'router/Entry';

import { changeLocation } from 'state/modules/app';
import { ProductPrepareTypes } from 'interfaces/preferences';

import ProjectSharing from 'containers/ProjectSharing';
import AppFlow from './AppFlow';

export declare type AuthenticatorRoute =
    | 'authenticated'
    | 'confirmResetPassword'
    | 'confirmSignIn'
    | 'confirmSignUp'
    | 'confirmVerifyUser'
    | 'forceNewPassword'
    | 'idle'
    | 'resetPassword'
    | 'setup'
    | 'signOut'
    | 'setupTOTP'
    | 'signIn'
    | 'signUp'
    | 'transition'
    | 'verifyUser';

declare type AuthStatus = 'configuring' | 'authenticated' | 'unauthenticated';

const Router = (): JSX.Element => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isSignIn, setIsSignIn] = useState(false);
    const [isAuthLoading, setAuthLoading] = useState(true);

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const {
        authStatus,
    }: {
        authStatus: AuthStatus;
    } = useAuthenticator((context) => [context.authStatus]);

    useEffect(() => {
        if (authStatus === 'authenticated') {
            setAuthenticated(true);
            setAuthLoading(false);
        } else if (authStatus === 'unauthenticated') {
            setAuthenticated(false);
            setAuthLoading(false);
        } else if (authStatus === 'configuring') {
            setAuthLoading(true);
        }
    }, [authStatus]);

    const invitationCode = params.get('invitationCode');
    const workspaceInviteId = params.get('workspaceInviteId');
    const selectedSubscriptionFromUrl = queryString.parse(location.hash)
        ?.selected_plan as string;
    const dispatch = useDispatch();

    useEffect(() => {
        if (invitationCode) {
            localStorage.setItem('invitationCode', invitationCode);
        }
    }, [invitationCode]);

    useEffect(() => {
        if (workspaceInviteId) {
            localStorage.setItem('workspaceInviteId', workspaceInviteId);
        }
    }, [workspaceInviteId]);

    useEffect(() => {
        if (
            selectedSubscriptionFromUrl &&
            selectedSubscriptionFromUrl.length > 0
        ) {
            sessionStorage.setItem(
                'selectedSubscription',
                selectedSubscriptionFromUrl
            );
            // navigate(location.pathname);
        }
    }, [selectedSubscriptionFromUrl, location]);

    useEffect(() => {
        if (location.pathname.includes('smart-search')) {
            dispatch(changeLocation(ProductPrepareTypes.LOGING));
        }
        if (location.pathname.includes('video-editing')) {
            dispatch(changeLocation(ProductPrepareTypes.PROJECTS));
        }
    }, [location, dispatch]);

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }: any) => {
            // console.log('auth', { event }, { data });
            switch (event) {
                case 'signIn':
                    // this.setState({ user: data });
                    setAuthenticated(true);
                    setIsSignIn(true);
                    break;
                case 'signOut':
                    // this.setState({ user: null });
                    setAuthenticated(false);
                    break;
                case 'customOAuthState':
                    // this.setState({ customState: data });
                    break;
                default:
                    return null;
            }
        });

        // Auth.currentAuthenticatedUser()
        //     .then((user) => console.log({ user }))
        //     .catch(() => console.log('Not signed in'));
    }, []);

    const renderRoutes = (): JSX.Element => {
        if (!isAuthLoading) {
            if (isAuthenticated) {
                return (
                    <AppFlow isSignIn={isSignIn} setIsSignIn={setIsSignIn} />
                );
            }

            return <Entry />;
        }

        return <></>;
    };

    return (
        <>
            <Suspense fallback={<></>}>
                <Routes>
                    <Route
                        path="/project/sharing"
                        element={<ProjectSharing />}
                    />
                    <Route path="*" element={renderRoutes()} />
                </Routes>
                {authStatus !== 'authenticated' ? <Authenticator /> : null}
                <ToastContainer />
            </Suspense>
        </>
    );
};

export default Router;
