import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import { Icon, NewButton, NewInput, PasswordInput } from 'components/UI';
import styles from './styles.module.scss';
import { amplitudeAnalytics } from '../../services/api/amplitudeAnalytics';
import { googleAnalytics } from '../../services/api/googleAnalytics';

export declare enum CognitoHostedUIIdentityProvider {
  Cognito = "COGNITO",
  Google = "Google",
  Facebook = "Facebook",
  Amazon = "LoginWithAmazon",
  Apple = "SignInWithApple"
}

interface IStepTwoState {
  isPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
}

interface IStepOneProps {
  email: string;
  emailError: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  passwordError: string;
  handleChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class StepOne extends Component<IStepOneProps, IStepTwoState> {
  public state = {
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
  };

  public handleChangeValueVisibility = (fieldType: string): void => {
    if (fieldType === 'password') {
      this.setState((prevState) => ({
        isPasswordVisible: !prevState.isPasswordVisible,
      }));
    } else {
      this.setState((prevState) => ({
        isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
      }));
    }
  };

  public togglePasswordVisibility = (): void => {
    this.handleChangeValueVisibility('password');
  };

  public handleGoogleAuth = () => {
    try {
        Auth.federatedSignIn({ provider: 'Google' } as any)
            .then(data => {
                console.log('Sign Up with Google: Success');

                amplitudeAnalytics.signUp({
                    login_method: 'google',
                });

                googleAnalytics.signUp({
                    method: 'google',
                });

            })
            .catch((error) => {
                console.error('Sign up error', error);
            });
    } catch (error) {
      console.log({ error })
    }
  }

  public renderEyeIcon = (visible: boolean): JSX.Element => (
    <Icon
      name={visible ? 'eye' : 'close-eye'}
      color={visible ? '#333333' : '#cecece'}
    />
  );

  public renderGoogleSignIn = () => <>
    <NewButton
      color="white"
      textColor="#62646a"
      leftContainer={<Icon size={18} name="google" />}
      bordered
      className={styles.StepOne__googleButton}
      onClick={this.handleGoogleAuth}
    >
      <p className={styles.StepOne__googleButtonTitle}>Continue with Google</p>
    </NewButton>
    <div className={styles.StepOne__divider}>
      <span className={styles.StepOne__dividerTitle}>Or</span>
    </div>
  </>

  public render(): JSX.Element {


    const {
      email,
      emailError,
      handleChangeEmail,
      passwordError,
      handleChangePassword,
      password,
    } = this.props;

    return (
      <div className={styles.StepOne}>
        {this.renderGoogleSignIn()}
        <div className={styles.StepOne__inputWrap}>
          <NewInput
            type="email"
            value={email}
            onChange={handleChangeEmail}
            placeholder="Email"
          />
          {emailError.length ? <p className={styles.StepOne__inputErrorMessage}>{emailError}</p> : null}
        </div>
        <PasswordInput
          type="password"
          value={password}
          onChange={handleChangePassword}
          placeholder="Password"
        />
        {passwordError.length ? <p className={styles.StepOne__inputErrorMessage}>{passwordError}</p> : null}
      </div>
    );
  }
}

export default StepOne;
