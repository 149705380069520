import { IconProps } from "./index";
import { calculateSideByRatio } from '../../../utils';

const SIDES_RATIO = 33 / 32;

export const AutoSubtitlingIcon = ({ size, color, secondColor }: IconProps) => {
    let height = 20;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.5 16C27.5 9.37258 22.1274 4 15.5 4C8.87258 4 3.5 9.37258 3.5 16C3.5 22.6274 8.87258 28 15.5 28C19.9417 28 23.8198 25.5868 25.8946 22M24.2081 14.6831L27.2081 17.6831L30.2081 14.6831"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 16.75L14.75 19L20.75 13"
                stroke={secondColor}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AutoSubtitlingIcon;
