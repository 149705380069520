import { Project } from 'interfaces/projects';
import { MediaFile, MediaType } from 'interfaces/videos';
import { DeletedSentence } from 'interfaces/transcript';
import { RawSentence, TransformedSentence } from '../metadata';

export interface ProjectVideoLanguages {
    videoId: string;
    languagesList: string[];
}

export interface SubtitlesExportStatusType {
    isLoading: boolean;
    isSuccess: boolean;
}

export interface ProjectSubtitlesState {
    projectCurrentSubtitlesList: TransformedSentence[];
    projectUnsyncedSubtitlesList: TransformedSentence[];
    projectDeletedUnsyncedSubtitlesIds: string[];
    projectAllSubtitlesList: TransformedSentence[];

    projectVideosIdsWithTranscriptGeneration: string[];
    projectVideosIdsWithTranslateGeneration: string[];

    projectVideosLanguages: ProjectVideoLanguages[];
    projectLanguages: string[];

    editableSentenceId: string;
    selectedSentenceId: string;

    searchQuery: string;

    getProjectSubtitlesListLoading: boolean;
    getProjectSubtitlesListSuccess: boolean;

    isProjectTranscriptCreationLoading: boolean;
    isProjectSubtitlesTranslateLoading: boolean;

    subtitlesExportStatus: SubtitlesExportStatusType;
    scrollableTranscriptIndex: number | null;

    addGeneratedSubtitlesLoading: boolean;
}

export enum ProjectSubtitlesActionTypes {
    GET_PROJECT_ALL_SUBTITLES_LIST = '@@projectSubtitles/GET_PROJECT_ALL_SUBTITLES_LIST',
    SET_PROJECT_ALL_SUBTITLES_LIST = '@@projectSubtitles/SET_PROJECT_ALL_SUBTITLES_LIST',
    ADD_GENERATED_SUBTITLES = '@@projectSubtitles/ADD_GENERATED_SUBTITLES',
    ADD_LIBRARY_VIDEO_SUBTITLES = '@@projectSubtitles/ADD_LIBRARY_VIDEO_SUBTITLES',
    DELETE_VIDEO_SUBTITLES = '@@projectSubtitles/DELETE_VIDEO_SUBTITLES',
    ADD_OR_EDIT_SUBTITLE = '@@projectSubtitles/ADD_OR_EDIT_SUBTITLE',
    DELETE_SUBTITLE = '@@projectSubtitles/DELETE_SUBTITLE',
    MERGE_SUBTITLES = '@@projectSubtitles/MERGE_SUBTITLES',
    SPLIT_SUBTITLE = '@@projectSubtitles/SPLIT_SUBTITLE',

    EXPORT_SUBTITLES = '@@projectSubtitles/EXPORT_SUBTITLES',
    SET_EXPORT_SUBTITLES_STATUS = '@@projectSubtitles/SET_EXPORT_SUBTITLES_STATUS',

    EXPORT_VIDEO_SUBTITLES = '@@projectSubtitles/EXPORT_VIDEO_SUBTITLES',

    SET_PROJECT_VIDEOS_LANGUAGES = '@@projectSubtitles/SET_PROJECT_VIDEOS_LANGUAGES',

    SET_SEARCH_QUERY = '@@projectSubtitles/SET_SEARCH_QUERY',
    REPLACE_SUBTITLES_TEXT = '@@projectSubtitles/REPLACE_SUBTITLES_TEXT',

    RESET_PROJECT_SUBTITLES_SETTINGS = '@@projectSubtitles/RESET_PROJECT_SUBTITLES_SETTINGS',

    UPLOAD_TRANSCRIPT = '@@projectSubtitles/UPLOAD_TRANSCRIPT',
    UPLOAD_TRANSCRIPT_SUCCESS = '@@projectSubtitles/UPLOAD_TRANSCRIPT_SUCCESS',

    MANUAL_CREATE_PROJECT_SENTENCE = '@@projectSubtitles/MANUAL_CREATE_PROJECT_SENTENCE',

    SET_SCROLLABLE_TRANSCRIPT_INDEX = '@@projectSubtitles/SET_SCROLLABLE_TRANSCRIPT_INDEX',

    SET_PROJECT_VIDEOS_IDS_WITH_TRANSCRIPT_GENERATION = '@@projectSubtitles/SET_PROJECT_VIDEOS_IDS_WITH_TRANSCRIPT_GENERATION',
    SET_PROJECT_VIDEOS_IDS_WITH_TRANSLATE_GENERATION = '@@projectSubtitles/SET_PROJECT_VIDEOS_IDS_WITH_TRANSLATE_GENERATION',

    GENERATE_PROJECT_VIDEO_TRANSCRIPT = '@@projectSubtitles/GENERATE_PROJECT_VIDEO_TRANSCRIPT',
    GENERATE_PROJECT_VIDEO_TRANSLATE = '@@projectSubtitles/GENERATE_PROJECT_VIDEO_TRANSLATE',
    GENERATE_PROJECT_VIDEO_AUTO_TRANSLATE = '@@projectSubtitles/GENERATE_PROJECT_VIDEO_AUTO_TRANSLATE',

    CANCEL_PROJECT_MEDIA_SOURCE_TRANSLATE = '@@projectSubtitles/CANCEL_PROJECT_MEDIA_SOURCE_TRANSLATE',

    CHANGE_PROJECT_VIDEO_LANGUAGE = '@@projectSubtitles/CHANGE_PROJECT_VIDEO_LANGUAGE',

    DELETE_PROJECT_SUBTITLES_LANGUAGE = '@@projectSubtitles/DELETE_PROJECT_SUBTITLES_LANGUAGE',

    CANCEL_PROJECT_TRANSLATE = '@@projectSubtitles/CANCEL_PROJECT_TRANSLATE',

    SET_EDITABLE_SENTENCE_ID = '@@projectSubtitles/SET_EDITABLE_SENTENCE_ID',

    UPDATE_PROJECT_TRANSCRIPT_ITEM = '@@projectSubtitles/UPDATE_PROJECT_TRANSCRIPT_ITEM',

    CREATE_PROJECT_TRANSCRIPT_BY_SPECIFIC_TIME = '@@projectSubtitles/CREATE_PROJECT_TRANSCRIPT_BY_SPECIFIC_TIME',

    SET_PROJECT_TRANSCRIPT_CREATION_LOADING = '@@projectSubtitles/SET_PROJECT_TRANSCRIPT_CREATION_LOADING',
    SET_PROJECT_SUBTITLES_TRANSLATE_LOADING = '@@projectSubtitles/SET_PROJECT_SUBTITLES_TRANSLATE_LOADING',

    GENERATE_PROJECT_TRANSLATION_FOR_DUBBING = '@@projectSubtitles/GENERATE_PROJECT_TRANSLATION_FOR_DUBBING',

    CHECK_EMPTY_VIDEO_TRANSCRIPT_LIST = '@@projectSubtitles/CHECK_EMPTY_VIDEO_TRANSCRIPT_LIST',

    CREATE_PROJECT_SENTENCES = '@@projectSubtitles/CREATE_PROJECT_SENTENCES',

    MERGE_SENTENCES = '@@projectSubtitles/MERGE_SENTENCES',

    // //////////
    ADD_OR_EDIT_SENTENCES_SUCCESS = '@@projectSubtitles/ADD_OR_EDIT_SENTENCES_SUCCESS',
    ADD_PROJECT_SUBTITLES_SUCCESS = '@@projectSubtitles/ADD_PROJECT_SUBTITLES_SUCCESS',
    ADD_PROJECT_SUBTITLES_FAILURE = '@@projectSubtitles/ADD_PROJECT_SUBTITLES_FAILURE',

    SET_UNSYNCED_SUBTITLES_LIST = '@@projectSubtitles/SET_UNSYNCED_SUBTITLES_LIST',
    SET_DELETED_UNSYNCED_SUBTITLES_LIST = '@@projectSubtitles/SET_DELETED_UNSYNCED_SUBTITLES_LIST',
}

export interface GetProjectAllSubtitlesListActionPayload {
    projectDetails?: Project;
    isVersionChange?: boolean;
    unsyncedSentencesIds?: string[];
}
export interface GetProjectAllSubtitlesListAction {
    type: ProjectSubtitlesActionTypes.GET_PROJECT_ALL_SUBTITLES_LIST;
    payload: GetProjectAllSubtitlesListActionPayload;
}

export interface SetProjectAllSubtitlesListActionPayload {
    subtitles: TransformedSentence[];
    projectAllSubtitles?: TransformedSentence[];
}
export interface SetProjectAllSubtitlesListAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_ALL_SUBTITLES_LIST;
    payload: SetProjectAllSubtitlesListActionPayload;
}

export interface AddGeneratedSubtitlesPayload {
    videos?: MediaFile[],
    languages?: string[],
    videosWithLanguages?: ProjectVideoLanguages[],
    projectDetails: Project,
}
export interface AddGeneratedSubtitlesAction {
    type: ProjectSubtitlesActionTypes.ADD_GENERATED_SUBTITLES;
    payload: AddGeneratedSubtitlesPayload;
}

export interface AddLibraryVideoSubtitlesPayload {
    projectDetails: Project;
    videoIds: string[];
}
export interface AddLibraryVideoSubtitlesAction {
    type: ProjectSubtitlesActionTypes.ADD_LIBRARY_VIDEO_SUBTITLES;
    payload: AddLibraryVideoSubtitlesPayload;
}

export interface DeleteVideoSubtitlesPayload {
    projectDetails: Project;
    videoIds: string[];
}
export interface DeleteVideoSubtitlesAction {
    type: ProjectSubtitlesActionTypes.DELETE_VIDEO_SUBTITLES;
    payload: DeleteVideoSubtitlesPayload;
}

export interface AddOrEditSubtitlePayload {
    projectDetails: Project;
    subtitles: RawSentence[] | TransformedSentence[];
    subtitleIds?: string[];
    getSubtitles?: boolean;
}
export interface AddOrEditSubtitleAction {
    type: ProjectSubtitlesActionTypes.ADD_OR_EDIT_SUBTITLE;
    payload: AddOrEditSubtitlePayload;
}

export interface DeleteSubtitlePayload {
    items: DeletedSentence[];
}
export interface DeleteSubtitleAction {
    type: ProjectSubtitlesActionTypes.DELETE_SUBTITLE;
    payload: DeleteSubtitlePayload;
}

export interface MergeSubtitlesPayload {
    projectDetails: Project;
    sentencesIds: string[];
    newSentence: RawSentence;
}
export interface MergeSubtitlesAction {
    type: ProjectSubtitlesActionTypes.MERGE_SUBTITLES;
    payload: MergeSubtitlesPayload;
}

export interface SplitSubtitlePayload {
    projectDetails: Project;
    subtitleId: string;
    splitSubtitles: TransformedSentence[] | RawSentence[];
}
export interface SplitSubtitleAction {
    type: ProjectSubtitlesActionTypes.SPLIT_SUBTITLE;
    payload: SplitSubtitlePayload;
}

export interface ExportSubtitlesPayload {
    type: string,
}
export interface ExportSubtitlesAction {
    type: ProjectSubtitlesActionTypes.EXPORT_SUBTITLES;
    payload: ExportSubtitlesPayload;
}

export interface SetExportSubtitlesStatusPayload {
    isSuccess: boolean;
}
export interface SetExportSubtitlesStatusAction {
    type: ProjectSubtitlesActionTypes.SET_EXPORT_SUBTITLES_STATUS;
    payload: SetExportSubtitlesStatusPayload;
}

export interface ExportVideoSubtitlesPayload {
    type: string,
    video: MediaFile | null,
    language: string,
}
export interface ExportVideoSubtitlesAction {
    type: ProjectSubtitlesActionTypes.EXPORT_VIDEO_SUBTITLES;
    payload: ExportVideoSubtitlesPayload;
}

export interface SetProjectVideosLanguagesAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_LANGUAGES;
    payload: ProjectVideoLanguages[];
}

export interface SetProjectSubtitlesSearchQueryAction {
    type: ProjectSubtitlesActionTypes.SET_SEARCH_QUERY;
    payload: string;
}

export interface ReplaceProjectSubtitlesTextActionPayload {
    oldText: string;
    newText: string;
}
export interface ReplaceProjectSubtitlesTextAction {
    type: ProjectSubtitlesActionTypes.REPLACE_SUBTITLES_TEXT;
    payload: ReplaceProjectSubtitlesTextActionPayload;
}

export interface ResetProjectSubtitlesSettingsPayload {
    mediaId: string,
}
export interface ResetProjectSubtitlesSettingsAction {
    type: ProjectSubtitlesActionTypes.RESET_PROJECT_SUBTITLES_SETTINGS;
    payload?: ResetProjectSubtitlesSettingsPayload
}

export interface UploadProjectTranscriptActionPayload {
    file: File;
    language: string;
    videoId: string;
}
export interface UploadProjectTranscriptAction {
    type: ProjectSubtitlesActionTypes.UPLOAD_TRANSCRIPT;
    payload: UploadProjectTranscriptActionPayload;
}

export interface UploadProjectTranscriptSuccessActionPayload {
    projectDetails: Project;
    language: string;
    videoId: string;
    userId: string;
}
export interface UploadProjectTranscriptSuccessAction {
    type: ProjectSubtitlesActionTypes.UPLOAD_TRANSCRIPT_SUCCESS;
    payload: UploadProjectTranscriptSuccessActionPayload;
}

export interface ManualCreateProjectSentenceActionPayload {
    language: string;
    videoId: string | null;
}
export interface ManualCreateProjectSentenceAction {
    type: ProjectSubtitlesActionTypes.MANUAL_CREATE_PROJECT_SENTENCE;
    payload: ManualCreateProjectSentenceActionPayload;
}

export interface SetProjectTranscriptCreationLoadingAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_TRANSCRIPT_CREATION_LOADING;
    payload: boolean;
}

export interface SetScrollableTranscriptIndexAction {
    type: ProjectSubtitlesActionTypes.SET_SCROLLABLE_TRANSCRIPT_INDEX;
    payload: number | null;
}

export interface SetProjectVideosIdsWithTranscriptGenerationAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSCRIPT_GENERATION;
    payload: string[];
}

export interface SetProjectVideosIdsWithTranslateGenerationAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSLATE_GENERATION;
    payload: string[];
}

export interface GenerateProjectVideoTranscriptActionPayload {
    language: string;
    mediaDetails: MediaFile;
}
export interface GenerateProjectVideoTranscriptAction {
    type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSCRIPT;
    payload: GenerateProjectVideoTranscriptActionPayload;
}

export interface GenerateProjectVideoTranslateActionPayload {
    languages: string[];
    sourceLanguage: string;
    mediaDetails?: MediaFile;
}
export interface GenerateProjectVideoTranslateAction {
    type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSLATE;
    payload: GenerateProjectVideoTranslateActionPayload;
}

export interface GenerateProjectVideoAutoTranslateActionPayload {
    sourceLanguage: string;
    targetLanguage: string;
    mediaDetails: MediaFile;
}
export interface GenerateProjectVideoAutoTranslateAction {
    type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_AUTO_TRANSLATE;
    payload: GenerateProjectVideoAutoTranslateActionPayload;
}

export interface CancelProjectMediaSourceTranslateAction {
    type: ProjectSubtitlesActionTypes.CANCEL_PROJECT_MEDIA_SOURCE_TRANSLATE;
    payload: string;
}

export interface ChangeProjectVideoLanguageActionPayload {
    language: string;
    videoId?: string;
    videoNewLanguages?: ProjectVideoLanguages[];
}
export interface ChangeProjectVideoLanguageAction {
    type: ProjectSubtitlesActionTypes.CHANGE_PROJECT_VIDEO_LANGUAGE;
    payload: ChangeProjectVideoLanguageActionPayload;
}

export interface DeleteProjectSubtitlesLanguageActionPayload {
    languages: string[];
    videoId: string;
}
export interface DeleteProjectSubtitlesLanguageAction {
    type: ProjectSubtitlesActionTypes.DELETE_PROJECT_SUBTITLES_LANGUAGE;
    payload: DeleteProjectSubtitlesLanguageActionPayload;
}

export interface CancelProjectTranslateAction {
    type: ProjectSubtitlesActionTypes.CANCEL_PROJECT_TRANSLATE;
}

export interface UpdateProjectTranscriptItemAction {
    type: ProjectSubtitlesActionTypes.UPDATE_PROJECT_TRANSCRIPT_ITEM;
    payload: TransformedSentence;
}

export interface SetProjectEditableSentenceIdAction {
    type: ProjectSubtitlesActionTypes.SET_EDITABLE_SENTENCE_ID;
    payload: string;
}

export interface CreateProjectTranscriptItemBySpecificTimeActionPayload {
    time: number;
    mediaType?: MediaType;
}
export interface CreateProjectTranscriptItemBySpecificTimeAction {
    type: ProjectSubtitlesActionTypes.CREATE_PROJECT_TRANSCRIPT_BY_SPECIFIC_TIME;
    payload: CreateProjectTranscriptItemBySpecificTimeActionPayload;
}

export interface SetProjectSubtitlesTranslateLoadingAction {
    type: ProjectSubtitlesActionTypes.SET_PROJECT_SUBTITLES_TRANSLATE_LOADING;
    payload: boolean;
}

export interface GenerateProjectTranslationForDubbingActionPayload {
    sourceLanguage: string;
    targetLanguage: string;
    mediaDetails: MediaFile;
    hasSubtitles: boolean;
}
export interface GenerateProjectTranslationForDubbingAction {
    type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_TRANSLATION_FOR_DUBBING;
    payload: GenerateProjectTranslationForDubbingActionPayload;
}

export interface CheckVideoEmptyTranscriptListActionPayload {
    ids: string[];
    videoId: string;
    language: string;
}
export interface CheckVideoEmptyTranscriptListAction {
    type: ProjectSubtitlesActionTypes.CHECK_EMPTY_VIDEO_TRANSCRIPT_LIST;
    payload: CheckVideoEmptyTranscriptListActionPayload[];
}

export interface MergeSentencesActionPayload {
    item: TransformedSentence;
    nextItem: TransformedSentence;
}
export interface MergeSentencesAction {
    type: ProjectSubtitlesActionTypes.MERGE_SENTENCES;
    payload: MergeSentencesActionPayload;
}

export interface AddOrEditSentenceSuccess {
    type: ProjectSubtitlesActionTypes.ADD_OR_EDIT_SENTENCES_SUCCESS,
    // payload?: Project,
}

export interface AddProjectSubtitlesSuccess {
    type: ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_SUCCESS,
    // payload?: Project,
}

export interface AddProjectSubtitlesFailure {
    type: ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_FAILURE,
    // payload?: Project,
}

export interface SetUnsyncedSubtitlesListActionPayload {
    subtitles: TransformedSentence[];
}
export interface SetUnsyncedSubtitlesListAction {
    type: ProjectSubtitlesActionTypes.SET_UNSYNCED_SUBTITLES_LIST,
    payload: SetUnsyncedSubtitlesListActionPayload
}

export interface SetDeletedUnsyncedSubtitlesListAction {
    type: ProjectSubtitlesActionTypes.SET_DELETED_UNSYNCED_SUBTITLES_LIST,
    payload: string[],
}

export type ProjectSubtitlesAction =
    | GetProjectAllSubtitlesListAction
    | SetProjectAllSubtitlesListAction
    | AddGeneratedSubtitlesAction
    | AddLibraryVideoSubtitlesAction
    | DeleteVideoSubtitlesAction
    | AddOrEditSubtitleAction
    | DeleteSubtitleAction
    | MergeSubtitlesAction
    | SplitSubtitleAction
    | ExportSubtitlesAction
    | ExportVideoSubtitlesAction
    | SetProjectVideosLanguagesAction
    | SetExportSubtitlesStatusAction
    | SetProjectSubtitlesSearchQueryAction
    | ReplaceProjectSubtitlesTextAction
    | ResetProjectSubtitlesSettingsAction
    | UploadProjectTranscriptAction
    | UploadProjectTranscriptSuccessAction
    | ManualCreateProjectSentenceAction
    | SetProjectTranscriptCreationLoadingAction
    | SetScrollableTranscriptIndexAction
    | SetProjectVideosIdsWithTranscriptGenerationAction
    | SetProjectVideosIdsWithTranslateGenerationAction
    | GenerateProjectVideoTranscriptAction
    | GenerateProjectVideoTranslateAction
    | CancelProjectMediaSourceTranslateAction
    | ChangeProjectVideoLanguageAction
    | DeleteProjectSubtitlesLanguageAction
    | CancelProjectTranslateAction
    | UpdateProjectTranscriptItemAction
    | SetProjectEditableSentenceIdAction
    | CreateProjectTranscriptItemBySpecificTimeAction
    | SetProjectSubtitlesTranslateLoadingAction
    | GenerateProjectTranslationForDubbingAction
    | CheckVideoEmptyTranscriptListAction
    | MergeSentencesAction
    | AddOrEditSentenceSuccess
    | AddProjectSubtitlesSuccess
    | AddProjectSubtitlesFailure
    | SetUnsyncedSubtitlesListAction
    | SetDeletedUnsyncedSubtitlesListAction
    | GenerateProjectVideoAutoTranslateAction;