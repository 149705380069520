import React, { useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Tap from '@tapfiliate/tapfiliate-js';
import { ThemeProvider } from 'styled-components';
import { Analytics } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import store from 'state/store';

import sagaMiddleware from 'state/middlewares/saga';
import { rootSaga } from 'state/modules';

import Theme from 'theme';
import Router from 'router';

sagaMiddleware.run(rootSaga);

Tap.init('37210-f91e2c', {
    integration: 'stripe',
});

Analytics.autoTrack('session', {
    enable: true,
    provider: 'AWSPinpoint',
    attributes: () => {
        const { user, payment } = store.getState();

        const userId = user?.userInfo?.id || '';
        const plan = payment?.currentPlan?.planNameId || '';

        console.log('session', { userId, plan });

        return {
            userId,
            plan,
        };
    },
});

Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    provider: 'AWSPinpoint',
    attributes: () => {
        const { user, payment } = store.getState();

        const userId = user?.userInfo?.id || '';
        const plan = payment?.currentPlan?.planNameId || '';
        console.log('pageView', { userId, plan });

        return {
            userId,
            plan,
        };
    },
    getUrl: () => {
        return window.location.origin + window.location.pathname;
    },
});

export const ThemePreferenceContext = React.createContext({});

function FallbackComponent() {
    return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

const themesMap = {
    light: Theme.lightTheme,
    dark: Theme.darkTheme,
} as {
    [key: string]: any;
};

const Root = () => {
    const [currentTheme, setCurrentTheme] = useState('light');

    const theme = { colors: themesMap[currentTheme] };

    const themeValue = useMemo(
        () => ({ currentTheme, setCurrentTheme }),
        [currentTheme, setCurrentTheme]
    );

    useEffect(() => {
        if (window.$crisp) {
            window.$crisp.push(['do', 'chat:hide']);
        }
    }, []);

    useEffect(() => {
        if (window.$crisp) {
            window.$crisp.push([
                'on',
                'message:received',
                () => window.$crisp.push(['do', 'chat:show']),
            ]);
        }
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={myFallback} showDialog>
            <BrowserRouter>
                <Provider store={store}>
                    <ThemePreferenceContext.Provider value={themeValue}>
                        <ThemeProvider theme={theme}>
                            <Authenticator.Provider>
                                <Router />
                            </Authenticator.Provider>
                        </ThemeProvider>
                    </ThemePreferenceContext.Provider>
                </Provider>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    );
};

export default Root;
