import { IconProps } from './index';

const YoutubeIcon = ({ color = '#ffffff', size }: IconProps): JSX.Element => {
    let height = 32;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.9985 11.0035C27.7225 10.018 26.9092 9.24196 25.8766 8.97858C24.005 8.5 16.5 8.5 16.5 8.5C16.5 8.5 8.99503 8.5 7.12336 8.97858C6.09077 9.242 5.27752 10.018 5.0015 11.0035C4.5 12.7896 4.5 16.5162 4.5 16.5162C4.5 16.5162 4.5 20.2428 5.0015 22.0289C5.27752 23.0143 6.09077 23.758 7.12336 24.0214C8.99503 24.5 16.5 24.5 16.5 24.5C16.5 24.5 24.005 24.5 25.8766 24.0214C26.9092 23.758 27.7225 23.0143 27.9985 22.0289C28.5 20.2428 28.5 16.5162 28.5 16.5162C28.5 16.5162 28.5 12.7896 27.9985 11.0035ZM14.0454 19.8996V13.1327L20.3181 16.5163L14.0454 19.8996Z"
                fill="#EC4722"
            />
        </svg>
    );
};

export default YoutubeIcon;
