import { AnyAction, combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import type { Reducer } from '@reduxjs/toolkit';

import videosReducer from 'state/modules/media/reducer';
import metadataReducer from 'state/modules/metadata/reducer';
import userReducer from 'state/modules/user/reducer';
import modalReducer from 'state/modules/modal/reducer';
import generationReducer from 'state/modules/generation/reducer';
import globalSearchReducer from 'state/modules/globalSearch/reducer';
import collectionsReducer from 'state/modules/collections/reducer';
import analysisReducer from 'state/modules/analysis/reducer';
import videoExplorerReducer from 'state/modules/videoExplorer/reducer';
import jobsReducer from 'state/modules/jobs/reducer';
import uploadingReducer from 'state/modules/uploading/reducer';
import appReducer from 'state/modules/app/reducer';
import exportReducer from 'state/modules/export/reducer';
import templatesReducer from 'state/modules/templates/reducer';
import shotsReducer from 'state/modules/shots/reducer';
import paymentReducer from 'state/modules/payment/reducer';
import transcriptReducer from 'state/modules/transcript/reducer';
import projectsReducer from 'state/modules/projects/reducer';
import projectSubtitlesReducerNew from 'state/modules/projectSubtitles/reducerNew';
import projectsStudioReducer from 'state/modules/projectsStudio/reducer';
import projectUploadReducer from 'state/modules/projectUpload/reducer';
import foldersReducer from 'state/modules/folders/reducer';
import workspacesReducer from 'state/modules/workspaces/reducer';
import stockMediaReducer from 'state/modules/stockMedia/reducer';

import { generationSaga } from './generation';
import { globalSearchSaga } from './globalSearch';
import { collectionsSaga } from './collections';
import { analysisSaga } from './analysis';
import { videoExplorerSaga } from './videoExplorer';
import { jobsSaga } from './jobs';
import { uploadingSaga } from './uploading';
import { appSaga } from './app';
import { exportSaga } from './export';
import { templatesSaga } from './templates';
import { shotsSaga } from './shots';
import { paymentSaga } from './payment';
import { modalSaga } from './modal';
import { UserActionTypes, userSaga } from './user';
import { metadataSaga } from './metadata';
import { videosSaga } from './media';
import { transcriptSaga } from './transcript';
import { projectsSaga } from './projects';
import { projectSubtitlesSagaNew } from './projectSubtitles';
import { projectsStudioSaga } from './projectsStudio';
import { projectUploadSaga } from './projectUpload';
import { foldersSaga } from './folders';
import { workspacesSaga } from './workspaces';
import { stockMediaSaga } from './stockMedia';

export type State = ReturnType<typeof rootReducer>;

const combinedReducer = combineReducers({
    videos: videosReducer,
    export: exportReducer,
    templates: templatesReducer,
    shots: shotsReducer,
    payment: paymentReducer,
    user: userReducer,
    modal: modalReducer,
    uploading: uploadingReducer,
    analysis: analysisReducer,
    generation: generationReducer,
    jobs: jobsReducer,
    app: appReducer,
    metadata: metadataReducer,
    collections: collectionsReducer,
    globalSearch: globalSearchReducer,
    videoExplorer: videoExplorerReducer,
    transcript: transcriptReducer,
    projects: projectsReducer,
    projectSubtitlesNew: projectSubtitlesReducerNew,
    projectsStudio: projectsStudioReducer,
    projectUpload: projectUploadReducer,
    folders: foldersReducer,
    workspaces: workspacesReducer,
    stockMedia: stockMediaReducer,
});

// Clear app state when signout action fires
export const rootReducer: Reducer = (state, action: AnyAction) => {
    if (action.type === UserActionTypes.SIGN_OUT) {
        state = undefined;
    }

    return combinedReducer(state, action);
};

export function* rootSaga(): Generator {
    const sagas = [
        workspacesSaga,
        videosSaga,
        metadataSaga,
        userSaga,
        modalSaga,
        generationSaga,
        globalSearchSaga,
        collectionsSaga,
        analysisSaga,
        videoExplorerSaga,
        jobsSaga,
        uploadingSaga,
        appSaga,
        exportSaga,
        templatesSaga,
        shotsSaga,
        paymentSaga,
        transcriptSaga,
        projectsSaga,
        projectSubtitlesSagaNew,
        projectsStudioSaga,
        projectUploadSaga,
        foldersSaga,
        stockMediaSaga,
    ].filter((saga) => saga !== undefined);

    yield all(sagas.map((saga) => fork(saga)));
}
