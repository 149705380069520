import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import isEqual from 'lodash.isequal';
import moment from 'moment';

import * as videosActions from 'state/modules/media/actions';
import * as modalActions from 'state/modules/modal/actions';
import * as videoExplorerActions from 'state/modules/videoExplorer/actions';
import * as metadataActions from 'state/modules/metadata/actions';
import * as transcriptActions from 'state/modules/transcript/actions';

import VideosClient from 'services/api/videos';

import { GetVideosDetailsResponse, GetVideosResponse } from 'models/videos';

import { MediaFile, VideoFilter } from 'interfaces/videos';
import { CurrentUserInfo } from 'interfaces/user';

import TrailersClient from 'services/api/trailers';
import MetadataClient from 'services/api/metadata';

import { getDateFromToRange } from 'utils/filter';
import { getInProgressStatus } from 'utils/statuses';

import { defaultVideoLibraryFilter } from 'state/utils/filters';

import { StatusType } from 'interfaces/statuses';
import {
    VideosActionTypes,
    ChangeVideoLibraryFilterAction,
    DeleteSpecificVideoAction,
    GetVideoDetailsAction,
    GetVideoListAction,
    GetVideoListByFilterReqParams,
    SelectVideoAction,
    UnselectVideoAction,
    UpdateVideoDetailsAction,
} from './types';

import {
    getSelectedVideos,
    getVideoFiles,
    getVideosIdsForDelete,
    checkVideosIsInProgressStatus,
    getIsSelectedAllVideosStatus,
    getVideoLibraryFilter,
    getVideosInProgress,
    getVideoFilesCount,
    getVideoInfo,
    getMediaListStatusFilter,
} from './selectors';
import { getVideoExplorerVideoList } from '../videoExplorer';
import { getProjectDetailsInfo } from '../projects';

export function* getVttForVideo(id: string): Generator {
    try {
        const vtt = (yield call(
            VideosClient.getVideoVtt,
            id
        )) as AxiosResponse<string>;

        if (vtt.data) {
            const blob = new Blob([vtt.data]);
            const url = URL.createObjectURL(blob);

            yield put(videosActions.setVideoVtt(url));
        }
    } catch (error) {
        console.log({ error });
    }
}

export function* handleGetVideoDetails(
    action: GetVideoDetailsAction
): Generator {
    const { id } = action.payload;

    try {
        const res = (yield call(
            VideosClient.getVideoDetails,
            id
        )) as AxiosResponse<GetVideosDetailsResponse>;

        const mediaDetails = res.data.content;

        if (mediaDetails && !Array.isArray(mediaDetails)) {
            const languagesRes = (yield call(
                MetadataClient.getAvailableLanguages,
                id,
                ''
            )) as AxiosResponse;

            const languagesObj = languagesRes.data.content;
            const languages = languagesObj.languages;
            // const videoLanguages = languagesObj.videoLanguages;

            const languagesList = languages;

            // const languagesList = languagesRes.data.content;

            const currentLanguage = languagesList.find(
                (languagesListItem: string) =>
                    languagesListItem === mediaDetails.defaultLanguage
            );

            yield put(transcriptActions.setMediaLanguagesCodes(languagesList));
            yield put(
                videoExplorerActions.setMediaLanguageCode(
                    currentLanguage || languagesList[0]
                )
            );
            yield put(videosActions.setVideoDetails(mediaDetails));

            yield put(videosActions.setVideoDetailsLoading(false));
            const inProgressStatus = getInProgressStatus(mediaDetails);

            if (inProgressStatus) {
                yield delay(30000);

                yield put(videosActions.syncMediaDetails());
            }
        } else {
            yield put(videosActions.setVideoDetailsLoading(false));
        }
    } catch (error) {
        console.log({ error });
        yield put(videosActions.setVideoDetailsLoading(false));
    }
}

export function* handleUpdateVideosStatuses(): Generator {
    const videosInProgress = (yield select(
        getVideosInProgress
    )) as Array<MediaFile>;

    if (videosInProgress.length) {
        yield put(videosActions.updateVideosStatusesStart());

        const currentUserInfo =
            (yield Auth.currentUserInfo()) as CurrentUserInfo;
        const videosId = videosInProgress.map((video: MediaFile) => video.id);
        const oldVideos = (yield select(getVideoFiles)) as Array<MediaFile>;
        // const currentMediaFile = (yield select(getVideoInfo)) as MediaFile;

        try {
            if (currentUserInfo?.id) {
                const res = (yield call(
                    VideosClient.getVideosStatuses,
                    videosId
                )) as AxiosResponse<GetVideosResponse>;

                if (res.data) {
                    const updatedVideos = oldVideos.map(
                        (oldVideo: MediaFile) => {
                            const updatedVideo = res.data.content.find(
                                (item: MediaFile) => item.id === oldVideo.id
                            );

                            if (updatedVideo) {
                                return updatedVideo;
                            }
                            return oldVideo;
                        }
                    );

                    const isVidesEqual = isEqual(oldVideos, updatedVideos);

                    // const updatedCurrentMediaFile = updatedVideos.find((mediaFile: MediaFile) => mediaFile.id === currentMediaFile.id);

                    // console.log({res})
                    // console.log({updatedCurrentMediaFile})

                    if (!isVidesEqual) {
                        yield put(
                            videosActions.updateVideosStatusesSuccess(
                                updatedVideos
                            )
                        );
                    }

                    // if (updatedCurrentMediaFile) {
                    //   yield put(
                    //     videosActions.setVideoDetails(
                    //       updatedCurrentMediaFile
                    //     ),
                    //   );
                    // }

                    const hasVideosInProgress = yield select(
                        checkVideosIsInProgressStatus
                    );

                    if (hasVideosInProgress) {
                        yield delay(25000);
                        yield put(videosActions.updateVideosStatuses());
                    }
                }
            }
        } catch (error) {
            yield put(videosActions.updateVideosStatusesFail(error));
        }
    }
}

export function* handleSelectVideo(action: SelectVideoAction): Generator {
    const { id } = action.payload;
    const videos = (yield select(getVideoFiles)) as Array<MediaFile>;

    const selectedVideos = (yield select(
        getSelectedVideos
    )) as Array<MediaFile>;

    const selectedVideo = videos.find((item: MediaFile) => item.id === id);

    const updatedSelectedVideos = [
        ...selectedVideos,
        selectedVideo,
    ] as Array<MediaFile>;

    yield put(videosActions.setSelectedVideos(updatedSelectedVideos));
}

export function* handleUnselectVideo(action: UnselectVideoAction): Generator {
    const { id } = action.payload;
    const selectedVideos = (yield select(
        getSelectedVideos
    )) as Array<MediaFile>;

    const updatedSelectedVideos = selectedVideos.filter(
        (item: MediaFile) => item.id !== id
    );

    yield put(videosActions.setSelectedVideos(updatedSelectedVideos));
}

export function* handleToggleAllVideos(): Generator {
    const isAllSelected = yield select(getIsSelectedAllVideosStatus);
    const videos = (yield select(getVideoFiles)) as Array<MediaFile>;

    let updatedSelectedVideos: Array<MediaFile> = [];

    if (isAllSelected) {
        updatedSelectedVideos = [];
    } else {
        updatedSelectedVideos = videos;
    }

    yield put(videosActions.setSelectedVideos(updatedSelectedVideos));
}

function* deleteVideo(videoId: string): Generator {
    try {
        yield call(VideosClient.deleteVideo, videoId);
    } catch (error) {
        console.log({ error });
    }
}

function* handleDeleteVideos(): Generator {
    yield put(videosActions.deleteVideosStart());
    const selectedVideos = (yield select(
        getSelectedVideos
    )) as Array<MediaFile>;
    const selectedVideosIds = selectedVideos.map(
        (video: MediaFile) => video.id
    );
    yield put(videosActions.setVideosIdsForDelete(selectedVideosIds));

    try {
        yield all(
            selectedVideos.map((video: MediaFile) =>
                call(deleteVideo, video.id)
            )
        );

        yield delay(3500);

        yield put(videosActions.getVideoList());
        yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
        yield put(videosActions.deleteVideosSuccess());
        yield put(videosActions.setSelectedVideos([]));
        yield put(videosActions.setVideosIdsForDelete([]));
    } catch (err) {
        yield put(videosActions.deleteVideosFail(err));
    }
}

function* handleDeleteSpecificVideo(
    action: DeleteSpecificVideoAction
): Generator {
    const { videoId } = action.payload;

    yield put(videosActions.deleteVideosStart());
    const selectedVideos = (yield select(
        getSelectedVideos
    )) as Array<MediaFile>;
    const selectedVideosIdsForDelete = (yield select(
        getVideosIdsForDelete
    )) as Array<string>;
    yield put(videosActions.setVideosIdsForDelete([videoId]));

    try {
        yield call(deleteVideo, videoId);

        yield delay(3500);

        const filteredSelectedVideos = selectedVideos.filter(
            (item: MediaFile) => item.id !== videoId
        );

        const filteredSelectedVideosIdsForDelete =
            selectedVideosIdsForDelete.filter((id: string) => id !== videoId);

        yield put(videosActions.getVideoList());
        yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
        yield put(videosActions.deleteVideosSuccess());
        yield put(videosActions.setSelectedVideos(filteredSelectedVideos));
        yield put(
            videosActions.setVideosIdsForDelete(
                filteredSelectedVideosIdsForDelete
            )
        );
    } catch (err) {
        yield put(videosActions.deleteVideosFail(err));
    }
}

function* downloadVideo(video: MediaFile): Generator {
    try {
        const downloadLinkRes = (yield call(
            TrailersClient.downloadVideo,
            video.id
        )) as AxiosResponse;

        const link = document.createElement('a');
        link.download = video.filename;
        link.target = '_blank';

        link.href = downloadLinkRes.data.url;

        document.body.appendChild(link);
        link.click();

        if (document?.body?.contains(link)) {
            document.body.removeChild(link);
        }
    } catch (error) {
        console.log({ error });
    }
}

function* handleDownloadVideos(): Generator {
    const selectedVideos = (yield select(
        getSelectedVideos
    )) as Array<MediaFile>;

    try {
        for (const video of selectedVideos) {
            yield call(downloadVideo, video);
            yield delay(1500);
        }
    } catch (err) {
        console.log({ err });
    }
}

function* handleGetVideoList(action: GetVideoListAction): Generator {
    yield put(videosActions.getVideoListStart());

    const { isLoadMore } = action.payload;

    const oldVideos = (yield select(getVideoFiles)) as Array<MediaFile>;
    const videoFilter = (yield select(getVideoLibraryFilter)) as VideoFilter;
    const videosCount = (yield select(getVideoFilesCount)) as number;
    const statusFilter = (yield select(getMediaListStatusFilter)) as
        | StatusType.FAILED
        | StatusType.SUCCEEDED
        | StatusType.IN_PROGRESS
        | null;

    const { dateFilter } = videoFilter;

    const dateFromToRange = getDateFromToRange({
        dateFilter,
        filter: videoFilter,
    });

    let filterParams = {
        // mediaType: MediaType.VIDEO,
        // mediaType: MediaType.AUDIO,
    } as GetVideoListByFilterReqParams;

    if (dateFromToRange) {
        filterParams = {
            ...filterParams,
            dateFrom: moment(dateFromToRange.dateFrom).toISOString(),
            dateTo: moment(dateFromToRange.dateTo).toISOString(),
            title: videoFilter.title,
            description: '',
        };
    } else {
        filterParams = {
            ...filterParams,
            title: videoFilter.title,
            description: '',
        };
    }

    try {
        const res = (yield call(
            VideosClient.getVideoListByFilter,
            filterParams,
            isLoadMore ? videosCount : 0,
            10,
            statusFilter
        )) as AxiosResponse;

        let videos = res.data.content;

        if (isLoadMore) {
            videos = [...oldVideos, ...videos];
        }

        yield put(
            videosActions.getVideoListSuccess(
                videos,
                res.data._metadata.totalCount
            )
        );

        const hasVideosInProgress = yield select(checkVideosIsInProgressStatus);

        if (hasVideosInProgress) {
            yield put(videosActions.updateVideosStatuses());
        }
    } catch (err) {
        console.log({ err });
        yield put(videosActions.getVideoListFail(err));
    }
}

export function* handleChangeVideoFilter(
    action: ChangeVideoLibraryFilterAction
): Generator {
    const { field, value } = action.payload;

    const oldVideoFilter = (yield select(getVideoLibraryFilter)) as VideoFilter;

    const updatedFilter = {
        ...oldVideoFilter,
        [field]: value,
    };

    yield put(videosActions.setVideoLibraryFilter(updatedFilter));

    if (field === 'title') {
        yield put(videosActions.getVideoList());
    }
}

export function* handleResetVideoLibraryFilter(): Generator {
    yield put(videosActions.getVideoDetailsStart());

    yield put(videosActions.setVideoLibraryFilter(defaultVideoLibraryFilter));

    yield put(videosActions.getVideoList());
}

export function* handleUpdateVideoDetails(
    action: UpdateVideoDetailsAction
): Generator {
    yield put(videosActions.updateVideoDetailsStart());
    const { title, description, videoId, defaultLanguageCode } = action.payload;

    try {
        yield call(VideosClient.editVideoDetails, {
            title,
            description,
            videoId,
            defaultLanguage: defaultLanguageCode,
        });

        const videosList = (yield select(getVideoFiles)) as MediaFile[];
        const mediaDashboardVideosList = (yield select(
            getVideoExplorerVideoList
        )) as MediaFile[];

        const updatedVideosList = videosList.map((video) => {
            if (video.id === videoId) {
                return {
                    ...video,
                    title,
                    description,
                    defaultLanguage: defaultLanguageCode,
                };
            }

            return video;
        });

        const updatedMediaDashboardVideosList = mediaDashboardVideosList.map(
            (video) => {
                if (video.id === videoId) {
                    return {
                        ...video,
                        title,
                        description,
                        defaultLanguage: defaultLanguageCode,
                    };
                }

                return video;
            }
        );

        yield put(videosActions.updateVideoDetailsSuccess());
        yield put(modalActions.hideModal());
        // yield put(videosActions.getVideoList());
        yield put(videosActions.updateVideosStatusesSuccess(updatedVideosList));
        yield put(
            videoExplorerActions.updateAnalyzerVideosStatusesSuccess(
                updatedMediaDashboardVideosList
            )
        );
    } catch (error) {
        console.log({ error });
        yield put(videosActions.updateVideoDetailsFail());
    }
}

export function* handleSyncMediaDetails(): Generator {
    // const projectDatails = (yield select(getProjectDetailsInfo)) as Project;
    const currentMediaDetails = (yield select(getVideoInfo)) as MediaFile;
    const mediaId = currentMediaDetails?.id;

    if (!mediaId) {
        return;
    }

    const mediaDetailsRes = (yield call(
        VideosClient.getVideoDetails,
        mediaId
    )) as AxiosResponse<GetVideosDetailsResponse>;

    const updatedMediaDetails = mediaDetailsRes.data.content;

    const languagesRes = (yield call(
        MetadataClient.getAvailableLanguages,
        mediaId,
        ''
    )) as AxiosResponse;

    const languagesObj = languagesRes.data.content;
    const languages = languagesObj.languages;
    // const videoLanguages = languagesObj.videoLanguages;
    //
    const languagesList = languages;

    // const languagesList = languagesRes.data.content;

    yield put(transcriptActions.setMediaLanguagesCodes(languagesList));
    yield put(videoExplorerActions.setMediaLanguageCode(languagesList[0]));
    yield put(videosActions.setVideoDetails(updatedMediaDetails));

    const inProgressStatus = getInProgressStatus(updatedMediaDetails);

    if (inProgressStatus) {
        yield delay(30000);

        yield put(videosActions.syncMediaDetails());
    } else {
        yield put(metadataActions.getTranscript(updatedMediaDetails.id, '', 0));
    }
}

export function* videosSaga(): Generator {
    yield takeLatest(VideosActionTypes.GET_VIDEOS_LIST, handleGetVideoList);
    yield takeLatest(
        VideosActionTypes.GET_VIDEO_DETAILS,
        handleGetVideoDetails
    );
    yield takeLatest(
        VideosActionTypes.UPDATE_VIDEOS_STATUSES,
        handleUpdateVideosStatuses
    );
    yield takeLatest(VideosActionTypes.SELECT_VIDEO, handleSelectVideo);
    yield takeLatest(VideosActionTypes.UNSELECT_VIDEO, handleUnselectVideo);
    yield takeLatest(
        VideosActionTypes.TOGGLE_ALL_VIDEOS,
        handleToggleAllVideos
    );
    yield takeLatest(
        VideosActionTypes.DELETE_SPECIFIC_VIDEO,
        handleDeleteSpecificVideo
    );
    yield takeLatest(VideosActionTypes.DELETE_VIDEOS, handleDeleteVideos);
    yield takeLatest(VideosActionTypes.DOWNLOAD_VIDEOS, handleDownloadVideos);
    yield takeLatest(
        VideosActionTypes.CHANGE_VIDEO_FILTER,
        handleChangeVideoFilter
    );
    yield takeLatest(
        VideosActionTypes.RESET_VIDEO_LIBRARY_FILTER,
        handleResetVideoLibraryFilter
    );
    yield takeLatest(
        VideosActionTypes.UPDATE_VIDEO_DETAILS,
        handleUpdateVideoDetails
    );
    yield takeLatest(
        VideosActionTypes.SYNC_MEDIA_DETAILS,
        handleSyncMediaDetails
    );
}
