import { IconProps } from '../index';
import { calculateSideByRatio } from '../../../../utils';

const SIDES_RATIO = 25 / 24;

const AnimationsIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 24;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.472 15.375 11.96 18 8.5 12l3.459-6L13.5 8.673m-6.022 5.858L6.268 16.5 3.5 12l2.767-4.5L7.5 9.505M22.5 12 18 19l-4.5-7L18 5l4.5 7z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke={color}
            />
        </svg>
    );
};

export default AnimationsIcon;