import { IconProps } from './index';

const ColorIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={height}
            height={height}
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M8.935 13.422a1.656 1.656 0 01-1.174.486c-.426 0-.851-.162-1.175-.486l-3.1-3.1a1.663 1.663 0 010-2.349l4.263-4.262c.22-.22.577-.22.798 0l4.65 4.65c.221.221.221.578 0 .799l-4.262 4.262zm3.064-4.662L8.148 4.909 4.285 8.771a.13.13 0 00-.021.03c-.004.008-.009.016-.015.023h7.685L12 8.76zm1.59 5.15c-.778 0-1.412-.655-1.412-1.46 0-.648.81-1.927 1.059-2.304.156-.238.55-.238.707 0 .249.377 1.059 1.656 1.059 2.304 0 .805-.634 1.46-1.412 1.46z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default ColorIcon;
