import { AxiosResponse } from 'axios';
import { InviteToWorkspaceStatuses, Workspace } from 'interfaces/workspaces';

import store from 'state/store';

import { IUpdateWorkspace } from 'models/workspaces';
import $http from './instance';

class WorkspacesClient {
    public static async getWorkspaces(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces`);
    }

    public static async getWorkspaceById(id: string): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces/${id}`);
    }

    public static async getCurrentWorkspace(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces?isSelected=true`);
    }

    public static async createWorkspace(
        data: Workspace
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/workspaces`, data);
    }

    public static async deleteWorkspace(id: string): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.delete(`/user/${userId}/workspaces/${id}`);
    }

    public static async updateWorkspace(
        id: string,
        data: IUpdateWorkspace
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(`/user/${userId}/workspaces/${id}`, data);
    }

    // Ivites

    public static async sendWorkspaceInvite(
        workspaceId: string,
        inviteeEmail: string,
        inviterEmail: string
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/workspaces/${workspaceId}/invite`, {
            inviteeEmail,
            inviterEmail,
        });
    }

    public static async getInviterInvitesList(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces/invites/inviter`);
    }

    public static async getInviteeInvitesList(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces/invites/invitee`);
    }

    public static async getInviteeWorkspaces(): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.get(`/user/${userId}/workspaces/invitee`);
    }

    public static async pinInviteWithEmail(
        inviteeEmail: string
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.post(`/user/${userId}/workspaces/invitee/pin`, {
            inviteeEmail,
        });
    }

    public static async updateInviteeWorkspaceInviteStatus(
        inviteId: string,
        status: InviteToWorkspaceStatuses
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(
            `/user/${userId}/workspaces/invitee/invite/${inviteId}`,
            {
                status,
            }
        );
    }

    public static async updateWorkspaceInviteStatus(
        inviteId: string,
        workspacesId: string,
        status: InviteToWorkspaceStatuses
    ): Promise<AxiosResponse> {
        const userId: string = store.getState().user.userInfo.id;

        return $http.put(
            `/user/${userId}/workspaces/${workspacesId}/invite/${inviteId}`,
            {
                status,
            }
        );
    }
}

export default WorkspacesClient;
