import styled from 'styled-components';
import RcSlider from 'rc-slider';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { SyntheticEvent, useRef, useState } from 'react';
import { Icon, NewButton } from '../../UI';
import { getModalOptionalData, hideModal } from '../../../state/modules/modal';
import { Project } from '../../../interfaces/projects';
import { replaceProjectWithTemplate } from '../../../state/modules/projects';

const Container = styled.div`
    height: 68vh;
    display: flex;
    gap: 8vw;
    padding: 5rem 5rem 0 5rem;
    min-width: 20vw;
    max-width: 90vw;
    max-height: 68vh;
    width: 77rem;
    background-color: rgb(255, 255, 255);
    overflow: hidden auto;
    border-radius: 1.25rem;
    z-index: 1;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    right: 18px;
    top: 18px;
    border: none;
    background: transparent;
    cursor: pointer;
`;

const VideoContainer = styled.div`
     width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const VideoWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const Video = styled.video`
    border-radius: 17px;
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    position: relative;
`;

const Controls = styled.div`
    display: flex;
    align-items: center;
    margin-top: 22px;
    width: 90%;
`;

const PlayToggler = styled.button<{isPlaying: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #000;
    border-radius: 100%;
    width: 34px;
    height: 33px;

    & > svg {
        margin-left: ${({ isPlaying }) => isPlaying ? '1px' : '4px'};
    }
`;

const ProgressContainer = styled.div`
    width: 100%;
    padding-left: 24px;
`;

const Progress = styled.div`
    position: relative;
    margin-bottom: 6px;
`;

const TimeContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Time = styled.p`
    font-family: 'Proxima Nova', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 400;
    display: flex;
    position: relative;
`;

const Info = styled.div`
     width: 30%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Name = styled.p`
    font-family: 'Proxima Nova Bold', sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    margin: 0;
`;

const ConfirmContainer = styled.div`
    height: 30vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    padding: 5rem 5rem 0 5rem;
    min-width: 20vw;
    max-width: 50vw;
    max-height: 40vh;
    width: 35rem;
    background-color: rgb(255,255,255);
    overflow: hidden auto;
    border-radius: 1.25rem;
    z-index: 1;
    position: relative;
`;

const ConfirmText = styled.div`
    font-family: "Proxima Nova Medium", sans-serif;
    font-weight: 500;
    font-size: 16px;
`;

const ConfirmButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 53px;
`;

const ConfirmButton = styled.button<{isConfirm: boolean}>`
    width: 160px;
    height: 40px;
    border: 1px solid black;
    border-radius: 7px;
    padding: 5px;
    font-family: "Proxima Nova Medium", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: ${({ isConfirm }) => !isConfirm ? '#000' : '#fff'};
    background-color: ${({ isConfirm }) => isConfirm ? '#000' : '#fff'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ConfirmButtonTitle = styled.p`
    margin-top: 3px;
`;

const TemplatePreviewModal = () => {

    const dispatch = useDispatch();

    const videoRef = useRef<any>(null);

    const template = useSelector(getModalOptionalData) as Project;

    const [duration, setDuration] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isPlaying, setPlaying] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleLoadMetadata = (
        event: SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setDuration((event.target as any).duration);
    };

    const handleTimeUpdate = (
        event: SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setCurrentProgress((event.target as any).currentTime);
    };

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const handlePause = () => {
        setPlaying(false);
    };

    const handlePlay = () => {
        setPlaying(true);
    };

    const hadnleEnded = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
        }
    };

    const handleSliderChange = (value: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = value;
        }
    };

    const handleUseTemplate = () => {
        setShowConfirmModal(true);
    };

    const handleConfirm = () => {
        dispatch(hideModal());
        dispatch(replaceProjectWithTemplate({ template }));
    }

    const handleCancel = () => {
        setShowConfirmModal(false);
    }

    const handleOnClose = () => {
        dispatch(hideModal());
        setShowConfirmModal(false);
    }
    
    const renderControls = () => {
        return (
            <Controls>
                <PlayToggler
                    isPlaying={isPlaying}
                    onClick={togglePlay}
                >
                    <Icon
                        name={isPlaying ? 'pause' : 'play-new'}
                        size={18}
                        color="#fff"
                    />
                </PlayToggler>
                <ProgressContainer>
                    {duration ? (
                        <Progress>
                            <RcSlider
                                min={0}
                                max={duration}
                                step={1}
                                onChange={handleSliderChange}
                                value={currentProgress}
                                handleStyle={{
                                    display: 'none',
                                }}
                                style={{
                                    height: '100%',
                                    cursor: 'pointer',
                                    marginTop: '20px',
                                }}
                                railStyle={{
                                    background: 'rgb(156, 156, 157)',
                                }}
                                trackStyle={{
                                    background: 'rgb(92, 94, 101)',
                                }}
                            />
                        </Progress>
                    ) : null}
                    <TimeContainer>
                        <Time>
                            {`${moment
                                .duration(currentProgress, 'seconds')
                                .format('mm:ss', {
                                    trim: false,
                                })}`}
                        </Time>
                        <Time>
                            {`${moment
                                .duration(duration, 'seconds')
                                .format('mm:ss', {
                                    trim: false,
                                })}`}
                        </Time>
                    </TimeContainer>
                </ProgressContainer>
            </Controls>
        );
    };

    const renderInfo = () => {
        return (
            <Info>
                <Name>{template.name}</Name>
                <NewButton
                    color="#000"
                    textColor="#fff"
                    bordered
                    borderRadius={10}
                    fontWeight="bold"
                    height={48}
                    onClick={handleUseTemplate}
                    disabled={false}
                    loading={false}
                >
                    Use Template
                </NewButton>
            </Info>
        );
    };

    const renderConfirm = () => {
        return (
            <ConfirmContainer>
                <CloseButton
                    onClick={handleOnClose}
                >
                    <Icon name="close" size={12} color="#6979A5" />
                </CloseButton>
                <ConfirmText>
                    <p>
                        All your project data will be replaced with the template data.
                    </p>
                    <br/>
                    <p>
                        Are you sure you want to use the template?
                    </p>
                </ConfirmText>
                <ConfirmButtonsContainer>
                    <ConfirmButton
                        onClick={handleCancel}
                        isConfirm={false}

                    >
                        <ConfirmButtonTitle>Cancel</ConfirmButtonTitle>
                    </ConfirmButton>
                    <ConfirmButton
                        isConfirm
                        onClick={handleConfirm}
                    >
                        <ConfirmButtonTitle>Confirm</ConfirmButtonTitle>
                    </ConfirmButton>
                </ConfirmButtonsContainer>
            </ConfirmContainer>
        );
    };

    const renderTemplatePreview = () => {
        return (
            <Container>
                <CloseButton
                    onClick={handleOnClose}
                >
                    <Icon name="close" size={12} color="#6979A5" />
                </CloseButton>
                <VideoContainer>
                    <VideoWrap>
                        <Video
                            ref={videoRef}
                            src={`${window.config.REACT_APP_CLOUDFRONT_URL}${template.s3SourcePath}`}
                            onLoadedMetadata={handleLoadMetadata}
                            onTimeUpdate={handleTimeUpdate}
                            controls={false}
                            onClick={togglePlay}
                            onPause={handlePause}
                            onPlay={handlePlay}
                            onEnded={hadnleEnded}
                        />
                    </VideoWrap>
                    {renderControls()}
                </VideoContainer>
                {renderInfo()}
            </Container>
        )
    };

    const renderContent = () => {
        if(showConfirmModal) {
            return renderConfirm();
        }

        return renderTemplatePreview();
    };

    return (
        <>
            {renderContent()}
        </>
    );
};

export default TemplatePreviewModal;