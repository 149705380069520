import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BrowserRecommendation, Icon } from 'components/UI';

import { hideModal, showModal } from 'state/modules/modal/actions';
import {
    getModalEvent, getModalOptionalData,
    getModalType,
    getModalVisibility,
} from 'state/modules/modal/selectors';
import { ModalEvent, ModalType } from 'state/modules/modal';
import {
    getAppTourStatus,
    getIsBrowserRecommendationActiveStatus,
} from 'state/modules/app';
import { getSubscriptionIssueStatus } from 'state/modules/payment';

import UploadModal from './UploadModal';
import MessageModal from './MessageModal';
import EditVideoModal from './EditVideoModal';
import CreateTemplateModal from './CreateTemplateModal';
import UploadFacesModal from './UploadFacesModal';
import GenerationModal from './GenerationModal';
import AvidExportModal from './AvidExportModal';
import AddCollectionModal from './AddCollectionModal';
import ReanalisysModal from './ReanalisysModal';
import EdlExportModal from './EdlExportModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import PaymentCardSelectModal from './PaymentCardSelectModal';
import UpgradePlanModal from './UpgradePlanModal';
import AddMoreCreditsModal from './AddMoreCreditsModal';
import AddMoreDubbingCreditsModal from './AddMoreDubbingCreditsModal';
import ManageStorageModal from './ManageStorageModal';
import CancelPlanModal from './CancelPlanModal';
import PlanCanceledModal from './PlanCanceledModal';
import AddCreditCardModal from './AddCreditCardModal';
import SelectPlanModal from './SelectPlanModal';
import SubscriptionAllSetModal from './SubscriptionAllSetModal';
import DeleteLastCardModal from './DeleteLastCardModal';
import AutoRenewalConfirmModal from './AutoRenewalConfirmModal';
import AppTourProposalModal from './AppTourProposalModal';
import ThumbnailsExportModal from './ThumbnailsExportModal';
import PreviewsModal from './PreviewsModal';
import ExportSubtitlesModal from './ExportSubtitlesModal';
import ExportAsVideoModal from './ExportAsVideoModal';
import CreateProjectModal from './CreateProjectModal';
import EditProjectModal from './EditProjectModal';
import ProjectUploadingStatus from './ProjectUploadingStatus';
import ProjectUploadModal from './ProjectUploadModal';
import AddProjectVideoFromLibraryModal from './AddProjectVideoFromLibraryModal';
import ProjectExternalSourceUploadStatus from './ProjectExternalSourceUploadStatus';
import ProjectImageUploadModal from './ProjectImageUploadModal';
import WelcomeModal from './WelcomeModal';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import ProjectAudioUploadModal from './ProjectAudioUploadModal';
import AccountSettingsModal from './AccountSettingsModal';
import RenameProjectModal from './RenameProjectModal';
import InviteModal from './InviteModal';
import CreateFolderModal from './CreateFolderModal';
import RenameFolderModal from './RenameFolderModal';
import MoveToFolderModal from './MoveToFolderModal';
import StockVideoModal from './StockVideoModal';
import StockImagesModal from './StockImagesModal';

import StockVideosModal from './StockVideosModal';
import InviteCollaboratorModal from './InviteCollaboratorModal';
import WorkspaceSettingsModal from './WorkspaceSettingsModal';
import InvoicesModal from './InvoicesModal';

import CreateWorkspaceModal from './CreateWorkspaceModal';

import styles from './styles.module.scss';
import MoveProjectToWorkspaceModal from './MoveProjectToWorkspaceModal';
import TemplatePreviewModal from './TemplatePreviewModal';
import { NEUTRAL_500 } from '../../styles/colors';

const ModalRoot = (): JSX.Element => {
    const dispatch = useDispatch();

    const modalType = useSelector(getModalType);
    const modalEvent = useSelector(getModalEvent);
    const optionalData = useSelector(getModalOptionalData);
    const isModalVisible = useSelector(getModalVisibility);
    const appTourStatus = useSelector(getAppTourStatus);
    const showBrowserRecommendation = useSelector(
        getIsBrowserRecommendationActiveStatus
    );
    const hasIssueWithSubscription = useSelector(getSubscriptionIssueStatus);

    const handleCloseModal = useCallback(() => {
        if (
            (modalEvent === ModalEvent.APP_INTRODUCTION_TOUR_PROPOSAL &&
                !appTourStatus?.isAppTourSkipped) ||
            modalType === ModalType.UPLOAD_FROM_EXTERNAL_SOURCE_STATUS ||
            (modalType === ModalType.SELECT_PLAN && hasIssueWithSubscription) ||
            modalType === ModalType.PROJECT_VIDEOS_UPLOADING_STATUS
        ) {
            return;
        }

        dispatch(hideModal());
        if(optionalData?.isFromWorkspaceSettings) {
            dispatch(
                showModal(
                    ModalType.WORKSPACE_SETTINGS,
                    ModalEvent.WORKSPACE_SETTINGS,
                    '',
                    {
                        isFromWorkspaceSettings: false,
                        defaultRoute: optionalData?.defaultRoute || 'settings',
                    }
                )
            );
        }
    }, [
        dispatch,
        hasIssueWithSubscription,
        modalType,
        modalEvent,
        appTourStatus,
        optionalData,
    ]);

    const keyDownHandler = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleCloseModal();
            }
        },
        [handleCloseModal]
    );

    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);

        return () => window.removeEventListener('keydown', keyDownHandler);
    }, [keyDownHandler]);

    const renderCloseButton = (): JSX.Element => (
        <div className={styles.CloseButton} onClick={handleCloseModal}>
            <Icon name="close-new" size={20} color={NEUTRAL_500} />
        </div>
    );

    const renderTitle = (): JSX.Element => {
        switch (modalEvent) {
            case ModalEvent.UPLOAD_FACES:
                return (
                    <span className={styles.MessageModal__title}>
                        Upload Faces
                    </span>
                );
            case ModalEvent.SIGN_UP:
                return (
                    <span className={styles.MessageModal__title}>
                        Registration successful
                    </span>
                );
            case ModalEvent.INVALID_HASH:
                return (
                    <span className={styles.MessageModal__title}>Error</span>
                );
            case ModalEvent.PRIVACY_POLICY:
                return (
                    <span className={styles.MessageModal__title}>
                        Privacy Policy
                    </span>
                );
            case ModalEvent.FORGOT_PASSWORD:
                return (
                    <span className={styles.MessageModal__title}>
                        Password successfully saved
                    </span>
                );
            default:
                return <></>;
        }
    };

    const renderText = (): JSX.Element => {
        switch (modalEvent) {
            case ModalEvent.SIGN_UP:
                return (
                    <p className={styles.MessageModal__message}>
                        Check your email to confirm your account
                    </p>
                );
            case ModalEvent.FORGOT_PASSWORD:
                return (
                    <p className={styles.MessageModal__message}>
                        Use it to login to your account.
                    </p>
                );
            case ModalEvent.INVALID_HASH:
                return (
                    <p className={styles.MessageModal__message}>
                        Sorry, your link is outdated. Please enter your email
                        again and we'll send you a new verification link.
                    </p>
                );
            case ModalEvent.PRIVACY_POLICY:
                return <p className={styles.MessageModal__message}>test</p>;
            default:
                return <></>;
        }
    };

    const renderModalByType = (): JSX.Element => {
        switch (modalType) {
            case ModalType.UPLOAD:
                return <UploadModal closeModal={handleCloseModal} />;
            case ModalType.UPLOAD_FACES:
                return (
                    <UploadFacesModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                        title={renderTitle()}
                    />
                );
            case ModalType.MESSAGE:
                return (
                    <MessageModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                        text={renderText()}
                        title={renderTitle()}
                    />
                );
            case ModalType.GENERATOR:
                return (
                    <GenerationModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                    />
                );
            case ModalType.AVID_EXPORT:
                return <AvidExportModal closeModal={handleCloseModal} />;
            case ModalType.EDL_EXPORT:
                return <EdlExportModal closeModal={handleCloseModal} />;
            case ModalType.THUMBNAILS_EXPORT:
                return (
                    <ThumbnailsExportModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                    />
                );
            case ModalType.ADD_COLLECTION:
                return <AddCollectionModal />;
            case ModalType.CREATE_TEMPLATE:
                return <CreateTemplateModal />;
            case ModalType.REANALISYS:
                return <ReanalisysModal />;
            case ModalType.CONFIRM_DELETE:
                return <ConfirmDeleteModal />;
            case ModalType.EDIT_VIDEO:
                return <EditVideoModal closeButton={renderCloseButton()} />;
            case ModalType.UPGRADE_PLAN:
                return <UpgradePlanModal closeButton={renderCloseButton()} />;
            case ModalType.PAYMENT_CARD_SELECT:
                return (
                    <PaymentCardSelectModal closeButton={renderCloseButton()} />
                );
            case ModalType.ADD_MORE_CREDITS:
                return (
                    <AddMoreCreditsModal closeButton={renderCloseButton()} />
                );
            case ModalType.ADD_MORE_DUBBING_CREDITS:
                return (
                    <AddMoreDubbingCreditsModal closeButton={renderCloseButton()} />
                );
            case ModalType.MANAGE_STORAGE:
                return <ManageStorageModal closeButton={renderCloseButton()} />;
            case ModalType.CANCEL_PLAN:
                return <CancelPlanModal closeButton={renderCloseButton()} />;
            case ModalType.PLAN_CANCELED:
                return <PlanCanceledModal closeButton={renderCloseButton()} />;
            case ModalType.ADD_CREDIT_CARD:
                return <AddCreditCardModal closeButton={renderCloseButton()} />;
            case ModalType.SELECT_PLAN:
                return <SelectPlanModal closeButton={renderCloseButton()} />;
            case ModalType.SUBSCRIPTION_ALL_SET:
                return <SubscriptionAllSetModal />;
            case ModalType.DELETE_LAST_CUSTOMER_CARD:
                return (
                    <DeleteLastCardModal closeButton={renderCloseButton()} />
                );
            case ModalType.AUTO_RENEWAL_CONFIRM:
                return (
                    <AutoRenewalConfirmModal
                        closeButton={renderCloseButton()}
                    />
                );
            case ModalType.CLIP_PREVIEWS:
                return <PreviewsModal />;
            case ModalType.EXPORT_VIDEO_SUBTITLES:
                return <ExportSubtitlesModal />;
            case ModalType.APP_INTRODUCTION_TOUR_PROPOSAL:
                return (
                    <AppTourProposalModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                    />
                );
            case ModalType.EXPORT_METADATA_AS_VIDEO:
                return (
                    <ExportAsVideoModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                    />
                );
            case ModalType.CREATE_PROJECT:
                return <CreateProjectModal closeButton={renderCloseButton()} />;
            case ModalType.ADD_PROJECT_VIDEO:
                return (
                    <AddProjectVideoFromLibraryModal
                        closeButton={renderCloseButton()}
                    />
                );
            case ModalType.EDIT_PROJECT:
                return <EditProjectModal closeButton={renderCloseButton()} />;
            case ModalType.PROJECT_VIDEOS_UPLOADING_STATUS:
                return <ProjectUploadingStatus />;
            case ModalType.PROJECT_AUDIO_UPLOAD:
                return <ProjectAudioUploadModal />;
            case ModalType.PROJECT_UPLOAD:
                return <ProjectUploadModal />;
            case ModalType.UPLOAD_FROM_EXTERNAL_SOURCE_STATUS:
                return <ProjectExternalSourceUploadStatus />;
            case ModalType.PROJECT_IMAGE_UPLOAD:
                return <ProjectImageUploadModal />;
            case ModalType.WELCOME:
                return <WelcomeModal />;
            case ModalType.KEYBOARD_SHORTCUTS:
                return <KeyboardShortcutsModal />;
            case ModalType.ACCOUNT_SETTINGS:
                return (
                    <AccountSettingsModal closeButton={renderCloseButton()} />
                );
            case ModalType.RENAME_PROJECT:
                return <RenameProjectModal closeModal={handleCloseModal} />;
            case ModalType.INVITE:
                return <InviteModal />;
            case ModalType.INVITE_COLLABORATOR:
                return <InviteCollaboratorModal />;
            case ModalType.INVOICES:
                return <InvoicesModal />;
            case ModalType.CREATE_FOLDER:
                return <CreateFolderModal />;
            case ModalType.RENAME_FOLDER:
                return <RenameFolderModal />;
            case ModalType.MOVE_TO_FOLDER:
                return <MoveToFolderModal />;
            case ModalType.STOCK_VIDEO:
                return <StockVideoModal />;
            case ModalType.STOCK_IMAGES:
                return <StockImagesModal />;
            case ModalType.STOCK_VIDEOS:
                return <StockVideosModal />;
            case ModalType.WORKSPACE_SETTINGS:
                return <WorkspaceSettingsModal />;
            case ModalType.CREATE_WORKSPACE:
                return <CreateWorkspaceModal />;
            case ModalType.MOVE_PROJECT_TO_WORKSPACE:
                return <MoveProjectToWorkspaceModal />;
            case ModalType.TEMPLATE_PREVIEW:
                return <TemplatePreviewModal />;
            default:
                return (
                    <MessageModal
                        closeButton={renderCloseButton()}
                        closeModal={handleCloseModal}
                        text={renderText()}
                        title={renderTitle()}
                    />
                );
        }
    };

    if (isModalVisible) {
        return (
            <div className={styles.ModalRoot}>
                <button
                    className={[styles.ModalRoot__backdrop].join(' ')}
                    onClick={handleCloseModal}
                />
                {modalType ? renderModalByType() : null}
                {showBrowserRecommendation ? <BrowserRecommendation /> : <></>}
            </div>
        );
    }

    return <></>;
};

// export default memo(withRouter(ModalRoot));
export default ModalRoot;
