import { IconProps } from './index';

const CaptionsIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 36;
  if (size) {
    height = size;
  }

  return (
    <svg width={height} height={height} version="1.1" viewBox="0 0 36 36">
      <path
        d="M11,11 C9.9,11 9,11.9 9,13 L9,23 C9,24.1 9.9,25 11,25 L25,25 C26.1,25 27,24.1 27,23 L27,13 C27,11.9 26.1,11 25,11 L11,11 Z M11,17 L14,17 L14,19 L11,19 L11,17 L11,17 Z M20,23 L11,23 L11,21 L20,21 L20,23 L20,23 Z M25,23 L22,23 L22,21 L25,21 L25,23 L25,23 Z M25,19 L16,19 L16,17 L25,17 L25,19 L25,19 Z"
        fill={color}
        id="ytp-id-16"
      />
    </svg>
  );
};

export default CaptionsIcon;
