import moment, { Moment } from 'moment';

import { DateFilterOption, DATE_FILTER_TYPE } from 'interfaces/filter';
import { UserStatsFilter } from 'interfaces/stats';
import { StatusType } from 'interfaces/statuses';

export const dateFilterOptions = [
    {
        value: DATE_FILTER_TYPE.ANYTIME,
        id: 1,
    },
    {
        value: DATE_FILTER_TYPE.YESTERDAY,
        id: 2,
    },
    {
        value: DATE_FILTER_TYPE.LAST_7_DAYS,
        id: 3,
    },
    {
        value: DATE_FILTER_TYPE.LAST_30_DAYS,
        id: 4,
    },
    {
        value: DATE_FILTER_TYPE.LAST_90_DAYS,
        id: 5,
    },
    {
        value: DATE_FILTER_TYPE.CUSTOM,
        id: 6,
    },
];

export const statusFilterOptions = [
    {
        value: null,
        id: 1,
        label: 'All',
    },
    {
        value: StatusType.SUCCEEDED,
        id: 2,
        label: 'Succeeded',
    },
    {
        value: StatusType.IN_PROGRESS,
        id: 3,
        label: 'In Progress',
    },
    {
        value: StatusType.FAILED,
        id: 4,
        label: 'Failed',
    },
];

export const defaultAnalyzerVideoFilter = {
    dateFrom: moment().startOf('day').toDate(),
    dateTo: moment().toDate(),
    title: '',
    description: '',
    dateFilter: dateFilterOptions[0],
};

export const getDateFromToRange = ({
    dateFilter,
    filter,
}: {
    dateFilter: DateFilterOption;
    filter: UserStatsFilter;
}): {
    dateFrom: Moment;
    dateTo: Moment;
} | null => {
    const selectedDateFilter = dateFilter.value;

    if (selectedDateFilter === DATE_FILTER_TYPE.YESTERDAY) {
        return {
            dateFrom: moment(filter.dateFrom).add(-1, 'day').startOf('day'),
            dateTo: moment(filter.dateTo).add(-1, 'day').endOf('day'),
        };
    }
    if (selectedDateFilter === DATE_FILTER_TYPE.LAST_7_DAYS) {
        return {
            dateFrom: moment(filter.dateFrom).add(-7, 'day').startOf('day'),
            dateTo: moment(),
        };
    }
    if (selectedDateFilter === DATE_FILTER_TYPE.LAST_30_DAYS) {
        return {
            dateFrom: moment(filter.dateFrom).add(-30, 'day').startOf('day'),
            dateTo: moment(),
        };
    }
    if (selectedDateFilter === DATE_FILTER_TYPE.LAST_90_DAYS) {
        return {
            dateFrom: moment(filter.dateFrom).add(-90, 'day').startOf('day'),
            dateTo: moment(),
        };
    }
    if (selectedDateFilter === DATE_FILTER_TYPE.CUSTOM) {
        return {
            dateFrom: moment(filter.dateFrom).add(-1, 'day').startOf('day'),
            dateTo: moment(filter.dateTo).endOf('day'),
        };
    }
    return null;
};
