import {
    RawAnalysisResultItem,
    RawSentence,
    TransformedAnalysisResultItem,
    TransformedSentence,
} from 'state/modules/metadata';

export const transformAnalysisResults = (
    results: Array<RawAnalysisResultItem>,
    videoId: string
): Array<TransformedAnalysisResultItem> => {
    const changedResults = results.map((item: RawAnalysisResultItem) => ({
        ...item,
        videoId,
        id: item.name + item.labelType + videoId,
    }));

    return changedResults;
};

export const transformTranscript = (
    transcript: Array<RawSentence>,
    isDefault?: boolean
): Array<TransformedSentence> => {
    const changedTranscript = transcript.map((item: RawSentence) => {
        const dataLangKey = `data_${item.language}`;

        const originalSentence = item[
            dataLangKey as keyof RawSentence
        ] as string;

        const data = {
            ...item,
            labelType: item.type,
            name: item.data,
            originalSentence,
            timestamps: [
                { timestamp: +item.startTime * 1000 },
                { timestamp: +item.endTime * 1000 },
            ],
        } as TransformedSentence;

        if (isDefault) {
            data.isDefault = true;
        }

        return data;
    });

    return changedTranscript;
};
