import { ChangeEvent, useState } from 'react';

import { FormContainer, NewButton, NewInput } from 'components/UI';

import { validate } from 'utils';
import { ValidationType, ValidatorType } from 'utils/validate';
import { NotificationTypes, showNotification } from 'utils/notifications';
import { Link, useNavigate } from 'react-router-dom';

import AuthClient from 'services/api/auth';
import styles from './styles.module.scss';

const ResendConfirmationEmail = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        const emailValidationErrors = validate(
            email,
            [ValidatorType.Required],
            ValidationType.OnSubmit
        );

        if (emailValidationErrors[0]) {
            setEmailError(emailValidationErrors[0]);
        } else {
            try {
                setIsLoading(true);

                const checkRes = await AuthClient.registerCheck(email);

                if (checkRes?.data?.code === 400) {
                    setIsLoading(false);

                    return showNotification(
                        NotificationTypes.error,
                        checkRes?.data?.message || ''
                    );
                }

                const res = await AuthClient.resendConfirmationEmail(
                    email.replace('@', '.')
                );

                setIsLoading(false);

                if (res?.data?.code === 404) {
                    return showNotification(
                        NotificationTypes.error,
                        // res?.data?.message || ''
                        'Username/email not found. Please try again or sign up as a new user.'
                    );
                }

                showNotification(
                    NotificationTypes.success,
                    'A confirmation email has been sent to your email'
                );
                navigate('/');
            } catch (error) {
                console.log({ error });
                setIsLoading(false);
                showNotification(
                    NotificationTypes.error,
                    (error as any).message
                );
            }
        }
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setEmail(value.toLowerCase());
        setEmailError('');
    };

    return (
        <FormContainer
            onSubmit={handleSubmit}
            className={styles.ResendConfirmationEmail__formContainer}
        >
            <div className={styles.ResendConfirmationEmail}>
                <div className={styles.ResendConfirmationEmail__form}>
                    <div className={styles.ResendConfirmationEmail__topLine}>
                        <h2
                            className={
                                styles.ResendConfirmationEmail__formTitle
                            }
                        >
                            Resend confirmation email
                        </h2>
                    </div>
                    <div className={styles.ResendConfirmationEmail__inputWrap}>
                        <NewInput
                            type="email"
                            value={email}
                            onChange={handleChangeEmail}
                            placeholder="Email"
                        />
                        {emailError.length ? (
                            <p
                                className={
                                    styles.ResendConfirmationEmail__inputErrorMessage
                                }
                            >
                                {emailError}
                            </p>
                        ) : null}
                    </div>
                    <div
                        className={styles.ResendConfirmationEmail__description}
                    >
                        <p
                            className={
                                styles.ResendConfirmationEmail__descriptionText
                            }
                        >
                            Enter your email address and we will resend you a
                            confirmation email
                        </p>
                    </div>
                    <div
                        className={
                            styles.ResendConfirmationEmail__formButtonContainer
                        }
                    >
                        <NewButton
                            color="rgb(0, 192, 186)"
                            type="submit"
                            disabled={isLoading}
                            loading={isLoading}
                            borderRadius={4}
                            textColor="#ffffff"
                            className={
                                styles.ResendConfirmationEmail__submitButton
                            }
                        >
                            Resend
                        </NewButton>
                    </div>
                </div>
                <div
                    className={styles.ResendConfirmationEmail__bottomContainer}
                >
                    <Link
                        to="/"
                        className={styles.ResendConfirmationEmail__backButton}
                    >
                        Back to Sign In
                    </Link>
                </div>
            </div>
        </FormContainer>
    );
};

export default ResendConfirmationEmail;
