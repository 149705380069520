import produce from 'immer';

import { SignOutAction, UserActionTypes } from 'state/modules/user';
import {
  CollectionsActionTypes,
  CollectionsState,
  CollectionsAction,
} from './types';

export const initialState: CollectionsState = {
  collections: [],
  collectionsItemsBySearch: [],
  selectedCollectionsItems: [],
  collectionTempItems: [],
  imagesForDelete: [],
  total: 0,
  isCollectionsLoading: false,
  isUploading: false,
  isDeleteLoading: false,
  collectionsItemsBySearchLoading: false,
  isCollectionDeleting: false,
  selectedCollectionsForDelete: [],
  searchQuery: '',
};

export default (
  state: CollectionsState = initialState,
  action: CollectionsAction | SignOutAction,
): CollectionsState => produce(state, (draft) => {
  switch (action.type) {
    case CollectionsActionTypes.GET_COLLECTIONS_START:
      draft.isCollectionsLoading = true;

      return;
    case CollectionsActionTypes.GET_COLLECTIONS_SUCCESS:
      draft.collections = action.payload.collections;
      draft.total = action.payload.total;
      draft.isCollectionsLoading = false;

      return;
    case CollectionsActionTypes.GET_COLLECTIONS_FAIL:
      draft.isCollectionsLoading = false;

      return;
    case CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_UPDATE:
      draft.collectionTempItems = action.payload;

      return;
    case CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_SUCCESS:
      draft.collections = action.payload;
      draft.isDeleteLoading = false;

      return;
    case CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_START:
      draft.isDeleteLoading = true;

      return;
    case CollectionsActionTypes.DELETE_COLLECTIONS_ITEMS_FAIL:
      draft.isDeleteLoading = false;

      return;
    case CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_START:
      draft.collectionsItemsBySearchLoading = true;

      return;
    case CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_FAIL:
      draft.collectionsItemsBySearchLoading = false;

      return;
    case CollectionsActionTypes.GET_COLLECTIONS_ITEMS_BY_SEARCH_SUCCESS:
      draft.collectionsItemsBySearch = action.payload;
      draft.collectionsItemsBySearchLoading = false;

      return;
    case CollectionsActionTypes.UPDATE_COLLECTIONS_ITEMS_SUCCESS:
      draft.collections = action.payload;

      return;
    case CollectionsActionTypes.ADD_COLLECTION_IMAGES_IDS_FOR_DELETE:
      draft.imagesForDelete = action.payload;

      return;
    case CollectionsActionTypes.SET_COLLECTION_IMAGES_IDS_FOR_DELETE:
      draft.imagesForDelete = action.payload;

      return;
    case CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_START:
      draft.isUploading = true;

      return;
    case CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_FAIL:
      draft.isUploading = false;

      return;
    case CollectionsActionTypes.UPLOAD_COLLECTION_ITEMS_SUCCESS:
      draft.isUploading = false;

      return;
    case CollectionsActionTypes.SET_SELECTED_COLLECTIONS_ITEMS:
      draft.selectedCollectionsItems = action.payload;

      return;
    case CollectionsActionTypes.SET_COLLECTION_DELETING:
      draft.isCollectionDeleting = action.payload;

      return;
    case CollectionsActionTypes.SET_COLLECTIONS_FOR_DELETE:
      draft.selectedCollectionsForDelete = action.payload;

      return;
    case CollectionsActionTypes.SET_COLLECTIONS_SEARCH_QUERY:
      draft.searchQuery = action.payload;

      return;
    case UserActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
});
