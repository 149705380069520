import { IconProps } from './index';

const TikTokSecondIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            className="sc-bcXHqe jyeyjY OptionItemstyled__SocialIcon-sc-1qp2ef7-1 snsbc"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.58333 1C3.05203 1 1 3.05203 1 5.58333V18.4167C1 20.948 3.05203 23 5.58333 23H18.4167C20.948 23 23 20.948 23 18.4167V5.58333C23 3.05203 20.948 1 18.4167 1H5.58333ZM19.3333 7.71048V10.4306C17.9597 10.4773 16.6033 10.1121 15.4375 9.38184C15.4375 10.271 15.4472 11.1607 15.4568 12.05C15.4653 12.8245 15.4737 13.5987 15.4757 14.372C15.4757 15.3532 15.1856 16.3125 14.6421 17.1283C14.0986 17.9442 13.3261 18.5802 12.4223 18.9557C11.5185 19.3312 10.524 19.4294 9.56456 19.238C8.6051 19.0466 7.72377 18.574 7.03204 17.8802C6.3403 17.1863 5.86922 16.3023 5.67838 15.3399C5.48753 14.3775 5.58548 13.3799 5.95984 12.4733C6.33421 11.5667 6.96817 10.7919 7.78156 10.2467C8.59496 9.70155 9.55125 9.41057 10.5295 9.41057C10.8014 9.4111 11.0728 9.43352 11.3411 9.47762V12.1116C10.8809 11.9471 10.3815 11.9282 9.91027 12.0574C9.43902 12.1867 9.01866 12.4578 8.70592 12.8343C8.39318 13.2108 8.20313 13.6745 8.16143 14.1627C8.11972 14.651 8.22837 15.1403 8.4727 15.5647C8.71703 15.989 9.08529 16.328 9.52774 16.5357C9.9702 16.7435 10.4656 16.8101 10.9469 16.7265C11.4283 16.643 11.8726 16.4133 12.2197 16.0684C12.5668 15.7236 12.8001 15.2802 12.888 14.7982V3.75L15.4518 3.81226V4.00861C15.4672 5.00019 15.8268 5.95535 16.4688 6.70959C17.0942 7.37047 18.0634 7.71048 19.3333 7.71048Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TikTokSecondIcon;
