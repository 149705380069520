import { Trailer } from 'interfaces/generation';

import {
  GenerateClipPreviewsFailAction,
  ClipGenRawData,
  GenerationActionTypes,
  GetTrailersAction,
  GetTrailersSuccessAction,
  GenerateClipPreviewsAction,
  GetTrailersFailAction,
  GetTrailersStartAction,
  GenerateClipPreviewsStartAction,
  GenerateClipPreviewsSuccessAction,
  GetPreviewsSuccessAction,
  GetPreviewsFailAction,
  GetPreviewsAction,
  GetPreviewsStartAction,
  ClipGenerationData,
  GenerateClipsAction,
  GenerateClipsStartAction,
  GenerateClipsSuccessAction,
  GenerateClipsFailAction,
  ClipGenerationDataClip,
} from './types';

export const getTrailers = (videoId: string): GetTrailersAction => ({
  type: GenerationActionTypes.GET_TRAILERS,
  payload: videoId,
});

export const getTrailersSuccess = (
  trailers: Array<Trailer>,
): GetTrailersSuccessAction => ({
  type: GenerationActionTypes.GET_TRAILERS_SUCCESS,
  payload: {
    trailers,
  },
});

export const getTrailersStart = (): GetTrailersStartAction => ({
  type: GenerationActionTypes.GET_TRAILERS_START,
});

export const getTrailersFail = (error: any): GetTrailersFailAction => ({
  type: GenerationActionTypes.GET_TRAILERS_FAIL,
  payload: error,
});

export const generateClipPreviews = (
  data: ClipGenRawData,
): GenerateClipPreviewsAction => ({
  type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS,
  payload: data,
});

export const generateClipPreviewsStart = (): GenerateClipPreviewsStartAction => ({
  type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_START,
});

export const generateClipPreviewsSuccess = (): GenerateClipPreviewsSuccessAction => ({
  type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_SUCCESS,
});

export const generateClipPreviewsFail = (
  error: any,
): GenerateClipPreviewsFailAction => ({
  type: GenerationActionTypes.GENERATE_CLIP_PREVIEWS_FAIL,
  payload: error,
});

export const getPreviews = (videoId: string): GetPreviewsAction => ({
  type: GenerationActionTypes.GET_PREVIEWS,
  payload: videoId,
});

export const getPreviewsStart = (): GetPreviewsStartAction => ({
  type: GenerationActionTypes.GET_PREVIEWS_START,
});

export const getPreviewsSuccess = ({
  previews,
}: {
    previews: Array<ClipGenerationData>;
}): GetPreviewsSuccessAction => ({
  type: GenerationActionTypes.GET_PREVIEWS_SUCCESS,
  payload: {
    previews,
  },
});

export const getPreviewsFail = (error: any): GetPreviewsFailAction => ({
  type: GenerationActionTypes.GET_PREVIEWS_FAIL,
  payload: error,
});

export const generateClips = ({
  videoId,
  selectedClips,
  previewsData,
}: {
    videoId: string;
    selectedClips: Array<ClipGenerationDataClip>;
    previewsData: ClipGenerationData;
}): GenerateClipsAction => ({
  type: GenerationActionTypes.GENERATE_CLIPS,
  payload: {
    videoId,
    selectedClips,
    previewsData,
  },
});

export const generateClipsStart = (): GenerateClipsStartAction => ({
  type: GenerationActionTypes.GENERATE_CLIPS_START,
});

export const generateClipsSuccess = (): GenerateClipsSuccessAction => ({
  type: GenerationActionTypes.GENERATE_CLIPS_SUCCESS,
});

export const generateClipsFail = (error: any): GenerateClipsFailAction => ({
  type: GenerationActionTypes.GENERATE_CLIPS_FAIL,
  payload: error,
});
