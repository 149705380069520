import { IconProps } from './index';

const AttentionIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 153;
  if (size) {
    height = size;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      viewBox="0 0 153 153"
    >
      <g id="noun_attention_1614108" transform="translate(0 -0.062)">
        <path
          d="M24.641,99.569,62.8,33.553a15.318,15.318,0,0,1,20.917-5.612,14.821,14.821,0,0,1,5.612,5.612l38.161,66.017a15.318,15.318,0,0,1-5.612,20.917,15.623,15.623,0,0,1-7.653,2.041H37.905A15.319,15.319,0,0,1,24.641,99.569Z"
          transform="translate(0.46 0.536)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6.82"
        />
        <path
          d="M0,35V0"
          transform="translate(76.526 53.271)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="7.73"
        />
        <circle
          cx="5.612"
          cy="5.612"
          r="5.612"
          transform="translate(70.914 99.085)"
          fill={color}
        />
        <rect
          width="153"
          height="153"
          transform="translate(0 0.062)"
          fill="none"
        />
      </g>
    </svg>
  );
};
export default AttentionIcon;
