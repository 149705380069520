import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 0.944;

const SearchIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 18;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8117 16.8291L12.7552 12.5032C13.9416 11.1737 14.6713 9.41465 14.6713 7.47962C14.6713 3.3553 11.3806 0 7.33566 0C3.29072 0 0 3.3553 0 7.47962C0 11.6039 3.29072 14.9592 7.33566 14.9592C8.99495 14.9592 10.5217 14.3884 11.7516 13.4378L15.8292 17.7859C15.9623 17.9286 16.141 18 16.3204 18C16.4896 18 16.6581 17.9363 16.7899 17.8081C17.0611 17.5441 17.0712 17.1056 16.8117 16.8291ZM1.35964 7.47962C1.35964 4.11947 4.04087 1.38563 7.33634 1.38563C10.6318 1.38563 13.313 4.11947 13.313 7.47962C13.313 10.8398 10.6318 13.5736 7.33634 13.5736C4.04087 13.5736 1.35964 10.8398 1.35964 7.47962Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
