import { NewButton } from 'components/UI';
import { useDispatch, useSelector } from 'react-redux';

import { getModalOptionalData, hideModal } from 'state/modules/modal';
import { deleteCustomerCard, setCardIdToRemove } from 'state/modules/payment';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const DeleteLastCardModal = (props: Props): JSX.Element => {
  const { closeButton } = props;

  const cardId = useSelector(getModalOptionalData);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(setCardIdToRemove(''));
    dispatch(hideModal());
  };

  const confirmDelete = () => {
    dispatch(deleteCustomerCard(cardId, true));
    dispatch(hideModal());
  };

  return (
    <div className={styles.DeleteLastCardModal}>
      <p className={styles.DeleteLastCardModal__title}>Are you sure?</p>
      <p className={styles.DeleteLastCardModal__message}>
        Attention. You are about to delete your only payment card. You
        account will be disabled after your subscription expires.
      </p>
      <div className={styles.DeleteLastCardModal__buttonWrap}>
        <NewButton
          color="#021B63"
          textColor={Colors.WHITE}
          bordered
          borderRadius={26}
          width={262}
          height={46}
          onClick={confirmDelete}
        >
          Okay
        </NewButton>
        <NewButton
          color="#021B63"
          textColor={Colors.WHITE}
          bordered
          borderRadius={26}
          width={262}
          height={46}
          onClick={close}
        >
          Cancel
        </NewButton>
      </div>
      {closeButton}
    </div>
  );
};

export default DeleteLastCardModal;
