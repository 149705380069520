import { AnalysisStatsCategory } from 'interfaces/stats';
import React, { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
    title: string;
    description: string | number;
    onClick?: (category?: AnalysisStatsCategory) => void;
    category?: AnalysisStatsCategory;
}

const StatsCard: React.FC<Props> = memo(
  ({
    title, description, onClick, category,
  }) => {
    const handleClick = () => {
      if (onClick) {
        if (category) {
          onClick(category);
        } else {
          onClick();
        }
      }
    };

    return (
      <button className={styles.StatsCard} onClick={handleClick}>
        <p className={styles.StatsCard__description}>{description}</p>
        <p className={styles.StatsCard__title}>{title}</p>
      </button>
    );
  },
);

export default StatsCard;
