import React, { memo, useState } from 'react';

import { Icon } from 'components/UI';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { OFFSET_MASK_STRING, OFFSET_TYPES } from 'interfaces/export';

import styles from './styles.module.scss';

interface Props {
  onChange: (type: OFFSET_TYPES) => void;
  offsetType: OFFSET_TYPES;
}

const OffsetFormatSelect = (props: Props) => {
  const [isOptionsActive, setIsOptionsActive] = useState(false);

  const { onChange, offsetType } = props;

  const optionsRef = React.useRef<HTMLDivElement>(null);

  const close = () => {
    setIsOptionsActive(false);
  };

  useOnClickOutside(optionsRef, () => close());

  const toggle = () => {
    setIsOptionsActive(!isOptionsActive);
  };

  const handleSelectType = (selectedType: OFFSET_TYPES) => {
    onChange(selectedType);
  };

  return (
    <div className={styles.OffsetFormatSelect} ref={optionsRef}>
      <button
        className={styles.OffsetFormatSelect__optionsToggler}
        onClick={toggle}
      >
        <div className={styles.OffsetFormatSelect__divider} />
        <span className={styles.OffsetFormatSelect__value}>
          {offsetType === OFFSET_TYPES.FF
            ? OFFSET_MASK_STRING.FF
            : OFFSET_MASK_STRING.MS}
        </span>
        {isOptionsActive ? (
          <div className={styles.OffsetFormatSelect__panel}>
            {Object.values(OFFSET_MASK_STRING).map(
              (type: OFFSET_MASK_STRING) => (
                <button
                  onClick={() => handleSelectType(
                    type === OFFSET_MASK_STRING.FF
                      ? OFFSET_TYPES.FF
                      : OFFSET_TYPES.MS,
                  )}
                  key={type}
                  className={
                    styles.OffsetFormatSelect__category
                  }
                >
                  {type}
                </button>
              ),
            )}
          </div>
        ) : null}
        <div className={styles.OffsetFormatSelect__iconWrap}>
          <Icon
            name={isOptionsActive ? 'arrowUp' : 'arrowDown'}
            size={10}
          />
        </div>
      </button>
    </div>
  );
};

export default memo(OffsetFormatSelect);
