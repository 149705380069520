import { TransformedSentence } from 'state/modules/metadata';
import { MediaFile, MediaInfoAudio, MediaInfoVideo, TempMediaFile } from './videos';
import { Voice } from './textToSpeech';

export enum ProjectStatuses {
    PREPARING = 'PREPARING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    WAITING_FOR_TRANSCODING = 'WAITING_FOR_TRANSCODING',
    DRAFT = 'DRAFT',
}

export enum TimelineItemType {
    TEXT,
    ELEMENT,
    IMAGE,
}

export interface ProjectItem {
    zIndex?: number;
}

export enum TextAnimationTabType {
    IN= 'in',
    OUT = 'out',
}

export enum InTextAnimationType {
    NONE = 'none',
    FADE = 'fade',
    SLIDE_RIGHT = 'slide_right',
    SLIDE_LEFT = 'slide_left',
    SLIDE_UP = 'slide_up',
    SLIDE_DOWN = 'slide_down',
    // BLOCK = 'block',
    ZOOM_IN = 'zoom_in',
    TYPEWRITER = 'typewriter',
    RISE = 'rise',
    POP = 'pop',
    DROP = 'drop',
    COMPRESS = 'compress',
    BOUNCE = 'bounce',
    WAVE = 'wave',
    FALL = 'fall',
    SKID = 'skid',
    FLIPBOARD = 'flipboard',
    SCALE = 'scale',
    DRAGONFLY = 'dragonfly',
    BILLBOARD = 'billboard',
    ROLL = 'roll',
}

export enum OutTextAnimationType {
    NONE = 'none',
    FADE = 'fade',
    SLIDE_RIGHT = 'slide_right',
    SLIDE_LEFT = 'slide_left',
    SLIDE_UP = 'slide_up',
    SLIDE_DOWN = 'slide_down',
    // BLOCK = 'block',
    ZOOM_OUT = 'zoom_out',
    SINK = 'sink',
    POP = 'pop',
    DROP = 'drop',
    DECOMPRESS = 'decompress',
    FALL = 'fall',
    SKID = 'skid',
    FLIPBOARD = 'flipboard',
    SCALE = 'scale',
    DRAGONFLY = 'dragonfly',
    BILLBOARD = 'billboard',
    ROLL = 'roll',
}

export type TextAnimationType = InTextAnimationType | OutTextAnimationType;

export interface InTextAnimation {
    type: InTextAnimationType,
}

export interface OutTextAnimation {
    type: OutTextAnimationType,
}

export interface TextAnimationsObject {
    [TextAnimationTabType.IN]?: InTextAnimation,
    [TextAnimationTabType.OUT]?: OutTextAnimation,
}

export interface ProjectText extends ProjectItem {
    data: string;
    textFontSize: number;
    translationY?: number; // 0-1
    translationX?: number; // 0-1
    angle: number;
    width: number; // 0-1
    align: SubtitlesAlign;
    textEffect: SubtitleEffect;
    textStyle?: SubtitlesTextStyle | null;
    fontFamily: string;
    startTime: number;
    endTime: number;
    outline: number;
    outlineColor: string;
    shadow: number;
    shadowColor: string;
    textColor: string;
    id: string;
    box?: ProjectSubtitlesSettingsBox;
    fontWeight: number;
    type?: TimelineItemType.TEXT;
    animations?: TextAnimationsObject;
}

export interface ProjectVideo extends ProjectItem {
    mediaSourcesId: string;
    name: string;
    startTime: number;
    endTime: number;
    trimStart: number;
    trimEnd: number;
    id: string;
    videoVolume: number;
    speed: number;
    mediaInfo: MediaInfoVideo;
    path: string;
    canvas?: ProjectVideoCanvas;
    isDummy: boolean;
    isAudioDetached?: boolean;
    isAudio?: boolean;
    isStockMedia?: boolean;
    stockMediaUrl?: string;
    stockVideoDownloadSize?: string;
    audioLanguage?: string;
    dubVolume?: number;
    enhanced?: boolean;
    enhancedVolume?: number;
    stockPreviewUrl?: string;
    audioFadeInDuration?: number;
    audioFadeOutDuration?: number;
}

export enum TemplateStatus {
    DRAFT = 'DRAFT',
    SUBMITTED_FOR_REVIEW = 'SUBMITTED_FOR_REVIEW',
    APPROVED = 'APPROVED',
    NEED_REVISION = 'NEED_REVISION',
    PUBLISHED = 'PUBLISHED',
    PUBLISHING = 'PUBLISHING',
}

export interface NewRawProject {
    mediaSources: string[];
    name: string;
    videos: ProjectVideo[];
    audios: ProjectAudio[];
    images: ProjectImage[];
    texts: ProjectText[];
    transcript: ProjectTranscript;
    isSelected?: boolean;
    settings: ProjectSettings;
    language: '';
    mediaSourcesSettings: ProjectMediaSourcesSettings[];
    aspectRatio: ProjectSize;
    videoVolume: number;
    speed: number;
    isProjectDurationAutomatic: boolean;
    folder?: string;
    workspaceId: string;
    isTemplate?: boolean;
    templateStatus?: TemplateStatus;
    username?: string;
    sentences?: string[];
}

export interface ProjectSettings {
    subtitles: ProjectSubtitlesSettings;
    video: ProjectVideoSettings;
    mediaSubtitlesSettings?: MediaSubtitlesSettings[];
}

export interface ProjectMediaSourcesSettings {
    mediaSourceId: string;
    language: string;
}

export enum SubtitleEffect {
    DEFAULT = 'DEFAULT',
    NORMAL = 'NORMAL',
    BLOCK = 'BLOCK',
    OUTLINE_ONLY = 'OUTLINE_ONLY',
    OUTLINE_FILLED = 'OUTLINE_FILLED',
    DROP_SHADOW = 'DROP_SHADOW',
    BOX_HIGHLIGHT = 'BOX_HIGHLIGHT',
    REVEAL = 'REVEAL',
    KARAOKE = 'KARAOKE',
}

export enum SubtitlesAlign {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export enum SubtitlesTextStyle {
    BOLD = 'bold',
    ITALIC = 'italic',
    NORMAL = 'normal',
}

export interface ProjectSubtitlesSettingsBox {
    borderWidth: number;
    round: number; // box raunding
}

export enum TextLinesValue {
    NONE = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
}
export interface ProjectSubtitlesSettings {
    subtitlesColor: string;
    subtitlesFontSize: number;
    translationY?: number; // 0-1
    translationX?: number; // 0-1
    box: ProjectSubtitlesSettingsBox;
    angle: number;
    width: number; // 0-1
    align: SubtitlesAlign;
    subtitleEffect: SubtitleEffect;
    subtitlesTextStyle?: SubtitlesTextStyle | null;
    // subtitlesFontWeight?: number;
    fontFamily: string;
    spacing: number;
    lineHeight: number;
    outline: number;
    outlineColor: string;
    shadow: number;
    shadowColor: string;
    textLines: TextLinesValue;
    fontWeight: number;
}

export interface MediaSubtitlesSettings {
    mediaId: string;
    settings: ProjectSubtitlesSettings
}

export enum ProjectSettingsPresetValue {
    YOUTUBE = 'YOUTUBE',
    INSTAGRAM = 'INSTAGRAM',
    TWITTER = 'TWITTER',
    CUSTOM = 'CUSTOM',
    DRAFT = 'DRAFT',
    STANDART = 'STANDART',
    HD = 'HD',
}

export enum ProjectSettingsPreset {
    FAST = 'FAST',
    MEDIUM = 'MEDIUM',
    VERY_FAST = 'VERYFAST',
}

export enum ProjectSettingsQuality {
    LOW = 'LOW',
    STANDARD = 'STANDARD',
    HIGH = 'HIGH',
}

export interface ProjectVideoSettings {
    resolution: string;
    quality: ProjectSettingsQuality;
    preset: ProjectSettingsPreset;
    fps: number;
    settingsPreset: ProjectSettingsPresetValue;
    bgColor: string;
}

export interface ProjectImage extends ProjectItem {
    startTime: number;
    endTime: number;
    s3ImagePath: string;
    translationY: number;
    translationX: number;
    angle: number;
    width: number;
    height: number;
    horizontMirror: boolean;
    verticalMirror: boolean;
    localImagePath?: string; // Thi path of image on backend server
    id: string;
    isStockMedia?: boolean;
    type?: TimelineItemType.IMAGE;
}

export interface ProjectAudioMediaSource {
    createdAt: string;
    id: string;
    mediaInfo: MediaInfoAudio;
    s3Path: string;
    uploadedAt: string;
    userId: string;
    videoId: string;
    isDetach?: boolean;
    decibelsPath?: string;
    filename?: string;
    language?: string;
    // isDetached?: boolean;
    isEnhanceReady?: boolean;
    enhancedAudioPath?: string;
}

export interface ProjectMediaAudioOptions {
    [key: string]: ProjectAudioMediaSource[];
}

export interface ProjectAudio {
    startTime: number;
    endTime: number;
    trimStart: number;
    trimEnd: number;
    s3AudioPath: string;
    localAudioPath?: string;
    volume: number;
    speed: number;
    mediaSourcesId: string;
    id: string;
    mediaInfo: MediaInfoAudio;
    name: string;
    videoId: string;
    projectTrackId: string;
    isActive?: boolean;
    stockMediaType?: 'song' | 'soundEffect';
    isStockMedia?: boolean;
    songId?: string;
    audioFileId?: string;
    stockMediaUrl?: string;
    enhanced?: boolean;
    enhancedVolume?: number;
    zIndex?: number;
    audioFadeInDuration?: number;
    audioFadeOutDuration?: number;
}

export enum ProjectSize {
    ORIGINAL = 'ORIGINAL',
    SQUARE = 'SQUARE',
    YOUTUBE = 'YOUTUBE',
    LANDSCAPE = 'LANDSCAPE',
    TWITTER_AND_FACEBOOK_PORTRAITE = 'TWITTER_AND_FACEBOOK_PORTRAITE',
    TWITTER_AND_FACEBOOK_LANDSCAPE = 'TWITTER_AND_FACEBOOK_LANDSCAPE',
    INSTAGRAM_STORY = 'INSTAGRAM_STORY',
    TIKTOK = 'TIKTOK',
    FACEBOOK_COVER = 'FACEBOOK_COVER',
    PINTEREST = 'PINTEREST',
    LINKEDIN_PORTRAITE = 'LINKEDIN_PORTRAITE',
    LINKEDIN_LANDSCAPE = 'LINKEDIN_LANDSCAPE',
}

export interface ProjectSizeOption {
    label: string;
    value: ProjectSize;
    icon: string;
    ratio?: {
        width: number;
        height: number;
    };
}

export interface ProjectCanvas {
    canvasSizeX: number;
    canvasSizeY: number;
}

export enum VideoTransitionType {
    NONE = 'none',
    FADE = 'fade',
    FADE_BLACK = 'fadeblack',
    FADE_WHITE = 'fadewhite',
    DISTANCE = 'distance',
    WIPE_LEFT = 'wipeleft',
    WIPE_RIGHT = 'wiperight',
    WIPE_UP = 'wipeup',
    WIPE_DOWN = 'wipedown',
    SLIDE_LEFT = 'slideleft',
    SLIDE_RIGHT = 'slideright',
    SLIDE_UP = 'slideup',
    SLIDE_DOWN = 'slidedown',
    SMOOTH_LEFT = 'smoothleft',
    SMOOTH_RIGHT = 'smoothright',
    SMOOTH_UP = 'smoothup',
    SMOOTH_DOWN = 'smoothdown',
    RECT_CROP = 'rectcrop',
    CIRCLE_CROP = 'circlecrop',
    CIRCLE_CLOSE = 'circleclose',
    CIRCLE_OPEN = 'circleopen',
    HORZ_CLOSE = 'horzclose',
    HORZ_OPEN = 'horzopen',
    VERT_CLOSE = 'vertclose',
    VERT_OPEN = 'vertopen',
    DIABGL = 'diagbl',
    DIAGBR = 'diagbr',
    DIAGTL = 'diagtl',
    DIAGTR = 'diagtr',
    HL_SLICE = 'hlslice',
    HR_SLICE = 'hrslice',
    VU_SLICE = 'vuslice',
    VD_SLICE = 'vdslice',
    DISSOLVE = 'dissolve',
    PIXELIZE = 'pixelize',
    RADIAL = 'radial',
    H_BLUR = 'hblur',
    WIPE_TL = 'wipetl',
    WIPE_TR = 'wipetr',
    WIPE_BL = 'wipebl',
    WIPE_BR = 'wipebr',
    FADE_GRAYS = 'fadegrays',
    SQUEEZE_V = 'squeezev',
    SQUEEZE_H = 'squeezeh',
    ZOOM_IN = 'zoomin',
}

export interface VideoTransition {
    duration: number;
    endTarget: string;
    startTarget: string;
    type: VideoTransitionType;
    id: string;
}

export enum VideoFilterType {
    NONE = 'none',
    BLACK_AND_WHITE = 'blackandwhite',
    NEGATIVE = 'negative',
    SEPIA = 'sepia',
    AMARO = 'amaro',
    LARK = 'lark',
    RISE = 'rise',
    RETRO = 'retro',
    MATTE = 'matte',
    COOL = 'cool',
}

export interface VideoFilter {
    id: string;
    videoId: string;
    type: string;
}

export interface Project {
    language: string;
    id: string;
    userId: string;
    version: string;
    isSelected: boolean;
    isSubtitling: boolean;
    status: ProjectStatuses;
    s3SourcePath: string;
    percentage: number;
    name: string;
    duration: number; // total duration of project in frames
    mediaSources: string[]; // video object from elastic
    audioMediaSorces: string[];
    videos: ProjectVideo[];
    images: ProjectImage[];
    audios: ProjectAudio[];
    texts: ProjectText[];
    elements: ProjectElement[];
    transcript: ProjectTranscript;
    settings: ProjectSettings;
    createdAt: Date;
    isUpdating: boolean;
    mediaSourcesSettings: ProjectMediaSourcesSettings[];
    updatedAt: Date;
    aspectRatio: ProjectSize;
    isProjectDurationAutomatic: boolean;
    transitions: VideoTransition[];
    defaultTransition?: VideoTransitionType | null;
    folder?: string;
    filters: VideoFilter[];
    subtitlesNotAttached?: boolean;
    workspaceId: string;
    latestAnalyzeJobId?: string;
    copyInProgress?: boolean;
    isTemplate?: boolean;
    templateStatus?: TemplateStatus;
    username?: string;
    isPublished?: boolean;
    thumbnailUrl?: string;
    sentences?: string[];
    lastExecutionArn?: string;
}

export enum ProjectMode {
    SETTINGS = 'SETTINGS',
    SUBTITLES = 'SUBTITLES',
}

export enum ProjectNavRoute {
    UPLOAD = 'UPLOAD',
    SETTINGS = 'SETTINGS',
    SUBTITLES = 'SUBTITLES',
    TEXT = 'TEXT',
    TRANSITION = 'TRANSITION',
    TEMPLATES = 'TEMPLATES',
    FILTER = 'FILTER',
    DRAW = 'DRAW',
    ELEMENTS = 'ELEMENTS',
    AUDIO = 'AUDIO',
    TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
    // AI = 'AI',
    STOCK_IMAGES = 'STOCK_IMAGES',
    STOCK_VIDEOS = 'STOCK_VIDEOS',
    STOCK_AUDIO = 'STOCK_AUDIO',
    STOCK_AUDIO_EFFECTS = 'STOCK_AUDIO_EFFECTS',
    DUBBING = 'DUBBING',
    STOCK = 'STOCK',
    TEXT_ANIMATIONS = 'TEXT_ANIMATIONS',
}

export interface ProjectSentenceObject {
    language: string;
    id: string;
    version: string;
}

export interface ProjectTranscript {
    deletedSentencesIds: string[];
    createdSentencesIds: string[];
    createdSentencesObjects: ProjectSentenceObject[];
    deletedSentencesObjects: ProjectSentenceObject[];
}

export interface ProjectTransformedSentence extends TransformedSentence {
    timelineStartTime: number;
    timelineEndTime: number;
    dataItems?: any[];
}

export interface ProjectExportPreset {
    resolution: string;
    quality: ProjectSettingsQuality;
    preset: ProjectSettingsPreset;
    fps: number;
    label: string;
    value: ProjectSettingsPresetValue;
    description: string;
}

export interface ProjectExportResolutionSettingsOption {
    label: string;
    value: string;
}
export interface ProjectExportQualitySettingsOption {
    label: string;
    value: ProjectSettingsQuality;
}
export interface ProjectExportPresetSettingsOption {
    label: string;
    value: ProjectSettingsPreset;
}

export interface ProjectVideoCanvas {
    videoWidth: number;
    videoHeight: number;
    translationY: number;
    translationX: number;
    angle: number;
}

export interface VideoTransitionItem {
    type: VideoTransitionType;
    preview?: string;
}

export interface VideoFilterItem {
    type: VideoFilterType;
    name: string;
}

export interface TextAnimationItem {
    type: TextAnimationType;
    name: string;
}

export enum ProjectSort {
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    NAME = 'name',
}

export enum ElementCategory {
    BRAND_LOGOS = 'brandLogos',
    CALLOUTS = 'callouts',
    DISCOUNT = 'discount',
    EMOJI = 'emoji',
    MOCKUP_DEVICES = 'mockupDevices',
    SHAPES = 'shapes',
    SIMPLE_SHAPES = 'simpleShapes',
}

export interface Element {
    id: string;
    s3Path: string;
    category: ElementCategory;
    name: string;
    createdAt: Date;
    uploadedAt: Date;
}

export interface UploadSrtBody {
    srtKey: string;
    language: string;
}

export interface ProjectElement extends Element, ProjectItem {
    startTime: number;
    endTime: number;
    translationY: number;
    translationX: number;
    angle: number;
    width: number;
    height: number;
    type?: TimelineItemType.ELEMENT;
}

export enum ProjectViewMode {
    GRID,
    LIST,
}

export type TimelineItem = ProjectText | ProjectElement | ProjectImage;

export interface TimelineMedias {
    videos: ProjectVideo[];
    subtitles: TransformedSentence[];
    frameRate: number;
    audios: ProjectVideo[];
    medias: ProjectVideo[];
    audioSubtitles: TransformedSentence[];
    videoSubtitles: TransformedSentence[];
    items: TimelineItem[];
}

export interface ProjectAnalyzeBody {
    stepFunctionsExecutionArn: string;
}

export interface VideoTracks {
    [zIndex: string]: ProjectVideo[];
}

export enum ProjectCopingType {
    SAME_WORKSPACE = 'SAME_WORKSPACE',
    OTHER_WORKSPACE = 'OTHER_WORKSPACE',
}

export enum DubbingSteps {
    SELECT_MEDIA = 'SELECT_MEDIA',
    SELECT_TARGET_LANGUAGE = 'SELECT_TARGET_LANGUAGE',
    // CHECK_TRANSLATION = 'CHECK_TRANSLATION',
}

export interface TransformedVoice extends Voice {
    label: string;
    value: string;
    StyleList?: string[];
}

export interface VoiceEmotion {
    label: string,
    value: string,
}

export interface UploadDetails extends TempMediaFile {
    isUploadFinished?: boolean;
    isUploadFailed?: boolean;
}

export interface ProjectUpload {
    id?: string,
    projectId: string,
    workspaceId: string,
    userId?: string,
    createdAt?: Date,
    updatedAt?: Date,
    projectUploadElasticId?: string,
    uploadDetails: UploadDetails,
}

export interface UploadsUpdateData {
    uploadId: string,
    prevData: ProjectUpload,
    updatedData: MediaFile,
}

export interface CleanAudioGenerationMediaStatuses {
    mediaId: string,
    isLoading: boolean,
    isSuccess: boolean,
    isFail: boolean,
}

export interface AnimationInstance {
    x?: number,
    y?: number,
    xPercent?: number,
    yPercent?: number,
    opacity?: number,
    ease?: string,
    transformOrigin?: string,
    scale?: number,
    stagger?: number | {
        from?: string,
        each?: number
    },
    onComplete?: any,
    [key: string]: number | string | any;
}

export type InAnimationInstanceProps = {
    [key in InTextAnimationType]: {
        initial: AnimationInstance;
        animation: AnimationInstance;
        final?: AnimationInstance;
        shouldSplit?: boolean;
        splitType?: 'chars' | 'words' | 'lines';
        staggerFactor?: number;
    };
};

export type OutAnimationInstanceProps = {
    [key in OutTextAnimationType]: {
        initial: AnimationInstance;
        animation: AnimationInstance;
        final?: AnimationInstance;
        shouldSplit?: boolean;
        splitType?: 'chars' | 'words' | 'lines';
        staggerFactor?: number,
    };
};

export const InTextAnimationProps: InAnimationInstanceProps = {
    [InTextAnimationType.NONE]: {
        initial: {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
            opacity: 1,
            scale: 1,
        },
        animation: {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
            opacity: 1,
            scale: 1,
        }
    },
    [InTextAnimationType.FADE]: {
        initial: {
            x: 0,
            y: 0,
            opacity: 0
        },
        animation: {
            opacity: 1,
            x: 0,
            y: 0,
            ease: 'power1.inOut'
        }
    },
    [InTextAnimationType.SLIDE_RIGHT]: {
        initial: {
            x: -100,
            y: 0,
            opacity: 0
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: 'power1.inOut'
        }
    },
    [InTextAnimationType.SLIDE_LEFT]: {
        initial: {
            x: 100,
            y: 0,
            opacity: 0
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: 'power1.inOut'
        }
    },
    [InTextAnimationType.SLIDE_UP]: {
        initial: {
            y: 100,
            x: 0,
            opacity: 0
        },
        animation: {
            y: 0,
            x: 0,
            opacity: 1,
            ease: 'power1.inOut'
        }
    },
    [InTextAnimationType.SLIDE_DOWN]: {
        initial: {
            y: -100,
            x: 0,
            opacity: 0
        },
        animation: {
            y: 0,
            x: 0,
            opacity: 1,
            ease: 'power1.inOut'
        }
    },
    [InTextAnimationType.ZOOM_IN]: {
        initial: {
            scale: 0.1,
            opacity: 0,
            transformOrigin: 'center center',
            x: 0,
            y: 0,
        },
        animation: {
            scale: 1,
            opacity: 1,
            ease: 'power1.inOut',
            x: 0,
            y: 0,
        }
    },
    [InTextAnimationType.TYPEWRITER]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 0,
            opacity: 0,
        },
        animation: {
            y: 0,
            opacity: 1,
            ease: 'steps(1)',
        }
    },
    [InTextAnimationType.RISE]: {
        shouldSplit: true,
        splitType: 'words',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 100,
            opacity: 0,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: 'power3.out',
        }
    },
    [InTextAnimationType.POP]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: 0,
            scale: 0,
            opacity: 0,
        },
        animation: {
            x: 0,
            y: 0,
            scale: 1,
            opacity: 1,
            ease: 'back.out(3)',
        },
    },
    [InTextAnimationType.DROP]: {
        initial: {
            scale: 4,
            opacity: 0,
            transformOrigin: 'center center',
            x: 0,
            y: 0,
        },
        animation: {
            scale: 1,
            opacity: 1,
            ease: 'power4.out',
            x: 0,
            y: 0,
        }
    },
    [InTextAnimationType.COMPRESS]: {
        initial: {
            opacity: 0.8,
            scaleX: 3,
            scaleY: 1,
            x: 0,
            y: 0,
            transformOrigin: 'center center',
        },
        animation: {
            opacity: 1,
            scaleX: 1,
            scaleY: 1,
            y: 0,
            x: 0,
            ease: 'power4.out',
        }
    },
    [InTextAnimationType.BOUNCE]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: -50,
            opacity: 0
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            ease:'power4.out',
        }
    },
    [InTextAnimationType.WAVE]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: 50,
            opacity: 0
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            ease:'power4.out',
        }
    },
    [InTextAnimationType.FALL]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: -70,
            opacity: 0,
            rotation: -30,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            rotation: 0,
            ease:'power4.out',

        }
    },
    [InTextAnimationType.SKID]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 3,
        initial: {
            x: 30,
            y: 0,
            opacity: 0
        },
        animation: {
            opacity: 1,
            x: 0,
            y: 0,
            ease: 'elastic.out(1, 0.3)',
        }
    },
    [InTextAnimationType.FLIPBOARD]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: 0,
            rotationX: 90 ,
            opacity: 0,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            rotationX: 0,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
    [InTextAnimationType.SCALE]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 0,
            opacity: 0,
            scale: 0.5,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            ease: 'elastic.out(1, 0.3)',
        }
    },
    [InTextAnimationType.DRAGONFLY]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            opacity: 0,
            scale: 0.1,
            xPercent: 50,
        },
        animation: {
            opacity: 1,
            scale: 1,
            xPercent: 0,
            ease: 'power3.out',
        }
    },
    [InTextAnimationType.BILLBOARD]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            opacity: 0,
            xPercent: -100,
            rotationX: -90,
        },
        animation: {
            opacity: 1,
            xPercent: 0,
            rotationX: 0,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
    [InTextAnimationType.ROLL]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            y: 0,
            opacity: 0,
            x: 100,
            rotationX: 60,
        },
        animation: {
            opacity: 1,
            x: 0,
            rotationX: 0,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
}

export const OutTextAnimationProps: OutAnimationInstanceProps = {
    [OutTextAnimationType.NONE]: {
        initial: {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
            opacity: 1,
            scale: 1,
        },
        animation: {
            x: 0,
            y: 0,
            xPercent: 0,
            yPercent: 0,
            opacity: 1,
            scale: 1,
        }
    },
    [OutTextAnimationType.FADE]: {
        initial: {
            opacity: 1,
            x: 0,
            y: 0,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 0,
            ease: 'power1.inOut'
        },
    },
    [OutTextAnimationType.SLIDE_RIGHT]: {
        initial: {
            x: 0,
            y: 0,
            opacity: 1
        },
        animation: {
            x: 100,
            y: 0,
            opacity: 0,
            ease: 'power1.inOut'
        }
    },
    [OutTextAnimationType.SLIDE_LEFT]: {
        initial: {
            x: 0,
            y: 0,
            opacity: 1
        },
        animation: {
            x: -100,
            y: 0,
            opacity: 0,
            ease: 'power1.inOut'
        }
    },
    [OutTextAnimationType.SLIDE_UP]: {
        initial: {
            y: 0,
            x: 0,
            opacity: 1
        },
        animation: {
            y: -100,
            x: 0,
            opacity: 0,
            ease: 'power1.inOut'
        }
    },
    [OutTextAnimationType.SLIDE_DOWN]: {
        initial: {
            y: 0,
            x: 0,
            opacity: 1
        },
        animation: {
            y: 100,
            x: 0,
            opacity: 0,
            ease: 'power1.inOut'
        }
    },
    [OutTextAnimationType.ZOOM_OUT]: {
        initial: {
            scale: 1,
            opacity: 1,
            transformOrigin: 'center center',
            x: 0,
            y: 0,
        },
        animation: {
            scale: 0,
            opacity: 0,
            ease: 'power1.inOut',
            x: 0,
            y: 0,
        }
    },
    [OutTextAnimationType.SINK]: {
        shouldSplit: true,
        splitType: 'words',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 0,
            opacity: 1,
        },
        animation: {
            x: 0,
            y: 100,
            opacity: 0,
            ease: 'power3.out',
        }
    },
    [OutTextAnimationType.POP]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: 0,
            scale: 1,
            opacity: 1,
        },
        animation: {
            x: 0,
            y: 0,
            scale: 1.2,
            opacity: 0,
            ease: 'back.out(3)',
        },
    },
    [OutTextAnimationType.DROP]: {
        initial: {
            scale: 1,
            opacity: 1,
            transformOrigin: 'center center',
            x: 0,
            y: 0,
        },
        animation: {
            scale: 4,
            opacity: 0,
            ease: 'power4.out',
            x: 0,
            y: 0,
        }
    },
    [OutTextAnimationType.DECOMPRESS]: {
        initial: {
            opacity: 1,
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0,
            transformOrigin: 'center center',
        },
        animation: {
            opacity: 0,
            scaleX: 3,
            scaleY: 1,
            y: 0,
            x: 0,
            ease: 'power4.out',
        }
    },
    [OutTextAnimationType.FALL]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 0,
            opacity: 1,
            rotation: 0,
        },
        animation: {
            x: 0,
            y: 70,
            opacity: 0,
            rotation: -30,
            ease:'power4.out',
        }
    },
    [OutTextAnimationType.SKID]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 3,
        initial: {
            x: 0,
            y: 0,
            opacity: 1
        },
        animation: {
            opacity: 0,
            x: -50,
            y: 0,
            ease: 'elastic.out(1, 0.3)',
        }
    },
    [OutTextAnimationType.FLIPBOARD]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            x: 0,
            y: 0,
            rotationX: 0,
            opacity: 1,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 0,
            rotationX: -90,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
    [OutTextAnimationType.SCALE]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 1,
        initial: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
        },
        animation: {
            x: 0,
            y: 0,
            opacity: 0,
            scale: 0.5,
            ease: 'elastic.out(1, 0.3)',
        }
    },
    [OutTextAnimationType.DRAGONFLY]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            opacity: 1,
            scale: 1,
            xPercent: 0,
        },
        animation: {
            opacity: 0,
            scale: 0.1,
            xPercent: -50,
            ease: 'power3.out',
        }
    },
    [OutTextAnimationType.BILLBOARD]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            opacity: 1,
            xPercent: 0,
            rotationX: 0,
        },
        animation: {
            opacity: 1,
            xPercent: 100,
            rotationX: 90,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
    [OutTextAnimationType.ROLL]: {
        shouldSplit: true,
        splitType: 'chars',
        staggerFactor: 2,
        initial: {
            y: 0,
            opacity: 1,
            x: 0,
            rotationX: 0,
        },
        animation: {
            opacity: 0,
            x: -100,
            rotationX: 60,
            transformOrigin: '50% 50%',
            ease: 'power3.out',
        }
    },
}

export interface AudioFadeProps {
    audioFadeInDuration?: number;
    audioFadeOutDuration?: number;
}

export enum AudioFadeType {
    IN = 'IN',
    OUT = 'OUT',
}