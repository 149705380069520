import { NewButton } from 'components/UI';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { getModalOptionalData, hideModal } from 'state/modules/modal';
import { CurrentSubscription } from 'state/modules/payment';

import { Colors } from 'styles';
import styles from './styles.module.scss';

interface Props {
    closeButton: JSX.Element;
}

const PlanCanceledModal = (props: Props): JSX.Element => {
  const { closeButton } = props;

  const dispatch = useDispatch();
  const canceledPlan = useSelector(
    getModalOptionalData,
  ) as CurrentSubscription;

  const close = () => {
    dispatch(hideModal());
  };

  const renderDate = () => {
    if (canceledPlan?.billingPeriod) {
      return (
        <p className={styles.PlanCanceledModal__date}>
          {`${moment
            .unix(canceledPlan?.billingPeriod?.billingEndDate)
            .format('DD/MM/YYYY')}`}
        </p>
      );
    }
    return <p className={styles.PlanCanceledModal__date}>---</p>;
  };

  return (
    <div className={styles.PlanCanceledModal}>
      <p className={styles.PlanCanceledModal__title}>
        Your plan is canceled
      </p>
      <p className={styles.PlanCanceledModal__message}>
        Your account will stay active and you are able to use all
        feature until
        <br />
        the end of your current billing period:
      </p>
      {renderDate()}
      <div className={styles.PlanCanceledModal__buttonWrap}>
        <NewButton
          color={Colors.GREY3}
          textColor={Colors.WHITE}
          bordered
          borderRadius={5}
          width={132}
          height={46}
          onClick={close}
        >
          Okay
        </NewButton>
      </div>
      {closeButton}
    </div>
  );
};

export default PlanCanceledModal;
