import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { ProgressBar } from 'components/UI';

import {
    getUploadingsInProgress,
    getUploadingStatuses,
} from 'state/modules/uploading';
import { hideModal } from 'state/modules/modal';

import { UploadingMedia, UploadingStatus } from 'interfaces/uploading';

import styles from './styles.module.scss';

const ProjectUploadingStatus = (): JSX.Element => {
    const dispatch = useDispatch();

    const uploadingStatuses = useSelector(getUploadingStatuses);

    const uploadingMedia = useSelector(getUploadingsInProgress);

    useEffect(() => {
        if (!uploadingStatuses.length) {
            dispatch(hideModal());
        }
    }, [dispatch, uploadingStatuses]);

    const renderMedia = (media: UploadingMedia): JSX.Element => {
        const uploadingStatus = uploadingStatuses.find(
            (item: UploadingStatus) => item.id === media.id
        );

        return (
            <div
                className={styles.ProjectUploadingStatus__video}
                key={media.id}
            >
                <p className={styles.ProjectUploadingStatus__videoTitle}>
                    {media.title}
                </p>
                {uploadingStatus && (
                    <div className={styles.StatusBar__progressWrap}>
                        <ProgressBar
                            currentProgress={uploadingStatus.currentProgress}
                            remainingAmount={uploadingStatus.remainingAmount}
                            withoutOffset
                        />
                    </div>
                )}
            </div>
        );
    };

    const renderUploadingVideos = () =>
        uploadingMedia.map((media) => renderMedia(media));

    return (
        <div className={styles.ProjectUploadingStatus}>
            <p className={styles.ProjectUploadingStatus__title}>Uploading</p>
            {renderUploadingVideos()}
        </div>
    );
};

export default ProjectUploadingStatus;
