import { AxiosResponse } from 'axios';
import { build } from 'search-params';

import store from 'state/store';

import $http from './instance';

class TextToSpeechClient {
    public static async getPreview({
        voiceId,
        language,
        rate,
        voiceStyle,
        text,
    }: {
        voiceId: string;
        language: string;
        rate: string;
        voiceStyle?: string;
        text?: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        const queryItems: any = {voiceId, language, rate};

        if(text) {
            queryItems.text = text;
        }
        if(voiceStyle) {
            queryItems.voiceStyle = voiceStyle;
        }

        let query = build(queryItems);

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/audios/synthesize/preview?${query}`
        );
    }

    public static async getVoicesList(): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/audios/synthesize/list-voices`
        );
    }

    public static async getStatus({
        taskId,
        s3Path,
    }: {
        taskId: string;
        s3Path: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        let query = build({
            taskId,
            s3Path,
        });

        return $http.get(
            `/user/${userId}/workspace/${workspaceId}/audios/synthesize/status?${query}`
        );
    }

    public static async generate({
        voiceId,
        language,
        rate,
        voiceStyle,
        text,
    }: {
        voiceId: string;
        language: string;
        rate: string;
        voiceStyle?: string;
        text: string;
    }): Promise<AxiosResponse> {
        const { user, workspaces, projects } = store.getState();

        const userId = user.userInfo.id;
        const workspaceId = projects.templateWorkspaceId || workspaces.currentWorkspace.id;

        return $http.post(
            `/user/${userId}/workspace/${workspaceId}/audios/synthesize/generate`,
            {
                voiceId,
                language,
                rate,
                voiceStyle,
                text,
            }
        );
    }
}

export default TextToSpeechClient;
