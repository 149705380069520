import { Project } from 'interfaces/projects';
import {
    ProjectSubtitlesActionTypes,
    GetProjectAllSubtitlesListAction,
    SetProjectAllSubtitlesListAction,
    SetProjectAllSubtitlesListActionPayload,
    GetProjectAllSubtitlesListActionPayload,
    AddGeneratedSubtitlesPayload,
    AddGeneratedSubtitlesAction,
    AddLibraryVideoSubtitlesPayload,
    AddLibraryVideoSubtitlesAction,
    DeleteVideoSubtitlesPayload,
    DeleteVideoSubtitlesAction,
    AddOrEditSubtitlePayload,
    AddOrEditSubtitleAction,
    DeleteSubtitlePayload,
    DeleteSubtitleAction,
    MergeSubtitlesPayload,
    MergeSubtitlesAction,
    SplitSubtitlePayload,
    SplitSubtitleAction,
    UploadProjectTranscriptAction,
    UploadProjectTranscriptActionPayload,
    ChangeProjectVideoLanguageAction,
    ChangeProjectVideoLanguageActionPayload,
    SetProjectTranscriptCreationLoadingAction,
    UploadProjectTranscriptSuccessActionPayload,
    UploadProjectTranscriptSuccessAction,
    SetProjectVideosLanguagesAction,
    ProjectVideoLanguages,
    SetScrollableTranscriptIndexAction,
    CancelProjectMediaSourceTranslateAction,
    SetProjectVideosIdsWithTranscriptGenerationAction,
    SetProjectVideosIdsWithTranslateGenerationAction,
    CancelProjectTranslateAction,
    SetProjectSubtitlesTranslateLoadingAction,
    ResetProjectSubtitlesSettingsPayload,
    ResetProjectSubtitlesSettingsAction,
    GenerateProjectVideoTranscriptActionPayload,
    GenerateProjectVideoTranscriptAction,
    ExportSubtitlesAction,
    ExportSubtitlesPayload,
    SetExportSubtitlesStatusAction,
    SetExportSubtitlesStatusPayload,
    SetProjectSubtitlesSearchQueryAction,
    ReplaceProjectSubtitlesTextAction,
    ReplaceProjectSubtitlesTextActionPayload,
    ManualCreateProjectSentenceAction,
    ManualCreateProjectSentenceActionPayload,
    GenerateProjectVideoTranslateAction,
    GenerateProjectVideoTranslateActionPayload,
    ExportVideoSubtitlesPayload,
    ExportVideoSubtitlesAction,
    DeleteProjectSubtitlesLanguageAction,
    DeleteProjectSubtitlesLanguageActionPayload,
    UpdateProjectTranscriptItemAction,
    SetProjectEditableSentenceIdAction,
    CreateProjectTranscriptItemBySpecificTimeAction,
    CreateProjectTranscriptItemBySpecificTimeActionPayload,
    GenerateProjectTranslationForDubbingAction,
    GenerateProjectTranslationForDubbingActionPayload,
    CheckVideoEmptyTranscriptListAction,
    CheckVideoEmptyTranscriptListActionPayload,
    MergeSentencesActionPayload,
    MergeSentencesAction,
    AddOrEditSentenceSuccess,
    SetUnsyncedSubtitlesListAction,
    SetUnsyncedSubtitlesListActionPayload,
    SetDeletedUnsyncedSubtitlesListAction,
    AddProjectSubtitlesSuccess,
    AddProjectSubtitlesFailure,
    GenerateProjectVideoAutoTranslateActionPayload,
    GenerateProjectVideoAutoTranslateAction,
} from './typesNew';
import { TransformedSentence } from '../metadata';

export function getProjectAllSubtitlesList (
    payload: GetProjectAllSubtitlesListActionPayload,
): GetProjectAllSubtitlesListAction {
    return {
        type: ProjectSubtitlesActionTypes.GET_PROJECT_ALL_SUBTITLES_LIST,
        payload,
    };
}

export function setProjectAllSubtitlesListAction (
    payload: SetProjectAllSubtitlesListActionPayload
): SetProjectAllSubtitlesListAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_ALL_SUBTITLES_LIST,
        payload
    };
}

export function setProjectUnsyncedSubtitlesListAction (
    payload: SetUnsyncedSubtitlesListActionPayload
): SetUnsyncedSubtitlesListAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_UNSYNCED_SUBTITLES_LIST,
        payload
    };
}

export function setProjectDeletedUnsyncedSubtitlesListAction (
    payload: string[],
): SetDeletedUnsyncedSubtitlesListAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_DELETED_UNSYNCED_SUBTITLES_LIST,
        payload
    };
}

export function addOrEditSubtitle(
    payload: AddOrEditSubtitlePayload
): AddOrEditSubtitleAction {
    return {
        type: ProjectSubtitlesActionTypes.ADD_OR_EDIT_SUBTITLE,
        payload,
    };
}

export function deleteSubtitle(
    payload: DeleteSubtitlePayload
): DeleteSubtitleAction {
    return {
        type: ProjectSubtitlesActionTypes.DELETE_SUBTITLE,
        payload,
    };
}


export function addGeneratedSubtitles(
    payload: AddGeneratedSubtitlesPayload
): AddGeneratedSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.ADD_GENERATED_SUBTITLES,
        payload,
    };
}

export function addLibraryVideoSubtitles(
    payload: AddLibraryVideoSubtitlesPayload
): AddLibraryVideoSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.ADD_LIBRARY_VIDEO_SUBTITLES,
        payload,
    };
}

export function deleteVideoSubtitles(
    payload: DeleteVideoSubtitlesPayload
): DeleteVideoSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.DELETE_VIDEO_SUBTITLES,
        payload,
    };
}

export function mergeSubtitles(
    payload: MergeSubtitlesPayload
): MergeSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.MERGE_SUBTITLES,
        payload,
    };
}

export function splitSubtitle(
    payload: SplitSubtitlePayload
): SplitSubtitleAction {
    return {
        type: ProjectSubtitlesActionTypes.SPLIT_SUBTITLE,
        payload,
    };
}

// other actions

export function exportSubtitles(
    payload: ExportSubtitlesPayload
): ExportSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.EXPORT_SUBTITLES,
        payload,
    };
}

export function setExportSubtitlesStatus(
    payload: SetExportSubtitlesStatusPayload
): SetExportSubtitlesStatusAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_EXPORT_SUBTITLES_STATUS,
        payload,
    };
}

export function exportVideoSubtitles(
    payload: ExportVideoSubtitlesPayload
): ExportVideoSubtitlesAction {
    return {
        type: ProjectSubtitlesActionTypes.EXPORT_VIDEO_SUBTITLES,
        payload,
    };
}

export function changeProjectVideoLanguage(
    payload: ChangeProjectVideoLanguageActionPayload
): ChangeProjectVideoLanguageAction {
    return {
        type: ProjectSubtitlesActionTypes.CHANGE_PROJECT_VIDEO_LANGUAGE,
        payload,
    };
}

export function setProjectVideosLanguages(
    payload: ProjectVideoLanguages[]
): SetProjectVideosLanguagesAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_LANGUAGES,
        payload,
    };
}

export function setProjectSubtitlesSearchQuery(
    payload: string
): SetProjectSubtitlesSearchQueryAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_SEARCH_QUERY,
        payload,
    };
}

export function replaceProjectSubtitlesText(
    payload: ReplaceProjectSubtitlesTextActionPayload
): ReplaceProjectSubtitlesTextAction {
    return {
        type: ProjectSubtitlesActionTypes.REPLACE_SUBTITLES_TEXT,
        payload,
    };
}

export function resetProjectSubtitlesSettings(
    payload?: ResetProjectSubtitlesSettingsPayload
): ResetProjectSubtitlesSettingsAction {
    return {
        type: ProjectSubtitlesActionTypes.RESET_PROJECT_SUBTITLES_SETTINGS,
        payload,
    };
}

export function uploadProjectTranscript(
    payload: UploadProjectTranscriptActionPayload
): UploadProjectTranscriptAction {
    return {
        type: ProjectSubtitlesActionTypes.UPLOAD_TRANSCRIPT,
        payload,
    };
}

export function uploadProjectTranscriptSuccess(
    payload: UploadProjectTranscriptSuccessActionPayload
): UploadProjectTranscriptSuccessAction {
    return {
        type: ProjectSubtitlesActionTypes.UPLOAD_TRANSCRIPT_SUCCESS,
        payload,
    };
}

export function manualCreateProjectSentence(
    payload: ManualCreateProjectSentenceActionPayload
): ManualCreateProjectSentenceAction {
    return {
        type: ProjectSubtitlesActionTypes.MANUAL_CREATE_PROJECT_SENTENCE,
        payload,
    };
}

export function setProjectTranscriptCreationLoading(
    payload: boolean
): SetProjectTranscriptCreationLoadingAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_TRANSCRIPT_CREATION_LOADING,
        payload,
    };
}

export function setScrollableTranscriptIndex(
    payload: number | null
): SetScrollableTranscriptIndexAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_SCROLLABLE_TRANSCRIPT_INDEX,
        payload,
    };
}

export function setProjectVideosIdsWithTranscriptGeneration(
    payload: string[]
): SetProjectVideosIdsWithTranscriptGenerationAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSCRIPT_GENERATION,
        payload,
    };
}

export function setProjectVideosIdsWithTranslateGeneration(
    payload: string[]
): SetProjectVideosIdsWithTranslateGenerationAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_VIDEOS_IDS_WITH_TRANSLATE_GENERATION,
        payload,
    };
}

export function generateProjectVideoTranscript(
    payload: GenerateProjectVideoTranscriptActionPayload
): GenerateProjectVideoTranscriptAction {
    return {
        type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSCRIPT,
        payload,
    };
}

export function generateProjectVideoTranslate(
    payload: GenerateProjectVideoTranslateActionPayload
): GenerateProjectVideoTranslateAction {
    return {
        type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_TRANSLATE,
        payload,
    };
}

export function generateProjectVideoAutoTranslate(
    payload: GenerateProjectVideoAutoTranslateActionPayload
): GenerateProjectVideoAutoTranslateAction {
    return {
        type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_VIDEO_AUTO_TRANSLATE,
        payload,
    };
}

export function cancelProjectMediaSourceTranslate(
    payload: string
): CancelProjectMediaSourceTranslateAction {
    return {
        type: ProjectSubtitlesActionTypes.CANCEL_PROJECT_MEDIA_SOURCE_TRANSLATE,
        payload,
    };
}

export function deleteProjectSubtitlesLanguage(
    payload: DeleteProjectSubtitlesLanguageActionPayload
): DeleteProjectSubtitlesLanguageAction {
    return {
        type: ProjectSubtitlesActionTypes.DELETE_PROJECT_SUBTITLES_LANGUAGE,
        payload,
    };
}

export function cancelProjectTranslate(): CancelProjectTranslateAction {
    return {
        type: ProjectSubtitlesActionTypes.CANCEL_PROJECT_TRANSLATE,
    };
}

export function updateProjectTranscriptItem(
    payload: TransformedSentence
): UpdateProjectTranscriptItemAction {
    return {
        type: ProjectSubtitlesActionTypes.UPDATE_PROJECT_TRANSCRIPT_ITEM,
        payload,
    };
}

export function setProjectEditableSentenceId(
    payload: string
): SetProjectEditableSentenceIdAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_EDITABLE_SENTENCE_ID,
        payload,
    };
}

export function createProjectTranscriptItemBySpecificTime(
    payload: CreateProjectTranscriptItemBySpecificTimeActionPayload
): CreateProjectTranscriptItemBySpecificTimeAction {
    return {
        type: ProjectSubtitlesActionTypes.CREATE_PROJECT_TRANSCRIPT_BY_SPECIFIC_TIME,
        payload,
    };
}

export function setProjectSubtitlesTranslateLoading(
    payload: boolean
): SetProjectSubtitlesTranslateLoadingAction {
    return {
        type: ProjectSubtitlesActionTypes.SET_PROJECT_SUBTITLES_TRANSLATE_LOADING,
        payload,
    };
}

export function generateProjectTranslationForDubbing(
    payload: GenerateProjectTranslationForDubbingActionPayload
): GenerateProjectTranslationForDubbingAction {
    return {
        type: ProjectSubtitlesActionTypes.GENERATE_PROJECT_TRANSLATION_FOR_DUBBING,
        payload,
    };
}


export function checkVideoEmptyTranscriptList(
    payload: CheckVideoEmptyTranscriptListActionPayload[]
): CheckVideoEmptyTranscriptListAction {
    return {
        type: ProjectSubtitlesActionTypes.CHECK_EMPTY_VIDEO_TRANSCRIPT_LIST,
        payload,
    };
}

export function mergeSentences(
    payload: MergeSentencesActionPayload
): MergeSentencesAction {
    return {
        type: ProjectSubtitlesActionTypes.MERGE_SENTENCES,
        payload,
    };
}

// ///////////////////
export function addOrEditSentencesSuccess(
    // payload: Project
): AddOrEditSentenceSuccess {
    return {
        type: ProjectSubtitlesActionTypes.ADD_OR_EDIT_SENTENCES_SUCCESS,
        // payload,
    };
}

export function addProjectSubtitlesSuccess(
): AddProjectSubtitlesSuccess {
    return {
        type: ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_SUCCESS,
        // payload,
    };
}

export function addProjectSubtitlesFailure(
): AddProjectSubtitlesFailure {
    return {
        type: ProjectSubtitlesActionTypes.ADD_PROJECT_SUBTITLES_FAILURE,
        // payload,
    };
}
