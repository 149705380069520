export const secondsToHms = (d: number): string => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? `${h} h ` : '';
  const mDisplay = m > 0 ? `${m} min ` : '';
  const sDisplay = s > 0 ? `${s} sec` : '';
  return hDisplay + mDisplay + sDisplay;
};

export const secondsToHHMMSS = (d: number): string => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h >= 10 ? h : `0${h}`;
  const mDisplay = m >= 10 ? m : `0${m}`;
  const sDisplay = s >= 10 ? s : `0${s}`;

  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const getFileNameByKey = (key: string): string => {
  const index = key.lastIndexOf('/');

  const cuttedName = key.substring(index + 1);

  return cuttedName;
};
