import Cards, { Focused } from 'react-credit-cards-2';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import Loader from 'react-loader-spinner';

import { Icon, LoaderWithOverlay } from 'components/UI';

import {
    deleteCustomerCard,
    getCardIdToRemove,
    getSetPreferredCardLoading,
} from 'state/modules/payment';

import './styles.css';
import styles from './styles.module.scss';
import 'react-credit-cards-2/dist/lib/styles-compiled.css';

interface Props {
    cvc: string | number;
    expiry: string | number;
    focused: Focused;
    name: string;
    number: string | number;
    preview: boolean;
    isPreferred: boolean;
    isSelected?: boolean;
    onClick: (id: string) => void;
    header?: JSX.Element | null;
    size?: string;
    id: string;
    removalAvailable?: boolean;
    cardType: string;
}

const CreditCard = (props: Props): JSX.Element => {
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
        useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const {
        cvc,
        expiry,
        focused,
        name,
        number,
        preview,
        isPreferred,
        isSelected,
        onClick,
        header,
        size,
        id,
        removalAvailable,
        cardType,
    } = props;

    const isLoading = useSelector(getSetPreferredCardLoading);
    const cardIdToRemove = useSelector(getCardIdToRemove);

    const isDeleting = cardIdToRemove === id;

    const ref = useRef(null);

    const dispatch = useDispatch();

    const handleDeleteCard = () => {
        dispatch(deleteCustomerCard(id));
    };

    const handleDelete = () => {
        setDeleteConfirmationVisible(true);
    };

    const declineDelete = () => {
        setDeleteConfirmationVisible(false);
    };

    const handleMouseOver = () => {
        setIsFocused(true);
    };

    const handleMouseLeave = () => {
        setIsFocused(false);
    };

    const renderDeleteButton = () =>
        !deleteConfirmationVisible &&
        !isDeleting &&
        isFocused &&
        !isLoading &&
        removalAvailable ? (
            <button
                onClick={handleDelete}
                className={styles.CreditCard__deleteButton}
            >
                <Icon name="trash" color="#000000" size={18} />
            </button>
        ) : null;

    const renderDeletingLoader = () => (
        <div className={styles.CreditCard__deleteLoader}>
            <Loader type="Oval" color="#fd0b50" height={20} width={20} />
            <p className={styles.CreditCard__deleteLoaderTitle}>Deleting...</p>
        </div>
    );

    const renderDeleteConfirmation = () => {
        if (deleteConfirmationVisible) {
            return (
                <div
                    className={[
                        styles.CreditCard__deleteConfirmation,
                        size === 'large'
                            ? styles.CreditCard__deleteConfirmation_large
                            : null,
                        size === 'medium'
                            ? styles.CreditCard__deleteConfirmation_medium
                            : null,
                        size === 'small'
                            ? styles.CreditCard__deleteConfirmation_small
                            : null,
                    ].join(' ')}
                >
                    {isDeleting ? (
                        renderDeletingLoader()
                    ) : (
                        <>
                            <p
                                className={
                                    styles.CreditCard__deleteConfirmationTitle
                                }
                            >
                                Are you sure you want to delete the card?
                            </p>
                            <div
                                className={
                                    styles.CreditCard__deleteConfirmationActions
                                }
                            >
                                <button
                                    className={
                                        styles.CreditCard__deleteConfirmationAction
                                    }
                                    onClick={handleDeleteCard}
                                >
                                    <Icon
                                        name="check"
                                        size={16}
                                        color="#ffffff"
                                    />
                                </button>
                                <button
                                    className={
                                        styles.CreditCard__deleteConfirmationAction
                                    }
                                    onClick={declineDelete}
                                >
                                    <Icon
                                        name="close"
                                        size={16}
                                        color="#ffffff"
                                    />
                                </button>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    };

    const renderLoader = () =>
        isLoading && isPreferred ? <LoaderWithOverlay color="#fd0b50" /> : null;

    return (
        <div
            className={[
                styles.CreditCard,
                size === 'large' ? 'CreditCard_large' : null,
                size === 'medium' ? 'CreditCard_medium' : null,
                size === 'small' ? 'CreditCard_small' : null,
            ].join(' ')}
            ref={ref}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
        >
            {header && !isLoading ? header : null}
            <button
                onClick={() => onClick(id)}
                className={[
                    styles.CreditCard__container,
                    isSelected ? styles.CreditCard__container_selected : null,
                    size === 'large'
                        ? styles.CreditCard__container_large
                        : null,
                    size === 'medium'
                        ? styles.CreditCard__container_medium
                        : null,
                    size === 'small'
                        ? styles.CreditCard__container_small
                        : null,
                ].join(' ')}
                disabled={isLoading}
            >
                {renderLoader()}
                <Cards
                    key={number}
                    cvc={cvc}
                    expiry={expiry}
                    focused={focused}
                    name={name}
                    number={number}
                    preview={preview}
                    issuer={cardType}
                />
            </button>
            {renderDeleteButton()}
            {renderDeleteConfirmation()}
        </div>
    );
};

export default CreditCard;
