import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import store from 'state/store';

import * as uploadingActions from 'state/modules/uploading/actions';
import * as modalActions from 'state/modules/modal/actions';
import * as appActions from 'state/modules/app/actions';
import * as videosActions from 'state/modules/media/actions';
import * as videoExplorerActions from 'state/modules/videoExplorer/actions';
import * as projectUploadActions from 'state/modules/projectUpload/actions';

import {
    getUploadingStatuses,
    getUploadingVideos,
    UploadingActionTypes,
    ChangeUploadingVideoInfoAction,
    ConfirmUploadingAction,
    UpdateUploadingStatusAction,
} from 'state/modules/uploading';

import {
    UploadingStatus,
    UploadingMedia,
    UploadingMediaDurationItem,
    UploadMediaType,
} from 'interfaces/uploading';

import { NotificationTypes, showNotification } from 'utils/notifications';
import {
    getTransformedUploadingFileName,
    getTrasformedMetadaForMediaFileUpload,
    getUploadingFileContentType,
} from 'utils/upload';

import VideosClient from 'services/api/videos';
import { AxiosResponse } from 'axios';
import { Workspace } from 'interfaces/workspaces';
import { googleAnalytics } from 'services/api/googleAnalytics';
import { VideoUploadTypes } from 'interfaces/upload';
import { amplitudeAnalytics } from 'services/api/amplitudeAnalytics';
import { getUploadingMediaDurationItems } from './selectors';
import {
    ChangeUploadingMediaDurationAction,
    UploadVideoFromExternalSourceAction,
} from './types';
import { ModalEvent, ModalType } from '../modal';
import { CurrentSubscription, getCurrentUserPlan } from '../payment';
import { getCurrentWorkspaceInfo } from '../workspaces';

const windowFork = window;

function* updateUploadingStatus(data: UploadingStatus): Generator {
    const uploadingStatuses = (yield select(
        getUploadingStatuses
    )) as Array<UploadingStatus>;
    const { currentProgress, remainingAmount, id, error } = data;

    const updatedStatuses = uploadingStatuses.map((item: UploadingStatus) => {
        if (item.id === id) {
            return {
                ...item,
                currentProgress:
                    currentProgress < item.currentProgress
                        ? item.currentProgress
                        : currentProgress,
                remainingAmount:
                    remainingAmount > item.remainingAmount
                        ? item.remainingAmount
                        : remainingAmount,
                error,
            };
        }

        return item;
    });

    yield put(uploadingActions.setUploadingStatuses(updatedStatuses));
}

function* filterUploadingStatusesAndVideos(id: string): Generator {
    const uploadingMedia = (yield select(
        getUploadingVideos
    )) as Array<UploadingMedia>;
    const uploadingStatuses = (yield select(
        getUploadingStatuses
    )) as Array<UploadingStatus>;
    const uploadingMediaDurationItems = (yield select(
        getUploadingMediaDurationItems
    )) as Array<UploadingMediaDurationItem>;

    const filteredStatuses = uploadingStatuses.filter(
        (item: UploadingStatus) => item.id !== id
    );
    const filteredMedia = uploadingMedia.filter(
        (item: UploadingMedia) => item.id !== id
    );
    const filteredUploadingMediaDurationItems =
        uploadingMediaDurationItems.filter(
            (item: UploadingMediaDurationItem) => item.id !== id
        );

    yield put(uploadingActions.setUploadingVideos(filteredMedia));
    yield put(uploadingActions.setUploadingStatuses(filteredStatuses));
    yield put(
        uploadingActions.setUploadingMediaDurationItems(
            filteredUploadingMediaDurationItems
        )
    );
}

function* handleUpdateUploadingStatus(
    action: UpdateUploadingStatusAction
): Generator {
    const { currentProgress, id } = action.payload;

    if (currentProgress === 100) {
        yield call(updateUploadingStatus, {
            ...action.payload,
        });

        yield delay(8500);

        yield call(filterUploadingStatusesAndVideos, id);

        yield delay(4000);

        yield put(videosActions.getVideoList());
        yield put(videoExplorerActions.getFilteredAnalyzerVideoList());
    } else {
        yield call(updateUploadingStatus, {
            ...action.payload,
        });
    }
}

function uploadCallback(
    progress: { loaded: number; total: number },
    id: string
): void {
    const currentProgress = Math.floor(
        (progress.loaded / progress.total) * 100
    );
    const remainingAmount = 100 - currentProgress;

    store.dispatch(
        uploadingActions.updateUploadingStatus({
            id,
            currentProgress,
            remainingAmount,
        })
    );

    if (currentProgress === 100) {
        store.dispatch(projectUploadActions.uploadProjectAudioSuccess());
    }
}

export function* uploadAudio(audioFile: UploadingMedia): Generator {
    const currentWorkspace = (yield select(
        getCurrentWorkspaceInfo
    )) as Workspace;

    try {
        const contentType = getUploadingFileContentType(audioFile.title);
        const fileKey = [
            'workspace',
            currentWorkspace.id,
            'media',
            audioFile.id,
            'audio',
            audioFile.title,
        ].join('/');

        yield call(Storage.put as any, fileKey, audioFile.file, {
            level: 'private',
            contentType,
            bucket: `${windowFork.config.REACT_APP_DOWNLOAD_TRAILER_BUCKET}`,
            progressCallback: (progress: { loaded: number; total: number }) =>
                uploadCallback(progress, audioFile.id),
        });
    } catch (error) {
        console.log({ error });

        showNotification(
            NotificationTypes.error,
            `Uploading failed. Something went wrong`
        );
    }
}

function* uploadVideo(
    video: UploadingMedia,
    showStatusBar?: boolean,
    fps?: number
): Generator {
    const currentWorkspace = (yield select(
        getCurrentWorkspaceInfo
    )) as Workspace;

    try {
        const fileName = video.file.name;

        if (showStatusBar) {
            showNotification(
                NotificationTypes.info,
                `${video.title} is uploading`
            );
        }

        yield delay(6500);

        if (showStatusBar) {
            yield put(appActions.setStatusBarActiveStatus(true));
        }

        const contentType = getUploadingFileContentType(fileName);
        const fileKey = getTransformedUploadingFileName(
            video,
            currentWorkspace.id
        );

        const transformedMetadata: any = getTrasformedMetadaForMediaFileUpload(
            video,
            fps
        );

        const upload = Storage.put(fileKey, video.file, {
            level: 'private',
            contentType,
            metadata: transformedMetadata,
            resumable: true,
            progressCallback: (progress: { loaded: number; total: number }) => {
                uploadCallback(progress, video.id);
            },
            // completeCallback: () => {
            //     showNotification(
            //         NotificationTypes.success,
            //         `${video.title} Successfully uploaded`
            //     );
            // },
            errorCallback: (error: any) => {
                console.log('UPLOAD ERROR', error);
                setTimeout(() => {
                    console.log('RESUME UPLOAD');
                    upload.resume();
                }, 1000);
            },
        });
    } catch (error) {
        console.log({ error });
        if (
            (error as any)?.message.includes('String contains non ISO-8859-1')
        ) {
            showNotification(
                NotificationTypes.error,
                `Please check your file name. It might contain a symbol our system doesn’t recognise`
            );

            yield put(
                uploadingActions.updateUploadingStatus({
                    id: video.id,
                    currentProgress: 0,
                    remainingAmount: 0,
                    error: 'Please check your file name. It might contain a symbol our system doesn’t recognise',
                })
            );
        } else {
            showNotification(
                NotificationTypes.error,
                `Uploading failed. Something went wrong`
            );
        }
    }
}

function* handleConfirmUploading(action: ConfirmUploadingAction): Generator {
    const { media, showNotifications, fps, mediaType } = action.payload;

    try {
        yield all(
            media.map((mediaFile: UploadingMedia) =>
                call(
                    mediaType === UploadMediaType.VIDEO
                        ? uploadVideo
                        : uploadAudio,
                    mediaFile,
                    showNotifications,
                    fps
                )
            )
        );
    } catch (error) {
        console.log({ error });
    }
}

function* handleChangeUploadingVideoInfo(
    action: ChangeUploadingVideoInfoAction
): Generator {
    const { value, videoId, field } = action.payload;
    const uploadingVideos = (yield select(
        getUploadingVideos
    )) as Array<UploadingMedia>;

    const updatedVideos = uploadingVideos.map((video: UploadingMedia) => {
        if (video.id === videoId) {
            return {
                ...video,
                [field]: value,
            };
        }
        return video;
    });

    yield put(uploadingActions.setUploadingVideos(updatedVideos));
}

function* handleChangeUploadingMediaDurationItems(
    action: ChangeUploadingMediaDurationAction
): Generator {
    const data = action.payload;

    const uploadingMediaDurationItems = (yield select(
        getUploadingMediaDurationItems
    )) as Array<UploadingMediaDurationItem>;

    const updatedUploadingMediaDurationItems = [
        ...uploadingMediaDurationItems,
        data,
    ];

    yield put(
        uploadingActions.setUploadingMediaDurationItems(
            updatedUploadingMediaDurationItems
        )
    );
}

export function* handleUploadVideoFromExternalSource(
    action: UploadVideoFromExternalSourceAction
): Generator {
    yield put(uploadingActions.uploadProjectVideoFromExternalSourceStart());

    const { data, isProjectUpload } = action.payload;

    try {
        const res = (yield call(
            VideosClient.uploadExternalVideo,
            data
        )) as AxiosResponse;

        if (data.type === VideoUploadTypes.STREAMABLE_LINK) {
            googleAnalytics.uploadMedia({
                media_type: 'video',
                media_source: 'Link',
            });

            amplitudeAnalytics.mediaUploaded({
                media_type: 'video',
                media_source: 'Link',
            });
        }

        const { videoId } = res.data.content;

        yield delay(1000);

        yield put(videosActions.getVideoList());

        if (isProjectUpload) {
            yield put(
                modalActions.showModal(
                    ModalType.UPLOAD_FROM_EXTERNAL_SOURCE_STATUS,
                    ModalEvent.UPLOAD_FROM_EXTERNAL_SOURCE_STATUS,
                    '',
                    videoId
                )
            );
        }

        yield put(
            uploadingActions.uploadProjectVideoFromExternalSourceSuccess()
        );
    } catch (error) {
        console.log({ error });
        if(error.response.status === 405) {
            showNotification(
                NotificationTypes.error,
                `Sorry. We do not support this kind of a link.`
            );
        }

        showNotification(
            NotificationTypes.error,
            `An error occurred when uploading the video.`
        );

        yield put(uploadingActions.uploadProjectVideoFromExternalSourceFail());
    }
}

export function* uploadingSaga(): Generator {
    yield takeEvery(
        UploadingActionTypes.UPDATE_UPLOADING_STATUS,
        handleUpdateUploadingStatus
    );
    yield takeEvery(
        UploadingActionTypes.CONFIRM_UPLOADING,
        handleConfirmUploading
    );
    yield takeEvery(
        UploadingActionTypes.CHANGE_UPLOADING_VIDEO_INFO,
        handleChangeUploadingVideoInfo
    );
    yield takeEvery(
        UploadingActionTypes.CHANGE_UPLOADING_MEDIA_DURATION,
        handleChangeUploadingMediaDurationItems
    );
    yield takeEvery(
        UploadingActionTypes.UPLOAD_VIDEO_FROM_EXTERNAL_SOURCE,
        handleUploadVideoFromExternalSource
    );
}
