import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import isEqual from 'lodash.isequal';

import {
  JobsActionTypes,
  getVideoJobsList,
  GetVideoJobsAction,
} from 'state/modules/jobs';

import { GetJobsByVideoIdResponse } from 'models/jobs';

import * as jobsActions from 'state/modules/jobs/actions';

import JobsClient from 'services/api/jobs';

function* handleGetJobs(): Generator {
  try {
    yield call(JobsClient.getJobs);
  } catch (error) {
    console.log({ error });
  }
}

export function* handleGetJobsByVideoId(action: GetVideoJobsAction): Generator {
  try {
    const oldJobs = yield select(getVideoJobsList);

    const res = (yield call(
      JobsClient.getJobsByVideoId,
      action.payload,
    )) as AxiosResponse<GetJobsByVideoIdResponse>;

    if (res.data) {
      const newJobs = res.data.content;

      const isJobsEqual = isEqual(newJobs, oldJobs);

      if (!isJobsEqual) {
        yield put(jobsActions.setVideoJobs(newJobs));
      }
    }
  } catch (error) {
    console.log({ error });
  }
}

export function* jobsSaga(): Generator {
  yield takeLatest(JobsActionTypes.GET_JOBS, handleGetJobs);
  yield takeLatest(
    JobsActionTypes.GET_JOBS_BY_VIDEO_ID,
    handleGetJobsByVideoId,
  );
}
