import { IconProps } from './index';

const QuestionIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 20;

    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_418_17478)">
                <path
                    d="M10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20ZM6.63281 6.45703C6.94141 5.58594 7.76953 5 8.69531 5H10.9727C12.3359 5 13.4375 6.10547 13.4375 7.46484C13.4375 8.34766 12.9648 9.16406 12.1992 9.60547L10.9375 10.3281C10.9297 10.8359 10.5117 11.25 10 11.25C9.48047 11.25 9.0625 10.832 9.0625 10.3125V9.78516C9.0625 9.44922 9.24219 9.14062 9.53516 8.97266L11.2656 7.98047C11.4492 7.875 11.5625 7.67969 11.5625 7.46875C11.5625 7.14062 11.2969 6.87891 10.9727 6.87891H8.69531C8.5625 6.87891 8.44531 6.96094 8.40234 7.08594L8.38672 7.13281C8.21484 7.62109 7.67578 7.875 7.19141 7.70312C6.70703 7.53125 6.44922 6.99219 6.62109 6.50781L6.63672 6.46094L6.63281 6.45703ZM11.25 13.75C11.25 14.4414 10.6914 15 10 15C9.30859 15 8.75 14.4414 8.75 13.75C8.75 13.0586 9.30859 12.5 10 12.5C10.6914 12.5 11.25 13.0586 11.25 13.75Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_418_17478">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default QuestionIcon;
