import { IconProps } from './index';

const PlusIcon = ({ color, size }: IconProps): JSX.Element => {
    let height = 17;
    if (size) {
        height = size;
    }

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Rev-5"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="07-Nova-Enterprise---Multiple-Search"
                    transform="translate(-1179.000000, -41.000000)"
                    fill={color}
                    fillRule="nonzero"
                >
                    <g
                        id="Right-Side-Panel"
                        transform="translate(1163.000000, 28.000000)"
                    >
                        <g
                            id="Add-Videos-Button"
                            transform="translate(1.000000, 0.000000)"
                        >
                            <path
                                d="M15.7179717,21.7179717 L22.2820283,21.7179717 L22.2820283,28.2820283 C22.2820283,28.6785531 22.6034752,29 23,29 C23.3965248,29 23.7179717,28.6785531 23.7179717,28.2820283 L23.7179717,21.7179717 L30.2820283,21.7179717 C30.6785531,21.7179717 31,21.3965248 31,21 C31,20.6034752 30.6785531,20.2820283 30.2820283,20.2820283 L23.7179717,20.2820283 L23.7179717,13.7179717 C23.7179717,13.3214469 23.3965248,13 23,13 C22.6034752,13 22.2820283,13.3214469 22.2820283,13.7179717 L22.2820283,20.2820283 L15.7179717,20.2820283 C15.3214469,20.2820283 15,20.6034752 15,21 C15,21.3965248 15.3214469,21.7179717 15.7179717,21.7179717 Z"
                                id="Add-Copy"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PlusIcon;
