import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 0.967;

const ProfileIcon = ({ size, color }: IconProps): JSX.Element => {
  let height = 31;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5357 24.2787L6.06004 26.7201C5.7974 26.8633 5.56136 27.0393 5.34174 27.2312C7.95117 29.4314 11.3197 30.7587 15 30.7587C18.6533 30.7587 22.0002 29.4512 24.6023 27.281C24.3623 27.0784 24.1019 26.8961 23.8133 26.7523L19.0206 24.3563C18.4014 24.0467 18.0102 23.4138 18.0102 22.7216V20.8412C18.1449 20.6878 18.2989 20.4908 18.4636 20.2582C19.1168 19.3355 19.611 18.3206 19.9534 17.2559C20.5682 17.0663 21.021 16.4985 21.021 15.8238V13.8167C21.021 13.3751 20.8246 12.9806 20.5195 12.7044V9.80288C20.5195 9.80288 21.1155 5.2876 15.0006 5.2876C8.8857 5.2876 9.48174 9.80288 9.48174 9.80288V12.7044C9.17608 12.9806 8.98023 13.3751 8.98023 13.8167V15.8238C8.98023 16.3525 9.25815 16.8178 9.67419 17.0867C10.1757 19.2699 11.4889 20.8412 11.4889 20.8412V22.6752C11.4883 23.3431 11.1227 23.9584 10.5357 24.2787Z"
        fill={color}
      />
      <path
        d="M15.2565 0.761023C6.97362 0.619513 0.143806 7.21952 0.00229619 15.5023C-0.0780812 20.1988 2.01513 24.4197 5.34796 27.2267C5.56588 27.0365 5.79966 26.8622 6.05947 26.7207L10.5351 24.2793C11.1221 23.959 11.4878 23.3437 11.4878 22.6746V20.8407C11.4878 20.8407 10.174 19.2693 9.67306 17.0861C9.25758 16.8173 8.97909 16.3525 8.97909 15.8233V13.8161C8.97909 13.3746 9.17551 12.9801 9.4806 12.7039V9.80235C9.4806 9.80235 8.88456 5.28706 14.9995 5.28706C21.1144 5.28706 20.5183 9.80235 20.5183 9.80235V12.7039C20.824 12.9801 21.0199 13.3746 21.0199 13.8161V15.8233C21.0199 16.498 20.567 17.0657 19.9523 17.2554C19.6099 18.3201 19.1157 19.335 18.4625 20.2576C18.2978 20.4903 18.1438 20.6873 18.0091 20.8407V22.721C18.0091 23.4133 18.4002 24.0467 19.0195 24.3557L23.8121 26.7518C24.0997 26.8956 24.3595 27.0773 24.5989 27.2793C27.831 24.5839 29.9191 20.5537 29.9967 16.0152C30.1393 7.73235 23.5399 0.902532 15.2565 0.761023Z"
        fill="none"
      />
    </svg>
  );
};

export default ProfileIcon;
