import { calculateSideByRatio } from 'utils';
import { IconProps } from './index';

const SIDES_RATIO = 18 / 20;

const ClockIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = 18;

    if (size) {
        height = size;
    }

    const width = calculateSideByRatio(height, SIDES_RATIO);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.08379 0C6.39198 0 5.83307 0.558594 5.83307 1.25C5.83307 1.94141 6.39198 2.5 7.08379 2.5H7.70914V3.84375C3.81238 4.44531 0.8302 7.8125 0.8302 11.875C0.8302 16.3633 4.46901 20 8.95986 20C13.4507 20 17.0895 16.3633 17.0895 11.875C17.0895 10.2422 16.6088 8.72266 15.7802 7.45312L16.7221 6.51172C17.2107 6.02344 17.2107 5.23047 16.7221 4.74219C16.2336 4.25391 15.4401 4.25391 14.9516 4.74219L14.1073 5.58594C13.0091 4.6875 11.6763 4.07031 10.2106 3.84375V2.5H10.8359C11.5277 2.5 12.0867 1.94141 12.0867 1.25C12.0867 0.558594 11.5277 0 10.8359 0H8.95986H7.08379ZM9.8979 7.5V12.5C9.8979 13.0195 9.47969 13.4375 8.95986 13.4375C8.44003 13.4375 8.02182 13.0195 8.02182 12.5V7.5C8.02182 6.98047 8.44003 6.5625 8.95986 6.5625C9.47969 6.5625 9.8979 6.98047 9.8979 7.5Z"
                fill={color}
            />
        </svg>
    );
};

export default ClockIcon;
