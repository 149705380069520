import { IconProps } from '../index';

const TextCompressIcon = ({ size, color }: IconProps): JSX.Element => {
    let height = size || 42;

    return (
        <svg
            width={height}
            height={height}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.71 25.433h-1.086l-1.349-2.924H7.671l-1.337 2.924H5.2l4.13-9.083h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662zM36.86 23.697a3.312 3.312 0 0 1-1.349 1.146 4.096 4.096 0 0 1-1.79.393c-1.002 0-1.842-.33-2.518-.99-.669-.66-1.003-1.468-1.003-2.423 0-.955.338-1.77 1.015-2.447.676-.684 1.551-1.026 2.625-1.026.637 0 1.222.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.58-.756-1.34-1.134-2.28-1.134-.74 0-1.36.239-1.861.716-.502.47-.752 1.054-.752 1.754s.247 1.301.74 1.803c.493.5 1.118.752 1.874.752.907 0 1.67-.382 2.291-1.146l.764.525z"
                fill="#F0F0F2" />
            <path
                d="M34.86 23.697a3.312 3.312 0 0 1-1.349 1.146 4.096 4.096 0 0 1-1.79.393c-1.003 0-1.842-.33-2.518-.99-.669-.66-1.003-1.468-1.003-2.423 0-.955.338-1.77 1.015-2.447.676-.684 1.551-1.026 2.625-1.026.637 0 1.222.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.58-.756-1.34-1.134-2.28-1.134-.74 0-1.36.239-1.861.716-.502.47-.752 1.054-.752 1.754s.247 1.301.74 1.803c.493.5 1.118.752 1.874.752.907 0 1.67-.382 2.291-1.146l.764.525zM15.71 25.433h-1.086l-1.349-2.924H9.671l-1.337 2.924H7.2l4.13-9.083h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662z"
                fill="#D0D0D0" />
            <path
                d="M17.71 25.433h-1.086l-1.349-2.924h-3.604l-1.337 2.924H9.2l4.13-9.083h.31l4.07 9.083zm-2.9-3.963-1.325-2.948-1.337 2.948h2.662zM19.73 19.883c.676-.748 1.487-1.122 2.434-1.122.947 0 1.743.338 2.387 1.014.653.669.979 1.476.979 2.423a3.36 3.36 0 0 1-.99 2.435c-.661.676-1.46 1.014-2.4 1.014-.93 0-1.734-.405-2.41-1.217v1.003h-.967V16.48h.966v3.402zm2.375 4.81c.676 0 1.249-.251 1.718-.752.478-.502.716-1.086.716-1.755 0-.676-.23-1.265-.692-1.766a2.255 2.255 0 0 0-1.73-.752c-.693 0-1.274.235-1.743.704-.461.47-.692 1.062-.692 1.778 0 .709.235 1.31.704 1.803.47.493 1.042.74 1.719.74zM33.2 24.108a3.312 3.312 0 0 1-1.349 1.146 4.096 4.096 0 0 1-1.79.393c-1.003 0-1.842-.33-2.518-.99-.669-.66-1.003-1.468-1.003-2.423 0-.955.338-1.77 1.015-2.447.676-.684 1.551-1.026 2.625-1.026.637 0 1.222.14 1.755.418a3.1 3.1 0 0 1 1.265 1.17l-.776.489c-.58-.756-1.34-1.134-2.28-1.134-.74 0-1.36.238-1.861.716-.502.47-.752 1.054-.752 1.754s.246 1.301.74 1.803c.493.5 1.117.751 1.873.751.908 0 1.671-.382 2.292-1.145l.764.525z"
                fill="#525253" />
            <path d="M39 22h-4m0 0 2 2m-2-2 2-2M3 22h4m0 0-2-2m2 2-2 2" stroke="#525253" strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    );
};

export default TextCompressIcon;