import { createSelector } from 'reselect';
import sortBy from 'lodash.sortby';
import groupBy from 'lodash.groupby';
import { State } from '../index';
import { ProjectSubtitlesState } from './typesNew';
import { TransformedSentence } from '../metadata';
import { ProjectsState } from '../projects';

export const getProjectsState = (state: State): ProjectsState => state.projects;

export const getProjectSubtitlesState = (state: State): ProjectSubtitlesState =>
    state.projectSubtitlesNew;

export const getProjectSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectCurrentSubtitlesList
);

export const getProjectAllCurrentSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => [...state.projectCurrentSubtitlesList, ...state.projectUnsyncedSubtitlesList]
);

export const getProjectUnsyncedSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectUnsyncedSubtitlesList
);

export const getProjectDeletedUnsyncedSubtitlesList = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectDeletedUnsyncedSubtitlesIds
);

export const getProjectSubtitlesExportStatus = createSelector(
    getProjectSubtitlesState,
    (state) => state.subtitlesExportStatus
);

export const getProjectVideosLanguages = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectVideosLanguages
);

export const getProjectSubtitlesSearchQuery = createSelector(
    getProjectSubtitlesState,
    (state) => state.searchQuery
);

export const getProjectTranscriptCreationLoading = createSelector(
    getProjectSubtitlesState,
    (state) => state.isProjectTranscriptCreationLoading
);

export const getProjectVideosIdsWithTranscriptGeneration = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectVideosIdsWithTranscriptGeneration
);

export const getProjectVideosIdsWithTranslateGeneration = createSelector(
    getProjectSubtitlesState,
    (state) => state.projectVideosIdsWithTranslateGeneration
);

export const getScrollableTranscriptIndex = createSelector(
    getProjectSubtitlesState,
    (state) => state.scrollableTranscriptIndex
);

export const getIsProjectSubtitlesTranslateLoading = createSelector(
    getProjectSubtitlesState,
    (state) => state.isProjectSubtitlesTranslateLoading
);

export const getEditableSentenceId = createSelector(
    getProjectSubtitlesState,
    (state) => state.editableSentenceId
);

export const getAddGeneratedSubtitlesLoading = createSelector(
    getProjectSubtitlesState,
    (state) => state.addGeneratedSubtitlesLoading
);

export const getProjectTranscriptList = createSelector(
    getProjectsState,
    getProjectSubtitlesState,
    (projectsState, state) => {
        const { projectDetails } = projectsState;
        const { projectCurrentSubtitlesList, projectUnsyncedSubtitlesList } = state;

        const projectTranscript = [...projectCurrentSubtitlesList, ...projectUnsyncedSubtitlesList];

        const groupedRelevantTranscript = groupBy(
            projectTranscript,
            'videoId'
        );

        const filteredByLanguageRelevantTranscript =
            [] as TransformedSentence[];

        Object.keys(groupedRelevantTranscript).forEach((id: string) => {
            const sentences = groupedRelevantTranscript[id];
            let filteredSentences;

            if (!projectDetails?.subtitlesNotAttached && projectDetails?.mediaSourcesSettings) {
                const mediaSourcesSettings =
                    projectDetails?.mediaSourcesSettings?.find(
                        (settings) => settings.mediaSourceId === id
                    );
                filteredSentences = sentences.filter(
                    (item) => item.language === mediaSourcesSettings?.language
                );
            } else {
                filteredSentences = sentences.filter(
                    (item) => item.language === projectDetails?.language
                );
            }

            filteredByLanguageRelevantTranscript.push(...filteredSentences);
        });

        return sortBy(
            filteredByLanguageRelevantTranscript,
            (item) => +item.startTime,
            (item) => +item.endTime
        );
    }
);