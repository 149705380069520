import { DateFilterOption } from './filter';

export interface UserStatsFilter {
    dateFrom: Date;
    dateTo: Date;
    dateFilter: DateFilterOption;
}

export enum StatsFilterCategory {
    UPLOAD = 'UPLOAD',
    GENERATION = 'GENERATION',
    ANALYSIS = 'ANALYSIS',
    ALL = 'ALL',
}

export enum AnalysisStatsCategory {
    TRANSCRIPT = 'TRANSCRIPT',
    SHOTS = 'SHOTS',
    FACES = 'FACES',
    COLLECTIONS = 'COLLECTIONS',
    MODERATED_LABELS = 'MODERATED_LABELS',
    OBJECTS = 'OBJECTS',
    CELEBS = 'CELEBS',
}
