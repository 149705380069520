import { AnalysisResultsType } from "interfaces/analysis";
import { MediaFile } from "interfaces/videos";
import groupBy from "lodash.groupby";
import { createSelector } from "reselect";
import { getInProgressStatus } from "utils/statuses";
import { State } from "state/modules";
import {
  Scene,
  SelectedAnalysisResultItem,
  SelectedMetadataItem,
  SelectedSentence,
  SelectedShotItem,
  VideoExplorerState,
} from "./types";

export const getVideoExplorerState = (state: State): VideoExplorerState =>
  state.videoExplorer;

export const getVideoExplorerMinScaleFactor = createSelector(
  getVideoExplorerState,
  (state) => state.minScaleFactor
);

export const getMediaLanguageCode = createSelector(
  getVideoExplorerState,
  (state) => state.mediaLanguageCode
);

export const getVideoExplorerSelectedCategory = createSelector(
  getVideoExplorerState,
  (state) => state.selectedCategory
);

export const getVideoExplorerMinConfidence = createSelector(
  getVideoExplorerState,
  (state) => state.minConfidence
);

export const getVideoExplorerSelectedItems = createSelector(
  getVideoExplorerState,
  (state) => state.selectedItems
);

export const getVideoExplorerSelectedItemById = (id: string) =>
  createSelector(getVideoExplorerSelectedItems, (items) =>
    items.find((item: SelectedMetadataItem) => item.id === id)
  );

export const getVideoExplorerSelectedItemIds = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) => selectedItems.map((item: SelectedMetadataItem) => item.id)
);

export const getVideoExplorerSearchQuery = createSelector(
  getVideoExplorerState,
  (state) => state.searchQuery
);

export const getTimestampsLoading = createSelector(
  getVideoExplorerState,
  (state) => state.isTimestampsLoading
);

export const getSelectedTrailer = createSelector(
  getVideoExplorerState,
  (state) => state.selectedTrailer
);

export const getSentencesToUpdate = createSelector(
  getVideoExplorerState,
  (state) => state.sentencesToUpdate
);

export const checkIsSentenceUpdating = (id: string) =>
  createSelector(
    getSentencesToUpdate,
    (sentencesToUpdate) =>
      sentencesToUpdate.findIndex((itemId: string) => itemId === id) >= 0
  );

export const getSentencesToDelete = createSelector(
  getVideoExplorerState,
  (state) => state.sentencesToDelete
);

export const checkIsSentenceDeleting = (id: string) =>
  createSelector(
    getSentencesToDelete,
    (sentencesToDelete) =>
      sentencesToDelete.findIndex((itemId: string) => itemId === id) >= 0
  );

export const getSelectedObjects = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) === AnalysisResultsType.OBJECT
    ) as Array<SelectedAnalysisResultItem>;
  }
);

export const getSelectedFacialAttributes = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;
    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) ===
          AnalysisResultsType.FACIAL_ATTRIBUTE ||
        (item.labelType || item.type) === AnalysisResultsType.EMOTION
    ) as Array<SelectedAnalysisResultItem>;
  }
);

export const getSelectedCelebs = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) === AnalysisResultsType.CELEBRITY
    ) as Array<SelectedAnalysisResultItem>;
  }
);

export const getSelectedTranscript = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) === AnalysisResultsType.SENTENCE
    ) as Array<SelectedSentence>;
  }
);

export const getSelectedUserDefined = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) === AnalysisResultsType.USER_DEFINED
    ) as Array<SelectedAnalysisResultItem>;
  }
);

export const getSelectedSegments = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter((item: SelectedMetadataItem) => {
      const type = item.type || item.labelType;
      return (
        type === AnalysisResultsType.SHOT ||
        type === AnalysisResultsType.BLACK_FRAME ||
        type === AnalysisResultsType.END_CREDITS ||
        type === AnalysisResultsType.CONTENT ||
        type === AnalysisResultsType.OPENING_CREDITS ||
        type === AnalysisResultsType.STUDIO_LOGO
      );
    }) as Array<SelectedShotItem>;
  }
);

export const getSelectedModeratedLabels = createSelector(
  getVideoExplorerState,
  (state) => {
    const selectedItems = state.selectedItems;

    return selectedItems.filter(
      (item: SelectedMetadataItem) =>
        (item.labelType || item.type) === AnalysisResultsType.MODERATED_LABEL
    ) as Array<SelectedAnalysisResultItem>;
  }
);

export const getVisibleSelectedItems = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems.filter((item: SelectedMetadataItem) => item.isVisible)
);

export const getSortedSelectedVisibleScenes = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) => {
    const items = selectedItems.filter(
      (item: SelectedMetadataItem) => item.isVisible
    );
    const scenes = [] as Array<Scene>;

    items.forEach((item: SelectedMetadataItem) => {
      scenes.push(...item.scenes);
    });

    return scenes.sort((a: Scene, b: Scene) => a.from - b.from);
  }
);

export const getVisibleScenesWithoutTranscript = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) => {
    const items = selectedItems.filter(
      (item: SelectedMetadataItem) => item.isVisible
    );
    const scenes = [] as Array<Scene>;

    items.forEach((item: SelectedMetadataItem) => {
      scenes.push(...item.scenes);
    });

    const sorted = scenes.sort((a: Scene, b: Scene) => a.from - b.from);

    return sorted.filter(
      (item: Scene) => item.type !== AnalysisResultsType.SENTENCE
    );
  }
);

export const getTranscriptVisibleScenes = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) => {
    const items = selectedItems.filter(
      (item: SelectedMetadataItem) => item.isVisible
    );
    const scenes = [] as Array<Scene>;

    items.forEach((item: SelectedMetadataItem) => {
      scenes.push(...item.scenes);
    });

    const sorted = scenes.sort((a: Scene, b: Scene) => a.from - b.from);

    return sorted.filter(
      (item: Scene) => item.type === AnalysisResultsType.SENTENCE
    );
  }
);

export const getGroupedByTypeVisibleScenes = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) => {
    const items = selectedItems.filter(
      (item: SelectedMetadataItem) => item.isVisible
    );
    const scenes = [] as Array<Scene>;

    items.forEach((item: SelectedMetadataItem) => {
      scenes.push(...item.scenes);
    });

    const sorted = scenes.sort((a: Scene, b: Scene) => a.from - b.from);

    return groupBy(sorted, (item: Scene) => item.type);
  }
);

export const getSelectedVisibleObjects = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) === AnalysisResultsType.OBJECT
      )
);

export const getSelectedVisibleFacialAttributes = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) ===
            AnalysisResultsType.FACIAL_ATTRIBUTE ||
          (item.labelType || item.type) === AnalysisResultsType.EMOTION
      )
);

export const getSelectedVisibleCelebs = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) === AnalysisResultsType.CELEBRITY
      )
);

export const getSelectedVisibleTranscript = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) === AnalysisResultsType.SENTENCE
      )
);

export const getSelectedVisibleUserDefined = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) === AnalysisResultsType.USER_DEFINED
      )
);

export const getSelectedVisibleSegments = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter((item: SelectedMetadataItem) => {
        const type = item.type || item.labelType;

        return (
          type === AnalysisResultsType.SHOT ||
          type === AnalysisResultsType.BLACK_FRAME ||
          type === AnalysisResultsType.CONTENT ||
          type === AnalysisResultsType.END_CREDITS
        );
      })
);

export const getSelectedVisibleModeratedLabels = createSelector(
  getVideoExplorerSelectedItems,
  (selectedItems) =>
    selectedItems
      .filter((item: SelectedMetadataItem) => item.isVisible)
      .filter(
        (item: SelectedMetadataItem) =>
          (item.labelType || item.type) === AnalysisResultsType.MODERATED_LABEL
      )
);

export const getVideoExplorerVideoFilter = createSelector(
  getVideoExplorerState,
  (state) => state.videoFilter
);

export const getVideoExplorerVideoListLoading = createSelector(
  getVideoExplorerState,
  (state) => state.isVideoLoading
);

export const getVideoExplorerVideoList = createSelector(
  getVideoExplorerState,
  (state) => state.videos
);

export const getVideoExplorerVideoListTotal = createSelector(
  getVideoExplorerState,
  (state) => state.videoListTotal
);

export const getVideoExplorerVideoListCount = createSelector(
  getVideoExplorerState,
  (state) => state.videos.length
);

export const getOriginalTimecodeEnabledStatus = createSelector(
  getVideoExplorerState,
  (state) => state.isOriginalTimecodeEnabled
);

export const getOriginalTimecode = createSelector(
  getVideoExplorerState,
  (state) => state.originalTimecode
);

export const checkAnalyzerVideosInProgressStatus = createSelector(
  getVideoExplorerState,
  (state) => {
    const videos = state.videos;
    const videoInProgressIndex = videos.findIndex((video: MediaFile) => {
      const statusInProgress = getInProgressStatus(video) as string | undefined;

      if (statusInProgress) {
        return video;
      }
    });

    return videoInProgressIndex >= 0;
  }
);

export const getAnalyzerVideosInProgress = createSelector(
  getVideoExplorerState,
  (state) => {
    const videos = state.videos;
    const videosInProgress = videos.filter((video: MediaFile) => {
      const statusInProgress = getInProgressStatus(video) as string | undefined;

      if (statusInProgress) {
        return video;
      }
    });

    return videosInProgress;
  }
);
